import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { uniq } from 'lodash';

import { heading2 } from '~/App/helpers/mixins';
import { PrimaryButton } from '~/App/shared/components/Elements';
import Selector from '~/App/shared/components/Donation/components/Forms/components/Selector';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { ViewWrapper } from '../../ViewWrapper';


const FlexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  max-width: 380px;
  margin: auto;
`;

const Heading1 = styled.h1`
  ${heading2};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
`;

const Text = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
  margin-bottom: 1.5rem;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 1.5rem;
`;

export function ChooseEmail() {
  const authentication = useAuthenticationContext();

  const [value, setValue] = useState(authentication.user?.email || '');

  const handleSet = useCallback((val) => setValue(val), []);
  const handleSubmit = useCallback(() => {
    authentication.update({ email: value });
  }, [authentication, value]);

  const options = useMemo(() => {
    if (!authentication.user) {
      return [];
    }

    const { email, emails = [] } = authentication.user;
    const all = [email, ...emails];
    const allEmails = all.filter((x): x is string => typeof x === 'string');
    const uniqed = uniq(allEmails);

    return uniqed.map((x) => ({
      label: x,
      value: x
    }));
  }, [authentication]);

  return (
    <ViewWrapper alternativeBackground>
      <FlexWrapper>
        <Heading1>Uppdatera din e-postadress?</Heading1>
        <Text>
          Du har redan en e-postadress registrerad hos oss. Välj vilken du vill
          få information till.
        </Text>
        <Selector
          value={value}
          setValue={handleSet}
          options={options}
          largeLayout="100"
          tinyLayout="100"
          smallLayout="100"
        />
        <SubmitButton
          children="Bekräfta"
          onClick={handleSubmit}
          buttonStyle="cta"
          isLoading={authentication.status === 'loading'}
          isDisabled={authentication.status === 'loading'}
        />
      </FlexWrapper>
    </ViewWrapper>
  );
}
