import React from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';
import { heading2 } from '~/App/helpers/mixins';
import Container from '~/App/shared/components/Container';
import { ResearchProject } from '../ResearchProject';

const HeadingContainer = styled(Container)`
  ${mq('<small')`
    padding-bottom: 1em;
  `};
  ${mq('>=small')`
    padding-top: 1.25em;
    padding-bottom: 2.5em;
  `};
`;

const HeadingTitle = styled.h1`
  ${heading2}
  text-align: left;
  margin-bottom: 1rem;

  ${mq('<small')`
    font-size: 1.375rem;
  `};
`;

const HeadingMetaContainer = styled.div`
    font-size: 1.125rem;
    font-weight: 450;
  ${mq('>small')`
    display: flex;
    font-size: 1.25rem;
    font-weight: 450;
  `};
`;

const HeadingApplicant = styled.div`
  margin-right: 2.75rem;
  ${mq('<small')`
    margin-bottom: 1rem;
  `};
`;

const HeadingMeta = styled.div`
  margin-right: 2.75rem;
`;

export default function Heading({
  title,
  applicant,
  university,
  year
}: Partial<ResearchProject>) {
  return (
    <HeadingContainer width="limited" paddingLeft="small" paddingRight="small">
      <HeadingTitle>{title}</HeadingTitle>
      <HeadingMetaContainer>
        <HeadingApplicant>{applicant}</HeadingApplicant>
        <HeadingMeta className="icon-location">{university}</HeadingMeta>
        <HeadingMeta>{year}</HeadingMeta>
      </HeadingMetaContainer>
    </HeadingContainer>
  );
}
