import { parse } from './parse';
import { stringify } from './stringify';

export { parse } from './parse';
export { stringify } from './stringify';

export * from './types';

export default {
  parse,
  stringify
};

export const queryString = {
  parse,
  stringify
};
