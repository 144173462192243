import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import mq from '~/App/helpers/mq';
import { outlinePrimaryButton } from '~/App/helpers/mixins';

import {
  IScreen,
  ISection,
  IQuestionScreen,
  ISectionStartScreen,
  IBMIScreen,
  IAboutScreen
} from '../types';

import { useSelfTest } from '../context/useSelfTest';

import { QuestionScreen } from './QuestionScreen';
import { SectionStartScreen } from './SectionStartScreen';
import { BMIScreen } from './BMIScreen';
import { Header } from './Header';
import { NextButton } from './NextButton';
import { AboutScreen } from './AboutScreen';
import { Progress } from './Progress';
import { Background } from './Background';

const StyledHeader = styled(Header)`
  display: none;

  ${mq('≥small')`
    display: flex;
  `};
`;

const ScreenWrapper = styled.div`
  margin: 0;
  width: 100%;
  padding: 2rem 1rem;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${mq('≥small')`
    width: 100%;
    max-width: 1000px;
    padding: 2rem 3rem 1rem;
    margin: calc(50vh - 330px) 2rem auto;
    min-height: 450px;
    border: dashed white 1px;
    transform: translateY(5rem);
    justify-content: space-between;
  `};
`;

const NavigationButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 1rem 1rem 0.5rem;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px -12px 20px 0px rgb(0 0 0 / 25%);
  z-index: 1;

  ${mq('≥small')`
    margin-top: 2rem;
    background: none;
    box-shadow: none;
    position: static;
  `};
`;

const PreviousButton = styled.button`
  ${outlinePrimaryButton};
  border: none;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.black};
  min-width: 0;
  padding-left: 0;
  margin-top: 0.25rem;

  &:hover,
  &:focus {
    background: none;
    text-decoration: none;
    color: ${(props) => props.theme.colors.black};

    ${mq('≥small')`
      color: ${({ theme }) => theme.colors.white};
    `};
  }

  ${mq('≥small')`
    color: ${({ theme }) => theme.colors.white};
  `};
`;

type Props = {
  screen: IScreen;
  section: ISection;
};

export function Screen({ screen, section }: Props) {
  const {
    isFirstScreen,
    isLastScreen,
    getAnswer,
    navigateTo,
    complete
  } = useSelfTest();

  const is = useCallback(
    <T extends IScreen>(screen: IScreen, type: string): screen is T =>
      screen.type === type,
    []
  );


  const value = useMemo(() => getAnswer(screen.id), [getAnswer, screen.id]);

  const screenMarkup = useMemo(() => {
    if (is<ISectionStartScreen>(screen, 'SectionStartScreen')) {
      return <SectionStartScreen screen={screen} />;
    }

    if (is<IQuestionScreen>(screen, 'QuestionScreen')) {
      return <QuestionScreen screen={screen} />;
    }

    if (is<IBMIScreen>(screen, 'BMIScreen')) {
      return <BMIScreen screen={screen} />;
    }

    if (is<IAboutScreen>(screen, 'AboutScreen')) {
      return <AboutScreen screen={screen} />;
    }
  }, [is, screen]);

  return useMemo(
    () => (
      <Background>
        <StyledHeader />
        <ScreenWrapper>
          <Progress screen={screen} section={section} />
          {screenMarkup}
          <NavigationButtonRow>
            {!isFirstScreen && (
              <PreviousButton onClick={navigateTo(-1)}>
                Föregående
              </PreviousButton>
            )}

            <NextButton
              value={value}
              screen={screen}
              children={isLastScreen ? 'Visa resultat' : 'Nästa'}
              onClick={isLastScreen ? complete : navigateTo(1)}
              isLastScreen={isLastScreen ? true : false}
            />
          </NavigationButtonRow>
        </ScreenWrapper>
      </Background>
    ),
    [screen, section, screenMarkup, isFirstScreen, navigateTo, value, isLastScreen, complete]
  );
}
