import React from 'react';
import styled from 'styled-components';
import { find, findIndex } from 'lodash';
import { formatMoney } from 'accounting';

// Helpers
import { heading3 } from '~/App/helpers/mixins';

// Shared components
import { PackageFeatureList, PackageIllustration } from './components/Packages';
import { Divider } from '~/App/shared/components/Blocks';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import { StepWrapper } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1.125rem;
`;

const Heading3 = styled(FormHeading)`
  text-align: center;
  margin-bottom: 2rem;
`;

const Heading4 = styled.h4`
  ${heading3};
  margin-bottom: 0.75rem;
`;

export const ChristmasPackageSummary = function (props) {
  const { part = 1, area } = props;

  let image = props.image;
  let price = props.price;
  let packages = props.packages;
  let activePackageId = props.activePackageId;

  if (area === 'form' && (part === 2 || part === 3)) {
    image = null;
    packages = null;
  }

  const activePackage = find(packages, { productId: activePackageId }) || {};
  const activePackageIndex = findIndex(packages, {
    productId: activePackageId
  });

  return (
    <React.Fragment>
      {image && (
        <PackageIllustration
          image={image}
          packageNumber={activePackageIndex + 1}
          totalPackages={packages.length}
        />
      )}
      {packages && (
        <div>
          <Heading3>{activePackage.name}</Heading3>
          <PackageFeatureList
            activePackageId={activePackageId}
            packages={packages}
          />
          <Divider
            width="narrow"
            paddingLeft="none"
            paddingRight="none"
            height="2px"
            alternativeColor={true}
          />
        </div>
      )}
      <StepWrapper paddingTop="small">
        <FormHeading>Din gåva</FormHeading>
        <AmountWrapper>
          <span>Gåvobelopp</span>
          <span>
            <Heading4>
              {formatMoney(price, 'kr', 0, ' ', null, '%v %s')}
            </Heading4>
          </span>
        </AmountWrapper>
      </StepWrapper>
    </React.Fragment>
  );
};
