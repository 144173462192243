// Config
import { collection } from '~/App/config/default-images';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';

export const buildState = (data) => ({
  collection: {
    name: '',
    senderName: '',
    image: collection[0],
    description: data.collectionDescription || '',
    monetaryGoal: '',
    collectionType: 'normal',
    showProgressBadge: true
  },
  authenticated: false,
  ssn: '',
  ssnFieldVisible: !isMobileBrowser()
});

export const partOptions = {
  name: 'create-collection-form',
  parts: 2,
  validationKeys: {
    1: [
      'collection.name',
      'collection.senderName',
      'collection.image',
      'collection.description',
      'collection.monetaryGoal'
    ],
    2: ['authenticated']
  }
};
