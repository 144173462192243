import React, { useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';

// helpers
import loadScript from '~/App/helpers/load-script';

import { theme } from './Graph.AmCharts.theme';

const Canvas = styled.div`
  width: 100%;
  height: 500px;
  margin-bottom: 1rem;
`;

export default function GraphAmCharts({ config = {} }) {
  const ref = useRef();
  const instance = useRef();

  const setup = useCallback(() => {
    window.am4core.useTheme(theme(config.type));
    window.am4core.options.commercialLicense = true;

    const chart = window.am4core.createFromConfig(
      {
        ...config,
        language: {
          locale: {
            _decimalSeparator: ',',
            _thousandSeparator: ' '
          }
        }
      },
      ref.current
    );

    instance.current = chart;

    setTimeout(() => {
      if (!ref.current) return;

      for (const el of ref.current.querySelectorAll(
        'svg > defs clipPath:first-child'
      )) {
        el.remove();
      }
    }, 500);
  }, [config]);

  const load = useCallback(async () => {
    const scripts = [
      { url: 'https://cdn.amcharts.com/lib/4/core.js' },
      { url: 'https://cdn.amcharts.com/lib/4/charts.js' },
      { url: 'https://cdn.amcharts.com/lib/4/maps.js' }
    ];

    if (config.type === 'MapChart') {
      scripts.push({
        url: 'https://cdn.amcharts.com/lib/4/geodata/data/countries2.js'
      });
    }

    for (const script of scripts) {
      await loadScript(script);
    }

    window.am4core?.ready(setup);
  }, [config.type, setup]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    load();

    return () => {
      instance.current?.dispose();
    };
  }, [load]);

  return <Canvas ref={ref} />;
}
