import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';

// Shared components
import { ColumnCard } from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import Slider from '~/App/shared/components/Slider';

const StyledColumnCard = styled(ColumnCard)`
  ${mq('<medium')`
     padding: 1.5rem 0 0;
     position: relative;
     max-height: 13rem;
     overflow: hidden;
  `};
`;

const MobileOnly = styled.span`
  display: block;

  ${mq('≥medium')`
    display: none;
  `};
`;

const DesktopOnly = styled.span`
  display: none;

  ${mq('≥medium')`
    display: block;
  `};
`;

const StyledFormHeading = styled(FormHeading)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Paragraph = styled.p`
  text-align: center;

  ${mq('<medium')`
     margin-bottom: 1.5rem;
  `};
`;

const Image = styled.img`
  display: block;
  margin: 2rem auto 1rem;
  height: 2.5rem;

  ${mq('<medium')`
     margin-top: 0;
  `};
`;

export default function RightColumn({ heading, items = [] }) {
  const renderItem = useCallback(
    (item) => (
      <div key={item.text}>
        <Image src={mediaHelper.getUrl(item.image, { height: 40 })} />
        {item.text && <Paragraph>{item.text}</Paragraph>}
      </div>
    ),
    []
  );

  const renderedItems = useMemo(() => items?.map(renderItem), [
    items,
    renderItem
  ]);

  if (!items || items.length < 1) {
    return null;
  }

  return (
    <StyledColumnCard>
      <StyledFormHeading>{heading}</StyledFormHeading>
      <MobileOnly>
        <Slider>{renderedItems}</Slider>
      </MobileOnly>
      <DesktopOnly>{renderedItems}</DesktopOnly>
    </StyledColumnCard>
  );
}
