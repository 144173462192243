import React from 'react';

// Shared components
import {
  Content,
  BlockHero,
  Breadcrumbs
} from '~/App/shared/components/Blocks';
import { TableOfContents } from '~/App/shared/components/TableOfContents';
import { PageFooter } from '~/App/shared/components/PageFooter';

export default class ArticlePage extends React.Component {
  constructor(props) {
    super(props);

    this.heroRef = React.createRef();
  }

  render() {
    const {
      hero = {},
      content = {},
      showHero = false,
      breadcrumbs = {},
      publishedAt = '',
      revisionDate = '',
      showBreadcrumbs = false,
      showPageFooter = true,
      author = {},
      editor = {},
      auditor = {},
      auditDate = null,
      tableOfContents = [],
      showTOC = false,
      sharingIcons = {}
    } = this.props;

    return (
      <div>
        {showHero && (
          <div ref={this.heroRef}>
            <BlockHero {...hero} />
          </div>
        )}
        {showBreadcrumbs && <Breadcrumbs {...breadcrumbs} />}
        {showTOC && (
          <TableOfContents
            tableOfContents={tableOfContents}
            heroRef={this.heroRef}
          />
        )}
        <div id={content.hash}>
          <Content
            {...content}
            publishedAt={publishedAt}
            revisionDate={revisionDate}
          />
        </div>
        {showPageFooter && (
          <PageFooter
            sharingIcons={sharingIcons}
            author={author}
            editor={editor}
            auditor={auditor}
            auditDate={auditDate}
            width={content.width}
          />
        )}
      </div>
    );
  }
}
