import {
  useContactInformationValues,
  ContactCustomerValues
} from '~/App/shared/components/Donation/components/States/useContactInformationValues';

import {
  usePaymentValues,
  PaymentValues
} from '~/App/shared/components/Donation/components/States/usePaymentValues';
import {
  ProductValues,
  useProductValues
} from '~/App/shared/components/Donation/components/States/useProductValues';
import { paymentMethods } from '~/App/config/paymentMethods';
import { useMemo } from 'react';

export type CommerceCheckoutState = ContactCustomerValues &
  PaymentValues &
  ProductValues;

export function useCommerceCheckoutState(): CommerceCheckoutState {
  const product = useProductValues({});

  const defaultValues = useMemo(
    () => ({
      values: {
        ...product,
        paymentMethod: {
          id: paymentMethods.klarnaPayments,
          name: 'Konto eller kort'
        }
      },
      formContent: {
        originPath: '',
        redirectPath: '/gavoshop/kassa/{order.id}/tack'
      }
    }),
    [product]
  );

  const payment = usePaymentValues(defaultValues);

  const contactCustomer = useContactInformationValues(payment);

  return contactCustomer;
}
