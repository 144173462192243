import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-direction: column;
  background: #f0f9fc;
  width: 100%;
  padding: 1rem;
  justify-content: center;
  text-align: center;
  margin-top: 0.5rem;
`;

const ShippingText = styled.p`
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
`;

export function FreeShipping() {
  return (
    <Wrapper>
      <ShippingText>
        <b>Fri frakt</b> vid köp över 295kr
      </ShippingText>
      <div>
        <Icon src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-swish_3x.png" />
        <Icon src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-visa-card_3x.png" />
        <Icon src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-mastercard_3x.png" />
        <Icon src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-amex_3x.png" />
      </div>
    </Wrapper>
  );
}
