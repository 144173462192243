import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { v4 } from 'uuid';

import { scrollLock } from '~/App/helpers/scrollLock';
import { ScrollLockContext } from './ScrollLockContext';

type Props = {
  children: ReactNode;
};

export function ScrollLockProvider({ children }: Props) {
  const [locked, setLocked] = useState<Record<string, boolean>>({});

  const lock = useCallback((shouldLock: boolean | undefined = true) => {
    if (!shouldLock) {
      return () => null;
    }

    const id = v4();

    setLocked((state) => ({ ...state, [id]: true }));

    return () => setLocked((state) => ({ ...state, [id]: false }));
  }, []);

  const isActive = useMemo(() => {
    for (const key in locked) {
      if (locked[key]) {
        return true;
      }
    }

    return false;
  }, [locked]);

  useEffect(() => {
    if (isActive) {
      return scrollLock(true);
    }

    return scrollLock(false);
  }, [isActive]);

  return useMemo(
    () => <ScrollLockContext.Provider value={{ lock }} children={children} />,
    [children, lock]
  );
}
