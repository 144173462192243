/* eslint-disable @typescript-eslint/ban-types */
import { css, DefaultTheme, CSSObject, FlattenInterpolation, FlattenSimpleInterpolation, Interpolation, InterpolationFunction, SimpleInterpolation, ThemedStyledProps } from 'styled-components';
import { breakpoints } from '~/App/config/mediaBreakpoints';

const lessThanOperators = ['<', '≤', '<='];
const greaterThanOperators = ['>', '≥', '>='];

const unitIntervals = {
  '': 0,
  px: 1,
  em: 0.01,
  rem: 0.1
};

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
type BreakpointKeys = keyof typeof breakpoints;

let settings = {
  operators: [...lessThanOperators, ...greaterThanOperators],
  breakpoints: breakpoints
};

function pxToEm(value: string) {
  return `${parseFloat(value) / 16}em`;
}

function getValueUnit(value: string): [number, string] {
  const number = parseFloat(value);
  const unit = value.slice(`${number}`.length);

  return [number, unit];
}

function isObjKey<T>(key: any, obj: T): key is keyof T {
  return key in obj;
}

function getNamedValue(value: BreakpointKeys | string) {
  if (isObjKey(value, settings.breakpoints) && settings.breakpoints[value]) {
    return settings.breakpoints[value];
  }

  return value;
}

function getQueryOperator(query: string) {
  return settings.operators
    .filter((operator) => !query.indexOf(operator))
    .reduce((_, c) => c, '');
}

function getQueryDimension(query: string, operator: string) {
  const operatorIndex = query.indexOf(operator);
  const parsedDimension = query.slice(0, operatorIndex);

  if (parsedDimension.length > 0) {
    return parsedDimension;
  }

  return 'width';
}

function getQueryPrefix(operator: string) {
  return greaterThanOperators.indexOf(operator) !== -1 ? 'min' : 'max';
}

function getQueryValue(query: string, operator: string) {
  const operatorIndex = query.indexOf(operator);
  const value = query.slice(operatorIndex + operator.length);

  const namedValue = getNamedValue(value);
  const [numericValue, unit] = getValueUnit(namedValue);

  const interval = isObjKey(unit, unitIntervals) ? unitIntervals[unit] : 0;

  if (operator === '>') {
    return `${numericValue + interval}${unit}`;
  }

  if (operator === '<') {
    return `${numericValue - interval}${unit}`;
  }

  return namedValue;
}

const parseQuery = (...queries: string[]) =>
  queries.reduce((prev, query, index) => {
    const operator = getQueryOperator(query);
    const dimension = getQueryDimension(query, operator);
    const prefix = getQueryPrefix(operator);
    const value = getQueryValue(query, operator);

    return prev + (index ? ' and ' : '') + `(${prefix}-${dimension}: ${value})`;
  }, '');

export interface BaseThemedCssFunction<P extends object = {}> {
  (first: TemplateStringsArray | CSSObject, ...interpolations: SimpleInterpolation[]): FlattenSimpleInterpolation;
  (
    first: TemplateStringsArray | CSSObject | InterpolationFunction<ThemedStyledProps<{}, DefaultTheme>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<{}, DefaultTheme>>>
  ): FlattenInterpolation<ThemedStyledProps<{}, DefaultTheme>>;
  (
    first: TemplateStringsArray | CSSObject | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
  ): FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>;
}

type Args<P extends object> = ArgumentTypes<BaseThemedCssFunction<P>>

function mq<P extends object = {}>(queries: string) {
  return function (...args: Args<P>) {
    return css<P>`
      @media ${parseQuery(queries)} {
        ${css<P>(...args)};
      }
  `;
  }
}

type CallbackProps = {
  settings: typeof settings,
  helpers: {
    pxToEm: typeof pxToEm
  }
}

const create = (callback: (props: CallbackProps) => typeof settings) => {
  settings = callback({ settings: settings, helpers: { pxToEm } });

  return mq;
};

export {
  mq,
  create,
  pxToEm,
  parseQuery,
  getValueUnit,
  getNamedValue,
  getQueryValue,
  getQueryPrefix,
  getQueryOperator,
  getQueryDimension
};
