import React, { useMemo } from 'react';
import styled from 'styled-components';

import { SubmitButton } from '../../components/Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '../../components/Forms/components/SwishOtherDeviceButton';
import { Section } from '../../../MultiPartForm';
import { IValidation } from '../../../Validation';
import { StateValues } from '../../components/States';
import { ISubmit } from '../../components/Submits';
import { ICollection } from '~/types/ICollection';
import { MultiPartFormValues } from '../../../MultiPartForm';
import { FormContent } from '../CollectionDonation';
import { Payment } from './Payment';
import { Product } from './Product';

import { AlertMessageContainer } from '../../../Elements';
import { IntegrityTextArea } from '../../components/Forms/components/IntegrityTextArea';
import { getPurchaseErrorMessage } from '../../components/Forms/helpers';

const IntegrityTextAreaWrapper = styled.div`
  margin: 0.5rem 0;
`;

type Props = {
  values: StateValues & MultiPartFormValues;
  submit: ISubmit;
  collection: ICollection;
  validation: IValidation;
  formContent: FormContent;
};

export function Form({
  values,
  submit,
  collection,
  validation,
  formContent
}: Props) {
  const submitButtons = useMemo(
    () => (
      <>
        {submit.errors && !submit.isPolling && !submit.isSending && (
          <AlertMessageContainer messageStyle="error">
            {getPurchaseErrorMessage(submit.exceptionCode)}
          </AlertMessageContainer>
        )}
        <SubmitButton values={values} validation={validation} submit={submit} />
        <SwishOtherDeviceButton
          values={values}
          text={formContent.anotherDeviceText}
        />

        <IntegrityTextAreaWrapper>
          <IntegrityTextArea values={values} />
        </IntegrityTextAreaWrapper>
      </>
    ),
    [formContent.anotherDeviceText, submit, validation, values]
  );

  return useMemo(
    () => (
      <form onSubmit={submit.handleSubmit} noValidate>
        <Section
          values={values}
          title="Din gåva"
          nextStepButton={{
            text: 'Till betalsätt'
          }}
          part={1}
          validation={validation}
        >
          <Product
            values={values}
            formContent={formContent}
            validation={validation}
            collection={collection}
          />
        </Section>
        <Section
          values={values}
          title="Välj betalsätt"
          part={2}
          validation={validation}
        >
          <Payment
            submitButtons={submitButtons}
            values={values}
            validation={validation}
            formContent={{
              ...formContent
            }}
          />
        </Section>
      </form>
    ),
    [
      collection,
      submit.handleSubmit,
      values,
      validation,
      submitButtons,
      formContent
    ]
  );
}
