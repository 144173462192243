import React, { useMemo, ReactNode, useCallback } from 'react';
import styled from 'styled-components';

// Helpers
import { partIsClickable as helperPartIsClickable } from '~/App/helpers/multipartHelper';
import { card } from '~/App/helpers/mixins';
import { MultiPartFormValues } from '~/App/shared/components/MultiPartForm';
import { IValidation } from '~/App/shared/components/Validation';

type ColumnSegmentStylesProps = {
  isOpen: boolean;
  isStarted: boolean;
  previousIsValid: boolean;
  partIsClickable: boolean;
};

const ColumnSegmentStyles = styled.div<ColumnSegmentStylesProps>`
  ${card}
  margin-top: 1rem;

  opacity: ${(props) =>
    props.isOpen ||
    props.isStarted ||
    (props.previousIsValid && props.partIsClickable)
      ? '1'
      : '0.5'};
  display: ${({ isOpen }) => (!isOpen ? 'flex' : 'block')};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }
`;

type Props = {
  values: MultiPartFormValues;
  part: number;
  children: ReactNode;
  className?: string;
  validation: IValidation;
  title?: string;
};

export function ColumnSegment({
  values,
  part,
  children,
  validation,
  className,
  title
}: Props) {
  const partIsClickable = useMemo(
    () =>
      helperPartIsClickable(
        values.partOptions.currentPart,
        values.partOptions.partStarted
      )(part),
    [part, values.partOptions.currentPart, values.partOptions.partStarted]
  );

  const isOpen = values.partOptions.currentPart === part;
  const isStarted = values.partOptions.partStarted[part];

  const previousIsValid = useMemo(
    () =>
      part === 1 ||
      validation.areValid(values.handlers.getValidationKeys(part - 1)),
    [part, validation, values.handlers]
  );

  const handleClick = useCallback(() => {
    if (!partIsClickable) {
      return;
    }

    values.handlers.handleGoToPart(part, title);
  }, [part, partIsClickable, values.handlers, title]);

  return useMemo(
    () => (
      <ColumnSegmentStyles
        className={className}
        isOpen={isOpen}
        isStarted={isStarted}
        id={values.handlers.buildPartName(part)}
        onClick={handleClick}
        previousIsValid={previousIsValid}
        partIsClickable={partIsClickable}
        children={children}
      />
    ),
    [
      children,
      className,
      handleClick,
      isOpen,
      isStarted,
      part,
      partIsClickable,
      previousIsValid,
      values.handlers
    ]
  );
}
