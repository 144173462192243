import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import queryString from '~/lib/query-string';
import { useQuery } from '~/App/shared/hooks/use-query';
import { IAnswer } from '~/types/IQuestionSession';

import { IQuestionSection, IScreen, ISelfTestConfig } from '../SelfTest/types';
import { Navigation } from './components/Navigation';
import { Hero } from './components/Hero';
import { SaveYourResultSection } from './components/SaveYourResultSection';
import { ResultSection } from './components/ResultSection';
import { SupportSection } from './components/SupportSection';
import { Footer } from '~/App/components/Footer';

type Query = {
  section: string;
};

type Props = {
  answers: IAnswer[];
  config: ISelfTestConfig;
};

export function SelfTestResult({ answers, config }: Props) {
  const query = useQuery<Query>();
  const location = useLocation();
  const navigate = useNavigate();

  const screens = useMemo(
    () =>
      config.sections.reduce(
        (prev: IScreen[], current) => [...prev, ...current.screens],
        []
      ),
    [config.sections]
  );

  const sections = useMemo(
    () =>
      config.sections.filter(
        (x): x is IQuestionSection => x.type === 'QuestionSection'
      ),
    [config.sections]
  );

  const section = useMemo(() => sections.find((x) => x.id === query.section), [
    query.section,
    sections
  ]);

  useEffect(() => {
    if (query.section) {
      return;
    }

    if (section) {
      return;
    }

    if (!config.sections.length) {
      return;
    }

    for (const section of config.sections) {
      if (section.id) {
        const params = queryString.stringify({
          ...query,
          section: section.id
        });

        return navigate(`${location.pathname}?${params}`, {
          replace: true
        });
      }
    }
  }, [navigate, location.pathname, query, section, config]);

  if (!section) {
    return null;
  }

  return (
    <>
      <div>
        <Hero
          screens={screens}
          answers={answers}
          image={section.backgroundImageURL}
        />
        <Navigation sections={sections} answers={answers} />
        <ResultSection section={section} answers={answers} />
        <SaveYourResultSection />
        <SupportSection />
      </div>
      <Footer />
    </>
  );
}
