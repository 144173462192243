// Helpers
import { prettyFormat } from '~/App/helpers/number-helper.js';

// Consts
import { collectionTypes } from '~/App/config/collectionTypes';

export const GOAL_TYPES = {
  DISTANCE: 'distance',
  MONETARY: 'monetary'
};

const convertToNumber = (input) => {
  if (typeof input !== 'number') {
    return Number(input);
  } else {
    return input;
  }
};

export const getGoalType = (collection) => {
  const isLopbandet = collection.collectionType === collectionTypes.lopbandet;

  if (isLopbandet) {
    return GOAL_TYPES.DISTANCE;
  } else {
    return GOAL_TYPES.MONETARY;
  }
};

export const getProgress = (collection, goalType) => {
  const {
    status: monetaryProgress,
    totalDistance: distanceProgress
  } = collection;

  let progress;
  switch (goalType) {
    case GOAL_TYPES.MONETARY:
      progress = monetaryProgress;
      break;

    case GOAL_TYPES.DISTANCE:
      progress = distanceProgress;
      break;

    default:
      progress = 0;
      break;
  }

  progress = convertToNumber(progress);

  if (goalType === GOAL_TYPES.DISTANCE) {
    progress = progress / 1000;
  }

  return progress;
};

export const formatProgress = (collection, goalType) => {
  const progress = getProgress(collection, goalType);

  return prettyFormat(progress);
};

export const getProgressPercent = (collection, goalType) => {
  const {
    progress: monetaryPercentageProgress,
    totalDistance: distanceProgress,
    distanceGoal
  } = collection;

  switch (goalType) {
    case GOAL_TYPES.MONETARY:
      return monetaryPercentageProgress;

    case GOAL_TYPES.DISTANCE:
      return Math.min((distanceProgress / distanceGoal) * 100, 100);

    default:
      return 100;
  }
};

export const getGoal = (collection, goalType) => {
  const { goal: monetaryGoal, distanceGoal } = collection;
  const formattedMonetaryGoal = monetaryGoal
    ? convertToNumber(monetaryGoal)
    : 0;
  const formattedDistanceGoal = distanceGoal
    ? convertToNumber(distanceGoal)
    : 0;

  switch (goalType) {
    case GOAL_TYPES.MONETARY:
      return formattedMonetaryGoal;

    case GOAL_TYPES.DISTANCE:
      return formattedDistanceGoal / 1000;

    default:
      return 0;
  }
};
