import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Helpers
import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';
import { collections } from '~/App/helpers/http';
import { useLogin } from '~/App/shared/hooks/use-login';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { pushGTMEvent } from '~/App/helpers/gtm-helper';

function Submit({ children, collection, ssn }) {
  const [errorCode, setErrorCode] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const navigate = useNavigate();
  const login = useLogin();
  const { isIdle, isAnonymous } = useAuthenticationContext();

  const beginOperation = useCallback(() => {
    setErrorCode(null);
    setHasErrors(false);
    setIsSending(true);
  }, [setErrorCode, setHasErrors, setIsSending]);

  const operationFailed = useCallback(
    (error) => {
      setErrorCode(error.response || error.response.status || 500);
      setHasErrors(true);
      setIsSending(false);
    },
    [setErrorCode, setHasErrors, setIsSending]
  );

  useEffect(() => {
    if (!isSending || !isIdle || !isAnonymous) {
      return;
    }

    setIsSending(false);
  }, [isSending, isAnonymous, isIdle, setIsSending]);

  // Login using BankId
  const performLogin = useCallback(
    async (sameDevice) => {
      const response = await login.handlers.handleLogin({
        qrInit: !sameDevice,
        sameDevice: sameDevice
      });

      return response.success;
    },
    [login, ssn]
  );

  // Upload file to Cloudinary
  const performUpload = useCallback(async (url) => {
    if (!url) {
      return null;
    }

    const fetchResponse = await fetch(url);
    const blob = await fetchResponse.blob();
    const uploadResponse = await uploadImage(blob, {
      timeout: 30000,
      folder: 'collection-pictures'
    });

    return uploadResponse?.data?.secure_url;
  }, []);

  // Post to MW
  const createCollection = useCallback(async () => {
    try {
      const imageUrl = await performUpload(collection.image);
      const { data = {} } = await collections.create({
        data: {
          collection: {
            name: collection.name,
            senderName: collection.senderName,
            description: collection.description,
            monetary_goal: collection.monetaryGoal || 0,
            collection_type: collection.collectionType,
            remote_image_url: imageUrl,
            show_progress_badge: collection.showProgressBadge
          }
        }
      });

      pushGTMEvent({
        category: 'Forms',
        action: 'Insamling',
        label: 'Skapad',
        value: `${collection.monetaryGoal}`
      });
      navigate(`/insamlingar/${data.slug}`);
    } catch (error) {
      operationFailed(error);
    }
  }, [collection, navigate, operationFailed, performUpload]);

  const handleSubmit = useCallback(
    async (event, sameDevice = false) => {
      event.preventDefault();
      beginOperation();

      if (!isAnonymous) {
        await createCollection();
        return;
      }

      if (!(await performLogin(sameDevice))) {
        return;
      }

      await createCollection();

      setIsSending(false);
    },
    [createCollection, beginOperation, setIsSending, performLogin, isAnonymous]
  );

  return children({
    handleSubmit,
    errorCode,
    hasErrors,
    isSending
  });
}

export default Submit;
