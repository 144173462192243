import styled from 'styled-components';

// Shared
import mq from '~/App/helpers/mq';
import {
  heading2,
  heading3,
  heading4,
  description2
} from '~/App/helpers/mixins';
import { close, check } from '~/App/helpers/icons';

// Shared components
import { CheckIcon } from '~/App/shared/components/Fields/Components';
import { CrossIcon } from '~/App/shared/components/Fields/Components';

export const FormTitle = styled.h1`
  ${heading2}
  font-size: 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: ${(props) => props.theme.themeColors.primary};
  max-width: 494px;
  margin-right: auto;
  margin-left: auto;

  ${mq('≥medium')`
  max-width: none;
`};
`;

export const FormHeading = styled.h3`
  ${heading3};
  margin-bottom: ${({ isOpen = true }) => (isOpen ? '0.75rem' : '0')};
  position: relative;

  color: ${({ isOpen = true, theme }) =>
    isOpen ? 'inherit' : theme.colors.dust};
  cursor: ${(props) => (props.isLink ? 'pointer' : 'auto')};

  &::before {
    ${close};
    display: ${(props) =>
      props.isValid === false && !props.isOpen && props.isStarted
        ? 'block'
        : 'none'};
    position: absolute;
    top: -0.25rem;
    left: -0.5rem;
    width: 1.9375rem;
    height: 1.625rem;
    background-color: ${(props) => props.theme.colors.white};
    font-size: 1.5rem;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.errorRed};

    ${mq('≥small')`
      width: 2rem;
      height: 1.6875rem;
      left: -0.25rem;
      font-size: 1.625rem;
    `};
  }

  &::after {
    ${check};
    display: ${(props) => (props.isValid && !props.isOpen ? 'block' : 'none')};
    position: absolute;
    top: -0.25rem;
    left: -0.5rem;
    width: 1.9375rem;
    height: 1.625rem;
    background-color: ${(props) => props.theme.colors.white};
    font-size: 1.5rem;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.deYork};

    ${mq('≥small')`
      width: 2rem;
      height: 1.6875rem;
      left: -0.25rem;
      font-size: 1.625rem;
    `};
  }
`;

export const FormSubHeading = styled.h4`
  ${heading4};
  font-weight: bold;
  padding-bottom: 0.25rem;
`;

export const InfoText = styled.span`
  ${description2};
  display: block;
  line-height: 1.1875rem;
`;

export const NewFormHeading = styled.h3`
  ${heading3};
  margin-bottom: ${({ isOpen = true }) => (isOpen ? '0.75rem' : '0')};
  padding-left: ${({ isOpen = true, isStarted = false }) =>
    isOpen ? '0' : isStarted ? '2.25rem' : '0'};
  position: relative;
  color: inherit;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'auto')};

  ${mq('≥small')`
    padding-left: ${({ isOpen = true, isStarted = false }) =>
      isOpen ? '0' : isStarted ? '2.25rem' : '0'};
  `};

  &::before {
    ${CrossIcon};
  }

  &::after {
    ${CheckIcon};
  }
`;
