import React, { useMemo } from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';
import { Period } from '~/types/IPurchase';
import { MemoryPeriodImage } from '../../../../../../../components/MemoryPeriodImage';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled(MemoryPeriodImage)`
  margin-right: 1rem;

  ${mq('>small')`
    margin-right: 1.125rem;
  `}
`;

const Link = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.darkBlue};

  ${mq('≥small')`
    font-size: 1.125rem;
    line-height: 1.25rem;
  `};
`;

const Text = styled.div`
  font-size: 0.75rem;

  ${mq('>small')`
    font-size: 0.875rem;
  `}
`;

type Props = {
  period: Period | null;
  showPreview: () => void;
};

export function MemoryTypePreview({ period, showPreview }: Props) {
  const text = useMemo(() => {
    switch (period) {
      case 'Month':
        return 'Bidra till minnessidan varje månad. Du stöttar då den livsviktiga forskningen och får ett fint ljus på ditt minne. Du väljer belopp och betalsätt senare.';
      case 'Once':
        return 'Bidra till den livsviktiga cancerforskningen så pryds ditt minne med ett ljus. Du väljer belopp och betalsätt senare.';
      case null:
        return 'Minnet visas utan ljus.';
      default:
        return null;
    }
  }, [period]);

  return (
    <Wrapper>
      <Image period={period} />
      <Column>
        <Text children={text} />
        <Link onClick={showPreview} children="Visa minne" />
      </Column>
    </Wrapper>
  );
}
