import styled from 'styled-components';
import mq from '~/App/helpers/mq';

type StepWrapperProps = {
  marginTop?: 'none' | 'tiny' | 'medium';
  paddingTop?: 'small';
};

export const StepWrapper = styled.div<StepWrapperProps>`
  margin-top: ${({ marginTop }) => {
    switch (marginTop) {
      case 'none':
        return '0rem';
      case 'tiny':
        return '0.25rem';
      case 'medium':
        return '2.5rem';
      default:
        break;
    }

    return '1rem';
  }};
  padding-top: ${({ paddingTop }) => (paddingTop === 'small' ? '1rem' : '0')};
`;

type HiddenAboveMediumProps = {
  marginBottom?: 'medium';
};

export const HiddenAboveMedium = styled.div<HiddenAboveMediumProps>`
  margin-top: 1rem;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom === 'medium' ? '2rem' : '1rem'};

  ${mq('≥medium')`
    display: none;
  `};
`;
