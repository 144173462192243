import React from 'react';
import styled from 'styled-components';

// Helpers
import omitProps from '~/App/helpers/omit-props';
import { caretLeft } from '~/App/helpers/icons';

// Shared components
import Link from '~/App/shared/components/Link';
import Container from '~/App/shared/components/Container';
import {
  BackgroundColor,
  BlockBackground
} from '~/App/shared/components/BlockBackground';
import { Width } from '~/types/Block';
import { useLocation } from 'react-router-dom';

const Block = styled.div`
  margin: 0 auto;
  font-size: 0.875em;
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

const List = styled.div`
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ListItem = styled.div`
  display: inline-block;
`;

const StyledLink = styled(omitProps(Link, ['last']))`
  display: inline-block;
  vertical-align: middle;
  color: underline;

  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }
`;

const Separator = styled.div`
  display: inline-block;
  padding-right: 0.75rem;
  vertical-align: middle;

  &:after {
    ${caretLeft};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.charcoal};
  }
`;

type Props = {
  text: string;
  linkToPage: string;
  width?: Width;
  verticalSpacing?: string;
  backgroundColor?: BackgroundColor;
};

export default function TopLeftNavigation(props: Props) {
  const {
    width = 'normal',
    verticalSpacing = '',
    backgroundColor = 'none'
  } = props;

  const searchParameters = useLocation().state;
  const destination = props.linkToPage + (typeof searchParameters === "string" ? searchParameters : '')

  const paddingTop =
    verticalSpacing === null
      ? 'none'
      : verticalSpacing.split('topLarge').length === 2
      ? 'large'
      : 'none';

  const paddingBottom =
    verticalSpacing === null
      ? 'none'
      : verticalSpacing.split('bottomLarge').length === 2
      ? 'large'
      : 'none';

  return (
    <BlockBackground backgroundColor={backgroundColor}>
      <Container
        width={width}
        paddingTop={paddingTop}
        paddingLeft="small"
        paddingRight="small"
        paddingBottom={paddingBottom}
      >
        <Block>
          <List>
            <ListItem>
              <Separator />
                <StyledLink to={destination}>{props.text}</StyledLink>
            </ListItem>
          </List>
        </Block>
      </Container>
    </BlockBackground>
  );
}
