import { useMemo, useCallback, useEffect } from 'react';

import { isActiveExperiment } from '~/App/config/abExperiments';
import { useAbContext } from '~/App/contexts/Ab';

import * as cookie from '~/App/helpers/cookie';

type Props = {
  experimentId: string;
  numberOfVariants: number;
  weights?: number[];
};

type UseExperiment = number;

type ExperimentId = string;
type VariantId = string;
type ExperimentCookie = Record<ExperimentId, VariantId>;

const COOKIE_NAME = 'cf_experiment';

export function useExperiment({
  experimentId,
  numberOfVariants,
  weights
}: Props): UseExperiment {
  const cohortIds = useAbContext((state) => state.cohortIds);

  const cohortId = useMemo(() => {
    const value = cohortIds[experimentId];

    if (!value) return 0;
    if (typeof value !== 'number') return 0;
    if (!(value >= 0 && value < 1)) return 0;
    if (!Number.isFinite(value)) return 0;

    return value;
  }, [cohortIds, experimentId]);

  const variantId = useMemo(() => {
    if (weights && Array.isArray(weights)) {
      if (weights.length !== numberOfVariants) {
        throw new Error('Number of weights must equal number of variants');
      }

      const sum = weights.reduce(
        (partialSum, currentWeight) => partialSum + currentWeight,
        0
      );

      if (sum !== 100) {
        throw new Error('Weights must sum to 100');
      } else {
        // Uneven distribution
        let threshold = 0;
        let pickedIndex = 0;
        for (let i = 0; i < weights.length; i += 1) {
          threshold += weights[i];
          if (cohortId * 100 < threshold) {
            pickedIndex = i;
            break;
          }
        }

        return pickedIndex;
      }
    } else {
      // Even distribution
      return Math.floor(cohortId * numberOfVariants);
    }
  }, [numberOfVariants, cohortId, weights]);

  const getCookieValue = useCallback(() => cookie.get(COOKIE_NAME), []);
  const setCookieValue = useCallback(
    (value: string) => cookie.set(COOKIE_NAME, value, 24),
    []
  );

  const parseExperimentCookie = useCallback((cookie?: string | null) => {
    if (!cookie) return {};

    return cookie.split('!').reduce<ExperimentCookie>((prev, current) => {
      const [experimentId, variantId] = current.split('.');

      return {
        ...prev,
        [experimentId]: variantId
      };
    }, {});
  }, []);

  const updateExperimentCookie = useCallback(() => {
    const experimentCookie = getCookieValue();
    const parsedExperiment = parseExperimentCookie(experimentCookie);

    if (parsedExperiment[experimentId]) return;

    if (experimentCookie) {
      let experiments = experimentCookie.split('!');

      if (!experimentCookie.includes(experimentId)) {
        experiments.push(`${experimentId}.${variantId}`);
      } else {
        experiments = experiments.map((experiment) => {
          if (experiment.includes(experimentId)) {
            return `${experimentId}.${variantId}`;
          }
          return experiment;
        });
      }
      setCookieValue(
        experiments
          .filter((experimentId) =>
            isActiveExperiment(experimentId.slice(0, -2))
          )
          .join('!')
      );
    } else if (isActiveExperiment(experimentId)) {
      setCookieValue(`${experimentId}.${variantId}`);
    }
  }, [
    experimentId,
    getCookieValue,
    parseExperimentCookie,
    setCookieValue,
    variantId
  ]);

  useEffect(updateExperimentCookie, [updateExperimentCookie]);

  return variantId;
}
