import styled, { css } from 'styled-components';

import mediaHelper from '~/App/helpers/media-helper';

import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { mq } from '~/lib/mq';

type BreakpointConfig = {
  url: string;
  size: string;
  style: string;
};

type Props = {
  small: BreakpointConfig;
  large: BreakpointConfig;
  lightTextColor: boolean;
};

export const Background = styled(BlockBackground)<Props>`
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  color: ${({ lightTextColor, theme }) =>
    lightTextColor ? theme.hero.lightTextColor : theme.hero.darkTextColor};

  ${({ small }) =>
    small.style &&
    small.style === 'fitScreen' &&
    css`
      height: 100vh;
    `};

  ${({ large }) =>
    large.style &&
    large.style === 'fitScreen' &&
    css`
      ${mq('≥small')`
        height: 100vh;
      `};
    `};

  ${({ small }) =>
    small.style &&
    small.style === 'fixed' &&
    css<Props>`
      height: ${({ small }) => (small.size === 'small' ? '200px' : '360px')};

      ${mq<Props>('≥small')`
        height: ${({ small }) => (small.size === 'small' ? '200px' : '360px')};
      `};

      ${mq<Props>('≥large')`
        height: ${({ small }) => (small.size === 'small' ? '360px' : '640px')};
      `};
    `};

  ${({ large }) =>
    large.style &&
    large.style === 'fixed' &&
    css<Props>`
      ${mq<Props>('≥large')`
        height: ${({ large }) => (large.size === 'small' ? '360px' : '640px')};
      `};
    `};

  ${({ small, large }) =>
    small.url &&
    large.url &&
    css<Props>`
      background-image: url(${({ small }) =>
        mediaHelper.getUrl(small.url, { width: 750 })});

      @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        background-image: url(${({ small }) =>
          mediaHelper.getUrl(small.url, { width: 1500 })});
      }

      @media only screen and (min-width: 768px) {
        background-image: url(${({ large }) =>
          mediaHelper.getUrl(large.url, { width: 1440 })});
      }

      @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px),
        only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 768px),
        only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 768px),
        only screen and (min-device-pixel-ratio: 2) and (min-width: 768px),
        only screen and (min-resolution: 192dpi) and (min-width: 768px),
        only screen and (min-resolution: 2dppx) and (min-width: 768px) {
        background-image: url(${({ large }) =>
          mediaHelper.getUrl(large.url, { width: 2880 })});
      }
    `};
`;
