import _ from 'lodash';

export function isCloudinaryUrl(url) {
  if (!url) return false;
  if (_.isUndefined(url)) return false;
  if (url.indexOf('//res.cloudinary.com/cancerfonden/') === -1) return false;

  return true;
}

export function getUrl(url, options, format = 'auto') {
  if (!isCloudinaryUrl(url)) {
    return url;
  }

  const regexImage = /cloudinary.com\/cancerfonden\/image\/upload/g;
  const regexVideo = /cloudinary.com\/cancerfonden\/video\/upload/g;
  const mediaType = url.match(regexImage)
    ? 'image'
    : url.match(regexVideo)
    ? 'video'
    : 'unknown';

  if (mediaType === 'unkown') {
    return url;
  }

  const defaultOptions =
    mediaType === 'image'
      ? {
          width: 'auto',
          height: 'auto',
          flags: 'progressive',
          quality: 'auto',
          fetch_format: 'auto'
        }
      : {
          width: 'auto',
          height: 'auto'
        };

  if (
    options &&
    (!_.isUndefined(options.width) || !_.isUndefined(options.height))
  ) {
    defaultOptions.crop = 'fill';
  }

  const mergedOptions = Object.assign({}, defaultOptions, options);

  const replacedUrl = url.replace('http://', '//');

  const tmp = replacedUrl.split(
    `cloudinary.com/cancerfonden/${mediaType}/upload`
  );
  let transformations = generateTransformationString(mergedOptions);

  // Prepend slash if transformations exists, this removes problem
  // with double slashes if transformations is empty
  if (transformations !== '') {
    transformations = '/' + transformations;
  }

  let newFileName = tmp[1];
  if (format !== 'auto') {
    const splitFileName = tmp[1].split('.');
    const fileNameWithoutExtension = splitFileName
      .slice(0, splitFileName.length - 1)
      .join('.');
    newFileName = `${fileNameWithoutExtension}.${format}`;
  }

  const src =
    tmp[0] +
    `cloudinary.com/cancerfonden/${mediaType}/upload` +
    transformations +
    newFileName;

  return src;
}

// List of the supported transformations
const TRANSFORMATION_PARAM_NAME_MAPPING = {
  angle: 'a',
  aspect_ratio: 'ar',
  audio_codec: 'ac',
  audio_frequency: 'af',
  background: 'b',
  bit_rate: 'br',
  border: 'bo',
  color: 'co',
  color_space: 'cs',
  crop: 'c',
  default_image: 'd',
  delay: 'dl',
  density: 'dn',
  dpr: 'dpr',
  duration: 'du',
  effect: 'e',
  end_offset: 'eo',
  fetch_format: 'f',
  flags: 'fl',
  fps: 'fps',
  gravity: 'g',
  height: 'h',
  keyframe_interval: 'ki',
  layer: 'l',
  offset: 'so',
  opacity: 'o',
  overlay: 'l',
  page: 'pg',
  prefix: 'p',
  quality: 'q',
  radius: 'r',
  start_offset: 'so',
  streaming_profile: 'sp',
  transformation: 't',
  underlay: 'u',
  video_codec: 'vc',
  video_sampling: 'vs',
  width: 'w',
  x: 'x',
  y: 'y'
};

// Transforming values to the correct structure for cloudinary api
const TRANSFORMATION_PARAM_VALUE_MAPPING = {
  layer: function (layer) {
    return layer;
  },
  angle: function (angle) {
    return buildArray(angle).join('.');
  },
  background: function (background) {
    return background.replace(/^#/, 'rgb:');
  },
  border: function (border) {
    if (_.isPlainObject(border)) {
      const borderWidth = '' + (border.width || 2);
      const borderColor = (border.color || 'black').replace(/^#/, 'rgb:');
      const resultBorder = `${borderWidth}px_solid_${borderColor}`;

      return resultBorder;
    }

    return border;
  },
  color: function (color) {
    return color.replace(/^#/, 'rgb:');
  },
  dpr: function (dpr) {
    const dprStringified = dpr.toString();
    if (dprStringified === 'auto') {
      return '1.0';
    } else if (dprStringified.match(/^\d+$/)) {
      return `${dprStringified}.0`;
    } else {
      return dprStringified;
    }
  },
  effect: function (effect) {
    return buildArray(effect).join(':');
  },
  end_offset: function (end_offset) {
    return end_offset.replace('%', 'p');
  },
  flags: function (flags) {
    return buildArray(flags).join('.');
  },
  offset: function (offset) {
    if (_.isArray(offset)) {
      return `${offset[0].replace('%', 'p')},eo_${offset[1].replace('%', 'p')}`;
    } else {
      return offset;
    }
  },
  start_offset: function (start_offset) {
    return start_offset.replace('%', 'p');
  },
  transformation: function (transformation) {
    return buildArray(transformation).join('.');
  },
  duration: function (duration) {
    return duration.replace('%', 'p');
  }
};

// Cloudinary's jQuery transformation mapper converted to non-jQuery
function generateTransformationString(options) {
  let baseTransformations = processBaseTransformations(options);
  const processedOptions = processHtmlDimensions(options);

  const transformationOptionProcessed = baseTransformations.length !== 0;

  let params = [];
  Object.keys(TRANSFORMATION_PARAM_NAME_MAPPING).forEach((param) => {
    if (!(param === 'transformation' && transformationOptionProcessed)) {
      let value = processedOptions[param];

      if (value) {
        if (TRANSFORMATION_PARAM_VALUE_MAPPING[param]) {
          value = TRANSFORMATION_PARAM_VALUE_MAPPING[param](value);
        }

        params.push(TRANSFORMATION_PARAM_NAME_MAPPING[param] + '_' + value);
      }
    }
  });
  params.sort();

  if (processedOptions.raw_transformation) {
    params.push(processedOptions.raw_transformation);
  }

  const transformation = params.join(',');

  if (transformation) {
    baseTransformations.push(transformation);
  }

  return baseTransformations.join('/');
}

function processBaseTransformations(options) {
  const transformations = buildArray(options.transformation);

  let allNamed = true;
  transformations.forEach((transformation) => {
    allNamed = allNamed && typeof transformation === 'string';
  });

  if (allNamed) {
    return [];
  }

  const baseTransformations = transformations.map((transformation) => {
    if (typeof transformation === 'string') {
      return `t_${transformation}`;
    } else {
      return generateTransformationString(transformation);
    }
  });

  return baseTransformations;
}

function processHtmlDimensions(options) {
  let processedOptions = { ...options };
  //remove default value for options
  if (options.width === 'auto') {
    delete processedOptions.width;
  }

  //remove default value for options
  if (options.height === 'auto') {
    delete processedOptions.height;
  }

  const width = processedOptions.width;
  const height = processedOptions.height;

  const hasLayer = options.overlay || options.underlay;
  const crop = options.crop;
  const useAsHtmlDimensions =
    !hasLayer &&
    !options.angle &&
    crop !== 'fit' &&
    crop !== 'limit' &&
    crop !== 'lfill';

  if (useAsHtmlDimensions) {
    if (
      width &&
      width !== 'auto' &&
      !options.html_width &&
      parseFloat(width) >= 1
    ) {
      processedOptions.html_width = width;
    }

    if (height && !options.html_height && parseFloat(height) >= 1) {
      processedOptions.html_height = height;
    }
  }

  if (!crop && !hasLayer) {
    delete processedOptions.width;
    delete processedOptions.height;
  }

  return processedOptions;
}

function buildArray(arg) {
  if (arg === null || typeof arg === 'undefined') {
    return [];
  } else if (_.isArray(arg)) {
    return arg;
  } else {
    return [arg];
  }
}

export default {
  getUrl,
  isCloudinaryUrl
};
