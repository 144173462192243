import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';
import {
  clearfix,
  heading1,
  heading2,
  heading3,
  sectionHeader
} from '~/App/helpers/mixins';

// Shared components
import Link from '~/App/shared/components/Link';
import HtmlContent from '~/App/shared/components/HtmlContent';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import TwoUpWrapper from './components/TwoUpWrapper';

const Heading1 = styled.h1`
  ${heading1};
  text-align: ${(props) => props.textAlign};
`;

const Heading2 = styled.h2`
  ${heading2};
  text-align: ${(props) => props.textAlign};
  overflow-wrap: break-word;
`;

const Heading3 = styled.h2`
  ${heading3};
  text-align: ${(props) => props.textAlign};
`;

const SectionHeading = styled.h2`
  ${sectionHeader};
  color: ${({ theme }) => theme.related.headingColor};
`;

const Block = styled.div`
  ${clearfix};
  display: flex;
  flex-flow: column;

  ${mq('≥small')`
    flex-flow: row;
    justify-content: ${(props) =>
      props.showAsTimeline ? 'space-between' : 'flex-start'};
    margin: 0 auto;
  `};
`;

const Figure = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  order: 1;

  ${mq('≥small')`
    margin-bottom: 0;
    float: ${(props) => (props.position === 'left' ? 'left' : 'right')};
    width: 50%;
    order: ${(props) => (props.position === 'left' ? 1 : 2)};
    padding-right: ${(props) =>
      props.showAsTimeline ? (props.position === 'left' ? '3rem' : 0) : 0};
    padding-left: ${(props) =>
      props.showAsTimeline ? (props.position === 'right' ? '3rem' : 0) : 0};
  `};
`;

const PictureElement = styled.picture`
  display: block;
  width: 100%;

  ${mq('≥small')`
    @supports (object-fit: cover) {
      height: ${(props) => (props.showAsTimeline ? '100%' : 'auto')};
    }
    max-width: ${(props) => (props.showAsTimeline ? '310px' : '100%')};
    margin-left: ${(props) =>
      props.showAsTimeline ? (props.position === 'right' ? 'auto' : 0) : 0};
  `};
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  border-radius: 2px;

  ${mq('≥small')`
    width: ${(props) => (props.showAsTimeline ? '100%' : 'auto')};
    height: ${(props) => (props.showAsTimeline ? '100%' : 'auto')};
    @supports (object-fit: cover) {
      object-fit: ${(props) => (props.showAsTimeline ? 'cover' : 'initial')};
    }
  `};
`;

const Caption = styled.div`
  margin: 0;
  padding: 0.5rem 0 0;
  font-size: 0.75rem;
  line-height: 1.583333333;
  font-style: italic;
  color: ${(props) => props.theme.colors.charcoal};

  ${mq('≥small')`
    max-width: ${(props) => (props.showAsTimeline ? '310px' : '100%')};
    margin-left: ${(props) =>
      props.showAsTimeline ? (props.position === 'right' ? 'auto' : 0) : 0};
  `};
`;

const HeadingLink = styled(Link)`
  color: inherit;
`;

const Content = styled.div`
  order: 2;

  ${mq('≥small')`
    display: flex;
    flex-flow: column;
    justify-content: center;

    float: ${(props) => (props.position === 'left' ? 'left' : 'right')};
    width: 50%;
    order: ${(props) => (props.position === 'left' ? 1 : 2)};
    padding-left: ${(props) =>
      props.showAsTimeline ? 0 : props.position === 'right' ? '2rem' : 0};
    padding-right: ${(props) =>
      props.showAsTimeline ? 0 : props.position === 'left' ? '2rem' : 0};
  `};

  ${mq('≥medium')`
    padding-right: ${(props) =>
      props.showAsTimeline ? 0 : props.position === 'left' ? '4rem' : '2rem'};
  `};

  ${Heading1}, ${Heading2}, ${Heading3} {
    color: ${(props) =>
      props.showAsTimeline ? 'inherit' : props.theme.themeColors.primary};
  }

  ${HeadingLink} {
    &:hover {
      color: ${(props) =>
        props.showAsTimeline
          ? 'inherit'
          : props.theme.themeColors.primaryHover};
    }
  }
`;

const ContentBlock = styled(HtmlContent)`
  margin-top: 0.5rem;
`;

const VideoPlayer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: 2px;
`;

const VideoSource = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const getHeadingElement = (size) => {
  if (size === 'heading1') {
    return Heading1;
  }

  if (size === 'heading2') {
    return Heading2;
  }

  if (size === 'sectionHeading') {
    return SectionHeading;
  }

  return Heading3;
};

export default React.memo(function TwoUpMedia(props) {
  const {
    body = '',
    image = {},
    imageCaption = '',
    imageFromUrl = '',
    heading = '',
    mediaType = '',
    blockLink = '',
    imageSmall = {},
    addBlockLink = false,
    showAsTimeline = false,
    mediaPosition = '',
    videoEmbedUrl = '',
    imageDescription = '',
    headingSettings: {
      fontSize: headingFontSize = '',
      textAlign: headingTextAlign = '',
      fontWeight: headingFontWeight = ''
    } = {},
    backgroundColor = 'none'
  } = props;

  const imageUrl = imageFromUrl || image.secure_url || '';
  const imageSmallUrl = imageSmall.secure_url
    ? imageSmall.secure_url
    : imageUrl;

  let videoUrl = videoEmbedUrl;

  if (!_.isEmpty(videoUrl)) {
    if (_.includes(videoUrl, '?')) {
      videoUrl = videoUrl + '&rel=0';
    } else {
      videoUrl = videoUrl + '?rel=0';
    }
  }

  const mediaElement = (
    <PictureElement position={mediaPosition} showAsTimeline={showAsTimeline}>
      <source
        media="(min-width: 48em)"
        srcSet={`
            ${mediaHelper.getUrl(imageUrl, { width: 1168 })} 2x,
            ${mediaHelper.getUrl(imageUrl, { width: 584 })} 1x
          `}
      />
      <source
        srcSet={`
            ${mediaHelper.getUrl(imageSmallUrl, { width: 1536 })} 2x,
            ${mediaHelper.getUrl(imageSmallUrl, { width: 768 })} 1x
          `}
      />
      <Image
        src={mediaHelper.getUrl(imageUrl, { width: 1536 })}
        alt={imageDescription}
        showAsTimeline={showAsTimeline}
      />
    </PictureElement>
  );

  return (
    <BlockBackground backgroundColor={backgroundColor}>
      <TwoUpWrapper {...props}>
        <Block showAsTimeline={showAsTimeline}>
          <Figure position={mediaPosition} showAsTimeline={showAsTimeline}>
            {mediaType === 'image' && imageUrl ? (
              addBlockLink && blockLink ? (
                <Link to={blockLink}>{mediaElement}</Link>
              ) : (
                mediaElement
              )
            ) : null}
            {mediaType === 'video' && (
              <VideoPlayer>
                <VideoSource src={videoUrl} frameBorder="0" allowFullScreen />
              </VideoPlayer>
            )}
            {imageCaption && (
              <Caption position={mediaPosition} showAsTimeline={showAsTimeline}>
                {imageCaption}
              </Caption>
            )}
          </Figure>
          <Content
            position={mediaPosition === 'left' ? 'right' : 'left'}
            showAsTimeline={showAsTimeline}
          >
            {heading
              ? addBlockLink && blockLink
                ? React.createElement(
                    getHeadingElement(headingFontSize),
                    {
                      textAlign: headingTextAlign,
                      fontWeight:
                        headingFontWeight === 'bold'
                          ? 'bold'
                          : headingFontWeight
                    },
                    React.createElement(HeadingLink, { to: blockLink }, heading)
                  )
                : React.createElement(
                    getHeadingElement(headingFontSize),
                    {
                      textAlign: headingTextAlign,
                      fontWeight:
                        headingFontWeight === 'bold'
                          ? 'bold'
                          : headingFontWeight
                    },
                    heading
                  )
              : null}
            {body && (
              <ContentBlock
                spacingTop={!!heading}
                dangerouslySetInnerHTML={{
                  __html: body
                }}
              />
            )}
          </Content>
        </Block>
      </TwoUpWrapper>
    </BlockBackground>
  );
});
