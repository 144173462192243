import { useWindowSize } from '~/App/shared/hooks/use-window-size';
import { useRef, useEffect } from 'react';
import { useAppContext } from '~/App/contexts/App';

import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import { useBreakpoint } from './use-breakpoint';

type Props = {
  onDismiss: () => void;
  isActive?: boolean;
  top?: number;
};

export function useDragToDismiss({ onDismiss, isActive = true, top }: Props) {
  const element = useRef<HTMLDivElement>(null);
  const ignore = useRef<HTMLDivElement>(null);
  const size = useWindowSize();
  const userAgent = useAppContext((x) => x.userAgent);
  const isDesktop = useBreakpoint('≥small', !isMobileBrowser(userAgent));

  const topPosition = useRef<number | undefined>(top);

  useEffect(() => {
    const el = element.current;

    if (!el) {
      return;
    }

    if (!isActive) {
      return;
    }

    const boundingClientRect = el.getBoundingClientRect();
    const styles = getComputedStyle(el);
    const marginTop = parseInt(styles.marginTop || '0px', 10);
    const topValue = window.innerHeight - boundingClientRect.height - marginTop;
    const threshold = boundingClientRect.height * 0.3;
    const isMobile = !isDesktop;

    if (!topPosition.current) {
      topPosition.current = topValue;
    }

    el.draggable = isMobile;
    el.style.position = isMobile ? 'absolute' : '';
    el.style.transition = isMobile ? 'top 0.3s' : '';
    el.style.top = isMobile ? `${topPosition.current}px` : '';

    if (isDesktop) {
      return;
    }

    let topOffset = 0;
    let initialOffset = 0;
    let topMovement = 0;

    const getScrollParent = (node: HTMLElement | null): HTMLElement | null => {
      if (node === null) {
        return null;
      }

      if (node?.scrollHeight > node?.clientHeight) {
        return node;
      } else {
        return getScrollParent(node.parentNode as HTMLElement);
      }
    };

    const shouldIgnoreTouch = ({ target }: TouchEvent) => {
      const div = target as HTMLElement;
      const scrollableNode = getScrollParent(div);
      const contentHasScroll = el.contains(scrollableNode);
      const insideIgnoreArea = ignore.current
        ? ignore.current.contains(div)
        : true;

      if (!contentHasScroll) {
        return false; // not inside modal
      }

      if (!insideIgnoreArea) {
        return false; // dragging from ignore area
      }

      return true;
    };

    const handleTouchStart = (event: TouchEvent) => {
      if (shouldIgnoreTouch(event)) return;

      if (!initialOffset) initialOffset = el.offsetTop;
      topOffset = event.changedTouches[0].clientY - initialOffset;
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (shouldIgnoreTouch(event)) return;

      event.preventDefault();

      topMovement = event.changedTouches[0].clientY - topOffset;

      if (topMovement <= initialOffset) {
        return;
      }

      el.style.position = 'absolute';
      el.style.top = `${topMovement}px`;
      el.style.transition = '';
    };

    const handleTouchEnd = (event: TouchEvent) => {
      if (shouldIgnoreTouch(event)) return;

      const overallMovement = Math.abs(topMovement - initialOffset);

      el.style.transition = 'top 0.3s';

      if (overallMovement < threshold) {
        el.style.top = `${topPosition.current}px`;
        return;
      }

      el.style.top = `${document.body.clientHeight}px`;

      setTimeout(onDismiss, 300);
    };

    el.addEventListener('touchstart', handleTouchStart, false);
    el.addEventListener('touchmove', handleTouchMove, false);
    el.addEventListener('touchend', handleTouchEnd, false);

    return () => {
      el.removeEventListener('touchstart', handleTouchStart);
      el.removeEventListener('touchmove', handleTouchMove);
      el.removeEventListener('touchend', handleTouchEnd);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.current, ignore.current, isActive, size, isDesktop]);

  useEffect(() => {
    if (isDesktop) {
      return;
    }

    if (top) {
      return;
    }

    topPosition.current = undefined;
  }, [size, top, isDesktop]);

  return {
    element,
    ignore
  };
}
