import React from 'react';
import styled from 'styled-components';

import Container from '~/App/shared/components/Container';
import { Form } from '~/App/shared/components/Donation/IncreaseMonthlyDonation/Klarna/Form';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPolar};
`;

type Props = {
  data: {
    defaultPrice?: number;
    infoText?: string;
    prices: number[];
    redirectPath: string;
  };
};
export default function IncreaseMonthlyKlarnaDonationForm({ data }: Props) {
  const { defaultPrice, infoText, prices, redirectPath } = data;

  return (
    <Wrapper>
      <Container
        width="limited"
        paddingLeft="small"
        paddingRight="small"
        paddingTop="small"
        paddingBottom="small"
      >
        <Form
          formContent={{ defaultPrice, infoText, prices }}
          redirectPath={redirectPath}
        />
      </Container>
    </Wrapper>
  );
}
