import React from 'react';
import { Helmet } from 'react-helmet';

// helpers
import { assemble } from '~/lib/slug-helper';
import { siteUrl } from '~/App/helpers/env';

import EditorialItem from '~/App/shared/components/EditorialItem';

export function Vacancy({ data: { editorialItem = {} } = {} }) {
  const { meta = {} } = editorialItem;

  return (
    <div>
      <Helmet>
        <title>{meta.pageTitle}</title>
        <link rel="canonical" href={siteUrl(assemble(editorialItem))} />
        <meta name="description" content={meta.pageDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:image" content={meta.ogImage} />
        <meta property="og:description" content={meta.ogDescription} />
      </Helmet>
      <EditorialItem
        content={editorialItem}
        parentSlug="/sok?ct=vacancy"
        parentName="Jobb"
      />
    </div>
  );
}
