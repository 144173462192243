import React from 'react';
import styled from 'styled-components';

import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues, ProductValues } from '../../States';
import { ContactFields } from '../../Forms/components/ContactFields';
import { mq } from '~/lib/mq';
import { usePreferredCustomerContactType } from '~/App/shared/hooks/use-preferred-customer-contact-type';

const Wrapper = styled.div`
  :not(:first-child) {
    margin-top: 1rem;
  }
`;

const Text = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;

  ${mq('>small')`
    font-size: 1.125rem;
  `};
`;

type Props = {
  values: ContactCustomerValues & ProductValues;
  validation: IValidation;
  email?: boolean;
  ssn?: boolean;
};

export function PaymentSlipFields({
  values,
  validation,
  email = false,
  ssn = false
}: Props) {
  usePreferredCustomerContactType({
    preferred: 'manual',
    values
  });

  return (
    <Wrapper>
      <Text
        children={
          values.product.id === 23 ||
          values.product.id === 25 ||
          values.product.id === 33
            ? 'Ett inbetalningskort skickas hem till dig inom 5 arbetsdagar.'
            : 'Ett inbetalningskort skickas hem till dig inom 30 arbetsdagar.'
        }
      />
      <ContactFields
        values={values}
        validation={validation}
        manual={{
          email: email ? 'Kvitto skickas till din e-post.' : false,
          ssn: ssn
            ? 'Frivilligt att ange, men behövs för eventuell skatteavdrag.'
            : false,
          privateCompany: values.product.id === 1,
          ssnRequired: false
        }}
      />
    </Wrapper>
  );
}
