import React, { ReactNode } from 'react';
import { OnceDonationNudge } from '../components/TaxReductionNudging/OnceDonationNudge';
import { MonthlyDonationNudge } from '../components/TaxReductionNudging/MonthlyDonationNudge';
import { ISubmit } from '../components/Submits';
import { IValidation } from '../../Validation';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';
import { SubmitValues } from '../components/Submits/Submit/types/SubmitValues';

type Props = {
  submit: ISubmit;
  validation: IValidation;
  values: SubmitValues;
  children: (submit: ISubmit) => ReactNode;
};
export function CollectionDonationNudging(props: Props) {
  switch (props.values.productOptions.product.period) {
    case 'Once':
      return (
        <OnceDonationNudge
          {...props}
          name="Insamling Engångsgåva"
          setAmount={props.values.handlers.setProductPrice}
          shouldNudge={featureIsEnabled('NUDGE_COLLECTION_DONATION_ENABLED')}
        />
      );
    case 'Month':
      return (
        <MonthlyDonationNudge
          {...props}
          name="Insamling Månadsgåva"
          shouldNudge={featureIsEnabled('NUDGE_COLLECTION_DONATION_ENABLED')}
        />
      );
    default:
      return <>{props.children(props.submit)} </>;
  }
}
