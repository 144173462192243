import React, { useState } from 'react';

// Shared components
import { useValidation } from '~/App/shared/components/Validation';
import { AccountColumn } from '~/App/views/Account/shared/components/AccountColumn';
import { AccountHeading1 } from '~/App/views/Account/shared/components/AccountHeadings';

// Local components
import { Form } from './components/Form';
import { Admins } from './components/Admins';
import { schema } from './schema';

import { IMeCollection } from '~/types/ICollection';
import { useCollectionEditState } from './hooks/useCollectionEditState';
import { useCollectionEditSubmit } from './hooks/useCollectionEditSubmit';

type Props = {
  data: IMeCollection;
};

export function CollectionEdit({ data }: Props) {
  const [error, setError] = useState<string | null>(null);

  const state = useCollectionEditState({ data, setError });
  const validation = useValidation({
    values: state.values,
    schema
  });

  const submit = useCollectionEditSubmit({
    setError,
    validation,
    state
  });

  return (
    <>
      <Admins values={data} />
      <AccountColumn>
        <AccountHeading1>Redigera insamling</AccountHeading1>
        <Form
          validation={validation}
          submit={submit}
          state={state}
          error={error}
        />
      </AccountColumn>
    </>
  );
}
