import React, { useCallback, useMemo } from 'react';

import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { IValidation } from '~/App/shared/components/Validation';
import { CreateMemorialPageStateValues } from '../hooks/useCreateMemorialPageState';
import styled from 'styled-components';
import {
  ctaButton,
  outlinePrimaryButton,
  primaryButton
} from '~/App/helpers/mixins';
import useLoginModal from '~/App/shared/hooks/use-login-modal';
import useLogin from '~/App/shared/hooks/use-login';

type AuthenticationProps = {
  validation: IValidation;
  values: CreateMemorialPageStateValues;
};

const Button = styled.button`
  ${primaryButton};
  display: block;
  margin: 2rem auto 0;
`;

const CtaButton = styled.button`
  ${ctaButton};
  display: block;
  margin: 1rem auto 0;
`;

const OutlineButton = styled.button`
  ${outlinePrimaryButton};
  display: block;
  margin: 1rem auto 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin: 1rem auto 0;
`;

export function Authentication({ values, validation }: AuthenticationProps) {
  const { user, isAuthenticated } = useAuthenticationContext();
  const login = useLogin();
  const loginModal = useLoginModal();

  const handleClick = useCallback(
    async (sameDevice) => {
      login.handlers.handleLogin({
        qrInit: !sameDevice,
        sameDevice: sameDevice
      });
      loginModal.handlers.toggleQrMode(!sameDevice);
    },
    [login.handlers, loginModal.handlers]
  );

  const mobileLoginButton = useMemo(
    () => (
      <ButtonWrapper>
        <CtaButton onClick={() => handleClick(true)}>
          BankID på denna enhet
        </CtaButton>
        <OutlineButton onClick={() => handleClick(false)}>
          BankID på annan enhet
        </OutlineButton>
      </ButtonWrapper>
    ),
    [handleClick]
  );

  if (isAuthenticated && user) {
    return (
      <p>
        Du är inloggad som{' '}
        <strong>
          {user.firstName} {user.lastName}
        </strong>{' '}
        och insamlingens administratör.
      </p>
    );
  }
  if (!isAuthenticated) {
    return (
      <>
        <p>
          För att skapa din insamling ber vi dig att bekräfta din identitet med
          BankID.
        </p>
        {values.sameDevice ? (
          mobileLoginButton
        ) : (
          <Button
            children="Bekräfta med BankID"
            type="button"
            onClick={() => handleClick(false)}
          />
        )}
      </>
    );
  }
  return null;
}
