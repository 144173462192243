import { useMemo } from 'react';
import { useContactInformationValues } from '../../components/States/useContactInformationValues';
import { useProductValues } from '../../components/States/useProductValues';
type Props = {
  formContent: {
    defaultPrice?: number;
  };
};
export function State({ formContent }: Props) {
  const defaultPrice = formContent.defaultPrice;
  const adjustedPrice =
    parseInt(`${defaultPrice}`, 10) >= 1 ? defaultPrice : 50;
  const contactInformation = useContactInformationValues({});
  const productValues = useProductValues({
    productOptions: {
      product: {
        price: adjustedPrice
      }
    }
  });

  return useMemo(
    () => ({
      values: {
        productOptions: {
          product: {
            price: productValues.productOptions.product.price,
            customPrice: productValues.productOptions.product.customPrice
          },
          customerContact: {
            email: contactInformation.productOptions.customerContact.email,
            ssn: contactInformation.productOptions.customerContact.ssn
          }
        }
      },
      handlers: {
        ...productValues.handlers,
        ...contactInformation.handlers
      }
    }),
    [productValues, contactInformation]
  );
}
