import styled from 'styled-components';

export const ItemLink = styled.a`
  display: block;
  color: ${(props) => props.theme.themeColors.primary};
  transition: color 0.3s ease;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.themeColors.primaryHover};
    text-decoration: underline;
  }
`;
