import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { NewCollectionDonation } from './NewCollectionDonation';
import { loadData } from './NewCollectionDonation.LoadData';

const View = (props) => {
  const pageContent = usePageContent();
  const children = useMemo(
    () =>
      pageContent ? (
        <NewCollectionDonation {...pageContent} {...props} />
      ) : null,
    [pageContent, props]
  );

  return children;
};

View.loadData = loadData;

export default View;
