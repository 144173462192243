import styled from 'styled-components';

// Shared components
import { SpecialBlockBackground } from '~/App/shared/components/BlockBackground';

export const Background = styled(SpecialBlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

export const Wrapper = styled.div`
  position: relative;
  max-width: 60.5rem;
  margin: 5rem auto 0 auto;
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
`;

export type ImageWrapperProps = {
  padding?: boolean;
};

export const ImageWrapper = styled.div<ImageWrapperProps>`
  display: block;
  width: 11.25rem;
  height: 11.25rem;
  position: absolute;
  top: -5.625rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.seashell};
  border: 1.25rem solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  padding: ${({ padding = true }) => (padding ? '1.5rem' : '0rem')};
`;

type ProductImageProps = {
  src: string;
};

export const ProductImage = styled.div<ProductImageProps>`
  display: block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${({ src }) => `url('${src}')`};
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  position: relative;
  max-width: 35.5rem;
  margin: 0 auto;
  padding: 7rem 0 4rem 0;
  text-align: center;
`;
