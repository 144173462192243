import React, { useMemo } from 'react';
import styled from 'styled-components';

import { RadioButtons } from './components/RadioButtons';
import { Address } from './fields';
import { ContactCustomerValues } from '../../../States';
import { TaxReductionInfo } from './components/TaxReductionInfo';
import { IValidation } from '~/App/shared/components/Validation';
import { Automatic, AutomaticProps } from './components/Automatic';
import { Manual, ManualProps } from './components/Manual';
import { Company, CompanyProps } from './components/Company';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ContactInputGroup = styled.div`
  margin-top: -0.5rem;
`;

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
  disabledBillingAddress?: boolean;
  taxReductionInfo?: boolean | string;

  automatic?: boolean | AutomaticProps;
  manual?: boolean | ManualProps;
  company?: boolean | CompanyProps;
};

export function ContactFields({
  values,
  validation,
  automatic,
  manual,
  company,
  disabledBillingAddress = false,
  taxReductionInfo = false
}: Props) {
  const props = useMemo(
    () => ({
      values,
      validation
    }),
    [validation, values]
  );

  const numberOfContactTypes = useMemo(
    () => [!!automatic, !!company, !!manual].filter((x) => x).length,
    [automatic, company, manual]
  );

  const layoutWidth = useMemo<'50' | '100' | 'auto'>(() => {
    switch (numberOfContactTypes) {
      case 2:
        return '50';
      case 3:
        return 'auto';
      default:
        break;
    }

    return '100';
  }, [numberOfContactTypes]);

  const renderAutomatic = useMemo(
    () =>
      (values.customerContactType === 'automatic' &&
        numberOfContactTypes > 1) ||
      (!manual && automatic && !company),
    [
      numberOfContactTypes,
      automatic,
      company,
      manual,
      values.customerContactType
    ]
  );

  const renderManual = useMemo(
    () =>
      (values.customerContactType === 'manual' && numberOfContactTypes > 1) ||
      (manual && !automatic && !company),
    [
      numberOfContactTypes,
      automatic,
      company,
      manual,
      values.customerContactType
    ]
  );

  const renderCompany = useMemo(
    () =>
      (values.customerContactType === 'company' && numberOfContactTypes > 1) ||
      (!manual && !automatic && company),
    [
      numberOfContactTypes,
      automatic,
      company,
      manual,
      values.customerContactType
    ]
  );

  const automaticProps = useMemo<AutomaticProps>(
    () => (typeof automatic === 'object' ? automatic : {}),
    [automatic]
  );

  const manualProps = useMemo<ManualProps>(
    () => (typeof manual === 'object' ? manual : {}),
    [manual]
  );

  const companyProps = useMemo<CompanyProps>(
    () => (typeof company === 'object' ? company : {}),
    [company]
  );

  if (disabledBillingAddress) {
    return (
      <div>
        <ContactInputGroup>
          <FlexWrapper>
            <Address {...props} disabled={true} />
          </FlexWrapper>
        </ContactInputGroup>
      </div>
    );
  }

  return (
    <div>
      <>
        {taxReductionInfo && (
          <TaxReductionInfo
            link={
              typeof taxReductionInfo === 'string'
                ? taxReductionInfo
                : undefined
            }
          />
        )}

        {numberOfContactTypes > 1 && (
          <RadioButtons
            {...props}
            showAutomatic={!!automatic}
            showCompany={!!company}
            showManual={!!manual}
            layoutWidth={layoutWidth}
          />
        )}

        {renderAutomatic && <Automatic props={props} {...automaticProps} />}
        {renderManual && <Manual props={props} {...manualProps} />}
        {renderCompany && <Company props={props} {...companyProps} />}
      </>
    </div>
  );
}
