import React, { useMemo } from 'react';
import styled from 'styled-components';
import { buttonStyles } from '~/App/config/buttonStyles';

// Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';

const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: 95px;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const EditButton = (props) => {
  const { part, values, validation, text = 'Ändra', stepName } = props;
  const isValid = useMemo(
    () =>
      validation.areValid(
        values.handlers.getValidationKeys(values.partOptions.currentPart)
      ),
    [validation, values.handlers, values.partOptions.currentPart]
  );

  if (values.partOptions.partStarted[part]) {
    return (
      <StyledPrimaryButton
        buttonType="button"
        buttonStyle={
          !isValid && part > values.partOptions.currentPart
            ? buttonStyles.disabled
            : buttonStyles.outlinePrimary
        }
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          values.handlers.handleGoToPart(part, stepName, text);
        }}
      >
        {text}
      </StyledPrimaryButton>
    );
  } else {
    return null;
  }
};
