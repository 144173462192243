import {
  useRef,
  useState,
  useEffect,
  FormEvent,
  useMemo,
  useCallback
} from 'react';

// Helpers
import { poll, mwAxios } from '~/App/helpers/http';
import { IValidation } from '../../../../../Validation';

export type PaymentMethod = 'klarna' | 'autogiro';
type Props = {
  values: {
    productOptions: {
      product: {
        price: number | null;
        customPrice: boolean;
      };
      customerContact: {
        email?: string;
        ssn?: string;
      };
    };
  };
  paymentMethod: PaymentMethod;
  validation: IValidation;
};

type Status =
  | 'idle'
  | 'pending'
  | 'no_monthly_donation'
  | 'invalid_payment_method'
  | 'sign_pending'
  | 'sign_failed'
  | 'signed'
  | 'completed'
  | 'failed';

type State = {
  isSending: boolean;
  isPolling: boolean;
  isCompleted: boolean;
  isWaitingForBankId: boolean;
  errors: boolean;
  publicToken?: string;
  pollingStatus: Status;
  qrCode: string;
  autostartToken: string;
};

type IncreaseDonationsResponse = {
  publicToken: string;
};

type IncreaseDonationsPollingResponse = {
  increaseDonation: {
    publicToken: string;
    status: Status;
    qrCode: string;
    autostartToken: string;
  };
};

export type ISubmitIncrease = State & {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
};

export function useSubmitIncrease({
  values,
  paymentMethod,
  validation
}: Props) {
  const source = useRef(poll.CancelToken.source());

  const [state, setState] = useState<State>({
    isSending: false,
    isPolling: false,
    isCompleted: false,
    isWaitingForBankId: false,
    errors: false,
    publicToken: '',
    pollingStatus: 'idle',
    qrCode: '',
    autostartToken: ''
  });

  useEffect(() => () => source?.current.cancel(), []);

  const handleSubmitError = useCallback((errorResponse: any) => {
    const { data: { increaseDonation = {} } = {} } = errorResponse;

    setState((state) => ({
      ...state,
      isPolling: false,
      isCompleted: false,
      isWaitingForBankId: false,
      publicToken: increaseDonation.publicToken,
      errors: !['no_monthly_donation', 'invalid_payment_method'].includes(
        increaseDonation.status
      ),
      pollingStatus: increaseDonation.status
    }));

    return errorResponse;
  }, []);

  const handleSubmitSuccess = useCallback((successResponse: any) => {
    const { data: { increaseDonation = {} } = {} } = successResponse;

    setState((state) => ({
      ...state,
      isPolling: false,
      isCompleted: true,
      isWaitingForBankId: false,
      publicToken: increaseDonation.publicToken,
      pollingStatus: increaseDonation.status
    }));

    return successResponse;
  }, []);

  const startPolling = useCallback(
    async (publicToken: string) => {
      setState((state) => ({
        ...state,
        isSending: false,
        isPolling: true,
        publicToken: publicToken
      }));

      const notPendingResponse = await poll.start({
        fn: () =>
          mwAxios.get<IncreaseDonationsPollingResponse>(
            `api/v2/increase_donations/${publicToken}`,
            {
              timeout: 15000,
              cancelToken: source.current?.token,
              responseType: 'text'
            }
          ),
        done: ({ data }) => data.increaseDonation.status !== 'pending',
        onEach: ({ data }) =>
          setState((state) => ({
            ...state,
            status: data.increaseDonation.status
          })),
        timeout: 60000,
        interval: 3000
      });

      const invalidStatuses = ['no_monthly_donation', 'invalid_payment_method'];
      const isInvalid = invalidStatuses.includes(
        notPendingResponse.data.increaseDonation.status
      );

      if (isInvalid) {
        throw notPendingResponse;
      }

      setState((state) => ({
        ...state,
        isWaitingForBankId: true,
        pollingStatus: notPendingResponse.data.increaseDonation.status
      }));

      const completedResponse = await poll.start({
        fn: () =>
          mwAxios.get<IncreaseDonationsPollingResponse>(
            `/api/v2/increase_donations/${publicToken}`,
            {
              timeout: 15000,
              cancelToken: source.current?.token,
              responseType: 'text'
            }
          ),
        done: ({ data }) => data.increaseDonation.status !== 'sign_pending',
        onEach: ({ data }) =>
          setState((state) => ({
            ...state,
            status: data.increaseDonation.status,
            qrCode: data.increaseDonation.qrCode,
            autostartToken: data.increaseDonation.autostartToken
          })),
        timeout: 180000,
        interval: 3000
      });

      const validStatuses = ['signed', 'completed'];
      const isValid = validStatuses.includes(
        completedResponse.data.increaseDonation.status
      );

      if (isValid) {
        return handleSubmitSuccess(completedResponse);
      }

      throw completedResponse;
    },
    [handleSubmitSuccess]
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      setState((state) => ({
        ...state,
        isSending: true
      }));

      try {
        const { data } = await mwAxios.post<IncreaseDonationsResponse>(
          '/api/v2/increase_donations',
          {
            increaseDonation: {
              ssn: values.productOptions.customerContact.ssn,
              additionalAmount: values.productOptions.product.price,
              email: values.productOptions.customerContact.email,
              paymentMethod: paymentMethod
            }
          }
        );

        await startPolling(data.publicToken);
      } catch (error) {
        handleSubmitError(error);
      }
    },
    [
      handleSubmitError,
      startPolling,
      validation,
      values.productOptions.customerContact.email,
      values.productOptions.customerContact.ssn,
      values.productOptions.product.price,
      paymentMethod
    ]
  );

  return useMemo(
    () => ({
      ...state,
      handleSubmit: handleSubmit
    }),
    [handleSubmit, state]
  );
}
