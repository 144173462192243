import React from 'react';

// Helpers
import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';

export default class ImageUploadButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      status: 'idle',
      error: ''
    };
  }

  handleChange(event) {
    this.setState({ status: 'uploading', error: '' });
    const sizeLimit = this.props.sizeLimit | 20000000;

    const file = event.target.files[0];
    if (!file) {
      return null;
    }
    if (file.size > sizeLimit) {
      this.setState({
        status: 'failed',
        error:
          'Bilden du försöker ladda upp är för stor. Max filstorlek är 20MB.'
      });
      return null;
    } else {
      uploadImage(file, { folder: this.props.folder })
        .then(({ data }) => {
          if (typeof this.props.onUploadCompleted === 'function') {
            this.props.onUploadCompleted(data);
          }
          this.setState({ status: 'completed' });
        })
        .catch(() => {
          this.setState({
            status: 'failed',
            error: 'Något gick fel vid uppladdningen, var vänlig försök igen.'
          });
        });
    }
  }

  render() {
    return this.props.children({
      ...this.state,
      handlers: { handleChange: this.handleChange }
    });
  }
}
