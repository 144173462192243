import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import mediaHelper from '~/App/helpers/media-helper';

import Intro from '~/App/shared/components/Intro';
import EmbedVideo from '~/App/shared/components/EmbedVideo';

import FeaturedBrickLinks from './components/FeaturedBrickLinks';

export default class FeaturedBrick extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      image,
      embed,
      hasMedia,
      maxWidth,
      textAlign,
      textWidth,
      imageSize,
      imageWidth,
      linkLayout,
      imagePosition
    } = this.props;

    imageWidth = imageWidth.replace('%', '') * 1;

    if (!maxWidth || maxWidth === '') {
      maxWidth = 'none';
    }

    if (_.get(image, 'url', '')) {
      image = mediaHelper.getUrl(image.url, imageSize);
    }

    if (imagePosition !== 'top' && imageWidth < 100) {
      textWidth = 100 - imageWidth;
    }

    textWidth += '%';
    imageWidth += '%';

    let wrapperClasses = classNames('featuredBrick', {
      'featuredBrick--flex': imagePosition !== 'top'
    });

    let figureClasses = classNames('featuredBrick__figure', {
      [`featuredBrick__figure--${imagePosition}`]: !_.isEmpty(imagePosition)
    });

    let textClasses = classNames('featuredBrick__text', {
      'featuredBrick__text--left': imagePosition === 'right',
      'featuredBrick__text--right': imagePosition === 'left'
    });

    return (
      <div
        className={wrapperClasses}
        style={{ maxWidth: maxWidth, textAlign: textAlign }}
      >
        {hasMedia ? (
          <div className={figureClasses} style={{ width: imageWidth }}>
            {embed ? (
              <EmbedVideo embed={embed} />
            ) : (
              <img className="featuredBrick__image" src={image} />
            )}
          </div>
        ) : null}
        <div className={textClasses} style={{ width: textWidth }}>
          <div className="featuredBrick__info">
            <Intro
              heading={_.get(this.props, 'heading', undefined)}
              preamble={_.get(this.props, 'preamble', undefined)}
              maxWidth={_.get(this.props, 'maxWidth', undefined)}
              textAlign={_.get(this.props, 'textAlign', undefined)}
              description={_.get(this.props, 'description', undefined)}
              headingFontSize={_.get(this.props, 'headingFontSize', undefined)}
              headingFontWeight={_.get(
                this.props,
                'headingFontWeight',
                undefined
              )}
              preambleFontWeight={_.get(
                this.props,
                'data.preambleFontWeight',
                undefined
              )}
            />
          </div>
          <FeaturedBrickLinks {...this.props} linkLayout={linkLayout} />
        </div>
      </div>
    );
  }
}

FeaturedBrick.displayName = 'FeaturedBrick';

FeaturedBrick.defaultProps = {
  hasMedia: true,
  maxWidth: false,
  textAlign: 'left',
  textWidth: 100,
  imageSize: { width: 600, height: 360 },
  imageWidth: '50%',
  imagePosition: 'right',
  preambleFontWeight: 'bold'
};
