import { contentApi } from '~/App/helpers/env';
import { mwAxios, fetch } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export async function loadData({ params, oldData }: LoadDataProps<'id'>) {
  if (oldData) {
    return oldData;
  }

  const [configResponse, AnswerResponse] = await Promise.all([
    fetch({ url: contentApi('/v1/global-components?slug=kolla-risken') }),
    mwAxios.get(`/api/v2/question_sessions/${params.id}/answers`)
  ]);

  return {
    answers: AnswerResponse.data,
    config: configResponse.data.config
  };
}
