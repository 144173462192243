import React, { useEffect } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { MultiPartFormValues } from '../../../MultiPartForm';
import { IValidation } from '../../../Validation';
import {
  FormSubHeading,
  NewFormHeading
} from '../../components/Forms/components/FormTypography';
import { StateValues } from '../../components/States';
import { Amount, Period } from '../../components/Steps';
import Text from '~/App/shared/components/Fields/Text';
import { FormContent } from '../CollectionDonation';
import { TaxReductionInfo } from '../../components/Forms/components/TaxReductionInfo';

import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';
import { ICollection } from '~/types/ICollection';

const NewFormSubHeading = styled(FormSubHeading)`
  padding-top: 3rem;
  padding-bottom: 0.875rem;
`;

const StyledTaxReductionInfo = styled(TaxReductionInfo)`
  margin-top: -1.90rem;
`;

const SectionHeading = styled(NewFormHeading)`
  margin-top: 2rem;
  margin-bottom: 0rem;
  font-size: 1.125rem;
`;

type Props = {
  formContent: FormContent;
  values: StateValues & MultiPartFormValues;
  validation: IValidation;
  collection: ICollection;
};
export function Product({
  values,
  formContent,
  validation,
  collection
}: Props) {
  const isRecurring = useMemo(
    () => values.productOptions.product?.period !== 'Once',
    [values.productOptions.product.period]
  );

  const formContentWithAdjustedPrice = useMemo(() => {
    if (isRecurring) {
      return {
        ...formContent,
        prices: ['100', '150', '350'],
        defaultPrice: 150
      };
    }
    return {
      ...formContent,
      prices: ['100', '350', '500'],
      defaultPrice: 350
    };
  }, [formContent, isRecurring]);

  useEffect(() => {
    if(values.productOptions.product.customPrice){
      return
    }
    values.handlers.setProductPrice(formContentWithAdjustedPrice.defaultPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContentWithAdjustedPrice.defaultPrice]);

  return useMemo(
    () => (
      <>
        <SectionHeading children="Hur ofta vill du ge?" />
        <Period values={values} />
        <SectionHeading children="Hur mycket vill du ge?" />
        <Amount
          values={values}
          validation={validation}
          formContent={formContentWithAdjustedPrice}
          collection={collection}
        />
        <NewFormSubHeading children="Vilket namn ska visas med din gåva?" />
        <Text
          name="socialName"
          type="text"
          onChange={values.handlers.handleSocialName}
          placeholder="Ditt/era namn som syns på insamlingen"
          instructions="Lämna tomt om du vill vara anonym."
        />
        <StyledTaxReductionInfo
          amount={getListPrice(values.productOptions)}
          quantity={getQuantity(values.productOptions)}
        />
      </>
    ),
    [collection, formContentWithAdjustedPrice, validation, values]
  );
}
