import React, { useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import omitProps from '~/App/helpers/omit-props';

// Shared components
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const HorizontalRule = styled(omitProps('hr', ['height', 'color']))`
  height: 0;
  margin: 0;
  border: none;
  border-top-width: ${(props) => (props.height ? props.height : '1px')};
  border-top-style: solid;
  border-top-color: ${(props) =>
    props.color
      ? props.theme.divider.alternativeColor
      : props.theme.divider.color};
`;

export default function Divider({
  width = '',
  height = '',
  alternativeColor = false,
  backgroundColor = '',
  paddingTop = '',
  paddingBottom = '',
  paddingLeft = '',
  paddingRight = ''
}) {
  return useMemo(
    () => (
      <BlockBackground backgroundColor={backgroundColor}>
        <Container
          width={width}
          paddingTop={paddingTop ? paddingTop : 'none'}
          paddingBottom={paddingBottom ? paddingBottom : 'none'}
          paddingLeft={paddingLeft ? paddingLeft : 'small'}
          paddingRight={paddingRight ? paddingRight : 'small'}
        >
          <HorizontalRule height={height} color={alternativeColor} />
        </Container>
      </BlockBackground>
    ),
    [
      alternativeColor,
      backgroundColor,
      height,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      width
    ]
  );
}
