import React from 'react';
import styled from 'styled-components';

// Helpers
import { heading2, preamble } from '~/App/helpers/mixins';
import mediaHelper from '~/App/helpers/media-helper';
import { buttonStyles } from '~/App/config/buttonStyles';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

// Shared components
import {
  Wrapper,
  ImageWrapper,
  ProductImage,
  Content
} from '~/App/shared/components/ThanksPage';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 2.5rem;
`;

const MaterialLink = styled(PrimaryButton)`
  margin-bottom: 1rem;
`;

class ChristmasCompanyPackageThanks extends React.Component {
  render() {
    const { data = {}, purchase = {} } = this.props;

    const {
      purchase: { productOptions } = {},
      product: productInfo
    } = purchase;

    const greeting =
      productOptions.customerContact && productOptions.customerContact.company
        ? `${data.heading} ${productOptions.customerContact.company}!`
        : `${data.heading}!`;

    const mediaContentUrl = data.downloadLinks[productInfo.id];

    return (
      <Background backgroundColor="primary">
        <Wrapper>
          <ImageWrapper>
            <ProductImage
              src={mediaHelper.getUrl(data.productImage, {
                width: 92,
                height: 92,
                dpr: '2.0',
                crop: 'pad',
                quality: 100
              })}
            />
          </ImageWrapper>
          <Content>
            <Heading1>{greeting}</Heading1>
            <Preamble>
              {data.description}{' '}
              {(productOptions.customerContact &&
                productOptions.customerContact.email) ||
                'din mail'}
              .
            </Preamble>

            <MaterialLink
              rel="noopener noreferrer"
              target="_blank"
              buttonType="link"
              buttonStyle={buttonStyles.outlinePrimary}
              to={mediaContentUrl}
            >
              Ta mig till materialet
            </MaterialLink>
            <FetchPurchaseDocuments purchase={purchase}>
              {({ receiptStatus, receipt }) => (
                <AsyncReceiptDocumentLink
                  url={receipt}
                  status={receiptStatus}
                  paymentMethodId={purchase?.paymentMethod?.id}
                />
              )}
            </FetchPurchaseDocuments>
          </Content>
        </Wrapper>
      </Background>
    );
  }
}

export default ChristmasCompanyPackageThanks;
