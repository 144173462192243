import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';

// Helpers
import { heading2 } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';
import { angleDown } from '~/App/helpers/icons';
import mediaHelper from '~/App/helpers/media-helper';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31rem;
  margin: 0 auto ${({ marginBottom }) => (marginBottom ? '3rem' : '0.5rem')};
  padding: 0 1.5rem;
`;

const FlagIcon = styled.img`
  margin: 0.5rem 0 1.5rem;
`;

const Heading = styled.h2`
  ${heading2};
  color: ${(props) => props.theme.themeColors.primary};
  margin-bottom: 0.5rem;
`;

const DescriptionWrapper = styled.div`
  max-height: 7.5rem;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.2, 0.5, 0.7, 1.3);

  ${mq('≥small')`
       max-height: 8rem;
  `};
`;

const ShowMoreButton = styled.button`
  color: ${(props) => props.theme.themeColors.primary};
  background: none;
  border: none;
  padding: none;
  margin-top: 1rem;
  cursor: pointer;
  outline: none;

  &::after {
    ${angleDown};
    line-height: 0;
    vertical-align: middle;
    font-weight: 700;
    font-size: 1.25rem;
    display: inline-block;
    margin-left: 0.25rem;
  }
`;

export const PausedCollectionMessage = (props) => {
  const { collection, hasGifts } = props;
  const flagIcon =
    'https://res.cloudinary.com/cancerfonden/image/upload/v1601906799/assets/collection-paused-flag.svg';
  const descriptionRef = useRef();
  const descriptionWrapperRef = useRef();
  const HeadingText = hasGifts
    ? 'Tack för alla gåvor'
    : 'Insamlingen är avslutad';

  const [showReadMoreButton, setShowReadMoreButton] = useState(false);

  useEffect(() => {
    if (descriptionRef.current?.clientHeight > 120) {
      setShowReadMoreButton(true);
    }
  }, []);

  const showMore = useCallback(() => {
    descriptionWrapperRef.current.style.maxHeight =
      descriptionRef.current.clientHeight + 'px';

    setShowReadMoreButton(false);
  }, [setShowReadMoreButton]);

  return (
    <Wrapper marginBottom={hasGifts}>
      <FlagIcon
        src={mediaHelper.getUrl(flagIcon, {
          width: 45,
          height: 45
        })}
      />
      <Heading>{HeadingText}</Heading>
      {collection.pausedMessage && (
        <React.Fragment>
          <DescriptionWrapper ref={descriptionWrapperRef}>
            <p ref={descriptionRef}>{collection.pausedMessage}</p>
          </DescriptionWrapper>
          {showReadMoreButton && (
            <ShowMoreButton onClick={showMore}>Visa mer</ShowMoreButton>
          )}
        </React.Fragment>
      )}
    </Wrapper>
  );
};
