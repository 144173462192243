import { useEffect, useState } from 'react';

import { useMatchMedia } from '~/App/shared/hooks/use-match-media';

type Props = {
  slideshow?: {
    mobile?: boolean;
  };
};

export function useCarousel({ slideshow }: Props) {
  const [isActive, setActive] = useState(false);

  const matches = useMatchMedia('(max-width: 767px)');

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!slideshow?.mobile) return;
    if (matches === null) return;

    setActive(matches);
  }, [matches, slideshow?.mobile]);

  return {
    isActive
  };
}
