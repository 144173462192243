import React from 'react';
import styled from 'styled-components';
import { formatDate } from '~/App/helpers/date.service';
import { mq } from '~/lib/mq';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;

  ${mq('≥small')`
  flex-basis: 4.75rem;
  min-height: 4.75rem;
  min-width: 4.75rem;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.seashell};
  margin: 0 1rem 0 0;
  border-radius: 0.5rem;
`};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;

  ${mq('≥small')`
    flex-direction: column;
  `};
`;

const Day = styled.span`
  display: block;
  margin: 0 0.25rem 0 0;
  font-size: 0.625rem;
  line-height: 1;

  ${mq('≥small')`
  font-size: 2rem;
  flex-basis: 100%;
  text-align: center;
  margin: 0 0 0.25rem 0;
`};
`;

const MonthYear = styled.span`
  display: block;
  font-size: 0.625rem;
  line-height: 1;
  text-transform: uppercase;
`;

type Props = {
  date: string;
  className?: string;
};

export function Date({ date, className }: Props) {
  return (
    <Wrapper className={className}>
      <Content>
        <Day children={formatDate(date, 'D')} />
        <MonthYear children={formatDate(date, 'MMM YYYY')} />
      </Content>
    </Wrapper>
  );
}
