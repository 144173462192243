import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { heading4 } from '~/App/helpers/mixins';
import { bell } from '~/App/helpers/icons';
import { ring } from '~/App/helpers/animations';
import { users } from '~/App/helpers/http';

// Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import { AccountColumn } from '~/App/views/Account/shared/components/AccountColumn';

// Shared
import { useAuthenticationContext } from '~/App/contexts/Authentication';

const Wrapper = styled(AccountColumn)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem 1.5rem 2rem;
  margin: 3rem 0 1rem;
  background: ${({ theme }) => theme.colors.accentYellow};
  color: ${({ theme }) => theme.themeColors.primary};

  ${mq('≥medium')`
    padding: 3rem 1.5rem 2rem;
    margin-top: 4rem;
    margin-bottom: 0rem;
  `};
`;

const Bell = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  top: -2rem;
  background: ${({ theme }) => theme.colors.accentYellow};
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    ${bell};
    font-size: 2.5rem;
    animation: ${ring} 4.5s 0.7s cubic-bezier(0, 0.01, 0.46, 0.9) infinite;
  }
`;

const Confirmation = styled.h3`
  ${heading4};
  margin-bottom: 0.5rem;
  max-width: 30rem;
`;

const Confirmation2 = styled.p`
  margin-bottom: 1rem;
`;

export function EmailVerificationNotification() {
  const [showSpinner, setShowSpinner] = useState(false);

  const user = useAuthenticationContext((x) => x.user);
  const buttonSpinnerTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleResendActivation = useCallback(async () => {
    setShowSpinner(true);

    buttonSpinnerTimer.current = setTimeout(() => {
      setShowSpinner(false);
    }, 2500);

    await users.resendActivion();

    if (document?.activeElement instanceof HTMLElement) {
      document?.activeElement?.blur();
    }
  }, []);

  const confirmationText = useMemo(() => {
    const hasOldItems = user?.hasOldItems;

    if (!hasOldItems) {
      return `Kom ihåg att bekräfta din e-post.`;
    }

    return `Kom ihåg att bekräfta din e-post, så att vi kan visa dina tidigare gåvor och insamlingar här.`;
  }, [user]);

  useEffect(
    () => () => {
      if (buttonSpinnerTimer.current) {
        clearTimeout(buttonSpinnerTimer.current);
      }
    },
    []
  );

  return (
    <Wrapper>
      <Bell />
      <Confirmation children={confirmationText} />
      <Confirmation2 children="Inte fått vårt bekräftelse-epost?" />
      <PrimaryButton
        isLoading={showSpinner}
        buttonStyle="cta"
        type="submit"
        onClick={handleResendActivation}
        children="Skicka om"
      />
    </Wrapper>
  );
}
