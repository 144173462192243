import { useContext } from 'react';

import {
  AuthenticationContext,
  AuthenticationContextValue
} from './AuthenticationContext';

type Selector<T> = (values: AuthenticationContextValue) => T;

export function useAuthenticationContext(): AuthenticationContextValue;
export function useAuthenticationContext<T>(selector: Selector<T>): T;
export function useAuthenticationContext<T = AuthenticationContextValue>(
  selector?: Selector<T>
): T {
  const values = useContext(AuthenticationContext);

  // @ts-expect-error
  return selector ? selector(values) : values;
}
