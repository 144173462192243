import React, { useMemo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

// Helpers
import { heading2, preamble } from '~/App/helpers/mixins';

import ShareButtons from '~/App/shared/components/ShareButtons';

import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { AsyncCertificateDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { Header } from './shared/Header';

const defaultImage =
  '//res.cloudinary.com/cancerfonden/image/upload/v1590132058/assets/hearts_and_hands_circle.svg';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom === 'large' ? '2rem' : 0.5};
  margin-top: ${({ marginTop }) => (marginTop === 'large' ? '2rem' : 0)};
  white-space: pre-line;
`;

const ShareButtonWrapper = styled.div`
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const StyledShareButtons = styled(ShareButtons)`
  width: 5.5rem;
  margin: 0 auto;
`;

export default function GenericCertificateThanks({ purchase, data }) {
  const user = useAuthenticationContext((x) => x.user);

  const productImage =
    purchase?.purchase?.productOptions?.certificate?.illustration;
  const firstName =
    purchase?.purchase?.productOptions?.customerContact?.firstName ||
    user?.firstName;

  const email =
    purchase?.purchase?.productOptions?.customerContact?.email ||
    purchase?.user?.email;

  const greeting = useMemo(
    () =>
      data.heading
        ? firstName
          ? data.heading.replace('%%first_name%%', ` ${firstName}`)
          : data.heading.replace('%%first_name%%', '')
        : 'Tack!',
    [data.heading, firstName]
  );

  const preambleWithEmail = useMemo(() => {
    const text = data?.texts?.text1;

    if (text) {
      return text.replace('%%email%%', email);
    }

    return 'Din gåva bidrar till att föra cancerforskningen framåt – du är med och räddar liv.';
  }, [data.texts.text1, email]);

  const receiverEmail =
    purchase?.purchase?.productOptions?.certificate?.receiverContact?.email;
  const deliveryDate =
    purchase?.purchase?.productOptions?.certificate?.deliveryDate;

  const receiverTextWithEmail = useMemo(() => {
    const text = data?.texts?.receiverEmailText;
    const todayText = `Vi har också skickat gåvobeviset direkt till ${receiverEmail}`;

    if (text) {
      text.replace('%%receiver_email%%', receiverEmail);
    }

    if (!deliveryDate) {
      return todayText;
    }

    const parsed = dayjs(deliveryDate).startOf('day');
    const today = dayjs().startOf('day');

    if (parsed.format() === today.format()) {
      return todayText;
    }

    const formatted = parsed.format('D MMMM YYYY');

    return `Den ${formatted} skickar vi också gåvobeviset till ${receiverEmail}`;
  }, [data.texts.receiverEmailText, deliveryDate, receiverEmail]);

  return (
    <div>
      <Background backgroundColor="primary">
        <Header
          image={productImage || defaultImage}
          transformation={false}
          imagePadding={false}
        >
          <Heading1 children={greeting} />
          <Preamble children={preambleWithEmail} />
          {receiverEmail && (
            <Preamble
              marginTop="large"
              marginBottom="large"
              children={receiverTextWithEmail}
            />
          )}

          <FetchPurchaseDocuments purchase={purchase}>
            {({ receiptStatus, receipt, certificate, certificateStatus }) => (
              <>
                <AsyncReceiptDocumentLink
                  url={receipt}
                  status={receiptStatus}
                  paymentMethodId={purchase?.paymentMethod?.id}
                />
                <AsyncCertificateDocumentLink
                  url={certificate}
                  status={certificateStatus}
                />
              </>
            )}
          </FetchPurchaseDocuments>

          <Preamble
            marginTop="large"
            children={
              data?.texts?.text2 ||
              'Din gåva bidrar till att föra cancerforskningen framåt – du är med och räddar liv.'
            }
          />

          <ShareButtonWrapper>
            <StyledShareButtons
              to={data.shareLink}
              email={{ subject: data.shareEmailSubject }}
              showFacebook={true}
              showX={false}
              showEmail={true}
              showPrint={false}
            />
          </ShareButtonWrapper>
        </Header>
      </Background>
    </div>
  );
}
