import { IAnswer, IQuestionSession } from '~/types/IQuestionSession';
import { mwAxios } from '.';

export const questionSession = {
  get: async (id: string) => {
    const { data } = await mwAxios.get<IQuestionSession>(
      `/api/v2/question_sessions/${id}`
    );

    return data;
  },
  create: async () => {
    const { data } = await mwAxios.post<IQuestionSession>(
      '/api/v2/question_sessions'
    );

    return data;
  },
  update: async (id: string, session: IQuestionSession) => {
    const { data } = await mwAxios.put<IQuestionSession>(
      `/api/v2/question_sessions/${id}`,
      session
    );

    return data;
  },
  send: async (id?: string, email?: string) => {
    const { status } = await mwAxios.post(
      `/api/v2/question_sessions/${id}/send`,
      { email },
      {
        validateStatus: () => true
      }
    );

    return status > 199 && status < 300;
  }
};

export const questionSessionAnswers = {
  getAll: async (sessionId: string) => {
    const { data } = await mwAxios.get<IAnswer[]>(
      `/api/v2/question_sessions/${sessionId}/answers`
    );

    return data;
  },
  create: async (sessionId: string, answer: IAnswer) => {
    const { data } = await mwAxios.post<IAnswer>(
      `/api/v2/question_sessions/${sessionId}/answers`,
      answer
    );

    return data;
  },
  update: async (id: string, sessionId: string, answer: IAnswer) => {
    const { data } = await mwAxios.put<IAnswer>(
      `/api/v2/question_sessions/${sessionId}/answers/${id}`,
      answer
    );

    return data;
  }
};
