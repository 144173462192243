import React, { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import styled from 'styled-components';

import { paymentMethods } from '~/App/config/paymentMethods';
import { swishTypes } from '~/App/config/swishTypes';
import { IValidation } from '~/App/shared/components/Validation';

import {
  IPaymentMethod,
  ICreditCardPaymentMethod,
  IAutoGiroBankIdPaymentMethod
} from '~/types/IPaymentMethod';

import { FormSubHeading, InfoText } from '../../../FormTypography';
import Text from '~/App/shared/components/Fields/Text';

import {
  AutoGiroBankIdFields,
  AutoGiroSlipFields
} from '../../../../../PaymentMethods/AutoGiro';

import { PaymentValues, ContactCustomerValues } from '../../../../../States';

import { ContactFields } from '../../../ContactFields';
import { CreditCardFields } from '../../../../../PaymentMethods/CreditCard';
import { FetchBankAccountValues } from '../../../FetchBankAccount';
import { InvoiceFields } from '../../../../../PaymentMethods/Invoice';

const StepWrapper = styled.div`
  padding-top: 1.25rem;
`;

const PaddedText = styled(Text)`
  margin-top: 0.5rem;
`;

const PaddedInfoText = styled(InfoText)`
  margin-top: 1rem;
`;

type Props = {
  method: IPaymentMethod;
  values: PaymentValues & ContactCustomerValues;
  validation: IValidation;
  fetchBankAccount: FetchBankAccountValues;
  formContent: {
    taxReductionInfoLink?: string;
  };
};

export function PaymentMethod({
  method,
  values,
  validation,
  formContent,
  fetchBankAccount
}: Props) {
  const period = useMemo(() => get(values, 'productOptions.product.period'), [
    values
  ]);

  const productId = useMemo(() => get(values, 'product.id'), [values]);

  const isActive = useCallback(
    <T extends IPaymentMethod = IPaymentMethod>(
      value: IPaymentMethod,
      paymentMethodId: number
    ): value is T => value.id === paymentMethodId,
    []
  );

  if (isActive(method, paymentMethods.swish)) {
    if (values.swish.swishType === swishTypes.ecommerce) {
      return (
        <StepWrapper>
          <FormSubHeading children="Ange ditt nummer kopplat till Swish" />
          <PaddedText
            name="tel"
            type="tel"
            value={values.swishPhoneNumber}
            onBlur={validation.handleBlur.bind(undefined, 'swishPhoneNumber')}
            errors={validation.errors['swishPhoneNumber']}
            isValid={validation.isValid('swishPhoneNumber')}
            onChange={values.handlers.handleSwishPhoneNumber}
            isInvalid={validation.isInvalid('swishPhoneNumber')}
            maxLength="10"
            placeholder="Ditt telefonnummer"
            autoComplete="mobile tel"
            iconUrl="/images/swish-logos/swish.png"
          />
        </StepWrapper>
      );
    }
  }

  if (
    isActive<IAutoGiroBankIdPaymentMethod>(
      method,
      paymentMethods.autoGiroBankId
    )
  ) {
    return (
      <StepWrapper>
        <AutoGiroBankIdFields
          values={values}
          validation={validation}
          fetchBankAccount={fetchBankAccount}
          showSsnField={method.showSsnField}
          showEmailField={method.showEmailField}
        />
      </StepWrapper>
    );
  }

  if (isActive<ICreditCardPaymentMethod>(method, paymentMethods.creditCard)) {
    const showContactFields =
      period !== 'Once' && method.showContactFields !== false;

    return (
      <>
        <StepWrapper>
          <CreditCardFields validation={validation} lastItem={true} />
        </StepWrapper>
        {showContactFields && (
          <StepWrapper>
            <ContactFields
              values={values}
              validation={validation}
              taxReductionInfo={formContent.taxReductionInfoLink || true}
              manual={{
                email: true
              }}
              automatic={{
                email: true
              }}
            />
          </StepWrapper>
        )}
      </>
    );
  }

  if (isActive(method, paymentMethods.paymentSlip)) {
    return (
      <StepWrapper>
        {values.customerContactType !== 'manual' && (
          <ContactFields
            values={values}
            validation={validation}
            manual={{
              email: false
            }}
          />
        )}
        <PaddedInfoText
          children={
            productId === 23 || productId === 25 || productId === 33
              ? 'Ett inbetalningskort skickas hem till dig inom 5 arbetsdagar.'
              : 'Ett inbetalningskort skickas hem till dig inom 30 arbetsdagar.'
          }
        />
      </StepWrapper>
    );
  }

  if (isActive(method, paymentMethods.invoice)) {
    return <InvoiceFields values={values} validation={validation} />;
  }

  if (isActive(method, paymentMethods.autoGiroSlip)) {
    return (
      <StepWrapper>
        <AutoGiroSlipFields values={values} validation={validation} />
      </StepWrapper>
    );
  }

  return null;
}
