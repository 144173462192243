import { isNaN } from 'lodash';
import { ParsedQuery, ParsedValue } from './types';

type Value = string | undefined | null;
type ParseAttempt<T> = {
	success: boolean;
	value: T | null;
};

function tryParseArray(value: Value): ParseAttempt<ParsedValue> {	
	if(!value) return { success: false, value: null };
	if(!value?.includes(',')) return { success: false, value: null};

	const splitted = value?.split(',');

	if(!splitted.length) return { success: false, value: null };

	const filtered = splitted.filter(val => val);
	const normalized = filtered.map(val => {
		const parseBooleanAttempt = tryParseBoolean(val);
	
	if (parseBooleanAttempt.success) {
		return parseBooleanAttempt.value;
	}

	const parseNumberAttempt = tryParseNumber(val);

	if (parseNumberAttempt.success) {
		return parseNumberAttempt.value;
	}

	return val;
	});

	return { success: true, value: normalized };
}

function tryParseNumber(value: Value): ParseAttempt<number> {
	if(!/^\d+$/.test(`${value}`)) {
		return {
			success: false,
			value: null
		}
	}
	
	const number = parseInt(`${value}`, 10);
	const success = typeof number === 'number' && !isNaN(number);
	
	return {
		success,
		value: success ? number : null
	};
}

function tryParseBoolean(value: Value): ParseAttempt<boolean> {
	if (value === 'true')
		return {
			success: true,
			value: true
		};

	if (value === 'false')
		return {
			success: true,
			value: false
		};

	return {
		success: false,
		value: null
	};
}

function isEncoded(uri: string) {
  return uri !== decodeURIComponent(uri);
}

function tryParseString(value: Value): ParseAttempt<string> {
	if(typeof value !== 'string') {
		return {
			success: false,
			value: null
		}
	}

	return {
		success: true,
		value: isEncoded(value) ? decodeURIComponent(value) : value 
	};
}

function parseValue(value: Value): ParsedValue {
	if (!value) return null;

	const parseArrayAttempt = tryParseArray(value);

	if (parseArrayAttempt.success) {
		return parseArrayAttempt.value;
	}

	const parseBooleanAttempt = tryParseBoolean(value);
	
	if (parseBooleanAttempt.success) {
		return parseBooleanAttempt.value;
	}

	const parseNumberAttempt = tryParseNumber(value);

	if (parseNumberAttempt.success) {
		return parseNumberAttempt.value;
	}

	const parseStringAttempt = tryParseString(value);

	if (parseStringAttempt.success) {
		return parseStringAttempt.value;
	}

	return null;
}

function parsePair(pair: string): ParsedQuery {
	const [ key, value ] = pair.split('=');

	return {
		[key]: parseValue(value)
	};
}

export function parse(value?: string): ParsedQuery {
	if (!value) return {};

	const cleaned = value.startsWith('?') ? value.substr(1) : value;
	const splitted = cleaned.split('&');
	
	return splitted.reduce<ParsedQuery>(
		(prev, current) => ({
			...prev,
			...parsePair(current)
		}),
		{}
	);
}
