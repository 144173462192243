import { IImage, IImageSource } from './IImage';
import { IMeta } from './IMeta';

export enum CmsProductTarget {
  private = 'private',
  company = 'company'
}

export enum CmsProductPriceType {
  fixedPrice = 'fixedPrice', // fast belopp
  flexiblePrice = 'flexiblePrice' // rörligt belopp
}

export interface ICmsProductPriceFixed {
  price: number;
  quantities: number[];
  defaultQuantity: number;
}

export interface ICmsProductPriceFlexible {
  prices: number[];
  defaultPrice: number;
}

export interface ICmsProductCertificate {
  heading: string;
  description: string;
  disclaimer: string;
  image: IImage;
  imageAlt: string;
}

export interface ICmsProductHero {
  heading: string;
  description: string;
  small: IImage;
  large: IImage;
  imageAlt: string;
  showLightTextColor: boolean;
  headingSettings: {
    fontSize: string;
    textAlign: string;
  };
}

export interface ICmsProduct {
  name: string;
  slug: string;
  productId: number;
  campaignActivityId?: number;
  target: CmsProductTarget;
  url: string;
  path: string;
  redirectPath: string;
  redirectUrl: string;
  productImage?: IImageSource;
  imageAlt?: string;

  certificate?: ICmsProductCertificate; // gåvobevis

  shortDescription?: string; // used in list-view
  longDescription?: string; // used on product page

  priceType: CmsProductPriceType;
  priceFixed?: ICmsProductPriceFixed;
  priceFlexible?: ICmsProductPriceFlexible;
  hero: ICmsProductHero;
  blocks: any[];
  meta: IMeta;
}
