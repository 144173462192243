import React, { useMemo } from 'react';
import styled from 'styled-components';
import mq from '~/App/helpers/mq';

import { ICmsProduct } from '~/types/ICmsProduct';

import {
  MultiPartFormValues,
  Section
} from '~/App/shared/components/MultiPartForm';
import {
  HiddenAboveMedium,
  StepWrapper
} from '~/App/shared/components/Donation/components/Forms/components/FormLayout';

import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';

import QuantitySelector from '~/App/shared/components/Donation/components/Forms/components/QuantitySelector';
import AmountSelector from '~/App/shared/components/Donation/components/Forms/components/AmountSelector';
import { AmountWrapper } from '~/App/shared/components/Donation/components/Forms/components/Summaries/components/AmountWrapper';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import { TaxReductionInfo } from '~/App/shared/components/Donation/components/Forms/components/TaxReductionInfo';
import { IValidation } from '~/App/shared/components/Validation';
import { StateValues } from '~/App/shared/components/Donation/components/States';

const StyledTaxReductionInfo = styled(TaxReductionInfo)`
  margin-top: -2rem;

  ${mq('≥small')`
    margin-top: -3rem;
  `};

  ${mq('≥medium')`
    margin-top: -2rem;
  `};
`;

type Props = {
  product: ICmsProduct;
  values: MultiPartFormValues & StateValues;
  validation: IValidation;
};

export default function FormPartAmountOrQuantity({
  product,
  validation,
  values
}: Props) {
  const { quantity = 0, price } = values.productOptions.product;

  const taxReductionInfo = useMemo(
    () => (
      <StyledTaxReductionInfo
        amount={getListPrice(values.productOptions)}
        quantity={getQuantity(values.productOptions)}
      />
    ),
    [values.productOptions]
  );

  if (product.priceType === 'fixedPrice') {
    return (
      <Section
        values={values}
        part={1}
        validation={validation}
        title={`Välj antal (à ${product.priceFixed?.price} kr)`}
        nextStepButton={{ text: 'Till kontaktuppgifter' }}
      >
        <StepWrapper>
          <QuantitySelector
            quantities={product.priceFixed?.quantities ?? []}
            selectedQuantity={values.productOptions.product.quantity}
            customQuantity={values.productOptions.product.customQuantity}
            setQuantity={values.handlers.setProductQuantity}
            setCustomQuantity={values.handlers.setProductCustomQuantity}
            resetQuantity={values.handlers.resetProductQuantity}
            validationKey={'productOptions.product.quantity'}
            validation={validation}
            tinyLayout={'auto'}
            smallLayout={'auto'}
            largeLayout={'auto'}
          />
        </StepWrapper>
        {taxReductionInfo}
        <HiddenAboveMedium>
          <FormHeading>Totalt att betala</FormHeading>
          <AmountWrapper
            text={`${price} kr x ${quantity} st`}
            amount={price && quantity ? price * quantity : null}
          />
        </HiddenAboveMedium>
      </Section>
    );
  }

  return (
    <Section
      values={values}
      part={1}
      validation={validation}
      title="Välj belopp"
      nextStepButton={{ text: 'Till kontaktuppgifter' }}
    >
      <StepWrapper>
        <AmountSelector
          amounts={product.priceFlexible?.prices ?? []}
          selectedAmount={values.productOptions.product.price}
          customAmount={values.productOptions.product.customPrice}
          setAmount={values.handlers.setProductPrice}
          setCustomAmount={values.handlers.setProductCustomPrice}
          resetAmount={values.handlers.resetProductPrice}
          validationKey={'productOptions.product.price'}
          validation={validation}
          tinyLayout={'50'}
          smallLayout={'50'}
          largeLayout={'50'}
        />
        {taxReductionInfo}
      </StepWrapper>

      <HiddenAboveMedium>
        <AmountWrapper text="Gåvobelopp" amount={price} />
      </HiddenAboveMedium>
    </Section>
  );
}
