import {
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';

import { Period } from '~/types/IPurchase';

import { mustMatch } from './helpers';

const PERIODS: Record<number, Period> = {
  31: 'Month',
  32: 'Month',
  3: 'Once',
  8: 'Year'
};

export type ProductValues = {
  product: {
    id: number;
  };
  productOptions: {
    product: {
      duration?: string;
      price: number | null;
      customPrice: boolean;
      customQuantity: boolean;
      quantity: number | null;
      period: Period;
    };
  };
  handlers: {
    setProductId: (id: number) => void;
    setProductPrice: (price: number | null) => void;
    setProductPeriod: Dispatch<SetStateAction<Period>>;
    setProductDuration: (duration: string) => void;
    setProductCustomPrice: (value: boolean) => void;
    setProductCustomQuantity: (value: boolean) => void;
    setProductQuantity: (value: number | null) => void;
    resetProductPrice: () => void;
    resetProductQuantity: () => void;
  };
};

type InputProductValues = {
  product?: {
    id?: number;
  };
  productOptions?: {
    product?: {
      duration?: string;
      customPrice?: boolean;
      customQuantity?: boolean;
      quantity?: number | null;
      price?: number | null;
      period?: Period;
    } & Record<string, unknown>;
  } & Record<string, unknown>;
  handlers?: Record<string, unknown>;
};

export function useProductValues<T extends InputProductValues>(values: T) {
  const { productOptions: { product } = {} } = values;

  const [id, setId] = useState(values.product?.id ?? 31);
  const [duration, setDuration] = useState(product?.duration ?? '');
  const [customPrice, setCustomPrice] = useState(product?.customPrice ?? false);
  const [customQuantity, setCustomQuantity] = useState(
    product?.customQuantity ?? false
  );

  const [price, setPrice] = useState<number | null>(product?.price ?? null);
  const [quantity, setQuantity] = useState<number | null>(
    product?.quantity ?? 1
  );
  const [period, setPeriod] = useState<Period>(
    values?.productOptions?.product?.period ?? PERIODS[id] ?? 'Once'
  );

  const resetPrice = useCallback(() => setPrice(null), []);
  const resetQuantity = useCallback(() => setQuantity(null), []);

  const handleSetProductId = useCallback((id: number) => {
    setId(id);

    const suggestedPeriod = PERIODS[id];

    if (suggestedPeriod) {
      setPeriod(suggestedPeriod);
    }
  }, []);

  return useMemo<T & ProductValues>(
    () => ({
      ...values,
      product: {
        id
      },
      productOptions: {
        ...values?.productOptions,
        product: {
          ...values?.productOptions?.product,
          price,
          customPrice,
          customQuantity,
          period,
          duration,
          quantity
        }
      },
      handlers: {
        ...values.handlers,
        setProductId: handleSetProductId,
        setProductPrice: setPrice,
        setProductPeriod: setPeriod,
        setProductDuration: mustMatch(setDuration, /^\d*$/),
        setProductCustomPrice: setCustomPrice,
        setProductCustomQuantity: setCustomQuantity,
        resetProductPrice: resetPrice,
        resetProductQuantity: resetQuantity,
        setProductQuantity: setQuantity
      }
    }),
    [
      values,
      id,
      price,
      customPrice,
      customQuantity,
      period,
      duration,
      quantity,
      handleSetProductId,
      resetPrice,
      resetQuantity
    ]
  );
}
