import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { formatMoney } from 'accounting';

// consts
import { collectionTypes } from '~/App/config/collectionTypes';
import * as themes from '~/App/config/themes';

// shared components
import Link from '~/App/shared/components/Link';

// helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';
import { heading3, arrowLink, card, downloadLink } from '~/App/helpers/mixins';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';
import {
  getGoalType,
  getProgressPercent
} from '~/App/helpers/collection-helper.js';
import { cog } from '~/App/helpers/icons';
import { pushGTMEvent } from '~/App/helpers/gtm-helper';
import { gtmCustomEventCategories } from '~/App/config/gtmCustomEvents';
import { CreatePDF } from '~/App/shared/components/PdfCreator';

const backgroundColor = featureIsEnabled('FEATURE_LOPBANDET_PINK')
  ? themes.global.collectionItem.imageAlternativeBackground
  : themes.global.exerciseApp.imageBackgroundColor;

const ArrowLink = styled(Link)`
  ${arrowLink};
  line-height: 1.25rem;
  margin-top: 0;

  ${mq('≥small')`
    line-height: 1.5rem;
  `};
`;

const Wrapper = styled.div`
  ${card}
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;

  ${mq('≥small')`
    flex-direction: row;
  `};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Avatar = styled.div`
  display: block;
  width: 5.5rem;
  height: 5.5rem;
  background-color: ${(props) =>
    props.backgroundColor
      ? backgroundColor
      : props.theme.collectionItem.imageBackground};
  border-radius: 50%;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
`;

const Info = styled.div`
  position: relative;
  display: block;
  width: calc(100% - 6.5rem);
  margin: 0 1rem 1rem 0;

  ${mq('≥small')`
    margin: 0 0 1rem 1rem;
  `};
`;

const Name = styled.h3`
  ${heading3};
  color: ${({ theme }) => theme.collectionItem.textColor};
  font-size: 1.375rem;
  line-height: 1.227272727;
  word-break: break-word;
  margin-bottom: 0.75rem;
  margin-right: ${(props) => (props.hasEditIcon === true ? '2.5rem' : '0')};
`;

const SettingsLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.collectionItem.settingsLinkColor};

  &:before {
    ${cog};
    font-size: 1.5rem;
  }
`;

const Status = styled.div`
  margin-bottom: 1rem;
  max-width: 29.25rem;
  display: flex;
  justify-content: space-between;

  ${mq('≥small')`
    p {
      font-size: 1rem;
      line-height: 1.5;
    }
  `};
`;

const ProgressWrapper = styled.div`
  width: 100%;
  max-width: 29.25rem;
  height: 0.75rem;
  background-color: ${(props) => props.theme.colors.seashell};
  border-radius: 0.375rem;
  margin: 0;
`;

const ProgressDone = styled.div`
  background-color: ${(props) =>
    props.paused
      ? props.theme.colors.silverChalice
      : props.theme.themeColors.primary};
  width: ${({ progress }) => `${progress}%`};
  height: 0.75rem;
  border-radius: 0.365rem;
`;

const Actions = styled.ul`
  list-style-type: none;
  margin: 2rem 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  li {
    margin: 0.25rem 1.25rem 0.25rem 0;

    ${mq('≥small')`
      margin: 0 1.25rem 0 0;
    `};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const EditStatus = styled.p`
  font-size: 0.9375rem;
  line-height: 1;
  margin-top: 0.5rem;

  ${mq('≥small')`
    font-size: 0.9375rem;
    line-height: 1;
  `};
`;

const DownloadLink = styled(Link)`
  ${downloadLink};
  line-height: 1.25rem;
  margin-top: 0;
  padding-right: 1.75rem;

  ${mq('≥small')`
    line-height: 1.5rem;
  `};
`;

export function Card({ collection }) {
  const isLopbandet = collection.collectionType === collectionTypes.lopbandet;
  const backgroundColor =
    (collection.imageUrl === '' || collection.imageUrl === null) &&
    collection.collectionType === collectionTypes.lopbandet;

  const progress = getProgressPercent(collection, getGoalType(collection));

  const imageSrc = useMemo(() => {
    const urlEmpty =
      collection.imageUrl === '' ||
      collection.imageUrl === null ||
      !collection.imageUrl;
    const defaultUrl = isLopbandet
      ? 'https://res.cloudinary.com/cancerfonden/image/upload/v1567681619/assets/empty-collection_lopbandet.png'
      : 'http://res.cloudinary.com/cancerfonden/image/upload/v1561540461/grncs9xxyzmee8mywbvs.jpg';
    const imageUrl = urlEmpty ? defaultUrl : collection.imageUrl;

    return mediaHelper.getUrl(imageUrl, {
      width: 88,
      height: 88,
      quality: 90,
      crop: 'fill',
      gravity: 'face',
      dpr: '2.0',
      radius: 88,
      fetch_format: 'png'
    });
  }, [collection.imageUrl, isLopbandet]);

  const sendEventToGtm = useCallback(
    () =>
      pushGTMEvent({
        category: gtmCustomEventCategories.downloadFigurmarke._category,
        action: gtmCustomEventCategories.downloadFigurmarke._action,
        label: gtmCustomEventCategories.downloadFigurmarke._label
      }),
    []
  );

  return useMemo(
    () => (
      <Wrapper key={collection.id}>
        <Avatar backgroundColor={backgroundColor}>
          <Image src={imageSrc} />
        </Avatar>
        <Info>
          <Name hasEditIcon={!isLopbandet}>{collection.name}</Name>
          {!isLopbandet && (
            <SettingsLink to={`/min-sida/insamlingar/${collection.slug}`} />
          )}
          <Status>
            <p>
              {formatMoney(
                collection.status,
                isLopbandet ? 'km' : 'kr',
                0,
                ' ',
                null,
                '%v %s'
              )}
            </p>
            {!collection.enabled && <p>Avslutad</p>}
          </Status>
          <ProgressWrapper>
            <ProgressDone progress={progress} paused={!collection.enabled} />
          </ProgressWrapper>
          <Actions>
            <li>
              <ArrowLink to={`/insamlingar/${collection.slug}`}>
                Visa insamling
              </ArrowLink>
            </li>
            {!collection.enabled ? null : (
              <li>
                <DownloadLink
                  to="https://res.cloudinary.com/cancerfonden/image/upload/v1610700976/assets/figurmarke-eps.eps"
                  onClick={sendEventToGtm}
                  children="Figurmärke"
                />
              </li>
            )}
            {!collection.enabled || isLopbandet ? null : (
              <li>
                <CreatePDF data={collection} />
              </li>
            )}
          </Actions>

          {isLopbandet && (
            <EditStatus>
              Du kan redigera din insamling i Löpbandet-appen.
            </EditStatus>
          )}
        </Info>
      </Wrapper>
    ),
    [
      collection,
      backgroundColor,
      imageSrc,
      isLopbandet,
      progress,
      sendEventToGtm
    ]
  );
}
