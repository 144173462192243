import React, { useCallback, ChangeEvent, FormEvent } from 'react';

import styled from 'styled-components';

// Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import Text from '~/App/shared/components/Fields/Text';
import Spacer from '~/App/shared/components/Spacer';
import { useValidation } from '~/App/shared/components/Validation';

const StyledSpacer = styled(Spacer)`
  text-align: center;
`;

const schema = {
  email: {
    presence: {
      allowEmpty: false,
      message: '^E-post krävs'
    },
    email: {
      message: '^Felaktig e-postadress'
    }
  }
};

type Values = {
  email: string;
};

type Props = {
  values: Values;
  errors: Record<string, string[]>;
  isLoading: boolean;
  onChange: (values: Values) => void;
  onSubmit: (values: Values) => Promise<void>;
};

export function Form({ values, errors, isLoading, onChange, onSubmit }: Props) {
  const handleChangeEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      onChange({
        ...values,
        email: event.target.value
      }),
    [onChange, values]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onSubmit(values);
    },
    [onSubmit, values]
  );

  const validation = useValidation({
    values,
    schema
  });

  return (
    <>
      <form action="" onSubmit={handleSubmit}>
        <Spacer top="medium">
          <Text
            name="email"
            type="email"
            value={values.email}
            placeholder="E-post"
            maxLength="100"
            onChange={handleChangeEmail}
            onBlur={validation.showError.bind(undefined, 'email')}
            errors={validation.errors['email'] || errors?.email}
            isValid={validation.isValid('email') && errors?.email?.length === 0}
            isInvalid={
              validation.isInvalid('email') || errors?.email?.length > 0
                ? true
                : false
            }
          />
        </Spacer>
        <StyledSpacer top="medium">
          <PrimaryButton
            type="submit"
            isLoading={isLoading}
            buttonStyle={validation.isValidated ? 'primary' : 'disabled'}
            isDisabled={!validation.isValidated}
            children="Spara e-post"
          />
        </StyledSpacer>
      </form>
      <Spacer top="medium" />
    </>
  );
}
