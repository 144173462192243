import { fetch } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export const loadData = async ({ params }: LoadDataProps<'slug'>) => {
  const response = await fetch({
    url: `${process.env.CONTENT_API}/v1/research-projects/${params.slug}`
  });

  return { data: response.data };
};
