import React from 'react';
import { ComponentProps } from 'react';

import { HtmlContent } from '~/App/shared/components/HtmlContent';

type Props = ComponentProps<typeof HtmlContent>;

export function Body(props: Props) {
  return props.children ? <HtmlContent {...props} /> : null;
}
