import React, { Fragment, useCallback, ReactNode } from 'react';
import styled from 'styled-components';

import { preamble } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';
import { ISubmit } from '../../components/Submits';
import { IValidation } from '../../../Validation';
import { TaxReductionNudging } from './TaxReductionNudging';
import { SubmitValues } from '../Submits/Submit/types/SubmitValues';

const Text = styled.p`
  ${preamble}
  font-size: 1rem;
  margin: 1rem 0;
  color: ${(props) => props.theme.colors.charcoal};
  font-weight: ${(props) => props.theme.fontWeights.regular};

  ${mq('≥small')`
   font-size: 1.125rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  `};
`;

type Props = {
  submit: ISubmit;
  validation: IValidation;
  values: SubmitValues;
  name: string;
  shouldNudge: boolean;
  setAmount: (amount: number | null) => void;
  children: (submit: ISubmit) => ReactNode;
  maximumNudgeAmount?: number | null;
};

export function OnceDonationNudge(props: Props) {
  const getRecommendedAmount = useCallback(
    (amount: number, maximumNudgeAmount?: number | null) => {
      if (maximumNudgeAmount && amount >= maximumNudgeAmount) {
        return null;
      }
      return Math.round(amount * 1.1);
    },
    []
  );

  const getContent = useCallback(
    ({ recommendedAmount, amount }) => ({
      heading: 'Ännu mer forskning?',
      children: (
        <Fragment>
          <Text>
            Din gåva stöttar forskning som kan besegra cancer - tack! Kan du
            tänka dig att öka med {recommendedAmount - amount} kronor och stötta
            ännu mer forskning?
          </Text>
        </Fragment>
      )
    }),
    []
  );

  return (
    <TaxReductionNudging
      {...props}
      getContent={getContent}
      getRecommendedAmount={getRecommendedAmount}
    />
  );
}
