import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';

// Helpers
import { primaryButton } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';
import { collections } from '~/App/helpers/http';
import { CollectionAdmin } from '~/types/ICollection';

// Shared components
import { AccountHeading1 } from '~/App/views/Account/shared/components/AccountHeadings';
import { AccountColumn } from '~/App/views/Account/shared/components/AccountColumn';
import ShareLink from '~/App/shared/components/ShareLink.jsx';
import { Divider } from '~/App/shared/components/Blocks';
import Redirect from '~/App/shared/components/Redirect';

// Local components
import { Admin } from './Admin';
import { ConfirmationModal } from './Admins.ConfirmationModal';

const Card = styled(AccountColumn)`
  margin-bottom: 1rem;
`;

const LinkButton = styled(ShareLink)`
  ${primaryButton};
  width: 100%;

  ${mq('≥small')`
    width: auto;
  `};
`;

const StyledParagraph = styled.p`
  margin-top: 1.5rem;
`;

const ShareURL = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.seashell};
  color: ${(props) => props.theme.colors.charcoal};
  word-break: break-all;
`;

type Props = {
  values: {
    ownerJoinCode?: string;
    slug?: string;
    owners: CollectionAdmin[];
    isPaused: boolean;
  };
};

export function Admins({ values }: Props) {
  const { ownerJoinCode, slug, isPaused } = values;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [adminToDelete, setAdminToDelete] = useState<CollectionAdmin | null>(
    null
  );
  const [redirectAfterDelete, setRedirectAfterDelete] = useState<boolean>(
    false
  );

  const [owners, setOwners] = useState<CollectionAdmin[]>(values.owners);

  useEffect(() => {
    setOwners(values.owners);
  }, [values]);

  const joinLink = useMemo(() => {
    if (typeof window === 'undefined') return '';

    const location = window.location;

    return `${location.protocol}//${location.hostname}?ownerJoinCode=${ownerJoinCode}&slug=${slug}`;
  }, [ownerJoinCode, slug]);

  const openModal = useCallback((admin: CollectionAdmin) => {
    setAdminToDelete(admin);
    setShowModal(true);
  }, []);

  const deleteAdmin = useCallback(async () => {
    await collections.deleteOwner({
      slug: `${slug}`,
      data: { user_id: adminToDelete?.id }
    });

    setShowModal(false);

    const filteredOwners = owners.filter(
      (owner) => owner.id !== adminToDelete?.id
    );

    setOwners(filteredOwners);

    if (adminToDelete?.you) {
      setRedirectAfterDelete(true);
    }
  }, [adminToDelete, owners, slug]);

  const renderedOwners = useMemo(() => {
    if (!owners) return null;

    return owners
      .sort((a, b) => (a.firstName < b.firstName ? -1 : 1))
      .map((admin) => (
        <Admin
          key={admin.id}
          admin={admin}
          removable={owners.length > 1}
          remove={openModal}
        />
      ));
  }, [openModal, owners]);

  if (redirectAfterDelete) {
    return <Redirect to="/min-sida/insamlingar" />;
  }

  return (
    <Card>
      {showModal && adminToDelete && (
        <ConfirmationModal
          admin={adminToDelete}
          showModal={setShowModal}
          deleteAdmin={deleteAdmin}
        />
      )}

      <AccountHeading1>Insamlingens administratörer</AccountHeading1>
      {!isPaused && (
        <>
          <StyledParagraph>
            Vill ni vara flera som hanterar insamlingen? Kopiera och skicka
            länken nedan till de du vill göra till administratörer.
          </StyledParagraph>
          <ShareURL>{joinLink}</ShareURL>
          <LinkButton to={joinLink}>Kopiera länk</LinkButton>
          <Divider
            width="narrow"
            paddingTop="small"
            paddingBottom="tiny"
            paddingLeft="none"
            paddingRight="none"
            alternativeColor={true}
            height="2px"
          />
        </>
      )}
      {renderedOwners}
    </Card>
  );
}
