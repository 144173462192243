import { fetch } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';

import { LoadDataProps } from '~/types/routes';

export const loadData = async ({ params }: LoadDataProps<'slug'>) => {
  const slug = params.slug;
  const response = await Promise.all([
    fetch({
      url: contentApi(`/v1/editorial-items/articles/${slug}`)
    }),
    fetch({
      url: contentApi('/v1/content-definitions/global-articles-feed')
    })
  ]);

  const [editorialItem, articleFeed] = response;

  return {
    data: {
      articleFeed: articleFeed.data.contentDefinition,
      editorialItem: editorialItem.data.editorialItem
    }
  };
};
