import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { IValidation } from '~/App/shared/components/Validation';
import { ISubmit } from '~/App/shared/components/Donation/components/Submits';

import { paymentMethods } from '~/App/config/paymentMethods';
import { getPurchaseErrorMessage } from '~/App/shared/components/Donation/components/Forms/helpers';

import { FavourablePaymentMethodSelector } from '~/App/shared/components/Donation/components/Forms/components/FavourablePaymentMethodSelector';
import { IntegrityTextArea } from '~/App/shared/components/Donation/components/Forms/components/IntegrityTextArea';
import { SubmitButton } from '~/App/shared/components/Donation/components/Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '~/App/shared/components/Donation/components/Forms/components/SwishOtherDeviceButton';
import { AlertMessageContainer } from '~/App/shared/components/Elements';
import { CreateMemoryStateValues } from '../hooks/useCreateMemoryState';

const IntegrityTextAreaWrapper = styled.div`
  margin: 0.5rem 0;
`;

type Props = {
  values: CreateMemoryStateValues;
  validation: IValidation;
  submit: ISubmit;
};

export function Payment({ values, validation, submit }: Props) {
  const isRecurring = useMemo(
    () => values.productOptions.product?.period !== 'Once',
    [values.productOptions.product.period]
  );

  const defaultPaymentMethod = useMemo(
    () => ({
      id: paymentMethods.klarnaPayments,
      name: 'Konto eller kort'
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRecurring]
  );

  const methods = useMemo(() => {
    if (isRecurring) {
      return [
        {
          id: paymentMethods.klarnaPayments,
          name: 'Konto eller kort',
          slug: 'klarna',
          favourable: true
        },
        {
          id: paymentMethods.autoGiroBankId,
          name: 'Autogiro Mobilt BankID',
          favourable: false,
          error:
            'Väljer du Klarna går mer av din gåva till forskningen. Detta betalsätt innebär en högre administrativ kostnad för Cancerfonden.'
        },
        {
          id: paymentMethods.autoGiroSlip,
          name: 'Autogiroblankett',
          favourable: false,
          error:
            'Väljer du Klarna går mer av din gåva till forskningen. Detta betalsätt innebär en högre administrativ kostnad för Cancerfonden.'
        }
      ];
    }

    return [
      {
        id: paymentMethods.swish,
        name: 'Swish',
        favourable: true
      },
      {
        id: paymentMethods.klarnaPayments,
        name: 'Konto eller kort',
        slug: 'klarna',
        favourable: true
      }
    ];
  }, [isRecurring]);

  useEffect(() => {
    values.handlers.handlePaymentMethod(defaultPaymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPaymentMethod]);

  return (
    <FavourablePaymentMethodSelector
      methods={methods}
      formContent={{}}
      values={values}
      validation={validation}
    >
      <>
        {submit.errors && !submit.isPolling && !submit.isSending && (
          <AlertMessageContainer
            messageStyle="error"
            children={getPurchaseErrorMessage(submit.exceptionCode)}
          />
        )}
        <SubmitButton values={values} validation={validation} submit={submit} />
        <SwishOtherDeviceButton values={values} text="Swish på annan enhet?" />

        <IntegrityTextAreaWrapper>
          <IntegrityTextArea values={values} />
        </IntegrityTextAreaWrapper>
      </>
    </FavourablePaymentMethodSelector>
  );
}
