import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { useWindowSize } from '~/App/shared/hooks/use-window-size';

type PreviewWrapperProps = {
  fullSize?: boolean;
  height: number | undefined;
};
const Wrapper = styled.div<PreviewWrapperProps>`
  position: relative;
  width: 100%;
  height: ${({ fullSize, height }) => (fullSize ? height + 'px' : '407px')};
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${({ fullSize }) => !fullSize && '288px'};
  margin-bottom: ${({ fullSize }) => !fullSize && '1.5rem'};
`;

type Props = {
  fullSize?: boolean;
  children: ReactNode;
};

export function PreviewWrapper({ fullSize, children }: Props) {
  const { width } = useWindowSize();
  // 1.4142 is aspect ratio for a4 paper, 625 is the modal maxWidth
  const height = useMemo(() => {
    return width && width <= 625 ? width * 1.4142 : 884;
  }, [width]);

  return <Wrapper height={height} fullSize={fullSize} children={children} />;
}
