import styled from 'styled-components';

// Helpers
import { shoppingCart } from '~/App/helpers/icons';
import mq from '~/App/helpers/mq';

export const CartIcon = styled.div`
  &:before {
    ${shoppingCart};
    //font-size: 1.75rem;
    font-weight: bold;
  }
  /* ${mq('<mediumLarge')`
    &:before{
      font-size: 1.25rem;
  }
  `}; */
`;
