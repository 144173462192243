import React from 'react';
import { omit } from 'lodash';

// Shared components
import {
  Content,
  BlockImage,
  Breadcrumbs
} from '~/App/shared/components/Blocks';
import { PageFooter } from '~/App/shared/components/PageFooter';

export default class PressPage extends React.Component {
  constructor(props) {
    super(props);

    this.heroRef = React.createRef();
  }

  render() {
    const {
      content = {},
      breadcrumbs = {},
      publishedAt = '',
      showBreadcrumbs = false,
      showPageFooter = true,
      sharingIcons = {},
      articleImage = {},
      extraData = {}
    } = this.props;

    const hasArticleImage =
      articleImage.large &&
      articleImage.large.source &&
      articleImage.large.source.secure_url;

    const preambleContent = omit(content, ['body']);
    const mainContent = omit(content, ['heading', 'preamble']);
    preambleContent.padding.bottom = 'tiny';
    mainContent.padding.top = 'tiny';

    return (
      <div>
        {showBreadcrumbs && <Breadcrumbs {...breadcrumbs} />}
        <div id={content.hash}>
          <Content
            {...preambleContent}
            padding={{ top: 'large', bottom: 'tiny' }}
            publishedAt={publishedAt}
          />
        </div>
        {hasArticleImage && <BlockImage {...articleImage} gravity="faces" />}
        <div>
          <Content {...mainContent} />
        </div>
        {showPageFooter && (
          <PageFooter
            sharingIcons={sharingIcons}
            width={content.width}
            images={extraData.images}
            links={extraData.links}
          />
        )}
      </div>
    );
  }
}
