import React from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';

import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import { ShipmentStatus } from './ShipmentStatus';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  :not(:last-child) {
    margin-bottom: 1rem;
  }

  ${mq('≥small')`
    flex-direction: row;
  `};
`;

const Group = styled.div`
  display: flex;
  margin-bottom: 0.25rem;
`;

const Quantity = styled.div`
  font-size: 1rem;
  margin-right: 1.5rem;
`;

const Name = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
`;

type Props = {
  orderLine: ICommerceOrderLine;
};

export function OrderLine({ orderLine }: Props) {
  return (
    <Row>
      <Group>
        <Quantity children={`${orderLine.quantity}st`} />
        <Name children={orderLine.variant?.name} />
      </Group>
      <ShipmentStatus shipment={orderLine.shippment} />
    </Row>
  );
}
