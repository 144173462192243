import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import { head, shuffle } from 'lodash';

import { randomNumber } from '~/lib/random-number';
import mediaHelper from '~/App/helpers/media-helper';

type Size = 'xsmall' | 'small' | 'mediumSmall' | 'medium' | 'large';

const COLORS = ['#95D4EE', '#AFCCAE'];

const SIZES: Record<Size, number> = {
  xsmall: 32,
  small: 48,
  mediumSmall: 56,
  medium: 128,
  large: 200
};

type WrapperProps = {
  color?: string;
  size: number;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size}px`};
  background-color: ${({ color }) => (color ? color : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

type LetterProps = {
  size: number;
};

const Letter = styled.div<LetterProps>`
  text-align: center;
  font-size: ${({ size }) => `${size / 2}px`};
  color: ${({ theme }) => theme.colors.darkBlue};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  line-height: ${({ size }) => `${size}px`};
  font-weight: bold;
`;

type Props = {
  user?: {
    imageUrl?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  };
  size?: Size;
  className?: string;
};

export function Avatar({ user = {}, size = 'medium', ...props }: Props) {
  const theme = useContext(ThemeContext);

  const [imageUrl, setImageUrl] = useState(user?.imageUrl);

  const handleLoadError = useCallback(() => setImageUrl(undefined), []);

  const letter = useMemo(
    () => user?.firstName?.slice(0, 1).toUpperCase() || 'A',
    [user]
  );

  const color = useMemo(() => {
    const randomColor = head(shuffle(COLORS));
    const { email, firstName, lastName } = user;

    const seed = firstName || lastName || email;

    if (!seed) {
      return randomColor;
    }

    const number = randomNumber(seed);
    const index = Math.floor(number * COLORS.length);

    return COLORS[index] || randomColor;
  }, [user]);

  useEffect(() => setImageUrl(user?.imageUrl), [user?.imageUrl]);

  if (imageUrl) {
    return (
      <Wrapper size={SIZES[size]} color={theme.colors.dust} {...props}>
        <Image
          onError={handleLoadError}
          src={mediaHelper.getUrl(imageUrl, {
            width: SIZES[size],
            height: SIZES[size],
            quality: 100,
            crop: 'fill',
            dpr: '2.0',
            radius: SIZES[size],
            fetch_format: 'png'
          })}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper size={SIZES[size]} color={color} {...props}>
      <Letter children={letter} size={SIZES[size]} />
    </Wrapper>
  );
}
