import React from 'react';
import classNames from 'classnames';

class Embed extends React.Component {
  constructor(props) {
    super(props);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      isVisible: false
    };
  }

  handleClose(event) {
    event.preventDefault();
    this.setState({ isVisible: false });
  }

  handleOpen(event) {
    event.preventDefault();
    this.setState({ isVisible: true });
  }

  render() {
    const { video: videoUrl = '', textColor = 'white' } = this.props;

    const videoClasses = classNames(
      'heroVideo__content',
      `heroVideo__content--${textColor}`
    );

    return (
      <div className="heroVideo">
        <div className={videoClasses} onClick={this.handleOpen}>
          <div className="heroVideo__button" />
          <div
            className="heroVideo__title"
            dangerouslySetInnerHTML={{ __html: this.props.heading }}
          />
          <div
            className="heroVideo__preamble heroVideo__preamble--long"
            dangerouslySetInnerHTML={{ __html: this.props.preamble }}
          />
          <div
            className="heroVideo__preamble heroVideo__preamble--short"
            dangerouslySetInnerHTML={{ __html: this.props.preambleShort }}
          />
        </div>
        {this.state.isVisible && (
          <div className="videoOverlay is-visible" onClick={this.handleClose}>
            <div className="videoOverlay__main">
              <div className="videoOverlay__content">
                <div className="ui-videoPlayer">
                  <iframe
                    id="heroVideo"
                    src={videoUrl}
                    frameBorder="0"
                    allowFullScreen="1"
                  />
                </div>
              </div>
            </div>
            <a
              href="#"
              className="videoOverlay__close"
              onClick={this.handleClose}
            />
            <div className="videoOverlay__backdrop" />
          </div>
        )}
      </div>
    );
  }
}

export default Embed;
