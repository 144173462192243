import React, { useCallback, MouseEvent, useEffect, ReactNode } from 'react';
import styled from 'styled-components';

// Config
import { buttonStyles } from '~/App/config/buttonStyles';

// Helpers
import mq from '~/App/helpers/mq';
import { closeLink } from '~/App/helpers/mixins';
import { slideUp } from '~/App/helpers/animations';

// Components
import { PrimaryButton } from '~/App/shared/components/Elements';
import { useScrollLock } from '~/App/contexts/ScrollLock';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 102;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);

  ${mq('≥small')`
    justify-content: center;
  `};
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 625px;
  max-height: calc(100% - 1rem);
  margin: 8px 0 0;
  padding: 1rem 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  animation: ${slideUp} 1 0.2s cubic-bezier(0.2, 0.4, 0.3, 1);

  ${mq('≥small')`
    padding: 1rem;
    margin: 3rem;
    max-height: calc(100% - 6rem + 1px);
    border-radius: 4px;
    padding: 1rem 1rem 1.5rem;
  `};
`;

const PreviewWrapper = styled.div`
  margin-bottom: 1rem;
  width: calc(100% + 2rem);
  overflow: auto;
  border-bottom: 2px solid
    ${({ theme }) => theme.themeColors.accentedBackgroundColor};

  ${mq('≥small')`
    margin: 1rem 0 1.5rem;
    border-top: 2px solid
      ${({ theme }) => theme.themeColors.accentedBackgroundColor};
  `};
`;

const CloseModal = styled.a`
  display: none;

  ${mq('≥small')`
    ${closeLink}
    align-self: flex-end;
    margin-top: 0;
  `};
`;

const FullWidthButton = styled(PrimaryButton)`
  align-self: center;
`;

type Props = {
  closeModal: (
    event?: MouseEvent<HTMLElement> | undefined,
    buttonText?: string | undefined
  ) => void;
  children: ReactNode;
  bottomSection?: ReactNode;
  showBottomSection?: boolean;
};

export function PreviewModal({
  closeModal,
  children,
  showBottomSection = false,
  bottomSection
}: Props) {
  const scrollLock = useScrollLock();
  const handleClose = useCallback(
    (event?: MouseEvent<HTMLElement>, buttonText?: string | undefined) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      closeModal(event, buttonText);
    },
    [closeModal]
  );

  useEffect(() => scrollLock.lock(), [scrollLock]);

  return (
    <Overlay onClick={handleClose}>
      <Modal onClick={(event) => event.stopPropagation()}>
        <CloseModal
          onClick={(event: MouseEvent<HTMLElement>) =>
            handleClose(event, 'stäng')
          }
          children="Stäng"
        />
        <PreviewWrapper children={children} />
        {showBottomSection ? (
          <>{bottomSection}</>
        ) : (
          <FullWidthButton
            buttonType="button"
            type="button"
            buttonStyle={buttonStyles.primary}
            children="Stäng förhandsgranskning"
            onClick={handleClose}
          />
        )}
      </Modal>
    </Overlay>
  );
}
