import React, { useMemo } from 'react';

import Select from '~/App/shared/components/Fields/Select';
import { InputWrapper } from '~/App/shared/components/Fields/InputWrapper';
import { IValidation } from '~/App/shared/components/Validation';
import {
  ContactFields,
  TabbedContactFields
} from '../../Forms/components/ContactFields';
import { ContactCustomerValues, PaymentValues } from '../../States';
import styled from 'styled-components';
import { usePreferredCustomerContactType } from '~/App/shared/hooks/use-preferred-customer-contact-type';

const SelectInputWrapper = styled(InputWrapper)`
  margin-bottom: 1rem;
`;

type Props = {
  validation: IValidation;
  values: ContactCustomerValues & PaymentValues;
  contactFieldsType?: 'radio' | 'tabs';
};

export function AutoGiroSlipFields({
  values,
  validation,
  contactFieldsType
}: Props) {
  usePreferredCustomerContactType({
    preferred: 'manual',
    values
  });

  const SelectedContactFields =
    contactFieldsType === 'tabs' ? TabbedContactFields : ContactFields;

  return useMemo(
    () => (
      <div>
        <SelectInputWrapper>
          <Select
            name="deliverPaper"
            value={values.directDebit.deliverPaper}
            onBlur={validation.handleBlur.bind(
              undefined,
              'directDebit.deliverPaper'
            )}
            errors={validation.errors['directDebit.deliverPaper']}
            options={[
              {
                value: 'send',
                label: 'Blankett skickas hem'
              },
              {
                value: 'print',
                label: 'Skriv ut själv'
              }
            ]}
            isValid={validation.isValid('directDebit.deliverPaper')}
            onChange={values.handlers.handleDirectDebitDeliverPaperChange}
            isInvalid={validation.isInvalid('directDebit.deliverPaper')}
            placeholder="Välj leveranssätt"
            required={true}
          />
        </SelectInputWrapper>
        <InputWrapper>
          <SelectedContactFields
            values={values}
            validation={validation}
            taxReductionInfo="#skatteavdrag-for-gavor"
            manual
            automatic
          />
        </InputWrapper>
      </div>
    ),
    [SelectedContactFields, validation, values]
  );
}
