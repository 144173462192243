import React from 'react';
import { ISubmit } from '../components/Submits';
import Redirect from '~/App/shared/components/Redirect';

type Props = {
  submit: ISubmit;
};
export default function SwishRedirect({ submit }: Props) {

  if (submit.swishToken) {
    return (
      <>
        <Redirect
          to={`swish://paymentrequest?token=${submit.swishToken}`}
          external
        />
      </>
    );
  }
  return null;
}
