import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { getReceiptNameWithPronoun } from '~/App/helpers/purchase-helper';
import { heading2, preamble } from '~/App/helpers/mixins';

// Shared components
import {
  Wrapper,
  ImageWrapper,
  ProductImage,
  Content
} from '~/App/shared/components/ThanksPage';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const PreambleWithMargin = styled(Preamble)`
  margin-bottom: 1rem;
`;

const DEFAULT_IMAGE =
  '//res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png';

export function DonationThanksHeader({ purchase, data, isLargeDonation }) {
  const receiptNameWithPronoun = getReceiptNameWithPronoun(
    purchase.paymentMethod.id
  );

  const receiptText = useCallback(
    (email) =>
      data?.texts?.receiptText
        ?.replace('%%email%%', email)
        .replace('%%receipt_name%%', receiptNameWithPronoun) ||
      `Vi har skickat ${receiptNameWithPronoun} till din e-post.`,
    [data?.texts?.receiptText, receiptNameWithPronoun]
  );

  const image = useMemo(
    () =>
      mediaHelper.getUrl(data?.headingImage || DEFAULT_IMAGE, {
        transformation: [
          {
            width: 280,
            height: 280,
            crop: 'lpad',
            gravity: 'center'
          },
          {
            width: 92,
            height: 92,
            dpr: '2.0',
            crop: 'pad',
            quality: 100
          }
        ]
      }),
    [data?.headingImage]
  );

  return (
    <Wrapper>
      <ImageWrapper>
        <ProductImage src={image} />
      </ImageWrapper>
      <Content>
        <Heading1
          children={
            isLargeDonation
              ? 'Ett stort varmt tack för din gåva!'
              : 'Tack, din gåva gör skillnad!'
          }
        />
        {isLargeDonation && (
          <PreambleWithMargin>
            Det är bidrag som ditt som verkligen gör skillnad. Och som gör att
            vi kan finansiera den främsta cancerforskningen i Sverige.
            Tillsammans räddar vi liv!
          </PreambleWithMargin>
        )}
        <FetchPurchaseDocuments purchase={purchase}>
          {({ receiptStatus, receipt, purchase }) => (
            <>
              <Preamble
                children={receiptText(
                  purchase.purchase.productOptions.customerContact.email
                )}
              />
              <AsyncReceiptDocumentLink
                url={receipt}
                status={receiptStatus}
                paymentMethodId={purchase?.paymentMethod?.id}
              />
            </>
          )}
        </FetchPurchaseDocuments>
      </Content>
    </Wrapper>
  );
}
