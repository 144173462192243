import _ from 'lodash';

export function isChartJs(config) {
  return (
    config &&
    _.isObject(config.data) &&
    _.isArray(config.data.labels) &&
    _.isArray(config.data.datasets)
  );
}

export function isAmCharts(config) {
  return config && config.type && _.isArray(config.data);
}
