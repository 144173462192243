import React, { useMemo } from 'react';
import { find } from 'lodash';

// Constants
import { paymentMethods } from '~/App/config/paymentMethods';
import { withRouter } from '~/App/hocs/with-router';

// Shared components
import Validation from '~/App/shared/components/Validation';

// Local components
import { Submit } from '../components/Submits';

import PaymentRedirect from '../components/PaymentRedirect';

import { CompanyPackageForm } from '../components/Forms';
import { State, MultiPartState } from '../components/States';

import { buildSchema } from './schema';
import { buildState } from './state';

function CompanyPackage(props) {
  const { data: formContent } = props;

  const schema = useMemo(() => buildSchema(props), [props]);
  const state = useMemo(() => buildState(props), [props]);

  const paymentMethodCreditCardAvailable = useMemo(
    () =>
      find(formContent.availablePaymentMethods, {
        id: paymentMethods.creditCard
      }),
    [formContent]
  );

  return (
    <State formContent={formContent} values={state}>
      {(values) => (
        <Validation
          schema={schema}
          values={values}
          hasStripe={paymentMethodCreditCardAvailable}
        >
          {(validation) => (
            <MultiPartState values={values} validation={validation}>
              {(multiPartValues) => (
                <Submit values={multiPartValues} validation={validation}>
                  {(submit) => (
                    <div>
                      <PaymentRedirect
                        submit={submit}
                        redirectPath={values.redirectPath}
                      />
                      <CompanyPackageForm
                        values={multiPartValues}
                        submit={submit}
                        validation={validation}
                        formContent={formContent}
                      />
                    </div>
                  )}
                </Submit>
              )}
            </MultiPartState>
          )}
        </Validation>
      )}
    </State>
  );
}

export default withRouter(CompanyPackage);
