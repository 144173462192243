import React from 'react';
import styled from 'styled-components';

// Helpers
import { link } from '~/App/helpers/mixins';

// Shared components
import { InfoText } from './FormTypography';

const Link = styled.a`
  ${link};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const PaddedInfoText = styled(InfoText)`
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 1rem;
  margin-bottom: 1.625rem;
`;

const INTEGRITY_TEXTS: Record<Variant, string[]> = {
  standard: [
    'Genom att gå vidare samtycker jag till att mina personuppgifter behandlas i enlighet med ',
    'Cancerfondens integritetspolicy',
    '.'
  ],
  monthlyDonationCard: [
    'Genom att gå vidare samtycker jag till att mina personuppgifter behandlas i enlighet med ',
    'Cancerfondens integritetspolicy',
    ' och att mina kortuppgifter får sparas så att gåvan kan dras varje månad på det belopp jag har valt.'
  ],
  billectaPaymentTerms: [
    'Vidare godkänner jag att en kontoinformationstjänst tillhandahålls i enlighet med ',
    'Billecta Payments användarvillkor',
    '.'
  ]
};

type Variant = 'standard' | 'monthlyDonationCard' | 'billectaPaymentTerms';

type Props = {
  anchor?: string;
  variant?: Variant;
  className?: string;
};

export function IntegrityText({
  variant = 'standard',
  anchor = '',
  ...props
}: Props) {
  const href =
    variant === 'billectaPaymentTerms'
      ? 'https://app.billecta.com/ht/accountinformationservices'
      : `/om-oss/personlig-integritet-och-cookies${anchor}`;

  return (
    <PaddedInfoText {...props}>
      {INTEGRITY_TEXTS[variant][0]}
      <Link target="_blank" href={`${href}`}>
        {INTEGRITY_TEXTS[variant][1]}
      </Link>
      {INTEGRITY_TEXTS[variant][2]}
    </PaddedInfoText>
  );
}
