import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useAppContext } from '~/App/contexts/App';

type ShorthandBlock = {
  id: string;
  shorthandUrl: string;
  stickyNavigation: boolean;
};

type Props = ShorthandBlock;

declare global {
  interface Window {
    Shorthand?: {
      embed?: (selector: string, url?: string) => void;
      remove?: () => void;
    };
  }
}

const Container = styled.div``;

export function Shorthand({
  id,
  shorthandUrl,
  stickyNavigation = true
}: Props) {
  const appContext = useAppContext();
  const ref = useRef<HTMLDivElement | null>(null);

  const initShorthand = useCallback(() => {
    let url = shorthandUrl;
    if (url.indexOf('http') === -1) url = 'https://' + url;

    const script = document.createElement('script');
    script.src = url.replace(/\/?$/, '/embed.js');
    ref.current?.insertAdjacentElement('beforebegin', script);
  }, [shorthandUrl, ref]);

  useEffect(() => {
    initShorthand();
    return () => {
      if (window?.Shorthand?.remove) window?.Shorthand?.remove();
    };
  }, [initShorthand]);

  useEffect(() => {
    if (stickyNavigation) {
      return;
    }

    appContext.setStickyNavigation(false);

    return () => {
      appContext.setStickyNavigation(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyNavigation]);

  return <Container ref={ref} id={`shorthand-${id}`} />;
}
