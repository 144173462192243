import { StockStatus } from '~/types/ICommerceVariant';

export const stockStatus = (stock: StockStatus | undefined) => {
  if (stock === 'InStock') {
    return 'I lager';
  } else if (stock === 'FewInStock') {
    return 'Få i lager';
  } else if (stock === 'OutOfStock') {
    return 'Tillfälligt slut';
  } else return '';
};
