import React from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';

import { ICommerceVariant } from '~/types/ICommerceVariant';

import * as TwoColumn from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import { CertificateSummary } from './CertificateSummary';
import { ICommerceProduct } from '~/types/ICommerceProduct';

const Wrapper = styled(TwoColumn.RightColumn)`
  ${mq('<medium')`
    display: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 1rem;
  `};
`;

const Card = styled(TwoColumn.ColumnCard)`
  ${mq('<medium')`
     padding: 1rem;
     position: relative;
     max-height: 13rem;
     overflow: hidden;
  `};
`;

const Image = styled.img`
  width: 100%;
`;

type Props = {
  variant: ICommerceVariant;
  product: ICommerceProduct;
  toggleModal: () => void;
};

export function RightColumn({ variant, product, toggleModal }: Props) {
  if (product?.type === 'DigitalCertificate') {
    return (
      <Wrapper>
        <Card>
          {variant.discontinued ? (
            <Image src={variant.primaryImage} />
          ) : (
            <CertificateSummary variant={variant} toggleModal={toggleModal} />
          )}
        </Card>
      </Wrapper>
    );
  }

  if (product?.type === 'Physical' && variant.primaryImage) {
    return (
      <Wrapper>
        <Card>
          <Image src={variant.primaryImage} />
        </Card>
      </Wrapper>
    );
  }

  return <Wrapper />;
}
