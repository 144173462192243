import React from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';

import Link from '~/App/shared/components/Link';

const Container = styled.div`
  font-size: 0.75rem;
  padding: 0 1rem 0;
  text-align: center;

  ${mq('≥small')`
  padding: 1rem 2rem;
  font-size: 0.875rem;
  text-align: left;
  background-color: ${({ theme }) => theme.themeColors.primaryBackgroundColor};
`};
`;

const Text = styled.div`
  max-width: 500px;
  margin: auto;
`;

export function Footer() {
  return (
    <Container>
      <Text>
        Jag samtycker till att mina personuppgifter behandlas enligt{' '}
        <Link to={`/om-oss/personlig-integritet-och-cookies`}>
          Cancerfondens integritetspolicy.
        </Link>
      </Text>
    </Container>
  );
}
