import React, { ComponentProps, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';
import { ICollection } from '~/types/ICollection';

import { Footer } from './Footer';
import { Hero, HeroVariant } from './Hero';

type WrapperProps = {
  alternativeBackground?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  flex-grow: 1;
  padding: 1rem;
  background-color: ${({ alternativeBackground, theme }) =>
    alternativeBackground
      ? theme.themeColors.primaryBackgroundColor
      : theme.colors.white};

  ${mq('≥mediumLarge')`
      padding: 2rem;
      overflow: auto;
    `};
`;

type Props = {
  hero?: HeroVariant | ICollection;
  footer?: boolean;
  children: ReactNode;
  alternativeBackground?: boolean;
};

export function ViewWrapper({ hero, footer, ...props }: Props) {
  const heroProps = useMemo<ComponentProps<typeof Hero>>(
    () =>
      typeof hero === 'string'
        ? { variant: hero as HeroVariant }
        : { collection: hero },
    [hero]
  );

  return (
    <>
      {hero && <Hero {...heroProps} />}
      <Wrapper {...props} />
      {footer && <Footer />}
    </>
  );
}
