import { paymentMethods } from '~/App/config/paymentMethods';
import { swishTypes } from '~/App/config/swishTypes';

import { CreateMemoryStateValues } from './hooks/useCreateMemoryState';

const onlyCompany = (_: unknown, values: CreateMemoryStateValues) => {
  return values.customerContactType === 'company';
};

const manualOrPaymentSlip = (_: unknown, values: CreateMemoryStateValues) => {
  return (
    values.customerContactType === 'manual' ||
    values.paymentMethod?.id === paymentMethods.paymentSlip
  );
};

export function buildSchema(values: CreateMemoryStateValues) {
  if (!values.productOptions.memory.period) {
    return {
      isAuthenticated: {
        inclusion: [true]
      },
    'productOptions.memory.content': {
      presence: {
        allowEmpty: false,
        message: '^Hälsningen måste vara minst 3 tecken'
      },
      length: {
        minimum: 3,
        message: '^Hälsningen måste vara minst 3 tecken'
      }
    }
    };
  }

  return {
    isAuthenticated: {
      inclusion: [true]
    },
    'product.id': {
      presence: {
        allowEmpty: false,
        message: '^Ange ett alternativ'
      },
      inclusion: [3, 31]
    },
    'productOptions.memory.content': {
      presence: {
        allowEmpty: false,
        message: '^Hälsningen måste vara minst 3 tecken'
      },
      length: {
        minimum: 3,
        message: '^Hälsningen måste vara minst 3 tecken'
      }
    },
    'productOptions.product.price': {
      presence: {
        allowEmpty: false,
        message: '^Ange ett belopp'
      },
      numericality: {
        greaterThan: 49,
        lessThanOrEqualTo: 999999,
        message: '^Minsta gåvobelopp är 50 kr'
      }
    },
    'productOptions.customerContact.email': {
      validateEmail: (
        _: unknown,
        { paymentMethod }: CreateMemoryStateValues
      ) => {
        return paymentMethod?.id !== paymentMethods.klarnaPayments;
      }
    },
    'productOptions.customerContact.cid': {
      validateCid: onlyCompany
    },
    'productOptions.customerContact.company': {
      validateCompany: onlyCompany
    },
    'productOptions.customerContact.firstName': {
      validateFirstName: manualOrPaymentSlip
    },
    'productOptions.customerContact.lastName': {
      validateLastName: manualOrPaymentSlip
    },
    'productOptions.customerContact.addressStreet': {
      validateStreetName: manualOrPaymentSlip
    },
    'productOptions.customerContact.addressZip': {
      validateZipCode: manualOrPaymentSlip
    },
    'productOptions.customerContact.addressCity': {
      validateCity: manualOrPaymentSlip
    },
    'productOptions.customerContact.phoneNumber': {
      validatePhoneNumber: onlyCompany
    },
    'klarna.isReady': {
      inclusion: (_: unknown, { paymentMethod }: CreateMemoryStateValues) => {
        if (paymentMethod?.id === paymentMethods.klarnaPayments) {
          return {
            within: [true],
            message: '^Klarna är inte redo'
          };
        }

        return false;
      }
    },
    swishPhoneNumber: {
      validatePhoneNumber: (
        _: unknown,
        { paymentMethod, swish }: CreateMemoryStateValues
      ) => {
        if (paymentMethod?.id !== 8) {
          return false;
        }

        if (swish.swishType === swishTypes.mcommerce) {
          return false;
        }

        return {
          message: '^Formatet för telefonnummer är 070XXXXXXX'
        };
      }
    },
    'productOptions.customerContact.ssn': {
      validateSsn: (
        _: unknown,
        { paymentMethod, customerContactType }: CreateMemoryStateValues
      ) => {
        if (paymentMethod?.id === paymentMethods.klarnaPayments) {
          return false;
        }

        if (paymentMethod?.id === paymentMethods.paymentSlip) {
          return {
            allowEmpty: true
          };
        }

        return (
          customerContactType === 'automatic' ||
          paymentMethod?.id === paymentMethods.bankID ||
          paymentMethod?.id === paymentMethods.autoGiroBankId
        );
      },
      validateLegalAge: (
        _: unknown,
        { paymentMethod, customerContactType }: CreateMemoryStateValues
      ) => {
        if (paymentMethod?.id === paymentMethods.klarnaPayments) {
          return false;
        }

        return (
          customerContactType === 'automatic' ||
          paymentMethod?.id === paymentMethods.bankID ||
          paymentMethod?.id === paymentMethods.autoGiroBankId
        );
      }
    },
    'mobileBankId.bank': {
      validateBank: (_: unknown, { paymentMethod }: CreateMemoryStateValues) =>
        paymentMethod?.id === paymentMethods.bankID ||
        paymentMethod?.id === paymentMethods.autoGiroBankId
    },
    'mobileBankId.accountNumber': {
      validateAccountNumber: (
        _: unknown,
        { paymentMethod }: CreateMemoryStateValues
      ) =>
        paymentMethod?.id === paymentMethods.bankID ||
        paymentMethod?.id === paymentMethods.autoGiroBankId
    },
    'mobileBankId.clearingNumber': {
      validateClearingNumber: (
        _: unknown,
        { paymentMethod }: CreateMemoryStateValues
      ) =>
        paymentMethod?.id === paymentMethods.bankID ||
        paymentMethod?.id === paymentMethods.autoGiroBankId
    },
    'directDebit.deliverPaper': {
      validateDeliverPaper: (
        _: unknown,
        { paymentMethod }: CreateMemoryStateValues
      ) => paymentMethod?.id === paymentMethods.autoGiroSlip
    }
  };
}
