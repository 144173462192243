import React, { useMemo } from 'react';
import styled from 'styled-components';

import { BackgroundColor } from '~/types/Block';
import { RelatedPageItem } from '~/types/RelatedBlock';

// Helpers
import mq from '~/App/helpers/mq';
import { heading2, heading3, primaryButton } from '~/App/helpers/mixins';

import Link from '~/App/shared/components/Link';
import { HtmlContent } from '~/App/shared/components/HtmlContent';

type WrapperProps = {
  backgroundColor: BackgroundColor;
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem;
  border-radius: 2px;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === 'none'
      ? theme.packageCard.alternateBackgroundColor
      : theme.packageCard.backgroundColor};

  ${mq('≥small')`
    padding: 2rem;
  `};
`;

const Legend = styled.div`
  ${heading3};
  color: ${({ theme }) => theme.packageCard.legendColor};
  text-align: center;
`;

const Heading = styled.h3`
  ${heading2};
  color: ${({ theme }) => theme.packageCard.legendColor};
  margin-top: 0.5rem;
  text-align: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Button = styled(Link)`
  ${primaryButton};
  display: block;
  width: 100%;
  min-width: 0;
  max-width: 200px;
  text-align: center;

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

const Preamble = styled.div`
  margin: 1rem 0 1.5rem;
  padding: 1.5rem 0;
  border-top: 1px solid ${({ theme }) => theme.packageCard.borderColor};
  border-bottom: 1px solid ${({ theme }) => theme.packageCard.borderColor};
  flex-grow: 1;

  ${mq('≥small')`
    margin: 1.5rem 0 2rem;
    padding: 2rem 0;
    font-size: 1.125rem;
  `};
`;

type Props = {
  item: RelatedPageItem;
  backgroundColor: BackgroundColor;
};

export function PackageCard({ item, backgroundColor }: Props) {
  const slug = item.slug;
  const heading = item.preview?.heading;
  const preamble = item.preview?.preamble;
  const legend = item.preview?.legend;
  const linkText = item.preview?.linkText;
  const contentType = item.contentType;

  return useMemo(
    () => (
      <Wrapper backgroundColor={backgroundColor}>
        {legend && <Legend>{legend}</Legend>}
        {heading && <Heading>{heading}</Heading>}
        {preamble && (
          <Preamble>
            <HtmlContent dangerouslySetInnerHTML={{ __html: preamble }} />
          </Preamble>
        )}
        <Footer>
          <Button to={{ slug, contentType }}>{linkText}</Button>
        </Footer>
      </Wrapper>
    ),
    [backgroundColor, contentType, heading, legend, linkText, preamble, slug]
  );
}
