import { withOverwrittenView } from '~/App/hocs/with-overwritten-view';

import { loadData } from './CommerceProduct.LoadData';
import { CommerceProduct } from './CommerceProduct';
import CmsProduct from '../CmsProduct';

export default withOverwrittenView(
  {
    Component: CommerceProduct,
    loadData
  },
  {
    Component: CmsProduct,
    loadData: CmsProduct.loadData
  }
);
