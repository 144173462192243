import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { card, heading4, arrowLink } from '~/App/helpers/mixins';

const Card = styled.div`
  ${card};
  text-align: center;
`;

const Heading = styled.h4`
  ${heading4};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 1rem;
`;

const Link = styled(NavLink)`
  ${arrowLink};
`;

export function EmptyState() {
  return (
    <Card>
      <Heading>Här kommer du kunna se dina beställningar.</Heading>
      <Link to="/gavoshop/privat/">Handla i butiken</Link>
    </Card>
  );
}
