import _ from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';

// Helpers
import { siteUrl } from '~/App/helpers/env';

import Section from '~/App/shared/components/Section';
import ThreeUpArticleFeed from '~/App/shared/components/ThreeUpArticleFeed';
import ContentDefinitionHero from '~/App/shared/components/ContentDefinitionHero';
import { useLocation } from 'react-router';

export function Articles({ data = {} }) {
  const location = useLocation();
  const {
    contentDefinitions: { meta = {}, viewArticlesFeed = {} } = {}
  } = ({} = data);

  return (
    <div>
      <Helmet>
        <title>{meta.pageTitle}</title>
        <link rel="canonical" href={siteUrl(location.pathname)} />
        <meta name="description" content={meta.pageDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:image" content={meta.ogImage} />
        <meta property="og:description" content={meta.ogDescription} />
      </Helmet>
      <ContentDefinitionHero {...viewArticlesFeed} />
      <Section>
        <ThreeUpArticleFeed
          modifier="threeUpArticleFeed--slimTop"
          slug={_.get(viewArticlesFeed, 'slug', '')}
          related={_.get(viewArticlesFeed, 'related', {})}
          pagination={true}
        />
      </Section>
    </div>
  );
}
