import React, { useMemo } from 'react';
import styled from 'styled-components';
import { memorial } from '~/App/config/default-images';

// Helpers
import mq from '~/App/helpers/mq';
import DateField from '~/App/shared/components/Fields/Date';
import { ImageSelector } from '~/App/shared/components/Fields/ImageSelector';
import Text from '~/App/shared/components/Fields/Text';
import { IValidation } from '~/App/shared/components/Validation';

import { CreateMemorialPageStateValues } from '../hooks/useCreateMemorialPageState';
const SubHeading = styled.h2`
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  margin: 1rem 0 1rem;
  color: #4a4a4a;

  ${mq('<small')`
  font-size: 1.125rem;
`}
`;
const LastSubHeading = styled.h2`
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  margin: 1rem 0 0;
  color: #4a4a4a;

  ${mq('<small')`
  font-size: 1.125rem;
`}
`;

const PaddingWrapper = styled.div`
  padding-bottom: 1rem;
`;

type Props = {
  validation: IValidation;
  values: CreateMemorialPageStateValues;
};

export function MemorialPerson({ values, validation }: Props) {
  const todaysDate = useMemo(() => new Date(), []);
  const anyDateBeforeToday = useMemo(
    () =>
      new Date(
        todaysDate.getFullYear() - 150,
        todaysDate.getMonth(),
        todaysDate.getDay()
      ),
    [todaysDate]
  );

  return (
    <>
      <SubHeading>Vem vill du minnas?</SubHeading>
      <PaddingWrapper>
        <Text
          value={values.person.name}
          maxLength="50"
          placeholder="Personens namn"
          onBlur={validation.showError.bind(undefined, 'person.name')}
          errors={validation.errors['person.name']}
          isValid={validation.isValid('person.name')}
          isInvalid={validation.isInvalid('person.name')}
          onChange={values.handlers.handlePersonName}
          required
        />
      </PaddingWrapper>
      <SubHeading children={'Ange personens födelsedatum'} />
      <PaddingWrapper>
        <DateField
          value={values.person.birth}
          onChange={values.handlers.setPersonBirth}
          errors={validation.errors['person.birth']}
          isValid={validation.isValid('person.birth')}
          isInvalid={validation.isInvalid('person.birth')}
          max={values.person.death ? values.person.death : todaysDate}
          min={anyDateBeforeToday}
          placeholder={'Datum'}
          showMonthYearDropdown={true}
          required={true}
          onBlur={validation.showError.bind(undefined, 'person.birth')}
        />
      </PaddingWrapper>
      <PaddingWrapper>
        <SubHeading children={'Ange datumet då personen gick bort'} />
        <DateField
          value={values.person.death}
          onChange={values.handlers.setPersonDeath}
          errors={validation.errors['person.death']}
          isValid={validation.isValid('person.death')}
          isInvalid={validation.isInvalid('person.death')}
          max={todaysDate}
          min={values.person.birth ? values.person.birth : anyDateBeforeToday}
          placeholder={'Datum'}
          showMonthYearDropdown={true}
          onBlur={validation.showError.bind(undefined, 'person.death')}
        />
      </PaddingWrapper>
      <LastSubHeading>Ladda upp bild på personen</LastSubHeading>
      <ImageSelector
        defaultImages={memorial}
        onChange={values.handlers.setPersonImage}
        errors={[]}
        isInvalid={false}
        value={values.person.image}
      />
    </>
  );
}
