import React from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';

const NinetyImage = styled.picture`
  line-height: 0;
`;

const NinetyLogo =
  'https://res.cloudinary.com/cancerfonden/image/upload/v1540810606/assets/90_Konto_Logo_Neg.png';

const NinetyLogoNegative = () => (
  <div>
    <NinetyImage>
      <source
        srcSet={`
          ${mediaHelper.getUrl(NinetyLogo, {
            width: 280,
            height: 70,
            quality: 100,
            crop: 'pad',
            gravity: 'west'
          })} 2x,
          ${mediaHelper.getUrl(NinetyLogo, {
            width: 140,
            height: 35,
            quality: 100,
            crop: 'pad',
            gravity: 'west'
          })} 1x
        `}
      />
      <img src={mediaHelper.getUrl(NinetyLogo, { width: 140, height: 35 })} />
    </NinetyImage>
  </div>
);

export default NinetyLogoNegative;
