import React, { useMemo } from 'react';

// Shared components
import { ProductCertificatePreview } from './ProductCertificatePreview';
import { PreviewButton } from '~/App/shared/components/Donation/components/Forms/components/CertificatePreviews/components/PreviewButton';

import { AmountWrapper } from '~/App/shared/components/Donation/components/Forms/components/Summaries/components/AmountWrapper';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';

type Props = {
  previewFields: PreviewFields;
  priceType: string;
  openPreviewModal: () => void;
  product: {
    price: number | null;
    quantity: number | null;
  };
};

type PreviewFields = {
  imageUrl?: string;
  heading?: string;
  disclaimer?: string;
  description?: string;
  imageAlt?: string;
};

export function ProductCertificateSummary({
  previewFields,
  product: { price, quantity },
  priceType,
  openPreviewModal
}: Props) {
  return useMemo(
    () => (
      <div>
        <ProductCertificatePreview
          heading={previewFields.heading}
          disclaimer={previewFields.disclaimer}
          description={previewFields.description}
          imageUrl={previewFields.imageUrl}
        />
        <PreviewButton onClick={openPreviewModal}>
          Förhandsgranska gåvobevis
        </PreviewButton>
        {priceType === 'flexiblePrice' && (
          <AmountWrapper text="Gåvobelopp" amount={price} />
        )}
        {priceType === 'fixedPrice' && (
          <>
            <FormHeading>Totalt att betala</FormHeading>
            <AmountWrapper
              text={`${price} kr x ${quantity} st`}
              amount={price && quantity ? price * quantity : undefined}
            />
          </>
        )}
      </div>
    ),
    [
      openPreviewModal,
      previewFields.description,
      previewFields.disclaimer,
      previewFields.heading,
      previewFields.imageUrl,
      price,
      priceType,
      quantity
    ]
  );
}
