import React, { ReactNode, useMemo, useState } from "react";

import { ImageSelectorContextValue, ImageSelectorContext } from './Context'

type Props = {
  children: ReactNode
}

export function ImageSelectorProvider({ children }: Props) {

  const [image, setImage] = useState<string | undefined>();

  const values = useMemo<ImageSelectorContextValue>(() => ({
    image,
    setImage
  }), [image]);

  return (<ImageSelectorContext.Provider value={values} children={children} />)
}