import { IPaymentMethod } from '~/types/IPaymentMethod';
import { ParsedQuery } from '~/lib/query-string';

import { gtmDetails, gtmCustomDetails } from '~/App/config/gtmDetails';
import { CustomerContactType } from '~/App/config/customerContactTypes';

type Args = {
  query: ParsedQuery;
  data: {
    productId?: number;
    defaultPrice?: number;
    gtmId?: string;
    campaignActivityId?: number;
    defaultPaymentMethod?: IPaymentMethod;
    defaultCustomerContactType?: CustomerContactType;
  };
};

export function buildState({ data, query }: Args) {
  const { defaultPrice = 150 } = data;
  const PRODUCT_ID = data.productId || 3;
  const GTM_ID = data.gtmId;
  const gtmInfo = GTM_ID ? gtmCustomDetails[GTM_ID] : gtmDetails[PRODUCT_ID];
  const campaignActivityId =
    parseInt(`${query?.campaignActivityId}`, 10) || data.campaignActivityId;

  return {
    product: { id: PRODUCT_ID },
    productOptions: {
      product: { price: defaultPrice },
      campaignActivityId: campaignActivityId,
      pul: true
    },
    paymentMethod: data.defaultPaymentMethod,
    customerContactType: data.defaultCustomerContactType,
    partOptions: {
      parts: 2,
      validationKeys: {
        1: ['productOptions.product.price'],
        2: [
          'productOptions.customerContact.company',
          'productOptions.customerContact.cid',
          'productOptions.customerContact.addressStreet',
          'productOptions.customerContact.addressZip',
          'productOptions.customerContact.addressCity',
          'productOptions.customerContact.firstName',
          'productOptions.customerContact.lastName',
          'productOptions.customerContact.phoneNumber',
          'productOptions.customerContact.ssn',
          'productOptions.customerContact.email',
          'stripeCard',
          'swishPhoneNumber'
        ]
      }
    },
    gtm: gtmInfo
  };
}
