import React, { useCallback } from 'react';

import { useLoginModal } from '~/App/shared/hooks/use-login-modal';

import { Modal } from './Modal';
import { Overlay } from './Overlay';

export function Login() {
  const loginModal = useLoginModal();

  const handleLoginToggle = useCallback(
    (event) => {
      event.preventDefault();

      if (loginModal.showLoginModal) {
        return loginModal.handlers.closeModal();
      }

      loginModal.handlers.openModal({
        onSuccess: '/min-sida'
      });
    },
    [loginModal.handlers, loginModal.showLoginModal]
  );

  return (
    <>
      <Modal />
      <Overlay show={loginModal.showLoginModal} onClick={handleLoginToggle} />
    </>
  );
}
