import { DefaultTheme } from 'styled-components';

import colors from './colors';
import iconAssets from './iconAssets';

export default {
	themeColors: {
		primary: colors.christmasRed,
		primaryHover: colors.monza,
		accentedBackgroundColor: colors.smoke
	},
	primaryButton: {
		textColor: colors.white,
		disabledTextColor: colors.white,
		borderColor: colors.christmasRed,
		disabledBorderColor: colors.silverChalice,
		backgroundColor: colors.christmasRed,
		disabledBackgroundColor: colors.silverChalice,
		hoverTextColor: colors.white,
		hoverBackgroundColor: colors.monza,
		hoverBorderColor: colors.monza
	},
	packageCard: {
		legendColor: colors.christmasRed
	},
	iconAssets: {
		check: iconAssets.checkChristmas
	}
} as DefaultTheme;
