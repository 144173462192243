import { ComponentType } from 'react';
import { LoadData } from '~/types/routes';

import { Page } from '../views/Page/Page';
import { loadData } from '../views/Page/Page.LoadData';
import { withOverwrittenView } from './with-overwritten-view';

export function withOverrideLegacy(
  Component: ComponentType<any>,
  loadDataFunc: LoadData
) {
  return withOverwrittenView(
    { Component: Page, loadData },
    {
      Component,
      loadData: loadDataFunc
    }
  );
}
