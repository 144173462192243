import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';

import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { pushGTMEvent } from '~/App/helpers/gtm-helper';
import { link, primaryButton } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  z-index: 103;
  padding: 2rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  align-items: center;
  font-size: 1.125rem;

  ${mq('<mediumLarge')`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  `};
`;

const Link = styled.p`
  ${link};
  text-decoration: underline;
  font-size: 1.125rem;

  ${mq('<mediumLarge')`
    font-size: 1rem;
  `};
`;

const Button = styled.button`
  ${primaryButton};
  width: 14rem;

  ${mq('<mediumLarge')`
    width: auto;
    font-size: 1rem;
  `};
`;

type Props = {
  isValidated: boolean;
};

export function BottomNavigation({ isValidated }: Props) {
  const shoppingCart = useShoppingCart();
  const location = useLocation();
  const navigate = useNavigate();

  const handleCheckoutClick = useCallback(() => {
    pushGTMEvent({
      category: 'Varukorg',
      action: 'click: Till kassan',
      label: shoppingCart.items.map((name) => name.variant?.name).join(' | '),
      value: shoppingCart.totalPrice
    });
    shoppingCart.close();
    navigate('/gavoshop/kassa');
  }, [navigate, shoppingCart]);

  const handleCloseClick = useCallback(() => {
    if (!location.pathname.startsWith('/gavoshop')) {
      navigate('/gavoshop/privat');
    }

    shoppingCart.close();
  }, [navigate, location.pathname, shoppingCart]);

  return (
    <Navigation>
      <Link onClick={handleCloseClick}>Fortsätt handla</Link>
      <Button
        onClick={handleCheckoutClick}
        buttonStyle={isValidated ? 'cta' : 'disabled'}
        children="Till kassan"
        disabled={!isValidated}
      />
    </Navigation>
  );
}
