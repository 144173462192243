import React from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  ${mq('>small')`
    margin-right: 1.125rem;
  `}
`;

const Link = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.darkBlue};

  ${mq('≥small')`
    font-size: 1.125rem;
    line-height: 1.25rem;
  `};
`;

const Text = styled.div`
  font-size: 0.75rem;

  ${mq('>small')`
    font-size: 0.875rem;
  `}
`;

const Image = styled.img`
  min-height: 4.5rem;
  min-width: 4.5rem;
`;

type Props = {
  handlePreviewOpen: () => void;
};

export function HideCollectionProgressPreview({ handlePreviewOpen }: Props) {
  return (
    <Wrapper>
      <Image
        src={
          'https://res.cloudinary.com/cancerfonden/image/upload/v1637846768/minnesida/no-donation.svg'
        }
      />
      <Column>
        <Text children={'Insamlingen visas utan ikon om hur det går.'} />
        <Link onClick={handlePreviewOpen} children="Se exempel" />
      </Column>
    </Wrapper>
  );
}
