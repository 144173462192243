import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { card, heading3 } from '~/App/helpers/mixins';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { mq } from '~/lib/mq';

import { Form } from '../components/Form';
import { PictureUploader } from '../components/PictureUploader';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

const Card = styled.div`
  ${card}
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 350px;
`;

const Heading = styled.h3`
  ${heading3};
  margin-bottom: 1rem;
  text-align: center;
`;

const Text = styled.p`
  font-size: 1rem;
  word-break: break-word;
  margin-bottom: 1rem;
  text-align: center;

  ${mq('≥small')`
    margin-bottom: 1.5rem;
  `};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: auto;
  display: block;
`;

const UploaderWrapper = styled.div`
  margin-bottom: 1rem;
`;

type Values = {
  email: string;
};

export function Information() {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Record<string, string[]>>({});
  const [values, setValues] = useState<Values>({ email: '' });

  const { user, update } = useAuthenticationContext();

  const toggleOpen = useCallback(() => setOpen((state) => !state), []);
  const handleChange = useCallback((values: Values) => {
    setError({});
    setValues(values);
  }, []);

  const handleSubmit = useCallback(
    async (values: Values) => {
      setIsLoading(true);

      const response = await update(values);

      if (response.success) {
        setOpen(false);
      } else {
        setError(response.errors);
      }

      setIsLoading(false);
    },
    [update]
  );

  const children = useMemo(() => {
    if (isOpen) {
      return (
        <>
          <Form
            onSubmit={handleSubmit}
            onChange={handleChange}
            isLoading={isLoading}
            errors={error}
            values={values}
          />
          <StyledPrimaryButton
            buttonType="button"
            buttonStyle="outlinePrimary"
            onClick={toggleOpen}
            children="Avbryt"
          />
        </>
      );
    }

    return (
      <>
        <Text>{user?.email}</Text>
        <StyledPrimaryButton
          buttonType="button"
          buttonStyle="outlinePrimary"
          onClick={toggleOpen}
          children="Ändra e-post"
        />
      </>
    );
  }, [
    handleChange,
    handleSubmit,
    toggleOpen,
    isOpen,
    isLoading,
    error,
    values,
    user
  ]);

  return (
    <Card>
      <Wrapper>
        <UploaderWrapper>
          <PictureUploader />
        </UploaderWrapper>
        <Heading children={`${user?.firstName} ${user?.lastName}`} />
        {children}
      </Wrapper>
    </Card>
  );
}
