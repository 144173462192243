import React from 'react';
import styled from 'styled-components';
import { debounce, sortBy } from 'lodash';
import { stringify } from '~/lib/query-string';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import mq from '~/App/helpers/mq';
import { headingFont } from '~/App/helpers/mixins';
import { buttonStyles } from '~/App/config/buttonStyles';
import { withRouter } from '~/App/hocs/with-router';

// Shared components
import Container from '~/App/shared/components/Container';
import * as RadioButtonHorizontal from '~/App/shared/components/Fields/RadioButtonHorizontal/';
import CustomAmount from '~/App/shared/components/Donation/components/Forms/components/CustomAmount';
import Text from '~/App/shared/components/Fields/Text';
import { PrimaryButton } from '~/App/shared/components/Elements';
import Link from '~/App/shared/components/Link';
import { breakpoints } from '~/App/config/mediaBreakpoints';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Background = styled.div`
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  min-height: calc(100vh - 66px);
  overflow: hidden;

  display: flex;
  flex-direction: column;

  ${mq('≥large')`
    min-height: calc(100vh - 130px);
  `};

  background-image: url(${(props) =>
    mediaHelper.getUrl(props.smallUrl, { width: 830 })});

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    background-image: url(${(props) =>
      mediaHelper.getUrl(props.smallUrl, { width: 830, dpr: '2.0' })});
  }

  @media only screen and (min-width: 480px) {
    background-image: url(${(props) =>
      mediaHelper.getUrl(props.smallUrl, { width: 1080 })});
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 480px),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 480px),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 480px),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 480px),
    only screen and (min-resolution: 192dpi) and (min-width: 480px),
    only screen and (min-resolution: 2dppx) and (min-width: 480px) {
    background-image: url(${(props) =>
      mediaHelper.getUrl(props.smallUrl, { width: 1080, dpr: '2.0' })});
  }

  @media only screen and (min-width: 768px) {
    background-image: url(${(props) =>
      mediaHelper.getUrl(props.smallUrl, { width: 1080 })});
  }

  @media only screen and (min-width: 768px) and (orientation: landscape) {
    background-image: url(${(props) =>
      mediaHelper.getUrl(props.largeUrl, { width: 1920, dpr: '2.0' })});
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 768px),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 768px),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 768px),
    only screen and (min-resolution: 192dpi) and (min-width: 768px),
    only screen and (min-resolution: 2dppx) and (min-width: 768px) {
    background-image: url(${(props) =>
      mediaHelper.getUrl(props.smallUrl, { width: 1080, dpr: '2.0' })});
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: landscape),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: landscape),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 768px) and (orientation: landscape),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 768px) and (orientation: landscape),
    only screen and (min-resolution: 192dpi) and (min-width: 768px) and (orientation: landscape),
    only screen and (min-resolution: 2dppx) and (min-width: 768px) and (orientation: landscape) {
    background-image: url(${(props) =>
      mediaHelper.getUrl(props.largeUrl, { width: 1920, dpr: '2.0' })});
  }
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${({ showVideoWrapper }) => (showVideoWrapper ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const VideoPlayer = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
`;

const Container2 = styled(Container)`
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  ${mq('≥tiny')`
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    margin-bottom: ${(props) => (props.bottomMargin === true ? '8.5rem' : '0')};
  `};
`;

const Container3 = styled(Container)`
  position: relative;
  padding-top: 0;
  padding-bottom: 2.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq('≥tiny')`
    align-items: flex-start;
    padding-bottom: 3.75rem;
  `};
`;

const CustomAmountWrapper = styled.div`
  margin: 0.5rem -0.25rem 0.25rem 0.25rem;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.lightDust};
  border-radius: 4px;
  background-color: ${(props) => props.theme.themeColors.fadedPrimary};
`;

const RadioButtonHorizontalWrapper = styled(RadioButtonHorizontal.Wrapper)`
  justify-content: space-between;
`;

const RadioButtonHorizontalItem = styled(RadioButtonHorizontal.Item)`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`;

const FieldWrapper = styled.div`
  margin-bottom: 1rem;

  ${mq('≥medium')`
    margin-bottom: 1rem;
  `};
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 100%;
  position: relative;

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0) 50%
    );
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }

  ${mq('≥medium')`
    padding-top: 0;
    align-items: center;
    width: 70%;
    position: relative;

    &::before {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(2, 2, 2, 0.6) 25%, rgba(0, 0, 0, 0) 70%);
    }
  `};

  ${mq('≥large')`
    width: 50%;

    &::before {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(2, 2, 2, 0.6) 40%, rgba(0, 0, 0, 0) 100%);
    }
  `};
`;

const SubTitle = styled.p`
  ${headingFont};
  color: ${(props) => props.theme.colors.white};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 1.3125rem;

  ${mq('≥medium')`
    font-size: 1.25rem;
    line-height: 1.6875rem;
  `};
`;

const Title = styled.h1`
  ${headingFont};
  color: ${(props) => props.theme.colors.white};
  font-size: 2.1875rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 2.875rem;
  margin-bottom: 0;

  ${mq('≥medium')`
    font-size: 3.25rem;
    line-height: 4.3125rem;
    margin-bottom: 1rem;
  `};
`;

const PricePickerTitle = styled.h2`
  ${headingFont};
  color: ${(props) => props.theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1rem;
  line-height: 1.3125rem;
  margin-bottom: 0.5rem;
  margin-top: 0.7rem;

  ${mq('≥medium')`
    font-size: 1.125rem;
    line-height: 1.5rem;
  `};
`;

const DonorTitle = styled.h2`
  ${headingFont};
  color: ${(props) => props.theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`;

const CustomAmountTitle = styled.h3`
  color: ${(props) => props.theme.colors.black};
  font-size: 1rem;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
`;

const ButtonWrapper = styled.div`
  ${mq('<tiny')`
    margin-top: 0.5rem;
  `};

  ${mq('<medium')`
    text-align: center;
  `};
`;

const WhiteLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;
  display: inline-block;
  margin: 0 auto;

  ${mq('≥medium')`
    display: none;
  `};
`;

const HiddenBelowMedium = styled.div`
  ${mq('<medium')`
    display: none;
  `};
`;

class MonthlyDonationForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleResize = debounce(this.handleResize.bind(this), 250);
    this.setProductPrice = this.setProductPrice.bind(this);
    this.resetProductPrice = this.resetProductPrice.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.vidRef = React.createRef();

    this.state = {
      backgroundStyle: {},
      price: props.data.defaultPrice || '150',
      priceIsInvalid: false,
      priceIsValid: false,
      priceErrors: [],
      showVideoWrapper: false,
      resized: false
    };
  }

  componentDidMount() {
    const mediaQueryLarge = window.matchMedia('(min-width: 75em)');

    if (mediaQueryLarge.matches) {
      this.playVideo();
    } else {
      this.pauseVideo();
    }

    mediaQueryLarge.addListener((event) => {
      event.matches ? this.playVideo() : this.pauseVideo();
    });

    window.addEventListener('resize', this.handleResize);

    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  playVideo() {
    if (this.vidRef.current) {
      this.vidRef.current.play();
      this.setState({ showVideoWrapper: true });
    }
  }

  pauseVideo() {
    if (this.vidRef.current) {
      this.vidRef.current.pause();
      this.setState({ showVideoWrapper: false });
    }
  }

  handleResize() {
    // Breakpoint tiny
    if (window.innerWidth < 480 && this.state.resized) {
      return;
    }

    const height = window.innerHeight;
    const headerHeight = document.querySelector('#headerContainer')
      .offsetHeight;

    return this.setState({
      backgroundStyle: {
        height: 'auto',
        minHeight: `${height - headerHeight}px`
      },
      resized: true
    });
  }

  setProductPrice(price) {
    this.setState({ price });
  }

  resetProductPrice() {
    this.setState({ price: '' });
  }

  selectVideo(backgrounds) {
    let selectedVideo = '';
    const now = new Date();
    const chronologicalVideos = sortBy(backgrounds, 'startTime');

    chronologicalVideos.forEach((video) => {
      const videoTime = new Date(video.startTime);
      if (videoTime < now) {
        selectedVideo = video;
      }
    });

    return selectedVideo;
  }

  selectBackground(backgrounds) {
    let selectedBackground = '';
    const now = new Date();
    const chronologicalBackgrounds = sortBy(backgrounds, 'startTime');

    chronologicalBackgrounds.forEach((background) => {
      const backgroundTime = new Date(background.startTime);
      if (backgroundTime < now) {
        selectedBackground = background;
      }
    });

    return selectedBackground;
  }

  handleBlur() {
    const { price } = this.state;
    const minimumPrice = 50;

    if (price < minimumPrice) {
      this.setPriceIsInvalid();
    } else {
      this.setPriceIsValid();
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const { price } = this.state;
    const { donationFormPage, skipTo = null } = this.props.data;

    const minimumPrice = 50;

    if (price < minimumPrice) {
      this.setPriceIsInvalid();
    } else {
      this.setPriceIsValid();

      const baseQuery = {
        price: price
      };
      const redirectQuery = skipTo
        ? { ...baseQuery, skipTo: skipTo }
        : { ...baseQuery };

      this.props.history.push(
        `${donationFormPage}?${stringify(redirectQuery)}`
      );
    }
  }

  setPriceIsInvalid() {
    this.setState({
      priceIsValid: false,
      priceIsInvalid: true,
      priceErrors: ['För lågt belopp']
    });
  }

  setPriceIsValid() {
    this.setState({
      priceIsValid: true,
      priceIsInvalid: false,
      priceErrors: []
    });
  }

  render() {
    const { data } = this.props;
    const {
      title,
      subTitle,
      priceTitle,
      prices = ['150', '200'],
      increaseDonationFormPage,
      showIncreaseInformation = true
    } = data;
    const { priceIsInvalid, priceIsValid, priceErrors } = this.state;

    const backgroundImage = this.selectBackground(data.backgrounds);

    const videoSource = this.selectVideo(data.backgrounds);

    return (
      <FlexWrapper>
        <Background
          style={this.state.backgroundStyle}
          largeUrl={backgroundImage.largeUrl}
          smallUrl={backgroundImage.smallUrl}
        >
          {videoSource.videoUrl && (
            <VideoWrapper
              ref={(c) => (this.heroVideoWrapper = c)}
              showVideoWrapper={this.state.showVideoWrapper}
            >
              <VideoPlayer
                id="heroVideo"
                ref={this.vidRef}
                aria-hidden="true"
                role="presentation"
                tabIndex="-1"
                loop={true}
                muted={true}
                playsInline={true}
              >
                <source
                  src={`
                    ${mediaHelper.getUrl(
                      videoSource.videoUrl,
                      {
                        audio_codec: 'none',
                        video_codec: 'h264'
                      },
                      'mp4'
                    )}
                  `}
                  type="video/mp4"
                />
                <source
                  src={`
                    ${mediaHelper.getUrl(
                      videoSource.videoUrl,
                      {
                        audio_codec: 'none',
                        video_codec: 'vp8'
                      },
                      'webm'
                    )}
                  `}
                  type="video/webm"
                />
              </VideoPlayer>
            </VideoWrapper>
          )}
          <ContentWrapper>
            <Container2
              width="slim"
              paddingLeft="small"
              paddingRight="small"
              bottomMargin={!showIncreaseInformation}
            >
              {subTitle && <SubTitle>{subTitle}</SubTitle>}
              {title && <Title>{title}</Title>}
              {priceTitle && <PricePickerTitle>{priceTitle}</PricePickerTitle>}
              <CustomAmount>
                {(customAmount) => (
                  <FieldWrapper>
                    <RadioButtonHorizontalWrapper>
                      {prices.map((price) => (
                        <RadioButtonHorizontalItem
                          key={price}
                          value={price}
                          checked={
                            this.state.price === price &&
                            !customAmount.isEnabled
                          }
                          onChange={(event) => {
                            customAmount.handlers.setIsEnabled(false);
                            this.setProductPrice(event.target.value);
                          }}
                          label={`${price} kr`}
                          tinyLayout="auto"
                          smallLayout="auto"
                          largeLayout="auto"
                        />
                      ))}
                      <RadioButtonHorizontalItem
                        checked={customAmount.isEnabled}
                        onChange={() => {
                          //this.textInput.focus();
                          customAmount.handlers.setIsEnabled(true);
                          this.resetProductPrice();
                        }}
                        label="Valfritt"
                        tinyLayout="auto"
                        smallLayout="auto"
                        largeLayout="auto"
                      />
                    </RadioButtonHorizontalWrapper>
                    {customAmount.isEnabled && (
                      <CustomAmountWrapper>
                        <CustomAmountTitle>Belopp</CustomAmountTitle>
                        <Text
                          name="price"
                          type="tel"
                          value={this.state.price}
                          errors={priceErrors}
                          isValid={priceIsValid}
                          isInvalid={priceIsInvalid}
                          onChange={(event) => {
                            this.setProductPrice(event.target.value);
                          }}
                          onBlur={this.handleBlur}
                          maxLength="6"
                          placeholder="Valfritt belopp (minst 50 kr)"
                          suffix="kr"
                        />
                      </CustomAmountWrapper>
                    )}
                  </FieldWrapper>
                )}
              </CustomAmount>
              <ButtonWrapper>
                <PrimaryButton
                  onClick={this.handleSubmit}
                  buttonStyle={buttonStyles.success}
                >
                  Gå vidare
                </PrimaryButton>
              </ButtonWrapper>
            </Container2>
            {showIncreaseInformation && (
              <Container3 width="slim" paddingLeft="small" paddingRight="small">
                <HiddenBelowMedium>
                  <DonorTitle>Redan månadsgivare?</DonorTitle>
                  <PrimaryButton
                    buttonType="link"
                    buttonStyle={buttonStyles.outlineInverted}
                    to={increaseDonationFormPage}
                  >
                    Höj ditt belopp
                  </PrimaryButton>
                </HiddenBelowMedium>
                <WhiteLink to={increaseDonationFormPage}>
                  Redan Månadsgivare? Höj ditt belopp.
                </WhiteLink>
              </Container3>
            )}
          </ContentWrapper>
        </Background>
      </FlexWrapper>
    );
  }
}

export default withRouter(MonthlyDonationForm);
