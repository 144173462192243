import React, { useMemo } from 'react';

import { CertificatePreview } from './CertificatePreview';

// Shared components
import { PreviewButton } from '~/App/shared/components/Donation/components/Forms/components/CertificatePreviews/components/PreviewButton';
import { ICommerceVariant } from '~/types/ICommerceVariant';

type Props = {
  variant: ICommerceVariant;
  toggleModal: () => void;
};

export function CertificateSummary({ variant, toggleModal }: Props) {
  return useMemo(
    () => (
      <>
        <CertificatePreview
          certificate={{ ...variant.certificate, image: variant.primaryImage }}
        />
        <PreviewButton onClick={toggleModal}>
          Förhandsgranska gåvobevis
        </PreviewButton>
      </>
    ),
    [toggleModal, variant]
  );
}
