import React from 'react';
import styled from 'styled-components';

// Thanks components
import {
  MemorialGiftThanks,
  MonthlyDonationThanks,
  DonationThanks,
  ChristmasDonationThanks,
  IncreaseMonthlyThanks,
  CollectionDonationThanks,
  SupportMemberThanks,
  CompanyDonationThanks,
  LotteryTicketThanks,
  CompanyPackageThanks,
  ChristmasCompanyPackageThanks,
  PinkCompanyPackageThanks,
  GenericCertificateThanks
} from './components';

// Shared components
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import Redirect from '~/App/shared/components/Redirect';

const Block = styled.div`
  margin: 0 auto;
  font-size: 0.875em;
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export default class Thanks extends React.Component {
  render() {
    if (!this.props.purchase && !this.props.product === 'increase-monthly') {
      return <Redirect to="/" replace />;
    } else {
      const {
        backgroundColor = '',
        product,
        purchase,
        collection,
        data
      } = this.props;
      return (
        <BlockBackground backgroundColor={backgroundColor}>
          <Container>
            <Block>
              {product === 'memorial-gift' && (
                <MemorialGiftThanks purchase={purchase} data={data} />
              )}
              {product === 'monthly-donation' && (
                <MonthlyDonationThanks purchase={purchase} data={data} />
              )}
              {product === 'donation' && (
                <DonationThanks purchase={purchase} data={data} />
              )}
              {(product === 'christmas-donation' ||
                product === 'christmas-donation-company' ||
                product === 'swish-certificate' ||
                product === 'christmas-research-time') && (
                <ChristmasDonationThanks purchase={purchase} data={data} />
              )}
              {product === 'increase-monthly' && (
                <IncreaseMonthlyThanks purchase={purchase} data={data} />
              )}
              {product === 'collection-donation' && (
                <CollectionDonationThanks
                  purchase={purchase}
                  data={data}
                  collection={collection}
                />
              )}
              {product === 'support-member' && (
                <SupportMemberThanks purchase={purchase} data={data} />
              )}
              {product === 'company-donation' && (
                <CompanyDonationThanks purchase={purchase} data={data} />
              )}
              {product === 'company-package' && (
                <CompanyPackageThanks purchase={purchase} data={data} />
              )}
              {(product === 'lottery-tickets' ||
                product === 'christmas-lottery-company') && (
                <LotteryTicketThanks purchase={purchase} data={data} />
              )}
              {product === 'christmas-package-company' && (
                <ChristmasCompanyPackageThanks
                  purchase={purchase}
                  data={data}
                />
              )}
              {product === 'company-package-pink' && (
                <PinkCompanyPackageThanks purchase={purchase} data={data} />
              )}

              {product === 'generic-certificate' && (
                <GenericCertificateThanks purchase={purchase} data={data} />
              )}
            </Block>
          </Container>
        </BlockBackground>
      );
    }
  }
}
