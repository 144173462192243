import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { loadData } from './Collections.loadData';
import { Collections } from './Collections';

const View = (props: Record<string, unknown>) => {
  const pageContent = usePageContent();
  const children = useMemo(
    () => (pageContent ? <Collections {...pageContent} {...props} /> : null),
    [pageContent, props]
  );

  return children;
};

View.loadData = loadData;

export default View;
