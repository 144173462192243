import { useMemo, useCallback, useEffect, useState } from 'react';

import useQuery from '~/App/shared/hooks/use-query';
import { ICollection } from '~/types/ICollection';
import { loadCollections } from '../Collections.LoadData';

type Props = {
  initalCollections: ICollection[];
};

type Query = {
  period: number;
  sorting: string;
};

export function useCollections({ initalCollections }: Props) {
  const [page, setPage] = useState(1);
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState<ICollection[]>(
    initalCollections || []
  );

  const rawQuery = useQuery();

  const { period, sorting } = useMemo<Query>(
    () => ({
      period: typeof rawQuery.period === 'number' ? rawQuery.period : 365,
      sorting:
        typeof rawQuery.sorting === 'string'
          ? rawQuery.sorting
          : 'latestDonated'
    }),
    [rawQuery]
  );

  const loadMore = useCallback(
    async (event) => {
      event.preventDefault();
      event.currentTarget.blur();

      const nextPage = page + 1;

      setPage(nextPage);
      setIsLoading(true);

      try {
        const { data = [] } = await loadCollections({
          query: {
            page: nextPage,
            period,
            sorting,
            per_page: 12
          }
        });

        setIsLoading(false);
        setIsVisible(data.length === 12);
        setCollections([...collections, ...data]);
      } catch (error) {
        console.log(error);

        setIsLoading(false);
      }
    },
    [collections, page, period, sorting]
  );

  useEffect(() => {
    if (!initalCollections) return;

    setCollections(initalCollections);
    setPage(1);
  }, [initalCollections]);

  return {
    loadMore,
    sorting,
    period,
    isLoading,
    isVisible,
    collections
  };
}
