import _ from 'lodash';
import React from 'react';
import { getOffset } from '~/App/helpers/units-helper';
import { scrollAnimation } from '~/App/helpers/animations';

import Link from '~/App/shared/components/Link';

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const { link: { props: { to: href = '' } = {} } = {} } = this;

    if (href !== '#' && _.startsWith(href, '#')) {
      event.preventDefault();

      const $header = document.querySelector('#headerContainer');
      const $section = document.querySelector(href);

      if ($section.size() > 0) {
        const scrollTop = getOffset($section).top - $header.offsetHeight;
        scrollAnimation(scrollTop, 500, 'linear');
      }
    }
  }

  render() {
    let { link, children, className, modifiers } = this.props;

    let buttonClass = className;

    if (className === 'button' && _.isEmpty(modifiers)) {
      modifiers = ['solid-ocean'];
    }

    if (!_.isEmpty(modifiers) && _.isArray(modifiers)) {
      _.map(modifiers, (modifier) => {
        buttonClass = buttonClass + ' ' + buttonClass + '--' + modifier;
      });
    }

    if (_.isEmpty(link) || _.isEmpty(children)) {
      return false;
    }

    return (
      <Link
        to={link}
        ref={(c) => (this.link = c)}
        onClick={this.handleClick}
        className={buttonClass}
        data-offset={this.props.scrollOffset}
      >
        {children}
      </Link>
    );
  }
}

Button.displayName = 'Button';

Button.defaultProps = {
  className: 'button',
  scrollOffset: 106
};

export default Button;
