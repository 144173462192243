import React, { useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { heading4, arrowLink, description1 } from '~/App/helpers/mixins';

import Link from '~/App/shared/components/Link';
import { HtmlContent } from '~/App/shared/components/HtmlContent';
import { RelatedPageItem } from '~/types/RelatedBlock';

const Wrapper = styled.div`
  display: flex;
`;

const Figure = styled.div`
  width: 20%;
  margin-right: 1.5rem;
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
`;

const Content = styled.div`
  overflow: hidden;
  width: 80%;
`;

const Heading = styled.h3`
  ${heading4};
`;

const Preamble = styled.h3`
  ${description1};
  padding-top: 0.25em;
`;

const ArrowLink = styled(Link)`
  ${arrowLink};
  ${description1};
  margin-top: 1rem;
`;

type Props = {
  item: RelatedPageItem;
};

export function EditorialBrick({ item }: Props) {
  const imageUrl = item.preview?.image?.secure_url;
  const heading = item.preview?.heading;
  const preamble = item.preview?.preamble;
  const linkText = item.preview?.linkText;
  const slug = item.slug;
  const contentType = item.contentType;

  return useMemo(
    () => (
      <Wrapper>
        {imageUrl && (
          <Figure>
            <Image
              src={mediaHelper.getUrl(imageUrl, {
                width: 200,
                height: 200
              })}
            />
          </Figure>
        )}
        <Content>
          {heading && <Heading>{heading}</Heading>}
          {preamble && (
            <Preamble>
              <HtmlContent dangerouslySetInnerHTML={{ __html: preamble }} />
            </Preamble>
          )}
          <ArrowLink to={{ slug, contentType }}>{linkText}</ArrowLink>
        </Content>
      </Wrapper>
    ),
    [contentType, heading, imageUrl, linkText, preamble, slug]
  );
}
