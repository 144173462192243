import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { useLogin } from '~/App/shared/hooks/use-login';

import {
  ctaButton,
  outlinePrimaryButton,
  primaryButton
} from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

import { IntegrityText } from '~/App/shared/components/Donation/components/Forms/components/IntegrityText';
import useLoginModal from '~/App/shared/hooks/use-login-modal';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';

const Preamble = styled.div`
  font-size: 1.125rem;
  margin-bottom: 1rem;

  ${mq('<small')`
    text-size: 1rem;
  `};
`;

const Button = styled.button`
  ${primaryButton};
  margin: 1rem auto 0;
`;

const CtaButton = styled.button`
  ${ctaButton};
  display: block;
  margin: 1rem auto 0;
`;

const OutlineButton = styled.button`
  ${outlinePrimaryButton};
  display: block;
  margin: 1rem auto 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin: 1rem auto 0;
`;

export function ContactInformation() {
  const { isAuthenticated, user } = useAuthenticationContext();
  const login = useLogin();
  const loginModal = useLoginModal();

  const handleClick = useCallback(
    async (sameDevice) => {
      login.handlers.handleLogin({
        qrInit: !sameDevice,
        sameDevice: sameDevice
      });
      loginModal.handlers.toggleQrMode(!sameDevice);
    },
    [loginModal.handlers, login.handlers]
  );

  const handleTestClick = useCallback(async () => {
    login.handlers.handleLogin({
      testLogin: true
    });
  }, [login.handlers]);

  const mobileLoginButton = useMemo(
    () => (
      <ButtonWrapper>
        <CtaButton onClick={() => handleClick(true)}>
          BankID på denna enhet
        </CtaButton>
        <OutlineButton onClick={() => handleClick(false)}>
          BankID på annan enhet
        </OutlineButton>
      </ButtonWrapper>
    ),
    [handleClick]
  );

  const content = useMemo(() => {
    if (!isAuthenticated) {
      return (
        <>
          {isMobileBrowser() ? (
            mobileLoginButton
          ) : (
            <>
              <Button
                children="Bekräfta med BankID"
                onClick={() => handleClick(false)}
              />
              {featureIsEnabled('ENABLE_TEST_BANKID_USER') && (
                <Button onClick={() => handleTestClick()}>
                  Snabblogin med Testanvändare
                </Button>
              )}
            </>
          )}

          <IntegrityText />
        </>
      );
    }

    return (
      <Preamble>
        Du är inloggad som{' '}
        <strong children={`${user?.firstName} ${user?.lastName}`} />.
      </Preamble>
    );
  }, [user, isAuthenticated, handleClick]);

  return (
    <>
      <Preamble>
        För att dela ett minne på minnessidan behöver du bekräfta din identitet
        (du får då ett inlogg hos oss).
      </Preamble>
      {content}
    </>
  );
}
