import React, { useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import dayjs from 'dayjs';

// Shared components
import { InputWrapper } from '~/App/shared/components/Fields/InputWrapper';
import Text from '~/App/shared/components/Fields/Text';
import DateField from '~/App/shared/components/Fields/Date';
import {
  PrimaryButton,
  AlertMessageContainer
} from '~/App/shared/components/Elements';
import { ImageUploader } from '~/App/shared/components/ImageUploader';
import { AccountHeading2 } from '~/App/views/Account/shared/components/AccountHeadings';
import { IValidation } from '~/App/shared/components/Validation';
import { MemorialEditState } from '../hooks/useMemorialEditState';
import { MemorialEditSubmit } from '../hooks/useMemorialEditSubmit';

const FlexWrapper = styled.div`
  ${mq('≥small')`
    display: flex;

    > div {
      width: 50%;

      &:last-child {
        margin-left: 1rem;
      }
    }
  `};
`;

const AlertMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: -1rem;

  ${mq('≥small')`
    margin-bottom: -2rem;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  flex-grow: 1;
  width: 100%;

  ${mq('≥small')`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
  `};
`;

const SaveCancelButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 1rem;
  }

  ${mq('≥small')`
    flex-direction: row-reverse;

    > * {
      margin-top: 0;
      margin-left: 1rem;
    }
  `};
`;

const ToggleCollectionStatusButton = styled(PrimaryButton)`
  ${mq('<small')`
    border: none;
    text-transform: uppercase;
    text-align: left;
    padding: 0;
    margin-bottom: 2rem;
    font-size: 0.9rem;
  `};
`;

const CancelButton = styled(PrimaryButton)`
  min-width: 8rem;
`;

const StyledInputWrapper = styled(InputWrapper)`
  margin-top: 1rem;
`;

type Props = {
  validation: IValidation;
  state: MemorialEditState;
  submit: MemorialEditSubmit;
  error: string | null;
};

export function MemorialForm({ validation, submit, state, error }: Props) {
  console.log(validation.errors);
  console.log(state);

  const todaysDate = useMemo(() => new Date(), []);

  const anyDateBeforeToday = useMemo(
    () =>
      new Date(
        todaysDate.getFullYear() - 150,
        todaysDate.getMonth(),
        todaysDate.getDay()
      ),
    [todaysDate]
  );

  return (
    <form onSubmit={submit.handleSaveSubmit}>
      <AccountHeading2>Redigera namn, datum och bild</AccountHeading2>
      <InputWrapper>
        <Text
          name="name"
          value={state.values.name}
          onChange={state.handlers.handleChange}
          placeholder="Avlidens namn"
          autoComplete="off"
          onBlur={validation.showError.bind(undefined, 'name')}
          errors={validation.errors['name']}
          isValid={validation.isValid('name')}
          isInvalid={validation.isInvalid('name')}
          required
        />
      </InputWrapper>
      <FlexWrapper>
        <div>
          <StyledInputWrapper>
            <DateField
              value={dayjs(state.values.birthDate).toDate()}
              onChange={(value: Date) => {
                state.handlers.handleDateChange('birthDate', value);
              }}
              errors={validation.errors['birthDate']}
              isValid={validation.isValid('birthDate')}
              isInvalid={validation.isInvalid('birthDate')}
              max={todaysDate}
              min={anyDateBeforeToday}
              placeholder={'Födelsedag'}
              showMonthYearDropdown={true}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <DateField
              value={dayjs(state.values.deathDate).toDate()}
              onChange={(value: Date) => {
                state.handlers.handleDateChange('deathDate', value);
              }}
              errors={validation.errors['deathDate']}
              isValid={validation.isValid('deathDate')}
              isInvalid={validation.isInvalid('deathDate')}
              max={todaysDate}
              min={state.values.birthDate}
              placeholder={'Dödsdag'}
              showMonthYearDropdown={true}
            />
          </StyledInputWrapper>
        </div>
        <ImageUploader
          value={state.values.imageUrl}
          onChange={state.handlers.handleImageChange}
          children="Byt minnesbild"
          showProgressBadge={state.values.showProgressBadge}
          collectionType={state.values.collectionType}
        />
      </FlexWrapper>
      {error && (
        <AlertMessageWrapper>
          <AlertMessageContainer messageStyle="error" children={error} />
        </AlertMessageWrapper>
      )}
      <ButtonWrapper>
        {state.values.enabled ? (
          <ToggleCollectionStatusButton
            buttonType="link"
            buttonStyle="outlinePrimary"
            to={`/min-sida/minnessidor/${state.values.slug}/avsluta`}
            children="Ta bort minnessida"
          />
        ) : (
          <ToggleCollectionStatusButton
            buttonStyle="outlinePrimary"
            onClick={submit.handleEnabledSubmit}
            isLoading={submit.isEnabled}
            children="Aktivera minnessida"
          />
        )}
        <SaveCancelButtonWrapper>
          <PrimaryButton
            buttonType="button"
            type="submit"
            buttonStyle={validation.isValidated ? 'primary' : 'disabled'}
            children="Spara och stäng"
            isLoading={submit.isSaving}
          />
          <CancelButton
            buttonType="link"
            buttonStyle="outlinePrimary"
            to="/min-sida/minnessidor"
            children="Avbryt"
          />
        </SaveCancelButtonWrapper>
      </ButtonWrapper>
    </form>
  );
}
