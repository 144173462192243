import React from 'react';

// Shared components
import Intro from '~/App/shared/components/Intro';
import Section from '~/App/shared/components/Section';
import ProductImages from '~/App/shared/components/ProductImages';

import ThanksForm from '~/App/shared/components/ThanksForm';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';

class SupportMemberThanks extends React.Component {
  render() {
    const purchase = this.props.purchase;
    const data = this.props.data;
    const { productImages } = data;

    return (
      <div>
        <Section modifier="section--narrow section--borderBottomFaded">
          <div className="ui-spacer">
            <Intro
              heading={data.heading}
              preamble={data.description}
              headingFontSize="large"
              headingFontWeight="bold"
              preambleFontWeight="light"
            />
            <FetchPurchaseDocuments purchase={purchase}>
              {({ receiptStatus, receipt }) => (
                <AsyncReceiptDocumentLink
                  url={receipt}
                  status={receiptStatus}
                  paymentMethodId={purchase?.paymentMethod?.id}
                />
              )}
            </FetchPurchaseDocuments>
          </div>
        </Section>
        <Section>
          <Intro
            heading="Hur vill du visa din gåva?"
            preamble="Du kan välja bild och valfritt namn på gåvan när den visas på vår webb."
            headingFontSize="large"
            headingFontWeight="bold"
            preambleFontWeight="light"
          />
        </Section>
        <Section modifier="section--slimTop">
          <ProductImages productImages={productImages}>
            {({ productImages, defaultProductImage }) => (
              <ThanksForm
                {...purchase}
                productImages={productImages}
                defaultProductImage={defaultProductImage}
              />
            )}
          </ProductImages>
        </Section>
      </div>
    );
  }
}

export default SupportMemberThanks;
