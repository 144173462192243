import React, { useMemo, ReactNode } from 'react';

import { useValidation } from '../../../Validation';
import {
  useState,
  MultiPartState,
  ContactCustomerValues,
  PaymentValues,
  ProductValues,
  AnalyticsValues
} from '../../components/States';

import { Submit, ISubmit } from '../../components/Submits';
import BankId from '../../components/BankId';
import PaymentRedirect from '../../components/PaymentRedirect';

import { validationKeys, parts } from '../state';
import { schema } from '../schema';
import { IValidation } from '../../../Validation/Validation';
import { MultiPartFormValues } from '../../../MultiPartForm';
import { CombinedDonationTaxReductionNudging } from './TaxReductionNudging';

type FormContent<T> = T & {
  originPath: string | Record<number, string>;
  redirectPath: string | Record<number, string>;
};

type Result<T> = {
  validation: IValidation;
  submit: ISubmit;
  values: ContactCustomerValues &
    PaymentValues &
    ProductValues &
    MultiPartFormValues &
    AnalyticsValues;
  formContent: FormContent<T>;
};

type Props<T> = {
  defaultValues: any;
  formContent: FormContent<T>;
  children: (result: Result<T>) => ReactNode;
};

export function Hoc<T>({ children, defaultValues, formContent }: Props<T>) {
  const values = useState({
    values: defaultValues,
    formContent
  });

  const validation = useValidation({
    values,
    schema,
    hasStripe: true
  });

  return useMemo(
    () => (
      <MultiPartState
        values={values}
        validation={validation}
        parts={parts}
        validationKeys={validationKeys}
      >
        {(multiPartValues) => (
          <Submit values={multiPartValues} validation={validation}>
            {(submit) => (
              <CombinedDonationTaxReductionNudging
                submit={submit}
                values={multiPartValues}
                validation={validation}
              >
                {(taxReductionNudgingSubmit) => (
                  <div>
                    <PaymentRedirect
                      submit={taxReductionNudgingSubmit}
                      redirectPath={
                        typeof formContent.redirectPath === 'string'
                          ? formContent.redirectPath
                          : formContent.redirectPath[values.product.id]
                      }
                    />
                    {taxReductionNudgingSubmit.isBankId ? (
                      <BankId
                        failed={taxReductionNudgingSubmit.errors}
                        loading={
                          taxReductionNudgingSubmit.isSending ||
                          taxReductionNudgingSubmit.isPolling
                        }
                        resetBankId={taxReductionNudgingSubmit.resetBankId}
                      />
                    ) : (
                      children({
                        values: multiPartValues,
                        submit: taxReductionNudgingSubmit,
                        validation,
                        formContent
                      })
                    )}
                  </div>
                )}
              </CombinedDonationTaxReductionNudging>
            )}
          </Submit>
        )}
      </MultiPartState>
    ),
    [children, formContent, validation, values]
  );
}
