import React from 'react';
import styled from 'styled-components';

// Constants
import { paymentMethods } from '~/App/config/paymentMethods';

// Helpers
import { heading2, preamble } from '~/App/helpers/mixins';

import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { Header } from './shared/Header';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 2.5rem;
  white-space: pre-line;
`;

const ButtonLink = styled(PrimaryButton)`
  margin-bottom: 1rem;
`;

class ChristmasDonationThanks extends React.Component {
  render() {
    const { purchase, data } = this.props;
    const {
      purchase: { productOptions = {} },
      paymentMethod = ({} = {})
    } = purchase;
    const { customerContact = {} } = productOptions;

    const nameInHeading = customerContact[data.headingName];

    const greeting =
      customerContact && nameInHeading
        ? `${data.heading} ${nameInHeading}!`
        : `${data.heading}!`;

    const emailInPreamble =
      (productOptions.customerContact &&
        productOptions.customerContact.email) ||
      'din mail';

    const receiptNameInPreamble =
      paymentMethod.id === paymentMethods.paymentSlip ||
      paymentMethod.id === paymentMethods.invoice
        ? 'bekräftelse'
        : 'kvitto';

    const preamble = data.description;
    const preambleWithEmail = preamble.replace('%%email%%', emailInPreamble);
    const preambleWithReceiptName = preambleWithEmail.replace(
      '%%receipt_name%%',
      receiptNameInPreamble
    );

    return (
      <div>
        <Background backgroundColor="primary">
          <Header image={data.productImage}>
            <Heading1 children={greeting} />
            <Preamble children={preambleWithReceiptName} />

            {purchase.product.id === 33 && (
              <ButtonLink
                rel="noopener noreferrer"
                target="_blank"
                buttonType="link"
                to={'/du-har-fatt-en-julklapp'}
              >
                Visa tackfilm
              </ButtonLink>
            )}
            <FetchPurchaseDocuments purchase={purchase}>
              {({ receiptStatus, receipt }) => (
                <AsyncReceiptDocumentLink
                  url={receipt}
                  status={receiptStatus}
                  paymentMethodId={paymentMethod?.id}
                />
              )}
            </FetchPurchaseDocuments>
          </Header>
        </Background>
      </div>
    );
  }
}

export default ChristmasDonationThanks;
