import React, { useCallback } from 'react';

import {
  Wrapper,
  Heading,
  StepWrapper,
  Step,
  Number,
  StepHeading,
  Description,
  StyledButton
} from './StepBlock.styled.js';

import { withRouter } from '~/App/hocs/with-router';

function Block({
  hash,
  backgroundColor = 'accentYellow',
  heading = '',
  width = 'normal',
  items = []
}) {
  const renderItem = useCallback(
    (step, i) => (
      <Step key={step.heading} backgroundColor={backgroundColor} width={width}>
        <Number backgroundColor={backgroundColor} children={i + 1} />
        {step.heading && <StepHeading children={step.heading} />}
        {step.description && (
          <Description dangerouslySetInnerHTML={{ __html: step.description }} />
        )}
        {step.link && step.linkText && (
          <StyledButton
            buttonType="link"
            buttonStyle="outlinePrimary"
            to={step.link}
            children={step.linkText}
          />
        )}
      </Step>
    ),
    [backgroundColor, width]
  );

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Heading id={hash}>{heading}</Heading>
      <StepWrapper width={width}>{items.map(renderItem)}</StepWrapper>
    </Wrapper>
  );
}

export const StepBlock = withRouter(Block);
