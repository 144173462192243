import React from 'react';
import styled from 'styled-components';

import { primaryButton } from '~/App/helpers/mixins';
import { mail } from '~/App/helpers/icons';
import { largeShareButton } from './LargeShareButtons.styled';

import EmailLink from '../EmailLink';

const Button = styled(EmailLink)`
  ${primaryButton};

  &:before {
    ${mail};
  }

  ${largeShareButton};
`;

type Props = {
  subject: string;
  body: string;
};

export function Email({ subject, body }: Props) {
  return <Button body={body} subject={subject} children="Dela med E-post" />;
}
