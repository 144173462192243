import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { HtmlContent } from '~/App/shared/components/HtmlContent';

import { useDragToDismiss } from '~/App/shared/hooks/use-drag-to-dismiss';

import { pushGTMPromoClick, pushGTMPromoView } from '~/App/helpers/gtm-helper';
import { slideUp } from '~/App/helpers/animations';
import { heading1, ctaButton } from '~/App/helpers/mixins';
import { close } from '~/App/helpers/icons';
import { mq } from '~/lib/mq';

import { IOverlay } from '~/types/IOverlay';
import { Logo } from '../../Logo';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 102;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.55);

  ${mq('≥small')`
    justify-content: center;
  `};
`;

type WrapperProps = {
  darkText: boolean;
  desktop: string | null;
  mobile: string | null;
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 100%;
  height: 100%;
  max-width: 625px;
  max-height: calc(100% - 2rem);
  margin: 8px 0 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  animation: ${slideUp} 1 0.6s cubic-bezier(0.2, 0.4, 0.3, 1);
  background-size: cover;
  background-image: url('${({ mobile }) => `data:image/png;base64,${mobile}`}');
  padding: 1.5rem;
  text-align: center;
  color: ${({ theme, darkText }) =>
    darkText ? theme.colors.darkBlue : theme.colors.white};

  ${mq<WrapperProps>('≥small')`
    background-image: url(${({ desktop }) =>
      `data:image/png;base64,${desktop}`});
    margin: 3rem;
    max-width: min(60rem, 66vw);
    max-height: min(40rem, 72vh);
    animation: ${slideUp} 1 0.2s cubic-bezier(0.2, 0.4, 0.3, 1);
    border-radius: 4px;
    padding: 2rem;
  `};

  ::before {
    content: ' ';
    width: 3.375rem;
    border-radius: 100px;
    background: #f1f1f1;
    height: 0.375rem;
    top: -2.5rem;
    position: relative;

    ${mq<WrapperProps>('≥small')`
      display: none;
    `}
  }
`;

const Heading = styled.div`
  ${heading1};

  ${mq('<small')`
      font-size: 2rem;
  `}
`;

const Text = styled(HtmlContent)`
  margin-top: 1rem;
`;

const Button = styled.button`
  ${ctaButton};
  margin-top: 2.5rem;

  ${mq('<small')`
      margin-top: 1.5rem;
  `}
`;

type CloseProps = {
  darkText: boolean;
};

const Close = styled.a<CloseProps>`
  margin-top: 0;
  font-size: 1rem !important;
  color: ${({ theme, darkText }) =>
    darkText ? theme.colors.darkBlue : theme.colors.white};
  display: flex;
  align-items: center;
  cursor: pointer;

  ${mq('<small')`
    text-decoration: underline;
    padding-right: 0 !important;
  `};

  ::after {
    ${close};

    margin-left: 6px;
    font-size: 1.5em;
    font-weight: ${(props) => props.theme.fontWeights.extraBold};

    ${mq('<small')`
      display: none;
    `}
  }

  &:hover {
    color: ${({ theme, darkText }) =>
      darkText ? theme.themeColors.primaryHover : theme.colors.polar};
  }
`;

const Justify = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${mq('≥small')`
    justify-content: space-between;
`};
`;

const Content = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  ${mq('≥small')`
    width: 80%;
`};
`;

const HiddenOnMobile = styled.div`
  display: none;

  ${mq('≥small')`
  display: initial;
`};
`;

const HiddenOnDesktop = styled.div`
  ${mq('≥small')`
display: none;
`};
`;

type Props = {
  overlay: IOverlay;
  images: {
    mobile: string | null;
    desktop: string | null;
  };
  onDismiss: () => void;
};

export function Modal({ overlay, images, onDismiss }: Props) {
  const navigate = useNavigate();
  const { element } = useDragToDismiss({
    onDismiss,
    top: 24
  });

  const handleModalClick = useCallback((e) => e.stopPropagation(), []);

  const handleClick = useCallback(() => {
    const url = overlay.content.button?.url;

    if (!url) {
      return;
    }

    onDismiss();
    pushGTMPromoClick({
      id: overlay.id,
      name: overlay.name,
      creative: overlay.content.heading,
      position: 'overlay',
      url
    });

    if (url.startsWith('http')) {
      return (window.location.href = url);
    }

    navigate(url);
  }, [navigate, onDismiss, overlay]);

  const darkText = useMemo(() => overlay.theme.textColor === 'dark', [
    overlay.theme.textColor
  ]);

  useEffect(() => {
    pushGTMPromoView({
      id: overlay.id,
      name: overlay.name,
      creative: overlay.content.heading,
      position: 'overlay'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => (
      <Overlay onClick={onDismiss}>
        <Wrapper
          ref={element}
          onClick={handleModalClick}
          darkText={darkText}
          mobile={images.mobile}
          desktop={images.desktop}
        >
          <Justify>
            <Logo color={darkText ? 'blue' : 'white'} link={false} />
            <HiddenOnMobile>
              <Close onClick={onDismiss} children="Stäng" darkText={darkText} />
            </HiddenOnMobile>
          </Justify>
          <Content>
            <Heading children={overlay.content.heading} />
            <Text dangerouslySetInnerHTML={{ __html: overlay.content.body }} />
            {overlay.content.button && (
              <Button
                onClick={handleClick}
                children={overlay.content.button.text}
              />
            )}
          </Content>
          <Justify>
            <HiddenOnDesktop>
              <Close onClick={onDismiss} children="Stäng" darkText={darkText} />
            </HiddenOnDesktop>
          </Justify>
        </Wrapper>
      </Overlay>
    ),
    [
      darkText,
      element,
      handleClick,
      handleModalClick,
      images.desktop,
      images.mobile,
      onDismiss,
      overlay.content.body,
      overlay.content.button,
      overlay.content.heading
    ]
  );
}
