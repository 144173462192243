import { useState, useCallback, ChangeEvent, useMemo } from 'react';

type State = {
  deliverPaper: string;
};

export type DirectDebit = State & {
  handlers: {
    handleDirectDebitDeliverPaperChange: (
      event: ChangeEvent<HTMLSelectElement>
    ) => void;
  };
};

export function useDirectDebit(): DirectDebit {
  const [state, setState] = useState<State>({
    deliverPaper: 'send'
  });

  const handleDirectDebitDeliverPaperChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      event.persist();
      const value = event.target.value;

      setState((state) => ({
        ...state,
        deliverPaper: value
      }));
    },
    []
  );

  return useMemo(
    () => ({
      ...state,
      handlers: {
        handleDirectDebitDeliverPaperChange
      }
    }),
    [handleDirectDebitDeliverPaperChange, state]
  );
}
