import { useMemo, useEffect, useState, useCallback, FormEvent } from 'react';

// Helpers
import { poll, purchases } from '~/App/helpers/http';
import { paymentMethods } from '~/App/config/paymentMethods';
import { pushGTMFormEvent } from '~/App/helpers/gtm-helper';

// Types
import { SubmitState } from '../types/SubmitState';
import { SubmitValues } from '../types/SubmitValues';

import { ISubmit } from '../types/ISubmit';
import { IValidation } from '../../../../../Validation';

import { usePurchaseRequestHandlers } from './usePurchaseRequestHandlers';
import { usePurchaseResponseHandlers } from './usePurchaseResponseHandlers';
import { CreateMemoryStateValues } from '~/App/views/MemorialPages/views/CreateMemory/components/Form/hooks/useCreateMemoryState';
import mediaHelper from '~/App/helpers/media-helper';
import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';

const DEFAULT_STATE: SubmitState = {
  errors: false,
  exceptionCode: null,
  isBankId: false,
  isPolling: false,
  isSending: false,
  isCompleted: false,
  hasTimedOut: false,

  klarnaRedirectUrl: undefined,
  paymentUrl: undefined,
  publicToken: undefined,
  swishToken: undefined,
  swishLoadUrl: undefined,
  gtmPurchase: undefined,
  qrCode: undefined,
  autoStartToken: undefined
};

type Props = {
  values: SubmitValues | CreateMemoryStateValues;
  validation: IValidation;
  shouldNudge?: boolean;
};

export function useSubmit({
  values,
  validation,
  shouldNudge = false
}: Props): ISubmit {
  const [state, setState] = useState(DEFAULT_STATE);

  const bankIdSource = useMemo(() => poll.CancelToken.source(), []);
  const purchaseSource = useMemo(() => poll.CancelToken.source(), []);

  const requestHandlers = usePurchaseRequestHandlers({
    setState
  });

  const responseHandlers = usePurchaseResponseHandlers({
    values,
    state,
    setState
  });

  const resetBankId = useCallback(
    () =>
      setState((state) => ({
        ...state,
        errors: false,
        exceptionCode: null,
        isBankId: false
      })),
    []
  );

  const resetSwishForm = useCallback(() => {
    setState(DEFAULT_STATE);
  }, []);

  const beginPolling = useCallback(
    async (publicToken: string) => {
      const response = await poll.start({
        fn: () =>
          purchases.show({
            slug: publicToken
          }),
        onEach: ({ data }) =>
          setState((state) => ({
            ...state,
            qrCode: data.qrCode,
            autoStartToken: data.autoStartToken
          })),
        done: ({ data }) => data.purchase.status !== 'pending',
        timeout: 60000,
        interval: 3000
      });

      if (response?.data?.purchase?.status === 'failed') {
        return responseHandlers.handleError(response, 'poll');
      }

      return responseHandlers.handleSuccess(response);
    },
    [responseHandlers]
  );

  const performUpload = useCallback(async (url) => {
    if (!url) {
      return null;
    }

    if (mediaHelper.isCloudinaryUrl(url)) {
      return url;
    }

    const fetchResponse = await fetch(url);
    const blob = await fetchResponse.blob();
    const uploadResponse = await uploadImage(blob, {
      timeout: 30000,
      folder: 'collection-pictures'
    });

    return uploadResponse?.data?.secure_url;
  }, []);

  function isMemory(
    values: SubmitValues | CreateMemoryStateValues
  ): values is CreateMemoryStateValues {
    return (
      (values as CreateMemoryStateValues).productOptions.memory !== undefined
    );
  }

  const handleSubmit = useCallback(
    async (
      event?: FormEvent<HTMLFormElement>,
      partName?: string,
      buttonText?: string
    ) => {
      if (event) {
        event.preventDefault();
      }

      if (!shouldNudge) {
        pushGTMFormEvent('formSubmit', {
          formName: values.gtm?.formName,
          amount:
            !isMemory(values) && values.productOptions?.memorialGift
              ? values.productOptions?.memorialGift.amount
              : values.productOptions?.product?.price,
          period: values.productOptions?.product?.period,
          step: values.partOptions?.currentPart,
          stepName: partName,
          paymentMethod:
            values.paymentMethod?.id === paymentMethods.klarnaPayments
              ? values.paymentMethod?.slug
              : values.paymentMethod?.name,
          buttonText
        });
      }

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      setState({
        ...DEFAULT_STATE,
        isSending: true
      });

      try {
        if (isMemory(values)) {
          const imageUrl = await performUpload(
            values.productOptions.memory.image
          );
          values.productOptions.memory.image = imageUrl;
        }

        const request = await requestHandlers.buildAsync(values);
        const response = await requestHandlers.sendAsync(request);

        const { data } = response;

        setState((state) => ({
          ...state,
          isSending: false,
          isPolling: true,
          publicToken: data.purchase.publicToken
        }));

        if (data.purchase.status === 'completed') {
          return responseHandlers.handleSuccess(response);
        }

        if (values.paymentMethod?.id === paymentMethods.creditCard) {
          const result = await values.handlers.validateCreditCardPayment({
            clientSecret: data.purchase?.stripeClientSecret,
            period: values?.productOptions?.product?.period
          });

          if (!result.success) {
            return responseHandlers.handleError(result, 'stripe');
          }

          return responseHandlers.handleSuccess(response);
        }

        if (values.paymentMethod?.id === paymentMethods.klarnaPayments) {
          const redirectUrl = data?.purchase?.klarnaRedirectUrl;

          if (!redirectUrl) {
            return responseHandlers.handleError(
              'No redirect url found',
              'Klarna payments'
            );
          }

          return responseHandlers.handleSuccess(response);
        }

        return await beginPolling(data.purchase.publicToken);
      } catch (error) {
        return responseHandlers.handleError(error);
      }
    },
    [
      validation,
      values,
      requestHandlers,
      beginPolling,
      performUpload,
      responseHandlers,
      shouldNudge
    ]
  );

  useEffect(
    () => () => {
      // component-will-unmount

      bankIdSource.cancel();
      purchaseSource.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return useMemo<ISubmit>(
    () => ({
      ...state,
      gtm: {
        name: '',
        brand: '',
        category: '',
        ...values.gtm
      },
      resetBankId: resetBankId,
      handleSubmit: handleSubmit,
      resetSwishForm: resetSwishForm
    }),
    [handleSubmit, resetBankId, resetSwishForm, state, values.gtm]
  );
}
