import React, { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

// Shared components
import { AlertMessageContainer } from '~/App/shared/components/Elements';
import Text from '~/App/shared/components/Fields/Text';

import { IncreaseMonthlySummary } from '../../components/Forms/components/Summaries';
import AmountSelector from '../../components/Forms/components/AmountSelector';
import BankIdWrapper from '../../components/Forms/components/BankIdWrapper';
import QRAutostartTokenWrapper from '../../components/Forms/components/QRAutostartTokenWrapper';
import { IntegrityText } from '../../components/Forms/components/IntegrityText';
import { FormHeading } from '../../components/Forms/components/FormTypography';
import {
  StepWrapper,
  HiddenAboveMedium
} from '../../components/Forms/components/FormLayout';
import { SubmitButton } from '../../components/Forms/components/SubmitButton';

import * as TwoColumn from '../../components/Forms/components/TwoColumn';
import { ISubmitIncrease } from '../../components/Submits/Submit/hooks/useSubmitIncrease';
import { IValidation } from '../../../Validation';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '../../components/States';

const CopyInline = styled.div`
  margin: 1rem 0 1rem;
`;

const InputWithSpacing = styled(Text)`
  margin-top: 1rem;
`;

type Props = {
  validation: IValidation;
  submit: ISubmitIncrease;
  values: ContactCustomerValues & ProductValues & PaymentValues;
  formContent: {
    prices: number[];
    infoText?: string;
  };
};

export function Form({ submit, values, validation, formContent }: Props) {
  const location = useLocation();
  const bankIdSsn = useRef<HTMLInputElement | null>(null);

  const rightColumnHeading = 'Ditt val';
  const infoText = formContent.infoText
    ? formContent.infoText
    : 'Det går endast att höja beloppet om du är månadsgivare via autogiro.';

  return (
    <TwoColumn.Wrapper>
      <TwoColumn.LeftColumn>
        <form onSubmit={submit.handleSubmit} noValidate>
          <TwoColumn.ColumnCard>
            <FormHeading>Välj det belopp du vill höja med</FormHeading>
            <CopyInline>
              <p>
                Om du ger minst 200 kr i månaden kan du ha rätt till
                <a
                  href={`${location.pathname}#skatteavdrag-for-gavor`}
                  aria-current="page"
                >
                  {' '}
                  skatteavdrag
                </a>
              </p>
            </CopyInline>

            <AmountSelector
              amounts={formContent.prices}
              selectedAmount={values.productOptions.product.price}
              customAmount={values.productOptions.product.customPrice}
              setAmount={values.handlers.setProductPrice}
              setCustomAmount={values.handlers.setProductCustomPrice}
              resetAmount={values.handlers.resetProductPrice}
              validationKey={'productOptions.product.price'}
              validation={validation}
              tinyLayout="50"
              smallLayout="50"
              largeLayout="auto"
            />

            <StepWrapper marginTop="medium">
              <FormHeading>Dina kontaktuppgifter</FormHeading>
              <div>
                {submit.pollingStatus !== 'sign_pending' && (
                  <div>
                    <Text
                      name="ssn"
                      type="tel"
                      value={values.productOptions.customerContact.ssn}
                      onBlur={validation.handleBlur.bind(
                        undefined,
                        'productOptions.customerContact.ssn'
                      )}
                      errors={
                        validation.errors['productOptions.customerContact.ssn']
                      }
                      isValid={
                        validation.isValid(
                          'productOptions.customerContact.ssn'
                        ) && submit.pollingStatus !== 'no_monthly_donation'
                      }
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        values.handlers.handleCustomerContactSsn(
                          event,
                          bankIdSsn.current
                        );
                      }}
                      inputRef={bankIdSsn}
                      isInvalid={
                        validation.isInvalid(
                          'productOptions.customerContact.ssn'
                        ) || submit.pollingStatus === 'no_monthly_donation'
                      }
                      maxLength="12"
                      placeholder="Personnummer (ÅÅÅÅMMDDCCCC)"
                      instructions="Vi hämtar och sparar dina folkbokförda uppgifter."
                    />
                    {submit.pollingStatus === 'no_monthly_donation' && (
                      <AlertMessageContainer>
                        Vi hittar inte ditt personnummer registrerat via
                        autogiro.
                        <br />
                        Varmt välkommen att ringa givarservice på 010-199 10 10
                        om du behöver hjälp.
                      </AlertMessageContainer>
                    )}
                    <InputWithSpacing
                      name="email"
                      type="email"
                      value={values.productOptions.customerContact.email}
                      onBlur={() => {
                        validation.handleBlur(
                          'productOptions.customerContact.email'
                        );
                      }}
                      errors={
                        validation.errors[
                          'productOptions.customerContact.email'
                        ]
                      }
                      isValid={validation.isValid(
                        'productOptions.customerContact.email'
                      )}
                      onChange={values.handlers.handleCustomerContactEmail}
                      isInvalid={validation.isInvalid(
                        'productOptions.customerContact.email'
                      )}
                      placeholder="E-post"
                      autoComplete="email"
                      instructions="Bekräftelse för din betalning skickas via e-post."
                    />
                    {(submit.pollingStatus === 'failed' || submit.errors) && (
                      <AlertMessageContainer messageStyle="error">
                        Det blev ett tekniskt fel. Testa gärna igen eller
                        välkommen att ringa givarservice på 010-199 10 10 om du
                        behöver hjälp.
                      </AlertMessageContainer>
                    )}
                    {submit.pollingStatus === 'invalid_payment_method' && (
                      <AlertMessageContainer messageStyle="error">
                        Tyvärr fungerar inte höjning av månadsbelopp med din
                        nuvarande betalmetod. Välkommen att ringa givarservice
                        på 010-199 10 10 om du behöver hjälp.
                      </AlertMessageContainer>
                    )}
                  </div>
                )}
                {submit.isPolling && submit.pollingStatus === 'sign_pending' ? (
                  submit.qrCode ? (
                    <QRAutostartTokenWrapper
                      qr={submit.qrCode}
                      autostartToken={submit.autostartToken}
                    />
                  ) : (
                    <BankIdWrapper />
                  )
                ) : null}
              </div>
            </StepWrapper>

            <HiddenAboveMedium marginBottom="medium">
              <IncreaseMonthlySummary
                price={values.productOptions.product.price}
                infoText={infoText}
                heading={rightColumnHeading}
              />
            </HiddenAboveMedium>

            {submit.pollingStatus === 'sign_failed' && (
              <AlertMessageContainer messageStyle="error">
                Något gick fel vid signeringen, var vänlig försök igen. Du är
                välkommen att ringa givarservice på 010-199 10 10.
              </AlertMessageContainer>
            )}

            {submit.pollingStatus !== 'sign_pending' && <IntegrityText />}
          </TwoColumn.ColumnCard>
          <SubmitButton values={values} validation={validation} submit={submit}>
            Signera med Mobilt BankID
          </SubmitButton>
        </form>
      </TwoColumn.LeftColumn>
      <TwoColumn.RightColumn>
        <TwoColumn.ColumnCard>
          <IncreaseMonthlySummary
            price={values.productOptions.product.price}
            infoText={infoText}
            heading={rightColumnHeading}
          />
        </TwoColumn.ColumnCard>
      </TwoColumn.RightColumn>
    </TwoColumn.Wrapper>
  );
}
