import React from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { arrowLink } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

// Shared components
import Link from '~/App/shared/components/Link';
import { useHelloBar } from '~/App/contexts/HelloBar';

const LinkWrapper = styled(Link)`
  background: ${({ theme }) => theme.colors.amour};
  width: 100%;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FakeArrowLink = styled.div`
  ${arrowLink};
  margin: 0;
  margin-left: 1rem;

  &:after {
    margin-top: 0.05em;

    ${mq('≥small')`
      margin-top: -0.05em;
  `};
  }
`;

export function HelloBar() {
  const data = useHelloBar();

  if (!data) {
    return null;
  }

  return (
    <LinkWrapper to={data.url} className="ga-hellobar">
      {data.image && (
        <img src={mediaHelper.getUrl(data.image.secure_url, { height: 32 })} />
      )}
      <FakeArrowLink children={data.text} />
    </LinkWrapper>
  );
}
