import { fetch } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';

export const loadData = async () => {
	const response = await fetch({
		url: contentApi('/v1/content-definitions/form-supporter-member')
	});

	return {
		data: response.data
	};
};
