import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ICommerceVariant } from '~/types/ICommerceVariant';

type BackgroundColorProps = {
  status?: string;
};

const getBackgroundColor = (status: string | undefined) => {
  switch (status) {
    case 'InStock':
      return '#F3F7F3';
    case 'FewInStock':
      return '#f9f9f2';
    case 'OutOfStock':
      return '#f9f2f3';
    default:
      return '#f9f2f3';
  }
};

const Background = styled.div<BackgroundColorProps>`
  width: fit-content;
  background-color: ${({ status }) => getBackgroundColor(status)};
  border-radius: 0.25rem;
  display: flex;
  padding: 0.25rem 0.75rem 0.25rem 0.4rem;
  align-items: center;
  margin-top: 1.5rem;

  img {
    &:hover {
      transform: scale(1) !important;
    }
  }
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  padding-left: 0.25rem;
`;

const StatusLogo = styled.img`
  height: auto;
`;

type Props = {
  variant?: ICommerceVariant;
};

export function StockInfoBox({ variant }: Props) {
  const stockText = useMemo(() => {
    if (variant?.discontinued) {
      return 'Produkten har utgått';
    }

    switch (variant?.stock?.status) {
      case 'InStock':
        return 'I lager';
      case 'FewInStock':
        return 'Få kvar i lager';
      case 'OutOfStock':
        return 'Tillfälligt slut';
    }
  }, [variant]);

  const stockLogo = useMemo(() => {
    if (variant?.discontinued) {
      return 'https://res.cloudinary.com/cancerfonden/image/upload/v1635927151/assets/icons/icons-ui-information.svg';
    }

    switch (variant?.stock?.status) {
      case 'InStock':
        return 'https://res.cloudinary.com/cancerfonden/image/upload/v1636548896/assets/icons/icons-ui-checkmark-icon-font.svg';
      case 'FewInStock':
        return 'https://res.cloudinary.com/cancerfonden/image/upload/v1635946106/assets/icons/icons-ui-information-alert.svg';
      case 'OutOfStock':
        return 'https://res.cloudinary.com/cancerfonden/image/upload/v1635927151/assets/icons/icons-ui-information.svg';
    }
  }, [variant]);

  return (
    <Background status={variant?.stock?.status}>
      <StatusLogo src={stockLogo} />
      <Text children={stockText}></Text>
    </Background>
  );
}
