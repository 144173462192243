import React, { useCallback } from 'react';
import styled from 'styled-components';

// shared components
import { ICmsProduct } from '~/types/ICmsProduct';
import { ICommerceProduct } from '~/types/ICommerceProduct';

// helpers
import { containerWidthsPx } from '~/App/config/containerWidths';

import { CmsProductCard } from './components/CmsProductCard';
import { CommerceProductCard } from './components/CommerceProductCard';

import { useImpressions } from './hooks/useImpressions';

const Container = styled.div`
  margin: 2rem auto;
  max-width: ${containerWidthsPx.normal};
`;

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

type Props =
  | {
      source: 'cms';
      items: ICmsProduct[];
    }
  | {
      source: 'commerce';
      items: ICommerceProduct[];
    };

export default function ProductList({ items, source }: Props) {
  const is = useCallback(
    <T extends any[]>(
      items: ICmsProduct[] | ICommerceProduct[],
      typeSource: string
    ): items is T => typeSource === source,
    [source]
  );

  const { container, itemClassName } = useImpressions({
    items: is<ICommerceProduct[]>(items, 'commerce') ? items : []
  });

  const renderCmsProduct = useCallback(
    (product: ICmsProduct, index: number) => (
      <CmsProductCard key={index} product={product} />
    ),
    []
  );

  const renderCommerceProduct = useCallback(
    (product: ICommerceProduct, index: number) => (
      <CommerceProductCard
        key={product.id}
        index={index}
        className={itemClassName}
        data-id={product.id}
        product={product}
      />
    ),
    [itemClassName]
  );

  if (is<ICmsProduct[]>(items, 'cms')) {
    return (
      <Container>
        <List children={items.map(renderCmsProduct)} />
      </Container>
    );
  }

  if (is<ICommerceProduct[]>(items, 'commerce')) {
    return (
      <Container ref={container}>
        <List children={items.map(renderCommerceProduct)} />
      </Container>
    );
  }

  return null;
}
