import React from 'react';
import styled, { keyframes } from 'styled-components';

// Helpers
import { buttonStyles } from '~/App/config/buttonStyles';
import { preamble, whiteGradient } from '~/App/helpers/mixins';
import { heart } from '~/App/helpers/icons';
import { slideUp, fadeIn } from '~/App/helpers/animations';

// Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import ShareButtons from '~/App/shared/components/ShareButtons';

const PulsateAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(7, 144, 9, 1); } 
  70% { box-shadow: 0 0 0 10px rgba(7, 144, 9, 0); } 
  100% { box-shadow: 0 0 0 50px rgba(7, 144, 9, 0); }
`;

const StickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  background: ${(props) => props.theme.colors.white};
  width: 100%;
  z-index: 1;
  transform: translateY(100%);
  animation: ${slideUp} 1 0.3s cubic-bezier(0.05, 0.4, 0.3, 1) forwards;

  &::before {
    ${whiteGradient}
    display: ${({ showGradient }) => (showGradient ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: -50%;
    z-index: -1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  margin: auto;
  min-height: 4rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`;

const DonateButton = styled(PrimaryButton)`
  position: absolute;
  top: -3.25rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  -moz-animation: ${PulsateAnimation} 2s infinite;
  -webkit-animation: ${PulsateAnimation} 2s infinite;
  animation: ${PulsateAnimation} 2s infinite;
`;

const Preamble = styled.p`
  ${preamble};
  margin: auto;

  &:before {
    ${heart};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.themeColors.primary};
    margin-right: 1rem;
    vertical-align: top;
    font-size: 1.5rem;
  }
`;

export const Footer = (props) => {
  const { collection } = props;

  return (
    <StickyFooter showGradient={!collection.isPaused}>
      <Wrapper>
        {collection.isPaused ? (
          <Preamble>Insamlingen är avslutad</Preamble>
        ) : (
          <React.Fragment>
            <DonateButton
              buttonType="link"
              buttonStyle={buttonStyles.cta}
              to={`/stod-oss/skank-till-en-insamling/${collection.slug}`}
            >
              Bidra till insamlingen
            </DonateButton>
            <ShareButtons
              showFacebook={true}
              showEmail={true}
              showLink={true}
              showLinkedIn={true}
            />
          </React.Fragment>
        )}
      </Wrapper>
    </StickyFooter>
  );
};
