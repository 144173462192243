import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import styled from 'styled-components';
import mq from '~/App/helpers/mq';
import { scrollAnimation } from '~/App/helpers/animations';
import { primaryButton } from '~/App/helpers/mixins';
import { ctaButton } from '~/App/helpers/mixins';
import { useInViewport } from '~/App/shared/hooks/use-in-viewport';
import { IAnswer, IBMIAnswer } from '~/types/IQuestionSession';
import { IScreen } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: flex-end;
`;

type ButtonProps = {
  isLastScreen: boolean;
};

const Button = styled.button<ButtonProps>`
  ${({ isLastScreen }) => (isLastScreen ? ctaButton : primaryButton)};
  min-width: 0;
  margin-bottom: 0.5rem;
`;

type MessageProps = {
  visible: boolean;
};

const Message = styled.div<MessageProps>`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  height: ${({ visible }) => (visible ? 'auto' : 0)};
  overflow: hidden;
  margin: 0;
  transition: all ease-in-out 0.2s;

  ${mq('≥small')`
    color: ${({ theme }) => theme.colors.white};
  `};
`;

type Props = {
  value: IAnswer | null;
  screen?: IScreen;
  children: ReactNode;
  onClick: () => void;
  isLastScreen: boolean;
};

export function NextButton({ screen, value, children, onClick, isLastScreen }: Props) {
  const [showError, setShowError] = useState(false);
  const [visible, ref] = useInViewport<HTMLButtonElement>();

  const disabled = useMemo(() => {
    if (screen?.optional) {
      return false;
    }

    if (screen?.type === 'SectionStartScreen') {
      return false;
    }

    if (screen?.type === 'BMIScreen') {
      if (!(value as IBMIAnswer)?.bmi) {
        return true;
      }
    }

    if (value?.value !== undefined) {
      return false;
    }

    return true;
  }, [screen, value]);

  const handleClick = useCallback(
    (next: () => void) => () => {
      ref.current?.blur();

      if (disabled) {
        return setShowError(true);
      }

      setShowError(false);
      next();
    },
    [disabled, ref]
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (disabled) {
      return scrollAnimation(0); // always scroll to top
    }

    if (screen?.type !== 'QuestionScreen') return;

    if (visible) {
      return;
    }

    scrollAnimation(document.body.scrollHeight);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, screen])

  return (
    <Wrapper>
      <Button onClick={handleClick(onClick)} ref={ref} children={children} isLastScreen={isLastScreen} />
      <Message
        visible={showError && disabled}
        children="Du måste svara för att gå vidare"
      />
    </Wrapper>
  );
}
