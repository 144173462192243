import _ from 'lodash';
import React from 'react';
import { formatUTCDate } from '~/App/helpers/date.service';
import styled from 'styled-components';

import Link from './Link';
import Author from './Author';
import Section from './Section';
import ShareButtons from './ShareButtons';
import EditorialHero from './EditorialHero';
import EditorialVideo from './EditorialVideo';
import EditorialImage from './EditorialImage';
import EditorialMetaItem from './EditorialMetaItem';
import EditorialImageHigh from './EditorialImageHigh';

// Shared components
import HtmlContent from '~/App/shared/components/HtmlContent';

const StyledShareButtons = styled(ShareButtons)`
  width: 12rem;
`;

class EditorialItem extends React.Component {
  render() {
    const { content = {} } = this.props;
    const { reviewer = {}, bodyVersion = 'v1' } = content;

    const publishedDate = formatUTCDate(content.publishedDate, 'D MMM YYYY');

    const reviewerName = reviewer && reviewer.name ? reviewer.name : '';
    const publishedLabel = reviewerName ? 'Granskad' : 'Publicerad';

    return (
      <div>
        <EditorialHero {...content} />
        <EditorialVideo {...content} />
        <EditorialImage {...content} />
        <Section modifier="section--slim">
          <div className="editorialItem">
            <div className="editorialItem__meta">
              <div className="editorialItem__share">
                <StyledShareButtons
                  showFacebook={true}
                  showX={true}
                  showEmail={true}
                  showPrint={true}
                />
              </div>
              <Link
                className="editorialItem__parentLink"
                to={this.props.parentSlug}
              >
                {this.props.parentName}
              </Link>
              <div className="editorialItem__metaItems">
                <EditorialMetaItem
                  label={publishedLabel}
                  title={publishedDate}
                />
                {_.get(content, 'responsible.name', '') ? (
                  <EditorialMetaItem
                    label="Sidansvarig"
                    title={content.responsible.name || false}
                    byline={content.responsible.role || ''}
                  />
                ) : null}
                {_.get(content, 'author.name', '') ? (
                  <EditorialMetaItem
                    label="Författare"
                    title={content.author.name || false}
                    byline={content.author.role || ''}
                  />
                ) : null}
                {_.get(content, 'reviewer.name', '') ? (
                  <EditorialMetaItem
                    label="Granskning"
                    title={content.reviewer.name || false}
                    byline={content.reviewer.role || ''}
                  />
                ) : null}
              </div>
            </div>
            <div className="editorialItem__body">
              <EditorialImageHigh {...content} />
              <h1 className="editorialItem__title">{content.heading}</h1>
              <p className="editorialItem__preamble">{content.preamble}</p>
              {_.has(content, 'data.button.slug', false) &&
              _.has(content, 'data.button.label', false) ? (
                <div className="editorialItem__productButton">
                  <Link
                    className="button button--solid-ocean"
                    to={_.get(content, 'data.button.slug', '')}
                  >
                    {_.get(content, 'data.button.label', '')}
                  </Link>
                </div>
              ) : null}
              {bodyVersion === 'v1' ? (
                <div
                  className="richTextContent"
                  dangerouslySetInnerHTML={{ __html: content.body }}
                />
              ) : (
                <HtmlContent
                  dangerouslySetInnerHTML={{ __html: content.body }}
                />
              )}
              {_.get(content, 'author.name', '') ? (
                <div className="editorialItem__author">
                  <Author {...content.author} />
                </div>
              ) : null}
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

EditorialItem.displayName = 'EditorialItem';

EditorialItem.defaultProps = {
  parentSlug: '/nyheter',
  parentName: 'Nyheter'
};

export default EditorialItem;
