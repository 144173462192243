import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import { OrderLine } from './OrderLine';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const Name = styled.div`
  font-size: 0.875rem;
`;

const OrderLines = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  name: string;
  orderLines: ICommerceOrderLine[];
};

export function OrderLinesGroup({ name, orderLines }: Props) {
  const renderOrderLine = useCallback(
    (orderLine: ICommerceOrderLine) => (
      <OrderLine key={orderLine.id} orderLine={orderLine} />
    ),
    []
  );

  if (!orderLines.length) {
    return null;
  }

  return (
    <Wrapper>
      <Name children={name} />
      <OrderLines children={orderLines.map(renderOrderLine)} />
    </Wrapper>
  );
}
