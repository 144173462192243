import styled from 'styled-components';

// Helpers
import { uiProfile } from '~/App/helpers/icons';
//import mq from '~/App/helpers/mq';

export const LoginIcon = styled.div`
  &:before {
    ${uiProfile};
    font-size: 1.75rem;
    font-weight: bold;
  }
`;
