import React, { useCallback, MouseEvent } from 'react';
import styled from 'styled-components';

// Constants
import { swishTypes } from '~/App/config/swishTypes';
import { paymentMethods } from '~/App/config/paymentMethods';

// Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import { PaymentValues } from '../../States';

const OtherDeviceButton = styled(PrimaryButton)`
  margin: auto;
  margin-top: 1rem;
  display: block;
`;

type Props = {
  values: PaymentValues;
  text?: string;
  isDisabled?: boolean;
};

export function SwishOtherDeviceButton({
  values,
  text = 'Swish på annan enhet?',
  isDisabled
}: Props) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      values.handlers.handleSwishTypeToggle();
    },
    [values.handlers]
  );

  if (values.swish.swishType !== swishTypes.mcommerce) {
    return null;
  }

  if (values.paymentMethod?.id !== paymentMethods.swish) {
    return null;
  }

  return (
    <OtherDeviceButton
      buttonStyle={isDisabled ? 'disabled' : 'outlinePrimary'}
      children={text}
      onClick={handleClick}
      isDisabled={isDisabled}
    />
  );
}
