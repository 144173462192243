import React, { ReactNode } from 'react';

// Config
import { featureToggles } from '~/App/config/featureToggles';
import { FeatureToggleContext } from './FeatureToggleContext';

type Props = {
  children: ReactNode;
};

export function FeatureToggleProvider({ children }: Props) {
  return (
    <FeatureToggleContext.Provider value={featureToggles} children={children} />
  );
}
