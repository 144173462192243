import React, { useCallback } from 'react';
import { formatMoney } from 'accounting';
import styled from 'styled-components';

import { downloadLinkIcon } from '~/App/helpers/icons';

import Link from '~/App/shared/components/Link';
import { ICommerceOrder } from '~/types/ICommerceOrder';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import { mq } from '~/lib/mq';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemName = styled.p`
  font-weight: bold;
  font-size: 1rem;
  color: #001489;

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

const OrderItem = styled.div`
  display: flex;
  width: auto;
  height: 6rem;
  margin-top: 1.5rem;
`;

type OrderItemPictureProps = {
  source?: string;
};

const OrderItemPicture = styled.div<OrderItemPictureProps>`
  display: flex;
  background-image: ${({ source }) => `url('${source}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 6rem;
  height: 6rem;
  border-radius: 0.25rem;
  flex-shrink: 0;

  ${mq('<mediumLarge')`
    min-height: 5rem;
    min-width: 5rem;
  `};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 1rem;
  height: 100%;
  width: 100%;

  ${mq('<mediumLarge')`
  `};
`;

const ItemPrice = styled.p`
  margin-left: auto;
`;

const AmountWrapper = styled.div`
  display: flex;
  margin-bottom: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

const DownloadLink = styled(Link)`
  font-size: 1rem;

  &::after {
    ${downloadLinkIcon};
    margin-top: -0.25rem;
    margin-left: 0.25rem;
  }

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

type Props = {
  order: ICommerceOrder;
};

export function OrderData({ order }: Props) {
  const formatAmount = useCallback(
    (price: number | null | undefined) =>
      price ? formatMoney(price, 'kr', 0, ' ', undefined, '%v %s') : '0 kr',
    []
  );

  const renderOrderLine = useCallback(
    (orderLine: ICommerceOrderLine) => (
      <OrderItem key={orderLine.id}>
        <OrderItemPicture source={orderLine.variant?.primaryImage} />
        <InfoWrapper>
          <ItemName children={orderLine.variant?.name} />
          {orderLine.shippment?.pdfUrl && (
            <DownloadLink
              to={orderLine.shippment?.pdfUrl}
              target="_blank"
              children="Gåvobevis"
            />
          )}
          <AmountWrapper>
            <p children={`${orderLine.quantity} st`} />
            <ItemPrice
              children={formatAmount(orderLine.price * orderLine.quantity)}
            />
          </AmountWrapper>
        </InfoWrapper>
      </OrderItem>
    ),
    [formatAmount]
  );

  return (
    <Wrapper>
      {order.orderLines.map(renderOrderLine)}
      <Row>
        <Paragraph>Frakt</Paragraph>
        <Paragraph children={formatAmount(order.shippingPrice)} />
      </Row>
    </Wrapper>
  );
}
