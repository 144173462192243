import { paymentMethods } from '~/App/config/paymentMethods';
import { merge } from 'lodash';
import { useMemo } from 'react';

import { gtmDetails, gtmFormName } from '~/App/config/gtmDetails';

type Props = {
  data?: {
    defaultPrice?: string;
    certificateImage?: unknown;
    textTemplates?: string[];
    campaignActivityId?: number | string | null;
  };
  defaultState?: unknown;
  component: string;
};

const PRODUCT_ID = 1;

export function useState({
  data: {
    defaultPrice,
    certificateImage,
    textTemplates = [],
    campaignActivityId
  } = {},
  defaultState = {},
  component
}: Props) {
  return useMemo(
    () =>
      merge(
        {},
        {
          product: { id: PRODUCT_ID },
          gtm: {
            ...gtmDetails[PRODUCT_ID],
            formName: gtmFormName[component]
          },
          customerContactType: 'automatic',
          productOptions: {
            memorialGift: {
              memorialType: 'Funeral',
              amount: defaultPrice ? parseInt(defaultPrice, 10) : 0,
              imageUrl: certificateImage,
              textGreeting: textTemplates[0] || ''
            },
            campaignActivityId: campaignActivityId,
            texts: textTemplates,
            pul: true
          },
          templates: textTemplates,
          paymentMethod: {
            id: paymentMethods.klarnaPayments,
            name: 'Konto och kort',
            slug: 'klarna'
          }
        },
        defaultState
      ),
    [
      defaultState,
      certificateImage,
      defaultPrice,
      textTemplates,
      component,
      campaignActivityId
    ]
  );
}

export const validationKeys = {
  1: [
    'productOptions.inMemoryOfContact.firstName',
    'productOptions.inMemoryOfContact.lastName',
    'productOptions.memorialGift.textGreeting'
  ],
  2: ['productOptions.memorialGift.amount'],
  3: [
    'swishPhoneNumber',
    'klarna.isReady',
    'productOptions.customerContact.email',
    'productOptions.customerContact.ssn',
    'productOptions.customerContact.lastName',
    'productOptions.customerContact.firstName',
    'productOptions.customerContact.addressStreet',
    'productOptions.customerContact.addressZip',
    'productOptions.customerContact.addressCity'
  ]
};
