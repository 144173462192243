import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const HeaderWrapper = styled.div`
  max-width: 592px;
  margin: 0 auto;
  padding: 0 0.5rem;
  cursor: pointer;
  background: ${(props) => props.theme.themeColors.accentYellow};
  transition: border-radius 0.4s linear;
  border-radius: ${({ isExpanded = true }) =>
    isExpanded ? '1.375rem 1.375rem 0 0' : '1.375rem'};

  ${mq('≥large')`
    max-width: none;
    margin: 0;
    padding: 0;
    background: transparent;
    border-radius: 0;
  `};
`;
