import { createContext } from 'react';
import { IOverlay } from '~/types/IOverlay';

export type OverlayContextValue = {
  overlay: null | IOverlay;
  dismiss: () => void;
};

export const OverlayContext = createContext<OverlayContextValue>({
  overlay: null,
  dismiss: () => null
});
