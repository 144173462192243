import React, { useCallback, useMemo } from 'react';

// Helpers
import { getPurchaseErrorMessage } from './helpers';

// Shared components
import * as TwoColumn from './components/TwoColumn';
import { StepWrapper, HiddenAboveMedium } from './components/FormLayout';
import { FormTitle } from './components/FormTypography';
import PaymentMethodSelector from './components/PaymentMethodSelector';
import { IntegrityText } from './components/IntegrityText';
import { ContactFields } from './components/ContactFields';
import { AlertMessageContainer } from '~/App/shared/components/Elements';
import { SubmitButton } from './components/SubmitButton';
import { SwishOtherDeviceButton } from './components/SwishOtherDeviceButton';
import * as RadioButtonHorizontal from '~/App/shared/components/Fields/RadioButtonHorizontal/';
import { Section } from '../../../MultiPartForm';

export default function GeneralPackageForm({
  values = {},
  submit = {},
  validation = {},
  formContent = {},
  summaryComponent: SummaryComponent
}) {
  const renderPackage = useCallback(
    (companyPackage) => (
      <RadioButtonHorizontal.Item
        key={`package-${companyPackage.name}`}
        value={companyPackage.productId}
        checked={values.product.id === companyPackage.productId}
        onChange={(event) => {
          values.handlers.setProductId(parseInt(event.target.value, 10));
          values.handlers.setProductPrice(companyPackage.price);

          if (companyPackage.campaignActivityId) {
            values.handlers.setCampaignActivityId(
              companyPackage.campaignActivityId
            );
          } else {
            values.handlers.unsetCampaignActivityId();
          }
        }}
        label={companyPackage.name}
        tinyLayout={formContent?.layout?.packageSelector?.tinyLayout || '50'}
        smallLayout={formContent?.layout?.packageSelector?.smallLayout || '50'}
        largeLayout={formContent?.layout?.packageSelector?.largeLayout || '50'}
      />
    ),
    [
      formContent?.layout?.packageSelector?.largeLayout,
      formContent?.layout?.packageSelector?.smallLayout,
      formContent?.layout?.packageSelector?.tinyLayout,
      values.handlers,
      values.product.id
    ]
  );

  return useMemo(
    () => (
      <>
        {formContent.heading && <FormTitle>{formContent.heading}</FormTitle>}
        <TwoColumn.Wrapper>
          <TwoColumn.LeftColumn>
            <form onSubmit={submit.handleSubmit} noValidate>
              <Section
                values={values}
                part={1}
                validation={validation}
                title={formContent.formHeading1 || 'Välj paket'}
                nextStepButton={{ text: 'Till kontaktuppgifter' }}
              >
                <StepWrapper>
                  <StepWrapper marginTop="tiny">
                    <RadioButtonHorizontal.Wrapper>
                      {formContent.packages.map(renderPackage)}
                    </RadioButtonHorizontal.Wrapper>
                  </StepWrapper>
                  <HiddenAboveMedium>
                    <SummaryComponent area="form" />
                  </HiddenAboveMedium>
                </StepWrapper>
              </Section>

              <Section
                values={values}
                part={2}
                validation={validation}
                title={formContent.formHeading2 || 'Dina kontaktuppgifter'}
                nextStepButton={{ text: 'Till betalsätt' }}
              >
                <StepWrapper>
                  <ContactFields
                    values={values}
                    validation={validation}
                    company={{
                      email: true,
                      phone: true
                    }}
                  />
                  <HiddenAboveMedium>
                    <SummaryComponent area="form" />
                  </HiddenAboveMedium>
                </StepWrapper>
              </Section>
              <Section
                values={values}
                part={3}
                validation={validation}
                title={formContent.formHeading3 || 'Välj betalsätt'}
                nextStepButton={{ text: 'Till betalsätt' }}
              >
                <StepWrapper>
                  <PaymentMethodSelector
                    formContent={formContent}
                    values={values}
                    validation={validation}
                  />
                  <IntegrityText />
                  {submit.errors && !submit.isPolling && !submit.isSending && (
                    <AlertMessageContainer messageStyle="error">
                      {getPurchaseErrorMessage(submit.exceptionCode)}
                    </AlertMessageContainer>
                  )}
                  <HiddenAboveMedium>
                    <SummaryComponent area="form" />
                  </HiddenAboveMedium>
                </StepWrapper>
              </Section>
              {values.partOptions.currentPart === 3 && (
                <>
                  <SubmitButton
                    values={values}
                    validation={validation}
                    submit={submit}
                  />
                  <SwishOtherDeviceButton
                    values={values}
                    text={formContent.anotherDeviceText}
                  />
                </>
              )}
            </form>
          </TwoColumn.LeftColumn>
          <TwoColumn.RightColumn>
            <TwoColumn.ColumnCard>
              <SummaryComponent area="side" />
            </TwoColumn.ColumnCard>
          </TwoColumn.RightColumn>
        </TwoColumn.Wrapper>
      </>
    ),
    [SummaryComponent, formContent, renderPackage, submit, validation, values]
  );
}
