import { useMemo, useState, useCallback, ChangeEvent } from 'react';
import { get } from 'lodash';

// Config
import { paymentMethods } from '~/App/config/paymentMethods.js';

import { useSwish, Swish } from '../PaymentMethods/Swish';
import { useCreditCard, CreditCard } from '../PaymentMethods/CreditCard';
import { useDirectDebit, DirectDebit } from '../PaymentMethods/DirectDebit';
import { useMobileBankId, MobileBankId } from '../PaymentMethods/MobileBankId';
import {
  useKlarnaPayments,
  KlarnaPayments
} from '../PaymentMethods/KlarnaPayments';

import { IPaymentMethod } from '~/types/IPaymentMethod';
import { Period } from '~/types/IPurchase';

type PaymentMethodOptions =
  | Omit<CreditCard, 'handlers'>
  | Omit<DirectDebit, 'handlers'>
  | Omit<MobileBankId, 'handlers'>
  | Omit<Swish, 'handlers'>
  | {
      sessionId?: string;
    };

export type PaymentValues = {
  originPath: string;
  redirectPath: string;
  taxReductionWanted?: boolean;
  paymentMethod?: IPaymentMethod | undefined;
  swishPhoneNumber: string;
  paymentMethodOptions: PaymentMethodOptions | null;
  creditCard: Omit<CreditCard, 'handlers'>;
  directDebit: Omit<DirectDebit, 'handlers'>;
  mobileBankId: Omit<MobileBankId, 'handlers'>;
  swish: Omit<Swish, 'handlers'>;
  klarna: Omit<KlarnaPayments, 'handlers'>;
  handlers: {
    handleTaxReductionWanted: (event: ChangeEvent<HTMLInputElement>) => void;
    handlePaymentMethod: (
      paymentMethod: IPaymentMethod,
      event?: ChangeEvent<HTMLInputElement>,
      callback?: () => void
    ) => void;
  } & CreditCard['handlers'] &
    DirectDebit['handlers'] &
    MobileBankId['handlers'] &
    Swish['handlers'] &
    KlarnaPayments['handlers'];
};
type FormContent = {
  originPath: string | Record<number, string>;
  redirectPath: string | Record<number, string>;
};

type InputProps = {
  values: {
    product?: {
      id?: number;
    };
    taxReductionWanted?: boolean;
    paymentMethod?: IPaymentMethod;
    productOptions?: {
      product?: {
        period?: Period;
      };
    };
    handlers?: Record<string, unknown>;
  };
  customGtmId?: string;
  formContent: FormContent;
};

type OutputProps = {
  values: PaymentValues;
};

export function usePaymentValues<T extends InputProps>({
  values,
  formContent,
  customGtmId
}: T) {
  const productId = useMemo(() => get(values, 'product.id'), [values]);
  const period = useMemo<Period>(
    () => get(values, 'productOptions.product.period') ?? 'Once',
    [values]
  );

  const originPath = useMemo(() => {
    if (!formContent?.originPath) {
      return '';
    }

    if (typeof formContent?.originPath === 'string') {
      return formContent.originPath;
    }

    if (productId) {
      return formContent.originPath[productId];
    }

    return '';
  }, [formContent, productId]);

  const redirectPath = useMemo(() => {
    if (!formContent?.redirectPath) {
      return '';
    }

    if (typeof formContent?.redirectPath === 'string') {
      return formContent.redirectPath;
    }

    if (productId) {
      return formContent.redirectPath[productId];
    }

    return '';
  }, [formContent.redirectPath, productId]);

  const { handlers: klarnaHandlers, ...klarna } = useKlarnaPayments({
    redirectPath,
    period
  });

  const { handlers: creditCardHandlers, ...creditCard } = useCreditCard();
  const { handlers: directDebitHandlers, ...directDebit } = useDirectDebit();
  const { handlers: mobileBankIdHandlers, ...mobileBankId } = useMobileBankId();

  const { handlers: swishHandlers, ...swish } = useSwish({
    productId,
    customGtmId,
    redirectPath,
    originPath
  });

  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>({
    id: 2,
    name: 'Betalkort',
    slug: 'betalkort',
    ...values.paymentMethod
  });

  const paymentMethodOptions = useMemo<PaymentMethodOptions | null>(() => {
    switch (paymentMethod.id) {
      case paymentMethods.creditCard:
        return creditCard;

      case paymentMethods.autoGiroSlip:
        return directDebit;

      case paymentMethods.swish:
        return swish;

      case paymentMethods.autoGiroBankId:
        return mobileBankId;

      case paymentMethods.klarnaPayments:
        return {
          sessionId: klarna.sessionId
        };

      default:
        return null;
    }
  }, [
    paymentMethod.id,
    creditCard,
    directDebit,
    swish,
    mobileBankId,
    klarna.sessionId
  ]);

  const [taxReductionWanted, setTaxReductionWanted] = useState(
    values?.taxReductionWanted || false
  );

  const handleTaxReductionWanted = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTaxReductionWanted(event.target.checked);
    },
    []
  );

  const handlePaymentMethod = useCallback(
    (
      paymentMethod: IPaymentMethod,
      event?: ChangeEvent<HTMLInputElement>,
      callback?: () => void
    ) => {
      if (event) {
        event.preventDefault();
      }

      setPaymentMethod(paymentMethod);

      if (callback) {
        callback();
      }
    },
    []
  );

  const nextHandlers = useMemo<OutputProps['values']['handlers']>(
    () => ({
      ...values.handlers,
      ...swishHandlers,
      ...creditCardHandlers,
      ...mobileBankIdHandlers,
      ...directDebitHandlers,
      ...klarnaHandlers,
      handleTaxReductionWanted,
      handlePaymentMethod
    }),
    [
      values.handlers,
      swishHandlers,
      creditCardHandlers,
      mobileBankIdHandlers,
      directDebitHandlers,
      klarnaHandlers,
      handleTaxReductionWanted,
      handlePaymentMethod
    ]
  );

  return useMemo<T['values'] & PaymentValues>(
    () => ({
      ...values,
      paymentMethod,
      paymentMethodOptions,
      taxReductionWanted,
      swishPhoneNumber: swish.phoneNumber.replace(/^46/, '0'),
      creditCard,
      directDebit,
      mobileBankId,
      klarna,
      swish,
      redirectPath,
      originPath,
      handlers: nextHandlers
    }),
    [
      creditCard,
      swish,
      mobileBankId,
      klarna,
      directDebit,
      originPath,
      paymentMethod,
      paymentMethodOptions,
      redirectPath,
      taxReductionWanted,
      values,
      nextHandlers
    ]
  );
}
