import styled from 'styled-components';

//Helpers
import { bell } from '~/App/helpers/icons';
import { ring } from '~/App/helpers/animations';

export const NotificationBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.accentYellow};
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  top: -12px;
  right: 10px;

  &::before {
    ${bell};
    font-size: 1rem;
    color: ${({ theme }) => theme.themeColors.primary};
    animation: ${ring} 4.5s 0.7s cubic-bezier(0, 0.01, 0.46, 0.9) infinite;
  }
`;
