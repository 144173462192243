import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { Link } from '~/App/shared/components/Link';
import { ShareModal } from '~/App/views/SelfTest/components/ShareModal';
import mq from '~/App/helpers/mq';

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
`;

const Logo = styled.img`
  width: 12rem;
`;

const ShareLink = styled.a`
  color: white;
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;
  transition: transform 0.3s ease-in-out;
  z-index: 10;

  ${mq('≥small')`
    font-size: 1.2rem;
  `};

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  &::after {
    content: '';
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1628767938/Sj%C3%A4lvtest/share-icon-white.svg');
    background-repeat: no-repeat;
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    background-size: contain;

    ${mq('≥small')`
      width: 1.5rem;
      height: 1.5rem;
    `};
  }
`;

const BringToTop = styled.div`
  z-index: 20;
`;

type Props = {
  className?: string;
};

export function Header({ className }: Props) {
  const [modalOpen, setOpen] = useState(false);

  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);

  const modal = useMemo(() => {
    if (!modalOpen) return null;

    return <ShareModal closeModal={closeModal} />;
  }, [modalOpen, closeModal]);

  return (
    <Wrapper className={className}>
      <Link to="/">
        <Logo
          className={'logo'}
          src="https://res.cloudinary.com/cancerfonden/image/upload/c_scale,w_500/v1544186351/assets/logo-cancerfonden_one-line_white.png"
        />
      </Link>
      <ShareLink onClick={openModal} className={'share-link'}>
        Dela
      </ShareLink>
      <BringToTop children={modal} />
    </Wrapper>
  );
}
