import { FormEvent, useCallback, useMemo, useState } from 'react';

import { logMessage } from '~/App/helpers/logger';
import { purchases } from '~/App/helpers/http';
import { pushGTMEvent, pushGTMPurchase } from '~/App/helpers/gtm-helper';
import { errorLevels } from '~/App/config/errorLevels';

import {
  ICreatePurchaseRequest,
  ICreatePurchaseResponse
} from '~/types/ICreatePurchase';

import { IValidation } from '../../../Validation';
import {
  AnalyticsValues,
  GenericValues,
  PaymentValues,
  ProductValues
} from '../../components/States';
import { getListPrice } from '~/App/helpers/purchase-helper';
import { useUtmContext } from '~/App/contexts/Utm';

declare global {
  interface Window {
    ga: any | undefined;
  }
}

type Props = {
  values: PaymentValues & ProductValues & GenericValues & AnalyticsValues;
  validation: IValidation;
  source?: string;
  initialPrice?: number | null;
};

type SubmitError = {
  reason: string;
  displayReason?: string;
};

export type ISubmit = {
  error?: string;
  isSending: boolean;
  redirectUrl?: string;
  handleSubmit: (
    event: FormEvent<HTMLFormElement> | undefined
  ) => Promise<void>;
};

export function useSubmit({ values, validation, source, initialPrice }: Props) {
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const utm = useUtmContext();

  const handleSubmitError = useCallback((error: SubmitError) => {
    setIsSending(false);
    setError(error.displayReason);

    logMessage({
      level: errorLevels.error,
      message: error.reason,
      data: error
    });
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement> | undefined) => {
      event?.preventDefault();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      setIsSending(true);
      setError(undefined);

      const price = getListPrice(values.productOptions);

      if (initialPrice !== price) {
        await values.handlers.klarnaPaymentsUpdateSession(price);
        await values.handlers.klarnaPaymentsLoad();
      }

      const response = await values.handlers.klarnaPaymentsAuthorize();

      if (!response.authorization_token) {
        await values.handlers.klarnaPaymentsLoad(price);

        return handleSubmitError({
          reason: `Failed to obtain authorization_token from Klarnas`
        });
      }

      const purchaseResponse = await purchases.create({
        data: {
          source,
          product: values.product,
          paymentMethod: values.paymentMethod,
          productOptions: values.productOptions,
          paymentMethodOptions: {
            authorizationToken: response.authorization_token
          },
          clientOptions: values.clientOptions,
          utmTags: utm
        } as ICreatePurchaseRequest
      });

      const purchase = purchaseResponse.data as ICreatePurchaseResponse;
      const redirectUrl = purchase?.purchase?.klarnaRedirectUrl;

      if (!redirectUrl) {
        return handleSubmitError({
          reason: 'Failed to obtain redirectUrl from MW',
          displayReason: 'Ett fel inträffade'
        });
      }

      if (source) {
        pushGTMEvent({
          category: 'Gala takeover 2021',
          action: source,
          label: `${values?.productOptions?.product?.price}`
        });
      }

      pushGTMPurchase(
        {
          purchaseId: purchase.purchase.id,
          totalAmount: purchase.purchase.amount,
          productId: values.product.id,
          quantity: values.productOptions.product.quantity
            ? values.productOptions.product.quantity
            : 1
        },
        {
          ...values.gtm,
          coupon: source
        },
        () => {
          setRedirectUrl(redirectUrl);
          setIsSending(false);
        }
      );
    },
    [
      handleSubmitError,
      initialPrice,
      source,
      utm,
      validation,
      values.clientOptions,
      values.gtm,
      values.handlers,
      values.paymentMethod,
      values.product,
      values.productOptions
    ]
  );

  return useMemo<ISubmit>(
    () => ({
      error,
      isSending,
      redirectUrl,
      handleSubmit
    }),
    [handleSubmit, isSending, error, redirectUrl]
  );
}
