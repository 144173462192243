import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';
import { sectionHeader, arrowLink } from '~/App/helpers/mixins';

// shared components
import Link from '~/App/shared/components/Link';
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const Heading = styled.h3`
  ${sectionHeader};
  color: ${({ theme }) => theme.related.headingColor};
`;

const LinkListWrapper = styled.div`
  ${mq('≥small')`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  `};
`;

const UnorderedList = styled.ul`
  width: 100%;

  ${mq('≥small')`
    width: ${(props) =>
      props.columns === 3
        ? 'calc(33.333333333% - 1.75rem)'
        : props.column === 2
        ? 'calc(50% - 1.3125rem)'
        : '100%'};
    flex-basis: ${(props) =>
      props.columns === 3
        ? 'calc(33.333333333% - 1.75rem)'
        : props.columns === 2
        ? 'calc(50% - 1.3125rem)'
        : '100%'};
  `};
`;

const UnorderedListItem = styled.li`
  display: block;
  margin: 0;
  padding: 0;
`;

const ArrowLink = styled(Link)`
  ${arrowLink};
  display: block;
  margin: 0;
  padding: 1.125rem 1rem 1.125rem 0;
  border-bottom: 1px solid ${(props) => props.theme.themeColors.primary};

  &::after {
    right: 0;
  }
`;

export default function LinkList({
  items = [],
  width = 'normal',
  columns = 3,
  heading = '',
  padding = {},
  backgroundColor = 'none'
}) {
  const groupedItems = useMemo(() => {
    const result = [];

    let itemsPerColumn;
    const itemsLeft = [...items];

    for (let i = 0; i < columns; i += 1) {
      itemsPerColumn = Math.ceil(itemsLeft.length / (columns - i));
      result[i] = itemsLeft.splice(0, itemsPerColumn);
    }

    return result;
  }, [columns, items]);

  const renderItem = useCallback(
    (item) => (
      <UnorderedListItem key={item.slug}>
        <ArrowLink to={item.slug} children={item.preview.heading} />
      </UnorderedListItem>
    ),
    []
  );

  const renderColumn = useCallback(
    (items, index) => (
      <UnorderedList
        columns={columns}
        key={index}
        children={items.map(renderItem)}
      />
    ),
    [columns, renderItem]
  );

  return useMemo(
    () => (
      <BlockBackground backgroundColor={backgroundColor}>
        <Container
          width={width}
          paddingTop={padding.top}
          paddingLeft="small"
          paddingRight="small"
          paddingBottom={padding.bottom}
        >
          {heading && <Heading>{heading}</Heading>}
          <LinkListWrapper>{groupedItems.map(renderColumn)}</LinkListWrapper>
        </Container>
      </BlockBackground>
    ),
    [
      backgroundColor,
      groupedItems,
      heading,
      padding.bottom,
      padding.top,
      renderColumn,
      width
    ]
  );
}
