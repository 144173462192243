import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';

import { IRelatedBlock, RelatedItem } from '~/types/RelatedBlock';

// shared components
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

import { Grid } from './components/Layout';
import { StyledCarousel } from './components/StyledCarousel';

import { useCarousel } from './hooks/use-carousel';
import { useItems } from './hooks/use-items';
import { useItemsRenderer } from './hooks/use-items-renderer';
import { useButton } from './hooks/use-button';
import { usePagination } from './hooks/use-pagination';

import { sectionHeader, spacer } from '~/App/helpers/mixins';

const Spacer = styled.div`
  ${spacer};
`;

const Heading = styled.h3`
  ${sectionHeader};
  color: ${({ theme }) => theme.related.headingColor};
`;

type Props = IRelatedBlock;

export function Related({
  width = 'normal',
  heading = '',
  padding = {
    top: 'none',
    bottom: 'none'
  },
  buttonUrl,
  buttonText,
  buttonStyle = 'arrowLink',
  justifyContent = 'flex-start',
  paginationEnabled = false,
  totalPaginationPages = 1,
  columns = 3,
  component = 'articleTeaser',
  gutterSize = 'medium',
  backgroundColor = 'none',
  accentColor = 'blue',
  showRoundImage = false,
  showPublishedAt = false,
  contentType = 'pages',
  itemsFor,
  ...props
}: Props) {
  const [page, setPage] = useState(1);

  const getCellKey = useCallback((item: RelatedItem) => `${item.id}`, []);

  const carousel = useCarousel({
    slideshow: props.slideshow
  });

  const { items, load } = useItems({
    initialItems: props.items,
    page: page,
    totalPages: totalPaginationPages,
    returnAll: carousel.isActive,
    getCellKey: getCellKey,
    itemsFor: itemsFor
  });

  const { children } = useItemsRenderer({
    getCellKey,
    accentColor,
    backgroundColor,
    showRoundImage,
    showPublishedAt,
    contentType,
    component,
    carousel,
    useGrid: component === 'squareTopicCard',
    columns,
    items
  });

  const { button } = useButton({
    text: buttonText,
    style: buttonStyle,
    url: buttonUrl
  });

  const { pagination, anchorRef, blockRef } = usePagination({
    totalPages: totalPaginationPages,
    enabled: paginationEnabled,
    carousel,
    page,
    load,
    setPage
  });

  return useMemo(() => {
    if (items.length === 0) {
      return null;
    }

    return (
      <BlockBackground backgroundColor={backgroundColor} ref={blockRef}>
        <Container
          width={width}
          paddingTop={padding.top}
          paddingLeft={carousel.isActive ? 'none' : 'small'}
          paddingRight={carousel.isActive ? 'none' : 'small'}
          paddingBottom={padding.bottom}
        >
          <Spacer>
            <div ref={anchorRef} />
            {heading && (
              <Heading style={{ marginLeft: carousel.isActive ? '1rem' : '0' }}>
                {heading}
              </Heading>
            )}
            {carousel.isActive ? (
              <StyledCarousel
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                showArrows={false}
                interval={1000 * 60 * 60}
                children={children}
                centerMode
                centerSlidePercentage={90}
              />
            ) : (
              <Grid
                id={`related-items-${props.id}`}
                aria-live={paginationEnabled && 'polite'}
                gutter={gutterSize}
                justifyContent={justifyContent}
                useGrid={component === 'squareTopicCard'}
                columns={columns}
                children={children}
              />
            )}
            {buttonStyle === 'arrowLink' ? button : null}
            {pagination}
            {buttonStyle === 'arrowLink' ? null : button}
          </Spacer>
        </Container>
      </BlockBackground>
    );
  }, [
    items.length,
    backgroundColor,
    blockRef,
    width,
    padding.top,
    padding.bottom,
    carousel.isActive,
    anchorRef,
    heading,
    children,
    props.id,
    paginationEnabled,
    gutterSize,
    justifyContent,
    component,
    buttonStyle,
    button,
    pagination,
    columns
  ]);
}
