import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import styled from 'styled-components';

import { useValidation } from '~/App/shared/components/Validation';
import { mq } from '~/App/helpers/mq';

import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { QuantityStepper } from '~/App/shared/components/Donation/components/Forms/components/QuantityStepper';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import { TrashCanIcon } from './components/TrashCanIcon';
import { PriceInput } from './components/PriceInput';

import {
  pushGTMDecrease,
  pushGTMIncrease,
  pushGTMRemove
} from '~/App/helpers/gtm-helper';
import { stockStatus } from '~/App/helpers/stock-status';

const CartItem = styled.div`
  display: flex;
  width: 100%;
  height: 7.25rem;
  margin-top: 2rem;

  ${mq('<mediumLarge')`
    padding-bottom: 1.25rem;
    margin-top: 1.5rem;
    height: 6.5rem;
  `};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1.5rem;
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex-grow: 2;

  ${mq('<mediumLarge')`
    margin-left: 0.75rem;
    padding-top: 0;
  `};
`;

const Title = styled.h4`
  font-weight: bold;
  font-size: 1.125rem;
  color: #001489;
`;

const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0.25rem;
`;

const DeleteButtonWrapper = styled.div`
  margin-right: 1.25rem;
  cursor: pointer;
  font-weight: normal;
  font-size: 1.5rem;

  ${mq('<mediumLarge')`
    margin-right: 0.75rem;
    padding-bottom: 0.25rem;
  `};
`;

const ItemPrice = styled.p`
  font-weight: bold;
  padding-top: 0.4rem;
  margin-left: auto;

  ${mq('<mediumLarge')`
    padding-top: 0.5rem;
  `};
`;

type CartItemPictureProps = {
  source?: string;
};

const CartItemPicture = styled.div<CartItemPictureProps>`
  display: flex;
  background-image: ${({ source }) => `url('${source}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 7.25rem;
  height: 7.25rem;
  border-radius: 0.25rem;

  ${mq('<mediumLarge')`
    width: 5.625rem;
    height: 5.625rem;
  `};
`;

const SubTitle = styled.p`
  font-size: 0.9rem;
  font-weight: normal;
  color: #4a4a4a;
  padding-bottom: 0.75rem;

  ${mq('<mediumLarge')`
    padding-bottom: 0;
  `};
`;

const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 0.25rem;
  font-size: 0.75rem;

  ${mq('>small')`
    font-size: 0.875rem;
  `};
`;

type Props = {
  item: ICommerceOrderLine;
  onValidationChange: (id: string, isValid: boolean) => void;
};

export function OrderLineItem({ item, onValidationChange }: Props) {
  const shoppingCart = useShoppingCart();

  const [price, setPrice] = useState(item.price);

  const schema = useMemo(() => {
    if (item.variant?.product?.type !== 'DigitalCertificate') {
      return {};
    }

    if (!item.variant?.price.minimum) {
      return {};
    }

    return {
      price: {
        presence: {
          allowEmpty: false,
          message: '^Ange ett belopp'
        },
        numericality: {
          greaterThan: item.variant?.price.minimum - 1,
          lessThanOrEqualTo: 999999,
          message: `^Minsta gåvobelopp är ${item.variant?.price.minimum} kr`
        }
      }
    };
  }, [item.variant?.price.minimum, item.variant?.product?.type]);

  const validation = useValidation({
    schema,
    values: { price }
  });

  const handleQuantityChange = useCallback(
    (quantity: number) => {
      shoppingCart.update({
        ...item,
        quantity
      });
      const product = {
        id: item.id,
        name: item.variant?.name,
        category: item.variant?.product?.target,
        variant: `${item.variant?.product?.target}/${item.variant?.product?.type}`,
        price: item.price,
        quantity: 1,
        dimension10: stockStatus(item.variant?.stock?.status)
      };
      if (quantity > item.quantity) {
        pushGTMIncrease(product);
      } else {
        pushGTMDecrease(product);
      }
    },
    [item, shoppingCart]
  );

  const handleRemoveItem = useCallback(() => {
    shoppingCart.remove(item);
    onValidationChange(`${item.id}`, true);
    pushGTMRemove({
      id: item.id,
      name: item.variant?.name,
      category: item.variant?.product?.target,
      variant: `${item.variant?.product?.target}/${item.variant?.product?.type}`,
      price: item.price,
      quantity: item.quantity,
      dimension10: stockStatus(item.variant?.stock?.status)
    });
  }, [item, onValidationChange, shoppingCart]);

  const handlePriceBlur = useCallback(() => {
    if (!validation.isValidated) {
      return validation.showAllErrors();
    }

    shoppingCart.update({
      ...item,
      price
    });
  }, [item, price, shoppingCart, validation]);

  const handlePriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value?.replace(/[^0-9]+/g, '');
      const value = parseInt(rawValue, 10);
      setPrice(value);
    },
    []
  );

  const isAvailable = useMemo(() => {
    if (item.variant?.discontinued) {
      return false;
    }

    if (item.variant?.stock?.status === 'OutOfStock') {
      return false;
    }

    return true;
  }, [item.variant?.discontinued, item.variant?.stock?.status]);

  useEffect(() => {
    onValidationChange(`${item.id}`, validation.isValidated);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.isValidated]);

  return (
    <CartItem data-cy="shoppingCart-item">
      <CartItemPicture source={item.variant?.primaryImage} />
      <InfoWrapper>
        <Title>{item.variant?.product?.name}</Title>
        {item.variant?.product?.type === 'DigitalCertificate' && (
          <SubTitle>Digitalt gåvobevis</SubTitle>
        )}
        <QuantityWrapper>
          <DeleteButtonWrapper>
            <TrashCanIcon onClick={handleRemoveItem} />
          </DeleteButtonWrapper>
          {item.variant?.price.type === 'Flexible' ? (
            <PriceInput
              type="tel"
              value={price && !isNaN(price) ? price : ''}
              onChange={handlePriceChange}
              onBlur={handlePriceBlur}
              maxLength={6}
              isInvalid={validation.isInvalid('price')}
              errors={validation.errors['price']}
            />
          ) : (
            <QuantityStepper
              value={item.quantity}
              onChange={handleQuantityChange}
            />
          )}
          <ItemPrice>
            {price && !isNaN(price) ? price * item.quantity : ''} kr
          </ItemPrice>
        </QuantityWrapper>
        {isAvailable ? null : (
          <ErrorText
            children={
              item.variant?.discontinued
                ? 'Produkten har utgått'
                : 'Tillfälligt slut'
            }
          />
        )}
      </InfoWrapper>
    </CartItem>
  );
}
