import React from 'react';
import styled from 'styled-components';

import { heading1 } from '~/App/helpers/mixins';
import { mq } from '~/App/helpers/mq';
import { siteUrl } from '~/App/helpers/env';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div`
  max-width: 72.5rem;
  padding: 4rem 1rem;
  margin: auto;
`;

const Heading = styled.div`
  ${heading1};
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0.5rem;
`;

const Preamble = styled.p`
  color: ${({ theme }) => theme.colors.black};
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 65ch;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 120px;
  margin: 2rem auto;

  ${mq('≥small')`
    margin: 2rem 0;
  `};
`;

export function SupportSection() {
  return (
    <Wrapper>
      <Container>
        <Heading>Råd och stöd</Heading>
        <Heading>010-199 10 10</Heading>
        <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1628851175/Sj%C3%A4lvtest/Headset_RGB.svg" />
        <Preamble>
          Har du funderingar eller frågor om cancer? Kontakta gärna Cancerlinjen
          så får du stöd av vår erfarna och legitimerade vårdpersonal. Ring
          010-199 10 10 eller{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href={siteUrl('/rad-och-stod/cancerlinjen/kontakt')}
            children="mejla oss"
          />
          .
        </Preamble>
      </Container>
    </Wrapper>
  );
}
