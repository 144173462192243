import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

// Helpers
import loadScript from '~/App/helpers/load-script';

const Canvas = styled.canvas`
  margin-top: 1rem;
`;

export default function GraphChartJs({ config = {} }) {
  const chart = useRef();

  useEffect(() => {
    let instance = null;

    loadScript({
      url: `https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.1/Chart.bundle.min.js`
    }).then(() => {
      instance = new window.Chart(chart.current.getContext('2d'), config);
    });

    return () => {
      instance?.destroy();
    };
  }, [chart, config]);

  return <Canvas ref={chart} width={16} height={9} />;
}
