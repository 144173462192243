import { formatMoney } from 'accounting';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';
import { ResearchFinance } from './components/ResearchFinance';
import { ResearchCategoryLine } from './components/ResearchCategoryLine';
import Container from '~/App/shared/components/Container';

const ResearchDataBackground = styled.div`
  background-color: ${(props) => props.theme.colors.lightPolar};
`;

type ResearchDataContainerProps = {
  linesDisplay: string;
};

const ResearchDataContainer = styled(Container)<ResearchDataContainerProps>`
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq<ResearchDataContainerProps>('>small')`
  flex-direction: row;
    justify-content: ${({ linesDisplay }) =>
      linesDisplay === 'block' ? 'flex-start' : 'space-between'};
  `};
`;

type ResearchCategoryLinesProps = {
  linesDisplay: string;
};

const ResearchCategoryLines = styled.div<ResearchCategoryLinesProps>`
  display: ${({ linesDisplay }) => linesDisplay};

  ${mq<ResearchCategoryLinesProps>('>small')`
    column-gap: 3.5rem;
    row-gap: 10px;
    width: ${({ linesDisplay }) => `${linesDisplay === 'block' ? '65%' : ''}`};
    padding-left: ${({ linesDisplay }) =>
      `${linesDisplay === 'block' ? '4rem' : '0rem'}`};
    grid-template-columns: auto auto auto;
  `};
  ${mq('<medium')`
    column-gap: 1.5rem;
  `};
  ${mq('<smallMedium')`
    grid-template-columns: auto auto;
    column-gap: 2.75rem;
    row-gap: unset;
  `};
  ${mq('<small')`
    grid-template-columns: auto auto auto;
    column-gap: 1.5rem;
    row-gap: 10px;
  `};
  ${mq('<tinySmall')`
    grid-template-rows: repeat(2, 1fr) 1.5fr;
    row-gap: unset;
    grid-template-columns: none;
  `};
`;

export type CategoryLine = {
  label: string;
  title: string | HTMLAnchorElement[] | any;
  hoverContent?: string[];
};
type Props = {
  amount: number;
  currency: string;
  categoryLines: CategoryLine[];
  linesDisplay?: string;
};

export const ResearchInformation = ({
  amount,
  currency,
  categoryLines,
  linesDisplay = 'block'
}: Props) => {
  const renderLine = useCallback(
    (line: CategoryLine) => (
      <ResearchCategoryLine
        key={line.label + line.title}
        label={line.label}
        title={line.title}
        hoverContent={line.hoverContent}
      />
    ),
    []
  );

  return (
    <ResearchDataBackground>
      <ResearchDataContainer
        width="limited"
        paddingLeft="small"
        paddingRight="small"
        paddingTop="tiny"
        paddingBottom="small"
        linesDisplay={linesDisplay}
      >
        <ResearchFinance
          label="Finansieras med"
          amount={formatMoney(amount, '', 0, ' ', '', '%v %s')}
          currency={currency}
          isSearchPage={linesDisplay !== 'block'}
        />
        <ResearchCategoryLines linesDisplay={linesDisplay}>
          {categoryLines.map(renderLine)}
        </ResearchCategoryLines>
      </ResearchDataContainer>
    </ResearchDataBackground>
  );
};
