import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import styled from 'styled-components';

// Helpers
import { collections } from '~/App/helpers/http';
import mq from '~/App/helpers/mq';

// Shared components
import { InputWrapper } from '~/App/shared/components/Fields/InputWrapper';
import TextArea from '~/App/shared/components/Fields/TextArea';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { AccountColumn } from '~/App/views/Account/shared/components/AccountColumn';
import { Redirect } from '~/App/shared/components/Redirect';
import {
  AccountHeading1,
  AccountHeading2
} from '~/App/views/Account/shared/components/AccountHeadings';
import { ICollection } from '~/types/ICollection';
import { useNavigate, useParams } from 'react-router-dom';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  > * {
    margin-top: 1rem;
  }

  ${mq('≥small')`
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-top: 4rem;

    > * {
      margin-top: 0;
      margin-left: 1rem;
    }
  `};
`;

type Props = {
  data: ICollection;
};

export function MemorialDisabled({ data }: Props) {
  const [disabledMessage, setDisabledMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const params = useParams<'slug'>();
  const navigate = useNavigate();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setDisabledMessage(event.target.value);
    },
    []
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      setLoading(true);

      try {
        await collections.disabled({
          slug: `${params.slug}`,
          data: { disabledMessage }
        });

        setLoading(false);
        navigate('/min-sida/minnessidor');
      } catch (error) {
        console.log(error);

        setLoading(false);
      }
    },
    [isLoading, navigate, params.slug, disabledMessage]
  );

  return (
    <>
      {data.isDisabled && <Redirect to="/min-sida/minnessidor" />}
      <AccountColumn>
        <form onSubmit={handleSubmit}>
          <AccountHeading1 children={`Avsluta "${data.name}"`} />
          <p>
            Om du ta bort minnessidan kommer besökare inte längre kunna se, läsa
            och skriva minnen. Minnessidan kommer inte längre vara sökbar eller
            visas bland andra minnessidor.
          </p>
          <AccountHeading2>Varför vill du ta bort Minnessidan?</AccountHeading2>
          <InputWrapper>
            <TextArea
              name="enableMessage"
              value={disabledMessage}
              onChange={handleChange}
              placeholder="Skriv.."
              autoComplete="off"
            />
          </InputWrapper>
          <ButtonWrapper>
            <PrimaryButton
              buttonType="button"
              type="submit"
              buttonStyle="primary"
              isLoading={isLoading}
              children="Ta bort och stäng"
            />
            <PrimaryButton
              buttonType="link"
              buttonStyle="outlinePrimary"
              children="Avbryt"
              to={`/min-sida/minnessidor/${data.slug}`}
            />
          </ButtonWrapper>
        </form>
      </AccountColumn>
    </>
  );
}
