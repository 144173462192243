import React, { useMemo, useCallback, MouseEvent, ComponentProps } from 'react';
import styled from 'styled-components';

// Config
import { paymentMethods } from '~/App/config/paymentMethods.js';

// Shared components
import {
  Item,
  Link,
  Navigation,
  Content
} from '~/App/shared/components/TabBox';

import { AutoGiroBankIdFields } from './AutoGiroBankIdFields';
import { AutoGiroSlipFields } from './AutoGiroSlipFields';

const MarginTop = styled.div`
  margin-top: 1rem;
`;

type AutoGiroBankIdProps = ComponentProps<typeof AutoGiroBankIdFields>;
type AutoGiroSlipProps = ComponentProps<typeof AutoGiroSlipFields>;
type Props = AutoGiroSlipProps &
  AutoGiroBankIdProps & {
    allowSlip: boolean;
    allowBankId: boolean;
  };

export function AutoGiroFields({
  values,
  fetchBankAccount,
  allowSlip,
  allowBankId,
  validation
}: Props) {
  const handlePaymentMethod = useCallback(
    (paymentMethodId: number) => (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      values.handlers.handlePaymentMethod({
        id: paymentMethodId,
        name: 'Autogiro',
        slug: 'autoGiro'
      });
    },
    [values.handlers]
  );

  const isActive = useCallback(
    (paymentMethodId: number) => values.paymentMethod?.id === paymentMethodId,
    [values.paymentMethod?.id]
  );

  const tabbedPaymentMethods = useMemo(
    () => (
      <div>
        <Navigation>
          <Item isActive={isActive(paymentMethods.autoGiroBankId)}>
            <Link
              children="Mobilt BankID"
              onClick={handlePaymentMethod(paymentMethods.autoGiroBankId)}
            />
          </Item>
          <Item isActive={isActive(paymentMethods.autoGiroSlip)}>
            <Link
              children="Skicka blankett"
              onClick={handlePaymentMethod(paymentMethods.autoGiroSlip)}
            />
          </Item>
        </Navigation>
        <Content>
          {isActive(paymentMethods.autoGiroBankId) && (
            <MarginTop>
              <AutoGiroBankIdFields
                values={values}
                validation={validation}
                fetchBankAccount={fetchBankAccount}
              />
            </MarginTop>
          )}

          {isActive(paymentMethods.autoGiroSlip) && (
            <MarginTop>
              <AutoGiroSlipFields values={values} validation={validation} />
            </MarginTop>
          )}
        </Content>
      </div>
    ),
    [fetchBankAccount, handlePaymentMethod, isActive, validation, values]
  );

  return useMemo(() => {
    if (allowSlip && allowBankId) {
      return tabbedPaymentMethods;
    }

    if (allowSlip) {
      return (
        <MarginTop>
          <AutoGiroSlipFields values={values} validation={validation} />
        </MarginTop>
      );
    }

    if (allowBankId) {
      return (
        <MarginTop>
          <AutoGiroBankIdFields
            values={values}
            validation={validation}
            fetchBankAccount={fetchBankAccount}
          />
        </MarginTop>
      );
    }

    return null;
  }, [
    allowBankId,
    allowSlip,
    fetchBankAccount,
    tabbedPaymentMethods,
    validation,
    values
  ]);
}
