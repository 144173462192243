import React from 'react';
import classNames from 'classnames';

export default function Button(props) {
  const {
    block,
    children,
    isActive,
    modifiers = [],
    isLoading,
    isDisabled,
    ...rest
  } = props;

  const buttonModifiers = modifiers.map((modifier) => `${block}--${modifier}`);

  const buttonState = {
    'is-active': isActive,
    'is-loading': isLoading,
    'is-disabled': isDisabled
  };

  const buttonClasses = classNames(block, buttonModifiers, buttonState);

  return (
    <button
      className={buttonClasses}
      disabled={isDisabled}
      type="submit"
      {...rest}
    >
      {children}
    </button>
  );
}
