import React, { useMemo } from 'react';
import styled from 'styled-components';

import { formatDate } from '~/App/helpers/date.service';
import { mq } from '~/lib/mq';

const Text = styled.time`
  display: block;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  margin: 1rem 0;
  padding: 0;

  ${mq('≥small')`
  font-size: 1.125rem;
`};
`;

type Props = {
  value: string | null;
};

export function PublishedAt({ value }: Props) {
  return useMemo(() => {
    if (!value) {
      return null;
    }

    return (
      <Text
        dateTime={formatDate(value, 'YYYY-MM-DD')}
        children={formatDate(value, 'D MMMM YYYY')}
      />
    );
  }, [value]);
}
