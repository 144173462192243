import { navigation } from '~/App/config/navigation';
import { isActive } from './menu-item-is-active';

export const isSubMenuActive = (pathname) => {
  const menuItem = navigation.primaryMenuItems.find(
    ({ url, alternativeActiveUrls }) => {
      return isActive({ url, pathname, alternativeActiveUrls });
    }
  );

  return menuItem && menuItem.menuItems.length > 0;
};
