import React from 'react';
import styled from 'styled-components';
import { heading2 } from '~/App/helpers/mixins';
import {
  Heading1 as HeroHeading1,
  Heading2 as HeroHeading2
} from '../../../../components/Blocks/components/BlockHero/components/Heading';
import { Width } from '~/types/Block';

const Heading1 = styled(HeroHeading1)`
  text-align: center;
`;

const Heading2 = styled.h2`
  ${heading2};
  text-align: center;
`;

const Heading2Hero = styled(HeroHeading2)`
  text-align: center;
`;
type Props = {
  heading: string;
  fontSize?: string;
  width: Width;
};

export function Heading({ heading, fontSize, width }: Props) {
  if (!heading) {
    return null;
  }

  if (fontSize === 'heading1') {
    return <Heading1 children={heading} />;
  }

  if (fontSize === 'heading2') {
    return width === 'full' ? (
      <Heading2Hero children={heading} />
    ) : (
      <Heading2 children={heading} />
    );
  }

  return null;
}
