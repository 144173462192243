import React from 'react';
import styled from 'styled-components';
import { heading1, heading2 } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

export const Heading1 = styled.h1`
  ${heading1};
  font-size: 2rem;
  line-height: 1.15625;
  word-wrap: break-word;

  ${mq('≥large')`
    font-size: 5.625rem;
    line-height: 1;
  `};
`;

export const Heading2 = styled.h2`
  ${heading2};
  font-size: 2rem;
  line-height: 1.15625;
  word-wrap: break-word;

  ${mq('≥large')`
  font-size: 3.125rem;
  line-height: 1.2;
`};
`;

const Heading3 = styled.h3`
  ${heading2};
  font-size: 2rem;
  line-height: 1.15625;

  ${mq('≥large')`
  font-size: 3.125rem;
  line-height: 1.2;
`};
`;

type Props = {
  heading: string;
  fontSize?: string;
};

export function Heading({ heading, fontSize }: Props) {
  if (!heading) {
    return null;
  }

  if (fontSize === 'heading1') {
    return <Heading1 children={heading} />;
  }

  if (fontSize === 'heading2') {
    return <Heading2 children={heading} />;
  }

  if (fontSize === 'heading3') {
    return <Heading3 children={heading} />;
  }

  return null;
}
