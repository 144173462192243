import { matchRoutes, RouteMatch, RouteObject } from 'react-router-dom';

import { LoadData, RouteConfig } from '~/types/routes';

type Arguments = {
  query: Record<string, any>;
  cookies: Record<string, any>;
  oldData?: unknown;
};

type Match = RouteMatch<string> & {
  route?: RouteObject & {
    component?: {
      loadData: LoadData;
    };
  };
};

export async function loadData(
  url: string,
  routes: RouteConfig[],
  args: Arguments
) {
  const matches = matchRoutes(routes, url);

  if (!matches) {
    return {};
  }

  const promises = matches.map(getPromise(args));
  const data = await Promise.all(promises);
  const filteredData = data.filter(notNull);
  const combinedData = filteredData.reduce(
    (combined: any, current: any) => ({
      ...combined,
      ...current
    }),
    {}
  );

  return combinedData;
}

function notNull<T>(x?: T) {
  return x !== null;
}

function getPromise(props: Arguments) {
  return function ({ route, ...args }: Match) {
    if (!route.component?.loadData) {
      return Promise.resolve(null);
    }

    return route.component?.loadData({ route, ...props, ...args });
  };
}
