import validate from 'validate.js';

import validateSsn from '~/App/helpers/validate-ssn';
import validateCid from '~/App/helpers/validate-cid';
import { isLegalAge, isValidDate } from '~/App/helpers/date.service';

import * as creditCard from '~/App/helpers/credit-card';

type Options = {
  message?: string;
  maxLengthMessage?: string;
  noInputMessage?: string;
  allowEmpty?: boolean;
};

validate.validators.validateSsn = (value: string, options: Options) => {
  const {
    message = '^Du måste ange ett korrekt personnummer',
    allowEmpty = false
  } = options;

  if (allowEmpty && validate.isEmpty(value)) {
    return null;
  }

  if (validateSsn(value) && value.length === 12) {
    return null;
  }

  return message;
};

validate.validators.validateLegalAge = (value: string, options: Options) => {
  const { message = '^Du måste vara över 18 år', allowEmpty = false } = options;

  if (allowEmpty && validate.isEmpty(value)) {
    return null;
  }

  if (isLegalAge(value.slice(0, 8))) {
    return null;
  }

  return message;
};

validate.validators.validateCid = (value: string, options: Options) => {
  const { message = '^Ej giltigt org.nummer' } = options;

  if (!validate.isEmpty(value) && validateCid(value)) {
    return null;
  }

  return message;
};

validate.validators.validateIsTrue = (value: boolean, options: Options) => {
  const { message = '^Måste vara vald' } = options;

  if (value === true) {
    return null;
  }

  return message;
};

validate.validators.validateEmail = (value: string, options: Options) => {
  const {
    noInputMessage = '^E-post krävs',
    maxLengthMessage = '^E-postadressen ser lite lång ut. Använd max 320 tecken',
    message = '^Felaktig e-postadress'
  } = options;

  if (value.length > 320) {
    return maxLengthMessage;
  }

  if (validate.isEmpty(value) && value.length < 1) {
    return noInputMessage;
  }

  const validationMessages = validate.single(value, {
    email: {
      message: message
    }
  });

  if (validationMessages) {
    return `^${validationMessages[0]}`;
  }

  return null;
};

validate.validators.validateOptionalEmail = (
  value: string,
  options: Options
) => {
  const {
    message = '^Felaktig e-postadress',
    maxLengthMessage = '^E-postadressen ser lite lång ut. Använd max 320 tecken'
  } = options;

  if (value === '') {
    return null;
  }

  if (value.length > 320) {
    return maxLengthMessage;
  }

  const validationMessages = validate.single(value, {
    email: {
      message: message
    }
  });

  if (validationMessages) {
    return `^${validationMessages[0]}`;
  }
  return null;
};

validate.validators.validateCompany = (value: string, options: Options) => {
  const { message = '^Du måste ange ett företagsnamn' } = options;

  if (!validate.isEmpty(value) && value.length > 1) {
    return null;
  }

  return message;
};

validate.validators.validateFirstName = (value: string, options: Options) => {
  const { message = '^Felaktigt förnamn', allowEmpty = false } = options;

  if (!value) {
    return message;
  }

  if ((allowEmpty && validate.isEmpty(value)) || value.length > 1) {
    return null;
  }

  return message;
};

validate.validators.validateLastName = (value: string, options: Options) => {
  const { message = '^Felaktigt efternamn', allowEmpty = false } = options;

  if (!value) {
    return message;
  }

  if ((allowEmpty && validate.isEmpty(value)) || value.length > 1) {
    return null;
  }

  return message;
};

type ValidateContactsAttributes = {
  productOptions: {
    customerContact: {
      lastName?: string;
      firstName?: string;
    };
    inMemoryOfContact: {
      lastName: string;
      firstName: string;
    };
  };
};

validate.validators.validateContacts = (
  _value: unknown,
  options: Options,
  _key: unknown,
  attributes: ValidateContactsAttributes
) => {
  const { message = '^Avliden samma som kontakt' } = options;
  const {
    productOptions: {
      customerContact: {
        lastName: customerLastName = '',
        firstName: customerFirstName = ''
      },
      inMemoryOfContact: {
        lastName: inMemoryOfLastName = '',
        firstName: inMemoryOfFirstName = ''
      }
    }
  } = attributes;

  if (
    inMemoryOfFirstName.toLowerCase() !== customerFirstName.toLowerCase() ||
    inMemoryOfLastName.toLowerCase() !== customerLastName.toLowerCase()
  ) {
    return null;
  }

  return message;
};

validate.validators.validatePhoneNumber = (value: string, options: Options) => {
  const {
    message = '^Formatet för telefonnummer är 070XXXXXXX',
    allowEmpty
  } = options;

  if (allowEmpty) {
    if (!value) {
      return null;
    }
  }

  if ((value.match(/\d/g) || []).length === 10) {
    return null;
  }

  return message;
};

validate.validators.validateZipCode = (value: string, options: Options) => {
  const { message = '^Felaktigt postnummer' } = options;

  if (value.match(/^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/g)) {
    return null;
  }

  return message;
};

validate.validators.validateStreetName = (value: string, options: Options) => {
  const { message = '^Felaktig adress' } = options;

  if (!validate.isEmpty(value) && value.length > 1) {
    return null;
  }

  return message;
};

validate.validators.validateCity = (value: string, options: Options) => {
  const { message = '^Felaktig postort' } = options;

  if (!validate.isEmpty(value) && value.length > 1) {
    return null;
  }

  return message;
};

validate.validators.validateCardNumber = (value: string, options: Options) => {
  const { message = '^Ogiltigt kortnummer' } = options;

  if (creditCard.validateCardNumber(value)) {
    return null;
  }

  return message;
};

validate.validators.validateCardExpiry = (value: string, options: Options) => {
  const { message = '^Ogiltigt datum' } = options;

  const expiry = creditCard.cardExpiryValue(value);

  if (creditCard.validateCardExpiry(expiry)) {
    return null;
  }

  return message;
};

validate.validators.validateCardCvc = (value: string, options: Options) => {
  const { message = '^Ogiltigt CVC' } = options;

  let number = value;

  if (validate.isNumber(number)) {
    number = number.toString();
  }

  if (!validate.isEmpty(number) && number.length >= 3 && number.length <= 4) {
    return null;
  }

  return message;
};

validate.validators.validateDeliverPaper = (
  value: string,
  options: Options
) => {
  const { message = '^Du måste välja leveranssätt' } = options;

  if (!validate.isEmpty(value) || value === 'send' || value === 'print') {
    return null;
  }

  return message;
};

validate.validators.validateBank = (value: string, options: Options) => {
  const { message = '^Du måste välja en bank' } = options;

  if (!validate.isEmpty(value)) {
    return null;
  }

  return message;
};

validate.validators.validateAccountNumber = (
  value: string,
  options: Options
) => {
  const { message = '^Du måste ange ett kontonummer' } = options;

  if (!validate.isEmpty(value)) {
    return null;
  }

  return message;
};

validate.validators.validateClearingNumber = (
  value: string,
  options: Options
) => {
  const { message = '^Du måste ange ett clearingnummer' } = options;

  if (!validate.isEmpty(value)) {
    return null;
  }

  return message;
};

validate.validators.validateMemorialDate = (
  value: string,
  options: Options
) => {
  const regex = /(^[0-9]{4}-[0-1][0-9]-[0-3][0-9]$)/;
  const { message = '^Felaktigt datumformat', allowEmpty = false } = options;

  if (allowEmpty && validate.isEmpty(value)) {
    return null;
  }

  if (isValidDate(value, 'YYYY-MM-DD') && regex.test(value)) {
    return null;
  }

  return message;
};

validate.validators.numericalityOrEmpty = (
  value: unknown,
  options: Options
) => {
  if (validate.isEmpty(value)) {
    return null;
  }

  return validate.validators.numericality(value, options);
};

export default validate;
