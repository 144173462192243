import React from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { merge } from 'lodash';

// Helpers
import * as themes from '~/App/config/themes';

// Shared components
import Container from '~/App/shared/components/Container';
import { MemorialGift } from '~/App/shared/components/Donation';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPolar};
`;

class MemorialGiftForm extends React.Component {
  render() {
    const theme = merge({}, themes.global);
    const { data, component } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Container
            width="limited"
            paddingLeft="small"
            paddingRight="small"
            paddingTop="small"
            paddingBottom="small"
          >
            <MemorialGift data={data} component={component} />
          </Container>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

export default MemorialGiftForm;
