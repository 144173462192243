import { useMemo } from 'react';
import { paymentMethods } from '~/App/config/paymentMethods';

import { swishTypes } from '~/App/config/swishTypes';
import { LotteryTicketStateValues } from '../components/States';

export function useSchema() {
  return useMemo(
    () => ({
      'productOptions.lotteryTicket.quantity': {
        presence: {
          allowEmpty: false,
          message: '^Ange antal lotter'
        },
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 500,
          message: '^Du kan beställa mellan 1 och 500 lotter'
        }
      },
      'productOptions.customerContact.ssn': {
        validateSsn: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) => customerContactType !== 'company',
        validateLegalAge: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) => customerContactType !== 'company'
      },
      'productOptions.customerContact.company': {
        validateCompany: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) => customerContactType === 'company'
      },
      'productOptions.customerContact.cid': {
        validateCid: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) => customerContactType === 'company'
      },
      'productOptions.customerContact.addressStreet': {
        validateStreetName: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) => customerContactType === 'manual'
      },
      'productOptions.customerContact.addressZip': {
        validateZipCode: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) => customerContactType === 'manual'
      },
      'productOptions.customerContact.addressCity': {
        validateCity: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) => customerContactType === 'manual'
      },
      'productOptions.customerContact.firstName': {
        validateFirstName: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) =>
          customerContactType === 'manual'
            ? { allowEmpty: false }
            : { allowEmpty: true }
      },
      'productOptions.customerContact.lastName': {
        validateLastName: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) =>
          customerContactType === 'manual'
            ? { allowEmpty: false }
            : { allowEmpty: true }
      },
      'productOptions.customerContact.phoneNumber': {
        validatePhoneNumber: (
          _: unknown,
          { customerContactType }: LotteryTicketStateValues
        ) => customerContactType === 'company'
      },
      'productOptions.customerContact.email': {
        validateEmail: (
          _: unknown,
          { paymentMethod }: LotteryTicketStateValues
        ) => paymentMethod?.id !== paymentMethods.klarnaPayments
      },
      'mobileBankId.bank': {
        validateBank: (
          _: unknown,
          { paymentMethod }: LotteryTicketStateValues
        ) => paymentMethod?.id === paymentMethods.autoGiroBankId
      },
      'mobileBankId.accountNumber': {
        validateAccountNumber: (
          _: unknown,
          { paymentMethod }: LotteryTicketStateValues
        ) => paymentMethod?.id === paymentMethods.autoGiroBankId
      },
      'mobileBankId.clearingNumber': {
        validateClearingNumber: (
          _: unknown,
          { paymentMethod }: LotteryTicketStateValues
        ) => paymentMethod?.id === paymentMethods.autoGiroBankId
      },
      'klarna.isReady': {
        inclusion: (
          _: unknown,
          { paymentMethod }: LotteryTicketStateValues
        ) => {
          if (paymentMethod?.id === paymentMethods.klarnaPayments) {
            return {
              within: [true],
              message: '^Klarna är inte redo'
            };
          }

          return false;
        }
      },
      swishPhoneNumber: {
        validatePhoneNumber: (
          _: unknown,
          { paymentMethod, swish }: LotteryTicketStateValues
        ) => paymentMethod?.id === 8 && swish.swishType === swishTypes.ecommerce
      }
    }),
    []
  );
}
