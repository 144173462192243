import React from 'react';
import styled, { keyframes } from 'styled-components';

import { ICommerceShipping } from '~/types/ICommerceShipping';
import { downloadLink, externalLink } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadLink = styled.a`
  ${downloadLink};
  margin-top: 0.25rem;
`;

const ExternalLink = styled.a`
  ${externalLink};
  margin-top: 0.25rem;
`;

const CheckmarkIcon = styled.div`
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.75rem;
  background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1636548896/assets/icons/icons-ui-checkmark-icon-font.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const glowing = keyframes`
  0% { 
    background-color: #20a922; 
    box-shadow: 0 0 0.125rem #20a922; 
  }
  50% { 
    background-color: #20a922; 
    box-shadow: 0 0 1rem #20a922; 
  }
  100% { 
    background-color: #20a922; 
    box-shadow: 0 0 0.125rem #20a922; 
    }
`;

const WorkingIcon = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.apple};
  animation: ${glowing} 1500ms infinite;
  margin-right: 0.75rem;
`;

const Text = styled.div`
  font-size: 1.125rem;

  ${mq('<mediumLarge')`
    font-size: 1rem;  
  `};
`;

type Props = {
  shipment: ICommerceShipping | undefined;
};

export function ShipmentStatus({ shipment }: Props) {
  if (shipment?.pdfUrl) {
    return (
      <Row>
        <CheckmarkIcon />
        <DownloadLink
          href={shipment.pdfUrl}
          target="_blank"
          children="Gåvobevis"
        />
      </Row>
    );
  }

  if (shipment?.trackingUrl) {
    return (
      <Row>
        <CheckmarkIcon />
        <ExternalLink
          href={shipment?.trackingUrl}
          target="_blank"
          children="Spåra leverans"
        />
      </Row>
    );
  }

  if (shipment?.status === 'Delivered') {
    return (
      <Row>
        <CheckmarkIcon />
        <Text children="Skickat" />
      </Row>
    );
  }

  return (
    <Row>
      <WorkingIcon />
      <Text children="Bearbetas" />
    </Row>
  );
}
