export const schema = {
  name: {
    length: {
      minimum: 5,
      message: '^Minst 5 bokstäver'
    },
    presence: {
      allowEmpty: false,
      message: '^Ange ett namn'
    }
  },
  imageUrl: {
    presence: {
      allowEmpty: false,
      message: '^Fel'
    }
  },
  birthDate: {
    presence: {
      allowEmpty: false,
      message: '^Ange personens födelsedag'
    }
  },
  deathDate: {
    presence: {
      allowEmpty: false,
      message: '^Ange personens dödsdag'
    }
  }
};
