import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';
import { link, externalLink } from '~/App/helpers/mixins';

const Image = styled.img`
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
`;

const Link = styled.a`
  ${link};
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 0.25rem);
  margin-bottom: 1rem;

  ${mq('<tiny')`
    &:nth-child(odd) {
      margin-right: 0.25rem;
    }

    &:nth-child(even) {
      margin-left: 0.25rem;
    }
  `};

  ${mq('≥tiny')`
    flex-basis: calc(33.333333333% - 0.375rem);

    &:nth-child(3n+2) {
      margin-right: 0.5625rem;
      margin-left: 0.5625rem;
    }
  `};

  .no-touchevents & {
    &:focus,
    &:hover {
      ${Image} {
        transform: scale(1.1);
      }
    }
  }
`;

const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
`;

const ImageWrapper = styled.div`
  display: block;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  z-index: 2;

  ${Image} {
    z-index: 1;
  }
`;

const ExternalLink = styled.p`
  ${externalLink}
  font-size: 1rem;
  line-height: 1.375rem;
  padding-right: 1.75rem;

  ${mq('≥small')`
    font-size: 1.125rem;
    line-height: 1.5rem;
  `};
`;

export const FooterImage = (props) => {
  const { url, description, title } = props.image;

  return (
    <Link href={url} target="_blank">
      <Wrapper>
        <ImageWrapper>
          <picture>
            <source
              srcSet={`
            ${mediaHelper.getUrl(url, {
              aspect_ratio: 1,
              crop: 'fill',
              gravity: 'faces',
              width: 364
            })} 2x,
            ${mediaHelper.getUrl(url, {
              aspect_ratio: 1,
              crop: 'fill',
              gravity: 'faces',
              width: 182
            })} 1x
          `}
            />
            <Image
              src={mediaHelper.getUrl(url, {
                aspect_ratio: 1,
                crop: 'fill',
                gravity: 'faces',
                width: 364
              })}
              alt={description}
            />
          </picture>
        </ImageWrapper>
      </Wrapper>
      <ExternalLink>{title}</ExternalLink>
    </Link>
  );
};
