import axios, { AxiosError } from 'axios';

type StartArguments<T> = {
  fn: () => Promise<T>;
  done: (data: T) => boolean;
  delay?: number;
  timeout?: number;
  interval?: number;
  onEach?: (data: T) => void;
};

export const poll = {
  start: <T>({
    fn,
    done,
    delay = 0,
    timeout = 30000,
    interval = 3000,
    onEach
  }: StartArguments<T>) => {
    const endTime = Number(new Date()) + timeout;
    const checkCondition = (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void
    ) => {
      const request = fn();

      return request
        .then((response) => {
          if (typeof onEach === 'function') {
            onEach(response);
          }

          if (done(response)) {
            return resolve(response);
          } else if (Number(new Date()) < endTime) {
            return setTimeout(checkCondition, interval, resolve, reject);
          } else {
            return reject(new Error('PollTimeoutError'));
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    };

    if (delay !== 0) {
      return new Promise<T>((resolve, reject) => {
        setTimeout(() => {
          checkCondition(resolve, reject);
        }, delay);
      });
    }

    return new Promise<T>(checkCondition);
  },
  isCancel: axios.isCancel,
  isTimeout: (error: AxiosError) => error.message === 'PollTimeoutError',
  CancelToken: axios.CancelToken
};
