import React from 'react';
import styled from 'styled-components';

// Helpers
import { heading2, preamble } from '~/App/helpers/mixins';
import {
  AsyncCertificateDocumentLink,
  AsyncReceiptDocumentLink
} from '~/App/shared/components/AsyncDocumentLink';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';

// Shared components
import { Content, Wrapper } from '~/App/shared/components/ThanksPage';
import { IPurchaseShow } from '~/types/IPurchaseShow';

const Heading = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const WrappedWrapper = styled(Wrapper)`
  margin-top: 1.5rem;
`;

const WrappedContent = styled(Content)`
  padding: 4rem 0;
`;

type Props = {
  heading: string;
  purchase: IPurchaseShow;
};

export function ReceiptAndCertificate({ heading, purchase }: Props) {
  const email = purchase?.purchase?.productOptions?.customerContact?.email;
  const receiptText = email
    ? `Vi har skickat gåvobevis och kvitto till ${email} men det går också bra att ladda ner dem här:`
    : null;

  return (
    <WrappedWrapper>
      <WrappedContent>
        <Heading children={heading} />
        <FetchPurchaseDocuments purchase={purchase}>
          {({ receiptStatus, receipt, certificate, certificateStatus }) => (
            <>
              <Preamble children={receiptText} />
              <AsyncReceiptDocumentLink
                url={receipt}
                status={receiptStatus}
                paymentMethodId={purchase?.paymentMethod?.id}
              />

              <AsyncCertificateDocumentLink
                url={certificate}
                status={certificateStatus}
              />
            </>
          )}
        </FetchPurchaseDocuments>
      </WrappedContent>
    </WrappedWrapper>
  );
}
