import React, { useCallback, useMemo } from 'react';
import { OptionProps } from 'react-select';
import { last } from 'lodash';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { outlinePrimaryButton, link } from '~/App/helpers/mixins';
import { IDeceasedContact } from '~/types/IDeceasedContact';
import { angleDown } from '~/App/helpers/icons';
import { Spinner } from '~/App/shared/components/Elements';
import { mq } from '~/lib/mq';

import { PersonNotFound } from './PersonNotFound';

type WrapperProps = {
  isLast?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ isLast }) => isLast && '1rem'};
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: 1.125rem;
  font-weight: bold;

  ${mq('<small')`
    font-size: 1rem;
  `}
`;

const Text = styled.div`
  font-size: 0.875rem;

  ${mq('<small')`
    font-size: 0.75rem;
  `}
`;

const ButtonWrapper = styled(Wrapper)`
  margin: 0.5rem 1rem;
  padding: 0;
`;

const Button = styled.button`
  flex-shrink: 1;
  margin-left: 1rem;
  font-size: 1rem;

  ${mq('<small')`
    ${link};
    border: none;
    background: none;
    padding: 0;
    text-decoration: underline;
  `};

  ${mq('≥small')`
    ${outlinePrimaryButton};
    min-width: 6rem;
  `};
`;

const StyledSpinner = styled(Spinner)`
  height: 1rem;
  width: 1rem;
`;

const MoreButton = styled.button`
  color: ${({ theme }) => theme.colors.darkBlue};
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;

  ${mq('>small')`
    font-size: 1.125rem;
  `};

  &:hover {
    color: ${({ theme }) => theme.colors.hoverBlue};
  }

  :after {
    ${angleDown};
    margin-left: 0.25rem;
    top: 1px;
  }
`;

type Props = OptionProps<IDeceasedContact, false>;
type BuildOptionProps = {
  loadMore: (() => void) | null;
  loading: boolean;
  openModal: () => void;
};

export function buildOption({
  loadMore,
  loading,
  openModal
}: BuildOptionProps) {
  return function Option({ data, options, selectOption, clearValue }: Props) {
    const handleClick = useCallback(() => selectOption(data), [
      selectOption,
      data
    ]);

    const isLast = useMemo(() => data.id === last<any>(options)?.id, [
      data.id,
      options
    ]);

    const subLabel = useMemo(() => {
      const place =
        data.death?.place || data.birth?.place || data.funeral?.place;
      const birthDate = data.birth?.date ? dayjs(data.birth.date) : null;
      const birthDateFormatted = birthDate?.format('YYYY');
      const deathDate = data.death?.date ? dayjs(data.death.date) : null;
      const deathDateFormatted = deathDate?.format('D MMM YYYY');

      const lifeSpan = [birthDateFormatted, deathDateFormatted]
        .filter((x) => x)
        .join(' - ');

      return [lifeSpan, place].filter((x) => x).join(' • ');
    }, [
      data.birth?.date,
      data.birth?.place,
      data.death?.date,
      data.death?.place,
      data.funeral?.place
    ]);

    const loadMoreButton = useMemo(() => {
      if (!isLast) {
        return null;
      }

      if (loading) {
        return (
          <Wrapper>
            <StyledSpinner size="1rem" />
          </Wrapper>
        );
      }

      if (loadMore) {
        return (
          <ButtonWrapper>
            <MoreButton type="button" onClick={loadMore} children="Visa fler" />
          </ButtonWrapper>
        );
      }

      return null;
    }, [isLast]);

    return (
      <>
        <Wrapper isLast={isLast}>
          <Content>
            <Title>
              {data.firstName} {data.lastName}
            </Title>
            <Text children={subLabel} />
            {data.funeralHome?.name && (
              <Text children={data.funeralHome?.name} />
            )}
          </Content>
          <div>
            <Button
              onClick={handleClick}
              data-cy="bitnet-select"
              children="Välj"
            />
          </div>
        </Wrapper>
        {loadMoreButton}
        {isLast && (
          <PersonNotFound
            openModal={() => {
              clearValue();
              openModal();
            }}
          />
        )}
      </>
    );
  };
}
