import React, { ReactNode, useMemo } from 'react';
import { CustomerContactType } from '~/App/config/customerContactTypes';

import { Tabs } from '~/App/shared/components/Tabs';
import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../States';

import { Automatic, AutomaticProps } from './components/Automatic';
import { Manual, ManualProps } from './components/Manual';
import { Company, CompanyProps } from './components/Company';
import { TaxReductionInfo } from './components/TaxReductionInfo';

type Tab = {
  label: string;
  value: CustomerContactType;
  content: ReactNode;
};

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;

  taxReductionInfo?: boolean | string;

  automatic?: boolean | AutomaticProps;
  manual?: boolean | ManualProps;
  company?: boolean | CompanyProps;
};

export function TabbedContactFields({
  values,
  validation,
  company,
  manual,
  automatic,
  taxReductionInfo
}: Props) {
  const props = useMemo(
    () => ({
      values,
      validation
    }),
    [validation, values]
  );

  const automaticProps = useMemo<AutomaticProps>(
    () => (typeof automatic === 'object' ? automatic : {}),
    [automatic]
  );

  const manualProps = useMemo<ManualProps>(
    () => (typeof manual === 'object' ? manual : {}),
    [manual]
  );

  const companyProps = useMemo<CompanyProps>(
    () => (typeof company === 'object' ? company : {}),
    [company]
  );

  const tabs = useMemo(() => {
    const result: Tab[] = [];
    const taxReductionInfoMarkup = taxReductionInfo ? (
      <TaxReductionInfo
        link={
          typeof taxReductionInfo === 'string' ? taxReductionInfo : undefined
        }
      />
    ) : null;

    if (automatic) {
      result.push({
        label: 'Personnummer',
        value: 'automatic',
        content: (
          <>
            {taxReductionInfoMarkup}
            <Automatic props={props} {...automaticProps} />
          </>
        )
      });
    }

    if (manual) {
      result.push({
        label: 'Fyll i själv',
        value: 'manual',
        content: (
          <>
            {taxReductionInfoMarkup}
            <Manual props={props} {...manualProps} />
          </>
        )
      });
    }

    if (company) {
      result.push({
        label: 'Företag',
        value: 'company',
        content: <Company props={props} {...companyProps} />
      });
    }

    return result;
  }, [
    taxReductionInfo,
    automatic,
    manual,
    company,
    props,
    automaticProps,
    manualProps,
    companyProps
  ]);

  return (
    <Tabs
      value={values.customerContactType}
      tabs={tabs}
      onChange={values.handlers.handleCustomerContactType}
    />
  );
}
