import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Columns } from '~/types/RelatedBlock';

// Helpers
import mq from '~/App/helpers/mq';

const gutterSizes = {
  none: '',
  small: '0.5rem',
  medium: '1rem',
  large: '2rem'
};

type CellProps = {
  width?: number;
  breakpoints?: Record<string, number>;
  carousel?: {
    isActive: boolean;
  };
  useGrid?: boolean;
};

export const Cell = styled.div<CellProps>`
  display: ${({ useGrid }) => (useGrid ? 'flex' : 'block')};
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  text-align: initial;

  ${mq('<small')`
    display: block
  `}

  ${mq<CellProps>('<small')`
    margin: ${({ carousel }) => {
      if (!carousel?.isActive) return null;

      return '0 0.5rem 1rem';
    }}; 
  `};

  ${({ width }) => (width ? `flex-basis: ${width * 100}%;` : '')};

  ${({ breakpoints = {} }) => {
    const keys = Object.keys(breakpoints);

    if (keys.length === 0) return '';

    return keys.map(
      (size: string) => mq(size)`
            flex-basis: ${breakpoints[size] * 100}%;
            max-width: ${breakpoints[size] * 100}%;
          `
    );
  }};
`;

type RowProps = {
  justifyContent: string;
  gutter: keyof typeof gutterSizes;
  useGrid?: boolean;
  columns: Columns;
};
const Row = styled.div<RowProps>`
  display: ${({ useGrid }) => (useGrid ? 'grid' : 'flex')};
  grid-auto-rows: 1fr;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  flex-wrap: wrap;
  flex-direction: row;
  flex-grow: 0;
  flex-basis: auto;
  flex-shrink: 0;
  justify-content: ${({ justifyContent }) => justifyContent};

  ${mq('<small')`
    display: flex
  `}

  margin: ${({ gutter }) => gutterSizes[gutter] && `-${gutterSizes[gutter]}`};

  & > ${Cell} {
    padding: ${({ gutter }) => gutterSizes[gutter]};
    justify-content: center;
  }
`;

const Container = styled.div`
  overflow: visible;
`;

type Props = {
  id?: string;
  className?: string;
  children: ReactNode;
  gutter?: keyof typeof gutterSizes;
  justifyContent?: string;
  useGrid?: boolean;
  columns: Columns;
};

export function Grid(props: Props) {
  return (
    <Container>
      <Row
        gutter="none"
        justifyContent="flex-start"
        useGrid={props.useGrid}
        {...props}
      />
    </Container>
  );
}
