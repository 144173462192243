import { paymentMethods } from '~/App/config/paymentMethods';
import { swishTypes } from '~/App/config/swishTypes';

type Values = {
	customerContactType: 'automatic' | 'company' | 'manual';
	paymentMethod?: {
		id: number;
	};
	swish: {
		swishType: string
	}
};

export const buildSchema = () => ({
	'productOptions.product.price': {
		presence: {
			allowEmpty: false,
			message: '^Ange ett belopp'
		},
		numericality: {
			greaterThan: 49,
			lessThanOrEqualTo: 999999,
			message: '^Minsta gåvobelopp är 50 kr'
		}
	},
	'productOptions.product.quantity': {
		presence: {
			allowEmpty: false,
			message: '^Ange antal'
		},
		numericality: {
			greaterThanOrEqualTo: 1,
			message: '^Minsta tillåtna antal är 1 st'
		}
	},
	'productOptions.customerContact.email': {
		validateEmail: (_: unknown, {}) => true
	},
	'productOptions.customerContact.ssn': {
		validateSsn: (_: any, { customerContactType }: Values) => customerContactType === 'automatic'
	},
	'productOptions.customerContact.cid': {
		validateCid: (_: any, { customerContactType }: Values) => customerContactType === 'company'
	},
	'productOptions.customerContact.company': {
		validateCompany: (_: any, { customerContactType }: Values) => customerContactType === 'company'
	},
	'productOptions.customerContact.firstName': {
		validateFirstName: (_: any, { customerContactType, paymentMethod }: Values) =>
		customerContactType !== 'automatic' || paymentMethod?.id === paymentMethods.paymentSlip
	},
	'productOptions.customerContact.lastName': {
		validateLastName: (_: any, { customerContactType, paymentMethod }: Values) =>
		customerContactType !== 'automatic' || paymentMethod?.id === paymentMethods.paymentSlip
	},
	'productOptions.customerContact.addressStreet': {
		validateStreetName: (_: any, { customerContactType, paymentMethod }: Values) =>
		customerContactType !== 'automatic' || paymentMethod?.id === paymentMethods.paymentSlip
	},
	'productOptions.customerContact.addressZip': {
		validateZipCode: (_: any, { customerContactType, paymentMethod }: Values) =>
			customerContactType !== 'automatic' || paymentMethod?.id === paymentMethods.paymentSlip
	},
	'productOptions.customerContact.addressCity': {
		validateCity: (_: any, { customerContactType, paymentMethod }: Values) =>
		customerContactType !== 'automatic'|| paymentMethod?.id === paymentMethods.paymentSlip
	},
	'productOptions.customerContact.phoneNumber': {
		validatePhoneNumber: (_: any, { customerContactType }: Values) => customerContactType === 'company'
	},
	swishPhoneNumber: {
		validatePhoneNumber: (_: any, { paymentMethod, swish }: Values) =>
			paymentMethod?.id === paymentMethods.swish && swish.swishType === swishTypes.ecommerce
	}
});
