import styled, { css } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { angleUp, angleDown } from '~/App/helpers/icons';

type CaretIconProps = {
  open?: boolean;
};

export const CaretIcon = styled.div<CaretIconProps>`
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.themeColors.primary};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  right: 8px;
  top: 20px;
  z-index: 10;

  ${mq('≥mediumLarge')`
    display: none;
  `};

  &:before {
    ${({ open }) => (open ? angleUp : angleDown)};
    display: block;
    line-height: 28px;
    font-size: 1.75rem;
    top: 1px;

    ${({ open }) =>
      open &&
      css`
        top: -2px;
      `};
  }
`;
