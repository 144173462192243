import { FormEvent, useCallback, useState } from 'react';
import { IValidation } from '~/App/shared/components/Validation';
import { CreateMemorialPageStateValues } from './useCreateMemorialPageState';
import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';
import mediaHelper from '~/App/helpers/media-helper';
import { collections } from '~/App/helpers/http';
import { useNavigate } from 'react-router';

export type CreateMemorialPageSubmit = {
  isSending: boolean;
  isCompleted: boolean;
  handleSubmit: (event?: FormEvent<HTMLFormElement>) => Promise<void>;
};

type Props = {
  validation: IValidation;
  values: CreateMemorialPageStateValues;
};

export function useCreateMemorialPageSubmit({
  validation,
  values
}: Props): CreateMemorialPageSubmit {
  const [isSending, setIsSending] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const navigate = useNavigate();

  const beginOperation = useCallback(() => {
    setIsSending(true);
  }, [setIsSending]);

  const operationFailed = useCallback(() => {
    setIsSending(false);
  }, [setIsSending]);

  // Upload file to Cloudinary
  const performUpload = useCallback(async (url) => {
    if (!url) {
      return null;
    }

    if (mediaHelper.isCloudinaryUrl(url)) {
      return url;
    }

    const fetchResponse = await fetch(url);
    const blob = await fetchResponse.blob();
    const uploadResponse = await uploadImage(blob, {
      timeout: 30000,
      folder: 'collection-pictures'
    });

    return uploadResponse?.data?.secure_url;
  }, []);

  const createMemory = useCallback(async () => {
    try {
      const imageUrl = await performUpload(values.person.image);
      const { data = {} } = await collections.create({
        data: {
          collection: {
            collection_type: 'memorial_page',
            name: values.person.name,
            remote_image_url: imageUrl,
            birth_date: values.person.birth,
            death_date: values.person.death
          }
        }
      });
      navigate(`/stod-oss/minnessidor/${data.slug}`);
    } catch (error) {
      operationFailed();
    }
  }, [
    navigate,
    operationFailed,
    performUpload,
    values.person.birth,
    values.person.death,
    values.person.image,
    values.person.name
  ]);

  const handleSubmit = useCallback(
    async (event?: FormEvent<HTMLFormElement>) => {
      event?.preventDefault();

      beginOperation();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      await createMemory();
      setIsCompleted(true);
      setIsSending(false);
    },
    [beginOperation, createMemory, validation, setIsSending, setIsCompleted]
  );

  return { handleSubmit, isSending, isCompleted };
}
