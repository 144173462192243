import React from 'react';

// Helpers
import { getDurationVariant } from '~/App/helpers/gtm-helper';

// Shared components
import * as RadioButtonHorizontal from '~/App/shared/components/Fields/RadioButtonHorizontal/';

export const DurationSelector = (props) => {
  const { formContent, values } = props;

  return (
    <RadioButtonHorizontal.Wrapper>
      {formContent.durations.map((duration) => {
        const durationSlug = duration === '' ? 'infinite' : duration;
        const label = duration === '' ? 'Tillsvidare' : `${duration} månader`;

        return (
          <RadioButtonHorizontal.Item
            key={`duration-${durationSlug}`}
            id={`duration-${durationSlug}`}
            value={values?.productOptions?.product?.duration}
            checked={values?.productOptions?.product?.duration === duration}
            onChange={() => {
              values?.handlers?.setProductDuration(duration);
              values?.handlers?.setGtmVariant(getDurationVariant(duration));
            }}
            label={label}
            name="duration"
            tinyLayout="50"
            smallLayout="50"
            largeLayout="auto"
          />
        );
      })}
    </RadioButtonHorizontal.Wrapper>
  );
};
