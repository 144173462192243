import React from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';
import { CollectionProgress } from '../../../components/CollectionImage/components/CollectionProgress';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  ${mq('>small')`
    margin-right: 1.125rem;
  `}
`;

const Link = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.darkBlue};

  ${mq('≥small')`
    font-size: 1.125rem;
    line-height: 1.25rem;
  `};
`;

const Text = styled.div`
  font-size: 0.75rem;

  ${mq('>small')`
    font-size: 0.875rem;
  `}
`;

type Props = {
  handlePreviewOpen: () => void;
};

export function CollectionProgressPreview({ handlePreviewOpen }: Props) {
  return (
    <Wrapper>
      <CollectionProgress percentage={50} position={'static'} />
      <Column>
        <Text
          children={
            'Ikonen läggs på din profilbild och visar hur långt det är kvar till insamlingens mål.'
          }
        />
        <Link onClick={handlePreviewOpen} children="Se exempel" />
      </Column>
    </Wrapper>
  );
}
