import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import omitProps from '~/App/helpers/omit-props';
import { clearfix } from '~/App/helpers/mixins';

// Shared components
import Link from '~/App/shared/components/Link';

const Wrapper = styled.div`
  font-size: 1em;
  float: left;

  ${mq('≥small')`
    font-size: 1.2em;
    float: none;
    margin: 0.75em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `};
`;

const StyledLink = styled(omitProps(Link, ['active']))`
  ${clearfix};
  cursor: pointer;
  display: block;
  float: left;
  text-decoration: none;
  color: ${(props) => (props.active ? '#000' : props.theme.colors.gray)};
  padding: 0.25em 0;
  padding-right: 1em;
  transition: color 0.3s ease, border 0.3s ease;

  ${mq('≥small')`
    padding-right: 2em;
    float: none;
    padding-right: 1em;
    border-right-width: 4px;
    border-right-style: solid;
    border-right-color: ${(props) =>
      props.active ? props.theme.colors.oceanBlue : 'transparent'};
  `};
`;

const Label = styled.div`
  float: left;
`;

const Value = styled.div`
  float: right;
  padding-left: 0.4em;
`;

export default class SearchCategory extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, value, active, onClick } = this.props;

    return (
      <Wrapper onClick={onClick}>
        <StyledLink active={active}>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </StyledLink>
      </Wrapper>
    );
  }
}
