import React from 'react';
import styled from 'styled-components';

// Local components
import { FooterImage } from '.';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const FooterImages = (props) => {
  return (
    <Wrapper>
      {props.images.map((image) => (
        <FooterImage image={image} key={image.url} />
      ))}
    </Wrapper>
  );
};
