import React from 'react';

export default class CustomAmount extends React.Component {
  constructor(props) {
    super(props);

    this.setIsEnabled = this.setIsEnabled.bind(this);

    this.state = {
      isEnabled: false
    };
  }

  setIsEnabled(isEnabled) {
    this.setState({ isEnabled: isEnabled });
  }

  render() {
    return this.props.children({
      ...this.state,
      handlers: {
        setIsEnabled: this.setIsEnabled
      }
    });
  }
}
