import React from 'react';

// Helpers and config
import { getPurchaseErrorMessage } from './helpers/';
import { customerContactTypes } from '~/App/config/customerContactTypes';

// AB-tests
import { Experiment, Variant } from '~/App/shared/components/ABTesting';
import { experiments } from '~/App/config/abExperiments';

// Shared components
import FormInfoBox from './components/FormInfoBox';
import * as TwoColumn from './components/TwoColumn/';
import AmountSelector from './components/AmountSelector';
import PaymentMethodSelector from './components/PaymentMethodSelector';
import { ContactFields } from './components/ContactFields';
import { IntegrityText } from './components/IntegrityText';
import { StepWrapper, HiddenAboveMedium } from './components/FormLayout';
import { FormTitle } from './components/FormTypography';

import { AlertMessageContainer } from '~/App/shared/components/Elements';

import { SubmitButton } from './components/SubmitButton';
import { SwishOtherDeviceButton } from './components/SwishOtherDeviceButton';
import { Section } from '../../../MultiPartForm';

export default class BasicDonationForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { handlers, paymentMethod } = this.props.values;
    handlers.handlePaymentMethod(paymentMethod);
  }

  renderFormInfoBox(formContent) {
    return (
      <>
        {formContent?.texts?.mobilePreamble &&
          formContent?.texts?.desktopPreamble && (
            <FormInfoBox>{formContent.texts}</FormInfoBox>
          )}
      </>
    );
  }

  renderAbExperiment(formContent) {
    if (formContent.abExperiment === 'alternativeCopyDonationForm') {
      const variantCopy =
        'En av tre får cancer. Vi arbetar för att färre ska drabbas och fler överleva sin sjukdom. Därför är ditt stöd livsviktigt.';
      const variantFormContent = {
        ...formContent,
        texts: {
          mobilePreamble: variantCopy,
          desktopPreamble: variantCopy
        }
      };

      return (
        <Experiment experimentId={experiments.alternativeCopyDonationForm}>
          <Variant>{this.renderFormInfoBox(formContent)}</Variant>
          <Variant>{this.renderFormInfoBox(variantFormContent)}</Variant>
        </Experiment>
      );
    } else {
      return this.renderFormInfoBox(formContent);
    }
  }

  render() {
    const {
      values = {},
      submit = {},
      validation = {},
      formContent = {},
      summaryComponent: SummaryComponent
    } = this.props;

    const showContactType = {
      company:
        formContent.availableCustomerContactTypes.indexOf(
          customerContactTypes.company
        ) !== -1,
      manual:
        formContent.availableCustomerContactTypes.indexOf(
          customerContactTypes.manual
        ) !== -1,
      automatic:
        formContent.availableCustomerContactTypes.indexOf(
          customerContactTypes.automatic
        ) !== -1
    };

    return (
      <>
        {formContent.heading && <FormTitle>{formContent.heading}</FormTitle>}
        <TwoColumn.Wrapper>
          {this.renderAbExperiment(formContent)}
          <TwoColumn.LeftColumn>
            <form onSubmit={submit.handleSubmit} noValidate>
              <Section
                part={1}
                values={values}
                validation={validation}
                title="Välj belopp"
                nextStepButton={{ text: 'Till kontaktuppgifter' }}
              >
                <StepWrapper>
                  <AmountSelector
                    amounts={formContent.prices}
                    selectedAmount={values.productOptions.product.price}
                    customAmount={values.productOptions.product.customPrice}
                    setAmount={values.handlers.setProductPrice}
                    setCustomAmount={values.handlers.setProductCustomPrice}
                    resetAmount={values.handlers.resetProductPrice}
                    validationKey={'productOptions.product.price'}
                    validation={validation}
                    tinyLayout={
                      formContent?.layout?.amountSelector?.tinyLayout || '50'
                    }
                    smallLayout={
                      formContent?.layout?.amountSelector?.smallLayout || '50'
                    }
                    largeLayout={
                      formContent?.layout?.amountSelector?.largeLayout || '50'
                    }
                  />
                  <HiddenAboveMedium>
                    <SummaryComponent area="form" />
                  </HiddenAboveMedium>
                </StepWrapper>
              </Section>
              <Section
                part={2}
                values={values}
                validation={validation}
                title="Dina kontaktuppgifter"
                nextStepButton={{ text: 'Till betalsätt' }}
              >
                <StepWrapper>
                  <ContactFields
                    values={values}
                    validation={validation}
                    manual={
                      showContactType.manual
                        ? {
                            email: formContent.emailCopy || true
                          }
                        : false
                    }
                    automatic={
                      showContactType.automatic
                        ? {
                            taxReductionInfo:
                              formContent.taxReductionInfoLink || true,
                            email: formContent.emailCopy || true
                          }
                        : false
                    }
                    company={
                      showContactType.company
                        ? {
                            phone: true,
                            email: formContent.emailCopy || true
                          }
                        : false
                    }
                  />
                  <HiddenAboveMedium>
                    <SummaryComponent area="form" />
                  </HiddenAboveMedium>
                </StepWrapper>
              </Section>
              <Section
                part={3}
                values={values}
                validation={validation}
                title="Välj betalsätt"
              >
                <StepWrapper>
                  <PaymentMethodSelector
                    formContent={formContent}
                    values={values}
                    validation={validation}
                    price={values.productOptions.product.price}
                  />
                  <IntegrityText anchor="#gava-via-cancerfondens-hemsida" />
                  {submit.errors && !submit.isPolling && !submit.isSending && (
                    <AlertMessageContainer messageStyle="error">
                      {getPurchaseErrorMessage(submit.exceptionCode)}
                    </AlertMessageContainer>
                  )}
                  <HiddenAboveMedium>
                    <SummaryComponent area="form" />
                  </HiddenAboveMedium>
                </StepWrapper>
              </Section>
              {values.partOptions.currentPart === 3 && (
                <>
                  <SubmitButton
                    values={values}
                    validation={validation}
                    submit={submit}
                  />
                  <SwishOtherDeviceButton
                    values={values}
                    text={formContent.anotherDeviceText}
                  />
                </>
              )}
            </form>
          </TwoColumn.LeftColumn>
          <TwoColumn.RightColumn>
            <TwoColumn.ColumnCard>
              <SummaryComponent area="side" />
            </TwoColumn.ColumnCard>
          </TwoColumn.RightColumn>
        </TwoColumn.Wrapper>
      </>
    );
  }
}
