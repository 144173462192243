import React, { ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { heading1 } from '~/App/helpers/mixins';

import Select from '~/App/shared/components/Fields/Select';
import Text from '~/App/shared/components/Fields/Text';
import { RadioButton } from '~/App/shared/components/Fields/RadioButton';

import { mq } from '~/lib/mq';
import { IAboutScreen } from '../types';
import { useSelfTest } from '../context/useSelfTest';
import { useDebouncedEffect } from '~/App/shared/hooks/use-debounced-effect';

const Content = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  ${mq('≥small')`
    margin-bottom: 0;
  `};
`;

const Heading = styled.h1`
  ${heading1}
  font-size: 2rem;
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  line-height: 1.2;
  margin-bottom: 1rem;
  line-height: 1.2;
  margin-bottom: 1rem;

  ${mq('≥small')`
    font-size: 5rem;
  `};
`;

const Body = styled.p`
  margin-bottom: 2rem;
  color: ${(props) => props.theme.colors.white};
`;

const InputLabel = styled.label`
  display: block;
  text-align: center;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.black};
  flex: 1;

  ${mq('≥small')`
    margin: 1rem;
    text-align: left;
  `};

  &.input-label--age {
    margin: auto;
    max-width: 160px;

    ${mq('≥small')`
      margin: 1rem;
  `};
  }

  &:last-of-type {
    margin-top: 2rem;

    ${mq('≥small')`
      flex-basis: 100%;
      margin-top: 0;

      .input-wrapper {
        display: flex;
        margin-left: 0;

        .label {
          margin: 0.5rem 1rem 0 0;
        }
      }
    `};
  }
`;

const StyledTextField = styled(Text)`
  padding: 4px 10px;
  color: ${(props) => props.theme.colors.black};
`;

const InputArea = styled.div`
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem 1.5rem 0.5rem;

  ${mq('≥small')`
    flex-direction: row;
    flex-wrap: wrap;
  `};
`;

const InputWrapper = styled.div`
  margin: 0.5rem auto 2rem;
  color: black;
`;

const Label = styled.label`
  color: white;
  display: flex;
  width: 18ch;
  margin: 1rem auto;
  color: ${(props) => props.theme.colors.black};
`;

type State = {
  gender: string;
  health: string;
  age: number | null;
  answeredHonestly: boolean | null;
};

type Props = {
  screen: IAboutScreen;
};

export function AboutScreen({ screen }: Props) {
  const { setSession, session } = useSelfTest();
  const [state, setState] = useState<State>({
    answeredHonestly: session?.answeredHonestly ?? null,
    age: session?.age || null,
    health: session?.health || '',
    gender: session?.gender || ''
  });

  const handleChange = useCallback((values: Partial<State>) => {
    setState((state) => ({
      ...state,
      ...values
    }));
  }, []);

  const handleGenderChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleChange({
        gender: event.currentTarget.value
      });
    },
    [handleChange]
  );

  const handleHealthChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleChange({ health: event.currentTarget.value });
    },
    [handleChange]
  );

  const handleAgeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value?.replace(/[^0-9]+/g, '');
      const parsed = parseInt(rawValue, 10);
      const value = isNaN(parsed) ? null : parsed;

      handleChange({ age: value });
    },
    [handleChange]
  );

  const handleAnsweredHonestlyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value === 'true' ? true : false;

      handleChange({ answeredHonestly: value });
    },
    [handleChange]
  );

  useDebouncedEffect(
    () => {
      setSession({
        ...session,
        ...state,
        answeredHonestly: state.answeredHonestly || false
      });
    },
    300,
    [state]
  );

  return (
    <Content>
      <Heading children={screen.heading} />
      <Body children={screen.body} />
      <InputArea>
        <InputLabel>
          Jag identifierar mig som
          <InputWrapper>
            <Select
              value={state.gender}
              name="gender"
              options={[
                { label: 'Välj', value: '', disabled: true },
                { label: 'Man', value: 'male' },
                { label: 'Kvinna', value: 'female' },
                { label: 'Ickebinär', value: 'non_binary' }
              ]}
              onChange={handleGenderChange}
            />
          </InputWrapper>
        </InputLabel>
        <InputLabel>
          Hur är din hälsa i allmänhet?
          <InputWrapper>
            <Select
              value={state.health}
              name="health"
              options={[
                { label: 'Välj', value: '', disabled: true },
                { label: 'Mycket bra', value: 'very_good' },
                { label: 'Bra', value: 'good' },
                { label: 'Någorlunda', value: 'fairly' },
                { label: 'Dålig', value: 'bad' },
                { label: 'Mycket dålig', value: 'very_bad' },
                { label: 'Vet ej', value: 'dont_know' },
                { label: 'Vill inte svara', value: 'unknown' }
              ]}
              onChange={handleHealthChange}
            />
          </InputWrapper>
        </InputLabel>
        <InputLabel className={'input-label--age'}>
          Hur gammal är du?
          <InputWrapper>
            <StyledTextField
              suffix="år"
              value={state.age || ''}
              onChange={handleAgeChange}
            />
          </InputWrapper>
        </InputLabel>
        <InputLabel>
          Handen på hjärtat, hur ärligt har du svarat?
          <InputWrapper className={'input-wrapper'}>
            <Label className={'label'} htmlFor="answeredHonestly-yes">
              <RadioButton
                onChange={handleAnsweredHonestlyChange}
                checked={state.answeredHonestly === true}
                id="answeredHonestly-yes"
                name="answeredHonestly"
                value="true"
              />
              <span>Jag har svarat ärligt</span>
            </Label>
            <Label className={'label'} htmlFor="answeredHonestly-no">
              <RadioButton
                onChange={handleAnsweredHonestlyChange}
                checked={state.answeredHonestly === false}
                id="answeredHonestly-no"
                name="answeredHonestly"
                value="false"
              />
              <span>Jag kan ha fuskat lite</span>
            </Label>
          </InputWrapper>
        </InputLabel>
      </InputArea>
    </Content>
  );
}
