import React, { ComponentProps } from 'react';
import styled from 'styled-components';

import { Modal } from '~/App/shared/components/Elements';

import { closeLink, heading2, card, arrowLink } from '~/App/helpers/mixins';

import mediaHelper from '~/App/helpers/media-helper';
import { mq } from '~/lib/mq';
import { NavLink } from 'react-router-dom';
import { useDragToDismiss } from '~/App/shared/hooks/use-drag-to-dismiss';

const Padding = styled.div`
  padding: 5.75rem 2rem 3rem 2rem;

  ${mq('<=medium')`
    padding: 0;
    display: flex;
    flex-direction: column;
`};
`;

const Heading = styled.h2`
  ${heading2}
  margin: 1.5rem 0 0.5rem;
  color: ${(props) => props.theme.colors.darkBlue};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mq('<=medium')`
    flex-direction: column;
`};
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.charcoal};
`;

const Link = styled(NavLink)`
  ${arrowLink}
  color: ${(props) => props.theme.colors.darkBlue};
  margin-top: 1rem;

  ${mq('<=medium')`
    margin-top: 0.75rem;
`};
`;

const CloseButton = styled.a`
  ${closeLink}
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: ${(props) => props.theme.colors.darkBlue};

  ${mq('<=medium')`
    order: 2;
    position: relative;
    text-align: center;
    margin-top: 1.75rem;
    right: initial;
    top: initial;
`};
`;

const Card = styled.div`
  ${card};
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 0.5rem);
  margin: 0.5rem;

  ${mq('<=medium')`
    flex-basis: fill;
`};
`;

const PictureWrapper = styled.div`
  position: relative;
  padding-top: 11.875rem;
  overflow: hidden;
  max-width: 100%;

  ${mq('<=medium')`
    display: none;
`};
`;

const Picture = styled.picture`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 192px;
  display: block;
  background: none;
  border-radius: 0.125rem;
  position: absolute;
  top: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextWrapper = styled.div`
  padding: 1.75rem 1.75rem 2.5rem 1.75rem;
`;

const StyledModal = styled(Modal)``;

const imageMarkup = (url: string) => {
  return (
    <PictureWrapper>
      <Picture>
        <source
          srcSet={`
          ${mediaHelper.getUrl(url, {
            width: 800
          })} 2x,
          ${mediaHelper.getUrl(url, {
            width: 400
          })} 1x
      `}
        />
        <Image
          src={mediaHelper.getUrl(url, {
            width: 400,
            height: 400,
            quality: 80,
            crop: 'fill',
            dpr: '2.0',
            fetch_format: 'png'
          })}
        />
      </Picture>
    </PictureWrapper>
  );
};

type Props = {
  closeModal: ComponentProps<typeof Modal>['closeModal'];
};

export function MemorialModal({ closeModal }: Props) {
  const { element } = useDragToDismiss({
    isActive: true,
    onDismiss: closeModal
  });

  return (
    <StyledModal
      closeModal={closeModal}
      maxWidth={'59rem'}
      isDraggable
      ref={element}
    >
      <Padding>
        <CloseButton onClick={closeModal}>Stäng</CloseButton>
        <CardWrapper>
          <Card>
            {imageMarkup(
              'https://res.cloudinary.com/cancerfonden/image/upload/q_80,c_fit,h_600/v1716553478/Puff-minnessidor_m3srsp.jpg'
            )}
            <TextWrapper>
              <Heading>Minnessida</Heading>
              <Text>
                Att dela minnen med familj och vänner och tända ett ljus på en
                personlig minnessida är ett fint sätt att hedra någon som har
                gått bort.
              </Text>
              <Link to="/stod-oss/minnessidor/skapa-minnessida">
                Skapa minnessida
              </Link>
            </TextWrapper>
          </Card>
          <Card>
            {imageMarkup(
              'https://res.cloudinary.com/cancerfonden/image/upload/v1730365914/Insamlingar-mobil-smal_reaz7a.jpg'
            )}
            <TextWrapper>
              <Heading>Insamling</Heading>
              <Text>
                Att driva en insamling är ett aktivt sätt att engagera dig och
                andra i en livsviktig fråga för att stötta cancerforskningen.
              </Text>
              <Link to="/stod-oss/insamlingar/starta-insamling">
                Skapa insamling
              </Link>
            </TextWrapper>
          </Card>
        </CardWrapper>
      </Padding>
    </StyledModal>
  );
}
