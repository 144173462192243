import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

class Grid extends React.Component {
  constructor(props) {
    super(props);
  }

  modifiers(name, pattern) {
    const omitted = _.omitBy(
      this.props,
      (_value, key) => !_.startsWith(key, pattern)
    );
    const mapped = _.mapKeys(omitted, (_value, key) =>
      key.replace(pattern, `${name}--`)
    );

    return mapped;
  }

  render() {
    let { block, element, children, style, ...rest } = this.props;

    element = `${block}__${element}`;

    let parentClasses = classNames(block, this.modifiers(block, 'p-'));
    let childClasses = classNames(element, this.modifiers(element, 'c-'));

    return (
      <div className={parentClasses} style={style} {...rest}>
        {React.Children.map(children, (child, key) => {
          if (!_.isEmpty(child)) {
            return (
              <div className={childClasses} key={key}>
                {child}
              </div>
            );
          }
        })}
      </div>
    );
  }
}

Grid.displayName = 'Grid';

Grid.defaultProps = {
  block: '',
  element: 'item'
};

export default Grid;
