import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useState
} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import Text from '../../Text';

export const Wrapper = styled.div`
  display: flex;
`;

const InputField = styled(Text)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

type ButtonProps = {
  isValid: boolean;
  isInvalid: boolean;
};

const Button = styled.div<ButtonProps>`
  background-color: ${({ theme, isValid, isInvalid }) => {
    if (isValid) return theme.colors.darkApple;
    if (isInvalid) return theme.colors.errorRed;

    return theme.colors.darkBlue;
  }};

  color: ${({ theme }) => theme.colors.white};
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
  max-height: 2.875rem;

  &:hover {
    background-color: ${({ theme, isValid, isInvalid }) => {
      if (isValid) return theme.colors.lightApple;
      if (isInvalid) return theme.colors.errorRedDark;

      return theme.colors.hoverBlue;
    }};
  }
`;

const ButtonImage = styled.img`
  width: 36px;
  height: 36px;
`;

type PipeFn = (value: string) => string;

type Props = {
  value?: string;
  errors?: string[];
  isValid: boolean;
  isInvalid: boolean;
  onClick?: () => void;
  onNativeChange: (value: Date) => void;
  onBlur?: () => void;
  placeholder?: string;
  required?: boolean;
};

export const CustomInput = forwardRef<unknown, Props>((props, _) => {
  const [inputValue, setInputValue] = useState('');

  const {
    value,
    errors,
    isValid,
    isInvalid,
    placeholder,
    required = false,
    onNativeChange,
    onClick,
    onBlur
  } = props;

  const pipe = useCallback(
    (...fns: PipeFn[]) => (x: string) => fns.reduce((v, f) => f(v), x),
    []
  );

  const addSeparatorIfNeeded = useCallback(
    (value: string) => {
      const separatorsPositions = [5, 8];

      for (const position of separatorsPositions) {
        const charIndex = position - 1;

        if (!value[charIndex]) {
          continue;
        }

        if (value[charIndex] === '-') {
          continue;
        }

        return `${value.slice(0, charIndex)}-${value.slice(charIndex)}`;
      }

      if (inputValue.length < value.length) {
        if (separatorsPositions.includes(value.length + 1)) {
          return `${value}-`;
        }
      }

      return value;
    },
    [inputValue.length]
  );

  const validateFormat = useCallback(
    (value: string) => {
      const format = /^([0-9]|$)([0-9]|$)([0-9]|$)([0-9]|$)(-|$)([0-9]|$)([0-9]|$)(-|$)([0-9]|$)([0-9]|$)$/;

      if (format.test(value)) {
        return value;
      }

      return inputValue;
    },
    [inputValue]
  );

  const validateMonth = useCallback((value: string) => {
    const month = value.slice(5, 7);

    if (month.length !== 2) {
      return value;
    }

    const parsed = parseInt(month, 10);

    if (parsed > 12) {
      return `${value.slice(0, 4)}-12`;
    }

    return value;
  }, []);

  const validateDay = useCallback((value: string) => {
    const day = value.slice(8, 10);

    if (day.length !== 2) {
      return value;
    }

    const parsed = parseInt(day, 10);
    const lastDay = dayjs(value.slice(0, 7), 'YYYY-MM').daysInMonth();

    if (parsed > lastDay) {
      return `${value.slice(0, 7)}-${lastDay}`;
    }

    return value;
  }, []);

  const parseDate = useCallback(
    (value: string) => {
      if (value.length < 10) {
        return value;
      }

      const parsed = dayjs(value, 'YYYY-MM-DD', true);
      const isValid = parsed.isValid();

      if (!isValid) {
        return value;
      }

      onNativeChange(parsed.toDate());

      return parsed.format('YYYY-MM-DD');
    },
    [onNativeChange]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const oldValue = inputValue;
      const newValue = event.target.value;

      // characters removed - just set value
      if (newValue.length < oldValue.length) {
        return setInputValue(newValue);
      }

      const value = pipe(
        addSeparatorIfNeeded,
        validateFormat,
        validateMonth,
        validateDay,
        parseDate
      )(newValue);

      setInputValue(value);
    },
    [
      inputValue,
      pipe,
      addSeparatorIfNeeded,
      validateFormat,
      validateMonth,
      validateDay,
      parseDate
    ]
  );

  useEffect(() => {
    const parsed = dayjs(value);

    if (!parsed.isValid()) {
      return;
    }

    setInputValue(dayjs(value).format('YYYY-MM-DD'));
  }, [value]);

  return (
    <Wrapper onClick={onClick}>
      <InputField
        value={inputValue}
        errors={errors}
        isValid={isValid}
        onClick={onClick}
        onChange={handleChange}
        onBlur={onBlur}
        isInvalid={isInvalid}
        placeholder={placeholder}
        autoComplete="off"
        required={required}
      />
      <Button isValid={isValid} isInvalid={isInvalid}>
        <ButtonImage src="https://res.cloudinary.com/cancerfonden/image/upload/v1614678635/assets/icons/icons-ui-calendar.svg" />
      </Button>
    </Wrapper>
  );
});
