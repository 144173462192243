import styled, { css } from 'styled-components';

// Helpers
import { arrowLink } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100%;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    height: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.seashell};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.themeColors.primary};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.themeColors.primaryHover};
  }
`;

export const ArrowLeft = styled.button`
  ${arrowLink};
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: 0;
  border: none;
  height: 2.5rem;
  padding: 0;
  width: 2.5rem;
  margin: 0;
  z-index: 1;

  ${mq('≥small')`
    left: 1.5rem;
  `};

  &:active,
  &:focus {
    outline: none;
  }

  &::after {
    font-size: 2.5rem;
    line-height: 0;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    transition: color 0.2s ease-out;

    ${mq('≥small')`
      font-size: 4rem;
    `};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &::after {
        color: ${({ theme }) => theme.colors.silverChalice};
      }

      &:hover {
        color: ${({ theme }) => theme.colors.silverChalice};
      }
    `};
`;

export const ArrowRight = styled(ArrowLeft)`
  left: auto;
  right: 0.5rem;

  ${mq('≥small')`
      left: auto;
      right: 1.5rem;
  `};

  &::after {
    transform: translateX(-50%) rotate(0deg);
  }
`;

export const Item = styled.div`
  padding: 0 2.5rem;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: ${({ width }) => (width ? `${width}px` : '100%')};
  scroll-snap-align: start;
`;
