import React from 'react';
import { find } from 'lodash';
import styled from 'styled-components';

// Shared components
import { PackageFeature } from './';

const PackageFeatureListStyles = styled.ul`
  margin: 0 0 2rem 2.5rem;
  font-size: 1.125rem;
`;

export const PackageFeatureList = (props) => {
  const { activePackageId, packages } = props;

  const activePackage = find(packages, { productId: activePackageId }) || {};

  return (
    <React.Fragment>
      <PackageFeatureListStyles>
        {activePackage.features &&
          activePackage.features.map((feature) => (
            <PackageFeature key={feature.name} enabled={feature.included}>
              {feature.name}
            </PackageFeature>
          ))}
      </PackageFeatureListStyles>
    </React.Fragment>
  );
};
