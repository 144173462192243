import { AxiosRequestConfig } from 'axios';

import { ICollectionActivity } from '~/types/ICollectionActivity';
import { Period } from '~/types/IPurchase';
import { mwAxios } from '.';
import { get } from '../cookie';

type ArgumentsWithSlug = AxiosRequestConfig & { slug: string };

type CollectionActivitiesResponse = {
  activities: ICollectionActivity[];
};

type CollectionActivityLikeResponse = {
  likes: number;
};

type CreateMemoryBody = {
  image: string;
  content: string;
  period: Period | null;
  by: string;
};

type CollectionSpecificMemory = {
  token: string;
  collection: string;
};

export const collectionActivities = {
  index: () =>
    mwAxios.get<CollectionActivitiesResponse>(`/api/v2/collections/activities`),
  show: (args: ArgumentsWithSlug) =>
    mwAxios.get<CollectionActivitiesResponse>(
      `/api/v2/collections/${args.slug}/activities`,
      {
        params: {
          ...args.params
        }
      }
    ),
  like: (id: string | number) =>
    mwAxios.post<CollectionActivityLikeResponse>(
      `/api/v2/collections/activities/${id}/like`
    ),
  unlike: (id: string | number) =>
    mwAxios.post<CollectionActivityLikeResponse>(
      `/api/v2/collections/activities/${id}/unlike`
    ),
  createMemory: (
    collection: number | string,
    { content, by, image }: CreateMemoryBody
  ) =>
    mwAxios.post<ICollectionActivity>(
      `/api/v2/collections/${collection}/activities`,
      {
        type: 'memory',
        content,
        image,
        by
      },
      {
        headers: {
          'X-Session-Key': get('secretKey')
        }
      }
    ),
  getMemory: ({ collection, token }: CollectionSpecificMemory) =>
    mwAxios.get<ICollectionActivity>(
      `/api/v2/collections/${collection}/activities/${token}`
    )
};
