import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ICommerceVariant } from '~/types/ICommerceVariant';
import { ICommerceProduct } from '~/types/ICommerceProduct';

import { FormInfoBox } from '../../CmsProduct/components/Form.InfoBox';
import { CertificatePreview } from './CertificatePreview';
import { PrimaryButton } from '~/App/shared/components/Elements';

import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';

import { HiddenAboveMedium } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';
import { PreviewModal } from '~/App/shared/components/Donation/components/Forms/components/PreviewModal';
import { QuantityStepper } from '~/App/shared/components/Donation/components/Forms/components/QuantityStepper';
import { CommerceProductState } from '../hooks/useCommerceProductState';
import { StockInfoBox } from '../../../shared/components/StockInfoBox';

// Config
import { buttonStyles } from '~/App/config/buttonStyles';
import { heading3 } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

const TopImage = styled.img`
  width: 185px;
  height: 185px;
  margin: auto;
  border-radius: 50%;
  border: 1rem solid white;
  display: block;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);

  ${mq('≥medium')`
    display: none;
  `};
`;

const StyledFormHeading = styled(FormHeading)`
  ${mq('<small')`
    display: none;
  `};
`;

const PreviewButton = styled(PrimaryButton)`
  display: block;
  margin: 1rem auto 1.5rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 0.5rem;
  align-items: center;
`;

const DeliveryTimeBox = styled.div`
  margin: 4px 78px 24px 2px;
  ${mq('<=small')`
    margin: 4px 5px 24px 0;
  `};
`;

const DeliveryTime = styled.p`
  margin: 4px 78px 24px 2px;
  font-size: 14px;
  ${mq('<=small')`
    margin: 4px 5px 24px 0;
    font-size: 12px;
  `};
`;

const Amount = styled.div`
  ${heading3};
  color: ${({ theme }) => theme.colors.darkBlue};
`;

type Props = {
  variant: ICommerceVariant;
  product: ICommerceProduct;
  state: CommerceProductState;
  modalOpen: boolean;
  toggleModal: () => void;
};

export function Information({
  variant,
  product,
  state,
  modalOpen,
  toggleModal
}: Props) {

  const deliveryTimes = {
    Normal: 'Levereras inom 1 till 2 arbetsdagar',
    OneWeek: 'Levereras inom 7 arbetsdagar',
    TwoWeeks: 'Levereras inom 14 arbetsdagar'
  };

  const previewCertificateMarkup = useMemo(() => {
    if (product.type !== 'DigitalCertificate') {
      return null;
    }
    if (!variant.discontinued) {
      return (
        <HiddenAboveMedium>
          <PreviewButton
            buttonStyle={buttonStyles.outlinePrimary}
            onClick={toggleModal}
            children="Förhandsgranska gåvobevis"
          />
        </HiddenAboveMedium>
      );
    }
  }, [product.type, variant.discontinued, toggleModal]);

  const fixedPriceMarkup = useMemo(() => {
    if (variant.price.type !== 'Fixed') {
      return;
    }

    return (
      <Flex>
        <Amount children={`${variant?.price?.value || '-'} kr`} />

        <QuantityStepper
          value={state.values.quantity}
          onChange={state.handlers.setQuantity}
        />
      </Flex>
    );
  }, [
    state.handlers.setQuantity,
    state.values.quantity,
    variant.price.type,
    variant.price?.value
  ]);

  return (
    <>
      {variant.primaryImage && <TopImage src={variant.primaryImage} />}
      {modalOpen && (
        <PreviewModal closeModal={toggleModal}>
          <CertificatePreview
            certificate={{
              ...variant.certificate,
              image: variant.primaryImage
            }}
            fullSize={true}
          />
        </PreviewModal>
      )}

      <FormInfoBox>
        <StyledFormHeading>Om produkten</StyledFormHeading>
        {variant.longDescription}
        {previewCertificateMarkup}
        {(variant.stock || variant.discontinued) && (
          <StockInfoBox variant={variant} />
        )}
        {variant.stock?.deliveryTime && (
        <DeliveryTimeBox>
          <DeliveryTime>
            {deliveryTimes[variant.stock?.deliveryTime]}
          </DeliveryTime>
        </DeliveryTimeBox>
        )}
        {fixedPriceMarkup}
      </FormInfoBox>
    </>
  );
}
