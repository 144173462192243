import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';
import { downloadLinkIcon } from '~/App/helpers/icons';
import { ICommerceOrder } from '~/types/ICommerceOrder';
import Link from '~/App/shared/components/Link';

import { OrderStatusModal } from './OrderStatusModal';
import { OrderReturnModal } from './OrderReturnModal';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.seashell};
  padding: 1rem 1.5rem 1.5rem;
  display: flex;
  justify-content: space-between;

  ${mq('≥small')`
      padding: 1.5rem 2.5rem 1.5rem 8.375rem;
  `};
`;

const DownloadLink = styled(Link)`
  font-size: 1rem;
  &::after {
    ${downloadLinkIcon};
    margin-top: -0.25rem;
    margin-left: 0.25rem;
  }

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

const ModalLink = styled(Link)`
  font-size: 1rem;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

type Props = {
  order: ICommerceOrder;
};

export function OrderActions({ order }: Props) {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);

  const closeModal = useCallback(
    (setState: Dispatch<SetStateAction<boolean>>) => () => setState(false),
    []
  );

  const openModal = useCallback(
    (setState: Dispatch<SetStateAction<boolean>>) => (
      event?: MouseEvent<HTMLDivElement>
    ) => {
      event?.preventDefault();
      setState(true);
    },
    []
  );

  const hasPhysicalProduct = useMemo<boolean>(
    () =>
      !!order.orderLines.find((x) => x.variant?.product?.type === 'Physical'),
    [order.orderLines]
  );

  return useMemo(
    () => (
      <>
        <Wrapper>
          {order.payment?.receiptUrl ? (
            <DownloadLink
              target="_blank"
              to={order.payment.receiptUrl}
              children="Kvitto"
            />
          ) : (
            <div />
          )}
          {hasPhysicalProduct ? (
          <div>
            <ModalLink
              to=""
              children="Retur"
              onClick={openModal(setShowReturnModal)}
            />
            <ModalLink
              to=""
              children="Orderstatus"
              onClick={openModal(setShowStatusModal)}
            />
          </div>) : (
            <div />
          )}
        </Wrapper>
        {showStatusModal && (
          <OrderStatusModal
            order={order}
            closeModal={closeModal(setShowStatusModal)}
          />
        )}
        {showReturnModal && (
          <OrderReturnModal
            order={order}
            closeModal={closeModal(setShowReturnModal)}
          />
        )}
      </>
    ),
    [closeModal, openModal, order, showReturnModal, showStatusModal, hasPhysicalProduct]
  );
}
