import React, { useCallback, useMemo } from 'react';
import {
  useAuthenticationContext,
  UserStatus
} from '~/App/contexts/Authentication';
import { HandleLoginOptions } from '~/App/contexts/Login';
import useLoginModal from '~/App/shared/hooks/use-login-modal';

// components
import { Qr } from './BankId.Qr';

import useLogin from '~/App/shared/hooks/use-login';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from './BankId.Spinner';
import { PrimaryButtonProps, primaryButton } from '~/App/helpers/mixins';
import styled, { css } from 'styled-components';
import mediaHelper from '~/App/helpers/media-helper';
import mq from '~/App/helpers/mq';
import { buttonStyles } from '~/App/config/buttonStyles';

const status: UserStatus[] = [
  'polling',
  'aborting',
  'signing',
  'waitingForBankID',
  'waitingForAppStart',
  'loading'
];

type BankIDButtonProps = {
  isDisabled: boolean;
} & PrimaryButtonProps;

const BankIDButton = styled.button<BankIDButtonProps>`
  ${primaryButton};
  display: block;
  margin: 1rem auto 0;

  ${mq<BankIDButtonProps>('≥mediumLarge')`
    display: inline-block;
    margin-bottom: 2.5rem;
    background-color: transparent;
    color: ${({ theme }) => theme.themeColors.primary};
    padding-left: 3.2rem;
    background-position: 1.2rem 0.7rem;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url(${mediaHelper.getUrl(
      'https://res.cloudinary.com/cancerfonden/image/upload/v1591085364/assets/login/bankid_logo.svg'
    )});

    &:hover {
      color: ${({ theme }) => theme.themeColors.primary};
      background-color: transparent;
    }

    ${({ theme, isDisabled }) =>
      isDisabled &&
      css`
        color: ${theme.colors.silverChalice};
        border-color: ${theme.colors.silverChalice};
        pointer-events: none;
      `};
  `};
`;

export function BankId() {
  const loginModal = useLoginModal();
  const [, setSearchParams] = useSearchParams();
  const authentication = useAuthenticationContext();
  const login = useLogin();
  const navigate = useNavigate();

  const toggleQrMode = useCallback(() => {
    loginModal.handlers.toggleQrMode(true);
  }, [loginModal.handlers]);

  const beginAuthentication = useCallback(
    async (option: HandleLoginOptions) => {
      const res = await login.handlers.handleLogin(option);

      if (res.success && res.shouldRedirect) {
        setSearchParams('');
        navigate('/min-sida');
      }
    },
    [login.handlers, navigate, setSearchParams]
  );

  const formElement = useMemo(() => {
    if (loginModal.qrMode) {
      return <Qr beginAuthentication={beginAuthentication} />;
    }

    if (authentication.status === 'idle') {
      return (
        <BankIDButton
          isDisabled={false}
          buttonStyle={buttonStyles.primary}
          children="Logga in med BankID"
          onClick={toggleQrMode}
        />
      );
    }

    return null;
  }, [
    authentication.status,
    beginAuthentication,
    loginModal.qrMode,
    toggleQrMode
  ]);

  const spinner = useMemo(() => {
    if (loginModal.qrMode || status.indexOf(authentication.status) === -1) {
      return null;
    }

    return <Spinner />;
  }, [loginModal.qrMode, authentication.status]);

  return (
    <>
      {formElement}
      {spinner}
    </>
  );
}
