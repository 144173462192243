import React from 'react';

// Shared components
import Validation from '~/App/shared/components/Validation';
import Redirect from '~/App/shared/components/Redirect';

// Local components
import { SubmitIncrease } from '../../components/Submits';
import BankId from '../../components/BankId';

import { Form } from './Form';
import { State } from '../../components/States';

import { schema } from '../schema';
import { useState } from './state';

type Props = {
  originPath: string;
  redirectPath: string;
  defaultState: Record<string, unknown>;
  formContent: {
    defaultPrice?: number;
    prices: number[];
    infoText?: string | undefined;
  };
};

export function IncreaseMonthlyAutogiroDonation({
  originPath,
  redirectPath,
  defaultState = {},
  formContent
}: Props) {
  const state = useState({ defaultState, formContent });

  return (
    <State
      values={state}
      formContent={{
        ...formContent,
        redirectPath,
        originPath
      }}
    >
      {(values: any) => (
        <Validation schema={schema} values={values}>
          {(validation) => (
            <SubmitIncrease
              values={values}
              paymentMethod={'autogiro'}
              validation={validation}
            >
              {(submit) => (
                <div>
                  {submit.isCompleted && (
                    <Redirect
                      to={`${redirectPath}?purchaseToken=${submit.publicToken}`}
                    />
                  )}
                  {submit.isBankId ? (
                    <BankId
                      failed={submit.errors}
                      loading={submit.isSending || submit.isPolling}
                      resetBankId={submit.resetBankId}
                    />
                  ) : (
                    <Form
                      values={values}
                      submit={submit}
                      validation={validation}
                      formContent={formContent}
                    />
                  )}
                </div>
              )}
            </SubmitIncrease>
          )}
        </Validation>
      )}
    </State>
  );
}
