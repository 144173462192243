import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '~/App/contexts/App';

export function usePageContent<T = any>(defaultValue?: T): T {
  const location = useLocation();
  const app = useAppContext();

  return useMemo<T>(
    () => (app.pageContent[location.pathname] || defaultValue) as T,
    [app.pageContent, location.pathname, defaultValue]
  );
}
