import React from 'react';
import styled from 'styled-components';
import * as icons from '~/App/helpers/icons';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 1rem 0 0 0;
`;

const Icon = styled.div`
  flex-shrink: 0;
  display: block;
  position: relative;
  width: 1.375rem;
  height: 1.375rem;
  color: ${(props) =>
    props.iconColor === 'blue'
      ? props.theme.colors.darkBlue
      : props.icon === 'heart'
      ? props.theme.themeColors.accentPink
      : 'inherit'};
  margin-right: 0.5rem;

  &::before {
    ${(props) => icons[props.icon]};
    position: absolute;
    font-size: 1.375rem;
    font-weight: bold;
    top: 50%;
    width: 100%;
    height: 100%;
    left: 50%;
    line-height: 1;
    text-align: center;
    transform: translate(-50%, -50%);
  }
`;

export const RightColumnText = function (props) {
  const { icon = 'heart', iconColor, children } = props;

  return (
    <TextWrapper>
      <Icon icon={icon} iconColor={iconColor}></Icon>
      <p>{children}</p>
    </TextWrapper>
  );
};
