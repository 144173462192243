export const buildSchema = () => ({
  'productOptions.product.price': {
    presence: {
      allowEmpty: false,
      message: '^Ange ett belopp'
    },
    numericality: {
      greaterThan: 49,
      lessThanOrEqualTo: 999999,
      message: '^Minsta belopp är 50 kr'
    }
  },
  'klarna.isReady': {
    inclusion: {
      within: [true],
      message: '^Klarna är inte redo'
    }
  }
});
