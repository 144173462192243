import { useMemo, useState, useEffect, useCallback } from 'react';

import * as cookie from '~/App/helpers/cookie';
import { getClientId } from '~/App/helpers/ga-helper';
import { pushGTMUserId, GtmDetails } from '~/App/helpers/gtm-helper';

import { gtmDetails, gtmCustomDetails } from '~/App/config/gtmDetails';
import { ProductValues } from '.';

export type AnalyticsValues = {
  productOptions: {
    campaignActivityId?: string | number;
  };
  clientOptions: {
    clientId: string | null;
    gclId?: string | null;
    userId: string | null;
  };
  gtm: GtmDetails;
  handlers: {
    setUserId: (value: string | null) => void;
    setGtmVariant: (value?: string) => void;
    setCampaignActivityId: (value?: number) => void;
    unsetCampaignActivityId: () => void;
  };
};

type InputProps = {
  values: ProductValues & {
    productOptions?: {
      campaignActivityId?: number | string | null;
    } & Record<string, unknown>;
    gtm?: {
      formName?: string | undefined;
      variant?: string;
    };
  };
  formContent?: {
    gtmId?: string;
  };
};

export function useAnalyticsValues<T extends InputProps>({
  values,
  formContent
}: T) {
  const [userId, setUserId] = useState<string | null>(null);
  const [campaignActivityId, setCampaignActivityId] = useState(
    values.productOptions?.campaignActivityId || undefined
  );
  const [variant, setGtmVariant] = useState<string | undefined>(
    values.gtm?.variant
  );

  const productId = useMemo(() => values.product?.id || 31, [values]);

  const gtm: GtmDetails = useMemo<GtmDetails>(() => {
    const gtmId = formContent?.gtmId;

    if (gtmId && gtmCustomDetails[gtmId]) {
      return {
        ...gtmCustomDetails[gtmId],
        formName: values.gtm?.formName,
        variant
      };
    }

    if (gtmDetails[productId]) {
      return {
        ...gtmDetails[productId],
        formName: values.gtm?.formName,
        variant
      };
    }

    return {
      name: '',
      brand: '',
      category: '',
      ...values.gtm,
      variant
    };
  }, [productId, formContent, values.gtm, variant]);

  const gclId = useMemo(() => cookie.get('gclid'), []);
  const clientId = useMemo(getClientId, []);

  const unsetCampaignActivityId = useCallback(
    () => setCampaignActivityId(undefined),
    []
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!userId) return;

    pushGTMUserId(userId);
  }, [userId]);

  return useMemo<AnalyticsValues & T['values']>(
    () => ({
      ...values,
      gtm,
      clientOptions: {
        clientId,
        gclId,
        userId
      },
      productOptions: {
        ...values.productOptions,
        campaignActivityId
      },
      handlers: {
        ...values.handlers,
        setUserId,
        setGtmVariant,
        setCampaignActivityId,
        unsetCampaignActivityId
      }
    }),
    [
      campaignActivityId,
      clientId,
      gclId,
      gtm,
      unsetCampaignActivityId,
      userId,
      values
    ]
  );
}
