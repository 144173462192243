import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import loadScript from '~/App/helpers/load-script';
import mediaHelper from '~/App/helpers/media-helper';

import { mq } from '~/lib/mq';
import { card, heading3 } from '~/App/helpers/mixins';
import { IBambuserVideo } from '~/types/IBambuserVideo';

import { HtmlContent } from '../../HtmlContent';

type ContentProps = {
  padding: string;
  carousel: {
    isActive: boolean;
  };
};

const Content = styled.div<ContentProps>`
  padding: ${({ padding }) => padding};
  width: 100%;
  flex: 1;

  ${mq<ContentProps>('<small')`
    padding: ${({ carousel }) => {
      if (carousel.isActive) {
        return '1rem 0';
      }

      return '1rem 1rem 2.5rem';
    }};
 `};
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  line-height: 0;

  &:hover {
    img {
      transform: scale(1.1);
    }

    ::before {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  ::before {
    content: '';
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1631526790/assets/icons/noun-play-3961832.svg');
    background-size: contain;
    height: 75px;
    width: 75px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 1;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
`;

const Image = styled.img`
  max-width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
`;

const Heading = styled.div`
  ${heading3};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 0.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

const layingStyles = css`
  ${Row} {
    flex-direction: row;
  }
`;

type CardProps = {
  lyingLayout: boolean;
  carousel: {
    isActive: boolean;
  };
};

const Card = styled.div<CardProps>`
  ${card};
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
  ${mq<CardProps>('>small')`
    ${({ lyingLayout }) => (lyingLayout ? layingStyles : null)};
 `};

  ${mq<CardProps>('<small')`
    ${({ carousel }) => (carousel.isActive ? ' box-shadow: none;' : null)};
 `};
`;

type BambuserLiveShoppingArguments = {
  showId: string;
  node: Node | HTMLElement | null;
  type: 'overlay';
};

type BambuserPlayer = {
  configure: (configuration: Record<string, unknown>) => void;
  BUTTON: {
    MINIMIZE: string;
    AUTO: string;
  };
  FLOATING_PLAYER_NAVIGATION_MODE: {
    MANUAL: string;
  };
  MINIMIZED_POSITION: {
    BOTTOM_RIGHT: string;
    BOTTOM_LEFT: string;
    TOP_LEFT: string;
    TOP_RIGHT: string;
  };
  EVENT: {
    NAVIGATE_BEHIND_TO: string;
  };
  on: (eventName: string, action: (event: { url: string }) => void) => void;
};

declare global {
  interface Window {
    initBambuserLiveShopping: (args: BambuserLiveShoppingArguments) => void;
    onBambuserLiveShoppingReady: (player: BambuserPlayer) => void;
  }
}

type Props = {
  video: IBambuserVideo;
  numberOfItems: number;
  carousel: {
    isActive: boolean;
  };
};

export function BambuserCard({ video, numberOfItems, carousel }: Props) {
  const ref = useRef<HTMLImageElement | null>(null);
  const navigate = useNavigate();

  const lyingLayout = numberOfItems === 1;
  const padding = useMemo(() => {
    if (numberOfItems === 1) return '2rem 1rem 2.5rem';
    if (numberOfItems === 2) return '1.5rem 1rem 2.5rem';
    return '1rem 1rem 2.5rem';
  }, [numberOfItems]);

  const image = useMemo(
    () =>
      mediaHelper.getUrl(video.image?.source?.secure_url, {
        width: 568,
        height: 335
      }),
    [video.image.source.secure_url]
  );

  const setupBambuser = useCallback(async () => {
    await loadScript({
      url: 'https://lcx-embed.bambuser.com/cancerfonden/embed.js'
    });

    window.initBambuserLiveShopping({
      showId: video.showId,
      node: ref.current,
      type: 'overlay'
    });

    window.onBambuserLiveShoppingReady = (player: BambuserPlayer) => {
      player.configure({
        currency: 'SEK',
        locale: 'sv-SE',
        buttons: {
          dismiss: player.BUTTON.AUTO,
          checkout: player.BUTTON.AUTO
        },
        floatingPlayer: {
          navigationMode: player.FLOATING_PLAYER_NAVIGATION_MODE.MANUAL
        },
        minimizedPosition: player.MINIMIZED_POSITION.BOTTOM_LEFT
      });

      player.on(player.EVENT.NAVIGATE_BEHIND_TO, function (event) {
        if (!event.url.startsWith('http')) {
          return navigate(event.url);
        }

        const url = new URL(event.url);

        if (url.hostname.endsWith('cancerfonden.se')) {
          return navigate(url.pathname);
        }

        if (url.hostname.endsWith('herokuapp.com')) {
          return navigate(url.pathname);
        }

        window.open(event.url, '_blank')?.focus();
      });
    };
  }, [video.showId, navigate]);

  useEffect(() => {
    setupBambuser();
  }, [setupBambuser]);

  return (
    <Card lyingLayout={lyingLayout} carousel={carousel}>
      <Row>
        <ImageWrapper ref={ref}>
          <Image src={image} />
        </ImageWrapper>
        <Content padding={padding} carousel={carousel}>
          <Heading children={video.heading} />
          <HtmlContent children={video.preamble} />
        </Content>
      </Row>
    </Card>
  );
}
