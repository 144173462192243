import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const Wrapper = styled.div`
  max-width: 494px;
  margin-right: auto;
  margin-left: auto;

  ${mq('≥medium')`
    max-width: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  `};
`;
