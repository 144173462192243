import { fetch } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';

export const loadData = async () => {
  const response = await fetch({
    url: contentApi('/v1/content-definitions'),
    params: {
      slugs: [
        'podcasts-hero',
        'podcasts-intro',
        'podcasts-brick1',
        'podcasts-chapters',
        'podcasts-brick2',
        'podcasts-brick3'
      ],
      metaKey: 'podcastsHero'
    }
  });

  return {
    data: response.data
  }
}