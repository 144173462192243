// import { meCollections } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export async function loadData({ cookies }: LoadDataProps) {
  return {
    data: []
  };
  // if (!cookies.secretKey) {
  //   return {
  //     data: []
  //   };
  // }

  // const { data } = await meCollections.index({
  //   params: {
  //     page: 1,
  //     per_page: 10,
  //     type: 'memorial_page'
  //   },
  //   secretKey: `${cookies.secretKey}`
  // });

  // return {
  //   data
  // };
}
