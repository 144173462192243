import React from 'react';

import Link from '~/App/shared/components/Link';

class Button extends React.Component {
  render() {
    if (!this.props.buttonLabel || !this.props.buttonLink) {
      return null;
    }

    return (
      <div className="heroTitle">
        <div className="heroTitle__button">
          <Link
            className="button button--solid-ocean"
            to={this.props.buttonLink}
          >
            {this.props.buttonLabel}
          </Link>
        </div>
      </div>
    );
  }
}

Button.displayName = 'HeroButton';

Button.defaultProps = {
  heroTextColor: '',
  buttonLabel: '',
  buttonLink: ''
};

export default Button;
