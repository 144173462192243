import { useRef, useState, useMemo, useCallback, useEffect } from 'react';

import _ from 'lodash';
import 'scroll-behavior-polyfill';

import { useScroll } from '~/App/shared/hooks/use-scroll';
import { useAppContext } from '~/App/contexts/App';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import { useBreakpoint } from '~/App/shared/hooks/use-breakpoint';
import { useWindowSize } from '~/App/shared/hooks/use-window-size';

export const sizes = {
  normal: {
    gutter: 1,
    edgePadding: 19.5,
    width: 48.5
  },
  limited: {
    gutter: 0.5,
    edgePadding: 6,
    width: 48.5
  },
  tight: {
    gutter: 0.25,
    edgePadding: 6,
    width: 25
  }
};

type Props = {
  items: any[];
  selectedSize?: 'normal' | 'limited' | 'tight';
};

export function useSlideshow({ items, selectedSize }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [suggestedIndex, setSuggestedIndex] = useState(0);
  const [isExact, setExact] = useState(true);
  const [isAnimating, setAnimating] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const userAgent = useAppContext((x) => x.userAgent);
  const isMobile = useBreakpoint('<medium', isMobileBrowser(userAgent));
  const windowSize = useWindowSize();

  const size = useMemo(
    () => (selectedSize ? sizes[selectedSize] : sizes.normal),
    [selectedSize]
  );

  const itemWidth = useMemo(
    () => ref.current?.children[0]?.clientWidth ?? 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowSize]
  );

  const isLast = useMemo(() => selectedIndex === items.length - 1, [
    items,
    selectedIndex
  ]);

  const isFirst = useMemo(() => selectedIndex === 0, [selectedIndex]);

  const updateIndex = useCallback(
    (delta: number) => () => {
      if ((delta < 0 && isFirst) || (delta > 0 && isLast)) {
        return;
      }

      setAnimating(true);
      setSelectedIndex(selectedIndex + delta);
    },
    [setSelectedIndex, selectedIndex, isFirst, isLast]
  );

  const getSuggested = useCallback(
    (index) => {
      const clientWidth = ref.current?.clientWidth || 0;
      const gutter = (clientWidth - itemWidth) / 2;
      const suggested = Math.round(index * itemWidth - gutter);

      return suggested;
    },
    [itemWidth]
  );

  const handleScroll = useCallback(
    (e) => {
      const offset = e.target?.scrollLeft ?? 0;
      const index = Math.round(offset / itemWidth);
      const suggestedIndex = _.clamp(index, 0, items.length);

      setExact(getSuggested(suggestedIndex) === getSuggested(selectedIndex));
      setSuggestedIndex(suggestedIndex);
    },
    [getSuggested, itemWidth, items.length, selectedIndex]
  );

  useScroll(handleScroll, {
    el: ref.current,
    debounceType: 'debouce'
  });

  useEffect(() => {
    if (isMobile) {
      return;
    }

    ref.current?.scroll({
      left: getSuggested(selectedIndex),
      behavior: 'smooth'
    });
  }, [ref, selectedIndex, itemWidth, isMobile, getSuggested]);

  useEffect(() => {
    const inSync = selectedIndex === suggestedIndex;
    const shouldChangeAnimatingStatus = inSync && isExact;

    if (isAnimating) {
      if (shouldChangeAnimatingStatus) setAnimating(false);
      return;
    }

    setSelectedIndex(suggestedIndex);
  }, [isAnimating, selectedIndex, suggestedIndex, isExact]);

  return useMemo(
    () => ({
      updateIndex,
      ref,
      isFirst,
      isLast,
      selectedIndex,
      size: {
        ...size,
        item: itemWidth
      }
    }),
    [isFirst, isLast, itemWidth, selectedIndex, size, updateIndex]
  );
}
