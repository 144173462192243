export function buildValidationKeys(onlyDigitalProducts: boolean) {
  if (onlyDigitalProducts) {
    return {
      1: [
        'swishPhoneNumber',
        'productOptions.customerContact.ssn',
        'productOptions.customerContact.email'
      ],
      2: []
    };
  }
  return {
    1: [
      'productOptions.customerContact.addressStreet',
      'productOptions.customerContact.addressZip',
      'productOptions.customerContact.addressCity',
      'productOptions.customerContact.firstName',
      'productOptions.customerContact.lastName',
      'productOptions.customerContact.phoneNumber'
    ],
    2: [
      'swishPhoneNumber',
      'productOptions.customerContact.ssn',
      'productOptions.customerContact.email'
    ]
  };
}
