import React from 'react';

import { Bold } from '.';
import { MemorialGiftValues } from '../../../../components/States';

type Props = {
  values: MemorialGiftValues;
};

export function CertificateSummery({ values }: Props) {
  return (
    <>
      <Bold
        children={`${values.productOptions?.inMemoryOfContact?.firstName} ${values.productOptions?.inMemoryOfContact?.lastName}`}
      />
      <Bold children={values.productOptions?.memorialGift?.textGreeting} />
    </>
  );
}
