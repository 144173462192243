import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useInfiniteScroll } from '~/App/shared/hooks/use-infinite-scroll';
import { IMeCollection } from '~/types/ICollection';
import { meCollections } from '~/App/helpers/http';
import { mq } from '~/lib/mq';

import { Container } from '~/App/shared/components/Container';
import { FloatingButton } from '~/App/shared/components/FloatingButton';
import { Card } from './Card';

const AccountCards = styled.div`
  display: grid;
  grid-gap: 2rem;

  ${mq('<medium')`
   grid-gap: 1rem;
  `};

  ${mq('<tiny')`
   grid-gap: 1rem;
  `};
`;

type Props = {
  data: IMeCollection[];
};

export function AccountMemorial({ data }: Props) {
  const [memorialPages, setMemorialPages] = useState(data);

  const renderCard = useCallback(
    (page: IMeCollection, index: number) => <Card key={index} page={page} />,
    []
  );

  const loadPage = useCallback(async (page: number) => {
    const res = await meCollections.index({
      params: {
        page,
        per_page: 12,
        type: 'memorial_page'
      }
    });

    setMemorialPages((state) => [...state, ...res.data]);

    return res.data.length === 12;
  }, []);

  const trigger = useInfiniteScroll({
    loadPage,
    hasFollowingPage: data.length === 12
  });
  const cards = useMemo(() => memorialPages.map(renderCard), [
    renderCard,
    memorialPages
  ]);

  return (
    <>
      <Container>
        <AccountCards children={cards} />
        {trigger}
      </Container>
      <FloatingButton
        buttonStyle="cta"
        to={'/stod-oss/minnessidor/skapa-minnessida'}
        children="Skapa en minnessida"
      />
    </>
  );
}
