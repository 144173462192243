import React from 'react';

export default class PrintLink extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick(event) {
    event.preventDefault();
    window.print();
  }

  render() {
    return (
      <a onClick={this.handleClick} {...this.props}>
        {this.props.children}
      </a>
    );
  }
}
