import { IDeceasedContact } from '~/types/IDeceasedContact';
import { mwAxios } from '~/App/helpers/http';

export const deceasedContact = {
  search: async (q: string, created_from: string) => {
    const { data } = await mwAxios.get<IDeceasedContact[]>(
      '/api/v2/bitnet/deceased_contacts',
      {
        params: {
          q,
          created_from
        }
      }
    );

    return data;
  }
};
