import React from 'react';

import { IScreen } from '../../../../SelfTest/types';
import { IAnswer, IBMIAnswer } from '~/types/IQuestionSession';

import { QuestionResultScreen } from './QuestionResultScreen';
import { BMIResultScreen } from './BMIResultScreen';

type Props = {
  screen: IScreen;
  value: IAnswer | undefined;
};

export function Screen({ screen, value }: Props) {
  if (!value) {
    return null;
  }

  if (screen.type === 'QuestionScreen') {
    return <QuestionResultScreen screen={screen} value={value} />;
  }

  if (screen.type === 'BMIScreen') {
    return <BMIResultScreen screen={screen} value={value as IBMIAnswer} />;
  }
  return null;
}
