import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

// Shared components
import PrintLink from '~/App/shared/components/PrintLink';
import EmailLink from '~/App/shared/components/EmailLink';
import TwitterShareUrl from '~/App/shared/components/TwitterShareUrl';
import FacebookShareUrl from '~/App/shared/components/FacebookShareUrl';
import LinkedInShareUrl from '~/App/shared/components/LinkedInShareUrl';
import ShareLink from '~/App/shared/components/ShareLink';

// Helpers
import {
  emailButton,
  printButton,
  xButton,
  facebookButton,
  linkedInButton,
  linkButton
} from '~/App/helpers/mixins';
import { siteUrl } from '~/App/helpers/env';
import mq from '~/App/helpers/mq';

const BUTTON_WIDTH = 2.5;
const BASE_GUTTER = 0.75;

const ShareButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  vertical-align: middle;
  width: ${({ largeGutter, buttonCount }) =>
    `${
      (largeGutter ? 2 : BASE_GUTTER) * (buttonCount - 1) +
      BUTTON_WIDTH * buttonCount
    }rem`};

  ${mq('≥small')`
      width: ${({ largeGutter, buttonCount }) =>
        `${
          (largeGutter ? 3 : BASE_GUTTER) * (buttonCount - 1) +
          BUTTON_WIDTH * buttonCount
        }rem`};
  `};
`;

const FacebookButton = styled(FacebookShareUrl)`
  ${facebookButton};
  width: ${BUTTON_WIDTH}rem;
  height: 2.5rem;
  font-size: 1.625rem;
`;

const LinkedInButton = styled(LinkedInShareUrl)`
  ${linkedInButton};
  width: ${BUTTON_WIDTH}rem;
  height: 2.5rem;
  font-size: 1.625rem;
`;

const XButton = styled(TwitterShareUrl)`
  ${xButton};
  width: ${BUTTON_WIDTH}rem;
  height: 2.5rem;
  font-size: 1.625rem;

  &:before {
    margin-left: 1px;
  }
`;

const EmailButton = styled(EmailLink)`
  ${emailButton};
  width: ${BUTTON_WIDTH}rem;
  height: 2.5rem;
  font-size: 1.625rem;

  &:before {
    margin-top: -1px;
  }
`;

const PrintButton = styled(PrintLink)`
  ${printButton};
  width: ${BUTTON_WIDTH}rem;
  height: 2.5rem;
  font-size: 1.625rem;

  &:before {
    margin-top: -1px;
  }
`;

const LinkButton = styled(ShareLink)`
  ${linkButton};
  width: ${BUTTON_WIDTH}rem;
  height: 2.5rem;
  font-size: 1.625rem;
  vertical-align: top;

  &:before {
    margin-top: -1px;
  }
`;

export default function ShareButtons({
  className = '',
  customUrl = '',
  ...props
}) {
  const location = useLocation();
  const {
    to = siteUrl(location.pathname),
    tweet: { text: tweetText = '' } = {},
    email: {
      subject: emailSubject = 'Intressant artikel på cancerfonden.se',
      body: emailBody
    } = {},
    facebook = '',
    showFacebook = false,
    showLinkedIn = false,
    showX = false,
    showEmail = false,
    showPrint = false,
    showLink = false,
    largeGutter = false,
    invertedColors = false
  } = props;

  const buttonCount =
    showFacebook + showLinkedIn + showX + showEmail + showPrint + showLink;

  return (
    <ShareButtonsWrapper
      className={className}
      buttonCount={buttonCount}
      largeGutter={largeGutter}
    >
      {showFacebook && (
        <FacebookButton
          invertedColors={invertedColors}
          theme={props.theme}
          text={facebook}
          to={customUrl ? customUrl : to}
        />
      )}
      {showLinkedIn && (
        <LinkedInButton
          invertedColors={invertedColors}
          theme={props.theme}
          to={customUrl ? customUrl : to}
        />
      )}
      {showX && (
        <XButton
          theme={props.theme}
          to={customUrl ? customUrl : to}
          text={tweetText}
          invertedColors={invertedColors}
        />
      )}
      {showEmail && (
        <EmailButton
          invertedColors={invertedColors}
          theme={props.theme}
          body={emailBody || to}
          subject={emailSubject}
        />
      )}
      {showPrint && (
        <PrintButton theme={props.theme} invertedColors={invertedColors} />
      )}
      {showLink && (
        <LinkButton
          invertedColors={invertedColors}
          theme={props.theme}
          to={customUrl ? customUrl : to}
        />
      )}
    </ShareButtonsWrapper>
  );
}
