import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { users } from '~/App/helpers/http';
import { heading2 } from '~/App/helpers/mixins';
import { ViewWrapper } from '../../../../ViewWrapper';

import { EmailForm } from '../../../components/EmailForm';
import { EmailConfirmationCompleted } from '../../EmailConfirmationCompleted';
import { EmailConfirmationSent } from '../../EmailConfirmationSent';
import { Loading } from '../../Loading';

const FlexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  max-width: 380px;
  margin: -2rem auto 0;
`;

const Heading1 = styled.h1`
  ${heading2};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
`;

const Preamble = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
  margin-bottom: 1.5rem;
`;

const Image = styled.img`
  display: block;
  width: 143px;
  margin: 0 auto 1.5rem;
`;

type Props = {
  value: string;
  token?: string;
};

export function UpdateEmail({ value, token }: Props) {
  const authentication = useAuthenticationContext();
  const [email, setEmail] = useState(value);
  const [isVerifying, setVerifying] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [confirmationNeeded, setConfirmationNeeded] = useState(false);

  const handleSubmit = useCallback(
    async (email) => {
      setEmail(email);
      setVerifying(true);

      if (token) {
        const { data } = await users.verifyEmail({
          email,
          token
        });

        setConfirmationNeeded(data.confirmationNeeded);
      } else {
        const response = await authentication.update({ email });

        if (response.success) {
          setConfirmationNeeded(response.user.emailStatus === 'unverified');
        }
      }

      setVerifying(false);
      setVerified(true);
    },
    [authentication, token]
  );

  if (isVerifying) {
    return <Loading />;
  }

  if (isVerified) {
    if (confirmationNeeded) {
      return <EmailConfirmationSent email={email} />;
    }

    return <EmailConfirmationCompleted />;
  }

  return (
    <ViewWrapper alternativeBackground>
      <FlexWrapper>
        <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1636967655/assets/icons-communication-newsletter_cropped.png" />
        <Heading1>Är din e-post korrekt?</Heading1>
        <Preamble>
          Kontrollera och bekräfta din e-postadress är aktuell.
        </Preamble>
        <EmailForm defaultValue={email} onSubmit={handleSubmit} />
      </FlexWrapper>
    </ViewWrapper>
  );
}
