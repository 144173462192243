import React from 'react';
import styled from 'styled-components';

// Helpers
import { clearfix } from '~/App/helpers/mixins';

const Header = styled.div`
  margin-bottom: 1.5rem;
`;

const Content = styled.div`
  ${clearfix};
`;

const Footer = styled.div`
  ${clearfix};
  width: 100%;
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid #ccc;
`;

export default class SearchResults extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { header, footer, content } = this.props;

    return (
      <div>
        <Header>{header}</Header>
        <Content>{content}</Content>
        <Footer>{footer}</Footer>
      </div>
    );
  }
}
