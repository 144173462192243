import React, { useMemo } from 'react';
import { useValidation } from '~/App/shared/components/Validation';
import { IImageSource } from '~/types/IImage';
import { buildSchema } from './schema';
import { useState } from '../components/States';
import { SwishBackground, Wrapper } from './SwishBlockDonation.styled';
import { Form } from './components/Form';
import { buildState } from './state';
import { useSubmit } from '../components/Submits';
import { useMultiPartFormState } from '../../MultiPartForm';
import SwishRedirect from './SwishRedirect';
import { useLocation } from 'react-router-dom';
import { Heading } from './components/Heading';
import { BackgroundColor, Padding, Width } from '~/types/Block';
import Container from '../../Container';

type Props = {
  heading: string;
  headingSettings?: {
    fontSize: string;
  };
  large: {
    style: string;
    size: string;
    source: IImageSource;
  };
  small: {
    style: string;
    size: string;
    source: IImageSource;
  };
  imageAlt?: string;
  campaignId?: number;
  width: Width;
  padding?: Padding;
  backgroundColor?: BackgroundColor;
  showLightTextColor: boolean;
};

export function SwishBlockDonation({
  heading,
  headingSettings,
  large,
  small,
  imageAlt,
  campaignId,
  width,
  padding,
  backgroundColor,
  showLightTextColor
}: Props) {
  const schema = useMemo(buildSchema, []);
  const state = useMemo(() => buildState({ campaignId }), [campaignId]);
  const values = useState({
    values: state,
    formContent: {
      redirectPath: useLocation().pathname,
      originPath: useLocation().pathname,
      gtmId: '3c'
    }
  });

  const validation = useValidation({
    values,
    schema
  });

  const multipartValues = useMultiPartFormState({
    values,
    validation,
    parts: 2
  });

  const submit = useSubmit({ values: multipartValues, validation });

  const largeBreakpoint = useMemo(
    () => ({
      ...large,
      url:
        large.source && large.source.secure_url ? large.source.secure_url : ''
    }),
    [large]
  );
  const smallBreakpoint = useMemo(
    () => ({
      ...small,
      url:
        small.source && small.source.secure_url
          ? small.source.secure_url
          : largeBreakpoint.url
    }),
    [largeBreakpoint.url, small]
  );

  return (
    <Container
      width={width}
      paddingTop={padding?.top}
      paddingBottom={padding?.bottom}
      paddingLeft={width === 'full' ? 'none' : 'small'}
      paddingRight={width === 'full' ? 'none' : 'small'}
    >
      <SwishBackground
        width={width}
        small={smallBreakpoint}
        large={largeBreakpoint}
        lightTextColor={showLightTextColor}
        backgroundColor={backgroundColor}
      >
        {imageAlt && <span role="img" aria-label={imageAlt} />}
        <Wrapper>
          {!submit.isCompleted && (
            <Heading
              heading={heading}
              fontSize={headingSettings?.fontSize}
              width={width}
            />
          )}
          <SwishRedirect // Only mobile
            submit={submit}
          />
          <Form
            validation={validation}
            submit={submit}
            values={multipartValues}
            width={width}
            headingSettings={headingSettings}
            showLightTextColor={showLightTextColor}
          />
        </Wrapper>
      </SwishBackground>
    </Container>
  );
}
