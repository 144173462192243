import axios from 'axios';

// Constants
import { errorLevels } from '~/App/config/errorLevels';

export const logMessage = (logData) => {
  const { level = errorLevels.info, message = '', data = {}, path } = logData;

  if (typeof window === 'undefined') {
    console.log({
      path: path || window.location.href,
      errorLevel: level,
      message: message,
      data: data
    });
  } else {
    axios({
      url: `/api/v2/log/`,
      method: 'post',
      timeout: 15000,
      responseType: 'json',
      data: {
        path: path || 'ServerSide',
        errorLevel: level,
        message: message,
        data: data
      }
    });
  }
};

export const logSimpleMessage = (message) => {
  if (typeof window === 'undefined') {
    console.log({ message: `ServerSide: ${message}` });
  } else {
    axios({
      url: `/api/v2/log/`,
      method: 'post',
      timeout: 15000,
      responseType: 'json',
      data: { message: `${window.location.href}: ${message}` }
    });
  }
};
