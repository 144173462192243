import React, { ComponentType, useMemo } from 'react';
import { LoadData, LoadDataProps } from '~/types/routes';
import { usePageContent } from '../shared/hooks/use-page-content';

type PageContent = Record<string, unknown> & {
  usePreferred?: boolean;
};

type View = {
  Component: ComponentType<any>;
  loadData: LoadData;
};

export function withOverwrittenView(preferred: View, fallback: View) {
  const View = (props: unknown) => {
    const pageContent = usePageContent<PageContent>();

    const children = useMemo(() => {
      if (!pageContent) {
        return null;
      }

      if (pageContent.usePreferred) {
        return <preferred.Component {...pageContent} {...props} />;
      }

      return <fallback.Component {...pageContent} {...props} />;
    }, [pageContent, props]);

    return children;
  };

  View.loadData = async (props: LoadDataProps) => {
    try {
      const data = await preferred.loadData(props);

      if (data) {
        return {
          usePreferred: true,
          ...data
        };
      }
    } catch (error) {
      // ignore
    }

    return await fallback.loadData(props);
  };

  return View;
}
