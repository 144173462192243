import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

// helpers
import { heading2 } from '~/App/helpers/mixins';
import { users } from '~/App/helpers/http';

// config
import colors from '~/App/config/themes/colors';

// shared
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { EmailForm } from '../components/EmailForm';
import { Loading } from './Loading';
import { EmailConfirmationSent } from './EmailConfirmationSent';
import { EmailConfirmationCompleted } from './EmailConfirmationCompleted';
import { ViewWrapper } from '../../ViewWrapper';

const FlexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  max-width: 380px;
  margin: auto;
`;

const Heading = styled.h1`
  ${heading2};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${colors.darkBlue};
`;

const Span = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: ${colors.darkBlue};
`;

const Image = styled.img`
  display: block;
  width: 90px;
  margin: 1.5rem auto;
`;

type Props = {
  token?: string;
};

export function Email({ token }: Props) {
  const [confirmationNeeded, setConfirmationNeeded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [email, setEmail] = useState('false');

  const authentication = useAuthenticationContext();

  const handleConfirm = useCallback(
    async (email: string) => {
      setLoading(true);
      setEmail(email);

      if (token) {
        const { data } = await users.verifyEmail({
          email,
          token
        });

        setConfirmationNeeded(data.confirmationNeeded);
      } else {
        const response = await authentication.update({ email });

        if (response.success) {
          setConfirmationNeeded(response.user.emailStatus === 'unverified');
        }
      }

      setLoading(false);
      setUpdated(true);
    },
    [authentication, token]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isUpdated) {
    return confirmationNeeded ? (
      <EmailConfirmationSent email={email} />
    ) : (
      <EmailConfirmationCompleted />
    );
  }

  return (
    <ViewWrapper alternativeBackground>
      <FlexWrapper>
        <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1590651238/assets/login/email.svg" />
        <Heading>Ange din e-post</Heading>
        <Span>
          Ange din e-postadress för att få viktig information från oss löpande.
        </Span>
        <EmailForm onSubmit={handleConfirm} />
      </FlexWrapper>
    </ViewWrapper>
  );
}
