import styled from 'styled-components';

//helpers
import mq from '~/App/helpers/mq';

//Shared components
import { PrimaryButton as BaseButton } from '~/App/shared/components/Elements';

export const Container = styled.div`
	position: absolute;
	background-color: ${(props) => props.theme.colors.white};
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1.5rem;
	text-align: center;
`;

export const PrimaryButton = styled(BaseButton)`
  margin: 0 0 1rem 0;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
	margin: 2rem auto 0.5rem;
	max-width: 258px;

	${mq('≥mediumLarge')`
    margin-left: 0;
  `};
`;
