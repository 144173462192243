import styled from 'styled-components';

import HtmlContent from '~/App/shared/components/HtmlContent';

import { heading3 } from '~/App/helpers/mixins';

export const Heading = styled.h2`
  ${heading3};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 0.5rem;
`;

export const Description = styled(HtmlContent)`
  margin-bottom: 1rem;
`;

export const Source = styled.div`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;
