import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { trashCan } from '~/App/helpers/icons';
//import mq from '~/App/helpers/mq';

export const TrashCanIcon = styled.div`
  &:before {
    ${trashCan};
    font-size: 1.5rem;
  }
  ${mq('<mediumLarge')`
  &:before {
    font-size: 1.25rem;
  }
  `};
`;
