import React, { ReactNode, MouseEvent, useMemo } from 'react';
import styled from 'styled-components';

// Components
import { PrimaryButton, Modal } from '../../../Elements';

// Helpers
import mq from '~/App/helpers/mq';
import { giftIncrease } from '~/App/helpers/icons';
import { heading1, description3 } from '~/App/helpers/mixins';

const StyledModal = styled(Modal)`
  text-align: center;
  padding: 2.5rem 1.25rem;

  ${mq('≥small')`
    padding: 4.063rem 5.5rem 3.5rem;
  `};
`;

const Icon = styled.div`
  margin-bottom: 2rem;

  ${mq('≥small')`
    margin-bottom: 1.5rem;
  `};

  &:after {
    ${giftIncrease};
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.darkBlue};

    ${mq('≥small')`
      font-size: 3.625rem;
    `};
  }
`;

const Title = styled.h2`
  ${heading1};
  color: ${(props) => props.theme.colors.darkBlue};

  ${mq('≥small')`
    margin-bottom: 1.5rem;
  `};
`;

const Wrapper = styled.div`
  margin: 0.75rem auto 0rem;
  display: flex;
  flex-direction: column;

  ${mq('≥small')`
    margin: 1rem auto 0rem;
  `};

  button {
    margin: 0.5rem auto;
  }
`;

const Disclaimer = styled.p`
  ${description3};
  margin: 1.5rem 0 0.5rem 0rem;
  color: ${(props) => props.theme.colors.charcoal};

  ${mq('≥small')`
    font-size: 0.875rem;
    margin-top: 2rem;
    line-height: 1.93;
  `};
`;

type Content = {
  children: ReactNode;
  heading?: string;
  disclaimer?: string;
};

type Values = {
  amount: number;
  recommendedAmount: number;
};

type Props = {
  amount: number;
  recommendedAmount: number;
  getContent: (values: Values) => Content;
  submitWithAmount: (event: MouseEvent<HTMLButtonElement>) => void;
  submitWithRecommendedAmount: (event: MouseEvent<HTMLButtonElement>) => void;
};

export function TaxReductionNudgingModal({
  getContent,
  amount,
  recommendedAmount,
  submitWithAmount,
  submitWithRecommendedAmount
}: Props) {
  const { heading, children, disclaimer } = useMemo(
    () => getContent({ amount, recommendedAmount }),
    [amount, getContent, recommendedAmount]
  );

  return useMemo(
    () => (
      <StyledModal maxWidth="44.25rem">
        <Icon />
        {heading && <Title children={heading} />}
        {children}
        <Wrapper>
          <PrimaryButton
            buttonStyle="cta"
            className="ga-nudge-up"
            onClick={submitWithRecommendedAmount}
            children={`Ge ${recommendedAmount} och slutför`}
          />

          <PrimaryButton
            buttonStyle="outlinePrimary"
            className="ga-no-nudge"
            onClick={submitWithAmount}
            children={`Ge ${amount} och slutför`}
          />
        </Wrapper>
        {disclaimer && <Disclaimer children={disclaimer} />}
      </StyledModal>
    ),
    [
      amount,
      children,
      disclaimer,
      heading,
      recommendedAmount,
      submitWithAmount,
      submitWithRecommendedAmount
    ]
  );
}
