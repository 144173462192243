import React, { useCallback, useEffect, useRef, useState } from 'react';

// shared components
import Redirect from '~/App/shared/components/Redirect';

import { pushGTMPurchaseAsync } from '~/App/helpers/gtm-helper';
import { ISubmit } from './Submits';
import { SubmitValues } from '~/App/shared/components/Donation/components/Submits/Submit/types/SubmitValues';

type Props = {
  submit: ISubmit;
  redirectPath: string;
  values?: SubmitValues;
};

export function PaymentRedirect({ submit, redirectPath, values }: Props) {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const isSent = useRef(false);

  const amount = values?.productOptions.memorialGift?.amount
    ? values.productOptions?.memorialGift.amount
    : values?.productOptions?.product?.price;

  const sendPurchaseToGtm = useCallback(() => {
    if (isSent.current) {
      return;
    }
    if (!submit.gtmPurchase) {
      return;
    }

    if (!submit.gtm) {
      return;
    }

    isSent.current = true;

    pushGTMPurchaseAsync(submit.gtmPurchase, submit.gtm).then(() => {
      setShouldRedirect(true);
    });
  }, [submit.gtm, submit.gtmPurchase]);

  useEffect(() => {
    if (!submit.klarnaRedirectUrl) return;

    sendPurchaseToGtm(); // Klarna
  }, [sendPurchaseToGtm, submit.klarnaRedirectUrl]);

  useEffect(() => {
    if (
      submit.isCompleted &&
      !submit.paymentUrl &&
      !submit.swishToken &&
      !submit.swishLoadUrl
    ) {
      sendPurchaseToGtm(); // Stripe
    }
  }, [
    submit.paymentUrl,
    submit.swishLoadUrl,
    submit.swishToken,
    submit.isCompleted,
    sendPurchaseToGtm
  ]);

  if (!submit.isCompleted) {
    return null;
  }

  if (submit.klarnaRedirectUrl) {
    return <Redirect to={submit.klarnaRedirectUrl} external />;
  }

  // Dibs
  if (submit.paymentUrl) {
    return <Redirect to={submit.paymentUrl} post />;
  }

  // Swish m-commerce
  if (submit.swishToken && submit.swishLoadUrl) {
    return (
      <>
        <Redirect
          to={`swish://paymentrequest?token=${
            submit.swishToken
          }&callbackurl=${encodeURIComponent(submit.swishLoadUrl)}`}
          external
        />
      </>
    );
  }
  // Swish e-commerce
  if (submit.swishLoadUrl) {
    return (
      <Redirect
        to={`${submit.swishLoadUrl}&amount=${amount}&step=${values?.partOptions?.currentPart}&formName=${values?.gtm?.formName}&period=${values?.productOptions.product.period}&paymentMethod=${values?.paymentMethod?.name}`}
        external
      />
    );
  }

  if (shouldRedirect) {
    return (
      <Redirect to={`${redirectPath}?purchaseToken=${submit.publicToken}`} />
    );
  }

  return null;
}

export default PaymentRedirect;
