import { ISelfTestConfig } from './../types';
import { createContext } from 'react';

import {
  IAnswer,
  IBaseAnswer,
  IQuestionSession
} from '~/types/IQuestionSession';
import { Index, ISection, IScreen } from '../types';

export type SelfTestContextValue = {
  session: IQuestionSession | null;
  config: ISelfTestConfig;
  isStarted: boolean;
  isFirstScreen: boolean;
  isLastScreen: boolean;
  currentQuestion: number | null;
  currentSection: ISection | null;
  currentScreen: IScreen | null;
  numberOfQuestions: number;
  index: Index | null;
  navigateTo: (delta: 1 | -1) => () => void;
  setSession: (value: IQuestionSession) => Promise<void>;
  setAnswer: (value: IAnswer) => Promise<void>;
  getAnswer: <T extends IAnswer = IBaseAnswer>(
    questionId: number | string
  ) => T | null;
  start: () => Promise<void>;
  complete: () => void;
};

export const Context = createContext<SelfTestContextValue>({
  session: null,
  config: {
    sections: []
  },
  isStarted: false,
  isFirstScreen: false,
  isLastScreen: false,
  currentQuestion: null,
  currentSection: null,
  currentScreen: null,
  numberOfQuestions: 0,
  index: null,
  navigateTo: (_: number) => () => null,
  start: () => Promise.resolve(),
  complete: () => null,
  setSession: (_: IQuestionSession) => Promise.resolve(),
  setAnswer: (_: IAnswer) => Promise.resolve(),
  getAnswer: (_: number | string) => null
});
