import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { useQuery } from '~/App/shared/hooks/use-query';
import { useLogin } from '~/App/shared/hooks/use-login';

import { Login } from './views/Login';
import { UpdateEmail } from './views/UpdateEmail';
import { HandleLoginOptions } from '~/App/contexts/Login';

import { Loading } from '../Loading';
import { Email } from '../Email';
import { mwAxios } from '~/App/helpers/http';

type Query = {
  verifyEmailToken: string | boolean;
};

type EmailAndToken = {
  email: string | null;
  token: string;
};

export function VerifyEmail() {
  const query = useQuery<Query>();
  const login = useLogin();
  const { isAuthenticated } = useAuthenticationContext();

  const hasUserToken = useMemo(
    () => typeof query.verifyEmailToken !== 'boolean',
    [query.verifyEmailToken]
  );

  const [token, setToken] = useState<string | undefined>();
  const [email, setEmail] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(hasUserToken);

  useEffect(() => {
    if (!hasUserToken) {
      return;
    }

    mwAxios
      .get<EmailAndToken>(`/api/v2/users/email/${query.verifyEmailToken}`)
      .then(({ data }) => {
        setLoading(false);
        setToken(data.token);
        setEmail(data.email);
      })
      .catch(() => {
        setLoading(false);
        setToken(undefined);
        setEmail(null);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserToken]);

  const handleLogin = useCallback(
    async (options: HandleLoginOptions) => {
      const response = await login.handlers.handleLogin(options);

      if (!response.success) {
        return;
      }

      if (response.user?.email) {
        setToken(undefined);
        setEmail(response.user.email);
      }
    },
    [login.handlers]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (email) {
    return <UpdateEmail value={email} token={token} />;
  }

  if (isAuthenticated) {
    return <Email />;
  }

  if (!token) {
    return <Login onLogin={handleLogin} />;
  }

  return <Email token={token} />;
}
