import React from 'react';
import styled from 'styled-components';

// Shared components
import Container from '~/App/shared/components/Container';
import { CompanyDonation } from '~/App/shared/components/Donation';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const CompanyDonationFormWrapper = styled(BlockBackground)``;

export default function CompanyDonationForm({ data, backgroundColor }) {
  return (
    <CompanyDonationFormWrapper backgroundColor={backgroundColor}>
      <Container
        width="limited"
        paddingLeft="small"
        paddingRight="small"
        paddingTop="small"
        paddingBottom="small"
      >
        <CompanyDonation data={data} />
      </Container>
    </CompanyDonationFormWrapper>
  );
}
