import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

type Props = {
  fullSize?: boolean;
  boxShadow?: boolean;
};

export const Preview = styled.div<Props>`
  height: ${({ boxShadow, fullSize }) =>
    boxShadow && fullSize
      ? 'calc(100% - 3rem)'
      : boxShadow && !fullSize
      ? 'calc(100% - 1rem)'
      : '100%'};
  width: ${({ boxShadow, fullSize }) =>
    boxShadow && fullSize
      ? 'calc(100% - 3rem)'
      : boxShadow && !fullSize
      ? 'calc(100% - 1rem)'
      : '100%'};
  background-color: ${(props) => props.theme.colors.white};
  padding: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.themeColors.primary};
  text-align: center;
  font-size: ${({ fullSize }) => (fullSize ? '70%' : '50%')};
  border: ${({ fullSize, boxShadow, theme }) =>
    !fullSize &&
    !boxShadow &&
    '1rem solid ' + theme.themeColors.accentedBackgroundColor};
  box-shadow: ${({ boxShadow }) =>
    boxShadow && '0 4px 8px 2px rgba(0, 0, 0, 0.25)'};

  ${mq<Props>('≥small')`
    font-size: ${({ fullSize }) => (fullSize ? '100%' : '50%')};
  `};
`;
