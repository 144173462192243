import { FormEvent, useCallback, useMemo, useState } from 'react';

import { stringify } from '~/lib/query-string';
import { paymentMethods } from '~/App/config/paymentMethods';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { poll, orders } from '~/App/helpers/http';
import { IValidation } from '~/App/shared/components/Validation';
import { ICommerceOrderStatus } from '~/types/ICommerceOrder';
import { CommerceCheckoutState } from './useCommerceCheckoutState';
import { eecProduct, pushEecEvent } from '../../../helpers/gtm-helper';
import { PaymentAction, PaymentStatus } from '~/types/ICommercePayment';

export type CommerceCheckoutSubmit = {
  errors: boolean;
  isSending: boolean;
  isPolling: boolean;
  isCompleted: boolean;
  redirectUrl: string | null;
  exceptionCode: string | null | undefined;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
};

type Props = {
  values: CommerceCheckoutState;
  validation: IValidation;
};

export function useCommerceCheckoutSubmit({
  values,
  validation
}: Props): CommerceCheckoutSubmit {
  const [errors, setErrors] = useState(false);
  const [isSending, setSending] = useState(false);
  const [isPolling, setPolling] = useState(false);
  const [isCompleted, setCompleted] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [exceptionCode, setExceptionCode] = useState<string | undefined>();

  const shoppingCart = useShoppingCart();

  const thankYou = useCallback(
    (id: string) => `/gavoshop/kassa/${id}/tack`,
    []
  );

  const handleError = useCallback((code?: string) => {
    setErrors(true);
    setSending(false);
    setPolling(false);
    setCompleted(false);
    setRedirectUrl(null);
    setExceptionCode(code);
  }, []);

  const logToAnalytics = useCallback(
    (order: ICommerceOrderStatus) => {
      if (
        `${order.payment?.methodId}` !== `${paymentMethods.creditCard}` &&
        `${order.payment?.methodId}` !== `${paymentMethods.klarnaPayments}`
      ) {
        return;
      }

      pushEecEvent('purchase', {
        products: shoppingCart.items?.map(eecProduct),
        actionField: {
          id: order.id,
          affiliation: 'Gåvoshop',
          revenue: order.totalPrice,
          shipping: order.shippingPrice
        }
      });
    },
    [shoppingCart.items]
  );

  const getOrder = async (orderId: string) => {
    const { data } = await orders.status(orderId);
    return data;
  };

  const handleSuccess = useCallback(
    async (orderId: string) => {
      const order: ICommerceOrderStatus = await getOrder(orderId);

      if (order.payment?.status === PaymentStatus.Paid) {
        return setRedirectUrl(thankYou(order.id));
      }

      if (order.payment?.status === PaymentStatus.Failed) {
        return handleError();
      }

      if (`${order.payment?.methodId}` === `${paymentMethods.swish}`) {
        const confirmPage = `/bekrafta-swishbetalning?${stringify({
          originPath: window.location.href,
          redirectPath: thankYou(order.id),
          order: `${order.id}`,
          type: values.swish.swishType
        })}`;

        if (order.payment?.action === PaymentAction.ReturnUrl) {
          window.location.href = order.payment.returnUrl;
        }

        return setRedirectUrl(confirmPage);
      }

      setPolling(true);

      try {
        const { data } = await poll.start({
          fn: () => orders.status(`${order.id}`),
          done: ({ data }) => data.payment?.status !== PaymentStatus.Pending
        });

        if (data.payment?.status === PaymentStatus.Paid) {
          logToAnalytics(order);
          `${order.payment?.methodId}` === `${paymentMethods.klarnaPayments}`
            ? order.payment?.action === PaymentAction.ReturnUrl &&
              setRedirectUrl(order.payment.returnUrl)
            : setRedirectUrl(thankYou(order.id));
          return;
        }

        if (data.payment?.status === PaymentStatus.Failed) {
          return handleError();
        }
      } catch (error: unknown) {
        return handleError(); // Polling timeout
      }
    },
    [handleError, thankYou, logToAnalytics, values.swish.swishType]
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      setSending(true);

      if (errors) setErrors(false);
      if (exceptionCode) setExceptionCode(undefined);

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      let paymentMethodOptions = {};
      if (values.paymentMethod?.id === paymentMethods.swish) {
        paymentMethodOptions = {
          phoneNumber:
            values.swish.swishType === 'ecommerce'
              ? values.swish.phoneNumber
              : undefined
        };
      }
      if (values.paymentMethod?.id === paymentMethods.klarnaPayments) {
        const response = await values.handlers.klarnaPaymentsAuthorize();

        paymentMethodOptions = {
          token: response.authorization_token
        };
      }

      const body = {
        totalPrice: shoppingCart.totalPrice,
        shippingPrice: shoppingCart.shippingPrice,
        orderLines: shoppingCart.items.map((x) => ({
          id: x.id,
          price: x.price,
          quantity: x.quantity
        })),
        customer: {
          cid: values.productOptions.customerContact.cid,
          ssn: values.productOptions.customerContact.ssn,
          email: values.productOptions.customerContact.email,
          firstName: values.productOptions.customerContact.firstName,
          lastName: values.productOptions.customerContact.lastName,
          phoneNumber: values.productOptions.customerContact.phoneNumber
        },
        deliveryAddress: {
          companyName: values.productOptions.customerContact.company,
          email: values.productOptions.customerContact.email,
          city: values.productOptions.customerContact.addressCity,
          street: values.productOptions.customerContact.addressStreet,
          zip: values.productOptions.customerContact.addressZip,
          phoneNumber: values.productOptions.customerContact.phoneNumber
        },
        paymentMethod: {
          id: values.paymentMethod?.id,
          options: paymentMethodOptions
        }
      };
      try {
        setSending(true);

        const { data, status } = await orders.checkout(body);

        if (status < 200 || status > 299) {
          return handleError();
        }

        return handleSuccess(`${data.id}`);
      } catch (error) {
        console.log(error);

        return handleError();
      }
    },
    [
      errors,
      exceptionCode,
      handleError,
      handleSuccess,
      shoppingCart.items,
      shoppingCart.shippingPrice,
      shoppingCart.totalPrice,
      validation,
      values.handlers,
      values.paymentMethod?.id,
      values.productOptions.customerContact.addressCity,
      values.productOptions.customerContact.addressStreet,
      values.productOptions.customerContact.addressZip,
      values.productOptions.customerContact.cid,
      values.productOptions.customerContact.company,
      values.productOptions.customerContact.email,
      values.productOptions.customerContact.firstName,
      values.productOptions.customerContact.lastName,
      values.productOptions.customerContact.phoneNumber,
      values.productOptions.customerContact.ssn,
      values.swish.phoneNumber,
      values.swish.swishType
    ]
  );

  return useMemo(
    () => ({
      errors,
      isSending,
      isPolling,
      isCompleted,
      exceptionCode,
      redirectUrl,
      handleSubmit
    }),
    [
      errors,
      exceptionCode,
      handleSubmit,
      isCompleted,
      isPolling,
      isSending,
      redirectUrl
    ]
  );
}
