import React from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { formatMoney } from 'accounting';

// Helpers
import { heading5, heading3 } from '~/App/helpers/mixins';

// Shared components
import { PackageFeatureList } from './components/Packages';
import { Divider } from '~/App/shared/components/Blocks';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import { StepWrapper } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1.125rem;
  padding: 0.5rem 0 1rem;
`;

const Heading4 = styled.h4`
  ${heading3};
`;

const Heading5 = styled.h5`
  ${heading5};
  margin-bottom: 0.75rem;
`;

export const CompanyPackageSummary = function (props) {
  const { part = 1, area } = props;

  let price = props.price;
  let packages = props.packages;
  let activePackageId = props.activePackageId;

  let showChosenPackage = true;
  let hideCompletely = false;

  if (area === 'form' && part === 1) {
    showChosenPackage = false;
  } else if (area === 'form' && (part === 2 || part === 3)) {
    hideCompletely = true;
  }

  const activePackage = find(packages, { productId: activePackageId }) || {};

  if (!hideCompletely) {
    return (
      <React.Fragment>
        {showChosenPackage && (
          <React.Fragment>
            <FormHeading>Ditt företagspaket</FormHeading>
            <AmountWrapper>
              <span>{activePackage.summaryName}</span>
              <span>
                <Heading4>
                  {formatMoney(price, 'kr', 0, ' ', null, '%v %s')}
                </Heading4>
              </span>
            </AmountWrapper>
          </React.Fragment>
        )}
        <Divider
          width="narrow"
          paddingLeft="none"
          paddingRight="none"
          height="2px"
          alternativeColor={true}
        />
        {packages && (
          <StepWrapper paddingTop="small">
            <Heading5>{`Detta ingår i ${activePackage.summaryName}`}</Heading5>
            <PackageFeatureList
              activePackageId={activePackageId}
              packages={packages}
            />
          </StepWrapper>
        )}
      </React.Fragment>
    );
  } else {
    return null;
  }
};
