import React, { useMemo } from 'react';
import { Error } from '../Error/Error';

import useLogin from '~/App/shared/hooks/use-login';

type Props = {
  errors: string[];
};

export function Failed({ errors }: Props) {
  const login = useLogin();

  const alertContent = useMemo(() => {
    const errorMessage = errors[0];

    if (errorMessage[0].includes('https://install.bankid.com')) {
      const textWithOutAnchorTag = errorMessage[0].replace(
        'https://install.bankid.com.',
        ''
      );

      return (
        <>
          {textWithOutAnchorTag}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://install.bankid.com"
            children={` https://install.bankid.com`}
          />
        </>
      );
    }

    return errorMessage;
  }, [errors]);

  return (
    <Error children={alertContent} tryAgain={login.handlers.handleLoginAbort} />
  );
}
