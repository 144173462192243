import React from 'react';
import styled from 'styled-components';
import { components, IndicatorsContainerProps } from 'react-select';

import { close, search } from '~/App/helpers/icons';
import { CategoryOption, GroupedOption } from '../CategoryFilter';

type IconProps = {
  open: boolean;
};

const Icon = styled.div<IconProps>`
  margin-right: 0.5rem;
  font-size: 1.25rem;

  :before {
    ${({ open }) => (open ? close : search)};
    font-weight: ${({ open }) => (open ? 700 : 400)};
    color: ${({ theme }) => theme.colors.darkBlue};
    top: 1px;
  }
`;

const CloseText = styled.span`
  font-size: 12px;
  padding-right: 4px;
  color: ${({ theme }) => theme.colors.darkBlue};
`;

type Props = IndicatorsContainerProps<
  CategoryOption,
  true | false,
  GroupedOption
>;

export function IndicatorsContainer(props: Props) {
  return (
    <components.IndicatorsContainer {...props}>
      {props.selectProps.menuIsOpen && <CloseText>Stäng</CloseText>}
      <Icon open={props.selectProps.menuIsOpen} />
    </components.IndicatorsContainer>
  );
}
