import React, { useMemo, useCallback, Fragment, useContext } from 'react';
import styled, { css } from 'styled-components';

//Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { camera, check } from '~/App/helpers/icons';

import { Message } from '../Components';

import { ImageSelectorContext } from './Context';

const ITEM_SIZE = 6;
const ITEM_PADDING = 0.75;

const Wrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

const ImageUploadWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.colors.lightDust};
  color: ${({ theme }) => theme.themeColors.primary};
  width: ${ITEM_SIZE}rem;
  height: ${ITEM_SIZE}rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;

  &::before {
    ${camera};
    font-size: 1.8125rem;
    margin-bottom: 0.5rem;
    display: initial;
  }
`;

const ImageUpload = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

type ImageWrapperProps = {
  isSelected: boolean;
  isRounded: boolean;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: ${ITEM_SIZE}rem;
  height: ${ITEM_SIZE}rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.colors.lightDust};
  border-color: ${({ isSelected, isRounded, theme }) => {
    if (isSelected) return theme.themeColors.primary;
    if (isRounded) return theme.colors.lightDust;

    return theme.colors.transparent;
  }};

  &:hover {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      &::before {
        ${check};
        background: ${theme.themeColors.primary};
        color: ${theme.colors.white};
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
      }
    `};
`;

type ImageProps = {
  isSelected: boolean;
  isRounded: boolean;
  src: string;
};

const Image = styled.div<ImageProps>`
  border-radius: ${({ isRounded }) => (isRounded ? '100%' : '0px')};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${({ src }) => `url('${src}')`};
  width: ${({ isRounded }) =>
    isRounded
      ? `calc(${ITEM_SIZE}rem - ${ITEM_PADDING * 2}rem)`
      : `calc(${ITEM_SIZE}rem - 2px)`};
  height: ${({ isRounded }) =>
    isRounded
      ? `calc(${ITEM_SIZE}rem - ${ITEM_PADDING * 2}rem)`
      : `calc(${ITEM_SIZE}rem - 2px)`};
`;

type Props = {
  defaultImages: string[];
  value?: string;
  onChange: (value: string) => void;
  errors: string[];
  isRounded?: boolean;
  isInvalid: boolean;
};

export function ImageSelector({
  defaultImages,
  value,
  onChange,
  errors = [],
  isRounded = false,
  isInvalid
}: Props) {
  const { image, setImage } = useContext(ImageSelectorContext);

  const normalizeUrl = useCallback(
    (image: File | string) =>
      image instanceof File ? URL.createObjectURL(image) : image,
    []
  );
  const handleClick = useCallback((image: string) => () => onChange(image), [
    onChange
  ]);

  const images = useMemo(
    () => (image ? [image, ...defaultImages] : defaultImages),
    [image, defaultImages]
  );

  const handleUpload = useCallback(
    (event) => {
      const image = event.target.files[0];
      const url = normalizeUrl(image);

      if (image.size > 20000000) {
        return; // Max size - 20MB
      }

      setImage(url);
      onChange(url);
    },
    [setImage, onChange, normalizeUrl]
  );

  const renderImage = useCallback(
    (image) => (
      <ImageWrapper
        key={image}
        isSelected={image === value}
        isRounded={isRounded}
      >
        <Image
          isRounded={isRounded}
          isSelected={image === value}
          src={mediaHelper.getUrl(image, {
            crop: 'fill',
            gravity: 'face'
          })}
          onClick={handleClick(image)}
        />
      </ImageWrapper>
    ),
    [value, isRounded, handleClick]
  );

  return useMemo(
    () => (
      <Fragment>
        <Wrapper>
          <ImageUploadWrapper>
            <Fragment>
              Välj bild
              <ImageUpload
                type="file"
                accept="image/*"
                onChange={handleUpload}
              />
            </Fragment>
          </ImageUploadWrapper>
          {images.map(renderImage)}
        </Wrapper>
        {errors ? <Message isInvalid={isInvalid} children={errors[0]} /> : null}
      </Fragment>
    ),
    [errors, handleUpload, images, isInvalid, renderImage]
  );
}
