import React, { ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';

const Wrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  color: ${({ theme }) => theme.colors.charcoal};
`;

const Nav = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
`;

type NavItemProps = {
  isSelected: boolean;
};

const NavItem = styled.div<NavItemProps>`
  padding: 0.5rem 0rem;
  flex: 1;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkBlue};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;

  ${mq('>small')`
    font-size: 1.125rem;
  `};
`;

type IndicatorProps = {
  index: number;
  count: number;
};

const Indicator = styled.div<IndicatorProps>`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  height: 0.25rem;
  position: absolute;
  transition: left 0.3s;
  bottom: 0px;
  width: ${({ count }) => 100 / count}%;
  left: ${({ index, count }) => `${index * (100 / count)}%`};
  border-radius: 1rem;
`;

type Tab<T> = {
  value: T;
  label: string;
  content: ReactNode;
};
type Props<T> = {
  value: T;
  tabs: Tab<T>[];
  onChange: (value: T) => void;
};

export function Tabs<T>({ value, tabs, onChange }: Props<T>) {
  const renderTab = useCallback(
    (tab: Tab<T>) => (
      <NavItem
        key={`${tab.value || tab.label}`}
        children={tab.label}
        isSelected={tab.value === value}
        onClick={() => onChange(tab.value)}
      />
    ),
    [onChange, value]
  );

  const content = useMemo<ReactNode>(
    () => tabs.find((x) => x.value === value)?.content || null,
    [tabs, value]
  );

  const index = useMemo(() => tabs.map((x) => x.value).indexOf(value), [
    tabs,
    value
  ]);

  return (
    <Wrapper>
      <Nav>
        {tabs.map(renderTab)}
        <Indicator count={tabs.length} index={index} />
      </Nav>
      <Content children={content} />
    </Wrapper>
  );
}
