import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { startsWith } from 'lodash';
import { ThemeProvider } from 'styled-components';

// Helpers
import { siteUrl, contentApi } from '~/App/helpers/env';
import { global } from '~/App/config/themes';
import { fetch } from '~/App/helpers/http';

// Components
import { GlobalMessages } from './components/GlobalMessages';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { ShoppingCart } from './components/ShoppingCart';
import { PageLoader } from './components/PageLoader';
import { GlobalStyle } from './components/Print';

import { Stripe } from './shared/components/Donation/components/Stripe';
import { HelloBar } from '~/App/components/HelloBar';
import { Overlay } from './components/Overlay';
import { Takeover } from './views/Takeover';
import styled from 'styled-components';

const isVisible = (currentPath, hiddenOn = []) => {
  return !hiddenOn.some((path) => startsWith(currentPath, path));
};

const pathsWithoutHeaderFooterAndCookie = ['/minska-risken/kolla-risken'];
const pathsWithoutHeader = ['/gavoshop/kassa'];

const SkipLink = styled.a`
  position: absolute;
  margin-right: 1rem;
  transform: translateX(-200%);
  transition: transform 0.3s;

  &:focus {
    position: static;
    transform: translateX(0);
    width: 100%;
  }
`;

export default function App({ children }) {
  const location = useLocation();
  const mainContent = useRef();

  const [globalMessages, setGlobalMessages] = useState([]);

  const showHeaderFooterAndContent = useMemo(
    () => isVisible(location.pathname, pathsWithoutHeaderFooterAndCookie),
    [location.pathname]
  );

  const showHeaderAndContent = useMemo(
    () => isVisible(location.pathname, pathsWithoutHeader),
    [location.pathname]
  );

  const fetchGlobalMessages = useCallback(async () => {
    const url = contentApi('/v1/globalmessages');
    const response = await fetch({ url });

    setGlobalMessages(response.data?.messages || []);
  }, []);

  useEffect(() => {
    fetchGlobalMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkipToMainContent = () => {
    mainContent.current.focus();
  };

  return useMemo(
    () => (
      <>
        <Helmet
          defaultTitle="Cancerfondens vision är att besegra cancer"
          titleTemplate="%s | Cancerfonden"
        >
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="sv_SE" />
          <meta property="og:site_name" content="Cancerfonden" />
          <meta property="og:url" content={siteUrl(location.pathname)} />
        </Helmet>
        <Stripe>
          <ThemeProvider theme={global}>
            <GlobalStyle />
            <ShoppingCart />
            <Overlay />
            <Takeover>
              <div>
                {showHeaderFooterAndContent && showHeaderAndContent && (
                  <Header>
                    <SkipLink href="#main" onClick={handleSkipToMainContent}>
                      Direkt till innehåll
                    </SkipLink>
                  </Header>
                )}
                {showHeaderFooterAndContent && (
                  <GlobalMessages globalMessages={globalMessages} />
                )}
                {showHeaderFooterAndContent && <HelloBar />}
                <div ref={mainContent} tabIndex="0">
                  {children}
                </div>
                {showHeaderFooterAndContent && <Footer />}
              </div>
              {showHeaderFooterAndContent && showHeaderAndContent && (
                <PageLoader />
              )}
            </Takeover>
          </ThemeProvider>
        </Stripe>
      </>
    ),
    [
      children,
      globalMessages,
      location.pathname,
      showHeaderAndContent,
      showHeaderFooterAndContent
    ]
  );
}
