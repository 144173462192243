import { fetch } from '~/App/helpers/http';
import { collections } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';
import { LoadDataProps } from '~/types/routes';

const getContentDefinition = () =>
  fetch({
    url: contentApi('/v1/content-definitions/new-collection-donation-form')
  });

const getCollection = (slug: string) => collections.show({ slug });

export const loadData = async ({ params }: LoadDataProps<string>) => {
  const { slug = '' } = params;

  const response = await Promise.all([
    getContentDefinition(),
    getCollection(slug)
  ]);

  const [content, collection] = response;

  if (collection.data.collectionType === 'memorial_page') {
    throw {
      response: {
        status: 404
      }
    };
  }

  return {
    data: {
      formContent: content.data.contentDefinition.data,
      collection: collection.data
    }
  };
};
