const getUserAgent = () => {
  if (typeof navigator !== 'undefined') {
    return navigator.userAgent || navigator.vendor || window.opera || '';
  }

  return '';
};

export const isMobileBrowser = (userAgent) =>
  isAndroid(userAgent) || isIos(userAgent) || isWindowsPhone(userAgent);

export const isIos = (userAgent) =>
  /iPad/i.test(userAgent ?? getUserAgent()) ||
  /CriOS/i.test(userAgent ?? getUserAgent()) ||
  /iPhone/i.test(userAgent ?? getUserAgent());

export const isAndroid = (userAgent) =>
  /Android/i.test(userAgent ?? getUserAgent());

export const isWindowsPhone = (userAgent) =>
  /Windows Phone/i.test(userAgent ?? getUserAgent());

export const isInstagramBrowser = (userAgent) =>
  /Instagram/i.test(userAgent ?? getUserAgent());
