import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { heading2 } from '~/App/helpers/mixins';

import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { PrimaryButton } from '~/App/shared/components/Elements';

import { ViewWrapper } from '../../ViewWrapper';

const FlexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  max-width: 380px;
  margin: auto;
`;

const Heading = styled.h1`
  ${heading2};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
`;

const Image = styled.img`
  display: block;
  width: 143px;
  margin: 0 auto 1.5rem;
`;

const Button = styled(PrimaryButton)`
  margin-top: 1.5rem;
  width: 50%;
`;

export function EmailConfirmationCompleted() {
  const navigate = useNavigate();
  const authentication = useAuthenticationContext();

  const setLoginViewPrivate = useCallback(() => navigate('/min-sida'), [
    navigate
  ]);

  return (
    <ViewWrapper alternativeBackground>
      <FlexWrapper>
        <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png" />
        <Heading children="Tack för din bekräftelse!" />
        {authentication.state === 'anonymous' && (
          <Button onClick={setLoginViewPrivate} children="Logga in" />
        )}
        {authentication.state === 'authenticated' && (
          <Button
            buttonType="link"
            to="/min-sida/"
            onClick={setLoginViewPrivate}
            children="Gå till Min sida"
          />
        )}
      </FlexWrapper>
    </ViewWrapper>
  );
}
