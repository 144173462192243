import { findIndex, compact } from 'lodash';

export const calculateNewPosition = (
  products = [],
  unselectedProducts = [],
  gridSize = {},
  clickedId = '',
  selectedId = ''
) => {
  if (!productsAndGridSizeIsOk(products, gridSize)) {
    return null;
  }

  let indexOfClicked = findIndex(products, { name: clickedId });
  const clickedPosition = getClickedPosition(
    products,
    gridSize,
    clickedId,
    selectedId
  );

  if (!clickedPosition) {
    return null;
  }

  let newProducts;
  let newUnselectedProducts;

  if (selectedId !== '') {
    newProducts = unselectedProducts.slice();
    const indexOfClickedInUnselected = findIndex(unselectedProducts, {
      name: clickedId
    });
    const clickedObject = unselectedProducts[indexOfClickedInUnselected];
    const targetIndex = positionToIndex(clickedPosition, gridSize);
    newProducts[indexOfClickedInUnselected] = newProducts[targetIndex];
    newProducts[targetIndex] = clickedObject;

    newUnselectedProducts = newProducts;
    indexOfClicked = targetIndex;
  } else {
    newProducts = products.slice();
    newUnselectedProducts = unselectedProducts;
  }

  // Move based on grid edges
  if (clickedPosition.column === gridSize.columns) {
    const clickedObject = newProducts[indexOfClicked];
    newProducts[indexOfClicked] = newProducts[indexOfClicked - 1];
    newProducts[indexOfClicked - 1] = clickedObject;
    indexOfClicked = indexOfClicked - 1;
  }

  if (clickedPosition.row === gridSize.rows - 1) {
    const clickedObject = newProducts[indexOfClicked];
    newProducts[indexOfClicked] =
      newProducts[indexOfClicked - gridSize.columns];
    newProducts[indexOfClicked - gridSize.columns] = clickedObject;
  } else if (clickedPosition.row === gridSize.rows) {
    const clickedObject = newProducts[indexOfClicked];
    newProducts[indexOfClicked] =
      newProducts[indexOfClicked - gridSize.columns * 2];
    newProducts[indexOfClicked - gridSize.columns * 2] = clickedObject;
  }

  return {
    products: compact(newProducts),
    unselectedProducts: compact(newUnselectedProducts)
  };
};

export const getClickedPosition = (
  productsWhenExpanded = [],
  gridSize = {},
  clickedId = '',
  selectedId = ''
) => {
  if (!productsAndGridSizeIsOk(productsWhenExpanded, gridSize)) {
    return null;
  }

  const indexOfClicked = findIndex(productsWhenExpanded, { name: clickedId });
  const indexOfSelected = findIndex(productsWhenExpanded, { name: selectedId });

  if (indexOfClicked === -1) {
    return null;
  }

  if (indexOfSelected === -1 || indexOfClicked < indexOfSelected) {
    const position = indexToPosition(indexOfClicked, gridSize);

    return position;
  } else if (
    indexOfClicked > indexOfSelected &&
    indexOfClicked <= indexOfSelected + (gridSize.columns - 2)
  ) {
    const appearedPosition = indexToPosition(indexOfClicked, gridSize);
    const actualPosition = addIndexToPosition(appearedPosition, gridSize, 1);

    return actualPosition;
  } else if (
    indexOfClicked > indexOfSelected + (gridSize.columns - 2) &&
    indexOfClicked <= indexOfSelected + 2 * (gridSize.columns - 2)
  ) {
    const appearedPosition = indexToPosition(indexOfClicked, gridSize);
    const actualPosition = addIndexToPosition(appearedPosition, gridSize, 3);

    return actualPosition;
  } else if (indexOfClicked > indexOfSelected + 2 * (gridSize.columns - 2)) {
    const appearedPosition = indexToPosition(indexOfClicked, gridSize);
    const actualPosition = addIndexToPosition(appearedPosition, gridSize, 5);

    return actualPosition;
  }

  return 1;
};

const productsAndGridSizeIsOk = (products = [], gridSize = {}) => {
  if (!products || products.length === 0) {
    return false;
  }

  if (!gridSize || Object.keys(gridSize).length === 0) {
    return false;
  }

  return true;
};

const indexToPosition = (index = 1, gridSize = {}) => {
  const row = Math.floor(index / gridSize.columns) + 1;
  const column = (index % gridSize.columns) + 1;

  return {
    row,
    column
  };
};

const addIndexToPosition = (position = {}, gridSize = {}, indexToAdd = 0) => {
  const indexOfPosition = positionToIndex(position, gridSize);
  const newIndex = indexOfPosition + indexToAdd;
  const newPosition = indexToPosition(newIndex, gridSize);

  return newPosition;
};

const positionToIndex = (position = {}, gridSize = {}) => {
  return (position.row - 1) * gridSize.columns + position.column - 1;
};
