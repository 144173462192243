import React from 'react';
import styled, { keyframes } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { card } from '~/App/helpers/mixins';

const heartbeat = keyframes`
  to {
    transform: scale(1.15);
  }
`;

const FormInfoBoxWrapper = styled.div`
  ${card}
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;

  ${mq('≥medium')`
    flex-basis: auto;
    align-items: center;
    margin-bottom: 2rem;
  `};

  ${mq('≥small')`
    padding: 2rem;
  `};
`;

const HeartIcon = styled.div`
  flex-basis: 4rem;
  flex-shrink: 0;
  display: block;
  position: relative;
  width: 4rem;
  height: 3.333333332rem;
  margin: 0 1rem 0 0;
  color: ${(props) => props.theme.themeColors.accentPink};

  ${mq('≥small')`
    flex-basis: 6rem;
    width: 6rem;
    height: 5rem;
    margin: 0 2rem 0 0;
  `};

  svg {
    stroke: currentColor;
    animation: ${heartbeat} 0.75s 2s 10 alternate;
  }
`;

const FormInfoMobileText = styled.p`
  ${mq('≥medium')`
    display: none;
  `};
`;

const FormInfoDesktopText = styled.p`
  ${mq('<medium')`
    display: none;
  `};
`;

export default React.memo(function FormInfoBox(props) {
  return (
    <FormInfoBoxWrapper>
      <HeartIcon>
        <svg
          viewBox="0 0 92 77"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(2.000000, 2.000000)">
              <path
                d="M81.98475,5.88225 L81.98475,5.88225 C74.05075,-1.96075 61.18975,-1.96075 53.25675,5.88225 L43.96775,15.06625 L34.67775,5.88225 C26.74475,-1.96075 13.88375,-1.96075 5.94975,5.88225 C-1.98325,13.72525 -1.98325,26.44125 5.94975,34.28525 L43.94075,71.84625 L43.96775,71.82025 L43.99375,71.84625 L81.98475,34.28525 C89.91775,26.44125 89.91775,13.72525 81.98475,5.88225 Z"
                strokeWidth="4"
              />
              <path
                d="M76.81275,28.22625 C81.61475,23.47825 81.61475,15.78125 76.81275,11.03425 C74.41175,8.66025 71.26475,7.47325 68.11875,7.47325"
                strokeWidth="2"
              />
            </g>
          </g>
        </svg>
      </HeartIcon>
      <FormInfoMobileText>{props.children.mobilePreamble}</FormInfoMobileText>
      <FormInfoDesktopText>
        {props.children.desktopPreamble}
      </FormInfoDesktopText>
    </FormInfoBoxWrapper>
  );
});
