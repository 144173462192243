import React from 'react';
import styled from 'styled-components';

import { paymentMethods } from '~/App/config/paymentMethods.js';
import { PaymentValues } from '../../States';
import { IntegrityText } from './IntegrityText';

const UpperIntegrityText = styled(IntegrityText)`
  margin-bottom: 0rem;
`;

const LowerIntegrityText = styled(IntegrityText)`
  margin-top: 0.75rem;
  margin-bottom: 0rem;
`;

type Props = {
  values: PaymentValues;
};

export function IntegrityTextArea({ values }: Props) {
  const isCreditCard = values?.paymentMethod?.id === paymentMethods.creditCard;
  const isAutoGiro =
    values?.paymentMethod?.id === paymentMethods.autoGiroBankId ||
    values?.paymentMethod?.id === paymentMethods.autoGiroSlip;

  return (
    <>
      <UpperIntegrityText
        variant={isCreditCard ? 'monthlyDonationCard' : 'standard'}
      />
      {isAutoGiro && <LowerIntegrityText variant="billectaPaymentTerms" />}
    </>
  );
}
