import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { Collections } from './Collections'
import { loadData } from './Collections.LoadData';

const View = (props: any) => {
  const pageContent = usePageContent();
  const children = useMemo(
    () => pageContent ? <Collections {...pageContent} {...props} /> : null,
    [pageContent, props]);

  return children;
};

View.loadData = loadData;

export default View;