import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('sv');
dayjs.extend(utc);
dayjs.extend(relativeTime);

export const parseDate = (date: string, format: string) => {
  return dayjs(date, format);
};

export const formatDate = (date: string, format: string) => {
  if (date) {
    return dayjs(date).format(format);
  }
  return dayjs().format(format);
};

export const formatUTCDate = (date: string, format: string) => {
  return dayjs.utc(date).format(format);
};

export const isLegalAge = (birthDate: string) => {
  return dayjs().subtract(18, 'year').isAfter(dayjs(birthDate));
};

export const isValidDate = (date: string, format: string) => {
  return dayjs(date, format).isValid();
};

export const timeFromNow = (date: string) => {
  return dayjs(date).fromNow();
};

export const getHoursSinceDate = (date: string) => {
  return dayjs().diff(dayjs(date), 'hour');
};

export const today = () => dayjs();