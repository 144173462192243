import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { MemorialPage } from './MemorialPage';
import { loadData } from './MemorialPage.loadData';

const View = (props: unknown) => {
  const pageContent = usePageContent();
  const children = useMemo(
    () =>
      pageContent ? (
        <MemorialPage
          key={pageContent.collection.slug}
          {...pageContent}
          {...props}
        />
      ) : null,
    [pageContent, props]
  );

  return children;
};

View.loadData = loadData;

export default View;
