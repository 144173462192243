import _ from 'lodash';
import { DeepPartial } from 'ts-essentials';

import { gtmCustomDetails } from '~/App/config/gtmDetails';
import { collectionTypes } from '~/App/config/collectionTypes';

import { ICollection } from '~/types/ICollection';
import { MultiPartFormValues } from '../../MultiPartForm';
import { FormContent } from './CollectionDonation';

import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues,
  GenericValues,
  AnalyticsValues
} from '../components/States';
import { paymentMethods } from '~/App/config/paymentMethods';

type BuildStateProps = {
  formContent: FormContent;
  collection: ICollection;
};

type State = DeepPartial<
  AnalyticsValues &
    MultiPartFormValues &
    ContactCustomerValues &
    GenericValues &
    ProductValues &
    PaymentValues
> & {
  productOptions: {
    collectionId: number;
  };
};

export const buildState = ({
  formContent,
  collection
}: BuildStateProps): State => {
  const PRODUCT_ID = 3;
  const GTM_CUSTOM_ID = '3a';

  const startOnCustomValue =
    collection.collectionType === collectionTypes.lopbandet &&
    collection.totalDistance !== 0;

  return {
    gtm: gtmCustomDetails[GTM_CUSTOM_ID],
    product: { id: PRODUCT_ID },
    productOptions: {
      product: {
        period: 'EveryThirdMonth',
        price: 150,
        customPrice: startOnCustomValue
      },
      pul: true,
      collectionId: collection.id,
      campaignActivityId: 2289
    },
    paymentMethod: {
       id: paymentMethods.klarnaPayments,
       name: 'Konto eller kort'
     },
    customerContactType: formContent.defaultCustomerContactType,
    partOptions: {
      parts: 2,
      validationKeys: {
        1: [
          'productOptions.product.price'
        ],
        2: [
          'swishPhoneNumber',
          'productOptions.customerContact.email',
          'productOptions.customerContact.ssn',
          'productOptions.customerContact.firstName',
          'productOptions.customerContact.lastName',
          'productOptions.customerContact.addressStreet',
          'productOptions.customerContact.addressZip',
          'productOptions.customerContact.addressCity',
          'mobileBankId.bank',
          'mobileBankId.accountNumber',
          'mobileBankId.clearingNumber'
        ]
      }
    }
  };
};
