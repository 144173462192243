import _ from 'lodash';
import React from 'react';

// Shared components
import Validation from '~/App/shared/components/Validation';

// Local components
import { Submit } from '../components/Submits';
import BankId from './../components/BankId';
import PaymentRedirect from './../components/PaymentRedirect';

import { MonthlyDonationParts } from './MonthlyDonation.Parts';
import { MonthlyDonationInline } from './MonthlyDonation.Inline';
import { MonthlyDonationNudge } from '../components/TaxReductionNudging/MonthlyDonationNudge';
import { State, MultiPartState } from './../components/States';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';

import { buildState } from './state';
import { buildSchema } from './schema';

import useQuery from '~/App/shared/hooks/use-query';

function MonthlyDonationHoc({ children, formContent, state, schema }) {
  return (
    <State formContent={formContent} values={state}>
      {(values) => (
        <Validation schema={schema} values={values} hasStripe={true}>
          {(validation) => (
            <MultiPartState values={values} validation={validation}>
              {(multiPartValues) => (
                <Submit
                  values={multiPartValues}
                  validation={validation}
                  shouldNudge={featureIsEnabled(
                    'NUDGE_MONTHLYDONATION_ENABLED'
                  )}
                >
                  {(submit) => (
                    <MonthlyDonationNudge
                      submit={submit}
                      values={multiPartValues}
                      validation={validation}
                      name="Månadsgivare"
                      shouldNudge={featureIsEnabled(
                        'NUDGE_MONTHLYDONATION_ENABLED'
                      )}
                    >
                      {(taxReductionNudgingSubmit) =>
                        children({
                          submit: taxReductionNudgingSubmit,
                          values: multiPartValues,
                          validation
                        })
                      }
                    </MonthlyDonationNudge>
                  )}
                </Submit>
              )}
            </MultiPartState>
          )}
        </Validation>
      )}
    </State>
  );
}

export default function MonthlyDonation({
  inline = false,
  formContent,
  defaultState,
  schema: defaultSchema,
  component
}) {
  const query = useQuery();
  const schema = _.merge({}, buildSchema(), defaultSchema);
  const state = _.merge(
    {},
    buildState(formContent, query, component),
    defaultState
  );
  const Form = inline ? MonthlyDonationInline : MonthlyDonationParts;

  return (
    <MonthlyDonationHoc schema={schema} state={state} formContent={formContent}>
      {({ submit, values, validation }) => (
        <div>
          <PaymentRedirect submit={submit} redirectPath={values.redirectPath} />
          {submit.isBankId ? (
            <BankId
              failed={submit.errors}
              loading={submit.isSending || submit.isPolling}
              timedout={false}
              inline={inline}
              originPath={values.originPath}
              resetBankId={submit.resetBankId}
              qrCode={submit.qrCode}
              autoStartToken={submit.autoStartToken}
            />
          ) : (
            <Form
              values={values}
              submit={submit}
              validation={validation}
              formContent={formContent}
              fromStart={query.fromStart === true}
            />
          )}
        </div>
      )}
    </MonthlyDonationHoc>
  );
}
