export const experiments = {
  // testExperiment: 'ThSbTYTRT42A9BhlMJyUng'
  alternativeCopyDonationForm: 'CXQttyCJQ76zQ-XD3xBxxg',
  alternativeMemorialGiftPrice: 'nS4NezUMQpSWW86DsFuxQA'
};

export const experimentIds = Object.values(experiments);

export const isActiveExperiment = (experimentId: string) => {
  return experimentIds.some((id) => id === experimentId);
};
