import React from 'react';
import Text from '~/App/shared/components/Fields/Text';

import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';

import { InputWrapper } from '../components/InputWrapper';

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
};

export function CompanyIdentity({ values, validation }: Props) {
  return (
    <>
      <InputWrapper percentageWidth="50">
        <Text
          name="organization"
          value={values.productOptions.customerContact.company}
          onBlur={validation.showError.bind(
            undefined,
            'productOptions.customerContact.company'
          )}
          errors={validation.errors['productOptions.customerContact.company']}
          isValid={validation.isValid('productOptions.customerContact.company')}
          onChange={values.handlers.handleCustomerContactCompany}
          isInvalid={validation.isInvalid(
            'productOptions.customerContact.company'
          )}
          maxLength="100"
          placeholder="Företagsnamn"
          autoComplete="organization"
          required
        />
      </InputWrapper>
      <InputWrapper percentageWidth="50">
        <Text
          name="cid"
          type="tel"
          value={values.productOptions.customerContact.cid}
          onBlur={validation.showError.bind(
            undefined,
            'productOptions.customerContact.cid'
          )}
          errors={validation.errors['productOptions.customerContact.cid']}
          isValid={validation.isValid('productOptions.customerContact.cid')}
          onChange={values.handlers.handleCustomerContactCid}
          isInvalid={validation.isInvalid('productOptions.customerContact.cid')}
          maxLength="10"
          placeholder="Org.nummer"
          required
        />
      </InputWrapper>
    </>
  );
}
