/*
  Failure message object mapping to Google Analytics structure
  category -> action -> label -> value. (Value is int)

  Example:
    category: Error
    action: BankID
    label: Fetch Bank Account Billecta error code: {errorCode}
*/

export const gtmCustomEventCategories = {
  errors: {
    _name: 'Error',
    bankId: {
      _name: 'BankID',
      fetchBankAccountBillecta: {
        _name: 'Fetch Bank Account Billecta error: '
      },
      fetchBankAccountMWTimeout: {
        _name: 'Fetch Bank Account MW to Billecta Timeout Error'
      },
      fetchBankAccountGeneralPollingError: {
        _name: 'Fetch Bank Account General Polling Error'
      }
    }
  },
  rosabandet2020: {
    _name: 'Rosa bandet 2020 Profilbild',
    changeImage: {
      _name: 'Byt bild'
    },
    downloadImage: {
      _name: 'Ladda ner bild'
    },
    login: {
      _name: 'Logga in'
    }
  },
  largeShareButtons: {
    _name: 'Stora delnings knappar',
    sms: {
      _name: 'Dela med sms'
    }
  },
  downloadFigurmarke: {
    _category: 'Min sida',
    _action: 'Ladda ner',
    _label: 'Figurmärke'
  },
  feedback: {
    _category: 'Feedback',
    thumbsUp: {
      _action: 'Click Ja',
      _label: 'Ja'
    },
    thumbsDown: {
      _action: 'Click Nej',
      _label: 'Nej'
    }
  },
  swishBlock: {
    _category: 'Swishblock',
    _action: 'Purchase Engångsgåva',
    _label: 'Ge en gåva | Swishblock'
  }
};
