import React, { useCallback, useMemo, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { stringify } from '~/lib/query-string';
import { ICollection } from '~/types/ICollection';

// Config
import { buttonStyles } from '~/App/config/buttonStyles';

// Helpers
import { siteUrl } from '~/App/helpers/env';

// Shared components
import { Container } from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { PrimaryButton } from '~/App/shared/components/Elements';

import { FilterSort } from './components/FilterSort';
import { useCollections } from './hooks/use-collections';
import { Hero } from './components/Hero';
import { Ticker } from './components/Ticker';
import { CollectionCard } from '~/App/shared/components/CollectionCard';
import { useNavigate } from 'react-router-dom';

const ItemContainer = styled.div`
  margin: 0 -1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const ShowMoreButton = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
`;

const Card = styled(CollectionCard)`
  margin: 1rem;
`;

type Props = {
  data: {
    content?: {
      contentDefinitions?: {
        meta?: {
          pageTitle?: string;
          pageDescription?: string;
          ogTitle?: string;
          ogImage?: string;
          ogDescription?: string;
        };
      };
    };
    collections?: ICollection[];
  };
};

export function Collections({
  data: { content: { contentDefinitions: { meta = {} } = {} } = {}, ...data }
}: Props) {
  const {
    isLoading,
    isVisible,
    sorting,
    period,
    collections,
    loadMore
  } = useCollections({
    initalCollections: data.collections || []
  });

  const navigate = useNavigate();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();

      const [sorting, period] = event.target.value.split('-');

      navigate(
        `/insamlingar?${stringify({
          period,
          sorting
        })}`
      );
    },
    [navigate]
  );

  const loadMoreButton = useMemo(() => {
    if (!isVisible) return;
    if (collections.length < 12) return;

    return (
      <Container
        width="normal"
        paddingLeft="small"
        paddingRight="small"
        paddingBottom="large"
      >
        <p>
          <ShowMoreButton
            buttonStyle={buttonStyles.primary}
            onClick={loadMore}
            isLoading={isLoading}
            children="Visa fler"
          />
        </p>
      </Container>
    );
  }, [collections.length, isLoading, isVisible, loadMore]);

  const children = useMemo(
    () =>
      collections.map((collection: ICollection) => (
        <Card collection={collection} key={collection.id} />
      )),
    [collections]
  );

  return (
    <div>
      <Helmet>
        <title>{meta.pageTitle}</title>
        <link rel="canonical" href={siteUrl('/insamlingar')} />
        <meta name="description" content={meta.pageDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:image" content={meta.ogImage} />
        <meta property="og:description" content={meta.ogDescription} />
      </Helmet>
      <BlockBackground>
        <Hero />
        <Container
          width="normal"
          paddingTop="small"
          paddingLeft="small"
          paddingRight="small"
        >
          <FilterSort
            sorting={sorting}
            period={period}
            handleChange={handleChange}
          />
        </Container>
        <Container
          width="normal"
          paddingTop="small"
          paddingLeft="small"
          paddingRight="small"
          paddingBottom="small"
        >
          <ItemContainer children={children} />
        </Container>
        {loadMoreButton}
      </BlockBackground>
      <Ticker />
    </div>
  );
}
