import styled, { css } from 'styled-components';

type Props = {
	messageStyle?: 'error' | 'warning' | 'information' | 'success';
};

export const AlertMessageContainer =
	styled.div <
	Props >
	`
  display: block;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.errorRedDark};

  ${({ messageStyle }) =>
		messageStyle === 'error' &&
		css`
			padding: 0.625rem 1rem;
			margin-top: 1rem;
			margin-bottom: 1rem;
			background-color: ${(props) => props.theme.colors.errorRedMedium};
			border: 1px solid ${(props) => props.theme.colors.errorRedMedium};
			border-radius: 0.25rem;
		`};

  ${({ messageStyle }) =>
		messageStyle === 'warning' &&
		css`
			padding: 0.625rem 1rem;
			margin-top: 1rem;
			margin-bottom: 1rem;
			color: ${(props) => props.theme.colors.gambogeOrange};
			background-color: ${(props) => props.theme.colors.blanchedAlmond};
			border: 1px solid ${(props) => props.theme.colors.blanchedAlmond};
			border-radius: 0.25rem;
		`};

  ${({ messageStyle }) =>
		messageStyle === 'information' &&
		css`
			padding: 0.625rem 1rem;
			margin-top: 1rem;
			margin-bottom: 1rem;
			color: ${(props) => props.theme.colors.deepSeaGreen};
			background-color: ${(props) => props.theme.colors.iceberg};
			border: 1px solid ${(props) => props.theme.colors.iceberg};
			border-radius: 0.25rem;
		`};

  ${({ messageStyle }) =>
		messageStyle === 'success' &&
		css`
			padding: 0.625rem 1rem;
			margin-top: 1rem;
			margin-bottom: 1rem;
			color: ${(props) => props.theme.colors.crusoe};
			background-color: ${(props) => props.theme.colors.skeptic};
			border: 1px solid ${(props) => props.theme.colors.skeptic};
			border-radius: 0.25rem;
		`};
`;
