/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, EffectCallback, DependencyList } from 'react';

export const useClientEffect = (callback: EffectCallback, deps: DependencyList = []) => {
	useEffect(
		() => {
			if (typeof window === 'undefined') {
				return;
			}

			return callback();
		},
		deps
	);
};

export default useClientEffect;
