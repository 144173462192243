import React from 'react';
import styled from 'styled-components';

// Helpers
import omitProps from '~/App/helpers/omit-props';
import { caretRight } from '~/App/helpers/icons';

// Shared components
import Link from '~/App/shared/components/Link';
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const Block = styled.div`
  margin: 0 auto;
  font-size: 0.875em;
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

const List = styled.div`
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ListItem = styled.div`
  display: inline-block;
`;

const StyledLink = styled(omitProps(Link, ['last']))`
  display: inline-block;
  vertical-align: middle;
  color: ${({ last, theme }) =>
    last ? theme.colors.charcoal : theme.themeColors.primary};
  text-decoration: ${({ last }) => (last ? 'none' : 'underline')};

  &:hover {
    color: ${({ last, theme }) =>
      last ? theme.colors.charcoal : theme.themeColors.primaryHover};
  }
`;

const Separator = styled.div`
  display: inline-block;
  padding: 0 0.75rem;
  vertical-align: middle;

  &:after {
    ${caretRight};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.charcoal};
  }
`;

export default React.memo(function Breadcrumbs(props) {
  const {
    width = '',
    breadcrumbs = [],
    verticalSpacing = '',
    backgroundColor = ''
  } = props;

  const paddingTop =
    verticalSpacing === null
      ? ''
      : verticalSpacing.split('topLarge').length === 2
      ? 'large'
      : '';

  const paddingBottom =
    verticalSpacing === null
      ? ''
      : verticalSpacing.split('bottomLarge').length === 2
      ? 'large'
      : '';

  return (
    <BlockBackground backgroundColor={backgroundColor}>
      <Container
        width={width}
        paddingTop={paddingTop}
        paddingLeft="large"
        paddingRight="large"
        paddingBottom={paddingBottom}
      >
        <Block>
          <List>
            {breadcrumbs.map((breadcrumb, key) => (
              <ListItem key={key}>
                <StyledLink
                  to={breadcrumb.path}
                  last={key + 1 === breadcrumbs.length}
                >
                  {breadcrumb.name}
                </StyledLink>
                {key + 1 !== breadcrumbs.length && <Separator />}
              </ListItem>
            ))}
          </List>
        </Block>
      </Container>
    </BlockBackground>
  );
});
