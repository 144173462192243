import React from 'react';
import styled from 'styled-components';

import { heading2 } from '~/App/helpers/mixins';
import { ViewWrapper } from '../../ViewWrapper';

const FlexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  max-width: 380px;
  margin: -2rem auto 0;
`;

const Heading1 = styled.h1`
  ${heading2};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
`;

const Preamble = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
  margin-bottom: 1.5rem;
`;

const Image = styled.img`
  display: block;
  width: 143px;
  margin: 0 auto 1.5rem;
`;

type Props = {
  email: string;
};

export function EmailConfirmationSent({ email }: Props) {
  return (
    <ViewWrapper alternativeBackground>
      <FlexWrapper>
        <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png" />
        <Heading1>Bekräftelsemejl skickat</Heading1>
        <Preamble>
          Följ instruktionerna som skickats till <strong children={email} /> för
          att bekräfta din e-post.
        </Preamble>
      </FlexWrapper>
    </ViewWrapper>
  );
}
