import React, { useMemo } from 'react';
import styled from 'styled-components';

// Config
import { collectionTypes } from '~/App/config/collectionTypes';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { prettyFormat } from '~/App/helpers/number-helper.js';
import {
  getGoalType,
  getGoal,
  getProgress
} from '~/App/helpers/collection-helper';
import { heading3 } from '~/App/helpers/mixins';
import { ICollection } from '~/types/ICollection';

import { CollectionProgress } from './components/CollectionProgress';

type WrapperProps = {
  keepAspectRatio: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  padding-top: ${({ keepAspectRatio }) =>
    keepAspectRatio ? '100%' : 'initial'};
  overflow: hidden;
  max-width: 100%;
`;

type PictureProps = {
  showBackgroundColor: boolean;
  keepAspectRatio: boolean;
};

const Picture = styled.picture<PictureProps>`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: block;
  background: ${({ showBackgroundColor, theme }) =>
    showBackgroundColor ? theme.themeColors.accentBlue : 'none'};
  border-radius: 0.125rem;
  position: ${({ keepAspectRatio }) =>
    keepAspectRatio ? 'absolute' : 'initial'};
  top: ${({ keepAspectRatio }) => (keepAspectRatio ? '0' : 'initial')};
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
`;

const Goal = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  height: 3rem;
`;

const Heading = styled.h3`
  ${heading3};
  color: ${({ theme }) => theme.colors.white};
`;

type Props = {
  collection: Pick<
    ICollection,
    | 'collectionType'
    | 'imageUrl'
    | 'status'
    | 'totalDistance'
    | 'progress'
    | 'goal'
    | 'showProgressBadge'
  >;
  keepAspectRatio?: boolean;
  percentage?: number;
};

export function CollectionImage({
  collection,
  keepAspectRatio = false,
  percentage
}: Props) {
  const {
    isLopbandet,
    imageUrl,
    goalUnit,
    hasGoal,
    primaryGoal,
    primaryStatus,
    progressPercentage
  } = useMemo(() => {
    const isLopbandet = collection.collectionType === collectionTypes.lopbandet;
    const fallbackImageUrl = isLopbandet
      ? 'https://res.cloudinary.com/cancerfonden/image/upload/v1567681619/assets/empty-collection_lopbandet.png'
      : 'http://res.cloudinary.com/cancerfonden/image/upload/v1561540461/grncs9xxyzmee8mywbvs.jpg';
    const imageUrl = collection.imageUrl || fallbackImageUrl;
    const goalType = getGoalType(collection);
    const status = getProgress(collection, goalType);
    const goal = Math.round(getGoal(collection, goalType));
    const primaryGoal = prettyFormat(goal);
    const primaryStatus = prettyFormat(status);
    const goalUnit = isLopbandet ? 'km' : 'kr';
    const hasGoal = goal > 0;
    const progressPercentage = percentage ? percentage : (status / goal) * 100;

    return {
      isLopbandet,
      imageUrl,
      primaryGoal,
      primaryStatus,
      hasGoal,
      goalUnit,
      progressPercentage
    };
  }, [collection, percentage]);

  return useMemo(
    () => (
      <Wrapper keepAspectRatio={keepAspectRatio}>
        {collection.showProgressBadge &&
        (hasGoal || percentage) &&
        !isLopbandet ? (
          <CollectionProgress
            percentage={progressPercentage}
            position={'absolute'}
          />
        ) : null}
        <Picture
          showBackgroundColor={isLopbandet && !collection.imageUrl}
          keepAspectRatio={keepAspectRatio}
        >
          <source
            srcSet={`
                      ${mediaHelper.getUrl(imageUrl, {
                        width: 800,
                        height: 800
                      })} 2x,
                      ${mediaHelper.getUrl(imageUrl, {
                        width: 400,
                        height: 400
                      })} 1x
                  `}
          />
          <Img
            src={mediaHelper.getUrl(imageUrl, {
              width: 400,
              height: 400,
              quality: 80,
              crop: 'fill',
              dpr: '2.0',
              fetch_format: 'png'
            })}
          />
        </Picture>
        {primaryStatus && (
          <Goal>
            <Heading>
              {primaryStatus} {goalUnit}{' '}
              {hasGoal ? `av ${primaryGoal} ${goalUnit}` : null}
            </Heading>
          </Goal>
        )}
      </Wrapper>
    ),
    [
      collection.imageUrl,
      imageUrl,
      goalUnit,
      hasGoal,
      isLopbandet,
      keepAspectRatio,
      primaryGoal,
      primaryStatus,
      percentage,
      progressPercentage,
      collection.showProgressBadge
    ]
  );
}
