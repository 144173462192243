import React from 'react';
import styled from 'styled-components';
import { formatNumber } from 'accounting';

// shared components
import { Divider } from '~/App/shared/components/Blocks';
import { NewFormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import { RightColumnText } from '~/App/shared/components/Donation/components/Forms/components/RightColumnText';

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -0.75rem;
`;

type Props = {
  heading?: string;
  area?: 'side' | 'form' | 'aside';
  price: number | null;
  part?: number;
  infoText?: string;
};

export function DonationSummary({
  heading,
  area,
  price,
  part = 1,
  infoText = ''
}: Props) {
  const showInfoText = part !== 1 && infoText;

  if (area === 'form') {
    return null;
  }

  return (
    <>
      <NewFormHeading children={heading || 'Din gåva'} />
      <AmountWrapper>
        <span>Gåvobelopp</span>
        <span>
          <NewFormHeading
            children={`${price ? formatNumber(price, 0, ' ') : 0} kr`}
          />
        </span>
      </AmountWrapper>
      {showInfoText && (
        <>
          <Divider
            width="narrow"
            paddingLeft="none"
            paddingRight="none"
            height="2px"
            alternativeColor={true}
          />
          <RightColumnText icon="heart" children={infoText} />
        </>
      )}
    </>
  );
}
