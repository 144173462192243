import React from 'react';
import styled from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';

type ImageProps = {
  marginBottom?: string;
  hideOnDesktop?: boolean;
}

const Image = styled.img<ImageProps>`
  align-self: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  height: 45px;
  width: 48px;

  ${mq<ImageProps>('≥mediumLarge')`
    display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'block')};
  `};
`;

type Props = ImageProps

export function Logo({
  marginBottom,
  hideOnDesktop
}: Props) {
  return (
    <Image
      marginBottom={marginBottom}
      hideOnDesktop={hideOnDesktop}
      src={mediaHelper.getUrl(
        'https://res.cloudinary.com/cancerfonden/image/upload/v1591085364/assets/login/bankid_logo.svg'
      )}
    />
  );
}