import React from 'react';
import styled, { keyframes } from 'styled-components';

const CircleSvg = styled.svg`
  width: 100%;
  height: 100%;
`;

const CircleBackground = styled.circle`
  stroke: #bcbcbc;
  fill: white;
  stroke-width: 3;
`;

const getFontSize = (percentage: number) => {
  if (percentage > 99 && percentage < 1000) return '24px';
  if (percentage > 999) return '16px';
  return '28px';
};
const getMargin = (percentage: number) => {
  if (percentage > 99) return '0';
  return '-0.25rem';
};

const getDashoffset = (percentage: number, circumference: number) => {
  const value = circumference - (percentage * circumference) / 100;
  return value > circumference || value < 0 ? 0 : value;
};

const getCircumference = (radius: number) => {
  return radius * 2 * Math.PI;
};

type CircleProgressProps = {
  percentage: number;
  circumference: number;
};

const strokeAnimation = (amount: number) => keyframes`
    to {
    stroke-dashoffset: ${amount}
    }
  `;

const CircleProgress = styled.circle<CircleProgressProps>`
  transform: rotate(-90deg) translate(-100%, 0);
  fill: none;
  stroke: ${({ percentage }) => (percentage < 100 ? '#001489' : '#008920')};
  stroke-width: 3;
  stroke-dasharray: ${({ circumference }) => circumference};
  stroke-dashoffset: ${({ circumference }) => circumference};
  animation: ${({ circumference, percentage }) =>
      strokeAnimation(getDashoffset(percentage, circumference))}
    2s ease-out forwards;
`;

type ProgressWrapperProps = {
  position: Position;
};

const ProgressWrapper = styled.div<ProgressWrapperProps>`
  position: ${({ position }) => position};
  top: 1rem;
  right: 1rem;
  z-index: 1;
`;

const PercentageText = styled.div`
  color: #001489;
  font-size: 10px;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
`;
type Position = 'absolute' | 'static';
type PercentageNumberProps = {
  percentage: number;
};

const PercentageNumber = styled.div<PercentageNumberProps>`
  color: #001489;
  font-size: ${({ percentage }) => getFontSize(percentage)};
  font-weight: bold;
  width: 100%;
`;

const ProgressTextWrapper = styled.div<PercentageNumberProps>`
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.25;
  margin-top: ${({ percentage }) => getMargin(percentage)};
`;

const CircleWrapper = styled.div`
  position: relative;
  width: 6rem;
  height: 6rem;
`;

function percentageToText(percentage: number) {
  if (percentage < 1) {
    return 'Bidra nu!';
  } else if (percentage > 0 && percentage < 30) {
    return 'På gång!';
  } else if (percentage > 29 && percentage < 45) {
    return 'På god väg!';
  } else if (percentage > 44 && percentage < 56) {
    return 'Halvvägs!';
  } else if (percentage > 55 && percentage < 80) {
    return 'Snart i mål!';
  } else if (percentage > 79 && percentage < 100) {
    return 'Slutspurt!';
  } else if (percentage === 100) {
    return 'I mål!';
  } else {
    return 'Wow!';
  }
}

type Props = {
  percentage: number;
  position: Position;
};

const radius = 44;

export function CollectionProgress({ percentage, position }: Props) {
  return (
    <ProgressWrapper position={position}>
      <CircleWrapper>
        <CircleSvg>
          <CircleBackground r={radius} cx="50%" cy="50%" />
          <CircleProgress
            r={radius}
            cx="50%"
            cy="50%"
            percentage={percentage}
            circumference={getCircumference(radius)}
          />
        </CircleSvg>
        <ProgressTextWrapper percentage={percentage}>
          <PercentageNumber
            percentage={percentage}
            children={`${Math.round(percentage)}%`}
          />
          <PercentageText>{percentageToText(percentage)}</PercentageText>
        </ProgressTextWrapper>
      </CircleWrapper>
    </ProgressWrapper>
  );
}
