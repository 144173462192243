import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import styled from 'styled-components';
import { angleDown } from '~/App/helpers/icons';
import { mq } from '~/lib/mq';

type ShowMoreButtonProps = {
  hideContent: boolean;
};

const ShowMoreButton = styled.button<ShowMoreButtonProps>`
  margin: 0 auto;
  display: block;
  color: ${({ theme }) => theme.themeColors.primary};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;

  ${mq('≥small')`
      margin-left: 0;
    `};

  &::after {
    ${angleDown};
    line-height: 0;
    vertical-align: middle;
    font-weight: 700;
    font-size: 1.25rem;
    display: inline-block;
    margin-left: 0.25rem;
    margin-bottom: ${({ hideContent }) => (hideContent ? '0.25rem' : '0')};
    transform: ${({ hideContent }) =>
      hideContent ? 'rotate(0deg)' : 'rotate(180deg)'};
  }
`;

type Args = {
  maximumRows: number;
};

export function useReadMore({ maximumRows }: Args) {
  const [showButton, setShowButton] = useState(false);
  const [hideContent, setHideContent] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const descriptionHight = ref.current.scrollHeight;
    const elementStyle = window.getComputedStyle(ref.current);
    const lineHeight = parseInt(
      elementStyle.getPropertyValue('line-height'),
      10
    );

    if (descriptionHight > lineHeight * maximumRows) {
      setShowButton(true);
      setHideContent(true);
      setStyles(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maximumRows, ref]);

  const setStyles = useCallback(
    (hideContent: boolean) => {
      if (!ref.current) {
        return;
      }

      const elementStyle = window.getComputedStyle(ref.current);
      const lineHeightValue = elementStyle.getPropertyValue('line-height');
      const lineHeight = parseInt(lineHeightValue, 10);
      const maxHeight = `${lineHeight * maximumRows}px`;

      ref.current.style.overflow = 'hidden';
      ref.current.style.maxHeight = hideContent ? maxHeight : 'none';
    },
    [maximumRows]
  );

  const toggleContent = useCallback(() => {
    const newHideContent = !hideContent;

    setHideContent(newHideContent);
    setStyles(newHideContent);
  }, [hideContent, setStyles]);

  return useMemo(
    () => ({
      ref,
      showButton,
      hideContent,
      button: showButton ? (
        <ShowMoreButton
          onClick={toggleContent}
          hideContent={hideContent}
          children={hideContent ? 'Visa mer' : 'Visa mindre'}
        />
      ) : null,
      toggleContent
    }),
    [hideContent, showButton, toggleContent]
  );
}
