import React from 'react';

class Preamble extends React.Component {
  render() {
    if (!this.props.preamble && !this.props.preambleShort) {
      return null;
    }

    return (
      <div className="heroTitle">
        {this.props.preamble ? (
          <p
            className="heroTitle__preamble heroTitle__preamble--default"
            dangerouslySetInnerHTML={{ __html: this.props.preamble }}
          />
        ) : null}

        {this.props.preambleShort ? (
          <p
            className="heroTitle__preamble heroTitle__preamble--phone"
            dangerouslySetInnerHTML={{ __html: this.props.preambleShort }}
          />
        ) : null}
      </div>
    );
  }
}

Preamble.displayName = 'EditorialHeroPreamble';

Preamble.defaultProps = {
  preamble: '',
  preambleShort: ''
};

export default Preamble;
