import React from 'react';
import styled from 'styled-components';

// Constants
import { collectionTypes } from '~/App/config/collectionTypes';
import * as themes from '~/App/config/themes';

// Helpers
import { heading2, preamble } from '~/App/helpers/mixins';
import mediaHelper from '~/App/helpers/media-helper';
import { siteUrl } from '~/App/helpers/env';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';

// Shared components
import {
  Background,
  Wrapper,
  ImageWrapper,
  Content
} from '~/App/shared/components/ThanksPage';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import Link from '~/App/shared/components/Link';
import ShareButtons from '~/App/shared/components/ShareButtons';

const backgroundColor = featureIsEnabled('FEATURE_LOPBANDET_PINK')
  ? themes.global.collectionItem.imageAlternativeBackground
  : themes.global.exerciseApp.imageBackgroundColor;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 2.5rem;
  white-space: pre-line;
`;

const CollectionImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 auto 1rem auto;
  background-color: ${(props) =>
    props.backgroundColor
      ? backgroundColor
      : props.theme.collectionItem.imageBackground};
  .owl-carousel .owl-item & {
    width: 10rem;
    height: 10rem;
  }
`;

const CollectionImageWrapper = styled(ImageWrapper)`
  padding: 0;
`;

const PreambleLine = styled.div`
  width: 74px;
  height: 2px;
  opacity: 0.43;
  border-radius: 1.5px;
  background-color: #afafaf;
  margin: 1.5rem auto 2rem auto;
`;

const CollectionLink = styled(Link)`
  text-decoration: underline;
`;

const CollectionPreamble = styled(Preamble)`
  margin-bottom: 1rem;
`;

const CollectionShareButtons = styled(ShareButtons)`
  width: 5.5rem;
  margin: 0 auto;
`;

class CollectionDonationThanks extends React.Component {
  render() {
    const purchase = this.props.purchase;
    const data = this.props.data;
    const collection = this.props.collection;
    const {
      purchase: { paymentMethod = {}, productOptions = {} }
    } = purchase;
    const { customerContact = {} } = productOptions;

    const firstName = customerContact.firstName;

    const greeting = firstName
      ? data.heading?.replace('%%first_name%%', ` ${firstName}`)
      : data.heading?.replace('%%first_name%%', '');

    const emailInPreamble =
      (productOptions.customerContact &&
        productOptions.customerContact.email) ||
      'din mail';
    let preamble = data.texts?.description?.replace(
      '%%email%%',
      emailInPreamble
    );

    const splitPreamble = preamble?.split('%%collection_link%%');

    const isLopbandet = collection.collectionType === collectionTypes.lopbandet;

    const collectionLink = `/insamlingar/${collection.slug}`;

    if (splitPreamble.length === 1) {
      preamble = <CollectionPreamble>{preamble}</CollectionPreamble>;
    } else {
      preamble = (
        <CollectionPreamble>
          {splitPreamble.map((element, index) => {
            if (index !== splitPreamble.length - 1) {
              return (
                <React.Fragment key={index}>
                  {element}
                  <CollectionLink to={collectionLink}>
                    {collection.name}
                  </CollectionLink>
                </React.Fragment>
              );
            } else {
              return <React.Fragment key={index}>{element}</React.Fragment>;
            }
          })}
        </CollectionPreamble>
      );
    }

    return (
      <div>
        <Background style={{ backgroundColor: themes.global.colors.lightBlue }}>
          <Wrapper>
            <CollectionImageWrapper>
              <CollectionImage
                backgroundColor={!collection.imageUrl && isLopbandet}
                src={mediaHelper.getUrl(
                  `${
                    !collection.imageUrl && isLopbandet
                      ? 'https://res.cloudinary.com/cancerfonden/image/upload/v1567681619/assets/empty-collection_lopbandet.png'
                      : !collection.imageUrl && !isLopbandet
                      ? 'http://res.cloudinary.com/cancerfonden/image/upload/v1561540461/grncs9xxyzmee8mywbvs.jpg'
                      : collection.imageUrl
                  }`,
                  {
                    width: 160,
                    height: 160,
                    quality: 80,
                    crop: 'fill',
                    dpr: '2.0',
                    radius: 160,
                    fetch_format: 'png'
                  }
                )}
              />
            </CollectionImageWrapper>
            <Content>
              <Heading1 children={greeting} />
              {preamble}
              <FetchPurchaseDocuments purchase={purchase}>
                {({ receipt, receiptStatus }) => (
                  <AsyncReceiptDocumentLink
                    url={receipt}
                    status={receiptStatus}
                    paymentMethodId={paymentMethod?.id}
                  />
                )}
              </FetchPurchaseDocuments>
              <PreambleLine />
              <CollectionPreamble children={data.texts?.socialText} />
              <CollectionShareButtons
                to={siteUrl(collectionLink)}
                showFacebook={true}
                showX={true}
              />
            </Content>
          </Wrapper>
        </Background>
      </div>
    );
  }
}

export default CollectionDonationThanks;
