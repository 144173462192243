import React, { ChangeEvent } from 'react'
import styled from 'styled-components';

import mq from '~/App/helpers/mq';
import { angleDown } from '~/App/helpers/icons';

const FilterSortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;

  ${mq('≥small')`
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  `};

  ${mq('≥large')`
    justify-content: space-between;
  `};
`;

const SortWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;

  &::before {
    ${angleDown};
    display: block;
    position: absolute;
    top: 50%;
    right: 0.75rem;
    font-size: 1.75em;
    color: ${({ theme }) => theme.colors.darkBlue};
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
  }

  ${mq('≥small')`
    max-width: 19rem;
  `};
`;

const SelectWrapper = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  border-radius: 0.25rem;
  width: 100%;
  padding-right: 2.5rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
`;

const SelectDropdown = styled.select`
  border: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  background: transparent;
  font-size: 0.9375rem;
  margin: 0.125rem 0;
  padding: 0.5rem 0 0.5rem 1rem;
  height: 2.75rem;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  appearance: none;
  width: calc(100% + 2.5em);
  color: ${({ theme }) => theme.colors.charcoal};

  &::-ms-expand {
    display: none;
  }

  option {
    appearance: none;
    padding: 0;
  }

  ${mq('≥small')`
    font-size: 1rem;
  `};
`;

type Props = {
  sorting: string
  period: string | number
  handleChange: (_: ChangeEvent<HTMLSelectElement>) => void
}

export function FilterSort({ sorting, period, handleChange }: Props) {
  return (
    <FilterSortWrapper>
      <SortWrapper>
        <SelectWrapper>
          <SelectDropdown
            value={`${sorting}-${period}`}
            onChange={handleChange}
            className="selectGroup__select">
            <option disabled>Sortera efter</option>
            <option value={`latestDonated-365`}>
              Som senast fått en gåva
            </option>
            <option value={`latestCreated-365`}>
              Senast skapade
            </option>
            <option value={`mostDonated-7`}>
              Mest insamlat senaste veckan
            </option>
            <option value={`mostDonated-30`}>
              Mest insamlat senaste månaden
            </option>
            <option value={`mostDonated-365`}>
              Mest insamlat senaste året
            </option>
            <option value={`mostDonations-7`}>
              Flest gåvor senaste veckan
            </option>
            <option value={`mostDonations-30`}>
              Flest gåvor senaste månaden
            </option>
            <option value={`mostDonations-365`}>
              Flest gåvor senaste året
            </option>
          </SelectDropdown>
        </SelectWrapper>
      </SortWrapper>
    </FilterSortWrapper>
  )
}