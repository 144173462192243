import React, { ReactNode, useMemo } from 'react';

import { useGenericValues } from './useGenericValues';

import { useContactInformationValues } from './useContactInformationValues';

import {
  MemorialGiftInputValues,
  MemorialGiftValues,
  useMemorialGiftValues
} from './useMemorialGiftValues';

import { useProductValues } from './useProductValues';
import { usePaymentValues } from './usePaymentValues';
import { useAnalyticsValues } from './useAnalyticsValues';

import { StateInputValues, StateValues } from './State';

type InputValues = StateInputValues & MemorialGiftInputValues;

type Props<T> = {
  values: InputValues & T;
  formContent: {
    originPath: string | Record<number, string>;
    redirectPath: string | Record<number, string>;
    gtmId?: string | undefined;
  };
  customGtmId?: string;
  children: (values: MemorialGiftStateValue & T) => ReactNode;
};

export type MemorialGiftStateValue = StateValues & MemorialGiftValues;

export function MemorialGiftState<T>({
  values,
  formContent,
  customGtmId,
  children
}: Props<T>) {
  const generic = useGenericValues(values);
  const contactInformation = useContactInformationValues(generic);
  const product = useProductValues(contactInformation);
  const memorialGift = useMemorialGiftValues(product);
  const analytics = useAnalyticsValues({
    values: memorialGift,
    formContent: formContent
  });

  const paymentValues = usePaymentValues({
    values: analytics,
    formContent: formContent,
    customGtmId: customGtmId
  });

  return useMemo(() => <>{children(paymentValues)}</>, [
    paymentValues,
    children
  ]);
}
