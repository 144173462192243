import React from 'react';
import styled from 'styled-components';
import { downloadLink, externalLink } from '~/App/helpers/mixins';

import { Spinner } from './components/Spinner';

const Feedback = styled.div`
  padding: 1.25rem;
  background: ${({ theme }) => theme.colors.lightPolar};
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.darkBlue};
  border-radius: 0.25rem;
  margin: 1.5rem auto;
  max-width: 40rem;
`;

const InlineLink = styled.a`
  text-decoration: underline;
`;

const LinkWrapper = styled.div`
  margin: 1.5rem;
`;

type LinkProps = {
  icon: 'external' | 'download';
};

const Link = styled.a<LinkProps>`
  ${({ icon }) => (icon === 'external' ? externalLink : downloadLink)};
`;

type Props = {
  url: string | null | undefined;
  status: Status;
  fileName: string;
  fileNameWithPronoun: string;
  fileNameForLink: string;
  icon?: 'external' | 'download';
};

export type Status = 'done' | 'fetching' | 'error' | 'timeout';

export function AsyncDocumentLink({
  url,
  status,
  fileName,
  fileNameForLink,
  fileNameWithPronoun,
  icon = 'download'
}: Props) {
  if (status === 'fetching') {
    return <Spinner fileName={fileName} />;
  }

  if (status === 'done' && url) {
    return (
      <LinkWrapper>
        <Link
          icon={icon}
          rel="noopener noreferrer"
          href={url}
          target="_blank"
          children={fileNameForLink}
        />
      </LinkWrapper>
    );
  }

  return (
    <Feedback>
      Det tar tyvärr lite tid att skapa {fileNameWithPronoun}. Se efter om du
      har fått ett mejl från oss med bekräftelse på din gåva. Om du inte har
      fått mejl inom en timme, kontakta gärna givarservice på{' '}
      <InlineLink
        href="mailto:info@cancerfonden.se"
        children="info@cancerfonden.se"
      />{' '}
      eller <InlineLink href="tel:0101991010" children="010-199 10 10" />.
    </Feedback>
  );
}
