import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { heading3, heading4 } from '~/App/helpers/mixins';
import { scrollAnimation } from '~/App/helpers/animations';

// Shared components
import Container from '~/App/shared/components/Container';
import { PrimaryButton } from '~/App/shared/components/Elements';

import Link from '~/App/shared/components/Link';
import { rotate360 } from '~/App/helpers/animations';

type BankIdContainerProps = {
  inline: boolean;
};

const BankIdContainer = styled.div<BankIdContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 30rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-shadow: ${({ inline }) =>
    inline ? 'none' : '0 4px 6px 0 rgba(0, 0, 0, 0.15)'};
  border-radius: 0.25rem;
`;

const BankIdPhone = styled.img`
  display: block;
  width: 10.125rem;
  height: 10rem;
  margin: 1.125rem auto 2rem auto;
`;

const BankIdInstructions = styled.h3`
  ${heading3};
  margin: 0 auto;
  text-align: center;
`;

const BankIdError = styled.p`
  max-width: 15rem;
  margin: 0.5rem auto 2rem auto;
  text-align: center;

  ${mq('≥small')`
    max-width: 17rem;
  `};
`;

const BankIdLink = styled(Link)`
  white-space: nowrap;
`;

const BankIdButton = styled(PrimaryButton)`
  margin: 0 auto 0.75rem auto;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  margin: 2rem auto 0.75rem auto;
`;

const BankIdSpinner = styled.div`
  width: 100%;
  height: 100%;
  animation: ${rotate360} 0.3s linear infinite;
  border-top: 2px solid ${({ theme }) => theme.bankIdSpinner.primaryColor};
  border-right: 2px solid ${({ theme }) => theme.bankIdSpinner.secondaryColor};
  border-bottom: 2px solid ${({ theme }) => theme.bankIdSpinner.secondaryColor};
  border-left: 2px solid ${({ theme }) => theme.bankIdSpinner.primaryColor};
  border-radius: 100%;
  content: ' ';
  z-index: 1;
`;

const InfoText = styled.span`
  display: block;
  text-align: center;
  margin: 0.75rem 0;
`;

const Heading4 = styled.h3`
  ${heading4};
  margin-bottom: 0.75rem;
`;

const QRImage = styled.img`
  margin-bottom: 1rem;
  width: 50%;
`;

type Props = {
  loading?: boolean;
  failed: boolean;
  inline?: boolean;
  loginStatus?: string;
  loginMobile?: boolean;
  resetBankId: () => void;
  qrCode?: string;
  autoStartToken?: string;
};

export default function BankId({
  loading,
  failed,
  inline = false,
  resetBankId,
  loginStatus = '',
  loginMobile = false,
  qrCode = '',
  autoStartToken = ''
}: Props) {
  useEffect(() => scrollAnimation(0), []);

  const userMessage = useMemo(() => {
    if (!loginStatus) {
      return 'Signera med Mobilt BankID';
    }

    if (loginMobile) {
      return loginStatus === 'signing'
        ? 'Skriv in din säkerhetskod och välj Legitimera eller Skriv under.'
        : 'Försöker starta BankID-appen.';
    }

    return loginStatus === 'signing'
      ? 'Skriv in din säkerhetskod i ditt mobila BankID och välj Legitimera eller Skriv under'
      : 'Starta din BankID-app';
  }, [loginMobile, loginStatus]);

  const handleTryAgin = useCallback(
    (event) => {
      event.preventDefault();
      resetBankId();
    },
    [resetBankId]
  );

  const handleSameDevice = useCallback(() => {
    window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
  }, [autoStartToken]);

  return (
    <Container
      width="limited"
      paddingLeft="small"
      paddingRight="small"
      paddingTop="small"
      paddingBottom="small"
    >
      {loading ? (
        <BankIdContainer inline={inline}>
          {qrCode ? (
            <InfoText>
              <QRImage src={qrCode} />
              <Heading4>Legitimera dig med Mobilt BankID</Heading4>
              Öppna Mobilt BankID på din mobil eller surfplatta och skanna
              QR-koden. Det kan ta upp till 45 sekunder att få återkoppling från
              din bank.
            </InfoText>
          ) : (
            <>
              <BankIdPhone src="https://res.cloudinary.com/cancerfonden/image/upload/v1574858696/assets/phone-bankid.png" />
              <BankIdInstructions children={userMessage} />
              <SpinnerWrapper>
                <BankIdSpinner />
              </SpinnerWrapper>
            </>
          )}
          {autoStartToken && (
            <BankIdButton
              buttonStyle="outlinePrimary"
              children="Öppna BankId på den här enheten"
              onClick={() => handleSameDevice()}
            />
          )}
        </BankIdContainer>
      ) : null}
      {failed && (
        <BankIdContainer inline={inline}>
          <BankIdPhone src="https://res.cloudinary.com/cancerfonden/image/upload/v1574933394/assets/phone-bankid-error.png" />
          <BankIdInstructions>
            Din signering blev inte godkänd
          </BankIdInstructions>
          <BankIdError>
            Kontakta givarservice på{' '}
            <BankIdLink to="tel:+46101991010">010-199 10 10</BankIdLink> eller
            försök igen.
          </BankIdError>
          <BankIdButton
            buttonType="link"
            buttonStyle="outlinePrimary"
            to="#"
            children="Försök igen"
            onClick={handleTryAgin}
          />
        </BankIdContainer>
      )}
    </Container>
  );
}
