import { useState, useMemo, useCallback, useEffect, ChangeEvent } from 'react';

import { SwishType, swishTypes } from '~/App/config/swishTypes';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import objectToQueryString from '~/App/helpers/object-to-query-string';

const BASE_URL = process.env.CLIENT_SITE_BASE_URL;

type State = {
  phoneNumber: string;
  swishType: SwishType;
};

type Props = {
  productId: number | undefined;
  originPath: string;
  redirectPath: string;
  customGtmId?: string | undefined;
};

export type Swish = State & {
  handlers: {
    handleSwishTypeToggle: () => void;
    setSwishPhoneNumber: (value: string) => void;
    handleSwishPhoneNumber: (event: ChangeEvent<HTMLInputElement>) => void;
  };
};

export function useSwish({
  productId,
  originPath,
  redirectPath,
  customGtmId
}: Props): Swish {
  const [state, setState] = useState<State>({
    phoneNumber: '',
    swishType: isMobileBrowser() ? swishTypes.mcommerce : swishTypes.ecommerce
  });

  const swishReturnParameters = useCallback(
    (type) =>
      objectToQueryString({
        originPath,
        redirectPath,
        gtmId: customGtmId,
        type: type
      }),
    [customGtmId, originPath, redirectPath]
  );

  const swishReturnUrl = useMemo(
    () =>
      `${BASE_URL}/bekrafta-swishbetalning${swishReturnParameters(
        state.swishType
      )}`,
    [state.swishType, swishReturnParameters]
  );

  const handleSwishTypeToggle = useCallback(
    () =>
      setState((state) => ({
        ...state,
        swishType:
          state.swishType === swishTypes.ecommerce
            ? swishTypes.mcommerce
            : swishTypes.ecommerce
      })),
    []
  );

  const setSwishPhoneNumber = useCallback((value: string) => {
    const phoneNumber = value.replace(/^\+46/, '0');
    const phoneNumberForSwish = phoneNumber
      ? `46${phoneNumber.replace(/^0+/, '')}`
      : '';

    setState((state) => ({
      ...state,
      phoneNumber: phoneNumberForSwish
    }));
  }, []);

  const handleSwishPhoneNumber = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSwishPhoneNumber(event.target.value),
    [setSwishPhoneNumber]
  );

  useEffect(() => {
    if (!isMobileBrowser()) {
      return; // mobile only
    }

    if (state.swishType === swishTypes.mcommerce) {
      return; // already set
    }

    setState((state) => ({
      ...state,
      swishType: swishTypes.mcommerce
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return useMemo(
    () => ({
      ...state,
      returnUrl: swishReturnUrl,
      handlers: {
        handleSwishTypeToggle,
        handleSwishPhoneNumber,
        setSwishPhoneNumber
      }
    }),
    [
      handleSwishPhoneNumber,
      handleSwishTypeToggle,
      setSwishPhoneNumber,
      state,
      swishReturnUrl
    ]
  );
}
