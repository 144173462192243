import React from 'react';
import styled from 'styled-components';

type WrapperProps = {
  src?: string;
  size: string;
};

const Wrapper = styled.div<WrapperProps>`
  background-color: transparent;
  background-image: url('${({ src }) => src}');
  background-size: cover;
  border-radius: ${({ size }) => size};
  width: ${({ size }) => size};
  max-width: calc(100vw - 2rem);
  padding-top: min(100%, ${({ size }) => size});
  box-sizing: content-box;
  margin: 0 auto;
  border: 0.5rem solid white;
`;

type CircleImageProps = {
  src?: string;
  size: string;
};

export function CircleImage(props: CircleImageProps) {
  return <Wrapper {...props} />;
}
