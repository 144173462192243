import React, { useMemo } from 'react';
import styled from 'styled-components';

// Local components
import { Preview, PreviewWrapper, CFLogo, Watermark } from './components';

type ImageProps = {
  src: string
}

const Image = styled.div<ImageProps>`
  width: 6em;
  height: 6em;
  background-image: ${({ src }) => `url('${src}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  margin-bottom: 1em;
`;

const Heading = styled.p`
  font-weight: 700;
  font-size: 2em;
  margin-bottom: 0.5em;
`;

const Greeting = styled.p`
  font-family: Arial, sans-serif;
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 1em;
  margin-bottom: 1.5em;
  word-wrap: break-word;
`;

const Description = styled.p`
  font-size: 0.75em;
  margin-top: 0.5em;
  width: 100%;
`;

type Props = {
  imageUrl?: string;
  heading?: string;
  greeting?: string;
  description?: string;
  fullSize?: boolean;
};

export function GenericCertificatePreview({
  imageUrl,
  heading,
  greeting,
  description,
  fullSize = false
}: Props) {
  return useMemo(
    () => (
      <PreviewWrapper fullSize={fullSize}>
        <Preview fullSize={fullSize}>
          {imageUrl && <Image src={imageUrl} />}
          {heading && <Heading>{heading}</Heading>}
          {greeting && (
            <Greeting>
              {greeting.split('\n').map((item, index) => {
                return (
                  <span key={index}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </Greeting>
          )}
          <CFLogo />
          {description && <Description>{description}</Description>}
          <Watermark isVisible={fullSize} />
        </Preview>
      </PreviewWrapper>
    ),
    [description, fullSize, greeting, heading, imageUrl]
  );
}
