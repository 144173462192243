import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import { IMenuItem } from '~/App/config/navigation';
import { isActive } from '~/App/helpers/menu-item-is-active';
import { linkUnderline } from '~/App/helpers/mixins';

import Link from '~/App/shared/components/Link';

// Helpers
import mq from '~/App/helpers/mq';

const List = styled.ul`
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.seashell};

  ${mq('≥mediumLarge')`
    position: absolute;
    display: flex;
    flex-direction: row;
    width: auto;
    top: 130px;
    right: 0;
    left: 0;
    padding: 0 1.5rem;
  `};
`;

type StyledLinkProps = {
  isActive: boolean;
};

export const StyledLink = styled(Link)<StyledLinkProps>`
  display: block;
  padding: 1rem 0.75rem 1rem 1.5rem;
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.3125;
  ${linkUnderline};

  &::after {
    height: 2px;
    background: ${({ theme }) => theme.subNavItem.color};
  }

  ${mq<StyledLinkProps>('≥mediumLarge')`
    display: flex;
    margin: 0 0.375rem;
    padding: 0 0.375rem;
    font-size: 0.875rem;
    line-height: 1.5;
    height: 50px;
    align-items: center;

    ${({ isActive }) =>
      isActive &&
      css`
        &::after {
          visibility: visible;
          transform: scaleX(1);
        }
      `};
  `};
`;

export const Item = styled.li`
  color: ${({ theme }) => theme.subNavItem.color};
  white-space: nowrap;

  ${mq('≥mediumLarge')`
    &:hover {
      ${StyledLink}::after {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  `};
`;

type TextProps = {
  isActive: boolean;
};

export const Text = styled.span<TextProps>`
  display: inline-block;
  vertical-align: middle;
  position: relative;

  ${mq<TextProps>('<mediumLarge')`
    &::after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      background: ${({ theme }) => theme.themeColors.primary};
      visibility: hidden;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        &::after {
          visibility: visible;
        }
      `};
  `};

  ${mq('≥mediumLarge')`
    padding-top: 0.25rem;
  `};
`;

type Props = {
  items: IMenuItem[];
  toggleVisible: () => void;
};

export function SubNavigation({ items, toggleVisible }: Props) {
  const location = useLocation();

  const renderItem = useCallback(
    (item: IMenuItem, index: number) => {
      const active = isActive({
        url: item.url,
        pathname: location.pathname
      });

      return (
        <Item key={index}>
          <StyledLink
            isActive={active}
            to={item.url}
            onClick={toggleVisible}
            target={item.target}
          >
            <Text isActive={active} children={item.text} />
          </StyledLink>
        </Item>
      );
    },
    [location.pathname, toggleVisible]
  );

  return <List children={items.map(renderItem)} />;
}
