import React from 'react';
import styled from 'styled-components';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';

// Helpers
import { heading4 } from '~/App/helpers/mixins';
import { PrimaryButton } from '~/App/shared/components/Elements';

const InfoText = styled.span`
  display: block;
  text-align: center;
  margin: 0.75rem 0;
`;

const Heading4 = styled.h3`
  ${heading4};
  margin-bottom: 0.75rem;
`;

const QRImage = styled.img`
  margin-bottom: 1rem;
  width: 50%;
`;

const BankIdButton = styled(PrimaryButton)`
  margin: 0 auto 0.75rem auto;
`;

type Props = {
  qr: string;
  autostartToken: string;
};

export default function QRAutostartTokenWrapper({
  qr = '',
  autostartToken = ''
}: Props) {
  return (
    <>
      {qr && (
        <InfoText>
          <QRImage src={qr} />
          <Heading4>Legitimera dig med Mobilt BankID</Heading4>
          Öppna Mobilt BankID på din mobil eller surfplatta och skanna QR-koden.
          Det kan ta upp till 45 sekunder att få återkoppling från din bank.
        </InfoText>
      )}
      {autostartToken && isMobileBrowser() ? (
        <BankIdButton
          buttonStyle="outlinePrimary"
          children="Öppna BankId på den här enheten"
          onClick={() => {
            window.location.href = `bankid:///?autostarttoken=${autostartToken}&redirect=null`;
          }}
        />
      ) : null}
    </>
  );
}
