import { Dispatch, SetStateAction, useCallback } from 'react';
import { get } from 'lodash';

import { errorLevels } from '~/App/config/errorLevels';
import { poll } from '~/App/helpers/http';
import { logMessage } from '~/App/helpers/logger';

import { SubmitState } from '../types/SubmitState';
import { SubmitValues } from '../types/SubmitValues';
import { pushGTMFormEvent } from '~/App/helpers/gtm-helper';
import { paymentMethods } from '~/App/config/paymentMethods';

type Props = {
  values: SubmitValues;
  state: SubmitState;
  setState: Dispatch<SetStateAction<SubmitState>>;
};

export function usePurchaseResponseHandlers({
  values,
  state,
  setState
}: Props) {
  const handleError = useCallback(
    (error, source = 'purchase') => {
      if (poll.isCancel(error)) {
        return;
      }

      if (poll.isTimeout(error)) {
        logMessage({
          level: errorLevels.warn,
          message: `Submit polling timed out for product ${values.product.id}`,
          data: {
            publicToken: state.publicToken,
            productId: values?.product?.id
          }
        });

        return setState((state) => ({
          ...state,
          hasTimedOut: true
        }));
      }

      let exceptionCode: string | null;
      switch (source) {
        case 'purchase':
          // Any Swish error
          exceptionCode = get(
            error,
            'response.data.errors.payment.exception[0]',
            null
          );
          break;
        case 'stripe':
          if (error.code === 'card_declined') {
            exceptionCode = `${error.code}_${error.decline_code}`;
          } else {
            exceptionCode = error.code;
          }
          break;
        case 'poll':
          exceptionCode = get(error, 'data.purchase.exceptionMessage');
          break;
        default:
          exceptionCode = null;
          break;
      }

      setState((state) => ({
        ...state,
        errors: true,
        exceptionCode: exceptionCode,
        isSending: false,
        isPolling: false,
        isCompleted: false
      }));
    },
    [setState, state.publicToken, values.product.id]
  );

  const handleSuccess = useCallback(
    (response) => {
      const { data: { purchase = {}, product = {} } = {} } = response;

      console.log('handle sucess');
      if (values.paymentMethod?.id !== paymentMethods.swish) {
        pushGTMFormEvent('formComplete', {
          formName: values.gtm?.formName,
          amount: values.productOptions.memorialGift?.amount
            ? values.productOptions?.memorialGift.amount
            : values.productOptions?.product?.price,
          period: values.productOptions.product.period,
          step: values.partOptions?.currentPart,
          stepName: 'Välj betalsätt',
          paymentMethod:
            values.paymentMethod?.id === paymentMethods.klarnaPayments
              ? values.paymentMethod.slug
              : values.paymentMethod?.name
        });
      }

      setState((state) => ({
        ...state,
        isPolling: false,
        isCompleted: true,
        klarnaRedirectUrl: purchase.klarnaRedirectUrl,
        paymentUrl: purchase.paymentUrl,
        publicToken: purchase.publicToken,
        swishToken: purchase.swishToken,
        swishLoadUrl: purchase.swishLoadUrl,
        gtmPurchase: {
          purchaseId: purchase.id,
          productId: product.id,
          totalAmount: purchase.amount,
          quantity: product.quantity
        }
      }));
    },
    [
      setState,
      values.gtm?.formName,
      values.productOptions.product.price,
      values.productOptions.product.period,
      values.partOptions?.currentPart,
      values.paymentMethod,
      values.productOptions.memorialGift
    ]
  );

  return {
    handleError,
    handleSuccess
  };
}
