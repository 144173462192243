import React from 'react';
import classNames from 'classnames';

import { Embed, Heading, Preamble, Button, Navigation, Wrapper } from './Hero';

class ContentDefinitionHero extends React.Component {
  heroContent() {
    const wrapperClasses = classNames('hero__content', {
      'hero__content--hasNavigation': this.props.navigation.length > 0,
      rb17: this.props.data && this.props.data.theme === 'rb17'
    });

    if (this.props.imageType === 'Embed' && this.props.embed !== '') {
      return (
        <Embed
          video={this.props.embed}
          heading={this.props.heading}
          preamble={this.props.preamble}
          preambleShort={this.props.preambleShort}
          textColor={this.props.heroTextColor}
          hasNavigation={false}
          data={this.props.data}
        />
      );
    }

    return (
      <div className={wrapperClasses}>
        <Heading
          heading={this.props.heading}
          headingFontSize={this.props.headingFontSize}
          headingFontWeight={this.props.headingFontWeight}
        />
        <Preamble
          preamble={this.props.preamble}
          preambleShort={this.props.preambleShort}
        />
        <Button {...this.props} />
        <Navigation {...this.props} />
      </div>
    );
  }

  render() {
    if (this.props.imageLayout !== 'hero') {
      return null;
    }

    return <Wrapper {...this.props}>{this.heroContent()}</Wrapper>;
  }
}

ContentDefinitionHero.displayName = 'ContentDefinitionHero';

ContentDefinitionHero.defaultProps = {
  imageLayout: 'hero',
  headingFontSize: 'large',
  headingFontWeight: 'light',
  navigation: []
};

export default ContentDefinitionHero;
