import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  to: string;
  post?: boolean;
  external?: boolean;
  replace?: boolean;
};

export function Redirect({ to, post, external, replace }: Props) {
  const navigate = useNavigate();

  const handlePost = useCallback(() => {
    const form = document.createElement('form');

    form.setAttribute('method', 'post');
    form.setAttribute('action', to);

    document.body.appendChild(form);

    form.submit();
  }, [to]);

  const handleReplace = useCallback(() => navigate(to, { replace: true }), [
    navigate,
    to
  ]);

  const handleExternal = useCallback(() => {
    window.location.href = to;
  }, [to]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (post) return handlePost();
    if (external) return handleExternal();
    if (replace) return handleReplace();

    navigate(to);
  }, [
    external,
    handleExternal,
    handlePost,
    handleReplace,
    navigate,
    post,
    replace,
    to
  ]);

  return null;
}

export default Redirect;
