import { useLocation } from 'react-router';
import { parse, ParsedQuery } from '~/lib/query-string';
import { useMemo } from 'react';

export function useQuery<T = ParsedQuery>() {
  const location = useLocation();
  const query = useMemo<T>(
    () => (parse(location.search) as unknown) as T,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search]
  );

  return query;
}

export default useQuery;
