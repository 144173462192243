import React from 'react';
import classNames from 'classnames';

class Section extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, modifier, ref, children } = this.props;

    const section = {
      ...(id ? { id } : {}),
      ref: ref,
      className: classNames('section', modifier)
    };

    return React.createElement(
      'div',
      section,
      React.createElement('div', { className: 'section__main' }, children)
    );
  }
}

Section.displayName = 'Section';

Section.defaultProps = {
  id: '',
  modifier: ''
};

export default Section;
