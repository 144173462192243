import React from 'react';
import { includes, values } from 'lodash';
import { parse, stringify } from '~/lib/query-string';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { withRouter } from '~/App/hocs/with-router';
import {
  headingFont,
  heading1,
  heading2,
  preamble
} from '~/App/helpers/mixins';
import {
  calculateScenario,
  calculateDropdownFromScenario
} from './helpers/inheritanceHelpers';
import mediaHelper from '~/App/helpers/media-helper';

// Shared components
import Container from '~/App/shared/components/Container';
import { Divider } from '~/App/shared/components/Blocks';
import { Select } from '~/App/shared/components/Elements/Select';
import HtmlContent from '~/App/shared/components/HtmlContent';
import ShareButtons from '~/App/shared/components/ShareButtons';
import { ArrowSegment } from '~/App/shared/components/ArrowSegment/';

const Heading1 = styled.h1`
  ${heading1};
  text-align: center;
`;

const Heading2 = styled.h2`
  ${heading2};
`;

const Preamble = styled.p`
  ${preamble};
  text-align: center;
  margin-top: 1rem;
`;

const DropdownBody = styled.span`
  ${headingFont};
  font-size: 1.2rem;
  font-weight: bold;
  padding-right: 0.25rem;
  padding-left: 0;
  vertical-align: top;

  ${mq('≥small')`
    padding-left: 0.5rem;
    font-size: 1.4rem;
    line-height: 1.6;
  `};
`;

const DropdownWrapper = styled.div`
  display: flex;

  ${mq('≥small')`
    display: inline-block;
  `};
`;

const InheritanceContentWrapper = styled.div`
  display: block;

  ${mq('≥small')`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  `};
`;

const InheritanceImageWrapper = styled.div`
  flex-basis: 50%;

  display: flex;
  justify-content: center;
  padding-right: 1.7rem;
  padding-left: 1.7rem;

  ${mq('≥small')`
    padding-right: 0;
    padding-left: 0;
  `};
`;

const InheritanceImage = styled.img`
  max-width: 100%;
`;

const InheritanceTextWrapper = styled.div`
  flex-basis: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-right: 2rem;
  padding-left: 2rem;

  ${mq('≥small')`
    padding-right: 3rem;
    padding-left: 0;
  `};
`;

const InheritanceText = styled.p`
  ${headingFont};
  font-size: 1rem;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.themeColors.primary};
  max-width: 100%;
`;

const InheritanceStepHeader = styled.h3`
  ${headingFont};
  font-weight: bold;
  color: ${(props) => props.theme.themeColors.primary};
  font-size: 2.25rem;
`;

const InheritanceInfoImage = styled.div`
  padding-bottom: 1rem;
`;

const SpecialColorArrowSegment = styled(ArrowSegment)`
  background-color: ${(props) => props.colors[props.stepNumber]};

  &::before {
    border-left-color: ${(props) => props.colors[props.stepNumber]};
    border-right-color: ${(props) => props.colors[props.stepNumber]};
  }

  &::after {
    border-top-color: ${(props) => props.colors[props.stepNumber]};
  }

  ${mq('≥small')`
    padding-top: 2rem;
  `};
`;

class InheritanceGenerator extends React.Component {
  constructor(props) {
    super(props);

    this.maritalStatusOptions = {
      married: 1,
      partner: 2,
      single: 3
    };

    this.childrenOptions = {
      hasChildren: 1,
      noChildren: 2
    };

    this.childrenOriginOptions = {
      currentRelationship: 1,
      pastRelationship: 2,
      bothRelationships: 3
    };

    this.scenarios = {
      marriedWithChildrenFromCurrent: 1,
      marriedWithChildrenFromEarlier: 2,
      marriedWithChildrenFromBoth: 3,
      marriedWithNoChildren: 4,
      partnerWithChildrenFromCurrent: 5,
      partnerWithChildrenFromEarlier: 6,
      partnerWithChildrenFromBoth: 7,
      partnerWithNoChildren: 8,
      singleWithChildren: 9,
      singleWithNoChildren: 10
    };

    this.scenariosNested = {
      1: {
        1: {
          1: this.scenarios.marriedWithChildrenFromCurrent,
          2: this.scenarios.marriedWithChildrenFromEarlier,
          3: this.scenarios.marriedWithChildrenFromBoth
        },
        2: {
          1: this.scenarios.marriedWithNoChildren,
          2: this.scenarios.marriedWithNoChildren,
          3: this.scenarios.marriedWithNoChildren
        }
      },
      2: {
        1: {
          1: this.scenarios.partnerWithChildrenFromCurrent,
          2: this.scenarios.partnerWithChildrenFromEarlier,
          3: this.scenarios.partnerWithChildrenFromBoth
        },
        2: {
          1: this.scenarios.partnerWithNoChildren,
          2: this.scenarios.partnerWithNoChildren,
          3: this.scenarios.partnerWithNoChildren
        }
      },
      3: {
        1: {
          1: this.scenarios.singleWithChildren,
          2: this.scenarios.singleWithChildren,
          3: this.scenarios.singleWithChildren
        },
        2: {
          1: this.scenarios.singleWithNoChildren,
          2: this.scenarios.singleWithNoChildren,
          3: this.scenarios.singleWithNoChildren
        }
      }
    };

    this.state = {
      dropdownStatus: {
        maritalStatus: null,
        children: null,
        childrenOrigin: null
      },
      elementStepWidth: 0,
      tempSelectValue: null
    };

    this.inheritanceWrapperElement = null;
    this.setInheritanceWrapperElementRef = (element) => {
      this.inheritanceWrapperElement = element;
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      elementStepWidth: this.inheritanceWrapperElement.offsetWidth
    });

    const { scenario = '1' } = parse(this.props.location.search);

    const options = {
      maritalStatusOptions: this.maritalStatusOptions,
      childrenOptions: this.childrenOptions,
      childrenOriginOptions: this.childrenOriginOptions
    };

    const {
      data: { dropdownOptions }
    } = this.props;

    const newDropdownStatus = calculateDropdownFromScenario(
      scenario,
      options,
      dropdownOptions
    );
    this.setState({ dropdownStatus: newDropdownStatus });
  }

  handleDropdownChange(dropdown) {
    return (option) => {
      let newDropdownStatus = { ...this.state.dropdownStatus };
      newDropdownStatus[dropdown] = option;

      const newScenario = calculateScenario(
        newDropdownStatus,
        this.scenariosNested
      );

      this.setState((prevState) => {
        let newState = { ...prevState };
        newState.dropdownStatus = newDropdownStatus;
        return newState;
      });

      const query = parse(this.props.location.search);
      const newQuery = { ...query, scenario: newScenario };
      const querystring = stringify(newQuery);

      this.props.history.push(`${this.props.location.pathname}?${querystring}`);
    };
  }

  childrenOriginIsVisible(scenarioString) {
    const scenario = parseInt(scenarioString, 10);

    const validScenarios = [
      this.scenarios.marriedWithChildrenFromCurrent,
      this.scenarios.marriedWithChildrenFromEarlier,
      this.scenarios.marriedWithChildrenFromBoth,
      this.scenarios.partnerWithChildrenFromCurrent,
      this.scenarios.partnerWithChildrenFromEarlier,
      this.scenarios.partnerWithChildrenFromBoth
    ];

    return includes(validScenarios, scenario);
  }

  render() {
    const { scenario = '1' } = parse(this.props.location.search);
    const { data } = this.props;

    const maritalStatusDropdown = 'maritalStatus';
    const childrenDropdown = 'children';
    const childrenOriginDropdown = 'childrenOrigin';
    const stepArrowWidth = this.state.elementStepWidth / 2;

    const dropdownOptions = {
      maritalStatus: values(data.dropdownOptions[1]),
      children: values(data.dropdownOptions[2]),
      childrenOrigin: values(data.dropdownOptions[3])
    };

    return (
      <div>
        <div>
          <Container
            width="narrow"
            paddingLeft="small"
            paddingRight="small"
            paddingBottom="small"
            paddingTop="small"
          >
            <Heading1>{data.title}</Heading1>
            <Preamble>{data.preamble}</Preamble>
          </Container>
          <Divider width="narrow" />
          <Container
            width="narrow"
            paddingLeft="small"
            paddingRight="small"
            paddingTop="small"
            paddingBottom="small"
          >
            <Heading2>{data.generatorTitle}</Heading2>
            <p>{data.generatorText}</p>

            <Container width="narrow" paddingTop="small" paddingBottom="small">
              <DropdownWrapper>
                <DropdownBody>Jag är</DropdownBody>
                <Select
                  instanceId="react-select-marital-status"
                  value={this.state.dropdownStatus.maritalStatus}
                  onChange={this.handleDropdownChange(maritalStatusDropdown)}
                  options={dropdownOptions.maritalStatus}
                  isSearchable={false}
                />
              </DropdownWrapper>

              <DropdownWrapper>
                <DropdownBody>och</DropdownBody>
                <Select
                  instanceId="react-select-children"
                  value={this.state.dropdownStatus.children}
                  onChange={this.handleDropdownChange(childrenDropdown)}
                  options={dropdownOptions.children}
                  isSearchable={false}
                />
              </DropdownWrapper>
              {this.childrenOriginIsVisible(scenario) && (
                <DropdownWrapper>
                  <DropdownBody>från</DropdownBody>
                  <Select
                    instanceId="react-select-children-origin"
                    value={this.state.dropdownStatus.childrenOrigin}
                    onChange={this.handleDropdownChange(childrenOriginDropdown)}
                    options={dropdownOptions.childrenOrigin}
                    isSearchable={false}
                  />
                </DropdownWrapper>
              )}
            </Container>
            <div ref={this.setInheritanceWrapperElementRef}>
              {Object.keys(data.scenarios[scenario].steps).map((stepKey) => (
                <SpecialColorArrowSegment
                  firstSegment={stepKey === '1'}
                  lastSegment={
                    stepKey ===
                    Object.keys(
                      data.scenarios[scenario].steps
                    ).length.toString()
                  }
                  key={stepKey}
                  stepNumber={stepKey}
                  colors={data.colors}
                  arrowWidth={stepArrowWidth}
                >
                  <InheritanceContentWrapper>
                    <InheritanceImageWrapper>
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={`${mediaHelper.getUrl(
                            data.scenarios[scenario].steps[stepKey].image,
                            { height: 480 }
                          )} 2x,
                          ${mediaHelper.getUrl(
                            data.scenarios[scenario].steps[stepKey].image,
                            { height: 240 }
                          )} 1x`}
                        />
                        <source
                          srcSet={`${mediaHelper.getUrl(
                            data.scenarios[scenario].steps[stepKey].imageSmall,
                            { height: 312 }
                          )} 2x,
                          ${mediaHelper.getUrl(
                            data.scenarios[scenario].steps[stepKey].imageSmall,
                            { height: 156 }
                          )} 1x`}
                        />
                        <InheritanceImage
                          src={mediaHelper.getUrl(
                            data.scenarios[scenario].steps[stepKey].image,
                            { height: 240 }
                          )}
                        />
                      </picture>
                    </InheritanceImageWrapper>
                    <InheritanceTextWrapper>
                      <InheritanceStepHeader>
                        {`${stepKey}.`}
                      </InheritanceStepHeader>
                      <InheritanceText>
                        {data.scenarios[scenario].steps[stepKey].text}
                      </InheritanceText>
                    </InheritanceTextWrapper>
                  </InheritanceContentWrapper>
                </SpecialColorArrowSegment>
              ))}
            </div>
          </Container>

          {Object.keys(data.scenarios[scenario].informationTexts).map(
            (informationTextKey) => (
              <div key={informationTextKey}>
                <Divider width="narrow" />
                <Container
                  width="narrow"
                  paddingLeft="small"
                  paddingRight="small"
                  paddingTop="small"
                  paddingBottom="small"
                >
                  <InheritanceInfoImage>
                    <picture>
                      <source
                        srcSet={`${mediaHelper.getUrl(
                          data.informationImages[informationTextKey],
                          { width: 200 }
                        )} 2x,
                        ${mediaHelper.getUrl(
                          data.informationImages[informationTextKey],
                          { width: 100 }
                        )} 1x`}
                      />
                      <InheritanceImage
                        src={mediaHelper.getUrl(
                          data.informationImages[informationTextKey],
                          { width: 100 }
                        )}
                      />
                    </picture>
                  </InheritanceInfoImage>
                  <Heading2>
                    {
                      data.scenarios[scenario].informationTexts[
                        informationTextKey
                      ].title
                    }
                  </Heading2>
                  <HtmlContent
                    dangerouslySetInnerHTML={{
                      __html:
                        data.scenarios[scenario].informationTexts[
                          informationTextKey
                        ].text
                    }}
                  />
                </Container>
              </div>
            )
          )}
          <Divider width="narrow" />
          <Container
            width="narrow"
            paddingTop="small"
            paddingBottom="small"
            paddingLeft="small"
            paddingRight="small"
          >
            <ShareButtons
              showFacebook={true}
              showX={true}
              showEmail={true}
              showPrint={true}
            />
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(InheritanceGenerator);
