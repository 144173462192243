// import { mePurchases } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export async function loadData({ cookies }: LoadDataProps) {
  return {
    info: null,
    data: []
  };
  // if (!cookies.secretKey) {
  //   return {
  //     info: null,
  //     data: []
  //   };
  // }

  // const infoTask = mePurchases.purchasesInfo({
  //   secretKey: `${cookies.secretKey}`
  // });

  // const dataTask = mePurchases.index({
  //   params: { page: 1, per_page: 10 },
  //   secretKey: `${cookies.secretKey}`
  // });

  // const [info, data] = await Promise.all([infoTask, dataTask]);

  // return {
  //   info,
  //   data
  // };
}
