import React, { useCallback, useMemo, ReactNode } from 'react';

import {
  LeftColumn,
  Wrapper,
  RightColumn,
  ColumnCard
} from '../components/Forms/components/TwoColumn';

import { Hoc } from './components/Hoc';
import { Form } from './components/Form';

import { Divider } from '../../Blocks';
import { DonationSummary } from '../components/Forms/components/Summaries';
import { RightColumnText } from '../components/Forms/components/RightColumnText';

import AmountSummary from '../MonthlyDonation/MonthlyDonation.AmountSummary';

import { Layout } from '~/types/Layout';
import { MultiPartFormValues } from '../../MultiPartForm';
import { ProductValues } from '../components/States';
import { useQuery } from '~/App/shared/hooks/use-query';
import { IPaymentMethod } from '~/types/IPaymentMethod';
import { CustomerContactType } from '~/App/config/customerContactTypes';

type FormContent = {
  productId: number;
  originPath: string | Record<number, string>;
  redirectPath: string | Record<number, string>;
  anotherDeviceText?: string;
  prices: Record<number, number[]>;
  defaultPrice: Record<number, number>;
  taxReductionInfoLink?: string | undefined;
  layout?: {
    amountSelector?: {
      tinyLayout: Layout;
      smallLayout: Layout;
      largeLayout: Layout;
    };
  };
  defaultCustomerContactType: CustomerContactType;
  defaultPaymentMethod: Record<number, IPaymentMethod>;
  campaignActivityId?: number;
  infoText: string;
  summaryHeading: string;
  infoTexts?: string[];
};

type Query = {
  campaignActivityId?: number;
};

type Props = {
  data: FormContent;
};

export function CombinedDonation({ data: formContent }: Props) {
  const query = useQuery<Query>();
  const defaultState = useMemo(
    () => ({
      product: { id: formContent.productId || 31 },
      productOptions: {
        product: {
          price: '200'
        },
        campaignActivityId:
          query.campaignActivityId || formContent.campaignActivityId,
        pul: true
      },
      paymentMethod: formContent.defaultPaymentMethod[31],
      paymentMethodOptions: {},
      customerContactType: formContent.defaultCustomerContactType,
      partOptions: {}
    }),
    [
      query.campaignActivityId,
      formContent.campaignActivityId,
      formContent.defaultPaymentMethod,
      formContent.defaultCustomerContactType,
      formContent.productId
    ]
  );

  return useMemo(
    () => (
      <Hoc defaultValues={defaultState} formContent={formContent}>
        {(props) => (
          <Wrapper>
            <LeftColumn>
              <Form {...props} />
            </LeftColumn>
            <RightColumn>
              <ColumnCard>
                <OnceSummery {...props} />
                <MonthlySummery {...props} />
              </ColumnCard>
            </RightColumn>
          </Wrapper>
        )}
      </Hoc>
    ),
    [defaultState, formContent]
  );
}

type OnceSummeryProps = {
  values: MultiPartFormValues & ProductValues;
  formContent: FormContent;
};

function OnceSummery({ values, formContent }: OnceSummeryProps) {
  return values.product.id === 3 ? (
    <DonationSummary
      part={values.partOptions.currentPart}
      area="aside"
      price={values.productOptions.product.price}
      infoText={formContent.infoText}
      heading={formContent.summaryHeading}
    />
  ) : null;
}

type IfProps = {
  show: boolean;
  children: () => ReactNode;
};

function If({ show, children }: IfProps) {
  return useMemo(() => <>{show ? children() : null}</>, [children, show]);
}

type MonthlySummeryProps = {
  values: ProductValues;
  formContent: FormContent;
};

function MonthlySummery({ values, formContent }: MonthlySummeryProps) {
  const renderRightColumnText = useCallback(
    (infoText, key) => (
      <RightColumnText
        icon={infoText.icon}
        iconColor={infoText.iconColor}
        key={key}
        children={infoText.text}
      />
    ),
    []
  );

  if (values.product.id !== 31) {
    return null;
  }

  return (
    <>
      <AmountSummary price={values.productOptions.product.price} />
      <If
        show={!!formContent.infoTexts}
        children={() => (
          <>
            <Divider
              width="narrow"
              paddingTop="tiny"
              paddingLeft="none"
              paddingRight="none"
              height="2px"
              alternativeColor={true}
            />
            {formContent.infoTexts?.map(renderRightColumnText)}
          </>
        )}
      />
    </>
  );
}
