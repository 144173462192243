import React from 'react';
import styled from 'styled-components';

// Shared components
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { VartGarPengarna } from '~/App/shared/components/Blocks/components/Thanks/components/shared/VartGarPengarna';

// Local components
import { DonationThanksHeader } from './DonationThanks.Header';
import { DonationThanksMonthlyDonation } from './DonationThanks.MonthlyDonation';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

export default function DonationThanks({ purchase, data }) {
  const isLargeDonation = purchase.purchase.amount >= 10000;

  return (
    <div>
      <Background backgroundColor="primary">
        <DonationThanksHeader
          data={data}
          purchase={purchase}
          isLargeDonation={isLargeDonation}
        />
        {isLargeDonation ? null : <DonationThanksMonthlyDonation />}
        <VartGarPengarna />
      </Background>
    </div>
  );
}
