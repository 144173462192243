import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

// Helpers
import { warningCircle, errorCircle, close } from '~/App/helpers/icons';
import { IGlobalMessage, SeverityLevel } from '~/types/IGlobalMessage';

type WrapperProps = {
  severityLevel: SeverityLevel;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  padding: 2.5rem 2.25rem 2.5rem 4.5rem;
  background-color: ${(props) => {
    switch (props.severityLevel) {
      case 'error':
        return props.theme.colors.errorRedMedium;
      case 'warning':
        return props.theme.colors.blanchedAlmond;
      case 'information':
        return props.theme.colors.iceberg;
      case 'success':
        return props.theme.colors.skeptic;
      default:
        return props.theme.colors.blanchedAlmond;
    }
  }};
  color: ${(props) => {
    switch (props.severityLevel) {
      case 'error':
        return props.theme.colors.errorRedDark;
      case 'warning':
        return props.theme.colors.gambogeOrange;
      case 'information':
        return props.theme.colors.deepSeaGreen;
      case 'success':
        return props.theme.colors.crusoe;
      default:
        return props.theme.colors.gambogeOrange;
    }
  }};

  &::before {
    ${warningCircle};
    display: ${(props) =>
      props.severityLevel === 'warning' ? 'block' : 'none'};
    width: 1.875rem;
    height: 1.875rem;
    position: absolute;
    top: 2.25rem;
    left: 2rem;
    font-size: 1.875rem;
    line-height: 1.875rem;
    text-align: center;
  }

  &::after {
    ${errorCircle};
    display: ${(props) => (props.severityLevel === 'error' ? 'block' : 'none')};
    width: 1.875rem;
    height: 1.875rem;
    position: absolute;
    top: 2.25rem;
    left: 2rem;
    font-size: 1.75rem;
    line-height: 1.875rem;
    text-align: center;
  }

  p {
    font-size: 1.125em;
    line-height: 1.5;
    text-align: left;
    margin: 0;
  }
`;

const CloseButton = styled.button`
  position: relative;
  color: inherit;
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 1.375rem;
  margin-left: 2rem;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  border: 0 none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &::before {
    ${close};
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1.375rem;
    height: 1.375rem;
    font-size: 1.875rem;
    line-height: 1.375rem;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    text-align: center;
  }

  span {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

type Props = {
  globalMessage: IGlobalMessage;
};

export function Message({ globalMessage }: Props) {
  const [show, setShow] = useState(true);

  const handleClose = useCallback((e) => {
    e.preventDefault();
    setShow(false);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Wrapper severityLevel={globalMessage.severityLevel}>
      <p>{globalMessage.message}</p>
      <CloseButton onClick={handleClose}>
        <span>Stäng meddelandet</span>
      </CloseButton>
    </Wrapper>
  );
}
