import React, { useMemo } from 'react';
import ReactSelect, { Props } from 'react-select';
import { useTheme } from 'styled-components';

// Shared components
import { Option, SingleValue, DropdownIndicator, SelectContainer } from '.';
import {
  ControlStyle,
  IndicatorSeparatorStyle,
  MenuStyle,
  ValueContainerStyle,
  IndicatorsContainerStyle
} from './Styles';

type Data = {
  value: string | number;
  label: string;
  subLabel?: string;
};

export function Select(props: Props<Data>) {
  const theme = useTheme();
  const components = useMemo(
    () =>
      Object.assign(
        {},
        {
          Option,
          SingleValue,
          DropdownIndicator,
          SelectContainer
        },
        props.components
      ),
    [props.components]
  );

  const styles = useMemo(
    () => ({
      control: ControlStyle(theme),
      indicatorSeparator: IndicatorSeparatorStyle(),
      menu: MenuStyle(),
      valueContainer: ValueContainerStyle(),
      indicatorsContainer: IndicatorsContainerStyle()
    }),
    [theme]
  );

  return <ReactSelect {...props} components={components} styles={styles} />;
}
