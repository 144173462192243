import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ButtonStyle } from '~/App/config/buttonStyles';

import { PrimaryButton } from '~/App/shared/components/Elements';
import { mq } from '~/lib/mq';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  
  ${mq('<small')`
    padding: 0;
  `};
`;

const Border = styled.div`
  background-color: ${({ theme }) => theme.colors.seashell};
  height: 1px;
  width: 100%;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 2rem auto;
  
  ${mq('<small')`
    margin: 1rem auto;
  `};
`;

type CloseButtonOptions = {
  text?: string;
  style?: ButtonStyle;
  border?: boolean;
};

type Props = {
  options: boolean | CloseButtonOptions;
  onClick: () => void;
};

export function CloseButton({ options, onClick }: Props) {
  const renderButton = useCallback(
    (options: CloseButtonOptions) => (
      <Wrapper>
        {options.border && <Border />}
        <StyledPrimaryButton
          onClick={onClick}
          buttonStyle={options.style}
          children={options.text}
        />
      </Wrapper>
    ),
    [onClick]
  );

  if (typeof options === 'boolean') {
    return options ? renderButton({ text: 'Stäng', border: false }) : null;
  }

  return renderButton(options);
}
