import React from 'react';
import styled from 'styled-components';

import { Spinner } from '~/App/shared/components/Elements';

const Wrapper = styled.div`
  height: 100vh;
  padding-top: 35vh;
`;

const Content = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Text = styled.p`
  margin-top: 1rem;
  font-size: 0.75rem;
`;

export function Loading() {
  return (
    <Wrapper>
      <Content>
        <Spinner />
        <Text>Laddar formulär</Text>
      </Content>
    </Wrapper>
  );
}
