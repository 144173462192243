import React, { useMemo } from 'react';
import Research from '~/App/views/Research';

// Shared components
import {
  InheritanceGenerator,
  MonthlyDonationForm,
  MonthlyDonationHero,
  IncreaseMonthlyAutogiroDonationForm,
  IncreaseMonthlyKlarnaDonationForm,
  DonationForm,
  MemorialGiftForm,
  ChristmasDonationForm,
  ChristmasCompanyPackageForm,
  CompanyPackageForm,
  LotteryForm,
  CancerlinjenForm,
  CombinedDonationForm,
  GenericCertificateForm,
  CreateCollectionForm,
  CompanyDonationForm
} from './components';

export default function DataBlock({ component, data, backgroundColor }) {
  return useMemo(
    () => (
      <div>
        {component === 'inheritanceGenerator' && (
          <InheritanceGenerator data={data} />
        )}
        {component === 'monthlyDonationForm' && (
          <MonthlyDonationForm component={component} data={data} />
        )}
        {component === 'monthlyDonationHero' && (
          <MonthlyDonationHero data={data} />
        )}
        {component === 'increaseMonthlyAutogiroDonationForm' && (
          <IncreaseMonthlyAutogiroDonationForm data={data} />
        )}
        {component === 'increaseMonthlyKlarnaDonationForm' && (
          <IncreaseMonthlyKlarnaDonationForm data={data} />
        )}
        {component === 'donationForm' && <DonationForm data={data} />}
        {component === 'memorialGiftForm' && (
          <MemorialGiftForm component={component} data={data} />
        )}
        {component === 'christmasDonationForm' && (
          <ChristmasDonationForm data={data} />
        )}
        {component === 'christmasCompanyPackageForm' && (
          <ChristmasCompanyPackageForm data={data} />
        )}
        {component === 'companyDonationForm' && (
          <CompanyDonationForm data={data} backgroundColor={backgroundColor} />
        )}
        {/* CompanyPackageForm is used for PinkCompanyPackage as well */}
        {component === 'CompanyPackageForm' && (
          <CompanyPackageForm data={data} backgroundColor={backgroundColor} />
        )}
        {component === 'lotteryForm' && <LotteryForm data={data} />}
        {component === 'cancerlinjenForm' && <CancerlinjenForm data={data} />}
        {component === 'genericCertificateForm' && (
          <GenericCertificateForm data={data} />
        )}
        {component === 'createCollectionForm' && (
          <CreateCollectionForm data={data} />
        )}
        {component === 'combinedDonationForm' && (
          <CombinedDonationForm data={data} />
        )}
        {component === 'researchSearchBlock' && <Research />}
      </div>
    ),
    [component, data, backgroundColor]
  );
}
