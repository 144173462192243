
import React from 'react';
import styled from 'styled-components';

const NoOptionsMessageContainer = styled.div`
  padding: 8px 12px;
  color: #a8a8a8;
  text-align: left;
`;

export const NoOptionsMessage = () => {
  return <NoOptionsMessageContainer>Inga resultat</NoOptionsMessageContainer>;
};