import React from 'react';
import styled from 'styled-components';

import { DraggableModal } from '~/App/shared/components/Elements/Modal';
import LargeShareButtons from '~/App/shared/components/LargeShareButtons';
import { closeLink, heading1 } from '~/App/helpers/mixins';

import { siteUrl } from '~/App/helpers/env';
import { ICollectionActivity } from '~/types/ICollectionActivity';
import { mq } from '~/lib/mq';

const Padding = styled.div`
  padding: 1.5rem;
  text-align: center;
`;

const Heading = styled.h1`
  ${heading1}
  margin: 2rem 0 1rem;
  color: #001489;
`;

const Text = styled.p`
  margin: 1rem 0 2rem;
  color: #4a4a4a;
`;

const CloseButton = styled.a`
  ${closeLink}
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: ${(props) => props.theme.colors.darkBlue};
  padding-right: 2.5rem;

  ${mq('<medium')`
    position: relative;
    right: 0;
    top: 0;
    display: block;
    padding: 0;
    margin: 0;
    text-align: center;
  `};
`;

type Props = {
  closeModal: () => void;
  memory: ICollectionActivity;
};

export function ShareModal({ closeModal, memory }: Props) {
  const emailOrMore = {
    subject: `Jag vill dela ett minne av ${memory.collection.name} med dig.`,
    title: `Jag vill dela ett minne av ${memory.collection.name} med dig.`,
    body: `Hej, Jag vill dela ett minne av ${
      memory.collection.name
    } med dig. Läs det på minnessidan hos Cancerfonden här: ${siteUrl(
      `/stod-oss/minnessidor/${memory.collection.slug}?memoryId=${memory.publicToken}`
    )}`
  };

  const x = {
    text: `Jag vill dela ett minne av ${
      memory.collection.name
    } - läs det på minnessidan hos Cancerfonden: ${siteUrl(
      `/stod-oss/minnessidor/${memory.collection.slug}?memoryId=${memory.publicToken}`
    )}`
  };

  const facebook = {
    text: `Jag vill deta ett minne av ${memory.collection.name}. Läs det på minnessidan hos Cancerfonden.`
  };

  return (
    <DraggableModal memory={memory} closeModal={closeModal}>
      <Padding>
        <Heading>Dela minnet</Heading>
        <Text>Låt fler ta del av minnet av {memory.collection.name}.</Text>
        <LargeShareButtons
          to={siteUrl(
            `/stod-oss/minnessidor/${memory.collection.slug}?memoryId=${memory.publicToken}`
          )}
          email={emailOrMore}
          facebook={facebook}
          x={x}
          copyLink={true}
        />
      </Padding>
      <CloseButton onClick={closeModal}>Stäng</CloseButton>
    </DraggableModal>
  );
}
