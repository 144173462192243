import { createContext } from 'react';

import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';

export type ShoppingCartContextValue = {
  items: ICommerceOrderLine[];
  isOpen: boolean;
  isEmpty: boolean;
  isPending: boolean;
  shippingPrice: number;
  totalPrice: number;

  add: (item: ICommerceOrderLine) => Promise<void>;
  remove: (orderLine: ICommerceOrderLine) => Promise<void>;
  update: (item: ICommerceOrderLine) => Promise<void>;
  refresh: () => Promise<void>;

  open: () => void;
  close: () => void;
  finish: () => void;
};

export const ShoppingCartContext = createContext<ShoppingCartContextValue>({
  items: [],
  isOpen: false,
  isEmpty: true,
  isPending: false,
  shippingPrice: 0,
  totalPrice: 0,

  add: (_: ICommerceOrderLine) => Promise.resolve(),
  remove: (_orderLine: ICommerceOrderLine) => Promise.resolve(),
  update: (_item: ICommerceOrderLine) => Promise.resolve(),
  refresh: () => Promise.resolve(),

  open: () => null,
  close: () => null,
  finish: () => null
});
