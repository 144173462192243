import { ChangeEvent, useCallback, useMemo, useState } from 'react';

type ReceiverContactType = 'private' | 'company';

export type GenericValues = {
  productOptions?: {
    pul: boolean;
    motherSender?: string;
    motherNumber?: string;
    motherText?: string;
    receiverContactType: ReceiverContactType;
  };
  socialName?: string;
  handlers: {
    handleReceiverContactType: (event: ChangeEvent<HTMLInputElement>) => void;
    handleProductOptionsPul: (event: ChangeEvent<HTMLInputElement>) => void;
    handleProductOptionsMotherNumber: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleProductOptionsMotherSender: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleProductOptionsMotherText: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleSocialName: (event: ChangeEvent<HTMLInputElement>) => void;
  };
};

type InputValues = {
  socialName?: string;
  productOptions?: {
    pul?: boolean;
    receiverContactType?: ReceiverContactType;
    motherSender?: string;
    motherNumber?: string;
    motherText?: string;
  } & Record<string, unknown>;
  handlers?: Record<string, unknown>;
};

export function useGenericValues<T extends InputValues>(values: T) {
  const [pul, setPul] = useState(values?.productOptions?.pul || false);
  const [motherSender, setMotherSender] = useState(
    values?.productOptions?.motherSender || ''
  );
  const [motherNumber, setMotherNumber] = useState(
    values?.productOptions?.motherNumber || ''
  );
  const [motherText, setMotherText] = useState(
    values?.productOptions?.motherText || ''
  );
  const [
    receiverContactType,
    setReceiverContactType
  ] = useState<ReceiverContactType>(
    values?.productOptions?.receiverContactType || 'private'
  );
  const [socialName, setSocialName] = useState(values?.socialName || '');

  const handleReceiverContactType = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value === 'private' || value === 'company') {
        setReceiverContactType(value);
      }
    },
    []
  );

  const handleProductOptionsPul = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPul(event.target.checked);
    },
    []
  );

  const handleProductOptionsMotherSender = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMotherSender(event.target.value);
    },
    []
  );

  const handleProductOptionsMotherNumber = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMotherNumber(event.target.value?.replace(/^\+46/, '0'));
    },
    []
  );

  const handleProductOptionsMotherText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/gi;

      const filteredMessage = value?.replace(emojiRegex, '');

      setMotherText(filteredMessage.slice(0, 100));
    },
    []
  );

  const handleSocialName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSocialName(event.target.value);
    },
    []
  );

  return useMemo<GenericValues & T>(
    () => ({
      ...values,
      productOptions: {
        ...values?.productOptions,
        pul,
        motherNumber,
        motherSender,
        motherText,
        receiverContactType
      },
      socialName,
      handlers: {
        ...values?.handlers,
        handleReceiverContactType,
        handleProductOptionsPul,
        handleProductOptionsMotherNumber,
        handleProductOptionsMotherSender,
        handleProductOptionsMotherText,
        handleSocialName
      }
    }),
    [
      handleProductOptionsMotherNumber,
      handleProductOptionsMotherSender,
      handleProductOptionsMotherText,
      handleProductOptionsPul,
      handleReceiverContactType,
      handleSocialName,
      motherNumber,
      motherSender,
      motherText,
      pul,
      receiverContactType,
      socialName,
      values
    ]
  );
}
