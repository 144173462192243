import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import { link, visuallyHidden } from '~/App/helpers/mixins';

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
  min-width: 20px;
`;

const Link = styled.div`
  ${link};
`;

const PaginationNumber = styled.div`
  color: ${({ theme }) => theme.themeColors.primary};
  font-size: 1.125rem;
  text-align: center;
`;

type PaginationUnderlineProps = {
  active: boolean
}

const PaginationUnderline = styled.div<PaginationUnderlineProps>`
  width: 100%;
  height: 3px;
  background-color: ${({ theme, active }) =>
    active ? theme.themeColors.primary : 'none'};
`;

const VisuallyHidden = styled.span`
  ${visuallyHidden}
`;

type Props = {
  page: number
  currentPage: number
  controls: string
  navigateTo: (page: number) => void
}

export function Page({ page, currentPage, controls, navigateTo }: Props) {
  const isActive = currentPage === page;

  const handleClick = useCallback(() => navigateTo(page), [navigateTo, page]);

  const children = useMemo(() => {
    if (isActive) {
      return (
        <React.Fragment>
          <VisuallyHidden>sida </VisuallyHidden>
          {page}
        </React.Fragment>
      );
    }

    return (
      <Link onClick={handleClick} aria-controls={controls}>
        <VisuallyHidden>sida </VisuallyHidden>
        {page}
      </Link>
    );
  }, [controls, handleClick, isActive, page]);

  return (
    <Wrapper aria-current={isActive && 'page'}>
      <PaginationNumber aria-disabled={isActive && 'true'}>
        {children}
      </PaginationNumber>
      <PaginationUnderline active={isActive}></PaginationUnderline>
    </Wrapper>
  );
}
