module.exports = {
  breakpoints: {
    micro: '360px',
    tiny: '480px',
    tinySmall: '624px',
    small: '768px',
    smallMedium: '880px',
    medium: '992px',
    mediumLarge: '1024px',
    large: '1200px'
  }
};
