import React from 'react';
import { useLocation } from "react-router";
import styled from "styled-components";

import Link from '~/App/shared/components/Link';

const Wrapper = styled.p`
  margin-bottom: 1rem;
`;

type Props = {
  link?: string
}

export function TaxReductionInfo({ link }: Props) {
  const { pathname } = useLocation();

  return (
    <Wrapper>
      {link ? (
        <>
          För{' '}
          <Link to={`${pathname}${link}`}>
            skatteavdrag
          </Link>{' '}
          på din gåva är personnummer obligatoriskt.
        </>
      ) : (
        <>För skatteavdrag på din gåva är personnummer obligatoriskt.</>
      )}
    </Wrapper>
  );
}