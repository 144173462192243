import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { supporters } from '~/App/helpers/http';
import { angleUp, angleDown } from '~/App/helpers/icons';

// Shared components
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const Wrapper = styled.div``;

const Header = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.primary};
`;

const AngleUp = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.white};

  &::after {
    ${angleUp};
    font-size: 1.5rem;
    line-height: 1.375rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.themeColors.primary};
    text-align: center;

    ${mq('≥small')`
      font-size: 2rem;
      line-height: 1.625rem;
      width: 1.875rem;
      height: 1.875rem;
    `};
  }

  &:hover,
  &:focus {
    &::after {
      background-color: ${({ theme }) => theme.themeColors.primaryHover};
    }
  }
`;

const AngleDown = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.white};

  &::after {
    ${angleDown};
    font-size: 1.5rem;
    line-height: 1.5rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.themeColors.primary};
    text-align: center;

    ${mq('≥small')`
      font-size: 2rem;
      line-height: 1.875rem;
      width: 1.875rem;
      height: 1.875rem;
    `};
  }

  &:hover,
  &:focus {
    &::after {
      background-color: ${({ theme }) => theme.themeColors.primaryHover};
    }
  }
`;

const Heading = styled.div`
  position: relative;
  text-align: left;
  color: ${({ theme }) => theme.themeColors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }

  ${mq('≥small')`
    font-size: 1.25rem;
  `};
`;

const List = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const Supporter = styled.div`
  margin-bottom: 0.5rem;
  overflow: hidden;

  ${mq('≥small')`
    display: inline-block;
    width: 25%;
  `};
`;

const Empty = styled.div`
  margin-bottom: 1.5rem;
`;

export default class Supporters extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.state = { open: false, status: 'idle', supporters: [] };
  }

  async componentDidMount() {
    const { status } = this.state;
    const { sort, level, endDate, perPage, withImage } = this.props;

    if (status !== 'cached' && status !== 'fetching') {
      try {
        this.setState({ status: 'fetching' });

        const { data } = await supporters.index({
          params: {
            sort: sort,
            level: level,
            end_date: endDate,
            per_page: perPage,
            with_image: withImage
          }
        });

        this.setState({ status: 'cached', supporters: data });
        this.setState({ open: true });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async handleClick(event) {
    event.preventDefault();

    this.setState((state) => ({ open: !state.open }));
  }

  render() {
    const { open, supporters } = this.state;
    const {
      width = '',
      padding = {},
      heading = '',
      backgroundColor = 'none'
    } = this.props;

    return (
      <BlockBackground backgroundColor={backgroundColor}>
        <Container
          width={width}
          paddingTop={padding.top}
          paddingLeft="small"
          paddingRight="small"
          paddingBottom={padding.bottom}
        >
          <Wrapper>
            <Header onClick={this.handleClick}>
              <Heading>
                {heading}
                {this.state.open ? <AngleUp /> : <AngleDown />}
              </Heading>
            </Header>
            {open && supporters.length > 0 ? (
              <List>
                {this.state.supporters.map((supporter) => (
                  <Supporter key={supporter.id}>{supporter.name}</Supporter>
                ))}
              </List>
            ) : (
              <Empty />
            )}
          </Wrapper>
        </Container>
      </BlockBackground>
    );
  }
}
