import React, { ComponentProps, useCallback } from 'react';

import { useDragToDismiss } from '~/App/shared/hooks/use-drag-to-dismiss';

import { Modal } from './Modal';

type Props = ComponentProps<typeof Modal>;

export function DraggableModal(props: Props) {
  const handleDismiss = useCallback(() => {
    props.closeModal && props.closeModal();
  }, [props]);

  const { element } = useDragToDismiss({
    onDismiss: handleDismiss,
    isActive: true
  });

  return <Modal ref={element} isDraggable {...props} />;
}
