import React, { useCallback } from 'react';

import {
  Wrapper,
  Item
} from '~/App/shared/components/Fields/RadioButtonHorizontal/';

import { ContactCustomerValues } from '../../../../States';

import { Layout } from '~/types/Layout';
import { CustomerContactType } from '~/App/config/customerContactTypes';

type Props = {
  showAutomatic: boolean;
  showManual: boolean;
  showCompany: boolean;
  values: ContactCustomerValues;
  layoutWidth: Layout;
};

export function RadioButtons({
  showAutomatic,
  showManual,
  showCompany,
  values,
  layoutWidth
}: Props) {
  const handleChange = useCallback(
    (type: CustomerContactType) => () => {
      values.handlers.handleCustomerContactType(type);
    },
    [values.handlers]
  );

  return (
    <Wrapper>
      {showAutomatic && (
        <Item
          value="automatic"
          id="contactType-automatic"
          checked={values.customerContactType === 'automatic'}
          onChange={handleChange('automatic')}
          label="Personnummer"
          tinyLayout="100"
          smallLayout={layoutWidth}
          largeLayout={layoutWidth}
        />
      )}
      {showManual && (
        <Item
          value="manual"
          id="contactType-manual"
          checked={values.customerContactType === 'manual'}
          onChange={handleChange('manual')}
          label="Fyll i själv"
          tinyLayout="100"
          smallLayout={layoutWidth}
          largeLayout={layoutWidth}
        />
      )}
      {showCompany && (
        <Item
          value="company"
          id="contactType-company"
          checked={values.customerContactType === 'company'}
          onChange={handleChange('company')}
          label="Företag"
          tinyLayout="100"
          smallLayout={layoutWidth}
          largeLayout={layoutWidth}
        />
      )}
    </Wrapper>
  );
}
