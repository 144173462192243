import { GtmDetails } from '../helpers/gtm-helper';

export const gtmDetails: Record<number, GtmDetails> = {
  1: {
    name: 'Minnesgåva',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat'
  },
  3: {
    name: 'Spontangåva',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat'
  },
  4: {
    name: 'Rikslott',
    brand: 'Cancerfonden',
    category: 'Specialprodukter'
  },
  6: {
    name: 'Gåvobevis',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat'
  },
  7: {
    name: 'Gratulationsgåva',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat'
  },
  8: {
    name: 'Stödmedlem',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat'
  },
  9: {
    name: 'Företagsvän',
    brand: 'Cancerfonden',
    category: 'Insamling företag',
    variant: 'Grund'
  },
  10: {
    name: 'Företagsvän',
    brand: 'Cancerfonden',
    category: 'Insamling företag',
    variant: 'Lilla'
  },
  11: {
    name: 'Företagsvän',
    brand: 'Cancerfonden',
    category: 'Insamling företag',
    variant: 'Mellan'
  },
  12: {
    name: 'Företagsvän',
    brand: 'Cancerfonden',
    category: 'Insamling företag',
    variant: 'Stora'
  },
  14: {
    name: 'Företagsvän rosa',
    brand: 'Cancerfonden',
    category: 'Kampanj - Företag',
    variant: 'Lilla'
  },
  15: {
    name: 'Företagsvän rosa',
    brand: 'Cancerfonden',
    category: 'Kampanj - Företag',
    variant: 'Mellan'
  },
  16: {
    name: 'Företagsvän rosa',
    brand: 'Cancerfonden',
    category: 'Kampanj - Företag',
    variant: 'Stora'
  },
  18: {
    name: 'Företagsvän Jul',
    brand: 'Cancerfonden',
    category: 'Kampanj - Företag',
    variant: 'Lilla'
  },
  19: {
    name: 'Företagsvän Jul',
    brand: 'Cancerfonden',
    category: 'Kampanj - Företag',
    variant: 'Mellan'
  },
  20: {
    name: 'Företagsvän Jul',
    brand: 'Cancerfonden',
    category: 'Kampanj - Företag',
    variant: 'Stora'
  },
  22: {
    name: 'Jullott - Företag',
    brand: 'Cancerfonden',
    category: 'Kampanj - Företag'
  },
  23: {
    name: 'Jullott',
    brand: 'Cancerfonden',
    category: 'Kampanj - Privat'
  },
  25: {
    name: 'Julgåva',
    brand: 'Cancerfonden',
    category: 'Kampanj - Privat'
  },
  26: {
    name: 'Julgåva - Företag',
    brand: 'Cancerfonden',
    category: 'Kampanj - Företag'
  },
  27: {
    name: 'Månadsgåva',
    brand: 'Cancerfonden',
    category: 'Regelbundna gåvor'
  },
  31: {
    name: 'Månadsgåva',
    brand: 'Cancerfonden',
    category: 'Regelbundna gåvor'
  },
  3101: {
    name: 'Höjt månadsbelopp',
    brand: 'Cancerfonden',
    category: 'Regelbundna gåvor'
  },
  32: {
    name: 'Månadsgåva',
    brand: 'Cancerfonden',
    category: 'Regelbundna gåvor'
  },
  33: {
    name: 'Forskningstid',
    brand: 'Cancerfonden',
    category: 'Kampanj - Privat'
  },
  34: {
    name: 'Gåvobevis',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat',
    variant: 'Mors dag'
  },
  35: {
    name: 'Gåvoshop',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat'
  },
  36: {
    name: 'Gåvoshop',
    brand: 'Cancerfonden',
    category: 'Engångsgåva företag'
  },
  41: {
    name: 'Spontangåva - Företag',
    brand: 'Cancerfonden',
    category: 'Insamling företag'
  }
};

export const gtmCustomDetails: Record<string, GtmDetails> = {
  '3a': {
    name: 'Webbinsamling',
    brand: 'Cancerfonden',
    category: 'Insamling'
  },
  '3b': {
    name: 'Spontangåva - Företag',
    brand: 'Cancerfonden',
    category: 'Insamling företag'
  },
  '3c': {
    name: 'Swishblock',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat'
  },
  '25a': {
    name: 'Swishgåvobevis',
    brand: 'Cancerfonden',
    category: 'Engångsgåva privat'
  }
};

export const gtmFormName: Record<string, string> = {
  monthlyDonationForm: 'Formulär: Månadsgivare',
  memorialGiftForm: 'Formulär: Minnesgåva',
  combinedDonationForm: 'Formulär: Kombinerad engångsgåva / månadsgivare'
};

/*
  Custom details:
  3a: CollectionDonation
  3b: CompanyDonation
  25a: SwishCertificateGift
*/
