import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const Content = styled.div`
  padding: 0 0.75em 1.25em;
  margin-bottom: 1rem;
  background: ${({ theme }) => theme.colors.grayAlabaster};
  border: 1px solid ${({ theme }) => theme.colors.gallery};
  border-radius: 0 0.25em 0.25em 0.25em;

  &:first-child {
    border-radius: 0.25em;
  }

  ${mq('≥small')`
    padding: 0 1em 1.25em;
  `};
`;
