import React, { useMemo } from 'react';
import { BlockHero } from '~/App/shared/components/Blocks';
import { ICommerceProduct } from '~/types/ICommerceProduct';

type Props = {
  product: ICommerceProduct;
};

export function Hero({ product }: Props) {
  const image = useMemo(
    () => ({
      style: 'fixed',
      size: 'small',
      source: {
        secure_url:
          'https://res.cloudinary.com/cancerfonden/image/upload/v1606902040/hero-images/content-components-hero-small-desktop-accent.png',
        url: '',
        format: 'png'
      }
    }),
    []
  );

  return (
    <BlockHero
      showLightTextColor={true}
      heading={product.name}
      headingSettings={{ fontSize: 'heading1', textAlign: 'center' }}
      imageAlt=""
      large={image}
      small={image}
    />
  );
}
