import React from 'react';
import styled from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';

// shared
import { Navigation } from './Hero.Navigation';

import { ICollection } from '~/types/ICollection';

type ImageProps = {
  mobileImage: string;
  tabletImage: string;
  desktopImage: string;
};

const mobileConfig = {
  width: 768,
  quality: 70,
  gravity: 'north_east'
};

const tabletConfig = {
  width: 1024,
  quality: 80,
  gravity: 'north'
};

const desktopConfig = {
  width: 514,
  quality: 80,
  gravity: 'north_east'
};

const Image = styled.div<ImageProps>`
  height: 150px;
  flex-shrink: 0;
  width: 100%;
  background-size: cover;
  position: relative;
  background-position: bottom center;
  background-image: url(${({ mobileImage }) =>
    mediaHelper.getUrl(mobileImage, mobileConfig)});

  ${mq<ImageProps>('≥small')`
    height: 30%;
    background-image: url(${({ tabletImage }) =>
      mediaHelper.getUrl(tabletImage, tabletConfig)}); `};

  ${mq<ImageProps>('≥mediumLarge')`
    background-image: url(${({ desktopImage }) =>
      mediaHelper.getUrl(desktopImage, desktopConfig)});`};
`;

type Props = {
  collection?: ICollection;
  variant?: HeroVariant;
};

export type HeroVariant = 'private' | 'researcher' | 'newsletter';

export function Hero({ collection, variant }: Props) {
  if (variant === 'private') {
    return (
      <Image
        mobileImage="https://res.cloudinary.com/cancerfonden/image/upload/v1589461574/assets/login/login-hero-mobile.jpg"
        tabletImage="https://res.cloudinary.com/cancerfonden/image/upload/v1589461969/assets/login/login-hero-tablet.jpg"
        desktopImage="https://res.cloudinary.com/cancerfonden/image/upload/v1589462249/assets/login/login-hero-desktop.jpg"
      >
        <Navigation alternateColor={false} />
      </Image>
    );
  }

  if (variant === 'researcher') {
    return (
      <Image
        mobileImage="https://res.cloudinary.com/cancerfonden/image/upload/v1591108844/assets/login/login-hero-researcher-mobile.jpg"
        tabletImage="https://res.cloudinary.com/cancerfonden/image/upload/v1591109232/assets/login/login-hero-researcher-tablet.jpg"
        desktopImage="https://res.cloudinary.com/cancerfonden/image/upload/v1591109422/assets/login/login-hero-researcher-desktop.jpg"
      >
        <Navigation alternateColor={true} />
      </Image>
    );
  }

  if (collection?.imageUrl) {
    return (
      <Image
        mobileImage={collection.imageUrl}
        tabletImage={collection.imageUrl}
        desktopImage={collection.imageUrl}
      />
    );
  }

  if (variant === 'newsletter') {
    return (
      <Image
        mobileImage={
          'https://res.cloudinary.com/cancerfonden/image/upload/v1618496093/assets/login/login-hero-newsletter.png'
        }
        tabletImage={
          'https://res.cloudinary.com/cancerfonden/image/upload/v1618496093/assets/login/login-hero-newsletter.png'
        }
        desktopImage={
          'https://res.cloudinary.com/cancerfonden/image/upload/v1618496093/assets/login/login-hero-newsletter.png'
        }
      />
    );
  }

  return null;
}
