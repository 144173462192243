import React, { useEffect, useMemo } from 'react';

import { IValidation } from '~/App/shared/components/Validation';
import { CommerceCheckoutState } from '../hooks/useCommerceCheckoutState';
import {
  Section,
  useMultiPartFormState
} from '~/App/shared/components/MultiPartForm';
import { ContactFields } from '~/App/shared/components/Donation/components/Forms/components/ContactFields';
import { SubmitButton } from '~/App/shared/components/Donation/components/Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '~/App/shared/components/Donation/components/Forms/components/SwishOtherDeviceButton';
import { Payment } from './Payment';
import { CommerceCheckoutSubmit } from '../hooks/useCommerceCheckoutSubmit';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { pushEecEvent, eecProduct } from '~/App/helpers/gtm-helper';
import { AlertMessageContainer } from '~/App/shared/components/Elements';
import { getPurchaseErrorMessage } from '~/App/shared/components/Donation/components/Forms/helpers';
import { IntegrityTextArea } from '~/App/shared/components/Donation/components/Forms/components/IntegrityTextArea';
import styled from 'styled-components';
import { buildValidationKeys } from '../validationKeys';

const IntegrityTextAreaWrapper = styled.div`
  margin: 0.5rem 0;
`;

type Props = {
  state: CommerceCheckoutState;
  submit: CommerceCheckoutSubmit;
  validation: IValidation;
  isDisabled: boolean;
};

export function Form({ state, submit, validation, isDisabled }: Props) {
  const shoppingCart = useShoppingCart();
  const onlyDigitalProducts = useMemo(() => {
    for (const item of shoppingCart.items) {
      if (item.variant?.product?.type !== 'DigitalCertificate') {
        return false;
      }
    }

    return true;
  }, [shoppingCart.items]);
  const hasDigitalProducts = useMemo(() => {
    for (const item of shoppingCart.items) {
      if (item.variant?.product?.type === 'DigitalCertificate') {
        return true;
      }
    }

    return false;
  }, [shoppingCart.items]);

  const validationKeys = useMemo(() => buildValidationKeys(onlyDigitalProducts), [
    onlyDigitalProducts
  ]);

  const values = useMultiPartFormState({
    values: state,
    validation,
    parts: onlyDigitalProducts ? 1 : 2,
    name: 'checkout',
    validationKeys: validationKeys });

  useEffect(() => {
    // this event triggers on render
    pushEecEvent('checkout', {
      products: shoppingCart.items.map(eecProduct),
      actionField: {
        step: 1
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.partOptions.currentPart !== 2) {
      return;
    }

    // this event triggers when part changes
    pushEecEvent('checkout', {
      actionField: {
        step: 2,
        option: values.paymentMethod?.name
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.partOptions.currentPart]);

  useEffect(() => {
    if (values.partOptions.currentPart !== 2) {
      return;
    }

    // this event triggers when payment method changes
    pushEecEvent('checkout_option', {
      actionField: {
        step: 2,
        option: values.paymentMethod?.name
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.paymentMethod?.name]);

  useEffect(() => {
    if (!values.productOptions.customerContact.phoneNumber) {
      return;
    }

    values.handlers.setSwishPhoneNumber(
      values.productOptions.customerContact.phoneNumber
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.productOptions.customerContact.phoneNumber]);

  const submitButtons = useMemo(
    () => (
      <>
        {submit.errors && !submit.isPolling && !submit.isSending && (
          <AlertMessageContainer messageStyle="error">
            {getPurchaseErrorMessage(submit.exceptionCode)}
          </AlertMessageContainer>
        )}
        <SubmitButton values={values} validation={validation} submit={submit} />
        <SwishOtherDeviceButton
          values={values}
        />

        <IntegrityTextAreaWrapper>
          <IntegrityTextArea values={values} />
        </IntegrityTextAreaWrapper>
      </>
    ),
    [submit, validation, values]
  );

  return ( isDisabled ? null : 
    <form onSubmit={submit.handleSubmit}>
      { !onlyDigitalProducts && <Section
        part={1}
        values={values}
        validation={validation}
        title="Din leveransadress"
      >
        <ContactFields
          values={values}
          validation={validation}
          company={false}
          automatic={ false }
          manual={{
            ssn: false,
            email: false,
            phone: {
              placeholder: 'Mobilnummer',
              instructions: 'Eventuell leveransavisering skickas via SMS.'
            }
          }}
        />
      </Section> }
      <Section
        part={onlyDigitalProducts ? 1 : 2}
        values={values}
        validation={validation}
        title="Välj betalsätt"
      >
        <Payment
          values={values}
          validation={validation}
          submitButtons={submitButtons}
          formContent={{}}
          showTaxReductionText={hasDigitalProducts}
        />
      </Section>
    </form>
  );
}
