import { LotteryTicketValues } from '.';

import { StateValues } from './State';

import { useAnalyticsValues } from './useAnalyticsValues';
import { useContactInformationValues } from './useContactInformationValues';
import { useGenericValues } from './useGenericValues';
import { useLotteryTicketValues } from './useLotteryTicketValues';
import { usePaymentValues } from './usePaymentValues';
import { useProductValues } from './useProductValues';

export type LotteryTicketStateValues = StateValues & LotteryTicketValues;

type InputProps = {
  values: {
    product?: {
      id?: number;
    };
    socialName?: string;
    productOptions?: {
      pul?: boolean;
      receiverContactType?: 'private' | 'company';
      campaignActivityId?: number | string | null;
      motherSender?: string;
      motherNumber?: string;
      motherText?: string;
    };
    gtm?: {
      variant?: string;
    };
    handlers?: Record<string, unknown>;
  };
  formContent: {
    originPath: string;
    redirectPath: string;
    gtmId?: string;
  };
  customGtmId?: string;
};

export function useLotteryTicketState<T extends InputProps>({
  values,
  formContent,
  customGtmId
}: T) {
  const generic = useGenericValues(values);
  const contactInformation = useContactInformationValues(generic);
  const product = useProductValues(contactInformation);

  const analytics = useAnalyticsValues({
    values: product,
    formContent: formContent
  });

  const payment = usePaymentValues({
    values: analytics,
    formContent: formContent,
    customGtmId: customGtmId
  });

  const lotteryTicketState = useLotteryTicketValues(payment);

  return lotteryTicketState;
}
