import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';
import { heading2, preamble } from '~/App/helpers/mixins';

import { Wrapper } from '~/App/shared/components/ThanksPage';

const Container = styled.div`
  padding: 0 1rem;
  max-width: 100%;
  width: 60rem;
`;

const Content = styled.div`
  position: relative;
  max-width: 35.5rem;
  margin: 0 auto;
  padding: 3.125rem 0 5rem;
  text-align: center;
`;

const StyledWrapper = styled(Wrapper)`
  margin-top: 1.5rem;
  text-align: center;

  ${mq('≥small')`
    margin-top: 1.5rem;
  `};

  &:last-child {
    margin-bottom: 5rem;
  }
`;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1.5rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const Image = styled.img`
  margin: 0 auto 1.5rem;
  max-width: 100%;
  height: 6rem;

  ${mq('≥small')`
    height: 10rem;
  `};
`;

type Props = {
  onClose: (url: string) => () => void;
  children: ReactNode;
};

export function ThankYou({ children, onClose }: Props) {
  return (
    <Container>
      <Wrapper style={{ width: '100%' }}>
        <Content>
          <Image src="https://res.cloudinary.com/cancerfonden/image/upload/f_auto,q_auto:eco/v1701074718/assets/STMC-logga-takeover_cropped.png" />
          <Heading1>Stort tack för att du är med i kampen mot cancer!</Heading1>
          <Preamble>
            Vi vet att det är möjligt att besegra cancer och vi vet hur vi ska
            nå dit. Varje dag görs upptäckter som för forskningen framåt. Nästa
            stora genombrott är inom räckhåll. Tack för att du är med i det
            livsviktiga arbetet!
          </Preamble>
          <br />
        </Content>
      </Wrapper>
      <StyledWrapper>
        <Content>
          <Heading1>Ditt kvitto</Heading1>
          <Preamble>
            Du får ett kvitto på att du blivit månadsgivare till din
            e-postadress. Din gåva kommer dras på ett säkert sätt via{' '}
            <a
              href="https://www.klarna.com/se/"
              target="_blank"
              rel="noreferrer"
            >
              Klarna{' '}
            </a>
            varje månad.
          </Preamble>
          {children}
        </Content>
      </StyledWrapper>
    </Container>
  );
}
