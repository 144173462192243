import React, { useMemo, ReactNode, MouseEvent, ComponentProps } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// config
import {
  ButtonStyle,
  buttonStyles,
  ButtonSize
} from '~/App/config/buttonStyles';

// helpers
import { primaryButton } from '~/App/helpers/mixins';
import omitProps from '~/App/helpers/omit-props';

// shared components
import Link from '~/App/shared/components/Link';
import { rotate360 } from '~/App/helpers/animations';

const Button = styled.button`
  ${primaryButton};
`;

const StyledLink = styled(
  omitProps(Link, ['isLoading', 'isDisabled', 'buttonStyle'])
)`
  ${primaryButton};
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

type ButtonSpinnerProps = {
  isLoading: boolean;
};

const ButtonSpinner = styled.div<ButtonSpinnerProps>`
  display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
  position: absolute;
  top: 0;
  right: 1.5rem;
  margin: calc(0.5rem + 2px) auto;
  width: 20px;
  height: 20px;
  animation: ${rotate360} 0.3s linear infinite;
  border-top: 2px solid ${(props) => props.theme.primaryButton.spinnerColorDark};
  border-right: 2px solid
    ${(props) => props.theme.primaryButton.spinnerColorLight};
  border-bottom: 2px solid
    ${(props) => props.theme.primaryButton.spinnerColorLight};
  border-left: 2px solid
    ${(props) => props.theme.primaryButton.spinnerColorDark};
  border-radius: 100%;
  content: ' ';
  z-index: 1;
`;

type Props = {
  children: string | ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  buttonStyle?: ButtonStyle;
  buttonSize?: ButtonSize;
  buttonType?: 'button' | 'link';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  to?: ComponentProps<typeof NavLink>['to'];
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  rel?: string;
  target?: string;
};

export default function PrimaryButton({
  children,
  isLoading = false,
  isDisabled = false,
  className = '',
  buttonStyle = buttonStyles.primary,
  buttonSize = 'large',
  buttonType = 'button',
  onClick,
  to = '#',
  type = 'button',
  ...rest
}: Props) {
  const renderedChildren = useMemo(
    () => (typeof children === 'string' ? children : <span>{children}</span>),
    [children]
  );

  const renderedSpinner = useMemo(() => {
    if (!isLoading) return null;

    return (
      <SpinnerWrapper>
        <ButtonSpinner isLoading={isLoading} />
      </SpinnerWrapper>
    );
  }, [isLoading]);

  if (buttonType === 'link') {
    return (
      <StyledLink
        isLoading={isLoading}
        isDisabled={isDisabled}
        className={`${className} ${buttonStyle} ga-button`}
        buttonStyle={buttonStyle}
        to={to}
        aria-disabled={isDisabled}
        {...rest}
      >
        {renderedChildren}
        {renderedSpinner}
      </StyledLink>
    );
  }

  return (
    <Button
      disabled={isDisabled}
      isLoading={isLoading}
      isDisabled={isDisabled}
      className={`${className} ${buttonStyle} ga-button`}
      buttonStyle={buttonStyle}
      buttonSize={buttonSize}
      onClick={onClick}
      type={type}
      aria-disabled={isDisabled}
      {...rest}
    >
      {renderedChildren}
      {renderedSpinner}
    </Button>
  );
}
