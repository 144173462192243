import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import {
  heading1,
  heading2,
  heading3,
  sectionHeader
} from '~/App/helpers/mixins';

type HeadingProps = {
  textAlign: string;
};

const Heading1 = styled.h1<HeadingProps>`
  ${heading1};
  color: ${({ theme }) => theme.themeColors.primary};
  text-align: ${({ textAlign }) => textAlign};
`;

const Heading2 = styled.h2<HeadingProps>`
  ${heading2};
  color: ${({ theme }) => theme.themeColors.primary};
  text-align: ${({ textAlign }) => textAlign};
`;

const Heading3 = styled.h2<HeadingProps>`
  ${heading3};
  color: ${({ theme }) => theme.themeColors.primary};
  text-align: ${({ textAlign }) => textAlign};
`;

const SectionHeading = styled.h2<HeadingProps>`
  ${sectionHeader};
  color: ${({ theme }) => theme.related.headingColor};
`;

type Props = {
  children: ReactNode;
  settings: {
    fontSize: 'heading1' | 'heading2' | 'heading3' | 'sectionHeading';
    textAlign: string;
    fontWeight: string;
  };
};

export function Heading({ children, settings }: Props) {
  const Heading = useMemo(() => {
    if (settings.fontSize === 'heading1') {
      return Heading1;
    }

    if (settings.fontSize === 'heading2') {
      return Heading2;
    }

    if (settings.fontSize === 'sectionHeading') {
      return SectionHeading;
    }

    return Heading3;
  }, [settings.fontSize]);

  if (!children) {
    return null;
  }

  return <Heading children={children} {...settings} />;
}
