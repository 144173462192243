/* eslint-disable no-unreachable */
import { AxiosResponse } from 'axios';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import { ICommerceShoppingCart } from '~/types/ICommerceShoppingCart';

import { siteAxios } from './client';

function ensureSuccessful(response: AxiosResponse<unknown>) {
  if (response.status < 200 || response.status > 299) {
    throw new Error('Failed to update cart');
  }
}

export const shoppingCart = {
  create: async (): Promise<ICommerceShoppingCart> => {
    const response = await siteAxios.post<ICommerceShoppingCart>(
      '/api/v2/carts'
    );
    const data = response.data;

    ensureSuccessful(response);

    return data;
  },

  get: async (id: string) => {
    const url = `/api/v2/carts/${id}`;
    const response = await siteAxios.get<ICommerceShoppingCart>(url);
    const data = response.data;

    return data;
  },

  getAllItems: async (id: string) => {
    const url = `/api/v2/carts/${id}`;
    const response = await siteAxios.get<ICommerceShoppingCart>(url);
    const data = response.data;

    return data.orderLines || [];
  },

  add: async (
    shoppingCartId: string,
    orderLine: ICommerceOrderLine
  ): Promise<ICommerceOrderLine> => {
    const body = {
      price: orderLine.price,
      quantity: orderLine.quantity,
      cart: {
        id: shoppingCartId
      },
      variant: {
        id: orderLine.variant?.id
      }
    };

    const url = '/api/v2/orderLines';
    const response = await siteAxios.post<ICommerceOrderLine>(url, body);
    const data = response.data;

    ensureSuccessful(response);

    return data;
  },

  remove: async (orderLineId: string): Promise<void> => {
    ensureSuccessful(
      await siteAxios.delete(`/api/v2/orderLines/${orderLineId}`)
    );
  },

  update: async (
    orderLine: ICommerceOrderLine
  ): Promise<ICommerceOrderLine> => {
    const body = {
      id: orderLine.id,
      price: orderLine.price,
      quantity: orderLine.quantity
    };

    const url = `/api/v2/orderLines/${orderLine.id}`;
    const response = await siteAxios.patch(url, body);
    const data = response.data;

    ensureSuccessful(response);

    return data;
  }
};
