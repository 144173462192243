import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import { card, heading3 } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';

const Card = styled.div`
  ${card}
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;

  ${mq('≥small')`
    flex-direction: row;
    align-items: stretch;
    padding: 1.5rem;
  `};
`;

const Heading = styled.h3`
  ${heading3}
  margin-bottom: 1rem;
`;

const ImageWrapper = styled.div`
  flex: 1 0 100%;
  margin: -1.5rem -1rem 0.5rem;

  ${mq('≥small')`
    flex-basis: 39%;
    margin: -1.5rem 1.5rem -1.5rem -1.5rem;
  `};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Description = styled.p`
  margin-bottom: 1rem;
`;

type Props = {
  imageUrl?: string;
  desktopImageUrl?: string;
  heading?: string;
  description?: string;
  children: ReactNode;
};

export function Teaser({
  imageUrl,
  desktopImageUrl = imageUrl,
  heading,
  description,
  children
}: Props) {
  return useMemo(
    () => (
      <Card>
        <ImageWrapper>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={`
                ${mediaHelper.getUrl(desktopImageUrl, { width: 768 })} 2x,
                ${mediaHelper.getUrl(desktopImageUrl, { width: 384 })} 1x
              `}
            />
            <source
              srcSet={`
                ${mediaHelper.getUrl(imageUrl, { width: 768 })} 2x,
                ${mediaHelper.getUrl(imageUrl, { width: 384 })} 1x
              `}
            />
            <Image src={mediaHelper.getUrl(desktopImageUrl, { width: 768 })} />
          </picture>
        </ImageWrapper>
        <div>
          <Heading children={heading} />
          <Description children={description} />
          {children}
        </div>
      </Card>
    ),
    [children, description, desktopImageUrl, heading, imageUrl]
  );
}
