import React from 'react';
import styled from 'styled-components';
import { slideUpAndBounce } from '~/App/helpers/animations';
import { CSSTransition } from 'react-transition-group';
import { mq } from '~/lib/mq';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';

const FreeShippingStyleBackground = styled.div`
  flex-direction: column;
  background: #f0f9fc;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-bottom: 7rem;

  ${mq('<mediumLarge')`
    padding-bottom: 2rem;
  `};
`;

const FreeShippingStyle = styled.div`
  &.freeshipping-appear-active {
    animation-name: ${slideUpAndBounce};
    animation-timing-function: ease-out;
    animation-duration: 1.4s;
  }

  &.freeshipping-exit {
    opacity: 1;
  }

  &.freeshipping-exit-active {
    opacity: 0;
    animation-name: ${slideUpAndBounce};
    animation-timing-function: ease-in;
    animation-duration: 1s;
    animation-direction: reverse;
  }

  &.freeshipping-exit-done {
    opacity: 0;
  }

  ${mq('<mediumLarge')`
    padding-bottom: 2rem;

  &.freeshipping-appear {
    opacity: 0;
  }

  &.freeshipping-appear-active {
    animation-name: ${slideUpAndBounce};
    animation-timing-function: ease-out;
    animation-duration: 1.1s;
    animation-delay: 330ms;
  }

  &.freeshipping-appear-done {
    opacity: 1;
  }
  `};
`;

const FreeShippingText = styled.div`
  margin-bottom: 1rem;
`;

const OvalSvg = styled.svg`
  width: 100%;
  position: relative;
  top: 0.5rem;
`;

const OvalBackground = styled.ellipse`
  fill: #f0f9fc;
  stroke: #f0f9fc;
`;

const PaymentMethodImage = styled.img`
  height: 32px;
  width: 32px;
`;

const ShippingWrapper = styled.div`
  flex-direction: column;
  width: 100%;
`;

export function FreeShippingLarge() {
  const shoppingCart = useShoppingCart();

  return (
    <ShippingWrapper>
      <OvalSvg viewBox="0 0 200 50">
        <OvalBackground cx="100" cy="55" rx="120" ry="50" />
      </OvalSvg>
      <FreeShippingStyleBackground>
        <CSSTransition
          in={shoppingCart.isOpen}
          appear={true}
          timeout={{ appear: 1430, enter: 1430, exit: 1400 }}
          classNames={"freeshipping"}
        >
          <FreeShippingStyle>
            <FreeShippingText>
              <b>Fri frakt</b> vid köp över 295kr.
            </FreeShippingText>
            <div>
              <PaymentMethodImage src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-swish_3x.png" />
              <PaymentMethodImage src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-visa-card_3x.png" />
              <PaymentMethodImage src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-mastercard_3x.png" />
              <PaymentMethodImage src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-amex_3x.png" />
            </div>
          </FreeShippingStyle>
        </CSSTransition>
      </FreeShippingStyleBackground>
    </ShippingWrapper>
  );
}
