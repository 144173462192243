import React, { useMemo } from 'react';
import styled from 'styled-components';

import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../States';
import { ContactFields } from '../../Forms/components/ContactFields';
import { description2 } from '~/App/helpers/mixins';
import { usePreferredCustomerContactType } from '~/App/shared/hooks/use-preferred-customer-contact-type';

const Wrapper = styled.div`
  :not(:first-child) {
    padding-top: 1rem;
  }
`;

const InfoText = styled.span`
  ${description2};
  display: block;
  line-height: 1.1875rem;
  margin-bottom: 1rem;
`;

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
};

export function InvoiceFields({ values, validation }: Props) {
  const preferred = useMemo(
    () => (values.customerContactType === 'company' ? 'company' : 'manual'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  usePreferredCustomerContactType({
    preferred,
    values
  });

  return (
    <Wrapper>
      <InfoText>
        Din faktura kommer att skickas till dig med post inom 2-4 vardagar.
      </InfoText>
      {values.customerContactType === 'company' ? (
        <ContactFields
          values={values}
          validation={validation}
          disabledBillingAddress={true}
        />
      ) : (
        <ContactFields
          values={values}
          validation={validation}
          manual={{
            email: false
          }}
        />
      )}
    </Wrapper>
  );
}
