import { useEffect } from 'react';
import { CustomerContactType } from '~/App/config/customerContactTypes';

type Props = {
  preferred: CustomerContactType | null;
  values: {
    customerContactType?: CustomerContactType | null;
    product?: {
      id: number;
    };

    handlers: {
      handleCustomerContactType?: (value: CustomerContactType | null) => void;
    } & Record<string, unknown>;
  } & Record<string, unknown>;
};

export function usePreferredCustomerContactType({ preferred, values }: Props) {
  useEffect(() => {
    if (!values.handlers.handleCustomerContactType) {
      return;
    }
    if (values.product?.id === 4) {
      return;
    }

    const customerContactType = values.customerContactType;

    if (customerContactType === preferred) {
      return;
    }

    values.handlers.handleCustomerContactType(preferred);

    return () => {
      if (!values.handlers.handleCustomerContactType) {
        return;
      }

      values.handlers.handleCustomerContactType(
        customerContactType || 'manual'
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
