import { useMemo } from 'react';

import { gtmDetails } from '~/App/config/gtmDetails';
import { Period } from '~/types/IPurchase';
import { LotteryProps } from './Lottery';

export function useState({ data }: LotteryProps) {
  const { defaultQuantity = '30', campaignActivityId } = data;

  const PRODUCT_ID = data.productId;
  const quantity = parseInt(defaultQuantity, 10);

  return useMemo(
    () => ({
      product: { id: PRODUCT_ID },
      productOptions: {
        product: {
          quantity: quantity,
          price: 100,
          period: 'Once' as Period
        },
        campaignActivityId: campaignActivityId,
        pul: true
      },
      paymentMethod: data.defaultPaymentMethod,
      customerContactType: data.defaultCustomerContactType,
      partOptions: {
        parts: 3,
        validationKeys: {
          1: ['productOptions.lotteryTicket.quantity'],
          2: [
            'productOptions.customerContact.company',
            'productOptions.customerContact.cid',
            'productOptions.customerContact.addressStreet',
            'productOptions.customerContact.addressZip',
            'productOptions.customerContact.addressCity',
            'productOptions.customerContact.firstName',
            'productOptions.customerContact.lastName',
            'productOptions.customerContact.phoneNumber',
            'productOptions.customerContact.ssn',
            'productOptions.customerContact.email'
          ],
          3: [
            'swishPhoneNumber',
            'mobileBankId.bank',
            'mobileBankId.accountNumber',
            'mobileBankId.clearingNumber'
          ]
        }
      },
      gtm: gtmDetails[PRODUCT_ID]
    }),
    [
      PRODUCT_ID,
      campaignActivityId,
      data.defaultCustomerContactType,
      data.defaultPaymentMethod,
      quantity
    ]
  );
}
