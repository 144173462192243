import React from 'react';
import styled from 'styled-components';

// Shared components
import {
  Instructions,
  Message,
  Icon,
  Label,
  TextArea as TextAreaField
} from './Components';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  padding-bottom: ${({ isInvalid }) => (isInvalid ? '0.25rem' : '0')};
`;

const TextAreaWrapper = styled.div`
  position: relative;
`;

const Counter = styled.div`
  font-size: 0.75rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.75rem;
`;

export default function TextArea({
  type = 'textarea',
  label = null,
  errors = null,
  isValid = null,
  isInvalid = null,
  counterValue = null,
  instructions = '',
  ...props
}) {
  return (
    <Wrapper isInvalid={isInvalid}>
      <Icon isValid={isValid && props.value} />
      <Label
        isEmpty={!props.value && !props.defaultValue}
        isInvalid={isInvalid}
      >
        {label || props.placeholder}
      </Label>
      <TextAreaWrapper>
        <TextAreaField
          isEmpty={!props.value && !props.defaultValue}
          isValid={isValid && props.value}
          isInvalid={isInvalid}
          type={type}
          {...props}
        />
        {counterValue && <Counter>{counterValue}</Counter>}
      </TextAreaWrapper>
      {errors ? (
        <Message className="text-area__message" isInvalid={isInvalid}>
          {errors[0]}
        </Message>
      ) : null}
      {instructions ? <Instructions>{instructions}</Instructions> : null}
    </Wrapper>
  );
}
