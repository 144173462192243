import React from 'react';
import styled from 'styled-components';
import { components, IndicatorsContainerProps } from 'react-select';

import { close, search } from '~/App/helpers/icons';

type IconProps = {
  open: boolean;
};

const Icon = styled.div<IconProps>`
  margin-right: 0.5rem;
  font-size: 1.25rem;

  :before {
    ${({ open }) => (open ? close : search)};
    font-weight: ${({ open }) => (open ? 700 : 400)};
    color: ${({ theme }) => theme.colors.darkBlue};
    top: 2px;
  }
`;

type Props<T> = IndicatorsContainerProps<T, false>;

export function IndicatorsContainer<T = unknown>(props: Props<T>) {
  return (
    <components.IndicatorsContainer {...props}>
      <Icon open={props.selectProps.menuIsOpen} />
    </components.IndicatorsContainer>
  );
}
