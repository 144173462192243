export const schema = {
  'collection.name': {
    length: {
      minimum: 5,
      message: '^Ange minst 5 bokstäver'
    },
    presence: {
      allowEmpty: false,
      message: '^Ange ett namn'
    }
  },
  'collection.description': {
    length: {
      minimum: 5,
      message: '^Ange minst 5 bokstäver'
    },
    presence: {
      allowEmpty: false,
      message: '^Ange beskrivning'
    },
    format: {
      pattern: /^(?!.*<[^>]+>).*/s,
      message:
        '^Beskriv din insamling med vanlig text. Html och annan kod fungerar inte.'
    }
  },
  'collection.monetaryGoal': {
    numericalityOrEmpty: {
      message: '^Endast siffror',
      onlyInteger: true,
      greaterThanOrEqualTo: 0
    }
  },
  'collection.image': {
    presence: {
      message: '^Du måste välja eller ladda upp en bild'
    }
  },
  ssn: {
    validateSsn: (_, { ssnFieldVisible, authenticated }) =>
      ssnFieldVisible && !authenticated
  }
};
