import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const Navigation = styled.ul`
  font-size: 0.75rem;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;

  ${mq('≥tiny')`
    font-size: 0.8125rem;
  `};

  ${mq('≥small')`
    font-size: 0.875rem;
  `};
`;
