import styled from 'styled-components';
import { mq } from '~/lib/mq';

type OverlayProps = {
  show: boolean;
};

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  top: 110px; 
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 0.15s ease-out;
  opacity: ${({ show }) => (show ? '0.7' : '0')};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  z-index: 1;

  ${mq('≥mediumLarge')`
    top: 0;
  `};
`;
