import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Period } from '~/types/IPurchase';

const Image = styled.img`
  min-height: 4.5rem;
  min-width: 4.5rem;
`;

type Props = {
  period: Period | null;
  className?: string;
};

export function MemoryPeriodImage({ period, ...props }: Props) {
  const url = useMemo(() => {
    switch (period) {
      case 'Month':
        return 'https://res.cloudinary.com/cancerfonden/image/upload/v1637845721/minnesida/monthly-donor.svg';
      case 'Once':
        return 'https://res.cloudinary.com/cancerfonden/image/upload/v1637845724/minnesida/one-time-donor.svg';
      case null:
        return 'https://res.cloudinary.com/cancerfonden/image/upload/v1637846768/minnesida/no-donation.svg';
      default:
        return '';
    }
  }, [period]);

  return <Image src={url} {...props} />;
}
