import React from 'react';
import styled from 'styled-components';
import { CategoryLine } from '../ResearchInformation';

type LineProps = {
  hasHoverItems: boolean;
};
const Line = styled.div<LineProps>`
  font-size: 20px;
  display: flex;
  color: ${({ hasHoverItems }) => (hasHoverItems ? '#001489' : 'none')};

  &:hover span {
    visibility: visible;
  }
`;

const Label = styled.span`
  font-weight: 600;
`;

const Title = styled.span<LineProps>`
  border-bottom: dashed 0.1rem ${({theme}) => theme.colors.charcoal};
  border-bottom: ${({ hasHoverItems }) => (!hasHoverItems && 'none')};
`;

const HoverContainer = styled.span`
  visibility: hidden;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.charcoal};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  border-radius: 6px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  width: 296px;
  transform: translateY(32px) translateX(-25%);

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent
      ${({ theme }) => theme.colors.charcoal} transparent;
  }
`;

export const ResearchCategoryLine = ({
  label,
  title,
  hoverContent = []
}: CategoryLine) => {
  
  return (
    <Line hasHoverItems={hoverContent.length > 0}>
      <Title key="title" hasHoverItems={hoverContent.length > 0}>
        <Label key="label">{label + ' '}</Label>
        {title}
      </Title>
      {hoverContent.length > 0 && (
        <HoverContainer
          dangerouslySetInnerHTML={{ __html: hoverContent.join('<br/>') }}
        />
      )}
    </Line>
  );
};
