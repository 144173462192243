import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';
import queryString from '~/lib/query-string';

import { IQuestionSection } from '../../../../SelfTest/types';
import { useQuery } from '~/App/shared/hooks/use-query';
import { useScore } from '../../../hooks/useScore';
import { ScoreIndicator } from '../../ScoreIndicator';
import { IAnswer } from '~/types/IQuestionSession';

type WrapperProps = {
  selected: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  padding: 1rem;
  position: relative;
  line-height: 1.2;
  font-size: 0.875rem;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  cursor: pointer;
  flex: 1;
  min-width: 30vw;

  ${mq('≥small')`
    padding: 1rem 1.5rem 1.5rem;
    min-width: 8rem;
    width: auto;
  `};
`;

const Text = styled.div`
  margin-top: 0.5rem;
  font-size: 0.875rem;

  ${mq('≥small')`
    margin-top: 1rem;
    font-size: 1.125rem;
  `};
`;

type Props = {
  section: IQuestionSection;
  answers: IAnswer[];
};

type Query = {
  section: string;
};

export function Item({ section, answers }: Props) {
  const query = useQuery<Query>();
  const navigate = useNavigate();
  const location = useLocation();

  const questionIds = useMemo(() => section.screens.map((x) => `${x.id}`), [
    section
  ]);

  const selected = useMemo(() => section.id === query.section, [
    query.section,
    section.id
  ]);

  const { score } = useScore({
    screens: section.screens,
    answers: answers.filter((x) => questionIds.includes(`${x.questionId}`))
  });

  const handleClick = useCallback(() => {
    const params = queryString.stringify({
      ...query,
      section: section.id
    });

    navigate(`${location.pathname}?${params}`);
  }, [query, section.id, navigate, location.pathname]);

  return (
    <Wrapper selected={selected} onClick={handleClick}>
      <ScoreIndicator value={score} />
      <Text children={section.name} />
    </Wrapper>
  );
}
