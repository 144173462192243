import React, {
  useMemo,
  ReactNode,
  useState,
  useCallback,
  useEffect
} from 'react';
import { useLocation } from 'react-router';

import { contentApi } from '~/App/helpers/env';
import { fetch } from '~/App/helpers/http';

import { match } from '~/lib/content-redirect/content-redirect.match';
import { sort } from '~/lib/content-redirect/content-redirect.sort';

// Shared components
import { IRedirect } from '~/types/IRedirect';
import { Redirect } from '../Redirect';

type Props = {
  children: ReactNode;
};

export function ContentRedirect({ children }: Props) {
  const location = useLocation();

  const [redirects, setRedirects] = useState<IRedirect[]>([]);

  const { pathname, search } = location;

  const fetchRedirects = useCallback(async () => {
    const url = contentApi('/v1/redirects');
    const response = await fetch({ url });

    setRedirects(response.data?.redirects || []);
  }, []);

  const sorted = useMemo(() => sort(redirects), [redirects]);
  const redirect = useMemo(() => match(sorted, { path: pathname }), [
    sorted,
    pathname
  ]);

  const to = useMemo(
    () =>
      redirect
        ? `${redirect.to}?${redirect.keepUrlParams ? search : undefined}`
        : null,
    [redirect, search]
  );

  useEffect(() => {
    fetchRedirects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {to && <Redirect to={to} external={to?.startsWith('http')} />}
      {children}
    </>
  );
}
