import React, { useMemo } from 'react';
import styled from 'styled-components';

// helpers
import { heading2, preamble } from '~/App/helpers/mixins';
import { siteUrl } from '~/App/helpers/env';
import { IPurchaseShow } from '~/types/IPurchaseShow';

import ShareButtons from '~/App/shared/components/ShareButtons';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import {
  AsyncMemorialCertificateDocumentLink,
  AsyncDocumentLink
} from '~/App/shared/components/AsyncDocumentLink';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { Header } from './shared/Header';
import { mq } from '~/lib/mq';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const PreambleWithMarginTop = styled(Preamble)`
  margin-top: 2rem;
`;

const StyledShareButtons = styled(ShareButtons)`
  width: 8.5rem;
  margin: 2rem auto;
`;

const SerialNumber = styled.div`
  font-weight: bold;
  font-size: 1.125rem;
  margin-top: 1rem;

  ${mq('<small')`
    font-size: 1rem;
  `};
`;

type Props = {
  purchase: IPurchaseShow;
  data: {
    heroHeading?: string;
    infoText: string;
  };
};

export default function MemorialGiftThanks({ purchase, data }: Props) {
  const { customerContact, inMemoryOfContact } =
    purchase.purchase.productOptions || {};

  const firstName = customerContact?.firstName;

  const greeting = data.heroHeading
    ? firstName
      ? data.heroHeading.replace('%%firstName%%', ` ${firstName}`)
      : data.heroHeading.replace('%%firstName%%', '')
    : 'Tack!';

  const email = customerContact?.email;
  const funeralHome = inMemoryOfContact?.funeralHome?.name;
  const contactName = `${inMemoryOfContact?.firstName} ${inMemoryOfContact?.lastName}`;

  const preamble = useMemo(() => {
    if (!inMemoryOfContact?.id) {
      return `Vi skickar minnesbladet för ${contactName} till begravningsbyrån, kyrkan, församlingen eller en anhörig beroende på de uppgifter du lämnat. Om vi inte kan reda ut var minnesbladet ska skickas, hör vi av oss till dig på mejl.`;
    }

    if (!funeralHome) {
      return `Vi har tacksamt tagit emot din minnesgåva och skickar minnesbladet för ${contactName}.`;
    }

    return `Vi har tacksamt tagit emot din minnesgåva och skickar minnesbladet för ${contactName} till ${funeralHome}.`;
  }, [contactName, funeralHome, inMemoryOfContact?.id]);

  return (
    <div>
      <Background backgroundColor="primary">
        <Header image="//res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png">
          <Heading1 children={greeting} />
          <Preamble children={preamble} />
          <FetchPurchaseDocuments purchase={purchase}>
            {({
              receiptStatus,
              receipt,
              certificate,
              certificateStatus,
              purchase
            }) => (
              <>
                {purchase?.purchase.productOptions?.memorialGift
                  ?.serialNumber && (
                  <SerialNumber
                    children={`Serienummer: ${purchase.purchase.productOptions.memorialGift.serialNumber}`}
                  />
                )}

                <AsyncMemorialCertificateDocumentLink
                  url={certificate}
                  status={certificateStatus}
                />
                <Preamble children="Du kan se om minnesbladet har nått mottagaren." />

                <AsyncDocumentLink
                  url={
                    purchase?.purchase.productOptions?.memorialGift
                      ?.trackableUrl
                  }
                  status={certificateStatus}
                  fileName="spårningslänk"
                  fileNameForLink="Spåra ditt minnesblad"
                  fileNameWithPronoun="din spårningslänk"
                  icon="external"
                />

                {email && (
                  <Preamble children={`Vi har skickat kvitto till ${email}`} />
                )}
                <AsyncReceiptDocumentLink
                  url={receipt}
                  status={receiptStatus}
                  paymentMethodId={purchase?.paymentMethod?.id}
                />
              </>
            )}
          </FetchPurchaseDocuments>
          <PreambleWithMarginTop>
            {data.infoText ||
              'Din gåva bidrar till att föra cancerforskningen framåt – du är med och räddar liv.'}
          </PreambleWithMarginTop>
          <StyledShareButtons
            showFacebook={true}
            showX={true}
            showEmail={true}
            to={siteUrl('/stod-oss/ge-en-minnesgava/')}
          />
        </Header>
      </Background>
    </div>
  );
}
