import styled, { css } from 'styled-components';

type IconProps = {
  isValid: boolean;
};

export const Icon = styled.div<IconProps>`
  pointer-events: none;
  position: absolute;
  top: calc(48px * 0.5);
  right: 0.75em;
  height: 1em;
  width: 1em;
  margin-top: calc(1em * -0.5);
  background: url('/images/icon-check-green.png') center center no-repeat;
  background-size: contain;
  z-index: 1;
  opacity: 0;
  font-size: 1em;
  transform: scale(0);
  transition: all 0.3s ease;

  ${({ isValid }) =>
    isValid &&
    css`
      opacity: 1;
      transform: scale(1);
    `};
`;
