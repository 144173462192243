import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';

// Helpers
import { textHelper } from '~/App/shared/components/Donation/components/Forms/helpers/textHelper';
import { IDeceasedContact } from '~/types/IDeceasedContact';

export type MemorialGiftInputValues = {
  productOptions: {
    memorialGift: {
      amount?: number;
      customAmount: boolean;
      textGreeting: string;
      memorialDate: string | null;
    };
    selectedTemplate?: number;
    texts?: string[];
  };
  templates?: string[];
  handlers?: Record<string, unknown>;
};

export type MemorialGiftValues = {
  productOptions: {
    memorialGift: {
      amount?: number;
      customAmount: boolean;
      textGreeting: string;
      memorialDate: string | null;
    };
    selectedTemplate: number;
    texts: string[];
    inMemoryOfContact: IDeceasedContact | null;
  };
  templates?: string[];
  handlers: {
    setMemorialGiftAmount: (value: string | number | null) => void;
    setMemorialGiftCustomAmount: Dispatch<SetStateAction<boolean>>;
    resetMemorialGiftAmount: () => void;
    setMemorialGiftMemorialDate: (value: string | null) => void;
    setMemorialGiftTextGreeting: (value: string) => void;
    setMemorialGiftTextGreetingSimple: Dispatch<SetStateAction<string>>;
    setInMemoryOfContact: (value: IDeceasedContact | null) => void;
    handleTextChange: (
      event: ChangeEvent<HTMLInputElement>,
      selectedTemplate: number
    ) => void;
    resetTextToTemplate: (selectedTemplate: number) => void;
    handleTemplateStep: (step?: number) => void;
  };
};

export function useMemorialGiftValues<T extends MemorialGiftInputValues>(
  values: T
) {
  const {
    productOptions: { memorialGift, ...productOptions },
    templates = []
  } = values;

  const [amount, setAmount] = useState(memorialGift?.amount ?? '500');
  const [customAmount, setCustomAmount] = useState(
    memorialGift?.customAmount ?? false
  );
  const [textGreeting, setTextGreeting] = useState<string>(
    memorialGift?.textGreeting ?? ''
  );
  const [memorialDate, setMemorialDate] = useState(
    memorialGift?.memorialDate ?? null
  );
  const [selectedTemplate, setSelectedTemplate] = useState(
    productOptions?.selectedTemplate ?? 0
  );

  const [texts, setTexts] = useState(productOptions?.texts ?? []);

  const [
    inMemoryOfContact,
    setInMemoryOfContact
  ] = useState<IDeceasedContact | null>(null);

  const setMemorialGiftAmount = useCallback((value: string | number | null) => {
    if (!value) {
      return setAmount('');
    }

    if (typeof value === 'number') {
      return setAmount(value);
    }

    if (!value.match(/^-?\d*\.?\d*$/)) {
      return;
    }

    setAmount(value);
  }, []);

  const resetMemorialGiftAmount = useCallback(() => {
    setAmount('');
  }, []);

  const setMemorialGiftMemorialDate = useCallback(
    (memorialDate: string | null) => {
      setMemorialDate(memorialDate ? memorialDate : null);
    },
    []
  );

  const setMemorialGiftTextGreeting = useCallback((textGreeting?: string) => {
    const maximumNumberOfLines = 10;

    if (textHelper.calculateUsedLines(textGreeting) <= maximumNumberOfLines) {
      setTextGreeting(textGreeting || '');
    }
  }, []);

  const handleTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, selectedTemplate: number) => {
      setMemorialGiftTextGreeting(event.target.value);
      const value = [...texts];

      value[selectedTemplate] = event.target.value;

      setTexts(value);
    },
    [setMemorialGiftTextGreeting, texts]
  );

  const resetTextToTemplate = useCallback(
    (selectedTemplate: number) => {
      setMemorialGiftTextGreeting(templates[selectedTemplate] || '');

      const value = [...texts];

      value[selectedTemplate] = templates[selectedTemplate] || '';

      setTexts(texts);
    },
    [setMemorialGiftTextGreeting, templates, texts]
  );

  const handleTemplateStep = useCallback(
    (step = 1) => {
      let nextTemplate = selectedTemplate;

      if (step > 0) {
        nextTemplate =
          selectedTemplate + 1 >= texts.length ? 0 : selectedTemplate + 1;
      } else {
        nextTemplate =
          selectedTemplate - 1 < 0 ? texts.length - 1 : selectedTemplate - 1;
      }

      setMemorialGiftTextGreeting(texts[nextTemplate]);
      setSelectedTemplate(nextTemplate);
    },
    [selectedTemplate, setMemorialGiftTextGreeting, texts]
  );

  return useMemo<MemorialGiftValues & T>(
    () => ({
      ...values,
      productOptions: {
        ...productOptions,
        memorialGift: {
          ...memorialGift,
          amount,
          customAmount,
          textGreeting,
          memorialDate
        },
        inMemoryOfContact: inMemoryOfContact,
        selectedTemplate: selectedTemplate,
        texts
      },
      handlers: {
        ...values.handlers,
        setMemorialGiftAmount,
        setMemorialGiftCustomAmount: setCustomAmount,
        resetMemorialGiftAmount,
        setMemorialGiftMemorialDate,
        setMemorialGiftTextGreeting,
        setMemorialGiftTextGreetingSimple: setTextGreeting,
        setInMemoryOfContact,
        handleTextChange,
        resetTextToTemplate,
        handleTemplateStep
      }
    }),
    [
      amount,
      customAmount,
      handleTemplateStep,
      handleTextChange,
      inMemoryOfContact,
      memorialDate,
      memorialGift,
      productOptions,
      resetMemorialGiftAmount,
      resetTextToTemplate,
      selectedTemplate,
      setMemorialGiftAmount,
      setMemorialGiftMemorialDate,
      setMemorialGiftTextGreeting,
      textGreeting,
      texts,
      values
    ]
  );
}
