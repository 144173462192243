import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { memorial } from '~/App/config/default-images';
import { RadioButtonAccordion } from '~/App/shared/components/Elements/RadioButtonAccordion';
import { ImageSelector } from '~/App/shared/components/Fields/ImageSelector';
import { IValidation } from '~/App/shared/components/Validation';
import { CreateMemoryStateValues } from '../../hooks/useCreateMemoryState';

import TextArea from '~/App/shared/components/Fields/TextArea';
import { MemoryTypePreview } from './components/MemoryPreview';
import { PreviewModal } from './components/PreviewModal';

const Heading = styled.div`
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const FieldWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

type Props = {
  values: CreateMemoryStateValues;
  validation: IValidation;
};

export function DescribeMemory({ values, validation }: Props) {
  const [previewOpen, setPreviewOpen] = useState(false);

  const handlePreviewClose = useCallback(() => setPreviewOpen(false), []);
  const handlePreviewOpen = useCallback(() => setPreviewOpen(true), []);

  return (
    <>
      <FieldWrapper>
        <Heading>Välj en bild</Heading>
        <ImageSelector
          defaultImages={memorial}
          onChange={values.handlers.setMemorialPageMemoryImage}
          value={values.productOptions.memory.image}
          errors={validation.errors['productOptions.memorialPageMemory.image']}
          isInvalid={validation.isInvalid(
            'productOptions.memorialPageMemory.image'
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Heading>Skriv ditt minne eller din hälsning</Heading>
        <TextArea
          placeholder="Skriv här"
          errors={validation.errors['productOptions.memory.content']}
          isValid={validation.isValid('productOptions.memory.content')}
          isInvalid={validation.isInvalid('productOptions.memory.content')}
          value={values.productOptions.memory.content}
          onChange={values.handlers.handleMemorialPageMemoryContent}
          onBlur={validation.showError.bind(
            undefined,
            'productOptions.memory.content'
          )}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <Heading>Namn som syns vid minnet/hälsningen</Heading>
        <TextArea
          errors={validation.errors['productOptions.memorialPageMemory.by']}
          isValid={validation.isValid('productOptions.memorialPageMemory.by')}
          isInvalid={validation.isInvalid(
            'productOptions.memorialPageMemory.by'
          )}
          value={values.productOptions.memory.by}
          onChange={values.handlers.handleMemorialPageMemoryBy}
          instructions="Lämna tomt om du/ni vill vara anonyma"
          placeholder="Ditt/Era namn"
        />
      </FieldWrapper>

      <FieldWrapper>
        <Heading>Tänd ett ljus</Heading>
        <RadioButtonAccordion
          value={values.productOptions.memory.period}
          onSelect={values.handlers.setMemorialPageMemoryPeriod}
          items={[
            {
              id: 'Month',
              name: 'Ge varje månad',
              children: (
                <MemoryTypePreview
                  period="Month"
                  showPreview={handlePreviewOpen}
                />
              )
            },
            {
              id: 'Once',
              name: 'Ge en gång',
              children: (
                <MemoryTypePreview
                  period="Once"
                  showPreview={handlePreviewOpen}
                />
              )
            },
            {
              id: null,
              name: 'Ingen gåva',
              children: (
                <MemoryTypePreview
                  period={null}
                  showPreview={handlePreviewOpen}
                />
              )
            }
          ]}
        />
      </FieldWrapper>
      <PreviewModal
        isOpen={previewOpen}
        onClose={handlePreviewClose}
        {...values.productOptions.memory}
      />
    </>
  );
}
