import React from 'react';
import styled from 'styled-components';
import { components, IndicatorsContainerProps } from 'react-select';

import { angleDown, angleUp } from '~/App/helpers/icons';
import { IProjectStatus } from '../SingleValueFilter';

type IconProps = {
  open: boolean;
};

const Icon = styled.div<IconProps>`
  margin-right: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;

  &::after {
    ${({ open }) => (open ? angleUp : angleDown)};
    color: ${({ theme }) => theme.colors.darkBlue};
    top: 2px;

    // TODO: Add Animation 
    // ${angleDown}
    // transition: transform 0.3s ease;
    // transform: ${({open}) => (open ? 'rotate(-180deg)' : 'none')};
  }
`;

type Props = IndicatorsContainerProps<IProjectStatus, false | true>;

export function IndicatorsContainer(props: Props) {
  return (
    <components.IndicatorsContainer {...props}>
      <Icon open={props.selectProps.menuIsOpen} />
    </components.IndicatorsContainer>
  );
}
