import React, { useMemo } from 'react';

import Text from '~/App/shared/components/Fields/Text';
import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';

import { InputWrapper } from '../components/InputWrapper';

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
  disabled?: boolean;
  required?: boolean;
};

export function Address({
  values,
  validation,
  disabled = false,
  required = true
}: Props) {
  return useMemo(
    () => (
      <>
        <InputWrapper>
          <Text
            name="streetAddress"
            value={values.productOptions.customerContact.addressStreet}
            onBlur={validation.showError.bind(
              undefined,
              'productOptions.customerContact.addressStreet'
            )}
            errors={
              validation.errors['productOptions.customerContact.addressStreet']
            }
            isValid={validation.isValid(
              'productOptions.customerContact.addressStreet'
            )}
            onChange={values.handlers.handleCustomerContactAddressStreet}
            isInvalid={validation.isInvalid(
              'productOptions.customerContact.addressStreet'
            )}
            maxLength="45"
            placeholder="Adress"
            autoComplete="street-address"
            isDisabled={disabled}
            required={required}
          />
        </InputWrapper>
        <InputWrapper percentageWidth="33">
          <Text
            name="postalCode"
            value={values.productOptions.customerContact.addressZip}
            onBlur={validation.showError.bind(
              undefined,
              'productOptions.customerContact.addressZip'
            )}
            errors={
              validation.errors['productOptions.customerContact.addressZip']
            }
            isValid={validation.isValid(
              'productOptions.customerContact.addressZip'
            )}
            onChange={values.handlers.handleCustomerContactAddressZip}
            isInvalid={validation.isInvalid(
              'productOptions.customerContact.addressZip'
            )}
            maxLength="10"
            placeholder="Postnr"
            autoComplete="postal-code"
            isDisabled={disabled}
            required={required}
          />
        </InputWrapper>
        <InputWrapper percentageWidth="66">
          <Text
            name="addressLevel2"
            value={values.productOptions.customerContact.addressCity}
            onBlur={validation.showError.bind(
              undefined,
              'productOptions.customerContact.addressCity'
            )}
            errors={
              validation.errors['productOptions.customerContact.addressCity']
            }
            isValid={validation.isValid(
              'productOptions.customerContact.addressCity'
            )}
            onChange={values.handlers.handleCustomerContactAddressCity}
            isInvalid={validation.isInvalid(
              'productOptions.customerContact.addressCity'
            )}
            maxLength="50"
            placeholder="Postort"
            autoComplete="address-level2"
            isDisabled={disabled}
            required={required}
          />
        </InputWrapper>
      </>
    ),
    [
      disabled,
      validation,
      values.handlers.handleCustomerContactAddressCity,
      values.handlers.handleCustomerContactAddressStreet,
      values.handlers.handleCustomerContactAddressZip,
      values.productOptions.customerContact.addressCity,
      values.productOptions.customerContact.addressStreet,
      values.productOptions.customerContact.addressZip,
      required
    ]
  );
}
