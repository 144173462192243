type Arguments = {
  url: string;
  alternativeActiveUrls?: string[];
  exact?: boolean;
  pathname: string;
};

export function isActive({
  url = '',
  alternativeActiveUrls = [],
  exact = false,
  pathname = ''
}: Arguments): boolean {
  if (!url && !pathname) {
    return false;
  }

  const urlParts = url.split('/');
  const pathnameParts = pathname.split('/');

  if (exact) {
    return url === pathname;
  }

  const matchesRealUrl = urlParts.reduce(
    (_, currentUrlPart, index) => pathnameParts[index] === currentUrlPart,
    false
  );

  if (matchesRealUrl) {
    return matchesRealUrl;
  }

  for (const url of alternativeActiveUrls) {
    if (
      isActive({
        url: url,
        pathname: pathname
      })
    ) {
      return true;
    }
  }

  return false;
}
