import React, { useState, useCallback, useMemo, ReactNode } from 'react';

import { useLocation } from 'react-router';

// Config and helpers
import {
  LoginContext,
  LoginContextValue,
  HandleLoginOptions,
  HandleLoginResponse
} from './LoginContext';
import { auth, poll } from '~/App/helpers/http';

import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { useQuery } from '~/App/shared/hooks/use-query';

import { set, get, remove } from '~/App/helpers/cookie';
import { pushGTMIsAuthenticated } from '~/App/helpers/gtm-helper';

let pollSource = poll.CancelToken.source();

type Props = {
  children: ReactNode;
};

export function LoginContextProvider({ children }: Props) {
  const location = useLocation();
  const query = useQuery();
  const authentication = useAuthenticationContext();

  const [qrCode, setQrCode] = useState('');
  const [isPolling, setIsPolling] = useState(false);

  const source = useMemo(() => location.pathname, [location]);
  const subscriptionToken = useMemo(
    () =>
      typeof query.subscriptionToken === 'string'
        ? query.subscriptionToken
        : undefined,
    [query.subscriptionToken]
  );

  const handleLoginAbort = useCallback(() => {
    if (pollSource) {
      pollSource.cancel();
    }
    authentication.setStatus('aborting');

    authentication.setErrors([]);
    pollSource = poll.CancelToken.source();
  }, [authentication]);

  const handleLogin = useCallback(
    async (options: HandleLoginOptions = {}): Promise<HandleLoginResponse> => {
      const { qrInit = false, sameDevice = false, testLogin = false } = options;

      setIsPolling(true);
      authentication.setStatus('polling');

      const response = testLogin
        ? await auth.testLogin()
        : await auth.login({
            source,
            subscriptionToken,
            cancelToken: pollSource.token,
            sameDevice,
            onQrCode: (code) => {
              if (qrInit) setQrCode(code);
            },
            onAutoStartToken: (token) => {
              if (sameDevice) {
                window.location.href = `bankid:///?autostarttoken=${token}&redirect=null`;
              }
            },
            onMessage: (message) => {
              if (message === 'RFA1') {
                authentication.setStatus('waitingForAppStart');
              } else if (message === 'RFA9') {
                authentication.setStatus('signing');
              } else if (message === 'RFA21') {
                authentication.setStatus('waitingForBankID');
              }
            }
          });

      setIsPolling(false);

      if (!response.success) {
        authentication.setState('anonymous');
        authentication.setStatus('idle');
        authentication.setErrors(response.errors);

        return {
          shouldRedirect: false,
          success: false,
          user: null
        };
      }

      const { user, secretKey } = response;

      set('secretKey', secretKey, 11);
      authentication.setUser(user);
      authentication.setStatus('idle');
      authentication.setState('authenticated');

      pushGTMIsAuthenticated(true);

      return {
        success: true,
        shouldRedirect: true,
        user
      };
    },
    [source, subscriptionToken, authentication]
  );

  const handleLogout = useCallback(async () => {
    if (!get('secretKey')) {
      return;
    }

    try {
      await auth.logout();
      remove('secretKey');

      authentication.setStatus('idle');
      authentication.setState('anonymous');
      authentication.setUser(null);

      return;
    } catch (error) {
      console.error(error);
    }
  }, [authentication]);

  const value = useMemo<LoginContextValue>(
    () => ({
      qrCode,
      isPolling,
      isAuthenticated: authentication.state === 'authenticated',
      user: authentication.user,
      handlers: {
        handleLogout,
        handleLogin: handleLogin,
        handleLoginAbort: handleLoginAbort
      }
    }),
    [
      qrCode,
      isPolling,
      authentication,
      handleLogout,
      handleLogin,
      handleLoginAbort
    ]
  );

  return <LoginContext.Provider value={value} children={children} />;
}
