import React, { ComponentProps } from 'react';
import styled from 'styled-components';

import { Modal } from '~/App/shared/components/Elements';
import LargeShareButtons from '~/App/shared/components/LargeShareButtons';
import { closeLink, heading2 } from '~/App/helpers/mixins';

import { siteUrl } from '~/App/helpers/env';

const Padding = styled.div`
  padding: 1.5rem;
`;

const Heading = styled.h2`
  ${heading2}
  margin: 2rem 0 1rem;
  color: black;
`;

const Text = styled.p`
  margin: 1rem 0 2rem;
  color: black;
`;

const CloseButton = styled.a`
  ${closeLink}
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: ${(props) => props.theme.colors.darkBlue};
`;

type Props = {
  closeModal: ComponentProps<typeof Modal>['closeModal'];
};

const emailOrMore = {
  subject: 'Kolla risken ',
  title: 'Kolla risken',
  body: `Jag har Kollat Risken - gör det du också på https://www.cancerfonden.se/minska-risken/kolla-risken`
};

const sms = {
  body: `Jag har Kollat Risken - gör det du också på https://www.cancerfonden.se/minska-risken/kolla-risken`
};

const xOrFacebook = {
  text:
    'Jag har gjort ett test för att se hur jag kan minska risken att få cancer. Kolla risken du med:'
};

export function ShareModal({ closeModal }: Props) {
  return (
    <Modal closeModal={closeModal}>
      <Padding>
        <CloseButton onClick={closeModal}>Stäng</CloseButton>
        <Heading>Tipsa om testet</Heading>
        <Text>
          Hjälp oss nå ut till fler för att färre ska drabbas av cancer. Dela
          testet (inte ditt resultat) med vänner och alla du känner.
        </Text>
        <LargeShareButtons
          to={siteUrl('/minska-risken/kolla-risken')}
          sms={sms}
          more={emailOrMore}
          facebook={xOrFacebook}
          x={xOrFacebook}
        />
      </Padding>
    </Modal>
  );
}
