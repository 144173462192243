import { siteUrl } from './env';

export const linkTypes = {
  internal: 'internal',
  external: 'external',
  file: 'file'
};

const fileEndings = [
  'jpg',
  'jpeg',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'png',
  'gif',
  'bmp'
];

export const getLinkType = (url) => {
  const fileEndingMatch = url.match(/([/.\w]+)[.]([\w]+)([?][\w./=]+)?/);
  const fileEnding = fileEndingMatch?.length >= 2 && fileEndingMatch[2];

  const isLink = fileEndings.indexOf(fileEnding) !== -1;

  if (isLink) {
    return linkTypes.file;
  }

  const isCancerfondenSe = !!url.match(/\/\/www.cancerfonden.se/);
  const isInternalPath = !!url.match(/^\/(?!\/)/);
  const baseSiteUrl = siteUrl() || 'https://www.cancerfonden.se';
  const environmentRegex = new RegExp(baseSiteUrl);
  const isEnvironment = !!url.match(environmentRegex);

  const isInternal = isCancerfondenSe || isInternalPath || isEnvironment;

  if (isInternal) {
    return linkTypes.internal;
  }

  return linkTypes.external;
};

export const stripInternalUrl = (url) => {
  const baseSiteUrl = siteUrl();
  const liveUrl = 'https://www.cancerfonden.se';

  if (baseSiteUrl) {
    return url.replace(baseSiteUrl, '').replace(liveUrl, '');
  } else {
    return url.replace(liveUrl, '');
  }
};
