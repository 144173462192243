import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { search } from '~/App/helpers/icons';
import { clearfix } from '~/App/helpers/mixins';
import { rotate360 } from '~/App/helpers/animations';

const Wrapper = styled.div`
  ${clearfix};
  display: block;
  width: 100%;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  padding-bottom: 0.5em;
  font-size: 0.8em;

  ${mq('≥small')`
    font-size: 1em;
  `};
`;

const Actions = styled.div`
  position: relative;
  display: inline-block;
  width: 3em;
  vertical-align: middle;

  ${mq('≥small')`
    width: 3.5em;
  `};
`;

const Field = styled.div`
  display: inline-block;
  width: calc(100% - 3em);
  vertical-align: middle;

  ${mq('≥small')`
    width: calc(100% - 3.5em);
  `};
`;

const Input = styled.input`
  width: 100%;
  padding: 0;
  border: 0;
  font-size: 2.25rem;
  line-height: 1.55;
  font-family: 'CancerfondenSans';
  font-weight: 300;
  border: 0;
  box-shadow: none;
  outline: none;
  background: transparent;

  ${mq('≥small')`
    font-size: 3rem;
    line-height: 1.5;
  `};
`;

const size = 1.6;
const speed = '0.3s';
const color = '#666';
const borderWidth = '2px';
const backgroundColor = '#ccc';

const Spinner = styled.div`
  animation: ${rotate360} ${speed} linear infinite;
  border-radius: 100%;
  border-bottom: ${borderWidth} solid ${backgroundColor};
  border-left: ${borderWidth} solid ${color};
  border-right: ${borderWidth} solid ${backgroundColor};
  border-top: ${borderWidth} solid ${color};
  display: block;
  height: ${size}em;
  width: ${size}em;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  margin-left: ${size * -0.5}em;
  margin-top: ${size * -0.5}em;

  opacity: ${(props) => (props.isLoading ? 1 : 0)};
  visibility: ${(props) => (props.isLoading ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const Button = styled.button`
  text-align: center;
  font-size: 1.5em;
  width: 100%;
  padding: 0.5em 0;
  border: 0;
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
  background-color: transparent;
  box-shadow: none;
  outline: none;
  transition: opacity 0.3s ease;
  color: #888;

  ${mq('≥small')`
    font-size: 1.75em;
  `};

  &:before {
    ${search};
  }
`;

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { search, onSubmit, onChange, isLoading } = this.props;

    return (
      <form action="" onSubmit={onSubmit} noValidate>
        <Wrapper>
          <Actions>
            <Spinner isLoading={isLoading ? isLoading : undefined} />
            <Button type="submit" isLoading={isLoading} />
          </Actions>
          <Field>
            <Input
              type="text"
              name="search"
              value={search}
              onChange={onChange}
              placeholder="Sök på cancerfonden.se"
            />
          </Field>
        </Wrapper>
      </form>
    );
  }
}
