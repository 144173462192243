import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';

// Helpers
import { breakpoints } from '~/App/config/mediaBreakpoints';
import {
  getOuterHeightWithMargin,
  getOffset
} from '~/App/helpers/units-helper';
import { scrollAnimation } from '~/App/helpers/animations';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import { siteUrl } from '~/App/helpers/env';
import { useQuery } from '~/App/shared/hooks/use-query';
import { useMatchMedia } from '~/App/shared/hooks/use-match-media';
import { useAppContext } from '~/App/contexts/App';

import Block from './components/Block';
import ArticlePage from './components/ArticlePage';
import NewsPage from './components/NewsPage';
import PressPage from './components/PressPage';
import { InlineRelatedBlock } from './components/InlineRelatedBlock';

import { AuthorHelperPage } from '~/App/shared/components/AuthorHelper';
import { NewsletterBlock } from '~/App/shared/components/Blocks';

export function Page({ data, route, match }) {
  const location = useLocation();
  const query = useQuery();
  const userAgent = useAppContext((x) => x.userAgent);
  const isLargeScreen = useMatchMedia(
    `(min-width: ${breakpoints.large})`,
    !isMobileBrowser(userAgent)
  );

  const handleSkipTo = useCallback(
    (skipTo) => {
      const element = document.getElementById(`${skipTo}`);
      const header = document.getElementById('headerContainer');
      const tableOfContents = document.getElementById('tableOfContents');

      if (!element) return;

      const offsetTop = getOffset(element).top;
      const headerHeight = header ? getOuterHeightWithMargin(header) : 0;
      const tableOfContentsHeight = tableOfContents
        ? getOuterHeightWithMargin(tableOfContents)
        : 0;

      const scrollOffset = isLargeScreen
        ? headerHeight
        : headerHeight + tableOfContentsHeight;

      scrollAnimation(offsetTop - scrollOffset);
    },
    [isLargeScreen]
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const hash = location.hash || window.location.hash;
    const skipTo = query.skipTo;

    if (skipTo) {
      handleSkipTo(skipTo);
    } else if (hash) {
      handleSkipTo(hash.substring(1));
    }
  }, [handleSkipTo, location, query, match, route, data]);

  const scripts = useMemo(() => {
    let pageData = {};
    switch (data.meta.structuredDataType) {
      case 'article':
        pageData = {
          '@context': 'https://schema.org',
          '@type': 'Article',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': 'https://www.cancerfonden.se' + data.slug
          },
          headLine: data.title,
          description: data.meta.description,
          datePublished: data.createdAt,
          dateModified: data.updatedAt,
          author:
            Object.entries(data.editor).length > 0 ||
            Object.entries(data.author).length > 0
              ? [
                  ...(Object.entries(data.editor).length > 0
                    ? [
                        {
                          '@type': 'Person',
                          name: data.editor.name,
                          jobTitle: data.editor.title,
                          email: data.editor.email,
                          url: data.editor.url
                            ? siteUrl(data.editor.url)
                            : undefined,
                          MedicalSpecialty: data.editor.medicalSpecialty
                        }
                      ]
                    : []),
                  ...(Object.entries(data.author).length > 0
                    ? [
                        {
                          '@type': 'Person',
                          name: data.author.name,
                          jobTitle: data.author.title,
                          email: data.author.email,
                          MedicalSpecialty: data.author.medicalSpecialty,
                          url: data.author.url
                            ? siteUrl(data.editor.url)
                            : undefined
                        }
                      ]
                    : [])
                ]
              : undefined,
          reviewedBy:
            Object.entries(data.auditor).length > 0
              ? {
                  '@type': 'Person',
                  name: data.auditor.name,
                  jobTitle: data.auditor.title,
                  MedicalSpecialty: data.auditor.medicalSpecialty,
                  url: data.auditor.url ? siteUrl(data.auditor.url) : undefined
                }
              : undefined,
          publisher: {
            '@type': 'Organization',
            address: {
              '@type': 'PostalAddress',
              addressLocality: 'Stockholm, Sverige',
              postalCode: '101 55',
              streetAddress: 'David Bagares gata 5'
            }
          }
        };
        break;
      default:
        break;
    }

    const organizationData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Stockholm, Sverige',
        postalCode: '101 55',
        streetAddress: 'David Bagares gata 5'
      },
      name: 'Cancerfonden',
      email: 'info@cancerfonden.se',
      url: 'https://www.cancerfonden.se',
      telephone: '+(46) 10-199 10 10',
      image: {
        '@type': 'ImageObject',
        url:
          'https://res.cloudinary.com/cancerfonden/image/upload/c_pad,fl_progressive,h_70,q_100,w_450/v1544186351/assets/logo-cancerfonden_one-line_blue.png'
      }
    };
    const jsonLd = [
      ...(Object.entries(pageData).length > 0 ? [pageData] : []),
      organizationData
    ];

    return [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(jsonLd)
      }
    ];
  }, [data]);

  return useMemo(() => {
    if (!data || !data.meta) {
      return null;
    }

    return (
      <div>
        <Helmet script={scripts}>
          <title>{data.title}</title>
          <link rel="canonical" href={siteUrl(data.meta.canonical)} />
          {data.meta.robots && (
            <meta name="robots" content={data.meta.robots} />
          )}
          {data.meta.description && (
            <meta name="description" content={data.meta.description} />
          )}
          {data.meta.ogTitle && (
            <meta property="og:title" content={data.meta.ogTitle} />
          )}
          {data.meta.ogImage && (
            <meta property="og:image" content={data.meta.ogImage} />
          )}
          {data.meta.ogDescription && (
            <meta property="og:description" content={data.meta.ogDescription} />
          )}
        </Helmet>
        <AuthorHelperPage
          id={data.id}
          title={data.title}
          path="/pages/"
          publishState={data.publishState}
        >
          {data.template === 'block' && <Block {...data} />}
          {data.template === 'article' && <ArticlePage {...data} />}
          {data.template === 'newsPage' && <NewsPage {...data} />}
          {data.template === 'pressPage' && <PressPage {...data} />}
          {data.related?.items?.length ? (
            <InlineRelatedBlock {...data} />
          ) : null}
          {data.newsletter && <NewsletterBlock {...data.newsletter} />}
        </AuthorHelperPage>
      </div>
    );
  }, [data, scripts]);
}
