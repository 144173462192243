import React from 'react';

import { buttonStyles } from '~/App/config/buttonStyles';
import { FloatingButton } from '~/App/shared/components/FloatingButton';

export const CreateCollectionButton = React.memo(
  function CreateCollectionButton() {
    return (
      <FloatingButton
        buttonStyle={buttonStyles.cta}
        to={'/stod-oss/insamlingar/starta-insamling'}
      >
        Starta insamling
      </FloatingButton>
    );
  }
);
