import { contentApi } from '~/App/helpers/env';
import {
  questionSession,
  questionSessionAnswers,
  fetch
} from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export async function loadData({ params, oldData }: LoadDataProps<'id'>) {
  if (oldData) {
    return oldData;
  }

  if (!params.id) {
    const configResponse = await fetch({
      url: contentApi('/v1/global-components?slug=kolla-risken')
    });

    return {
      defaultSession: null,
      defaultAnswers: [],
      config: configResponse.data.config
    };
  }

  const [configResponse, defaultSession, defaultAnswers] = await Promise.all([
    fetch({ url: contentApi('/v1/global-components?slug=kolla-risken') }),
    questionSession.get(params.id),
    questionSessionAnswers.getAll(params.id)
  ]);

  return {
    config: configResponse.data.config,
    defaultSession,
    defaultAnswers
  };
}
