const getOuterHeightWithMargin = (element: HTMLElement) => {
  let height = element.offsetHeight;
  const style = getComputedStyle(element);
  height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
  return height;
};

const getOffset = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top + document.documentElement.scrollTop,
    left: rect.left + document.documentElement.scrollLeft
  };
};
export { getOuterHeightWithMargin, getOffset };
