import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

type Props = {
  isActive?: boolean;
  hiddenSmall?: boolean;
};

export const Item = styled.li<Props>`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  position: relative;
  bottom: -1px;
  vertical-align: bottom;
  color: ${({ isActive, theme }) =>
    isActive ? 'black' : theme.themeColors.primary};
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.grayAlabaster : 'transparent'};
  border: 1px solid;
  border-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.gallery : 'transparent'};
  border-bottom: 0;
  transition: color 0.3s ease, border 0.3s ease;
  border-radius: 0.25em 0.25em 0 0;
  display: ${({ hiddenSmall }) => (hiddenSmall ? 'none' : 'inline-block')};

  ${mq<Props>('≥tiny')`
    margin-left: 0.25em;
    ${({ hiddenSmall }) => hiddenSmall && 'display: inline-block'};
  `};

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: ${({ isActive, theme }) =>
      isActive ? 'black' : theme.themeColors.primaryHover};
  }
`;
