import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useParams, useNavigate } from 'react-router';

import { questionSession, questionSessionAnswers } from '~/App/helpers/http';
import {
  IAnswer,
  IBaseAnswer,
  IQuestionSession
} from '~/types/IQuestionSession';

import { IScreen, ISelfTestConfig } from '../types';
import { useNavigation } from './hooks/useNavigation';
import { useTracking } from './hooks/useTracking';
import { Context, SelfTestContextValue } from './SelfTestContext';

type Params = {
  id?: string;
};

type Props = {
  defaultSession: IQuestionSession | null;
  defaultAnswers: IAnswer[];
  config: ISelfTestConfig;
  children: ReactNode;
};

export function SelfTestProvider({
  children,
  config,
  defaultAnswers,
  defaultSession = null
}: Props) {
  const [session, setSession] = useState<IQuestionSession | null>(
    defaultSession
  );
  const [results, setResults] = useState<IAnswer[]>([...defaultAnswers]);

  const params = useParams<Params>();
  const navigate = useNavigate();
  const navigation = useNavigation({
    session,
    config
  });

  const currentSection = useMemo(
    () => (navigation.index ? config.sections[navigation.index.section] : null),
    [navigation.index, config]
  );

  const currentScreen = useMemo(
    () =>
      navigation.index
        ? config.sections[navigation.index.section].screens[
            navigation.index.screen
          ]
        : null,
    [navigation.index, config]
  );

  const allScreens = useMemo(
    () =>
      config.sections.reduce(
        (prev: IScreen[], current) => [...prev, ...current.screens],
        []
      ),
    [config]
  );

  const allQuestions = useMemo(
    () =>
      allScreens.filter((x) =>
        ['QuestionScreen', 'BMIScreen'].includes(x.type)
      ),
    [allScreens]
  );

  const currentQuestion = useMemo(() => {
    if (!currentScreen) return null;

    const index = allQuestions.indexOf(currentScreen);
    const position = index === -1 ? null : index + 1;

    return position;
  }, [allQuestions, currentScreen]);

  const tracking = useTracking({
    screen: currentScreen,
    question: currentQuestion,
    section: currentSection
  });

  const start = useCallback(async () => {
    const response = await questionSession.create();

    navigate(`/minska-risken/kolla-risken/${response?.id}`);
    tracking.trackStart();

    setSession(response);
  }, [navigate, tracking]);

  const getAnswer = useCallback(
    <T extends IAnswer = IBaseAnswer>(questionId: number | string): T | null =>
      (results.find((x) => `${x.questionId}` === `${questionId}`) as T) || null,
    [results]
  );

  const setAnswer = useCallback(
    async (value: IAnswer) => {
      if (!session?.id) return;

      const answer = getAnswer(value.questionId);
      const exists = !!answer;
      const task = answer?.id
        ? questionSessionAnswers.update(answer?.id, session?.id, {
            ...value
          })
        : questionSessionAnswers.create(session?.id, {
            ...value
          });

      const data = await task;

      setResults((state) =>
        exists
          ? state.map((x) => (x.questionId === data.questionId ? data : x))
          : [...state, data]
      );
    },
    [getAnswer, session?.id]
  );

  const persistSession = useCallback(async (value: IQuestionSession) => {
    if (!value.id) return;

    setSession(value);
    questionSession.update(value.id, value);
  }, []);

  const navigateTo = useCallback(
    (delta: 1 | -1) => () => {
      navigation.goTo(delta);
      tracking.trackNavigation(delta === 1 ? 'Nästa' : 'Föregående');
    },
    [navigation, tracking]
  );

  const complete = useCallback(() => {
    tracking.trackNavigation('Visa resultat');
    navigate(`/minska-risken/kolla-risken/${session?.id}/resultat`);
  }, [navigate, session?.id, tracking]);

  useEffect(() => {
    params.id || setSession(null);
  }, [params.id]);

  const value = useMemo<SelfTestContextValue>(
    () => ({
      session,
      config,
      isStarted: !!session,
      isFirstScreen: navigation.isFirstScreen,
      isLastScreen: navigation.isLastScreen,
      currentQuestion,
      currentSection,
      currentScreen,
      numberOfQuestions: allQuestions.length,
      index: navigation.index,
      start,
      complete,
      setSession: persistSession,
      getAnswer,
      setAnswer,
      navigateTo
    }),
    [
      session,
      config,
      navigation.isFirstScreen,
      navigation.isLastScreen,
      navigation.index,
      currentQuestion,
      currentSection,
      currentScreen,
      allQuestions.length,
      start,
      complete,
      persistSession,
      getAnswer,
      setAnswer,
      navigateTo
    ]
  );

  return <Context.Provider value={value} children={children} />;
}
