import React from 'react';
import styled from 'styled-components';

import { sectionHeader } from '~/App/helpers/mixins';
import { Spinner } from '~/App/shared/components/Elements';

const Wrapper = styled.div`
  display: flex;
  padding: 8rem 0;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  background-color: ${({ theme }) => theme.colors.smoke};
`;

const Status = styled.h3`
  ${sectionHeader};
  color: ${({ theme }) => theme.related.headingColor};
  align-text: center;
  margin: 1rem 0;
`;

type Props = {
  status?: string;
};

export default function Loading({ status }: Props) {
  return (
    <Wrapper>
      <div>
        {status && <Status children={status} />}
        <Spinner />
      </div>
    </Wrapper>
  );
}
