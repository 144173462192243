import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { BlockBackground } from '~/App/shared/components/BlockBackground';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

type Props = {
  children: ReactNode;
};

export function Wrapper({ children }: Props) {
  return <Background backgroundColor="primary" children={children} />;
}
