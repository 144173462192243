import React from 'react';
import styled from 'styled-components';

// Shared components
import Container from '~/App/shared/components/Container';
import { IncreaseMonthlyAutogiroDonation } from '~/App/shared/components/Donation';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPolar};
`;

const Container2 = styled(Container)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

class IncreaseMonthlyAutogiroDonationForm extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <Wrapper>
        <Container2
          width="limited"
          paddingLeft="small"
          paddingRight="small"
          paddingTop="small"
          paddingBottom="small"
        >
          <IncreaseMonthlyAutogiroDonation
            originPath={data.originPath}
            formContent={data}
            redirectPath={data.redirectPath}
            availableBanks={data.availableBanks}
            availablePaymentMethods={data.availablePaymentMethods}
          />
        </Container2>
      </Wrapper>
    );
  }
}

export default IncreaseMonthlyAutogiroDonationForm;
