import { merge } from 'lodash';

// Constants
import { paymentMethods } from '~/App/config/paymentMethods';
import { swishTypes } from '~/App/config/swishTypes';

export const buildSchema = ({ schema = {} }) =>
  merge(
    {},
    {
      'productOptions.product.price': {
        presence: {
          allowEmpty: false,
          message: '^Ange ett belopp'
        },
        numericality: {
          greaterThan: 49,
          lessThanOrEqualTo: 999999,
          message: '^Minsta gåvobelopp är 50 kr'
        }
      },
      'productOptions.customerContact.company': {
        validateCompany: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.cid': {
        validateCid: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.firstName': {
        validateFirstName: (value, { customerContactType, paymentMethod }) =>
          customerContactType === 'manual' ||
          paymentMethod.id === paymentMethods.paymentSlip
      },
      'productOptions.customerContact.lastName': {
        validateLastName: (value, { customerContactType, paymentMethod }) =>
          customerContactType === 'manual' ||
          paymentMethod.id === paymentMethods.paymentSlip
      },
      'productOptions.customerContact.addressStreet': {
        validateStreetName: (value, { customerContactType, paymentMethod }) =>
          customerContactType !== 'automatic' ||
          paymentMethod.id === paymentMethods.paymentSlip
      },
      'productOptions.customerContact.addressZip': {
        validateZipCode: (value, { customerContactType, paymentMethod }) =>
          customerContactType !== 'automatic' ||
          paymentMethod.id === paymentMethods.paymentSlip
      },
      'productOptions.customerContact.addressCity': {
        validateCity: (value, { customerContactType, paymentMethod }) =>
          customerContactType !== 'automatic' ||
          paymentMethod.id === paymentMethods.paymentSlip
      },
      'productOptions.customerContact.phoneNumber': {
        validatePhoneNumber: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.ssn': {
        validateSsn: (value, { customerContactType }) =>
          customerContactType === 'automatic'
      },
      'productOptions.customerContact.email': {
        validateEmail: (_, {}) => true
      },
      swishPhoneNumber: {
        validatePhoneNumber: (_, { paymentMethod, swish }) =>
          paymentMethod.id === 8 && swish.swishType === swishTypes.ecommerce
      }
    },
    schema
  );
