import React from 'react';
import styled from 'styled-components';
import { wallet } from '~/App/helpers/icons';
import { mq } from '~/lib/mq';

type BoxProps = {
  isSearchPage: boolean;
};

const Box = styled.div<BoxProps>`
  font-size: 20px;
  width: ${({ isSearchPage }) => (isSearchPage ? '' : '35%')};
  ${mq('<small')`
    margin-bottom: 0.5rem;
  `};
`;

const Line = styled.div`
  display: flex;
  align-items: baseline;
`;

const Label = styled.div`
  position: relative;
  top: 0.5rem;
`;

const MoneyIcon = styled.span`
  ${wallet}
  &:after {
    ${wallet};
    font-size: 3rem;
    top: 0.5rem;
  }
`;

const Amount = styled.span`
  font-size: 30px;
  font-weight: 600;
  margin-right: 8px;
  margin-left: 0.75rem;
`;

const Currency = styled.span`
  font-size: 20px;
  font-weight: normal;
`;

type Props = {
  label: string;
  amount: string;
  currency: string;
  isSearchPage: boolean;
};

export const ResearchFinance = ({
  label,
  amount,
  currency,
  isSearchPage
}: Props) => {
  return (
    <Box isSearchPage={isSearchPage}>
      <Label>{label}</Label>
      <Line>
        <MoneyIcon />
        <Amount>
          {amount}
          <Currency>{currency === 'kronor' ? 'kr' : currency}</Currency>
        </Amount>
      </Line>
    </Box>
  );
};
