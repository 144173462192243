import React from 'react';
import { scrollAnimation } from '~/App/helpers/animations';
import { getOffset } from '~/App/helpers/units-helper';

class NavigationItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();

    var $link = event.target;
    var targetHref = $link.setAttribute('href');
    var $targetElement = document.querySelector(targetHref);

    if ($targetElement && $targetElement.length > 0) {
      scrollAnimation(getOffset($targetElement).top - 124);
    }
  }

  render() {
    if (this.props.url === '') {
      return null;
    }

    var href = '#' + this.props.url,
      key = this.props.url + this.props.index;

    return (
      <a
        key={key}
        className="heroNavigation__link"
        href={href}
        onClick={this.handleClick}
      >
        {this.props.text}
      </a>
    );
  }
}

NavigationItem.displayName = 'HeroNavigationItem';

NavigationItem.defaultProps = {
  url: '',
  index: '',
  text: ''
};

export default NavigationItem;
