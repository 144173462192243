import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const RightColumn = styled.div`
  display: none;
  width: 368px;
  flex-basis: auto;
  margin-left: 2rem;

  ${mq('≥medium')`
    display: block;
  `};
`;
