import React, { useCallback, useState, ChangeEvent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { questionSession } from '~/App/helpers/http';

import { heading1 } from '~/App/helpers/mixins';
import { PrimaryButton } from '~/App/shared/components/Elements';
import Text from '~/App/shared/components/Fields/Text';
import { useValidation } from '~/App/shared/components/Validation';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.accentYellow};
`;

const Container = styled.div`
  max-width: 72.5rem;
  padding: 4rem 1rem;
  margin: auto;
`;

const Heading = styled.div`
  ${heading1};
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 1.5rem;
`;

const Preamble = styled.p`
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 1rem;
  max-width: 65ch;
`;

const Disclaimer = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 0.75rem;
  max-width: 32rem;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.darkBlue};
  text-decoration: underline;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  max-width: 22rem;
`;

const Button = styled(PrimaryButton)`
  margin: 1rem 0;
  min-width: 0px;
  align-self: flex-end;
`;

type FeedbackProps = {
  isFailed: boolean;
};

const Feedback = styled.p<FeedbackProps>`
  margin-top: 1rem;
  font-size: 1rem;
  color: ${({ theme, isFailed }) =>
    isFailed ? theme.colors.errorRed : theme.colors.darkBlue};
`;

type Status = 'idle' | 'loading' | 'success' | 'failed';

type Params = {
  id: string;
};

export function SaveYourResultSection() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<Status>('idle');

  const params = useParams<Params>();

  const validation = useValidation({
    schema: {
      email: {
        presence: {
          allowEmpty: false,
          message: '^E-post krävs'
        },
        email: {
          message: '^Felaktig e-postadress'
        }
      }
    },
    values: {
      email
    }
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!validation.isValidated) {
      return validation.showAllErrors();
    }

    setStatus('loading');

    if (!(await questionSession.send(params.id, email))) {
      return setStatus('failed');
    }

    setStatus('success');
    setEmail('');

    setTimeout(() => setStatus('idle'), 5000);
  }, [email, params.id, validation]);

  return useMemo(
    () => (
      <Wrapper>
        <Container>
          <Heading>Ladda ner ditt resultat</Heading>
          <Preamble>
            Få ditt resultat mejlat till dig så kan du läsa i lugn och ro.
          </Preamble>
          <StyledText
            value={email}
            type="email"
            name="email"
            onChange={handleChange}
            placeholder="E-post"
            errors={validation.errors['email']}
            isInvalid={validation.isInvalid('email')}
          />
          {status === 'success' ? (
            <Feedback
              isFailed={false}
              children="Tack, vi har skickat ett mejl till dig"
            />
          ) : null}

          {status === 'failed' ? (
            <Feedback
              isFailed={true}
              children="Ett fel inträffade, försök igen"
            />
          ) : null}

          <Button
            onClick={handleSubmit}
            children="Skicka"
            isLoading={status === 'loading'}
          />
          <Disclaimer>
            Jag samtycker till att Cancerfonden behandlar min e-postadress för
            att skicka mig information. Din e-postadress kommer inte att kunna
            sammankopplas med de svar du lämnat. Läs mer om hur vi behandlar
            personuppgifter i vår{' '}
            <Link
              href="/om-oss/personlig-integritet-och-cookies"
              target="_blank"
            >
              integritetspolicy
            </Link>
          </Disclaimer>
        </Container>
      </Wrapper>
    ),
    [email, handleChange, handleSubmit, status, validation]
  );
}
