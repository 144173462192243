import styled from 'styled-components';

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
`;
