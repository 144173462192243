import React, { ComponentProps, useEffect } from 'react';
import { find } from 'lodash';

import { IPaymentMethod } from '~/types/IPaymentMethod';
import { IntegrityText } from '../Forms/components/IntegrityText';
import { AlertMessageContainer } from '../../../Elements';
import { StepWrapper } from '../Forms/components/FormLayout';
import { getPurchaseErrorMessage } from '../Forms/helpers';

import PaymentMethodSelector from '../Forms/components/PaymentMethodSelector';

type Props = ComponentProps<typeof PaymentMethodSelector> & {
  submit: {
    errors: boolean;
    isPolling: boolean;
    isSending: boolean;
    exceptionCode: string | null | undefined;
  };
  formContent: {
    availablePaymentMethods: IPaymentMethod[];
    defaultPaymentMethod: IPaymentMethod;
  };
};

export function Payment({ values, formContent, submit, validation }: Props) {
  useEffect(() => {
    const { handlers } = values;
    const { availablePaymentMethods, defaultPaymentMethod } = formContent;

    if (!availablePaymentMethods?.length || !defaultPaymentMethod) {
      return; // default values not set
    }

    const currentPaymentMethod = find(availablePaymentMethods, {
      id: values.paymentMethod?.id
    });

    if (currentPaymentMethod) {
      return; // selected payment options is accepted
    }

    handlers.handlePaymentMethod(defaultPaymentMethod);
  }, [values, formContent]);

  return (
    <StepWrapper>
      <PaymentMethodSelector
        formContent={formContent}
        values={values}
        validation={validation}
      />

      <IntegrityText />
      {submit?.errors && !submit?.isPolling && !submit?.isSending && (
        <AlertMessageContainer messageStyle="error">
          {getPurchaseErrorMessage(submit?.exceptionCode)}
        </AlertMessageContainer>
      )}
    </StepWrapper>
  );
}
