import React, { useMemo } from 'react';
import styled from 'styled-components';

// Local components
import { Preview, PreviewWrapper, CFLogo, Watermark } from './components';

const Heading = styled.p`
  font-size: 2em;
`;

const Name = styled.p`
  font-size: 2em;
  font-family: Arial, sans-serif;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin: 0.25em 0 1em 0;
  width: 100%;
`;

const Description = styled.p`
  font-size: 1.75em;
  line-height: 1.333333333;
  margin-bottom: 1em;
  padding: 0 1em;
  width: 100%;
`;

const HeartIcon = styled.div`
  display: block;
  position: relative;
  width: 4em;
  height: 3.5em;
  margin: 0 auto 2em auto;

  svg {
    stroke: currentColor;
  }
`;

const Greeting = styled.p`
  font-family: Arial, sans-serif;
  font-size: 1em;
  padding-bottom: 3.073438807em;
  word-wrap: break-word;
  width: 100%;
`;
const StyledPreview = styled(Preview)`
  border-radius: 4px;
`;

export const MemorialGiftPreview = function (props) {
  const { values, fullSize } = props;

  const greeting = useMemo(() => {
    return (
      <Greeting>
        {values.productOptions.memorialGift.textGreeting
          .split('\n')
          .map((item, index) => (
            <span key={index}>
              {item}
              <br />
            </span>
          ))}
      </Greeting>
    );
  }, [values.productOptions.memorialGift.textGreeting]);

  return (
    <PreviewWrapper fullSize={fullSize}>
      <StyledPreview fullSize={fullSize} boxShadow={true}>
        <Heading>Till minne av</Heading>
        <Name>
          {values.productOptions.inMemoryOfContact?.firstName}{' '}
          {values.productOptions.inMemoryOfContact?.lastName}
        </Name>
        <Description>har Cancerfonden tacksamt tagit emot en gåva</Description>
        <HeartIcon>
          <svg
            viewBox="0 0 92 77"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(2.000000, 2.000000)">
                <path
                  d="M81.98475,5.88225 L81.98475,5.88225 C74.05075,-1.96075 61.18975,-1.96075 53.25675,5.88225 L43.96775,15.06625 L34.67775,5.88225 C26.74475,-1.96075 13.88375,-1.96075 5.94975,5.88225 C-1.98325,13.72525 -1.98325,26.44125 5.94975,34.28525 L43.94075,71.84625 L43.96775,71.82025 L43.99375,71.84625 L81.98475,34.28525 C89.91775,26.44125 89.91775,13.72525 81.98475,5.88225 Z"
                  strokeWidth="4"
                />
                <path
                  d="M76.81275,28.22625 C81.61475,23.47825 81.61475,15.78125 76.81275,11.03425 C74.41175,8.66025 71.26475,7.47325 68.11875,7.47325"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </HeartIcon>
        {greeting}
        <CFLogo />
        <Watermark isVisible={fullSize} />
      </StyledPreview>
    </PreviewWrapper>
  );
};
