import React, { useMemo, ReactNode } from 'react';
import styled from 'styled-components';

import { EditButton } from '~/App/shared/components/Donation/components/Forms/components/EditButton';
import { NextPartButton } from '~/App/shared/components/Donation/components/Forms/components/NextPartButton';
import { ColumnSegment } from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormHeading';
import { IValidation } from '../Validation';
import { MultiPartFormValues } from '.';
import { ButtonStyle } from '~/App/config/buttonStyles';

type MaxWidthProps = {
  direction: 'row' | 'column';
  align?: 'center';
};

const MaxWidth = styled.div<MaxWidthProps>`
  max-width: 32rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  align-items: ${({ align }) => align};
`;

type Props = {
  part: number;
  title: string;
  values: MultiPartFormValues;
  validation: IValidation;
  children: ReactNode;
  nextStepButton?: {
    text?: string;
    style?: ButtonStyle;
  };
};

export default function MultiPartFormSection({
  part,
  title,
  values,
  validation,
  nextStepButton,
  children
}: Props) {
  if (!values?.partOptions) {
    throw 'Must be inside of state';
  }

  const isActive = useMemo(() => values.partOptions?.currentPart === part, [
    part,
    values.partOptions?.currentPart
  ]);

  const isLast = useMemo(() => values.partOptions?.parts === part, [
    part,
    values.partOptions?.parts
  ]);

  return useMemo(
    () => (
      <>
        <ColumnSegment
          values={values}
          part={part}
          validation={validation}
          className={undefined}
          title={title}
          children={
            isActive ? (
              <MaxWidth direction="column">
                <FormHeading part={part} values={values} children={title} />
                {children}
              </MaxWidth>
            ) : (
              <MaxWidth direction="row" align="center">
                <FormHeading part={part} values={values} children={title} />
                <EditButton
                  part={part}
                  values={values}
                  validation={validation}
                  stepName={title}
                />
              </MaxWidth>
            )
          }
        />
        {isLast ? null : (
          <NextPartButton
            part={part}
            values={values}
            validation={validation}
            text={nextStepButton?.text}
            buttonStyle={nextStepButton?.style}
            stepName={title}
          />
        )}
      </>
    ),
    [
      children,
      isActive,
      isLast,
      part,
      title,
      validation,
      values,
      nextStepButton
    ]
  );
}
