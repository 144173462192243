import React, { useMemo } from 'react';
import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';
import {
  Email,
  Name,
  PrivateCompany,
  Address,
  Telephone,
  Ssn
} from '../fields';

import { ContactInputGroup } from './ContactInputGroup';
import { FlexWrapper } from './FlexWrapper';

export type ManualProps = {
  phone?: boolean | { placeholder?: string; instructions?: string };
  email?: boolean | string;
  privateCompany?: boolean;
  ssn?: boolean | string;
  disableName?: boolean;
  disableAddress?: boolean;
  ssnRequired?: boolean;
};

type Props = ManualProps & {
  props: {
    validation: IValidation;
    values: ContactCustomerValues;
  };
};

export function Manual({
  props,
  email = true,
  privateCompany = false,
  phone = false,
  ssn = false,
  disableName = false,
  disableAddress = false,
  ssnRequired
}: Props) {
  const phoneProps = useMemo(
    () => ({
      ...props,
      ...(typeof phone === 'boolean' ? {} : phone)
    }),
    [phone, props]
  );

  return (
    <ContactInputGroup>
      <FlexWrapper>
        <Name {...props} disabled={disableName} />
        {privateCompany && <PrivateCompany {...props} />}
        <Address {...props} disabled={disableAddress} />
        {ssn && (
          <Ssn
            {...props}
            instructionText={typeof ssn === 'string' ? ssn : undefined}
            required={ssnRequired}
          />
        )}
        {email && (
          <Email
            {...props}
            copy={typeof email === 'string' ? email : undefined}
          />
        )}
        {phone && <Telephone {...phoneProps} />}
      </FlexWrapper>
    </ContactInputGroup>
  );
}
