import React, { useMemo } from 'react';
import { useLocation } from 'react-router';

import { buildFormContent } from './formContent';
import { buildSchema } from './schema';
import { buildState } from './state';

import { useSubmit } from './hooks/useSubmit';
import { useState } from '../components/States';
import { useValidation } from '../../Validation';

import { Form } from './components/Form';
import { Redirect } from '../../Redirect';

type Props = {
  redirectPath: string;
  source?: string;
};

export function MonthlyKlarnaDonationGala({ redirectPath, source }: Props) {
  const location = useLocation();
  const formContent = useMemo(() => buildFormContent(redirectPath, location), [
    redirectPath,
    location
  ]);

  const schema = useMemo(buildSchema, []);
  const state = useMemo(() => buildState(formContent), [formContent]);

  const values = useState({
    values: state,
    formContent
  });

  const validation = useValidation({
    values,
    schema
  });

  const submit = useSubmit({
    initialPrice: state.productOptions?.product?.price,
    validation,
    values,
    source
  });

  return (
    <>
      {submit.redirectUrl && <Redirect to={submit.redirectUrl} external />}
      <Form
        formContent={formContent}
        values={values}
        validation={validation}
        submit={submit}
      />
    </>
  );
}
