import React from 'react';
import styled from 'styled-components';

// shared components
import Container from '~/App/shared/components/Container';
import { CombinedDonation } from '~/App/shared/components/Donation';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPolar};
`;

export default function CombinedDonationForm({ data }) {
  return (
    <Wrapper>
      <Container
        width="limited"
        paddingLeft="small"
        paddingRight="small"
        paddingTop="small"
        paddingBottom="small"
      >
        <CombinedDonation data={data} />
      </Container>
    </Wrapper>
  );
}
