import { useMemo } from 'react';
import { IAnswer, Score } from '~/types/IQuestionSession';
import { IScreen } from '../../SelfTest/types';

type Props = {
  screens: IScreen[];
  answers: IAnswer[];
};

export function useScore({ screens, answers }: Props) {
  const maximum = useMemo(
    () =>
      screens.reduce((prev: number, current: IScreen) => {
        if (current.type === 'QuestionScreen') {
          return Math.max(...current.alternatives.map((x) => x.value)) + prev;
        }

        if (current.type === 'BMIScreen') {
          return prev + 1;
        }

        return prev;
      }, 0),
    [screens]
  );

  const current = useMemo(
    () =>
      answers
        .map((x) => x.value || 0)
        .reduce((prev: number, current: number) => prev + current, 0),
    [answers]
  );

  const percent = useMemo(
    () => ((current + maximum) / (maximum + maximum)) * 100,
    [current, maximum]
  );

  const score = useMemo<Score>(() => {
    if (!answers.length) {
      return 1;
    }

    if (percent < 50) return -1;
    if (percent <= 60) return 0;

    return 1;
  }, [answers.length, percent]);

  return useMemo(
    () => ({
      score,
      percent
    }),
    [score, percent]
  );
}
