import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState
} from 'react';

import { IMeCollection } from '~/types/ICollection';

export type MemorialEditState = {
  values: IMeCollection;
  handlers: {
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleDateChange: (name: string, value: Date) => void;
    handleImageChange: ChangeEventHandler<HTMLInputElement>;
    setImageUrl: (url: string) => void;
  };
};

type Args = {
  data: IMeCollection;
  setError: (error: string | null) => void;
};

export function useMemorialEditState({ data, setError }: Args) {
  const [values, setValues] = useState<IMeCollection>(data);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValues((state) => ({
      ...state,
      [name]: value
    }));
  }, []);

  const handleDateChange = useCallback(
    (name: string, value: Date) => {
      setValues((state) => ({
        ...state,
        [name]: value
      }));
    },
    []
  );

  const setImageUrl = useCallback(
    (url: string) => setValues((state) => ({ ...state, imageUrl: url })),
    []
  );

  const handleImageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const image = event.target.files?.[0];

      if (!image) {
        return;
      }

      if (image.size > 20000000) {
        return setError(
          'Bilden du försöker ladda upp är för stor. Max filstorlek är 20MB.'
        );
      }

      setValues((state) => ({
        ...state,
        imageUrl: URL.createObjectURL(image)
      }));
    },
    [setError]
  );

  return useMemo<MemorialEditState>(
    () => ({
      values,
      handlers: {
        handleImageChange,
        handleDateChange,
        handleChange,
        setImageUrl
      }
    }),
    [handleChange, handleDateChange, handleImageChange, setImageUrl, values]
  );
}
