import { isCloudinaryUrl } from '~/App/helpers/media-helper';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';
import { collections, meCollections } from '~/App/helpers/http';
import { IValidation } from '~/App/shared/components/Validation';
import { MemorialEditState } from './useMemorialEditState';

const IMAGE_UPLOAD_EXCEPTION = 'imageUploadException';

export type MemorialEditSubmit = {
  isSaving: boolean;
  isEnabled: boolean;
  handleSaveSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  handleEnabledSubmit: () => Promise<void>;
};

type Args = {
  state: MemorialEditState;
  validation: IValidation;
  setError: (error: string | null) => void;
};

export function useMemorialEditSubmit({ state, validation, setError }: Args) {
  const [isEnabled, setEnabled] = useState(state.values.enabled);
  const [isSaving, setSaving] = useState(false);

  const navigate = useNavigate();

  const handleImageUpload = useCallback(async () => {
    const url = state.values.imageUrl;

    if (!url) {
      return url;
    }

    if (isCloudinaryUrl(url)) {
      return url;
    }

    try {
      const { data } = await uploadImage(url, {
        timeout: 30000,
        folder: 'collection-pictures'
      });
      const imageUrl = data?.secure_url;

      if (!imageUrl) {
        return null;
      }

      state.handlers.setImageUrl(imageUrl);

      return imageUrl;
    } catch (error) {
      setError('Något gick fel vid bilduppladdningen, var vänlig försök igen.');
    }

    return null;
  }, [setError, state.handlers, state.values.imageUrl]);

  const handleSaveSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      try {
        setSaving(true);

        await collections.edit({
          slug: `${state.values.slug}`,
          data: {
            collection: {
              name: state.values.name,
              image_url: await handleImageUpload(),
              birthDate: state.values.birthDate,
              deathDate: state.values.deathDate
            }
          }
        });

        setSaving(false);
        navigate('/min-sida/minnessidor');
      } catch (error) {
        switch (error) {
          case IMAGE_UPLOAD_EXCEPTION:
            break;

          default:
            setError('Något gick fel vid sparningen, var vänlig försök igen.');
            break;
        }

        setSaving(false);
      }
    },
    [
      handleImageUpload,
      navigate,
      setError,
      state.values.name,
      state.values.slug,
      state.values.birthDate,
      state.values.deathDate,
      validation
    ]
  );

  const handleEnabledSubmit = useCallback(async () => {
    setEnabled(true);

    try {
      await meCollections.enabled({
        slug: `${state.values.slug}`
      });

      navigate('/min-sida/minnessidor');
    } catch (error) {
      console.error(error);
      setEnabled(false);
    }
  }, [navigate, state.values.slug]);

  return useMemo(
    () => ({
      isSaving,
      isEnabled,
      handleSaveSubmit,
      handleEnabledSubmit
    }),
    [handleSaveSubmit, handleEnabledSubmit, isSaving, isEnabled]
  );
}
