import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { CreateMemory } from './CreateMemory';
import { loadData } from './CreateMemory.loadData';

const View = (props: unknown) => {
  const pageContent = usePageContent();
  const children = useMemo(
    () =>
      pageContent ? (
        <CreateMemory key={pageContent.data.slug} {...pageContent} {...props} />
      ) : null,
    [pageContent, props]
  );

  return children;
};

View.loadData = loadData;

export default View;
