import React, { useMemo } from 'react';
import styled from 'styled-components';

import { IBMIAnswer } from '~/types/IQuestionSession';
import { IBMIScreen } from '../../../../SelfTest/types';

import { Feedback } from './Feedback';
import { Card } from './Card';

const Text = styled.p`
  margin-bottom: 1rem;
`;

type Props = {
  screen: IBMIScreen;
  value: IBMIAnswer;
};

export function BMIResultScreen({ screen, value }: Props) {
  const bmi = useMemo(() => (value?.bmi ? Math.round(value.bmi) : null), [
    value.bmi
  ]);

  const contentMarkup = useMemo(() => {
    if (!bmi) {
      return null;
    }

    if (bmi < 18.5) {
      return (
        <Text
          children="Ditt BMI indikerar att du har undervikt. Om du har ett BMI under 18 bör du vända 
        dig till sjukvården för att se över ditt näringsintag."
        />
      );
    }

    if (bmi < 25) {
      return (
        <Text
          children="Ditt BMI indikerar hälsosam vikt vilket innebär en minskad risk för cancer. 
          Behåll vikten genom att äta hälsosam mat i lagom mängd och röra på dig 
          i vardagen."
        />
      );
    }

    if (bmi < 30) {
      return (
        <Text children="Ditt BMI indikerar övervikt vilket innebär en låg 
        - måttlig risk för överviktsrelaterad sjukdom, bland annat cancer." />
      );
    }

    return (
      <Text children="Ditt BMI indikerar övervikt vilket innebär en låg 
      - måttlig risk för överviktsrelaterad sjukdom, bland annat cancer." />
    );
  }, [bmi]);

  const moreMarkup = useMemo(
    () => (
      <>
        <Text children={screen.feedback} />
        <Feedback screen={screen} />
      </>
    ),
    [screen]
  );

  if (!bmi) {
    return null;
  }

  return (
    <Card
      title={`Ditt BMI: ${bmi}`}
      more={moreMarkup}
      content={contentMarkup}
      value={value}
    />
  );
}
