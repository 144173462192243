import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
  margin: 0.75rem 0;
`;

const LikeNumber = styled.p`
  margin: 0;
  padding-left: 0.25rem;
  font-weight: bold;
  align-items: center;
  font-size: 0.875rem;
`;

type Props = {
  likes: number;
};

export function Likes({ likes }: Props) {
  if (!likes) {
    return null;
  }

  return (
    <Wrapper>
      <img src="https://res.cloudinary.com/cancerfonden/image/upload/v1645005043/minnesida/icons-ui-share-cropped.svg" />
      <LikeNumber>{likes}</LikeNumber>
    </Wrapper>
  );
}
