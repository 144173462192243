import React from 'react';
import styled from 'styled-components';

// Shared components
import Container from '~/App/shared/components/Container';
import { Lottery } from '~/App/shared/components/Donation';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPolar};
`;

class LotteryForm extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <Wrapper>
        <Container
          width="limited"
          paddingLeft="small"
          paddingRight="small"
          paddingTop="small"
          paddingBottom="small"
        >
          <Lottery data={data} />
        </Container>
      </Wrapper>
    );
  }
}

export default LotteryForm;
