import React, { useMemo } from 'react';
import styled from 'styled-components';

import { collectionTypes } from '~/App/config/collectionTypes';

import { pink, global } from '~/App/config/themes';
import mediaHelper from '~/App/helpers/media-helper';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';
import { NewFormHeading } from '../../components/Forms/components/FormTypography';

import { ICollection } from '~/types/ICollection';
import { ProductValues } from '../../components/States';

const backgroundColor = featureIsEnabled('FEATURE_LOPBANDET_PINK')
  ? pink.exerciseApp.primaryThemeColor
  : global.exerciseApp.primaryThemeColor;

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -0.75rem;
`;

type FigureProps = {
  isLopbandet: boolean;
};

const Figure = styled.div<FigureProps>`
  display: block;
  width: 8.875rem;
  height: 8.875rem;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ isLopbandet }) =>
    isLopbandet
      ? backgroundColor
      : global.collectionItem.imageBackground};
`;

const CollectionImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const CollectionInformation = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.divider.alternativeColor};
`;

type Props = {
  collection: ICollection;
  values: ProductValues;
};

export function Summery({ collection, values }: Props) {
  const isLopbandet = collection.collectionType === collectionTypes.lopbandet;
  const collectionDistance = collection.totalDistance
    ? collection.totalDistance / 1000
    : 0;

  const imageUrl = useMemo(() => {
    const defaultUrl = isLopbandet
      ? 'https://res.cloudinary.com/cancerfonden/image/upload/v1567681619/assets/empty-collection_lopbandet.png'
      : 'http://res.cloudinary.com/cancerfonden/image/upload/v1561540461/grncs9xxyzmee8mywbvs.jpg';

    return mediaHelper.getUrl(collection.imageUrl || defaultUrl, {
      width: 142,
      height: 142,
      quality: 80,
      crop: 'fill',
      dpr: '2.0',
      radius: 142,
      fetch_format: 'png'
    });
  }, [collection.imageUrl, isLopbandet]);

  return useMemo(
    () => (
      <>
        <Figure isLopbandet={isLopbandet}>
          <CollectionImage src={imageUrl} />
        </Figure>
        <CollectionInformation>
          <NewFormHeading children={collection.name} />
          {isLopbandet && (
            <p>
              De har hittils sprungit {collectionDistance} km! Bidra till
              insamlingen genom att skänka valfritt belopp.
            </p>
          )}
          {!isLopbandet && (
            <p>
              De har hittills samlat in {parseInt(collection.status || '', 10)}{' '}
              kr! Bidra till insamlingen genom att skänka valfritt belopp.
            </p>
          )}
        </CollectionInformation>
        <NewFormHeading children="Din gåva" />
        <AmountWrapper>
          <span>Gåvobelopp</span>
          <span>
            <NewFormHeading
              children={`${values.productOptions.product.price || 0} kr`}
            />
          </span>
        </AmountWrapper>
      </>
    ),
    [
      collection.name,
      collection.status,
      collectionDistance,
      imageUrl,
      isLopbandet,
      values.productOptions.product.price
    ]
  );
}
