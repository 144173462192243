import React from 'react';

// Shared components
import { GeneralPackageForm } from './';
import { CompanyPackageSummary } from './components/Summaries';

export const CompanyPackageForm = (props) => {
  const { values, formContent } = props;

  const SummaryComponent = (innerProps) => {
    return (
      <CompanyPackageSummary
        part={values.partOptions.currentPart}
        area={innerProps.area}
        price={values.productOptions.product.price}
        packages={formContent.packages}
        activePackageId={values.product.id}
      />
    );
  };

  return <GeneralPackageForm summaryComponent={SummaryComponent} {...props} />;
};
