import { formatMoney } from 'accounting';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatDate } from '~/App/helpers/date.service';

import { card } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';
import { ICommerceOrder } from '~/types/ICommerceOrder';
import { Date } from '../../../shared/components/Date';
import { OrderData } from './OrderData';
import { OrderActions } from './OrderActions';

const Card = styled.div`
  ${card};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 0 0 !important;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${mq('≥small')`
     padding: 2.5rem 0 0 !important;
  `};
`;

const Content = styled.div`
  width: 100%;
`;

const Text = styled.div`
  font-size: 0.75rem;

  ${mq('≥small')`
    font-size: 0.875rem;
  `};
`;

const Reference = styled.div`
  font-weight: bold;
  font-size: 1.375rem;
`;

const TotalPrice = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;

type RowProps = {
  alignItems: 'flex-end' | 'flex-start';
};

const Row = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems};
  margin-bottom: 1rem;
`;

const Hr = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightDust};
`;

const StyledDate = styled(Date)`
  display: none;

  ${mq('≥small')`
    display: flex;
  `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PaddingX = styled(Row)`
  padding: 0 1.5rem;

  ${mq('≥small')`
     padding: 0 2.5rem;

  `};
`;

const MobileDate = styled.div`
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  ${mq('≥small')`
    display: none;
  `};
`;

type Props = {
  order: ICommerceOrder;
};

export function Order({ order }: Props) {
  const totalPrice = useMemo(
    () => formatMoney(order.totalPrice, 'kr', 0, ' ', undefined, '%v %s'),
    [order.totalPrice]
  );

  return (
    <Card>
      <Column>
        <PaddingX alignItems="flex-start">
          {order.createdAt && <StyledDate date={order.createdAt} />}
          <Content>
            {order.createdAt && (
              <MobileDate
                children={formatDate(order.createdAt || '', 'D MMM YYYY')}
              />
            )}

            <Row alignItems="flex-end">
              <div>
                <Text>Ordernummer</Text>
                <Reference children={order.reference} />
              </div>
              <TotalPrice children={totalPrice} />
            </Row>
            <Hr />
            <OrderData order={order} />
          </Content>
        </PaddingX>
        <OrderActions order={order} />
      </Column>
    </Card>
  );
}
