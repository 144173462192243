import React from 'react';
import { Heading } from './Heading';
import styled from 'styled-components';
import mediaHelper from '~/App/helpers/media-helper';
import { beat } from '~/App/helpers/animations';
import mq from '~/App/helpers/mq';
import { Width } from '~/types/Block';

type Props = {
  width: Width;
  headingSettings?: {
    fontSize: string;
  };
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img<Props>`
  height: 6.375rem;
  width: 7.6rem;
  margin-top: 3.625rem;
  background-size: cover;
  animation: 3.5s ease 0s infinite ${beat};

  ${mq<Props>('≥large')`
  width: ${({ width }) => (width === 'full' ? '9.5rem' : '5rem')};
  height: ${({ width }) => (width === 'full' ? '7.875rem' : '4.125rem')};
  margin-top: ${({ headingSettings }) =>
    headingSettings?.fontSize === 'heading1' ? '4.5rem' : '3.5rem'}; 
  `};
`;

export function Thanks({ width, headingSettings }: Props) {
  return (
    <>
      <Heading
        heading="Tack för din gåva"
        fontSize={headingSettings?.fontSize}
        width={width}
      />
      <Container>
        <Image
          width={width}
          headingSettings={headingSettings}
          src={mediaHelper.getUrl(
            'https://res.cloudinary.com/cancerfonden/image/upload/v1552481956/assets/white-icon-heart.svg'
          )}
        ></Image>
      </Container>
    </>
  );
}
