import styled, { css } from 'styled-components';

import { arrowLink } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

const Left = styled.button`
  ${arrowLink};
  background: none;
  border: none;
  height: 3rem;
  padding: 0;
  width: 3rem;
  margin: 0;
  z-index: 1;

  &:active,
  &:focus {
    outline: none;
  }

  &::after {
    font-size: 4rem;
    line-height: 0;
    transform: translateX(-50%) rotate(180deg);
    transition: color 0.2s ease-out;
    ${mq('<small')`
        left: 50%;
    `};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      &::after {
        color: ${({ theme }) => theme.colors.silverChalice};
      }
      &:hover {
        color: ${({ theme }) => theme.colors.silverChalice};
      }
    `};

  ${mq('<medium')`
    display: none;
  `};
`;

const Right = styled(Left)`
  z-index: 1;

  &::after {
    transform: translateX(-50%) translateY(-5px) rotate(0deg);
  }

  ${mq('<medium')`
    left: auto;
    right: 0.5rem;
  `};
`;

export const Arrows = {
  Left,
  Right
};
