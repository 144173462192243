import axios from 'axios';

axios.defaults.timeout = 60000;
axios.defaults.responseType = 'json';

function basicAuth(username: string | undefined, password: string | undefined) {
  if (!username || !password) {
    return null;
  }

  return `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`;
}

export const mwAxios = axios.create({
  baseURL: process.env.MIDDLEWARE_API,
  headers: { Authorization: `Token token=${process.env.MIDDLEWARE_API_KEY}` }
});

export const contentAxios = axios.create({
  baseURL: process.env.CONTENT_API
});

export const commerceAxios = axios.create({
  baseURL: process.env.COMMERCE_API,
  validateStatus: () => true,
  headers: {
    'Content-Type': 'application/vnd.api+json'
  }
});

export const siteAxios = axios.create({
  baseURL:
    typeof window === 'undefined'
      ? process.env.CLIENT_SITE_BASE_URL
      : location.origin,
  headers: {
    Authorization: basicAuth(
      process.env.ACCESS_USERNAME,
      process.env.ACCESS_PASSWORD
    )
  }
});

export const wufooAxios = axios.create({
  baseURL: 'https://cancerfonden.wufoo.com/api/v3/forms',
  timeout: 15000,
  responseType: 'json',
  headers: {
    Authorization: basicAuth(
      process.env.WUFOO_USERNAME,
      process.env.WUFOO_PASSWORD
    )
  }
});
