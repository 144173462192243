import React, { ComponentProps, ChangeEvent } from 'react';
import styled, { css } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { Layout } from '~/types/Layout';

// Shared components
import { RadioButton } from '~/App/shared/components/Fields/RadioButton';

type Value = string | number | boolean | null | undefined;

type ItemWrapperProps = {
  tinyLayout?: Layout;
  smallLayout?: Layout;
  largeLayout?: Layout;
};

const ItemWrapper = styled.li<ItemWrapperProps>`
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  flex-basis: ${({ tinyLayout }) => {
    switch (tinyLayout) {
      case '50':
        return 'calc(50% - 0.5rem)';
      case '100':
        return 'calc(100% - 0.5rem)';

      default:
        break;
    }

    return 'auto';
  }};

  ${mq<ItemWrapperProps>('≥tiny')`
    flex-basis: ${({ smallLayout }) => {
      switch (smallLayout) {
        case '50':
          return 'calc(50% - 0.5rem)';
        case '100':
          return 'calc(100% - 0.5rem)';

        default:
          break;
      }

      return 'auto';
    }};
  `};

  ${mq<ItemWrapperProps>('≥small')`
    flex-basis: ${({ largeLayout }) => {
      switch (largeLayout) {
        case '50':
          return 'calc(50% - 0.5rem)';
        case '100':
          return 'calc(100% - 0.5rem)';

        default:
          break;
      }

      return 'auto';
    }};
  `};
`;

type LabelProps = {
  checked: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
};

const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;
  padding: 10px;
  border: 1px solid
    ${({ theme, isInvalid }) =>
      isInvalid ? theme.colors.errorRed : theme.colors.lightDust};
  border-radius: 2px;
  cursor: pointer;
  background-color: ${({ theme, isInvalid }) =>
    isInvalid ? theme.colors.lightRed : theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.themeColors.primary};
      border-color: ${({ theme }) => theme.themeColors.primary};
    `};
`;

type Props = {
  value?: Value;
  checked: boolean;
  label: string;
  isValid?: boolean;
  isinValid?: boolean;
  tinyLayout?: Layout;
  smallLayout?: Layout;
  largeLayout?: Layout;
} & ComponentProps<typeof RadioButton>;

export function Item({
  value,
  checked,
  onChange,
  label,
  isValid,
  isInvalid,
  tinyLayout,
  smallLayout,
  largeLayout,
  ...props
}: Props) {
  return (
    <ItemWrapper
      className={props.className}
      tinyLayout={tinyLayout}
      smallLayout={smallLayout}
      largeLayout={largeLayout}
    >
      <Label checked={checked} isInvalid={isInvalid} isValid={isValid}>
        <RadioButton
          value={value}
          checked={checked}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange(event, label)
          }
          {...props}
        />
        <span>{label}</span>
      </Label>
    </ItemWrapper>
  );
}
