import React from 'react';
import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';

import { CompanyIdentity, Address, Email, Telephone, Name } from '../fields';
import { ContactInputGroup } from './ContactInputGroup';
import { FlexWrapper } from './FlexWrapper';

export type CompanyProps = {
  phone?: boolean;
  email?: boolean | string;
  addressRequired?: boolean;
  nameRequired?: boolean;
};

type Props = CompanyProps & {
  props: {
    values: ContactCustomerValues;
    validation: IValidation;
  };
};

export function Company({
  props,
  email = true,
  phone = false,
  addressRequired,
  nameRequired
}: Props) {
  return (
    <ContactInputGroup>
      <FlexWrapper>
        <CompanyIdentity {...props} />
        <Address {...props} required={addressRequired} />
        <Name {...props} required={nameRequired} />
        {phone && <Telephone {...props} />}
        {email && (
          <Email
            {...props}
            copy={typeof email === 'string' ? email : undefined}
          />
        )}
      </FlexWrapper>
    </ContactInputGroup>
  );
}
