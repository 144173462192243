import styled from 'styled-components';
import { heading3 } from '~/App/helpers/mixins';

type Props = {
  isValid: boolean;
  isInvalid: boolean;
  isStarted: boolean;
  isActive: boolean;
};

export const Number = styled.div<Props>`
  ${heading3};
  padding: 1.25rem;
  padding-right: ${({ isStarted, isValid, isInvalid, isActive }) => {
    return isStarted && (isValid || isInvalid || isActive) ? 0 : '1.25rem';
  }};
  display: flex;
  align-items: center;
  background-color: ${({ theme, isValid, isInvalid, isStarted }) => {
    if (isStarted) {
      if (isValid) {
        return theme.colors.darkApple;
      }

      if (isInvalid) {
        return theme.colors.errorRed;
      }
    }

    return theme.colors.darkBlue;
  }};
  color: ${({ theme }) => {
    return theme.colors.white;
  }};
`;
