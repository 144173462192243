import { merge } from 'lodash';

// Constants
import { gtmDetails } from '~/App/config/gtmDetails';

export const buildState = ({ data = {}, defaultState = {} }) => {
  const { defaultPrice = '150', campaignActivityId = null } = data;
  const PRODUCT_ID = data.productId;

  return merge(
    {},
    {
      product: { id: PRODUCT_ID },
      productOptions: {
        product: { price: defaultPrice },
        pul: true,
        campaignActivityId: campaignActivityId
      },
      paymentMethod: data.defaultPaymentMethod,
      customerContactType: data.defaultCustomerContactType,
      partOptions: {
        parts: 3,
        validationKeys: {
          1: ['productOptions.product.price'],
          2: [
            'productOptions.customerContact.company',
            'productOptions.customerContact.cid',
            'productOptions.customerContact.addressStreet',
            'productOptions.customerContact.addressZip',
            'productOptions.customerContact.addressCity',
            'productOptions.customerContact.firstName',
            'productOptions.customerContact.lastName',
            'productOptions.customerContact.phoneNumber',
            'productOptions.customerContact.ssn',
            'productOptions.customerContact.email'
          ],
          3: ['stripeCard', 'swishPhoneNumber']
        }
      },
      gtm: gtmDetails[PRODUCT_ID]
    },
    defaultState
  );
};
