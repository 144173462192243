import React, { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';

import styled from 'styled-components';

import { withAuthorization } from './components/Authorization';

// Shared components
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { Footer } from '~/App/views/Account/components/Footer';
import { heading1, heading3 } from '~/App/helpers/mixins';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { Avatar } from '~/App/shared/components/Avatar';
import { Padding } from '~/types/Block';
import { Navigation } from './components/Navigation';
import { TeaserOrNotification } from './components/TeaserOrNotification';
import { siteUrl } from '~/App/helpers/env';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 48rem;
  margin: auto;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
  margin: 3rem 0 0.75rem;
`;

const MarginBottom = styled.div`
  margin-bottom: 1.5rem;
`;

const Heading = styled.div`
  ${heading1};
`;
const Heading3 = styled.div`
  ${heading3};
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 1rem;
`;

const Link = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 1.125rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.6;
  }
`;

const BackLink = styled(Link)`
  &::before {
    content: ' ';
    transform: scaleX(-1);
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1599574628/assets/mail/arrow-blue.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    margin-right: 0.5rem;
  }
`;

const ProfileLink = styled(Link)`
  &::before {
    content: ' ';
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1566560711/assets/icons-profile-blue.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    margin-right: 0.5rem;
  }
`;

const padding: Padding = {
  top: 'large',
  bottom: 'large',
  left: 'small',
  right: 'small'
};

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.errorRedMedium};
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 1rem;
`;

type Props = {
  purchaseInfo:
    | { isMonthlyDonor: false }
    | { isMonthlyDonor: true; autogiroAmount: number };
};

function Account({ purchaseInfo }: Props) {
  const { user } = useAuthenticationContext();
  const location = useLocation();
  const navigation = useNavigate();

  const isStartPage = useMemo(() => location.pathname === '/min-sida', [
    location.pathname
  ]);

  const navigate = useCallback((path: string) => () => navigation(path), [
    navigation
  ]);

  if (!user) {
    return null;
  }

  if (isStartPage) {
    return (
      <BlockBackground backgroundColor="primary">
        <Container width="normal" padding={padding}>
          <Wrapper>
            <Row>
              <StyledAvatar size="mediumSmall" user={user} />
              <Heading children={`Hej ${user.firstName}!`} />
            </Row>
            <TeaserOrNotification purchaseInfo={purchaseInfo} />
            <SpaceBetweenRow>
              <Heading3 children="Mitt konto" />
              <ProfileLink
                onClick={navigate('/min-sida/profil')}
                children="Min profil"
              />

            </SpaceBetweenRow>
            <ErrorWrapper>
              <p>Hej!<br />
              Vi genomför ett byte av IT-plattform för Mina sidor.
              Det gör att du just nu inte kan se dina uppgifter här.
              Om du har du en egen insamling kan du administrera den från länken du fick i bekräftelsemejlet (som du fick när du startade insamlingen).<br /><br />
              Följ gärna din insamling på <a href={`${siteUrl()}/insamlingar`}>cancerfonden.se/insamlingar</a>.<br /><br />
              Frågor? Kontakta Givarservice på <a href="tel:0101991010">010-199 10 10</a>.</p>
            </ErrorWrapper>
            <Navigation />
          </Wrapper>
        </Container>
        <Footer />
      </BlockBackground>
    );
  }

  return (
    <BlockBackground backgroundColor="primary">
      <Container width="normal" padding={padding}>
        <Wrapper>
          <MarginBottom>
            <BackLink onClick={navigate('/min-sida')} children="Tillbaka" />
          </MarginBottom>
          <ErrorWrapper>
            <p>Hej!<br />
            Vi genomför ett byte av IT-plattform för Mina sidor.
            Det gör att du just nu inte kan se dina uppgifter här.
            Om du har du en egen insamling kan du administrera den från länken du fick i bekräftelsemejlet (som du fick när du startade insamlingen).<br /><br />
            Följ gärna din insamling på <a href={`${siteUrl()}/insamlingar`}>cancerfonden.se/insamlingar</a>.<br /><br />
            Frågor? Kontakta Givarservice på <a href="tel:0101991010">010-199 10 10</a>.</p>
          </ErrorWrapper>
          <Outlet />
        </Wrapper>
      </Container>
      <Footer />
    </BlockBackground>
  );
}

export default withAuthorization(Account);
