import React, { useMemo } from 'react';
import styled from 'styled-components';

import ChartJs from './Graph.ChartJs';
import AmCharts from './Graph.AmCharts';

import { Heading, Description, Source } from './Graph.styled';
import { isChartJs, isAmCharts } from './Graph.helpers';

import mq from '~/App/helpers/mq';
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const GraphContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${mq('>small')`
   flex-direction: ${({ position }) => {
     switch (position) {
       case 'right':
         return 'row';
       case 'left':
         return 'row-reverse';
       case 'bottom':
       default:
         return 'column';
     }
   }};
  `}
`;

const CopyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const GraphContent = styled.div`
  flex: 1;
`;

export function Graph({
  id,
  body,
  width,
  config,
  source,
  heading,
  logGraphConfig,
  position = 'bottom',
  stylingOptions = {},
  verticalSpacing = ''
}) {
  const padding = useMemo(
    () => ({
      top: verticalSpacing.split('topLarge').length === 2 ? 'large' : '',
      bottom: verticalSpacing.split('bottomLarge').length === 2 ? 'large' : ''
    }),
    [verticalSpacing]
  );

  const content = useMemo(() => {
    if (!heading && !body && !source) return null;

    return (
      <CopyContent>
        {heading && <Heading children={heading} />}
        {body && (
          <Description
            spacingTop={false}
            dangerouslySetInnerHTML={{
              __html: body
            }}
          />
        )}
        {position !== 'bottom' && source && <Source children={source} />}
      </CopyContent>
    );
  }, [body, heading, position, source]);

  const graph = useMemo(
    () => (
      <GraphContent>
        {isChartJs(config) && <ChartJs config={config} />}
        {isAmCharts(config) && <AmCharts config={config} />}
        {position === 'bottom' && source && <Source children={source} />}
      </GraphContent>
    ),
    [config, position, source]
  );

  if (isAmCharts(config) && logGraphConfig) {
    var str = JSON.stringify(config);
    console.log(str);
  }

  return (
    <BlockBackground {...stylingOptions} id={id}>
      <GraphContainer
        width={width}
        position={position}
        paddingTop={padding.top}
        paddingLeft="small"
        paddingRight="small"
        paddingBottom={padding.bottom}
      >
        {content}
        {graph}
      </GraphContainer>
    </BlockBackground>
  );
}
