import React from 'react';
import styled from 'styled-components';

// Shared components
import { Teaser } from '~/App/shared/components/Teaser';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { buttonStyles } from '~/App/config/buttonStyles';

const StyledTeaser = styled(Teaser)`
  margin: 2.125rem 0 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem 1.5rem;
  background: ${({ theme }) => theme.colors.seashell};
`;

const Heading = styled.p`
  margin-bottom: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-transform: uppercase;
  font-size: 1rem;
`;

type Props = {
  amount: number;
};

export function IncreaseMonthlyDonationTeaser({ amount }: Props) {
  return (
    <StyledTeaser
      heading="Tack för att du är månadsgivare och ger varje månad!"
      description="Höj din månadsgåva och ge ännu fler drabbade en bättre framtid."
      imageUrl="https://res.cloudinary.com/cancerfonden/image/upload/v1637673634/assets/bahar-mobil_3x.jpg"
      desktopImageUrl="https://res.cloudinary.com/cancerfonden/image/upload/v1637673634/assets/bahar-desktop_3x.jpg"
    >
      <Wrapper>
        <Heading>Du ger just nu {amount} kr</Heading>
        <PrimaryButton
          to="/stod-oss/hoj-manadsbeloppet"
          buttonType="link"
          buttonStyle={buttonStyles.cta}
          children="Höj ditt månadsbelopp"
        />
      </Wrapper>
    </StyledTeaser>
  );
}
