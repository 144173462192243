import React, { useMemo } from 'react';
import { find } from 'lodash';

// Constants
import { paymentMethods } from '~/App/config/paymentMethods';
import { IPaymentMethod } from '~/types/IPaymentMethod';

// Shared components
import { useValidation } from '~/App/shared/components/Validation';

// Local components
import { useSubmit } from '../components/Submits';
import PaymentRedirect from '../components/PaymentRedirect';

import { Form } from './components/Form';
import { useLotteryTicketState } from '../components/States';

import {
  LeftColumn,
  RightColumn,
  ColumnCard,
  Wrapper
} from '../components/Forms/components/TwoColumn';

import { useSchema } from './useSchema';
import { useState } from './useState';
import {
  CustomerContactType,
  customerContactTypes
} from '~/App/config/customerContactTypes';
import { useMultiPartFormState } from '../../MultiPartForm';
import { mq } from '~/lib/mq';
import styled from 'styled-components';
import { LotterySummary } from './components/Summary';
import BankId from '../components/BankId';
import { SubmitValues } from '../components/Submits/Submit/types/SubmitValues';

const TwoColumnWrapper = styled(Wrapper)`
  ${mq('≥medium')`
    justify-content: space-between;
  `};
`;

export type LotteryFormContent = {
  lotteryTicketAmounts: string[];
  defaultQuantity: string;
  productId: number;
  campaignActivityId?: string | number;
  originPath: string;
  redirectPath: string;
  heading?: string;
  availableCustomerContactTypes: CustomerContactType[];
  defaultCustomerContactType: CustomerContactType;
  availablePaymentMethods: IPaymentMethod[];
  defaultPaymentMethod: IPaymentMethod;
  lotteryImage: string;
  anotherDeviceText?: string;
  taxReductionInfoLink?: string | undefined;
  infoTexts: { icon: string; text: string }[];
};

export type LotteryProps = {
  data: LotteryFormContent;
};

export default function Lottery({ data }: LotteryProps) {
  const schema = useSchema();
  const state = useState({ data });

  const isCompany = useMemo(
    () =>
      data.availableCustomerContactTypes.indexOf(
        customerContactTypes.company
      ) !== -1,
    [data.availableCustomerContactTypes]
  );

  const paymentMethodCreditCardAvailable = useMemo(
    () =>
      !!find(data.availablePaymentMethods, {
        id: paymentMethods.creditCard
      }),
    [data]
  );

  const values = useLotteryTicketState({
    values: state,
    formContent: data
  });

  const validation = useValidation({
    values,
    schema,
    hasStripe: isCompany && paymentMethodCreditCardAvailable
  });

  const multiPartValues = useMultiPartFormState({
    values,
    validation,
    parts: 3
  });

  const submit = useSubmit({
    values: multiPartValues as SubmitValues,
    validation
  });

  return (
    <TwoColumnWrapper>
      <LeftColumn>
        <PaymentRedirect submit={submit} redirectPath={data.redirectPath} />
        {submit.isBankId ? (
          <BankId
            failed={submit.errors}
            loading={submit.isSending || submit.isPolling}
            inline={true}
            resetBankId={submit.resetBankId}
            qrCode={submit.qrCode}
            autoStartToken={submit.autoStartToken}
          />
        ) : (
          <Form
            values={multiPartValues}
            submit={submit}
            validation={validation}
            formContent={data}
          />
        )}
      </LeftColumn>
      <RightColumn>
        <ColumnCard>
          <LotterySummary
            lotteryImage={data.lotteryImage}
            values={multiPartValues}
            infoTexts={data.infoTexts}
          />
        </ColumnCard>
      </RightColumn>
    </TwoColumnWrapper>
  );
}
