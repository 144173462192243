import React, { useMemo } from 'react';

import { Wrapper } from './LargeShareButtons.styled';
import { Email } from './LargeShareButtons.Email';
import { Facebook } from './LargeShareButtons.Facebook';
import { Sms } from './LargeShareButtons.Sms';
import { More } from './LargeShareButtons.More';
import { X } from './LargeShareButtons.X';
import { CopyLink } from './LargeShareButtons.CopyLink';

type Props = {
  to: string;
  copyLink?: boolean;
  email?: {
    subject: string;
    body: string;
  };
  sms?: {
    body: string;
  };
  more?: {
    title: string;
    body: string;
  };
  facebook?:
    | boolean
    | {
        text: string;
      };
  x?:
    | boolean
    | {
        text: string;
        hashtags?: string[];
      };
};

export function LargeShareButtons({
  to,
  email,
  sms,
  more,
  x,
  facebook,
  copyLink
}: Props) {
  return useMemo(
    () => (
      <Wrapper>
        {sms && <Sms {...sms} />}
        {facebook && <Facebook to={to} {...facebook} />}
        {x && <X to={to} {...x} />}
        {email && <Email {...email} />}
        {more && <More to={to} {...more} />}
        {copyLink && <CopyLink to={to} />}
      </Wrapper>
    ),
    [copyLink, email, facebook, more, sms, to, x]
  );
}

export default LargeShareButtons;
