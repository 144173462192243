const questionsString = `Vid frågor, ring givarservice på 010-199 10 10.`;
const generalLoginError = `Tekniskt fel.`;
const generalPaymentError = `Något gick fel vid betalningen, vänligen försök igen. ${questionsString}`;
const generalPaymentErrorBank = `Något gick fel vid betalningen, vänligen kontakta din bank eller använd ett annat kort. ${questionsString}`;
const incorrectNumber = `Kortnummret är felaktigt, vänligen försök igen. ${questionsString}`;
const incorrectCvc = `CVC-koden är felaktig, vänligen försök igen ${questionsString}`;
const incorrectExpiryYear = `Utgångsår är felaktig, vänligen försök igen. ${questionsString}`;

export const exceptionMessages: Record<string, string> = {
  RP06: `Det pågår redan en annan Swishbetalning, vänligen försök igen. ${questionsString}`,
  ACMT03: `Du har inte aktiverat Swish. Prova gärna ett annat betalsätt. ${questionsString}`,
  ACMT01: generalPaymentError,
  ACMT07: generalPaymentError,
  TM01: `Swish avbröts innan betalningen påbörjades, vänligen försök igen. ${questionsString}`,
  RF07: `Swishtransaktionen medgavs inte. Prova gärna ett annat betalsätt. ${questionsString}`,
  BE18: `Ditt Swishnummer är inte giltigt. Prova gärna ett annat betalsätt. ${questionsString}`,
  FF10: generalPaymentError,
  BANKIDCL: `BankID-godkännandet avbrutet, vänligen försök igen. ${questionsString}`,
  BANKIDUNKN: `BankID kunde inte verifieras, vänligen försök igen. ${questionsString}`,
  BANKIDONGOING: `BankID upptaget med annan signering, vänligen försök igen. ${questionsString}`,
  processing_error: generalPaymentError,
  expired_card: `Kortet har gått ut. Prova gärna ett annat betalsätt. ${questionsString}`,
  incorrect_cvc: incorrectCvc,
  invalid_cvc: incorrectCvc,
  incorrect_number: incorrectNumber,
  invalid_number: incorrectNumber,
  invalid_expiry_month: `Utgångsmånad är felaktig, vänligen försök igen. ${questionsString}`,
  invalid_expiry_year: incorrectExpiryYear,
  card_declined_authentication_required: `Verifiering misslyckades, vänligen försök igen. ${questionsString}`,
  card_declined_approve_with_id: `Verifiering misslyckades, vänligen försök igen. ${questionsString}`,
  card_declined_call_issuer: generalPaymentErrorBank,
  card_declined_card_not_supported: `Korttyp stöds inte, vänligen försök igen med ett annat kort. ${questionsString}`,
  card_declined_card_velocity_exceeded: generalPaymentErrorBank,
  card_declined_currency_not_supported: generalPaymentErrorBank,
  card_declined_do_not_honor: generalPaymentErrorBank,
  card_declined_do_not_try_again: generalPaymentErrorBank,
  card_declined_duplicate_transaction: `En liknande transaktion har nyligen registrerats. Var vänlig kolla ditt kontoutdrag och försök senare. ${questionsString}`,
  card_declined_expired_card: `Kortet har gått ut. Prova gärna ett annat betalsätt. ${questionsString}`,
  card_declined_fraudulent: generalPaymentError,
  card_declined_generic_decline: generalPaymentError,
  card_declined_incorrect_number: incorrectNumber,
  card_declined_incorrect_cvc: incorrectCvc,
  card_declined_insufficient_funds: `Köpet medges ej. Prova gärna ett annat betalsätt. ${questionsString}`,
  card_declined_invalid_account: generalPaymentErrorBank,
  card_declined_invalid_amount: generalPaymentErrorBank,
  card_declined_invalid_cvc: incorrectCvc,
  card_declined_invalid_expiry_year: incorrectExpiryYear,
  card_declined_invalid_number: incorrectNumber,
  card_declined_issuer_not_available: `Vi fick inte kontakt med din bank, vänligen försök igen. ${questionsString}`,
  card_declined_lost_card: generalPaymentError,
  card_declined_merchant_blacklist: generalPaymentError,
  card_declined_new_account_information_available: generalPaymentErrorBank,
  card_declined_no_action_taken: generalPaymentErrorBank,
  card_declined_not_permitted: generalPaymentErrorBank,
  card_declined_pickup_card: generalPaymentErrorBank,
  card_declined_processing_error: generalPaymentError,
  card_declined_reenter_transaction: generalPaymentError,
  card_declined_restricted_card: generalPaymentErrorBank,
  card_declined_revocation_of_all_authorizations: generalPaymentErrorBank,
  card_declined_revocation_of_authorization: generalPaymentErrorBank,
  card_declined_security_violation: generalPaymentErrorBank,
  card_declined_service_not_allowed: generalPaymentErrorBank,
  card_declined_stolen_card: generalPaymentError,
  card_declined_stop_payment_order: generalPaymentErrorBank,
  card_declined_testmode_decline: `Du använde ett testkort, var vänlig försök igen med ett riktigt kort. ${questionsString}`,
  card_declined_transaction_not_allowed: generalPaymentErrorBank,
  card_declined_try_again_later: generalPaymentError,
  card_declined_withdrawal_count_limit_exceeded: `Köpet medges ej. Prova gärna ett annat betalsätt. ${questionsString}`,
  RFA3: `Åtgärd avbruten`,
  RFA4: `En identifiering eller underskrift för det här personnumret är redan påbörjad.`,
  RFA5: `Internt tekniskt fel`,
  RFA6: `Åtgärd avbruten`,
  RFA8: `BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din Bank.`,
  RFA16: `Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.`,
  RFA17: `BankID-appen verkar inte finnas i din dator eller telefon. Installera den och hämta ett BankID hos din internetbank. 
  Installera appen från din appbutik eller https://install.bankid.com.`,
  RFA17B: `Misslyckades att läsa av QR koden. Starta BankID-appen och läs av QR koden. Om du inte har BankID-appen måste du installera den och hämta ett BankID hos din internetbank. 
  Installera appenfrån din appbutik eller https://install.bankid.com.	`,
  RFA22: `Okänt fel.`,
  PollTimeoutError: `Det tog för lång tid, försök igen.`,
  generalLoginError: generalLoginError,
  generalPaymentError: generalPaymentError
};
