import React, { useCallback, useMemo } from 'react';
import { CSSProperties } from 'styled-components';
import { Message } from '~/App/shared/components/Fields/Components';
import {
  Wrapper,
  Item
} from '~/App/shared/components/Fields/RadioButtonHorizontal/';

type Value = string | number | boolean | undefined | null;
type Layout = '100' | '50' | 'auto';

type Option<T> = {
  value: T;
  label: string;
};

type Props<T> = {
  value: T;
  setValue: (value: T) => void;
  errors?: string[];
  isInvalid?: boolean;
  isValid?: boolean;
  tinyLayout?: Layout;
  smallLayout?: Layout;
  largeLayout?: Layout;
  options: Option<T>[];
  style?: CSSProperties;
};

export default function Selector<T extends Value>({
  value,
  setValue,
  isValid,
  isInvalid,
  options = [],
  errors = [],
  smallLayout,
  tinyLayout,
  largeLayout,
  ...props
}: Props<T>) {
  const handleChange = useCallback((value: T) => () => setValue(value), [
    setValue
  ]);

  const getAutomaticLayout = useCallback(
    (index: number): Layout => {
      if (options.length % 2 === 0) {
        return '50';
      }

      if (index === options.length - 1) {
        return '100';
      }

      return '50';
    },
    [options.length]
  );

  const renderItem = useCallback(
    (option: Option<T>, index: number) => (
      <Item
        value={value}
        key={`Selector-${option.value}`}
        checked={option.value === value}
        onChange={handleChange(option.value)}
        label={option.label}
        smallLayout={smallLayout || getAutomaticLayout(index)}
        tinyLayout={tinyLayout || getAutomaticLayout(index)}
        largeLayout={largeLayout || getAutomaticLayout(index)}
        isValid={isValid}
        isInvalid={isInvalid}
      />
    ),
    [
      handleChange,
      getAutomaticLayout,
      isInvalid,
      isValid,
      largeLayout,
      smallLayout,
      tinyLayout,
      value
    ]
  );

  return useMemo(
    () => (
      <>
        <Wrapper children={options.map(renderItem)} {...props} />
        {errors ? <Message isInvalid={isInvalid} children={errors[0]} /> : null}
      </>
    ),
    [errors, isInvalid, options, props, renderItem]
  );
}
