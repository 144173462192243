import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { heading1 } from '~/App/helpers/mixins';
import { IAnswer } from '~/types/IQuestionSession';
import { IQuestionSection, IScreen } from '../../../SelfTest/types';
import { useScore } from '../../hooks/useScore';
import { Screen } from './components/Screen';
import { mq } from '~/App/helpers/mq';

const Wrapper = styled.div`
  padding: 2rem 1rem;
`;

const ResultCards = styled.div`
  max-width: 72.5rem;
  margin: auto;

  ${mq('≥small')`
    padding: 0 1rem;
    display: grid;
    grid-template-columns: calc(50% - 1.25rem) calc(50% - 1.25rem);
    grid-gap: 2.5rem;
  `};
`;

const Heading = styled.h2`
  ${heading1};
  line-height: 1.25;
  margin-bottom: 0.75rem;
`;

const TextWrapper = styled.div`
  margin-bottom: 3rem;
  max-width: 70ch;
`;

const Text = styled.p`
  color: black;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  max-width: 73rem;
  margin: 0 auto;

  ${mq('≥small')`
    padding: 0 1rem;
  `};
`;

type Props = {
  section: IQuestionSection;
  answers: IAnswer[];
};

export function ResultSection({ section, answers }: Props) {
  const questionIds = useMemo(() => section.screens.map((x) => `${x.id}`), [
    section
  ]);

  const { score } = useScore({
    screens: section.screens,
    answers: answers.filter((x) => questionIds.includes(`${x.questionId}`))
  });

  const renderScreen = useCallback(
    (screen: IScreen, index) => (
      <Screen
        key={index}
        screen={screen}
        value={answers.find((x) => `${x.questionId}` === `${screen.id}`)}
      />
    ),
    [answers]
  );

  return (
    <Wrapper>
      <Content>
        <Heading children={section.name} />
        <TextWrapper>
          <Text children={section.result[score]} />
          <Text children={section.summary} />
        </TextWrapper>
      </Content>
      <ResultCards children={section.screens.map(renderScreen)} />
    </Wrapper>
  );
}
