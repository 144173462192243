import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';
import { link } from '~/App/helpers/mixins';
import { doubleRight } from '~/App/helpers/icons';

// shared components
import { View } from './components/View';

import { useLoginModal } from '~/App/shared/hooks/use-login-modal';
import { useScrollLock } from '~/App/contexts/ScrollLock';
import useLogin from '~/App/shared/hooks/use-login';

type WrapperProps = {
  show: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  position: fixed;
  top: 116px;
  height: calc(100vh - 56px);
  right: 0;
  width: 100%;
  transition: opacity 0.12s cubic-bezier(0.2, 0.7, 0.5, 1);
  opacity: ${({ show }) => (show ? '1' : '0')};
  z-index: 2;
  overflow: scroll;
  overflow-x: visible;

  ${mq('≥small')`
    height: 592px;
  `};

  ${mq<WrapperProps>('≥mediumLarge')`
    transition: all 0.33s cubic-bezier(0.2, 0.7, 0.5, 1);
    max-height: 100vh;
    height: 100vh;
    width: 514px;
    top: 0;
    opacity: 1;
    transform: ${({ show }) =>
      show ? 'translateX(0)' : 'translateX(100%) translateX(40px)'};
  `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 1;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

  ${mq('≥small')`
    height: 100%;
  `};

  ${mq('≥mediumLarge')`
    height: 100vh;
  `};
`;

const CloseTab = styled.div`
  position: absolute;
  bottom: 33px;
  left: -33px;
  background-color: ${({ theme }) => theme.colors.white};
  height: 52px;
  width: 52px;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.15s cubic-bezier(0.2, 0.5, 0.7, 1.3),
    background-color 0.3s cubic-bezier(0.2, 0.5, 0.7, 1.3);
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      theme.themeColors.primaryBackgroundColor};
    transform: translateX(-7px);
  }

  &::after {
    ${doubleRight};
    font-size: 0.9rem;
    top: 15px;
    left: 11px;
    color: ${({ theme }) => theme.themeColors.primary};
  }

  ${mq('<mediumLarge')`
    display: none;
  `};
`;

const Link = styled.p`
  ${link};
  text-decoration: underline;
  padding: 1.5rem 0 1.5rem 0;
  font-size: 1rem;
  text-align: center;
`;

export function Modal() {
  const loginModal = useLoginModal();
  const login = useLogin();
  const scrollLock = useScrollLock();

  const [isOpen, setOpen] = useState<boolean>(loginModal.showLoginModal);

  const handleClose = useCallback(
    () => loginModal.handlers.toggleLogin(false),
    [loginModal.handlers]
  );

  useEffect(() => {
    const timeout = setTimeout(() => setOpen(loginModal.showLoginModal), 200);
    return () => clearTimeout(timeout);
  }, [loginModal.showLoginModal]);

  useEffect(() => scrollLock.lock(loginModal.showLoginModal), [
    scrollLock,
    loginModal.showLoginModal
  ]);

  return useMemo(() => {
    if (!loginModal.showLoginModal) {
      return null;
    }
    return (
      <Wrapper show={isOpen}>
        <Content>
          <View view={loginModal.loginView} />
          {!login.isPolling && <Link onClick={handleClose} children="Stäng" />}
        </Content>
        <CloseTab onClick={handleClose} />
      </Wrapper>
    );
  }, [handleClose, isOpen, loginModal.loginView, loginModal.showLoginModal]);
}
