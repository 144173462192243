import React, { ReactNode } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { card } from '~/App/helpers/mixins';

const FormInfoBoxWrapper = styled.div`
  ${card}
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;

  ${mq('<medium')`
    padding-top: 7.5rem;
    margin-top: 2.5rem;
  `};

  ${mq('≥medium')`
    flex-basis: auto;
    align-items: center;
    margin-bottom: 1rem;
    padding: 2rem;
  `};
`;

const FormInfoDesktopText = styled.div`
  width: 100%;
  white-space: pre-wrap;

  ${mq('<medium')`
    display: none;
  `};
`;
const FormInfoMobileText = styled.div`
  width: 100%;
  white-space: pre-wrap;

  ${mq('>medium')`
    display: none;
  `};
`;

type Props = {
  children: ReactNode;
};

export function FormInfoBox({ children }: Props) {
  return (
    <FormInfoBoxWrapper>
      <FormInfoDesktopText children={children} />
      <FormInfoMobileText children={children} />
    </FormInfoBoxWrapper>
  );
}
