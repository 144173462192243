import React, { useEffect, useRef } from 'react'
import styled from 'styled-components';
import loadScript from '~/App/helpers/load-script';
import { useInViewport } from '~/App/shared/hooks/use-in-viewport';

const SCRIPT = '//translate.google.com/translate_a/element.js?cb=initGoogleTranslate';
const ID = 'google_translate_element'

declare global {
  interface Window {
    initGoogleTranslate: any;
    google: any;
  }
}

const Wrapper = styled.div`
  padding: 1rem 0;

  select.goog-te-combo {
    font-family: inherit !important;
    color: ${({ theme }) => theme.colors.gray} !important;
  }
  
  .goog-te-gadget, .goog-logo-link {
    font-family: inherit !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export default function GoogleTranslate() {

  const [inViewport, ref] = useInViewport<HTMLDivElement>();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!inViewport) return;
    if (isInitialized.current) return;

    isInitialized.current = true;

    loadScript({ url: SCRIPT });

    window.initGoogleTranslate = () => {
      const options = {
        pageLanguage: 'sv'
      };

      new window.google.translate.TranslateElement(options, ID)
    }

  }, [inViewport])

  return (
    <Wrapper id={ID} ref={ref} />
  );
}