import React, { useState } from 'react';

// Shared components
import { useValidation } from '~/App/shared/components/Validation';
import { AccountColumn } from '~/App/views/Account/shared/components/AccountColumn';
import { AccountHeading1 } from '~/App/views/Account/shared/components/AccountHeadings';

// Local components
import { MemorialForm } from './components/MemorialForm';
import { schema } from './MemorialSchema';

import { IMeCollection } from '~/types/ICollection';
import { useMemorialEditState } from './hooks/useMemorialEditState';
import { useMemorialEditSubmit } from './hooks/useMemorialEditSubmit';

type Props = {
  data: IMeCollection;
};

export function MemorialEdit({ data }: Props) {
  const [error, setError] = useState<string | null>(null);

  const state = useMemorialEditState({ data, setError });
  const validation = useValidation({
    values: state.values,
    schema
  });

  const submit = useMemorialEditSubmit({
    setError,
    validation,
    state
  });

  return (
    <>
      <AccountColumn>
        <AccountHeading1>Redigera Minnessida</AccountHeading1>
        <MemorialForm
          validation={validation}
          submit={submit}
          state={state}
          error={error}
        />
      </AccountColumn>
    </>
  );
}
