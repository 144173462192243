import React, { useMemo } from 'react';

import { ISubmit } from '~/App/shared/components/Donation/components/Submits';
import { IValidation } from '~/App/shared/components/Validation';
import { ICmsProduct, CmsProductTarget } from '~/types/ICmsProduct';
import { paymentMethods } from '~/App/config/paymentMethods';

import {
  MultiPartFormValues,
  Section
} from '~/App/shared/components/MultiPartForm';
import { StepWrapper } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';
import { AlertMessageContainer } from '~/App/shared/components/Elements';
import { getPurchaseErrorMessage } from '~/App/shared/components/Donation/components/Forms/helpers';

import PaymentMethodSelector from '~/App/shared/components/Donation/components/Forms/components/PaymentMethodSelector';
import { IntegrityText } from '~/App/shared/components/Donation/components/Forms/components/IntegrityText';
import { StateValues } from '~/App/shared/components/Donation/components/States';

type Props = {
  product: ICmsProduct;
  values: MultiPartFormValues & StateValues;
  validation: IValidation;
  submit: ISubmit;
};

type PaymentMethod = {
  id: number;
  name: string;
  slug: string;
};

export default function FormPartPayment({
  validation,
  values,
  product,
  submit
}: Props) {
  const companyPaymentMethods = useMemo<PaymentMethod[]>(
    () => [
      {
        id: paymentMethods.swish,
        name: 'Swish',
        slug: 'swish'
      },
      {
        id: paymentMethods.creditCard,
        name: 'Betalkort',
        slug: 'betalkort'
      },
      {
        id: paymentMethods.invoice,
        name: 'Faktura',
        slug: 'faktura'
      }
    ],
    []
  );

  const privatePaymentMethods = useMemo<PaymentMethod[]>(
    () => [
      {
        id: paymentMethods.swish,
        name: 'Swish',
        slug: 'swish'
      },
      {
        id: paymentMethods.creditCard,
        name: 'Betalkort',
        slug: 'betalkort'
      }
    ],
    []
  );

  const isCompany = useMemo(() => product.target === CmsProductTarget.company, [
    product
  ]);

  const formContent = useMemo(
    () => ({
      availablePaymentMethods: isCompany
        ? companyPaymentMethods
        : privatePaymentMethods
    }),
    [companyPaymentMethods, isCompany, privatePaymentMethods]
  );

  return (
    <Section
      values={values}
      part={3}
      validation={validation}
      title="Välj betalsätt"
    >
      <StepWrapper>
        <PaymentMethodSelector
          formContent={formContent}
          values={values}
          validation={validation}
        />
        <IntegrityText />
        {submit.errors && !submit.isPolling && !submit.isSending && (
          <AlertMessageContainer messageStyle="error">
            {getPurchaseErrorMessage(submit.exceptionCode)}
          </AlertMessageContainer>
        )}
      </StepWrapper>
    </Section>
  );
}
