import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import mq from '~/App/helpers/mq';

// Helpers
import { siteUrl } from '~/App/helpers/env';

const ButtonWrapper = styled.div`
  position: relative;
  ${mq('≥small')`
  display: inline-block;
  `};
`;

const Notification = styled.div`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 50%;
  transform: ${({ show }) =>
    show ? 'translate(-50%, 0.25rem)' : 'translate(-50%, 0.75rem)'};
  opacity: ${({ show }) => (show ? '0' : '1')};
  right: 0;
  background: ${({ theme }) => theme.colors.darkCharcoal};
  color: ${({ theme }) => theme.colors.white};
  color: white;
  padding: 0.25rem;
  width: 7rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  text-align: center;
  transition: ${({ show }) =>
    show
      ? 'transform 0.3s cubic-bezier(0.1,0.6,0.3,1.15), opacity 0.4s ease-out 1.5s'
      : 'none'};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: ${({ theme }) => theme.colors.darkCharcoal};
    border-bottom: 0;
    margin-left: -8px;
    margin-bottom: -8px;
  }
`;

export default function ShareLink({ children, to, className = '' }) {
  const [showNotification, setShowNotification] = useState(false);
  const notificationTimer = useRef();
  const location = useLocation();

  useEffect(() => {
    return () => {
      if (!notificationTimer.current) return;
      clearTimeout(notificationTimer.current);
    };
  }, []);

  const copyUrlToClipboard = useCallback(
    (shareUrl) => () => {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', shareUrl);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);

      setShowNotification(true);

      notificationTimer.current = setTimeout(() => {
        setShowNotification(false);
      }, 1800);
    },
    [setShowNotification]
  );

  const defaultTo = useMemo(() => siteUrl(location.pathname), [
    location.pathname
  ]);

  return (
    <ButtonWrapper>
      <button
        onClick={copyUrlToClipboard(to || defaultTo)}
        className={className}
        children={children}
      />
      <Notification show={showNotification}>Länk kopierad!</Notification>
    </ButtonWrapper>
  );
}
