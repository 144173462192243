import React from 'react';
import styled from 'styled-components';

import { Modal } from '~/App/shared/components/Elements';
import { mq } from '~/lib/mq';
import { CollectionType } from '~/types/ICollection';
import { CollectionImage } from '../../../components/CollectionImage';

const StyledModal = styled(Modal)`
  padding: 2rem;

  ${mq('<small')`
    padding: 0 0 1rem;
  `};
`;

type Props = {
  imageUrl: string;
  collectionType: CollectionType;
  showProgressBadge: boolean;
  isOpen: boolean;

  onClose: () => void;
};

export function PreviewModal({
  imageUrl,
  collectionType,
  showProgressBadge,
  isOpen,
  onClose
}: Props) {
  return isOpen ? (
    <StyledModal
      closeModal={onClose}
      closeButton={{ text: 'Stäng förhandsgranskning', border: true }}
    >
      <CollectionImage
        collection={{ imageUrl, collectionType, showProgressBadge }}
        percentage={50}
      />
    </StyledModal>
  ) : null;
}
