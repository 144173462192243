export const get = (name: string) => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const nameEQ = name + '=';
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return undefined;
};

export const getAll = (): Record<string, unknown> => {
  if (typeof window === 'undefined') {
    return {};
  }

  return document.cookie.split(';').reduce((cookies, cookie) => {
    const [name, val] = cookie.split('=').map((c) => c.trim());

    return {
      ...cookies,
      [name]: val
    };
  }, {});
};

export const remove = (name: string) => set(name, '', -1000);

export const set = (name: string, value: string, hours?: number) => {
  document.cookie = `${name}=${value}${getExpires(
    hours
  )}; path=/${getSecure()}`;
};

const getExpires = (hours?: number): string => {
  if (!hours) {
    return '';
  }

  const date = new Date();

  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return '; expires=' + date.toUTCString();
};

const getSecure = () =>
  window.location.protocol === 'https:' ? '; secure' : '';

export const cookie = {
  get,
  set
};
