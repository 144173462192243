import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { meOrders } from '~/App/helpers/http';
import { FloatingButton } from '~/App/shared/components/FloatingButton';

import { ICommerceOrder } from '~/types/ICommerceOrder';
import { EmptyState } from './components/EmptyState';
import { Order } from './components/Order';
import { PrimaryButton } from '~/App/shared/components/Elements';

const ShowMoreButton = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
`;

type Props = {
  orders: ICommerceOrder[];
};

export function Orders({ orders }: Props) {
  const [items, setItems] = useState(orders);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(orders.length === 10);
  const [isLoading, setLoading] = useState(false);

  const renderOrder = useCallback(
    (order: ICommerceOrder) => <Order key={order.id} order={order} />,
    []
  );

  const children = useMemo(
    () => (items.length ? items.map(renderOrder) : <EmptyState />),
    [items, renderOrder]
  );

  const loadMore = useCallback(async () => {
    setLoading(true);

    const data = await meOrders.all({
      pagination: {
        page: page + 1,
        perPage: 10
      }
    });

    setItems((state) => [...state, ...data]);
    setPage((state) => state + 1);
    setHasMore(data.length === 10);
    setLoading(false);
  }, [page]);

  return (
    <>
      {children}
      {hasMore && (
        <ShowMoreButton
          buttonStyle="primary"
          onClick={loadMore}
          isLoading={isLoading}
          children="Visa fler"
        />
      )}
      <FloatingButton
        buttonStyle="cta"
        to="/gavoshop/privat"
        children="Handla i gåvoshopen"
      />
    </>
  );
}
