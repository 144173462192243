import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { CollectionAdmin } from '~/types/ICollection';

//Helpers
import mq from '~/App/helpers/mq';
import { Avatar } from '~/App/shared/components/Avatar';
import { primaryButton } from '~/App/helpers/mixins';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RemoveButton = styled.button`
  ${primaryButton};
  min-width: 7rem;
  height: 2.5rem;
  ${mq('<small')`
    display:none;
  `};
`;

const RemoveLink = styled.a`
  cursor: pointer;
  ${mq('≥small')`
    display:none;
  `};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.75rem;

  ${mq('≥small')`
    margin-right: 1rem;
  `};
`;

const Info = styled.p`
  > * {
    font-size: 14px;
    display: block;
  }
`;

type Props = {
  admin: CollectionAdmin;
  removable: boolean;
  remove: (admin: CollectionAdmin) => void;
};

export function Admin({ admin, removable, remove }: Props) {
  const handleRemove = useCallback(() => remove(admin), [admin, remove]);

  return useMemo(
    () => (
      <Wrapper>
        <Content>
          <StyledAvatar user={admin} size="small" />
          <Info>
            {`${admin.firstName} ${admin.lastName}` +
              (admin.you ? ' (Du)' : '')}
            {removable && (
              <RemoveLink onClick={handleRemove} children="Ta bort" />
            )}
          </Info>
        </Content>
        {removable && (
          <RemoveButton
            buttonStyle="outlinePrimary"
            onClick={handleRemove}
            children="Ta bort"
          />
        )}
      </Wrapper>
    ),
    [admin, handleRemove, removable]
  );
}
