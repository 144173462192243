export const prettyFormat = (number, precision = 2) => {
  if (
    number !== undefined &&
    number !== null &&
    typeof number === 'number' &&
    Number.isFinite(number)
  ) {
    const numberString = number.toString();

    const splitNumberString = numberString.split('.');
    const integerPart = splitNumberString[0];

    let fractionalPart = '';
    if (splitNumberString.length > 1) {
      fractionalPart = `,${number.toFixed(precision).split('.')[1]}`;
    }

    let formattedIntegerPart = integerPart;
    if (integerPart.length > 3) {
      let formattedString = [];
      integerPart
        .split('')
        .reverse()
        .forEach((number, index) => {
          if (index !== 0 && index % 3 === 0) {
            formattedString.push(' ');
          }

          formattedString.push(number);
        });

      formattedIntegerPart = formattedString.reverse().join('');
    }

    return `${formattedIntegerPart}${fractionalPart}`;
  } else {
    return null;
  }
};
