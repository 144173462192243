/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useCallback } from 'react';
import { debounce, throttle } from 'lodash';

type Options = {
  el?: HTMLElement | HTMLDivElement | null;
  debounceType?: 'throttle' | 'debouce' | 'none';
  debounceValue?: number;
};

export function useScroll(
  callback: (event: Event) => void,
  { debounceType = 'throttle', debounceValue = 300, el }: Options = {}
) {
  const getDebounceMethod = useCallback((method) => {
    if (debounceType === 'debouce') return debounce(method, debounceValue);
    if (debounceType === 'throttle') return throttle(method, debounceValue);

    return method;
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const element = el ? el : window;
    const debouncedCallback = getDebounceMethod(callback);

    element.addEventListener('scroll', debouncedCallback);

    return () => {
      element.removeEventListener('scroll', debouncedCallback);
    };
  }, [el, callback, getDebounceMethod]);
}

export default useScroll;
