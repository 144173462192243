import styled from 'styled-components';

export const NoResultContent = styled.div`
  max-width: 20rem;
  margin: 0 auto;
  text-align: center;

  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
