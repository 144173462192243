import React, { useMemo } from 'react';
import styled from 'styled-components';

// shared componenets
import { PrimaryButton } from '~/App/shared/components/Elements';
import { IValidation } from '~/App/shared/components/Validation';
import { CreateMemorialPageSubmit } from '../hooks/useCreateMemorialPageSubmit';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin: 1rem auto 0;
`;

type Props = {
  validation: IValidation;
  submit: CreateMemorialPageSubmit;
};

export function SubmitButton({ validation, submit }: Props) {
  const authentication = useAuthenticationContext();

  const defaultSubmitButton = useMemo(
    () => (
      <ButtonWrapper>
        <PrimaryButton
          buttonStyle={
            validation.isValidated && !submit.isCompleted && !submit.isSending
              ? 'cta'
              : 'disabled'
          }
          type="submit"
          isLoading={submit.isSending}
          isDisabled={
            submit.isSending || submit.isCompleted || authentication.isAnonymous
          }
        >
          Skapa minnessida
        </PrimaryButton>
      </ButtonWrapper>
    ),
    [
      validation.isValidated,
      submit.isCompleted,
      submit.isSending,
      authentication.isAnonymous
    ]
  );

  return defaultSubmitButton;
}
