import React, { ChangeEvent, useCallback, useMemo } from 'react';
import {
  Item,
  Wrapper
} from '~/App/shared/components/Fields/RadioButtonHorizontal';

import { Period } from '~/types/IPurchase';
import { Layout } from '~/types/Layout';

type Option = {
  value: Period;
  label: string;
};

type Props = {
  value: Period;
  options: Option[];
  onChange: (value: Period) => void;
  tinyLayout: Layout;
  smallLayout: Layout;
  largeLayout: Layout;
};

export function PeriodSelector({
  value,
  options,
  onChange,
  tinyLayout,
  smallLayout,
  largeLayout
}: Props) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as Period);
    },
    [onChange]
  );

  const renderItem = useCallback(
    (option: Option) => (
      <Item
        key={option.value}
        id={`period-${option.value}`}
        value={option.value}
        checked={value === option.value}
        onChange={handleChange}
        label={option.label}
        name="period"
        tinyLayout={tinyLayout}
        smallLayout={smallLayout}
        largeLayout={largeLayout}
      />
    ),
    [handleChange, largeLayout, smallLayout, tinyLayout, value]
  );

  return useMemo(() => <Wrapper>{options.map(renderItem)}</Wrapper>, [
    options,
    renderItem
  ]);
}
