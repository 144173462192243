import React, { ComponentProps } from 'react';
import styled from 'styled-components';

//Shared components
import { Modal } from '~/App/shared/components/Elements';

//Helpers
import mq from '~/App/helpers/mq';
import { heading1 } from '~/App/helpers/mixins';

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem 4rem;
  text-align: center;

  ${mq('≥small')`
    padding: 3rem 1.5rem 4rem;
    max-width: 53ch;
  `};
`;

const StyledHeading = styled.h1`
  ${heading1};
  color: ${(props) => props.theme.colors.darkBlue};
  margin: 1rem auto;

  ${mq('≥small')`
    margin-bottom: 2rem;
  `};
`;

const StyledParagraph = styled.p`
  margin-bottom: 1rem;

  ${mq('≥small')`
    margin-bottom: 1.5rem;
  `};
`;

const Icon = styled.img`
  width: 60px;

  ${mq('≥small')`
    width: 80px;
  `};
`;

type Props = {
  closeModal: ComponentProps<typeof Modal>['closeModal'];
};

export function TaxReductionInfoModal({ closeModal }: Props) {
  return (
    <StyledModal
      className="taxReduction__infoModal"
      closeModal={closeModal}
      closeButton
    >
      <Icon src="https://res.cloudinary.com/cancerfonden/image/upload/v1615801585/assets/icons/icons-communication-insamlingar_1.svg"></Icon>
      <StyledHeading>Så funkar skatteavdrag</StyledHeading>
      <StyledParagraph>
        Du kan få skatteavdrag på 25% för gåvor på 200 kr eller mer, om du ger
        minst 2000 kr under ett kalenderår (till oss eller andra godkända
        organisationer).
      </StyledParagraph>
      <StyledParagraph>
        När du anger ditt personnummer skickar vi dina kontrolluppgifter
        automatiskt till Skatteverket och de kommer förtryckta på din
        skattedeklaration.
      </StyledParagraph>
    </StyledModal>
  );
}
