import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

type Props = {
  padding?: 'tiny';
};

export const Link = styled.a<Props>`
  display: inline-block;
  color: inherit;
  padding: ${({ padding }) =>
    padding === 'tiny' ? '0.5rem 0.3125rem 0.7rem' : '0.5rem 0.75rem 0.7rem'};
  background: transparent;
  border: none;
  cursor: pointer;

  ${mq<Props>('≥small')`
    padding-right: ${({ padding }) =>
      padding === 'tiny' ? '0.8125rem' : '1rem'};
    padding-left: ${({ padding }) =>
      padding === 'tiny' ? '0.8125rem' : '1rem'};
  `};
`;
