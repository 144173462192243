import styled, { css } from 'styled-components';

type Props = {
  isInvalid?: boolean;
};

export const Message = styled.div<Props>`
  font-size: 0.875em;
  margin-left: 0.25rem;

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      color: ${(props) => props.theme.colors.errorRed};
      margin-top: 0.325rem;
    `};
`;
