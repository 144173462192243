import React from 'react';
import styled from 'styled-components';
import mq from '~/App/helpers/mq';

import { shoppingCart } from '~/App/helpers/icons';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';

type CounterProps = {
  amount: number;
};

const Counter = styled.div<CounterProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: #ba0913;
  position: absolute;
  z-index: 1;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -4rem;
  margin-left: 4rem;

  ${mq('<mediumLarge')`
    margin-top: -3.5rem;
    margin-left: 3rem;
  `};
`;

const Container = styled.div`
  font-size: 6rem;
  text-align: center;
  color: #001489;

  ${mq('<mediumLarge')`
    font-size: 5rem;
    margin-top: 0;
  `};
`;

const Icon = styled.div`
  &:before {
    ${shoppingCart};
    font-weight: bold;
  }
`;

export function ShoppingCartLarge() {
  const shoppingCart = useShoppingCart();
  const itemAmount = shoppingCart.items.length;

  return (
    <Container>
      <Icon />
      <Counter amount={itemAmount} children={`${itemAmount}`}></Counter>
    </Container>
  );
}
