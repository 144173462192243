import React, { useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import { preamble } from '~/App/helpers/mixins';

// Shared components
import { Container } from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

import { Heading } from './components/Heading';
import { PublishedAt } from './components/PublishedAt';
import { Body } from './components/Body';
import { BackgroundColor, Padding, Width } from '~/types/Block';

type PreambleProps = {
  textAlign: string;
};

const Preamble = styled.p<PreambleProps>`
  ${preamble};
  margin: 0.5rem 0 1rem 0;
  text-align: ${({ textAlign }) => textAlign};
`;

type Props = {
  template: string;
  heading: string;
  body: string;
  preamble: string;
  preambleClasses: string[];
  publishedAt: string;
  revisionDate?: string; 
  show?: {
    publishedAt: boolean;
  };
  headingSettings: {
    fontSize: 'heading1' | 'heading2' | 'heading3' | 'sectionHeading';
    textAlign: string;
    fontWeight: string;
  };
  width: Width;
  padding: Padding;
  backgroundColor: BackgroundColor;
};

export default function Content({
  body,
  show,
  width,
  heading,
  padding = {},
  preamble,
  publishedAt,
  revisionDate,
  preambleClasses,
  headingSettings,
  backgroundColor = 'none',
  template
}: Props) {
  const containerPadding = useMemo<Padding>(
    () => ({ ...padding, left: 'small', right: 'small' }),
    [padding]
  );

  const children = useMemo(
    () => (
      <>
        <Heading children={heading} settings={headingSettings} />
        <PublishedAt
          value={show?.publishedAt && revisionDate ? revisionDate : (show?.publishedAt && publishedAt ? publishedAt : null)}
        />
        {preamble && (
          <Preamble textAlign={preambleClasses[0]} children={preamble} />
        )}
        <Body
          spacingTop={!!heading || !!preamble || !!show?.publishedAt}
          children={body}
          template={template}
        />
      </>
    ),
    [
      body,
      heading,
      headingSettings,
      preamble,
      preambleClasses,
      publishedAt,
      revisionDate,
      show?.publishedAt,
      template
    ]
  );

  return (
    <BlockBackground backgroundColor={backgroundColor}>
      <Container width={width} padding={containerPadding} children={children} />
    </BlockBackground>
  );
}
