import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

// Shared components
import { NewFormHeading } from '../../components/Forms/components/FormTypography';

// Fields
import { MultiPartFormValues, Section } from '../../../MultiPartForm';
import { LotteryFormContent } from '../Lottery';
import { IValidation } from '../../../Validation';
import { LotteryTicketStateValues } from '../../components/States';
import { Period, Quantity } from '../../components/Steps';

const SectionHeading = styled(NewFormHeading)`
  margin-top: 2rem;
  margin-bottom: 0rem;
`;

type Props = {
  formContent: LotteryFormContent;
  validation: IValidation;
  values: LotteryTicketStateValues & MultiPartFormValues;
};

export function Product({ formContent, validation, values }: Props) {
  const { lotteryTicketAmounts = ['2', '6', '8'] } = formContent;

  const isCompany = useMemo(() => values.customerContactType === 'company', [
    values.customerContactType
  ]);

  useEffect(() => {
    if (!isCompany) return;
    if (values.productOptions.product.period === 'Once') return;

    values.handlers.setProductPeriod('Once');
  }, [isCompany, values.handlers, values.productOptions.product.period]);

  return (
    <>
      {isCompany ? (
        <Section
          values={values}
          part={1}
          validation={validation}
          title="Välj antal (à 100 kr)"
          nextStepButton={{ text: 'Till uppgifter' }}
        >
          <Quantity
            options={lotteryTicketAmounts}
            values={values}
            customTitle="Antal lotter"
            validation={validation}
          />
        </Section>
      ) : (
        <Section
          values={values}
          part={1}
          validation={validation}
          title="Hur ofta vill du få lotter?"
          nextStepButton={{ text: 'Till uppgifter' }}
        >
          <Period values={values} />
          <SectionHeading>Välj antal (à 100 kr)</SectionHeading>
          <Quantity
            options={lotteryTicketAmounts}
            values={values}
            customTitle="Antal lotter"
            validation={validation}
          />
        </Section>
      )}
    </>
  );
}
