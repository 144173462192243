import { css } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { check } from '~/App/helpers/icons';

type CheckIconProps = {
  isValid?: boolean;
  isOpen?: boolean;
  isStarted?: boolean;
};

export const CheckIcon = css<CheckIconProps>`
  ${check};
  display: ${({ isValid, isOpen }) => (isValid && !isOpen ? 'block' : 'none')};
  top: -0.25rem;
  left: 0rem;
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.darkApple};
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  ${mq('≥small')`
    width: 2rem;
    height: 2rem;
    left: -0.25rem;
    font-size: 1.5rem;
    line-height: 2rem;
  `};
`;
