import { merge } from 'lodash';
import { useMemo } from 'react';
import { MemorialGiftStateValue } from './../components/States/MemorialGiftState';
import { paymentMethods } from '~/App/config/paymentMethods';
import { swishTypes } from '~/App/config/swishTypes';

type Props = {
  schema?: Record<string, unknown>;
};

type Values = {
  customerContactType: string;
  paymentMethod: {
    id: number;
  };
  productOptions: {
    receiverContactType: string;
    memorialGift: {
      deliverPaper: boolean;
    };
  };
  swish: {
    swishType: string;
  };
};

export function useSchema({ schema = {} }: Props) {

  return useMemo(() => {

    return merge(
      {},
      {
        'productOptions.inMemoryOfContact.firstName': {
          presence: (_: unknown, values: MemorialGiftStateValue) => {
            if (values.productOptions.inMemoryOfContact?.id) {
              return null;
            }

            return {
              allowEmpty: false,
              message: '^Du måste ange ett förnamn på den avlidne'
            };
          }
        },
        'productOptions.inMemoryOfContact.lastName': {
          presence: (_: unknown, values: MemorialGiftStateValue) => {
            if (values.productOptions.inMemoryOfContact?.id) {
              return null;
            }

            return {
              allowEmpty: false,
              message: '^Du måste ange ett efternamn på den avlidne'
            };
          }
        },
        'productOptions.memorialGift.textGreeting': {
          presence: {
            allowEmpty: false,
            message: '^Glöm inte att skriva en hälsning och ditt/era namn'
          }
        },
        'productOptions.memorialGift.amount': {
          presence: {
            allowEmpty: false,
            message: '^Ange ett belopp'
          },
          numericality: {
            greaterThanOrEqualTo: 150,
            lessThanOrEqualTo: 999999,
            message: `^Minsta belopp är 150 kr`
          }
        },
        'productOptions.customerContact.ssn': {
          validateSsn: (
            _: unknown,
            { customerContactType, paymentMethod }: Values
          ) => {
            if (paymentMethod.id === paymentMethods.paymentSlip) {
              return {
                allowEmpty: true
              };
            }

            return (
              customerContactType === 'automatic' &&
              paymentMethod.id !== paymentMethods.klarnaPayments
            );
          }
        },
        'productOptions.customerContact.firstName': {
          validateFirstName: (
            _: unknown,
            { customerContactType, paymentMethod }: Values
          ) =>
            customerContactType === 'manual' ||
            paymentMethod.id === paymentMethods.paymentSlip
        },
        'productOptions.customerContact.lastName': {
          validateLastName: (
            _: unknown,
            { customerContactType, paymentMethod }: Values
          ) =>
            customerContactType === 'manual' ||
            paymentMethod.id === paymentMethods.paymentSlip
        },
        'productOptions.customerContact.addressStreet': {
          validateStreetName: (
            _: unknown,
            { customerContactType, paymentMethod }: Values
          ) =>
            customerContactType === 'manual' ||
            paymentMethod.id === paymentMethods.paymentSlip
        },
        'productOptions.customerContact.addressZip': {
          validateZipCode: (
            _: unknown,
            { customerContactType, paymentMethod }: Values
          ) =>
            customerContactType === 'manual' ||
            paymentMethod.id === paymentMethods.paymentSlip
        },
        'productOptions.customerContact.addressCity': {
          validateCity: (
            _: unknown,
            { customerContactType, paymentMethod }: Values
          ) =>
            customerContactType === 'manual' ||
            paymentMethod.id === paymentMethods.paymentSlip
        },
        'productOptions.customerContact.email': {
          validateEmail: (_: unknown, { paymentMethod }: Values) => 
          paymentMethod.id !== paymentMethods.klarnaPayments
        },
        'klarna.isReady': {
          inclusion: (_: unknown, { paymentMethod }: Values) => {
            if (paymentMethod.id !== paymentMethods.klarnaPayments) {
              return false;
            }

            return {
              within: [true],
              message: '^Klarna är inte redo'
            };
          }
        },
        swishPhoneNumber: {
          validatePhoneNumber: (_: unknown, { paymentMethod, swish }: Values) =>
            paymentMethod.id === 8 && swish.swishType === swishTypes.ecommerce
        }
      },
      schema
    );
  }, [schema]);
}
