import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

const ProductImageWrapper = styled.div`
  margin: 0 0 1.5rem 0;
  padding: 1.5rem 2rem;
  background-color: ${(props) => props.theme.colors.seashell};
  text-align: center;
  border-radius: 1px;

  ${mq('≥small')`
    padding: 1.5rem 3.625rem;
  `};
`;

const ProductImageContainer = styled.div`
  max-width: 16rem;
  margin: 0 auto;

  ${mq('≥medium')`
    max-width: 100%;
  `};
`;

const ProductImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
`;

export const CertificatePreview = function (props) {
  const { donationImage } = props;

  return (
    <ProductImageWrapper>
      <ProductImageContainer>
        <ProductImage src={donationImage} />
      </ProductImageContainer>
    </ProductImageWrapper>
  );
};
