import React, { useMemo, ReactNode } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

// helpers
import mq from '~/App/helpers/mq';
import { isSubMenuActive } from '~/App/helpers/sub-menu-active';
import { useLoginModal } from '~/App/shared/hooks/use-login-modal';
import { useAppContext } from '~/App/contexts/App';

type WrapperProps = {
  isSticky: boolean;
  showHeader: boolean;
  showLoginModal: boolean;
};

const Wrapper = styled.nav<WrapperProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
  transition: transform 0.2s ease;
  transform: ${({ showHeader }) =>
    showHeader === true ? 'translateY(0)' : 'translateY(-100%)'};
  box-shadow: ${({ showHeader }) =>
    showHeader === true ? '0 0 .25rem .25rem rgba(64,64,64,.1875)' : 'none'};
  width: 100%;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: ${({ theme }) => theme.colors.white};

  ${mq('≥mediumLarge')`
    padding: 0 1rem 0 0.875rem;
    transition: transform 0s linear;
    transform: translateY(0);
    box-shadow: none;
  `};

  transform: ${({ showLoginModal }) => showLoginModal && 'translateY(-60px)'};
`;

const BASE_HEIGHT_DESKTOP = 130;
const BASE_HEIGHT_MOBILE = 116;
const SUB_NAVIGATION_HEIGHT = 50;

type MenuHeightProps = {
  showSubNavigation?: boolean;
};

const MenuHeight = styled.div<MenuHeightProps>`
  height: ${({ showSubNavigation }) => {
    let height = BASE_HEIGHT_DESKTOP;

    if (showSubNavigation) {
      height = height + SUB_NAVIGATION_HEIGHT;
    }

    return height;
  }}px;

  ${mq<MenuHeightProps>('<mediumLarge')`
    height: ${BASE_HEIGHT_MOBILE}px;
  `};
`;

type Props = {
  showHeader: boolean;
  children: ReactNode;
};

export function Navbar({ children, showHeader }: Props) {
  const stickyNavigation = useAppContext((x) => x.stickyNavigation);
  const location = useLocation();
  const loginModal = useLoginModal();

  const showSubNavigation = useMemo(() => isSubMenuActive(location.pathname), [
    location.pathname
  ]);

  return (
    <header>
      <Wrapper
        id="headerContainer"
        showHeader={showHeader}
        showLoginModal={loginModal.showLoginModal}
        isSticky={stickyNavigation}
        children={children}
      />
      {stickyNavigation && <MenuHeight showSubNavigation={showSubNavigation} />}
    </header>
  );
}
