import React, { useMemo, ReactNode } from 'react';
import { useLocation } from 'react-router';

// Helpers
import { siteUrl } from '~/App/helpers/env';

type Props = {
  to?: string;
  className?: string;
  children: ReactNode;
};

export default function LinkedInShareUrl({ to, className, children }: Props) {
  const location = useLocation();

  const url = useMemo(() => {
    const encodedUrl = encodeURIComponent(to || siteUrl(location.pathname));

    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
  }, [location.pathname, to]);

  return (
    <a
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      className={className}
      children={children}
    />
  );
}
