import React, {
  useState,
  useMemo,
  useCallback,
  ChangeEvent,
  FormEvent
} from 'react';
import { IValidation } from '../../../Validation';
import {
  InputFieldWrapper,
  InputGroup,
  Submit,
  InputWrapper,
  StyledIntegrityText
} from '../SwishBlockDonation.styled';
import Text from '~/App/shared/components/Fields/Text';
import { buttonStyles } from '~/App/config/buttonStyles';
import { ISubmit } from '../../components/Submits';
import { StateValues } from '../../components/States';
import { MultiPartFormValues } from '~/App/shared/components/MultiPartForm';
import { swishTypes } from '~/App/config/swishTypes';
import ConfirmSwishPayment from '~/App/views/ConfirmSwishPayment';
import { Width } from '~/types/Block';

export type Props = {
  validation: IValidation;
  submit: ISubmit;
  values: MultiPartFormValues & StateValues;
  width: Width;
  headingSettings?: {
    fontSize: string;
  };
  showLightTextColor: boolean;
};
export function Form({
  validation,
  submit,
  values,
  headingSettings,
  width,
  showLightTextColor
}: Props) {
  const [currentPart, setCurrentPart] = useState(0);

  function resetForm() {
    submit.resetSwishForm();
    values.handlers.handleGoToPart(1);
    validation.hideAllErrors();
    values.handlers.setSwishPhoneNumber('');
    values.handlers.setProductPrice(null);
    setCurrentPart(0);
  }

  const parts = [
    {
      part: 'productOptions.product.price',
      name: 'price',
      value: values.productOptions.product.price,
      placeHolder: 'Valfritt belopp',
      submitText: 'Swisha nu',
      maxLength: '6'
    },
    {
      part: 'swishPhoneNumber',
      name: 'tel',
      value: values.swishPhoneNumber,
      placeHolder: 'Fyll i ditt mobilnummer',
      submitText: 'Gå till Swish',
      maxLength: '10'
    }
  ];

  const validationKey = useMemo(
    () => values.handlers.getValidationKeys(values.partOptions.currentPart)[0],
    [values.handlers, values.partOptions.currentPart]
  );

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (currentPart === 0) {
        const rawValue = event.target.value?.replace(/[^0-9]+/g, '');
        const value = parseInt(rawValue, 10);

        if (isNaN(value)) {
          values.handlers.setProductPrice(null);
          return;
        }
        values.handlers.setProductPrice(value);
      }
      if (currentPart === 1) {
        values.handlers.handleSwishPhoneNumber(event);
      }
    },
    [currentPart, values]
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (validation.isValid(validationKey)) {
        if (
          currentPart === 1 ||
          values.swish.swishType === swishTypes.mcommerce
        ) {
          submit.handleSubmit();
          return;
        }
        setCurrentPart(currentPart + 1);
      }
      values.handlers.handleGoToPart(values.partOptions.currentPart + 1);
    },
    [
      currentPart,
      submit,
      validation,
      validationKey,
      values.handlers,
      values.partOptions.currentPart,
      values.swish.swishType
    ]
  );

  const buttonStyle = useMemo(() => {
    if (validation.isValid(validationKey)) {
      return buttonStyles.success;
    }
    if (validation.isInvalid(validationKey)) {
      return buttonStyles.error;
    }

    return buttonStyles.disabled;
  }, [validation, validationKey]);

  return (
    <>
      {!submit.isCompleted && (
        <>
          <form onSubmit={handleSubmit}>
            <InputWrapper headingSettings={headingSettings}>
              <InputGroup>
                <InputFieldWrapper>
                  <Text
                    value={parts[currentPart].value ?? ''}
                    isValid={validation.isValid(validationKey)}
                    isInvalid={validation.isInvalid(validationKey)}
                    onBlur={validation.handleBlur.bind(
                      undefined,
                      validationKey
                    )}
                    errors={validation.errors[validationKey]}
                    onChange={handleChange}
                    placeholder={parts[currentPart].placeHolder}
                    maxLength={parts[currentPart].maxLength}
                    name={parts[currentPart].name}
                    iconUrl="/images/swish-logos/swish.png"
                  />
                </InputFieldWrapper>
                <Submit
                  buttonStyle={buttonStyle}
                  type="submit"
                  className="cta"
                  children={parts[currentPart].submitText}
                  aria-disabled={
                    submit.isSending || submit.isPolling || submit.isCompleted
                  }
                />
              </InputGroup>
              <StyledIntegrityText
                headingSettings={headingSettings}
                showLightTextColor={showLightTextColor}
              />
            </InputWrapper>
          </form>
        </>
      )}
      {submit.isCompleted && (
        <ConfirmSwishPayment
          publicToken={submit.publicToken}
          handleChange={resetForm}
          isSwishBlock={true}
          swishType={values.swish.swishType}
          headingSettings={headingSettings}
          width={width}
        />
      )}
    </>
  );
}
