import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useMemo
} from 'react';

import { scrollAnimation } from '~/App/helpers/animations';
import {
  getOffset,
  getOuterHeightWithMargin
} from '~/App/helpers/units-helper';

import { useInViewport } from '~/App/shared/hooks/use-in-viewport';
import { RelatedItems } from '~/types/RelatedBlock';
import { Pagination } from '../components/Pagination';

type Props = {
  id?: string;
  enabled: boolean;
  page: number;
  totalPages: number;
  carousel: {
    isActive: boolean;
  };
  setPage: Dispatch<SetStateAction<number>>;
  load: (page: number) => Promise<RelatedItems>;
};

export function usePagination({
  id,
  page,
  enabled,
  totalPages,
  carousel,
  load,
  setPage
}: Props) {
  const blockRef = useRef<HTMLDivElement>(null);

  const [anchorInViewport, anchorRef] = useInViewport();

  const scrollToTop = useCallback(() => {
    if (anchorInViewport) return;

    const $block = blockRef.current;
    const $header = document.getElementById('headerContainer');

    if (!$block) return;
    if (!$header) return;

    const offsetTop = getOffset($block).top;
    const headerHeight = getOuterHeightWithMargin($header);

    scrollAnimation(offsetTop - headerHeight);
  }, [anchorInViewport]);

  const navigateTo = useCallback(
    async (page) => {
      if (page < 1) return;
      if (page > totalPages) return;

      await load(page);
      setPage(page);
      scrollToTop();
    },
    [load, scrollToTop, setPage, totalPages]
  );

  const pagination = useMemo(() => {
    if (carousel.isActive) return null;
    if (!enabled) return null;
    if (totalPages < 2) return null;

    return (
      <Pagination
        currentPage={page}
        navigateTo={navigateTo}
        totalPages={totalPages}
        controls={`related-items-${id}`}
      />
    );
  }, [carousel.isActive, navigateTo, page, enabled, id, totalPages]);

  return { pagination, blockRef, anchorRef };
}
