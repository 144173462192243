import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { contentApi } from '~/App/helpers/env';
import { fetch } from '~/App/helpers/http';
import { get, set } from '~/App/helpers/cookie';

import { OverlayContext } from './OverlayContext';
import { IOverlay } from '~/types/IOverlay';

type Props = {
  children: ReactNode;
  value: {
    overlay: IOverlay | null
  };
};

export function OverlayProvider({ children, value }: Props) {
  const [overlay, setOverlay] = useState(value.overlay);

  const location = useLocation();
  const key = useCallback((overlay: IOverlay) => `overlay:${overlay.id}`, []);

  const isVisible = useCallback((overlay: IOverlay) => !get(key(overlay)), [key]);

  useEffect(() => {
    if(get('overlay:disabled')) {
      return;
    }
    
    fetch({
      url: contentApi('/v1/overlay'),
      params: {
        url: location.pathname
      }
    })
      .then(({ data }) => {
        if (isVisible(data)) {
          setOverlay(data);
          set(key(data), 'true', data ? 1 : -1);
        } else {
          setOverlay(null);
        }
      })
      .catch(() => setOverlay(null));
  }, [isVisible, location.pathname, key]);

  return useMemo(
    () => (
      <OverlayContext.Provider
        value={{
          overlay,
          dismiss: () => setOverlay(null)
        }}
        children={children}
      />
    ),
    [overlay, children]
  );
}
