import React from 'react';
import styled from 'styled-components';
import { mq } from '~/App/helpers/mq';
import { ICommerceThankYouOrderLine } from '~/types/ICommerceOrderLine';

const OrderInfoText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: auto;
`;

const QuantityStyle = styled.p`
  line-height: 1.75rem;
  margin-right: auto;
`;

const ProductName = styled.p`
  width: 30%;
  text-align: left;
  line-height: 1.75rem;

  ${mq('<mediumLarge')`
    width: 50%;
  `};
`;

type Props = {
  orderLine: ICommerceThankYouOrderLine;
};

export function OrderLineProduct({ orderLine }: Props) {
  return (
    <OrderInfoText>
      <ProductName>{orderLine.productName}</ProductName>
      <QuantityStyle>{orderLine.quantity} st</QuantityStyle>
      <p>{orderLine.price} kr</p>
    </OrderInfoText>
  );
}
