import React from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';

const ResultBar = styled.div`
  position: relative;
  background-image: linear-gradient(to right, red, yellow, green);
  border-radius: 0.5rem;
  height: 1rem;
  width: 100%;
  margin: 0.5rem auto 5rem;

  ${mq('≥small')`
  margin-bottom: 7rem;
`};
`;

const IndicatorRange = styled.div`
  position: relative;
  width: calc(100% - 1.5rem);
  height: 100%;
  margin: auto;
`;

type IndicatorProps = {
  percent: number;
};

const ResultIndicator = styled.div<IndicatorProps>`
  position: absolute;
  background: black;
  width: 0.5rem;
  height: 0.5rem;
  top: 0.5rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  left: ${({ percent }) => `${percent}%`};

  &::before {
    display: block;
    content: 'Ditt resultat';
    position: absolute;
    left: 50%;
    width: 8ch;
    top: 3rem;
    text-align: center;
    transform: translateX(-50%);
    font-size: 0.75rem;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    height: 3rem;
    width: 1px;
    left: 50%;
    transform: translateX(-50%);
    background: black;
  }
`;

type Props = {
  percent: number;
};

export function Result({ percent }: Props) {
  return (
    <ResultBar>
      <IndicatorRange>
        <ResultIndicator percent={percent} />
      </IndicatorRange>
    </ResultBar>
  );
}
