import React from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  ${mq('>small')`
    margin-right: 1.125rem;
  `}
`;

const Text = styled.div`
  font-size: 0.75rem;

  ${mq('>small')`
    font-size: 0.875rem;
  `}
`;
const Image = styled.img`
  min-height: 6rem;
  min-width: 6rem;
`;

export function HideCollectionProgressPreview() {
  return (
    <Wrapper>
      <Image
        src={
          'https://res.cloudinary.com/cancerfonden/image/upload/v1637846768/minnesida/no-donation.svg'
        }
      />
      <Column>
        <Text children={'Insamlingen visas utan ikon om hur det går.'} />
      </Column>
    </Wrapper>
  );
}
