import React, { useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import Select, {
  InputActionMeta,
  SingleValue,
  StylesConfig
} from 'react-select';

import { IDeceasedContact } from '~/types/IDeceasedContact';
import { useDebouncedEffect } from '~/App/shared/hooks/use-debounced-effect';
import { deceasedContact } from '~/App/helpers/http/deceasedContact';

import { IndicatorsContainer } from './components/IndicatorsContainer';
import { buildNoOptionsMessage } from './components/NoOptionsMessage';
import { buildOption } from './components/Option';
import { Preview } from './components/Preview';
import { Input } from './components/Input';
import { NewPersonModal } from './components/NewPersonModal';

const Wrapper = styled.div`
  margin-top: 0.75rem;
`;

type Props = {
  value: IDeceasedContact | null;
  onChange: (
    newValue: SingleValue<IDeceasedContact>,
    fieldValue?: string
  ) => void;
};

export function BitNetSelect({ value, onChange }: Props) {
  const [options, setOptions] = useState<IDeceasedContact[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [months, setMonths] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const openMenu = useCallback(() => setMenuOpen(true), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const loadMore = useCallback(() => setMonths(3), []);

  const components = useMemo(
    () => ({
      IndicatorsContainer,
      Option: buildOption({
        loadMore: months < 3 ? loadMore : null,
        openModal,
        loading
      }),
      NoOptionsMessage: buildNoOptionsMessage({
        loading,
        openModal
      }),
      Input
    }),
    [months, loadMore, openModal, loading]
  );

  const shouldSearch = useMemo(() => {
    if (!inputValue) {
      return false;
    }

    return inputValue.length > 2;
  }, [inputValue]);

  const search = useCallback(
    async (value: string, months: number) => {
      if (!inputValue) {
        return;
      }

      setLoading(true);

      const createdFrom = dayjs()
        .subtract(months, 'months')
        .format('YYYY-MM-DD');

      try {
        const data = await deceasedContact.search(value, createdFrom);
        if (!data.length) {
          if (months === 1) {
            return setMonths(3);
          }
        }
        setOptions(data);
      } catch (err) {
        console.error('Error: ', err);
      }

      setLoading(false);
    },
    [inputValue]
  );

  useDebouncedEffect(
    () => {
      if (!shouldSearch) {
        return;
      }

      search(inputValue, months);
    },
    300,
    [inputValue, months, shouldSearch]
  );

  const styles = useMemo<StylesConfig<IDeceasedContact, false>>(
    () => ({
      control: (provided) => ({
        ...provided,
        minHeight: '46px',
        borderColor: theme.colors.lightDust,
        boxShadow: 'none',
        ':hover': {
          ...provided[':hover'],
          borderColor: theme.colors.lightDust
        },
        ':focus-within': {
          borderColor: theme.colors.dust
        }
      }),
      container: (provided) => ({
        ...provided,
        fontSize: '0.9375em'
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 2
      }),
      menuList: (provided) => ({
        ...provided,
        paddingBottom: '0',
        maxHeight: '324px'
      })
    }),
    [theme.colors.dust, theme.colors.lightDust]
  );

  const handleInputChange = useCallback(
    (value: string, { action }: InputActionMeta) => {
      if (action === 'input-change' || action === 'set-value') {
        setInputValue(value);
        setMonths(1);
      }
    },
    []
  );

  const handleChange = useCallback(
    (newValue: SingleValue<IDeceasedContact>, fieldValue?: string) => {
      onChange(newValue, fieldValue);

      // reset input value when newValue is selected
      if (newValue) {
        setInputValue('');
      }
    },
    [onChange]
  );

  const getOptionLabel = useCallback(
    (value: IDeceasedContact) => `${value.firstName} ${value.lastName}`,
    []
  );

  const getOptionValue = useCallback(
    (value: IDeceasedContact) => `${value.id}`,
    []
  );

  const placeHolder = 'Den avlidnes namn';

  return (
    <Wrapper>
      <Select
        instanceId="bitnet"
        value={null}
        styles={styles}
        options={options}
        inputValue={inputValue}
        components={components}
        menuIsOpen={menuOpen && shouldSearch}
        placeholder={placeHolder}
        onInputChange={handleInputChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onMenuClose={closeMenu}
        onMenuOpen={openMenu}
        onChange={(newValue) => handleChange(newValue, placeHolder)}
      />
      <Preview openModal={openModal} value={value} />
      {modalOpen && (
        <NewPersonModal
          value={value}
          onSubmit={(newValue) => handleChange(newValue, placeHolder)}
          closeModal={closeModal}
        />
      )}
    </Wrapper>
  );
}
