import styled from 'styled-components';
import { mq } from '~/lib/mq';

type ScrollBarProps = {
    count: number;
    index: number
}

export const ScrollBar = styled.div<ScrollBarProps>`
  width: calc(100% - 2rem);
  position: absolute;
  height: 0.5rem;
  bottom: 2.5rem;
  left: 1rem;

  ${mq('≥tiny')`
    left: 50%;
    transform: translateX(-50%);
  `};

  ${mq('≥medium')`

  `};

  &::after {
    content: '';
    position: absolute;
    width: ${({ count }) => `${(1 / count) * 100}%`};
    left: ${({ count, index }) => `${((index + 1) / count) * 100}%`};
    transform: translateX(-100%);
    height: 100%;
    background: ${({ theme }) => theme.colors.darkBlue};
    transition: left 0.5s cubic-bezier(0.2, 0.7, 0.5, 1);
    border-radius: 0.25rem;
  }
`;
