import React, { useCallback, useMemo, useState } from 'react';
import { set, isNaN, parseInt } from 'lodash';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

export default function State({ defaultState, children }) {
  const authenticated = useAuthenticationContext(
    (x) => x.isAuthenticated && x.isIdle
  );

  const [state, setState] = useState(defaultState || {});

  const handleSsn = useCallback(
    (event) => {
      event.persist();

      const nextState = set(state, 'ssn', event.target.value);
      const immutable = { ...nextState };

      setState(immutable);
    },
    [state]
  );

  const handleSsnFieldVisible = useCallback(
    (value) => {
      const nextState = set(state, 'ssnFieldVisible', value);
      const immutable = { ...nextState };

      setState(immutable);
    },
    [state]
  );

  const handleCollectionName = useCallback(
    (event) => {
      event.persist();

      const nextState = set(state, 'collection.name', event.target.value);
      const immutable = { ...nextState };

      setState(immutable);
    },
    [state]
  );

  const handleSenderName = useCallback(
    (event) => {
      event.persist();

      const nextState = set(state, 'collection.senderName', event.target.value);
      const immutable = { ...nextState };

      setState(immutable);
    },
    [state]
  );

  const handleShowProgressBadge = useCallback(
    (showProgressBadge) => {
      const nextState = set(
        state,
        'collection.showProgressBadge',
        showProgressBadge
      );
      const immutable = { ...nextState };

      setState(immutable);
    },
    [state]
  );

  const handleCollectionDescription = useCallback(
    (event) => {
      event.persist();

      const nextState = set(
        state,
        'collection.description',
        event.target.value
      );
      const immutable = { ...nextState };

      setState(immutable);
    },
    [state]
  );

  const handleCollectionMonetaryGoal = useCallback(
    (event) => {
      event.persist();

      let value = parseInt(`${event.target.value}`);

      if (isNaN(value)) {
        value = event.target.value.substring(0, event.target.value.length - 1);
      }

      const nextState = set(state, 'collection.monetaryGoal', value);
      const immutable = { ...nextState };

      setState(immutable);
    },
    [state]
  );

  const handleCollectionImage = useCallback(
    (image) => {
      const nextState = set(state, 'collection.image', image);
      const immutable = { ...nextState };

      setState(immutable);
    },
    [state]
  );

  const values = useMemo(
    () => ({
      ...state,
      authenticated,
      handlers: {
        handleCollectionMonetaryGoal,
        handleCollectionDescription,
        handleCollectionName,
        handleSenderName,
        handleCollectionImage,
        handleSsn,
        handleSsnFieldVisible,
        handleShowProgressBadge
      }
    }),
    [
      authenticated,
      handleCollectionDescription,
      handleCollectionImage,
      handleCollectionMonetaryGoal,
      handleCollectionName,
      handleSenderName,
      handleSsn,
      handleSsnFieldVisible,
      handleShowProgressBadge,
      state
    ]
  );

  return useMemo(() => <>{children(values)}</>, [children, values]);
}
