import React, { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { ctaButton } from '~/App/helpers/mixins';

import Loading from '~/App/views/Loading';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import { useAppContext } from '~/App/contexts/App';

const Wrapper = styled.div`
  display: flex;
  padding: 8rem 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
`;

const Button = styled.a`
  ${ctaButton}
  margin-top: 1rem;
`;
const Image = styled.img`
  height: 64px;
`;

const swishLogo =
  'https://res.cloudinary.com/cancerfonden/image/upload/v1606135291/assets/Swish_Logo_Primary_Light-BG_SVG.svg';
const fallbackUrl = '/stod-oss/ge-en-gava?campaignActivityId=2652';

function buildSwishUrl(amount?: number) {
  const params = {
    message: { value: 'Cancerfonden', editable: false },
    payee: { value: '123 901 95 14', editable: false },
    amount: amount ? { value: amount, editable: true } : undefined,
    version: 1
  };

  const query = `data=${encodeURIComponent(JSON.stringify(params))}`;

  return `swish://payment?${query}`;
}

export default function Swish() {
  const [isLoading, setIsLoading] = useState(true);

  const userAgent = useAppContext((state) => state.userAgent);
  const swishUrl = useMemo(() => buildSwishUrl(), []);
  const isMobile = useMemo(() => isMobileBrowser(userAgent), [userAgent]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!isMobile) return;

    setTimeout(() => window.location.replace(swishUrl), 500);
    setTimeout(() => setIsLoading(false), 3000);
  }, [swishUrl, isMobile]);

  if (!isMobile) {
    return <Navigate to={fallbackUrl} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isMobile) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Wrapper>
          <Image src={swishLogo} />
          <Button href={swishUrl}>Öppna Swish</Button>
        </Wrapper>
      </>
    );
  }

  return null;
}
