import { CancelToken } from 'axios';
import { IUser } from '~/types/IUser';
import { mwAxios, poll } from '.';
import { get } from '../cookie';
import { getLoginErrorMessage } from '../getLoginErrorMessage';

async function testLogin(): Promise<LoginResult> {
  const { data } = await mwAxios.post<{
    secretKey?: string;
    user?: IUser;
    status?: number;
  }>(`/api/v2/bankid/test_env_login`);

  const { user, secretKey } = data;
  if (user && secretKey) {
    return {
      success: true,
      user,
      secretKey
    };
  }

  return {
    success: false,
    errors: []
  };
}

type CancelPollParams = {
  orderRef: string;
};

function cancelPoll({ orderRef }: CancelPollParams) {
  return mwAxios.delete<Record<string, never>>(`/api/v2/bankid/cancel`, {
    data: {
      orderRef: orderRef
    }
  });
}

function logout() {
  return mwAxios.delete<Record<string, never>>(`/api/v2/session`, {
    headers: {
      'X-Session-Key': get('secretKey')
    }
  });
}

type CheckParams = {
  secretKey: string;
};

type CheckResponse = {
  user?: IUser;
};

function check({ secretKey }: CheckParams) {
  return mwAxios.get<CheckResponse>(`/api/v2/session`, {
    headers: {
      'X-Session-Key': secretKey
    }
  });
}

type LoginResult =
  | {
      success: true;
      user: IUser;
      secretKey: string;
    }
  | {
      success: false;
      errors: string[];
    };

type LoginBankIdResponse = {
  orderRef: string;
  autoStartToken: string;
};

async function loginBankId() {
  const { data } = await mwAxios.post<LoginBankIdResponse>(
    `/api/v2/bankid/start`
  );

  return data;
}

type LoginPollParams = {
  orderRef: string;
  source?: string;
  subscriptionToken?: string;
  cancelToken?: CancelToken;
};

type LoginPollResponse = {
  orderStatus: 'pending' | 'complete';
  message: string;
  secretKey?: string;
  user?: IUser;
  status?: number;
};

async function loginPoll({
  orderRef,
  source,
  subscriptionToken,
  cancelToken
}: LoginPollParams) {
  const { data } = await mwAxios.put<LoginPollResponse>(
    `/api/v2/bankid/status`,
    {
      orderRef,
      source,
      subscriptionToken
    },
    {
      cancelToken
    }
  );

  return data;
}

type QrPollParams = {
  orderRef: string;
};

type QrPollResponse = {
  qrCode: string;
};

async function qrPoll({ orderRef }: QrPollParams) {
  const { data } = await mwAxios.put<QrPollResponse>(`/api/v2/bankid/qr`, {
    orderRef
  });
  return data;
}

type LoginParams = {
  cancelToken?: CancelToken;
  source?: string;
  subscriptionToken?: string;
  sameDevice?: boolean;
  onAutoStartToken: (token: string) => void;
  onQrCode: (code: string) => void;
  onMessage: (message: string) => void;
};

async function login({
  source,
  cancelToken,
  subscriptionToken,
  sameDevice,
  onAutoStartToken,
  onQrCode,
  onMessage
}: LoginParams): Promise<LoginResult> {
  try {
    const startResponse = await loginBankId();

    let shouldFetchQr = true;

    if (startResponse.autoStartToken) {
      onAutoStartToken(startResponse.autoStartToken);
    }

    if (!sameDevice) {
      try {
        poll.start({
          fn: () =>
            qrPoll({
              orderRef: startResponse.orderRef
            }),
          done: (data) => (data && shouldFetchQr ? false : true),
          onEach: ({ qrCode }) => {
            if (qrCode) {
              onQrCode(qrCode);
            }
          },
          delay: 1000,
          timeout: 35000,
          interval: 1000
        });
      } catch (error: any) {
        console.log(error);
      }
    }

    try {
      const pollResponse = await poll.start({
        fn: () =>
          loginPoll({
            cancelToken,
            orderRef: startResponse.orderRef,
            source,
            subscriptionToken
          }),
        done: ({ orderStatus }) => orderStatus !== 'pending',
        onEach: ({ message }) => {
          if (message) {
            onMessage(message);
            if (message !== 'RFA1') shouldFetchQr = false;
          }
        },
        delay: 1000,
        timeout: 180000,
        interval: 2000
      });

      const { user, secretKey } = pollResponse;

      if (user && secretKey) {
        return {
          success: true,
          user,
          secretKey
        };
      }
    } catch (error: any) {
      shouldFetchQr = false;

      // Request is cancelled
      if (poll.isCancel(error)) {
        cancelPoll({ orderRef: startResponse.orderRef }).catch(() => null);

        return { success: false, errors: [] };
      }

      // Get error message
      let errorResponse;
      const errorString = String(error);
      if (errorString.includes('PollTimeoutError')) {
        errorResponse = getLoginErrorMessage('PollTimeoutError');
      } else if (!sameDevice) {
        errorResponse = getLoginErrorMessage(
          error?.response?.data?.message + 'B'
        );
      } else {
        errorResponse = getLoginErrorMessage(error?.response?.data?.message);
      }

      return {
        success: false,
        errors: [errorResponse]
      };
    }
  } catch (error: any) {
    return {
      success: false,
      errors: [getLoginErrorMessage(error?.response?.data?.message)]
    };
  }

  return {
    success: false,
    errors: []
  };
}

export const auth = {
  login: login,
  testLogin: testLogin,
  logout,
  check
};
