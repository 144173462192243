import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

// Local components
import {
  Preview,
  PreviewWrapper,
  CFLogo,
  Watermark
} from '~/App/shared/components/Donation/components/Forms/components/CertificatePreviews/components';

const Image = styled.img`
  width: 9em;
  height: 9em;
  margin-top: 2em;
  margin-bottom: 1em;
  border-radius: 50%;
`;

const Heading = styled.p`
  font-weight: 700;
  font-size: 2em;
  margin-bottom: 0.5em;
`;

const Description = styled.p`
  font-family: Arial, sans-serif;
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: 1em;
  margin-bottom: 1.5em;
  word-wrap: break-word;
`;

const Disclaimer = styled.p`
  font-size: 0.75em;
  margin-top: 0.5em;
  width: 100%;
`;

type Props = {
  imageUrl?: string;
  heading?: string;
  disclaimer?: string;
  description?: string;
  fullSize?: boolean;
};

export function ProductCertificatePreview({
  imageUrl,
  heading,
  disclaimer,
  description,
  fullSize
}: Props) {
  const renderItem = useCallback(
    (item: string, index: number) => (
      <span key={index}>
        {item}
        <br />
      </span>
    ),
    []
  );

  return useMemo(
    () => (
      <PreviewWrapper fullSize={fullSize}>
        <Preview fullSize={fullSize}>
          {imageUrl && <Image src={imageUrl} />}
          {heading && <Heading children={heading} />}
          {description && (
            <Description children={description.split('\n').map(renderItem)} />
          )}
          <CFLogo />
          {disclaimer && <Disclaimer children={disclaimer} />}
          <Watermark isVisible={fullSize} />
        </Preview>
      </PreviewWrapper>
    ),
    [description, disclaimer, fullSize, heading, imageUrl, renderItem]
  );
}
