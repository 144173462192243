import React, {
  useCallback,
  useState,
  useMemo,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  ReactNode
} from 'react';

import { genericCertificate } from '~/App/config/default-images';
import { textHelper } from '~/App/shared/components/Donation/components/Forms/helpers/textHelper.js';

type ReceiverContact = {
  email?: string;
  name?: string;
};

type HeadingSlug = {
  heading: string;
  slug: string;
};

export type CertificateValues = {
  productOptions: {
    certificate: {
      textGreeting: string;
      illustration?: string;
      heading: string;
      headingSlug: string;
      description: string;
      receiverContact?: ReceiverContact;
    };
  };
  handlers: {
    setCertificateTextGreeting: (event: ChangeEvent<HTMLInputElement>) => void;
    setCertificateIllustration: (value: string) => void;
    setCertificateHeadingAndSlug: (value: HeadingSlug) => void;
    setCertificateDescription: (value: string) => void;
    handleReceiverContactEmail: (event: ChangeEvent<HTMLInputElement>) => void;
    handleReceiverContactName: (event: ChangeEvent<HTMLInputElement>) => void;
    setDeliveryDate: Dispatch<SetStateAction<Date>>;
  };
};

type InputCertificateValues = {
  productOptions?: {
    certificate?: {
      textGreeting?: string;
      illustration?: string;
      heading?: string;
      headingSlug?: string;
      description?: string;
      receiverContact?: ReceiverContact;
    };
  } & Record<string, unknown>;
  handlers?: Record<string, unknown>;
};

export function useCertificateValues<T extends InputCertificateValues>(
  values: T
) {
  const [certificate, setCertificate] = useState({
    textGreeting: '',
    illustration: genericCertificate[0],
    heading: '',
    headingSlug: '',
    description: '',
    ...values?.productOptions?.certificate
  });

  const [receiverContact, setReceiverContact] = useState<ReceiverContact>({
    email: '',
    name: '',
    ...values?.productOptions?.certificate?.receiverContact
  });

  const [deliveryDate, setDeliveryDate] = useState(new Date());

  const setCertificateTextGreeting = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const maximumNumberOfLines = 10;
      const textGreeting = event.target.value;

      if (textHelper.calculateUsedLines(textGreeting) <= maximumNumberOfLines) {
        setCertificate((state) => ({ ...state, textGreeting: textGreeting }));
      }
    },
    []
  );

  const setCertificateIllustration = useCallback(
    (illustration: string) =>
      setCertificate((state) => ({ ...state, illustration })),
    []
  );

  const setCertificateHeadingAndSlug = useCallback(
    ({ heading, slug }) =>
      setCertificate((state) => ({
        ...state,
        heading: heading,
        headingSlug: slug
      })),
    []
  );

  const setCertificateDescription = useCallback(
    (description: string) =>
      setCertificate((state) => ({ ...state, description })),
    []
  );

  const handleReceiverContactEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setReceiverContact((state) => ({ ...state, email: value }));
    },
    []
  );

  const handleReceiverContactName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setReceiverContact((state) => ({ ...state, name: value }));
    },
    []
  );

  return useMemo<T & CertificateValues>(
    () => ({
      ...values,
      productOptions: {
        ...values?.productOptions,
        certificate: {
          ...certificate,
          deliveryDate: deliveryDate,
          receiverContact: receiverContact
        }
      },
      handlers: {
        ...values.handlers,
        setCertificateTextGreeting: setCertificateTextGreeting,
        setCertificateIllustration: setCertificateIllustration,
        setCertificateHeadingAndSlug: setCertificateHeadingAndSlug,
        setCertificateDescription: setCertificateDescription,
        handleReceiverContactEmail: handleReceiverContactEmail,
        handleReceiverContactName: handleReceiverContactName,
        setDeliveryDate: setDeliveryDate
      }
    }),
    [
      certificate,
      deliveryDate,
      handleReceiverContactEmail,
      handleReceiverContactName,
      receiverContact,
      setDeliveryDate,
      setCertificateDescription,
      setCertificateHeadingAndSlug,
      setCertificateIllustration,
      setCertificateTextGreeting,
      values
    ]
  );
}

type Props<T> = {
  values: InputCertificateValues & T;
  children: (values: CertificateValues & T) => ReactNode;
};

export function CertificateState<T>({ values, children }: Props<T>) {
  const certificate = useCertificateValues(values);

  return useMemo(() => <>{children(certificate)}</>, [certificate, children]);
}
