import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { useQuery } from '~/App/shared/hooks/use-query';
import { IQuestionSession } from '~/types/IQuestionSession';
import { ISelfTestConfig, Index } from '../../types';
import { stringify } from '~/lib/query-string';

type Query = {
  screen?: number;
  section?: number;
};

type Props = {
  config: ISelfTestConfig;
  session: IQuestionSession | null;
};

export function useNavigation({ config, session }: Props) {
  const query = useQuery<Query>();
  const location = useLocation();
  const navigate = useNavigate();

  const index = useMemo<Index | null>(() => {
    const screen = parseInt(`${query.screen || 0}`, 10);
    const section = parseInt(`${query.section || 0}`, 10);

    if (!session) {
      return null;
    }

    return {
      section,
      screen
    };
  }, [query, session]);

  const isValid = useCallback(
    (index: Index): boolean =>
      !!(
        config.sections[index.section] &&
        config.sections[index.section].screens[index.screen]
      ),
    [config]
  );

  const setIndex = useCallback(
    (index: Index | null) => {
      const url = index
        ? `${location.pathname}?${stringify(index)}`
        : location.pathname;

      navigate(url);
    },
    [navigate, location.pathname]
  );

  const getValidIndexWithDelta = useCallback(
    (delta: number): Index | null => {
      const startIndex: Index = { section: 0, screen: 0 };

      if (!index) {
        if (isValid(startIndex)) {
          return startIndex;
        }

        return null;
      }

      const previousSection = config.sections[index.section - 1];
      const indexBelowZero = index.screen + delta < 0;
      const shouldDecreaseSection = indexBelowZero && previousSection;

      const nextScreenIndex = shouldDecreaseSection
        ? previousSection.screens.length - 1
        : index.screen + delta;

      const nextSectionIndex = shouldDecreaseSection
        ? index.section - 1
        : index.section;

      const nextScreen: Index = {
        section: nextSectionIndex,
        screen: nextScreenIndex
      };

      if (isValid(nextScreen)) {
        return nextScreen;
      }

      const nextSection: Index = {
        screen: 0,
        section: index.section + delta
      };

      if (isValid(nextSection)) {
        return nextSection;
      }

      return null;
    },
    [index, isValid, config]
  );

  const goTo = useCallback(
    (delta: number) => setIndex(getValidIndexWithDelta(delta)),
    [getValidIndexWithDelta, setIndex]
  );

  const isFirstScreen = useMemo(
    () => !!index && index.screen === 0 && index.section === 0,
    [index]
  );

  const isLastScreen = useMemo(() => !getValidIndexWithDelta(1), [
    getValidIndexWithDelta
  ]);

  return {
    index,
    isFirstScreen,
    isLastScreen,
    goTo
  };
}
