import React from 'react';
import styled from 'styled-components';

import { caretRight } from '~/App/helpers/icons';
import { mq } from '~/lib/mq';

const Link = styled.a`
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 2rem 0 4rem;
  line-height: 1.375rem;
  text-align: center;
  cursor: pointer;

  ${mq('<mediumLarge')`
   font-size: 14px;
  `};

  &:hover {
    color: ${({ theme }) => theme.colors.hoverBlue};
  }

  &:after {
    ${caretRight};
    font-size: 1.5rem;
    margin-left: 0.375rem;
    top: 0.25rem;
  }
`;

type Props = {
  onClose: () => void;
  text: string;
};

export function CloseButton({ onClose, text }: Props) {
  return <Link onClick={onClose}>{text}</Link>;
}
