import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { formatNumber } from 'accounting';

// Shared components
import Text from '~/App/shared/components/Fields/Text';
import {
  Item,
  Wrapper
} from '~/App/shared/components/Fields/RadioButtonHorizontal/';
import { IValidation } from '~/App/shared/components/Validation';
import { Layout } from '~/types/Layout';

const CustomAmountWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.themeColors.fadedPrimary};
`;

const CustomAmountTitle = styled.h4`
  color: black;
  font-size: 1rem;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
`;

type Value = string | number;
type Null = null | undefined;

type Props = {
  amountType?: 'krPerKm' | 'lottery' | 'money';
  amounts: Value[];
  selectedAmount: Value | Null;
  customAmount: boolean;
  setAmount: (_: number | null) => void;
  setCustomAmount: (_: boolean) => void;
  resetAmount: () => void;
  collectionDistance?: number;
  validationKey: string;
  validation: IValidation;
  tinyLayout?: Layout;
  smallLayout?: Layout;
  largeLayout?: Layout;
  pushGtmFormInteraction?: (fieldName: string, fieldValue: string) => void;
};

type Copy = {
  customAmountTitle: string;
  customLabel: string;
  placeholder: string;
  suffix: string;
  amountValue: Value | Null;
  unitOfMeasurement: 'st' | 'kr';
  idPrefix: string;
};

export function AmountSelector({
  selectedAmount,
  amountType,
  amounts = [100, 350, 500],
  customAmount,
  setAmount,
  setCustomAmount,
  resetAmount,
  collectionDistance,
  validationKey,
  validation,
  tinyLayout,
  smallLayout,
  largeLayout,
  pushGtmFormInteraction
}: Props) {
  const [lopbandetKrPerKm, setLopbandetKrPerKm] = useState<number | null>(null);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      label: string,
      customAmount: boolean
    ) => {
      setCustomAmount(customAmount);

      if (customAmount) {
        resetAmount();
      } else {
        setAmount(parseInt(event.target.value, 10));
      }

      if (pushGtmFormInteraction) {
        pushGtmFormInteraction(event.target.name, label);
      }
    },
    [setAmount, setCustomAmount, resetAmount, pushGtmFormInteraction]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value?.replace(/[^0-9]+/g, '');
      const value = parseInt(rawValue, 10);

      if (isNaN(value)) {
        setAmount(null);
        setLopbandetKrPerKm(null);
        return;
      }

      if (amountType === 'krPerKm') {
        const amount = value * (collectionDistance ?? 1);

        setLopbandetKrPerKm(value);
        setAmount(amount);
      } else {
        setAmount(value);
      }
    },
    [amountType, collectionDistance, setAmount]
  );

  const isSelected = useCallback(
    (value: Value) => `${value}` === `${selectedAmount}`,
    [selectedAmount]
  );

  const {
    idPrefix,
    customAmountTitle,
    customLabel,
    placeholder,
    suffix,
    amountValue,
    unitOfMeasurement
  } = useMemo<Copy>(() => {
    if (amountType === 'krPerKm') {
      return {
        customAmountTitle: 'Belopp',
        customLabel: 'kr/km',
        placeholder: 'Ex "1"',
        suffix: 'kr/km',
        amountValue: lopbandetKrPerKm,
        unitOfMeasurement: 'kr',
        idPrefix: 'distance'
      };
    }

    if (amountType === 'lottery') {
      return {
        customAmountTitle: 'Antal lotter',
        customLabel: 'Valfritt',
        placeholder: 'Valfritt antal',
        suffix: 'amount',
        amountValue: selectedAmount,
        unitOfMeasurement: 'st',
        idPrefix: 'amount'
      };
    }

    return {
      customAmountTitle: 'Belopp',
      customLabel: 'Valfritt',
      placeholder: 'Valfritt belopp',
      suffix: 'kr',
      amountValue: selectedAmount,
      unitOfMeasurement: 'kr',
      idPrefix: 'price'
    };
  }, [amountType, lopbandetKrPerKm, selectedAmount]);

  const renderItem = useCallback(
    (amount: Value) => (
      <Item
        key={amount}
        id={`${idPrefix}-${amount}`}
        value={amount}
        checked={!customAmount && isSelected(amount)}
        onChange={(event: ChangeEvent<HTMLInputElement>, label: string) =>
          handleChange(event, label, false)
        }
        label={`${formatNumber(
          typeof amount === 'string' ? parseInt(amount, 10) : amount,
          0,
          ' '
        )} ${unitOfMeasurement}`}
        name="price"
        tinyLayout={tinyLayout}
        smallLayout={smallLayout}
        largeLayout={largeLayout}
      />
    ),
    [
      customAmount,
      idPrefix,
      isSelected,
      largeLayout,
      smallLayout,
      tinyLayout,
      unitOfMeasurement,
      handleChange
    ]
  );

  return useMemo(
    () => (
      <>
        <Wrapper>
          {amounts.map(renderItem)}
          <Item
            value={undefined}
            checked={customAmount}
            onChange={(event: ChangeEvent<HTMLInputElement>, label: string) =>
              handleChange(event, label, true)
            }
            label={customLabel}
            name="price"
            tinyLayout={tinyLayout}
            smallLayout={smallLayout}
            largeLayout={largeLayout}
          />
        </Wrapper>
        {customAmount && (
          <CustomAmountWrapper>
            <CustomAmountTitle children={customAmountTitle} />
            <Text
              name="price"
              type="tel"
              value={amountValue ?? ''}
              onBlur={validation.showError.bind(undefined, validationKey)}
              errors={validation.errors[validationKey]}
              isValid={validation.isValid(validationKey)}
              onChange={handleInputChange}
              isInvalid={validation.isInvalid(validationKey)}
              maxLength="6"
              placeholder={placeholder}
              suffix={suffix}
              required
            />
            {amountType === 'krPerKm' && (
              <p>
                x {collectionDistance} km ={' '}
                <strong>{selectedAmount || 0} kr</strong>
              </p>
            )}
          </CustomAmountWrapper>
        )}
      </>
    ),
    [
      amountType,
      amountValue,
      amounts,
      collectionDistance,
      customAmount,
      customAmountTitle,
      customLabel,
      handleChange,
      largeLayout,
      placeholder,
      renderItem,
      selectedAmount,
      smallLayout,
      suffix,
      tinyLayout,
      validation,
      validationKey,
      handleInputChange
    ]
  );
}

export default AmountSelector;
