import React, { useMemo } from 'react';

// Shared components
import Validation from '~/App/shared/components/Validation';

// Local components
import { Submit } from '../components/Submits';
import PaymentRedirect from '../components/PaymentRedirect';

import { Form } from './components/Form';
import { State, MultiPartState, CertificateState } from '../components/States';
import { ImageSelectorProvider } from '../../Fields/ImageSelector';

import { buildSchema } from './schema';
import { buildState } from './state';
import { useQuery } from '~/App/shared/hooks/use-query';
import { CustomerContactType } from '~/App/config/customerContactTypes';
import { GtmDetails } from '~/App/helpers/gtm-helper';
import { IPaymentMethod } from '~/types/IPaymentMethod';
import { OnceDonationNudge } from '../components/TaxReductionNudging/OnceDonationNudge';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';

type Variant = {
  heading: string;
  hidden: boolean;
  slug: string;
  gtmVariant?: string;
  campaignActivityId?: number;
};

type Props = {
  data: {
    originPath: string | Record<number, string>;
    redirectPath: string | Record<number, string>;
    gtmId?: string | undefined;
    productId?: number;
    defaultPrice?: string;
    gtmDetails?: GtmDetails;
    defaultCustomerContactType: CustomerContactType;
    defaultPaymentMethod: IPaymentMethod;
    certificateInfo: {
      description?: string;
      variants: Variant[];
    };
  };
};

export default function GenericCertificate({ data }: Props) {
  const query = useQuery();
  const schema = useMemo(() => buildSchema(), []);
  const state = useMemo(
    () =>
      buildState({
        data,
        query
      }),
    [data, query]
  );

  return (
    <ImageSelectorProvider>
      <State formContent={data} values={state}>
        {(values) => (
          <CertificateState values={values}>
            {(certificateValues) => (
              <Validation
                schema={schema}
                values={certificateValues}
                hasStripe={false}
              >
                {(validation) => (
                  <MultiPartState
                    values={certificateValues}
                    validation={validation}
                  >
                    {(multiPartValues) => (
                      <Submit values={multiPartValues} validation={validation}>
                        {(submit) => (
                          <OnceDonationNudge
                            submit={submit}
                            values={multiPartValues}
                            validation={validation}
                            name="Gåvobevis"
                            setAmount={values.handlers.setProductPrice}
                            shouldNudge={featureIsEnabled(
                              'NUDGE_GENERIC_CERTIFICATE_ENABLED'
                            )}
                          >
                            {(taxReductionNudgingSubmit) => (
                              <div>
                                <PaymentRedirect
                                  submit={taxReductionNudgingSubmit}
                                  redirectPath={
                                    typeof data.redirectPath === 'string'
                                      ? data.redirectPath
                                      : data.redirectPath[
                                          multiPartValues.product.id
                                        ]
                                  }
                                />
                                <Form
                                  values={multiPartValues}
                                  submit={taxReductionNudgingSubmit}
                                  validation={validation}
                                  formContent={data}
                                />
                              </div>
                            )}
                          </OnceDonationNudge>
                        )}
                      </Submit>
                    )}
                  </MultiPartState>
                )}
              </Validation>
            )}
          </CertificateState>
        )}
      </State>
    </ImageSelectorProvider>
  );
}
