import { useCallback, useEffect, useState, ChangeEvent, useMemo } from 'react';

import { mustMatch, pipeEvent } from './helpers';
import {
  formatSsn,
  moveCursorAfterYear,
  normalizeSsn
} from '~/App/helpers/ssn-helper';

import validateSsn from '~/App/helpers/validate-ssn';

import { CustomerContactType } from '~/App/config/customerContactTypes';
import { ICustomerContact } from '~/types/ICustomerContact';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

export type ContactCustomerValues = {
  productOptions: {
    customerContact: ICustomerContact;
  };
  customerContactType: CustomerContactType | null;
  handlers: {
    handleCustomerContactSsn: (
      event: ChangeEvent<HTMLInputElement>,
      input: HTMLInputElement | null
    ) => void;
    handleCustomerContactCid: (event: ChangeEvent<HTMLInputElement>) => void;
    handleCustomerContactEmail: (event: ChangeEvent<HTMLInputElement>) => void;
    handleCustomerContactCompany: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleCustomerContactLastName: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleCustomerContactFirstName: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleCustomerContactAddressZip: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleCustomerContactAddressCity: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleCustomerContactPhoneNumber: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleCustomerContactAddressStreet: (
      event: ChangeEvent<HTMLInputElement>
    ) => void;
    handleCustomerContactType: (
      value: CustomerContactType | null,
      event?: ChangeEvent<HTMLInputElement>
    ) => void;
  };
};

type InputValues = {
  customerContactType?: CustomerContactType;
  productOptions?: {
    customerContact?: {
      ssn?: string;
      cid?: string;
      email?: string;
      company?: string;
      lastName?: string;
      firstName?: string;
      addressZip?: string;
      addressCity?: string;
      addressStreet?: string;
      phoneNumber?: string;
    };
  } & Record<string, unknown>;
  handlers?: Record<string, unknown>;
};

export function useContactInformationValues<T extends InputValues>(values: T) {
  const { productOptions: { customerContact = {} } = {} } = values;

  const authentication = useAuthenticationContext();

  const [ssnPrefilled, setSsnPrefilled] = useState(false);
  const [emailPrefilled, setEmailPrefilled] = useState(false);

  const [ssn, setSsn] = useState(customerContact?.ssn ?? '');
  const [cid, setCid] = useState(customerContact.cid ?? '');
  const [email, setEmail] = useState(customerContact.email ?? '');
  const [company, setCompany] = useState(customerContact.company ?? '');
  const [lastName, setLastName] = useState(customerContact.lastName ?? '');
  const [firstName, setFirstName] = useState(customerContact.firstName ?? '');
  const [
    customerContactType,
    setCustomerContactType
  ] = useState<CustomerContactType | null>(
    values.customerContactType || 'manual'
  );
  const [addressZip, setAddressZip] = useState(
    customerContact.addressZip ?? ''
  );
  const [addressCity, setAddressCity] = useState(
    customerContact.addressCity ?? ''
  );
  const [phoneNumber, setPhoneNumber] = useState(
    customerContact.phoneNumber ?? ''
  );
  const [addressStreet, setAddressStreet] = useState(
    customerContact.addressStreet ?? ''
  );

  const handleCustomerContactSsn = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      input: HTMLInputElement | null = null
    ) => {
      const value = event?.target.value;
      const normalized = formatSsn(value, ssn, () => {
        if (input) {
          moveCursorAfterYear(input);
        }
      });

      setSsn(normalized);
    },
    [ssn]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactCid = useCallback(pipeEvent(setCid), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactEmail = useCallback(pipeEvent(setEmail), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactCompany = useCallback(pipeEvent(setCompany), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactLastName = useCallback(pipeEvent(setLastName), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactFirstName = useCallback(
    pipeEvent(setFirstName),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactAddressZip = useCallback(
    pipeEvent(mustMatch(setAddressZip, /^-?\d*\.?\d*$/)),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactAddressCity = useCallback(
    pipeEvent(setAddressCity),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactPhoneNumber = useCallback(
    pipeEvent(mustMatch(setPhoneNumber, /^-?\d*\.?\d*$/)),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomerContactAddressStreet = useCallback(
    pipeEvent(setAddressStreet),
    []
  );

  const handleCustomerContactType = useCallback(
    (
      customerContactType: CustomerContactType | null,
      event?: ChangeEvent<HTMLInputElement>
    ) => {
      event?.preventDefault();
      setCustomerContactType(customerContactType);
    },
    []
  );

  useEffect(() => {
    if (authentication.status !== 'idle') {
      return;
    }

    // prefill email
    if (email === '' && authentication.user?.email && !emailPrefilled) {
      setEmailPrefilled(true);
      setEmail(authentication.user.email);
    }

    // prefill ssn
    if (ssn === '' && authentication.user?.ssn) {
      const normalizedSsn = normalizeSsn(authentication.user.ssn);
      const isValid = validateSsn(normalizedSsn);

      if (isValid && !ssnPrefilled) {
        setSsnPrefilled(true);
        setSsn(normalizedSsn);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, ssn, authentication]);

  return useMemo<ContactCustomerValues & T>(
    () => ({
      ...values,
      customerContactType,
      productOptions: {
        ...values.productOptions,
        customerContact: {
          ssn,
          cid,
          email,
          status: '',
          company,
          lastName,
          firstName,
          addressCo: '',
          addressZip,
          addressCity,
          phoneNumber,
          addressStreet,
          phoneNumberType: 'Mobile'
        }
      },
      handlers: {
        ...values.handlers,
        handleCustomerContactSsn,
        handleCustomerContactCid,
        handleCustomerContactEmail,
        handleCustomerContactCompany,
        handleCustomerContactLastName,
        handleCustomerContactFirstName,
        handleCustomerContactAddressZip,
        handleCustomerContactAddressCity,
        handleCustomerContactPhoneNumber,
        handleCustomerContactAddressStreet,
        handleCustomerContactType
      }
    }),
    [
      addressCity,
      addressStreet,
      addressZip,
      cid,
      company,
      customerContactType,
      email,
      firstName,
      handleCustomerContactAddressCity,
      handleCustomerContactAddressStreet,
      handleCustomerContactAddressZip,
      handleCustomerContactCid,
      handleCustomerContactCompany,
      handleCustomerContactEmail,
      handleCustomerContactFirstName,
      handleCustomerContactLastName,
      handleCustomerContactPhoneNumber,
      handleCustomerContactSsn,
      handleCustomerContactType,
      lastName,
      phoneNumber,
      ssn,
      values
    ]
  );
}
