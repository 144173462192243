import styled from 'styled-components';
import { primaryButton } from '~/App/helpers/mixins';
import { Container } from '~/App/shared/components/Container';
import { ButtonStyle } from '~/App/config/buttonStyles';
import mq from '~/App/helpers/mq';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { Background } from '../../Blocks/components/BlockHero/components/Background';
import { Width } from '~/types/Block';
import { IntegrityText } from '../components/Forms/components/IntegrityText';

type SubmitProps = {
  buttonStyle: ButtonStyle;
};

type InputWrapperProps = {
  headingSettings?: {
    fontSize: string;
  };
};

type BackgroundProps = {
  width: Width;
};

type StyledIntegrityTextProps = {
  headingSettings?: {
    fontSize: string;
  };
  showLightTextColor: boolean;
};

export const SwishBackground = styled(Background)<BackgroundProps>`
  height: ${({ width }) => (width !== 'full' ? '300px' : '')};
  padding: 0 1.8rem 0;

  ${mq<BackgroundProps>('≥small')`
     height: ${({ width }) => (width !== 'full' ? '264px' : '')}; 
    `};
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  max-width: 29.125rem;
  margin-top: ${({ headingSettings }) =>
    headingSettings?.fontSize === 'heading1' ? '4.5rem' : '2.5rem'};

  ${mq<InputWrapperProps>('<large')`
     margin-top: ${({ headingSettings }) =>
       headingSettings?.fontSize === 'heading1' ? '3.75rem' : '2.5rem'}; 
    `};
  ${mq<InputWrapperProps>('<small')`
      margin-top: ${({ headingSettings }) =>
        headingSettings?.fontSize === 'heading1' ? '3.25rem' : '2.5rem'};
    `};
`;

export const Wrapper = styled(Container)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InputGroup = styled.div`
  display: flex;
  input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    max-height: 2.875rem;
  }

  button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    min-width: 8.5rem;
    max-height: 2.875rem;

    ${mq('≥large')`
  min-width: 10.375rem;
  `};
  }
`;
export const InputFieldWrapper = styled.div`
  flex: 1;
`;

export const Submit = styled(PrimaryButton)<SubmitProps>`
  ${primaryButton};
`;

export const StyledIntegrityText = styled(
  IntegrityText
)<StyledIntegrityTextProps>`
  margin-bottom: ${({ headingSettings }) =>
    headingSettings?.fontSize !== 'heading1' && '0rem'};

  ${mq('<tiny')`
      margin-bottom: 0rem;
  `};

  a {
    text-decoration: underline;
    font-weight: normal;
    color: ${({ showLightTextColor, theme }) =>
      showLightTextColor && theme.colors.white};

    &:hover {
      color: ${({ showLightTextColor, theme }) =>
        showLightTextColor && theme.colors.white};
    }
  }
`;
