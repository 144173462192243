import React from 'react';

import {
  getReceiptName,
  getReceiptNameWithPronoun
} from '~/App/helpers/purchase-helper';

import { Status, AsyncDocumentLink } from './AsyncDocumentLink';

type Props = {
  url: string | null | undefined;
  status: Status;
  paymentMethodId?: number;
};

export function AsyncReceiptDocumentLink({ paymentMethodId, ...props }: Props) {
  return (
    <AsyncDocumentLink
      {...props}
      icon="download"
      fileName={getReceiptName(paymentMethodId)}
      fileNameForLink={`Ladda ner ${getReceiptName(paymentMethodId)}`}
      fileNameWithPronoun={getReceiptNameWithPronoun(paymentMethodId)}
    />
  );
}
