import React from 'react';
import styled, { keyframes } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

// Reducers
import { useAppContext } from '../contexts/App';

const loaderOpacity = keyframes`
  100% {
    opacity: 0.8;
  }
`;

const LoaderOverlay = styled.div`
  pointer-events: none;
  display: block;
  width: 100%;
  height: calc(100vh - 66px);
  position: fixed;
  top: 66px;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  opacity: 0;
  z-index: 9;
  animation: ${loaderOpacity} 0.5s ease 1 forwards;

  ${mq('≥large')`
    height: calc(100vh - 130px);
    top: 130px;
  `};
`;

export function PageLoader() {
  return useAppContext((x) =>
    x.status === 'loading' ? <LoaderOverlay /> : null
  );
}
