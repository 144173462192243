import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  ReactNode
} from 'react';

// Local components
import { Brand } from './components/Brand';
import { Navbar } from './components/Navbar';

import { HighlightedNavigation } from './components/HighlightedNavigation';
import { PrimaryNavigation } from './components/PrimaryNavigation';
import { SecondaryNavigation } from './components/SecondaryNavigation';

import { useBreakpoint } from '~/App/shared/hooks/use-breakpoint';
import { useShowHeader } from './hooks/use-show-header';

import { useScrollLock } from '~/App/contexts/ScrollLock';
import { Login } from '../Login';
type Props = {
  children: ReactNode;
};
export function Header({ children }: Props) {
  const [isOpen, setOpen] = useState(false);
  const withinBreakpoint = useBreakpoint('<mediumLarge', false);

  const visible = useMemo(() => (isOpen && withinBreakpoint ? true : false), [
    isOpen,
    withinBreakpoint
  ]);

  const scrollLock = useScrollLock();
  const showHeader = useShowHeader({ visible });

  const toggleVisible = useCallback(() => {
    if (withinBreakpoint) {
      setOpen((state) => !state);
    }
  }, [withinBreakpoint]);

  useEffect(() => scrollLock.lock(visible), [scrollLock, visible]);

  return useMemo(
    () => (
      <>
        <Navbar showHeader={showHeader}>
          {children}
          <Brand />
          <HighlightedNavigation
            visible={visible}
            toggleVisible={toggleVisible}
          />
          <PrimaryNavigation visible={visible} toggleVisible={toggleVisible} />
          <SecondaryNavigation visible={visible} />
          <Login />
        </Navbar>
      </>
    ),
    [showHeader, visible, toggleVisible, children]
  );
}
