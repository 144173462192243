import { Sha256 } from '@aws-crypto/sha256-browser';

export const getHash = async (value: string): Promise<string> => {
	const hasher = new Sha256();
	hasher.update(value);
	const bitArray = await hasher.digest();
	const result = bitArray.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');

	return result;
};
