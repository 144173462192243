import React, { useState, useEffect, useRef, useCallback } from 'react';

// helpers
import { users } from '~/App/helpers/http';

// hooks
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { useQuery } from '~/App/shared/hooks/use-query';

// Shared components
import { Loading } from './Loading';
import { EmailConfirmationCompleted } from './EmailConfirmationCompleted';

type Status = 'loading' | 'idle';

export function ConfirmEmail() {
  const authentication = useAuthenticationContext();

  const { activationToken } = useQuery();

  const [status, setStatus] = useState<Status>('loading');
  const activated = useRef(false);

  const runActivation = useCallback(
    async (activationToken: string) => {
      try {
        await users.activate({ activationToken });
        await authentication.refresh();

        setStatus('idle');
      } catch {
        setStatus('idle');
      }
    },
    [authentication]
  );

  useEffect(() => {
    if (activated.current) return;
    if (authentication.status !== 'idle') return;
    if (!activationToken) return;
    if (typeof activationToken !== 'string') return;

    activated.current = true;

    runActivation(activationToken);
  }, [activationToken, authentication, runActivation]);

  if (status === 'loading') {
    return <Loading heading="Bekräftar din e-post" />;
  }

  return <EmailConfirmationCompleted />;
}
