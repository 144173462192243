import React from 'react';
import styled from 'styled-components';

// Shared components
import { Teaser } from '~/App/shared/components/Teaser';
import { PrimaryButton } from '~/App/shared/components/Elements';

const StyledTeaser = styled(Teaser)`
  margin: 2.125rem 0 0;
`;

const StyledButton = styled(PrimaryButton)`
  display: block;
  margin: auto;
  width: fit-content;
`;

export function MonthlyDonorTeaser() {
  return (
    <StyledTeaser
      heading="Bli månadsgivare - bidra till livsviktig forskning varje månad"
      description="En av tre får cancer, men alla drabbas. Som månadsgivare är du med och skapar en bättre framtid för fler drabbade, både sjuka och närstående. Ge ett valfritt månadsbelopp och stötta kampen mot cancer."
      imageUrl="https://res.cloudinary.com/cancerfonden/image/upload/v1637673634/assets/bahar-mobil_3x.jpg"
      desktopImageUrl="https://res.cloudinary.com/cancerfonden/image/upload/v1637673634/assets/bahar-desktop_3x.jpg"
    >
      <StyledButton
        to="/stod-oss/bli-framtidskampe"
        buttonStyle="cta"
        buttonType="link"
        children="Bli månadsgivare"
      />
    </StyledTeaser>
  );
}
