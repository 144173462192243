import React, { useMemo } from 'react';

import { paymentMethods } from '~/App/config/paymentMethods';
import { Bold, Row } from '.';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '../../../../components/States';

type Props = {
  values: PaymentValues & ProductValues & ContactCustomerValues;
};

export function PaymentSummery({ values }: Props) {
  return useMemo(
    () => (
      <>
        <Bold>{values.paymentMethod?.name}</Bold>

        {values.paymentMethod?.id === paymentMethods.paymentSlip && (
          <>
            <Row>
              <Bold>{values.productOptions.customerContact.firstName}</Bold>
              <Bold>{values.productOptions.customerContact.lastName}</Bold>
            </Row>
            <Bold margin={false}>
              {values.productOptions.customerContact.company}
            </Bold>
            <Bold margin={false}>
              {values.productOptions.customerContact.addressStreet}
            </Bold>
            <Row>
              <Bold margin={false}>
                {values.productOptions.customerContact.addressZip}
              </Bold>
              <Bold margin={false}>
                {values.productOptions.customerContact.addressCity}
              </Bold>
            </Row>
          </>
        )}
      </>
    ),
    [
      values.paymentMethod?.id,
      values.paymentMethod?.name,
      values.productOptions.customerContact.addressCity,
      values.productOptions.customerContact.addressStreet,
      values.productOptions.customerContact.addressZip,
      values.productOptions.customerContact.company,
      values.productOptions.customerContact.firstName,
      values.productOptions.customerContact.lastName
    ]
  );
}
