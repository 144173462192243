import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

const ProductImageWrapper = styled.div`
  margin: 2.5rem;

  ${mq('≥medium')`
    margin: 0 0 1.5rem 0;
    background-color: ${(props) => props.theme.colors.seashell};
    text-align: center;
    border-radius: 1px;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  `};
`;

const ProductImageContainer = styled.div`
  display: none;

  ${mq('≥medium')`
    display: block;
    margin: 0 auto;
    max-width: 100%;
  `};
`;

const ProductImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const LotteryPreview = function (props) {
  const { lotteryImage } = props;

  return (
    <ProductImageWrapper>
      <ProductImageContainer>
        <ProductImage src={lotteryImage} />
      </ProductImageContainer>
    </ProductImageWrapper>
  );
};
