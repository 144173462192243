import React, { Fragment, PureComponent, createRef } from 'react';

// Helpers
import { blockTemplates } from '~/App/config/blockTemplates';

import { TableOfContents } from '~/App/shared/components/TableOfContents';
import { PageFooter } from '~/App/shared/components/PageFooter';
import BlockArea from '~/App/shared/components/BlockArea';

class Block extends PureComponent {
  constructor(props) {
    super(props);

    this.heroRef = createRef();
  }

  tableOfContentsPosition(blocks) {
    let position = 0;
    blocks.some((block) => {
      if (
        block.template === blockTemplates.hero ||
        block.template === blockTemplates.breadcrumbs
      ) {
        position = position + 1;
      } else {
        return true; // Break loop
      }
    });

    return position;
  }
  footerPosition(blocks) {
    return blocks[blocks.length - 1].template === blockTemplates.related &&
      !this.props.related
      ? blocks.length - 1
      : blocks.length;
  }

  render() {
    const {
      blocks = [],
      showTOC = false,
      tableOfContents = [],
      showPageFooter = false,
      pageFooterWidth = 'tight',
      sharingIcons = {},
      author = {},
      editor = {},
      auditor = {},
      auditDate = null,
      purchase,
      collection
    } = this.props;

    const tableOfContentsPosition = showTOC
      ? this.tableOfContentsPosition(blocks)
      : 0;

    const pageFooterPosition = showPageFooter
      ? this.footerPosition(blocks)
      : blocks.length;

    return (
      <Fragment>
        {showTOC && (
          <Fragment>
            <BlockArea
              blocks={blocks.slice(0, tableOfContentsPosition)}
              heroRef={this.heroRef}
              purchase={purchase}
              collection={collection}
            />
            <TableOfContents
              tableOfContents={tableOfContents}
              heroRef={this.heroRef}
            />
          </Fragment>
        )}
        <BlockArea
          blocks={blocks.slice(tableOfContentsPosition, pageFooterPosition)}
          purchase={purchase}
          collection={collection}
          heroRef={this.heroRef}
        />
        {showPageFooter && (
          <Fragment>
            <PageFooter
              sharingIcons={sharingIcons}
              author={author}
              editor={editor}
              auditor={auditor}
              auditDate={auditDate}
              width={pageFooterWidth}
            />
            <BlockArea
              blocks={blocks.slice(pageFooterPosition)}
              purchase={purchase}
              collection={collection}
              heroRef={this.heroRef}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default Block;
