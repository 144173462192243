import React from 'react';
import styled from 'styled-components';

import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';
import { AmountSelector } from '~/App/shared/components/Donation/components/Forms/components/AmountSelector';
import { TaxReductionInfo } from '~/App/shared/components/Donation/components/Forms/components/TaxReductionInfo';
import { IValidation } from '~/App/shared/components/Validation';
import { CreateMemoryStateValues } from '../hooks/useCreateMemoryState';
import { mq } from '~/lib/mq';

const FieldWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const Preamble = styled.div`
  font-size: 1.125rem;
  margin-bottom: 1rem;

  ${mq('<small')`
    text-size: 1rem;
  `};
`;

type Props = {
  values: CreateMemoryStateValues;
  validation: IValidation;
};

export function SelectAmount({ validation, values }: Props) {
  const preamble =
    values.productOptions.product.period === 'Month'
      ? 'Välj hur mycket du vill skänka i månaden.'
      : 'Välj hur mycket du vill skänka.';

  const amounts =
    values.productOptions.product.period === 'Month'
      ? [150, 200]
      : [350, 500, 800];

  return (
    <>
      <FieldWrapper>
        <Preamble children={preamble} />
        <AmountSelector
          amounts={amounts}
          selectedAmount={values.productOptions.product.price}
          customAmount={values.productOptions.product.customPrice}
          setAmount={values.handlers.setProductPrice}
          setCustomAmount={values.handlers.setProductCustomPrice}
          resetAmount={values.handlers.resetProductPrice}
          validationKey="productOptions.product.price"
          validation={validation}
          tinyLayout="auto"
          smallLayout="auto"
          largeLayout="auto"
        />
        <TaxReductionInfo
          inline
          amount={getListPrice(values.productOptions)}
          quantity={getQuantity(values.productOptions)}
        />
      </FieldWrapper>
    </>
  );
}
