export const scrollLock = (lock: boolean, position = 0) => {
  if (typeof window === 'undefined') {
    return;
  }

  document.body.style.overflow = lock ? 'hidden' : 'visible'; //Setting for IE which does not support 'Initial'
  document.body.style.overflow = lock ? 'hidden' : 'initial';
  document.body.style.height = lock ? '100%' : 'auto';
  document.body.style.width = lock ? '100%' : 'auto';

  // @ts-expect-error
  document.documentElement.style.lock = lock ? 'hidden' : 'auto';

  // @ts-expect-error
  document.documentElement.style.lock = lock ? '100%' : 'auto';

  if (!lock && position) {
    window.scrollTo(0, position);
  }
};
