import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

// Shared components
import LinkList from '~/App/shared/components/LinkList';

export default function Jumbotron({
  heading,
  preamble,
  preambleShort,
  description,
  buttonLink,
  buttonLabel,
  ...props
}) {
  let wrapperClasses = classNames('jumbotron', {
    'jumbotron--textWhite': props.textColor === 'white',
    'jumbotron--textBlack': props.textColor === 'black'
  });

  let titleClasses = classNames('jumbotron__title', {
    [`jumbotron__title--size${_.capitalize(
      props.headingFontSize
    )}`]: !_.isEmpty(props.headingFontSize),
    [`jumbotron__title--weight${_.capitalize(
      props.headingFontWeight
    )}`]: !_.isEmpty(props.headingFontWeight)
  });

  let descriptionPieces = '';

  if (!_.isEmpty(description)) {
    descriptionPieces = description.split(/\r?\n/);
  }

  return (
    <div className={wrapperClasses}>
      {!_.isEmpty(heading) ? (
        <h2
          className={titleClasses}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      ) : null}
      {!_.isEmpty(preamble) || !_.isEmpty(preambleShort) ? (
        <div className="jumbotron__preambleContainer">
          {!_.isEmpty(preamble) ? (
            <p
              className="jumbotron__preamble jumbotron__preamble--default"
              dangerouslySetInnerHTML={{ __html: preamble }}
            />
          ) : null}
          {!_.isEmpty(preambleShort) ? (
            <p
              className="jumbotron__preamble jumbotron__preamble--phone"
              dangerouslySetInnerHTML={{ __html: preambleShort }}
            />
          ) : null}
        </div>
      ) : null}
      {!_.isEmpty(descriptionPieces) ? (
        <ul className="jumbotron__descriptionContainer">
          {_.map(descriptionPieces, (descriptionPiece, key) => {
            return (
              <li
                key={key}
                className="jumbotron__description"
                dangerouslySetInnerHTML={{ __html: _.trim(descriptionPiece) }}
              />
            );
          })}
        </ul>
      ) : null}
      {(!_.isEmpty(buttonLink) && !_.isEmpty(buttonLabel)) ||
      _.get(props, 'data.links', false) ? (
        <div className="jumbotron__links">
          <LinkList
            {...props}
            modifiers={['wideSpacing']}
            buttonLink={buttonLink}
            buttonLabel={buttonLabel}
          />
        </div>
      ) : null}
    </div>
  );
}

Jumbotron.defaultProps = {
  textColor: 'white',
  contentPosition: 'center-middle',
  backgroundPosition: 'center-center'
};
