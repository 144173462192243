import React, { useCallback, useEffect, useRef, useState } from 'react';

import Redirect from '~/App/shared/components/Redirect';
import useQuery from '~/App/shared/hooks/use-query';

import { Container } from '../../../Styled';
import { Logo } from '../../../Logo';
import { Error } from '../../../Error';
import { ICollection } from '~/types/ICollection';
import { Spinner } from '~/App/shared/components/Elements';
import { collections } from '~/App/helpers/http';
import useLoginModal from '~/App/shared/hooks/use-login-modal';

type Props = {
  collection: ICollection;
};

export function JoinCollectionApply({ collection }: Props) {
  const isSent = useRef(false);
  const query = useQuery();
  const loginModal = useLoginModal();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const joinCollection = useCallback(
    async (ownerJoinCode: string) => {
      setSuccess(false);
      setError(false);

      const response = await collections.ownerJoinCode({
        validateStatus: () => true,
        data: {
          ownerJoinCode
        }
      });

      if (response.status >= 200 && response.status < 300) {
        loginModal.handlers.closeModal();

        return setSuccess(true); // added
      }

      setError(true);
    },
    [loginModal.handlers]
  );

  const tryJoinCollection = useCallback(() => {
    if (typeof query.ownerJoinCode !== 'string' || !query.ownerJoinCode) return;

    joinCollection(query.ownerJoinCode);
  }, [joinCollection, query.ownerJoinCode]);

  useEffect(() => {
    if (typeof query.ownerJoinCode !== 'string' || !query.ownerJoinCode) return;
    if (isSent.current) return;

    joinCollection(query.ownerJoinCode);
  }, [joinCollection, query.ownerJoinCode]);

  if (error) {
    return (
      <Error tryAgain={tryJoinCollection}>
        Ett fel inträffade, försök igen
      </Error>
    );
  }

  if (success) {
    return <Redirect to="/min-sida/insamlingar" />;
  }

  return (
    <Container>
      <Logo marginBottom="2rem" />
      <p
        children={`Lägger till dig som administratör i insamlingen "${collection.name}".`}
      />
      <Spinner size="2rem" alternateColor={true} marginTop={true} />
    </Container>
  );
}
