import React, { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';

// shared components
import { ICmsProduct } from '~/types/ICmsProduct';
import { PrimaryButton } from '~/App/shared/components/Elements';

// helpers
import { heading3, link } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1.5rem 1rem;
  flex-basis: 100%;

  ${mq('≥tiny')`
    flex-basis: 50%;
    max-width: 50%;
    padding: 2rem 1rem 3rem;
  `};

  ${mq('≥small')`
    flex-basis: 33%;
    max-width: 33%;
  `};
`;

const Image = styled.img`
  transition: transform 0.3s ease;
  max-width: 100%;
  max-height: 100%;
`;

const ImageWrapper = styled.div`
  display: block;
  border-radius: 1000px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  z-index: 2;

  ${Image} {
    z-index: 1;
  }

  max-width: 3.5rem;
  max-height: 3.5rem;

  ${mq('≥tiny')`
    margin: 0 auto 1.5rem;
    max-width: 12rem;
    max-height 12rem;
  `};
`;

const ZoomOnHoverLink = styled.div`
  ${link};
  position: relative;
  display: flex;
  width: 100%;

  ${mq('≥tiny')`
    flex-direction: column;
    align-items: center;
    text-align: center;
  `};

  .no-touchevents & {
    &:focus,
    &:hover {
      ${Image} {
        transform: scale(1.1);
      }
    }
  }
`;

const Content = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: calc(100% - 104px);

  ${mq('≥tiny')`
    margin-left: 0;
    max-width: 100%;
    align-items: center;
  `};
`;

const Title = styled.h2`
  ${heading3};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 0.4rem;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.hoverBlue};
  }
`;

const Body = styled.p`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.charcoal};
  max-width: 100%;
`;

const BuyButton = styled(PrimaryButton)`
  margin-right: auto;
  margin-top: auto;
  padding-right: 100px;
  max-width: 250px;

  ${mq('≥tiny')`
    margin: auto auto 0;
  `};

  &:after {
    content: 'Köp';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -2px;
    top: -2px;
    height: calc(100% + 4px);
    width: 80px;
    color: white;
    background: ${({ theme }) => theme.colors.darkBlue};
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &:hover,
  &:active {
    background: white;
    color: ${({ theme }) => theme.colors.darkBlue};
    border-color: ${({ theme }) => theme.colors.hoverBlue};

    &:after {
      background: ${({ theme }) => theme.colors.hoverBlue};
    }
  }
`;

type Props = {
  product: ICmsProduct;
};

export function CmsProductCard({ product }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const price = useMemo(
    () =>
      product.priceFixed
        ? `${product.priceFixed.price} kr`
        : `Från ${product.priceFlexible?.prices[0]} kr`,
    [product]
  );

  const to = useMemo(() => ({ ...location, pathname: product.path }), [
    location,
    product.path
  ]);

  const url = useMemo(
    () =>
      product.productImage?.secure_url
        ? mediaHelper.getUrl(
            product.productImage?.secure_url,
            {
              width: 192,
              height: 192,
              quality: 80,
              radius: 'max',
              gravity: 'face'
            },
            'png'
          )
        : 'https://res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png',
    [product]
  );

  const handleClick = useCallback(() => navigate(to), [to, navigate]);

  return useMemo(
    () => (
      <Wrapper>
        <ZoomOnHoverLink onClick={handleClick}>
          <ImageWrapper>
            <Image src={url} alt={product.imageAlt} />
          </ImageWrapper>
          <Content>
            <Title children={product.name} />
            <Body children={product.shortDescription} />
            <BuyButton
              buttonType="button"
              buttonStyle="outlinePrimary"
              children={price}
            />
          </Content>
        </ZoomOnHoverLink>
      </Wrapper>
    ),
    [handleClick, price, product, url]
  );
}
