import React from 'react';

import { Information } from './cards/Information';

export default function Profile() {
  return (
    <>
      <Information />
    </>
  );
}
