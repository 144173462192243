import React from 'react';

import { AsyncDocumentLink, Status } from './AsyncDocumentLink';

type Props = {
  url: string | null | undefined;
  status: Status;
};

export function AsyncCertificateDocumentLink(props: Props) {
  return (
    <AsyncDocumentLink
      {...props}
      fileName="gåvobevis"
      fileNameForLink="Ladda ner gåvobevis"
      fileNameWithPronoun="ditt gåvobevis"
      icon="download"
    />
  );
}
