import React from 'react';
import styled from 'styled-components';

const ProductImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 8.625rem;
  height: 8.625rem;
  margin: 0 auto 1.5rem auto;
`;

const ProductImage = styled.img`
  display: block;
  width: ${(props) => 100 - (props.totalPackages - props.packageNumber) * 10}%;
  height: ${(props) => 100 - (props.totalPackages - props.packageNumber) * 10}%;
  margin: 0 auto;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
`;

export const PackageIllustration = (props) => {
  const { packageNumber = 1, totalPackages = 1, image } = props;

  return (
    <ProductImageWrapper>
      <ProductImage
        src={image}
        packageNumber={packageNumber}
        totalPackages={totalPackages}
      />
    </ProductImageWrapper>
  );
};
