import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { heading2, link } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

import Container from '~/App/shared/components/Container';
import { RangeSlider } from '~/App/shared/components/Elements/RangeSlider';

import { TaxReductionBlockModal } from './TaxReductionBlock.Modal';

const Block = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${mq('>small')`
    flex-direction: row;

    ${Block}:first-child {
      padding-right: 1rem;
    }

    ${Block}:last-child {
      padding-left: 1rem;
    }
  `}
`;

const BlueBox = styled.div`
  border-radius: 2.5rem;
  border-bottom-left-radius: 0rem;
  background-color: ${({ theme }) => theme.colors.lightPolar};
  padding: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mq('>small')`
     padding: 0 4.75rem;
    text-align: center;
    height: 23.375rem;
  `}
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: 'row';
  margin: 0 1rem;
`;

const Heading = styled.h3`
  ${heading2};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 0.75rem;
`;

const SubHeading = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-top: -0.25rem;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1.1275rem;
  max-width: 100%;
`;

const Link = styled.div`
  ${link};
  margin-top: 1rem;
  text-decoration: underline;
`;

const Icon = styled.img`
  width: 4.875rem;
  height: 3.5rem;
  margin-bottom: 1rem;
`;

const StyledRangeSlider = styled(RangeSlider)`
  margin: 2rem 0 1.5rem;
`;

const DEFAULT_VALUE = 50;
const MIN_VALUE = 50;
const MAX_VALUE = 1000;
const STEP_VALUE = 50;

type Content = {
  icon?: string;
  heading?: string;
  subHeading?: string;
  body: string;
};

export function TaxReductionBlock() {
  const [value, setValue] = useState<number>(DEFAULT_VALUE);
  const [modalOpen, setOpen] = useState(false);

  const returnedAmount = useMemo(() => Math.ceil(value * 12 * 0.25), [value]);

  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);

  const { heading, subHeading, body, icon } = useMemo<Content>(() => {
    if (value < 200) {
      return {
        icon:
          'https://res.cloudinary.com/cancerfonden/image/upload/v1615562465/assets/icons/icons-communication-insamlingar.svg',
        body:
          'För att få skatteavdrag måste din månadsgåva vara på minst 200 kr.'
      };
    }

    if (value < 500) {
      return {
        heading: `Få tillbaka ${returnedAmount} kr`,
        body: 'Du behöver inte göra något, vi skickar in alla dina uppgifter.'
      };
    }

    return {
      heading: `Få tillbaka ${returnedAmount} kr`,
      subHeading: 'Maxbelopp för ett kalenderår',
      body: 'Du behöver inte göra något. Vi skickar in dina uppgifter.'
    };
  }, [returnedAmount, value]);

  const modal = useMemo(() => {
    if (!modalOpen) return null;

    return <TaxReductionBlockModal closeModal={closeModal} />;
  }, [modalOpen, closeModal]);

  return (
    <StyledContainer
      width="limited"
      paddingLeft="small"
      paddingRight="small"
      paddingTop="large"
      paddingBottom="large"
    >
      <Block>
        <Heading>Visste du att du kan få pengar tillbaka?</Heading>
        <Text>
          Ange hur mycket du vill ge i månaden, så räknar vi ut vad du kan dra
          av på skatten under ett kalenderår.
        </Text>
        <StyledRangeSlider
          onChange={setValue}
          value={value}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={STEP_VALUE}
        />
        <SpaceBetween>
          <Text children={`${MIN_VALUE} kr`} />
          <Text children={`${MAX_VALUE} kr`} />
        </SpaceBetween>
      </Block>
      <Block>
        <BlueBox>
          {modal}
          {icon && <Icon src={icon} />}
          {heading && <Heading children={heading} />}
          {subHeading && <SubHeading children={subHeading} />}
          <Text children={body} />
          <Link onClick={openModal} children="Så funkar det" />
        </BlueBox>
      </Block>
    </StyledContainer>
  );
}
