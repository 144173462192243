import { collections } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export const loadData = async ({ params }: LoadDataProps<string>) => {
  const { slug = '' } = params;
  const response = await collections.show({ slug });

  return {
    data: response.data
  };
};
