import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { clearfix } from '~/App/helpers/mixins';

const Wrapper = styled.div`
  padding: 0.5em 0;

  ${mq('≥small')`
    border-right: 1px solid #ccc;
    text-align: right;
    padding: 1em 0;
  `};

  ${mq('small ≤ width < medium')`
    font-size: 0.9em;
  `};
`;

const List = styled.div`
  ${clearfix};
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default class SearchCategories extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Wrapper>
        <List>{this.props.children}</List>
      </Wrapper>
    );
  }
}
