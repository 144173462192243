import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

// consts
import * as themes from '~/App/config/themes';

// shared components
import Link from '~/App/shared/components/Link';

// helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';
import { heading3, arrowLink, card } from '~/App/helpers/mixins';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';
import { cog } from '~/App/helpers/icons';

const backgroundColor = featureIsEnabled('FEATURE_LOPBANDET_PINK')
  ? themes.global.collectionItem.imageAlternativeBackground
  : themes.global.exerciseApp.imageBackgroundColor;

const ArrowLink = styled(Link)`
  ${arrowLink};
  line-height: 1.25rem;
  margin-top: 0;

  ${mq('≥small')`
    line-height: 1.5rem;
  `};
`;

const Wrapper = styled.div`
  ${card}
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;

  ${mq('≥small')`
    flex-direction: row;
  `};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Avatar = styled.div`
  display: block;
  width: 5.5rem;
  height: 5.5rem;
  background-color: ${(props) =>
    props.backgroundColor
      ? backgroundColor
      : props.theme.collectionItem.imageBackground};
  border-radius: 50%;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%;
`;

const Info = styled.div`
  position: relative;
  display: block;
  width: calc(100% - 6.5rem);
  margin: 0 1rem 1rem 0;

  ${mq('≥small')`
    margin: 0 0 1rem 1rem;
  `};
`;

const Name = styled.h3`
  ${heading3};
  color: ${({ theme }) => theme.collectionItem.textColor};
  font-size: 1.375rem;
  line-height: 1.227272727;
  word-break: break-word;
  margin-bottom: 0.75rem;
  margin-right: ${(props) => (props.hasEditIcon === true ? '2.5rem' : '0')};
`;

const SettingsLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.collectionItem.settingsLinkColor};

  &:before {
    ${cog};
    font-size: 1.5rem;
  }
`;

const Status = styled.div`
  margin-bottom: 1rem;
  max-width: 29.25rem;
  display: flex;
  justify-content: space-between;

  ${mq('≥small')`
    p {
      font-size: 1rem;
      line-height: 1.5;
    }
  `};
`;

const Actions = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  li {
    margin: 0.25rem 1.25rem 0.25rem 0;

    ${mq('≥small')`
      margin: 0 1.25rem 0 0;
    `};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ActivitiesStyle = styled.p`
  &:before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi4yNjkzIDE5LjEzNDlMNS4zOTAyIDEyLjIxMjVDMy43MTk1NSAxMC41MDcgMy42MjEyOCA3LjY5NzkzIDUuMzkwMiA1Ljk5MjQyQzcuMDYwODQgNC4zODcyMyA5LjcxNDIxIDQuMzg3MjMgMTEuMzg0OSA2LjA5Mjc0TDEyLjU2NDEgNy4xOTYzMUwxMy42NDUxIDYuMDkyNzRDMTUuMzE1OCA0LjM4NzIzIDE4LjE2NTcgNC4zODcyMyAxOS44MzYzIDYuMDkyNzRDMjEuNTA3IDcuNzk4MjUgMjEuNDA4NyAxMC41MDcgMTkuNzM4MSAxMi4xMTIyTDEyLjc2MDcgMTkuMDM0NkMxMi43NjA3IDE5LjIzNTIgMTIuNDY1OSAxOS4yMzUyIDEyLjI2OTMgMTkuMTM0OVoiIGZpbGw9IiNFQzlCQkEiLz4KPC9zdmc+Cg==');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: ' ';
    height: 24px;
    width: 24px;
  }
  height: 24px;
  font-size: 1rem;
  display: flex;
  align-items: center;
`;

export function Card({ page }) {
  const backgroundColor = page.imageUrl === '' || page.imageUrl === null;
  const imageSrc = useMemo(() => {
    const urlEmpty = page.imageUrl === '' || page === null || !page.imageUrl;
    const defaultUrl =
      'http://res.cloudinary.com/cancerfonden/image/upload/v1561540461/grncs9xxyzmee8mywbvs.jpg';
    const imageUrl = urlEmpty ? defaultUrl : page.imageUrl;

    return mediaHelper.getUrl(imageUrl, {
      width: 88,
      height: 88,
      quality: 90,
      crop: 'fill',
      fetch_format: 'auto'
    });
  }, [page]);

  const activitiesCount = useCallback(() => {
    return page.activitiesCount < 1 ? null : page.activitiesCount > 1 ? (
      <ActivitiesStyle>{`${page.activitiesCount} minnen`}</ActivitiesStyle>
    ) : (
      <ActivitiesStyle>{`${page.activitiesCount} minne`}</ActivitiesStyle>
    );
  }, [page.activitiesCount]);

  return useMemo(
    () => (
      <Wrapper key={page.id}>
        <Avatar backgroundColor={backgroundColor}>
          <Image src={imageSrc} />
        </Avatar>
        <Info>
          <Name hasEditIcon={page}>{page.name}</Name>
          <SettingsLink to={`/min-sida/minnessidor/${page.slug}`} />
          {page.enabled && activitiesCount()}
          <Status>{!page.enabled && <p>Borttagen</p>}</Status>
          <Actions>
            <li>
              {!page.enabled ? null : (
                <ArrowLink to={`/stod-oss/minnessidor/${page.slug}`}>
                  Visa minnessidan
                </ArrowLink>
              )}
            </li>
          </Actions>
        </Info>
      </Wrapper>
    ),
    [page, backgroundColor, imageSrc, activitiesCount]
  );
}
