import React, { useCallback, useMemo } from 'react';
import { slice, take } from 'lodash';

import { RelatedCollectionsGlobalComponent } from '~/types/GlobalComponent';

import { contentApi } from '~/App/helpers/env';
import { fetch } from '~/App/helpers/http';
import { useAsync } from '~/App/shared/hooks/use-async';

import { Related } from '~/App/shared/components/Related';

type Pagination = {
  enabled: boolean;
  total: number;
};

const PER_PAGE = 3;

export function RelatedCollections() {
  const fetchGlobalComponent = useCallback(async () => {
    const url = contentApi(`/v1/global-components`);
    const response = await fetch({
      url,
      params: { slug: 'related-collections' }
    });

    return response.data;
  }, []);

  const { loading, res } = useAsync<RelatedCollectionsGlobalComponent>(
    fetchGlobalComponent,
    []
  );

  const pagination = useMemo<Pagination>(() => {
    const totalItems = res?.items?.length || 0;
    const totalPages = Math.ceil(totalItems / PER_PAGE);

    return {
      enabled: totalItems > PER_PAGE,
      total: totalPages
    };
  }, [res?.items?.length]);

  const items = useMemo(() => take(res?.items || [], PER_PAGE), [res]);
  const itemsFor = useCallback(
    (page: number) => slice(res?.items || [], PER_PAGE * (page - 1)),
    [res]
  );

  return useMemo(() => {
    if (loading || !res) {
      return null;
    }

    if (res.items?.length === 0) {
      return null;
    }

    return (
      <Related
        heading={res.heading}
        component="collectionItem"
        contentType="collections"
        backgroundColor="standard"
        padding={{
          top: 'small',
          bottom: 'small'
        }}
        itemsFor={itemsFor}
        items={items}
        slideshow={{ mobile: true }}
        paginationEnabled={pagination.enabled}
        totalPaginationPages={pagination.total}
      />
    );
  }, [items, itemsFor, loading, pagination.enabled, pagination.total, res]);
}
