import { ICommerceOrder, ICommerceOrderStatus } from '~/types/ICommerceOrder';
import { siteAxios } from '.';

export const orders = {
  checkout: (order: ICommerceOrder) => {
    return siteAxios.post<ICommerceOrder>('/api/v2/checkout', order);
  },
  get: (id: string, params?: Record<string, unknown>) => {
    return siteAxios.get<ICommerceOrder>(`/api/v2/orders/${id}`, { params });
  },
  status: (id: string, params?: Record<string, unknown>) => {
    return siteAxios.get<ICommerceOrderStatus>(`/api/v2/orders/${id}/status`, {
      params
    });
  }
};
