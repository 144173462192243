import React from 'react';
import styled from 'styled-components';

// helpers
import { heading2, preamble } from '~/App/helpers/mixins';
import { siteUrl } from '~/App/helpers/env';

// shared components
import ShareButtons from '~/App/shared/components/ShareButtons';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { Header } from './shared/Header';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

const StyledShareButtons = styled(ShareButtons)`
  width: 8.5rem;
  margin: 2rem auto;
`;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

export default function IncreaseMonthlyThanks({ data }) {
  return (
    <Background backgroundColor="primary">
      <Header image="//res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png">
        <Heading1 children={data.heading || 'Tack!'} />
        <Preamble
          children={
            data.description ||
            'Din gåva bidrar till att föra cancerforskningen framåt – du är med och räddar liv.'
          }
        />
        <StyledShareButtons
          showFacebook={true}
          showX={true}
          showEmail={true}
          to={siteUrl('/stod-oss/bli-manadsgivare/')}
        />
      </Header>
    </Background>
  );
}
