import React from 'react';
import styled from 'styled-components';

import { ISubmit } from '.';
import { AlertMessageContainer } from '../../../Elements';
import { IValidation } from '../../../Validation';
import { IntegrityTextArea } from '../Forms/components/IntegrityTextArea';
import { SubmitButton } from '../Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '../Forms/components/SwishOtherDeviceButton';
import { getPurchaseErrorMessage } from '../Forms/helpers';
import { PaymentValues } from '../States';

const IntegrityTextAreaWrapper = styled.div`
  margin: 0.5rem 0;
`;

type Props = {
  values: PaymentValues;
  submit: ISubmit;
  validation: IValidation;
};

export function DonationSubmitButton({ submit, values, validation }: Props) {
  return (
    <>
      {submit.errors && !submit.isPolling && !submit.isSending && (
        <AlertMessageContainer
          messageStyle="error"
          children={getPurchaseErrorMessage(submit.exceptionCode)}
        />
      )}
      <SubmitButton values={values} validation={validation} submit={submit} />
      <SwishOtherDeviceButton values={values} />

      <IntegrityTextAreaWrapper>
        <IntegrityTextArea values={values} />
      </IntegrityTextAreaWrapper>
    </>
  );
}
