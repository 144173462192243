const validateCid = (value) => {
  // Calculate R where R = R1 + R3 + R5 + R7 + R9, and Ri = INT(Ci/5) + (Ci*2) modulo 10
  var vatNumber = value.replace('-', '');
  var R = 0;
  var digit;

  if (vatNumber.length === 0 || vatNumber.length < 10) {
    return false;
  }

  for (var i = 0; i < 9; i = i + 2) {
    digit = Number(vatNumber.charAt(i));
    R = R + Math.floor(digit / 5) + ((digit * 2) % 10);
  }

  // Calculate S where S = C2 + C4 + C6 + C8
  var S = 0;

  for (var i = 1; i < 9; i = i + 2) {
    S = S + Number(vatNumber.charAt(i));
  }

  // Calculate the check digit
  var cd = (10 - ((R + S) % 10)) % 10;
  var last = Number(vatNumber.slice(9, 10));

  // Compare it with the 10th character of the VAT number. If it is the same, then it's a valid check digit.
  if (cd !== last) {
    return false;
  }

  return true;
};

export default validateCid;
