import React from 'react';
import styled from 'styled-components';
import { formatMoney } from 'accounting';

// Helpers
import mq from '~/App/helpers/mq';
import { heading4 } from '~/App/helpers/mixins';

// Shared components
import { CertificatePreview } from './components';
import { Divider } from '~/App/shared/components/Blocks';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import { RightColumnText } from '~/App/shared/components/Donation/components/Forms/components/RightColumnText';

const NewFormHeading = styled(FormHeading)`
  ${mq('<medium')`
    display: none;
  `};
`;

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Heading4 = styled.h4`
  ${heading4};
  margin-bottom: 0.75rem;
`;

const SoloDividerWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const CompanyDonationSummary = function (props) {
  const { part = 1, area } = props;

  let donationImage = props.donationImage;
  let price = props.price;
  let infoText = props.infoText;
  let heading1 = props.heading1;
  let heading2 = props.heading2;
  let hideCompletely = false;

  if (area === 'form') {
    if (part === 1) {
      heading1 = '';
      heading2 = '';
    } else if (part === 2) {
      hideCompletely = true;
    } else if (part === 3) {
      donationImage = null;
      infoText = null;
      heading1 = null;
      heading2 = null;
    }
  }

  if (!hideCompletely) {
    return (
      <React.Fragment>
        {donationImage && (
          <div>
            {heading1 && <NewFormHeading>{heading1}</NewFormHeading>}
            <CertificatePreview donationImage={donationImage} />
          </div>
        )}
        {!infoText && (
          <SoloDividerWrapper>
            <Divider width="narrow" paddingLeft="none" paddingRight="none" />
          </SoloDividerWrapper>
        )}
        {heading2 && <NewFormHeading>{heading2}</NewFormHeading>}
        <AmountWrapper>
          <span>Gåvobelopp</span>
          <span>
            <Heading4>
              {formatMoney(price, 'kr', 0, ' ', null, '%v %s')}
            </Heading4>
          </span>
        </AmountWrapper>

        {infoText && (
          <React.Fragment>
            <Divider
              width="narrow"
              paddingLeft="none"
              paddingRight="none"
              height="2px"
              alternativeColor={true}
            />
            <RightColumnText icon="heart">{infoText}</RightColumnText>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  } else {
    return null;
  }
};
