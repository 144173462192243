import React, { useMemo } from 'react';

// Form parts
import FormPartCollection from './Form.Part.Collection';
import FormPartLogin from './Form.Part.Login';

import SubmitButton from './LeftColumn.SubmitButton';

import { Spinner } from '~/App/shared/components/Elements';
import { useLogin } from '~/App/shared/hooks/use-login';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

export default function LeftColumn({ values = {}, validation = {}, submit }) {
  const loginHandler = useLogin();

  const { isIdle, isAuthenticated } = useAuthenticationContext();
  const lastPartActive = useMemo(
    () => values.partOptions.currentPart === values.partOptions.parts,
    [values.partOptions.currentPart, values.partOptions.parts]
  );

  return (
    <form onSubmit={submit.handleSubmit} noValidate>
      <FormPartCollection values={values} validation={validation} part={1} />
      <FormPartLogin values={values} validation={validation} part={2} />

      {isIdle ? null : <Spinner size="1.5em" marginTop />}

      {lastPartActive ? (
        <SubmitButton
          values={values}
          validation={validation}
          submit={submit}
          loginHandler={loginHandler}
          loading={!isIdle}
          authenticated={isAuthenticated}
        />
      ) : null}
    </form>
  );
}
