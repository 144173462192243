import React, { useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

// Local components
import State from './components/State';
import Submit from './components/Submit';
import LeftColumn from './components/LeftColumn';
import RightColumn from './components/RightColumn';
import { schema } from './index.schema';
import { buildState, partOptions } from './index.state';

// Shared components
import Validation from '~/App/shared/components/Validation';
import * as MultiPartForm from '~/App/shared/components/MultiPartForm';
import * as TwoColumn from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import { ImageSelectorProvider } from '../Fields/ImageSelector';

const StyledWrapper = styled(TwoColumn.Wrapper)`
  ${mq('<medium')`
      display: flex;
      flex-direction: column;
  `};
`;

const StyledRightColumn = styled(TwoColumn.RightColumn)`
  ${mq('<medium')`
      display: block;
      width: 100%;
      margin-left: 0;
      margin-bottom: 1rem;
  `};
`;

const StyledLeftColumn = styled(TwoColumn.LeftColumn)`
  ${mq('<medium')`
      order: 1;
  `};
`;

export default function CreateCollection({ data }) {
  return useMemo(
    () => (
      <Hoc state={buildState(data)} schema={schema}>
        {({ values, validation, submit }) => (
          <div>
            <StyledWrapper>
              <StyledLeftColumn>
                <LeftColumn
                  values={values}
                  submit={submit}
                  validation={validation}
                />
              </StyledLeftColumn>
              <StyledRightColumn>
                <RightColumn
                  heading={data.rightColumnHeading}
                  items={data.rightColumnItems}
                />
              </StyledRightColumn>
            </StyledWrapper>
          </div>
        )}
      </Hoc>
    ),
    [data]
  );
}

const Hoc = ({ state, schema, children }) => (
  <ImageSelectorProvider>
    <State defaultState={state}>
      {(values) => (
        <Validation schema={schema} values={values}>
          {(validation) => (
            <MultiPartForm.State
              values={values}
              validation={validation}
              {...partOptions}
            >
              {(multiPartValues) => (
                <Submit {...multiPartValues}>
                  {(submit) =>
                    children({
                      values: multiPartValues,
                      validation,
                      submit
                    })
                  }
                </Submit>
              )}
            </MultiPartForm.State>
          )}
        </Validation>
      )}
    </State>
  </ImageSelectorProvider>
);
