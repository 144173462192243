import React from 'react';

import { HeartIcon } from './components/HeartIcon';
import { Button } from '../../../Button';

const DONATION_URL = '/stod-oss/ge-en-gava';

export function DonationButton() {
  return (
    <Button
      to={DONATION_URL}
      icon={<HeartIcon />}
      text="Ge en gåva"
      pulsating={false}
    />
  );
}
