import React, { ReactNode, useMemo } from 'react';
import mediaHelper from '~/App/helpers/media-helper';

import { NavItem } from './components/NavItem';
import { NavText } from './components/NavText';
import { NavLink } from './components/NavLink';
import { NavIcon } from './components/NavIcon';

import { IPrimaryMenuItem } from '~/App/config/navigation';
import { useLocation } from 'react-router';
import { isActive } from '~/App/helpers/menu-item-is-active';

type Props = {
  item: IPrimaryMenuItem;
  children?: ReactNode;
  onClick?: () => void;
};

export function NavigationItem({ item, children, onClick }: Props) {
  const subNavigation = item?.menuItems?.length > 0;
  const location = useLocation();
  const active = useMemo(
    () =>
      isActive({
        url: item.url,
        pathname: location.pathname,
        alternativeActiveUrls: item.alternativeActiveUrls
      }),
    [item.alternativeActiveUrls, item.url, location.pathname]
  );

  return useMemo(
    () => (
      <NavItem
        active={active}
        hideOn={item.hideOn}
        subNavigation={subNavigation}
      >
        <NavLink
          active={active}
          to={item.url}
          highlighted={item.highlighted}
          onClick={onClick}
        >
          {item.icon && (
            <NavIcon src={mediaHelper.getUrl(item.icon, { height: 24 })} />
          )}
          <NavText
            active={active}
            highlighted={item.highlighted}
            children={item.text}
          />
        </NavLink>
        {children || null}
      </NavItem>
    ),
    [active, children, item, onClick, subNavigation]
  );
}
