import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { heading3 } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

type LargeTextProps = {
  isActive: boolean;
  isValid: boolean;
  isStarted: boolean;
  isInvalid: boolean;
};

export const LargeText = styled.div<LargeTextProps>`
  ${heading3};
  color: ${({ theme, isActive, isValid, isInvalid, isStarted }) => {
    if (isStarted) {
      if (isValid || isInvalid) {
        return theme.colors.white;
      }
    }

    if (!isActive) {
      return theme.colors.darkBlue;
    }

    return theme.colors.white;
  }};
`;

type WrapperProps = {
  isActive: boolean;
  isValid: boolean;
  isStarted: boolean;
  isInvalid: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  padding: 1.25rem;
  flex-grow: 1;
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme, isActive, isValid, isInvalid, isStarted }) => {
    if (isStarted) {
      if (isValid) {
        return theme.colors.darkApple;
      }
      if (isInvalid) {
        return theme.colors.errorRed;
      }
    }

    if (isActive) {
      return theme.colors.darkBlue;
    }

    return theme.colors.white;
  }};

  div:not(:first-child) {
    margin-top: 0.5rem;
  }

  ${mq('≥small')`
    min-height: 4.375rem;
  `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

type TextProps = {
  isValid: boolean;
  isStarted: boolean;
  isInvalid: boolean;
};

const Text = styled.div<TextProps>`
  color: ${({ isStarted, isInvalid, isValid, theme }) => {
    if (isStarted) {
      if (isValid || isInvalid) {
        return theme.colors.white;
      }
    }

    return 'inherit';
  }};
`;

const Link = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background: transparent;
  border: none;
  text-decoration: underline;
`;

type Props = {
  heading: ReactNode;
  subHeading: ReactNode;
  isActive: boolean;
  isValid: boolean;
  isStarted: boolean;
  isInvalid: boolean;
  onEdit: () => void;
};

export function Heading({
  heading,
  subHeading,
  isActive,
  isValid,
  isStarted,
  isInvalid,
  onEdit
}: Props) {
  const showSubHeading = useMemo(() => {
    if (!subHeading) return false;
    if (isActive) return false;
    if (isValid) return false;
    if (isInvalid) return false;

    return true;
  }, [isActive, isInvalid, isValid, subHeading]);

  const showEditButton = useMemo(() => {
    if (!isStarted) return false;
    if (isActive) return false;

    return true;
  }, [isActive, isStarted]);

  return (
    <Wrapper
      isActive={isActive}
      isValid={isValid}
      isStarted={isStarted}
      isInvalid={isInvalid}
    >
      <Column>
        {heading && (
          <LargeText
            children={heading}
            isActive={isActive}
            isValid={isValid}
            isStarted={isStarted}
            isInvalid={isInvalid}
          />
        )}
        {showSubHeading && (
          <Text
            children={subHeading}
            isValid={isValid}
            isInvalid={isInvalid}
            isStarted={isStarted}
          />
        )}
      </Column>
      {showEditButton && (
        <Link type="button" children="Ändra" onClick={onEdit} />
      )}
    </Wrapper>
  );
}
