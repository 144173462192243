import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';
import { formatMoney } from 'accounting';
import { ParsedQuery, stringify } from '~/lib/query-string';

// Config
import { buttonStyles } from '~/App/config/buttonStyles';
import { downloadLinkIcon } from '~/App/helpers/icons';

// Helpers
import { primaryButton } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

import SearchResultItem from './SearchResultItem';
import Container from '~/App/shared/components/Container';
import { CSVLink } from 'react-csv';
import { contentApi } from '~/App/helpers/env';
import axios from 'axios';
import { ResearchProject } from '../../../ResearchProject/ResearchProject';
import { Request } from '../../Research';

const Link = styled.a`
  display: block;
  width: 13rem;
  margin-top: 2.125rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 1.125rem;
  text-align: center;

  &:after {
    ${downloadLinkIcon}
    font-size: 1.5rem;
    top: 2px;
  }

  ${mq('<small')`
    margin: 0 auto;
    margin-top: 2.125rem;
    margin-bottom: 1rem;
  `};
`;

const PaginationSpan = styled.span`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 3em;
  font-size: 1.125rem;
  line-height: 1.5;
`;

const PrimaryButton = styled.button`
  ${primaryButton};
  display: block;
  margin: 0 auto;
  margin-top: 1em;
`;

const NoBreak = styled.span`
  white-space: nowrap;
`;

type ResearchProjectItem = {
  item: ResearchProject;
};

type Props = {
  researchProjects: ResearchProject[];
  query: ParsedQuery;
  numberOfProjects: number;
};

export function SearchResults({
  researchProjects,
  query,
  numberOfProjects
}: Props) {
  const csvLink = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [downloadData, setDownloadData] = useState([]);
  const location = useLocation();

  const navigate = useNavigate();

  const loadMore = useCallback(
    (event) => {
      event.preventDefault();

      const queryString = stringify({
        ...query,
        take: query.take ? Number(query.take) + 10 : 20
      });

      navigate(`${location.pathname}?${queryString}`);
    },
    [navigate, location.pathname, query]
  );

  const renderResearchProject = useCallback(
    ({ item }) => (
      <SearchResultItem
        key={item.id}
        amount={
          <NoBreak>
            {formatMoney(item.amount, 'kr', 0, ' ', '', '%v %s')}
          </NoBreak>
        }
        slug={item.slug}
        heading={item.title}
        preamble={item.background}
        applicant={item.applicant}
        university={item.university}
        researchArea={item.researchArea}
        diagnoseGroups={item.diagnoseGroups}
        contentType={item.contentType}
        onGoing={item.status === 'ongoing'}
      />
    ),
    []
  );

  const handleDownloadClick = useCallback(
    async (event) => {
      event.preventDefault();

      const request: Request = {
        url: contentApi('/v1/research-projects'),
        params: {
          ...query,
          take: numberOfProjects
        },
        method: 'get',
        timeout: 15000,
        responseType: 'json'
      };

      const response = await axios(request);
      const data = response.data?.researchProjects;

      const translateGender = {
        male: 'Man',
        female: 'Kvinna'
      };

      const translateStatus = {
        ongoing: 'Pågående',
        completed: 'Avslutad'
      };

      const dataWithoutItem = await data.map((item: ResearchProjectItem) => ({
        Titel: item.item.title,
        Universitet: item.item.university,
        Forskningsområde: item.item.researchArea,
        Diagnosgrupp: item.item.diagnoseGroups?.join(', '),
        Forskare: item.item.applicant,
        Kön: item.item.gender in translateGender ? translateGender[item.item.gender] : item.item.gender,
        Summa: item.item.amount,
        År: item.item.year,
        Projektstatus: item.item.status in translateStatus ? translateStatus[item.item.status] : item.item.status,
        Bakgrund: item.item.background,
        Beskrivning: item.item.description,
        Målsättning: item.item.goal
      }));

      setDownloadData(dataWithoutItem);
      csvLink.current?.link.click();
    },
    [query, numberOfProjects]
  );

  return (
    <Container
      width="limited"
      paddingLeft="small"
      paddingRight="small"
      paddingBottom="large"
    >
      <Link onClick={handleDownloadClick}>Ladda ner lista (csv) </Link>
      <CSVLink
        ref={csvLink}
        rel="noopener noreferrer"
        filename="Cancerfonden-forskningproject.csv"
        data={downloadData}
        className="hidden"
        target="_blank"
        separator={';'}
      ></CSVLink>
      {researchProjects.map(renderResearchProject)}
      {researchProjects.length < numberOfProjects && (
        <PaginationSpan>
          Visar {researchProjects.length} av {numberOfProjects}
        </PaginationSpan>
      )}
      {researchProjects.length < numberOfProjects && (
        <PrimaryButton
          onClick={loadMore}
          buttonStyle={buttonStyles.outlinePrimary}
        >
          Visa fler
        </PrimaryButton>
      )}
    </Container>
  );
}
