import { createContext, Dispatch, SetStateAction } from 'react';
import { IUser } from '~/types/IUser';

type User = IUser | null;

export type UserState = 'authenticated' | 'anonymous';
export type UserStatus =
  | 'idle'
  | 'loading'
  | 'polling'
  | 'signing'
  | 'aborting'
  | 'waitingForAppStart'
  | 'waitingForBankID';

export type UpdateResult =
  | {
      success: true;
      user: IUser;
    }
  | {
      success: false;
      errors: Record<string, string[]>;
    };

export type AuthenticationContextValue = {
  user: User;
  state: UserState;
  status: UserStatus;
  errors: string[];

  isIdle: boolean;
  isAuthenticated: boolean;
  isAnonymous: boolean;

  setState: Dispatch<SetStateAction<UserState>>;
  setStatus: Dispatch<SetStateAction<UserStatus>>;
  setUser: Dispatch<SetStateAction<User>>;
  setErrors: Dispatch<SetStateAction<string[]>>;
  update: (user: IUser | Partial<IUser>) => Promise<UpdateResult>;
  refresh: () => Promise<void>;
};

export const AuthenticationContext = createContext<AuthenticationContextValue>({
  user: null,
  state: 'anonymous',
  status: 'idle',
  errors: [],

  isIdle: true,
  isAuthenticated: false,
  isAnonymous: true,

  setUser: (_: User | ((_: User) => User)) => null,
  setState: (_: UserState | ((_: UserState) => UserState)) => null,
  setStatus: (_: UserStatus | ((_: UserStatus) => UserStatus)) => null,
  setErrors: (_: string[] | ((_: string[]) => string[])) => null,
  update: (_: IUser | Partial<IUser>) =>
    Promise.resolve({ success: true, user: {} }),
  refresh: () => Promise.resolve()
});
