import React from 'react';

import { ICmsProduct } from '~/types/ICmsProduct';
import { IPurchaseShow } from '~/types/IPurchaseShow';

import { Header } from './components/Header';
import { ReceiptAndCertificate } from './components/ReceiptAndCertificate';
import { Share } from './components/Share';
import { Wrapper } from './components/Wrapper';

type Props = {
  product: ICmsProduct;
  purchase: IPurchaseShow;
};

export default function CmsProductThankYouPrivate({
  purchase,
  product
}: Props) {
  const name =
    purchase.purchase.productOptions?.customerContact?.firstName ??
    purchase.user?.firstName;

  return (
    <Wrapper>
      <Header
        heading={`Stort tack ${name} – du är med och räddar liv!`}
        purchase={purchase}
        product={product}
      >
        Vill du handla fler livsviktiga produkter? Du behöver inte ange dina
        kontaktuppgifter igen utan kan snabbt och enkelt handla mer i vår
        gåvoshop.
      </Header>
      <Share />
      <ReceiptAndCertificate
        heading="Ditt gåvobevis och kvitto"
        purchase={purchase}
      />
    </Wrapper>
  );
}
