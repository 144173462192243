import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { get, set, remove } from '~/App/helpers/cookie';
import { collectionActivities } from '~/App/helpers/http';

type ButtonProps = {
  isLiked: boolean;
};

const Button = styled.button<ButtonProps>`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkBlue};
  cursor: ${({ isLiked }) => (isLiked ? 'pointer' : 'default')};
`;

const Image = styled.img`
  margin-right: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
`;

type Props = {
  collectionActivityId: number;
  onLike: (count: number) => void;
};

export function LikeButton({ collectionActivityId, onLike }: Props) {
  const [isLiked, setLiked] = useState(false);

  const cookieKey = useMemo(() => `_cf_likes_${collectionActivityId}`, [
    collectionActivityId
  ]);

  const handleClick = useCallback(async () => {
    if (isLiked) {
      try {
        setLiked(false);

        remove(cookieKey);
        const { data } = await collectionActivities.unlike(
          collectionActivityId
        );

        onLike(data.likes);
      } catch (error) {
        setLiked(true);
      }
      return;
    }

    try {
      setLiked(true);
      set(cookieKey, 'true', 24 * 360 * 5);

      const { data } = await collectionActivities.like(collectionActivityId);

      onLike(data.likes);
    } catch (error) {
      setLiked(false);
      remove(cookieKey);
    }
  }, [collectionActivityId, cookieKey, isLiked, onLike]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (get(cookieKey)) {
      setLiked(true);
    }
  }, [cookieKey]);

  return (
    <Button isLiked={isLiked} onClick={handleClick}>
      <Image
        src={
          isLiked
            ? 'https://res.cloudinary.com/cancerfonden/image/upload/v1640858204/minnesida/icons-ui-heart-pink-filled_1.svg'
            : 'https://res.cloudinary.com/cancerfonden/image/upload/v1640858207/minnesida/icons-ui-heart-blue.svg'
        }
      />
      {isLiked ? 'Gillat' : 'Gilla'}
    </Button>
  );
}
