import React, { useCallback, useState, useMemo, ReactNode } from 'react';
import { orderBy, filter, map } from 'lodash';
import styled from 'styled-components';

import { IValidation } from '~/App/shared/components/Validation';
import { IFavourablePaymentMethod } from '~/types/IPaymentMethod';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '../../../States';
import { Item } from './components/Item';
import { link } from '~/App/helpers/mixins';
import { angleDown, angleUp } from '~/App/helpers/icons';
import { useFetchBankAccount } from '../FetchBankAccount';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type ButtonProps = {
  isOpen: boolean;
};

const Button = styled.div<ButtonProps>`
  ${link};
  ::after {
    ${({ isOpen }) => (isOpen ? angleUp : angleDown)};
    margin-left: 0.5rem;
    font-size: 1.5rem;
    top: 0.225rem;
  }

  font-size: 0.875rem;
`;

type Props = {
  methods: IFavourablePaymentMethod[];
  values: PaymentValues & ContactCustomerValues & ProductValues;
  validation: IValidation;
  formContent: {
    taxReductionInfoLink?: string;
  };
  pushGtmFormInteraction?: (
    method: IFavourablePaymentMethod,
    fieldName: string | undefined
  ) => void;
  children: ReactNode;
};

export function FavourablePaymentMethodSelector({
  methods,
  values,
  validation,
  formContent,
  children,
  pushGtmFormInteraction
}: Props) {
  const [showAll, setShowAll] = useState(false);

  const fetchBankAccount = useFetchBankAccount({ values });
  const hasHiddenMethods = useMemo(() => !!methods.find((x) => !x.favourable), [
    methods
  ]);

  const renderItem = useCallback(
    (method: IFavourablePaymentMethod) => (
      <Item
        key={method.id}
        method={method}
        values={values}
        validation={validation}
        formContent={formContent}
        fetchBankAccount={fetchBankAccount}
        children={children}
        pushGtmFormInteraction={pushGtmFormInteraction}
      />
    ),
    [
      children,
      fetchBankAccount,
      formContent,
      validation,
      values,
      pushGtmFormInteraction
    ]
  );

  const toggleShow = useCallback(() => setShowAll((x) => !x), []);

  const items = useMemo(() => {
    const sorted = orderBy(
      methods,
      [(x: IFavourablePaymentMethod) => x.favourable || false],
      ['desc']
    );
    const filtered = filter(sorted, (x: IFavourablePaymentMethod) =>
      showAll ? true : x.favourable
    );
    const mapped = map(filtered, renderItem);

    return mapped;
  }, [methods, renderItem, showAll]);

  const button = useMemo(
    () =>
      hasHiddenMethods ? (
        <ButtonWrapper>
          <Button
            onClick={toggleShow}
            children={showAll ? 'Dölj fler betalsätt' : 'Visa fler betalsätt'}
            isOpen={showAll}
            data-cy="show-all"
          />
        </ButtonWrapper>
      ) : null,
    [hasHiddenMethods, showAll, toggleShow]
  );

  return (
    <Wrapper>
      {items}
      {button}
    </Wrapper>
  );
}
