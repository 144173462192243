import React, { useState, ChangeEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { usePageContent } from '../hooks/use-page-content';
import { feedback } from '~/App/helpers/http';
import { pushGTMEvent } from '~/App/helpers/gtm-helper';
import { thumbsUp } from '~/App/helpers/icons';
import { gtmCustomEventCategories } from '~/App/config/gtmCustomEvents';

import TextArea from '~/App/shared/components/Fields/TextArea';
import { PrimaryButton } from '~/App/shared/components/Elements';

const FeedbackWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 2rem auto;
  padding: 1.5rem 1rem;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.lightPolar};

  &:before {
    content: '';
    height: 1px;
    width: calc(100% + 2rem);
    background: ${(props) => props.theme.colors.lightDust};
    display: block;
    position: relative;
    top: -2.5rem;
    left: -1rem;
  }
`;

const CenteredContent = styled.div`
  text-align: center;
`;

const ThumbButton = styled(PrimaryButton)`
  min-width: 0;
  margin: 1rem 0.5rem 0;
  padding-left: 2.5rem;

  &:after {
    ${thumbsUp};
    position: absolute;
    top: 0;
    left: 0.5rem;
    font-size: 2rem;
  }
`;

type ThumbsButtonProps = {
  tabindex: string;
  clicked: boolean;
};

const ThumbsUpButton = styled(ThumbButton)<ThumbsButtonProps>`
  border-color: ${(props) =>
    props.clicked ? props.theme.colors.darkApple : null};
  color: ${(props) => (props.clicked ? props.theme.colors.darkApple : null)};

  &:hover {
    background-color: ${(props) => props.theme.colors.transparent};
    border-color: ${(props) =>
      props.clicked
        ? props.theme.colors.darkApple
        : props.theme.colors.hoverBlue};
    color: ${(props) =>
      props.clicked
        ? props.theme.colors.darkApple
        : props.theme.colors.hoverBlue};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.transparent};
    border-color: ${(props) => props.theme.colors.darkApple};
    color: ${(props) => props.theme.colors.darkApple};
  }
`;

const ThumbsDownButton = styled(ThumbButton)<ThumbsButtonProps>`
  border-color: ${(props) =>
    props.clicked ? props.theme.colors.errorRed : null};
  color: ${(props) => (props.clicked ? props.theme.colors.errorRed : null)};

  &:after {
    transform: rotateX(180deg);
    top: 0.25rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.transparent};
    border-color: ${(props) =>
      props.clicked
        ? props.theme.colors.errorRed
        : props.theme.colors.hoverBlue};
    color: ${(props) =>
      props.clicked
        ? props.theme.colors.errorRed
        : props.theme.colors.hoverBlue};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.transparent};
    border-color: ${(props) => props.theme.colors.errorRed};
    color: ${(props) => props.theme.colors.errorRed};
  }
`;

const FollowUpQuestion = styled.p`
  margin: 1rem auto;
  text-align: center;
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: block;
  margin: 1rem auto;
`;

const StyledTextArea = styled(TextArea)`
  text-align: left;
  min-height: 140px;

  .Label {
    text-align: left;
  }
`;

const ThanksMessage = styled.p`
  margin-top: 1.5rem;
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const Feedback = () => {
  const location = useLocation();
  const pageContent = usePageContent();

  const [like, setLike] = useState<boolean | null>(null);
  const [answer, setAnswer] = useState<string>('');
  const [feedbackSubmited, setFeedbackSubmited] = useState<boolean>(false);
  const [publicId, setPublicId] = useState<string>('');

  const { template, showFeedback } = pageContent?.data || {};

  const question = useMemo(() => {
    switch (template) {
      case 'article':
      case 'block':
        return {
          mainQuestion: 'Hittade du informationen du sökte?',
          followUpQuestion: 'Vad skulle vi kunna göra bättre?'
        };
      case 'newsPage':
        return {
          mainQuestion: 'Var det här en intressant nyhet?',
          followUpQuestion: 'Vad skulle du vilja läsa om?'
        };
    }

    return null;
  }, [template]);

  const sendEventToGtm = useCallback((value: boolean) => {
    const gtmPropertyName = value ? 'thumbsUp' : 'thumbsDown';

    pushGTMEvent({
      category: gtmCustomEventCategories.feedback._category,
      action: gtmCustomEventCategories.feedback[gtmPropertyName]._action,
      label: gtmCustomEventCategories.feedback[gtmPropertyName]._label
    });
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAnswer(event.target.value);
  };

  const handleLikeSubmit = useCallback(
    (value: boolean) => async () => {
      if (value === like) return;

      setLike(value);

      try {
        //Send feedback to MW
        const response = await feedback.send({
          data: {
            source: location.pathname,
            question: question?.followUpQuestion,
            like: value
          }
        });

        setPublicId(response.data.publicId);
      } catch (error) {
        console.log(error);
      }

      //Send feedback to GA
      sendEventToGtm(value);
    },
    [like, sendEventToGtm, location.pathname, question?.followUpQuestion]
  );

  const handleTextSubmit = useCallback(async () => {
    setFeedbackSubmited(true);
    try {
      await feedback.edit({
        data: {
          public_id: publicId,
          answer: answer
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [answer, publicId]);

  return useMemo(() => {
    if (template === 'pressPage' || !showFeedback) {
      return null;
    }

    return (
      <FeedbackWrapper>
        <CenteredContent>
          <p children={question?.mainQuestion} />
          <ThumbsUpButton
            tabindex="0"
            buttonStyle="outlinePrimary"
            onClick={handleLikeSubmit(true)}
            clicked={like || false}
            children="Ja"
          />

          <ThumbsDownButton
            tabindex="0"
            buttonStyle="outlinePrimary"
            onClick={handleLikeSubmit(false)}
            clicked={like === false}
            children="Nej"
          />

          {(like || feedbackSubmited) && (
            <ThanksMessage children="Tack för din feedback!" />
          )}
        </CenteredContent>
        {like === false && !feedbackSubmited && (
          <>
            <FollowUpQuestion children={question?.followUpQuestion || null} />
            <StyledTextArea
              name="feedback"
              value={answer}
              maxLength="2000"
              placeholder="Skriv här"
              onChange={handleChange}
            />
            <StyledPrimaryButton
              onClick={handleTextSubmit}
              isDisabled={answer === ''}
              children="Skicka ditt svar"
            />
          </>
        )}
      </FeedbackWrapper>
    );
  }, [
    answer,
    feedbackSubmited,
    handleLikeSubmit,
    handleTextSubmit,
    like,
    question?.followUpQuestion,
    question?.mainQuestion,
    showFeedback,
    template
  ]);
};

export default Feedback;
