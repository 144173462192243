import { swishTypes } from '~/App/config/swishTypes';
import { paymentMethods } from '~/App/config/paymentMethods';

import {
  ProductValues,
  ContactCustomerValues,
  PaymentValues,
  CertificateValues
} from '../components/States';

type Values = ProductValues &
  ContactCustomerValues &
  PaymentValues &
  CertificateValues;

export const buildSchema = () => ({
  'productOptions.product.price': {
    presence: {
      allowEmpty: false,
      message: '^Ange ett belopp'
    },
    numericality: {
      greaterThan: 49,
      lessThanOrEqualTo: 999999,
      message: '^Minsta gåvobelopp är 50 kr'
    }
  },
  'productOptions.certificate.textGreeting': {
    presence: {
      allowEmpty: false,
      message: '^Skriv en hälsning och ditt/era namn'
    }
  },
  'productOptions.customerContact.email': {
    validateEmail: (_: unknown, { paymentMethod }: Values) =>
      paymentMethod?.id !== paymentMethods.klarnaPayments
  },
  'productOptions.customerContact.ssn': {
    validateSsn: (_: unknown, { customerContactType }: Values) =>
      customerContactType === 'automatic'
  },
  'productOptions.customerContact.firstName': {
    validateFirstName: (
      _: unknown,
      { customerContactType, paymentMethod }: Values
    ) =>
      (customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip) &&
      paymentMethod?.id !== paymentMethods.klarnaPayments
  },
  'productOptions.customerContact.lastName': {
    validateLastName: (
      _: unknown,
      { customerContactType, paymentMethod }: Values
    ) =>
      (customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip) &&
      paymentMethod?.id !== paymentMethods.klarnaPayments
  },
  'productOptions.customerContact.addressStreet': {
    validateStreetName: (
      _: unknown,
      { customerContactType, paymentMethod }: Values
    ) =>
      (customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip) &&
      paymentMethod?.id !== paymentMethods.klarnaPayments
  },
  'productOptions.customerContact.addressZip': {
    validateZipCode: (
      _: unknown,
      { customerContactType, paymentMethod }: Values
    ) =>
      (customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip) &&
      paymentMethod?.id !== paymentMethods.klarnaPayments
  },
  'productOptions.customerContact.addressCity': {
    validateCity: (
      _: unknown,
      { customerContactType, paymentMethod }: Values
    ) =>
      (customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip) &&
      paymentMethod?.id !== paymentMethods.klarnaPayments
  },
  'productOptions.certificate.receiverContact.email': function (
    value: string,
    { productOptions }: Values
  ) {
    if (!value) {
      if (productOptions.certificate.receiverContact?.name) {
        return {
          presence: {
            allowEmpty: false,
            message: '^Var vänlig kontrollera e-postadressen'
          }
        };
      }
    }

    return {
      validateOptionalEmail: {
        message: '^Felaktig e-postadress'
      }
    };
  },
  'productOptions.certificate.receiverContact.name': function (
    _: unknown,
    { productOptions }: Values
  ) {
    return {
      presence: {
        allowEmpty: !productOptions.certificate.receiverContact?.email,
        message: '^Glöm inte att fylla i mottagarens namn'
      }
    };
  },
  'productOptions.certificate.deliveryDate': {
    presence: {
      allowEmpty: false,
      message: '^Glöm inte att fylla i datum'
    }
  },

  swishPhoneNumber: {
    validatePhoneNumber: (_: unknown, { paymentMethod, swish }: Values) =>
      paymentMethod?.id === paymentMethods.swish &&
      swish.swishType === swishTypes.ecommerce
  },
  'klarna.isReady': {
    inclusion: (_: unknown, { paymentMethod }: Values) => {
      if (paymentMethod?.id === paymentMethods.klarnaPayments) {
        return {
          within: [true],
          message: '^Klarna är inte redo'
        };
      }

      return false;
    }
  }
});
