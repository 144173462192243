import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

export default class Intro extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      heading = '',
      preamble = '',
      maxWidth = '',
      textAlign = '',
      description = '',
      headingFontSize = '',
      headingFontWeight = '',
      preambleFontWeight = ''
    } = this.props;

    if (_.isEmpty(heading) && _.isEmpty(preamble) && _.isEmpty(description)) {
      return null;
    }

    let titleClasses = classNames('intro__title', {
      [`intro__title--size${_.capitalize(headingFontSize)}`]: !_.isEmpty(
        headingFontSize
      ),
      [`intro__title--weight${_.capitalize(headingFontWeight)}`]: !_.isEmpty(
        headingFontWeight
      )
    });

    let preambleClasses = classNames('intro__preamble', {
      [`intro__preamble--weight${_.capitalize(
        preambleFontWeight
      )}`]: !_.isEmpty(preambleFontWeight)
    });

    return (
      <div
        className="intro"
        style={{ maxWidth: maxWidth, textAlign: textAlign }}
      >
        {!_.isEmpty(heading) ? (
          <h2
            className={titleClasses}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        ) : null}
        {!_.isEmpty(preamble) ? (
          <p
            className={preambleClasses}
            dangerouslySetInnerHTML={{ __html: preamble }}
          />
        ) : null}
        {!_.isEmpty(description) ? (
          <p
            className="intro__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}
      </div>
    );
  }
}

Intro.displayName = 'Intro';

Intro.defaultProps = {
  maxWidth: 740,
  textAlign: 'center',
  headingFontSize: 'medium',
  headingFontWeight: 'bold'
};
