import React from 'react';
import styled from 'styled-components';

import Text from '~/App/shared/components/Fields/Text';
import { IValidation } from '~/App/shared/components/Validation';
import { usePreferredCustomerContactType } from '~/App/shared/hooks/use-preferred-customer-contact-type';
import { TabbedContactFields } from '../../Forms/components/ContactFields';
import { ManualProps } from '../../Forms/components/ContactFields/components/Manual';
import {
  PaymentValues,
  ContactCustomerValues,
  ProductValues
} from '../../States';

const Wrapper = styled.div`
  :not(:first-child) {
    padding-top: 1rem;
  }
`;

const Input = styled(Text)``;

type Props = {
  validation: IValidation;
  values: PaymentValues & ContactCustomerValues & ProductValues;
  contactField?: boolean;
  taxReductionInfo?: boolean | string;
  manualProps?: ManualProps;
};

export function SwishFields({
  validation,
  values,
  contactField = true,
  taxReductionInfo = '#skatteavdrag-for-gavor',
  manualProps = {}
}: Props) {
  usePreferredCustomerContactType({
    preferred: 'automatic',
    values
  });

  return (
    <div>
      {values.swish.swishType === 'ecommerce' && (
        <Wrapper>
          <Input
            name="tel"
            type="tel"
            value={values.swishPhoneNumber}
            onBlur={validation.showError.bind(undefined, 'swishPhoneNumber')}
            errors={validation.errors['swishPhoneNumber']}
            isValid={validation.isValid('swishPhoneNumber')}
            onChange={values.handlers.handleSwishPhoneNumber}
            isInvalid={validation.isInvalid('swishPhoneNumber')}
            maxLength="10"
            placeholder="07X XXX XX XX"
            autoComplete="mobile tel"
            iconUrl="/images/swish-logos/swish.png"
            instructions="Ange ditt mobilnummer som är kopplat till Swish."
            required
          />
        </Wrapper>
      )}

      {contactField && (
        <Wrapper>
          <TabbedContactFields
            values={values}
            validation={validation}
            taxReductionInfo={taxReductionInfo}
            manual={{
              privateCompany: values.product?.id === 1,
              disableAddress: manualProps.disableAddress,
              disableName: manualProps.disableName
            }}
            automatic
          />
        </Wrapper>
      )}
    </div>
  );
}
