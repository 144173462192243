import {
  useCallback,
  ChangeEvent,
  useState,
  useMemo,
  ChangeEventHandler,
  Dispatch,
  SetStateAction
} from 'react';

import { WufooField } from '../components/Field';

type Value = string | number | boolean;
type Values = Record<string, Value>;

export type WufooState = {
  values: Values;
  handlers: {
    resetValues: (fields: WufooField[]) => void;
    handleChange: (field: WufooField) => ChangeEventHandler;
    handleSet: (field: WufooField) => (value: Value) => void;
    handleSetAcceptIntegrityPolicy: ChangeEventHandler;
    setValues: Dispatch<SetStateAction<Values>>;
  };
};

export function useWufooState() {
  const [values, setValues] = useState<Values>({
    acceptIntegrityPolicy: false
  });

  const handleChange = useCallback(
    (field: WufooField) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setValues((state) => ({
        ...state,
        [field.ID]: value
      }));
    },
    []
  );

  const handleSet = useCallback(
    (field: WufooField) => (value: Value) => {
      setValues((state) => ({
        ...state,
        [field.ID]: value
      }));
    },
    []
  );
  const handleSetAcceptIntegrityPolicy = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      setValues((state) => ({
        ...state,
        acceptIntegrityPolicy: checked
      }));
    },
    []
  );

  const resetValues = useCallback((fields: WufooField[]) => {
    const defaultValues = fields.reduce(
      (prev, current) => ({
        ...prev,
        [current.ID]: ''
      }),
      {
        acceptIntegrityPolicy: false
      }
    );

    setValues(defaultValues);
  }, []);

  return useMemo<WufooState>(
    () => ({
      values,
      handlers: {
        setValues,
        handleSet,
        resetValues,
        handleChange,
        handleSetAcceptIntegrityPolicy
      }
    }),
    [
      handleChange,
      handleSet,
      resetValues,
      handleSetAcceptIntegrityPolicy,
      values
    ]
  );
}
