import React from 'react';
import styled from 'styled-components';

const Feature = styled.li`
  position: relative;
  margin: 0 0 1rem 0;
  text-decoration: ${(props) => (props.enabled ? 'none' : 'line-through')};
  opacity: ${(props) => (props.enabled ? '1' : '0.3')};

  &::before {
    display: block;
    width: 1.625rem;
    height: 1.625rem;
    position: absolute;
    top: 0px;
    left: -2.25rem;
    background: transparent url(${(props) => props.theme.iconAssets.check})
      no-repeat 50% 50%;
    background-size: contain;
    content: '';
  }
`;

export const PackageFeature = (props) => {
  const { enabled = true, children } = props;

  return <Feature enabled={enabled}>{children}</Feature>;
};
