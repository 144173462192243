import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';

//Shared components
import Container from '~/App/shared/components/Container';
import { TaxReductionInfoModal } from './TaxReductionInfo.Modal';

//Helpers
import { link } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

const inlineCss = css`
  margin-top: 1rem;
`;

const fixedCss = css`
  margin-top: -1rem;
  width: calc(100% + 2rem);
  left: -1rem;
  bottom: -1.5rem;
  padding: 1rem 1.5rem 2.5rem;

  ${mq('≥small')`
    margin-top: -2.25rem;
    width: calc(100% + 5rem);
    left: -2.5rem;
    bottom: -2.5rem;
    padding: 1rem 2.5rem 2.5rem;
  `};
`;

type StyledContainerProps = {
  inline?: boolean;
};

const StyledContainer = styled(Container)<StyledContainerProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  ${({ inline }) => (inline ? inlineCss : fixedCss)};
`;

const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.charcoal};
`;

const BoldText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Link = styled.span`
  ${link};
  text-decoration: underline;
  display: block;
  margin-top: 0.25rem;

  ${mq('≥small')`
    display: inline-block;
    margin-left: 0.75ch;
    margin-top: 0;
  `};
`;

type Props = {
  inline?: boolean;
  amount?: number;
  quantity?: number;
  className?: string;
  minAmount?: number;
};

export function TaxReductionInfo({
  inline,
  amount,
  quantity,
  className = '',
  minAmount = 50
}: Props) {
  const showInfo = useMemo(() => {
    if (!amount || !quantity) return false;

    const totalAmount = amount * quantity;

    if (totalAmount >= minAmount && totalAmount < 200) {
      return true;
    }
    return false;
  }, [amount, quantity, minAmount]);

  const [showModal, setShowModal] = useState(false);

  const modal = useMemo(() => {
    if (!showModal) return null;

    return <TaxReductionInfoModal closeModal={() => setShowModal(false)} />;
  }, [showModal]);

  return useMemo(() => {
    if (!showInfo) return null;

    return (
      <StyledContainer
        width="limited"
        paddingLeft="small"
        paddingRight="small"
        paddingTop="small"
        paddingBottom="small"
        className={className}
        inline={inline}
      >
        <StyledParagraph>
          <BoldText>Visste du att</BoldText> om du ger minst 200 kr kan du ha
          rätt till skatteavdrag?
          <Link
            className="taxreduction__modal-link"
            onClick={() => setShowModal(true)}
            children="Så funkar det"
          />
        </StyledParagraph>
        {modal}
      </StyledContainer>
    );
  }, [modal, showInfo, className, inline]);
}
