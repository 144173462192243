import React, { ComponentProps, useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { heading3 } from '~/App/helpers/mixins';
import HtmlContent from '../../../../../HtmlContent';
import { VideoMedia } from './components/VideoMedia';
import { ImageMedia } from './components/ImageMedia';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 1.5rem;
  height: 100%;
`;

const PaddingX = styled.div`
  padding: 0 1rem;
`;

type PaddingTopProps = {
  size?: number;
};

const PaddingTop = styled.div<PaddingTopProps>`
  padding-top: ${({ size = 1 }) => `${size}rem`};
`;

const ImageText = styled.div`
  font-size: 0.8rem;
  padding-top: 0.5rem;
`;

const Heading = styled.div`
  ${heading3}
  margin-bottom: 0;
`;

export type Item = {
  heading: string;
  preamble: string;
  imageText: string;
  videoUrl?: string;
} & ComponentProps<typeof ImageMedia>;

type Props = {
  item: Item;
  index: number;
};

export function Slide({ item }: Props) {
  return useMemo(() => {
    return (
      <Wrapper>
        {!item.videoUrl && item.image && <ImageMedia {...item} />}
        {item.videoUrl && <VideoMedia url={item.videoUrl} />}
        <PaddingX>
          {item.imageText && <ImageText children={item.imageText} />}
          {item.heading && (
            <PaddingTop>
              <Heading children={item.heading} />
            </PaddingTop>
          )}

          {item.preamble && (
            <PaddingTop size={item.heading ? 0.5 : 1}>
              <HtmlContent children={item.preamble} />
            </PaddingTop>
          )}
        </PaddingX>
      </Wrapper>
    );
  }, [item]);
}
