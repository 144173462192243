export const schema = {
  name: {
    length: {
      minimum: 5,
      message: '^Minst 5 bokstäver'
    },
    presence: {
      allowEmpty: false,
      message: '^Ange ett namn'
    }
  },
  senderName: {
    presence: {
      allowEmpty: false,
      message: '^Vem står bakom insamlingen'
    }
  },
  description: {
    length: {
      minimum: 5,
      message: '^Minst 5 bokstäver'
    },
    presence: {
      allowEmpty: false,
      message: '^Ange beskrivning'
    },
    format: {
      pattern: /^(?!.*<[^>]+>).*/s,
      message:
        '^Beskriv din insamling med vanlig text. Html och annan kod fungerar inte.'
    }
  },
  goal: {
    presence: {
      allowEmpty: false,
      message: '^Ange en siffra'
    },
    numericality: {
      message: '^Endast siffror',
      onlyInteger: true,
      greaterThanOrEqualTo: 0
    }
  },
  collectionType: {
    inclusion: {
      within: ['rosa', 'normal', 'memorial', 'memorial_page', 'celebration'],
      message: '^Välj insamlingstyp'
    }
  },
  imageUrl: {
    presence: {
      allowEmpty: false,
      message: '^Fel'
    }
  }
};
