import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { paper } from '~/App/helpers/icons';
import {
  clearfix,
  heading5,
  arrowLink,
  description2
} from '~/App/helpers/mixins';

// Shared components
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const ListItem = styled.div`
  margin-top: 2em;

  &:first-child {
    margin-top: 0;
  }
`;

const Row = styled.div`
  ${clearfix};
  display: block;
`;

const Box = styled.div`
  float: left;
  width: 3em;
  font-size: 0.875em;
  text-align: center;

  ${mq('≥small')`
    width: 3.5em;
  `};
`;

const IconBackground = styled.div`
  padding: 0.65em 0.25em 0.75em;
  line-height: 1.1;
  border-radius: 0.25em;
  background-color: #f4f4f4;
`;

const FileType = styled.div`
  font-size: 0.75em;
  margin-top: 0.75em;
  color: #888;
`;

const PaperIcon = styled.div`
  &:after {
    ${paper};
    font-size: 1.75rem;
  }
`;

const ImageIcon = styled.div`
  &:after {
    ${paper};
    font-size: 1.75rem;
  }
`;

const Content = styled.div`
  float: left;
  width: calc(100% - 3em);
  padding-left: 1em;

  ${mq('≥small')`
    width: calc(100% - 3.5em);
    padding-left: 1.75em;
  `};
`;

const Heading = styled.h4`
  ${heading5};
`;

const Preamble = styled.div`
  ${description2};
  margin: 0.5em 0 0.25em;
`;

const ArrowLink = styled.a`
  ${arrowLink};
`;

export default class FileList extends React.Component {
  render() {
    const {
      width = '',
      files = [],
      verticalSpacing = '',
      backgroundColor = ''
    } = this.props;

    return (
      <BlockBackground backgroundColor={backgroundColor}>
        <Container
          width={width}
          paddingTop={
            verticalSpacing.split('topLarge').length === 2 ? 'large' : ''
          }
          paddingLeft="small"
          paddingRight="small"
          paddingBottom={
            verticalSpacing.split('bottomLarge').length === 2 ? 'large' : ''
          }
        >
          {files.map((file, key) => (
            <ListItem key={key}>
              <Row>
                <Box>
                  <IconBackground>
                    {file.icon === 'pdf' && <PaperIcon />}
                    {file.icon === 'image' && <ImageIcon />}
                    {file.icon === 'document' && <PaperIcon />}
                    {file.icon === 'spreadsheet' && <PaperIcon />}
                  </IconBackground>
                  <FileType>
                    {file.icon === 'pdf' && 'PDF'}
                    {file.icon === 'image' && 'Bild'}
                    {file.icon === 'document' && 'Dokument'}
                    {file.icon === 'spreadsheet' && 'Excel'}
                  </FileType>
                </Box>
                <Content>
                  <Heading>{file.name}</Heading>
                  <Preamble>{file.description}</Preamble>
                  <ArrowLink href={file.url} target="_blank">
                    Ladda ner
                  </ArrowLink>
                </Content>
              </Row>
            </ListItem>
          ))}
        </Container>
      </BlockBackground>
    );
  }
}
