import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { angleDown, angleUp } from '~/App/helpers/icons';
import { GroupedOption, CategoryType } from '../CategoryFilter';

type GroupHeadingProps = {
  showItems: boolean;
};

type Props = {
  data: GroupedOption;
  showCategoryItems: (category: CategoryType) => void;
  openCategories: (CategoryType | undefined)[];
};

const StyledHeading = styled.div<GroupHeadingProps>`
  font-size: 1.375rem;
  line-height: 1.23;
  display: flex;

  &::after {
    ${({ showItems }) => (showItems ? angleUp : angleDown)};
    padding-left: 10px;
    font-size: 1.75rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }
`;

export function GroupHeading({
  data,
  showCategoryItems,
  openCategories
}: Props) {
  const [iconIsOpen, setIconIsOpen] = useState(
    data.category === 'Diagnose' ? true : false
  );

  useEffect(() => {
    if (openCategories.includes(data.category)) {
      setIconIsOpen(true);
    } else {
      setIconIsOpen(false);
    }
  }, [data.category, openCategories]);

  const handleClick = () => {
    setIconIsOpen(!iconIsOpen);
    showCategoryItems(data.category);
  };

  return (
    <>
      <StyledHeading onClick={handleClick} showItems={iconIsOpen}>
        {data.label}
      </StyledHeading>
    </>
  );
}
