import { fetch, purchases } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';

import { LoadDataProps } from '~/types/routes';

type Query = {
  purchaseToken: string;
};

export const loadData = async ({
  params,
  query
}: LoadDataProps<'slug', Query>) => {
  const slug = params?.slug;
  const purchaseToken = query?.purchaseToken;

  const productTask = fetch({ url: contentApi(`/v1/products/${slug}`) });
  const purchaseTask = purchases.show({ slug: purchaseToken });

  const [product, purchase] = await Promise.all([productTask, purchaseTask]);

  return {
    product: product.data.product,
    purchase: purchase.data
  };
};
