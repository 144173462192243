import { useCallback, useEffect, useState } from 'react';

export function useMatchMedia(query: string, defaultValue: boolean | null = null): boolean | null {
	const [ matches, setMatches ] = useState<null | boolean>(defaultValue);

	const handleChange = useCallback((ev: MediaQueryListEvent) => {
		setMatches(ev.matches);
	}, []);

	useEffect(
		() => {
			if (typeof window === 'undefined' || query === '') {
				return;
			}

			const media = matchMedia(query);

			setMatches(media.matches);

			if (media.addListener) {
				media.addListener(handleChange); // for IE 11
			} else if (media.addEventListener) {
				media.addEventListener('change', handleChange);
			}

			return () => {
				if (media.removeListener) {
					media.removeListener(handleChange); // for IE 11
				} else if (media.removeEventListener) {
					media.removeEventListener('change', handleChange);
				}
			};
		},
		[ handleChange, query ]
	);

	return matches;
}
