import React from 'react';
import styled from 'styled-components';

// Helpers
import { camera } from '~/App/helpers/icons';

const Label = styled.label`
  line-height: 3rem;
  text-align: center;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: 3%;
  bottom: 7%;
  border-radius: 50%;
  background: #fff;
  border: solid 1px ${({ theme }) => theme.colors.alto};

  &::before {
    ${camera};
    font-size: 1.3125rem;
    text-align: center;
    display: inline-block;
    line-height: inherit;
    color: ${({ theme }) => theme.themeColors.primary};
    height: 100%;
  }
`;

type Props = {
  className?: string
  htmlFor: string
}

export default function ImageUploadButton({ className, htmlFor }: Props) {
  return <Label className={className} htmlFor={htmlFor} />;
}
