import React, { useMemo, ReactNode } from 'react';
import { useLocation } from 'react-router';

// Helpers
import { siteUrl } from '~/App/helpers/env';

type Props = {
  to?: string;
  text?: string;
  className?: string;
  children: ReactNode;
};

export default function FacebookShareUrl({
  to,
  text,
  className,
  children
}: Props) {
  const location = useLocation();

  const url = useMemo(() => {
    const encodedUrl = encodeURIComponent(to || siteUrl(location.pathname));
    const encodedText = text ? encodeURIComponent(text) : '';

    return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}${
      text ? `&quote=${encodedText}` : ''
    }`;
  }, [location.pathname, text, to]);

  return (
    <a
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      className={className}
      children={children}
    />
  );
}
