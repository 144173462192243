import React, {
  useMemo,
  ReactNode,
  useState,
  useCallback,
  useEffect
} from 'react';
import { shoppingCart } from '~/App/helpers/http';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import {
  ShoppingCartContext,
  ShoppingCartContextValue
} from './ShoppingCartContext';
import { get, set } from '~/App/helpers/cookie';
import { ShoppingCartState } from '~/types/IPreloadedState';
import { pushGTMEvent } from '~/App/helpers/gtm-helper';

type Props = {
  state: ShoppingCartState;
  children: ReactNode;
};

export function ShoppingCartProvider({ children, state }: Props) {
  const [isOpen, setOpen] = useState(false);
  const [isPending, setPending] = useState(false);
  const [items, setItems] = useState(state.items);
  const [cartId, setCartId] = useState(get('cartId'));
  const [shippingPrice, setShippingPrice] = useState(state.shippingPrice);

  const totalPrice = useMemo(
    () =>
      items.reduce(
        (val, item: ICommerceOrderLine) => val + item.price * item.quantity,
        0
      ),
    [items]
  );

  const getCart = useCallback(async () => {
    if (cartId) {
      return cartId;
    }

    setPending(true);
    const cart = await shoppingCart.create();

    set('cartId', cart.id);
    setCartId(cart.id);
    setPending(false);
    return cart.id;
  }, [cartId]);

  const shouldModifyCurrent = useCallback(
    (orderLine: ICommerceOrderLine): [boolean, ICommerceOrderLine | null] => {
      if (orderLine.variant?.product?.type !== 'Physical') {
        return [false, null];
      }

      const exists = items.find((x) => x.variant?.id === orderLine.variant?.id);

      if (!exists) {
        return [false, null];
      }

      return [true, exists];
    },
    [items]
  );

  const refresh = useCallback(
    async (cartId?: string) => {
      const cart = await shoppingCart.get(cartId ?? (await getCart()));

      setItems(cart.orderLines || []);
      setShippingPrice(cart.shippingPrice ?? 0);
    },
    [getCart]
  );

  const remove = useCallback(
    async (orderLine: ICommerceOrderLine) => {
      if (!orderLine.id) return;

      await shoppingCart.remove(orderLine.id);
      await refresh(await getCart());
    },
    [getCart, refresh]
  );

  const update = useCallback(
    async (orderLine: ICommerceOrderLine) => {
      await shoppingCart.update(orderLine);
      await refresh(await getCart());
    },
    [getCart, refresh]
  );

  const add = useCallback(
    async (orderLine: ICommerceOrderLine) => {
      const [shouldModify, item] = shouldModifyCurrent(orderLine);

      if (shouldModify && item) {
        return await update({
          ...item,
          quantity: item.quantity + orderLine.quantity
        });
      }

      const cart = await getCart();

      await shoppingCart.add(cart, orderLine);
      await refresh(cart);
    },
    [getCart, refresh, shouldModifyCurrent, update]
  );

  const open = useCallback(() => {
    pushGTMEvent({
      category: 'Varukorg',
      action: 'click: open',
      label: items.map((name) => name.variant?.name).join(' | '),
      value: totalPrice
    });

    setOpen(true);
  }, [items, totalPrice]);

  const close = useCallback(() => {
    pushGTMEvent({
      category: 'Varukorg',
      action: 'click: close',
      label: items.map((name) => name.variant?.name).join(' | '),
      value: totalPrice
    });

    setOpen(false);
  }, [items, totalPrice]);

  const finish = useCallback(() => {
    cartId ? set('cartId', '', -1000) : null;
    setItems([]);
    setCartId(undefined);
  }, [cartId]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (!cartId) {
      return;
    }

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const value = useMemo<ShoppingCartContextValue>(
    () => ({
      items,
      isOpen,
      isEmpty: items.length === 0,
      isPending,
      totalPrice: totalPrice + shippingPrice,
      shippingPrice,

      add,
      remove,
      update,
      refresh,

      open,
      close,
      finish
    }),
    [
      isOpen,
      isPending,
      items,
      totalPrice,
      shippingPrice,
      add,
      close,
      open,
      finish,
      remove,
      update,
      refresh
    ]
  );

  return <ShoppingCartContext.Provider value={value} children={children} />;
}
