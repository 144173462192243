import { FormEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';

import { collectionActivities } from '~/App/helpers/http';
import mediaHelper from '~/App/helpers/media-helper';
import { IValidation } from '~/App/shared/components/Validation';
import { CreateMemoryStateValues } from './useCreateMemoryState';
import { useSubmit } from '~/App/shared/components/Donation/components/Submits/Submit';

type Args = {
  values: CreateMemoryStateValues;
  validation: IValidation;
  collection: {
    id: number;
    slug: string;
  };
};

export function useCreateMemorySubmit({
  values,
  validation,
  collection
}: Args) {
  const [isSending, setSending] = useState(false);

  const navigate = useNavigate();

  const submit = useSubmit({
    values,
    validation
  });

  const hasPurchase = useMemo(() => !!values.productOptions.memory.period, [
    values.productOptions.memory.period
  ]);

  const performUpload = useCallback(async (url) => {
    if (!url) {
      return null;
    }

    if (mediaHelper.isCloudinaryUrl(url)) {
      return url;
    }

    const fetchResponse = await fetch(url);
    const blob = await fetchResponse.blob();
    const uploadResponse = await uploadImage(blob, {
      timeout: 30000,
      folder: 'collection-pictures'
    });

    return uploadResponse?.data?.secure_url;
  }, []);

  const handleSubmit = useCallback(
    async (event?: FormEvent<HTMLFormElement>) => {
      event?.preventDefault();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      setSending(true);

      const imageUrl = await performUpload(values.productOptions.memory.image);
      values.productOptions.memory.image = imageUrl;

      await collectionActivities.createMemory(
        collection.id,
        values.productOptions.memory
      );

      setSending(false);

      navigate(`/stod-oss/minnessidor/${collection.slug}`);
    },
    [
      validation,
      performUpload,
      values.productOptions.memory,
      collection.id,
      collection.slug,
      navigate
    ]
  );

  if (hasPurchase) {
    return submit;
  }

  return {
    ...submit,
    isSending,
    handleSubmit
  };
}
