import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { MemorialPages } from './MemorialPages';
import { loadData } from './MemorialPages.loadData';

const View = (props: any) => {
  const pageContent = usePageContent();
  const children = useMemo(
    () => (pageContent ? <MemorialPages {...pageContent} {...props} /> : null),
    [pageContent, props]
  );

  return children;
};

View.loadData = loadData;

export default View;
