import React, { ReactNode } from 'react';
import styled from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';

// shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import { AlertMessageContainer } from '~/App/shared/components/Elements';

// local components
import { Container } from '../Styled';
import { Logo } from '../Logo';

const FlexWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 470px;

  ${mq('≥mediumLarge')`
      margin-top: 0;
      height: auto;
  `};
`;

const Button = styled(PrimaryButton)`
  margin: 0.75rem auto;
`;

type Props = {
  children: ReactNode
  tryAgain?: () => void
}

export function Error({ children, tryAgain }: Props) {
  return (
    <Container>
      <FlexWrapper>
        <Logo marginBottom="1rem" />
        <AlertMessageContainer messageStyle="error" children={children} />
      </FlexWrapper>
      {tryAgain && (
        <Button
          buttonStyle="outlinePrimary"
          onClick={tryAgain}
          children="Försök igen" />
      )}
    </Container>
  );
}