import { CancelToken } from 'axios';
import { mwAxios } from '.';
import { get } from '~/App/helpers/cookie';
import { logSimpleMessage } from '../logger';
import { IPurchaseShow } from '~/types/IPurchaseShow';
import { getListPrice } from '../purchase-helper';

type PurchasesShowArguments = {
  slug: string;
  cancelToken?: CancelToken;
  params?: Record<string, unknown>;
};

type PurchasesShowSocialArguments = {
  publicToken: string;
};

type PurchasesSocialArguments = {
  publicToken: string;
  data: Record<string, unknown>;
};

type PurchasesCreateArguments = {
  data: any;
};

export const purchases = {
  show: ({ slug, params }: PurchasesShowArguments) => {
    logSimpleMessage(`Fetching purchase ${slug}`);

    return mwAxios.get<IPurchaseShow>(`/api/v2/purchases/${slug}`, {
      params
    });
  },
  showSocial: ({ publicToken }: PurchasesShowSocialArguments) => {
    return mwAxios.get(`/api/v2/purchases/${publicToken}/social`);
  },
  social: ({ publicToken, data }: PurchasesSocialArguments) =>
    mwAxios({
      url: `/api/v2/purchases/${publicToken}/social`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        ...data
      }
    }),
  create: ({ data }: PurchasesCreateArguments) => {
    const price = getListPrice(data.productOptions);
    const clientId = data?.clientOptions?.clientId;
    const productId = data?.product?.id;

    logSimpleMessage(
      `Creating purchase (${productId}: ${price} kr) from client id ${clientId}`
    );

    return mwAxios({
      url: `/api/v2/purchases`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        purchase: {
          ...data
        }
      }
    });
  }
};
