import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ICollection } from '~/types/ICollection';
import { Spinner } from '~/App/shared/components/Elements';
import { collections } from '~/App/helpers/http';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { useLogin } from '~/App/shared/hooks/use-login';
import { useQuery } from '~/App/shared/hooks/use-query';
import { mq } from '~/lib/mq';

import { BankId } from '../../../BankId';
import { JoinCollectionApply } from './JoinCollectionApply';
import { ViewWrapper } from '../../../ViewWrapper';

const Text = styled.div`
  margin-bottom: 0.5rem;
`;

const Bold = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 1rem;
`;

const Center = styled.div`
  text-align: center;

  ${mq('≥mediumLarge')`
    text-align: left;
  `}
`;

const AbsoluteCenter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  max-width: 380px;
  margin: auto;
`;

type Query = {
  slug: string;
};

export function JoinCollection() {
  const login = useLogin();
  const isAuthenticated = useAuthenticationContext((x) => x.isAuthenticated);
  const [collection, setCollection] = useState<ICollection | undefined>();

  const { slug } = useQuery<Query>();

  useEffect(() => {
    if (collection && collection.slug === slug) return;
    if (!slug) return;

    collections
      .show({ slug })
      .then((res: any) => setCollection(res.data))
      .catch(console.log);
  }, [collection, slug]);

  const children = useMemo(() => {
    if (!collection) {
      return (
        <AbsoluteCenter>
          <Spinner />
        </AbsoluteCenter>
      );
    }

    if (isAuthenticated) {
      return <JoinCollectionApply collection={collection} />;
    }

    return (
      <BankId beginAuthentication={login.handlers.handleLogin}>
        <Center>
          <Bold
            children={`Bli administratör för insamlingen "${collection.name}"`}
          />
          <Text children={`Bekräfta din identitet med BankID.`} />
        </Center>
      </BankId>
    );
  }, [collection, isAuthenticated, login.handlers.handleLogin]);

  return (
    <ViewWrapper hero={collection} footer={!!collection} children={children} />
  );
}
