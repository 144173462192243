import { useContext } from 'react';
import { AppContext, AppContextValue } from './AppContext';

type Selector<T> = (values: AppContextValue) => T;

export function useAppContext(): AppContextValue;
export function useAppContext<T>(selector: Selector<T>): T;
export function useAppContext<T = AppContextValue>(selector?: Selector<T>): T {
  const values = useContext(AppContext);

  // @ts-expect-error
  return selector ? selector(values) : values;
}
