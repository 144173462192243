import React from 'react';

class EditorialMetaItem extends React.Component {
  render() {
    if (!this.props.title) {
      return null;
    }

    return (
      <div className="editorialMetaItem">
        {this.props.label ? (
          <div className="editorialMetaItem__label">{this.props.label}</div>
        ) : null}
        {this.props.title ? (
          <div className="editorialMetaItem__title">{this.props.title}</div>
        ) : null}
        {this.props.byline ? (
          <div className="editorialMetaItem__byline">{this.props.byline}</div>
        ) : null}
      </div>
    );
  }
}

EditorialMetaItem.displayName = 'EditorialMetaItem';

EditorialMetaItem.defaultProps = {
  label: '',
  title: '',
  byline: ''
};

export default EditorialMetaItem;
