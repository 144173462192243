import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { users } from '~/App/helpers/http';

import { IUser } from '~/types/IUser';
import {
  UpdateResult,
  AuthenticationContext,
  UserState,
  UserStatus
} from './AuthenticationContext';

type Props = {
  children: ReactNode;
  value: {
    state: UserState;
    status: UserStatus;
    user: IUser | null;
    errors: [];
  };
};

export function AuthenticationProvider({ children, value }: Props) {
  const [user, setUser] = useState<IUser | null>(value.user);
  const [state, setState] = useState<UserState>(value.state);
  const [status, setStatus] = useState<UserStatus>(value.status);
  const [errors, setErrors] = useState<string[]>(value.errors);

  const update = useCallback(
    async (user: IUser | Partial<IUser>): Promise<UpdateResult> => {
      const { data, status } = await users.edit({
        data: { user },
        validateStatus: () => true
      });

      if (status === 422) {
        return {
          success: false,
          errors: data.validations
        };
      }

      if (status === 200) {
        setUser(data);

        return {
          success: true,
          user: data
        };
      }

      return {
        success: false,
        errors: {}
      };
    },
    []
  );

  const refresh = useCallback(
    async () => setUser(state === 'authenticated' ? await users.show() : null),
    [state]
  );

  return useMemo(
    () => (
      <AuthenticationContext.Provider
        children={children}
        value={{
          user,
          state,
          status,
          errors,

          isIdle: status === 'idle',
          isAuthenticated: state === 'authenticated',
          isAnonymous: state === 'anonymous',

          update,
          refresh,
          setUser,
          setState,
          setStatus,
          setErrors
        }}
      />
    ),
    [children, user, state, status, errors, update, refresh]
  );
}
