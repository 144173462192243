import React, { useMemo } from 'react';
import styled from 'styled-components';

// Consts
import { buttonStyles } from '~/App/config/buttonStyles';

// Import shared components
import { PrimaryButton } from '~/App/shared/components/Elements';

const Wrapper = styled.div`
  text-align: center;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 1rem;
`;

export function FormButton({
  handleGoToPart,
  toPart,
  children,
  stepName,
  buttonStyle = buttonStyles.success,
  ...rest
}) {
  return useMemo(
    () => (
      <Wrapper>
        <StyledButton
          buttonType="button"
          buttonStyle={buttonStyle}
          onClick={(event) => {
            event.preventDefault();
            handleGoToPart(toPart, stepName, children);
          }}
          children={children}
          {...rest}
        />
      </Wrapper>
    ),
    [buttonStyle, children, handleGoToPart, rest, toPart, stepName]
  );
}
