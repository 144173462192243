import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Shared components
import { Wrapper } from '~/App/shared/components/ThanksPage';

// Helpers
import { heading2, preamble, arrowLink } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const StyledWrapper = styled(Wrapper)`
  margin-top: 1.5rem;
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  max-width: 35.5rem;
  margin: 0 auto;
  padding: 3rem 0;
  text-align: center;
`;

const ArrowLink = styled(Link)`
  ${arrowLink}
  font-size: 1rem;
  display: inline-block;
  margin-top: 1rem;

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

export function VartGarPengarna() {
  return (
    <StyledWrapper>
      <Content>
        <Heading1>Hit går dina pengar</Heading1>
        <Preamble>
          Cancerfonden finansierar den främsta cancerforskningen i Sverige.
          Pengarna används inom många olika typer av forskning och för en mängd
          olika cancerformer.
        </Preamble>
        <ArrowLink to="/forskning">Följ forskningens framsteg</ArrowLink>
      </Content>
    </StyledWrapper>
  );
}
