import { Children, ReactNode, useMemo } from 'react';

import { useExperiment } from './use-experiment';

type Props = {
  experimentId: string
  weights?: number[]
  children: ReactNode
}

export function Experiment({
  experimentId,
  weights,
  children
}: Props) {

  const childrenArray = useMemo(() => Children.toArray(children), [children]);
  const childrenLength = useMemo(() => childrenArray.length, [childrenArray.length]);

  const variantId = useExperiment({
    experimentId,
    numberOfVariants: childrenLength,
    weights: weights
  })

  return useMemo(() => childrenArray[variantId], [childrenArray, variantId])
}