import { useState, useCallback } from 'react';

import { collectionActivities } from '~/App/helpers/http';
import { IGift } from '~/types/ICollection';

type Status = 'inital' | 'fetching' | 'idle' | 'error';

export type UseGifts = {
  fetch: () => Promise<void>;
  items: IGift[];
  status: Status;
  total: number;
  page: number;
};

export function useGifts(slug: string): UseGifts {
  const [items, setItems] = useState<IGift[]>([]);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [status, setStatus] = useState<Status>('inital');

  const fetch = useCallback(async () => {
    setStatus('fetching');

    if (status === 'fetching') return;

    try {
      const { data, headers = {} } = await collectionActivities.show({
        slug,
        params: {
          type: 'gift',
          page: page + 1,
          per_page: 7
        }
      });

      setItems((items) => [...items, ...data.activities]);
      setPage((page) => page + 1);
      setTotal(parseInt(headers.total || headers.Total, 10));
      setStatus('idle');
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  }, [slug, status, page]);

  return {
    fetch,
    items,
    status,
    total,
    page
  };
}
