import React, { ComponentProps, useCallback, useMemo } from 'react';
import { find } from 'lodash';

import { IValidation } from '~/App/shared/components/Validation';
import { IPaymentMethod } from '~/types/IPaymentMethod';

import { useFetchBankAccount } from '../FetchBankAccount';

import { groupPaymentMethods, isPaymentMethodGroup } from './helpers';

import { PaymentMethodGroup } from './components/PaymentMethodGroup';
import { PaymentMethod } from './components/PaymentMethod';

import Selector from '../Selector';

type Props = {
  values: ComponentProps<typeof PaymentMethod>['values'];
  validation: IValidation;
  formContent: {
    availablePaymentMethods: IPaymentMethod[];
    taxReductionInfoLink?: string;
  };
};

export function PaymentMethodSelector({
  formContent,
  values,
  validation
}: Props) {
  const fetchBankAccount = useFetchBankAccount({ values });

  const groupedPaymentMethods = useMemo(
    () => groupPaymentMethods(formContent.availablePaymentMethods),
    [formContent.availablePaymentMethods]
  );

  const paymentMethodOrGroupFor = useCallback(
    (id?: number) => {
      if (!id) return null;

      for (const item of groupedPaymentMethods) {
        if (item.id === id) {
          return item;
        }

        if (!isPaymentMethodGroup(item)) {
          continue;
        }

        for (const nested of item.paymentMethods) {
          if (nested.id === id) {
            return item;
          }
        }
      }

      return null;
    },
    [groupedPaymentMethods]
  );

  const paymentMethodOrGroupToRender = useMemo(() => {
    return paymentMethodOrGroupFor(values.paymentMethod?.id);
  }, [paymentMethodOrGroupFor, values.paymentMethod?.id]);

  const handlePaymentMethodChange = useCallback(
    (methodId) => {
      const paymentMethod = find(groupedPaymentMethods, { id: methodId });

      if (paymentMethod) {
        values.handlers.handlePaymentMethod(paymentMethod);
      }
    },
    [groupedPaymentMethods, values.handlers]
  );

  const selectedPaymentMethodGroupId = useMemo(() => {
    return paymentMethodOrGroupFor(values.paymentMethod?.id)?.id || 0;
  }, [paymentMethodOrGroupFor, values.paymentMethod?.id]);

  const children = useMemo(() => {
    if (!paymentMethodOrGroupToRender) {
      return null;
    }

    if (isPaymentMethodGroup(paymentMethodOrGroupToRender)) {
      return (
        <PaymentMethodGroup
          group={paymentMethodOrGroupToRender}
          values={values}
          validation={validation}
          formContent={formContent}
          fetchBankAccount={fetchBankAccount}
        />
      );
    }

    return (
      <PaymentMethod
        method={paymentMethodOrGroupToRender}
        values={values}
        validation={validation}
        formContent={formContent}
        fetchBankAccount={fetchBankAccount}
      />
    );
  }, [
    fetchBankAccount,
    formContent,
    paymentMethodOrGroupToRender,
    validation,
    values
  ]);

  const selector = useMemo(() => {
    if (groupedPaymentMethods.length < 2) {
      return null;
    }

    return (
      <Selector
        value={selectedPaymentMethodGroupId}
        setValue={handlePaymentMethodChange}
        options={groupedPaymentMethods.map((method) => ({
          value: method.id,
          label: method.name
        }))}
      />
    );
  }, [
    groupedPaymentMethods,
    handlePaymentMethodChange,
    selectedPaymentMethodGroupId
  ]);

  return (
    <>
      {selector}
      {children}
    </>
  );
}
