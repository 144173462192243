import React, { useMemo } from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';

import {
  RightColumn,
  ColumnCard
} from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import { ProductCertificateSummary } from './ProductCertificateSummary';

import { ICmsProduct } from '~/types/ICmsProduct';
import { StateValues } from '~/App/shared/components/Donation/components/States';

const Wrapper = styled(RightColumn)`
  ${mq('<medium')`
    display: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 1rem;
  `};
`;

const Card = styled(ColumnCard)`
  ${mq('<medium')`
     padding: 1rem;
     position: relative;
     max-height: 13rem;
     overflow: hidden;
  `};
`;

type Props = {
  values: StateValues;
  product: ICmsProduct;
  togglePreviewModal: () => void;
};

export default function FormRightColumn({
  values,
  product,
  togglePreviewModal
}: Props) {
  const previewFields = useMemo(
    () => ({
      imageUrl: product.productImage?.secure_url,
      heading: product.certificate?.heading,
      disclaimer: product.certificate?.disclaimer,
      description: product.certificate?.description,
      imageAlt: product.certificate?.imageAlt
    }),
    [
      product.certificate?.description,
      product.certificate?.disclaimer,
      product.certificate?.heading,
      product.certificate?.imageAlt,
      product.productImage?.secure_url
    ]
  );

  return (
    <Wrapper>
      <Card>
        <ProductCertificateSummary
          previewFields={previewFields}
          priceType={product.priceType}
          product={values.productOptions.product}
          openPreviewModal={togglePreviewModal}
        ></ProductCertificateSummary>
      </Card>
    </Wrapper>
  );
}
