import React, { FormEvent, ReactNode, useCallback, useMemo } from 'react';

// components
import { Qr } from './BankId.Qr';
import { Failed } from './BankId.Failed';
import { Spinner } from './BankId.Spinner';
import { SameDevice } from './BankId.SameDevice';

import useLoginModal from '~/App/shared/hooks/use-login-modal';
import {
  useAuthenticationContext,
  UserStatus
} from '~/App/contexts/Authentication';
import { HandleLoginOptions, HandleLoginResponse } from '~/App/contexts/Login';
import styled, { css } from 'styled-components';
import mq from '~/App/helpers/mq';
import { primaryButton, PrimaryButtonProps } from '~/App/helpers/mixins';
import mediaHelper from '~/App/helpers/media-helper';
import { buttonStyles } from '~/App/config/buttonStyles';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';

const status: UserStatus[] = [
  'polling',
  'aborting',
  'signing',
  'waitingForBankID',
  'waitingForAppStart',
  'loading'
];

type Props = {
  beginAuthentication: (
    options: HandleLoginOptions
  ) => Promise<void | HandleLoginResponse>;
  children?: ReactNode;
};

const BankIDButton = styled.button<PrimaryButtonProps>`
  ${primaryButton};
  display: block;
  margin: 1rem auto 0;

  ${mq<PrimaryButtonProps>('≥mediumLarge')`
    display: inline-block;
    margin-bottom: 2.5rem;
    background-color: transparent;
    color: ${({ theme }) => theme.themeColors.primary};
    padding-left: 3.2rem;
    background-position: 1.2rem 0.7rem;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url(${mediaHelper.getUrl(
      'https://res.cloudinary.com/cancerfonden/image/upload/v1591085364/assets/login/bankid_logo.svg'
    )});

    &:hover {
      color: ${({ theme }) => theme.themeColors.primary};
      background-color: transparent;
    }

    ${({ theme, isDisabled }) =>
      isDisabled &&
      css`
        color: ${theme.colors.silverChalice};
        border-color: ${theme.colors.silverChalice};
        pointer-events: none;
      `};
  `};
`;

const Button = styled.button`
  ${primaryButton};
  margin: 1rem auto 0;
  display: block;

  ${mq('≥mediumLarge')`
    display: inline-block;
  `};
`;

export function BankId({ beginAuthentication, children }: Props) {
  const loginModal = useLoginModal();
  const authentication = useAuthenticationContext();

  const failed = useMemo(
    () => Object.keys(authentication.errors).length !== 0,
    [authentication.errors]
  );

  const toggleQrMode = useCallback(() => {
    loginModal.handlers.toggleQrMode(true);
  }, [loginModal.handlers]);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      //Formevent is due to Cypress test
      event.preventDefault();
      beginAuthentication({ testLogin: true });
    },
    [beginAuthentication]
  );

  const formElement = useMemo(() => {
    if (loginModal.mobileInitView && authentication.status === 'idle') {
      return <SameDevice beginAuthentication={beginAuthentication} />;
    }

    if (failed) {
      return <Failed errors={authentication.errors} />;
    }

    if (loginModal.qrMode) {
      return <Qr beginAuthentication={beginAuthentication} />;
    }

    if (authentication.status === 'idle') {
      return (
        <>
          <BankIDButton
            buttonStyle={buttonStyles.primary}
            children="Logga in med BankID"
            onClick={toggleQrMode}
          />
          {featureIsEnabled('ENABLE_TEST_BANKID_USER') && (
            <form onSubmit={(event) => handleSubmit(event)}>
              <Button>Snabblogin med Testanvändare</Button>
            </form>
          )}
        </>
      );
    }

    return null;
  }, [
    beginAuthentication,
    failed,
    loginModal.mobileInitView,
    loginModal.qrMode,
    authentication.errors,
    authentication.status,
    toggleQrMode,
    handleSubmit
  ]);

  const spinner = useMemo(() => {
    if (loginModal.qrMode || status.indexOf(authentication.status) === -1) {
      return null;
    }

    return <Spinner />;
  }, [loginModal.qrMode, authentication.status]);

  const showSubHeader = useMemo(() => {
    if (!children) return false;
    if (failed) return false;

    return true;
  }, [children, failed]);

  return (
    <>
      {showSubHeader ? children : null}
      {formElement}
      {spinner}
    </>
  );
}
