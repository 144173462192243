import styled from 'styled-components';
import React, { useCallback } from 'react';

// Shared components
import { Divider } from '~/App/shared/components/Blocks';
import { RightColumnText } from '~/App/shared/components/Donation/components/Forms/components/RightColumnText';
import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';

import AmountSelector from './../components/Forms/components/AmountSelector';
import { DurationSelector } from './../components/Forms/components/DurationSelector';
import { TaxReductionInfo } from '../components/Forms/components/TaxReductionInfo';
import { paymentMethods } from '~/App/config/paymentMethods';
import { pushGTMFormEvent } from '~/App/helpers/gtm-helper';

import {
  StepWrapper,
  HiddenAboveMedium
} from './../components/Forms/components/FormLayout';
import { NewFormHeading } from './../components/Forms/components/FormTypography';

const InlineTaxReductionInfo = styled(TaxReductionInfo)`
  width: 100%;
  margin-top: 1rem;
  left: 0;
  bottom: 0;
`;

export default function MonthlyDonationFormPartOne({
  formContent,
  values,
  validation,
  inline = false,
  partName
}) {
  const pushGtmFormInteraction = useCallback(
    (fieldName, label) => {
      pushGTMFormEvent('formInteraction', {
        formName: values.gtm?.formName,
        fieldName,
        step: values.partOptions.currentPart,
        stepName: partName,
        paymentMethod:
          values.paymentMethod.id === paymentMethods.klarnaPayments
            ? values.paymentMethod.slug
            : values.paymentMethod.name,
        fieldValue: label
      });
    },
    [
      values.gtm?.formName,
      values.partOptions.currentPart,
      partName,
      values.paymentMethod
    ]
  );
  return (
    <>
      <AmountSelector
        amounts={formContent.prices}
        selectedAmount={values.productOptions.product.price}
        customAmount={values.productOptions.product.customPrice}
        setAmount={values.handlers.setProductPrice}
        setCustomAmount={values.handlers.setProductCustomPrice}
        resetAmount={values.handlers.resetProductPrice}
        pushGtmFormInteraction={pushGtmFormInteraction}
        validationKey={'productOptions.product.price'}
        validation={validation}
        tinyLayout="50"
        smallLayout="50"
        largeLayout="auto"
      />
      <If show={formContent?.durations}>
        <StepWrapper>
          <NewFormHeading>Välj tidsperiod</NewFormHeading>
          <DurationSelector values={values} formContent={formContent} />
        </StepWrapper>
      </If>

      <HiddenAboveMedium>
        <If show={formContent?.infoTexts?.[1]}>
          <>
            <Divider
              width="narrow"
              paddingLeft="none"
              paddingRight="none"
              alternativeColor={true}
              height="2px"
            />
            <RightColumnText
              icon={formContent?.infoTexts?.[1]?.icon}
              iconColor={formContent?.infoTexts?.[1]?.iconColor}
            >
              {formContent?.infoTexts?.[1]?.text}
            </RightColumnText>
          </>
        </If>
      </HiddenAboveMedium>
      {inline ? (
        <InlineTaxReductionInfo
          amount={getListPrice(values.productOptions)}
          quantity={getQuantity(values.productOptions)}
        />
      ) : (
        <TaxReductionInfo
          amount={getListPrice(values.productOptions)}
          quantity={getQuantity(values.productOptions)}
        />
      )}
    </>
  );
}

const If = ({ show, children }) => (show ? children : null);
