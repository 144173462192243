import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { mq } from '~/lib/mq';

import { ShoppingCart } from '../ShoppingCart';
import { AccountButton } from './components/AccountButton';
import { AuthenticationButton } from '../AuthenticationButton';
import { DonationButton } from './components/DonationButton';
import { Search } from './components/Search';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

const List = styled.ul`
  display: flex;
  margin-left: auto;
  flex-direction: row;
`;

const Item = styled.li`
  color: ${({ theme }) => theme.themeColors.primary};
  position: relative;

  &:not(:first-child) {
    margin-left: 2rem;
  }

  ${mq('<mediumLarge')`
    display: none;
  `};
`;

const ButtonItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  transition: opacity 0.35s ease;
  margin-left: 2rem;
  margin: 0.375rem 0.75rem;

  ${mq('≥mediumLarge')`
    align-items: flex-start;
    margin: 0 0rem 0 2rem;
    padding-top: 1.5rem;
    opacity: 1;
    pointer-events: auto;
  `};
`;

const FillItem = styled.li`
  width: 190px;
`;

type Props = {
  visible: boolean;
};

export function SecondaryNavigation({ visible }: Props) {
  const location = useLocation();
  const { isAnonymous, isAuthenticated } = useAuthenticationContext();
  const isCollectionPage =
    location.pathname.includes('/insamlingar/') &&
    !location.pathname.includes('/starta-insamling');
  const isDonationPage = location.pathname.includes('/stod-oss/');

  return useMemo(
    () => (
      <List>
        <Item>
          <ShoppingCart />
        </Item>
        <Item>
          <Search />
        </Item>
        <Item>
          <AuthenticationButton display="text" />
        </Item>

        {isAuthenticated && !visible && (
          <ButtonItem>
            <AccountButton />
          </ButtonItem>
        )}
        {!isCollectionPage && !isDonationPage && isAnonymous && !visible ? (
          <ButtonItem>
            <DonationButton />
          </ButtonItem>
        ) : (
          <FillItem />
        )}
      </List>
    ),
    [isAnonymous, isAuthenticated, isCollectionPage, isDonationPage, visible]
  );
}
