import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { Screen } from './components/Screen';
import { WelcomeScreen } from './components/WelcomeScreen';
import { Footer } from '~/App/components/Footer';
import { useSelfTest } from './context/useSelfTest';

export function SelfTest() {
  const { isStarted, currentSection, currentScreen } = useSelfTest();

  return useMemo(() => {
    if (currentSection && currentScreen && isStarted) {
      return <Screen screen={currentScreen} section={currentSection} />;
    }

    return (
      <>
        <Helmet>
          <title children="Kolla risken - testa dina levnadsvanor" />
          <meta
            name="description"
            content="Ett test för dig som vill se hur hälsosamma levnadsvanor kan minska risken för cancer."
          />
          <meta
            property="og:title"
            content="Kolla risken - testa dina levnadsvanor - Cancerfonden"
          />
          <meta
            property="og:description"
            content="Ett test för dig som vill se hur hälsosamma levnadsvanor kan minska risken för cancer."
          />

          <meta
            property="og:image"
            content="https://res.cloudinary.com/cancerfonden/image/upload/v1645017968/Sj%C3%A4lvtest/dela-kolla-risken.jpg"
          />
        </Helmet>
        <WelcomeScreen />
        <Footer />
      </>
    );
  }, [currentScreen, currentSection, isStarted]);
}
