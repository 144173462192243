export const paymentMethods = {
  internetBank: 1,
  creditCard: 2,
  invoice: 3,
  autoGiroSlip: 4,
  paymentSlip: 5,
  test: 6,
  sergelSMS: 7,
  swish: 8,
  bankID: 9,
  autoGiroBankId: 10,
  klarna: 11,
  offsiteSwish: 12,
  klarnaPayments: 13
};
