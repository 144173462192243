import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';

import { IList, IScreenWithFeedback } from '../../../../SelfTest/types';

const Content = styled.div``;

const List = styled.div`
  margin-top: 1rem;
  font-size: 1rem;

  ${mq('≥small')`
    font-size: 1.25rem;
  `};
`;

const Bold = styled.p`
  font-weight: bold;
`;

const Ul = styled.ul`
  margin-left: 1.5rem;
`;

const Li = styled.li`
  font-size: 1rem;
  list-style-type: disc;

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

type Props = {
  screen: IScreenWithFeedback;
};

export function Feedback({ screen }: Props) {
  const renderList = useCallback(({ name, items }: IList, index) => {
    return (
      <List key={index}>
        {name && <Bold children={name} />}
        <Ul
          children={items.map((x) => (
            <Li key={x} children={x} />
          ))}
        />
      </List>
    );
  }, []);

  const lists = useMemo(() => screen.feedbackLists?.map(renderList), [
    renderList,
    screen.feedbackLists
  ]);

  return <Content children={lists} />;
}
