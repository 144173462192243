import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react';

import { IOverlay } from '~/types/IOverlay';
import { fetch } from '~/App/helpers/http';

type Props = {
  overlay: IOverlay | null;
};

export function useImages({ overlay }: Props) {
  const [desktop, setDesktop] = useState<string | null>(null);
  const [mobile, setMobile] = useState<string | null>(null);

  const hasMobileUrl = useMemo(
    () => !!overlay?.content.imageMobile?.secure_url, 
    [overlay?.content.imageMobile?.secure_url]);

  const hasDesktopUrl = useMemo(
    () => !!overlay?.content.imageDesktop?.secure_url, 
    [overlay?.content.imageDesktop?.secure_url]);

    const allLoaded = useMemo(() => {
      if(hasMobileUrl && !mobile) return false;
      if(hasDesktopUrl && !desktop) return false;

      return true;
    }, [desktop, hasDesktopUrl, hasMobileUrl, mobile])

  const download = useCallback(
    (url: string | undefined, setImage: Dispatch<SetStateAction<string | null>>) => {
      if(!url) return;

      fetch({
        url,
        responseType: 'arraybuffer'
      })
        .then(({ data }) =>
          setImage(Buffer.from(data, 'binary').toString('base64'))
        )
        .catch(() => setImage(null))
    },
    []
  );

  useEffect(() => {
    if (hasMobileUrl)
      download(overlay?.content?.imageMobile?.secure_url, setMobile);
    if (hasDesktopUrl)
      download(overlay?.content?.imageDesktop?.secure_url, setDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overlay?.content.imageDesktop?.secure_url,
    overlay?.content.imageMobile?.secure_url
  ]);

  return useMemo(() => ({
    desktop: hasDesktopUrl ? desktop : mobile,
    mobile: hasMobileUrl ? mobile : desktop,
    completed: allLoaded
  }), [allLoaded, desktop, hasDesktopUrl, hasMobileUrl, mobile]);
}
