import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

// Helpers
import { assemble } from '~/lib/slug-helper';
import { siteUrl } from '~/App/helpers/env';

import Section from '~/App/shared/components/Section';
import NewsFeed from '~/App/shared/components/NewsFeed';
import EditorialItem from '~/App/shared/components/EditorialItem';
import { OneRowPreamble } from '~/App/shared/components/RelatedBox';

export function Post({ data: { editorialItem = {}, newsFeed = {} } = {} }) {
  const { meta = {}, related = [] } = editorialItem;

  return (
    <div>
      <Helmet>
        <title>{meta.pageTitle}</title>
        <link rel="canonical" href={siteUrl(assemble(editorialItem))} />
        <meta name="description" content={meta.pageDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:image" content={meta.ogImage} />
        <meta property="og:description" content={meta.ogDescription} />
      </Helmet>
      <EditorialItem
        content={editorialItem}
        parentSlug="/om-cancerfonden"
        parentName="Om oss"
      />
      {related.length > 0 ? (
        <Section modifier="section--slimTop section--narrow">
          <OneRowPreamble heading="Cancerfonden" related={related} />
        </Section>
      ) : null}
      <Section modifier="section--borderTopFaded">
        <NewsFeed {...newsFeed} />
      </Section>
    </div>
  );
}
