import { fetch, collections } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';
import { IMemorialPage } from '~/types/ICollection';
import { LoadDataProps } from '~/types/routes';

type Params = any;
type Query = any;

export const loadContent = () =>
  fetch({
    url: contentApi('/v1/content-definitions'),
    params: {
      slugs: ['memorial-pages-hero'],
      metaKey: 'memorialPagesHero'
    }
  });

export const loadMemorialPages = ({ query }: { query: Query }) =>
  collections.index<IMemorialPage>({
    params: {
      sorting: 'latestCreated',
      period: 3650,
      per_page: 12,
      type: 'memorial_page',
      ...query
    }
  });

export const loadData = async ({ query }: LoadDataProps<Params, Query>) => {
  const response = await Promise.all([
    loadContent(),
    loadMemorialPages({ query })
  ]);

  const [content, memorialPages] = response;

  return {
    data: {
      content: content.data,
      memorialPages: memorialPages.data
    }
  };
};
