import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { card } from '~/App/helpers/mixins';
import { useInViewport } from '~/App/shared/hooks/use-in-viewport';
import { translateYAnimation } from '~/App/helpers/animations';
import { ICollectionActivity } from '~/types/ICollectionActivity';
import { Likes } from './components/Likes';
import { LikeButton } from './components/LikeButton';
import { useReadMore } from '~/App/shared/hooks/use-read-more';
import { mq } from '~/lib/mq';
import { ShareButton } from './components/ShareButton';
import { MemoryPeriodImage } from '../../../../components/MemoryPeriodImage';
import dayjs from 'dayjs';
import mediaHelper from '~/App/helpers/media-helper';

const Card = styled.div`
  ${card};
  ${translateYAnimation};
  padding: 0rem !important;
  overflow: hidden;

  margin-bottom: 2rem;
`;

const Content = styled.div`
  padding: 1rem;
`;

type ImageProps = {
  url: string;
};

const Image = styled.div<ImageProps>`
  background-image: url('${({ url }) => url}');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100%;
  position: relative;
`;

const StyledMemoryPeriodImage = styled(MemoryPeriodImage)`
  width: 4.75rem;
  height: 4.75rem;
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
`;

const Sender = styled.div`
  font-weight: bold;
  font-size: 1.375rem;
  white-space: pre-line;
`;

const Blue = styled.div`
  font-weight: bold;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 0 0 0.5rem;
`;

const Hr = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.lightDust};
  margin: 1rem 0;
`;

const Preamble = styled.p`
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 1rem;
  overflow: hidden;
  font-size: 1rem;

  ${mq('≥small')`
    font-size: 1.125rem;
    `};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDate = styled.p`
  font-size: 0.85rem;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
`;

type Props = {
  activity: ICollectionActivity;
  openShareModal: (activity: ICollectionActivity) => void;
};

export function MemoryCard({ activity, openShareModal }: Props) {
  const [isVisible, refVisible] = useInViewport();
  const [likes, setLikes] = useState(activity.likes);
  const [isRunning, setRunning] = useState(false);

  const { button, ref } = useReadMore({
    maximumRows: 5
  });
  const createMemoryDate = activity.createdAt
    ? dayjs(activity.createdAt)
    : null;
  const createMemoryDateFormatted = createMemoryDate?.format('D MMM YYYY');

  useEffect(() => {
    if (isRunning) return;
    if (!isVisible) return;

    setRunning(true);
  }, [isVisible, isRunning]);

  const imageUrl = useMemo(
    () =>
      mediaHelper.getUrl(activity.imageUrl, {
        width: 400,
        height: 400
      }),
    [activity]
  );

  return (
    <Card ref={refVisible} isRunning={isRunning}>
      <Image
        url={imageUrl}
        children={
          activity.period ? (
            <StyledMemoryPeriodImage period={activity.period} />
          ) : null
        }
      />
      <Content>
        <Sender children={`Av ${activity.user.by}`} />
        {activity.period === 'Once' ? (
          <Blue children="Har skänkt en gåva" />
        ) : null}
        {activity.period === 'Month' ? (
          <Blue children="Ger varje månad" />
        ) : null}

        <Preamble children={activity.content} ref={ref} />

        <ButtonWrapper>{button}</ButtonWrapper>
        <StyledDate children={createMemoryDateFormatted} />
        <Likes likes={likes} />
        <Hr />
        <Row>
          <LikeButton collectionActivityId={activity.id} onLike={setLikes} />
          <ShareButton openShareModal={openShareModal} activity={activity} />
        </Row>
      </Content>
    </Card>
  );
}
