import React, { useMemo } from 'react';

import { ICmsProduct, CmsProductTarget } from '~/types/ICmsProduct';
import { IValidation } from '~/App/shared/components/Validation';

import {
  MultiPartFormValues,
  Section
} from '~/App/shared/components/MultiPartForm';
import { StepWrapper } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';
import { ContactFields } from '~/App/shared/components/Donation/components/Forms/components/ContactFields';
import { StateValues } from '~/App/shared/components/Donation/components/States';

type Props = {
  product: ICmsProduct;
  values: MultiPartFormValues & StateValues;
  validation: IValidation;
};

export default function FormPartContactInformation({
  validation,
  values,
  product
}: Props) {
  const showContactType = useMemo(
    () => ({
      company: product.target === CmsProductTarget.company,
      manual: product.target === CmsProductTarget.private,
      automatic: product.target === CmsProductTarget.private
    }),
    [product]
  );

  return (
    <Section
      values={values}
      part={2}
      validation={validation}
      title="Dina kontaktuppgifter"
      nextStepButton={{ text: 'Till betalsätt' }}
    >
      <StepWrapper>
        <ContactFields
          values={values}
          validation={validation}
          taxReductionInfo
          company={showContactType.company ? { phone: true } : false}
          manual={showContactType.manual ? { email: true } : false}
          automatic={
            showContactType.automatic
              ? {
                  email: true
                }
              : false
          }
        />
      </StepWrapper>
    </Section>
  );
}
