import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Container } from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { BackgroundColor } from '~/types/Block';
import { heading2 } from '~/App/helpers/mixins';
import { HtmlContent } from '../../../HtmlContent';
import loadScript from '~/App/helpers/load-script';

const Heading = styled.h3`
  ${heading2};
  color: ${({ theme }) => theme.colors.darkBlue};
  text-align: center;
  max-width: 46.25rem;
  margin: 0 auto 1rem;
`;

const Body = styled(HtmlContent)`
  text-align: center;
  max-width: 46.25rem;
  margin: 0 auto;
`;

const RenderHere = styled.div`
  min-height: 30rem;
`;

declare global {
  interface Window {
    lawlySettings?: {
      app_id?: string;
      widget_id?: string;
      mount?: HTMLDivElement;
    };
  }
}

type Props = {
  backgroundColor: BackgroundColor;
  heading: string;
  body: string;
};

export function Lawly({ backgroundColor, heading, body }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!ref.current) {
      return;
    }

    window.lawlySettings = {
      app_id: 'PGwFfR0az5',
      widget_id: 'Xh5Jfq89',
      mount: ref.current
    };

    loadScript({ url: 'https://embed.lawly.app/contractor/shim.latest.js' });
  }, []);

  return (
    <BlockBackground backgroundColor={backgroundColor}>
      <Container
        width="limited"
        paddingTop="small"
        paddingLeft="small"
        paddingRight="small"
        paddingBottom="none"
      >
        {heading && <Heading children={heading} />}
        {body && <Body children={body} />}
        <RenderHere ref={ref} />
      </Container>
    </BlockBackground>
  );
}
