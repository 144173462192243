import React from 'react';
import classNames from 'classnames';
import accounting from 'accounting';

import mediaHelper from '~/App/helpers/media-helper';

const formatMoney = (value) =>
  accounting.formatMoney(value, 'kr', 0, ' ', null, '%v %s');

export default class EngagementItem extends React.Component {
  render() {
    const {
      fluid = false,
      amount = 0,
      avatarText = '',
      avatarImageWidth = 150,
      avatarImageHeight = 150,
      backgroundImageWidth = 300,
      backgroundImageHeight = 300
    } = this.props;

    let { avatarImage = '', backgroundImage = '' } = this.props;

    if (backgroundImage) {
      backgroundImage = mediaHelper.getUrl(backgroundImage, {
        width: backgroundImageWidth,
        height: backgroundImageHeight
      });
    }

    if (avatarImage) {
      avatarImage = mediaHelper.getUrl(avatarImage, {
        width: avatarImageWidth,
        height: avatarImageHeight
      });
    } else {
      avatarImage = '/images/default-avatar.png';
    }

    return (
      <div
        className={classNames('engagementItem', {
          'engagementItem--fluid': fluid,
          'engagementItem--image': backgroundImage,
          'engagementItem--caption': avatarText
        })}
      >
        <div className="engagementItem__content">
          <div className="engagementItem__info">
            <div className="engagementItem__caption engagementItem__caption--donation">
              {amount && amount !== '0.0' ? (
                <span>
                  Har skänkt
                  <em>{formatMoney(amount)}</em>
                </span>
              ) : (
                <span>Har skänkt en gåva</span>
              )}
            </div>
          </div>
          {backgroundImage && (
            <div
              style={{ backgroundImage: `url(${backgroundImage})` }}
              className="engagementItem__image"
            />
          )}
        </div>
        <div className="engagementItem__meta">
          <div className="engagementItemMeta">
            <div className="engagementItemMeta__content">
              {avatarImage && (
                <div
                  style={{ backgroundImage: `url(${avatarImage})` }}
                  className="engagementItemMeta__avatar"
                />
              )}
              <div className="engagementItemMeta__info engagementItemMeta__info--avatar">
                {avatarText && (
                  <div className="engagementItemMeta__name ui-truncate">
                    {avatarText}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
