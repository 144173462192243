import { paymentMethods } from '~/App/config/paymentMethods';

type Value = string | number | null | undefined;

interface IProductOptions {
  product?: {
    price?: Value;
    quantity?: Value;
  };
  memorialGift?: {
    amount?: Value;
  };
  lotteryTicket?: {
    price?: Value;
    quantity?: Value;
  };
}

export function getNumber(value: Value): number {
  return parseInt(`${value}`, 10) || 0;
}

export function getListPrice(productOptions: IProductOptions) {
  if (productOptions.product?.price) {
    return getNumber(productOptions.product.price);
  }

  if (productOptions.memorialGift?.amount) {
    return getNumber(productOptions.memorialGift.amount);
  }

  if (productOptions.lotteryTicket?.price) {
    return getNumber(productOptions.lotteryTicket.price);
  }

  return 0;
}

export function getQuantity(productOptions: IProductOptions) {
  if (productOptions.lotteryTicket?.quantity) {
    return getNumber(productOptions.lotteryTicket.quantity);
  }

  return getNumber(productOptions.product?.quantity);
}

export function getReceiptName(paymentMethodId?: number) {
  const receiptName =
    paymentMethodId === paymentMethods.paymentSlip ||
    paymentMethodId === paymentMethods.invoice ||
    paymentMethodId === paymentMethods.autoGiroSlip
      ? 'bekräftelse'
      : 'kvitto';

  return receiptName;
}

export function getReceiptNameWithPronoun(paymentMethodId?: number) {
  const receiptName =
    paymentMethodId === paymentMethods.paymentSlip ||
    paymentMethodId === paymentMethods.invoice ||
    paymentMethodId === paymentMethods.autoGiroSlip
      ? 'din bekräftelse'
      : 'ditt kvitto';

  return receiptName;
}
