import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { NavLink } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import dayjs from 'dayjs';

import { mq } from '~/lib/mq';
import { ctaButton, heading1 } from '~/App/helpers/mixins';
import { collectionActivities } from '~/App/helpers/http';
import { ICollectionActivity } from '~/types/ICollectionActivity';
import { IMemorialPage } from '~/types/ICollection';
import { useInfiniteScroll } from '~/App/shared/hooks/use-infinite-scroll';
import { useImage } from '~/App/shared/hooks/use-image';
import { Container } from '~/App/shared/components/Container';
import { FloatingButton } from '~/App/shared/components/FloatingButton';

import { CircleImage } from '../../components/CircleImage';
import { Background } from './components/Background';
import { Share } from './components/Share';
import { EmptyState } from './components/EmptyState';
import { MemoryCard } from './components/MemoryCard';
import { formatMoney } from 'accounting';
import { MemoryModal } from '../MemoryModal/MemoryModal';
import useQuery from '~/App/shared/hooks/use-query';
import { ShareModal } from '../ShareModal/ShareModal';
import mediaHelper from '~/App/helpers/media-helper';
import { Helmet } from 'react-helmet';
import { IMeta } from '~/types/IMeta';
import { siteUrl } from '~/App/helpers/env';
import { assemble } from '~/lib/slug-helper';

const Heading = styled.div`
  ${heading1};
  color: ${({ theme }) => theme.colors.white};
  max-width: 40rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4.25rem 0;

  ${mq('<medium')`
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 2rem 0;
  `};
`;

const Column = styled.div``;

const StyledCircleImage = styled(CircleImage)`
  margin: 0 3rem 0 0;

  ${mq('<medium')`
    margin: 0 0 1rem;
  `};
`;

const Preamble = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white};

  ${mq('<medium')`
    font-size: 1rem;
  `};
`;

const Bold = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 0.5rem;

  ${mq('<medium')`
    font-size: 1rem;
  `};
`;

const Link = styled(NavLink)`
  ${ctaButton};
  margin-top: 1.5rem;

  ${mq('<small')`
    display: none;
  `}
`;

const StyledFloatingButton = styled(FloatingButton)`
  bottom: 1rem;
  display: none;

  ${mq('<small')`
    display: block;
  `}
`;

const DesktopShareButtons = styled(Share)`
  margin-left: auto;

  ${mq('<medium')`
    display: none;
  `};
`;

const MobileShareButtons = styled(Share)`
  display: none;
  flex-direction: column-reverse;
  margin-top: 3rem;

  div:first-child {
    margin: 1rem 0 0;
    font-size: 1rem;
  }

  ${mq('<medium')`
    display: flex;
  `};
`;

const BringToTop = styled.div`
  z-index: 20;
`;

const MasonryStyles = createGlobalStyle`
.my-masonry-grid {
  display: flex;
  margin-left: -2rem;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 2rem;
  background-clip: padding-box;
}
`;

type Query = {
  memoryId?: string;
};

type Props = {
  collection: IMemorialPage & {meta: IMeta};
  activities: ICollectionActivity[];
};

export function MemorialPage({ collection: {meta, ...collection}, activities}: Props) {
  const [data, setData] = useState(activities);

  const [
    memoryModalOpen,
    setMemoryModalOpen
  ] = useState<ICollectionActivity | null>(null);
  const [
    shareModalOpen,
    setShareModalOpen
  ] = useState<ICollectionActivity | null>(null);

  const openModal = useCallback(
    (memory: ICollectionActivity) => setMemoryModalOpen(memory),
    []
  );
  const openShareModal = useCallback(
    (memory: ICollectionActivity) => setShareModalOpen(memory),
    []
  );
  const closeModal = useCallback(() => setMemoryModalOpen(null), []);
  const closeShareModal = useCallback(() => setShareModalOpen(null), []);

  const shareModal = useMemo(() => {
    if (!shareModalOpen) return null;
    return <ShareModal closeModal={closeShareModal} memory={shareModalOpen} />;
  }, [shareModalOpen, closeShareModal]);

  const memoryModal = useMemo(() => {
    if (!memoryModalOpen) return null;
    //ska vara den andra modalen
    return (
      <MemoryModal
        collection={collection}
        closeModal={closeModal}
        memory={memoryModalOpen}
      />
    );
  }, [memoryModalOpen, collection, closeModal]);

  const query = useQuery<Query>();

  useEffect(() => {
    // const memory = data.find((x) => x.id === query.memoryId);

    if (!query.memoryId) return;
    collectionActivities
      .getMemory({
        collection: collection.slug,
        token: query.memoryId
      })
      .then((response) => openModal(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.memoryId]);

  const date = useMemo(
    () =>
      [collection.birthDate, collection.deathDate]
        .filter((x) => !!x)
        .map((x) => dayjs(x ?? '').format('D MMM YYYY'))
        .join(' - '),
    [collection]
  );

  const renderActivity = useCallback(
    (activity: ICollectionActivity) => (
      <MemoryCard openShareModal={openShareModal} activity={activity} />
    ),
    [openShareModal]
  );

  const loadPage = useCallback(
    async (page: number) => {
      const res = await collectionActivities.show({
        slug: collection.slug,
        params: {
          page: page,
          per_page: 12
        }
      });

      setData((state) => [...state, ...res.data.activities]);

      return res.data.activities.length === 12;
    },
    [collection.slug]
  );

  const trigger = useInfiniteScroll({
    hasFollowingPage: activities.length === 12,
    loadPage
  });
  const children = useMemo(() => {
    if (data.length) {
      return (
        <Container
          width="normal"
          padding={{
            left: 'small',
            right: 'small',
            top: 'large',
            bottom: 'large'
          }}
        >
          <Masonry
            breakpointCols={{
              768: 1,
              1024: 2,
              default: 3
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            children={data.map(renderActivity)}
          />
        </Container>
      );
    }

    return <EmptyState />;
  }, [data, renderActivity]);

  const source = useImage(
    'https://res.cloudinary.com/cancerfonden/image/upload/v1637756882/minnesida/hero_3x.jpg'
  );

  const status = useMemo(() => {
    if (!collection.status) {
      return 'Till minne av';
    }

    if (collection.status === '0.0') {
      return 'Till minne av';
    }

    return `${formatMoney(
      collection.status,
      'kr',
      0,
      ' ',
      undefined,
      '%v %s'
    )} insamlat till minne av`;
  }, [collection.status]);

  const imageUrl = useMemo(
    () =>
      mediaHelper.getUrl(collection.imageUrl, {
        width: 400,
        height: 400
      }),
    [collection]
  );

  const defaultDescription =
    `Var med och dela minnen av ${meta?.ogTitle} hos Cancerfonden.`;

  return (
    <>
      <Helmet>
        <title>{'Minnessida för ' + meta?.ogTitle}</title>
        <link
          rel="canonical"
          href={siteUrl(assemble(`stod-oss/minnessidor/${collection.slug}`))}
        />
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content={meta?.ogDescription || defaultDescription}
        />
        {meta?.ogTitle && (
          <meta
            property="og:title"
            content={`Det finns en minnessida för ${meta.ogTitle}`}
          />
        )}
        <meta
          property="og:description"
          content={meta?.ogDescription || defaultDescription}
        />
      </Helmet>
      <MasonryStyles />
      <Background src={source}>
        <Container width="normal" padding={{ left: 'small', right: 'small' }}>
          <DesktopShareButtons collection={collection} />
          <Flex>
            <StyledCircleImage src={imageUrl} size="23rem" />
            <Column>
              <Preamble children={status} />
              <Heading children={collection.name} />
              {date && <Bold children={date} />}
              <Link
                to={`/stod-oss/minnessidor/${collection.slug}/skapa-minne`}
                children="Skriv in ditt minne"
              />
              <MobileShareButtons collection={collection} />
            </Column>
          </Flex>
        </Container>
      </Background>
      {children}
      {trigger}
      <StyledFloatingButton
        buttonStyle="cta"
        to={`/stod-oss/minnessidor/${collection.slug}/skapa-minne`}
        children="Skriv in ditt minne"
      />
      <BringToTop children={memoryModal} />
      <BringToTop children={shareModal} />
    </>
  );
}
