import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

const Text = styled.p`
  display: none;

  ${mq('≥small')`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    color: black;
    opacity: 0.07;
    transform: rotate(-45deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    font-size: 2.5em;
    font-weight: 700;
  `};
`;

export const Watermark = function Watermark({ isVisible }) {
  return <Text isVisible={isVisible}>Förhandsgranskning</Text>;
};
