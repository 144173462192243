import React from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';

const Wrapper = styled.div`
  background-color: rgba(32, 169, 34, 0.1);
  padding: 1.5rem 1.5rem 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;

  ${mq('<small')`
    padding: 1rem 1rem 2.5rem 1rem
  `}
`;

const Heading = styled.h2`
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;

  ${mq('<small')`
    font-size: 1rem;
  `}
`;

const Info = styled.p`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 0.5rem;

  ${mq('<small')`
    font-size: 1rem;
  `}
`;

const Link = styled.a`
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-decoration: underline;
  margin-top: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  ${mq('<small')`
    font-size: 16px;
  `}
`;
type Props = {
  openModal: () => void;
};

export function PersonNotFound({ openModal }: Props) {
  return (
    <Wrapper>
      <Heading>Hittar du inte rätt person?</Heading>
      <Info>
        Personens begravning kanske inte har registrerats ännu, men vi hjälper
        gärna till att hitta rätt adress. Fyll i de uppgifter du har så skickar
        vi minnesbladet så fort vi kan.
      </Info>
      <Link onClick={openModal}>Fyll i uppgifter om personen</Link>
    </Wrapper>
  );
}
