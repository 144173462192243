import React, { useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { outlinePrimaryButton } from '~/App/helpers/mixins';

// Shared components
import Link from '~/App/shared/components/Link';
import { NotificationBadge } from '~/App/shared/components/Elements/NotificationBadge';
import { ProfileIcon } from './components/ProfileIcon';
import { getHoursSinceDate } from '~/App/helpers/date.service';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

const StyledLink = styled(Link)`
  ${outlinePrimaryButton};
  min-width: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 0;
  align-items: center;
  line-height: 1;
  font-size: 0.875rem;
  padding: 0.625rem 1rem;
  color: ${({ theme }) => theme.themeColors.primary};
  background-color: ${({ theme }) => theme.themeColors.primaryBackgroundColor};
  border-color: ${({ theme }) => theme.themeColors.primaryBackgroundColor};
  font-weight: 700;
  position: relative;

  ${mq('≥micro')`
    padding: 0.625rem 1.25rem;
  `};

  ${mq('≥large')`
    font-size: 1rem;
    padding: 0.625rem 1.5rem;
  `};
`;

const Icon = styled.span`
  display: block;
  margin: 0 0.5rem 0 0;
  padding: 0;
`;

export function AccountButton() {
  const user = useAuthenticationContext(({ user }) => user);
  const showNotificationBadge = useMemo(() => {
    if (user?.emailStatus === 'verified') return false;
    if (!user?.firstLoginAt) return true;
    if (getHoursSinceDate(user?.firstLoginAt) > 24) return true;

    return false;
  }, [user?.firstLoginAt, user?.emailStatus]);

  return (
    <StyledLink to="/min-sida" aria-label="Min sida">
      <Icon>
        <ProfileIcon />
      </Icon>
      {showNotificationBadge && <NotificationBadge />}
      <span children="Min sida" />
    </StyledLink>
  );
}
