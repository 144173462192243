import { ICommerceProduct } from '~/types/ICommerceProduct';
import { siteAxios } from './client';

export const products = {
  show: async (slug: string) => {
    const url = `/api/v2/products/${slug}`;
    const response = await siteAxios.get<ICommerceProduct>(url);
    const data = response.data;

    return data;
  }
};
