import React, { useMemo } from 'react';
import styled from 'styled-components';

import { heading5 } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';

const Wrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  width: 100%;
`;

const Image = styled.img`
  height: 6rem;
  width: 6rem;
  min-width: 6rem;
  min-height: 6rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
`;

const Name = styled.div`
  ${heading5};
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const ProductName = styled.div`
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

const Text = styled.div`
  font-size: 1rem;
  margin-top: 0.125rem;
`;

const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 0.25rem;
  font-size: 0.75rem;

  ${mq('>small')`
    font-size: 0.875rem;
  `};
`;

type Props = {
  item: ICommerceOrderLine;
};

export function SummeryItem({ item }: Props) {
  const isAvailable = useMemo(() => {
    if (item.variant?.discontinued) {
      return false;
    }

    if (item.variant?.stock?.status === 'OutOfStock') {
      return false;
    }

    return true;
  }, [item.variant?.discontinued, item.variant?.stock?.status]);

  return (
    <Wrapper>
      <Image src={item.variant?.primaryImage} />
      <Column>
        <Name children={item.variant?.product?.name} />
        {item.variant?.product?.type === 'DigitalCertificate' && (
          <ProductName children="Digitalt gåvobevis" />
        )}
        <Row>
          <Text children={`${item.quantity} st`} />
          <Text children={`${item.price * item.quantity} kr`} />
        </Row>
        {isAvailable ? null : (
          <ErrorText
            children={
              item.variant?.discontinued
                ? 'Produkten har utgått'
                : 'Tillfälligt slut'
            }
          />
        )}
      </Column>
    </Wrapper>
  );
}
