import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { mq } from '~/App/helpers/mq';
import { Container } from '~/App/shared/components/Container';

import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { BackgroundColor, Padding } from '~/types/Block';

import { Slide, Item } from './components/Slide';
import { ScrollBar } from './components/ScrollBar';
import { Arrows } from './components/Arrows';
import { useSlideshow } from './hooks/useSlideshow';
import { Heading } from './components/Heading';

const Block = styled(BlockBackground)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: 1.5rem -1rem 4rem;

  ${mq('≥medium')`
    margin: 2.5rem -2.75rem 4.5rem;
  `};
`;

const Slides = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

type SlideWrapperProps = {
  size: {
    edgePadding: number;
    gutter: number;
  };
};

const SlideWrapper = styled.div<SlideWrapperProps>`
  flex-grow: 0;
  flex-shrink: 0;
  scroll-snap-align: center;
  scroll-snap-stop: normal;
  flex-basis: ${({ size }) => `calc(100% - ${size.edgePadding}rem)`};
  padding: ${({ size }) => `0 ${size.gutter}rem`};

  &:first-of-type {
    flex-basis: ${({ size }) =>
      `calc(100% - ${size.edgePadding}rem - ${size.gutter}rem)`};
    padding-left: 0;
  }

  &:last-of-type {
    flex-basis: ${({ size }) =>
      `calc(100% - ${size.edgePadding}rem - ${size.gutter}rem)`};
    padding-right: 0;
  }

  ${mq('<small')`
    flex-basis: calc(100% - 2rem);
    padding: 0 0.25rem;

    &:first-of-type {
      flex-basis: calc(100% - 16px);
      padding-left: 16px;
    }

    &:last-of-type {
      flex-basis: calc(100% - 16px);
      padding-right: 16px;
    }
  `};
`;

type Props = {
  heading: string;
  items: Item[];
  hash: string;
  size: 'normal' | 'limited' | 'tight';
  backgroundColor: BackgroundColor;
  padding: Padding;
  headingSettings: {
    fontSize: 'heading1' | 'heading2' | 'heading3' | 'sectionHeading';
    textAlign: string;
    fontWeight: string;
  };
};

export function Slideshow({
  heading,
  items = [],
  size: selectedSize,
  backgroundColor,
  padding,
  headingSettings
}: Props) {
  const slideshow = useSlideshow({
    items,
    selectedSize
  });

  const { ref, size, isFirst, isLast, selectedIndex, updateIndex } = slideshow;

  const renderItem = useCallback(
    (item, index) => (
      <SlideWrapper
        key={item.id || index}
        size={size}
        children={<Slide index={index} item={item} />}
      />
    ),
    [size]
  );

  const containerPadding = useMemo<Padding>(
    () => ({
      ...padding,
      left: 'small',
      right: 'small'
    }),
    [padding]
  );

  if (!items || !items.length) {
    return null;
  }

  return (
    <Block backgroundColor={backgroundColor}>
      <Container width={selectedSize} padding={containerPadding}>
        <Heading children={heading} settings={headingSettings} />
        <Content>
          <Arrows.Left onClick={updateIndex(-1)} disabled={isFirst} />
          <Slides ref={ref} children={items.map(renderItem)} />
          <Arrows.Right onClick={updateIndex(1)} disabled={isLast} />
        </Content>
        <ScrollBar count={items.length} index={selectedIndex} />
      </Container>
    </Block>
  );
}
