import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

export default (url: string | null) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!url) {
      return;
    }

    if (location.pathname === url) {
      return;
    }

    navigate(url, {
      replace: true
    });
  }, [location, url, navigate]);
};
