import { useState, useEffect } from 'react';
import _ from 'lodash';

type WindowSize = {
	width: number | undefined;
	height: number | undefined;
};

export function useWindowSize() {
	const [ windowSize, setWindowSize ] = useState<WindowSize>({
		width: undefined,
		height: undefined
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		}

		const debouncedHandler = _.debounce(handleResize, 300);

		window.addEventListener('resize', debouncedHandler);
		handleResize();

		return () => window.removeEventListener('resize', debouncedHandler);
	}, []);

	return windowSize;
}

export default useWindowSize;