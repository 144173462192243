import React, { useMemo } from 'react';
import Select, { ActionMeta, MultiValue, SingleValue, StylesConfig } from 'react-select';
import styled from 'styled-components';
import colors from '~/App/config/themes/colors';
import { CategoryOption } from './CategoryFilter';
import { NoOptionsMessage } from './components/NoMessageContainer';
import { IndicatorsContainer } from './components/SingleIndicatorsContainer';

const GroupHeading = styled.div`
  font-size: 1.375rem;
  line-height: 1.23;
`;

const GroupStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export type IProjectStatus = {
  value: string;
  label: string;
};

type Props = {
  options: string[];
  placeholder: string;
  onChange: (
    newValue: MultiValue<CategoryOption> | SingleValue<CategoryOption>,
    actionMeta: ActionMeta<CategoryOption>
  ) => void;
  value: string[];
};

interface GroupedOption {
  readonly label: string;
  readonly options: readonly CategoryOption[];
}
export function SingleValueFilter({
  options,
  placeholder,
  onChange,
  value
}: Props) {
  const selectStyle = useMemo<StylesConfig<IProjectStatus, true | false>>(
    () => ({
      container: (styles) => ({
        ...styles,
        marginRight: '12px',
        width: '23.5rem',
        textAlign: 'left',
        height: '100%',
        '@media (max-width: 423px)': {
          width: '100%'
        }
      }),
      control: (provided) => ({
        ...provided,
        cursor: 'pointer'
      }),
      option: (styles) => ({
        ...styles,
        backgroundColor: colors.seashell,
        color: colors.darkBlue,
        padding: '4px 8px',
        marginRight: '8px',
        marginBottom: '8px',
        width: 'none',
        cursor: 'pointer'
      }),
      menuList: (provided) => ({
        ...provided,
        cursor: 'default'
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: colors.seashell,
        color: colors.darkBlue
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: colors.darkBlue
      }),
      group: (provided) => ({
        ...provided,
        paddingTop: '12px',
        paddingLeft: '24px',
        paddingBottom: '0px',
        '> div': {
          display: 'flex',
          flexWrap: 'wrap'
        }
      }),
      groupHeading: () => ({
        marginBottom: '0.75em',
        fontWeight: 'bold',
        color: '#4a4a4a'
      })
    }),
    []
  );

  const groupedOptions: readonly GroupedOption[] = [
    {
      label: placeholder,
      options: makeGroupOptions(options) 
    }
  ];

  const formatGroupLabel = () => (
    <GroupStyle>
      <GroupHeading>{placeholder}</GroupHeading>
    </GroupStyle>
  );

  const components = useMemo(
    () => ({
      NoOptionsMessage,
      IndicatorsContainer
    }),
    []
  );

  return (
    <Select<IProjectStatus, false | true>
      instanceId={placeholder}
      placeholder={placeholder}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      onChange={onChange}
      defaultValue={makeGroupOptions(value)}
      styles={selectStyle}
      components={components}
      closeMenuOnSelect={false}
      isMulti={true}
    />
  );
}

const makeGroupOptions = (categoryNames: string[]): CategoryOption[] => {
  return categoryNames?.length > 0
    ? categoryNames?.map((name) => ({
        value: name,
        label: name
      }))
    : [];
};
