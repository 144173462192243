import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import { CustomInput } from './components/CustomInput';

export const Wrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    border-color: ${({ theme }) => theme.colors.lightDust};
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  .react-datepicker__day-name {
    font-size: 0.75rem;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.seashell};
    border-color: ${({ theme }) => theme.colors.lightDust};
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.darkBlue};

    &:hover {
      background-color: ${({ theme }) => theme.colors.hoverBlue};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.transparent};
    color: ${({ theme }) => theme.colors.black};
  }

  .react-datepicker__navigation--next {
    border-left-color: ${({ theme }) => theme.colors.darkBlue};

    &:hover {
      border-left-color: ${({ theme }) => theme.colors.hoverBlue};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${({ theme }) => theme.colors.darkBlue};

    &:hover {
      border-right-color: ${({ theme }) => theme.colors.hoverBlue};
    }
  }
`;

type Value = Date;

type Props = {
  value: Value | null | undefined;
  errors?: string[];
  onChange: (value: Value) => void;
  onBlur?: () => void;
  min?: Date;
  max?: Date;
  isValid?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  showMonthYearDropdown?: boolean;
  required?: boolean;
};

export function Date({
  value,
  errors,
  onChange,
  onBlur,
  min,
  max,
  isValid = false,
  isInvalid = false,
  placeholder,
  showMonthYearDropdown = false,
  required
}: Props) {
  const [touched, setTouched] = useState(false);

  const locale = useMemo(() => {
    const months = [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December'
    ];
    const days = ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'];

    return {
      localize: {
        month: (n: number) => months[n],
        day: (n: number) => days[n]
      },
      formatLong: {}
    } as any;
  }, []);

  const handleCalendarOpen = useCallback(() => {
    if (!touched) setTouched(true);
  }, [touched]);

  return useMemo(
    () => (
      <Wrapper>
        <DatePicker
          selected={value}
          onChange={onChange}
          onCalendarOpen={handleCalendarOpen}
          customInput={
            <CustomInput
              errors={errors}
              isValid={isValid && touched}
              isInvalid={isInvalid}
              onNativeChange={onChange}
              required={required}
              onBlur={onBlur}
            />
          }
          locale={locale}
          maxDate={max}
          minDate={min}
          dateFormat="yyyy-MM-dd"
          placeholderText={placeholder}
          showYearDropdown={showMonthYearDropdown}
          showMonthDropdown={showMonthYearDropdown}
          dropdownMode="select"
          required={required}
          onBlur={onBlur}
        />
      </Wrapper>
    ),
    [
      errors,
      handleCalendarOpen,
      isInvalid,
      isValid,
      locale,
      max,
      min,
      onChange,
      placeholder,
      showMonthYearDropdown,
      touched,
      value,
      onBlur,
      required
    ]
  );
}
