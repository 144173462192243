import { ParsedQuery } from './types';

export function stringify(query: ParsedQuery): string {
  const entries = Object.entries(query);

  const filteredEntries = entries.filter(
    ([_, value]) => value !== null && value !== undefined
  );
  const joinedEntries = filteredEntries.map(
    ([key, value]) => `${key}=${value}`
  );
  const joinedQuery = joinedEntries.join('&');

  return joinedQuery;
}
