import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useState, useCallback, ChangeEvent, useMemo } from 'react';
import {
  formatExpiry,
  cardExpiryValue,
  formatCardNumber
} from '~/App/helpers/credit-card';
import { Period } from '~/types/IPurchase';

type State = {
  cvc: string;
  number: string;
  expiry: string;
  expiryYear: string;
  expiryMonth: string;
};

type ValidatePaymentArguments = {
  clientSecret: string;
  period: Period;
};

type ValidatePaymentResult = {
  success: boolean;
  message?: string;
  code?: string;
};

type ValidatePayment = (
  args: ValidatePaymentArguments
) => Promise<ValidatePaymentResult>;

export type CreditCard = State & {
  handlers: {
    validateCreditCardPayment: ValidatePayment;
    handleCreditCardCvc: (value: ChangeEvent<HTMLInputElement>) => void;
    handleCreditCardNumber: (value: ChangeEvent<HTMLInputElement>) => void;
    handleCreditCardExpiry: (value: ChangeEvent<HTMLInputElement>) => void;
    handleCreditCardExpiryYear: (value: ChangeEvent<HTMLInputElement>) => void;
    handleCreditCardExpiryMonth: (value: ChangeEvent<HTMLInputElement>) => void;
  };
};

export function useCreditCard(): CreditCard {
  const stripe = useStripe();
  const elements = useElements();

  const [state, setState] = useState<State>({
    cvc: '',
    number: '',
    expiry: '',
    expiryYear: '',
    expiryMonth: ''
  });

  const handleCreditCardCvc = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setState((state) => ({
        ...state,
        cvc: event.target.value
      })),
    []
  );

  const handleCreditCardNumber = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setState((state) => ({
        ...state,
        number: formatCardNumber(event.target.value)
      })),
    []
  );

  const handleCreditCardExpiry = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = formatExpiry(event.target.value);
      const { year, month } = cardExpiryValue(value);

      setState((state) => ({
        ...state,
        expiry: value,
        expiryYear: year,
        expiryMonth: month
      }));
    },
    []
  );

  const handleCreditCardExpiryYear = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setState((state) => ({
        ...state,
        expiryYear: event.target.value
      })),
    []
  );

  const handleCreditCardExpiryMonth = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setState((state) => ({
        ...state,
        expiryMonth: event.target.value
      })),
    []
  );

  const validateCreditCardPayment = useCallback(
    async ({
      clientSecret,
      period
    }: ValidatePaymentArguments): Promise<ValidatePaymentResult> => {
      try {
        const cardElement = elements?.getElement(CardElement);

        if (!cardElement) {
          return {
            success: false,
            message: 'Card element not found'
          };
        }

        if (!stripe) {
          return {
            success: false,
            message: 'Stripe instance not found'
          };
        }

        const stripeConfirm =
          !period || period === 'Once'
            ? stripe?.confirmCardPayment
            : stripe?.confirmCardSetup;

        const stripeResponse = await stripeConfirm(clientSecret, {
          payment_method: {
            card: cardElement
          }
        });

        if (stripeResponse.error) {
          return {
            code: stripeResponse.error.code,
            message: stripeResponse.error.message,
            success: false
          };
        } else {
          return {
            success: true
          };
        }
      } catch (error) {
        console.log(error);

        return {
          success: false
        };
      }
    },
    [elements, stripe]
  );

  return useMemo(
    () => ({
      ...state,
      handlers: {
        handleCreditCardCvc,
        handleCreditCardNumber,
        handleCreditCardExpiry,
        handleCreditCardExpiryYear,
        handleCreditCardExpiryMonth,
        validateCreditCardPayment
      }
    }),
    [
      handleCreditCardCvc,
      handleCreditCardExpiry,
      handleCreditCardExpiryMonth,
      handleCreditCardExpiryYear,
      handleCreditCardNumber,
      validateCreditCardPayment,
      state
    ]
  );
}
