import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatMoney } from 'accounting';

// Helpers
import mq from '~/App/helpers/mq';
import { timeFromNow } from '~/App/helpers/date.service';
import { labelText } from '~/App/helpers/mixins';
import { IGift } from '~/types/ICollection';

// Shared components
import { Avatar } from '~/App/shared/components/Avatar';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0.75rem;
  padding-right: 1.5rem;
  margin-bottom: 0.75rem;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.725rem;

  ${mq('≥small')`
    margin-right: 1rem;
  `};
`;

const Content = styled.div`
  ${mq('≥small')`
    display:flex;
    justify-content: space-between;
    margin-right: 1.5rem;
    flex-grow: 1;
  `};
`;

const Donation = styled.p`
  ${labelText};
  flex-grow: 1;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-right: 0.625rem;
`;

const Moment = styled.p`
  ${labelText};
  flex-basis: 100%;

  ${mq('≥small')`
    flex-basis: auto;
    padding-left: 0;
    white-space: nowrap;
    margin-left: auto;
  `};
`;

type Props = {
  gift: IGift;
};

export function Gift({ gift }: Props) {
  const amount = useMemo(() => {
    if (!gift.amount) return null;
    if (gift.amount === '0.0') return null;

    return formatMoney(gift.amount, 'kr', 0, ' ', undefined, '%v %s');
  }, [gift.amount]);

  return useMemo(
    () => (
      <Wrapper>
        <StyledAvatar
          user={{ firstName: gift.user.by, imageUrl: gift.avatarImage }}
          size="small"
        />
        <Content>
          <Donation
            children={
              amount
                ? `${gift.text} gav ${amount}`
                : `${gift.text} har skänkt en gåva`
            }
          />
          {gift.createdAt && <Moment children={timeFromNow(gift.createdAt)} />}
        </Content>
      </Wrapper>
    ),
    [amount, gift.avatarImage, gift.createdAt, gift.text, gift.user.by]
  );
}
