import styled from 'styled-components';
import { mq } from '~/lib/mq';

type BackgroundProps = {
  image: string;
};

export const Background = styled.div<BackgroundProps>`
  background-image: radial-gradient(circle at 37% 40%, #001489, #5b6bcb 150%);
  position: relative;
  overflow: hidden;
  margin: 0 auto 0;
  color: white;
  min-height: 100vh;
  display: flex;

  &::after,
  &::before {
    content: '';
    display: block;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: solid 70px #83d5f1;
    position: absolute;
    z-index: 0;

    ${mq('≥medium')`
        width: 1000px;
        height: 1000px;
        border-width: 90px;
      `};
  }

  &::before {
    right: 0;
    top: 0;
    transform: translate(60%, -60%);
    filter: blur(1.5rem);
  }

  &::after {
    left: 0;
    bottom: 0;
    transform: translate(-60%, 60%);

    ${mq('≥medium')`
        border-width: 140px;
      `};
  }
`;
