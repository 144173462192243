const calculateScenario = (dropdownStatus, scenariosNested) => {
  const { maritalStatus, children, childrenOrigin } = dropdownStatus;
  const newScenario =
    scenariosNested[maritalStatus.value][children.value][childrenOrigin.value];

  return newScenario;
};

const calculateDropdownFromScenario = (
  scenario,
  { maritalStatusOptions, childrenOptions, childrenOriginOptions },
  dropdownData
) => {
  const optionsFromScenarios = {
    1: {
      maritalStatus: maritalStatusOptions.married,
      children: childrenOptions.hasChildren,
      childrenOrigin: childrenOriginOptions.currentRelationship
    },
    2: {
      maritalStatus: maritalStatusOptions.married,
      children: childrenOptions.hasChildren,
      childrenOrigin: childrenOriginOptions.pastRelationship
    },
    3: {
      maritalStatus: maritalStatusOptions.married,
      children: childrenOptions.hasChildren,
      childrenOrigin: childrenOriginOptions.bothRelationships
    },
    4: {
      maritalStatus: maritalStatusOptions.married,
      children: childrenOptions.noChildren,
      childrenOrigin: childrenOriginOptions.currentRelationship
    },
    5: {
      maritalStatus: maritalStatusOptions.partner,
      children: childrenOptions.hasChildren,
      childrenOrigin: childrenOriginOptions.currentRelationship
    },
    6: {
      maritalStatus: maritalStatusOptions.partner,
      children: childrenOptions.hasChildren,
      childrenOrigin: childrenOriginOptions.pastRelationship
    },
    7: {
      maritalStatus: maritalStatusOptions.partner,
      children: childrenOptions.hasChildren,
      childrenOrigin: childrenOriginOptions.bothRelationships
    },
    8: {
      maritalStatus: maritalStatusOptions.partner,
      children: childrenOptions.noChildren,
      childrenOrigin: childrenOriginOptions.currentRelationship
    },
    9: {
      maritalStatus: maritalStatusOptions.single,
      children: childrenOptions.hasChildren,
      childrenOrigin: childrenOriginOptions.currentRelationship
    },
    10: {
      maritalStatus: maritalStatusOptions.single,
      children: childrenOptions.noChildren,
      childrenOrigin: childrenOriginOptions.currentRelationship
    }
  };

  let newDropdownStatus = {
    maritalStatus:
      dropdownData[1][optionsFromScenarios[scenario].maritalStatus],
    children: dropdownData[2][optionsFromScenarios[scenario].children],
    childrenOrigin:
      dropdownData[3][optionsFromScenarios[scenario].childrenOrigin]
  };

  return newDropdownStatus;
};

export { calculateScenario, calculateDropdownFromScenario };
