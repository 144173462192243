import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

// Consts
import { buttonStyles } from '~/App/config/buttonStyles';

// Import shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import { IValidation } from '~/App/shared/components/Validation';
import { FetchBankAccountValues } from './FetchBankAccount';

const Wrapper = styled.div`
  text-align: center;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 1rem;
`;

type Props = {
  validation: IValidation;
  fetchBankAccount: FetchBankAccountValues;
};

export function FetchBankAccountsButton({
  validation,
  fetchBankAccount,
  ...rest
}: Props) {
  const validationKeys = useMemo(
    () => [
      'productOptions.customerContact.ssn',
      'mobileBankId.bank',
      'productOptions.customerContact.email'
    ],
    []
  );

  const isValid = useMemo(() => validation.areValid(validationKeys), [
    validation,
    validationKeys
  ]);
  const isLoading = useMemo(
    () =>
      fetchBankAccount.status === 'sending' ||
      fetchBankAccount.status === 'polling',
    [fetchBankAccount.status]
  );

  const handleClick = useCallback(
    (event) => {
      
      if (!isValid) {
        return validation.showSomeErrors(validationKeys);
      }

      fetchBankAccount.handlers.handleClick(event);
    },
    [fetchBankAccount.handlers, isValid, validation, validationKeys]
  );

  return (
    <Wrapper>
      <StyledButton
        isLoading={isLoading}
        isDisabled={isLoading}
        onClick={handleClick}
        buttonStyle={isValid ? buttonStyles.primary : buttonStyles.disabled}
        children="Hämta konto med BankID"
        {...rest}
      />
    </Wrapper>
  );
}
