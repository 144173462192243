import React, { useMemo } from 'react';
import styled from 'styled-components';

import Text from '~/App/shared/components/Fields/Text';
import TextArea from '~/App/shared/components/Fields/TextArea';
import Selector from '~/App/shared/components/Donation/components/Forms/components/Selector';

import { WufooState } from '../hooks/useWufooState';
import { IValidation } from '~/App/shared/components/Validation';
import { heading4 } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

const Heading = styled.p`
  ${heading4};
  margin-bottom: 0.5rem;
  a {
    text-decoration: underline;
  }
`;

const Preamble = styled.p`
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
`;

type WrapperProps = {
  cssSyntax?: string;
};

const Wrapper = styled.div<WrapperProps>`
  margin: 0 0.5rem 1.5rem 0;
  width: 100%;

  ${mq<WrapperProps>('>small')`
    width: ${({ cssSyntax = '' }) => {
      for (const command of cssSyntax.split(' ')) {
        if (command === 'col-12') return `calc(${(12 / 12) * 100}% - 0.5rem)`;
        if (command === 'col-10') return `calc(${(10 / 12) * 100}% - 0.5rem)`;
        if (command === 'col-8') return `calc(${(8 / 12) * 100}% - 0.5rem)`;
        if (command === 'col-6') return `calc(${(6 / 12) * 100}% - 0.5rem)`;
        if (command === 'col-4') return `calc(${(4 / 12) * 100}% - 0.5rem)`;
        if (command === 'col-3') return `calc(${(3 / 12) * 100}% - 0.5rem)`;
      }

      return '100%';
    }}
  `};
`;

type WufooChoise = {
  Label: string;
};

export type WufooField = {
  ID: string;
  Type: 'text' | 'radio' | 'textarea' | 'date' | 'email';
  Title: string;
  Instructions?: string;
  IsRequired?: '1' | '0';
  ClassNames?: string;
  DefaultVal?: string;
  Page?: string;
  Choices?: WufooChoise[];
  HasOtherField?: boolean;
};

type Props = {
  field: WufooField;
  validation: IValidation;
  state: WufooState;
};

const HIDDEN_FIELDS = ['Entry Id', 'Created By', 'Updated By'];

export function Field({
  field,
  validation,
  state: { values, handlers }
}: Props) {
  const input = useMemo(() => {
    if (field.Type === 'text' || field.Type === 'email') {
      return (
        <Text
          name={field.ID}
          type={field.Type}
          value={values[field.ID]}
          errors={validation.errors[field.ID]}
          isValid={validation.isValid(field.ID)}
          onChange={handlers.handleChange(field)}
          isInvalid={validation.isInvalid(field.ID)}
          instructions={field.Instructions}
          placeholder={field.DefaultVal}
          maxLength="100"
        />
      );
    }

    if (field.Type === 'textarea') {
      return (
        <TextArea
          name={field.ID}
          value={values[field.ID]}
          maxLength="2000"
          onChange={handlers.handleChange(field)}
          instructions={field.Instructions}
          placeholder={field.DefaultVal}
          errors={validation.errors[field.ID]}
          isValid={validation.isValid(field.ID)}
          isInvalid={validation.isInvalid(field.ID)}
        />
      );
    }

    if (field.Type === 'radio') {
      return (
        <Selector
          value={values[field.ID]}
          options={
            field.Choices?.map((x) => ({
              label: x.Label,
              value: x.Label
            })) || []
          }
          errors={validation.errors[field.ID]}
          isValid={validation.isValid(field.ID)}
          isInvalid={validation.isInvalid(field.ID)}
          setValue={handlers.handleSet(field)}
        />
      );
    }

    return null;
  }, [field, handlers, validation, values]);

  if (HIDDEN_FIELDS.includes(field.Title)) {
    return null;
  }

  if (!input) {
    return null;
  }

  const [heading, text] = field.Title.split('---');

  return (
    <Wrapper cssSyntax={field.ClassNames}>
      {heading && <Heading dangerouslySetInnerHTML={{ __html: heading }} />}
      {text && <Preamble dangerouslySetInnerHTML={{ __html: text }} />}
      {input}
    </Wrapper>
  );
}
