import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';
import { menu, close } from '~/App/helpers/icons';

const Wrapper = styled.button`
  display: flex;
  padding: 0;
  color: ${({ theme }) => theme.themeColors.primary};
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  border: none;
  transition: transform 0.35s ease;

  ${mq('≥mediumLarge')`
    display: none;
  `};

  &:focus {
    outline: 0 none;
  }
`;

type IconProps = {
  primaryVisible: boolean;
};

const Icon = styled.div<IconProps>`
  &:after {
    ${menu};
    top: 0;
    font-size: 1.5rem;

    ${({ primaryVisible }) =>
      primaryVisible &&
      css`
        ${close};
      `};
  }
`;

type TextProps = {
  primaryVisible: boolean;
};

const Text = styled.span<TextProps>`
  display: ${({ primaryVisible }) =>
    primaryVisible ? 'inline-block' : 'none'};
  font-size: 1rem;
  position: absolute;
  left: 2rem;
`;

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  primaryVisible: boolean;
};

export function MenuToggler({ onClick, primaryVisible }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <Icon primaryVisible={primaryVisible} />
      <Text primaryVisible={primaryVisible}>Stäng</Text>
    </Wrapper>
  );
}
