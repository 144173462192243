import React from 'react';

import { ICmsProduct, CmsProductTarget } from '~/types/ICmsProduct';
import { IPurchaseShow } from '~/types/IPurchaseShow';

import Company from './CmsProductThankYou.Company';
import Private from './CmsProductThankYou.Private';

type Props = {
  product: ICmsProduct;
  purchase: IPurchaseShow;
};

export function CmsProductThankYou({ product, purchase }: Props) {
  if (product?.target === CmsProductTarget.company) {
    return <Company product={product} purchase={purchase} />;
  }

  if (product?.target === CmsProductTarget.private) {
    return <Private product={product} purchase={purchase} />;
  }

  return null;
}
