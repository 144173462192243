import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import styled from 'styled-components';

// Helpers
import { collections } from '~/App/helpers/http';
import mq from '~/App/helpers/mq';

// Shared components
import { InputWrapper } from '~/App/shared/components/Fields/InputWrapper';
import TextArea from '~/App/shared/components/Fields/TextArea';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { AccountColumn } from '~/App/views/Account/shared/components/AccountColumn';
import { Redirect } from '~/App/shared/components/Redirect';
import {
  AccountHeading1,
  AccountHeading2
} from '~/App/views/Account/shared/components/AccountHeadings';
import { ICollection } from '~/types/ICollection';
import { useNavigate, useParams } from 'react-router-dom';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  > * {
    margin-top: 1rem;
  }

  ${mq('≥small')`
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-top: 4rem;

    > * {
      margin-top: 0;
      margin-left: 1rem;
    }
  `};
`;

type Props = {
  data: ICollection;
};

export function CollectionPause({ data }: Props) {
  const [pausedMessage, setPausedMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const params = useParams<'slug'>();
  const navigate = useNavigate();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setPausedMessage(event.target.value);
    },
    []
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      setLoading(true);

      try {
        await collections.pause({
          slug: `${params.slug}`,
          data: { pausedMessage }
        });

        setLoading(false);
        navigate('/min-sida/insamlingar');
      } catch (error) {
        console.log(error);

        setLoading(false);
      }
    },
    [isLoading, navigate, params.slug, pausedMessage]
  );

  return (
    <>
      {data.isPaused && <Redirect to="/min-sida/insamlingar" />}
      <AccountColumn>
        <form onSubmit={handleSubmit}>
          <AccountHeading1 children={`Avsluta "${data.name}"`} />
          <p>
            Om du avslutar din insamling kommer besökare inte längre kunna ge
            bidrag. Insamlingen kommer inte längre vara sökbar eller visas bland
            andra insamlingar men besökare som har en direktlänk kan fortfarande
            besöka sidan.
          </p>
          <AccountHeading2>Varför vill du avsluta insamlingen?</AccountHeading2>
          <InputWrapper>
            <TextArea
              name="pausedMessage"
              value={pausedMessage}
              onChange={handleChange}
              placeholder="Skriv en hälsning till dem som besöker insamligen"
              autoComplete="off"
            />
          </InputWrapper>
          <ButtonWrapper>
            <PrimaryButton
              buttonType="button"
              type="submit"
              buttonStyle="primary"
              isLoading={isLoading}
              children="Avsluta och stäng"
            />
            <PrimaryButton
              buttonType="link"
              buttonStyle="outlinePrimary"
              children="Avbryt"
              to={`/min-sida/insamlingar/${data.slug}`}
            />
          </ButtonWrapper>
        </form>
      </AccountColumn>
    </>
  );
}
