import React, { useCallback, MouseEvent, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';

import { linkUnderline } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';
import { LogoutIcon } from './components/LogoutIcon';
import { LoginIcon } from './components/LoginIcon';

import { useLogin } from '~/App/shared/hooks/use-login';
import { useLoginModal } from '~/App/shared/hooks/use-login-modal';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

type ButtonProps = {
  active?: boolean;
};

export const Button = styled.button<ButtonProps>`
  border: 0 none;
  background: none;
  cursor: pointer;
  line-height: 1;
  outline: 0;
  margin: 0 0.5rem 0 0;
  padding: 1rem 0.3rem 0.5rem 0.5rem;
  color: ${({ theme }) => theme.themeColors.primary};
  transition: opacity 0.2s ease-out;

  ${mq('≥mediumLarge')`
    display: initial;
    ${linkUnderline};
    font-size: 1.125rem;
    margin: 1.5rem auto 0 auto;
    padding: 1rem 0rem;

    &::after {
      height: 4px;
      background: ${({ theme }) => theme.themeColors.primary};
      margin-left: 0;
    }

    &:hover,
    &:focus,
    &:active {
      &::after {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  `};
`;

type Props = {
  display: 'text' | 'icon';
};

export function AuthenticationButton({ display }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthenticationContext();
  const loginModalHandler = useLoginModal();
  const login = useLogin();
  const handleLogin = useCallback(
    (event) => {
      event.preventDefault();

      if (loginModalHandler.showLoginModal) {
        return loginModalHandler.handlers.closeModal();
      }
      loginModalHandler.handlers.openModal({
        onSuccess: '/min-sida'
      });
    },
    [loginModalHandler.handlers, loginModalHandler.showLoginModal]
  );

  const handleLogout = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (location.pathname.startsWith('/min-sida')) {
        navigate('/');
      }

      login.handlers.handleLogout();
    },
    [navigate, location.pathname, login.handlers]
  );

  const children = useMemo(() => {
    if (display === 'icon') {
      return isAuthenticated ? <LogoutIcon /> : <LoginIcon />;
    }

    if (display === 'text') {
      return isAuthenticated ? 'Logga ut' : 'Logga in';
    }

    return null;
  }, [display, isAuthenticated]);

  return (
    <Button
      active={loginModalHandler.showLoginModal}
      onClick={isAuthenticated ? handleLogout : handleLogin}
      data-cy={isAuthenticated ? 'Logga ut' : 'Logga in'}
      children={children}
    />
  );
}
