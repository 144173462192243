import React from 'react';
import Masonry from 'react-masonry-component';
import { shuffle } from 'lodash';
import styled from 'styled-components';

// Helpers
import { breakpoints } from '~/App/config/mediaBreakpoints';
import mq from '~/App/helpers/mq';
import { calculateNewPosition } from './helpers/productGridHelper';

// Shared components
import Container from '~/App/shared/components/Container';
import Product from './components/Product';

const GridSizer = styled.div`
  width: ${100 / 3}%;

  ${mq('≥small')`
    width: ${100 / 6}%;
  `};
`;

class ProductGrid extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.calculateFullRows = this.calculateFullRows.bind(this);

    this.state = {
      selectedProduct: '',
      isSelected: false,
      products: [],
      unselectedProducts: [],
      gridSize: {
        rows: 0,
        columns: 0
      }
    };
  }

  componentDidMount() {
    const mediaQuery = window.matchMedia(`(min-width: ${breakpoints.small})`);
    mediaQuery.addListener(this.handleResize);

    const shuffledProducts = shuffle(this.props.info);
    this.setState(
      { products: shuffledProducts, unselectedProducts: shuffledProducts },
      triggerHandleResize(mediaQuery)
    );

    function triggerHandleResize(mediaQuery) {
      return function () {
        this.handleResize(mediaQuery);
      };
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  calculateFullRows(itemCount, columns) {
    return Math.floor(itemCount / columns);
  }

  calculateExtraRows(itemCount, columns) {
    const remainder = itemCount % columns;
    const itemsAddedWhenExpanded = 5;
    const total = remainder + itemsAddedWhenExpanded;
    const rowsToAdd = Math.ceil(total / columns);

    return rowsToAdd;
  }

  handleResize(mediaQueryList) {
    const itemCount = this.state.products.length;

    let columns = 1;
    if (mediaQueryList.matches) {
      columns = 6;
    } else {
      columns = 3;
    }

    const rows =
      this.calculateFullRows(itemCount, columns) +
      this.calculateExtraRows(itemCount, columns);

    const gridSize = {
      rows,
      columns
    };
    this.setState({ gridSize });
  }

  handleClick(productName) {
    if (productName === this.state.selectedProduct) {
      return null;
    }

    this.setState((previousState) => {
      let nextState = { ...previousState };
      nextState.selectedProduct = productName;
      nextState.isSelected = true;

      const newProducts = calculateNewPosition(
        previousState.products,
        previousState.unselectedProducts,
        previousState.gridSize,
        productName,
        previousState.selectedProduct
      );

      nextState.products = newProducts.products;
      nextState.unselectedProducts = newProducts.unselectedProducts;

      return nextState;
    });
  }

  handleClose() {
    this.setState((previousState) => {
      let nextState = { ...previousState };
      nextState.selectedProduct = '';
      nextState.isSelected = false;
      nextState.products = previousState.unselectedProducts;

      return nextState;
    });
  }

  render() {
    const {
      width = 'normal',
      padding: {
        top: paddingTop = 'large',
        bottom: paddingBottom = 'large'
      } = {}
    } = this.props;

    const { products } = this.state;

    return (
      <Container
        width={width}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
      >
        <Masonry
          elementType={'ul'}
          horizontalOrder={false}
          columnWidth=".grid-sizer"
          ref={function (c) {
            this.masonry = this.masonry || c.masonry;
          }.bind(this)}
        >
          <GridSizer className="grid-sizer" />
          {products &&
            products.map((product) => (
              <Product
                product={product}
                key={product.name}
                selected={this.state.selectedProduct === product.name}
                handleClick={this.handleClick}
                handleClose={this.handleClose}
              />
            ))}
        </Masonry>
      </Container>
    );
  }
}

export default ProductGrid;
