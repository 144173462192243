import { ICommerceOrder } from './ICommerceOrder';

export interface ICommercePayment {
  id?: string;
  status: PaymentStatus;
  action: PaymentAction;
  receiptUrl?: string;
  returnUrl: string;
  secret: string;
  order?: ICommerceOrder;
}

export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Failed = 'Failed'
}

export enum PaymentAction {
  None = 'None',
  Secret = 'Secret',
  ReturnUrl = 'ReturnUrl'
}
