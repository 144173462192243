import React from 'react';

// config
import { buttonStyles } from '~/App/config/buttonStyles';

// shared components
import { SubHeroText } from '../../SubHeroText';

import { PrimaryButton, ButtonWrapper } from '../../Styled';
import { ViewWrapper } from '../../ViewWrapper';

export function Researcher() {
  return (
    <ViewWrapper hero="researcher" footer>
      <SubHeroText isPrivate={false} />
      <ButtonWrapper>
        <PrimaryButton
          rel="noopener noreferrer"
          target="_blank"
          buttonType="link"
          to="https://ansokan.cancerfonden.se/sbs/forskning/grandid/user/login/selectidp/bankid.deviceChoice"
          children="Logga in hos Fenix"
        />
        <PrimaryButton
          rel="noopener noreferrer"
          target="_blank"
          buttonType="link"
          buttonStyle={buttonStyles.outlinePrimary}
          to="https://ansokan.cancerfonden.se/sbs/forskning/minsida/signup"
          children="Skapa konto hos Fenix"
        />
      </ButtonWrapper>
    </ViewWrapper>
  );
}
