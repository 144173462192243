import { useRef, useState, useCallback, useEffect } from 'react';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import useScroll from '~/App/shared/hooks/use-scroll';

type Props = {
  visible: boolean;
};

export function useShowHeader({ visible }: Props): boolean {
  const shoppingCart = useShoppingCart();
  const lastScrollPos = useRef(0);

  const [showHeader, setShowHeader] = useState(true);

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset;

    if (showHeader) {
      if (lastScrollPos.current < scrollTop - 20 && scrollTop > 120) {
        setShowHeader(false);
      }
    }

    if (!showHeader) {
      if (lastScrollPos.current > scrollTop + 20) {
        setShowHeader(true);
      }
    }

    lastScrollPos.current = visible ? lastScrollPos.current : scrollTop;
  }, [visible, showHeader]);

  useScroll(handleScroll, {
    debounceValue: 250
  });
  
  useEffect(() => {
  setShowHeader(true);
  }, [shoppingCart.items])
  
  return showHeader;
}
