import React from 'react';

// Shared components
import Link from '~/App/shared/components/Link';

// Fields
import Checkbox from './Checkbox';

export default function IntegrityPolicyCheckbox({
  values,
  validation,
  integrityAnchor = ''
}) {
  return (
    <Checkbox
      name="pul"
      checked={values.productOptions.pul}
      errors={validation.errors['productOptions.pul']}
      onChange={values.handlers.handleProductOptionsPul}
      isInvalid={validation.isInvalid('productOptions.pul')}
    >
      <span>
        Jag samtycker till att mina personuppgifter behandlas i enlighet med{' '}
        <Link
          to={`/om-oss/personlig-integritet-och-cookies${integrityAnchor}`}
          target="_blank"
        >
          Cancerfondens integritetspolicy
        </Link>
        .
      </span>
    </Checkbox>
  );
}
