import React, { useMemo } from 'react';
import styled from 'styled-components';

// Config
import { SwishType, swishTypes } from '~/App/config/swishTypes';

// Helpers
import mq from '~/App/helpers/mq';
import { heading3 } from '~/App/helpers/mixins';

// Shared components
import Container from '~/App/shared/components/Container';
import { PrimaryButton } from '~/App/shared/components/Elements';

import Link from '~/App/shared/components/Link';
import { rotate360 } from '~/App/helpers/animations';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.accentedBackgroundColor};
`;

const SwishContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 30rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
`;

const SwishPhone = styled.img`
  display: block;
  width: 10.125rem;
  height: 10rem;
  margin: 1.125rem auto 2rem auto;
`;

type SwishInstructionsProps = {
  size?: 'large' | 'small';
};

const SwishInstructions = styled.h3<SwishInstructionsProps>`
  ${heading3};
  max-width: ${({ size }) => (size === 'large' ? '20rem' : '16rem')};
  margin: 0 auto;
  text-align: center;

  ${mq<SwishInstructionsProps>('≥small')`
    max-width: ${({ size }) => (size === 'large' ? '22rem' : '18rem')};
  `};
`;

const SwishError = styled.p`
  max-width: 15rem;
  margin: 0.5rem auto 2rem auto;
  text-align: center;

  ${mq('≥small')`
    max-width: 17rem;
  `};
`;

const SwishLink = styled(Link)`
  white-space: nowrap;
`;

const SwishButton = styled(PrimaryButton)`
  margin: 0 auto 0.75rem auto;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  margin: 2rem auto 0.75rem auto;
`;

const SwishSpinner = styled.div`
  width: 100%;
  height: 100%;
  animation: ${rotate360} 0.3s linear infinite;
  border-top: 2px solid ${({ theme }) => theme.swishSpinner.primaryColor};
  border-right: 2px solid ${({ theme }) => theme.swishSpinner.secondaryColor};
  border-bottom: 2px solid ${({ theme }) => theme.swishSpinner.secondaryColor};
  border-left: 2px solid ${({ theme }) => theme.swishSpinner.primaryColor};
  border-radius: 100%;
  content: ' ';
  z-index: 1;
`;

type Props = {
  failed: boolean;
  originPath?: string;
  type: SwishType;
};

export function Instructions({ failed, originPath, type }: Props) {
  const children = useMemo(() => {
    if (failed) {
      return (
        <SwishContainer>
          <SwishPhone src="https://res.cloudinary.com/cancerfonden/image/upload/v1573848109/assets/phone-swish-error.png" />
          <SwishInstructions size="large">
            Din betalning blev inte godkänd
          </SwishInstructions>
          <SwishError>
            Kontakta givarservice på{' '}
            <SwishLink to="tel:+46101991010">010-199 10 10</SwishLink> eller
            försök igen.
          </SwishError>
          <SwishButton
            buttonType="link"
            buttonStyle="outlinePrimary"
            children="Försök igen"
            to={originPath}
          />
        </SwishContainer>
      );
    }

    return (
      <SwishContainer>
        <SwishPhone src="https://res.cloudinary.com/cancerfonden/image/upload/v1573846402/assets/phone-swish.png" />
        {type === swishTypes.ecommerce && (
          <SwishInstructions size="small">
            Starta Swish-appen på din mobil eller surfplatta
          </SwishInstructions>
        )}
        {type === swishTypes.mcommerce && (
          <SwishInstructions size="large">
            Väntar på bekräftelse från Swish
          </SwishInstructions>
        )}
        <SpinnerWrapper>
          <SwishSpinner />
        </SpinnerWrapper>
      </SwishContainer>
    );
  }, [failed, originPath, type]);

  return (
    <Wrapper>
      <Container
        width="limited"
        paddingLeft="small"
        paddingRight="small"
        paddingTop="small"
        paddingBottom="small"
        children={children}
      />
    </Wrapper>
  );
}
