import React from 'react';

// Shared components
import { BasicDonationForm } from './';
import { CompanyDonationSummary } from './components/Summaries';

export const CompanyDonationForm = (props) => {
  const { values, formContent } = props;

  const SummaryComponent = (innerProps) => {
    return (
      <CompanyDonationSummary
        part={values.partOptions.currentPart}
        area={innerProps.area}
        donationImage={formContent.donationImage}
        price={values.productOptions.product.price}
        infoText={formContent.infoText}
        heading1={formContent.sideHeading1}
        heading2={formContent.sideHeading2}
      />
    );
  };

  return <BasicDonationForm summaryComponent={SummaryComponent} {...props} />;
};
