import { find } from 'lodash';

import { IPaymentMethod } from '~/types/IPaymentMethod';
import { IPaymentMethodGroup } from './types';

export function isActive(
  value: number | number[],
  selectedPaymentMethodId?: number
) {
  if (!selectedPaymentMethodId) {
    return false;
  }

  if (typeof value === 'number') {
    return selectedPaymentMethodId === value;
  }

  return value.includes(selectedPaymentMethodId);
}

type GroupPaymentMethodsResult = (IPaymentMethod | IPaymentMethodGroup)[];

export function isPaymentMethodGroup(
  value: IPaymentMethod | IPaymentMethodGroup
): value is IPaymentMethodGroup {
  return (value as IPaymentMethodGroup)?.paymentMethods !== undefined;
}

export function groupPaymentMethods(
  allPaymentMethods: IPaymentMethod[]
): GroupPaymentMethodsResult {
  const result: GroupPaymentMethodsResult = [];

  for (const method of allPaymentMethods) {
    if (!method.group) {
      result.push(method);
      continue;
    }

    const group = find(result, { name: method.group });

    if (!group) {
      result.push({
        id: method.id,
        name: method.group,
        paymentMethods: [method]
      });
      continue;
    }

    if (!isPaymentMethodGroup(group)) {
      continue;
    }

    group.paymentMethods.push(method);
  }

  return result;
}
