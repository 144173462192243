import { collections } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export async function loadData({ params }: LoadDataProps<'slug'>) {
  const { data } = await collections.show({ slug: `${params.slug}` });

  return {
    data
  };
}
