import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactNode
} from 'react';

import { omit } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

// Config and helpers
import { loginViews, LoginView } from '~/App/components/Header/constants';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';

import {
  LoginModalContext,
  LoginModalContextValue,
  OpenModalArguments,
  OpenModalSuccessCallback
} from './LoginModalContext';
import { parse } from '~/lib/query-string/parse';
import { stringify } from '~/lib/query-string';

import { useLogin } from '~/App/shared/hooks/use-login';
import { useQuery } from '../../shared/hooks/use-query';
import { useAppContext } from '~/App/contexts/App';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { useScrollLock } from '../ScrollLock';

type Props = {
  children: ReactNode;
};

const LOGIN_QUERY_PARAMS = [
  'subscriptionToken',
  'verifyEmailToken',
  'activationToken',
  'ownerJoinCode',
  'logga-in',
  'slug'
];

type Query = {
  subscriptionToken?: string;
  verifyEmailToken?: string;
  activationToken?: string;
  ownerJoinCode?: string;
  'logga-in'?: string;
  slug?: string;
};

export function LoginModalContextProvider({ children }: Props) {
  const {
    subscriptionToken,
    verifyEmailToken,
    activationToken,
    ownerJoinCode,
    slug,
    ...query
  } = useQuery<Query>();

  const login = useLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const scrollLock = useScrollLock();

  const userAgent = useAppContext((x) => x.userAgent);
  const { user, status, isAnonymous } = useAuthenticationContext();

  const [innerHeight, setInnerHeight] = useState<number | undefined>();
  const [mobileInitView, setMobileInitView] = useState(
    isMobileBrowser(userAgent)
  );

  const hasMultipleEmails = useMemo(() => {
    if (!user?.emails) return false;
    if (!user?.email) return false;

    for (const email of user.emails) {
      if (email !== user?.email) return true;
    }

    return false;
  }, [user]);

  const missingEmail = useMemo(() => {
    if (isAnonymous) return false;

    return (
      !user?.email ||
      !user?.emailStatus ||
      user?.emailStatus === 'failed' ||
      user?.emailStatus === 'unknown'
    );
  }, [isAnonymous, user?.email, user?.emailStatus]);

  const shouldShowLoginModal = useMemo(() => {
    if (activationToken) return true;
    if (ownerJoinCode && slug) return true;
    if (subscriptionToken) return true;
    if (query['logga-in'] && isAnonymous) return true;
    if (missingEmail) return true;
    if (hasMultipleEmails) return true;
    if (verifyEmailToken) return true;

    return false;
  }, [
    activationToken,
    ownerJoinCode,
    slug,
    subscriptionToken,
    query,
    isAnonymous,
    missingEmail,
    hasMultipleEmails,
    verifyEmailToken
  ]);

  const shouldLoginView = useMemo<LoginView>(() => {
    if (verifyEmailToken) return loginViews.verifyEmail;
    if (activationToken) return loginViews.confirmEmail;
    if (ownerJoinCode && slug) return loginViews.joinCollection;
    if (subscriptionToken) return loginViews.newsletter;
    if (missingEmail) return loginViews.email;
    if (hasMultipleEmails) return loginViews.chooseEmail;

    return loginViews.private;
  }, [
    verifyEmailToken,
    activationToken,
    ownerJoinCode,
    slug,
    subscriptionToken,
    missingEmail,
    hasMultipleEmails
  ]);

  const shouldOnSuccess = useMemo(() => {
    const value = query?.['logga-in'];

    const isPath = typeof value === 'string' && value?.startsWith('/');
    const path = isPath ? value : location.pathname;

    if (isPath) {
      return `${path}`;
    }

    return undefined;
  }, [location.pathname, query]);

  const [onSuccess, setOnSuccess] = useState<OpenModalSuccessCallback>(
    shouldOnSuccess
  );

  const [showLoginModal, setShowLoginModal] = useState<boolean>(
    shouldShowLoginModal
  );

  const [loginView, setLoginView] = useState<LoginView>(shouldLoginView);
  const [qrMode, setQrMode] = useState(false);

  const toggleQrMode = useCallback(
    (suggestedValue?: boolean) => {
      const nextValue = suggestedValue ?? !qrMode;

      if (!nextValue && status !== 'idle') {
        login.handlers.handleLoginAbort();
      }
      setQrMode(nextValue);
    },
    [qrMode, status, login.handlers]
  );

  const openModal = useCallback((args: OpenModalArguments = {}) => {
    setOnSuccess(() => args.onSuccess);
    setLoginView(args.view ?? 'private');
    setShowLoginModal(true);
    setInnerHeight(window.innerHeight);
  }, []);

  const closeModal = useCallback(() => {
    if (login.isPolling) {
      return;
    }

    setShowLoginModal(false);
    setOnSuccess(undefined);
    toggleQrMode(false);

    const query = parse(location.search);
    const cleaned = omit(query, LOGIN_QUERY_PARAMS);
    const queryString = stringify(cleaned);

    if (queryString !== location.search) {
      navigate(`${location.pathname}?${queryString}`, {
        replace: true
      });
    }
  }, [
    location.pathname,
    location.search,
    login.isPolling,
    navigate,
    toggleQrMode
  ]);

  const toggleLogin = useCallback(
    (newShowLoginModal?: boolean) => {
      const newState =
        newShowLoginModal !== undefined &&
        typeof newShowLoginModal === 'boolean'
          ? newShowLoginModal
          : !showLoginModal;

      if (newState) {
        return openModal();
      }

      closeModal();
    },
    [closeModal, openModal, showLoginModal]
  );
  const cancelLogin = useCallback(() => {
    login.handlers.handleLoginAbort();
    toggleLogin(false);
    setQrMode(false);
  }, [login, toggleLogin, setQrMode]);

  useEffect(() => (showLoginModal ? scrollLock.lock() : undefined), [
    scrollLock,
    showLoginModal
  ]);

  useEffect(() => {
    if (loginView === loginViews.researcher && status !== 'idle') {
      login.handlers.handleLoginAbort();
    }
  }, [loginView, login, status]);

  useEffect(() => {
    setShowLoginModal(shouldShowLoginModal);
    setLoginView(shouldLoginView);

    if (shouldOnSuccess) {
      setOnSuccess(shouldOnSuccess);
    }
  }, [shouldLoginView, shouldOnSuccess, shouldShowLoginModal]);

  useEffect(() => {
    if (isAnonymous) {
      return; // not authenticated
    }

    if (shouldShowLoginModal) {
      return; // should remain open
    }

    if (!showLoginModal) {
      return; // already closed
    }

    closeModal();

    if (typeof onSuccess === 'function') {
      return onSuccess({
        success: true,
        shouldRedirect: true,
        user: user
      });
    }

    if (typeof onSuccess === 'string') {
      return navigate(onSuccess);
    }
  }, [
    closeModal,
    navigate,
    onSuccess,
    shouldShowLoginModal,
    shouldLoginView,
    showLoginModal,
    isAnonymous,
    user
  ]);

  useEffect(() => {
    if (showLoginModal) {
      return;
    }

    if (!login.isPolling) {
      return;
    }

    setShowLoginModal(true);
  }, [login.isPolling, showLoginModal]);

  const state = useMemo<LoginModalContextValue>(
    () => ({
      showLoginModal: showLoginModal,
      loginView,
      innerHeight,
      qrMode,
      mobileInitView,
      handlers: {
        setShowLoginModal,
        setLoginView,
        setInnerHeight,
        setMobileInitView,
        toggleLogin,
        toggleQrMode,
        openModal,
        closeModal,
        cancelLogin
      }
    }),
    [
      showLoginModal,
      loginView,
      innerHeight,
      qrMode,
      mobileInitView,
      toggleLogin,
      toggleQrMode,
      openModal,
      closeModal,
      cancelLogin
    ]
  );

  return <LoginModalContext.Provider value={state} children={children} />;
}
