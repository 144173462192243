import React, { useMemo, useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { ICmsProduct } from '~/types/ICmsProduct';
import { mq } from '~/lib/mq';

import * as TwoColumn from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import Validation from '~/App/shared/components/Validation';

import LeftColumn from './Form.LeftColumn';
import RightColumn from './Form.RightColumn';

import { buildState } from '../state';
import { buildSchema } from '../schema';
import { buildFormContent } from '../formContent';

import * as MultiPartForm from '~/App/shared/components/MultiPartForm';
import { Submit } from '~/App/shared/components/Donation/components/Submits';

import PaymentRedirect from '~/App/shared/components/Donation/components/PaymentRedirect';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import {
  CertificateState,
  State
} from '~/App/shared/components/Donation/components/States';

const Wrapper = styled(TwoColumn.Wrapper)`
  padding: 2rem 0;

  ${mq('<medium')`
    display: flex;
    flex-direction: column;
  `};
`;

type Props = {
  product: ICmsProduct;
};

export default function ProductForm({ product }: Props) {
  const location = useLocation();
  const state = useMemo(() => buildState(product, location), [
    product,
    location
  ]);
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);

  const formContent = useMemo(() => buildFormContent(product), [product]);
  const schema = useMemo(buildSchema, []);
  const togglePreviewModal = useCallback(() => {
    setPreviewModalOpen(!previewModalOpen);
  }, [previewModalOpen]);

  return (
    <BlockBackground backgroundColor="primary">
      <State values={state} formContent={formContent}>
        {(values) => (
          <CertificateState values={values}>
            {(certificateValues) => (
              <Validation schema={schema} values={certificateValues}>
                {(validation) => (
                  <MultiPartForm.State
                    validation={validation}
                    values={certificateValues}
                  >
                    {(multiPartValues) => (
                      <Submit values={multiPartValues} validation={validation}>
                        {(submit) => (
                          <>
                            <PaymentRedirect
                              submit={submit}
                              redirectPath={formContent.redirectPath}
                            />
                            <Wrapper>
                              <LeftColumn
                                product={product}
                                values={multiPartValues}
                                validation={validation}
                                submit={submit}
                                previewModalOpen={previewModalOpen}
                                togglePreviewModal={togglePreviewModal}
                              />
                              <RightColumn
                                product={product}
                                values={multiPartValues}
                                togglePreviewModal={togglePreviewModal}
                              />
                            </Wrapper>
                          </>
                        )}
                      </Submit>
                    )}
                  </MultiPartForm.State>
                )}
              </Validation>
            )}
          </CertificateState>
        )}
      </State>
    </BlockBackground>
  );
}
