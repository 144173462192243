import React, { useMemo, useState, ReactNode } from 'react';
import { AppContext, AppStatus, PageContent } from './AppContext';

type Props = {
  value: {
    status: AppStatus;
    pageContent: PageContent;
    stickyNavigation: boolean;
    userAgent: string | null;
    cookies: Record<string, string>;
  };
  children: ReactNode;
};

export function AppProvider({ value, children }: Props) {
  const [status, setStatus] = useState<AppStatus>(value.status || 'idle');

  const [stickyNavigation, setStickyNavigation] = useState(
    value.stickyNavigation
  );

  const [pageContent, setPageContent] = useState<PageContent>(
    value.pageContent || {}
  );

  return useMemo(
    () => (
      <AppContext.Provider
        value={{
          status,
          pageContent,
          stickyNavigation,
          cookies: value.cookies,
          userAgent:
            typeof window === 'undefined'
              ? value.userAgent
              : navigator.userAgent,

          setStatus,
          setPageContent,
          setStickyNavigation
        }}
        children={children}
      />
    ),
    [
      children,
      pageContent,
      status,
      stickyNavigation,
      value.cookies,
      value.userAgent
    ]
  );
}
