import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { primaryButton } from '~/App/helpers/mixins';
import { largeShareButton } from './LargeShareButtons.styled';
import { link } from '~/App/helpers/icons';
import mq from '~/App/helpers/mq';

const Button = styled.button`
  ${primaryButton};
  text-align: center;
  &:before {
    ${link};
    font-size: 1rem;
    font-weight: 700;
    position: relative;
    padding-right: 0.5rem;
    transform: translate(-50%, -50%);
  }

  text-align: center;
`;

const ButtonWrapper = styled.div`
  position: relative;
  ${mq('≥small')`
  display: inline-block;
  `};
  ${largeShareButton};
  padding: 0;
`;

type NotificationProps = {
  show: boolean;
};

const Notification = styled.div<NotificationProps>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 50%;
  transform: ${({ show }) =>
    show ? 'translate(-50%, 0.25rem)' : 'translate(-50%, 0.75rem)'};
  opacity: ${({ show }) => (show ? '0' : '1')};
  right: 0;
  background: ${({ theme }) => theme.colors.darkCharcoal};
  color: ${({ theme }) => theme.colors.white};
  color: white;
  padding: 0.25rem;
  width: 7rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  text-align: center;
  transition: ${({ show }) =>
    show
      ? 'transform 0.3s cubic-bezier(0.1,0.6,0.3,1.15), opacity 0.4s ease-out 1.5s'
      : 'none'};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: ${({ theme }) => theme.colors.darkCharcoal};
    border-bottom: 0;
    margin-left: -8px;
    margin-bottom: -8px;
  }
`;

type Props = {
  to: string;
};

export function CopyLink({ to }: Props) {
  const [showNotification, setShowNotification] = useState(false);

  const notificationTimer = useRef<null | ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    return () => {
      if (!notificationTimer.current) return;
      clearTimeout(notificationTimer.current);
    };
  }, []);

  const copyUrlToClipboard = useCallback(() => {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', to);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    setShowNotification(true);

    notificationTimer.current = setTimeout(() => {
      setShowNotification(false);
    }, 1800);
  }, [to, setShowNotification]);

  return (
    <ButtonWrapper>
      <Button onClick={copyUrlToClipboard} children={'Kopiera länk'} />{' '}
      <Notification show={showNotification}>Länk kopierad!</Notification>
    </ButtonWrapper>
  );
}
