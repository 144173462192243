import React from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';

// Shared components
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const Figure = styled.div`
  margin: 0 0 1rem 0;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 100%;
`;

const Caption = styled.div`
  margin: 0;
  padding: 0.5rem 0 0;
  font-size: 0.75rem;
  line-height: 1.583333333;
  font-style: italic;
  color: ${(props) => props.theme.colors.charcoal};
`;

const getImageWidth = (width) => {
  if (width === 'slim') {
    return 448;
  }

  if (width === 'tight') {
    return 560;
  }

  if (width === 'narrow') {
    return 708;
  }

  if (width === 'limited') {
    return 888;
  }

  if (width === 'normal') {
    return 1168;
  }

  return width;
};

const calculateSize = ({ size, width, retina = false }) => {
  const imageWidth = getImageWidth(width);
  const maxWidth = retina ? imageWidth * 2 : imageWidth;

  const [sizeWidth, sizeHeight] = size.split('/');
  const sizeRatio = Number(sizeHeight) / Number(sizeWidth);

  return {
    crop: 'fill',
    width: maxWidth,
    height: Math.round(maxWidth * sizeRatio)
  };
};

const getSourceSet = ({ url, size, width, gravity }) => {
  const retinaSize = calculateSize({ size: size, width: width, retina: true });
  const regularSize = calculateSize({ size: size, width: width });

  const retinaOptions = gravity ? { ...retinaSize, gravity } : retinaSize;
  const regularOptions = gravity ? { ...regularSize, gravity } : regularSize;

  return [
    `${mediaHelper.getUrl(url, retinaOptions, 'jpg')} 2x`,
    `${mediaHelper.getUrl(url, regularOptions, 'jpg')} 1x`
  ].join(', ');
};

export default class BlockImage extends React.PureComponent {
  render() {
    const {
      width = '',
      large = {},
      small = {},
      padding = {},
      caption = '',
      description = '',
      gravity,
      backgroundColor
    } = this.props;

    const largeUrl =
      large.source && large.source.secure_url ? large.source.secure_url : '';

    const smallUrl =
      small.source && small.source.secure_url
        ? small.source.secure_url
        : largeUrl;

    const largeSourceSet = getSourceSet({
      url: largeUrl,
      size: large.size,
      width: width,
      gravity: gravity
    });

    const smallSourceSet = getSourceSet({
      url: smallUrl,
      size: small.size,
      width: 375,
      gravity: gravity
    });

    return (
      <BlockBackground backgroundColor={backgroundColor}>
        <Container
          width={width}
          paddingTop={padding.top}
          paddingLeft="small"
          paddingRight="small"
          paddingBottom={padding.bottom}
        >
          <Figure>
            {smallUrl && largeUrl && (
              <picture>
                <source media="(min-width: 768px)" srcSet={largeSourceSet} />
                <source srcSet={smallSourceSet} />
                <Image src={largeUrl} alt={description} />
              </picture>
            )}
            {caption && <Caption>{caption}</Caption>}
          </Figure>
        </Container>
      </BlockBackground>
    );
  }
}
