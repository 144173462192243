import React, { useCallback } from 'react';

import { paymentMethods } from '~/App/config/paymentMethods';
import { IValidation } from '~/App/shared/components/Validation';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '../../../../States';
import {
  IAutoGiroBankIdPaymentMethod,
  IKlarnaPaymentMethod,
  IPaymentMethod,
  ISwishPaymentMethod
} from '~/types/IPaymentMethod';
import { SwishFields } from '../../../../PaymentMethods/Swish';
import { PaymentSlipFields } from '../../../../PaymentMethods/PaymentSlip';
import { KlarnaPaymentsFields } from '../../../../PaymentMethods/KlarnaPayments';
import { AutoGiroBankIdFields } from '../../../../PaymentMethods/AutoGiro';
import { AutoGiroSlipFields } from '../../../../PaymentMethods/AutoGiro';
import { FetchBankAccountValues } from '../../FetchBankAccount';
import { FavourableInvoiceFields } from '../../../../PaymentMethods/Invoice';

type Props = {
  method: IPaymentMethod;
  validation: IValidation;
  fetchBankAccount: FetchBankAccountValues;
  values: ContactCustomerValues & PaymentValues & ProductValues;
};

export function PaymentMethod({
  method,
  values,
  validation,
  fetchBankAccount
}: Props) {
  const isActive = useCallback(
    <T extends IPaymentMethod>(value: IPaymentMethod, id: number): value is T =>
      values.paymentMethod?.id === id,
    [values.paymentMethod?.id]
  );

  return (
    <>
      {isActive<ISwishPaymentMethod>(method, paymentMethods.swish) && (
        <SwishFields
          validation={validation}
          values={values}
          contactField={method.showContactFields}
          taxReductionInfo={method.showTaxReductionText}
          manualProps={method.manualProps}
        />
      )}

      {isActive(method, paymentMethods.paymentSlip) && (
        <PaymentSlipFields validation={validation} values={values} email ssn />
      )}

      {isActive<IKlarnaPaymentMethod>(method, paymentMethods.klarnaPayments) && (
        <KlarnaPaymentsFields values={values} isGiftShop={method.isGiftShop} />
      )}

      {isActive<IAutoGiroBankIdPaymentMethod>(
        method,
        paymentMethods.autoGiroBankId
      ) && (
        <AutoGiroBankIdFields
          values={values}
          validation={validation}
          fetchBankAccount={fetchBankAccount}
          showIntegrityText={true}
          showEmailField={method.showEmailField}
          showSsnField={method.showSsnField}
        />
      )}

      {isActive(method, paymentMethods.autoGiroSlip) && (
        <AutoGiroSlipFields
          values={values}
          validation={validation}
          contactFieldsType="tabs"
        />
      )}
      {isActive(method, paymentMethods.invoice) && (
        <FavourableInvoiceFields values={values} validation={validation} />
      )}
    </>
  );
}
