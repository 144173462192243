import React, { useMemo } from 'react';
import styled from 'styled-components';

import { IQuestionScreen } from '../../../../SelfTest/types';
import { Feedback } from './Feedback';
import { Card } from './Card';
import { IAnswer } from '~/types/IQuestionSession';

const Text = styled.p``;

type Props = {
  screen: IQuestionScreen;
  value: IAnswer;
};

export function QuestionResultScreen({ screen, value }: Props) {
  const contentMarkup = useMemo(() => <Text children={screen.feedback} />, [
    screen.feedback
  ]);
  const moreMarkup = useMemo(() => <Feedback screen={screen} />, [screen]);

  return (
    <Card
      title={screen.questionHeading}
      more={moreMarkup}
      content={contentMarkup}
      value={value}
    />
  );
}
