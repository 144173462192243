import { merge, find } from 'lodash';
import { parse } from '~/lib/query-string';

import { gtmDetails } from '~/App/config/gtmDetails';

export const buildState = ({
  defaultState = {},
  data: formContent = {},
  location = {}
}) => {
  const query = parse(location.search);
  const packageSlugFromQuery = query.paket;
  const querySlugExists = !!query.paket;
  const packageFromQuery = find(formContent.packages, {
    slug: packageSlugFromQuery
  });

  const defaultPackage = querySlugExists
    ? packageFromQuery
    : formContent.defaultPackage;

  const PRODUCT_ID = defaultPackage.productId;

  return merge(
    {},
    {
      product: { id: PRODUCT_ID },
      productOptions: {
        product: {
          price: defaultPackage.price,
          label: defaultPackage.name
        },
        campaignActivityId: defaultPackage.campaignActivityId,
        pul: true
      },
      paymentMethod: formContent.defaultPaymentMethod,
      customerContactType: formContent.defaultCustomerContactType,
      partOptions: {
        parts: 3,
        currentPart: querySlugExists ? 2 : 1,
        partStarted: {
          1: true,
          2: querySlugExists
        },
        partValid: querySlugExists ? { 1: true } : {},
        validationKeys: {
          1: [],
          2: [
            'productOptions.customerContact.company',
            'productOptions.customerContact.cid',
            'productOptions.customerContact.addressStreet',
            'productOptions.customerContact.addressZip',
            'productOptions.customerContact.addressCity',
            'productOptions.customerContact.phoneNumber',
            'productOptions.customerContact.email'
          ],
          3: ['stripeCard', 'swishPhoneNumber']
        }
      },
      gtm: gtmDetails[PRODUCT_ID]
    },
    defaultState
  );
};
