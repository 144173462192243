import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useShoppingCart } from '~/App/contexts/ShoppingCart';

import { card, link } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

const Card = styled.div`
  ${card};
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.colors.lightRed};
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.errorRed};
  margin-bottom: 0.75rem;
  font-size: 1rem;

  ${mq('>small')`
    font-size: 1.125rem;
  `};
`;

const Link = styled.a`
  ${link};
  text-decoration: underline;
  font-size: 1rem;

  ${mq('>small')`
    font-size: 1.125rem;
  `};
`;

export function OutOfStock() {
  const navigate = useNavigate();
  const shoppingCart = useShoppingCart();

  const handleClick = useCallback(() => {
    navigate('/gavoshop/privat');
    shoppingCart.open();
  }, [navigate, shoppingCart]);

  return (
    <Card>
      <Text>
        En eller flera produkter i din varukorg går tyvärr inte att köpa. Gå
        till varukorgen och ta bort produkten/erna innan du genomför ditt köp.
      </Text>
      <Link onClick={handleClick}>Till varukorgen</Link>
    </Card>
  );
}
