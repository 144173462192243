import styled, { css } from 'styled-components';
import {
  GeneralStyles as InputGeneralStyles,
  EmptyStyles,
  ValidStyles,
  InvalidStyles,
  DisabledStyles,
  AutofillStyles
} from './mixins';

type GeneralStylesProps = {
  isEmpty?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
};

export const GeneralStyles = css<GeneralStylesProps>`
  ${InputGeneralStyles};

  ${({ isEmpty }) => isEmpty && EmptyStyles};
  ${({ isValid }) => isValid && ValidStyles};
  ${({ isInvalid }) => isInvalid && InvalidStyles};
  ${({ isDisabled }) => isDisabled && DisabledStyles};

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border-color: ${({ theme }) => theme.colors.errorRed};
      background: ${({ theme }) => theme.colors.lightRed};
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      border-color: ${({ theme }) => theme.colors.silver};
      color: ${({ theme }) => theme.colors.gray};
      background: ${({ theme }) => theme.colors.grayAlabaster};
    `};

  &:focus,
  &:-webkit-autofill {
    ${AutofillStyles};
  }

  &:focus {
    border-color: ${({ theme, isValid }) =>
      isValid ? theme.colors.apple : theme.colors.dust};
    outline: 0;
  }
`;

type InputProps = {
  hasIcon?: boolean;
  hasSuffix?: boolean;
};

export const Input = styled.input<InputProps>`
  ${GeneralStyles};
  ${(props) => props.hasIcon && 'padding-left: 48px'};
  ${(props) => props.hasSuffix && 'padding-right: 35px'};
`;

type TextAreaProps = {
  small?: boolean;
  medium?: boolean;
};

export const TextArea = styled.textarea<TextAreaProps>`
  ${GeneralStyles};

  resize: none;
  min-height: 240px;
  white-space: pre-wrap !important;

  ${({ small }) =>
    small &&
    css`
      min-height: 100px;
    `};

  ${({ medium }) =>
    medium &&
    css`
      min-height: 160px;
    `};
`;
