import { DefaultTheme } from 'styled-components';

import colors from './colors';

export default {
  themeColors: {
    accentedBackgroundColor: colors.smoke,
    primaryBackgroundColor: colors.amour,
    fadedPrimary: colors.white
  },
  packageCard: {
    alternateBackgroundColor: colors.amour
  },
  collectionItem: {
    statusColor: colors.pink,
    progressBarColor: colors.chantilly,
    contentBackgroundColor: colors.wispPinkLight
  },
  exerciseApp: {
    primaryThemeColor: colors.amour,
    secondaryThemeColor: colors.azalea,
    thirdThemeColor: colors.carissma,
    imageBackgroundColor: colors.pastelPink
  }
} as DefaultTheme;
