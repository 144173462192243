import React, { ComponentProps, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import BlockArea from '~/App/shared/components/BlockArea';
import { Modal } from '~/App/shared/components/Elements';

import { contentApi } from '~/App/helpers/env';
import { fetch } from '~/App/helpers/http';

const Padding = styled.div`
  padding: 1.5rem 0 0rem;
`;

type Props = {
  closeModal: ComponentProps<typeof Modal>['closeModal'];
};

export function TaxReductionBlockModal({ closeModal }: Props) {
  const [block, setBlock] = useState<unknown | null>(null);

  const fetchBlock = useCallback(async () => {
    const url = contentApi(`/v1/blocks?id=5db842fd08a9f60004c15f36`);
    const response = await fetch({ url });
    const data = response.data?.block;

    if (data)
      setBlock({
        ...data,
        padding: {
          bottom: 'none',
          top: 'none'
        }
      });
  }, []);

  useEffect(() => {
    fetchBlock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!block) {
    return null;
  }

  return (
    <Modal closeModal={closeModal} closeButton>
      <Padding>
        <BlockArea blocks={[block]} />
      </Padding>
    </Modal>
  );
}
