import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState
} from 'react';
import { useLocation } from 'react-router';

// Helpers
import { isSubMenuActive } from '~/App/helpers/sub-menu-active';
import { breakpoints } from '~/App/config/mediaBreakpoints';
import {
  scrollAnimation,
  slideElementDown,
  slideElementUp
} from '~/App/helpers/animations';

import {
  getOuterHeightWithMargin,
  getOffset
} from '~/App/helpers/units-helper';

// Shared components
import {
  Wrapper,
  HeaderWrapper,
  Header,
  ItemList,
  Item,
  ItemLink
} from './components';

import useScroll from '../../hooks/use-scroll';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import { useMatchMedia } from '../../hooks/use-match-media';
import { useAppContext } from '~/App/contexts/App';

export function TableOfContents({ tableOfContents }) {
  const userAgent = useAppContext((x) => x.userAgent);
  const location = useLocation();
  const isLargeScreen = useMatchMedia(
    `(min-width: ${breakpoints.large})`,
    !isMobileBrowser(userAgent)
  );

  const [isTouched, setTouched] = useState(false);
  const [isExpanded, setExpanded] = useState(isLargeScreen);
  const [isSticky, setSticky] = useState(false);

  const itemListRef = useRef();
  const wrapperRef = useRef();
  const headerWrapperRef = useRef();

  const subMenuActive = useMemo(() => isSubMenuActive(location.pathname), [
    location.pathname
  ]);

  useEffect(() => {
    if (!itemListRef.current) {
      return;
    }

    if (typeof window !== 'undefined') {
      document.querySelector('html').style.overflow =
        isExpanded && !isLargeScreen ? 'hidden' : 'auto';
    }

    isExpanded
      ? slideElementDown(itemListRef.current, 400)
      : slideElementUp(itemListRef.current, 400);
  }, [isExpanded, isLargeScreen]);

  const toggleExpanded = useCallback(() => {
    setTouched(true);
    setExpanded((x) => !x);
  }, []);

  const handleMenuClick = useCallback(
    (id) => (event) => {
      event.preventDefault();

      const $element = document.getElementById(id.slice(1));
      const $header = document.getElementById('headerContainer');

      const offsetTop = $element ? getOffset($element).top : 0;
      const scrollOffset = $header ? getOuterHeightWithMargin($header) : 0;

      const finalScrollPosition = offsetTop - scrollOffset - 16;

      if (!isLargeScreen) {
        toggleExpanded();
      }

      scrollAnimation(finalScrollPosition);
    },
    [isLargeScreen, toggleExpanded]
  );

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY || window.pageYOffset;
    const heroHeight = 0;

    if (scrollY < heroHeight && isSticky) {
      setSticky(false);
    } else if (scrollY >= heroHeight && !isSticky) {
      setSticky(true);
    }

    if (scrollY < heroHeight && !isTouched && isLargeScreen) {
      setExpanded(true);
    } else if (scrollY >= heroHeight && !isTouched && isLargeScreen) {
      setExpanded(false);
    }
  }, [isLargeScreen, isSticky, isTouched]);

  const renderItem = useCallback(
    ({ id, heading }) => (
      <Item key={id}>
        <ItemLink href={id} children={heading} onClick={handleMenuClick(id)} />
      </Item>
    ),
    [handleMenuClick]
  );

  useScroll(handleScroll);

  return (
    <Wrapper
      subMenuActive={subMenuActive}
      isSticky={isSticky}
      id="tableOfContents"
      ref={wrapperRef}
      isExpanded={isExpanded}
    >
      <HeaderWrapper ref={headerWrapperRef} isExpanded={isExpanded}>
        <Header
          onClick={toggleExpanded}
          isTouched={isTouched}
          isExpanded={isExpanded}
          children="Hitta rätt direkt"
        />
      </HeaderWrapper>
      <ItemList
        id="ToCItemList"
        ref={itemListRef}
        children={tableOfContents.map(renderItem)}
      />
    </Wrapper>
  );
}
