import React, { ReactNode } from 'react';
import styled from 'styled-components';

// Helpers
import { headingFont } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

export const Component = styled.div`
  ${headingFont};
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  color: white;
  text-transform: lowercase;

  ${mq('≥small')`
    font-size: 1.4rem;
    line-height: 1.4rem;
  `};
`;

type Props = {
  children: ReactNode;
};

export function SingleValue({ children }: Props) {
  return <Component children={children} />;
}
