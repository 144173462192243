import { useMemo } from 'react';
import { paymentMethods } from '~/App/config/paymentMethods';
import { ContactCustomerValues, ProductValues } from '../components/States';

const swish = {
  id: paymentMethods.swish,
  name: 'Swish'
};

const creditCard = {
  id: paymentMethods.creditCard,
  name: 'Betalkort',
  slug: 'betalkort',
  showContactFields: false
};

const autoGiro = {
  id: paymentMethods.autoGiroBankId,
  name: 'Autogiro',
  slug: 'autoGiro',
  showSsnField: false,
  showEmailField: false
};

type Props = {
  values: ProductValues & ContactCustomerValues;
};

export function useSuggestedPaymentMethod({ values }: Props) {
  const isRecurring = useMemo(
    () => values.productOptions.product?.period !== 'Once',
    [values.productOptions.product.period]
  );

  const availablePaymentMethods = useMemo(() => {
    if (isRecurring) {
      return [autoGiro, creditCard];
    }

    return [swish, creditCard];
  }, [isRecurring]);

  const defaultPaymentMethod = useMemo(() => {
    if (isRecurring) {
      return autoGiro;
    }

    return swish;
  }, [isRecurring]);

  return {
    defaultPaymentMethod,
    availablePaymentMethods
  };
}
