import { merge } from 'lodash';
import { useMemo } from 'react';

import { paymentMethods } from '~/App/config/paymentMethods';
import { useQuery } from '~/App/shared/hooks/use-query';

type Query = {
  price?: number;
};

type Props = {
  formContent: {
    defaultPrice?: number;
  };
  defaultState: Record<string, unknown>;
};

export function useState({ defaultState, formContent }: Props) {
  const defaultPrice = formContent.defaultPrice;
  const { price = defaultPrice } = useQuery<Query>();
  const minimumPrice = 1;
  const adjustedPrice =
    parseInt(`${price}`, 10) >= minimumPrice ? price : `${minimumPrice}`;

  return useMemo<any>(
    () =>
      merge(
        {},
        {
          product: { id: 3101 },
          paymentMethod: { id: paymentMethods.autoGiroBankId },
          productOptions: {
            product: {
              price: adjustedPrice,
              period: 'Month'
            },
            pul: true
          },
          customerContactType: 'automatic'
        },
        defaultState
      ),
    [adjustedPrice, defaultState]
  );
}
