import _ from 'lodash';

export type LookupItem =
  | string
  | {
      slug?: string;
      url?: string;
      contentType?: string;
    };

export type Conversion = {
  slug: string;
  contentType: string;
  friendlyName: string;
};

const conversions: Conversion[] = [
  {
    contentType: 'about cancer',
    friendlyName: 'Om Cancer',
    slug: 'om-cancer'
  },
  {
    contentType: 'news',
    friendlyName: 'Nyheter',
    slug: 'nyheter'
  },
  {
    contentType: 'collection',
    friendlyName: 'Insamlingar',
    slug: 'insamlingar'
  },
  {
    contentType: 'memorial_page',
    friendlyName: 'Minnessidor',
    slug: 'stod-oss/minnessidor'
  },
  {
    contentType: 'research project',
    friendlyName: 'Forskningsprojekt',
    slug: 'forskning/projekt'
  },
  {
    contentType: 'research',
    friendlyName: 'Forskning',
    slug: 'forskning'
  },
  {
    contentType: 'article',
    friendlyName: 'Artiklar',
    slug: 'artiklar'
  },
  {
    contentType: 'cancerfondsrapporten',
    friendlyName: 'Cancerfondsrapporten',
    slug: 'cancerfondsrapporten'
  },
  {
    contentType: 'press release',
    friendlyName: 'Pressreleaser',
    slug: 'press'
  },
  {
    contentType: 'publication',
    friendlyName: 'Publikationer',
    slug: 'publikationer'
  },
  {
    contentType: 'about cancerfonden',
    friendlyName: 'Om Cancerfonden',
    slug: 'om-cancerfonden'
  },
  {
    contentType: 'document',
    friendlyName: 'Dokument',
    slug: 'nedladdning/dokument'
  },
  {
    contentType: 'vacancy',
    friendlyName: 'Jobb',
    slug: 'jobb'
  },
  {
    contentType: 'support cancerfonden',
    friendlyName: 'Stöd Cancerfonden',
    slug: 'stod-cancerfonden'
  },
  {
    contentType: 'prevention',
    friendlyName: 'Livsstil',
    slug: 'livsstil'
  },
  {
    contentType: 'supporters',
    friendlyName: 'Företag',
    slug: 'foretag'
  },
  {
    contentType: 'conversation',
    friendlyName: 'Samtal',
    slug: 'samtal'
  },
  {
    contentType: 'rosa bandet',
    friendlyName: 'Rosa bandet',
    slug: 'rosabandet'
  },
  {
    contentType: 'rosa bandet formgivare',
    friendlyName: 'Rosa bandet formgivare',
    slug: 'rosabandet/formgivare'
  },
  {
    contentType: 'leva med rosa post',
    friendlyName: 'Rosa bloggveckan',
    slug: 'rosabandet/rosa-bloggveckan'
  },
  {
    contentType: 'other',
    friendlyName: 'Övrigt',
    slug: 'ovrigt'
  },
  {
    contentType: 'ambassador',
    friendlyName: 'Ambassadörer',
    slug: 'ambassadorer'
  },
  {
    contentType: 'page',
    friendlyName: 'Sida',
    slug: ''
  },
  {
    contentType: '',
    friendlyName: 'Alla',
    slug: ''
  }
];

export function assemble(item: LookupItem, absolute = false): string {
  if (typeof item === 'string') {
    return item;
  }

  if (
    item.contentType &&
    item.contentType === 'Content Item' &&
    item.url &&
    item.url.length > 0
  ) {
    if (absolute) {
      return process.env.CLIENT_SITE_BASE_URL + item.url;
    }

    return item.url;
  }

  // Return slug if contentType is empty
  if (_.isUndefined(item.contentType)) {
    return '/' + item.slug;
  }

  // Find correct parent
  const parent = _.find(conversions, {
    contentType: item.contentType.toLowerCase()
  });

  // Verify that parent exists, if not return root path
  if (_.isUndefined(parent)) {
    return '/' + item.slug;
  }

  // Return a complete path to item
  if (!absolute) {
    if (!parent.slug && item.slug && _.startsWith(item.slug, '/')) {
      return item.slug;
    }

    return ['', parent.slug, item.slug].join('/');
  }

  return [process.env.CLIENT_SITE_BASE_URL, parent.slug, item.slug].join('/');
}

export function contentTypes() {
  return _.map(conversions, 'contentType');
}

export function friendlyName(contentType: string) {
  // Find correct conversion
  const match = _.find(conversions, {
    contentType: contentType.toLowerCase()
  });

  // Return input contentType if no match is found
  if (_.isUndefined(match)) {
    return contentType;
  }

  return match.friendlyName;
}

export const mappings = conversions;
