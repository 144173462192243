import React, { useMemo } from 'react';

import { ICollection } from '~/types/ICollection';
import { IPaymentMethod } from '~/types/IPaymentMethod';
import { CustomerContactType } from '~/App/config/customerContactTypes';
import { collectionTypes } from '~/App/config/collectionTypes';

import styled from 'styled-components';
import { mq } from '~/lib/mq';
import { heading2 } from '~/App/helpers/mixins';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';
import * as themes from '~/App/config/themes';

// Local components
import { Form } from './components/Form';
import { Summery } from './components/Summery';
import { useState } from '../components/States';
import { useSubmit } from '../components/Submits';

import {
  LeftColumn,
  RightColumn,
  ColumnCard,
  Wrapper
} from '../components/Forms/components/TwoColumn';
import Container from '~/App/shared/components/Container';
import { useMultiPartFormState } from '../../MultiPartForm';
import { buildSchema } from './schema';
import { buildState } from './state';
import { CollectionDonationNudging } from './CollectionDonation.Nudging';
import { useValidation } from '../../Validation';
import PaymentRedirect from '../components/PaymentRedirect';

const Heading1 = styled.h1`
  ${heading2}
  font-size: 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: ${({ theme }) => theme.themeColors.primary};
  max-width: 494px;
  margin-right: auto;
  margin-left: auto;

  ${mq('≥medium')`
    max-width: none;
  `};
`;

const backgroundColor = featureIsEnabled('FEATURE_LOPBANDET_PINK')
  ? themes.pink.exerciseApp.primaryThemeColor
  : themes.global.exerciseApp.primaryThemeColor;

const backgroundImage = featureIsEnabled('FEATURE_LOPBANDET_PINK')
  ? 'https://res.cloudinary.com/cancerfonden/image/upload/v1567433950/assets/collection-page-background-shoes.png'
  : 'https://res.cloudinary.com/cancerfonden/image/upload/v1572511379/assets/collection-page-background-shoes_blue.png';

type BackgroundWrapperProps = {
  isLopbandet: boolean;
};

const BackgroundWrapper = styled.div<BackgroundWrapperProps>`
  background-color: ${({ isLopbandet }) =>
    isLopbandet
      ? backgroundColor
      : themes.global.exerciseApp.primaryThemeColor};
  background-image: ${({ isLopbandet }) =>
    isLopbandet ? `url('${backgroundImage}')` : 'none'};
  background-position: 100% 0;
  background-size: 605px 302px;
  background-repeat: no-repeat;
`;

const StyledWrapper = styled(Wrapper)`
  ${mq('≥medium')`
    justify-content: space-between;
  `};
`;

export type FormContent = {
  defaultPrice?: number;
  defaultPaymentMethod?: IPaymentMethod;
  defaultCustomerContactType?: CustomerContactType;
  availablePaymentMethods: IPaymentMethod[];
  originPath: string;
  redirectPath: string;
  prices: string[];
  texts: {
    desktopPreamble: string;
    mobilePreamble: string;
  };

  // used by not returned
  anotherDeviceText?: string;
  taxReductionInfoLink?: string;
};

type Props = {
  formContent: FormContent;
  collection: ICollection;
  originPath: string;
  redirectPath: string;
};

export default function CollectionDonation(props: Props) {
  const { formContent, collection, originPath, redirectPath } = props;

  const schema = useMemo(buildSchema, []);

  const state = useMemo(() => buildState({ formContent, collection }), [
    collection,
    formContent
  ]);

  const values = useState({
    values: state,
    formContent: {
      ...formContent,
      gtmId: '3a',
      redirectPath,
      originPath
    },
    customGtmId: '3a'
  });

  const validation = useValidation({
    values,
    schema
  });

  const multiPartValues = useMultiPartFormState({
    values,
    validation,
    parts: 2
  });

  const submit = useSubmit({ values: multiPartValues, validation });

  return useMemo(
    () => (
      <BackgroundWrapper
        isLopbandet={
          props.collection.collectionType === collectionTypes.lopbandet
        }
      >
        <Container
          width="limited"
          paddingLeft="small"
          paddingRight="small"
          paddingTop="small"
          paddingBottom="small"
        >
          <Heading1 children={`Bidra till ${props.collection.name}`} />
          <StyledWrapper>
            <CollectionDonationNudging
              submit={submit}
              values={multiPartValues}
              validation={validation}
            >
              {(nudingSubmit) => (
                <LeftColumn>
                  <PaymentRedirect
                    submit={nudingSubmit}
                    redirectPath={redirectPath}
                  />
                  <Form
                    values={multiPartValues}
                    validation={validation}
                    submit={nudingSubmit}
                    {...props}
                  />
                </LeftColumn>
              )}
            </CollectionDonationNudging>
            <RightColumn>
              <ColumnCard>
                <Summery values={multiPartValues} {...props} />
              </ColumnCard>
            </RightColumn>
          </StyledWrapper>
        </Container>
      </BackgroundWrapper>
    ),
    [multiPartValues, props, redirectPath, submit, validation]
  );
}
