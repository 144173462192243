import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

const Wrapper = styled.div`
  width: 100%;
  max-width: 20rem;
  height: 0.75rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.375rem;
  margin: 0 auto 6rem;

  ${mq('≥small')`
    margin: 0;
  `};
`;

const Done = styled.div`
  background-color: ${(props) => props.theme.themeColors.primary};
  width: ${({ progress }) => `${progress}%`};
  height: 0.75rem;
  border-radius: 0.365rem;
`;

export const Progress = (props) => {
  return (
    <Wrapper>
      <Done progress={props.progress} />
    </Wrapper>
  );
};
