import React, { useMemo } from 'react';

// Helpers
import { MemorialPerson } from './components/MemorialPerson';
import { HowItWorks } from './components/HowItWorks';

import { StepWrapper } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';
import { IntegrityText } from '~/App/shared/components/Donation/components/Forms/components/IntegrityText';

import { BlockHero } from '~/App/shared/components/Blocks';

import {
  useCreateMemorialPageState
  //useMemorialPageState
} from './hooks/useCreateMemorialPageState';
import { useValidation } from '~/App/shared/components/Validation';
import {
  Section,
  useMultiPartFormState
} from '~/App/shared/components/MultiPartForm';
import * as TwoColumn from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import Container from '~/App/shared/components/Container';
import { Authentication } from './components/Authentication';
import { ImageSelectorProvider } from '~/App/shared/components/Fields/ImageSelector';
import { useCreateMemorialPageSubmit } from './hooks/useCreateMemorialPageSubmit';
import styled from 'styled-components';
import { mq } from '~/App/helpers/mq';
import { SubmitButton } from './components/SubmitButton';
import { Helmet } from 'react-helmet';
import { siteUrl } from '~/App/helpers/env';

const HowItWorksItems = [
  {
    image:
      'https://res.cloudinary.com/cancerfonden/image/upload/c_fill,fl_progressive,h_40,q_80/v1599029724/assets/starta-insamling-ikon.svg',
    text: 'Skapa minnessidan'
  },
  {
    image:
      'https://res.cloudinary.com/cancerfonden/image/upload/c_fill,fl_progressive,h_40,q_80/v1603270956/assets/dela.svg',
    text: 'Dela minnessidan med nära och kära.'
  },
  {
    image:
      'https://res.cloudinary.com/cancerfonden/image/upload/v1638975309/assets/icons/icons-ui-minnes_r%C3%A4tt_format.svg',
    text: 'Låt familj och vänner minnas tillsammans.'
  }
];

const ButtonWrapper = styled.div`
  text-align: center;
`;

const StyledWrapper = styled(TwoColumn.Wrapper)`
  ${mq('<medium')`
      display: flex;
      flex-direction: column-reverse;
  `};
`;

const StyledRightColumn = styled(TwoColumn.RightColumn)`
  ${mq('<medium')`
      display: block;
      width: 100%;
      margin-left: 0;
      margin-bottom: 1rem;
  `};
`;

export function CreateMemorialPage() {
  const values = useCreateMemorialPageState();
  const validation = useValidation({
    values: values,
    schema: {
      'person.name': {
        presence: {
          allowEmpty: false,
          message: '^Glöm inte att skriva personens namn'
        }
      },
      'person.birth': {
        presence: { allowEmpty: false, message: '^Glöm inte att fylla i datum' }
      },
      'person.death': {}
    }
  });

  const multiPartValues = useMultiPartFormState({
    values,
    validation,
    parts: 2,
    name: 'CreateMemorialPage',
    validationKeys: {
      1: ['person.name', 'person.birth']
    }
  });

  const submit = useCreateMemorialPageSubmit({ validation, values });

  return useMemo(() => {
    return (
      <form onSubmit={submit.handleSubmit}>
        <Helmet>
          <title>{'Skapa minnessida'}</title>
          <link
            rel="canonical"
            href={siteUrl('stod-oss/minnessidor/skapa-minnessida')}
          />
          <meta name="robots" content="index,follow" />
          <meta name="description" content={''} />
          <meta property="og:title" content={'Skapa minnessida'} />
          <meta
            property="og:image"
            content={
              'https://res.cloudinary.com/cancerfonden/image/upload/v1645705076/collection-pictures/zj68yybfkrbbnrlaqvzj.jpg'
            }
          />
          <meta
            property="og:description"
            content={
              'Hos Cancerfonden kan du skapa en personlig minnessida för någon som har gått bort. Där kan nära och kära dela minnen och tankar.'
            }
          />
        </Helmet>
        <ImageSelectorProvider>
          <BlockHero
            large={{
              source: {
                secure_url:
                  'https://res.cloudinary.com/cancerfonden/image/upload/v1645607282/assets/Skapa-minnessida.jpg',
                url: '',
                format: 'jpg'
              },
              size: 'small',
              style: 'fixed'
            }}
            small={{
              source: {
                secure_url:
                  'https://res.cloudinary.com/cancerfonden/image/upload/v1716553478/Puff-minnessidor_m3srsp.jpg',
                url: '',
                format: 'jpg'
              },
              size: 'large',
              style: 'fixed'
            }}
            heading={'Skapa minnessida'}
            headingSettings={{
              fontSize: 'heading1',
              textAlign: 'center'
            }}
            showLightTextColor={true}
            body={
              '<p>Sätt upp en personlig minnessida för den som gått bort där ni kan dela era minnen och tankar.</p>'
            }
          />
          <BlockBackground backgroundColor="primary">
            <Container
              width="normal"
              paddingTop="large"
              paddingLeft="small"
              paddingRight="small"
              paddingBottom="large"
            >
              <StyledWrapper>
                <TwoColumn.LeftColumn>
                  <Section
                    part={1}
                    title={'Om personen'}
                    values={multiPartValues}
                    validation={validation}
                    nextStepButton={{ text: 'Till kontaktuppgifter' }}
                  >
                    <MemorialPerson values={values} validation={validation} />
                  </Section>
                  <Section
                    part={2}
                    title={'Kontaktuppgifter'}
                    values={multiPartValues}
                    validation={validation}
                  >
                    <StepWrapper marginTop="tiny">
                      <Authentication values={values} validation={validation} />

                      <IntegrityText />
                    </StepWrapper>
                  </Section>

                  {multiPartValues.partOptions.currentPart === 2 && (
                    <ButtonWrapper>
                      <SubmitButton validation={validation} submit={submit} />
                    </ButtonWrapper>
                  )}
                </TwoColumn.LeftColumn>

                <StyledRightColumn>
                  <HowItWorks
                    heading={'Så funkar det'}
                    items={HowItWorksItems}
                  />
                </StyledRightColumn>
              </StyledWrapper>
            </Container>
          </BlockBackground>
        </ImageSelectorProvider>
      </form>
    );
  }, [multiPartValues, submit, validation, values]);
}
