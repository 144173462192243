import React, { ComponentProps, useCallback, useMemo } from 'react';
import {
  Columns,
  Component,
  ContentType,
  RelatedItem,
  RelatedCollectionItem,
  RelatedBambuserItem
} from '~/types/RelatedBlock';

import { AccentColor, BackgroundColor } from '~/types/Block';

import { Cell } from '../components/Layout';

import { CollectionCard } from '../../CollectionCard';
import { ArticleTeaser } from '../components/ArticleTeaser';
import { NewsCard } from '../components/NewsCard';
import { BambuserCard } from '../components/BambuserCard';
import { GuideCard } from '../components/GuideCard';
import { TopicCard } from '../components/TopicCard';
import { SquaredTopicCard } from '../components/SquaredTopicCard';
import { SponsorCard } from '../components/SponsorCard';
import { PartnerCard } from '../components/PartnerCard';
import { PackageCard } from '../components/PackageCard';
import { EditorialBrick } from '../components/EditorialBrick';

type Props = {
  items: RelatedItem[];
  contentType: ContentType;
  component: Component;
  carousel: {
    isActive: boolean;
  };
  useGrid: boolean;
  columns: Columns;
  accentColor: AccentColor;
  backgroundColor: BackgroundColor;
  showPublishedAt: boolean;
  showRoundImage: boolean;
  getCellKey: (item: RelatedItem) => string;
};

export function useItemsRenderer({
  accentColor,
  backgroundColor,
  showPublishedAt,
  showRoundImage,
  contentType,
  component,
  carousel,
  useGrid,
  columns,
  items,
  getCellKey
}: Props) {
  // @ts-expect-error
  const breakpoints = useMemo<Record<any, number>>(() => {
    if (component === 'topicCard') {
      return {
        '≥small': 1 / columns,
        '<small': 0.5,
        '<tiny': 1
      };
    }

    if (component === 'squareTopicCard') {
      return {
        '≥small': 1,
        '<small': 0.5,
        '<tiny': 1
      };
    }

    if (contentType === 'collections') {
      return {
        '≥medium': 1 / columns,
        '<medium': 1 / 2,
        '<small': 1
      };
    }

    if (contentType === 'bambuser') {
      if (items.length === 1) {
        return { '≥small': 1 };
      }

      if (items.length === 2) {
        return { '≥small': 1 / 2 };
      }
    }

    return { '≥small': 1 / columns };
  }, [columns, component, contentType, items.length]);

  const isCollection = useCallback(
    (item: RelatedItem): item is RelatedCollectionItem =>
      contentType === 'collections',
    [contentType]
  );

  const isBambuser = useCallback(
    (item: RelatedItem): item is RelatedBambuserItem =>
      contentType === 'bambuser',
    [contentType]
  );

  const renderItem = useCallback(
    (item: RelatedItem) => {
      const cellProps: ComponentProps<typeof Cell> = {
        key: getCellKey(item),
        breakpoints: breakpoints,
        carousel: carousel,
        useGrid: useGrid
      };

      if (isCollection(item)) {
        return (
          <Cell {...cellProps}>
            <CollectionCard collection={item} />
          </Cell>
        );
      }

      if (isBambuser(item)) {
        return (
          <Cell {...cellProps}>
            <BambuserCard
              video={item}
              numberOfItems={items.length}
              carousel={carousel}
            />
          </Cell>
        );
      }

      return (
        <Cell {...cellProps}>
          {component === 'guideCard' && (
            <GuideCard item={item} accentColor={accentColor} />
          )}
          {component === 'topicCard' && (
            <TopicCard item={item} showRoundImage={showRoundImage} />
          )}
          {component === 'squareTopicCard' && <SquaredTopicCard item={item} />}
          {component === 'newsCard' && <NewsCard item={item} />}
          {component === 'sponsorCard' && (
            <SponsorCard backgroundColor={backgroundColor} item={item} />
          )}
          {component === 'partnerCard' && (
            <PartnerCard backgroundColor={backgroundColor} item={item} />
          )}
          {component === 'packageCard' && (
            <PackageCard backgroundColor={backgroundColor} item={item} />
          )}
          {component === 'articleTeaser' && (
            <ArticleTeaser
              columns={columns}
              showPublishedAt={showPublishedAt}
              item={item}
            />
          )}
          {component === 'editorialBrick' && <EditorialBrick item={item} />}
        </Cell>
      );
    },
    [
      accentColor,
      backgroundColor,
      breakpoints,
      carousel,
      columns,
      component,
      getCellKey,
      isBambuser,
      isCollection,
      items.length,
      showPublishedAt,
      showRoundImage,
      useGrid
    ]
  );

  const children = useMemo(() => items.map(renderItem), [items, renderItem]);

  return {
    children
  };
}
