import React, { Fragment, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';

// Helpers
import mq from '~/App/helpers/mq';
import { buttonStyles } from '~/App/config/buttonStyles';
import { genericCertificate } from '~/App/config/default-images';

import { textHelper } from '../Forms/helpers';
import { FormSubHeading } from '../Forms/components/FormTypography';
import { StepWrapper } from '../Forms/components/FormLayout';

import { PrimaryButton } from '~/App/shared/components/Elements';
import { ImageSelector } from '../../../Fields/ImageSelector';
import TextArea from '~/App/shared/components/Fields/TextArea';
import Select from '~/App/shared/components/Fields/Select';

const FullWidthButton = styled(PrimaryButton)`
  margin-top: ${(props) => (props.marginTop === false ? '0' : '1rem')};
  width: 100%;

  ${mq('≥medium')`
    display: none;
  `};
`;

export function GenericCertificateCustomization({
  togglePreviewModal,
  formContent,
  values,
  validation
}) {
  const variants = useMemo(() => formContent.certificateInfo.variants || [], [
    formContent.certificateInfo.variants
  ]);

  const calculatedLines = useMemo(
    () =>
      textHelper.calculateUsedLines(
        values.productOptions.certificate.textGreeting
      ),
    [values.productOptions.certificate.textGreeting]
  );

  const headingOptions = useMemo(
    () =>
      variants.map((variant) => ({
        value: variant.slug,
        label: variant.heading
      })),
    [variants]
  );

  const handleHeadingChange = useCallback(
    (event) => {
      const slug = event.target.value;
      const variant = find(variants, { slug: slug });

      if (!variant) return;

      const heading = variant.heading;
      const campaignActivityId = variant.campaignActivityId;

      values.handlers.setCertificateHeadingAndSlug({
        heading: heading,
        slug: slug
      });

      values.handlers.setGtmVariant(variant.gtmVariant);

      if (campaignActivityId) {
        values.handlers.setCampaignActivityId(campaignActivityId);
      } else {
        values.handlers.unsetCampaignActivityId();
      }
    },
    [values.handlers, variants]
  );

  return useMemo(
    () => (
      <Fragment>
        {variants.length > 1 && (
          <StepWrapper>
            <FormSubHeading children="Välj rubrik" />
            <Select
              name="heading"
              value={values.productOptions.certificate.headingSlug}
              options={headingOptions}
              onChange={handleHeadingChange}
              required={true}
            />
          </StepWrapper>
        )}
        <StepWrapper>
          <FormSubHeading>Välj motiv</FormSubHeading>
          <ImageSelector
            defaultImages={genericCertificate}
            value={values.productOptions.certificate.illustration}
            onChange={values.handlers.setCertificateIllustration}
            errors={
              validation.errors['productOptions.certificate.illustration']
            }
            isRounded={true}
            isInvalid={validation.isInvalid(
              'productOptions.certificate.illustration'
            )}
          />
        </StepWrapper>
        <StepWrapper>
          <FormSubHeading children="Skriv en hälsning och ditt/era namn" />
          <TextArea
            name="certificate.textGreeting"
            value={values.productOptions.certificate.textGreeting}
            maxLength="2000"
            placeholder="Skriv här"
            onChange={values.handlers.setCertificateTextGreeting}
            onBlur={validation.showError.bind(
              undefined,
              'productOptions.certificate.textGreeting'
            )}
            errors={
              validation.errors['productOptions.certificate.textGreeting']
            }
            isValid={validation.isValid(
              'productOptions.certificate.textGreeting'
            )}
            isInvalid={validation.isInvalid(
              'productOptions.certificate.textGreeting'
            )}
            counterValue={`${calculatedLines}/10 rader`}
            required
          />
          <FullWidthButton
            buttonType="button"
            buttonStyle={buttonStyles.outlinePrimary}
            onClick={togglePreviewModal}
            children="Förhandsgranska gåvobevis"
          />
        </StepWrapper>
      </Fragment>
    ),
    [
      calculatedLines,
      handleHeadingChange,
      headingOptions,
      togglePreviewModal,
      validation,
      values.handlers.setCertificateIllustration,
      values.handlers.setCertificateTextGreeting,
      values.productOptions.certificate.headingSlug,
      values.productOptions.certificate.illustration,
      values.productOptions.certificate.textGreeting,
      variants.length
    ]
  );
}
