import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import styled from 'styled-components';

import mediaHelper from '~/App/helpers/media-helper';
import { fetch } from '~/App/helpers/http';
import { mq } from '~/lib/mq';
import { useSelfTest } from '../context/useSelfTest';

type WrapperProps = {
  desktop: string | null;
  mobile: string | null;
};

const Wrapper = styled.div<WrapperProps>`
  background-image: url(${({ desktop, mobile }) =>
    `data:image/png;base64,${mobile || desktop}`});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 4.5rem;

  ${mq<WrapperProps>('≥small')`
    padding-bottom: 10rem;
    background-image: url(${({ desktop, mobile }) =>
      `data:image/png;base64,${desktop || mobile}`});
  `};
`;

type Cache = Record<string, string | null>;

type Props = {
  children: ReactNode;
};

export function Background({ children }: Props) {
  const [cache, setCache] = useState<Cache>({});

  const { currentScreen, currentSection, config } = useSelfTest();

  const mobileUrl = useCallback(
    (url: string | undefined) =>
      url
        ? mediaHelper.getUrl(url, {
            width: 600,
            format: 'jpg',
            quality: 80
          })
        : null,
    []
  );

  const desktopUrl = useCallback(
    (url: string | undefined) =>
      url
        ? mediaHelper.getUrl(url, {
            width: 1280,
            format: 'jpg',
            quality: 80
          })
        : null,
    []
  );

  const download = useCallback(
    async (url: string) => {
      if (!url) {
        return;
      }

      if (cache[url]) {
        return;
      }

      try {
        const response = await fetch({
          url,
          responseType: 'arraybuffer'
        });

        setCache((state) => ({
          ...state,
          [url]: Buffer.from(response.data, 'binary').toString('base64')
        }));
      } catch (_) {
        setCache((state) => ({
          ...state,
          [url]: null
        }));
      }
    },
    [cache]
  );

  const cacheAllImages = useCallback(async () => {
    for (const section of config.sections) {
      for (const screen of section.screens) {
        const desktopImage = desktopUrl(
          screen.backgroundImageURL || section.backgroundImageURL
        );

        const mobileImage = mobileUrl(
          screen.backgroundImageMobileURL || section.backgroundImageMobileURL
        );

        if (desktopImage) {
          await download(desktopImage);
        }

        if (mobileImage) {
          await download(mobileImage);
        }
      }
    }
  }, [config.sections, desktopUrl, download, mobileUrl]);

  useEffect(() => {
    cacheAllImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const desktop = useMemo(
    () =>
      cache[
        desktopUrl(
          currentScreen?.backgroundImageURL ||
            currentSection?.backgroundImageURL
        )
      ],
    [
      currentScreen?.backgroundImageURL,
      currentSection?.backgroundImageURL,
      cache,
      desktopUrl
    ]
  );

  const mobile = useMemo(
    () =>
      cache[
        mobileUrl(
          currentScreen?.backgroundImageMobileURL ||
            currentSection?.backgroundImageMobileURL
        )
      ],
    [
      currentScreen?.backgroundImageMobileURL,
      currentSection?.backgroundImageMobileURL,
      cache,
      mobileUrl
    ]
  );

  return useMemo(
    () => <Wrapper desktop={desktop} mobile={mobile} children={children} />,
    [children, desktop, mobile]
  );
}
