import React, { Fragment, useCallback, ReactNode } from 'react';
import styled from 'styled-components';

import { preamble } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';
import { ISubmit } from '../../components/Submits';
import { IValidation } from '../../../Validation';
import { TaxReductionNudging } from './TaxReductionNudging';
import { SubmitValues } from '../Submits/Submit/types/SubmitValues';

const Text = styled.p`
  ${preamble}
  font-size: 1rem;
  margin: 1rem 0;
  color: ${(props) => props.theme.colors.charcoal};
  font-weight: ${(props) => props.theme.fontWeights.regular};

  ${mq('≥small')`
  font-size: 1.125rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  `};
`;

type Props = {
  submit: ISubmit;
  validation: IValidation;
  values: SubmitValues;
  name: string;
  shouldNudge: boolean;
  children: (submit: ISubmit) => ReactNode;
};

export function MonthlyDonationNudge(props: Props) {
  const getRecommendedAmount = useCallback((amount: number) => {
    if (amount < 150) return null;
    if (amount > 375) return null;

    return Math.round(amount * ((4 * 1) / 3));
  }, []);

  const getContent = useCallback(
    ({ recommendedAmount, amount }) => ({
      heading: 'Visste du?',
      disclaimer:
        'Gäller om du ger minst 2 000 kronor under ett kalenderår, till oss eller andra godkända organisationer.',
      children: (
        <Fragment>
          <Text>
            Om du höjer din gåva till {recommendedAmount} kr betalar du ändå
            inte mer! Då får du nämligen {recommendedAmount - amount} kr per
            månad tillbaka på skatten och mer pengar går till kampen mot cancer.
          </Text>
          <Text>
            PS. Du behöver inte göra något. Vi skickar dina uppgifter till
            Skatteverket.
          </Text>
        </Fragment>
      )
    }),
    []
  );

  return (
    <TaxReductionNudging
      {...props}
      setAmount={props.values.handlers.setProductPrice}
      getContent={getContent}
      getRecommendedAmount={getRecommendedAmount}
    />
  );
}
