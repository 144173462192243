import React, { ReactNode, useMemo } from 'react';

import { paymentMethods } from '~/App/config/paymentMethods';

import { FavourablePaymentMethodSelector } from '~/App/shared/components/Donation/components/Forms/components/FavourablePaymentMethodSelector';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '~/App/shared/components/Donation/components/States/';
import { IValidation } from '~/App/shared/components/Validation';

type Props = {
  values: ContactCustomerValues & PaymentValues & ProductValues;
  validation: IValidation;
  submitButtons: ReactNode;
  formContent: {
    taxReductionInfoLink?: string;
  };
  showTaxReductionText: boolean;
};

export function Payment({
  values,
  formContent,
  validation,
  submitButtons,
  showTaxReductionText
}: Props) {
  const methods = useMemo(() => {
    return [
      {
        id: paymentMethods.swish,
        name: 'Swish',
        favourable: true,
        showTaxReductionText: showTaxReductionText,
        manualProps: {
          disableName: true,
          disableAddress: true
        }
      },
      {
        id: paymentMethods.klarnaPayments,
        name: 'Konto eller kort',
        slug: 'klarna',
        favourable: true,
        isGiftShop: true
      }
    ];
  }, [showTaxReductionText]);

  return (
    <FavourablePaymentMethodSelector
      methods={methods}
      formContent={formContent}
      values={values}
      validation={validation}
      children={submitButtons}
    />
  );
}
