import React from 'react';
import styled from 'styled-components';

import { Container } from '~/App/shared/components/Container';
import { HtmlContent } from '~/App/shared/components/HtmlContent';
import { preamble } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';
import { Width } from '~/types/Block';

type WrapperProps = {
  showContentBelowHero: boolean;
};

const Wrapper = styled(Container)<WrapperProps>`
  display: ${({ showContentBelowHero }) =>
    showContentBelowHero ? 'block' : 'none'};

  ${mq('≥large')`
  display: none;
`};
`;

const Text = styled(HtmlContent)`
  p {
    ${preamble};
  }
`;

type Props = {
  width?: Width;
  body: string;
  showContentBelowHero: boolean;
};

export function ContentBelowHero({ width, body, showContentBelowHero }: Props) {
  if (!body) {
    return null;
  }

  return (
    <Wrapper
      width={width}
      padding={{ top: 'small', left: 'small', right: 'small', bottom: 'small' }}
      children={<Text children={body} />}
      showContentBelowHero={showContentBelowHero}
    />
  );
}
