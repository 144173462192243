import React, { ChangeEventHandler, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper, { isCloudinaryUrl } from '~/App/helpers/media-helper';
import { rotate360 } from '~/App/helpers/animations';

import { CollectionProgress } from '~/App/shared/components/CollectionImage/components/CollectionProgress';

const ImageUploaderWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid ${(props) => props.theme.colors.lightDust};
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ImageLabel = styled.label`
  cursor: pointer;
  font-size: 1.125rem;
  color: ${(props) => props.theme.exerciseApp.primaryHeadingColor};
`;

const ImageUpload = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;

  @supports (object-fit: cover) {
    object-fit: cover;
    height: 100%;
  }
`;

const ImageMemorial = styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 50%;

  @supports (object-fit: cover) {
    object-fit: cover;
    height: 100%;
  }
`;

const ImageMemorialWrapper = styled.div`
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 3.75rem;
  height: 3.75rem;
  margin: 0 1rem 0 0;
`;

const ImageUploaderMemorialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid ${(props) => props.theme.colors.lightDust};
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 1.45rem 1rem;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  margin: 0 1rem 0 0;
`;

const Spinner = styled.div`
  width: 100%;
  height: 100%;
  animation: ${rotate360} 0.3s linear infinite;
  border-top: 2px solid ${({ theme }) => theme.primaryButton.spinnerColorDark};
  border-right: 2px solid
    ${({ theme }) => theme.primaryButton.spinnerColorLight};
  border-bottom: 2px solid
    ${({ theme }) => theme.primaryButton.spinnerColorLight};
  border-left: 2px solid ${({ theme }) => theme.primaryButton.spinnerColorDark};
  border-radius: 100%;
  content: ' ';
  z-index: 1;
`;

type Props = {
  value: string | File | undefined | null;
  children: ReactNode;
  isLoading?: boolean;
  showProgressBadge: boolean;
  collectionType: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export function ImageUploader({
  value,
  isLoading,
  children,
  showProgressBadge,
  collectionType,
  onChange
}: Props) {
  const imageElement = useMemo(() => {
    if (typeof value === 'string') {
      if (collectionType === 'memorial_page') {
        const src = isCloudinaryUrl(value)
          ? mediaHelper.getUrl(value, {
              width: 60,
              height: 60,
              quality: 100,
              crop: 'fill',
              gravity: 'face',
              dpr: '2.0',
              radius: 60,
              fetch_format: 'png'
            })
          : value;

        return <ImageMemorial src={src} />;
      } else {
        const src = isCloudinaryUrl(value)
          ? mediaHelper.getUrl(value, {
              width: 400,
              height: 400,
              quality: 80,
              crop: 'fill',
              gravity: 'face',
              dpr: '2.0',
              fetch_format: 'png'
            })
          : value;

        return <Image src={src} />;
      }
    }

    if (value) {
      return collectionType === 'memorial_page' ? (
        <ImageMemorial src={URL.createObjectURL(value)} />
      ) : (
        <Image src={URL.createObjectURL(value)} />
      );
    }

    return collectionType === 'memorial_page' ? <ImageMemorial /> : <Image />;
  }, [value, collectionType]);

  return collectionType === 'memorial_page' ? (
    <ImageUploaderMemorialWrapper>
      {isLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          {showProgressBadge && (
            <CollectionProgress percentage={50} position={'absolute'} />
          )}
          <ImageMemorialWrapper children={imageElement} />
        </>
      )}
      <ImageLabel htmlFor="profile-image" children={children} />
      <ImageUpload
        id="profile-image"
        type="file"
        accept="image/*"
        onChange={onChange}
      />
    </ImageUploaderMemorialWrapper>
  ) : (
    <ImageUploaderWrapper>
      {isLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          {showProgressBadge && (
            <CollectionProgress percentage={50} position={'absolute'} />
          )}
          <ImageWrapper children={imageElement} />
        </>
      )}
      <LabelWrapper>
        <ImageLabel htmlFor="profile-image" children={children} />
      </LabelWrapper>
      <ImageUpload
        id="profile-image"
        type="file"
        accept="image/*"
        onChange={onChange}
      />
    </ImageUploaderWrapper>
  );
}
