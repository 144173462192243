import axios from 'axios';

async function getBlobFromUrl(url: string) {
  const fetchResponse = await fetch(url);
  const blob = await fetchResponse.blob();

  return blob;
}

type Options = {
  uploadPreset?: string;
  maxFileSize?: string | number;
  timeout?: number;
  folder?: string;
};

type Response = {
  secure_url?: string;
};

export async function uploadImage(
  data: File | Blob | string,
  options: Options = {}
) {
  const {
    uploadPreset = 's5omqdvm',
    maxFileSize = '20000000',
    timeout = 15000,
    folder
  } = options;

  const formData = new FormData();
  const file = typeof data === 'string' ? await getBlobFromUrl(data) : data;

  formData.append('file', file);
  formData.append('api_key', `${process.env.CLOUDINARY_KEY}`);
  formData.append('upload_preset', uploadPreset);
  formData.append('max_file_size', `${maxFileSize}`);

  if (folder) {
    formData.append('folder', folder);
  }

  const request = {
    url: 'https://api.cloudinary.com/v1_1/cancerfonden/upload',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout
  };

  return axios.post<Response>(
    'https://api.cloudinary.com/v1_1/cancerfonden/upload',
    formData,
    request
  );
}
