import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

// config
import { buttonStyles } from '~/App/config/buttonStyles';

// local components
import { ButtonWrapper, PrimaryButton } from '../Styled';
import { Logo } from '../Logo';
import { HandleLoginOptions } from '~/App/contexts/Login';

import useLoginModal from '~/App/shared/hooks/use-login-modal';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
`;

type Props = {
  beginAuthentication: (options: HandleLoginOptions) => void;
};

export function SameDevice({ beginAuthentication }: Props) {
  const loginModal = useLoginModal();

  const handleClick = useCallback(() => {
    beginAuthentication({ sameDevice: true });
  }, [beginAuthentication]);

  const handleOtherDeviceClick = useCallback(() => {
    loginModal.handlers.setMobileInitView(false);
  }, [loginModal.handlers]);

  useEffect(() => {
    if (!loginModal.qrMode) return;

    loginModal.handlers.toggleQrMode(false);
  }, [loginModal.handlers, loginModal.qrMode]);

  return (
    <FlexWrapper>
      <ButtonWrapper>
        <PrimaryButton onClick={handleClick} children="BankID på denna enhet" />
        <PrimaryButton
          buttonStyle={buttonStyles.outlinePrimary}
          onClick={handleOtherDeviceClick}
          children="BankID på annan enhet"
        />
      </ButtonWrapper>
      <Logo marginBottom="0.5rem" />
    </FlexWrapper>
  );
}
