import React from 'react';
import Text from '~/App/shared/components/Fields/Text';
import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';

import { InputWrapper } from '../components/InputWrapper';

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
  placeholder?: string;
  instructions?: string;
};

export function Telephone({
  values,
  validation,
  placeholder,
  instructions
}: Props) {
  return (
    <InputWrapper>
      <Text
        name="tel"
        type="tel"
        value={values.productOptions.customerContact.phoneNumber}
        onBlur={validation.showError.bind(
          undefined,
          'productOptions.customerContact.phoneNumber'
        )}
        errors={validation.errors['productOptions.customerContact.phoneNumber']}
        isValid={validation.isValid(
          'productOptions.customerContact.phoneNumber'
        )}
        onChange={values.handlers.handleCustomerContactPhoneNumber}
        isInvalid={validation.isInvalid(
          'productOptions.customerContact.phoneNumber'
        )}
        maxLength="20"
        placeholder={placeholder || 'Telefonnummer'}
        instructions={instructions}
        autoComplete="work tel"
        required
      />
    </InputWrapper>
  );
}
