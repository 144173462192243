import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent
} from 'react';

import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

// helpers
import mq from '~/App/helpers/mq';
import { doubleRight } from '~/App/helpers/icons';

type OverlayProps = {
  show: boolean;
};

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  z-index: 102;
`;

type WrapperProps = {
  show: boolean;
  useDelay: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 24px;
  height: calc(100% - 1.5rem);
  right: 0;
  width: 100%;
  z-index: 102;
  overflow-x: initial;
  overflow-y: visible;

  &.wrapper-appear {
    transform: translateY(100%);
  }

  &.wrapper-appear-active {
    transition: all 0.33s cubic-bezier(0.2, 0.7, 0.5, 1);
    transform: translateY(0);
  }

  &.wrapper-exit-active {
    transition: ${({ useDelay }) =>
      useDelay
        ? 'all 0.33s cubic-bezier(0.2, 0.7, 0.5, 1) 1s'
        : 'all 0.33s cubic-bezier(0.2, 0.7, 0.5, 1)'};
    transform: translateY(100%);
  }

  &.wrapper-exit-done {
    transform: translateY(100%);
  }

  ${mq<WrapperProps>('≥mediumLarge')`
    max-height: 100vh;
    height: 100vh;
    width: 514px;
    top: 0;

    &.wrapper-appear {
      transform: translateX(100%) translateX(40px);
    }

    &.wrapper-appear-active {

      transition: all 0.33s cubic-bezier(0.2, 0.7, 0.5, 1);
      transform: translateX(0);
    }

    &.wrapper-exit-active {
      transition: ${({ useDelay }) =>
        useDelay
          ? 'all 0.33s cubic-bezier(0.2, 0.7, 0.5, 1) 1s'
          : 'all 0.33s cubic-bezier(0.2, 0.7, 0.5, 1)'};
      transform: translateX(100%) translateX(40px);
    }

    &.wrapper-exit-done {
      transform: translateX(100%) translateX(40px);
    }
    `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 1;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

  ${mq('<mediumLarge')`
    max-height: 100%;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
  `};

  ${mq('≥mediumLarge')`
    height: 100vh;
  `};
`;

const CloseTab = styled.div`
  position: absolute;
  bottom: 33px;
  left: -33px;
  background-color: ${({ theme }) => theme.colors.white};
  height: 52px;
  width: 52px;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.15s cubic-bezier(0.2, 0.5, 0.7, 1.3),
    background-color 0.3s cubic-bezier(0.2, 0.5, 0.7, 1.3);
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      theme.themeColors.primaryBackgroundColor};
    transform: translateX(-7px);
  }

  &::after {
    ${doubleRight};
    font-size: 0.9rem;
    top: 15px;
    left: 11px;
    color: ${({ theme }) => theme.themeColors.primary};
  }

  ${mq('<mediumLarge')`
    display: none;
  `};
`;

type Props = {
  children: ReactNode;
  isOpen: boolean;
  isEmpty: boolean;
  onClose: () => void;
};

export function SidebarModal({ isOpen, isEmpty, children, onClose }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  const [delayedOpenStatus, setdelayedOpenStatus] = useState(isOpen);

  const handleWrapperClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (ref.current?.contains(event.target as Node)) {
        return;
      }

      return onClose();
    },
    [onClose]
  );

  useEffect(() => {
    const timeout = setTimeout(
      () => setdelayedOpenStatus(isOpen),
      isEmpty ? 1330 : 330
    );
    return () => clearTimeout(timeout);
  }, [isEmpty, isOpen]);

  if (!isOpen && !delayedOpenStatus) return null;

  return (
    <>
      <Overlay show={isOpen || delayedOpenStatus} onClick={handleWrapperClick}>
        <CSSTransition
          in={isOpen}
          appear={true}
          timeout={1330}
          classNames="wrapper"
        >
          <Wrapper
            show={delayedOpenStatus}
            useDelay={isEmpty}
            onClick={handleWrapperClick}
          >
            <Content children={children} ref={ref} />
            <CloseTab onClick={onClose} />
          </Wrapper>
        </CSSTransition>
      </Overlay>
    </>
  );
}
