import React from 'react';
import styled from 'styled-components';

import { siteUrl } from '~/App/helpers/env';
import ShareButtons from '~/App/shared/components/ShareButtons';
import { ICollectionActivity } from '~/types/ICollectionActivity';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Text = styled.div`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 0.75rem;
`;

type Props = {
  memory: ICollectionActivity;
  className?: string;
  customText?: string;
};

export function Share({ memory, ...props }: Props) {
  return (
    <Wrapper {...props}>
      <Text
        children={props.customText ? props.customText : 'Dela minnessidan'}
      />
      <ShareButtons
        customUrl={`${siteUrl(
          `/stod-oss/minnessidor/${memory.collection.slug}?memoryId=${memory.publicToken}`
        )}`}
        showFacebook
        showX
        showEmail
        showLink
        invertedColors
        facebook={`Dela dina minnen tillsammans med mig på ${memory.collection.name}s minnessida hos Cancerfonden.`}
        tweet={{
          text: `Dela dina minnen tillsammans med mig på ${memory.collection.name}s minnessida hos Cancerfonden.`
        }}
        email={{
          subject: `Jag vill dela ${memory.collection.name}s minnessida med dig`,
          body: `Hej, tillsammans kan vi dela minnen och tankar om ${
            memory.collection.name
          } på en minnessida hos Cancerfonden. Du hittar minnessidan här ${siteUrl(
            `/stod-oss/minnessidor/${memory.collection.slug}?memoryId=${memory.publicToken}`
          )}`
        }}
      />
    </Wrapper>
  );
}
