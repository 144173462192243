import React, { useMemo } from 'react';
import styled from 'styled-components';

// Config
import { buttonStyles } from '~/App/config/buttonStyles';

// Helpers
import { arrowLink, heading4 } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

// Shared components
import { Divider } from '~/App/shared/components/Blocks';
//import { HiddenAboveMedium } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';
import { PrimaryButton } from '~/App/shared/components/Elements';
import {
  ApprovedWrapper,
  CheckIcon
} from '~/App/shared/components/Donation/MemorialGift/components/Form/Form';

export const HiddenAboveMedium = styled.div`
  margin: 2rem 0 0 0;

  ${mq('<small')`
    margin: 2rem 0 1.5rem 0;
  `};

  ${mq('≥medium')`
    display: none;
  `};
`;

const ArrowLink = styled.a`
  ${arrowLink};
`;

const Heading4 = styled.h4`
  ${heading4};
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 2.5rem;

  &::after {
    position: absolute;
    top: -0.125rem;
    left: 0;
    display: block;
    content: '';
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1552481956/assets/icon-heart.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 1.75rem;
    height: 1.375rem;
  }
`;

const FullWidthButton = styled(PrimaryButton)`
  margin-top: ${(props) => (props.marginTop === false ? '0' : '1rem')};

  ${mq('≤medium')`
    width: 15.5rem;
    display: block;
    margin: auto;
    padding: 0.625rem 1rem;
  `};
`;

export function MemorialGiftSummary({
  values,
  formContent,
  area,
  openPreviewModal,
  isApproved
}) {
  const helpText = useMemo(() => {
    let key = '';

    switch (values?.partOptions?.currentPart) {
      case 3:
        if (values?.productOptions?.receiverContactType === 'company') {
          key = 'funeralHome';
        }
        break;
    }

    const text = formContent?.helpTexts[key];

    if (!text) {
      return null;
    }

    return (
      <div>
        <Divider
          width="narrow"
          paddingLeft="none"
          paddingRight="none"
          height="2px"
          alternativeColor={true}
        />
        <Heading4>{text.title}</Heading4>
        <p>{text.text}</p>
        {text.links &&
          text.links.map((link) => (
            <p key={link.url}>
              <ArrowLink
                href={link.url}
                target="_blank"
                rel="noopener"
                children={link.text}
              />
            </p>
          ))}
      </div>
    );
  }, [
    formContent.helpTexts,
    values.partOptions.currentPart,
    values.productOptions.receiverContactType
  ]);

  const previewModalButton = useMemo(() => {
    if (area !== 'form') return null;
    if (values?.partOptions?.currentPart !== 1) return null;
    if (isApproved) {
      return (
        <ApprovedWrapper>
          <CheckIcon />
          <p>Du har godkänt minnesbladet</p>
        </ApprovedWrapper>
      );
    }

    return (
      <FullWidthButton
        buttonType="button"
        buttonStyle={buttonStyles.outlinePrimary}
        onClick={openPreviewModal}
        children="Förhandsgranska minnesblad"
      />
    );
  }, [area, openPreviewModal, values?.partOptions?.currentPart, isApproved]);

  const summaryContent = useMemo(() => {
    if (!helpText && !previewModalButton) {
      return null;
    }

    return (
      <>
        {helpText}
        {previewModalButton}
      </>
    );
  }, [helpText, previewModalButton]);

  if (!summaryContent) {
    return null;
  } else if (area === 'form') {
    return <HiddenAboveMedium children={summaryContent} />;
  } else {
    return <div children={summaryContent} />;
  }
}
