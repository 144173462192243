import { useCallback, useEffect, useMemo, useState, ReactElement } from 'react';
import _ from 'lodash';

import { paymentMethods } from '~/App/config/paymentMethods';

import validate from '~/App/helpers/validate';
import { pushGTMEvent } from '~/App/helpers/gtm-helper';
import { getHash } from '~/App/helpers/hash-helper';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

const STRIPE_KEY = 'stripeCard';
const EMAIL_KEY = 'productOptions.customerContact.email';

type StripeError = StripeCardElementChangeEvent['error'];

type UseValidationProps<T> = {
  hasStripe?: boolean;
  values: T;
  schema: Record<string, unknown>;
};

export type IValidation = {
  validation: Record<string, string[]>;
  errors: Record<string, string[]>;
  isValidated: boolean;

  isValid: (key: string) => boolean;
  areValid: (keys: string[]) => boolean;
  isInvalid: (key: string) => boolean;
  showError: (key: string) => void;
  hideError: (key: string) => void;
  handleBlur: (key: string) => void;
  hideAllErrors: () => void;
  showAllErrors: () => void;
  showSomeErrors: (keys: string[]) => void;
  handleStripeValidation: (event: StripeCardElementChangeEvent) => void;
};

export function useValidation<T>({
  hasStripe = false,
  values,
  schema
}: UseValidationProps<T>) {
  const [visibleErrors, setVisibleErrors] = useState<string[]>([]);
  const [stripeComplete, setStripeComplete] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<StripeError | undefined>();

  const isCreditCard = useMemo(
    () => _.get(values, 'paymentMethod.id') === paymentMethods.creditCard,
    [values]
  );

  const validateStripe = useCallback(() => {
    if (!hasStripe) {
      return {};
    }

    if (!isCreditCard) {
      return {};
    }

    if (!stripeError && stripeComplete) {
      return {};
    }

    const error =
      stripeError?.message || 'Du måste fylla i dina kortuppgifter.';

    return { stripeCard: [error] };
  }, [hasStripe, isCreditCard, stripeComplete, stripeError]);

  const validation = useMemo(
    () => ({
      ...validate(values, schema),
      ...validateStripe()
    }),
    [schema, validateStripe, values]
  );

  const errors = useMemo(() => _.pick(validation, visibleErrors), [
    validation,
    visibleErrors
  ]);

  const logErrorToGtm = useCallback(
    (key: string) => {
      const messages = validation[key];

      if (!messages || !messages.length) {
        return; // no error message found
      }

      pushGTMEvent({
        category: 'Form errors',
        action: window.location?.pathname,
        label: messages[0]
      });
    },
    [validation]
  );

  const showError = useCallback(
    (key: string) => {
      if (visibleErrors.indexOf(key) !== -1) {
        return;
      }

      logErrorToGtm(key);
      setVisibleErrors((state) => [...state, key]);
    },
    [logErrorToGtm, visibleErrors]
  );

  const showSomeErrors = useCallback(
    (keysToShow: string[]) => keysToShow.forEach(showError),
    [showError]
  );

  const showAllErrors = useCallback(() => {
    const visibleErrors = _.map(schema, (_, key) => key);

    if (hasStripe) {
      visibleErrors.push(STRIPE_KEY);
    }

    visibleErrors.forEach(showError);
  }, [hasStripe, schema, showError]);

  const hideAllErrors = useCallback(() => setVisibleErrors([]), []);

  const hideError = useCallback(
    (key: string) => {
      const index = visibleErrors.indexOf(key);

      if (index !== -1) {
        return;
      }

      setVisibleErrors([
        ...visibleErrors.slice(0, index),
        ...visibleErrors.slice(index + 1)
      ]);
    },
    [visibleErrors]
  );

  const areValid = useCallback(
    (keysToCheck: string[]) => {
      const invalidInTestSet = _.pick(validation, keysToCheck);
      const numberOfInvalid = Object.keys(invalidInTestSet).length;

      return numberOfInvalid <= 0;
    },
    [validation]
  );

  const isValid = useCallback(
    (key: string) => {
      const keysToValidate = hasStripe
        ? { ...schema, [STRIPE_KEY]: true }
        : schema;

      const validated = _.omit(keysToValidate, _.keys(validation));

      if (_.has(validated, key)) {
        return true;
      }

      return false;
    },
    [hasStripe, schema, validation]
  );

  const isInvalid = useCallback((key: string) => _.has(errors, key), [errors]);

  const handleStripeValidation = useCallback(
    (event: StripeCardElementChangeEvent) => {
      hideError(STRIPE_KEY);

      setStripeComplete(event.complete);
      setStripeError(event.error);
    },
    [hideError]
  );

  useEffect(() => {
    const email = _.get(values, EMAIL_KEY)?.toLowerCase();
    const setUserId = _.get('handlers.setUserId', EMAIL_KEY);

    if (!email) return;
    if (!isValid(EMAIL_KEY)) return;
    if (typeof setUserId !== 'function') return;

    getHash(email).then(setUserId);
  }, [isValid, values]);

  const handleBlur = useCallback(
    (key: string) => {
      const value = _.get(values, key);

      if (!value) {
        return;
      }

      return showError(key);
    },
    [showError, values]
  );

  return useMemo<IValidation>(
    () => ({
      errors: errors,
      validation: validation,
      isValidated: _.isEmpty(validation),

      isValid,
      areValid,
      isInvalid,
      showError,
      hideError,
      handleBlur,
      showAllErrors,
      hideAllErrors,
      showSomeErrors,
      handleStripeValidation
    }),
    [
      areValid,
      errors,
      handleBlur,
      handleStripeValidation,
      hideAllErrors,
      hideError,
      isInvalid,
      isValid,
      showAllErrors,
      showError,
      showSomeErrors,
      validation
    ]
  );
}

type ValidationProps<T> = UseValidationProps<T> & {
  children: (validation: IValidation) => ReactElement<any>;
};

export function Validation<T>({ children, ...props }: ValidationProps<T>) {
  const validation = useValidation(props);

  return useMemo(() => children(validation), [children, validation]);
}

export default Validation;
