import React, { useCallback } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-width: 7.5rem;
  height: 2.375rem;
`;

const Label = styled.div`
  color: #4a4a4a;
  margin: 0 0.25rem;
  font-size: 0.925rem;
  user-select: none;
  text-align: center;
  flex: 2;
`;

type ButtonProps = {
  icon: string;
  disabled?: boolean;
};

const Button = styled.button<ButtonProps>`
  color: ${({ theme }) => theme.colors.darkBlue};
  filter: ${({ disabled }) =>
    disabled
      ? 'invert(93%) sepia(0%) saturate(0%) hue-rotate(220deg) brightness(87%) contrast(106%)'
      : null};
  border: 0;
  cursor: pointer;
  background: none;
  font-size: 1.25rem;
  padding: 0;
  height: 1.125rem;
  width: 1.125rem;
  margin: 0.5rem;
  flex: 1;
  background-image: ${({ icon }) => `url('${icon}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

type Props = {
  value: number;
  onChange: (value: number) => void;
};

export function QuantityStepper({ value, onChange }: Props) {
  const handleClick = useCallback(
    (delta: number) => () => onChange(value + delta),
    [onChange, value]
  );

  return (
    <Wrapper>
      <Button
        disabled={value <= 1}
        onClick={handleClick(-1)}
        icon="https://res.cloudinary.com/cancerfonden/image/upload/v1622108768/assets/icons/minus.svg"
      />
      <Label children={`${value} st`} />
      <Button
        onClick={handleClick(1)}
        icon="https://res.cloudinary.com/cancerfonden/image/upload/v1622108768/assets/icons/plus.svg"
      />
    </Wrapper>
  );
}
