import React from 'react';
import { ThemeProvider } from 'styled-components';
import { merge } from 'lodash';

// Helpers
import * as themes from '~/App/config/themes';

// Shared components
import Container from '~/App/shared/components/Container';
import { ChristmasDonation } from '~/App/shared/components/Donation';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

class ChristmasDonationForm extends React.Component {
  render() {
    const theme = merge({}, themes.global);
    const { data } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <BlockBackground backgroundColor="primary">
          <Container
            width="limited"
            paddingLeft="small"
            paddingRight="small"
            paddingTop="small"
            paddingBottom="small"
          >
            <ChristmasDonation data={data} />
          </Container>
        </BlockBackground>
      </ThemeProvider>
    );
  }
}

export default ChristmasDonationForm;
