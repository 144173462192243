import React from 'react';

export default class ProductImages extends React.Component {
  render() {
    const baseUrl = '//res.cloudinary.com/cancerfonden/image/upload';

    const defaultProductImages = [
      `${baseUrl}/v1435221280/product-images/default-1.jpg`,
      `${baseUrl}/v1435221280/product-images/default-2.jpg`,
      `${baseUrl}/v1435221280/product-images/default-3.jpg`,
      `${baseUrl}/v1435221280/product-images/default-4.jpg`,
      `${baseUrl}/v1435221280/product-images/default-5.jpg`,
      `${baseUrl}/v1435221280/product-images/default-6.jpg`
    ];

    const { productImages = defaultProductImages } = this.props;

    return this.props.children({
      productImages: productImages,
      defaultProductImage: 0
    });
  }
}
