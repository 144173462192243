import { DeepPartial } from 'ts-essentials';
import { MultiPartFormValues } from '../../MultiPartForm';
import { paymentMethods } from '~/App/config/paymentMethods';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues,
  GenericValues,
  AnalyticsValues
} from '../components/States';
import { gtmCustomDetails } from '~/App/config/gtmDetails';

type State = DeepPartial<
  AnalyticsValues &
    MultiPartFormValues &
    ContactCustomerValues &
    GenericValues &
    ProductValues &
    PaymentValues
>;
type Props = {
  campaignId?: number;
};

export const buildState = ({ campaignId }: Props): State => {
  return {
    product: {
      id: 3
    },
    productOptions: {
      product: {
        period: 'Once'
      },
      campaignActivityId: campaignId,
      pul: true
    },
    paymentMethod: {
      id: paymentMethods.swish,
      name: 'swish'
    },
    partOptions: {
      parts: 2,
      validationKeys: {
        1: ['productOptions.product.price'],
        2: ['swishPhoneNumber']
      }
    },
    gtm: gtmCustomDetails['3c']
  };
};
