import React, { ComponentProps, forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.span`
  cursor: pointer;
  display: block;
  height: 22px;
  margin-right: 7px;
`;

const Input = styled.input`
  clip: rect(0, 0, 0, 0);
  clip-path: polygon(0 0);
  position: absolute;
`;

type IconProps = {
  checked: boolean;
};

const Icon = styled.span<IconProps>`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  transition: border 0.3s ease, background 0.3s ease;

  ${({ checked }) =>
    checked &&
    css`
      background: #fff;
      border-color: ${({ theme }) => theme.colors.darkBlue};
    `};

  &:after {
    content: ' ';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.themeColors.primary};
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    ${({ checked }) =>
      checked &&
      css`
        opacity: 1;
        transform: scale(1);
      `};
  }
`;

type Props = ComponentProps<typeof Input>;

export const RadioButton = forwardRef((props: Props, ref) => (
  <Wrapper>
    <Icon checked={props.checked} />
    <Input type="radio" {...props} ref={ref} />
  </Wrapper>
));
