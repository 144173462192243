import { createContext, Dispatch, SetStateAction } from 'react';

import { LoginView } from '~/App/components/Header/constants';
import { HandleLoginResponse } from '../Login/LoginContext';

type Set<T> = Dispatch<SetStateAction<T>>;
type Func<T> = (_value: T) => void;

export type OpenModalSuccessCallback =
  | ((res: HandleLoginResponse) => void)
  | string
  | undefined;

export type OpenModalArguments = {
  onSuccess?: OpenModalSuccessCallback;
  view?: LoginView;
};

export type LoginModalContextValue = {
  showLoginModal: boolean;
  loginView: LoginView;
  innerHeight?: number;
  qrMode: boolean;
  mobileInitView: boolean;
  handlers: {
    setShowLoginModal: Set<boolean>;
    setLoginView: Set<LoginView>;
    setInnerHeight: Set<number | undefined>;
    setMobileInitView: Set<boolean>;
    toggleLogin: Func<boolean | undefined>;
    toggleQrMode: (value?: boolean) => void;
    openModal: Func<OpenModalArguments> | (() => void);
    closeModal: () => void;
    cancelLogin: () => void;
  };
};

const emptyFunc = <T>(value?: T) => value;

const defaultValue: LoginModalContextValue = {
  showLoginModal: false,
  loginView: 'private',
  qrMode: false,
  mobileInitView: false,
  handlers: {
    setShowLoginModal: emptyFunc,
    setLoginView: emptyFunc,
    setInnerHeight: emptyFunc,
    setMobileInitView: emptyFunc,
    toggleLogin: emptyFunc,
    toggleQrMode: emptyFunc,
    openModal: emptyFunc,
    closeModal: emptyFunc,
    cancelLogin: emptyFunc
  }
};

export const LoginModalContext = createContext<LoginModalContextValue>(
  defaultValue
);
