import axios, { CancelToken } from 'axios';
import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { uniqBy } from 'lodash';

import { RelatedItems, RelatedItem } from '~/types/RelatedBlock';

type Props = {
  initialItems: RelatedItems;
  page: number;
  totalPages: number;
  returnAll: boolean;
  getCellKey: (item: RelatedItem) => string | undefined;
  itemsFor: (
    page: number,
    cancelToken?: CancelToken
  ) => RelatedItems | Promise<RelatedItems>;
};

export function useItems({
  initialItems,
  page,
  returnAll,
  getCellKey,
  itemsFor
}: Props) {
  const [cache, setCache] = useState<Record<number, RelatedItems>>({
    [page]: initialItems
  });

  const hasRun = useRef(false);
  const cancelToken = useRef(axios.CancelToken.source());

  const load = useCallback(
    async (page: number) => {
      if (cache[page]) {
        return cache[page];
      }

      const task = itemsFor(page, cancelToken.current?.token);
      const items = task instanceof Promise ? await task : task;

      setCache((state) => ({
        ...state,
        [page]: items
      }));

      return items;
    },
    [cache, itemsFor]
  );

  const allItems = useMemo(() => {
    const values = Object.values(cache || {});
    const items = values.reduce((prev, current) => [...prev, ...current], []);
    const uniq = uniqBy(items, getCellKey);

    return uniq;
  }, [cache, getCellKey]);

  const items = useMemo(() => (returnAll ? allItems : cache[page] || []), [
    allItems,
    cache,
    page,
    returnAll
  ]);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    const token = cancelToken.current;

    return () => {
      token?.cancel();
    };
  }, []);

  return {
    load,
    items
  };
}
