import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { containerWidths } from '~/App/config/containerWidths';

// Shared components
import Container from '~/App/shared/components/Container';
import { ArrowSegment } from '~/App/shared/components/ArrowSegment/';

const ContentWrapper = styled.div`
  padding: 2rem 2rem 0;

  ${mq('≥small')`
    padding: 2rem 5rem 0;
  `};
`;

export default class TwoUpWrapper extends React.Component {
  constructor() {
    super();

    this.state = {
      elementStepWidth: containerWidths.limited
    };

    this.arrowSegmentWrapperElement = null;
    this.setArrowSegmentWrapperElementRef = (element) => {
      this.arrowSegmentWrapperElement = element;
    };
  }

  componentDidMount() {
    if (this.props.showAsTimeline) {
      this.setState({
        elementStepWidth: this.arrowSegmentWrapperElement.offsetWidth
      });
    }
  }

  render() {
    const stepArrowWidth = this.state.elementStepWidth / 2;

    const {
      width = 'normal',
      padding = {},
      showAsTimeline = false,
      firstTimelineSegment,
      lastTimelineSegment,
      timelineSegmentStyle
    } = this.props;

    if (showAsTimeline) {
      return (
        <Container
          width={width}
          paddingTop={padding.top}
          paddingLeft="none"
          paddingRight="none"
          paddingBottom={padding.bottom}
        >
          <div ref={this.setArrowSegmentWrapperElementRef}>
            <ArrowSegment
              arrowWidth={stepArrowWidth}
              firstSegment={firstTimelineSegment}
              lastSegment={lastTimelineSegment}
              backgroundColor={timelineSegmentStyle}
            >
              <ContentWrapper>{this.props.children}</ContentWrapper>
            </ArrowSegment>
          </div>
        </Container>
      );
    }

    return (
      <Container
        width={width}
        paddingTop={padding.top}
        paddingLeft="small"
        paddingRight="small"
        paddingBottom={padding.bottom}
      >
        {this.props.children}
      </Container>
    );
  }
}
