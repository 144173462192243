import React from 'react';
import styled from 'styled-components';

import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../States';
import { ContactFields } from '../../Forms/components/ContactFields';
import { usePreferredCustomerContactType } from '~/App/shared/hooks/use-preferred-customer-contact-type';
import { description2 } from '~/App/helpers/mixins';

const Wrapper = styled.div`
  :not(:first-child) {
    padding-top: 1rem;
  }
`;

const InfoText = styled.span`
  ${description2};
  display: block;
  line-height: 1.1875rem;
  margin-bottom: 1rem;
`;

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
};

export function FavourableInvoiceFields({ values, validation }: Props) {
  usePreferredCustomerContactType({
    preferred: 'company',
    values
  });

  return (
    <Wrapper>
      <InfoText>
        Din faktura kommer att skickas till dig med post inom 2-4 vardagar.
      </InfoText>
      <ContactFields
        values={values}
        validation={validation}
        company={{
          phone: true,
          addressRequired: false,
          nameRequired: false
        }}
      />
    </Wrapper>
  );
}
