import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ICollection } from '~/types/ICollection';

// Helpers
import mq from '~/App/helpers/mq';
import { heading3 } from '~/App/helpers/mixins';
import { buttonStyles } from '~/App/config/buttonStyles';
import { textHelper } from '~/App/shared/components/Donation/components/Forms/helpers/textHelper.js';

// Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import Link from '~/App/shared/components/Link';

import { CollectionImage } from '../CollectionImage';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;

  ${mq('≥small')`
    flex-basis: calc(50% - 2rem);
    max-width: calc(50% - 2rem);
  `};

  ${mq('≥mediumLarge')`
    flex-basis: calc(33% - 2rem);
    max-width: calc(33% - 2rem);
  `};

  a {
    img {
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 1rem 1rem 1.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const Heading = styled.h3`
  ${heading3};
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Paragraph = styled.p`
  width: 100%;
  margin: 0.5rem 0 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${mq('>small')`
  flex-direction: row;
  `};
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  margin-bottom: 1rem;

  ${mq('>small')`
    margin-bottom: 0rem;
  `};
`;

const DonationButton = styled(PrimaryButton)`
  min-width: auto;
  margin-top: auto;
`;

type Props = {
  collection: ICollection;
  className?: string;
};

export function CollectionCard({ collection, ...props }: Props) {
  const description = useMemo(
    () => textHelper.trimTextGreeting(collection.description, 2),
    [collection.description]
  );

  return useMemo(
    () => (
      <Wrapper {...props}>
        <Link to={`/insamlingar/${collection.slug}`}>
          <CollectionImage collection={collection} keepAspectRatio={true} />
        </Link>
        <ContentWrapper>
          <Heading>{collection.name}</Heading>
          <Paragraph>{description}</Paragraph>
          <ButtonWrapper>
            <StyledLink
              to={`/insamlingar/${collection.slug}`}
              children="Se insamlingen"
            />
            <DonationButton
              buttonType="link"
              buttonStyle={buttonStyles.primary}
              to={`/stod-oss/skank-till-en-insamling/${collection.slug}`}
              children="Ge en gåva"
            />
          </ButtonWrapper>
        </ContentWrapper>
      </Wrapper>
    ),
    [collection, description, props]
  );
}
