import React from 'react';
import styled from 'styled-components';

// Helpers
import { clearfix, heading5, description2 } from '~/App/helpers/mixins';

// Shared components
import Link from '~/App/shared/components/Link';

const Wrapper = styled(Link)`
  ${clearfix};
  display: block;
  margin-top: 2em;
  color: #000;
  background-color: #fff;

  &:first-child {
    margin-top: 0;
  }
`;

const Info = styled.div`
  padding: 1.5em;
`;

const Heading = styled.h2`
  ${heading5};
`;

const Url = styled.div`
  margin: 0.25em 0;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.themeColors.primary};
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.themeColors.primaryHover};
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FormattedDate = styled.div`
  color: #999;
`;

const Description = styled.div`
  ${description2};
`;

export default class SearchResult extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { url, date, heading, description } = this.props;

    return (
      <Wrapper to={url}>
        <Info>
          <Heading>{heading}</Heading>
          <Url>{url}</Url>
          <FormattedDate>{date}</FormattedDate>
          <Description>{description}</Description>
        </Info>
      </Wrapper>
    );
  }
}
