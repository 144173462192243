import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { formatMoney } from 'accounting';
import { isNaN } from 'lodash';

// Helpers
import { heading3 } from '~/App/helpers/mixins';

// Shared components
import { LotteryPreview } from '../../components/Forms/components/Summaries/components';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import { MultiPartFormValues } from '../../../MultiPartForm';
import { LotteryTicketStateValues } from '../../components/States';
import { Divider } from '../../../Blocks';
import { RightColumnText } from '../../components/Forms/components/RightColumnText';

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1.125rem;
`;

type Heading3Props = {
  paddingTop?: 'small';
};

const Heading3 = styled(FormHeading)<Heading3Props>`
  padding-top: ${({ paddingTop }) => (paddingTop === 'small' ? '1.5rem' : '0')};
`;

const Heading4 = styled.h4`
  ${heading3};
  margin-bottom: 0.75rem;
`;

type Props = {
  lotteryImage?: string;
  values: LotteryTicketStateValues & MultiPartFormValues;
  paddingTop?: 'small';
  infoTexts?: { icon: string; text: string }[];
};

export function LotterySummary({
  lotteryImage,
  values,
  paddingTop,
  infoTexts
}: Props) {
  const quantity = useMemo<number | null>(() => {
    const value: number | null = values.productOptions.lotteryTicket.quantity;

    if (isNaN(value)) return null;
    if (!value) return null;

    return value;
  }, [values.productOptions.lotteryTicket.quantity]);

  const totalPrice = useMemo<number | null>(() => {
    const fixedPrice = values.productOptions.lotteryTicket.price;

    if (!fixedPrice || !quantity) {
      return null;
    }

    if (isNaN(quantity) || isNaN(fixedPrice)) {
      return null;
    }

    return quantity * fixedPrice;
  }, [values.productOptions.lotteryTicket.price, quantity]);

  const ticketText = useMemo(() => {
    if (values.productOptions.product.period === 'Once') {
      return `100 kr x ${quantity || 0} ${quantity === 1 ? 'lott' : 'lotter'}`;
    }

    return `${quantity || 0} ${
      quantity === 1 ? 'lott' : 'lotter'
    } per tillfälle`;
  }, [quantity, values.productOptions.product.period]);

  const renderRightColumnText = useCallback(
    (infoText, key) => (
      <RightColumnText
        icon={infoText.icon}
        iconColor={infoText.iconColor}
        key={key}
        children={infoText.text}
      />
    ),
    []
  );

  return useMemo(
    () => (
      <>
        {lotteryImage && <LotteryPreview lotteryImage={lotteryImage} />}
        <Heading3 paddingTop={paddingTop}>Totalt att betala</Heading3>
        <AmountWrapper>
          <div children={ticketText} />
          <Heading4
            children={formatMoney(
              totalPrice || 0,
              'kr',
              0,
              ' ',
              undefined,
              '%v %s'
            )}
          />
          <Divider
            width="narrow"
            paddingLeft="none"
            paddingRight="none"
            height="2px"
            alternativeColor={true}
          />
          {infoTexts?.map(renderRightColumnText)}
        </AmountWrapper>
      </>
    ),
    [
      lotteryImage,
      paddingTop,
      totalPrice,
      ticketText,
      infoTexts,
      renderRightColumnText
    ]
  );
}
