import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { heading2, link } from '~/App/helpers/mixins';
import { Summery } from './components/Summery';
import { Form } from './components/Form';
import { OutOfStock } from './components/OutOfStock';
import { Redirect } from '~/App/shared/components/Redirect';

import { useValidation } from '~/App/shared/components/Validation';
import { useCommerceCheckoutState } from './hooks/useCommerceCheckoutState';
import { useCommerceCheckoutSchema } from './hooks/useCommerceCheckoutSchema';
import { useCommerceCheckoutSubmit } from './hooks/useCommerceCheckoutSubmit';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { mq } from '~/lib/mq';
import { TinyHeader } from '~/App/components/TinyHeader';

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPolar};
`;

const Container = styled.div`
  padding-bottom: 2rem;
  width: 37.5rem;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1rem 2rem;
`;

const Row = styled.div`
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
  align-items: center;

  ${mq('<small')`
    margin-top: 2.5rem;
  `};
`;

const Header = styled.div`
  ${heading2};
`;

const Link = styled(NavLink)`
  ${link};
  text-decoration: underline;
  font-size: 1.125rem;

  ${mq('<small')`
    font-size: 1rem;
  `};
`;

export function CommerceCheckout() {
  const shoppingCart = useShoppingCart();
  const values = useCommerceCheckoutState();
  const schema = useCommerceCheckoutSchema();
  const validation = useValidation({
    schema,
    values,
    hasStripe: false
  });

  const submit = useCommerceCheckoutSubmit({
    values,
    validation
  });

  const anyProductOutOfStock = useMemo(() => {
    for (const orderLine of shoppingCart.items) {
      if (orderLine.variant?.discontinued) {
        return true;
      }

      if (orderLine.variant?.stock?.status === 'OutOfStock') {
        return true;
      }
    }

    return false;
  }, [shoppingCart.items]);

  useEffect(() => {
    shoppingCart.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (shoppingCart.isEmpty) {
    return <Redirect to="/gavoshop/privat" />;
  }

  return (
    <Background>
      <TinyHeader />
      <Container>
        <Row>
          <Header children="Kassa" />
          <Link to="/gavoshop/privat">Fortsätt handla</Link>
        </Row>
        {anyProductOutOfStock && <OutOfStock />}
        <Summery />
        <Form
          state={values}
          isDisabled={anyProductOutOfStock}
          validation={validation}
          submit={submit}
        />
      </Container>
      {submit.redirectUrl && (
        <Redirect
          to={submit.redirectUrl}
          external={submit.redirectUrl.startsWith('http')}
        />
      )}
    </Background>
  );
}
