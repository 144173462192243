import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { loadData } from './Account.loadData';
import Account from './Account';

const View = (props: any) => {
  const pageContent = usePageContent();
  const children = useMemo(
    () => (pageContent ? <Account {...pageContent} {...props} /> : null),
    [pageContent, props]
  );

  return children;
};

View.loadData = loadData;

export default View;
