import React, { ReactNode, useEffect, useMemo, useState } from 'react';

// Helpers
import { poll, purchases } from '~/App/helpers/http';
import { IPurchaseShow } from '~/types/IPurchaseShow';

type Props = {
  purchase?: IPurchaseShow;
  children: (state: State) => ReactNode;
};

type Status = 'done' | 'fetching' | 'error' | 'timeout';

type State = {
  certificateStatus: Status;
  certificate: string | null;
  receipt: string | null;
  receiptStatus: Status;
  purchase?: IPurchaseShow;
};

export function FetchPurchaseDocuments({ children, purchase }: Props) {
  const [data, setData] = useState(purchase);

  const { hasCertificate, hasReceipt } = useMemo(
    () => ({
      hasCertificate: !!data?.product.files?.certificate,
      hasReceipt: !!data?.product.files?.receipt
    }),
    [data?.product.files?.certificate, data?.product.files?.receipt]
  );

  const receipt = data?.purchase.files?.receipt || null;
  const certificate = data?.purchase.files?.certificate || null;

  const isCertificateComplete = hasCertificate ? !!certificate : true;
  const isReceiptComplete = hasReceipt ? !!receipt : true;
  const isComplete = isCertificateComplete && isReceiptComplete;

  const publicToken = data?.purchase.publicToken;

  const [certificateStatus, setCertificateStatus] = useState<Status>(() =>
    isCertificateComplete ? 'done' : 'fetching'
  );
  const [receiptStatus, setReceiptStatus] = useState<Status>(() =>
    isReceiptComplete ? 'done' : 'fetching'
  );

  useEffect(() => {
    if (isComplete) {
      return;
    }

    if (!publicToken) {
      return;
    }

    const source = poll.CancelToken.source();

    poll
      .start({
        fn: () =>
          purchases.show({
            slug: publicToken,
            cancelToken: source.token
          }),
        done: ({ data }) => {
          if (hasCertificate) {
            if (!data.purchase.files?.certificate) {
              return false;
            }
          }

          if (hasReceipt) {
            if (!data.purchase.files?.receipt) {
              return false;
            }
          }

          return true;
        },
        onEach: ({ data }) => {
          setCertificateStatus(
            hasCertificate && data.purchase.files?.certificate
              ? 'done'
              : 'fetching'
          );
          setReceiptStatus(
            hasReceipt && data.purchase.files?.receipt ? 'done' : 'fetching'
          );
          setData(data);
        },
        timeout: 120000,
        interval: 3000
      })
      .catch((error: any) => {
        if (poll.isCancel(error)) {
          return false;
        }

        if (poll.isTimeout(error)) {
          setCertificateStatus('timeout');
          setReceiptStatus('timeout');

          return;
        }

        setCertificateStatus('error');
        setReceiptStatus('error');
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicToken]);

  return useMemo(
    () => (
      <>
        {children({
          receipt,
          receiptStatus,
          certificateStatus,
          certificate,
          purchase: data
        })}
      </>
    ),
    [children, certificate, certificateStatus, data, receipt, receiptStatus]
  );
}
