import React from 'react';
import styled from "styled-components";
import { useSelfTest } from '../context/useSelfTest';

import { IScreen, ISection } from "../types";

const Text = styled.p`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-size: 1rem;
`;

type Props = {
  section: ISection
  screen: IScreen
}

export function Progress({ section, screen }: Props) {
  const { numberOfQuestions, currentQuestion } = useSelfTest();

  if (section.type !== 'QuestionSection') {
    return null;
  }

  if (screen.type === 'SectionStartScreen') {
    return null;
  }

  if (!currentQuestion) {
    return <Text children={section.name} />;
  }

  return (
    <Text>
      Fråga {currentQuestion} av {numberOfQuestions} / {section.name}
    </Text>
  );
}