import { DeepPartial } from 'ts-essentials';
import { Location } from 'history';

import { CertificateValues, StateValues } from '~/App/shared/components/Donation/components/States';

import { IPaymentMethod } from '~/types/IPaymentMethod';
import { ICustomerContact } from '~/types/ICustomerContact';
import {
  ICmsProduct,
  CmsProductPriceType,
  CmsProductTarget
} from '~/types/ICmsProduct';

import { paymentMethods } from '~/App/config/paymentMethods';
import mediaHelper from '~/App/helpers/media-helper';
import { MultiPartFormValues } from '~/App/shared/components/MultiPartForm';

type LocationState = {
  productId?: number;
  customerContact?: ICustomerContact;
  paymentMethod?: IPaymentMethod;
  swish?: {
    phoneNumber?: string;
  };
};

function getCustomerContactType(
  product: ICmsProduct,
  state: LocationState = {}
) {
  if (product.target === CmsProductTarget.company) {
    return 'company';
  }

  return state.customerContact?.firstName ? 'manual' : 'automatic';
}

function getCustomerContact(state: LocationState = {}) {
  return state?.customerContact || {};
}

function getProductImage({ productImage }: ICmsProduct) {
  return mediaHelper.getUrl(
    productImage?.secure_url,
    {
      width: 192,
      height: 192,
      quality: 80,
      radius: 'max', // Set image radius for thank you-mail to outlook clients.
      gravity: 'face'
    },
    'png'
  );
}

function getPaymentMethod(product: ICmsProduct, state: LocationState = {}) {
  if (state.paymentMethod) {
    return state.paymentMethod;
  }

  if (product.target === CmsProductTarget.company) {
    return {
      id: paymentMethods.creditCard,
      name: 'Betalkort',
      slug: 'betalkort'
    };
  }

  if (product.target === CmsProductTarget.private) {
    return {
      id: paymentMethods.swish,
      name: 'Swish',
      slug: 'swish'
    };
  }

  return undefined;
}

function getPreloadedState(
  { productId }: ICmsProduct,
  state: LocationState = {}
) {
  return state?.productId === productId ? state : {};
}

type State = DeepPartial<StateValues & CertificateValues & MultiPartFormValues>;

export function buildState(product: ICmsProduct, location: Location): State {
  const state = getPreloadedState(product, location.state as LocationState);
  const isFixedPrice = product.priceType === CmsProductPriceType.fixedPrice;
  const imageUrl = getProductImage(product);

  return {
    product: {
      id: product.productId
    },
    productOptions: {
      product: {
        quantity: isFixedPrice ? product.priceFixed?.defaultQuantity : 1,
        price: isFixedPrice
          ? product.priceFixed?.price
          : product.priceFlexible?.defaultPrice
      },
      certificate: {
        heading: product.certificate?.heading,
        illustration: imageUrl,
        textGreeting: product.certificate?.description,
        description: product.certificate?.disclaimer
      },
      customerContact: getCustomerContact(state),
      campaignActivityId: product.campaignActivityId,
      pul: true
    },
    gtm: {
      variant: product.name
    },
    paymentMethod: getPaymentMethod(product, state),
    customerContactType: getCustomerContactType(product, state),
    swish: state.swish,
    partOptions: {
      parts: 3,
      name: 'product-checkout-form',
      validationKeys: {
        1: ['productOptions.product.quantity', 'productOptions.product.price'],
        2: [
          'productOptions.customerContact.company',
          'productOptions.customerContact.cid',
          'productOptions.customerContact.addressStreet',
          'productOptions.customerContact.addressZip',
          'productOptions.customerContact.addressCity',
          'productOptions.customerContact.firstName',
          'productOptions.customerContact.lastName',
          'productOptions.customerContact.phoneNumber',
          'productOptions.customerContact.ssn',
          'productOptions.customerContact.email'
        ]
      }
    }
    // Add default state
  };
}
