import React from 'react';

class Heading extends React.Component {
  render() {
    if (this.props.heading === '') {
      return null;
    }

    var fontSizeClass = ' heroTitle__title--' + this.props.headingFontSize,
      fontWeightClass = ' heroTitle__title--' + this.props.headingFontWeight;

    return (
      <div className="heroTitle">
        <h2
          className={'heroTitle__title' + fontSizeClass + fontWeightClass}
          dangerouslySetInnerHTML={{ __html: this.props.heading }}
        />
      </div>
    );
  }
}

Heading.displayName = 'EditorialHeroHeading';

Heading.defaultProps = {
  heading: '',
  headingFontSize: 'large',
  headingFontWeight: 'light'
};

export default Heading;
