import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { buttonStyles } from '~/App/config/buttonStyles';

// helpers
import mq from '~/App/helpers/mq';
import { heading4, arrowLink } from '~/App/helpers/mixins';
import { meCollections } from '~/App/helpers/http';

// shared components
import Link from '~/App/shared/components/Link';
import { AccountColumn } from '~/App/views/Account/shared/components/AccountColumn';
import { NoResultContent } from '~/App/views/Account/shared/components/NoResultContent';
import { PrimaryButton } from '~/App/shared/components/Elements';

// local components
import { CreateCollectionButton } from './components/CreateCollectionButton';
import { Card } from './components/Card';
import { IMeCollection } from '~/types/ICollection';

const Heading4 = styled.h4`
  ${heading4};
  color: ${({ theme }) => theme.themeColors.primary};
  margin: 0 0 2rem 0;
`;

const ArrowLink = styled(Link)`
  ${arrowLink};
  line-height: 1.25rem;
  margin-top: 0;

  ${mq('≥small')`
    line-height: 1.5rem;
  `};
`;

const ShowMoreButton = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
`;

const PER_PAGE = 10;

type Props = {
  data: IMeCollection[];
};

export function Collections({ data }: Props) {
  const [page, setPage] = useState(1);
  const [isVisible, setIsVisible] = useState(true);
  const [collections, setCollections] = useState(data);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadMore = useCallback(
    async (event) => {
      event.preventDefault();
      event.currentTarget.blur();

      const nextPage = page + 1;

      setPage(nextPage);
      setIsLoadingMore(true);

      try {
        const { data } = await meCollections.index({
          params: { page: nextPage, per_page: PER_PAGE }
        });

        setCollections([...collections, ...data]);
        setIsVisible(data.length === PER_PAGE);
        setIsLoadingMore(false);
      } catch (error) {
        console.log(error);

        setIsLoadingMore(false);
      }
    },
    [collections, page]
  );

  const children = useMemo(
    () => collections.map((x) => <Card key={x.id} collection={x} />),
    [collections]
  );

  const hasCollections = collections.length > 0;

  return (
    <>
      <>
        {!hasCollections && (
          <AccountColumn>
            <NoResultContent>
              <Heading4>Här kommer du kunna hantera dina insamlingar.</Heading4>
              <p>
                <ArrowLink to="/stod-oss/insamlingar/starta-insamling">
                  Starta en insamling
                </ArrowLink>
              </p>
            </NoResultContent>
          </AccountColumn>
        )}
        {hasCollections && (
          <>
            {children}
            {isVisible && collections.length >= PER_PAGE && (
              <ShowMoreButton
                buttonStyle={buttonStyles.primary}
                onClick={loadMore}
                isLoading={isLoadingMore}
                children="Visa fler"
              />
            )}
          </>
        )}
      </>
      <CreateCollectionButton />
    </>
  );
}
