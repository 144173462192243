import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { toNumber } from 'lodash';

import styled from 'styled-components';
import { formatNumber } from 'accounting';

// Shared components
import Text from '~/App/shared/components/Fields/Text';
import {
  Item,
  Wrapper
} from '~/App/shared/components/Fields/RadioButtonHorizontal/';
import { IValidation } from '~/App/shared/components/Validation';

const CustomWrapper = styled.div`
  margin-top: 0.5rem;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.themeColors.fadedPrimary};
`;

const CustomTitle = styled.h4`
  color: black;
  font-size: 1rem;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
`;

type Layout = '50' | 'auto';
type Value = string | number | null;
type Props = {
  quantities: Value[];
  selectedQuantity: Value;
  customQuantity: boolean;
  customTitle?: string;
  setQuantity: (_: number) => void;
  setCustomQuantity: (_: boolean) => void;
  resetQuantity: () => void;
  validationKey: string;
  validation: IValidation;
  tinyLayout: Layout;
  smallLayout: Layout;
  largeLayout: Layout;
};

export default function QuantitySelector({
  quantities,
  customQuantity,
  selectedQuantity,
  tinyLayout,
  smallLayout,
  largeLayout,
  validation,
  validationKey,
  customTitle = 'Antal',
  setCustomQuantity,
  setQuantity,
  resetQuantity
}: Props) {
  const asNumber = useCallback((val: string) => {
    const onlyNumbers = val?.replace(/[^0-9]+/g, '');
    const number = parseInt(onlyNumbers, 10);

    return number;
  }, []);

  const renderQuantity = useCallback(
    (quantity: Value) => (
      <Item
        key={quantity}
        id={`quantities-${quantity}`}
        value={quantity}
        checked={!customQuantity && `${selectedQuantity}` === `${quantity}`}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = asNumber(event.target.value);

          setCustomQuantity(false);
          setQuantity(value);
        }}
        label={`${formatNumber(toNumber(quantity), 0, ' ')} st`}
        name="price"
        tinyLayout={tinyLayout}
        smallLayout={smallLayout}
        largeLayout={largeLayout}
      />
    ),
    [
      customQuantity,
      largeLayout,
      selectedQuantity,
      smallLayout,
      tinyLayout,
      setCustomQuantity,
      setQuantity,
      asNumber
    ]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value;
      const value = asNumber(rawValue);

      if (isNaN(value)) {
        resetQuantity();
        return;
      }

      setQuantity(value);
    },
    [setQuantity, asNumber, resetQuantity]
  );

  return useMemo(
    () => (
      <>
        <Wrapper>
          {quantities.map(renderQuantity)}
          <Item
            value={undefined}
            checked={customQuantity}
            onChange={() => {
              setCustomQuantity(true);
              resetQuantity();
            }}
            label="Valfritt"
            name="quantity"
            tinyLayout={tinyLayout}
            smallLayout={smallLayout}
            largeLayout={largeLayout}
          />
        </Wrapper>

        {customQuantity && (
          <CustomWrapper>
            <CustomTitle children={customTitle} />
            <Text
              name="quantity"
              type="tel"
              value={selectedQuantity ?? ''}
              onBlur={validation.handleBlur.bind(undefined, validationKey)}
              errors={validation.errors[validationKey]}
              isValid={validation.isValid(validationKey)}
              onChange={handleChange}
              isInvalid={validation.isInvalid(validationKey)}
              maxLength="6"
              placeholder="Valfritt antal"
              suffix="st"
            />
          </CustomWrapper>
        )}
      </>
    ),
    [
      customQuantity,
      customTitle,
      handleChange,
      largeLayout,
      quantities,
      renderQuantity,
      resetQuantity,
      selectedQuantity,
      setCustomQuantity,
      smallLayout,
      tinyLayout,
      validation,
      validationKey
    ]
  );
}
