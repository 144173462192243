import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import mq from '~/App/helpers/mq';
import { heading1, primaryButton } from '~/App/helpers/mixins';

import { IQuestionScreen, IQuestionAlternative } from '../types';
import { useSelfTest } from '../context/useSelfTest';
import { IQuestionAnswer } from '~/types/IQuestionSession';

const Wrapper = styled.div`
  width: 100%;
  margin: 1rem auto;
`;

const Heading = styled.h1`
  ${heading1}
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  max-width: 65ch;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;

  ${mq('≥small')`
    font-size: 5rem;
  `};
`;

const Body = styled.p`
  margin-bottom: 2rem;
  color: ${(props) => props.theme.colors.white};
  max-width: 65ch;
`;

const QuestionImage = styled.img`
  display: block;
  max-width: min(430px, 100%);
  margin: 2rem 0;

  ${mq('≥small')`
    margin: 3rem 0;
  `};
`;

type AlternativeProps = {
  isActive: boolean;
};

const AlternativesWrapper = styled.div`
  ${mq('≥small')`
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(270px,1fr));
  `};
`;

const Alternative = styled.button<AlternativeProps>`
  ${primaryButton};
  display: block;
  min-width: 270px;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: ${({ isActive }) => isActive && 'white'};
  color: ${(props) => props.theme.colors.white};
  color: ${({ isActive }) => isActive && 'black'};
  border-radius: 0.5rem;
  border: 1px solid white;
  border-color: ${({ isActive }) => isActive && 'black'};
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

  ${mq('≥small')`
    margin: 0;
    max-width: none;
  `};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.black};
    background-color: white;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25);
    border: 1px solid black;
  }

  &:after {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 1px black solid;
    animation: shrink-bounce 300ms cubic-bezier(0.4, 0, 0.23, 1);
  }

  &:before {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    position: absolute;
    top: 1.5rem;
    right: 2.1rem;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
  }

  @keyframes shrink-bounce {
    0% {
      transform: scale(1) translateY(-50%);
    }
    33% {
      transform: scale(0.85) translateY(-57.5%);
    }
    100% {
      transform: scale(1) translateY(-50%);
    }
  }

  @keyframes checkbox-check {
    0% {
      width: 0;
      height: 0;
      border-color: black;
      transform: translate3d(0, 0, 0) rotate(45deg);
    }
    33% {
      width: 0.4em;
      height: 0;
      transform: translate3d(0.4em, 0, 0) rotate(45deg);
    }
    100% {
      width: 0.4em;
      height: 0.7em;
      border-color: black;
      transform: translate3d(0.4em, -0.7em, 0) rotate(45deg);
    }
  }
`;

type Props = {
  screen: IQuestionScreen;
};

export function QuestionScreen({ screen }: Props) {
  const { setAnswer, getAnswer } = useSelfTest();

  const value = useMemo(() => getAnswer<IQuestionAnswer>(screen.id), [
    getAnswer,
    screen.id
  ]);

  const handleSelect = useCallback(
    (alternative: IQuestionAlternative) => () =>
      setAnswer({
        alternativeId: alternative.id,
        alternativeLabel: alternative.name,
        questionId: screen.id,
        questionLabel: screen.questionBody,
        value: alternative.value
      }),
    [setAnswer, screen]
  );

  const renderAlternative = useCallback(
    (alternative: IQuestionAlternative) => (
      <Alternative
        key={alternative.name}
        children={alternative.name}
        isActive={value?.alternativeId === alternative.id}
        onClick={handleSelect(alternative)}
      />
    ),
    [handleSelect, value]
  );

  return useMemo(
    () => (
      <Wrapper>
        <Heading>{screen.questionHeading}</Heading>
        <Body>{screen.questionBody}</Body>
        {screen.imageUrl && <QuestionImage src={screen.imageUrl} />}
        <AlternativesWrapper
          children={screen.alternatives.map(renderAlternative)}
        />
      </Wrapper>
    ),
    [
      renderAlternative,
      screen.alternatives,
      screen.questionHeading,
      screen.questionBody,
      screen.imageUrl
    ]
  );
}
