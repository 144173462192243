import React from 'react';
import styled from 'styled-components';
import { formatDate } from '~/App/helpers/date.service';

// Helpers
import {
  sectionHeader,
  arrowLink,
  externalLink,
  downloadLink
} from '~/App/helpers/mixins';
import {
  getLinkType,
  linkTypes,
  stripInternalUrl
} from '~/App/helpers/url-helper';

// Shared components
import Container from '~/App/shared/components/Container';
import { Divider } from '~/App/shared/components/Blocks';
import Author from '~/App/shared/components/Author/Author';
import ShareButtons from '~/App/shared/components/ShareButtons';
import Link from '~/App/shared/components/Link';
import Feedback from '~/App/shared/components/Feedback';

// Local components
import { FooterImages } from '.';

const PageFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: ${({ hasSiblingAfter }) => (hasSiblingAfter ? '0' : '2rem')};
`;

const PeopleWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding: 0 1rem;
`;

const DateInfo = styled.p`
  padding: 1rem 1rem 0 1rem;
`;

const Heading = styled.h3`
  ${sectionHeader};
  color: ${({ theme }) => theme.related.headingColor};
`;

const StyledLink = styled(Link)`
  ${({ type }) => {
    switch (type) {
      case linkTypes.internal:
        return arrowLink;

      case linkTypes.external:
        return externalLink;

      case linkTypes.file:
        return downloadLink;

      default:
        return arrowLink;
    }
  }}
  padding-right: ${({ type }) =>
    type === linkTypes.file
      ? '1.625rem'
      : type === linkTypes.external
      ? '1.75rem'
      : '1rem'};

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export default class PageFooter extends React.Component {
  render() {
    const {
      sharingIcons = {},
      author = {},
      editor = {},
      auditor = {},
      auditDate = null,
      publishDate = null,
      width = 'tight',
      images = [],
      links = []
    } = this.props;

    const shouldShowSharingIcons =
      sharingIcons.facebook ||
      sharingIcons.twitter ||
      sharingIcons.email ||
      sharingIcons.print;

    const shouldShowLowerFooter =
      auditDate ||
      publishDate ||
      Object.keys(auditor).length !== 0 ||
      Object.keys(editor).length !== 0 ||
      Object.keys(author).length !== 0;

    const shouldShowExtrasFooter = images.length > 0 || links.length > 0;

    const extraImages = (images) => {
      if (images.length > 0) {
        return (
          <Container
            paddingTop="tiny"
            paddingLeft="small"
            paddingRight="small"
            paddingBottom="small"
          >
            <Heading>Pressmaterial</Heading>
            <FooterImages images={images} />
          </Container>
        );
      } else {
        return null;
      }
    };

    const extraLinks = (links) => {
      if (links.length > 0) {
        return (
          <Container
            paddingTop="tiny"
            paddingLeft="small"
            paddingRight="small"
            paddingBottom="small"
          >
            <Heading>Läs mer</Heading>
            {links.map((link) => (
              <StyledLink
                type={getLinkType(link.url)}
                to={stripInternalUrl(link.url)}
                key={link.url}
              >
                {link.title}
              </StyledLink>
            ))}
          </Container>
        );
      } else {
        return null;
      }
    };

    return (
      <Container width={width}>
        {shouldShowSharingIcons && (
          <Container paddingLeft="small" paddingRight="small">
            <PageFooterWrapper hasSiblingAfter={shouldShowLowerFooter}>
              <ShareButtons
                showFacebook={sharingIcons.facebook}
                showX={sharingIcons.twitter}
                showEmail={sharingIcons.email}
                showPrint={sharingIcons.print}
              />
            </PageFooterWrapper>
            <Feedback />
          </Container>
        )}
        {shouldShowLowerFooter && (
          <React.Fragment>
            <Container paddingTop="tiny">
              <Divider />
            </Container>
            {auditDate && (
              <DateInfo>
                Granskad {formatDate(auditDate || '1970-01-01', 'D MMMM YYYY')}
              </DateInfo>
            )}
            {publishDate && (
              <DateInfo>
                Publicerad{' '}
                {formatDate(publishDate || '1970-01-01', 'D MMMM YYYY')}
              </DateInfo>
            )}
            <PeopleWrapper>
              {Object.keys(auditor).length !== 0 && (
                <Author
                  author={auditor}
                  heading="Granskare"
                  linkAuthor={true}
                />
              )}
              {Object.keys(editor).length !== 0 && (
                <Author
                  author={editor}
                  heading="Sidansvarig"
                  linkAuthor={true}
                />
              )}
              {Object.keys(author).length !== 0 && (
                <Author
                  author={author}
                  heading="Författare"
                  linkAuthor={true}
                />
              )}
            </PeopleWrapper>
          </React.Fragment>
        )}
        {shouldShowExtrasFooter && (
          <React.Fragment>
            <Container paddingTop="tiny" paddingBottom="tiny">
              <Divider />
            </Container>
            {extraImages(images)}
            {extraLinks(links)}
          </React.Fragment>
        )}
      </Container>
    );
  }
}
