import { useMemo, useCallback } from 'react';

import { pushGTMEvent } from '~/App/helpers/gtm-helper';
import { IScreen, ISection } from '../../types';

type Props = {
  screen: IScreen | null;
  section: ISection | null;
  question: number | null;
};

export function useTracking({ question, screen, section }: Props) {
  const trackStart = useCallback(
    () =>
      pushGTMEvent({
        category: 'Självtest',
        action: 'Starta testet',
        label: 'Kolla risken'
      }),
    []
  );

  const trackNavigation = useCallback(
    (action: 'Nästa' | 'Föregående' | 'Visa resultat') => {
      const getScreenLabel = () => {
        switch (screen?.type) {
          case 'SectionStartScreen':
            return screen.heading;

          case 'QuestionScreen':
            return screen.questionHeading;

          case 'BMIScreen':
            return screen.heading;

          case 'AboutScreen':
            return screen?.heading;
        }

        return null;
      };
      const getSectionLabel = () => {
        if (screen?.type === 'SectionStartScreen') return null;
        if (screen?.type === 'AboutScreen') return null;

        switch (section?.type) {
          case 'QuestionSection':
            return section.name;
        }

        return null;
      };

      const getValue = () => {
        if (screen?.type === 'QuestionScreen') return question;
        if (screen?.type === 'BMIScreen') return question;
        if (screen?.type === 'AboutScreen') return 19;

        return null;
      };

      pushGTMEvent({
        category: 'Självtest',
        action,
        label: ['Kolla risken', getSectionLabel(), getScreenLabel()]
          .filter((x) => x)
          .join(' | '),
        value: getValue()
      });
    },
    [question, screen, section]
  );

  return useMemo(() => ({ trackNavigation, trackStart }), [
    trackNavigation,
    trackStart
  ]);
}
