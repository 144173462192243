export function randomNumber(seed: string) {
  if (!seed) {
    return -1;
  }

  let newSeed = 0;

  for (let i = 0; i < seed.length; i += 1) {
    newSeed += seed.charCodeAt(i) * Math.pow(10, i);
  }

  const x = Math.sin(newSeed) * 10000;

  return x - Math.floor(x);
}
