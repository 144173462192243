import React from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { headingFont } from '~/App/helpers/mixins';
import { rotate360 } from '~/App/helpers/animations';

const InfoText = styled.span`
  display: block;
  text-align: center;
  margin: 0.75rem 0;

  picture {
    display: block;
    width: 5.5rem;
    margin: 1.25rem auto;
  }
`;

const WaitingTitle = styled.h3`
  ${headingFont};
  font-size: 1rem;
  line-height: 1.25;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
  max-width: 11rem;
  margin: 0 auto 0.5rem auto;
`;

const WaitingText = styled.p`
  ${headingFont};
  font-size: 0.75rem;
  line-height: 1.25;
  text-align: center;
  max-width: 14rem;
  margin: 0 auto 1rem auto;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 auto;
`;

const BankIdSpinner = styled.div`
  width: 100%;
  height: 100%;
  animation: ${rotate360} 0.3s linear infinite;
  border-top: 2px solid ${(props) => props.theme.bankIdSpinner.primaryColor};
  border-right: 2px solid ${(props) => props.theme.bankIdSpinner.secondaryColor};
  border-bottom: 2px solid
    ${(props) => props.theme.bankIdSpinner.secondaryColor};
  border-left: 2px solid ${(props) => props.theme.bankIdSpinner.primaryColor};
  border-radius: 100%;
  content: ' ';
  z-index: 1;
`;

export default class BankIdWrapper extends React.Component {
  render() {
    return (
      <InfoText>
        <picture>
          <source
            srcSet={`
              ${mediaHelper.getUrl(
                'https://res.cloudinary.com/cancerfonden/image/upload/v1574858696/assets/phone-bankid.png',
                { width: 176 }
              )} 2x,
              ${mediaHelper.getUrl(
                'https://res.cloudinary.com/cancerfonden/image/upload/v1574858696/assets/phone-bankid.png',
                { width: 88 }
              )} 1x
            `}
          />
          <img
            src={mediaHelper.getUrl(
              'https://res.cloudinary.com/cancerfonden/image/upload/v1574858696/assets/phone-bankid.png',
              { width: 88 }
            )}
            alt="Mobilt BankID"
          />
        </picture>
        <WaitingTitle>Öppna din BankID-app</WaitingTitle>
        <WaitingText>
          Om inte BankID-appen öppnas automatiskt, öppna din app manuellt.
        </WaitingText>
        <WaitingText>
          Det kan ta upp till 45 sekunder att få återkoppling från din bank
          efter att du legitimerat dig.
        </WaitingText>
        <SpinnerWrapper>
          <BankIdSpinner />
        </SpinnerWrapper>
      </InfoText>
    );
  }
}
