import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';

import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { SidebarModal } from '~/App/shared/components/SidebarModal';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';

import { OrderLineItem } from './components/OrderLineItem';
import { FreeShipping } from './components/FreeShipping';
import { EmptyShoppingCart } from './components/EmptyShoppingCart';
import { BottomNavigation } from './components/BottomNavigation';
import { useScrollLock } from '~/App/contexts/ScrollLock';

const Container = styled.div`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  overflow-y: auto;
  z-index: 101;

  ${mq('<mediumLarge')`
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #d4d4d4;
  align-items: center;
  padding-bottom: 1.375rem;

  ${mq('<mediumLarge')`
    padding-bottom: 0.75rem;
  `};
`;

const TotalPriceStyle = styled.h2`
  font-weight: bold;
  font-size: 2rem;
  color: #001489;
  align-self: center;

  ${mq('<mediumLarge')`
    font-size: 1.375rem;
  `};
`;

const ShippingCost = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: solid 2px #d4d4d4;
  align-items: center;
  font-size: 1.125rem;

  ${mq('<mediumLarge')`
    margin-top: 1.75rem;
    padding-top: 1rem;
  `};
`;

const Headline = styled.h3`
  font-size: 1.125rem;
  color: #4a4a4a;
  font-weight: bold;

  ${mq('<mediumLarge')`
    font-size: 0.875rem;
  `};
`;

export function ShoppingCart() {
  const shoppingCart = useShoppingCart();
  const scrollLock = useScrollLock();

  useEffect(() => scrollLock.lock(shoppingCart.isOpen), [
    scrollLock,
    shoppingCart.isOpen
  ]);

  const [validation, setValidation] = useState<Record<string, boolean>>({});

  const handleValidationChange = (id: string, childData: boolean) => {
    setValidation((state) => ({ ...state, [id]: childData }));
  };

  const isValidated = useMemo(
    () => Object.values(validation).every((item) => item === true),
    [validation]
  );

  const renderItem = useCallback(
    (item: ICommerceOrderLine) => (
      <OrderLineItem
        item={item}
        key={item.id}
        onValidationChange={handleValidationChange}
      />
    ),
    []
  );

  const content = useMemo(() => {
    if (shoppingCart.isEmpty) {
      return <EmptyShoppingCart />;
    }

    return (
      <>
        <Container>
          <Header>
            <Headline>VARUKORG</Headline>
            <TotalPriceStyle>
              Totalt {shoppingCart.totalPrice} kr
            </TotalPriceStyle>
          </Header>
          <FreeShipping />
          <div children={shoppingCart.items.map(renderItem)} />
          <ShippingCost>
            <div>Frakt</div>
            <div>
              <b children={`${shoppingCart.shippingPrice} kr`} />
            </div>
          </ShippingCost>
        </Container>
        <BottomNavigation isValidated={isValidated} />
      </>
    );
  }, [
    renderItem,
    shoppingCart.isEmpty,
    shoppingCart.items,
    shoppingCart.shippingPrice,
    shoppingCart.totalPrice,
    isValidated
  ]);

  return (
    <SidebarModal
      isOpen={shoppingCart.isOpen}
      isEmpty={shoppingCart.isEmpty}
      onClose={shoppingCart.close}
      children={content}
    />
  );
}
