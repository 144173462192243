import React from 'react';
import BlockArea from '~/App/shared/components/BlockArea';

type Props = {
  block: any;
};

export function Block({ block }: Props) {
  if (!block) {
    return null;
  }

  return <BlockArea blocks={[block]} />;
}
