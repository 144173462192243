import styled from 'styled-components';
import { mq } from '~/lib/mq';

type BackgroundProps = {
  src: string;
};

export const Background = styled.div<BackgroundProps>`
  background-image: url('${({ src }) => src}');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3.5rem 0;
  overflow: hidden;

  ${mq('<medium')`
    padding: 0;
  `};
`;
