import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { card } from '~/App/helpers/mixins';
import { useQuery } from '~/App/shared/hooks/use-query';
import { mq } from '~/lib/mq';
import { IAnswer } from '~/types/IQuestionSession';
import { IQuestionSection } from '../../../SelfTest/types';
import { Item } from './components/Item';

const Wrapper = styled.div`
  ${card};
  height: auto;
  padding: 0 !important;
  overflow-x: auto;
  border-radius: 0px !important;
`;

const Content = styled.div`
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  position: relative;
`;

type IndicatorProps = {
  index: number;
  numberOfSections: number;
};

const Indicator = styled.div<IndicatorProps>`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  height: 0.5rem;
  position: absolute;
  left: ${({ index }) => `${index * 30}vw`};
  transition: left 0.3s;
  bottom: 0px;
  width: 30vw;

  ${mq<IndicatorProps>('≥small')`
    width: ${({ numberOfSections }) => 100 / numberOfSections}%;
    left: ${({ index, numberOfSections }) =>
      `${index * (100 / numberOfSections)}%`};
  `};
`;

type Query = {
  section: string;
};

type Props = {
  sections: IQuestionSection[];
  answers: IAnswer[];
};

export function Navigation({ sections, answers }: Props) {
  const query = useQuery<Query>();
  const renderSection = useCallback(
    (section: IQuestionSection, index) => (
      <Item key={index} section={section} answers={answers} />
    ),
    [answers]
  );

  const selectedIndex = useMemo(
    () => sections.map((x) => x.id).indexOf(query.section),
    [query.section, sections]
  );

  return (
    <Wrapper>
      <Content children={sections.map(renderSection)} />
      <Content>
        <Indicator index={selectedIndex} numberOfSections={sections.length} />
      </Content>
    </Wrapper>
  );
}
