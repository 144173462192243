import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';

import { IDeceasedContact } from '~/types/IDeceasedContact';

function hexToRGB(hex: string, alpha = 1) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => hexToRGB(theme.colors.darkApple, 0.1)};
  padding: 1.5rem;
  margin-top: 1rem;
  display: flex;
  align-items: start;

  ${mq('<small')`
    flex-direction: column;
  `}
`;

const Content = styled.div``;

const Image = styled.img`
  margin: 0.5rem 1rem 0.5rem 0;
  width: 28px;
`;

const Title = styled.div`
  font-size: 1.375rem;
  font-weight: bold;

  ${mq('<small')`
    font-size: 1.125rem;
  `}
`;

const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.25rem;

  ${mq('<small')`
    font-size: 0.75rem;
  `}
`;

const Small = styled.div`
  font-size: 0.875rem;
  margin-top: 0.25rem;

  ${mq('<small')`
    font-size: 0.75rem;
  `}
`;
const SmallWithTopMargin = styled.div`
  font-size: 0.875rem;
  margin-top: 1rem;

  ${mq('<small')`
    font-size: 0.75rem;
  `}
`;

const Link = styled.a`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  &:hover {
    cursor: pointer;
    color: #3547ad;
  }
`;

const EditRow = styled.div`
  align-items: center;
  display: flex;
  margin-top: 1rem;
`;

const PenImage = styled.img`
  margin: 0.5 0.5 0.5 0;
  width: 28px;
`;

type Props = {
  value: IDeceasedContact | null;
  openModal: () => void;
};

export function Preview({ value, openModal }: Props) {
  const lifeSpanLabel = useMemo(() => {
    if (!value) {
      return null;
    }

    const birthDate = value?.birth?.date ? dayjs(value?.birth.date) : null;
    const birthDateFormatted = birthDate?.format('D MMM YYYY');
    const deathDate = value?.death?.date ? dayjs(value.death.date) : null;
    const deathDateFormatted = deathDate?.format('D MMM YYYY');

    const lifeSpan = [birthDateFormatted, deathDateFormatted]
      .filter((x) => x)
      .join(' - ');

    return lifeSpan;
  }, [value]);

  const funeralDateFormatted = useMemo(() => {
    if (!value) {
      return null;
    }

    const deathDate = value?.funeral?.date ? dayjs(value.funeral.date) : null;
    const deathDateFormatted = deathDate?.format('D MMM YYYY');
    const text = 'Begravning';
    const deathDateAndText = [text, deathDateFormatted].join(' ');

    return deathDateAndText;
  }, [value]);

  if (!value) {
    return null;
  }

  return (
    <Wrapper>
      <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1632836066/assets/icons/icons-ui-checkmark.svg" />
      <Content>
        <Title>
          {value.firstName} {value.lastName}
        </Title>
        <Small children={lifeSpanLabel} />
        <Small children={value.funeral?.place} />
        <Small children={funeralDateFormatted} />
        {value.funeralHome?.name && (
          <SubTitle children={value.funeralHome?.name} />
        )}
        {value.note && <SmallWithTopMargin children={value?.note} />}

        {!value.id && (
          <EditRow>
            <PenImage src="https://res.cloudinary.com/cancerfonden/image/upload/v1634299920/assets/icons/icons-ui-edit.svg" />
            <Link onClick={openModal}>Ändra</Link>
          </EditRow>
        )}
      </Content>
    </Wrapper>
  );
}
