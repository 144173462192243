import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
    0% { transform: rotateZ(-360deg); }
    100% { transform: rotateZ(0deg); }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
`;

const Circle = styled.div`
  animation: ${animation} 0.3s linear infinite;
  border-radius: 100%;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #666;
  border-right: 2px solid #ccc;
  border-top: 2px solid #666;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  z-index: 1;
  left: 50%;
  margin-right: 0.75rem;
`;

const Text = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

type Props = {
  fileName: string;
};

export function Spinner({ fileName }: Props) {
  return (
    <Wrapper>
      <Circle />
      <Text>Skapar {fileName}...</Text>
    </Wrapper>
  );
}
