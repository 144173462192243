import React from 'react';
import classNames from 'classnames';

import { purchases } from '~/App/helpers/http';
import { withRouter } from '~/App/hocs/with-router';
import mediaHelper from '~/App/helpers/media-helper';

import EngagementItem from '~/App/shared/components/EngagementItem';
import { ImageUploadButton } from '~/App/shared/components/ImageUploadButton';

class ThanksForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleName = this.handleName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImageSelect = this.handleImageSelect.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.handleAvatarUpload = this.handleAvatarUpload.bind(this);

    this.state = {
      imageUrl: props.productImages[props.defaultProductImage],
      avatarUrl: '',
      socialName: ''
    };
  }

  handleImageUpload(image) {
    const imageUrl = mediaHelper.getUrl(image.secure_url, {
      crop: 'fill',
      width: 800,
      height: 600
    });

    this.setState({ imageUrl: imageUrl });
  }

  handleAvatarUpload(image) {
    const avatarUrl = mediaHelper.getUrl(image.secure_url, {
      crop: 'fill',
      width: 400,
      height: 400
    });

    this.setState({ avatarUrl: avatarUrl });
  }

  handleImageSelect(productImage) {
    this.setState({ imageUrl: productImage });
  }

  handleName(event) {
    this.setState({ socialName: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      const { data = {} } = await purchases.social({
        publicToken: this.props.purchase.publicToken,
        data: {
          purchase: {
            social: {
              imageUrl: this.state.imageUrl,
              anonymous: this.state.socialName === '',
              profile: {
                name: this.state.socialName,
                imageUrl: this.state.avatarUrl,
                location: ''
              }
            }
          }
        }
      });

      this.props.history.push(`/dela/${data.purchase.publicToken}`);
    } catch (errors) {
      console.log(errors);

      this.setState({ status: 'idle' });
    }
  }

  render() {
    const { imageUrl = '' } = this.state;
    const { purchase: { amount = '' } = {}, productImages = [] } = this.props;

    return (
      <form action="" onSubmit={this.handleSubmit} className="form" noValidate>
        <div className="form__section">
          <div className="formSharePreview">
            <div className="formSharePreview__image">
              <EngagementItem
                amount={amount}
                avatarText={this.state.socialName}
                avatarImage={this.state.avatarUrl}
                backgroundImage={imageUrl}
                backgroundImageWidth={300}
                backgroundImageHeight={300}
              />
            </div>
            {productImages.length > 0 && (
              <div>
                <div className="ui-center">
                  <div className="formSharePreview__listTitle">
                    Välj en alternativ motivbild
                  </div>
                </div>
                <div className="formSharePreview__list">
                  {productImages.map((productImage, key) => (
                    <div
                      key={key}
                      onClick={this.handleImageSelect.bind(this, productImage)}
                      className={classNames('formSharePreview__item', {
                        'is-selected': productImage === imageUrl
                      })}
                    >
                      <img
                        src={productImage}
                        className="formSharePreview__itemImage"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="form__section">
          <div className="formLayout formLayout--narrow">
            <div className="formLayout__item">
              <div className="inputGroup">
                <label className="formLabel">Namn</label>
                <div className="inputGroup__input">
                  <input
                    type="text"
                    name="socialName"
                    value={this.state.socialName}
                    onChange={this.handleName}
                    className="inputGroup__field"
                    maxLength="20"
                  />
                </div>
              </div>
            </div>
            <div className="formLayout__item formLayout__item--annotation">
              Utrymmet för namnet är begränsat till 20 tecken.
            </div>
            <div className="formLayout__item formLayout__item--twoCol1 formLayout__item--stack ui-center">
              <ImageUploadButton
                text="Ladda upp profilbild"
                onUploadCompleted={this.handleAvatarUpload}
              />
            </div>
            <div className="formLayout__item formLayout__item--twoCol2 formLayout__item--stack ui-center">
              <ImageUploadButton
                text="Ladda upp motivbild"
                onUploadCompleted={this.handleImageUpload}
              />
            </div>
          </div>
        </div>
        <div className="form__submit">
          <div className="form__button">
            <button
              className={classNames(
                'button',
                'button--large',
                'button--solid-ocean',
                {
                  'is-loading': this.props.isLoading
                }
              )}
            >
              Visa min gåva
            </button>
          </div>
          <div className="form__disclaimer">
            Jag samtycker till att Cancerfonden publicerar min gåva med namn och
            bild enligt ovan på sin webbplats.
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(ThanksForm);
