import React, { useMemo } from 'react';
import { ButtonStyle, buttonStyles } from '~/App/config/buttonStyles';
import { MultiPartFormValues } from '~/App/shared/components/MultiPartForm';
import { IValidation } from '~/App/shared/components/Validation';

import { FormButton } from './FormButton';

type Props = {
  part: number;
  values: MultiPartFormValues;
  validation: IValidation;
  buttonStyle?: ButtonStyle;
  text?: string;
  handleGoToPart?: any;
  stepName?: string;
};

export function NextPartButton({
  part,
  values,
  validation,
  text = 'Gå vidare',
  buttonStyle = 'primary',
  handleGoToPart,
  stepName
}: Props) {
  const isValid = useMemo(
    () =>
      validation.areValid(
        values.handlers.getValidationKeys(values.partOptions.currentPart)
      ),
    [validation, values.handlers, values.partOptions.currentPart]
  );

  if (values.partOptions.currentPart !== part) {
    return null;
  }

  return (
    <FormButton
      buttonStyle={isValid ? buttonStyle : buttonStyles.disabled}
      handleGoToPart={
        handleGoToPart ? handleGoToPart : values.handlers.handleGoToPart
      }
      fromPart={values.partOptions.currentPart}
      toPart={part + 1}
      data-cy="next-step"
      children={text}
      stepName={stepName}
    />
  );
}
