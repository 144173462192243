import { createContext, Dispatch, SetStateAction } from 'react';

export type AppStatus = 'loading' | 'idle';
export type PageContent = Record<string, unknown | undefined>;
export type AppContextValue = {
  status: AppStatus;
  pageContent: PageContent;
  stickyNavigation: boolean;
  userAgent: string | null;
  cookies: Record<string, string>;

  setStatus: Dispatch<SetStateAction<AppStatus>>;
  setPageContent: Dispatch<SetStateAction<PageContent>>;
  setStickyNavigation: Dispatch<SetStateAction<boolean>>;
};

export const AppContext = createContext<AppContextValue>({
  pageContent: {},
  status: 'idle',
  stickyNavigation: true,
  userAgent: null,
  cookies: {},

  setStatus: (_: AppStatus | ((_: AppStatus) => AppStatus)) => null,
  setPageContent: (_: PageContent | ((_: PageContent) => PageContent)) => null,
  setStickyNavigation: (_: boolean | ((_: boolean) => boolean)) => null
});
