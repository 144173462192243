import styled from 'styled-components';
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import {
  BlockBackground,
  BackgroundColor
} from '~/App/shared/components/BlockBackground';
import { Container } from '~/App/shared/components/Container';
import { PrimaryButton } from '~/App/shared/components/Elements';
import Checkbox from '../../../Fields/Checkbox';

import { siteAxios } from '~/App/helpers/http';
import { heading2 } from '~/App/helpers/mixins';

import { Field, WufooField } from './components/Field';
import { Loading } from './components/Loading';

import { useValidation } from '~/App/shared/components/Validation';
import { useWufooState } from './hooks/useWufooState';
import { useWufooSubmit } from './hooks/useWufooSubmit';
import HtmlContent from '../../../HtmlContent';

const Heading = styled.h2`
  ${heading2};
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const Preamble = styled(HtmlContent)`
  margin-top: 0.625rem;
`;

const Form = styled.form`
  max-width: 35.5rem;
  margin: 0 auto;
  padding: 3.5rem 1rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FieldWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

type PopupProps = {
  color?: 'success' | 'error';
};

const Popup = styled.div<PopupProps>`
  border-radius: 0.25rem;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  background-color: ${({ color, theme }) => {
    if (color === 'success') return theme.colors.skeptic;
    if (color === 'error') return theme.colors.errorRedMedium;

    return theme.colors.dust;
  }};

  color: ${({ color, theme }) => {
    if (color === 'success') return theme.colors.crusoe;
    if (color === 'error') return theme.colors.errorRed;

    return theme.colors.charcoal;
  }};
`;

type WufooBlock = {
  id: string;
  wufooId: string;
  heading: string;
  body: string;
  disclamer: string;
  disclamerError: string;
  accentColor: string;
  backgroundColor: BackgroundColor;
  padding: {
    top: 'small' | 'none' | 'large' | 'tiny';
    bottom: 'small' | 'none' | 'large' | 'tiny';
  };
};

type WufooForm = {
  Name: string;
  Description: string;
  RedirectMessage: string;
  Fields: WufooField[];
};

const isRequired = (x: WufooField) => x.IsRequired === '1';

export function Wufoo({
  wufooId,
  padding,
  heading,
  body,
  backgroundColor,
  disclamer,
  disclamerError = 'Du måste godkänna Cancerfondens integritetspoliy'
}: WufooBlock) {
  const [form, setForm] = useState<WufooForm | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const schema = useMemo(() => {
    const schemaFromFields = form?.Fields.filter(isRequired).reduce(
      (prev, current) => ({
        ...prev,
        [current.ID]: {
          presence: {
            allowEmpty: false,
            message: '^Glöm inte att fylla i detta fält'
          }
        }
      }),
      {}
    );

    return {
      ...schemaFromFields,
      acceptIntegrityPolicy: {
        validateIsTrue: {
          message: `^${disclamerError}`
        }
      }
    };
  }, [form, disclamerError]);

  const state = useWufooState();
  const validation = useValidation({
    schema,
    values: state.values
  });

  const submit = useWufooSubmit({
    wufooId,
    state,
    fields: form?.Fields || [],
    validation
  });

  const fetchFields = useCallback(async () => {
    setLoading(true);

    const response = await siteAxios.get(`/api/v2/wufoo/forms/${wufooId}`);

    const form = response.data as WufooForm;
    const fields: WufooField[] = form.Fields || [];

    state.handlers.resetValues(fields);

    setForm(form);
    setLoading(false);
  }, [state.handlers, wufooId]);

  const renderField = useCallback(
    (field: WufooField) => (
      <Field
        field={field}
        key={field.ID}
        validation={validation}
        state={state}
      />
    ),
    [validation, state]
  );

  const children = useMemo(() => {
    if (loading || !form) {
      return <Loading />;
    }

    return (
      <Form onSubmit={submit.handleSubmit}>
        <FieldWrapper>
          {heading && <Heading children={heading} />}
          {body && <Preamble dangerouslySetInnerHTML={{ __html: body }} />}
        </FieldWrapper>
        {form?.Fields && form.Fields.map(renderField)}
        <FieldWrapper>
          <Checkbox
            name="acceptIntegrityPolicy"
            checked={state.values['acceptIntegrityPolicy']}
            onChange={state.handlers.handleSetAcceptIntegrityPolicy}
            errors={validation.errors['acceptIntegrityPolicy']}
            isInvalid={validation.isInvalid('acceptIntegrityPolicy')}
          >
            <HtmlContent dangerouslySetInnerHTML={{ __html: disclamer }} />
          </Checkbox>
        </FieldWrapper>
        <Center>
          <PrimaryButton
            buttonStyle={validation.isValidated ? 'cta' : 'disabled'}
            isLoading={submit.isSubmitting}
            children="Skicka"
            type="submit"
          />
        </Center>
        {submit.success && form?.RedirectMessage && (
          <Popup color="success" children={form?.RedirectMessage} />
        )}

        {submit.success === false && (
          <Popup color="error" children="Ett fel inträffade, försök igen" />
        )}
      </Form>
    );
  }, [
    loading,
    form,
    submit.handleSubmit,
    submit.isSubmitting,
    submit.success,
    heading,
    body,
    renderField,
    state.values,
    state.handlers.handleSetAcceptIntegrityPolicy,
    validation,
    disclamer
  ]);

  useEffect(() => {
    fetchFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => (
      <BlockBackground backgroundColor={backgroundColor}>
        <Container
          paddingTop={padding.top}
          paddingBottom={padding.bottom}
          children={children}
        />
      </BlockBackground>
    ),
    [backgroundColor, padding, children]
  );
}
