import React from 'react';
import styled from 'styled-components';

import { heading2 } from '~/App/helpers/mixins';
import { Spinner } from '~/App/shared/components/Elements';
import { ViewWrapper } from '../../ViewWrapper';

const Heading = styled.h1`
  ${heading2};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
`;

const AbsoluteCenter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  max-width: 380px;
  margin: auto;
`;

type Props = {
  heading?: string;
};

export function Loading({ heading }: Props) {
  return (
    <ViewWrapper alternativeBackground>
      <AbsoluteCenter>
        {heading && <Heading children={heading} />}
        <Spinner alternateColor={true} />
      </AbsoluteCenter>
    </ViewWrapper>
  );
}
