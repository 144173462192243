import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IFavourablePaymentMethod } from '~/types/IPaymentMethod';

import { MultiPartFormValues, Section } from '../../../MultiPartForm';
import { IValidation } from '../../../Validation';
import { AmountSelector } from '../../components/Forms/components/AmountSelector';
import { FavourablePaymentMethodSelector } from '../../components/Forms/components/FavourablePaymentMethodSelector';
import { DonationSummary } from '../../components/Forms/components/Summaries';
import { TwoColumn } from '../../components/Forms/components/TwoColumn';

import { StateValues } from '../../components/States';
import { DonationSubmitButton, ISubmit } from '../../components/Submits';

const StepWrapper = styled.div`
  margin-top: 1rem;
`;

type Props = {
  values: MultiPartFormValues & StateValues;
  validation: IValidation;
  submit: ISubmit;
  data: {
    infoText?: string;
    summaryHeading?: string;
    prices: number[];
    taxReductionInfoLink?: string;
    availablePaymentMethods: IFavourablePaymentMethod[];
  };
};

export function Form({ values, validation, submit, data }: Props) {
  const firstSection = useMemo(
    () => (
      <StepWrapper>
        <AmountSelector
          amounts={data.prices}
          selectedAmount={values.productOptions.product.price}
          customAmount={values.productOptions.product.customPrice}
          setAmount={values.handlers.setProductPrice}
          setCustomAmount={values.handlers.setProductCustomPrice}
          resetAmount={values.handlers.resetProductPrice}
          validationKey="productOptions.product.price"
          validation={validation}
          tinyLayout="auto"
          smallLayout="auto"
          largeLayout="auto"
        />
      </StepWrapper>
    ),
    [
      data.prices,
      validation,
      values.handlers.resetProductPrice,
      values.handlers.setProductCustomPrice,
      values.handlers.setProductPrice,
      values.productOptions.product.customPrice,
      values.productOptions.product.price
    ]
  );

  const secondSection = useMemo(
    () => (
      <StepWrapper>
        <FavourablePaymentMethodSelector
          values={values}
          validation={validation}
          methods={data.availablePaymentMethods}
          formContent={data}
          children={
            <DonationSubmitButton
              values={values}
              validation={validation}
              submit={submit}
            />
          }
        />
      </StepWrapper>
    ),
    [data, submit, validation, values]
  );

  return (
    <TwoColumn.Wrapper>
      <TwoColumn.LeftColumn>
        <Section
          title="Välj belopp"
          nextStepButton={{ text: 'Till betalsätt' }}
          part={1}
          values={values}
          validation={validation}
          children={firstSection}
        />
        <Section
          title="Betalsätt"
          part={2}
          values={values}
          validation={validation}
          children={secondSection}
        />
      </TwoColumn.LeftColumn>
      <TwoColumn.RightColumn>
        <TwoColumn.ColumnCard>
          <DonationSummary
            part={values.partOptions.currentPart}
            area="side"
            price={values.productOptions.product.price}
            infoText={data.infoText}
            heading={data.summaryHeading}
          />
        </TwoColumn.ColumnCard>
      </TwoColumn.RightColumn>
    </TwoColumn.Wrapper>
  );
}
