import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { IGlobalMessage } from '~/types/IGlobalMessage';

// Shared components
import { Message } from './components/Message';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;

type Props = {
  globalMessages: IGlobalMessage[];
};

export function GlobalMessages({ globalMessages }: Props) {
  const renderMessage = useCallback(
    (message) => <Message globalMessage={message} key={message.id} />,
    []
  );

  return useMemo(
    () =>
      globalMessages ? (
        <Wrapper children={globalMessages.map(renderMessage)} />
      ) : null,
    [globalMessages, renderMessage]
  );
}
