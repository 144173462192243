import React from 'react';
import styled from 'styled-components';

// Helpers
import validate from '~/App/helpers/validate';
import { supportMessages } from '~/App/helpers/http';

// Shared components
import Link from '~/App/shared/components/Link';
import {
  PrimaryButton,
  AlertMessageContainer
} from '~/App/shared/components/Elements';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';

// Fields
import { InputWrapper } from '~/App/shared/components/Fields/InputWrapper';
import Text from '~/App/shared/components/Fields/Text';
import Checkbox from '~/App/shared/components/Fields/Checkbox';
import TextArea from '~/App/shared/components/Fields/TextArea';

const schema = {
  email: {
    presence: {
      allowEmpty: false,
      message: '^Ange e-postadress'
    }
  },
  repeatEmail: {
    presence: {
      allowEmpty: false,
      message: '^Ange e-postadress'
    },
    equality: {
      attribute: 'email',
      message: '^De angivna e-postadresserna matchar inte'
    }
  },
  message: {
    presence: {
      allowEmpty: false,
      message: '^Du måste fylla i ett meddelande'
    }
  },
  accept: {
    validateIsTrue: {
      message: '^Du måste acceptera att vi behandlar dina personuppgifter'
    }
  }
};

const ButtonWrapper = styled(InputWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default class CancerlinjenForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      email: '',
      repeatEmail: '',
      message: '',
      accept: false,
      error: {},
      status: 'idle',
      validationErrors: {},
      isCompleted: false
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleAccept() {
    this.setState({ accept: !this.state.accept });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const errors = validate(this.state, schema);
    this.setState({ error: {}, validationErrors: errors || {} });
    const { email, message } = this.state;

    if (!errors) {
      this.setState({ status: 'loading' });

      try {
        await supportMessages.create({
          data: { email, message }
        });

        this.setState({
          isCompleted: true,
          status: 'idle',
          email: '',
          repeatEmail: '',
          message: '',
          accept: false
        });
      } catch (error) {
        this.setState({ error: error, status: 'idle' });
      }
    }
  }

  render() {
    const { error, validationErrors, isCompleted } = this.state;
    const { data } = this.props;

    const confirmationText =
      data.confirmationText ||
      'Tack för ditt meddelande. Vi återkommer så snart vi kan.';
    const errorText =
      data.errorText ||
      'Din fråga skickades tyvärr inte, prova igen eller kontakta vår kundservice.';

    return (
      <React.Fragment>
        {isCompleted && (
          <AlertMessageContainer messageStyle="success">
            {confirmationText}
          </AlertMessageContainer>
        )}
        <form
          onSubmit={this.handleSubmit}
          className="tmcDonationForm"
          noValidate
        >
          <FormHeading>{data.heading || 'Kontakta oss via mejl'}</FormHeading>
          <InputWrapper>
            <Text
              name="email"
              type="email"
              value={this.state.email}
              errors={validationErrors.email}
              onChange={this.handleChange}
              isInvalid={validationErrors.email}
              maxLength="100"
              placeholder="E-postadress"
              required
            />
          </InputWrapper>
          <InputWrapper>
            <Text
              name="repeatEmail"
              type="email"
              value={this.state.repeatEmail}
              errors={validationErrors.repeatEmail}
              onChange={this.handleChange}
              isInvalid={validationErrors.repeatEmail}
              maxLength="100"
              placeholder="Repetera e-postadress"
              required
            />
          </InputWrapper>
          <InputWrapper>
            <TextArea
              name="message"
              type="text"
              value={this.state.message}
              errors={validationErrors.message}
              onChange={this.handleChange}
              isInvalid={validationErrors.message}
              placeholder="Meddelande"
              required
            />
          </InputWrapper>
          <InputWrapper>
            <Checkbox
              name="accept"
              checked={this.state.accept}
              errors={validationErrors.accept}
              onChange={this.handleAccept}
              isInvalid={validationErrors.accept}
              required
            >
              <span>
                Jag godkänner att Cancerfonden hanterar de personuppgifter som
                jag lämnar för att på bästa sätt ge mig råd och stöd.
                Uppgifterna sparas i upp till två veckor för att se hur många
                som kontaktar oss och vilka frågor som är vanligast.
                Personuppgifter behandlas i enlighet med{' '}
                <Link
                  to="/om-oss/personlig-integritet-och-cookies"
                  target="_blank"
                >
                  Cancerfondens integritetspolicy
                </Link>
                .
              </span>
            </Checkbox>
          </InputWrapper>
          <ButtonWrapper>
            <PrimaryButton
              isLoading={this.state.status === 'loading'}
              isDisabled={this.state.status === 'loading'}
              type="submit"
              buttonStyle="success"
            >
              Skicka
            </PrimaryButton>
          </ButtonWrapper>
          {Object.keys(error).length > 0 && (
            <AlertMessageContainer messageStyle="error">
              {errorText}
            </AlertMessageContainer>
          )}
        </form>
      </React.Fragment>
    );
  }
}
