import styled, { css } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

const arrowHeight = '40px';
const halfArrowHeight = '20px';

export const ArrowSegment = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 48px;
  padding: 1rem 0;
  display: block;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === 'standard'
      ? theme.themeColors.accentedBackgroundColor
      : backgroundColor === 'primary'
      ? theme.themeColors.primaryBackgroundColor
      : theme.themeColors.accentedBackgroundColor};

  ${mq('≥small')`
    padding-bottom: 2rem;
  `};

  &::before {
    position: absolute;
    top: 0;
    content: '';
    width: 100%;
    height: ${arrowHeight};
    margin-top: calc(-${arrowHeight});
    border-top: ${arrowHeight} solid transparent;

    border-left-color: ${({ backgroundColor, theme }) =>
      backgroundColor === 'standard'
        ? theme.themeColors.accentedBackgroundColor
        : backgroundColor === 'primary'
        ? theme.themeColors.primaryBackgroundColor
        : theme.themeColors.accentedBackgroundColor};
    border-left-style: solid;
    border-left-width: ${(props) => props.arrowWidth}px;

    border-right-color: ${({ backgroundColor, theme }) =>
      backgroundColor === 'standard'
        ? theme.themeColors.accentedBackgroundColor
        : backgroundColor === 'primary'
        ? theme.themeColors.primaryBackgroundColor
        : theme.themeColors.accentedBackgroundColor};
    border-right-style: solid;
    border-right-width: ${(props) => props.arrowWidth}px;

    ${(props) =>
      props.firstSegment &&
      css`
        border: 0;
        height: 0;
      `};

    box-sizing: border-box;

    @media print {
      display: none;
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    margin-bottom: -${arrowHeight};
    content: '';
    width: 100%;
    height: ${arrowHeight};

    border-top-color: ${({ backgroundColor, theme }) =>
      backgroundColor === 'standard'
        ? theme.themeColors.accentedBackgroundColor
        : backgroundColor === 'primary'
        ? theme.themeColors.primaryBackgroundColor
        : theme.themeColors.accentedBackgroundColor};
    border-top-style: solid;
    border-top-width: ${arrowHeight};

    border-left: transparent solid ${(props) => props.arrowWidth}px;
    border-right: transparent solid ${(props) => props.arrowWidth}px;

    ${(props) =>
      props.lastSegment &&
      css`
        border: 0;
        height: 0;
        border-top-style: solid;
        border-top-width: ${halfArrowHeight};
        border-top-color: ${({ backgroundColor, theme }) =>
          backgroundColor === 'standard'
            ? theme.themeColors.accentedBackgroundColor
            : backgroundColor === 'primary'
            ? theme.themeColors.primaryBackgroundColor
            : theme.themeColors.accentedBackgroundColor};
        margin-bottom: -${halfArrowHeight};
      `};

    box-sizing: border-box;

    @media print {
      display: none;
    }
  }
`;
