import React, { ReactNode, useMemo } from 'react';

import { collectionTypes } from '~/App/config/collectionTypes';

import AmountSelector from '../Forms/components/AmountSelector';

import { StepWrapper } from '../Forms/components/FormLayout';
import { Layout } from '~/types/Layout';
import { ICollection } from '~/types/ICollection';
import { IValidation } from '../../../Validation';
import { ProductValues } from '../States';

type Price = string | number;
type Props = {
  values: ProductValues;
  validation: IValidation;
  collection?: ICollection;
  formContent: {
    prices: Price[];
    layout?: {
      amountSelector?: {
        tinyLayout: Layout;
        smallLayout: Layout;
        largeLayout: Layout;
      };
    };
  };
  children?: ReactNode;
};

export function Amount({
  formContent,
  values,
  validation,
  collection,
  children
}: Props) {
  const isLopbandet = collection?.collectionType === collectionTypes.lopbandet;
  const collectionDistance = collection?.totalDistance
    ? collection?.totalDistance / 1000
    : 0;

  const showKrPerKm = isLopbandet && collectionDistance !== 0;
  const amountType = showKrPerKm ? 'krPerKm' : 'money';

  return useMemo(
    () => (
      <StepWrapper>
        <StepWrapper marginTop="tiny">
          <AmountSelector
            amountType={amountType}
            amounts={formContent.prices}
            selectedAmount={values.productOptions.product.price}
            customAmount={values.productOptions.product.customPrice}
            setAmount={values.handlers.setProductPrice}
            setCustomAmount={values.handlers.setProductCustomPrice}
            resetAmount={values.handlers.resetProductPrice}
            validationKey={'productOptions.product.price'}
            validation={validation}
            collectionDistance={collectionDistance}
            tinyLayout={formContent?.layout?.amountSelector?.tinyLayout || '50'}
            smallLayout={
              formContent?.layout?.amountSelector?.smallLayout || '50'
            }
            largeLayout={
              formContent?.layout?.amountSelector?.largeLayout || '50'
            }
          />
          {children}
        </StepWrapper>
      </StepWrapper>
    ),
    [
      amountType,
      children,
      collectionDistance,
      formContent?.layout?.amountSelector?.largeLayout,
      formContent?.layout?.amountSelector?.smallLayout,
      formContent?.layout?.amountSelector?.tinyLayout,
      formContent.prices,
      validation,
      values.handlers.resetProductPrice,
      values.handlers.setProductCustomPrice,
      values.handlers.setProductPrice,
      values.productOptions.product.customPrice,
      values.productOptions.product.price
    ]
  );
}
