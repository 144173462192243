import React from 'react';
import mediaHelper from '../../../App/helpers/media-helper';

class EditorialImageHigh extends React.Component {
  render() {
    var imageFormat = this.props.imageLayout || 'hero',
      imageUrl = mediaHelper.getUrl(this.props.image.secure_url, {
        width: 420
      });

    if (this.props.imageType !== 'Image' || imageFormat !== 'high') {
      return null;
    }

    return (
      <div className={'editorialImage editorialImage--high'}>
        <div className="editorialImage__content">
          {this.props.imageCaption ? (
            <div className="editorialImage__caption">
              {this.props.imageCaption}
            </div>
          ) : null}
          <img className="editorialImage__image" src={imageUrl} />
        </div>
      </div>
    );
  }
}

EditorialImageHigh.displayName = 'EditorialImageHigh';

EditorialImageHigh.defaultProps = {
  image: '',
  imageCaption: ''
};

export default EditorialImageHigh;
