import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

class SectionInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { heading, modifier, preamble, description, headingStyle } = this.props;

    if (_.isEmpty(heading) && _.isEmpty(preamble) && _.isEmpty(description)) {
      return null;
    }

    let wrapperClasses = classNames('sectionInfo', {
      [modifier]: !_.isEmpty(modifier)
    });

    let titleClasses = classNames('sectionInfo__title', {
      [`sectionInfo__title--${headingStyle}`]: !_.isEmpty(headingStyle)
    });

    return (
      <div className={wrapperClasses}>
        {!_.isEmpty(heading) ? (
          <h2
            className={titleClasses}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        ) : null}
        {!_.isEmpty(preamble) ? (
          <p
            className="sectionInfo__preamble"
            dangerouslySetInnerHTML={{ __html: preamble }}
          />
        ) : null}
        {!_.isEmpty(description) ? (
          <p
            className="sectionInfo__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}
      </div>
    );
  }
}

SectionInfo.displayName = 'SectionInfo';

SectionInfo.defaultProps = {
  heading: false,
  modifier: false,
  preamble: false,
  description: false,
  headingStyle: 'normal'
};

export default SectionInfo;
