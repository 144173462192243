import { omit } from 'lodash';
import { FormEvent, useCallback, useState, useMemo } from 'react';

import { siteAxios } from '~/App/helpers/http';

import { IValidation } from '~/App/shared/components/Validation';
import { WufooField } from '../components/Field';
import { WufooState } from './useWufooState';

type Props = {
  wufooId: string;
  state: WufooState;
  fields: WufooField[];
  validation: IValidation;
};

export function useWufooSubmit({ wufooId, state, fields, validation }: Props) {
  const [success, setSuccess] = useState<boolean | undefined>();
  const [isSubmitting, setSubmitting] = useState<boolean | undefined>();

  const data = useMemo(() => {
    const paths = Object.keys(state.values).filter(
      (x) => !x.startsWith('Field')
    );
    const values = omit(state.values, paths);

    return values;
  }, [state.values]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      setSuccess(undefined);
      setSubmitting(true);

      try {
        const response = await siteAxios.post(
          `/api/v2/wufoo/forms/${wufooId}`,
          data
        );

        const isSuccess =
          response.status === 200 && response.data?.Success === 1;

        setSuccess(isSuccess);

        if (isSuccess) {
          validation.hideAllErrors();
          state.handlers.resetValues(fields);
        }
      } catch (error) {
        setSuccess(false);
      }

      setSubmitting(false);

      return null;
    },
    [fields, data, state.handlers, validation, wufooId]
  );

  return {
    success,
    isSubmitting,
    handleSubmit
  };
}
