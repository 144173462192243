import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ICollectionActivity } from '~/types/ICollectionActivity';

const Button = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const Image = styled.img`
  margin-right: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
`;

type Props = {
  activity: ICollectionActivity;
  openShareModal: (activity: ICollectionActivity) => void;
};

export function ShareButton({ activity, openShareModal }: Props) {
  const openModal = useCallback(() => openShareModal(activity), [
    activity,
    openShareModal
  ]);

  return (
    <>
      <Button onClick={openModal}>
        <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1638970491/minnesida/icons-ui-share.png" />
        Dela minne
      </Button>
    </>
  );
}
