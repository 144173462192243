import styled, { css } from 'styled-components';

type InputWrapperProps = {
  percentageWidth?: '66' | '50' | '33';
};

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-top: 0.5rem;
  flex-grow: 0;
  flex-shrink: 0;

  ${({ percentageWidth }) =>
    percentageWidth === '66'
      ? css`
          flex-basis: calc(66.66% - 0.25rem);
        `
      : percentageWidth === '50'
      ? css`
          flex-basis: calc(50% - 0.25rem);
        `
      : percentageWidth === '33'
      ? css`
          flex-basis: calc(33.33% - 0.25rem);
        `
      : css`
          flex-basis: 100%;
        `};
`;
