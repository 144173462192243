import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { card, heading3 } from '~/App/helpers/mixins';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import { SummeryItem } from './SummeryItem';

const Card = styled.div`
  ${card};
  margin: 1rem 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Heading = styled.div`
  ${heading3};
`;

const Hr = styled.div`
  width: 100%;
  height: 1px;
  border: solid 1px #d4d4d4;
  margin: 1.5rem 0;
`;

const Text = styled.div`
  margin-top: 1.5rem;
`;

export function Summery() {
  const shoppingCart = useShoppingCart();
  const renderItem = useCallback(
    (item: ICommerceOrderLine) => <SummeryItem key={item.id} item={item} />,
    []
  );

  return (
    <Card>
      <Heading children="Dina varor" />
      {shoppingCart.items.map(renderItem)}
      <Row>
        <Text>Frakt</Text>
        <Text children={`${shoppingCart.shippingPrice} kr`} />
      </Row>
      <Hr />
      <Row>
        <Heading children="Totalt" />
        <Heading children={`${shoppingCart.totalPrice} kr`} />
      </Row>
    </Card>
  );
}
