import React, { Fragment } from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';

import { NewFormHeading } from './../components/Forms/components/FormTypography';

const MonthlyAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -0.75rem;

  ${mq('≥medium')`
    font-size: 1.125rem;
    margin-bottom: 0rem;
  `};
`;

export default function MonthlyDonationAmountSummary({ price }) {
  return (
    <Fragment>
      <NewFormHeading>Ditt val</NewFormHeading>
      <MonthlyAmountWrapper>
        <span>Månadsbelopp</span>
        <span>
          <NewFormHeading>{price ?? 0} kr</NewFormHeading>
        </span>
      </MonthlyAmountWrapper>
    </Fragment>
  );
}
