import { contentAxios } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export const loadData = async ({ params }: LoadDataProps<'id'>) => {
  const { data } = await contentAxios.get('/v1/blocks', {
    params: { id: params.id }
  });

  return data;
};
