import { useState, useCallback, ChangeEvent, useMemo } from 'react';

type State = {
  bank: string;
  accountNumber: string;
  clearingNumber: string;
};

type SetMobileBankIdAccountArgs = {
  clearingNo: string;
  accountNo: string;
};

export type MobileBankId = State & {
  handlers: {
    setMobileBankIdAccount: (values: SetMobileBankIdAccountArgs) => void;
    handleMobileBankIdChange: (
      event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void;
    handleMobileBankIdSelectChange: (
      event: ChangeEvent<HTMLSelectElement>
    ) => void;
  };
};

export function useMobileBankId(): MobileBankId {
  const [state, setState] = useState<State>({
    bank: '',
    accountNumber: '',
    clearingNumber: ''
  });

  const setMobileBankIdAccount = useCallback(
    ({ clearingNo, accountNo }: SetMobileBankIdAccountArgs) =>
      setState((state) => ({
        ...state,
        accountNumber: accountNo,
        clearingNumber: clearingNo
      })),
    []
  );

  const handleMobileBankIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      event.persist();

      setState((state) => ({
        ...state,
        [event.target.name]: event.target.value
      }));
    },
    []
  );

  const handleMobileBankIdSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const [clearingNo, accountNo] = event.target.value.split('-');

      setMobileBankIdAccount({
        accountNo: accountNo,
        clearingNo: clearingNo
      });
    },
    [setMobileBankIdAccount]
  );

  return useMemo(
    () => ({
      ...state,
      handlers: {
        setMobileBankIdAccount,
        handleMobileBankIdChange,
        handleMobileBankIdSelectChange
      }
    }),
    [
      handleMobileBankIdChange,
      handleMobileBankIdSelectChange,
      setMobileBankIdAccount,
      state
    ]
  );
}
