import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import mediaHelper from '~/App/helpers/media-helper';

import { Modal } from '~/App/shared/components/Elements';
import { useDragToDismiss } from '~/App/shared/hooks/use-drag-to-dismiss';
import { mq } from '~/lib/mq';
import { Period } from '~/types/IPurchase';

import { MemoryPeriodImage } from '../../../../../../../components/MemoryPeriodImage';

const StyledModal = styled(Modal)`
  padding: 2rem;

  ${mq('<small')`
    padding: 0 0 1rem;
  `};
`;

const Content = styled.div`
  ${mq('<small')`
    padding: 0 1rem;
  `};
`;

const Wrapper = styled.div``;

type ImageProps = {
  url: string;
};

const Image = styled.div<ImageProps>`
  width: 100%;
  padding-top: 100%;
  background-image: url('${({ url }) => url}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 1rem;
`;

const StyledMemoryPeriodImage = styled(MemoryPeriodImage)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  min-height: 8.25rem;
  min-width: 8.25rem;

  ${mq('<small')`
    min-height: 4.75rem;
    min-width: 4.75rem;
  `};
`;

const From = styled.div`
  font-weight: bold;
  font-size: 1.375rem;
  margin-bottom: 0.25rem;
  white-space: pre-line;

  ${mq('<mediumLarge')`
    font-size: 1.125rem;
  `};
`;

const Type = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkBlue};

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${mq('<mediumLarge')`
    font-size: 1rem;
  `};
`;

const StyledDate = styled.p`
  font-size: 0.85rem;
  font-weight: bold;
`;

const Preamble = styled.div`
  font-size: 1.125rem;
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 1rem;

  ${mq('<mediumLarge')`
    font-size: 1rem;
  `};
`;

type Props = {
  image: string;
  content: string;
  by: string;
  period: Period | null;
  isOpen: boolean;
  onClose: () => void;
};

export function PreviewModal({
  image,
  by,
  period,
  content,
  isOpen,
  onClose
}: Props) {
  const periodLabel = useMemo(() => {
    switch (period) {
      case 'Once':
        return 'Ge en gång';
      case 'Month':
        return 'Ge varje månad';

      default:
        return '';
    }
  }, [period]);

  const createMemoryDateFormatted = dayjs().format('D MMM YYYY');

  const { element } = useDragToDismiss({
    isActive: isOpen,
    onDismiss: onClose
  });

  const imageUrl = useMemo(
    () =>
      mediaHelper.getUrl(image, {
        width: 400,
        height: 400
      }),
    [image]
  );

  return isOpen ? (
    <StyledModal
      closeModal={onClose}
      closeButton={{ text: 'Stäng förhandsgranskning', border: true }}
      isDraggable
      ref={element}
    >
      <Wrapper>
        <Image url={imageUrl}>
          {period ? <StyledMemoryPeriodImage period={period} /> : null}
        </Image>
        <Content>
          <From children={by ? `Av ${by}` : 'Av Anonym'} />
          <Type children={periodLabel} />
          {content && <Preamble children={content} />}
        </Content>
        <StyledDate children={createMemoryDateFormatted} />
      </Wrapper>
    </StyledModal>
  ) : null;
}
