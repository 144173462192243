import React from 'react';
import styled from 'styled-components';

//Shared
import Link from '~/App/shared/components/Link';

// Helpers
import { heading5 } from '~/App/helpers/mixins';

const AuthorWrapper = styled.div`
  max-width: 21rem;
  margin-top: 1.5rem;
`;

const AuthorHeading = styled.p`
  ${heading5};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-bottom: 0.5rem;
`;

const AuthorName = styled.p`
  ${heading5};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

const AuthorNamePointer = styled(AuthorName)`
  cursor: pointer;
`;

const AuthorTitle = styled.p``;

export default class Author extends React.Component {
  render() {
    const { author = {}, heading = '', linkAuthor = false } = this.props;

    return (
      <AuthorWrapper>
        {heading && <AuthorHeading>{heading}</AuthorHeading>}
        {(author.url && linkAuthor && (
          <Link to={author.url}>
            <AuthorNamePointer>{author.name}</AuthorNamePointer>
          </Link>
        )) || <AuthorName>{author.name}</AuthorName>}
        <AuthorTitle>{author.title}</AuthorTitle>
      </AuthorWrapper>
    );
  }
}
