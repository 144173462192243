import React, { useEffect, useMemo } from 'react';
import { some } from 'lodash';

import AmountSelector from '../../components/Forms/components/AmountSelector';
import { TaxReductionInfo } from '../../components/Forms/components/TaxReductionInfo';
import { StepWrapper } from '../../components/Forms/components/FormLayout';
import { NewFormHeading } from '../../components/Forms/components/FormTypography';
import Selector from '../../components/Forms/components/Selector';
import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';
import { ProductValues } from '../../components/States';
import { IValidation } from '../../../Validation';
import { Layout } from '~/types/Layout';

const includes = <T extends unknown>(arr: T[], val: T) =>
  some(arr, (x) => `${x}` === `${val}`);

type Props = {
  values: ProductValues;
  validation: IValidation;
  formContent: {
    prices: Record<number, number[]>;
    defaultPrice: Record<number, number>;
    layout?: {
      amountSelector?: {
        tinyLayout: Layout;
        smallLayout: Layout;
        largeLayout: Layout;
      };
    };
  };
};

export function Product({ values, formContent, validation }: Props) {
  useEffect(() => {
    const productId = values.product.id;
    const price = values.productOptions.product.price;
    const customPrice = values.productOptions.product.customPrice;
    const prices = formContent.prices[productId];
    const defaultPrice = formContent.defaultPrice[productId];

    if (customPrice) {
      return; // dont change selected price
    }

    if (!prices || !price) {
      return; // values are missing - do nothing
    }

    if (includes(prices, price)) {
      return; // Selected price is a option
    }

    if (!defaultPrice) {
      return; // do default price - do nothing
    }

    values.handlers.setProductPrice(defaultPrice);
  }, [values, formContent]);

  return useMemo(
    () => (
      <>
        <Selector
          options={[
            { value: 3, label: 'En gång' },
            { value: 31, label: 'Varje månad' }
          ]}
          value={values.product.id}
          setValue={values.handlers.setProductId}
          style={{ marginBottom: '1rem' }}
        />

        <NewFormHeading>Hur mycket vill du ge?</NewFormHeading>
        <StepWrapper>
          <AmountSelector
            amounts={formContent.prices[values.product.id]}
            selectedAmount={values.productOptions.product.price}
            customAmount={values.productOptions.product.customPrice}
            setAmount={values.handlers.setProductPrice}
            setCustomAmount={values.handlers.setProductCustomPrice}
            resetAmount={values.handlers.resetProductPrice}
            validationKey={'productOptions.product.price'}
            validation={validation}
            tinyLayout={formContent?.layout?.amountSelector?.tinyLayout || '50'}
            smallLayout={
              formContent?.layout?.amountSelector?.smallLayout || '50'
            }
            largeLayout={
              formContent?.layout?.amountSelector?.largeLayout || '50'
            }
          />
          <TaxReductionInfo
            amount={getListPrice(values.productOptions)}
            quantity={getQuantity(values.productOptions)}
          />
        </StepWrapper>
      </>
    ),
    [
      formContent?.layout?.amountSelector?.largeLayout,
      formContent?.layout?.amountSelector?.smallLayout,
      formContent?.layout?.amountSelector?.tinyLayout,
      formContent.prices,
      validation,
      values.handlers.resetProductPrice,
      values.handlers.setProductCustomPrice,
      values.handlers.setProductId,
      values.handlers.setProductPrice,
      values.product.id,
      values.productOptions
    ]
  );
}
