import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { heading1, invertedButton } from '~/App/helpers/mixins';
import { Header } from './Header';
import { mq } from '~/App/helpers/mq';
import { useSelfTest } from '../context/useSelfTest';
import { caretRight } from '~/App/helpers/icons';

const Background = styled.div`
  background-image: radial-gradient(circle at 37% 40%, #001489, #5b6bcb 150%);
  position: relative;
  overflow: hidden;

  &::after,
  &::before {
    content: '';
    display: block;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: solid 70px #83d5f1;
    position: absolute;
    z-index: 0;

    ${mq('≥medium')`
      width: 1000px;
      height: 1000px;
      border-width: 90px;
    `};
  }

  &::before {
    right: 0;
    top: 0;
    transform: translate(60%, -60%);
    filter: blur(1.5rem);
  }

  &::after {
    left: 0;
    bottom: 0;
    transform: translate(-60%, 60%);

    ${mq('≥medium')`
      border-width: 140px;
    `};
  }
`;

const StyledLink = styled(Link)`
  color: white;

  &:hover {
    color: ${(props) => props.theme.themeColors.accentBlue};
  }

  &:after {
    ${caretRight};
    font-size: 1.5rem;
    margin-left: 0.375rem;
    top: 0.25rem;
  }
`;

const ScreenWrapper = styled.div`
  margin: auto;
  padding: 5rem 1rem 2rem;
  min-height: 100vh;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  z-index: 1;
  position: relative;

  ${mq('≥micro')`
    padding-top: 3rem;
  `};
`;

const Heading = styled.h1`
  ${heading1}
  margin-bottom: 1rem;
  font-size: 3rem;
  line-height: 1.2;

  ${mq('≥small')`
    font-size: 5rem;
  `};
`;

const Text = styled.p`
  margin-bottom: 1rem;
`;

const StartButton = styled.button`
  margin-top: 2rem;
  ${invertedButton};
`;

const Disclaimer = styled.p`
  font-size: 0.875rem;
  margin-top: 3rem;
  max-width: 55ch;

  ${mq('≥small')`
    font-size: 1rem;
  `};
`;

export function WelcomeScreen() {
  const { start } = useSelfTest();

  return (
    <Background>
      <Header />
      <ScreenWrapper>
        <Heading>Välkommen till Kolla risken!</Heading>
        <Text>
          Det här testet är för dig som vill veta mer om hur hälsosamma
          levnadsvanor kan minska risken för cancer.
        </Text>
        <Text>Testet tar ca 5 minuter.</Text>
        <StartButton onClick={start}>Starta testet</StartButton>
        <Disclaimer>
          <StyledLink to={'/minska-risken/om-kolla-risken'}>
            Läs mer om testet
          </StyledLink>
          <br />
          <br />
          All data sparas anonymt och kan användas i arbetet för att färre ska
          drabbas och fler överleva cancer.{' '}
        </Disclaimer>
      </ScreenWrapper>
    </Background>
  );
}
