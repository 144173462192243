import React, { useMemo } from 'react';

// shared componenets
import { SubmitButton } from '~/App/shared/components/Donation/components/Forms/components/SubmitButton';

export default function LeftColumnSubmitButton({
  values,
  validation,
  submit,
  loading,
  authenticated
}) {
  const defaultSubmitButton = useMemo(
    () => (
      <SubmitButton
        values={values}
        validation={validation}
        submit={submit}
        isDisabled={!authenticated}
      >
        Skapa din insamling
      </SubmitButton>
    ),
    [values, validation, submit]
  );

  if (loading) {
    return null;
  }

  return defaultSubmitButton;
}
