import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import Slider from '~/App/shared/components/Slider';
import { ColumnCard } from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';

// Helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';

const Image = styled.img`
  display: block;
  margin: 2rem auto 1rem;
  height: 2.5rem;

  ${mq('<medium')`
     margin-top: 0;
  `};
`;

const Paragraph = styled.p`
  text-align: center;

  ${mq('<medium')`
     margin-bottom: 1.5rem;
  `};
`;

const StyledColumnCard = styled(ColumnCard)`
  ${mq('<medium')`
     padding: 1.5rem 0 0;
     position: relative;
     max-height: 13rem;
     overflow: hidden;
  `};
`;

const MobileOnly = styled.span`
  display: block;

  ${mq('≥medium')`
    display: none;
  `};
`;

const DesktopOnly = styled.span`
  display: none;

  ${mq('≥medium')`
    display: block;
  `};
`;

const StyledFormHeading = styled(FormHeading)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

type Item = { image: string; text: string };

type Props = {
  heading: string;
  items: Item[];
};

export function HowItWorks({ heading, items }: Props) {
  const renderItem = useCallback(
    (item) => (
      <div key={item.text}>
        <Image src={mediaHelper.getUrl(item.image, { height: 40 })} />
        {item.text && <Paragraph>{item.text}</Paragraph>}
      </div>
    ),
    []
  );

  const renderedItems = useMemo(() => items?.map(renderItem), [
    items,
    renderItem
  ]);

  if (!items || items.length < 1) {
    return null;
  }

  return (
    <>
      <StyledColumnCard>
        <StyledFormHeading>{heading}</StyledFormHeading>
        <MobileOnly>
          <Slider children={renderedItems} />
        </MobileOnly>
        <DesktopOnly>{renderedItems}</DesktopOnly>
      </StyledColumnCard>
    </>
  );
}
