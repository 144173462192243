import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

// Config
import { hero as defaultHeroImage } from '~/App/config/default-images';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { formatDate } from '~/App/helpers/date.service';

// Shared components
import Link from '~/App/shared/components/Link';

export default function ArticleThumb(props) {
  const {
    link,
    label = '',
    embed,
    heading,
    preamble,
    dataLabel,
    defaultImage,
    publishedDate
  } = props;

  const image = !props.image && defaultImage ? defaultHeroImage : props.image;
  const imageSrc = mediaHelper.getUrl(image, { width: 400, height: 260 });

  let prettyPublishedDate = false;

  if (!_.isEmpty(publishedDate)) {
    prettyPublishedDate = formatDate(publishedDate, 'D MMMM YYYY');
  }

  const wrapperClasses = classNames('articleThumb__wrapper', {
    'articleThumb__wrapper--disableHover': label
  });

  return (
    <div className="articleThumb">
      <Link className={wrapperClasses} to={link}>
        <div className="articleThumb__thumb">
          {!_.isEmpty(dataLabel) ? (
            <div className="articleThumb__label">{dataLabel}</div>
          ) : null}
          {!_.isEmpty(embed) ? (
            <div className="articleThumb__playIcon" />
          ) : null}
          <img className="articleThumb__image" src={imageSrc} />
        </div>
        <div className="articleThumb__info">
          <h2 className="articleThumb__heading">{heading}</h2>
          {prettyPublishedDate ? (
            <p className="articleThumb__date">{prettyPublishedDate}</p>
          ) : null}
          <p className="articleThumb__preamble">{preamble}</p>
          {label && (
            <div className="articleThumb__link">
              <div className="ui-arrowLink">{label.trim()}</div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

ArticleThumb.defaultProps = {
  link: '',
  image: null,
  label: null,
  heading: '',
  preamble: '',
  dataLabel: '',
  publishedDate: ''
};
