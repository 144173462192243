import { useContext } from 'react';

import { AbContext, AbContextValue } from './AbContext';

type Selector<T> = (values: AbContextValue) => T;

export function useAbContext(): AbContextValue;
export function useAbContext<T>(selector: Selector<T>): T;
export function useAbContext<T = AbContextValue>(selector?: Selector<T>): T {
  const values = useContext(AbContext);

  // @ts-expect-error
  return selector ? selector(values) : values;
}
