import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ICollection, CollectionAdmin } from '~/types/ICollection';

// Helpers
import mq from '~/App/helpers/mq';
import { heading2, preamble } from '~/App/helpers/mixins';
import { formatDate } from '~/App/helpers/date.service';

// Shared components
import { Divider } from '~/App/shared/components/Blocks';

// Local components
import { CollectionImage } from '~/App/shared/components/CollectionImage';
import { Admin } from './Admin';
import { useReadMore } from '~/App/shared/hooks/use-read-more';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq('≥small')`
        align-items: flex-start;
        flex-direction: row;
        padding: 3.75rem 1rem 2rem;
  `};
`;

const Content = styled.div`
  flex: 1 1 0;
  padding: 1.5rem 1.5rem 1rem;

  ${mq('≥small')`
        padding: 0 2rem;
  `};
`;

const Heading = styled.h1`
  ${heading2};
  color: ${(props) => props.theme.themeColors.primary};
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const Preamble = styled.p`
  ${preamble};
  margin: 1rem 0 0rem;

  ${mq('≥small')`
    margin: 1.5rem 0 0rem;
  `};
`;

const Date = styled.p`
  margin: 0rem 0 0.5rem;

  ${mq('≥small')`
  margin: 0rem 0 1rem;
`};
`;

const Description = styled.p`
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 1rem;
  overflow: hidden;
`;

type Props = {
  collection: ICollection & { senderName?: string };
  admins: CollectionAdmin[];
};

export function TopSection({ collection, admins }: Props) {
  const renderedAdmins = useMemo(
    () => admins.map((admin, index) => <Admin key={index} admin={admin} />),
    [admins]
  );

  const { button, ref } = useReadMore({
    maximumRows: 5
  });

  return useMemo(
    () => (
      <Wrapper>
        <CollectionImage collection={collection} />
        <Content>
          <Heading children={collection.name} />
          <Preamble children={`Av ${collection.senderName}`} />
          <Date
            children={formatDate(collection.createdAt || '', 'D MMMM YYYY')}
          />
          <Description ref={ref} children={collection.description} />

          {button}
          <Divider
            width="narrow"
            paddingTop="tiny"
            paddingLeft="none"
            paddingRight="none"
            alternativeColor={true}
            height="2px"
          />
          {renderedAdmins}
        </Content>
      </Wrapper>
    ),
    [collection, ref, button, renderedAdmins]
  );
}
