import _ from 'lodash';
import React from 'react';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { formatUTCDate } from '~/App/helpers/date.service';

// Shared components
import Link from '~/App/shared/components/Link';

class NewsFeed extends React.Component {
  render() {
    var related = this.props.related.slice(0, 3);

    if (this.props.data && this.props.data.hidden) {
      return null;
    }

    return (
      <div className="globalNewsFeed" id={this.props.id}>
        {related.map((item, key) => {
          const { data: { label: categoryLabel = 'Nyhet' } = {} } = item;
          const publishedDate = formatUTCDate(
            item.publishedDate,
            'D MMMM YYYY'
          );
          if (
            _.has(item, 'teaserImage') &&
            _.isObject(item.teaserImage) &&
            item.teaserImage.secure_url !== ''
          ) {
            imageUrl = mediaHelper.getUrl(item.teaserImage.secure_url, {
              width: 300,
              height: 300
            });
          } else {
            var url = !_.isObject(item.image)
                ? item.image
                : (url = item.image.secure_url),
              imageUrl = mediaHelper.getUrl(url, { width: 300, height: 300 });
          }

          return (
            <div className="globalNewsFeed__item" key={item.slug + key}>
              <Link className="globalNewsFeedItem" to={item.url || item}>
                {key === 0 && (
                  <div className="globalNewsFeedItem__imageWrapper">
                    <img className="globalNewsFeedItem__image" src={imageUrl} />
                  </div>
                )}
                <div className="globalNewsFeedItem__info">
                  <p className="globalNewsFeedItem__category">
                    {categoryLabel}
                  </p>
                  <h3 className="globalNewsFeedItem__title">
                    {item.teaserHeading || item.heading}
                  </h3>
                  <p className="globalNewsFeedItem__meta">{publishedDate}</p>
                  <p className="globalNewsFeedItem__preamble">
                    {item.teaserPreamble || item.preamble}
                  </p>
                  <div className="globalNewsFeedItem__link">
                    <span className="ui-arrowLink">
                      {item.teaserLabel ? item.teaserLabel.trim() : 'Läs mer'}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}

NewsFeed.displayName = 'GlobalNewsFeed';

NewsFeed.defaultProps = {
  id: '',
  related: []
};

export default NewsFeed;
