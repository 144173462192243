import React, { ReactNode, useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';

// Shared components
import { RadioButton } from '~/App/shared/components/Fields/RadioButton';

type WrapperProps = {
  checked: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  margin-bottom: 0.5rem;
  flex-basis: calc(100% - 0.5rem);
  border-radius: 2px;
  cursor: ${({ checked }) => (checked ? '' : 'pointer')};
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  padding: 1rem;
  ${({ checked }) =>
    checked &&
    css`
      border-color: ${({ theme }) => theme.themeColors.primary};
    `};
`;

type HeaderProps = {
  checked: boolean;
};

const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  z-index: 1;
  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.themeColors.primary};
    `};
`;

type LabelProps = {
  isValid?: boolean;
  isInvalid?: boolean;
};

const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Content = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightDust};
  padding-top: 1rem;
  margin-top: 1rem;
`;

type Props<T> = {
  id: T;
  name: ReactNode;
  isActive: boolean;
  children: ReactNode;
  icon?: ReactNode;
  onSelect: (id: T) => void;
};

export function Item<T>({
  id,
  name,
  isActive,
  icon,
  children,
  onSelect
}: Props<T>) {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleChange = useCallback(() => {
    if (isActive) return;

    onSelect(id);
  }, [id, isActive, onSelect]);

  return useMemo(
    () => (
      <Wrapper checked={isActive} onClick={handleChange}>
        <Header checked={isActive} ref={ref}>
          <Label>
            <RadioButton
              value={id}
              checked={isActive}
              onChange={handleChange}
            />
            <span children={name} />
          </Label>
          {icon}
        </Header>
        {isActive && children ? <Content children={children} /> : null}
      </Wrapper>
    ),
    [isActive, handleChange, id, name, icon, children]
  );
}
