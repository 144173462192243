import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

import Link from '~/App/shared/components/Link';

export default class FeaturedBrickLinks extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      data,
      related,
      linkClass,
      linkLayout,
      buttonLink,
      buttonLabel
    } = this.props;

    let links = [];

    let linkClasses = classNames('featuredBrick__link', {
      [`featuredBrick__link--${linkLayout}`]: linkLayout
    });

    if (!_.isEmpty(buttonLink) && !_.isEmpty(buttonLabel)) {
      links.push({
        url: buttonLink,
        text: buttonLabel,
        itemClass: linkClass
      });
    }

    if (!_.isEmpty(related)) {
      _.map(related, (link) => {
        links.push({
          url: link,
          text: link.teaserHeading || link.heading,
          itemClass: linkClass
        });
      });
    }

    if (_.get(data, 'links', '')) {
      _.map(data.links, (link) => {
        links.push({
          url: link.url || link,
          text: link.text || link.teaserHeading || link.heading,
          itemClass: link.class || linkClass
        });
      });
    }

    if (_.isEmpty(links)) {
      return null;
    }

    return (
      <ul className="featuredBrick__links">
        {_.map(links, ({ url, text, itemClass }, key) => {
          return (
            <li className={linkClasses} key={key}>
              <Link className={itemClass} to={url}>
                {text}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }
}

FeaturedBrickLinks.displayName = 'FeaturedBrickLinks';

FeaturedBrickLinks.defaultProps = {
  linkClass: 'ui-arrowLink'
};
