import { fetch } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';
import { LoadDataProps } from '~/types/routes';

export const loadData = async ({ params }: LoadDataProps<'slug'>) => {
  const response = await fetch({
    url: contentApi(`/v1/editorial-items/vacancies/${params.slug}`)
  });

  return {
    data: response.data
  };
};
