import React from 'react';
import { Helmet } from 'react-helmet';

import { siteUrl } from '~/App/helpers/env';

// Shared components
import { CollectionDonation } from '~/App/shared/components/Donation';

export function NewCollectionDonation({
  data: { formContent = {}, collection = {} } = {}
}) {
  return (
    <div>
      <Helmet>
        <title>{`Skänk till - ${collection.name}`}</title>
        <link
          rel="canonical"
          href={siteUrl(`/stod-oss/skank-till-en-insamling/${collection.slug}`)}
        />
        <meta name="robots" content="noindex, follow" />
        <meta name="description" content={`Skänk till "${collection.name}"`} />
      </Helmet>
      <CollectionDonation
        formContent={formContent}
        collection={collection}
        originPath={`/stod-oss/skank-till-en-insamling/${collection.slug}`}
        redirectPath="/stod-oss/skank-till-en-insamling/tack"
      />
    </div>
  );
}
