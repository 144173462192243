import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import useQuery from '~/App/shared/hooks/use-query';
import { ParsedValue } from '~/lib/query-string';
import { UtmContext, UtmContextValue } from './UtmContext';

type Props = {
  children: ReactNode;
};

export function UtmContextProvider({ children }: Props) {
  const query = useQuery();

  const [source, setSource] = useState<string | undefined>();
  const [medium, setMedium] = useState<string | undefined>();
  const [content, setContent] = useState<string | undefined>();
  const [campaign, setCampaign] = useState<string | undefined>();

  const isValid = useCallback((value: ParsedValue): value is string => {
    return value && typeof value === 'string' ? true : false;
  }, []);

  useEffect(() => {
    if (isValid(query.utm_source)) {
      setSource(query.utm_source);
    }

    if (isValid(query.utm_medium)) {
      setMedium(query.utm_medium);
    }

    if (isValid(query.utm_content)) {
      setContent(query.utm_content);
    }

    if (isValid(query.utm_campaign)) {
      setCampaign(query.utm_campaign);
    }
  }, [
    isValid,
    query.utm_campaign,
    query.utm_content,
    query.utm_medium,
    query.utm_source
  ]);

  const value = useMemo<UtmContextValue>(
    () => ({
      utmSource: source,
      utmMedium: medium,
      utmContent: content,
      utmCampaign: campaign
    }),
    [campaign, content, medium, source]
  );

  return <UtmContext.Provider value={value} children={children} />;
}
