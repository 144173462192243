import React, { ReactNode } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const Component = styled.div`
  display: inline-flex;
  flex: 1 1 auto;
  margin-bottom: 0.4rem;
  cursor: pointer;
  position: relative;

  ${mq('≥small')`
    margin-bottom: 1rem;
  `};

  input {
    height: 1px;
  }
`;

type Props = {
  children: ReactNode;
};

export function SelectContainer({ children }: Props) {
  return <Component children={children} />;
}
