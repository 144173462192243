import styled from 'styled-components';

type Size = 'none' | 'small' | 'medium' | 'large';

type Props = {
  top?: Size
  bottom?: Size
}

const sizes: Record<Size, string> = {
  none: '',
  small: '0.5rem',
  medium: '1rem',
  large: '2rem'
};

const Spacer = styled.div<Props>`
  margin-top: ${({ top = 'none' }) => sizes[top]};
  margin-bottom: ${({ bottom = 'none' }) => sizes[bottom]};
`;

export default Spacer;
