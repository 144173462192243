import { IPurchaseInfo } from './../../../types/IPurchaseInfo';
import { IMeCollection } from './../../../types/ICollection';
import { ICollection } from './../../../types/ICollection';
import axios, { AxiosRequestConfig } from 'axios';

import { get } from '~/App/helpers/cookie';

import { mwAxios } from '.';
import { IMePurchase } from '~/types/IPurchase';

type Arguments = AxiosRequestConfig & {
  secretKey?: string;
};

type ArgumentsWithSlug = AxiosRequestConfig & {
  slug: string;
  secretKey?: string;
};

const fetch = (args: Arguments) => {
  const request: Arguments = {
    ...{ method: 'get' },
    ...args
  };
  return axios(request);
};

const supporters = {
  index: (args: Arguments) => {
    return mwAxios({
      url: `/api/v2/supporters`,
      method: 'get',
      ...args
    });
  }
};

type PaymentMethodCategory = {
  identifier: string;
  name: string;
};

type SessionResponse = {
  id: string;
  client_token: string;
  payment_method_categories: PaymentMethodCategory[];
};

const klarna = {
  createSession: async ({ data, ...args }: Arguments) => {
    const response = await mwAxios.post<SessionResponse>(
      `/api/v2/klarna/sessions`,
      data,
      {
        headers: {
          'X-Session-Key': get('secretKey')
        },
        ...args
      }
    );

    return response.data;
  },
  updateSession: async (id: string, price: number) => {
    const response = await mwAxios.put<SessionResponse>(
      `/api/v2/klarna/sessions/${id}`,
      { price },
      {
        headers: {
          'X-Session-Key': get('secretKey')
        }
      }
    );

    return response.data;
  }
};

const meCollections = {
  index: (args: Arguments) => {
    return mwAxios.get<IMeCollection[]>(`/api/v2/users/me/collections`, {
      headers: {
        'X-Session-Key': args.secretKey || get('secretKey')
      },
      params: {
        ...args.params
      }
    });
  },
  show: (args: ArgumentsWithSlug) => {
    return mwAxios.get<IMeCollection>(
      `/api/v2/users/me/collections/${args.slug}`,
      {
        headers: {
          'X-Session-Key': args.secretKey || get('secretKey')
        },
        params: {
          ...args.params
        }
      }
    );
  },
  unpause: (args: ArgumentsWithSlug) =>
    mwAxios({
      url: `/api/v2/collections/${args.slug}/unpause`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      }
    }),
  enabled: (args: ArgumentsWithSlug) =>
    mwAxios({
      url: `/api/v2/collections/${args.slug}/enable`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      }
    })
};

const collections = {
  index: <T extends ICollection = ICollection>(args: Arguments) =>
    mwAxios.get<T[]>(`/api/v2/collections`, {
      params: {
        ...args.params
      }
    }),
  show: <T extends ICollection = ICollection>(args: ArgumentsWithSlug) =>
    mwAxios.get<T>(`/api/v2/collections/${args.slug}`),
  owners: (args: ArgumentsWithSlug) =>
    mwAxios({
      url: `/api/v2/collections/${args.slug}/owners`,
      method: 'get',
      params: {
        ...args.params
      }
    }),
  deleteOwner: (args: ArgumentsWithSlug) =>
    mwAxios.delete(`/api/v2/collections/${args.slug}/owners`, {
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        ...args.data
      }
    }),
  edit: (args: ArgumentsWithSlug) =>
    mwAxios({
      url: `/api/v2/collections/${args.slug}`,
      method: 'put',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        ...args.data
      }
    }),
  pause: (args: ArgumentsWithSlug) =>
    mwAxios({
      url: `/api/v2/collections/${args.slug}/pause`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        ...args.data
      }
    }),
  disabled: (args: ArgumentsWithSlug) =>
    mwAxios({
      url: `/api/v2/collections/${args.slug}/disable`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        ...args.data
      }
    }),
  create: (args: Arguments) =>
    mwAxios({
      url: `/api/v2/collections`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        ...args.data
      }
    }),
  ownerJoinCode: (args: Arguments) =>
    mwAxios({
      ...args,
      url: `/api/v2/collections/owners`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        ...args.data
      }
    })
};

const mePurchases = {
  index: async (args: Arguments) => {
    const { data } = await mwAxios.get<IMePurchase[]>(
      `/api/v2/users/me/purchases`,
      {
        headers: {
          'X-Session-Key': args.secretKey || get('secretKey')
        },
        params: {
          ...args.params
        }
      }
    );

    return data;
  },
  purchasesInfo: async ({ secretKey }: Arguments) => {
    const { data } = await mwAxios.get<IPurchaseInfo>(
      `/api/v2/users/me/purchases/info`,
      {
        headers: {
          'X-Session-Key': secretKey || get('secretKey')
        }
      }
    );

    return data;
  }
};

const supportMessages = {
  create: (args: Arguments) =>
    mwAxios({
      url: `/api/v2/support_messages`,
      method: 'post',
      data: {
        ...args.data
      }
    })
};

const emailSubscriptions = {
  create: (args: Arguments) =>
    mwAxios({
      url: `/api/v2/email/subscriptions`,
      method: 'post',
      data: {
        ...args.data
      }
    })
};

const feedback = {
  send: (args: Arguments) =>
    mwAxios({
      url: `/api/v2/feedback`,
      method: 'post',
      data: {
        ...args.data
      }
    }),

  edit: (args: Arguments) => {
    return mwAxios({
      url: `/api/v2/feedback`,
      method: 'put',
      data: {
        ...args.data
      }
    });
  }
};

export {
  fetch,
  supporters,
  meCollections,
  collections,
  mePurchases,
  supportMessages,
  emailSubscriptions,
  klarna,
  feedback
};
