import styled from 'styled-components';

// Helpers
import { heading2, heading4 } from '~/App/helpers/mixins';

export const AccountHeading1 = styled.h1`
  ${heading2};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

export const AccountHeading2 = styled.h2`
  ${heading4};
  margin-top: 1.5rem;
`;
