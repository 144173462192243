const expectedCharactersPerLine = 65;

const calculateUsedLines = (testString) => {
  const splitString = testString.split('\n');

  let lineCount = 0;
  splitString.forEach((subString) => {
    let subStringTrimmed = subString;
    lineCount += 1;
    while (subStringTrimmed.length > expectedCharactersPerLine) {
      lineCount += 1;
      subStringTrimmed = subStringTrimmed.slice(expectedCharactersPerLine);
    }
  });

  return lineCount;
};

const splitToLines = (
  textGreeting,
  charactersPerLine = expectedCharactersPerLine
) => {
  const splitString = textGreeting.split('\n');

  let newStringArray = [];
  splitString.forEach((subString) => {
    if (subString.length > charactersPerLine) {
      let subStringTrimmed = subString;
      while (subStringTrimmed.length > charactersPerLine) {
        const subStringToAdd = subStringTrimmed.slice(0, charactersPerLine);
        newStringArray.push(subStringToAdd);
        subStringTrimmed = subStringTrimmed.slice(charactersPerLine);
      }
      newStringArray.push(subStringTrimmed);
    } else {
      newStringArray.push(subString);
    }
  });

  return newStringArray;
};

const trimTextGreeting = (textGreeting, lineLimit) => {
  const splitString = textGreeting.trim().split('\n');

  if (splitString.length === 1) {
    return textGreeting.slice(0, expectedCharactersPerLine * lineLimit);
  } else {
    const splitStringExtended = splitToLines(
      textGreeting,
      expectedCharactersPerLine
    );

    if (splitStringExtended.length > lineLimit) {
      splitStringExtended.splice(
        lineLimit,
        splitStringExtended.length - lineLimit
      );
    }
    return splitStringExtended.join('\n');
  }
};

export const textHelper = {
  calculateUsedLines,
  trimTextGreeting
};
