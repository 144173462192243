import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { assemble } from '~/lib/slug-helper';

class OneBox extends React.Component {
  render() {
    if (_.isEmpty(this.props.related)) {
      return null;
    }

    var heading = this.props.heading
      ? 'Läs mer om ' + this.props.heading
      : 'Läs mer';

    return (
      <div className="relatedBox">
        <div className="relatedBox__heading">{heading}</div>
        <div className="relatedBox__list">
          {_.map(this.props.related, function (related) {
            return (
              <div className="relatedBox__listItem">
                <Link className="relatedBox__link" to={assemble(related)}>
                  {related.teaserHeading || related.heading}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

OneBox.displayName = 'RelatedBoxOneRow';

OneBox.defaultProps = {
  heading: '',
  related: []
};

export default OneBox;
