// Constants and helpers
import { gtmDetails, gtmFormName } from '~/App/config/gtmDetails';
import { paymentMethods } from '~/App/config/paymentMethods';
import { getDurationVariant } from '~/App/helpers/gtm-helper';

const DEFAULT_MONTHLY_DONATION_PRODUCT_ID = 31;
const MINIMUM_PRICE = 50;

export const buildState = (formContent, query, component) => {
  const price = query.price || formContent.defaultPrice;
  const fromStartProp = query.fromStart === true;
  const adjustedPrice = () => {
    const defaultPrice = parseInt(price, 10);
    if (isNaN(defaultPrice)) {
      return 0;
    }
    return defaultPrice >= MINIMUM_PRICE ? defaultPrice : MINIMUM_PRICE;
  };

  return {
    product: {
      id: formContent.productId
    },
    paymentMethod: {
      id: paymentMethods.klarnaPayments,
      name: 'Konto eller kort',
      slug: 'klarna'
    },
    productOptions: {
      product: {
        price: adjustedPrice(),
        customPrice: !formContent?.prices?.includes(adjustedPrice().toString()),
        duration: formContent?.defaultDuration || '',
        period: 'Month'
      },
      pul: true,
      campaignActivityId: formContent.campaignActivityId
    },
    customerContactType: 'automatic',
    partOptions: {
      parts: 2,
      currentPart: fromStartProp ? 2 : 1,
      validationKeys: {
        1: ['productOptions.product.price'],
        2: [
          'productOptions.customerContact.ssn',
          'productOptions.customerContact.email',
          'mobileBankId.bank',
          'mobileBankId.accountNumber',
          'mobileBankId.clearingNumber',
          'productOptions.customerContact.firstName',
          'productOptions.customerContact.lastName',
          'productOptions.customerContact.addressStreet',
          'productOptions.customerContact.addressZip',
          'productOptions.customerContact.addressCity',
          'stripeCard'
        ]
      }
    },
    gtm: {
      ...gtmDetails[formContent.productId],
      variant: getDurationVariant(formContent?.defaultDuration),
      formName: gtmFormName[component]
    } || {
      ...gtmDetails[DEFAULT_MONTHLY_DONATION_PRODUCT_ID],
      variant: getDurationVariant(formContent?.defaultDuration)
    }
  };
};
