import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

type WrapperProps = {
  isReady: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  opacity: ${({ isReady }) => (isReady ? 1 : 0)};
`;

const PlayIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.75rem;
  padding: 1rem 1.5rem;
  transition: all 0.3s;
  display: flex;
  font-size: 1.125rem;
  align-items: center;

  &::before {
    width: 1.375rem;
    height: 1.5rem;
    content: ' ';
    display: block;
    margin-right: 0.5rem;
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1634907218/assets/icons/noun-play-3501993.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 50%);
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${PlayIcon} {
      opacity: 0.9;
    }
  }
`;

const Player = styled(ReactPlayer)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
`;

type Props = {
  url: string;
};

export function VideoMedia({ url }: Props) {
  const [duration, setDuration] = useState<number | null>(null);
  const [isPlaying, setPlaying] = useState(false);
  const [isReady, setReady] = useState(false);

  const handlePlay = useCallback(() => setPlaying(true), []);
  const handlePause = useCallback(() => setPlaying(false), []);
  const handleReady = useCallback(() => setReady(true), []);

  const isLocalVideo = useMemo(
    () => !!url?.match(new RegExp('^.*.(mp4|MP4|mpg|MPG|mov|MOV|avi|AVI)$')),
    [url]
  );

  const playerIconLabel = useMemo(() => {
    if (!duration) return null;

    const value = Math.floor(duration);
    const seconds = value % 60;
    const minutes = Math.floor(value / 60);

    return `${minutes}:${seconds} min`;
  }, [duration]);

  const overlay = useMemo(() => {
    if (isPlaying) {
      return null;
    }
    if (!isLocalVideo) {
      return null;
    }

    if (!playerIconLabel) {
      return null;
    }

    return (
      <Overlay
        onClick={handlePlay}
        children={<PlayIcon children={playerIconLabel} />}
      />
    );
  }, [handlePlay, isLocalVideo, isPlaying, playerIconLabel]);

  if (!url) {
    return null;
  }

  if (!ReactPlayer.canPlay(url)) {
    return null;
  }

  return (
    <Wrapper isReady={isReady}>
      {overlay}
      <Player
        url={url}
        width="100%"
        height="100%"
        controls={isPlaying || !isLocalVideo}
        playing={isPlaying}
        onPause={handlePause}
        onPlay={handlePlay}
        onDuration={setDuration}
        onReady={handleReady}
      />
    </Wrapper>
  );
}
