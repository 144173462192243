import React, { useRef, useCallback, ChangeEvent } from 'react';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

import Text from '~/App/shared/components/Fields/Text';
import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';
import { InputWrapper } from '../components/InputWrapper';

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
  instructionText?: string;
  required?: boolean;
};

export function Ssn({
  values,
  validation,
  instructionText = 'Vi hämtar och sparar dina folkbokförda uppgifter.',
  required = true
}: Props) {
  const { isAuthenticated } = useAuthenticationContext();
  const value = values.productOptions.customerContact.ssn;
  const bankIdSsn = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      values.handlers.handleCustomerContactSsn(event, bankIdSsn.current);
    },
    [values.handlers]
  );

  return (
    <InputWrapper>
      <Text
        name="ssn"
        type="tel"
        value={value}
        onBlur={validation.showError.bind(
          undefined,
          'productOptions.customerContact.ssn'
        )}
        errors={validation.errors['productOptions.customerContact.ssn']}
        isValid={validation.isValid('productOptions.customerContact.ssn')}
        onChange={handleChange}
        inputRef={bankIdSsn}
        isInvalid={validation.isInvalid('productOptions.customerContact.ssn')}
        maxLength="12"
        isDisabled={value && isAuthenticated ? true : false}
        placeholder="Personnr (ÅÅÅÅMMDDCCCC)"
        instructions={instructionText}
        required={required}
      />
    </InputWrapper>
  );
}
