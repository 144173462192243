import React from 'react';
import styled from 'styled-components';

// Shared components
import Container from '~/App/shared/components/Container';
import CreateCollection from '~/App/shared/components/CreateCollection';

const Wrapper = styled.div`
  background-color: ${(props) =>
    props.theme.themeColors.primaryBackgroundColor};
`;

export const CreateCollectionForm = React.memo(function CreateCollectionForm(
  props
) {
  return (
    <Wrapper>
      <Container
        width="limited"
        paddingLeft="small"
        paddingRight="small"
        paddingTop="small"
        paddingBottom="small"
      >
        <CreateCollection {...props} />
      </Container>
    </Wrapper>
  );
});
