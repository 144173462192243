import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { parseInt, isNaN } from 'lodash';
import styled from 'styled-components';

import { heading1 } from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

import { IBMIScreen } from '../types';
import { useSelfTest } from '../context/useSelfTest';
import { useDebouncedEffect } from '~/App/shared/hooks/use-debounced-effect';
import { IBMIAnswer } from '~/types/IQuestionSession';

const Wrapper = styled.div`
  color: white;
  width: 100%;
`;

const BMIHeading = styled.h1`
  ${heading1}
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;

  ${mq('≥small')`
  font-size: 5.75rem;
`};
`;

const BMIBody = styled.p`
  margin-bottom: 2rem;
  color: ${(props) => props.theme.colors.white};
`;

const InputWrapper = styled.div`
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  margin-bottom: 2rem;

  ${mq('≥small')`
    flex-direction: row;
  `};
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;

  ${mq('≥small')`
    margin-right: 2rem;
    text-align: left;
  `};
`;

const Input = styled.input`
  text-align: center;
  width: 9rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-top: 0.25rem;
  font-size: 1rem;
  border: none;

  &:focus,
  &:active {
    outline: none;
  }
`;

type Props = {
  screen: IBMIScreen;
};

export function BMIScreen({ screen }: Props) {
  const { getAnswer, setAnswer } = useSelfTest();

  const answer = useMemo(() => getAnswer<IBMIAnswer>(screen.id), [
    getAnswer,
    screen.id
  ]);

  const [height, setHeight] = useState<number | undefined>(answer?.height);
  const [weight, setWeight] = useState<number | undefined>(answer?.weight);

  const getNumber = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const parsed = parseInt(`${event.target.value}`);
    const isValid = parsed && !isNaN(parsed);
    const value = isValid ? parsed : undefined;

    return value;
  }, []);

  const handleHeightChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setHeight(getNumber(event)),
    [getNumber]
  );

  const handleWeightChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setWeight(getNumber(event)),
    [getNumber]
  );

  const bmi = useMemo(() => {
    if (!weight || !height) {
      return undefined;
    }

    const heightInMeters = height / 100;
    const bmi = weight / (heightInMeters * heightInMeters);

    return bmi;
  }, [height, weight]);

  const value = useMemo(() => {
    if (!bmi) return 0;
    if (bmi < 25) {
      return 1;
    }

    if (bmi < 30) {
      return 0;
    }

    return -1;
  }, [bmi]);

  useDebouncedEffect(
    () => {
      setAnswer({
        ...answer,
        questionId: screen.id,
        questionLabel: screen.body,
        height,
        weight,
        bmi,
        value
      });
    },
    300,
    [height, weight, bmi, value]
  );

  return useMemo(
    () => (
      <Wrapper>
        <BMIHeading children={screen.heading} />
        <BMIBody children={screen.body} />
        <InputWrapper>
          <Label>
            Din längd (cm)
            <Input value={`${height || ''}`} onChange={handleHeightChange} />
          </Label>
          <Label>
            Din vikt (kg)
            <Input value={`${weight || ''}`} onChange={handleWeightChange} />
          </Label>
        </InputWrapper>
      </Wrapper>
    ),
    [
      handleHeightChange,
      handleWeightChange,
      height,
      screen.body,
      screen.heading,
      weight
    ]
  );
}
