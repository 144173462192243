import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent
} from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { spacer, heading5, description2 } from '~/App/helpers/mixins';
import { angleDown } from '~/App/helpers/icons';
import { slideElementDown, slideElementUp } from '~/App/helpers/animations';

// Shared components
import Container from '~/App/shared/components/Container';
import { HtmlContent } from '~/App/shared/components/HtmlContent';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { useMatchMedia } from '~/App/shared/hooks/use-match-media';
import { Heading } from './components/Heading';
import { BackgroundColor, Padding, Width } from '~/types/Block';

const Spacer = styled.div`
  ${spacer};
`;

type Heading4Props = {
  isEnabled: boolean;
  isOpen?: boolean;
};

const Heading4 = styled.h4<Heading4Props>`
  ${heading5};
  position: relative;
  font-weight: ${({ isOpen, isEnabled, theme }) =>
    isOpen || !isEnabled ? theme.fontWeights.bold : theme.fontWeights.regular};
  color: ${({ theme }) => theme.themeColors.primary};
  padding: ${({ isEnabled }) =>
    isEnabled ? '0 2.5rem 0.5rem 0' : '0 0 0.5rem 0'};
  line-height: 1.555555556;
  margin: 1rem 0 0 0;
  transition: border-bottom-color 0.3s ease;

  ${mq('≥small')`
    line-height: 1.555555556;
  `};

  &::after {
    ${angleDown};
    font-size: 1.75rem;
    line-height: 1.75rem;
    display: ${({ isEnabled }) => (isEnabled ? 'block' : 'none')};
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.themeColors.primary};
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    color: ${({ theme }) => theme.colors.white};
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'none')};
  }

  &:hover,
  &:focus {
    color: ${({ isEnabled, theme }) =>
      isEnabled ? theme.themeColors.primaryHover : theme.themeColors.primary};

    &::after {
      background-color: ${({ theme }) => theme.themeColors.primaryHover};
    }
  }
`;

type DescriptionProps = {
  isEnabled: boolean;
  isOpen?: boolean;
};

const Description = styled.div<DescriptionProps>`
  ${description2};
  padding-right: ${({ isEnabled, isOpen }) =>
    isEnabled && isOpen ? '2.5rem' : '0'};
  border-bottom-width: ${({ isEnabled }) => (isEnabled ? '1px' : '0px')};
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.themeColors.primary};
  overflow: hidden;

  > *:last-child {
    padding-bottom: ${({ isEnabled, isOpen }) =>
      isEnabled && isOpen ? '1rem ' : '0'};
  }
`;

const TwoColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

type ColumnProps = {
  columns?: number;
};

const Column = styled.div<ColumnProps>`
  margin: 0;
  flex-basis: 100%;

  ${mq<ColumnProps>('≥small')`
    flex-basis: ${({ columns }) =>
      columns === 2 ? 'calc(50% - 1rem)' : '100%'};
  `};
`;

type Page = {
  id: string;
  preview: {
    heading: string;
    preamble: string;
  };
};

type Props = {
  pages: Page[];
  width: Width;
  heading?: string;
  padding: Padding;
  backgroundColor: BackgroundColor;
  headingSettings: {
    fontSize: 'heading1' | 'heading2' | 'heading3' | 'sectionHeading';
    textAlign: string;
  };
  settings: {
    mobile: boolean;
    desktop: boolean;
    columns: number;
  };
};

export default function FAQ({
  pages = [],
  width,
  heading = '',
  padding,
  backgroundColor = 'none',
  headingSettings,
  settings
}: Props) {
  const [isEnabled, setEnabled] = useState(false);
  const [open, setOpen] = useState<Record<string, boolean>>({});

  const wrapper = useRef<HTMLDivElement | null>(null);

  const matches = useMatchMedia(
    [
      settings.mobile ? '(max-width: 48em)' : '',
      settings.desktop ? '(min-width: 48em)' : ''
    ]
      .filter((m) => m)
      .join(', ')
  );

  const enableAccordion = useCallback(() => {
    if (isEnabled) return;

    setEnabled(true);
    const items = wrapper.current?.querySelectorAll('.js-content') || [];

    Array.from(items).forEach((element) => {
      slideElementUp(element as HTMLElement, 0);
    });
  }, [isEnabled]);

  const disableAccordion = useCallback(() => {
    if (!isEnabled) return;

    setEnabled(false);
    const items = wrapper.current?.querySelectorAll('.js-content') || [];
    Array.from(items).forEach((element) => {
      slideElementDown(element as HTMLElement, 0);
    });
  }, [isEnabled]);

  const slideToggle = useCallback(
    (target, duration = 300, isOpen) =>
      isOpen
        ? slideElementUp(target, duration)
        : slideElementDown(target, duration),
    []
  );

  const handleClick = useCallback(
    (page) => (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (!isEnabled) return;

      const target = event.target as Node;

      setOpen((state) => ({
        ...state,
        [page.id]: state[page.id] ? false : true
      }));

      setTimeout(() => slideToggle(target.nextSibling, 300, open[page.id]), 50);
    },
    [isEnabled, open, slideToggle]
  );

  const renderPage = useCallback(
    (page: Page, index) => (
      <div key={index}>
        {page.preview.heading && (
          <Heading4
            onClick={handleClick(page)}
            isEnabled={isEnabled}
            isOpen={open[page.id]}
            children={page.preview.heading}
          />
        )}
        {page.preview.preamble && (
          <Description
            isOpen={open[page.id]}
            isEnabled={isEnabled}
            className="js-content"
          >
            <HtmlContent children={page.preview.preamble} />
          </Description>
        )}
      </div>
    ),
    [handleClick, isEnabled, open]
  );

  useEffect(() => {
    matches ? enableAccordion() : disableAccordion();
  }, [disableAccordion, enableAccordion, matches]);

  const halfLength = Math.ceil(pages.length / 2);
  const firstPages = pages.slice(0, halfLength);
  const lastPages = pages.slice(halfLength, pages.length);

  return (
    <BlockBackground backgroundColor={backgroundColor}>
      <Container
        width={width}
        paddingTop={padding.top}
        paddingLeft="small"
        paddingRight="small"
        paddingBottom={padding.bottom}
      >
        {heading && <Heading {...headingSettings} children={heading} />}
        <Spacer ref={wrapper}>
          <TwoColumns>
            <Column
              columns={settings.columns}
              children={firstPages.map(renderPage)}
            />
            <Column
              columns={settings.columns}
              children={lastPages.map(renderPage)}
            />
          </TwoColumns>
        </Spacer>
      </Container>
    </BlockBackground>
  );
}
