import React from 'react';
import classNames from 'classnames';

import mediaHelper from '~/App/helpers/media-helper';
import { hero as defaultHeroImage } from '~/App/config/default-images';

const getImageUrl = (value) => {
  const { secure_url: secureUrl = '' } = value;

  if (value && typeof value === 'string') {
    return value;
  }

  if (secureUrl) {
    return secureUrl;
  }

  return defaultHeroImage;
};

class Wrapper extends React.Component {
  render() {
    const { heroAlignContent = '', heroAlignBackground = '' } = this.props;

    const [textAlign, verticalAlign] = heroAlignContent.split('-');

    const imageUrl = mediaHelper.getUrl(getImageUrl(this.props.image), {
      height: 844,
      width: 1500
    });

    const wrapperClasses = classNames('hero', {
      'hero--overlay':
        this.props.overlay || this.props.heroBackgroundModifier === 'overlay'
    });

    const infoClasses = classNames('hero__info', {
      'hero__info--darkText': this.props.heroTextColor === 'black'
    });

    const contentStyles = { textAlign, verticalAlign };

    const backgroundStyles = {
      backgroundImage: `url(${imageUrl})`,
      backgroundPosition: heroAlignBackground.split('-').join(' ')
    };

    return (
      <div className={`${wrapperClasses} ${this.props.modifier}`}>
        <div className="hero__background" style={backgroundStyles}>
          <div className="hero__main">
            <div className={infoClasses} style={contentStyles}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Wrapper.displayName = 'HeroWrapper';

Wrapper.defaultProps = {
  modifier: '',
  overlay: false,
  heroTextColor: 'white',
  heroBackgroundModifier: '',
  heroAlignBackground: 'center-center',
  backgroundImageAlign: 'center-center',
  image: defaultHeroImage
};

export default Wrapper;
