import React, { useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Select, { InputActionMeta, StylesConfig } from 'react-select';

import { IndicatorsContainer } from '../../../IndicatorsContainer';
import { buildNoOptionsMessage } from './components/NoOptionsMessage';
import { FuneralHome } from '~/types/IDeceasedContact';
import { useDebouncedEffect } from '~/App/shared/hooks/use-debounced-effect';
import { funeralHome } from '~/App/helpers/http/funeralHome';
import { IValidation } from '~/App/shared/components/Validation';

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const Message = styled.div`
  font-size: 0.875em;
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 0.325rem;
`;

type Props = {
  value: FuneralHome | null;
  validation: IValidation;
  validationKey: string;
  onChange: (value: FuneralHome | null) => void;
};

export function FuneralHomeSelect({
  value,
  validation,
  validationKey,
  onChange
}: Props) {
  const [funeralHomes, setFuneralHomes] = useState<FuneralHome[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const shouldSearch = useMemo(() => {
    if (!inputValue) {
      return false;
    }

    return inputValue.length > 0;
  }, [inputValue]);

  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = useCallback(() => setMenuOpen(true), []);
  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const search = useCallback(
    async (value: string) => {
      if (!inputValue) {
        return;
      }

      setFuneralHomes([]);
      setLoading(true);

      const data = await funeralHome.search(value);

      setFuneralHomes(data);
      setLoading(false);
    },
    [inputValue]
  );

  useDebouncedEffect(
    () => {
      if (!shouldSearch) {
        return;
      }

      search(inputValue);
    },
    300,
    [inputValue, shouldSearch]
  );

  const handleInputChange = useCallback(
    (value: string, { action }: InputActionMeta) => {
      if (action === 'input-change' || action === 'set-value') {
        setInputValue(value);
        onChange({ name: value });
      }
    },
    [onChange]
  );

  const theme = useTheme();

  const components = useMemo(
    () => ({
      IndicatorsContainer,
      NoOptionsMessage: buildNoOptionsMessage({ loading })
    }),
    [loading]
  );

  const isValid = useMemo(() => validation.isValid(validationKey), [
    validation,
    validationKey
  ]);

  const isInvalid = useMemo(() => validation.isInvalid(validationKey), [
    validation,
    validationKey
  ]);

  const errors = useMemo(() => validation.errors[validationKey], [
    validation.errors,
    validationKey
  ]);

  const borderColor = useCallback(
    (color: string) => {
      if (isInvalid) {
        return theme.colors.errorRed;
      }

      if (isValid) {
        return theme.colors.apple;
      }

      return color;
    },
    [isInvalid, isValid, theme.colors.apple, theme.colors.errorRed]
  );

  const styles = useMemo<StylesConfig<FuneralHome, false>>(
    () => ({
      control: (provided) => ({
        ...provided,
        minHeight: '46px',
        borderColor: borderColor(theme.colors.lightDust),
        backgroundColor: isInvalid ? theme.colors.lightRed : theme.colors.white,
        boxShadow: 'none',
        ':hover': {
          ...provided[':hover'],
          borderColor: borderColor(theme.colors.lightDust)
        },
        ':focus-within': {
          borderColor: borderColor(theme.colors.dust),
          backgroundColor: theme.colors.white
        }
      }),
      container: (provided) => ({
        ...provided,
        fontSize: '0.9375em'
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 2
      })
    }),
    [
      borderColor,
      isInvalid,
      theme.colors.dust,
      theme.colors.lightRed,
      theme.colors.lightDust,
      theme.colors.white
    ]
  );
  return (
    <Wrapper>
      <Select
        onMenuClose={closeMenu}
        onMenuOpen={openMenu}
        menuIsOpen={menuOpen}
        components={components}
        instanceId="funeral-home"
        placeholder="Sök efter begravningsbyrå"
        styles={styles}
        options={funeralHomes}
        onInputChange={handleInputChange}
        onChange={onChange}
        inputValue={inputValue}
        value={menuOpen ? null : value}
        getOptionLabel={(x) => x.name || ''}
        getOptionValue={(x) => `${x.id}`}
      />
      {errors && errors[0] ? <Message children={errors[0]} /> : null}
    </Wrapper>
  );
}
