import React, { ComponentProps } from 'react';
import styled, { css } from 'styled-components';

// Shared components
import { GeneralStyles } from '~/App/shared/components/Fields/Components/Input';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 7.5rem;
  height: 2.375rem;
`;

const InputWrapper = styled.div`
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 10px;
    content: 'kr';
  }
`;

const Input = styled.input`
  ${GeneralStyles};
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  min-height: 2.375rem;
`;

type Props = {
  errors?: string[];
  isInvalid?: boolean;
} & ComponentProps<typeof Input>;

const Message = styled.div<Props>`
  font-size: 0.875em;
  width: 100%;

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      color: ${(props) => props.theme.colors.errorRed};
      margin-top: 0.325rem;
    `};
`;

export function PriceInput({ errors = [], isInvalid, ...props }: Props) {
  return (
    <div>
      <Wrapper>
        <InputWrapper>
          <Input
            isEmpty={!props.value && !props.defaultValue}
            isInvalid={isInvalid}
            type={'text'}
            {...props}
          />
        </InputWrapper>
      </Wrapper>
      {errors && errors[0] ? (
        <Message isInvalid={isInvalid}>{errors[0]}</Message>
      ) : null}
    </div>
  );
}
