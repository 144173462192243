import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { useInfiniteScroll } from '~/App/shared/hooks/use-infinite-scroll';
import { IMemorialPage } from '~/types/ICollection';
import { collections } from '~/App/helpers/http';
import { ctaButton } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

import { BlockHero } from '~/App/shared/components/Blocks';
import { Container } from '~/App/shared/components/Container';
import { FloatingButton } from '~/App/shared/components/FloatingButton';
import { MemorialPageCard } from './components/MemorialPageCard';
import { Helmet } from 'react-helmet';
import { siteUrl } from '~/App/helpers/env';

const CreateButton = styled.button`
  ${ctaButton};
  margin-top: 2rem;

  ${mq('<small')`
    display: none;
  `}
`;

const StyledFloatingButton = styled(FloatingButton)`
  bottom: 1rem;

  ${mq('>small')`
    display: none;
  `}
`;

const Cards = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  ${mq('<medium')`
   grid-gap: 1rem;
   grid-template-columns: 1fr 1fr;
  `};

  ${mq('<tiny')`
   grid-gap: 1rem;
   grid-template-columns: 1fr;
  `};
`;

type Props = {
  data: {
    content?: {
      contentDefinitions?: {
        meta?: {
          pageTitle?: string;
          pageDescription?: string;
          ogTitle?: string;
          ogImage?: string;
          ogDescription?: string;
        };
      };
    };
    memorialPages: IMemorialPage[];
  };
};

export function MemorialPages({
  data: {
    content: { contentDefinitions: { meta = {} } = {} } = {},
    memorialPages
  }
}: Props) {
  const [data, setData] = useState(memorialPages);

  const navigate = useNavigate();

  const handleButtonClick = useCallback(
    () => navigate('/stod-oss/minnessidor/skapa-minnessida'),
    [navigate]
  );

  const renderCard = useCallback(
    (page: IMemorialPage, index: number) => (
      <MemorialPageCard key={index} page={page} />
    ),
    []
  );

  const loadPage = useCallback(async (page: number) => {
    const res = await collections.index<IMemorialPage>({
      params: {
        page,
        period: 3650,
        sorting: 'latestCreated',
        per_page: 12,
        type: 'memorial_page'
      }
    });

    setData((state) => [...state, ...res.data]);

    return res.data.length === 12;
  }, []);

  const trigger = useInfiniteScroll({
    loadPage,
    hasFollowingPage: data.length === 12
  });
  const cards = useMemo(() => data.map(renderCard), [renderCard, data]);

  return (
    <>
      <Helmet>
        <title>{meta.pageTitle}</title>
        <link rel="canonical" href={siteUrl('stod-oss/minnessidor')} />
        <meta name="description" content={meta.pageDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:image" content={meta.ogImage} />
        <meta property="og:description" content={meta.ogDescription} />
      </Helmet>
      <BlockHero
        width="normal"
        heading="Minnessidor"
        body="<p>Dela minnen och tankar om någon nära som gått bort på en personlig minnessida.</p>"
        showLightTextColor={true}
        large={{
          style: 'fixed',
          size: 'large',
          videoSource:
            'https://res.cloudinary.com/cancerfonden/video/upload/v1632860745/video/edis-test/Minnessida_Hero_2.mp4',
          source: {
            secure_url:
              'https://res.cloudinary.com/cancerfonden/image/upload/v1645607282/assets/Skapa-minnessida.jpg',
            url: '',
            format: 'jpg'
          }
        }}
        small={{
          style: 'fixed',
          size: 'large',
          videoSource:
            'https://res.cloudinary.com/cancerfonden/video/upload/v1652801370/video/edis-test/376x360_hero_Minnessidor.mp4',
          source: {
            secure_url:
              'https://res.cloudinary.com/cancerfonden/image/upload/q_80,c_fit,h_600/hero-images/pnn1z7gymwf45xmdb4zf.jpg',
            url: '',
            format: 'jpg'
          }
        }}
        headingSettings={{
          fontSize: 'heading1',
          textAlign: ''
        }}
      >
        <CreateButton
          children="Skapa en minnessida"
          onClick={handleButtonClick}
        />
        <StyledFloatingButton
          buttonStyle="cta"
          onClick={handleButtonClick}
          children="Skapa en minnessida"
        />
      </BlockHero>
      <Container
        width="normal"
        padding={{
          top: 'large',
          bottom: 'large',
          left: 'small',
          right: 'small'
        }}
      >
        <Cards children={cards} />
        {trigger}
      </Container>
    </>
  );
}
