import { fetch } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';
import { collections as collectionsResource } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

type Params = any;
type Query = any;

export const loadContent = () =>
	fetch({
		url: contentApi('/v1/content-definitions'),
		params: {
			slugs: [ 'collections-hero', 'collections-hero-box-left', 'collections-hero-box-right' ],
			metaKey: 'collectionsHero'
		}
	});

export const loadCollections = ({ query }: { query: Query }) =>
	collectionsResource.index({
		params: {
			period: 365,
			sorting: 'latestDonated',
			per_page: 12,
			...query
		}
	});

export const loadData = async ({ query }: LoadDataProps<Params, Query>) => {
	const response = await Promise.all([ loadContent(), loadCollections({ query }) ]);

	const [ content, collections ] = response;

	return {
		data: {
			content: content.data,
			collections: collections.data
		}
	};
};
