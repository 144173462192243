import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';

// Helpers
import { siteUrl } from '~/App/helpers/env';
import { assemble } from '~/lib/slug-helper';

// Components
import TopLeftNavigation from './components/TopLeftNavigation';
import { ResearchInformation } from './components/ResearchInformation/ResearchInformation';
import Body from './components/Body';
import Heading from './components/Heading';
import { diagnoseLinks } from './components/ResearchInformation/components/DiagnoseLinks';
import BlockArea from '~/App/shared/components/BlockArea';

type Meta = {
  description: string;
  title: string;
};

export type ResearchProject = {
  id: string;
  meta: Meta;
  applicantId: string;
  contentType: string;
  slug: string;
  applicant: string;
  gender: 'male' | 'female';
  university: string;
  diagnoseGroups: string[];
  researchArea: string;
  title: string;
  background: string;
  description: string;
  goal: string;
  amount: number;
  year: string;
  status: 'ongoing' | 'completed';
  blocks: any;
};

type Props = {
  data: { researchProject: ResearchProject };
};

export function ResearchProject({ data: { researchProject } }: Props) {
  const { meta } = researchProject;
  const processedBlocks = researchProject.blocks;

  const renderResearchLinks = useCallback((diagnose, index, arr) => {
    return (
      <React.Fragment key={diagnose}>
        {diagnose in diagnoseLinks ? (
          <a href={diagnoseLinks[diagnose as keyof typeof diagnoseLinks]}>
            {diagnose.toLocaleLowerCase()}
          </a>
        ) : (
          diagnose.toLocaleLowerCase()
        )}
        {arr.length - 1 !== index && ', '}
      </React.Fragment>
    );
  }, []);

  return (
    <div>
      <Helmet>
        <title>{meta.title}</title>
        <link rel="canonical" href={siteUrl(assemble(researchProject))} />
        <meta name="description" content={meta.description} />
      </Helmet>
      <TopLeftNavigation
        text="Till alla projekt"
        linkToPage="/forskning/projekt"
      />
      <Heading
        title={researchProject.title}
        applicant={researchProject.applicant}
        university={researchProject.university}
        year={researchProject.year}
      />
      <ResearchInformation
        amount={researchProject.amount}
        currency={'kronor'}
        categoryLines={[
          {
            label: 'Diagnos:',
            title: researchProject.diagnoseGroups.map((item, index, arr) =>
              renderResearchLinks(item, index, arr)
            )
          },
          {
            label: 'Forskningsområde:',
            title: researchProject.researchArea.toLocaleLowerCase()
          }
        ]}
      />
      <Body
        background={researchProject.background}
        description={researchProject.description}
        goal={researchProject.goal}
      />
      <BlockArea blocks={processedBlocks}></BlockArea>
    </div>
  );
}
