import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation, UNSAFE_LocationContext } from 'react-router-dom';
import { Location, Action } from 'history';

import { stringify, parse } from '~/lib/query-string';

import routes from './App/config/routes';
import { loadData } from './lib/data-fetching';
import { getAll } from './App/helpers/cookie';
import { useQuery } from './App/shared/hooks/use-query';

type VoidFunction = () => void | null;

type DataFetchingProps = {
  pageContent: Record<string, unknown>;
  children: React.ReactNode;
  onTransition: VoidFunction;
  onLoading: VoidFunction;
  onComplete: VoidFunction;
  onData: (location: Location, data: any) => void;
};

const defaultFunc = () => null;

export default function DataFetching({
  children,
  pageContent,
  onTransition = defaultFunc,
  onLoading = defaultFunc,
  onComplete = defaultFunc,
  onData = defaultFunc
}: DataFetchingProps) {
  const location = useLocation();
  const query = useQuery();

  const [previousLocation, setPreviousLocation] = useState<Location | null>(
    location
  );
  const [hasError, setHasError] = useState<boolean>(false);

  const ensureHasError = useCallback(
    (value: boolean) => (hasError !== value ? setHasError(value) : null),
    [hasError, setHasError]
  );

  const handleComplete = useCallback(
    (location: Location, data: unknown) => {
      const pathHasChanged = location.pathname !== previousLocation?.pathname;

      if (pathHasChanged) {
        onTransition();
      }

      if (data) {
        onData(location, data);
      }

      setPreviousLocation(location);
      onComplete();
    },
    [onComplete, onData, onTransition, previousLocation]
  );

  const handleError = useCallback(
    (location: Location) => {
      setPreviousLocation(location);
      ensureHasError(true);
      onComplete();
    },
    [ensureHasError, onComplete]
  );

  useEffect(() => {
    const currentPath = location?.pathname;
    const currentSearch = location?.search;
    const previousPath = previousLocation?.pathname;
    const previousSearch = previousLocation?.search;

    if (!previousPath) {
      setPreviousLocation(location);
      return;
    }

    if (previousPath === currentPath && previousSearch === currentSearch) {
      return;
    }

    const alreadyHasData = pageContent[location.pathname];
    const mustWaitForData = !alreadyHasData;

    if (mustWaitForData) {
      onLoading();
    }

    loadData(location.pathname, routes, {
      query: parse(location.search),
      oldData: pageContent[location.pathname],
      cookies: getAll()
    })
      .then((data) => {
        ensureHasError(false);
        handleComplete(location, data);
      })
      .catch(() => handleError(location));
  }, [
    location,
    handleComplete,
    handleError,
    previousLocation,
    ensureHasError,
    pageContent,
    onLoading
  ]);

  useEffect(() => {
    if (!hasError) {
      return;
    }

    const search = stringify({
      ...query,
      skip_takeover: true
    });

    const url = `${window.location.origin}${window.location.pathname}?${search}`;

    window.location.href = url;
  }, [hasError, query]);

  return useMemo(
    () => (
      <UNSAFE_LocationContext.Provider
        children={hasError ? null : children}
        value={{
          location: previousLocation || location,
          navigationType: Action.Push
        }}
      />
    ),
    [children, hasError, location, previousLocation]
  );
}
