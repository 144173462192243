import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';
import { stringify } from '~/lib/query-string';

// Helpers
import mq from '~/App/helpers/mq';
import useQuery from '~/App/shared/hooks/use-query';
import {
  CategoryFilter,
  CategoryOption,
  CategoryType
} from './components/CategoryFilter';
import { SingleValueFilter } from './components/SingleValueFilter';
import Container from '~/App/shared/components/Container';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { pushGTMResearchFilter } from '~/App/helpers/gtm-helper';

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.lightPolar};
`;

const FilterContainer = styled(Container)``;

const DropDownContainer = styled.div`
  display: flex;
  margin-top: 0.75rem;

  ${mq('<small')`
    display: block;
    margin-top: 1.5rem;
  `};
`;

const StupidMargin = styled.div`
  ${mq('<small')`
    margin-top: 1.5rem;
  `};
`;
const StyledPrimaryButton = styled(PrimaryButton)`
  height: 40px;
`;

const Form = styled.form``;

export type Categories = {
  universityOptions: string[];
  researchAreaOptions: string[];
  diagnoseGroupOptions: string[];
  yearsOptions: string[];
};

export type QueryParameters = {
  diagnoseGroup: string | string[];
  researchArea: string | string[];
  university: string | string[];
  status: string | string[];
  year: number | number[] | string[];
};

export default function SearchField({ ...props }: Categories) {
  const query: QueryParameters = useQuery();

  const [chosenDiagnoseGroup, setChosenDiagnoseGroup] = useState(
    makeChosenCategoryOptions(query.diagnoseGroup, 'Diagnose')
  );
  const [chosenResearchArea, setChosenResearchArea] = useState(
    makeChosenCategoryOptions(query.researchArea, 'Research')
  );
  const [chosenUniversity, setChosenUniversity] = useState(
    makeChosenCategoryOptions(query.university, 'University')
  );
  const [year, setYear] = useState(
    typeof query?.year === 'number'
      ? [query.year?.toString()]
      : query.year?.map((y: number | string) => y.toString())
  );
  const [status, setStatus] = useState(
    typeof query?.status === 'string'
      ? [query?.status]
      : query?.status
      ? query.status?.map((i) => decodeURIComponent(i))
      : ['Pågående']
  );

  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const chosenDiagnoseGroupNames =
        chosenDiagnoseGroup &&
        chosenDiagnoseGroup.map((c: CategoryOption) => c.value);

      const chosenResearchAreaNames =
        chosenResearchArea &&
        chosenResearchArea.map((c: CategoryOption) => c.value);

      const chosenUniversityNames =
        chosenUniversity &&
        chosenUniversity.map((c: CategoryOption) => c.value);

      const queryString = stringify({
        ...query,
        diagnoseGroup: chosenDiagnoseGroupNames,
        researchArea: chosenResearchAreaNames,
        university: chosenUniversityNames,
        year: year,
        status: status
      });

      pushGTMResearchFilter({
        diagnoseGroup: chosenDiagnoseGroupNames,
        researchArea: chosenResearchAreaNames,
        university: chosenUniversityNames,
        status: status,
        year: year
      });

      navigate(`${location.pathname}?${queryString}`);
    },
    [
      query,
      chosenDiagnoseGroup,
      chosenResearchArea,
      chosenUniversity,
      year,
      status,
      navigate,
      location.pathname
    ]
  );

  const handleChangeStatus = useCallback((status) => {
    setStatus(
      status.map((item: CategoryOption) => {
        return item.value;
      })
    );
  }, []);

  const handleChangeYear = useCallback((year) => {
    setYear(
      year.map((item: CategoryOption) => {
        return item.value;
      })
    );
  }, []);

  const handleChangeCategories = useCallback((chosenCategoryOptions) => {
    setChosenDiagnoseGroup(
      chosenCategoryOptions.filter(
        (item: CategoryOption) => item.type === 'Diagnose'
      )
    );
    setChosenResearchArea(
      chosenCategoryOptions.filter(
        (item: CategoryOption) => item.type === 'Research'
      )
    );
    setChosenUniversity(
      chosenCategoryOptions.filter(
        (item: CategoryOption) => item.type === 'University'
      )
    );
  }, []);

  return (
    <Background>
      <FilterContainer width="limited" paddingLeft="small" paddingRight="small">
        <Form action="" onSubmit={handleSubmit} noValidate>
          <CategoryFilter
            onChange={handleChangeCategories}
            chosenCategoryOptions={{
              Diagnose: chosenDiagnoseGroup,
              Research: chosenResearchArea,
              University: chosenUniversity
            }}
            {...props}
          />
          <DropDownContainer>
            <SingleValueFilter
              placeholder="Projektstatus"
              options={['Pågående', 'Avslutad']}
              onChange={handleChangeStatus}
              value={status}
            />
            <StupidMargin />
            <SingleValueFilter
              placeholder="År"
              options={props.yearsOptions}
              onChange={handleChangeYear}
              value={year}
            />
            <StupidMargin />
            <StyledPrimaryButton onClick={handleSubmit} data-ga-track="1337">
              Sök
            </StyledPrimaryButton>
          </DropDownContainer>
        </Form>
      </FilterContainer>
    </Background>
  );
}

const makeChosenCategoryOptions = (
  categoryNames: string | string[],
  categoryType?: CategoryType
): CategoryOption[] | [] => {
  if (categoryNames && typeof categoryNames === 'string') {
    return [
      {
        value: categoryNames,
        label: categoryNames,
        type: categoryType
      }
    ];
  }
  if (Array.isArray(categoryNames)) {
    return categoryNames?.map((name) => ({
      value: decodeURIComponent(name),
      label: decodeURIComponent(name),
      type: categoryType
    }));
  }
  return [];
};
