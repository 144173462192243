import { swishTypes } from '~/App/config/swishTypes';
import { paymentMethods } from '~/App/config/paymentMethods';

export const buildSchema = () => ({
  'productOptions.product.price': {
    presence: {
      allowEmpty: false,
      message: '^Ange ett belopp'
    },
    numericality: {
      greaterThan: 49,
      lessThanOrEqualTo: 999999,
      message: '^Minsta gåvobelopp är 50 kr'
    }
  },
  'productOptions.customerContact.email': {
    validateEmail: (_, { paymentMethod }) => {
      return paymentMethod.id !== paymentMethods.klarnaPayments;
    }
  },
  'productOptions.customerContact.ssn': {
    validateSsn: (value, { customerContactType }) =>
      customerContactType === 'automatic'
  },
  'productOptions.customerContact.firstName': {
    validateFirstName: (value, { customerContactType }) =>
      customerContactType === 'manual'
  },
  'productOptions.customerContact.lastName': {
    validateLastName: (value, { customerContactType }) =>
      customerContactType === 'manual'
  },
  'productOptions.customerContact.addressStreet': {
    validateStreetName: (value, { customerContactType }) =>
      customerContactType === 'manual'
  },
  'productOptions.customerContact.addressZip': {
    validateZipCode: (value, { customerContactType }) =>
      customerContactType === 'manual'
  },
  'productOptions.customerContact.addressCity': {
    validateCity: (value, { customerContactType }) =>
      customerContactType === 'manual'
  },
  'mobileBankId.bank': {
    validateBank: (value, { paymentMethod }) =>
      paymentMethod.id === paymentMethods.autoGiroBankId
  },
  'mobileBankId.accountNumber': {
    validateAccountNumber: (value, { paymentMethod }) =>
      paymentMethod.id === paymentMethods.autoGiroBankId
  },
  'mobileBankId.clearingNumber': {
    validateClearingNumber: (value, { paymentMethod }) =>
      paymentMethod.id === paymentMethods.autoGiroBankId
  },
  swishPhoneNumber: {
    validatePhoneNumber: (_, { paymentMethod, swish }) =>
      paymentMethod.id === 8 && swish.swishType === swishTypes.ecommerce
  },
  'klarna.isReady': {
    inclusion: (_, { paymentMethod }) => {
      if (paymentMethod.id === paymentMethods.klarnaPayments) {
        return {
          within: [true],
          message: '^Klarna är inte redo'
        };
      }

      return false;
    }
  }
});
