import React from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { heading2, preamble } from '~/App/helpers/mixins';
import { specialBackgrounds } from '~/App/shared/components/BlockBackground';

// Shared components
import {
  Background,
  Wrapper,
  ImageWrapper,
  ProductImage
} from '~/App/shared/components/ThanksPage';
import { VartGarPengarna } from '~/App/shared/components/Blocks/components/Thanks/components/shared/VartGarPengarna';
import LargeShareButtons from '~/App/shared/components/LargeShareButtons';

// Local components
import { MonthlyDonationThanksReceipt } from './MonthlyDonationThanks.Receipt';

const Content = styled.div`
  position: relative;
  max-width: 35.5rem;
  margin: 0 auto;
  padding: 7rem 0 3rem;
  text-align: center;
`;
const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const ShareButtonWrapper = styled.div`
  margin-top: 2rem;
`;

export default function MonthlyDonationThanks({ purchase, data }) {
  const background = specialBackgrounds[data.background || 'cfBluePink'];

  const firstName = purchase.purchase.productOptions.customerContact.firstName;

  const greeting = firstName
    ? data.heading.replace('%%first_name%%', ` ${firstName}`)
    : data.heading.replace('%%first_name%%', '');

  return (
    <div>
      <Background background={background}>
        <Wrapper>
          <ImageWrapper>
            <ProductImage
              src={mediaHelper.getUrl(data.headingImage, {
                transformation: [
                  {
                    width: 280,
                    height: 280,
                    crop: 'lpad',
                    gravity: 'center'
                  },
                  {
                    width: 92,
                    height: 92,
                    dpr: '2.0',
                    crop: 'pad',
                    quality: 100
                  }
                ]
              })}
            />
          </ImageWrapper>
          <Content>
            <Heading1>{greeting}</Heading1>
            <Preamble>{data.texts.text1}</Preamble>
            <ShareButtonWrapper>
              <LargeShareButtons
                to={data.shareLink}
                email={{ subject: data.shareEmailSubject }}
                sms={{ body: data.shareSmsBody }}
                more={{ title: data.shareMoreTitle, body: data.shareMoreBody }}
                facebook={true}
              />
            </ShareButtonWrapper>
          </Content>
        </Wrapper>
        <MonthlyDonationThanksReceipt purchase={purchase} data={data} />
        <VartGarPengarna />
      </Background>
    </div>
  );
}
