import React from 'react';
import styled from 'styled-components';

import { CollectionAdmin } from '~/types/ICollection';

//Helpers
import mq from '~/App/helpers/mq';
import { Avatar } from '~/App/shared/components/Avatar';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.75rem;

  ${mq('≥small')`
    margin-right: 1rem;
  `};
`;

const Info = styled.p`
  > * {
    font-size: 14px;
    display: block;
  }
`;

type Props = {
  admin: CollectionAdmin;
};

export function Admin({ admin }: Props) {
  return (
    <Wrapper>
      <StyledAvatar user={admin} size="small" />
      <Info>
        {`${admin.firstName} ${admin.lastName || ''}` + (admin.you ? ' (Du)' : '')}
        <span>Administratör</span>
      </Info>
    </Wrapper>
  );
}
