import React from 'react';
import styled from 'styled-components';
import { heading3 } from '~/App/helpers/mixins';
import Container from '~/App/shared/components/Container';
import { ResearchProject } from '../ResearchProject';

const Heading3 = styled.h2`
  ${heading3}
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  margin-bottom: 3.5625rem;
`;

const ResearchProjectContent = styled.div`
  max-width: 740px;
`;

export default function Body({
  background,
  description,
  goal
}: Partial<ResearchProject>) {
  return (
    <Container
      width="limited"
      paddingLeft="small"
      paddingRight="small"
      paddingTop="large"
      paddingBottom='large'
    >
      <ResearchProjectContent>
        <Heading3>Bakgrund</Heading3>
        <Paragraph>{background}</Paragraph>
        <Heading3>Beskrivning</Heading3>
        <Paragraph>{description}</Paragraph>
        <Heading3>Mål</Heading3>
        <Paragraph>{goal}</Paragraph>
      </ResearchProjectContent>
    </Container>
  );
}
