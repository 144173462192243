import { ComponentType, createElement, ReactNode } from 'react';
import { omit } from 'lodash';

type Props<T> = T & {
  children?: ReactNode;
};

export default function omitProps<T = any>(
  element: string | ComponentType<T>,
  omitProps: string[]
) {
  return function ({ children, ...props }: Props<T>) {
    return createElement(element, omit(props, omitProps) as T, children);
  };
}
