import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const Item = styled.li`
  display: block;
  padding: 0.5rem 1rem 0.25rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightDust};

  ${mq('≥large')`
    border-bottom: 0 none;
  `};

  &:first-child {
    margin-top: 0.75rem;

    ${mq('≥large')`
      margin-top: 0;
    `};
  }

  &:last-child {
    border-bottom: 0 none;
    margin-bottom: 2.75rem;

    ${mq('≥large')`
      margin-bottom: 0;
    `};
  }
`;
