import React from 'react';
import styled, { keyframes } from 'styled-components';

import { PrimaryButton } from '~/App/shared/components/Elements';

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-0.5rem);
  }

  50% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-0.25rem);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
  animation: ${bounce} 2s infinite;
  padding: 0;
  min-width: 3rem;
  min-height: 3rem;
`;

const Image = styled.img`
  filter: invert(1);
  position: relative;
  top: 1px;
`;

type Props = {
  onClick: () => void;
};

export function BouncingButton({ onClick }: Props) {
  return (
    <StyledPrimaryButton onClick={onClick}>
      <Image
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yODUsMTEuMjg0TDE2LDE5LjU3MWwtOC4yODUtOC4yODhjLTAuMzk1LTAuMzk1LTEuMDM0LTAuMzk1LTEuNDI5LDAgIGMtMC4zOTQsMC4zOTUtMC4zOTQsMS4wMzUsMCwxLjQzbDguOTk5LDkuMDAybDAsMGwwLDBjMC4zOTQsMC4zOTUsMS4wMzQsMC4zOTUsMS40MjgsMGw4Ljk5OS05LjAwMiAgYzAuMzk0LTAuMzk1LDAuMzk0LTEuMDM2LDAtMS40MzFDMjUuMzE5LDEwLjg4OSwyNC42NzksMTAuODg5LDI0LjI4NSwxMS4yODR6IiBmaWxsPSIjMTIxMzEzIiBpZD0iRXhwYW5kX01vcmUiLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjxnLz48L3N2Zz4="
        alt="Arrow down"
      />
    </StyledPrimaryButton>
  );
}
