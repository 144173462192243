import { useMemo } from 'react';

import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { paymentMethods } from '~/App/config/paymentMethods';

import { swishTypes } from '~/App/config/swishTypes';
import { CommerceCheckoutState } from './useCommerceCheckoutState';

export function useCommerceCheckoutSchema() {
  const shoppingCart = useShoppingCart();
  const hasPhysicalProducts = useMemo(
    () =>
      !!shoppingCart.items.find((x) => x.variant?.product?.type === 'Physical'),
    [shoppingCart.items]
  );

  return useMemo(
    () => ({
      'productOptions.customerContact.ssn': {
        validateSsn: (
          _: unknown,
          { paymentMethod }: CommerceCheckoutState
        ) => ({
          allowEmpty: paymentMethod?.id === paymentMethods.klarnaPayments
        })
      },
      'productOptions.customerContact.company': {
        validateCompany: (
          _: unknown,
          { customerContactType }: CommerceCheckoutState
        ) => customerContactType === 'company'
      },
      'productOptions.customerContact.cid': {
        validateCid: (
          _: unknown,
          { customerContactType }: CommerceCheckoutState
        ) => customerContactType === 'company'
      },
      'productOptions.customerContact.addressStreet': {
        validateStreetName: (
          _: unknown,
          { customerContactType }: CommerceCheckoutState
        ) =>
          customerContactType === 'manual' || customerContactType === 'company'
      },
      'productOptions.customerContact.addressZip': {
        validateZipCode: (
          _: unknown,
          { customerContactType }: CommerceCheckoutState
        ) =>
          customerContactType === 'manual' || customerContactType === 'company'
      },
      'productOptions.customerContact.addressCity': {
        validateCity: (
          _: unknown,
          { customerContactType }: CommerceCheckoutState
        ) =>
          customerContactType === 'manual' || customerContactType === 'company'
      },
      'productOptions.customerContact.firstName': {
        validateFirstName: (
          _: unknown,
          { customerContactType }: CommerceCheckoutState
        ) => (customerContactType === 'manual' ? { allowEmpty: false } : false)
      },
      'productOptions.customerContact.lastName': {
        validateLastName: (
          _: unknown,
          { customerContactType }: CommerceCheckoutState
        ) => (customerContactType === 'manual' ? { allowEmpty: false } : false)
      },
      'productOptions.customerContact.phoneNumber': {
        presence: {
          allowEmpty: !hasPhysicalProducts,
          message: '^Glöm inte att fylla i ditt mobilnummer'
        },
        validatePhoneNumber: (
          _: unknown,
          { customerContactType }: CommerceCheckoutState
        ) => ({
          message: '^Mobilnumret måste vara 10 siffror',
          allowEmpty: !(
            customerContactType === 'company' || hasPhysicalProducts
          )
        })
      },
      'productOptions.customerContact.email': {
        validateEmail: (
          _: unknown,
          { paymentMethod }: CommerceCheckoutState
        ) => {
          return paymentMethod?.id !== paymentMethods.klarnaPayments;
        }
      },
      'klarna.isReady': {
        inclusion: (_: unknown, { paymentMethod }: CommerceCheckoutState) => {
          if (paymentMethod?.id === paymentMethods.klarnaPayments) {
            return {
              within: [true],
              message: '^Klarna är inte redo'
            };
          }

          return false;
        }
      },
      'mobileBankId.bank': {
        validateBank: (_: unknown, { paymentMethod }: CommerceCheckoutState) =>
          paymentMethod?.id === paymentMethods.bankID ||
          paymentMethod?.id === paymentMethods.autoGiroBankId
      },
      'mobileBankId.accountNumber': {
        validateAccountNumber: (
          _: unknown,
          { paymentMethod }: CommerceCheckoutState
        ) =>
          paymentMethod?.id === paymentMethods.bankID ||
          paymentMethod?.id === paymentMethods.autoGiroBankId
      },
      'mobileBankId.clearingNumber': {
        validateClearingNumber: (
          _: unknown,
          { paymentMethod }: CommerceCheckoutState
        ) =>
          paymentMethod?.id === paymentMethods.bankID ||
          paymentMethod?.id === paymentMethods.autoGiroBankId
      },
      swishPhoneNumber: {
        validatePhoneNumber: (
          _: unknown,
          { paymentMethod, swish }: CommerceCheckoutState
        ) => paymentMethod?.id === 8 && swish.swishType === swishTypes.ecommerce
      }
    }),
    [hasPhysicalProducts]
  );
}
