import React from 'react';
import styled from 'styled-components';

//helpers
import mq from '~/App/helpers/mq';
import { caretRight } from '~/App/helpers/icons';

// Shared components
import TextArea from '~/App/shared/components/Fields/TextArea';

const Wrapper = styled.div`
  .text-area__message {
    position: absolute;
    margin-top: 3.5rem;
  }
`;

const PreviousArrow = styled.button`
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  width: 2.75rem;
  height: 2.75rem;
  display: block;
  font-size: 2rem;
  color: ${({ theme }) => theme.themeColors.primary};
  transform: scale(-1, 1);

  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }

  &::after {
    ${caretRight};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    text-align: center;
  }
`;

const NextArrow = styled.button`
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  width: 2.75rem;
  height: 2.75rem;
  display: block;
  font-size: 2rem;
  color: ${({ theme }) => theme.themeColors.primary};

  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }

  &::after {
    ${caretRight};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    text-align: center;
  }
`;

const HiddenTextSpan = styled.span`
  width: 0;
  height: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  display: block;
`;

const TextSpan = styled.span`
  white-space: nowrap;

  ${mq('≤tiny')`
    font-size: 0.75rem;
  `};
`;

const AboveMicroText = styled.span`
  ${mq('≤micro')`
    display: none;
  `};
`;

const NumberSpan = styled.span`
  letter-spacing: 0.125rem;
  font-size: 0.875rem;
  padding: 0 0.5rem;

  ${mq('≤small')`
    font-size: 0.75rem;
  `};

  ${mq('≤medium')`
    padding: 0 0.375rem;
  `};
`;

const MemorialGreetingTextArea = styled(TextArea)`
  border-radius: 0.25rem 0.25rem 0 0;
  margin-top: 0.25rem;
  margin-bottom: ${({ isInvalid }) => (isInvalid ? '-0.25rem' : '')};
`;

const BrowseNextGreetingButtons = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.alto};
  border-top: 0;
  background-color: ${(props) => props.theme.colors.smoke};
  border-radius: 0 0 0.25rem 0.25rem;

  ${mq('≥medium')`
    margin-bottom: 1rem;
  `};
`;

const ResetLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export default class TextAreaWithTemplates extends React.Component {
  render() {
    const { templates = [] } = this.props;
    const {
      selectedTemplate,
      handleTextChange,
      handleTemplateStep,
      resetTextToTemplate
    } = this.props;
    const numberOfTemplates = templates.length;
    const previousStep = -1;
    const nextStep = 1;
    const isDifference =
      this.props.texts[selectedTemplate] !== templates[selectedTemplate];

    return (
      <Wrapper>
        <MemorialGreetingTextArea
          {...this.props}
          onChange={(event) => {
            handleTextChange(event, selectedTemplate);
          }}
        />
        {templates.length > 0 && (
          <BrowseNextGreetingButtons>
            <div>
              <PreviousArrow
                type="button"
                onClick={() => handleTemplateStep(previousStep)}
              >
                <HiddenTextSpan>Föregående</HiddenTextSpan>
              </PreviousArrow>
            </div>
            {selectedTemplate === 0 ? (
              <TextSpan>
                Bläddra för exempel{' '}
                <AboveMicroText>på hälsningar</AboveMicroText>
              </TextSpan>
            ) : (
              <div>
                <TextSpan>
                  Exempel <AboveMicroText>på hälsning</AboveMicroText>
                </TextSpan>
                <NumberSpan>
                  ({selectedTemplate}/{numberOfTemplates - 1})
                </NumberSpan>
                {isDifference && (
                  <TextSpan>
                    <ResetLink
                      onClick={() => {
                        resetTextToTemplate(selectedTemplate);
                      }}
                    >
                      Återställ
                    </ResetLink>
                  </TextSpan>
                )}
              </div>
            )}

            <div>
              <NextArrow
                type="button"
                onClick={() => handleTemplateStep(nextStep)}
              >
                <HiddenTextSpan>Nästa</HiddenTextSpan>
              </NextArrow>
            </div>
          </BrowseNextGreetingButtons>
        )}
      </Wrapper>
    );
  }
}
