import { formatDate } from './date.service';

export const formatSsn = (input, previousInput = '', addedYearCallback) => {
  const ssnRegex = /^\d*$/;

  if (!ssnRegex.test(input)) {
    return previousInput;
  }

  if (previousInput.length === 1 && input.length === 2) {
    if (input !== '19' && input !== '20') {
      const fullYear = addYear(input);

      if (typeof addedYearCallback === 'function') {
        addedYearCallback();
      }

      return fullYear;
    }
  }

  return input;
};

const addYear = (input) => {
  const currentYear = formatDate(null, 'YY');

  if (parseInt(input, 10) < parseInt(currentYear - 10, 10)) {
    return `20${input}`;
  } else {
    return `19${input}`;
  }
};

export const moveCursorAfterYear = (inputRef) => {
  setTimeout(() => {
    inputRef.selectionEnd = 4;
    inputRef.selectionStart = 4;
  }, 10);
};

// 890506-9368 -> 198905069368
export const normalizeSsn = (ssn) => {
  if (!ssn) {
    return '';
  }

  const cleanedValue = ssn.replace('-', '');
  const shouldAddYear = cleanedValue.length === 10;
  const withYear = shouldAddYear ? addYear(cleanedValue) : cleanedValue;

  return withYear;
};
