import React, { useMemo } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export function Stripe({ children }) {
  const stripePromise = useMemo(() => loadStripe(process.env.STRIPE_KEY), []);

  return <Elements stripe={stripePromise} children={children} />;
}

export default Stripe;
