import React, { useMemo } from 'react';
import styled from 'styled-components';

// Shared components
import { HiddenAboveMedium } from '../../components/Forms/components/FormLayout';

import { LotterySummary } from './Summary';
import { SubmitButton } from '../../components/Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '../../components/Forms/components/SwishOtherDeviceButton';
import { IntegrityTextArea } from '../../components/Forms/components/IntegrityTextArea';

// Fields
import { MultiPartFormValues, Section } from '../../../MultiPartForm';
import { LotteryFormContent } from '../Lottery';
import { IValidation } from '../../../Validation';
import { LotteryTicketStateValues } from '../../components/States';
import { ISubmit } from '../../components/Submits';
import { Payment } from './Payment';
import { AlertMessageContainer } from '../../../Elements';
import { getPurchaseErrorMessage } from '../../components/Forms/helpers';

import { Product } from './Product';
import { Customer } from './Customer';
import { customerContactTypes } from '~/App/config/customerContactTypes';
import * as Old from '../../components/Steps/Payment';
import { useSuggestedPaymentMethod } from '../useSuggestedPaymentMethod';

const IntegrityTextAreaWrapper = styled.div`
  margin: 0.5rem 0;
`;

type Props = {
  formContent: LotteryFormContent;
  validation: IValidation;
  submit: ISubmit;
  values: LotteryTicketStateValues & MultiPartFormValues;
};

export function Form({ formContent, validation, values, submit }: Props) {
  const {
    availablePaymentMethods,
    defaultPaymentMethod
  } = useSuggestedPaymentMethod({ values });

  const smallSummery = useMemo(
    () => <LotterySummary paddingTop="small" values={values} />,
    [values]
  );

  const submitButtons = useMemo(
    () => (
      <>
        {submit.errors && !submit.isPolling && !submit.isSending && (
          <AlertMessageContainer messageStyle="error">
            {getPurchaseErrorMessage(submit.exceptionCode)}
          </AlertMessageContainer>
        )}
        <SubmitButton values={values} validation={validation} submit={submit} />
        <SwishOtherDeviceButton
          values={values}
          text={formContent.anotherDeviceText}
        />

        <IntegrityTextAreaWrapper>
          <IntegrityTextArea values={values} />
        </IntegrityTextAreaWrapper>
      </>
    ),
    [formContent.anotherDeviceText, submit, validation, values]
  );

  const isCompany = useMemo(
    () =>
      formContent.availableCustomerContactTypes.indexOf(
        customerContactTypes.company
      ) !== -1,
    [formContent.availableCustomerContactTypes]
  );

  return (
    <form onSubmit={submit.handleSubmit} noValidate>
      <Product
        formContent={formContent}
        values={values}
        validation={validation}
      />

      <Customer
        formContent={formContent}
        validation={validation}
        values={values}
      />

      <Section
        values={values}
        part={3}
        validation={validation}
        title={'Välj betalsätt'}
      >
        {values.productOptions.product.period !== 'Once' && (
          <p>
            För att få lotter flera gånger per år behöver du ett autogiro hos
            oss. Signera med Mobilt BankID eller betalkort.
          </p>
        )}
        {isCompany ? (
          <Old.Payment
            values={values}
            validation={validation}
            submit={submit}
            formContent={{
              ...formContent,
              defaultPaymentMethod,
              availablePaymentMethods
            }}
          />
        ) : (
          <Payment
            values={values}
            validation={validation}
            submitButtons={submitButtons}
            formContent={formContent}
          />
        )}
        <HiddenAboveMedium children={smallSummery} />
      </Section>
      {values.partOptions.currentPart === 3 && isCompany && (
        <>
          <SubmitButton
            values={values}
            validation={validation}
            submit={submit}
          />
          <SwishOtherDeviceButton
            values={values}
            text={formContent.anotherDeviceText}
          />
        </>
      )}
    </form>
  );
}
