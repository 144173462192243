import React, { ComponentType, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import {
  heading1,
  heading2,
  heading3,
  sectionHeader
} from '~/App/helpers/mixins';

type HeadingProps = {
  textAlign: string;
};

const Heading1 = styled.h1<HeadingProps>`
  ${heading1};
  color: ${({ theme }) => theme.themeColors.primary};
  text-align: ${({ textAlign }) => textAlign};
`;

const Heading2 = styled.h2<HeadingProps>`
  ${heading2};
  color: ${({ theme }) => theme.themeColors.primary};
  text-align: ${({ textAlign }) => textAlign};
`;

const Heading3 = styled.h3<HeadingProps>`
  ${heading3};
  color: ${({ theme }) => theme.themeColors.primary};
  text-align: ${({ textAlign }) => textAlign};
`;

const SectionHeading = styled.h2<HeadingProps>`
  ${sectionHeader};
  color: ${({ theme }) => theme.related.headingColor};
`;

type Props = {
  fontSize: string;
  textAlign: string;
  children: ReactNode;
};

export function Heading({ fontSize, ...props }: Props) {
  const Component = useMemo<ComponentType<HeadingProps>>(() => {
    if (fontSize === 'heading1') {
      return Heading1;
    }

    if (fontSize === 'heading2') {
      return Heading2;
    }

    if (fontSize === 'sectionHeading') {
      return SectionHeading;
    }

    return Heading3;
  }, [fontSize]);

  return <Component {...props} />;
}
