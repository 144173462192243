import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { heading3 } from '~/App/helpers/mixins';

import Link from '~/App/shared/components/Link';
import { Heading } from './Heading';
import { Thanks } from './Thanks';
import { rotate360 } from '~/App/helpers/animations';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { Width } from '~/types/Block';
import { errorCircle } from '~/App/helpers/icons';

const ErrorIcon = styled.div`
  color: ${({ theme }) => theme.colors.errorRed};
  font-size: 3rem;
  margin: 0 auto;

  &::after {
    ${errorCircle};
  }
`;

type SwishInstructionsProps = {
  size?: 'large' | 'small';
};

const SwishInstructions = styled.h3<SwishInstructionsProps>`
  ${heading3};
  max-width: ${({ size }) => (size === 'large' ? '20rem' : '16rem')};
  margin: 0 auto;
  text-align: center;

  ${mq<SwishInstructionsProps>('≥small')`
    max-width: ${({ size }) => (size === 'large' ? '22rem' : '18rem')};
  `};
`;

const SwishContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwishError = styled.p`
  max-width: 15rem;
  margin: 0.5rem auto 1rem auto;
  text-align: center;

  ${mq('≥large')`
    margin: 0.5rem auto 1.5rem auto;
  `};
  ${mq('≥small')`
    max-width: 17rem;
  `};
`;

const SwishButton = styled(PrimaryButton)`
  margin: 0px auto 0.75rem;
`;

const SwishLink = styled(Link)`
  white-space: nowrap;
  color: inherit;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  margin: 2rem auto 0.75rem auto;
`;

const SwishSpinner = styled.div`
  width: 100%;
  height: 100%;
  animation: ${rotate360} 0.3s linear infinite;
  border-top: 2px solid ${({ theme }) => theme.swishSpinner.primaryColor};
  border-right: 2px solid ${({ theme }) => theme.swishSpinner.secondaryColor};
  border-bottom: 2px solid ${({ theme }) => theme.swishSpinner.secondaryColor};
  border-left: 2px solid ${({ theme }) => theme.swishSpinner.primaryColor};
  border-radius: 100%;
  content: ' ';
  z-index: 1;
`;

type PaymentStatus = 'unknown' | 'pending' | 'paid' | 'failed' | 'timedout';
type Props = {
  paymentStatus: PaymentStatus;
  width: Width;
  headingSettings?: {
    fontSize: string;
  };
  handleClick?: () => void | null;
  isDesktop?: boolean;
};

export function SwishBlockInstructions({
  paymentStatus,
  width,
  headingSettings,
  handleClick,
  isDesktop
}: Props) {
  if (paymentStatus === 'failed') {
    return (
      <SwishContainer>
        <ErrorIcon />
        <SwishInstructions size="large">
          Din betalning blev inte godkänd
        </SwishInstructions>
        <SwishError>
          Kontakta givarservice på{' '}
          <SwishLink to="tel:+46101991010">010-199 10 10</SwishLink> eller
          försök igen.
        </SwishError>
        <SwishButton children="Försök igen" onClick={handleClick} />
      </SwishContainer>
    );
  }

  if (paymentStatus === 'paid') {
    return <Thanks width={width} headingSettings={headingSettings} />;
  }
  return (
    <>
      <Heading
        heading={isDesktop ? 'Öppna Swish i din mobil' : 'Strax klart!'}
        fontSize={headingSettings?.fontSize}
        width={width}
      ></Heading>
      <SpinnerWrapper>
        <SwishSpinner />
      </SpinnerWrapper>
    </>
  );
}
