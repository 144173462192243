import React, { Fragment } from 'react';
import styled from 'styled-components';

// Shared components
import Container from '~/App/shared/components/Container';
import { MonthlyDonation } from '~/App/shared/components/Donation';
import {
  SpecialBlockBackground,
  specialBackgrounds
} from '~/App/shared/components/BlockBackground';
import { TaxReductionBlock } from './TaxReductionBlock/TaxReductionBlock';
import useQuery from '~/App/shared/hooks/use-query';

const Wrapper = styled(SpecialBlockBackground)`
  background-color: ${({ theme }) => theme.colors.lightPolar};
`;

export default function MonthlyDonationForm({ data, component }) {
  const query = useQuery();

  const fromStart = query.fromStart === true;
  const background = fromStart ? specialBackgrounds.gala : null;

  return (
    <Fragment>
      <Wrapper background={background}>
        <Container
          width="limited"
          paddingLeft="small"
          paddingRight="small"
          paddingTop="small"
          paddingBottom="small"
        >
          <MonthlyDonation component={component} formContent={data} />
        </Container>
      </Wrapper>
      <TaxReductionBlock />
    </Fragment>
  );
}
