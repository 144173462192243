import React from 'react';

import styled from 'styled-components';
import { heading1 } from '~/App/helpers/mixins';
import { ISectionStartScreen } from '../types';

import mq from '~/App/helpers/mq';

type Props = {
  screen: ISectionStartScreen;
};

const Wrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

const Heading = styled.h1`
  ${heading1};
  color: ${(props) => props.theme.colors.white};
  max-width: 65ch;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;

  ${mq('≥small')`
    font-size: 5rem;
  `};
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.white};
  max-width: 65ch;
`;

const Image = styled.img`
  width: 13rem;
  height: 13rem;
  margin: 2rem auto;
  display: block;
  background: white;
  padding: 1rem;
  border-radius: 50%;

  ${mq('≥small')`
   width: 9rem;
    height: 9rem;
    margin: 1rem auto 0;
  `};
`;

export function SectionStartScreen({ screen }: Props) {
  return (
    <Wrapper>
      <Heading>{screen.heading}</Heading>
      <Text>{screen.text}</Text>
      <Image src={screen.imageUrl} />
    </Wrapper>
  );
}
