import { css } from 'styled-components';

export const GeneralStyles = css`
  font-size: 0.9375em;
  font-family: inherit;
  display: block;
  width: 100%;
  min-height: 46px;
  text-align: inherit;
  color: ${({ theme }) => theme.colors.black};
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  padding: 17px 10px 4px;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease, background 0.15s ease, border ease-in-out 0.15s,
    padding 0.15s ease;
`;

export const EmptyStyles = css`
  padding-top: 9px;
  padding-bottom: 10px;
`;

export const ValidStyles = css`
  border-color: ${({ theme }) => theme.colors.apple};

  &::-ms-clear {
    display: none;
  }
`;

export const InvalidStyles = css`
  border-color: ${({ theme }) => theme.colors.errorRed};
  background: ${({ theme }) => theme.colors.lightRed};
`;

export const DisabledStyles = css`
  border-color: ${({ theme }) => theme.colors.silver};
`;

export const AutofillStyles = css`
  box-shadow: inset 0 0 200px #fff, inset 0 0 200px #fff, inset 0 0 200px #fff;
`;
