import React, { useMemo, useState, useCallback } from 'react';
import Select, {
  StylesConfig,
  InputActionMeta,
  MultiValue,
  ActionMeta,
  SingleValue
} from 'react-select';
import styled from 'styled-components';
import colors from '~/App/config/themes/colors';
import { NoOptionsMessage } from './components/NoMessageContainer';
import { IndicatorsContainer } from './components/IndicatorsContainer';
import { GroupHeading } from './components/GroupHeading';

const GroupStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export type CategoryType = 'Diagnose' | 'Research' | 'University';

export type CategoryOption = {
  value: string;
  label: string;
  type?: CategoryType;
};

export interface GroupedOption {
  label: string;
  category: CategoryType;
  options: CategoryOption[];
}

type CategoryParams = {
  Diagnose: CategoryOption[] | [];
  Research: CategoryOption[] | [];
  University: CategoryOption[] | [];
};

type Props = {
  universityOptions: string[];
  researchAreaOptions: string[];
  diagnoseGroupOptions: string[];
  chosenCategoryOptions: CategoryParams;
  onChange: (
    newValue: MultiValue<CategoryOption> | SingleValue<CategoryOption>,
    actionMeta: ActionMeta<CategoryOption>
  ) => void;
};

export function CategoryFilter({
  diagnoseGroupOptions,
  researchAreaOptions,
  universityOptions,
  onChange,
  chosenCategoryOptions
}: Props) {
  const [openCategories, setOpenCategories] = useState<
    (CategoryType | undefined)[]
  >(['Diagnose']);

  const groupedOptions: readonly GroupedOption[] = useMemo(
    () => [
      {
        label: 'Diagnos',
        category: 'Diagnose',
        options: makeGroupOptions(diagnoseGroupOptions, 'Diagnose')
      },
      {
        label: 'Forskningsområde',
        category: 'Research',
        options: makeGroupOptions(researchAreaOptions, 'Research')
      },
      {
        label: 'Lärosäte',
        category: 'University',
        options: makeGroupOptions(universityOptions, 'University')
      }
    ],
    [diagnoseGroupOptions, researchAreaOptions, universityOptions]
  );

  const searchStyles = useMemo<
    StylesConfig<CategoryOption, false | true, GroupedOption>
  >(
    () => ({
      container: (provided) => ({
        ...provided,
        textAlign: 'left',
        cursor: 'pointer',
        paddingTop: '2rem'
      }),
      control: (provided) => ({
        ...provided,
        cursor: 'pointer'
      }),
      option: (styles, option) => ({
        ...styles,
        color: colors.darkBlue,
        padding: '4px 8px',
        marginRight: '8px',
        marginBottom: '8px',
        width: 'none',
        cursor: 'pointer',
        backgroundColor: colors.seashell,
        display: openCategories.includes(option.data.type) ? '' : 'none'
      }),
      menuList: (provided) => ({
        ...provided,
        cursor: 'default'
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: colors.seashell,
        color: colors.darkBlue
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: colors.darkBlue
      }),
      group: (provided) => ({
        ...provided,
        paddingTop: '24px',
        paddingLeft: '24px',
        paddingBottom: '0px',
        '> div': {
          display: 'flex',
          flexWrap: 'wrap'
        }
      }),
      groupHeading: () => ({
        marginBottom: '0.75em',
        fontWeight: 'bold',
        cursor: 'pointer',
        color: '#4a4a4a'
      })
    }),
    [openCategories]
  );

  const showCategoryItems = useCallback(
    (category: CategoryType) => {
      if (openCategories.includes(category)) {
        return setOpenCategories(
          openCategories.filter((selected) => selected !== category)
        );
      } else {
        return setOpenCategories([...openCategories, category]);
      }
    },
    [openCategories]
  );

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      newValue.length > 0
        ? setOpenCategories(['Diagnose', 'Research', 'University'])
        : setOpenCategories([]);
    }
  };

  const formatGroupLabel = useMemo(
    () => (data: GroupedOption) => (
      <GroupStyle>
        <GroupHeading
          showCategoryItems={showCategoryItems}
          openCategories={openCategories}
          data={data}
        />
      </GroupStyle>
    ),
    [openCategories, showCategoryItems]
  );

  const components = useMemo(
    () => ({
      IndicatorsContainer,
      NoOptionsMessage
    }),
    []
  );

  const component = useMemo(
    () => (
      <Select<CategoryOption, false | true, GroupedOption>
        name="search"
        instanceId={"multiple-category-filter"}
        placeholder="Sök"
        components={components}
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
        defaultValue={[
          ...chosenCategoryOptions.Diagnose,
          ...chosenCategoryOptions.Research,
          ...chosenCategoryOptions.University
        ]}
        onChange={onChange}
        styles={searchStyles}
        closeMenuOnSelect={false}
        isMulti={true}
        onInputChange={onInputChange}
      />
    ),
    [
      components,
      formatGroupLabel,
      groupedOptions,
      onChange,
      searchStyles,
      chosenCategoryOptions
    ]
  );

  return component;
}

// TODO: This is called on update
const makeGroupOptions = (
  categoryNames: string[],
  categoryType?: CategoryType
): CategoryOption[] => {
  return categoryNames?.length > 0
    ? categoryNames?.map((name) => ({
        value: name,
        label: name,
        type: categoryType
      }))
    : [];
};
