import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Columns, RelatedPageItem } from '~/types/RelatedBlock';

// Helpers
import mq from '~/App/helpers/mq';
import {
  heading3,
  arrowLink,
  preamble,
  description3
} from '~/App/helpers/mixins';
import { formatUTCDate } from '~/App/helpers/date.service';

import Link from '~/App/shared/components/Link';
import { HtmlContent } from '~/App/shared/components/HtmlContent';
import { ArticleTeaserImage } from './ArticleTeaser.Image';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Heading = styled.h3`
  ${heading3};
  color: ${(props) => props.theme.themeColors.primary};
`;

const Preamble = styled.div`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-top: 0.25rem;
  margin-bottom: 1rem;
`;

const PublishDate = styled.div`
  ${description3};
  margin-bottom: 0.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  text-transform: none;
`;

const HeadingWrapper = styled.div`
  margin-top: 1rem;
`;

const ArrowLink = styled(Link)`
  ${arrowLink};
  line-height: 1.25rem;
  margin-top: 0;

  ${mq('≥small')`
    line-height: 1.5rem;
  `};
`;

const HeadingLink = styled(Link)`
  display: block;
  color: inherit;

  &:hover {
    color: ${(props) => props.theme.themeColors.primaryHover};
  }
`;

type Props = {
  showPublishedAt: boolean;
  columns: Columns;
  item: RelatedPageItem;
};

export function ArticleTeaser({ showPublishedAt, columns, item }: Props) {
  const formattedPublishedAt = useMemo(() => {
    if (!item.publishedAt) return null;
    return formatUTCDate(item.publishedAt, 'YYYY-MM-DD');
  }, [item.publishedAt]);

  const to = useMemo(
    () => ({
      slug: item.slug,
      contentType: item.contentType
    }),
    [item.slug, item.contentType]
  );

  return useMemo(
    () => (
      <Wrapper>
        <ArticleTeaserImage
          imageUrl={item.preview?.image?.secure_url}
          imageAlt={item.preview?.imageAlt}
          containerColumns={columns}
          slug={item.slug}
          contentType={item.contentType}
        />
        <HeadingWrapper>
          {showPublishedAt && formattedPublishedAt && (
            <PublishDate children={formattedPublishedAt} />
          )}

          {item.preview?.heading &&
            (item.slug ? (
              <Heading>
                <HeadingLink to={to} children={item.preview?.heading} />
              </Heading>
            ) : (
              <Heading children={item.preview?.heading} />
            ))}
        </HeadingWrapper>
        {item.preview?.preamble && (
          <Preamble>
            <HtmlContent
              dangerouslySetInnerHTML={{
                __html: item.preview.preamble
              }}
            />
          </Preamble>
        )}
        {item.slug && <ArrowLink to={to} children={item.preview?.linkText} />}
      </Wrapper>
    ),
    [columns, formattedPublishedAt, item, showPublishedAt, to]
  );
}
