import { Dispatch, SetStateAction, createContext } from "react";

export type ImageSelectorContextValue = {
  image?: string
  setImage: Dispatch<SetStateAction<string | undefined>>
}

export const ImageSelectorContext = createContext<ImageSelectorContextValue>({
  image: undefined,
  setImage: () => undefined
});