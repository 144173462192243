import React, { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import mediaHelper from '~/App/helpers/media-helper';
import { card } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

const Sticky = styled.div`
  ${card};
  height: 5.375rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  border-radius: 0;
  padding: 0 2rem 0 2rem !important;

  ${mq('<mediumLarge')`
    padding: 0 1rem 0 1rem !important;
    height: 4.75rem;
  `};
`;

const Logo = styled.img`
  height: 35px;

  ${mq('<mediumLarge')`
    height: 27px;
  `};
`;

const Link = styled.div`
  cursor: pointer;
`;

type Props = {
  onClick?: () => void;
  children?: ReactNode;
};

export function TinyHeader({ onClick, children }: Props) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => navigate('/'), [navigate]);

  return useMemo(
    () => (
      <>
        <Link onClick={onClick || handleClick}>
          <Sticky>
            <Logo
              src={mediaHelper.getUrl(
                'https://res.cloudinary.com/cancerfonden/image/upload/v1544186351/assets/logo-cancerfonden_one-line_blue.png',
                { height: 35 }
              )}
            />
            {children}
          </Sticky>
        </Link>
      </>
    ),
    [handleClick, onClick, children]
  );
}
