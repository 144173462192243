import React, { useMemo } from 'react';
import styled from 'styled-components';

import { heading2 } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';
import { DraggableModal } from '~/App/shared/components/Elements/Modal';
import { ICommerceOrder } from '~/types/ICommerceOrder';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import { OrderLinesGroup } from './components/OrderLinesGroup';
import { formatDate } from '~/App/helpers/date.service';

const Header = styled.div`
  background: ${({ theme }) => theme.colors.lightPolar};
  padding: 2.5rem 2rem 1.5rem;
  width: 100%;
`;

const Heading = styled.div`
  ${heading2};
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.25rem;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 1.125rem;

  ${mq('<mediumLarge')`
    font-size: 1rem;
  `};
`;

const Content = styled.div`
  padding: 1rem 2rem 2.5rem;
`;

const StyledDraggableModal = styled(DraggableModal)`
  width: 100%;
  padding: 0;
`;

type Props = {
  order: ICommerceOrder;
  closeModal: () => void;
};

export function OrderStatusModal({ closeModal, order }: Props) {
  const digitalProducts = useMemo<ICommerceOrderLine[]>(
    () =>
      order.orderLines.filter(
        (x) => x.variant?.product?.type === 'DigitalCertificate'
      ),
    [order.orderLines]
  );

  const nehProducts = useMemo<ICommerceOrderLine[]>(
    () =>
      order.orderLines.filter(
        (x) =>
          x.variant?.product?.type === 'Physical' &&
          x.variant.product.deliveryManager === 'NeH'
      ),
    [order.orderLines]
  );
  const internalProducts = useMemo<ICommerceOrderLine[]>(
    () =>
      order.orderLines.filter(
        (x) =>
          x.variant?.product?.type === 'Physical' &&
          x.variant.product.deliveryManager === 'Internal'
      ),
    [order.orderLines]
  );

  const date = useMemo(
    () => (order.createdAt ? formatDate(order.createdAt, 'DD MMM YYYY') : null),
    [order.createdAt]
  );

  return (
    <StyledDraggableModal
      closeModal={closeModal}
      closeButton={{ style: 'outlinePrimary', text: 'Stäng' }}
    >
      <Header>
        <Heading children="Orderstatus" />
        <Row>
          {date && <Text children={`Orderdatum ${date}`} />}
          {order.reference && <Text children={order.reference} />}
        </Row>
      </Header>
      <Content>
        <OrderLinesGroup name="Digital leverans" orderLines={digitalProducts} />
        <OrderLinesGroup
          name="Skickas av Cancerfonden"
          orderLines={internalProducts}
        />
        <OrderLinesGroup
          name="Skickas av Cancerfondens leverantör NeH" 
          orderLines={nehProducts}
        />
      </Content>
    </StyledDraggableModal>
  );
}
