import React from 'react';
import styled from 'styled-components';
import { heading2 } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

const Wrapper = styled.div`
  text-align: center;
`;

const Heading = styled.div`
  ${heading2};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 4rem 2rem 0;
`;

const ImageDesktop = styled.img`
  margin: 2.5rem auto 5rem;
  width: 10rem;

  ${mq('<medium')`
    display: none;
  `};
`;
const ImageMobile = styled.img`
  margin: 1rem auto 2.125rem;
  width: 5rem;
  display: none;

  ${mq('<medium')`
    display: block;
  `};
`;

export function EmptyState() {
  return (
    <Wrapper>
      <Heading>Var först med att skriva ett minne.</Heading>
      <ImageDesktop src="https://res.cloudinary.com/cancerfonden/image/upload/v1645631930/minnesida/icons-communication-heart-blue100.svg" />
      <ImageMobile src="https://res.cloudinary.com/cancerfonden/image/upload/v1645631930/minnesida/group-2-blue100.png" />
    </Wrapper>
  );
}
