import React, { useMemo } from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';
import { arrowLink } from '~/App/helpers/mixins';

import Link from '~/App/shared/components/Link';

import { PrimaryButton } from '~/App/shared/components/Elements';
import { ButtonStyle } from '~/types/RelatedBlock';

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 3.25rem;
`;

const LinkWrapper = styled.div`
  text-align: center;

  ${mq('≥small')`
  text-align: right;
`};
`;

const ArrowLink = styled(Link)`
  ${arrowLink};
`;

type Props = {
  text?: string;
  url?: string;
  style?: ButtonStyle;
};

export function useButton({ text, url, style }: Props) {
  const button = useMemo(() => {
    if (!url) return null;
    if (!text) return null;

    if (style === 'primaryButton') {
      return (
        <ButtonWrapper>
          <PrimaryButton
            to={url}
            buttonType="link"
            buttonStyle="primary"
            children={text}
          />
        </ButtonWrapper>
      );
    }
    if (style === 'outlinePrimaryButton') {
      return (
        <ButtonWrapper>
          <PrimaryButton
            to={url}
            buttonType="link"
            buttonStyle="outlinePrimary"
            children={text}
          />
        </ButtonWrapper>
      );
    }

    if (style === 'arrowLink') {
      return (
        <LinkWrapper>
          <ArrowLink to={url} children={text} />
        </LinkWrapper>
      );
    }

    return null;
  }, [style, text, url]);

  return { button };
}
