const defaultFormat = /(\d{1,4})/g;

const cards = [
  {
    type: 'maestro',
    patterns: [5018, 502, 503, 506, 56, 58, 639, 6220, 67],
    format: defaultFormat,
    length: [12, 13, 14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'forbrugsforeningen',
    patterns: [600],
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'dankort',
    patterns: [5019],
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'visa',
    patterns: [4],
    format: defaultFormat,
    length: [13, 16],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'mastercard',
    patterns: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27],
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'amex',
    patterns: [34, 37],
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    length: [15],
    cvcLength: [3, 4],
    luhn: true
  },
  {
    type: 'dinersclub',
    patterns: [30, 36, 38, 39],
    format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    length: [14],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'discover',
    patterns: [60, 64, 65, 622],
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'unionpay',
    patterns: [62, 88],
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: false
  },
  {
    type: 'jcb',
    patterns: [35],
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }
];

const cardFromType = (type) => {
  for (let i = 0; i < cards.length; i += 1) {
    const card = cards[i];

    if (card.type === type) {
      return card;
    }
  }
};

const cardFromNumber = (number) => {
  const formattedNumber = (number + '').replace(/\D/g, '');

  for (let i = 0; i < cards.length; i += 1) {
    const card = cards[i];

    for (let j = 0; j < card.patterns.length; j += 1) {
      const pattern = card.patterns[j];
      const p = pattern + '';

      if (formattedNumber.substr(0, p.length) === p) {
        return card;
      }
    }
  }
};

const cardExpiryValue = (value) => {
  let [month, year] = value.split(/[\s\/]+/, 2);

  if ((year ? year.length : null) === 2 && /^\d+$/.test(year)) {
    let prefix = new Date().getFullYear();

    prefix = prefix.toString().slice(0, 2);

    year = prefix + year;
  }

  month = parseInt(month, 10);
  year = parseInt(year, 10);

  return {
    month: month,
    year: year
  };
};

const luhnCheck = (number) => {
  let odd = true;
  let sum = 0;

  let digits = (number + '').split('').reverse();

  for (let i = 0; i < digits.length; i += 1) {
    let digit = digits[i];

    digit = parseInt(digit, 10);

    odd = !odd;

    if (odd) {
      digit = digit * 2;
    }

    if (digit > 9) {
      digit = digit - 9;
    }

    sum = sum + digit;
  }

  return sum % 10 === 0;
};

const formatCardNumber = (number) => {
  const digits = number.replace(/\D/g, '');
  const card = cardFromNumber(digits);

  if (!card) {
    return digits;
  }

  const maxLength = card.length[card.length.length - 1];
  const boundDigits = digits.slice(0, maxLength);

  if (card.format.global) {
    const digitsMatch = boundDigits.match(card.format);

    if (digitsMatch !== null) {
      return digitsMatch.join(' ');
    }

    return null;
  }

  let groups = card.format.exec(boundDigits);

  if (groups === null) {
    return null;
  }

  groups.shift();

  groups = groups.filter((n) => {
    return n;
  });

  return groups.join(' ');
};

const formatExpiry = (expiry) => {
  const parts = expiry.match(/^\D*(\d{1,2})(\D+)?(\d{1,2})?/);

  if (!parts) {
    return '';
  }

  let month = parts[1] || '';
  let separator = parts[2] || '';
  let year = parts[3] || '';

  if (year.length > 0) {
    separator = ' / ';
  } else if (separator === ' /') {
    month = month.substring(0, 1);
    separator = '';
  } else if (month.length === 2 || separator.length > 0) {
    separator = ' / ';
  } else if (month.length === 1 && month !== '0' && month !== '1') {
    month = '0' + month;
    separator = ' / ';
  }

  return month + separator + year;
};

const validateCardCvc = (cvc, type) => {
  const formattedCvc = cvc.trim();

  if (!/^\d+$/.test(formattedCvc)) {
    return false;
  }

  const card = cardFromType(type);

  if (card && card.cvcLength.indexOf(formattedCvc.length) === -1) {
    return false;
  }

  if (formattedCvc.length < 3 && formattedCvc.length > 4) {
    return false;
  }

  return true;
};

const validateCardNumber = (number) => {
  const formattedNumber = (number + '').replace(/\s+|-/g, '');

  if (!/^\d+$/.test(formattedNumber)) {
    return false;
  }

  const card = cardFromNumber(formattedNumber);

  if (!card) {
    return false;
  }

  if (card.length.indexOf(formattedNumber.length) === -1) {
    return false;
  }

  if (card.luhn) {
    if (!luhnCheck(formattedNumber)) {
      return false;
    }
  }

  return true;
};

const validateCardExpiry = (expiry) => {
  let { year, month } = expiry;

  if (!month && !year) {
    return false;
  }

  if (!/^\d+$/.test(month)) {
    return false;
  }

  if (!/^\d+$/.test(year)) {
    return false;
  }

  if (!(1 <= month && month <= 12)) {
    return false;
  }

  if (year.length === 2) {
    if (year < 70) {
      year = '20' + year;
    } else {
      year = '19' + year;
    }
  }

  if ((year + '').length !== 4) {
    return false;
  }

  const currentExpiry = new Date(year, month);
  const currentTime = new Date();

  currentExpiry.setMonth(currentExpiry.getMonth() - 1);
  currentExpiry.setMonth(currentExpiry.getMonth() + 1, 1);

  return currentExpiry > currentTime;
};

export {
  cards,
  cardFromType,
  cardFromNumber,
  cardExpiryValue,
  formatExpiry,
  formatCardNumber,
  validateCardCvc,
  validateCardNumber,
  validateCardExpiry
};
