import { Wrapper } from './Wrapper';
import { LeftColumn } from './LeftColumn';
import { RightColumn } from './RightColumn';
import { ColumnSegment } from './ColumnSegment';
import { ColumnCard } from './ColumnCard';

export { Wrapper } from './Wrapper';
export { LeftColumn } from './LeftColumn';
export { RightColumn } from './RightColumn';
export { ColumnSegment } from './ColumnSegment';
export { ColumnCard } from './ColumnCard';

export const TwoColumn = {
  Wrapper,
  LeftColumn,
  RightColumn,
  ColumnSegment,
  ColumnCard
};
