import React, { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { heading2, preamble, ctaButton } from '~/App/helpers/mixins';

// Shared components
import {
  Wrapper,
  ImageWrapper,
  ProductImage,
  Content
} from '~/App/shared/components/ThanksPage';
import { ICmsProduct, CmsProductTarget } from '~/types/ICmsProduct';
import { IPurchaseShow } from '~/types/IPurchaseShow';

const Heading = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const Button = styled(Link)`
  ${ctaButton};

  margin-top: 2rem;
`;

type Props = {
  heading: string;
  product: ICmsProduct;
  purchase: IPurchaseShow;
  children: ReactNode;
};

const cleanPhoneNumber = (number?: string): string | undefined => {
  if (!number) {
    return number;
  }

  if (number.startsWith('46')) {
    return `0${number.slice(2)}`;
  }

  return number;
};

export function Header({ heading, product, purchase, children }: Props) {
  const url = useMemo(
    () =>
      mediaHelper.getUrl(
        product.productImage?.secure_url,
        {
          transformation: [
            {
              width: 280,
              height: 280,
              crop: 'lpad',
              gravity: 'center',
              radius: 'max'
            },
            {
              width: 92,
              height: 92,
              dpr: '2.0',
              crop: 'pad',
              quality: 100,
              radius: 'max'
            }
          ]
        },
        'png'
      ),
    [product]
  );

  const location = useMemo(
    () => ({
      pathname: `/gavoshop/${
        product?.target === CmsProductTarget.company ? 'foretag' : 'privat'
      }`,
      state: {
        productId: purchase.product?.id,
        customerContact: {
          ...purchase?.purchase?.productOptions?.customerContact,
          phoneNumber: cleanPhoneNumber(
            purchase?.purchase?.productOptions?.customerContact?.phoneNumber
          )
        },
        paymentMethod: purchase?.paymentMethod,
        swish: {
          phoneNumber: cleanPhoneNumber(
            purchase?.purchase?.productOptions?.customerContact?.phoneNumber
          )
        }
      }
    }),
    [product, purchase]
  );

  return (
    <Wrapper>
      <ImageWrapper padding={false}>
        <ProductImage src={url} />
      </ImageWrapper>
      <Content>
        <Heading children={heading} />
        <Preamble children={children} />
        <Button to={location}>Tillbaka till gåvoshopen</Button>
      </Content>
    </Wrapper>
  );
}
