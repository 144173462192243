import { FuneralHome } from '~/types/IDeceasedContact';
import { mwAxios } from '~/App/helpers/http';

export const funeralHome = {
  search: async (q: string) => {
    const { data } = await mwAxios.get<FuneralHome[]>(
      '/api/v2/bitnet/funeral_homes',
      {
        params: {
          q
        }
      }
    );

    return data;
  }
};
