import React, { ComponentProps, useMemo } from 'react';

import { StepWrapper } from '../Forms/components/FormLayout';
import { PeriodSelector } from '../Forms/components/PeriodSelector';
import { ProductValues } from '../States';

type Props = {
  values: ProductValues;
};

export function Period({ values }: Props) {
  const options = useMemo<ComponentProps<typeof PeriodSelector>['options']>(
    () => [
      {
        label: 'En gång',
        value: 'Once'
      },
      {
        label: 'Var tredje månad',
        value: 'EveryThirdMonth'
      },
      {
        label: 'Varannan månad',
        value: 'EveryOtherMonth'
      },
      {
        label: 'Varje månad',
        value: 'Month'
      }
    ],
    []
  );

  return (
    <StepWrapper>
      <StepWrapper marginTop="tiny">
        <PeriodSelector
          options={options}
          onChange={values.handlers.setProductPeriod}
          value={values.productOptions.product.period}
          tinyLayout="50"
          smallLayout="50"
          largeLayout="50"
        />
      </StepWrapper>
    </StepWrapper>
  );
}
