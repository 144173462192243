import React from 'react';
import styled from 'styled-components';

//helpers
import mq from '~/App/helpers/mq';

type StylingProps = {
  hideMobile?: boolean;
};

const Styling = styled.p<StylingProps>`
  display: ${({ hideMobile }) => (hideMobile ? 'none' : 'block')};

  ${mq('≥mediumLarge')`
        display: block;
	    margin-bottom: 1.5rem;
	    font-weight: ${({ theme }) => theme.fontWeights.bold};
	    width: 100%;
    `}
`;

type Props = {
  isPrivate: boolean;
  hideMobile?: boolean;
};

export function SubHeroText({ hideMobile, isPrivate }: Props) {
  return (
    <Styling hideMobile={hideMobile}>
      {isPrivate ? (
        <>
          Följ vart dina pengar går, hantera dina gåvor och starta insamlingar
          med ett klick. Logga in eller skapa ett konto med BankID.
        </>
      ) : (
        <>
          Ansök om forskningsbidrag, utökad stöd, forskningsresor och anordning
          av kongresser, m.m.
        </>
      )}
    </Styling>
  );
}
