import React from 'react';

import { Embed, Heading, Preamble, Button, Wrapper } from './Hero';

class EditorialHero extends React.Component {
  constructor(props) {
    super(props);

    this.heroContent = this.heroContent.bind(this);
  }

  heroContent() {
    if (this.props.imageType === 'Embed' && this.props.embed !== '') {
      return (
        <Embed
          video={this.props.embed}
          heading={this.props.heroHeading}
          preamble={this.props.imagePreamble}
          preambleShort={this.props.imagePreambleShort}
          textColor={this.props.heroTextColor}
          hasNavigation={false}
          data={this.props.data}
        />
      );
    }

    return (
      <div className="hero__content">
        <Heading
          heading={this.props.heroHeading}
          headingFontSize={this.props.heroHeadingFontSize}
          headingFontWeight={this.props.heroHeadingFontWeight}
        />
        <Preamble
          preamble={this.props.imagePreamble}
          preambleShort={this.props.imagePreambleShort}
        />
        <Button {...this.props} />
      </div>
    );
  }

  render() {
    if (this.props.imageLayout !== 'hero') {
      return null;
    }

    return <Wrapper {...this.props}>{this.heroContent()}</Wrapper>;
  }
}

EditorialHero.displayName = 'EditorialHero';

EditorialHero.defaultProps = {
  imageLayout: 'hero',
  heroHeadingFontSize: 'large',
  heroHeadingFontWeight: 'light',
  navigation: []
};

export default EditorialHero;
