import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ContentType, Columns } from '~/types/RelatedBlock';

// Helpers and constants
import mediaHelper from '~/App/helpers/media-helper';
import { breakpoints } from '~/App/config/mediaBreakpoints';

// Shared components
import Link from '~/App/shared/components/Link';

const Picture = styled.picture`
  display: block;
  width: 100%;
  transition: transform 0.3s ease;
`;

const Figure = styled.div`
  display: block;
  overflow: hidden;
  border-radius: 2px;
`;

const FigureLink = styled(Link)`
  display: block;
  overflow: hidden;
  border-radius: 2px;

  &:hover {
    ${Picture} {
      transform: scale(1.1);
    }
  }
`;

const Image = styled.img`
  max-width: 100%;
`;

type Props = {
  imageUrl?: string;
  imageAlt?: string;
  slug?: string;
  contentType: ContentType;
  containerColumns: Columns;
};

export function ArticleTeaserImage({
  imageUrl,
  imageAlt,
  slug,
  contentType,
  containerColumns = 3
}: Props) {
  const mediaWidth = useMemo(
    () => ({
      isAboveTablet: `(min-width: ${breakpoints.small})`,
      isAboveMobile: `(min-width: ${breakpoints.tiny})`
    }),
    []
  );

  const defaultMediaOptions = useMemo(
    () => ({
      crop: 'fill',
      gravity: 'faces',
      aspect_ratio: '16:10'
    }),
    []
  );

  const getSourceSet = useCallback(
    (width: number) => {
      const retinaUrl = mediaHelper.getUrl(
        imageUrl,
        {
          width: width * 2,
          ...defaultMediaOptions
        },
        'jpg'
      );

      const standardUrl = mediaHelper.getUrl(
        imageUrl,
        {
          width: width,
          ...defaultMediaOptions
        },
        'jpg'
      );

      return `${retinaUrl} 2x, ${standardUrl} 1x`;
    },
    [defaultMediaOptions, imageUrl]
  );

  const pictureElement = useMemo(
    () => (
      <Picture>
        <source
          media={mediaWidth.isAboveTablet}
          srcSet={getSourceSet(containerColumns === 3 ? 368 : 568)}
        />
        <source media={mediaWidth.isAboveMobile} srcSet={getSourceSet(735)} />
        <source srcSet={getSourceSet(447)} />
        <Image
          alt={imageAlt}
          src={mediaHelper.getUrl(
            imageUrl,
            {
              width: 368,
              ...defaultMediaOptions
            },
            'jpg'
          )}
        />
      </Picture>
    ),
    [
      containerColumns,
      defaultMediaOptions,
      getSourceSet,
      imageAlt,
      imageUrl,
      mediaWidth.isAboveMobile,
      mediaWidth.isAboveTablet
    ]
  );

  if (!imageUrl) return null;

  if (slug) {
    return <FigureLink to={{ slug, contentType }}>{pictureElement}</FigureLink>;
  }

  return <Figure>{pictureElement}</Figure>;
}
