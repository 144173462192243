import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { heading2, heading3 } from '~/App/helpers/mixins';
import { containerWidthsPx } from '~/App/config/containerWidths';

// Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';
import HtmlContent from '~/App/shared/components/HtmlContent';

const ARROW_HEIGHT = 20;
const LARGE_ARROW_HEIGHT = 40;

export const Wrapper = styled.div`
  background: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  padding: 2.5rem 1rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq('≥mediumLarge')`
    padding: 3rem 1rem 3.5rem;
  `};
`;

export const StepWrapper = styled.div`
  width: 100%;
  max-width: ${({ width }) => containerWidthsPx[width]};
  overflow-x: hidden;
`;

export const Step = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1rem 1rem 2rem;
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:not(:first-child) {
    padding-top: 2.5rem;

    ${mq('≥mediumLarge')`
        padding-top: ${({ width }) => (width === 'normal' ? 4.5 : 2.5)}rem;
    `};

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 0;
      box-sizing: border-box;
      height: 0;
      right: 50%;
      top: 0;
      border-left: 50vw solid transparent;
      border-top: ${ARROW_HEIGHT}px solid
        ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
      border-right: 50vw solid transparent;
      transform: translateX(50%);

      ${mq('≥mediumLarge')`
        border-top-width: ${({ width }) =>
          width === 'normal' ? LARGE_ARROW_HEIGHT : ARROW_HEIGHT}px;
      `};
    }
  }

  &:not(:last-child) {
    ${mq('≥mediumLarge')`
        padding-bottom: ${({ width }) => (width === 'normal' ? 1 : 2)}rem;
    `};

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0;
      box-sizing: border-box;
      height: 0;
      right: 50%;
      bottom: ${-ARROW_HEIGHT}px;
      border-left: 50vw solid transparent;
      border-top: ${ARROW_HEIGHT}px solid ${({ theme }) => theme.colors.white};
      border-right: 50vw solid transparent;
      transform: translateX(50%);
      z-index: 1;

      ${mq('≥mediumLarge')`
        bottom: ${({ width }) =>
          width === 'normal' ? -LARGE_ARROW_HEIGHT : -ARROW_HEIGHT}px;
        border-top-width: ${({ width }) =>
          width === 'normal' ? LARGE_ARROW_HEIGHT : ARROW_HEIGHT}px;
      `};
    }
  }
`;

export const Number = styled.div`
  border-radius: 50%;
  background: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: ${({ theme }) => theme.themeColors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Heading = styled.h2`
  ${heading2};
  color: ${({ theme }) => theme.themeColors.primary};
  margin-bottom: 0.75rem;

  ${mq('≥mediumLarge')`
    margin-bottom: 1.75rem;
  `};
`;

export const StepHeading = styled.h3`
  ${heading3};
  max-width: 35rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const Description = styled(HtmlContent)`
  max-width: 35rem;
  text-align: center;
`;

export const StyledButton = styled(PrimaryButton)`
  margin-top: 1.5rem;
`;
