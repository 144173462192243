import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useMemo,
  useState
} from 'react';
import styled from 'styled-components';

import { useValidation } from '~/App/shared/components/Validation';

import Text from '~/App/shared/components/Fields/Text';
import { primaryButton, heading4 } from '~/App/helpers/mixins';
import { ButtonStyle, buttonStyles } from '~/App/config/buttonStyles';
import { Message } from '~/App/shared/components/Fields/Components';
import { users } from '~/App/helpers/http';
import { pushGTMDynamicEvent } from '~/App/helpers/gtm-helper';

const schema = {
  email: {
    validateEmail: {
      message: '^Glöm inte att fylla i din e-post'
    },
    email: {
      message: '^Glöm inte att fylla i din e-post'
    }
  }
};

type SubmitProps = {
  buttonStyle: ButtonStyle;
};

const Submit = styled.button<SubmitProps>`
  ${primaryButton};
`;

const InputFieldWrapper = styled.div`
  flex: 1;
`;

const InputGroup = styled.div`
  display: flex;

  input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    min-width: 0;
  }
`;

const Success = styled.p`
  ${heading4};
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.darkBlue};
`;

type Props = {
  thankYouText: string;
};

export function Form({ thankYouText }: Props) {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState<boolean | undefined>();
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);

  const values = useMemo(() => ({ email }), [email]);
  const validation = useValidation({
    values,
    schema
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setSubmitErrors([]);
    setSuccess(undefined);
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      try {
        const response = await users.addSubscription({
          data: {
            email
          }
        });

        if (response.status >= 200 && response.status < 300) {
          setEmail('');
          setSuccess(true);
          validation.hideAllErrors();
          pushGTMDynamicEvent({
            event: 'NewsLetterBlock',
            category: 'Nyhetsbrev',
            action: 'anmäld',
            label: `${thankYouText} | ${window?.location.href}`
          });
        }
      } catch (error) {
        setSuccess(false);
        setSubmitErrors(['E-postadressen är redan anmäld för nyhetsbrev']);
      }
    },
    [email, thankYouText, validation]
  );

  const errors = useMemo(
    () => [...(validation.errors['email'] || []), ...submitErrors],
    [submitErrors, validation.errors]
  );

  const isValid = useMemo(
    () => validation.isValid('email') && errors.length === 0,
    [errors.length, validation]
  );

  const isInvalid = useMemo(() => errors.length > 0, [errors.length]);

  const buttonStyle = useMemo(() => {
    if (isValid) return buttonStyles.success;
    if (isInvalid) return buttonStyles.error;

    return buttonStyles.disabled;
  }, [isInvalid, isValid]);

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup>
        <InputFieldWrapper>
          <Text
            isValid={isValid}
            isInvalid={isInvalid}
            value={values.email}
            onChange={handleChange}
            onBlur={validation.showError.bind(undefined, 'email')}
            placeholder="E-post"
            required
          />
        </InputFieldWrapper>
        <Submit
          buttonStyle={buttonStyle}
          className="ga-button"
          type="submit"
          children="Skicka"
        />
      </InputGroup>
      {errors.length ? (
        <Message isInvalid={isInvalid} children={errors[0]} />
      ) : null}
      {success && <Success children={thankYouText} />}
    </form>
  );
}
