import { orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { ICommerceProduct } from '~/types/ICommerceProduct';
import { StockStatus } from '~/types/ICommerceVariant';
import { Period } from '~/types/IPurchase';

type Amount = number | null | undefined;

export type CommerceProductState = {
  values: {
    price: Amount;
    customPrice: boolean;
    quantity: number;
    period: Period;
    stockStatus?: StockStatus;
  };
  handlers: {
    setPrice: (value: Amount) => void;
    setCustomPrice: (value: boolean) => void;
    setQuantity: (value: number) => void;
    setPeriod: (value: Period) => void;
    resetPrice: () => void;
  };
};

type Props = {
  product: ICommerceProduct;
};

export function useCommerceProductState({
  product
}: Props): CommerceProductState {
  const defaultVariant = useMemo(() => {
    const sorted = orderBy(product.variants || [], [(x) => !x.default]);
    const variant = sorted.find(() => true);

    return variant;
  }, [product.variants]);

  const [price, setPrice] = useState<Amount>(
    defaultVariant?.price?.value || null
  );

  const [customPrice, setCustomPrice] = useState<boolean>(false);
  const [quantity, setQuantity] = useState(1);
  const [period, setPeriod] = useState<Period>(
    defaultVariant?.period || 'Once'
  );

  const resetPrice = useCallback(() => setPrice(null), []);

  return {
    values: {
      price,
      customPrice,
      quantity,
      period,
      stockStatus: defaultVariant?.stock?.status
    },
    handlers: {
      resetPrice,
      setPrice,
      setQuantity,
      setPeriod,
      setCustomPrice
    }
  };
}
