export const products = {
  memorialGift: {
    id: 1
  },
  donation: {
    id: 3
  },
  lotteryTickets: {
    id: 4
  },
  gratulationGift: {
    id: 7
  },
  supportMember: {
    id: 8
  },
  companyPackageBase: {
    id: 9
  },
  companyPackageSmall: {
    id: 10
  },
  companyPackageMedium: {
    id: 11
  },
  companyPackageLarge: {
    id: 12
  },
  pinkCompanyPackageSmall: {
    id: 14
  },
  pinkCompanyPackageMedium: {
    id: 15
  },
  pinkCompanyPackageLarge: {
    id: 16
  },
  christmasCompanyPackageBase: {
    id: 18
  },
  christmasCompanyPackageSmall: {
    id: 19
  },
  christmasCompanyPackageMedium: {
    id: 20
  },
  christmasCompanyPackageLarge: {
    id: 21
  },
  christmasCompanyLotteryTickets: {
    id: 22
  },
  christmasLotteryTickets: {
    id: 23
  },
  christmasDonation: {
    id: 25
  },
  christmasCompanyDonation: {
    id: 26
  },
  motherSwishDonation: {
    id: 29
  },
  monthlyDonation: {
    id: 31
  },
  monthlyDonationGala: {
    id: 32
  },
  researchTime: {
    id: 33
  },
  giftCertificate: {
    id: 34
  }
};
