import React, { useMemo } from 'react';
// Shared components
import { Divider } from '~/App/shared/components/Blocks';
import { GenericCertificatePreview } from '~/App/shared/components/Donation/components/Forms/components/CertificatePreviews';
import { PreviewButton } from '~/App/shared/components/Donation/components/Forms/components/CertificatePreviews/components/PreviewButton';
import { RightColumnText } from '~/App/shared/components/Donation/components/Forms/components/RightColumnText';
// Local components
import { AmountWrapper } from './components';

type Props = {
  previewFields: {
    heading: string;
    imageUrl?: string;
    greeting: string;
    description: string;
    fullSize?: boolean;
  };
  infoText?: string;
  price: number | null | undefined;
  openPreviewModal: () => void;
};

export function GenericCertificateSummary({
  previewFields,
  price = 0,
  infoText,
  openPreviewModal
}: Props) {
  return useMemo(
    () => (
      <div>
        <GenericCertificatePreview {...previewFields} />
        <PreviewButton onClick={openPreviewModal}>
          Förhandsgranska gåvobevis
        </PreviewButton>
        <AmountWrapper text="Gåvobelopp" amount={price} />
        <Divider
          width="narrow"
          paddingLeft="none"
          paddingRight="none"
          height="2px"
          alternativeColor={true}
        />
        {infoText && (
          <RightColumnText icon="heart" iconColor="blue">
            {infoText}
          </RightColumnText>
        )}
      </div>
    ),
    [infoText, openPreviewModal, previewFields, price]
  );
}
