import React, { useMemo, ReactNode } from 'react';

// types
import { SubmitValues } from './types/SubmitValues';
import { IValidation } from '../../../../Validation';

import { ISubmit, useSubmit } from '.';

type Props = {
  values: SubmitValues;
  validation: IValidation;
  children: (submit: ISubmit) => ReactNode;
  shouldNudge?: boolean;
};

export function Submit({
  values,
  validation,
  children,
  shouldNudge = false
}: Props) {
  const submit = useSubmit({
    values,
    validation,
    shouldNudge
  });

  return useMemo(() => <>{children(submit)}</>, [children, submit]);
}
