import { css, keyframes } from 'styled-components';

import 'scroll-behavior-polyfill';

const beat = keyframes`
0%, 50%, 100% { transform: scale(1, 1); }
  30%, 80% { transform: scale(0.92, 0.95); }
`;

const rotate360 = keyframes`
  0% {
    transform: rotateZ(-360deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
`;

const ring = keyframes`
  15% { transform: rotate(0); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }
  43% { transform: rotate(0deg); }
  100% { transform: rotate(0); }
`;

const heartBeatAnimation = keyframes`
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.3);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.3);
  }

  80% {
    transform: scale(1);
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const roll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-110%);
  }

`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const translateY = keyframes`
    from {
        transform: translateY(1rem);
    }
    to {
        transform: translateY(0rem);
    }
`;

const slideDownAndBounce = keyframes`
	0% {
		transform: translateY(-100%);
    opacity: 0;
	}
	70%{
		transform: translateY(8%);
    opacity: 1;
	}
	100% {
		transform: translateY(0%);
    opacity: 1;
	}		
`;

const slideDownAndBounceMobile = keyframes`
	0% {
		transform: translateY(-4rem);
    opacity: 0;
	}
	70%{
		transform: translateY(5%);
    opacity: 1;
	}
	100% {
		transform: translateY(0%);
    opacity: 1;
	}		
`;

const slideUpAndBounce = keyframes`
	0% {
		transform: translateY(100%);
    opacity: 0;
	}
	70%{
		transform: translateY(-5%);
    opacity: 1;
	}
	100% {
		transform: translateY(0%);
    opacity: 1;
	}		
`;

type TranslateYAnimationProps = {
  isRunning?: boolean;
};

const translateYAnimation = css<TranslateYAnimationProps>`
  animation-name: ${({ isRunning }) => (isRunning ? translateY : null)};
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  transform: translateY(3rem);
`;

const scrollAnimation = (destination: number) => {
  if (typeof window === 'undefined') return;

  if (window.scroll) {
    window.scroll({ top: destination, left: 0, behavior: 'smooth' });
    return;
  }

  if (window.scrollTo) {
    window.scrollTo({ top: destination, left: 0, behavior: 'smooth' });
    return;
  }
};

const slideElementDown = (elem: HTMLElement, duration: number) => {
  const height = elem.scrollHeight;
  elem.style.transition = `height ${duration}ms ease-in-out`;
  elem.style.height = `${height}px`;
};

const slideElementUp = (elem: HTMLElement, duration: number) => {
  elem.style.transition = `height ${duration}ms ease-in-out`;
  elem.style.height = '0px';
};

export {
  ring,
  slideUp,
  fadeIn,
  roll,
  heartBeatAnimation,
  translateY,
  translateYAnimation,
  slideDownAndBounce,
  slideDownAndBounceMobile,
  slideUpAndBounce,
  scrollAnimation,
  slideElementDown,
  slideElementUp,
  beat,
  rotate360
};
