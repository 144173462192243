import React, { useCallback } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

// Helpers
import { youtube, instagram, facebook, x } from '~/App/helpers/icons';

const socialItems = [
  {
    url: 'https://www.youtube.com/cancerfonden',
    icon: 'icon-brand-youtube',
    label: 'Prenumerera på Cancerfondens videos på Youtube'
  },
  {
    url: 'https://instagram.com/cancerfonden',
    icon: 'icon-brand-instagram',
    label: 'Följ Cancerfonden på Instagram'
  },
  {
    url: 'https://www.facebook.com/cancerfonden',
    icon: 'icon-brand-facebook',
    label: 'Gilla Cancerfonden på Facebook'
  },
  {
    url: 'https://twitter.com/cancerfonden',
    icon: 'icon-brand-x',
    label: 'Prata med Cancerfonden på X'
  }
];

const SocialIconsList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: space-between;
`;

const SocialIconsLink = styled.a`
  display: block;
  width: 2.625rem;
  height: 2.625rem;
  font-size: 1.75rem;
  line-height: 2.625rem;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkBlue};
  border-radius: 50%;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.hoverBlue};
  }

  &::after {
    display: block;
    width: 100%;
    height: 100%;
    color: inherit;
    text-align: center;
  }

  &.icon-brand-youtube {
    &::after {
      ${youtube};
      line-height: inherit;
    }
  }

  &.icon-brand-instagram {
    &::after {
      ${instagram};
      line-height: inherit;
    }
  }

  &.icon-brand-facebook {
    &::after {
      ${facebook};
      line-height: inherit;
    }
  }

  &.icon-brand-x {
    &::after {
      ${x};
      line-height: inherit;
    }
  }
`;

const SocialIconsText = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export default function SocialMediaItems() {
  const renderSocialItem = useCallback(
    (socialItem, index) => (
      <li key={'socialItem' + index}>
        <SocialIconsLink
          rel="noopener noreferrer"
          href={socialItem.url}
          target="_blank"
          className={classNames(socialItem.icon)}
        >
          <SocialIconsText>{socialItem.label}</SocialIconsText>
        </SocialIconsLink>
      </li>
    ),
    []
  );

  return <SocialIconsList>{socialItems.map(renderSocialItem)}</SocialIconsList>;
}
