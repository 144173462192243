import { IRedirect } from '../../types/IRedirect';

const equals = (a: string, b: string) => a === b;
const startsWith = (a: string, b: string) => a.startsWith(b);

export const match = (redirects: IRedirect[] = [], { path = '' }) =>
  redirects.find((redirect) => {
    switch (redirect.match) {
      case 'exact':
        return equals(redirect.from, path);
      case 'path':
        return equals(redirect.from, path);
      case 'beginsWith':
        return startsWith(path, redirect.from);
      default:
        return false;
    }
  });
