import { omitBy, map } from 'lodash';

const objectToQueryString = (object) => {
  const objectWithValues = omitBy(
    object,
    (value) => value === '' || value === null || value === undefined
  );
  const arrayWithValues = map(
    objectWithValues,
    (value, key) => `${key}=${encodeURIComponent(value)}`
  );

  if (arrayWithValues.length > 0) {
    return `?${arrayWithValues.join('&')}`;
  }

  return '';
};

export default objectToQueryString;
