import React from 'react';

import { AsyncDocumentLink, Status } from './AsyncDocumentLink';

type Props = {
  url: string | null | undefined;
  status: Status;
};

export function AsyncMemorialCertificateDocumentLink(props: Props) {
  return (
    <AsyncDocumentLink
      {...props}
      fileName="minnesblad"
      fileNameForLink="Se ditt minnesblad"
      fileNameWithPronoun="ditt minnesblad"
      icon="external"
    />
  );
}
