import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { heading4 } from '~/App/helpers/mixins';

// Shared components
import { useLocation, useNavigate } from 'react-router-dom';
import { assemble } from '~/lib/slug-helper';

const Wrapper = styled.div`
  padding: 0.875rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.15);
  background-color: white;
  cursor: pointer;
  display: block;

  ${mq('≥small')`
    padding: 2.875rem;
    margin-bottom: 2rem;
    display: flex;
  `};
`;

const Heading = styled.h3`
  ${heading4};
  cursor: pointer;
  font-size: 1.125rem;
  position: relative;
  margin-bottom: 0.25rem;
`;

const Applicant = styled.div`
  font-size: 1.125rem;
  line-height: 1.5;
  font-style: italic;
`;

const Meta = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.93;
  margin: 0.25em 0;

  ${mq('≥small')`
    margin: auto 0;
  `};
`;

const MetaItem = styled.span`
  display: block;
  text-align: left;
  width: 157px;
  padding-bottom: 0.625rem;

  ${mq('<small')`
    width: unset;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 0px;
  `};
`;

const Preamble = styled.p`
  font-size: 1.125em;
  line-height: 1.5;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0.5em 0;

  ${mq('<small')`
   -webkit-line-clamp: 5;
           line-clamp: 5; 
  `};
`;
type OnGoingProps = {
  isOnGoing: boolean;
};

const OnGoing = styled.span<OnGoingProps>`
  padding-left: 1.25rem;

  &:after {
    content: '·';
    display: flex;
    position: relative;
    font-size: 5rem;
    color: ${({ isOnGoing }) => (isOnGoing ? 'green' : 'darkblue')};
    height: 0;
    bottom: 4.3rem;
  }
`;

const BlockWrapper = styled.div`
  display: block;
`;

const Border = styled.div`
  ${mq('<small')`
    border-top: 1px solid gray;
    margin: 0.875rem auto;
  `};

  ${mq('≥small')`
    border-left: 1px solid gray;
    height: 150px;
    margin: auto 2rem;
  `};
`;

type Props = {
  slug: string;
  amount: ReactNode;
  heading: string;
  preamble: string;
  applicant: string;
  university: string;
  contentType?: string;
  diagnoseGroups: string[];
  researchArea: string;
  onGoing: boolean;
};

export default function SearchResultItem({
  slug,
  amount,
  heading,
  preamble,
  applicant,
  university,
  contentType,
  diagnoseGroups,
  researchArea,
  onGoing
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = useCallback(
    (e) => {
      if (e.target instanceof HTMLAnchorElement) return;
      navigate(assemble({ slug, contentType }), { state: location.search });
    },
    [contentType, location.search, navigate, slug]
  );

  return (
    <Wrapper onClick={handleClick}>
      <BlockWrapper>
        <Heading>{heading}</Heading>
        <Applicant>{applicant}</Applicant>
        <OnGoing isOnGoing={onGoing}>
          {onGoing ? 'Pågående' : 'Avslutad'}
        </OnGoing>
        <Preamble>{preamble}</Preamble>
      </BlockWrapper>
      <Border></Border>
      <Meta>
        <MetaItem>
          {diagnoseGroups && diagnoseGroups.length > 1
            ? diagnoseGroups[0] + ' m.fl.'
            : diagnoseGroups}
        </MetaItem>
        <MetaItem>{researchArea}</MetaItem>
        {university && <MetaItem>{university}</MetaItem>}
        <MetaItem>{amount}</MetaItem>
      </Meta>
    </Wrapper>
  );
}
