import React from 'react';
import styled from 'styled-components';
import { IFavourablePaymentMethod } from '~/types/IPaymentMethod';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.errorRedMedium};
  color: ${({ theme }) => theme.colors.errorRedDark};
  padding: 0.5rem 1rem;
  border-radius: 2px;
  margin-bottom: 1rem;
`;

type Props = {
  method: IFavourablePaymentMethod;
};

export function Error({ method }: Props) {
  if (!method.error) {
    return null;
  }

  return <Wrapper children={method.error} />;
}
