import { css } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { close } from '~/App/helpers/icons';

type CrossIconProps = {
  isValid?: boolean;
  isOpen?: boolean;
  isStarted?: boolean;
};

export const CrossIcon = css<CrossIconProps>`
  ${close};
  display: ${({ isValid, isOpen, isStarted }) =>
    !isValid && !isOpen && isStarted ? 'block' : 'none'};
  position: absolute;
  top: -0.25rem;
  left: 0;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.errorRed};
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  ${mq('≥small')`
    width: 2rem;
    height: 2rem;
    left: -0.25rem;
    font-size: 1.5rem;
    line-height: 2rem;
  `};
`;
