import React, { useMemo } from 'react';
import styled from 'styled-components';

// helpers
import mediaHelper from '~/App/helpers/media-helper';
import mq from '~/App/helpers/mq';

// shared components
import Link from '~/App/shared/components/Link';

const Wrapper = styled.div`
  display: inline-block;
  margin: 0 0 0 0.5rem;

  ${mq('≥micro')`
    margin: 0 0 0 0.75rem;
  `};
`;

const StyledLink = styled(Link)`
  display: flex;
  height: 60px;
  align-items: center;
  margin: 0;

  ${mq('≥mediumLarge')`
    height: 80px;
    margin: 0 0 0 0.625rem;
    align-items: flex-start;
    padding-top: 1.5rem;
  `};
`;

const CfImage = styled.picture`
  line-height: 0;
  margin-top: -1px;
  display: block;
  width: 174px;
  height: 27px;

  ${mq('≥mediumLarge')`
    margin-top: -1px;
    width: 225px;
    height: 35px;
  `};

  img {
    max-height: 100%;
  }
`;

type Props = {
  color?: 'blue' | 'white' | 'black';
};

export function Brand({ color = 'blue' }: Props) {
  const url = useMemo(
    () =>
      `https://res.cloudinary.com/cancerfonden/image/upload/v1544186351/assets/logo-cancerfonden_one-line_${color}.png`,
    [color]
  );

  return useMemo(
    () => (
      <Wrapper>
        <StyledLink to="/">
          <CfImage>
            <source
              srcSet={`
            ${mediaHelper.getUrl(url, {
              width: 450,
              height: 70,
              quality: 100,
              crop: 'pad'
            })} 2x,
            ${mediaHelper.getUrl(url, {
              width: 225,
              height: 35,
              quality: 100,
              crop: 'pad'
            })} 1x
          `}
            />
            <img src={mediaHelper.getUrl(url, { height: 35 })} />
          </CfImage>
        </StyledLink>
      </Wrapper>
    ),
    [url]
  );
}
