import React from 'react';
import styled from 'styled-components';

import { NewFormHeading } from './../components/Forms/components/FormTypography';

import MonthlyDonationFormPartOne from './MonthlyDonation.Form.Part.One';
import MonthlyDonationFormPartTwo from './MonthlyDonation.Form.Part.Two';

const Wrapper = styled.div`
  max-width: 33rem;
  margin: 0 auto;
  padding: 0 1rem 1.5rem;
`;

export function MonthlyDonationInline({
  submit,
  values,
  validation,
  ...props
}) {
  const formProps = {
    submit,
    values,
    validation,
    ...props
  };

  return (
    <form onSubmit={submit.handleSubmit} noValidate>
      <Wrapper>
        <NewFormHeading>Välj belopp</NewFormHeading>
        <MonthlyDonationFormPartOne inline {...formProps} />
      </Wrapper>
      <Wrapper>
        <NewFormHeading>Välj betalsätt</NewFormHeading>
        <MonthlyDonationFormPartTwo {...formProps} />
      </Wrapper>
    </form>
  );
}
