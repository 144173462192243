import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

// Helpers
import {
  textHelper,
  getPurchaseErrorMessage
} from '../../../components/Forms/helpers';
import { check } from '~/App/helpers/icons';

// Shared components
import AmountSelector from '../../../components/Forms/components/AmountSelector';
import {
  Wrapper,
  LeftColumn,
  RightColumn,
  ColumnCard
} from '../../../components/Forms/components/TwoColumn';
import { useInViewport } from '~/App/shared/hooks/use-in-viewport';

import TextAreaWithTemplates from '../../../components/Forms/components/TextAreaWithTemplates';
import { IntegrityText } from '../../../components/Forms/components/IntegrityText';
import { MemorialGiftSummary } from '../../../components/Forms/components/Summaries';
import { MemorialGiftPreview } from '../../../components/Forms/components/CertificatePreviews';
import { PreviewButton } from '~/App/shared/components/Donation/components/Forms/components/CertificatePreviews/components/PreviewButton';
import {
  NewFormHeading,
  FormSubHeading
} from '../../../components/Forms/components/FormTypography';
import { StepWrapper } from '../../../components/Forms/components/FormLayout';
import { AlertMessageContainer } from '~/App/shared/components/Elements';
import { SubmitButton } from '../../../components/Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '../../../components/Forms/components/SwishOtherDeviceButton';
import { PreviewModal } from '../../../components/Forms/components/PreviewModal';
import { BlueSection, MultiPartFormValues } from '../../../../MultiPartForm';

import { TaxReductionInfo } from '../../../components/Forms/components/TaxReductionInfo';
import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';
import { BitNetSelect } from '../BitNetSelect';
import { IValidation } from '../../../../Validation';
import {
  IFavourablePaymentMethod,
  IPaymentMethod
} from '~/types/IPaymentMethod';
import { ISubmit } from '../../../components/Submits';
import { MemorialGiftStateValue } from '../../../components/States/MemorialGiftState';
import { CertificateSummery, PaymentSummery, Bold } from './summeries';
import { FavourablePaymentMethodSelector } from '../../../components/Forms/components/FavourablePaymentMethodSelector';
import { paymentMethods } from '~/App/config/paymentMethods';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { buttonStyles } from '~/App/config/buttonStyles';
import { pushGTMFormEvent } from '~/App/helpers/gtm-helper';
import { IDeceasedContact } from '~/types/IDeceasedContact';
import { SingleValue } from 'react-select';

type Step = {
  stepName: string;
  buttonText: string;
};

const Image = styled.img`
  max-width: 40px;
  max-height: 40px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const FullWidthButton = styled(PrimaryButton)`
  align-self: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;
const ButtonLink = styled.a`
  align-self: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.themeColors.primary};
  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }
  cursor: pointer;
`;

export const ApprovedWrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
`;

export const CheckIcon = styled.span`
  &::after {
    ${check};
    padding: 3px;
    font-size: 1rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.darkApple};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1rem;
  }
`;

const formParts: Record<number, Step> = {
  1: {
    stepName: 'Minnesblad',
    buttonText: 'Till belopp'
  },
  2: {
    stepName: 'Gåvobelopp',
    buttonText: 'Till betalsätt'
  },
  3: {
    stepName: 'Betalsätt',
    buttonText: 'Betala och slutför'
  }
};

type Props = {
  values: MemorialGiftStateValue & MultiPartFormValues;
  validation: IValidation;
  submit: ISubmit;
  formContent: {
    prices: number[];
    textTemplates: string[];
    taxReductionInfoLink?: string;
    anotherDeviceText?: string;
    availablePaymentMethods: IPaymentMethod[];
  };
};

export function Form({ values, validation, submit, formContent }: Props) {
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [isApproved, setApproved] = useState(false);
  const [seenBlock, setSeenBlock] = useState(false);
  const [
    pushedTextGreetingInteraction,
    setPushedTextGreetingInteraction
  ] = useState(false);
  const [isVisible, ref] = useInViewport({ threshold: 0.5 });

  const togglePreviewModal = useCallback((event?: React.MouseEvent) => {
    setPreviewModalOpen((prevState) => !prevState);
  }, []);

  const changeMemorialGift = useCallback(
    (event?: React.MouseEvent, buttonText?: string | undefined) => {
      if (previewModalOpen) {
        pushGTMFormEvent('formChange', {
          formName: values.gtm?.formName,
          amount: values.productOptions?.memorialGift.amount,
          period: values?.productOptions?.product?.period,
          step: values.partOptions.currentPart,
          stepName: formParts[values.partOptions.currentPart].stepName,
          paymentMethod:
            values.paymentMethod?.id === paymentMethods.klarnaPayments
              ? values.paymentMethod?.slug
              : values.paymentMethod?.name,
          buttonText
        });
      }
      togglePreviewModal();
    },
    [
      togglePreviewModal,
      values.gtm?.formName,
      values.productOptions?.memorialGift.amount,
      values?.productOptions?.product?.period,
      values.partOptions.currentPart,
      values.paymentMethod,
      previewModalOpen
    ]
  );

  const approveMemorialGift = useCallback(() => {
    setApproved(true);
    togglePreviewModal();
    values.handlers.handleGoToPart(
      2,
      formParts[1].stepName,
      'Godkänn minnesblad'
    );
  }, [values.handlers, togglePreviewModal]);

  const calculatedLines = useMemo(
    () =>
      textHelper.calculateUsedLines(
        values.productOptions.memorialGift?.textGreeting
      ),
    [values.productOptions.memorialGift?.textGreeting]
  );

  const modalBottomSection = useMemo(
    () => (
      <>
        <p>Ser allt rätt ut? </p>
        <p>Godkänn ditt minnesblad för att fortsätta</p>
        <FullWidthButton
          buttonType="button"
          type="button"
          buttonStyle={buttonStyles.success}
          children="Godkänn minnesblad"
          onClick={approveMemorialGift}
          data-cy="approve-memorial-gift"
        />
        <ButtonLink
          type="button"
          children="Jag vill ändra minnesbladet"
          onClick={(event: React.MouseEvent) =>
            changeMemorialGift(event, 'Jag vill ändra minnesbladet')
          }
        ></ButtonLink>
      </>
    ),
    [approveMemorialGift, changeMemorialGift]
  );
  useEffect(() => {
    setApproved(false);
  }, [values.productOptions.memorialGift.textGreeting]);

  useEffect(() => {
    if (!isVisible || seenBlock) return;
    pushGTMFormEvent('formImpression', {
      formName: values.gtm?.formName,
      amount: values.productOptions.memorialGift.amount,
      period: values.productOptions.product.period
    });
    setSeenBlock(true);
  }, [
    isVisible,
    values.gtm.formName,
    values.productOptions.product.period,
    seenBlock,
    values.productOptions.memorialGift.amount
  ]);

  const pushGtmFormInteraction = useCallback(
    (fieldName: string, fieldValue?: string) => {
      pushGTMFormEvent('formInteraction', {
        formName: values.gtm?.formName,
        fieldName: fieldName,
        step: values.partOptions.currentPart,
        stepName: formParts[values.partOptions.currentPart].stepName,
        paymentMethod:
          values.paymentMethod?.id === paymentMethods.klarnaPayments
            ? values.paymentMethod?.slug
            : values.paymentMethod?.name,
        fieldValue: fieldValue
      });
    },
    [values.gtm?.formName, values.partOptions.currentPart, values.paymentMethod]
  );

  const pushGtmPaymentFormInteraction = useCallback(
    (method: IFavourablePaymentMethod, fieldName: string | undefined) => {
      pushGTMFormEvent('formInteraction', {
        formName: values.gtm?.formName,
        fieldName,
        step: values.partOptions.currentPart,
        stepName: formParts[values.partOptions.currentPart].stepName,
        paymentMethod:
          method.id === paymentMethods.klarnaPayments
            ? method.slug
            : method.name,
        fieldValue: method.name
      });
    },
    [values.gtm?.formName, values.partOptions.currentPart]
  );

  const handleSetInMemoryOfContact = useCallback(
    (value: SingleValue<IDeceasedContact>, fieldValue?: string) => {
      values.handlers.setInMemoryOfContact(value);
      if (value) {
        pushGtmFormInteraction('memorialGift.deceased', fieldValue);
      }
    },
    [values.handlers, pushGtmFormInteraction]
  );

  const pushTextGreetingFormInteraction = useCallback(
    (fieldName: string, fieldValue: string) => {
      if (
        values.productOptions.memorialGift.textGreeting &&
        !pushedTextGreetingInteraction
      ) {
        pushGtmFormInteraction(fieldName, fieldValue);
        setPushedTextGreetingInteraction(true);
      }
    },
    [
      values.productOptions.memorialGift.textGreeting,
      pushedTextGreetingInteraction,
      pushGtmFormInteraction
    ]
  );

  return useMemo(
    () => (
      <Wrapper>
        <LeftColumn>
          {previewModalOpen && (
            <PreviewModal
              closeModal={togglePreviewModal}
              showBottomSection={true}
              bottomSection={modalBottomSection}
            >
              <MemorialGiftPreview values={values} fullSize={true} />
            </PreviewModal>
          )}
          <form
            onSubmit={(e) =>
              submit.handleSubmit(
                e,
                formParts[3].stepName,
                formParts[3].buttonText
              )
            }
            noValidate
          >
            <BlueSection
              values={values}
              part={1}
              validation={validation}
              heading={formParts[1].stepName}
              nextStepButton={{ text: formParts[1].buttonText }}
              summery={<CertificateSummery values={values} />}
              handleGoToPart={
                isApproved ? values.handlers.handleGoToPart : togglePreviewModal
              }
            >
              <div ref={ref}>
                <StepWrapper>
                  <FormSubHeading>Vem vill du hedra?</FormSubHeading>
                  <p>
                    Sök efter personen genom att fylla i namnet, så skickar vi
                    minnesbladet direkt till begravningsbyrån.
                  </p>
                  <BitNetSelect
                    value={values.productOptions.inMemoryOfContact}
                    onChange={handleSetInMemoryOfContact}
                  />
                </StepWrapper>
                <StepWrapper>
                  <FormSubHeading>
                    Skriv en hälsning och ditt/era namn
                  </FormSubHeading>
                  <TextAreaWithTemplates
                    name="memorialGift.textGreeting"
                    value={values.productOptions.memorialGift.textGreeting}
                    maxLength="2000"
                    placeholder="Hälsning på minnesbladet"
                    texts={values.productOptions.texts}
                    selectedTemplate={values.productOptions.selectedTemplate}
                    handleTextChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      selectedTemplate: number
                    ) => {
                      values.handlers.handleTextChange(event, selectedTemplate);
                      pushTextGreetingFormInteraction(
                        event.target.name,
                        event.target.placeholder
                      );
                    }}
                    onBlur={validation.showError.bind(
                      undefined,
                      'productOptions.memorialGift.textGreeting'
                    )}
                    errors={
                      validation.errors[
                        'productOptions.memorialGift.textGreeting'
                      ]
                    }
                    isValid={validation.isValid(
                      'productOptions.memorialGift.textGreeting'
                    )}
                    isInvalid={validation.isInvalid(
                      'productOptions.memorialGift.textGreeting'
                    )}
                    resetTextToTemplate={values.handlers.resetTextToTemplate}
                    handleTemplateStep={values.handlers.handleTemplateStep}
                    templates={formContent.textTemplates}
                    counterValue={`${calculatedLines}/10 rader`}
                    required
                  />
                </StepWrapper>
                <MemorialGiftSummary
                  values={values}
                  formContent={formContent}
                  area="form"
                  openPreviewModal={togglePreviewModal}
                  isApproved={isApproved}
                />
              </div>
            </BlueSection>
            <BlueSection
              values={values}
              part={2}
              validation={validation}
              heading={formParts[2].stepName}
              subHeading="Visas ej på minnesbladet"
              nextStepButton={{ text: formParts[2].buttonText }}
              summery={
                <Bold>{values.productOptions.memorialGift.amount} kr</Bold>
              }
            >
              <div>
                <StepWrapper>
                  <StepWrapper marginTop="tiny">
                    <AmountSelector
                      amounts={formContent.prices}
                      selectedAmount={values.productOptions.memorialGift.amount}
                      customAmount={
                        values.productOptions.memorialGift.customAmount
                      }
                      setAmount={values.handlers.setMemorialGiftAmount}
                      setCustomAmount={
                        values.handlers.setMemorialGiftCustomAmount
                      }
                      resetAmount={values.handlers.resetMemorialGiftAmount}
                      validationKey={'productOptions.memorialGift.amount'}
                      validation={validation}
                      tinyLayout="50"
                      smallLayout="50"
                      largeLayout="auto"
                      pushGtmFormInteraction={pushGtmFormInteraction}
                    />
                    <MemorialGiftSummary
                      values={values}
                      formContent={formContent}
                      area="form"
                      openPreviewModal={togglePreviewModal}
                      isApproved={isApproved}
                    />
                  </StepWrapper>
                </StepWrapper>
              </div>
              <TaxReductionInfo
                amount={getListPrice(values.productOptions)}
                quantity={getQuantity(values.productOptions)}
                minAmount={150}
              />
            </BlueSection>
            <BlueSection
              values={values}
              part={3}
              validation={validation}
              heading={formParts[3].stepName}
              subHeading={
                <>
                  <div>Swish · Bankkonto · Kort</div>
                  <ImageWrapper>
                    <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1619068772/assets/payment-methods/logos-swish_3x.png" />
                    <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1633338141/assets/klarna/Klarna_Payment_Badge.svg" />
                  </ImageWrapper>
                </>
              }
              summery={<PaymentSummery values={values} />}
            >
              <div>
                <StepWrapper>
                  <FavourablePaymentMethodSelector
                    methods={[
                      {
                        id: paymentMethods.swish,
                        name: 'Swish',
                        favourable: true
                      },
                      {
                        id: paymentMethods.klarnaPayments,
                        name: 'Konto och kort',
                        slug: 'klarna',
                        favourable: true
                      },
                      {
                        id: paymentMethods.paymentSlip,
                        name: 'Inbetalningskort',
                        favourable: false
                      }
                    ]}
                    formContent={formContent}
                    values={values}
                    validation={validation}
                    pushGtmFormInteraction={pushGtmPaymentFormInteraction}
                  >
                    <>
                      {submit.errors &&
                        !submit.isPolling &&
                        !submit.isSending && (
                          <AlertMessageContainer messageStyle="error">
                            {getPurchaseErrorMessage(submit.exceptionCode)}
                          </AlertMessageContainer>
                        )}
                      <SubmitButton
                        values={values}
                        validation={validation}
                        submit={submit}
                        children={formParts[3].buttonText}
                      />
                      <SwishOtherDeviceButton
                        values={values}
                        text={formContent.anotherDeviceText}
                      />
                      <IntegrityText anchor="#minnesgava-via-cancerfondens-hemsida" />
                    </>
                  </FavourablePaymentMethodSelector>
                  <MemorialGiftSummary
                    values={values}
                    formContent={formContent}
                    area="form"
                    openPreviewModal={togglePreviewModal}
                    isApproved={isApproved}
                  />
                </StepWrapper>
              </div>
            </BlueSection>
            {values.partOptions.currentPart === 4 && (
              <>
                <SubmitButton
                  values={values}
                  validation={validation}
                  submit={submit}
                />
                <SwishOtherDeviceButton
                  values={values}
                  text={formContent.anotherDeviceText}
                />
              </>
            )}
          </form>
        </LeftColumn>
        <RightColumn>
          <ColumnCard>
            <NewFormHeading>Ditt minnesblad</NewFormHeading>
            <MemorialGiftPreview values={values} />
            {!isApproved ? (
              <PreviewButton onClick={togglePreviewModal}>
                Förhandsgranska minnesblad
              </PreviewButton>
            ) : (
              <>
                <ApprovedWrapper>
                  <CheckIcon />
                  <p>Du har godkänt minnesbladet</p>
                </ApprovedWrapper>
              </>
            )}
            <MemorialGiftSummary
              values={values}
              formContent={formContent}
              area="side"
              openPreviewModal={previewModalOpen}
              isApproved={isApproved}
            />
          </ColumnCard>
        </RightColumn>
      </Wrapper>
    ),
    [
      calculatedLines,
      formContent,
      previewModalOpen,
      submit,
      togglePreviewModal,
      validation,
      values,
      isApproved,
      modalBottomSection,
      handleSetInMemoryOfContact,
      pushGtmFormInteraction,
      pushTextGreetingFormInteraction,
      pushGtmPaymentFormInteraction,
      ref
    ]
  );
}
