import styled from 'styled-components';
import React, { useCallback, useState, ChangeEvent } from 'react';

import Text from '~/App/shared/components/Fields/Text';
import { card, heading3, primaryButton, heading4 } from '~/App/helpers/mixins';
import { IImageSource } from '~/types/IImage';
import { useValidation } from '~/App/shared/components/Validation';
import { mq } from '~/lib/mq';
import { siteAxios } from '~/App/helpers/http';
import HtmlContent from '~/App/shared/components/HtmlContent';

const Image = styled.img`
  width: 100%;
`;

const Heading = styled.div`
  ${heading3};
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const Preamble = styled(HtmlContent)`
  margin-top: 0.5rem;
  flex-grow: 1;
`;

const Content = styled.div`
  padding: 1.5rem 1rem 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledText = styled(Text)`
  margin-top: 1.5rem;
`;

const Button = styled.button`
  ${primaryButton};
  min-width: initial;
  width: 8.125rem;
  margin: 1rem 0 0;
`;

type WrapperProps = {
  numberOfColumns: number;
};

const Wrapper = styled.div<WrapperProps>`
  ${card};

  padding: 0 !important;
  flex-basis: 100%;
  overflow: hidden;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  ${mq<WrapperProps>('>small')`
    flex-direction: ${({ numberOfColumns }) =>
      numberOfColumns === 1 ? 'row' : 'column'};
      flex-basis: ${({ numberOfColumns }) => {
        if (numberOfColumns === 3) {
          return 'calc(33% - 2rem)';
        }

        if (numberOfColumns === 2) {
          return 'calc(50% - 2rem)';
        }

        return '100%';
      }};
      margin: 0 1rem 2rem;

    ${Image} {
     width: ${({ numberOfColumns }) =>
       numberOfColumns === 1 ? '50%' : '100%'};
    }

    ${Content} {
    padding-left: ${({ numberOfColumns }) =>
      numberOfColumns === 1 ? '2rem' : '1rem'};
    }
    `};
`;

type DisclaimerProps = {
  numberOfColumns: number;
};

const Disclaimer = styled(HtmlContent)<DisclaimerProps>`
  margin-top: 1rem;
  max-width: 35.5rem;
  display: block;

  ${mq<DisclaimerProps>('>small')`
    display: ${({ numberOfColumns }) =>
      numberOfColumns !== 3 ? 'block' : 'none'};
  `};

  p {
    font-size: 0.75rem;
    line-height: 1.75;
  }

  a {
    text-decoration: underline;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 0.875em;
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 0.325rem;
`;
const Success = styled.p`
  ${heading4};
  margin-top: 1rem;
`;

type Props = {
  blockId: string;
  index: number;
  heading: string;
  body: string;
  image?: IImageSource;
  numberOfColumns: number;
  disclaimer: string;
};

export function Card({
  blockId,
  index,
  heading,
  body,
  image,
  disclaimer,
  numberOfColumns
}: Props) {
  const [email, setEmail] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<string>();

  const validation = useValidation({
    values: {
      email
    },
    schema: {
      email: {
        email: {
          message: '^E-post krävs'
        }
      }
    }
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setSubmitErrors(undefined);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!validation.isValidated) {
      return validation.showAllErrors();
    }

    try {
      await siteAxios.post('/api/v2/email-leads', {
        blockId: blockId,
        columnIndex: index,
        email: email
      });
      setSubmitted(true);
      setEmail('');
      validation.hideAllErrors();
    } catch (error) {
      setSubmitErrors('Någonting gick fel. Testa igen senare');
    }
  }, [blockId, email, index, validation]);

  return (
    <>
      <Wrapper numberOfColumns={numberOfColumns}>
        {image && <Image src={image?.secure_url} />}
        <Content>
          <Heading children={heading} />
          <Preamble children={body} />
          <div>
            <StyledText
              isValid={validation.isValid('email')}
              isInvalid={validation.isInvalid('email')}
              errors={validation.errors['email']}
              placeholder="E-post"
              type="email"
              value={email}
              onChange={handleChange}
              onBlur={validation.showError.bind(undefined, 'email')}
              required
            />
          </div>
          {isSubmitted && <Success>Tack! Du kommer att få mejl.</Success>}
          {submitErrors && <ErrorMessage>{submitErrors} </ErrorMessage>}
          <Button
            buttonStyle={validation.isValidated ? 'primary' : 'disabled'}
            onClick={handleSubmit}
            children="Skicka"
          />
          <Disclaimer numberOfColumns={numberOfColumns} children={disclaimer} />
        </Content>
      </Wrapper>
    </>
  );
}
