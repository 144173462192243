import { create } from '~/lib/mq';

export const mq = create(({ settings, helpers }) => {

    type Breakpoints = typeof settings.breakpoints;
    type BreakpointKeys = keyof Breakpoints;

    const keys = Object.keys(settings.breakpoints) as unknown as Array<BreakpointKeys>;

    return {
        ...settings,
        breakpoints: keys.reduce<Breakpoints>((
            breakpoints,
            breakpointName) => ({
                ...breakpoints,
                [breakpointName]: helpers.pxToEm(settings.breakpoints[breakpointName])
            }), settings.breakpoints)
    }
});

export default mq;

