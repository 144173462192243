import { ChangeEvent, ChangeEventHandler, useCallback, useState } from 'react';
import { memorial } from '~/App/config/default-images';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';

export type CreateMemorialPageStateValues = {
  handlers: {
    handleSsn: ChangeEventHandler<HTMLInputElement>;
    handlePersonName: ChangeEventHandler<HTMLInputElement>;
    setPersonBirth: (value: Date) => void;
    setPersonDeath: (value: Date) => void;
    setPersonImage: (value: string) => void;
    setSameDevice: (value: boolean) => void;
  };
  ssn: string;
  person: IPerson;
  sameDevice: boolean;
};

type IPerson = {
  name: string;
  birth: Date | null;
  death: Date | null;
  image: string;
};

export function useCreateMemorialPageState(): CreateMemorialPageStateValues {
  const [ssn, setSsn] = useState('');
  const [person, setPerson] = useState<IPerson>({
    name: '',
    birth: null,
    death: null,
    image: memorial[0]
  });
  const [sameDevice, setSameDevice] = useState<boolean>(isMobileBrowser());

  const handleSsn = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setSsn(event.target.value);
  }, []);

  const handlePersonName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setPerson((person) => ({ ...person, name: event.target.value }));
    },
    []
  );

  const setPersonBirth = useCallback((value: Date) => {
    setPerson((person) => ({ ...person, birth: value }));
  }, []);

  const setPersonDeath = useCallback((value: Date) => {
    setPerson((person) => ({ ...person, death: value }));
  }, []);

  const setPersonImage = useCallback((url: string) => {
    setPerson((person) => ({ ...person, image: url }));
  }, []);

  return {
    handlers: {
      handleSsn,
      handlePersonName,
      setPersonBirth,
      setPersonDeath,
      setPersonImage,
      setSameDevice
    },
    ssn,
    person,
    sameDevice
  };
}
