import React from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';

// Shared components
import Link from '~/App/shared/components/Link';
import { Icon } from './components/Icon';

export const StyledLink = styled(Link)`
  display: block;
  padding: 1.25rem 3rem 0.5rem 1.125rem;
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.75;

  ${mq('≥mediumLarge')`
  display: flex;
  padding: 0;
  font-size: 1rem;
  line-height: 1.75;
  height: 80px;
  align-items: flex-start;
  padding-top: 2.125rem;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryHover};
  }
`};
`;

export const Text = styled.span`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    display: block;
    width: 100%;
    height: 6px;
    background: ${({ theme }) => theme.themeColors.primary};
    visibility: hidden;
  }
`;

export function Search() {
  return (
    <StyledLink to="/sok">
      <Text>
        <Icon />
      </Text>
    </StyledLink>
  );
}
