import React, { ReactNode, useContext, useMemo } from 'react';
import styled from 'styled-components';

import { AuthorHelperContext } from './AuthorHelperContext';

const editorUrl = process.env.EDITOR_URL || '';
const showEditorLink = process.env.EDITOR_LINKS === 'enabled';

const Wrapper = styled.div`
  position: static;
`;

const Link = styled.a`
  position: absolute;
  z-index: 9;
  background-color: red;
  border: 1px solid pink;
  color: #fff;
  padding: 0.5em;

  &:hover {
    z-index: 900;
  }
`;

type Props = {
  id: string;
  path: string;
  text: string;
  publishState: string;
  children: ReactNode;
};

export default function AuthorHelper({
  id = '',
  path = '',
  text = '',
  publishState = '',
  children
}: Props) {
  const isEnabled = useContext(AuthorHelperContext);
  const showBadge = isEnabled ? showEditorLink && id : false;

  return useMemo(() => {
    if (showBadge) {
      return (
        <Wrapper>
          <Link
            href={editorUrl + path + id}
            target="_blank"
            children={`${text} (${publishState})`}
          />
          {children}
        </Wrapper>
      );
    }

    return <div>{children}</div>;
  }, [children, id, path, publishState, showBadge, text]);
}
