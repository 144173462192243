import React from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';
import { PersonNotFound } from './PersonNotFound';

const Wrapper = styled.div`
  padding: 1rem 1.25rem;
  font-size: 1.125rem;

  ${mq('<small')`
    font-size: 1rem;
  `}
`;

type BuildNoOptionsMessageArgs = {
  loading: boolean;
  openModal: () => void;
};

type Props = {
  clearValue: () => void;
};

export function buildNoOptionsMessage({
  loading,
  openModal
}: BuildNoOptionsMessageArgs) {
  return function NoOptionsMessage({ clearValue }: Props) {
    if (loading) {
      return <Wrapper children="Söker..." />;
    }

    return (
      <>
        <Wrapper children="Inga resultat" />
        <PersonNotFound
          openModal={() => {
            clearValue();
            openModal();
          }}
        />
      </>
    );
  };
}
