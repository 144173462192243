import React, { useMemo } from 'react';
import styled from 'styled-components';

// helpers
import mediaHelper from '~/App/helpers/media-helper';
import mq from '~/App/helpers/mq';

// shared components
import Link from '~/App/shared/components/Link';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0;

  ${mq('≥mediumLarge')`
    align-items: flex-start;
  `};
`;

const Image = styled.picture`
  line-height: 0;
  margin-top: -1px;
  display: block;
  width: 174px;
  height: 27px;

  ${mq('≥mediumLarge')`
    margin-top: -1px;
    width: 225px;
    height: 35px;
  `};

  img {
    max-height: 100%;
  }
`;

type Props = {
  color?: 'blue' | 'white' | 'black';
  link?: boolean;
};

export function Logo({ color = 'blue', link = true }: Props) {
  const url = useMemo(
    () =>
      `https://res.cloudinary.com/cancerfonden/image/upload/v1544186351/assets/logo-cancerfonden_one-line_${color}.png`,
    [color]
  );

  const logo = useMemo(
    () => (
      <Image>
        <source
          srcSet={`
      ${mediaHelper.getUrl(url, {
        width: 450,
        height: 70,
        quality: 100,
        crop: 'pad'
      })} 2x,
      ${mediaHelper.getUrl(url, {
        width: 225,
        height: 35,
        quality: 100,
        crop: 'pad'
      })} 1x
    `}
        />
        <img src={mediaHelper.getUrl(url, { height: 35 })} />
      </Image>
    ),
    [url]
  );

  return link ? <StyledLink to="/" children={logo} /> : logo;
}
