import { useState, useCallback } from 'react';

import { collections } from '~/App/helpers/http';

import { CollectionAdmin } from '~/types/ICollection';

export type UseAdmins = {
  items: CollectionAdmin[];
  fetch: () => Promise<void>;
};

export function useAdmins(slug: string): UseAdmins {
  const [items, setItems] = useState<CollectionAdmin[]>([]);

  const fetch = useCallback(async () => {
    const { data = [] } = await collections.owners({ slug });
    setItems(data);
  }, [slug]);

  return {
    fetch,
    items
  };
}
