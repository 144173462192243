import styled, { css } from 'styled-components';
import { linkUnderline } from '~/App/helpers/mixins';
import omitProps from '~/App/helpers/omit-props';
import Link from '~/App/shared/components/Link';
import mq from '~/App/helpers/mq';

export type NavLinkProps = {
  active?: boolean;
  highlighted?: boolean;
};

export const NavLink = styled(
  omitProps(Link, ['active', 'highlighted'])
)<NavLinkProps>`
  display: block;
  padding: 1.25rem 3rem 0.5rem 1.125rem;
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.777777778;
  ${linkUnderline};

  &::after {
    height: 4px;
    background: ${({ theme }) => theme.themeColors.primary};
  }

  ${mq<NavLinkProps>('≥mediumLarge')`
        display: flex;
        padding: 0 0.375rem;
        font-size: 1.125rem;
        line-height: 1.777777778;
        height: 50px;
        align-items: center;
    
        ${(props) =>
          props.active &&
          css`
            &::after {
              visibility: visible;
              transform: scaleX(1);
            }
          `};
      `};

  ${(props) =>
    props.highlighted &&
    css`
      font-size: 1rem;
      padding: 1rem 0.3rem 0.5rem 0.5rem;

      ${mq('≥micro')`
            padding: 1.25rem 0.5rem 0.5rem 0.75rem;
          `};
    `};
`;
