import React, { ComponentProps } from 'react';
import styled from 'styled-components';

// Helpers
import { headingFont } from '~/App/helpers/mixins';

type OptionWrapperProps = {
  isFocused?: boolean;
};

const OptionWrapper = styled.div<OptionWrapperProps>`
  padding: 1rem 1.75rem;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
  background-color: ${({ theme, isFocused }) =>
    isFocused && theme.colors.smoke};

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.smoke};
  }
`;

const OptionLabel = styled.p`
  ${headingFont};
  font-size: 1.25rem;
`;

const OptionSubLabel = styled.p`
  ${headingFont};
  font-size: 1.125rem;
`;

type Props = {
  innerProps: ComponentProps<typeof OptionWrapper>;
  data: {
    value: number | string;
    label: string;
    subLabel?: string;
  };
  isFocused: boolean;
};

export function Option({ innerProps, data, isFocused }: Props) {
  return (
    <OptionWrapper {...innerProps} isFocused={isFocused}>
      <OptionLabel children={data.label} />
      {data.subLabel && <OptionSubLabel children={data.subLabel} />}
    </OptionWrapper>
  );
}
