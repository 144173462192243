import React from 'react';

// Local components
import NavigationItem from './NavigationItem';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll(this));

    // Call on first render in case we have done a page reload
    this.onScroll();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    var breakPointSticky = document.querySelector('.hero').offsetHeight;
    var breakPointSmall = breakPointSticky * 0.2;

    var stickyTopPosition =
      document.querySelector('.header') &&
      document.querySelector('.header').offsetHeight;
    var scrollPosition = document.documentElement.scrollTop;

    if (scrollPosition > breakPointSmall) {
      document.querySelector('.heroNavigation') &&
        document.querySelector('.heroNavigation').classList.add('is-small');
    } else {
      document.querySelector('.heroNavigation') &&
        document.querySelector('.heroNavigation').classList.remove('is-small');
    }

    if (scrollPosition > breakPointSticky) {
      document.querySelector('.heroNavigation')
        ? (document
            .querySelector('.heroNavigation')
            .classList.add('is-sticky').style.top = stickyTopPosition)
        : null;
    } else {
      document.querySelector('.heroNavigation')
        ? (document
            .querySelector('.heroNavigation')
            .classList.add('is-sticky').style.top = '')
        : null;
    }
  }

  render() {
    if (!this.props.navigation || this.props.navigation.length === 0) {
      return null;
    }

    return (
      <div className="hero__navigation">
        <div className="heroNavigation">
          <nav className="heroNavigation__main">
            <div className="heroNavigation__links">
              {this.props.navigation.map(function (link, i) {
                return (
                  <NavigationItem
                    key={'navigationItem-' + i}
                    url={link.url}
                    text={link.text}
                  />
                );
              })}
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

Navigation.displayName = 'HeroNavigation';

Navigation.defaultProps = {
  navigation: []
};

export default Navigation;
