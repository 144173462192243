import { useState, useEffect } from 'react';

export function useInterval(ms = 1000) {
  const [ticks, setTicks] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTicks((val) => val + 1);
    }, ms);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ticks;
}

export default useInterval;
