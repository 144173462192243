import React from 'react';

import { Email, Ssn } from '../fields';
import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';
import { ContactInputGroup } from './ContactInputGroup';
import { FlexWrapper } from './FlexWrapper';

export type AutomaticProps = {
  ssn?: string;
  email?: boolean | string;
};

type Props = AutomaticProps & {
  props: {
    validation: IValidation;
    values: ContactCustomerValues;
  };
};

export function Automatic({ props, email = true, ssn }: Props) {
  return (
    <ContactInputGroup>
      <FlexWrapper>
        <Ssn {...props} instructionText={ssn} />
        {email && (
          <Email
            {...props}
            copy={typeof email === 'string' ? email : undefined}
          />
        )}
      </FlexWrapper>
    </ContactInputGroup>
  );
}
