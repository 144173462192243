import { isCloudinaryUrl } from '~/App/helpers/media-helper';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';
import { collections, meCollections } from '~/App/helpers/http';
import { IValidation } from '~/App/shared/components/Validation';
import { CollectionEditState } from './useCollectionEditState';

const IMAGE_UPLOAD_EXCEPTION = 'imageUploadException';

export type CollectionEditSubmit = {
  isSaving: boolean;
  isUnpausing: boolean;
  handleSaveSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  handleUnpauseSubmit: () => Promise<void>;
};

type Args = {
  state: CollectionEditState;
  validation: IValidation;
  setError: (error: string | null) => void;
};

export function useCollectionEditSubmit({ state, validation, setError }: Args) {
  const [isUnpausing, setUnpausing] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const navigate = useNavigate();

  const handleImageUpload = useCallback(async () => {
    const url = state.values.imageUrl;

    if (!url) {
      return url;
    }

    if (isCloudinaryUrl(url)) {
      return url;
    }

    try {
      const { data } = await uploadImage(url, {
        timeout: 30000,
        folder: 'collection-pictures'
      });
      const imageUrl = data?.secure_url;

      if (!imageUrl) {
        return null;
      }

      state.handlers.setImageUrl(imageUrl);

      return imageUrl;
    } catch (error) {
      setError('Något gick fel vid bilduppladdningen, var vänlig försök igen.');
    }

    return null;
  }, [setError, state.handlers, state.values.imageUrl]);

  const handleSaveSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      try {
        setSaving(true);

        await collections.edit({
          slug: `${state.values.slug}`,
          data: {
            collection: {
              name: state.values.name,
              senderName: state.values.senderName,
              description: state.values.description,
              image_url: await handleImageUpload(),
              monetary_goal: state.values.goal,
              collection_type: state.values.collectionType,
              show_progress_badge: state.values.showProgressBadge
            }
          }
        });

        setSaving(false);
        navigate('/min-sida/insamlingar');
      } catch (error) {
        switch (error) {
          case IMAGE_UPLOAD_EXCEPTION:
            break;

          default:
            setError('Något gick fel vid sparningen, var vänlig försök igen.');
            break;
        }

        setSaving(false);
      }
    },
    [
      handleImageUpload,
      navigate,
      setError,
      state.values.collectionType,
      state.values.description,
      state.values.goal,
      state.values.name,
      state.values.senderName,
      state.values.slug,
      state.values.showProgressBadge,
      validation
    ]
  );

  const handleUnpauseSubmit = useCallback(async () => {
    setUnpausing(true);

    try {
      await meCollections.unpause({
        slug: `${state.values.slug}`
      });

      navigate('/min-sida/insamlingar');
    } catch (error) {
      console.error(error);
      setUnpausing(false);
    }
  }, [navigate, state.values.slug]);

  return useMemo(
    () => ({
      isSaving,
      isUnpausing,
      handleSaveSubmit,
      handleUnpauseSubmit
    }),
    [handleSaveSubmit, handleUnpauseSubmit, isSaving, isUnpausing]
  );
}
