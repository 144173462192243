export const schema = {
  'productOptions.product.price': {
    presence: {
      allowEmpty: false,
      message: '^Ange ett belopp'
    },
    numericality: {
      greaterThan: 0,
      notGreaterThan: '^Beloppet måste vara positivt.',
      lessThanOrEqualTo: 999999,
      notLessThankOrEqualTo: '^Beloppet är för högt.'
    }
  },
  'productOptions.customerContact.email': {
    email: {
      message: '^Felaktig e-postadress'
    }
  },
  'productOptions.customerContact.ssn': {
    validateSsn: true,
    validateLegalAge: true
  }
};
