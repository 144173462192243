const windowExists = typeof window !== 'undefined';
const windowFeatureTogglesExists = windowExists && window.__FEATURE_TOGGLES__;

export type FeatureToggles = Record<string, boolean>;

declare global {
  interface Window {
    __FEATURE_TOGGLES__?: FeatureToggles;
  }
}

export const featureToggles: FeatureToggles =
  windowFeatureTogglesExists && window.__FEATURE_TOGGLES__
    ? window.__FEATURE_TOGGLES__
    : {
        // FEATURE_EXAMPLE: (process.env.FEATURE_EXAMPLE === 'true')
        ENABLE_TEST_BANKID_USER: process.env.ENABLE_TEST_BANKID_USER === 'true',
        FEATURE_LOPBANDET_CAMPAIGN_SITE:
          process.env.FEATURE_LOPBANDET_CAMPAIGN_SITE === 'true',
        FEATURE_LOPBANDET_PINK: process.env.FEATURE_LOPBANDET_PINK === 'true',
        FEATURE_KLARNA_TAKEOVER: process.env.FEATURE_KLARNA_TAKEOVER === 'true',
        FEATURE_GOOGLE_TRANSLATE:
          process.env.FEATURE_GOOGLE_TRANSLATE === 'true',
        FEATURE_GAVOSHOP: process.env.FEATURE_GAVOSHOP === 'true',
        FEATURE_SELFTEST: process.env.FEATURE_SELFTEST === 'true',
        FEATURE_COMMERCE: process.env.FEATURE_COMMERCE === 'true',
        FEATURE_LOTTERY_ENCASING:
          process.env.FEATURE_LOTTERY_ENCASING === 'true',
        NUDGE_MONTHLYDONATION_ENABLED:
          process.env.NUDGE_MONTHLYDONATION_ENABLED === 'true',
        NUDGE_GENERIC_CERTIFICATE_ENABLED:
          process.env.NUDGE_GENERIC_CERTIFICATE_ENABLED === 'true',
        NUDGE_MEMORIAL_GIFT_ENABLED:
          process.env.NUDGE_MEMORIAL_GIFT_ENABLED === 'true',
        NUDGE_COLLECTION_DONATION_ENABLED:
          process.env.NUDGE_COLLECTION_DONATION_ENABLED === 'true',
        NUDGE_COMBINED_DONATION_ENABLED:
          process.env.NUDGE_COMBINED_DONATION_ENABLED === 'true',
        NUDGE_KLARNA_DONATION_ENABLED:
          process.env.NUDGE_KLARNA_DONATION_ENABLED === 'true'
      };
