import styled from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';
import { profile } from '~/App/helpers/icons';

export const ProfileIcon = styled.div`
  display: block;
  position: relative;
  width: 1rem;
  height: 01rem;

  ${mq('≥large')`
    width: 1.3755rem;
    height: 1.25rem;
  `};

  &:before {
    ${profile};
    position: absolute;
    font-size: 0.875rem;
    font-weight: bold;
    top: 50%;
    width: 100%;
    height: 100%;
    left: 50%;
    line-height: 1;
    text-align: center;
    transform: translate(-50%, -50%);

    ${mq('≥large')`
      font-size: 1.2rem;
    `};
  }
`;
