import React, { useMemo, ReactNode } from 'react';

import { GenericValues, useGenericValues } from './useGenericValues';

import {
  ContactCustomerValues,
  useContactInformationValues
} from './useContactInformationValues';

import { ProductValues, useProductValues } from './useProductValues';
import { PaymentValues, usePaymentValues } from './usePaymentValues';
import { AnalyticsValues, useAnalyticsValues } from './useAnalyticsValues';

export type StateValues = ProductValues &
  AnalyticsValues &
  GenericValues &
  ContactCustomerValues &
  PaymentValues & {
    redirectPath?: string | Record<number, string>;
    originPath?: string | Record<number, string>;
  };

export type StateInputValues = {
  product?: {
    id?: number;
  };
  socialName?: string;
  productOptions?: {
    pul?: boolean;
    receiverContactType?: 'private' | 'company';
    campaignActivityId?: number | string | null;
    motherSender?: string;
    motherNumber?: string;
    motherText?: string;
  };
  gtm?: {
    variant?: string;
    formName?: string;
  };
  redirectPath?: string | Record<number, string>;
  originPath?: string | Record<number, string>;
};

export type UseStateProps = {
  values: StateInputValues;
  formContent: {
    originPath: string | Record<number, string>;
    redirectPath: string | Record<number, string>;
    gtmId?: string;
  };
  customGtmId?: string;
};

export type StateProps = {
  values: StateInputValues;
  formContent: {
    originPath: string | Record<number, string>;
    redirectPath: string | Record<number, string>;
    gtmId?: string;
  };
  customGtmId?: string;
  children: (values: StateValues) => ReactNode;
};

export function useState<T extends UseStateProps>({
  values,
  formContent,
  customGtmId
}: T) {
  const generic = useGenericValues(values);
  const contactInformation = useContactInformationValues(generic);
  const product = useProductValues(contactInformation);

  const analytics = useAnalyticsValues({
    values: product,
    formContent: formContent
  });

  const paymentValues = usePaymentValues({
    values: analytics,
    formContent: formContent,
    customGtmId: customGtmId
  });

  return paymentValues;
}

export function State<T extends StateProps>({ children, ...props }: T) {
  const state = useState(props);

  return useMemo(() => <>{children(state)}</>, [state, children]);
}
