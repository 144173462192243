import React, { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router';

// Helpers
import { siteUrl } from '~/App/helpers/env';

type Props = {
  to?: string;
  text?: string;
  hashtags?: string[];
  className?: string;
  children: ReactNode;
};

export default function TwitterShareUrl({
  to,
  text,
  hashtags = [],
  className,
  children
}: Props) {
  const location = useLocation();
  const url = useMemo(
    () =>
      [
        'https://twitter.com/share',
        '?url=' + encodeURIComponent(to || siteUrl(location.pathname)),
        '&text=' + encodeURIComponent(text || ''),
        '&hashtags=' + encodeURIComponent(hashtags.join(','))
      ].join(''),
    [hashtags, location.pathname, text, to]
  );

  return (
    <a
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      className={className}
      children={children}
    />
  );
}
