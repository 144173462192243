import React, { MouseEventHandler, ReactNode, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';
import { primaryButton } from '~/App/helpers/mixins';

// config
import { buttonStyles } from '~/App/config/buttonStyles';

// shared components
import Link from '~/App/shared/components/Link';
import useScroll from '../../../../shared/hooks/use-scroll';

const genericButtonStyling = css`
  ${primaryButton};
  min-width: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 0;
  align-items: center;
  line-height: 1;
  font-size: 0.875rem;
  padding: 0.625rem 1rem;

  ${mq('≥large')`
    font-size: 1rem;
    padding: 0.625rem 1.25rem;
  `};

  ${mq('<small')`
  padding: 0.625rem 1rem;
  min-width: 0;
  line-height: 1;
  `};
`;

const StyledLink = styled(Link)`
  ${genericButtonStyling}
`;

const PulsateAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 20, 137, 1); } 
  70% { box-shadow: 0 0 0 10px rgba(0, 20, 137, 0); } 
  100% { box-shadow: 0 0 0 50px rgba(0, 20, 137, 0); }
`;

const StyledButton = styled.button`
  ${genericButtonStyling};
`;

const PulsatingButton = styled(StyledButton)`
  box-shadow: 0 0 0 0 rgba(88, 120, 243, 0.4);
  -moz-animation: ${PulsateAnimation} 3s 3;
  -webkit-animation: ${PulsateAnimation} 3s 3;
  animation: ${PulsateAnimation} 3s 3;
`;

const Icon = styled.span`
  display: block;
  margin: 0 0.5rem 0 0;
  padding: 0;

  ${mq('<tiny')`
    display: none;
  `};
`;

const Text = styled.span`
  margin: 0;
`;

type Props = {
  to?: string;
  text?: string;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  pulsating?: boolean;
};

export function Button({ to, text, icon, onClick, pulsating }: Props) {
  const [pulsateAnimation, setPulsateAnimation] = useState<boolean | undefined>(
    pulsating
  );

  const handleScroll = () => {
    setPulsateAnimation(false);
  };

  useScroll(handleScroll);

  if (to) {
    return (
      <StyledLink to={to} aria-label={text}>
        {icon && <Icon>{icon}</Icon>}
        {text && <Text>{text}</Text>}
      </StyledLink>
    );
  }

  const Button = pulsateAnimation ? PulsatingButton : StyledButton;

  return (
    <Button
      onClick={onClick}
      aria-label={text}
      buttonStyle={buttonStyles.primary}
    >
      {icon && <Icon>{icon}</Icon>}
      {text && <Text>{text}</Text>}
    </Button>
  );
}
