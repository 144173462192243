import React, {
  ComponentProps,
  ReactNode,
  MouseEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import styled from 'styled-components';

// helpers
import mq from '~/App/helpers/mq';
import { slideUp } from '~/App/helpers/animations';

import { CloseButton } from './components/CloseButton';
import { useScrollLock } from '~/App/contexts/ScrollLock';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 102;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  ${mq('≥small')`
    justify-content: center;
  `};
`;

type ModalContentProps = {
  isDraggable?: boolean;
  maxWidth?: string;
};

const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  max-width: ${({ maxWidth }) => maxWidth || '39rem'};
  width: 100%;
  max-height: calc(100% - 1.5rem);
  margin: 8px 0 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  animation: ${slideUp} 1 0.2s cubic-bezier(0.2, 0.4, 0.3, 1);

  ${mq('≥small')`
    margin: 3rem;
    max-height: calc(100% - 6rem + 1px);
    border-radius: 4px;
  `};

  ::before {
    content: ${({ isDraggable }) => (isDraggable ? "' '" : null)};
    width: 3.375rem;
    border-radius: 100px;
    background: #f1f1f1;
    height: 0.375rem;
    top: -1rem;
    position: absolute;

    ${mq('≥small')`
      display: none;
    `}
  }
`;

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  padding: 1rem 1rem 2rem;
`;

type CloseButtonOptions = {
  text?: string;
  border?: boolean;
};

type Props = {
  closeModal?: () => void;
  closeButton?: boolean | CloseButtonOptions;
  className?: string;
  isDraggable?: boolean;
  children: ReactNode;
  maxWidth?: string;
  closeOnOverlayClick?: boolean;
} & ComponentProps<typeof ModalContent>;

export const Modal = forwardRef<HTMLDivElement, Props>(
  (
    {
      closeModal,
      closeButton = false,
      isDraggable,
      closeOnOverlayClick = true,
      maxWidth,
      ...props
    },
    ref
  ) => {
    const scrollLock = useScrollLock();

    const handleModalClick = useCallback(
      (event) => event.stopPropagation(),
      []
    );

    const handleOverlayClick = useCallback(() => {
      if (closeModal && closeOnOverlayClick) {
        closeModal();
      }
    }, [closeModal, closeOnOverlayClick]);

    const handleCloseButtonClick = useCallback(
      (event?: MouseEvent<HTMLDivElement>) => {
        event?.preventDefault();

        if (closeModal) {
          closeModal();
        }
      },
      [closeModal]
    );

    useEffect(() => scrollLock.lock(), [scrollLock]);

    return useMemo(() => {
      if (closeButton && !closeModal) {
        throw 'closeModal function must be set if closeButton is true';
      }

      return (
        <Overlay onClick={handleOverlayClick}>
          <ModalContent
            onClick={handleModalClick}
            isDraggable={isDraggable}
            ref={ref}
            maxWidth={maxWidth}
          >
            <Wrapper {...props} />
            <CloseButton
              onClick={handleCloseButtonClick}
              options={closeButton}
            />
          </ModalContent>
        </Overlay>
      );
    }, [
      closeButton,
      closeModal,
      handleOverlayClick,
      handleModalClick,
      isDraggable,
      ref,
      maxWidth,
      props,
      handleCloseButtonClick
    ]);
  }
);
