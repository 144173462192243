import React from 'react';

// Shared components
import Validation from '~/App/shared/components/Validation';

// Local components
import { Submit } from '../components/Submits';
import PaymentRedirect from '../components/PaymentRedirect';

import { MemorialGiftState } from '../components/States';
import { Form } from './components/Form';

import * as MultiPart from '~/App/shared/components/MultiPartForm';

import { useSchema } from './use-schema';
import { useState, validationKeys } from './use-state';
import { OnceDonationNudge } from '../components/TaxReductionNudging/OnceDonationNudge';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';

export default function MemorialGift({ data, ...props }) {
  const schema = useSchema(props);
  const state = useState({ ...props, data });

  return (
    <MemorialGiftState formContent={data} values={state}>
      {(values) => (
        <Validation schema={schema} values={values} hasStripe={true}>
          {(validation) => (
            <MultiPart.State
              values={values}
              validation={validation}
              parts={3}
              validationKeys={validationKeys}
            >
              {(multiPartValues) => (
                <Submit
                  values={multiPartValues}
                  validation={validation}
                  shouldNudge={featureIsEnabled('NUDGE_MEMORIAL_GIFT_ENABLED')}
                >
                  {(submit) => (
                    <OnceDonationNudge
                      submit={submit}
                      values={multiPartValues}
                      validation={validation}
                      name="Minnesgåva"
                      setAmount={multiPartValues.handlers.setMemorialGiftAmount}
                      shouldNudge={featureIsEnabled(
                        'NUDGE_MEMORIAL_GIFT_ENABLED'
                      )}
                      maximumNudgeAmount={500}
                    >
                      {(taxReductionNudgingSubmit) => (
                        <div>
                          <PaymentRedirect
                            submit={taxReductionNudgingSubmit}
                            redirectPath={values.redirectPath}
                            values={multiPartValues}
                          />
                          <Form
                            values={multiPartValues}
                            submit={taxReductionNudgingSubmit}
                            validation={validation}
                            formContent={data}
                          />
                        </div>
                      )}
                    </OnceDonationNudge>
                  )}
                </Submit>
              )}
            </MultiPart.State>
          )}
        </Validation>
      )}
    </MemorialGiftState>
  );
}
