import { errorLevels } from '../config/errorLevels';
import { logMessage } from './logger';

function getInstance() {
  if (typeof window === 'undefined' || !window.ga) {
    return [false];
  }

  return [true, window.ga];
}

export function getClientId(): string | null {
  try {
    const [success, ga] = getInstance();

    if (!success) {
      return null;
    }

    const trackers = ga.getAll();
    const firstTracker = trackers[0];

    if (!firstTracker) {
      return null;
    }

    return firstTracker.get('clientId') as string;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      return null;
    }

    logMessage({
      level: errorLevels.warn,
      message: `ClientID set failed`,
      data: { error: `${error}` }
    });
  }

  return null;
}
