import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { formatMoney } from 'accounting';
import styled from 'styled-components';

// Shared components
import Link from '~/App/shared/components/Link';
import { PrimaryButton } from '~/App/shared/components/Elements';

// Helpers
import { roll } from '~/App/helpers/animations';
import { collectionActivities } from '~/App/helpers/http';
import useInterval from '~/App/shared/hooks/use-interval';
import mq from '~/App/helpers/mq';
import { whiteGradient } from '~/App/helpers/mixins';
import { Avatar } from '~/App/shared/components/Avatar';
import { ICollectionActivity } from '~/types/ICollectionActivity';
import { MemorialModal } from './MemorialModal';

const FloatingBar = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  height: 70px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 8px 0px;
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;

  ${mq('≥small')`
      box-shadow: rgb(0 0 0 / 15%) 0px 0px 8px 0px;
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 100%;
  width: 100%;
  margin: 0 1rem;
  position: relative;
`;

const Button = styled(PrimaryButton)`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

  ${mq('≥small')`
      box-shadow: none;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1;
  padding: 0.5rem 1rem;

  &::before {
    ${whiteGradient};
  }

  ${mq('≥small')`
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    height: 100%;
    transform: none;
    top: 0;
    left: 0;
    width: auto;
    padding: 0.5rem 1rem;
    border-left: 1px white solid;

    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      right: 0;
      height: 100%;
      width: 0.5rem;
      right: -0.5rem;
      background-color: ${({ theme }) => theme.colors.white};
      background: linear-gradient(to right, white 0%, rgba(255,255,255,0%) 100%);
    }
  `};
`;

const Gift = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  height: 2.5rem;
`;

type GiftsWrapperProps = {
  activitiesCount: number;
};

const GiftsWrapper = styled.div<GiftsWrapperProps>`
  display: flex;
  align-items: center;
  overflow-x: visible;
  animation-name: ${roll};
  animation-duration: ${({ activitiesCount }) =>
    activitiesCount * 10}s; //6 sekunder för att animera en aktivitet
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.35, 0.11, 0.79, 1.01);

  &:hover {
    animation-play-state: paused;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.75rem;
`;

const StyledParagraph = styled.p`
  color: ${(props) => props.theme.colors.darkBlue};
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const BringToTop = styled.div`
  z-index: 110;
`;

export function Ticker() {
  const ticks = useInterval(60 * 1000);

  const [activities, setActivities] = useState<ICollectionActivity[]>([]);

  const formatAmount = useCallback((amount: string) => {
    if (!amount || amount === '0.0') {
      return 'har skänkt en gåva';
    }

    return `gav ${formatMoney(amount, 'kr', 0, ' ', undefined, '%v %s')}`;
  }, []);

  const fetchActivity = useCallback(async () => {
    const response = await collectionActivities.index();
    setActivities(response.data.activities);
  }, []);

  const renderActivity = useCallback(
    ({ user, collection, avatarImage, id, amount }: ICollectionActivity) => (
      <Gift key={id}>
        <StyledAvatar
          user={{ imageUrl: avatarImage, firstName: user.by }}
          size="xsmall"
        />
        <StyledParagraph>
          {user.by} {formatAmount(amount)} till{' '}
          <StyledLink to={`/insamlingar/${collection.slug}`}>
            {collection.name}
          </StyledLink>
        </StyledParagraph>
      </Gift>
    ),
    [formatAmount]
  );

  useEffect(() => {
    fetchActivity();
  }, [fetchActivity, ticks]);

  const [modalOpen, setOpen] = useState(false);

  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);

  const modal = useMemo(() => {
    if (!modalOpen) return null;

    return <MemorialModal closeModal={closeModal} />;
  }, [modalOpen, closeModal]);

  return useMemo(
    () => (
      <FloatingBar>
        <ContentWrapper>
          <ButtonWrapper onClick={openModal}>
            <Button
              buttonType="link"
              buttonStyle="cta"
              children="Starta insamling"
            />
          </ButtonWrapper>
          <GiftsWrapper activitiesCount={activities.length}>
            {activities.map(renderActivity)}
          </GiftsWrapper>
        </ContentWrapper>
        <BringToTop children={modal} />
      </FloatingBar>
    ),
    [activities, modal, openModal, renderActivity]
  );
}
