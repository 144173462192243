import React, {
  useState,
  useCallback,
  useMemo,
  ReactNode,
  SetStateAction,
  Dispatch
} from 'react';

import { omitBy, get } from 'lodash';
import { DeepPartial } from 'ts-essentials';

import { scrollAnimation } from '~/App/helpers/animations';
import { getOffset } from '~/App/helpers/units-helper';
import { IValidation } from '../Validation';
import { IPaymentMethod } from '~/types/IPaymentMethod';
import { GtmDetails, pushGTMFormEvent } from '~/App/helpers/gtm-helper';
import { paymentMethods } from '~/App/config/paymentMethods';
import { Period } from '~/types/IPurchase';

type ValidationKeys = Record<number, string[]>;
type PartValid = Record<number, boolean>;
type PartStarted = Record<number, boolean>;

export type PartOptions = {
  name: string | number;
  parts: number;
  currentPart: number;
  validationKeys: ValidationKeys;
  partValid: PartValid;
  partStarted: PartStarted;
};

export type MultiPartFormValues = {
  partOptions: PartOptions;
  handlers: {
    setCurrentPart: Dispatch<SetStateAction<number>>;
    getValidationKeys: (part: number) => string[];
    buildPartName: (part: number) => string;
    handleGoToPart: (
      part: number,
      stepName?: string,
      buttonText?: string
    ) => void;
  };
};

type Props<T> = {
  values: T &
    DeepPartial<Omit<MultiPartFormValues, 'handlers'>> & {
      handlers: Record<string, unknown>;
      productOptions?: {
        product?: {
          price?: number | null;
          period?: Period;
        };
        memorialGift?: {
          amount?: number | null;
        };
      };
      gtm?: GtmDetails;
      paymentMethod?: IPaymentMethod | undefined;
    };
  validation: IValidation;
  parts?: number;
  name?: string;
  validationKeys?: ValidationKeys;
};

export function useMultiPartFormState<T>({
  values,
  validation,
  ...props
}: Props<T>) {
  const [currentPart, setCurrentPart] = useState(1);
  const [partStarted, setPartStarted] = useState<PartStarted>({
    1: true
  });

  const [partValid, setPartValid] = useState<PartValid>({});

  const productId = useMemo(() => get(values, 'product.id'), [values]);

  const validationKeys = useMemo<ValidationKeys>(() => {
    if (props.validationKeys) {
      return props.validationKeys;
    }

    return omitBy<string[] | undefined>(
      values.partOptions?.validationKeys || {},
      (value) => typeof value === 'undefined'
    ) as ValidationKeys;
  }, [props.validationKeys, values.partOptions?.validationKeys]);

  const name = useMemo(
    () => `${props.name ?? values?.partOptions?.name ?? productId ?? 0}`,
    [props.name, values?.partOptions?.name, productId]
  );

  const parts = useMemo(() => props.parts ?? values.partOptions?.parts ?? 1, [
    props.parts,
    values.partOptions?.parts
  ]);

  const buildPartName = useCallback((part: number) => `part-${name}-${part}`, [
    name
  ]);

  const getElement = useCallback(
    (part: number) => document.getElementById(`${buildPartName(part)}`),
    [buildPartName]
  );

  const getValidationKeys = useCallback(
    (part: number) => validationKeys[part] || [],
    [validationKeys]
  );

  const triggerScroll = useCallback(
    (part: number) =>
      setTimeout(() => {
        const $targetElement = getElement(part);

        if (!$targetElement) return;

        const offset = getOffset($targetElement);

        if (!offset) return;

        const offsetTop = offset.top;
        const $headerContainer = document.getElementById('headerContainer');

        if (!$headerContainer) return;

        const headerHeight = $headerContainer.offsetHeight + 70;

        scrollAnimation(offsetTop - headerHeight);
      }, 50),
    [getElement]
  );

  const handleGoToPart = useCallback(
    (targetPart: number, stepName?: string, buttonText?: string): void => {
      const insideRange = targetPart <= parts && targetPart >= 0;
      const goingBackwards = targetPart < currentPart;

      if (!insideRange) return;

      if (goingBackwards) {
        pushGTMFormEvent('formChange', {
          formName: values.gtm?.formName,
          amount: values.productOptions?.memorialGift
            ? values.productOptions?.memorialGift.amount
            : values.productOptions?.product?.price,
          period: values?.productOptions?.product?.period,
          step: targetPart,
          stepName,
          paymentMethod:
            values.paymentMethod?.id === paymentMethods.klarnaPayments
              ? values.paymentMethod?.slug
              : values.paymentMethod?.name,
          buttonText
        });
        setPartValid((state) => ({
          ...state,
          [currentPart]: validation.areValid(getValidationKeys(currentPart))
        }));

        triggerScroll(targetPart);
        setCurrentPart(targetPart);
        return;
      }
      pushGTMFormEvent('formSubmit', {
        formName: values.gtm?.formName,
        amount: values.productOptions?.memorialGift
          ? values.productOptions?.memorialGift.amount
          : values.productOptions?.product?.price, // Se över
        period: values?.productOptions?.product?.period,
        step: targetPart - 1,
        stepName,
        paymentMethod:
          values.paymentMethod?.id === paymentMethods.klarnaPayments
            ? values.paymentMethod?.slug
            : values.paymentMethod?.name,
        buttonText
      });

      const partsToValidate = targetPart - 1;
      const invalidParts = [];

      for (let i = 1; i <= partsToValidate; i += 1) {
        if (!validation.areValid(getValidationKeys(i))) {
          validation.showSomeErrors(getValidationKeys(i));

          setPartValid((state) => ({
            ...state,
            [i]: false
          }));

          invalidParts.push(i);
        } else {
          setPartValid((state) => ({
            ...state,
            [i]: true
          }));
        }
      }

      if (invalidParts.length > 0) {
        triggerScroll(invalidParts[0]);
        setCurrentPart(invalidParts[0]);
        return;
      }

      setPartStarted((state) => ({
        ...state,
        [targetPart]: true
      }));

      triggerScroll(targetPart);
      setCurrentPart(targetPart);
    },
    [
      parts,
      currentPart,
      triggerScroll,
      validation,
      getValidationKeys,
      values.paymentMethod,
      values.gtm?.formName,
      values.productOptions?.product?.price,
      values?.productOptions?.product?.period,
      values?.productOptions?.memorialGift
    ]
  );

  return useMemo<MultiPartFormValues & Props<T>['values']>(
    () => ({
      ...values,
      partOptions: {
        name,
        parts,
        validationKeys,
        currentPart,
        partStarted,
        partValid
      },
      handlers: {
        ...values.handlers,
        getValidationKeys,
        setCurrentPart,
        buildPartName,
        handleGoToPart
      }
    }),
    [
      buildPartName,
      currentPart,
      getValidationKeys,
      handleGoToPart,
      name,
      partStarted,
      partValid,
      parts,
      validationKeys,
      values
    ]
  );
}

type MultiPartFormStateProps<T> = Props<T> & {
  children: (value: MultiPartFormValues & T) => ReactNode;
};

export default function MultiPartFormState<T>({
  children,
  ...props
}: MultiPartFormStateProps<T>) {
  const values = useMultiPartFormState(props);
  return useMemo(() => <>{children(values)}</>, [children, values]);
}
