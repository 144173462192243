import { RouteConfig } from '~/types/routes';

import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';

import Page from '~/App/views/Page';
import Swish from '~/App/views/Swish';
import Search from '~/App/views/Search';
import Podcasts from '~/App/views/Podcasts';
//import Newsletter from '~/App/views/Newsletter';
import ConfirmSwishPayment from '~/App/views/ConfirmSwishPayment';

import SelfTest from '~/App/views/SelfTest';
import SelfTestResult from '~/App/views/SelfTestResult';

import ResearchProject from '~/App/views/ResearchProject';

import AboutCancerfondenPost from '~/App/views/AboutCancerfonden/views/Post';

import Articles from '~/App/views/Articles';
import ArticlesPost from '~/App/views/Articles/views/Post';

import Other from '~/App/views/Others/views/Other';
import Vacancy from '~/App/views/Vacancies/views/Vacancy';

import Collections from '~/App/views/Collections';
import Collection from '~/App/views/Collection';

import Account from '~/App/views/Account';
import AccountDonations from '~/App/views/Account/views/Donations';
import AccountCollections from '~/App/views/Account/views/Collections';
import AccountCollectionEdit from '~/App/views/Account/views/Collections/views/Edit/CollectionEdit.Loader';
import AccountCollectionPause from '~/App/views/Account/views/Collections/views/Pause';
import AccountProfile from '~/App/views/Account/views/Profile';
import AccountOrders from '~/App/views/Account/views/Orders';
import AccountMemorial from '~/App/views/Account/views/MemorialPages';
import AccountMemorialEdit from '~/App/views/Account/views/MemorialPages/views/Edit/MemorialEdit.Loader';
import AccountMemorialDisabled from '~/App/views/Account/views/MemorialPages/views/Disabled';

import NewCollectionDonation from '~/App/views/SupportUs/views/NewCollectionDonation';
import SupportMember from '~/App/views/SupportUs/views/SupportMember';

import Product from '~/App/views/CmsProduct';
import ProductThankYou from '~/App/views/CmsProductThankYou';
import CmsProduct from '../views/CmsProduct';

import CommerceThankYou from '~/App/views/CommerceThankYou';
import CommerceProduct from '~/App/views/CommerceProduct';
import CommerceCheckout from '~/App/views/CommerceCheckout';

import { Block } from '~/App/views/Block';

import { MemorialPages } from '~/App/views/MemorialPages';
import { MemorialPage } from '~/App/views/MemorialPages/views/MemorialPage';
import { CreateMemory } from '~/App/views/MemorialPages/views/CreateMemory';
import { CreateMemorialPage } from '~/App/views/MemorialPages/views/CreateMemorialPage';
import TakeoverPage from '../views/Takeover/TakeoverPage';

const routes: RouteConfig[] = [
  {
    path: '/manadsgivare-nu',
    component: TakeoverPage
  },
  {
    path: '/forskning/projekt/:slug',
    component: ResearchProject
  },
  {
    path: '/om-cancerfonden/:slug',
    component: AboutCancerfondenPost
  },
  {
    path: '/minska-risken/kolla-risken/:id',
    component: featureIsEnabled('FEATURE_SELFTEST') ? SelfTest : Page
  },
  {
    path: '/minska-risken/kolla-risken',
    component: featureIsEnabled('FEATURE_SELFTEST') ? SelfTest : Page
  },
  {
    path: '/minska-risken/kolla-risken/:id/resultat',
    component: featureIsEnabled('FEATURE_SELFTEST') ? SelfTestResult : Page
  },
  {
    path: '/artiklar/:slug',
    component: ArticlesPost
  },
  {
    path: '/artiklar',
    component: Articles
  },
  {
    path: '/jobb/:slug',
    component: Vacancy
  },
  {
    path: '/ovrigt/:slug',
    component: Other
  },
  {
    path: '/swish',
    component: Swish
  },
  {
    path: '/podd',
    component: Podcasts
  },
  {
    path: '/sok',
    component: Search
  },
  {
    path: '/min-sida',
    component: Account,
    children: [
      {
        path: 'gavor',
        component: AccountDonations
      },
      {
        path: 'orderhistorik',
        component: AccountOrders
      },
      {
        path: 'insamlingar/:slug/avsluta',
        component: AccountCollectionPause
      },
      {
        path: 'insamlingar/:slug',
        component: AccountCollectionEdit
      },
      {
        path: 'insamlingar',
        component: AccountCollections
      },
      {
        path: 'minnessidor/:slug/avsluta',
        component: AccountMemorialDisabled
      },
      {
        path: 'minnessidor/:slug',
        component: AccountMemorialEdit
      },
      {
        path: 'minnessidor',
        component: AccountMemorial
      },
      {
        path: 'profil',
        component: AccountProfile
      }
    ]
  },
  {
    path: '/stod-oss/skank-till-en-insamling/tack',
    component: Page
  },
  {
    path: '/stod-oss/skank-till-en-insamling/:slug',
    component: NewCollectionDonation
  },
  {
    path: '/stod-oss/bli-stodmedlem',
    component: SupportMember
  },
  {
    path: '/stod-oss/minnessidor/skapa-minnessida',
    component: CreateMemorialPage
  },
  {
    path: '/stod-oss/minnessidor/:slug/skapa-minne',
    component: CreateMemory
  },
  {
    path: '/stod-oss/minnessidor/:slug',
    component: MemorialPage
  },
  {
    path: '/stod-oss/minnessidor',
    component: MemorialPages
  },
  {
    path: '/bekrafta-swishbetalning',
    component: ConfirmSwishPayment
  },
  {
    path: '/gavoshop/kassa/:orderId/tack',
    component: featureIsEnabled('FEATURE_COMMERCE') ? CommerceThankYou : Page
  },
  {
    path: '/gavoshop/kassa',
    component: featureIsEnabled('FEATURE_COMMERCE') ? CommerceCheckout : Page
  },
  {
    path: '/gavoshop/privat/:slug/tack',
    component: ProductThankYou
  },
  {
    path: '/gavoshop/foretag/:slug/tack',
    component: ProductThankYou
  },
  {
    path: '/gavoshop/privat/:slug',
    component: featureIsEnabled('FEATURE_COMMERCE')
      ? CommerceProduct
      : CmsProduct
  },
  {
    path: '/gavoshop/foretag/:slug',
    component: Product
  },
  {
    path: '/insamlingar/:slug',
    component: Collection
  },
  {
    path: '/insamlingar',
    component: Collections
  },
  {
    path: '/blocks/:id',
    component: Block
  },
  {
    path: '*',
    component: Page
  }
];

export default routes;
