import React, { useMemo } from 'react';

import useLoginModal from '~/App/shared/hooks/use-login-modal';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import { useAppContext } from '~/App/contexts/App';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { Loading } from '~/App/components/Login/components/Loading/Loading';

export function Spinner() {
  const status = useAuthenticationContext((x) => x.status);
  const userAgent = useAppContext((x) => x.userAgent);
  const loginModal = useLoginModal();

  const message = useMemo(() => {
    if (!status) {
      return 'Signera med Mobilt BankID';
    }

    if (isMobileBrowser(userAgent)) {
      return status === 'signing'
        ? 'Skriv in din säkerhetskod och välj Legitimera eller Skriv under.'
        : loginModal.mobileInitView
        ? 'Försöker starta BankID-appen.'
        : 'Starta BankID-appen.';
    }
    if (status === 'aborting') {
      return '';
    }

    return status === 'signing'
      ? 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera eller Skriv under.'
      : 'Starta BankID-appen.';
  }, [loginModal.mobileInitView, status, userAgent]);

  return <Loading children={message} />;
}
