import React, { ReactNode, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import queryString from '~/lib/query-string';
import { card, heading1 } from '~/App/helpers/mixins';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { mq } from '~/App/helpers/mq';

import { ScoreIndicator } from '../../ScoreIndicator';
import { useQuery } from '~/App/shared/hooks/use-query';
import { IAnswer } from '~/types/IQuestionSession';

const Wrapper = styled.div`
  ${card};
  margin: 0 auto 2rem;
  max-width: 75rem;
  box-shadow: 0 0 50px 0 rgba(207, 207, 207, 0.5);
  border-radius: 1rem;
  break-inside: avoid;

  ${mq('≥small')`
    margin-bottom: auto;
  `};
`;

const Heading = styled.div`
  ${heading1};
  font-size: 2rem;
  margin-bottom: 1rem;
  line-height: 1.3;

  ${mq('≥small')`
    font-size: 2.8rem;
  `};
`;

const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 2rem auto 1.25rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  font-size: 0.875rem;
  min-width: auto;
`;

type Query = {
  questions?: number[] | number;
};

type Props = {
  title: string;
  content: ReactNode;
  more: ReactNode;
  value: IAnswer;
};

export function Card({ title, more, content, value }: Props) {
  const query = useQuery<Query>();
  const navigate = useNavigate();
  const location = useLocation();

  const questions = useMemo(() => {
    if (!query.questions) {
      return [];
    }

    if (typeof query.questions === 'number') {
      return [query.questions];
    }

    return query.questions;
  }, [query.questions]);

  const isOpen = useMemo(
    () => !!questions.find((x) => `${x}` === `${value?.questionId}`),
    [questions, value?.questionId]
  );

  const nextQuestions = useMemo(
    () =>
      isOpen
        ? questions.filter((x) => `${x}` !== `${value?.questionId}`)
        : [...questions, value.questionId],
    [isOpen, questions, value.questionId]
  );

  const toggleOpen = useCallback(() => {
    const params = queryString.stringify({
      ...query,
      questions: nextQuestions
    });

    navigate(`${location.pathname}?${params}`);
  }, [navigate, location.pathname, nextQuestions, query]);

  return (
    <Wrapper>
      <Heading children={title} />
      {content}
      {isOpen && more}
      <Line />
      <Footer>
        <ScoreIndicator value={value.value} />
        <StyledPrimaryButton
          onClick={toggleOpen}
          children={isOpen ? '- Visa mindre' : '+ Jag vill ha tips'}
        />
      </Footer>
    </Wrapper>
  );
}
