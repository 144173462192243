import React, { ReactNode } from 'react';
import { AbContext, AbContextValue } from './AbContext';

type Props = {
  children: ReactNode;
  value: AbContextValue;
};

export function AbProvider({ children, value }: Props) {
  return <AbContext.Provider value={value} children={children} />;
}
