import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { arrowLink, card } from '~/App/helpers/mixins';

import { DraggableModal } from '~/App/shared/components/Elements/Modal';
import { Share } from './components/Share';

import { NavLink } from 'react-router-dom';
import { ctaButton } from '~/App/helpers/mixins';
import { ICollectionActivity } from '~/types/ICollectionActivity';
import { MemoryPeriodImage } from '../../components/MemoryPeriodImage';
import { mq } from '~/lib/mq';
import { LikeButton } from '../MemorialPage/components/MemoryCard/components/LikeButton';
import { Likes } from '../MemorialPage/components/MemoryCard/components/Likes';
import { useReadMore } from '~/App/shared/hooks/use-read-more';
import { ICollection } from '~/types/ICollection';
import { useWindowSize } from '~/App/shared/hooks/use-window-size';
import mediaHelper from '~/App/helpers/media-helper';
import dayjs from 'dayjs';

const Card = styled.div`
  ${card};
  overflow: hidden;
  box-shadow: none;
  padding: 2.5rem 6.25rem 0 6.25rem !important;

  ${mq('<medium')`
  padding: 1rem 0 0 0 !important;
  `};
`;

const Content = styled.div`
  padding-bottom: 1rem;
  padding-top: 1.5rem;

  ${mq('<medium')`
  margin: 0 1rem 0 1rem;
  `};
`;

const StyledDraggableModal = styled(DraggableModal)`
  padding: 0;
`;

type ImageProps = {
  url: string;
};

const Image = styled.div<ImageProps>`
  background-image: url('${({ url }) => url}');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100%;
  position: relative;
`;

const StyledMemoryPeriodImage = styled(MemoryPeriodImage)`
  width: 8.25rem;
  height: 8.25rem;
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
`;

const Sender = styled.p`
  font-weight: bold;
  font-size: 1.375rem;
`;

const Blue = styled.p`
  font-weight: bold;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 0 0 0.5rem;
`;

const Hr = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.lightDust};
  margin: 1rem 0;
`;

const Preamble = styled.p`
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 1rem;
  overflow: hidden;
  font-size: 1rem;

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.a`
  ${arrowLink}
  margin-left: auto;
  position: relative;
  margin-bottom: 1.25rem;
  padding-bottom: 0.625rem;
  color: ${(props) => props.theme.colors.darkBlue};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #001489;
  height: 165px;
  text-align: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2.75rem;
`;

const Link = styled(NavLink)`
  ${ctaButton};
  max-width: 1rem;
`;

const FooterHeading = styled.h2`
  font-size: 1.375rem;
  color: white;
  margin-bottom: 1.25rem;
  font-weight: bold;
  line-height: 1.23;

  ${mq('<medium')`
    font-size: 1.125rem;
  `};
`;

const StyledDate = styled.p`
  font-size: 0.85rem;
  font-weight: bold;
`;

const StyledShare = styled(Share)`
  div:first-child {
    color: #001489;
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

type Props = {
  closeModal: () => void;
  memory: ICollectionActivity;
  collection: ICollection;
};

export function MemoryModal({ collection, closeModal, memory }: Props) {
  const windowSize = useWindowSize();
  const [likes, setLikes] = useState(memory.likes);

  const { button, ref } = useReadMore({
    maximumRows: 5
  });

  const createMemoryDate = collection.createdAt
    ? dayjs(collection.createdAt)
    : null;
  const createMemoryDateFormatted = createMemoryDate?.format('D MMM YYYY');

  const imageUrl = useMemo(
    () =>
      mediaHelper.getUrl(memory.imageUrl, {
        width: 400,
        height: 400
      }),
    [memory]
  );

  return (
    <StyledDraggableModal
      maxWidth={'48rem'}
      memory={memory}
      closeModal={closeModal}
    >
      <Card>
        <Row>
          <CloseButton onClick={closeModal}>
            Till {memory.collection.name}s minnessida
          </CloseButton>
        </Row>
        <Image
          url={imageUrl}
          children={
            memory.period ? (
              <StyledMemoryPeriodImage period={memory.period} />
            ) : null
          }
        />
        <Content>
          <Sender children={`Av ${memory.user.by}`} />
          {memory.period === 'Once' ? (
            <Blue children="Har skänkt en gåva" />
          ) : null}
          {memory.period === 'Month' ? (
            <Blue children="Ger varje månad" />
          ) : null}
          <Preamble children={memory.content} ref={ref} />
          <ButtonWrapper>{button}</ButtonWrapper>
          <StyledDate children={createMemoryDateFormatted} />
          <Row>
            <Likes likes={likes} />
            {windowSize.width && windowSize.width <= 769 && (
              <LikeButton collectionActivityId={memory.id} onLike={setLikes} />
            )}
          </Row>
          <Hr />
          <Row>
            {windowSize.width && windowSize.width > 769 && (
              <LikeButton collectionActivityId={memory.id} onLike={setLikes} />
            )}
            <StyledShare customText="Dela minnet" memory={memory} />
          </Row>
        </Content>
      </Card>
      <Footer>
        <FooterHeading
          children={`Var med och minns ${memory.collection.name}`}
        />
        <Link
          to={`/stod-oss/minnessidor/${collection.slug}/skapa-minne`}
          children="Skriv in ditt minne"
        />
      </Footer>
    </StyledDraggableModal>
  );
}
