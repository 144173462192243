import styled from 'styled-components';

type BoldProps = {
  margin?: boolean;
};

export const Bold = styled.p<BoldProps>`
  font-weight: bold;
  margin-top: ${({ margin = true }) => (margin ? '1rem' : '0rem')};
  margin-right: 0.5rem;
  white-space: pre-wrap;
`;

export const Row = styled.div`
  display: flex;
`;

export { CertificateSummery } from './CertificateSummery';
export { PaymentSummery } from './PaymentSummery';
