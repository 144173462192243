import React from 'react';
import styled from 'styled-components';
import { formatMoney } from 'accounting';

// Helpers
import mq from '~/App/helpers/mq';
import { heading3 } from '~/App/helpers/mixins';

// Shared components
import { Divider } from '~/App/shared/components/Blocks';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import { RightColumnText } from '~/App/shared/components/Donation/components/Forms/components/RightColumnText';

const NewFormHeading = styled(FormHeading)`
  ${mq('<medium')`
    display: none;
  `};
`;

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem 0 1rem;
`;

const Heading4 = styled.h4`
  ${heading3};
`;

const Description = styled.p`
  ${mq('≥medium')`
    max-width: 180px;
  `};
`;

export const IncreaseMonthlySummary = function (props) {
  const { price, infoText = '', heading = '' } = props;

  return (
    <React.Fragment>
      {heading && <NewFormHeading>{heading}</NewFormHeading>}
      <AmountWrapper>
        <Description>Ditt månadsbelopp höjs med</Description>
        <Heading4>{formatMoney(price, 'kr', 0, ' ', null, '%v %s')}</Heading4>
      </AmountWrapper>
      <Divider
        width="narrow"
        paddingLeft="none"
        paddingRight="none"
        height="2px"
        alternativeColor={true}
      />
      <RightColumnText icon="info">{infoText}</RightColumnText>
    </React.Fragment>
  );
};
