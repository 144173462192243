import React, { ReactNode, useCallback, useMemo } from 'react';
import { every, some } from 'lodash';

import { NextPartButton } from '~/App/shared/components/Donation/components/Forms/components/NextPartButton';
import { IValidation } from '../../Validation';
import { MultiPartFormValues } from '..';
import { Content } from './components/Content';
import { ButtonStyle } from '~/App/config/buttonStyles';

type Props = {
  part: number;
  values: MultiPartFormValues;
  validation: IValidation;
  heading: ReactNode;
  subHeading?: ReactNode;
  children: ReactNode;
  summery: ReactNode;
  nextStepButton?: {
    text?: string;
    style?: ButtonStyle;
  };
  handleGoToPart?: any;
};

export function BlueSection({
  part,
  heading,
  subHeading,
  summery,
  values,
  validation,
  nextStepButton,
  children,
  handleGoToPart
}: Props) {
  const isActive = useMemo(() => values.partOptions?.currentPart === part, [
    part,
    values.partOptions?.currentPart
  ]);

  const isLast = useMemo(() => values.partOptions?.parts === part, [
    part,
    values.partOptions?.parts
  ]);

  const validationKeys = useMemo(
    () => values.partOptions.validationKeys[part] || [],
    [part, values.partOptions.validationKeys]
  );

  const isValid = useMemo(() => every(validationKeys, validation.isValid), [
    validation.isValid,
    validationKeys
  ]);

  const isInvalid = useMemo(() => some(validationKeys, validation.isInvalid), [
    validation.isInvalid,
    validationKeys
  ]);

  const isStarted = useMemo(
    () => values.partOptions.partStarted[part] || false,
    [part, values.partOptions.partStarted]
  );

  const id = useMemo(() => values.handlers.buildPartName(part), [
    part,
    values.handlers
  ]);

  const handleEdit = useCallback(() => {
    values.handlers.handleGoToPart(part, heading?.toString(), 'Ändra');
  }, [part, values.handlers, heading]);

  if (!values?.partOptions) {
    throw 'Must be inside of state';
  }

  return useMemo(
    () => (
      <>
        <Content
          id={id}
          part={part}
          isActive={isActive}
          isValid={isValid}
          isInvalid={isInvalid}
          isStarted={isStarted}
          heading={heading}
          subHeading={subHeading}
          summery={summery}
          children={children}
          onEdit={handleEdit}
        />
        {isLast ? null : (
          <NextPartButton
            part={part}
            values={values}
            validation={validation}
            text={nextStepButton?.text}
            buttonStyle={nextStepButton?.style}
            handleGoToPart={handleGoToPart}
            stepName={heading?.toString()}
          />
        )}
      </>
    ),
    [
      id,
      children,
      handleEdit,
      heading,
      isActive,
      isInvalid,
      isLast,
      isStarted,
      isValid,
      part,
      subHeading,
      nextStepButton,
      summery,
      validation,
      values,
      handleGoToPart
    ]
  );
}
