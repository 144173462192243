import React, { Fragment } from 'react';
import Text from '~/App/shared/components/Fields/Text';

import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';

import { InputWrapper } from '../components/InputWrapper';

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
  disabled?: boolean;
  required?: boolean;
};

export function Name({
  values,
  validation,
  disabled = false,
  required = true
}: Props) {
  return (
    <Fragment>
      <InputWrapper percentageWidth="50">
        <Text
          name="givenName"
          value={values.productOptions.customerContact.firstName}
          onBlur={validation.showError.bind(
            undefined,
            'productOptions.customerContact.firstName'
          )}
          errors={validation.errors['productOptions.customerContact.firstName']}
          isValid={validation.isValid(
            'productOptions.customerContact.firstName'
          )}
          onChange={values.handlers.handleCustomerContactFirstName}
          isInvalid={validation.isInvalid(
            'productOptions.customerContact.firstName'
          )}
          maxLength="50"
          placeholder="Förnamn"
          autoComplete="given-name"
          isDisabled={disabled}
          required={required}
        />
      </InputWrapper>
      <InputWrapper percentageWidth="50">
        <Text
          name="familyName"
          value={values.productOptions.customerContact.lastName}
          onBlur={validation.showError.bind(
            undefined,
            'productOptions.customerContact.lastName'
          )}
          errors={validation.errors['productOptions.customerContact.lastName']}
          isValid={validation.isValid(
            'productOptions.customerContact.lastName'
          )}
          onChange={values.handlers.handleCustomerContactLastName}
          isInvalid={validation.isInvalid(
            'productOptions.customerContact.lastName'
          )}
          maxLength="50"
          placeholder="Efternamn"
          autoComplete="family-name"
          isDisabled={disabled}
          required={required}
        />
      </InputWrapper>
    </Fragment>
  );
}
