import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';

const rowHeight = 140;

const ProductWrapper = styled.li`
  width: ${(props) => (props.selected ? (100 / 3) * 2 : (100 / 3) * 1)}%;
  height: ${(props) => (props.selected ? rowHeight * 3 : rowHeight)}px;
  padding: 5px;

  ${mq('≥small')`
    width: ${(props) => (props.selected ? (100 / 6) * 2 : (100 / 6) * 1)}%;
  `};
`;

const StyledProduct = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1.5em;
  overflow: hidden;

  background-color: ${(props) => (props.selected ? '#FEF6F8' : 'transparent')};
  border-radius: ${(props) => (props.selected ? '5px' : '3px')};
  box-shadow: ${(props) => (props.selected ? '0px 2px 4px 0px #e0e0e0;' : '0')};

  &:hover {
    ${(props) => !props.selected && 'cursor: pointer'};
    ${(props) => !props.selected && 'background-color: #f5f2f2'};
  }
`;

const ProductCloseButton = styled.button`
  position: absolute;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;

  align-self: flex-end;
  width: 1em;

  img {
    width: 20px;
  }
`;

const ImageWrapper = styled.div`
  min-height: 0;
  max-height: ${(props) => (props.selected ? 66 : 100)}%;
  flex-basis: ${(props) => (props.selected ? 66 : 100)}%;
  padding: ${(props) => (props.selected ? 2 : 0)}em;
`;

const InfoWrapper = styled.div`
  max-height: 33%;
  flex-basis: 33%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  font-size: 0.938em;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ProductName = styled.h3`
  color: ${({ theme }) => theme.colors.pink};
  font-family: 'CancerfondenSans', sans-serif;
  font-size: 1.125em;
  font-weight: 700;
`;

const calculateSourceSet = (url) => {
  return [
    `${mediaHelper.getUrl(url, { width: 600, effect: 'trim' })} 2x`,
    `${mediaHelper.getUrl(url, { width: 300, effect: 'trim' })} 1x`
  ].join(', ');
};

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      big: false
    };
  }

  handleClick() {
    this.props.handleClick(this.props.product.name);
  }

  handleClose(event) {
    event.stopPropagation();
    this.props.handleClose();
  }

  render() {
    const { product = {} } = this.props;

    return (
      <ProductWrapper selected={this.props.selected}>
        <StyledProduct
          onClick={this.handleClick}
          selected={this.props.selected}
        >
          {this.props.selected && (
            <ProductCloseButton onClick={this.handleClose}>
              <img src="/images/rosabandet/icon-close-pink.png" />
            </ProductCloseButton>
          )}
          <ImageWrapper selected={this.props.selected}>
            <picture>
              <source srcSet={calculateSourceSet(product.image)} />
              <ProductImage
                src={mediaHelper.getUrl(product.image, { width: 300 })}
                alt={product.name}
              />
            </picture>
          </ImageWrapper>
          {this.props.selected && (
            <InfoWrapper>
              <ProductName>{product.name}</ProductName>
              <p>{product.description}</p>
            </InfoWrapper>
          )}
        </StyledProduct>
      </ProductWrapper>
    );
  }
}

export default Product;
