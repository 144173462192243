import { fetch } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';
import { LoadDataProps } from '~/types/routes';

export const loadData = async ({ params }: LoadDataProps<'slug'>) => {
  const tasks = [
    fetch({
      url: contentApi(`/v1/editorial-items/about-cancerfonden/${params.slug}`)
    }),
    fetch({
      url: contentApi('/v1/content-definitions/global-news-feed')
    })
  ];

  const [editorialItem, newsFeed] = await Promise.all(tasks);

  return {
    data: {
      newsFeed: newsFeed.data.contentDefinition,
      editorialItem: editorialItem.data.editorialItem
    }
  };
};
