import React from 'react';

// Helpers
import { partIsClickable as helperPartIsClickable } from '~/App/helpers/multipartHelper';

// Shared components
import { NewFormHeading } from './FormTypography';

export const FormHeading = (props) => {
  const { part, values, children } = props;

  const partIsClickable = helperPartIsClickable(
    values.partOptions.currentPart,
    values.partOptions.partStarted
  );

  return (
    <NewFormHeading
      isOpen={values.partOptions.currentPart === part}
      isValid={values.partOptions.partValid[part]}
      isStarted={values.partOptions.partStarted[part]}
      isLink={partIsClickable(part)}
      children={children}
    />
  );
};
