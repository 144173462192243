import React, { Fragment, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { ICollection } from '~/types/ICollection';
import { IMeta } from '~/types/IMeta';

// Helpers
import { siteUrl } from '~/App/helpers/env';
import { assemble } from '~/lib/slug-helper';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';

// Shared components
import Container from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

// Local components
import {
  Gifts,
  TopSection,
  PausedCollectionMessage,
  RelatedCollections,
  Footer
} from './components';

import { useGifts } from './hooks/use-gifts';
import { useAdmins } from './hooks/use-admins';
import { useAppContext } from '~/App/contexts/App';
import { useBreakpoint } from '~/App/shared/hooks/use-breakpoint';

type Props = {
  data: ICollection & { meta: IMeta };
};

export function Collection({ data: { meta, ...data } }: Props) {
  const gifts = useGifts(data.slug);
  const admins = useAdmins(data.slug);
  const userAgent = useAppContext((x) => x.userAgent);
  const isDesktop = useBreakpoint('≥small', !isMobileBrowser(userAgent));

  useEffect(() => {
    gifts.fetch();
    admins.fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const defaultDescription =
    'Jag har startat en insamling till förmån för Cancerfonden. Alla bidrag är viktiga!';

  return (
    <Fragment>
      <Helmet>
        <title>{meta?.ogTitle}</title>
        <link
          rel="canonical"
          href={siteUrl(assemble(`/insamlingar/${data.slug}`))}
        />
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content={meta?.ogDescription || defaultDescription}
        />
        {meta?.ogTitle && (
          <meta
            property="og:title"
            content={`Stötta insamlingen ${meta.ogTitle}`}
          />
        )}
        {meta?.ogImage && <meta property="og:image" content={meta.ogImage} />}
        <meta
          property="og:description"
          content={meta?.ogDescription || defaultDescription}
        />
      </Helmet>
      <Container width="limited">
        <TopSection collection={data} admins={admins.items} />
      </Container>
      {(gifts.items.length > 0 ||
        !data.isPaused ||
        (data.isPaused && data.pausedMessage)) && (
        <BlockBackground backgroundColor="primary">
          <Container width="limited" paddingTop="small" paddingBottom="small">
            {data.isPaused && data.pausedMessage && (
              <PausedCollectionMessage
                collection={data}
                hasGifts={gifts.items.length > 0}
              />
            )}
            <Gifts collection={data} gifts={gifts} isDesktop={isDesktop} />
          </Container>
        </BlockBackground>
      )}
      <RelatedCollections />
      {!isDesktop && <Footer collection={data} />}
    </Fragment>
  );
}
