import React, { useCallback } from 'react';
import styled from 'styled-components';

import { IScreen } from '../../../SelfTest/types';
import { IAnswer } from '~/types/IQuestionSession';

import { useScore } from '../../hooks/useScore';
import { heading1 } from '~/App/helpers/mixins';
import { Header } from '../../../SelfTest/components/Header';
import { mq } from '~/App/helpers/mq';

import { BouncingButton } from './components/BouncingButton';
import { Background } from './components/Background';
import { Result } from './components/Result';

const StyledHeader = styled(Header)`
  .share-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    background-color: ${({ theme }) => theme.colors.darkApple};
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 15;
    box-shadow: 0px 0px 10px 0px #0d0d0d7a;

    ${mq('≥small')`
      width: 5rem;
      height: 5rem;
    `};

    &:after {
      margin-left: 0;
      width: 1.8rem;
      height: 1.8rem;

      ${mq('≥small')`
        width: 2.5rem;
        height: 2.5rem;
      `};
    }
  }
`;

const Wrapper = styled.div`
  max-width: 54.875rem;
  width: 100%;
  padding: 2rem 1rem 3rem;
  margin: auto;
  z-index: 1;
`;

const Heading = styled.h1`
  ${heading1};
`;

const Container = styled.div`
  background: rgba(255, 255, 255, 1);
  width: 100%;
  padding: 1.5rem 2.5rem;
  border-radius: 1rem;
  color: black;
  margin-top: 1.5rem;

  ${mq('≥small')`
     padding: 2.5rem;
  `};
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

const SmallText = styled.p`
  font-size: 0.75rem;
`;

const Text = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
`;

type Props = {
  screens: IScreen[];
  answers: IAnswer[];
  image: string;
};

export function Hero({ screens, answers, image }: Props) {
  const { percent } = useScore({
    screens,
    answers
  });

  const scrollToResult = useCallback(
    () =>
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: window.innerHeight
      }),
    []
  );

  return (
    <Background image={image}>
      <StyledHeader />
      <Wrapper>
        <Heading>Här är ditt resultat:</Heading>
        <Container>
          <SpaceBetween>
            <SmallText>Högre risk</SmallText>
            <SmallText>Lägre risk</SmallText>
          </SpaceBetween>
          <Result percent={percent} />
          <Text>Se ditt resultat fråga för fråga</Text>
          <BouncingButton onClick={scrollToResult} />
        </Container>
      </Wrapper>
    </Background>
  );
}
