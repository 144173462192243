import React, { ReactNode, useMemo } from 'react';

import { paymentMethods } from '~/App/config/paymentMethods';

import { FavourablePaymentMethodSelector } from '../../components/Forms/components/FavourablePaymentMethodSelector';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '../../components/States';
import { IValidation } from '../../../Validation';

type Props = {
  values: ContactCustomerValues & PaymentValues & ProductValues;
  validation: IValidation;
  submitButtons: ReactNode;
  formContent: {
    taxReductionInfoLink?: string;
  };
};

export function Payment({
  values,
  formContent,
  validation,
  submitButtons
}: Props) {
  const methods = useMemo(() => {
    return [
      {
        id: paymentMethods.swish,
        name: 'Swish',
        favourable: true
      },
      {
        id: paymentMethods.klarnaPayments,
        name: 'Konto eller kort',
        slug: 'klarna',
        favourable: true
      },
      {
        id: paymentMethods.paymentSlip,
        name: 'Inbetalningskort',
        favourable: false,
        error:
          'Väljer du Swish eller Klarna går mer av din gåva till forskningen. Detta betalsätt innebär en högre administrativ kostnad för Cancerfonden.'
      }
    ];
  }, []);

  return (
    <FavourablePaymentMethodSelector
      methods={methods}
      formContent={formContent}
      values={values}
      validation={validation}
      children={submitButtons}
    />
  );
}
