import styled, { DefaultTheme, css } from "styled-components";
import mq from "~/App/helpers/mq";

export type NavTextProps = {
    highlighted?: boolean;
    active?: boolean;
    theme: DefaultTheme;
}

export const NavText = styled.span<NavTextProps>`
      display: ${(props) => (props.highlighted ? 'block' : 'inline-block')};
      vertical-align: middle;
      position: relative;
    
      ${mq('<mediumLarge')`
        &::after {
          content: '';
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          display: block;
          width: 100%;
          height: 4px;
          background: ${({ theme }: NavTextProps) => theme.themeColors.primary};
          visibility: hidden;
        }
    
        ${(props: NavTextProps) =>
            props.active &&
            css`
            &::after {
              visibility: visible;
            }
          `};
      `};
    
      ${mq('≥mediumLarge')`
        padding-top: 0.25rem;
      `};
    `;