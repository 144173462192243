import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Item } from './components/Item';

const items = [
  {
    text: 'Gåvor',
    url: '/min-sida/gavor'
  },
  {
    text: 'Orderhistorik',
    url: '/min-sida/orderhistorik'
  },
  {
    text: 'Insamlingar',
    url: '/min-sida/insamlingar'
  },
  {
    text: 'Minnessidor',
    url: '/min-sida/minnessidor'
  }
];

const Wrapper = styled.div``;

export function Navigation() {
  const renderItem = useCallback(
    (item, index) => <Item {...item} key={index} />,
    []
  );

  return <Wrapper children={items.map(renderItem)} />;
}
