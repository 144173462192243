import React, { ReactNode, useContext } from 'react';

// config
import { FeatureToggleContext } from './FeatureToggleContext';

type Props = {
  children: (enabled: boolean) => ReactNode;
  feature: string;
};

export function FeatureToggle({ children, feature }: Props) {
  return (
    <FeatureToggleContext.Consumer>
      {(FeatureToggles) => children(FeatureToggles[feature])}
    </FeatureToggleContext.Consumer>
  );
}

export function useFeatureToggle(key: string) {
  const values = useContext(FeatureToggleContext);

  return values[key] || false;
}
