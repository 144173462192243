import React from 'react';

// Shared components
import Container from '~/App/shared/components/Container';
import { CancerlinjenForm } from '~/App/shared/components/CancerlinjenForm';

class CancerlinjenFormBlock extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <Container
        width="tight"
        paddingLeft="small"
        paddingRight="small"
        paddingTop="small"
        paddingBottom="small"
      >
        <CancerlinjenForm data={data} />
      </Container>
    );
  }
}

export default CancerlinjenFormBlock;
