export const parts = 2;

export const validationKeys = {
  1: ['productOptions.product.price', 'product.id'],
  2: [
    'mobileBankId.bank',
    'mobileBankId.accountNumber',
    'mobileBankId.clearingNumber',

    'productOptions.customerContact.email',
    'productOptions.customerContact.ssn',
    'productOptions.customerContact.firstName',
    'productOptions.customerContact.lastName',
    'productOptions.customerContact.phoneNumber',
    'productOptions.customerContact.addressStreet',
    'productOptions.customerContact.addressZip',
    'productOptions.customerContact.addressCity',
    'productOptions.customerContact.cid',
    'productOptions.customerContact.company',
    'swishPhoneNumber',
    'stripeCard'
  ]
};
