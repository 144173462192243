import { css } from 'styled-components';

const icon = css`
  position: relative;
  speak: none;
  font-family: 'IcoMoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const wallet = css`
  ${icon};
  content: '\\e917';
`;

const menu = css`
  ${icon};
  content: '\\e120';
`;

const mail = css`
  ${icon};
  content: '\\e002';
`;

const paper = css`
  ${icon};
  content: '\\e034';
`;

const image = css`
  ${icon};
  content: '\\e010';
`;

const print = css`
  ${icon};
  content: '\\e900';
`;

const heart = css`
  ${icon};
  content: '\\e024';
`;

const info = css`
  ${icon};
  content: '\\e609';
`;

const search = css`
  ${icon};
  content: '\\e036';
`;

const twitter = css`
  ${icon};
  content: '\\e603';
`;

const facebook = css`
  ${icon};
  content: '\\e601';
`;

const instagram = css`
  ${icon};
  content: '\\e600';
`;

const youtube = css`
  ${icon};
  content: '\\e604';
`;

const angleUp = css`
  ${icon};
  content: '\\f106';
`;

const angleDown = css`
  ${icon};
  content: '\\f107';
`;

const caretLeft = css`
  ${icon};
  content: '\\f104';
`;

const caretRight = css`
  ${icon};
  content: '\\f105';
`;

const externalLinkIcon = css`
  ${icon};
  content: '\\e901';
`;

const downloadLinkIcon = css`
  ${icon};
  content: '\\e902';
`;

const check = css`
  ${icon};
  content: '\\e116';
`;

const close = css`
  ${icon};
  content: '\\e117';
`;

const cog = css`
  ${icon};
  content: '\\e023';
`;

const share = css`
  ${icon};
  content: '\\e903';
`;

const more = css`
  ${icon};
  content: '\\e904';
`;

const plusCircle = css`
  ${icon};
  content: '\\e905';
`;

const warningCircle = css`
  ${icon};
  content: '\\e906';
`;

const errorCircle = css`
  ${icon};
  content: '\\e907';
`;

const link = css`
  ${icon};
  content: '\\e9cb';
`;

const profile = css`
  ${icon};
  content: '\\e909';
`;

const doubleRight = css`
  ${icon};
  content: '\\e90a';
`;

const camera = css`
  ${icon};
  content: '\\e90b';
`;

const bell = css`
  ${icon};
  content: '\\e90c';
`;

const collections = css`
  ${icon};
  content: '\\e90f';
`;

const donations = css`
  ${icon};
  content: '\\e90d';
`;

const rosabandet = css`
  ${icon};
  content: '\\e90e';
`;

const thumbsUp = css`
  ${icon};
  content: '\\e910';
`;

const giftIncrease = css`
  ${icon};
  content: '\\e911';
`;

const shoppingCart = css`
  ${icon};
  content: '\\e912';
`;

const orders = css`
  ${icon};
  content: '\\e916';
`;

const uiProfile = css`
  ${icon};
  content: '\\e913';
`;

const uiLogout = css`
  ${icon};
  content: '\\e914';
`;

const trashCan = css`
  ${icon};
  content: '\\e915';
`;

const bank = css`
  ${icon};
  content: '\\e908';
`;

const linkedIn = css`
  ${icon};
  content: '\\e918';
`;

const x = css`
  ${icon};
  content: '\\e919';
`;

export {
  wallet,
  bank,
  menu,
  mail,
  paper,
  image,
  print,
  heart,
  info,
  search,
  twitter,
  facebook,
  instagram,
  youtube,
  angleUp,
  angleDown,
  caretLeft,
  caretRight,
  externalLinkIcon,
  downloadLinkIcon,
  check,
  close,
  cog,
  share,
  more,
  plusCircle,
  warningCircle,
  errorCircle,
  link,
  profile,
  doubleRight,
  camera,
  bell,
  collections,
  donations,
  rosabandet,
  thumbsUp,
  giftIncrease,
  shoppingCart,
  uiProfile,
  uiLogout,
  trashCan,
  orders,
  linkedIn,
  x
};
