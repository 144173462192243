import React, { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';
import { Message } from './Components';

const Wrapper = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
`;

const InputContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75rem;
`;

type InputIconProps = {
  isInvalid: boolean;
};

const InputIcon = styled.div<InputIconProps>`
  position: absolute;
  top: 0.125rem;
  width: 1.125rem;
  height: 1.125rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isInvalid, theme }) =>
    isInvalid ? theme.colors.errorRed : theme.colors.dust};
  border-radius: 0.125rem;
  background: ${({ isInvalid, theme }) =>
    isInvalid ? theme.colors.lightRed : theme.colors.grayAlabaster};
  transition: border-color 0.15s ease, background 0.15s ease;

  &::after {
    display: block;
    content: ' ';
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background: transparent;
    transition: background 0.3s ease;
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${InputIcon} {
    border-color: ${(props) => props.theme.colors.gray};
    background: ${(props) => props.theme.colors.white};

    &::after {
      background: ${(props) => props.theme.themeColors.primary};
    }
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  font-size: 0.875rem;
`;

type Props = {
  name: string;
  isInvalid: boolean;
  errors?: string[];
  children?: ReactNode;
} & ComponentProps<typeof Input>;

export default function Checkbox({
  name,
  isInvalid,
  errors = [],
  children,
  ...props
}: Props) {
  return (
    <Wrapper htmlFor={name}>
      <InputContainer>
        <Input type="checkbox" name={name} id={name} {...props} />
        <InputIcon isInvalid={isInvalid} />
      </InputContainer>
      <TextContainer>
        {children}
        {errors?.length > 0 ? (
          <Message isInvalid={isInvalid}>{errors[0]}</Message>
        ) : null}
      </TextContainer>
    </Wrapper>
  );
}
