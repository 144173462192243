import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { link, heading3 } from '~/App/helpers/mixins';

const Wrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.charcoal};
  margin: 0;
  padding: 1.5rem 1rem 2rem;

  ${mq('≥small')`
    padding: 3.375rem 1rem 3.875rem;
  `};
`;

const Content = styled.div`
  max-width: 48rem;
  margin: 0 auto;
`;

const Heading = styled.h4`
  ${heading3};
  margin: 0 0 0.5rem 0;
`;

const Link = styled.a`
  ${link};
  text-decoration: underline;
  white-space: nowrap;
`;

export function Footer() {
  return (
    <Wrapper>
      <Content>
        <Heading>Har du frågor?</Heading>
        <p>
          Kontakta vår givarservice på telefon{' '}
          <Link href="tel:+46101991010">010-199 10 10</Link> eller e-post{' '}
          <Link href="mailto:info@cancerfonden.se">info@cancerfonden.se</Link>.
        </p>
      </Content>
    </Wrapper>
  );
}
