import React from 'react';
import styled from 'styled-components';

// Helpers
import { siteUrl } from '~/App/helpers/env';
import { heading2, preamble } from '~/App/helpers/mixins';
import { LargeShareButtons } from '~/App/shared/components/LargeShareButtons/LargeShareButtons';

// Shared components
import { Content, Wrapper } from '~/App/shared/components/ThanksPage';

const Heading = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 1rem;
  white-space: pre-line;
`;

const WrappedWrapper = styled(Wrapper)`
  margin-top: 1.5rem;
`;

const WrappedContent = styled(Content)`
  padding: 4rem 0;
`;

export function Share() {
  return (
    <WrappedWrapper>
      <WrappedContent>
        <Heading>Tipsa andra</Heading>
        <Preamble>
          Hjälp oss bli ännu fler som stöttar bästa cancerforskningen - tipsa
          gärna dina vänner och bekanta om gåvoshopen.
        </Preamble>
        <LargeShareButtons
          to={siteUrl('/gavoshop/privat')}
          email={{
            subject: 'Jag har gjort en livsviktig sak!',
            body:
              'Jag har köpt en livsviktig produkt i Cancerfondens gåvoshop. Gör det du också. \n\n https://www.cancerfonden.se/gavoshop/privat'
          }}
          x={{
            text:
              'Jag har köpt en livsviktig produkt i Cancerfondens gåvoshop. Gör det du också.'
          }}
          facebook={true}
        />
      </WrappedContent>
    </WrappedWrapper>
  );
}
