import { useMemo } from 'react';
import { To, useNavigate } from 'react-router-dom';

// This should NOT be used and refactored to new useNavigate hook instead
export function useHistory() {
  const navigate = useNavigate();

  return useMemo(
    () => ({
      push: navigate,
      replace: (url: To) => navigate(url, { replace: true })
    }),
    [navigate]
  );
}
