import React, { useMemo, useCallback, ChangeEvent, FocusEvent } from 'react';
import styled, { css } from 'styled-components';
import { angleDown, bank } from '~/App/helpers/icons';

const OuterWrapper = styled.div`
  margin-top: 0;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

type InnerWrapperProps = {
  isBank: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
};

const InnerWrapper = styled.div<InnerWrapperProps>`
  position: relative;
  width: 100%;
  height: 46px;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  border-color: ${({ isValid, isInvalid, theme }) => {
    if (isValid) return theme.colors.apple;
    if (isInvalid) return theme.colors.errorRed;

    return theme.colors.lightDust;
  }};
  padding-right: 2.5rem;
  overflow: hidden;
  background-color: ${({ theme, isInvalid }) => {
    if (isInvalid) return theme.colors.lightRed;

    return theme.colors.white;
  }};

  ${({ isBank }) =>
    isBank &&
    css`
      &:after {
        ${bank};
        position: absolute;
        font-size: 1.375em;
        top: 0.5625rem;
        left: 0.75rem;
        z-index: 1;
        pointer-events: none;
      }
    `};

  &:before {
    ${angleDown};
    position: absolute;
    font-size: 1.25em;
    top: 50%;
    right: 0.75em;
    z-index: 1;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

type StyledSelectProps = {
  isBank: boolean;
};

const StyledSelect = styled.select<StyledSelectProps>`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  font-size: 1em;
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  padding-left: ${({ isBank }) => isBank && '2.5rem'};
`;

const Message = styled.div`
  font-size: 0.875em;
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 0.325rem;
`;

type Value = string | number;

type Option = {
  value: Value;
  label: string;
  disabled?: boolean;
};

type Props = {
  value?: Value;
  defaultValue?: Value;
  errors?: string[];
  options?: Option[];
  isValid?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  bankIcon?: boolean;
  name?: string;
  className?: string;
  required?: boolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
};

export default function Select({
  errors = [],
  options = [],
  isValid,
  isInvalid,
  placeholder,
  bankIcon,
  className,
  required = false,
  ...rest
}: Props) {
  const renderOption = useCallback(
    (option) => (
      <option
        value={option.value}
        key={option.value}
        disabled={option.disabled}
        children={option.label}
      />
    ),
    []
  );

  const optionsMarkup = useMemo(
    () => (
      <>
        {placeholder
          ? renderOption({
              disabled: true,
              value: '',
              label: placeholder
            })
          : null}
        {options.map(renderOption)}
      </>
    ),
    [options, placeholder, renderOption]
  );

  return useMemo(
    () => (
      <OuterWrapper className={className}>
        <Wrapper>
          <InnerWrapper
            isValid={isValid && !!rest.value}
            isInvalid={isInvalid}
            isBank={!!bankIcon}
          >
            <StyledSelect
              {...rest}
              isBank={!!bankIcon}
              children={optionsMarkup}
              required={required}
            />
          </InnerWrapper>
          {errors && errors.length ? <Message children={errors[0]} /> : null}
        </Wrapper>
      </OuterWrapper>
    ),
    [
      bankIcon,
      className,
      errors,
      isInvalid,
      isValid,
      optionsMarkup,
      rest,
      required
    ]
  );
}
