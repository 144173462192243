import React from 'react';
import Text from '~/App/shared/components/Fields/Text';

import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';

import { InputWrapper } from '../components/InputWrapper';

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
};

export function PrivateCompany({ values, validation }: Props) {
  return (
    <InputWrapper>
      <Text
        name="organization"
        value={values.productOptions.customerContact.company}
        onBlur={validation.handleBlur.bind(
          undefined,
          'productOptions.customerContact.company'
        )}
        errors={validation.errors['productOptions.customerContact.company']}
        isValid={validation.isValid('productOptions.customerContact.company')}
        onChange={values.handlers.handleCustomerContactCompany}
        isInvalid={validation.isInvalid(
          'productOptions.customerContact.company'
        )}
        maxLength="50"
        placeholder="Företag"
        instructions="Fyll endast i om du vill ge som företag."
        autoComplete="organization"
      />
    </InputWrapper>
  );
}
