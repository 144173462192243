import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { linkUnderline } from '~/App/helpers/mixins';

import { largeShareButton } from './LargeShareButtons.styled';

import { isAndroid, isIos } from '~/App/helpers/is-mobile-browser';
import { useAppContext } from '~/App/contexts/App';

const Button = styled.a`
  ${linkUnderline};
  text-decoration: underline;
  ${largeShareButton};
`;

type Props = {
  to: string;
  title: string;
  body: string;
};

export function More({ to, title, body }: Props) {
  const userAgent = useAppContext((x) => x.userAgent);

  const canShare = useMemo(() => {
    if (typeof window === 'undefined') {
      return false;
    }

    if (!(isIos(userAgent) || isAndroid(userAgent))) {
      return false;
    }

    // @ts-expect-error
    if (window.navigator.share) {
      return true;
    }

    return false;
  }, [userAgent]);

  const handleClick = useCallback(
    () =>
      navigator.share({
        title: title,
        text: body,
        url: to
      }),
    [to, body, title]
  );

  if (!canShare) {
    return null;
  }

  return (
    <Button href="javascript:void(0)" onClick={handleClick}>
      Fler sätt att dela
    </Button>
  );
}
