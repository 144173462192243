import { merge } from 'lodash';

import { swishTypes } from '~/App/config/swishTypes';

export const buildSchema = ({ schema = {} }) =>
  merge(
    {},
    {
      'productOptions.customerContact.company': {
        validateCompany: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.cid': {
        validateCid: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.addressStreet': {
        validateStreetName: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.addressZip': {
        validateZipCode: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.addressCity': {
        validateCity: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.firstName': {
        validateFirstName: (value, { customerContactType }) =>
          customerContactType === 'company' ? { allowEmpty: true } : false
      },
      'productOptions.customerContact.lastName': {
        validateLastName: (value, { customerContactType }) =>
          customerContactType === 'company' ? { allowEmpty: true } : false
      },
      'productOptions.customerContact.phoneNumber': {
        validatePhoneNumber: (value, { customerContactType }) =>
          customerContactType === 'company'
      },
      'productOptions.customerContact.email': {
        validateEmail: (_, {}) => true
      },
      swishPhoneNumber: {
        validatePhoneNumber: (_, { paymentMethod, swish }) =>
          paymentMethod.id === 8 && swish.swishType === swishTypes.ecommerce
      }
    },
    schema
  );
