import React, { useMemo } from 'react';
import styled from 'styled-components';

import { card, heading3 } from '~/App/helpers/mixins';
import { StepWrapper } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';

import AmountSelector from '~/App/shared/components/Donation/components/Forms/components/AmountSelector';

import { IValidation } from '~/App/shared/components/Validation';
import { ICommerceVariant } from '~/types/ICommerceVariant';
import { CommerceProductState } from '../hooks/useCommerceProductState';

const Card = styled.div`
  ${card};
  margin-bottom: 1rem;
`;

export const Heading = styled.h3`
  ${heading3};
  margin-bottom: 0.75rem;
  position: relative;
  color: inherit;
`;
type Props = {
  state: CommerceProductState;
  validation: IValidation;
  variant: ICommerceVariant;
};

export function Amount({ state, validation, variant }: Props) {
  const amounts = useMemo<number[]>(() => variant?.price?.options || [], [
    variant?.price?.options
  ]);

  return (
    <Card>
      <Heading>Välj belopp</Heading>
      <StepWrapper>
        <AmountSelector
          amounts={amounts}
          selectedAmount={state.values.price}
          customAmount={state.values.customPrice}
          setAmount={state.handlers.setPrice}
          setCustomAmount={state.handlers.setCustomPrice}
          resetAmount={state.handlers.resetPrice}
          validationKey="price"
          validation={validation}
          tinyLayout="50"
          smallLayout="50"
          largeLayout="50"
        />
      </StepWrapper>
    </Card>
  );
}
