import React, { ReactNode, useMemo } from 'react';

import { IPreloadedState } from '~/types/IPreloadedState';

import { AbProvider } from './Ab';
import { AppProvider } from './App';
import { UtmContextProvider } from './Utm';
import { OverlayProvider } from './Overlay';
import { HelloBarProvider } from './HelloBar';
import { LoginContextProvider } from './Login';
import { ScrollLockProvider } from './ScrollLock';
import { ShoppingCartProvider } from './ShoppingCart';
import { LoginModalContextProvider } from './LoginModal';
import { FeatureToggleProvider } from './FeatureToggles';
import { AuthenticationProvider } from './Authentication';

type Props = {
  state: IPreloadedState;
  children: ReactNode;
};

export function Providers({ state, children }: Props) {
  return useMemo(
    () => (
      <ScrollLockProvider>
        <HelloBarProvider value={state.helloBar}>
          <AbProvider value={state.ab}>
            <AuthenticationProvider value={state.authentication}>
              <AppProvider value={state.app}>
                <LoginContextProvider>
                  <LoginModalContextProvider>
                    <UtmContextProvider>
                      <ShoppingCartProvider state={state.shoppingCart}>
                        <FeatureToggleProvider>
                          <OverlayProvider
                            value={{ overlay: null }}
                            children={children}
                          />
                        </FeatureToggleProvider>
                      </ShoppingCartProvider>
                    </UtmContextProvider>
                  </LoginModalContextProvider>
                </LoginContextProvider>
              </AppProvider>
            </AuthenticationProvider>
          </AbProvider>
        </HelloBarProvider>
      </ScrollLockProvider>
    ),
    [
      children,
      state.ab,
      state.app,
      state.authentication,
      state.helloBar,
      state.shoppingCart
    ]
  );
}
