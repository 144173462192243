import styled, { keyframes } from 'styled-components';

const spinnerAnimation = keyframes`
  0% {
    transform: rotateZ(-360deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
`;

type SpinnerProps = {
  marginTop?: boolean
  alternateColor?: boolean
  size?: string
}

export const Spinner = styled.div<SpinnerProps>`
  margin-top: ${({ marginTop }) => (marginTop ? '2rem' : '0')};

  &:after {
    content: ' ';
    animation: ${spinnerAnimation} 0.3s linear infinite;
    border-radius: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightDust};
    border-left: 2px solid
      ${({ alternateColor, theme }) =>
        alternateColor ? theme.themeColors.primary : theme.colors.doveGray};
    border-right: 2px solid ${({ theme }) => theme.colors.lightDust};
    border-top: 2px solid
      ${({ alternateColor, theme }) =>
        alternateColor ? theme.themeColors.primary : theme.colors.doveGray};
    display: block;
    height: ${({ size }) => size || '3em'};
    width: ${({ size }) => size || '3em'};
    z-index: 1;
    margin: auto;
  }
`;
