import { ICommerceOrder } from '~/types/ICommerceOrder';
import { get } from '../cookie';
import { mwAxios } from './client';

type AllArguments = {
  secretKey?: string;
  pagination?: {
    page?: number;
    perPage?: number;
  };
};

export const meOrders = {
  all: async ({ secretKey, pagination }: AllArguments) => {
    const config = {
      headers: {
        'X-Session-Key': secretKey || get('secretKey')
      },
      params: pagination || {}
    };

    const response = await mwAxios.get<ICommerceOrder[]>(
      `/api/v2/users/me/orders`,
      config
    );

    return response.data;
  }
};
