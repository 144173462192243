import React from 'react';
import styled from 'styled-components';

import MonthlyDonation from '~/App/shared/components/Donation/MonthlyDonation';
import { heading2, preamble } from '~/App/helpers/mixins';
import { Wrapper } from '~/App/shared/components/ThanksPage';

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const WhiteWrapper = styled(Wrapper)`
  margin-top: 1.5rem;
  padding: 0;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  padding-top: 2rem;
`;

const Content = styled.div`
  position: relative;
  max-width: 37.5rem;
  margin: 0 auto;
  padding: 1rem 1rem 3rem;
  text-align: center;
`;

const FORM_CONTENT = {
  originPath: '/stod-oss/bli-manadsgivare',
  redirectPath: '/stod-oss/bli-manadsgivare/tack',
  productId: 31,
  campaignActivityId: 2544,
  availablePaymentMethods: [
    {
      id: 'autoGiro',
      name: 'Autogiro',
      allowBankId: true,
      allowSlip: true
    },
    {
      id: 'creditCard',
      name: 'Betalkort'
    }
  ],
  availableBanks: [
    {
      name: 'CUSTOM',
      displayName: 'Fyll i kontouppgifter manuellt'
    },
    {
      name: 'OEB',
      displayName: 'DanskeBank'
    },
    {
      name: 'SHB',
      displayName: 'Handelsbanken'
    },
    {
      name: 'ICA',
      displayName: 'Ica'
    },
    {
      name: 'LFB',
      displayName: 'Länsförsäkringar'
    },
    {
      name: 'NB',
      displayName: 'Nordea'
    },
    {
      name: 'SBAB',
      displayName: 'SBAB'
    },
    {
      name: 'SEB',
      displayName: 'SEB'
    },
    {
      name: 'SKB',
      displayName: 'Skandia'
    },
    {
      name: 'SYD',
      displayName: 'Sparbanken Syd'
    },
    {
      name: 'FSPASB',
      displayName: 'Sparbankerna'
    },
    {
      name: 'FSPA',
      displayName: 'Swedbank'
    }
  ],
  prices: ['150', '200'],
  defaultPrice: '200',
  infoTexts: [
    {
      text: 'Tack! Ditt bidrag hjälper till att föra cancerforskningen framåt.',
      iconColor: 'blue',
      icon: 'heart'
    },
    {
      text:
        'Du kan när som helst avsluta ditt månadsgivande via vår givarservice.',
      iconColor: 'blue',
      icon: 'check'
    }
  ],
  taxReductionInfoLink: '#skatteavdrag-for-gavor'
};

export function DonationThanksMonthlyDonation() {
  return (
    <WhiteWrapper>
      <HeaderWrapper>
        <Content>
          <Heading1>
            Bli månadsgivare - bidra till livsviktig forskning varje månad
          </Heading1>
          <Preamble>
            En av tre får cancer, men alla drabbas. Som månadsgivare är du med
            och skapar en bättre framtid för fler drabbade, både sjuka och
            närstående. Ge ett valfritt månadsbelopp och stötta kampen mot
            cancer.
          </Preamble>
        </Content>
      </HeaderWrapper>

      <div>
        <MonthlyDonation inline formContent={FORM_CONTENT} />
      </div>
    </WhiteWrapper>
  );
}
