import React from 'react';
import mediaHelper from '~/App/helpers/media-helper';

class Author extends React.Component {
  render() {
    var imageUrl = mediaHelper.getUrl(this.props.image.secure_url, {
      width: 120,
      height: 120
    });

    if (!this.props.name || !imageUrl) {
      return null;
    }

    return (
      <div className="author">
        <div className="author__imageWrapper author__imageWrapper--small">
          <img className="author__image" src={imageUrl} />
        </div>
        <div className="author__info">
          <h3 className="author__name">{this.props.name}</h3>
          <p className="author__role">{this.props.role}</p>
        </div>
      </div>
    );
  }
}

Author.displayName = 'Author';

Author.defaultProps = {
  name: '',
  image: {}
};

export default Author;
