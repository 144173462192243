import React from 'react';

import styled from 'styled-components';
import { mq } from '~/lib/mq';

import { ICmsProduct } from '~/types/ICmsProduct';
import { IValidation } from '~/App/shared/components/Validation';
import { ISubmit } from '~/App/shared/components/Donation/components/Submits';

import { LeftColumn } from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import { FormHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography.jsx';

import { FormInfoBox } from './Form.InfoBox';

import FormPartAmountOrQuantity from './Form.Part.AmountOrQuantity';
import FormPartContactInformation from './Form.Part.ContactInformation';
import FormPartPayment from './Form.Part.Payment';

import { SubmitButton } from '~/App/shared/components/Donation/components/Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '~/App/shared/components/Donation/components/Forms/components/SwishOtherDeviceButton';
import { HiddenAboveMedium } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { PreviewModal } from '~/App/shared/components/Donation/components/Forms/components/PreviewModal';
import { ProductCertificatePreview } from './ProductCertificatePreview';

// Config
import { buttonStyles } from '~/App/config/buttonStyles';
import { CertificateValues } from '~/App/shared/components/Donation/components/States';
import { StateValues } from '~/App/shared/components/Donation/components/States';
import { MultiPartFormValues } from '~/App/shared/components/MultiPartForm';

const Wrapper = styled(LeftColumn)`
  ${mq('<medium')`
    position: relative;
    order: 1;
    padding: 1rem;
  `};
`;

type Props = {
  product: ICmsProduct;
  submit: ISubmit;
  values: StateValues & MultiPartFormValues & CertificateValues;
  validation: IValidation;
  previewModalOpen: boolean;
  togglePreviewModal: () => void;
};

const TopImage = styled.img`
  width: 185px;
  height: 185px;
  margin: auto;
  border-radius: 50%;
  border: 1rem solid white;
  display: block;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);

  ${mq('≥medium')`
    display: none;
  `};
`;

const StyledFormHeading = styled(FormHeading)`
  ${mq('<small')`
    display: none;
  `};
`;

const PreviewButton = styled(PrimaryButton)`
  display: block;
  margin: 1rem auto 1.5rem;
`;

export default function FormLeftColumn({
  validation,
  values,
  product,
  submit,
  previewModalOpen,
  togglePreviewModal
}: Props) {
  const { certificate } = product;
  return (
    <Wrapper>
      {product.productImage && (
        <TopImage src={product.productImage?.secure_url} />
      )}
      {previewModalOpen && (
        <PreviewModal closeModal={togglePreviewModal}>
          <ProductCertificatePreview
            heading={certificate?.heading}
            disclaimer={certificate?.disclaimer}
            description={certificate?.description}
            imageUrl={product.productImage?.secure_url}
            fullSize={true}
          ></ProductCertificatePreview>
        </PreviewModal>
      )}
      {product.longDescription ? (
        <FormInfoBox>
          <StyledFormHeading>Om produkten</StyledFormHeading>
          {product.longDescription}
          <HiddenAboveMedium>
            <PreviewButton
              buttonStyle={buttonStyles.outlinePrimary}
              onClick={togglePreviewModal}
              children="Förhandsgranska gåvobevis"
            />
          </HiddenAboveMedium>
        </FormInfoBox>
      ) : (
        <HiddenAboveMedium>
          <FormInfoBox>
            <PreviewButton
              buttonStyle={buttonStyles.outlinePrimary}
              onClick={togglePreviewModal}
              children="Förhandsgranska gåvobevis"
            />
          </FormInfoBox>
        </HiddenAboveMedium>
      )}

      <form onSubmit={submit.handleSubmit}>
        <FormPartAmountOrQuantity
          product={product}
          validation={validation}
          values={values}
        />

        <FormPartContactInformation
          product={product}
          validation={validation}
          values={values}
        />

        <FormPartPayment
          product={product}
          validation={validation}
          values={values}
          submit={submit}
        />

        {values.partOptions.currentPart === 3 && (
          <>
            <SubmitButton
              values={values}
              validation={validation}
              submit={submit}
            />
            <SwishOtherDeviceButton values={values} />
          </>
        )}
      </form>
    </Wrapper>
  );
}
