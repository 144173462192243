import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ICommerceVariant } from '~/types/ICommerceVariant';
import { IValidation } from '~/App/shared/components/Validation';
import { primaryButton } from '~/App/helpers/mixins';
import { shoppingCart } from '~/App/helpers/icons';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { CommerceProductState } from '../hooks/useCommerceProductState';
import { ICommerceProduct } from '~/types/ICommerceProduct';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { pushGTMAdd } from '~/App/helpers/gtm-helper';
import { stockStatus } from '~/App/helpers/stock-status';

const Button = styled(PrimaryButton)`
  &:before {
    ${shoppingCart};
    font-weight: bold;
    margin-right: 0.5rem;
  }
  ${primaryButton};
`;

const Wrapper = styled.div`
  text-align: center;
`;

type Props = {
  validation: IValidation;
  variant: ICommerceVariant;
  product: ICommerceProduct;
  state: CommerceProductState;
};

export function AddToCartButton({
  validation,
  variant,
  product,
  state
}: Props) {
  const [isLoading, setLoading] = useState(false);
  const shoppingCart = useShoppingCart();
  const handleClick = useCallback(async () => {
    if (!validation.isValidated) {
      return validation.showAllErrors();
    }

    if (!state.values.price) {
      return;
    }

    if (isLoading) {
      return;
    }

    setLoading(true);

    await shoppingCart.add({
      variant: {
        ...variant,
        product
      },
      quantity: state.values.quantity,
      price: state.values.price
    });
    pushGTMAdd({
      id: variant?.id,
      name: product.name,
      category: `${product.target}/${product.type}`,
      variant: product.name,
      price: state.values.price,
      quantity: state.values.quantity,
      dimension10: stockStatus(variant.stock?.status)
    });

    setLoading(false);
  }, [
    product,
    shoppingCart,
    state.values.price,
    state.values.quantity,
    validation,
    variant,
    isLoading
  ]);

  return (
    <Wrapper>
      <Button
        onClick={handleClick}
        isDisabled={isLoading || !validation.isValidated}
        isLoading={isLoading}
        buttonStyle="cta"
        children="Lägg i varukorg"
      />
    </Wrapper>
  );
}
