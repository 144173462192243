import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

//helpers
import mq from '~/App/helpers/mq';
import { heading4 } from '~/App/helpers/mixins';

// Shared components
import { Spinner } from '~/App/shared/components/Elements/Spinner';

import { HandleLoginOptions } from '~/App/contexts/Login';

import useLogin from '~/App/shared/hooks/use-login';
import useLoginModal from '~/App/shared/hooks/use-login-modal';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

const Container = styled.div`
  background: white;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq('≥mediumLarge')`
    position: relative;
    padding: 0;
    align-items: flex-start;
  `};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  margin: auto;
  margin-bottom: 3rem;

  ${mq('≥mediumLarge')`
    flex-direction: row;
    margin-bottom: 2rem;
  `};
`;

const CancelButton = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  margin: auto;
  padding: 0;
  color: ${({ theme }) => theme.themeColors.primary};
  cursor: pointer;
  margin-top: 1rem;
`;

const TextContainer = styled.div`
  padding-left: 2rem;
  ${mq('≥mediumLarge')`
    padding-left: 0rem;
  `};
`;
const Heading = styled.h3`
  ${heading4};
  margin: 0.75rem 0 0.5rem;
  text-align: center;
  margin-top: 0;
  text-align: left;
`;

const DescriptionDesktop = styled.ul`
  text-align: left;
`;

const ImageWrapper = styled.div`
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq('≥mediumLarge')`
    margin-left: -1rem;
    margin-right: 0.5rem;
  `};
`;

const QRImage = styled.img`
  height: 100%;
  width: 100%;
`;

type Props = {
  beginAuthentication: (options: HandleLoginOptions) => void;
};

export function Qr({ beginAuthentication }: Props) {
  const login = useLogin();
  const loginModal = useLoginModal();

  const authentication = useAuthenticationContext();
  const isSigning = authentication.status === 'signing';

  useEffect(() => {
    beginAuthentication({ qrInit: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const qrImage = useMemo(
    () =>
      login.qrCode && !isSigning ? (
        <QRImage src={`data:image/png;base64,${login.qrCode}`} />
      ) : (
        <Spinner size="2.5rem" alternateColor={true} />
      ),
    [login.qrCode]
  );

  const cancelLogin = useCallback(() => {
    loginModal.handlers.cancelLogin();
  }, [loginModal.handlers]);

  return (
    <Container>
      <FlexWrapper>
        <ImageWrapper>{qrImage}</ImageWrapper>
        <TextContainer>
          <Heading>Logga in med BankID</Heading>
          <DescriptionDesktop>
            <li>1. Öppna BankID-appen.</li>
            <li>2. Tryck på QR-symbolen i appen.</li>
            <li>3. Scanna QR-koden.</li>
            <li>4. Legitimera dig.</li>
          </DescriptionDesktop>
        </TextContainer>
      </FlexWrapper>
      {isSigning && (
        <p>
          Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller
          Skriv under.
        </p>
      )}

      <CancelButton onClick={cancelLogin}>Avbryt</CancelButton>
    </Container>
  );
}
