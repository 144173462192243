import { filter } from 'lodash';

import { ParsedQuery } from '~/lib/query-string';
import { genericCertificate } from '~/App/config/default-images';

import { gtmDetails } from '~/App/config/gtmDetails';
import { GtmDetails } from '~/App/helpers/gtm-helper';
import { CustomerContactType } from '~/App/config/customerContactTypes';
import { IPaymentMethod } from '~/types/IPaymentMethod';
import { paymentMethods } from '~/App/config/paymentMethods';

type Variant = {
  heading: string;
  hidden: boolean;
  slug: string;
  gtmVariant?: string;
  campaignActivityId?: number;
};

type Props = {
  query: ParsedQuery;
  data: {
    productId?: number;
    defaultPrice?: string;
    gtmDetails?: GtmDetails;
    redirectPath: string | Record<number, string>;
    defaultCustomerContactType: CustomerContactType;
    defaultPaymentMethod: IPaymentMethod;
    certificateInfo: {
      description?: string;
      variants: Variant[];
    };
  };
};

const filterHiddenVariants = (variants: Variant[]) =>
  filter(variants, (variant) => !variant.hidden);

export const buildState = ({ data, query = {} }: Props) => {
  const { defaultPrice = '500', certificateInfo } = data;
  const { variants = [], description = '' } = certificateInfo;

  const filteredVariants = filterHiddenVariants(variants);
  const variantFromSlug = filteredVariants.find(
    (x) => x.slug === query.variant
  );
  const defaultVariant =
    query.variant && variantFromSlug ? variantFromSlug : filteredVariants[0];
  data.certificateInfo.variants = filteredVariants;

  const PRODUCT_ID = data.productId || 34;
  const gtmInfo = data.gtmDetails || gtmDetails[PRODUCT_ID];

  gtmInfo.variant = defaultVariant.gtmVariant || '';

  return {
    redirectPath: data.redirectPath,
    product: { id: PRODUCT_ID },
    productOptions: {
      product: { price: parseInt(defaultPrice, 10) },
      campaignActivityId: defaultVariant.campaignActivityId,
      pul: true,
      certificate: {
        heading: defaultVariant.heading || 'Gåvobevis',
        headingSlug: defaultVariant.slug || 'gavobevis',
        description: description,
        illustration: genericCertificate[0],
        deliveryDate: null
      }
    },
    paymentMethod: {
      id: paymentMethods.klarnaPayments,
      name: 'Konto eller kort',
      slug: 'klarna'
    },
    customerContactType: data.defaultCustomerContactType || 'automatic',
    partOptions: {
      parts: 4,
      validationKeys: {
        1: ['productOptions.certificate.textGreeting'],
        2: ['productOptions.product.price'],
        3: [
          'productOptions.certificate.receiverContact.email',
          'productOptions.certificate.receiverContact.name',
          'productOptions.certificate.deliveryDate'
        ],
        4: [
          'swishPhoneNumber',
          'productOptions.customerContact.addressStreet',
          'productOptions.customerContact.addressZip',
          'productOptions.customerContact.addressCity',
          'productOptions.customerContact.firstName',
          'productOptions.customerContact.lastName',
          'productOptions.customerContact.phoneNumber',
          'productOptions.customerContact.ssn',
          'productOptions.customerContact.email'
        ]
      }
    },
    gtm: gtmInfo
  };
};
