import { IMemorialPage } from '~/types/ICollection';
import { collections, collectionActivities } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export const loadData = async ({ params }: LoadDataProps<string>) => {
  const { slug = '' } = params;
  const collectionTask = collections.show<IMemorialPage>({
    slug: slug
  });
  const activitiesTask = collectionActivities.show({
    slug: slug,
    params: { per_page: 12 }
  });

  const [{ data }, activities] = await Promise.all([
    collectionTask,
    activitiesTask
  ]);

  if (data.collectionType !== 'memorial_page') {
    throw {
      response: {
        status: 404
      }
    };
  }

  return {
    collection: data,
    activities: activities.data.activities || []
  };
};
