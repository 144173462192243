import { parseDate } from '~/App/helpers/date.service';

const validateSsn = (instance) => {
  let ssn = instance.replace('-', '');
  let parsedDate;

  if (ssn.length === 0) {
    return true;
  }

  if (ssn.length === 12) {
    parsedDate = parseDate(ssn.substr(0, 8), 'YYYYMMDD');
    if (parsedDate.year() < 1900) {
      return false;
    }

    ssn = ssn.substr(2);
  } else if (ssn.length === 10) {
    parsedDate = parseDate(ssn.substr(0, 6), 'YYMMDD');
  }

  if (ssn.length !== 10) {
    return false;
  }

  if (!parsedDate.isValid()) {
    return false;
  }

  const parts = ssn.split('').map((i) => {
    return Number(i);
  });

  const control = parts.pop();

  let inc = 0;
  let product = 0;
  let multiplicator = 2;

  for (var i in parts) {
    product = parts[i] * multiplicator;

    if (product > 9) {
      inc += product - 9;
    } else {
      inc += product;
    }

    multiplicator = multiplicator === 1 ? 2 : 1;
  }

  let control_ = 10 - (inc - Math.floor(inc / 10) * 10);

  if (control_ === 10) {
    control_ = 0;
  }

  return control === control_;
};

export default validateSsn;
