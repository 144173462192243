import React from 'react';
import styled from 'styled-components';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

// Shared components
import Container from '~/App/shared/components/Container';
import { CompanyPackage } from '~/App/shared/components/Donation';

const CompanyPackageFormWrapper = styled(BlockBackground)``;

class CompanyPackageForm extends React.Component {
  render() {
    const { data, backgroundColor } = this.props;

    return (
      <CompanyPackageFormWrapper backgroundColor={backgroundColor}>
        <Container
          width="limited"
          paddingLeft="small"
          paddingRight="small"
          paddingTop="small"
          paddingBottom="small"
        >
          <CompanyPackage data={data} />
        </Container>
      </CompanyPackageFormWrapper>
    );
  }
}

export default CompanyPackageForm;
