import React from 'react';

export default class EmailLink extends React.Component {
  render() {
    const { body = '', subject = '', children, className } = this.props;

    const encodedBody = encodeURIComponent(body);
    const encodedSubject = encodeURIComponent(subject);

    const shareUrl = `mailto:?subject=${encodedSubject.trim()}&body=${encodedBody.trim()}`;

    return <a href={shareUrl} className={className} children={children} />;
  }
}
