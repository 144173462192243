import React, { ReactNode, useEffect, useCallback } from 'react';

// Shared components
import { Spinner } from '~/App/shared/components/Elements';

// Local components
import { Container } from '../Styled';
import { Logo } from '../Logo';

import useLoginModal from '~/App/shared/hooks/use-login-modal';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
};

const CancelButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
  color: ${({ theme }) => theme.themeColors.primary};
  cursor: pointer;
  padding-top: 1.5rem;
`;

export function Loading({ children }: Props) {
  const loginModal = useLoginModal();

  const handleClose = useCallback(() => {
    loginModal.handlers.cancelLogin();
  }, [loginModal.handlers]);

  useEffect(() => {
    return () => {
      if (loginModal.mobileInitView) {
        loginModal.handlers.setMobileInitView(false);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Logo marginBottom="2rem" />
      <p children={children} />
      <Spinner size="2rem" alternateColor={true} marginTop={true} />
      <CancelButton onClick={handleClose}>Avbryt inloggning</CancelButton>
    </Container>
  );
}
