import React from 'react';
import { Helmet } from 'react-helmet';

// Helpers
import { assemble } from '~/lib/slug-helper';
import { siteUrl } from '~/App/helpers/env';

// Shared components
import Grid from '~/App/shared/components/Grid';
import Intro from '~/App/shared/components/Intro';
import Section from '~/App/shared/components/Section';
import SectionInfo from '~/App/shared/components/SectionInfo';
import ArticleThumb from '~/App/shared/components/ArticleThumb';
import EditorialItem from '~/App/shared/components/EditorialItem';
import ThreeUpArticleFeed from '~/App/shared/components/ThreeUpArticleFeed';

export function ArticlePost({
  data: { editorialItem = {}, articleFeed = {} } = {}
}) {
  const {
    meta = {},
    data: { relatedHeading = 'Rekommenderad läsning' } = {},
    related = [],
    relatedContentItems = []
  } = editorialItem;

  const recommendedItems = [...relatedContentItems, ...related].slice(0, 3);
  const modifier = recommendedItems.length > 0 ? '' : 'section--borderTopFaded';

  return (
    <div>
      <Helmet>
        <title>{meta.pageTitle}</title>
        <link rel="canonical" href={siteUrl(assemble(editorialItem))} />
        <meta name="description" content={meta.pageDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:image" content={meta.ogImage} />
        <meta property="og:description" content={meta.ogDescription} />
      </Helmet>
      <EditorialItem
        content={editorialItem}
        parentSlug="/artiklar"
        parentName="Artiklar"
      />
      {recommendedItems.length > 0 && (
        <Section modifier="section--smoke">
          <Grid block="packer">
            <Intro heading={relatedHeading} />
            <div className="threeUpArticleFeed threeUpArticleFeed--slimTop">
              <ul className="threeUpArticleFeed__list">
                {recommendedItems.map((item, key) => {
                  const {
                    image: { secure_url: imageSecureUrl = '' } = {},
                    teaserImage: { secure_url: teaserImageSecureUrl = '' } = {}
                  } = item;

                  const imageUrl = teaserImageSecureUrl
                    ? teaserImageSecureUrl
                    : imageSecureUrl;

                  return (
                    <li className="threeUpArticleFeed__item" key={key}>
                      <ArticleThumb
                        link={item.url || assemble(item)}
                        image={imageUrl}
                        embed={item.embed}
                        label={item.teaserLabel}
                        heading={item.teaserHeading || item.heading}
                        preamble={item.teaserPreamble || item.preamble}
                        defaultImage
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </Grid>
        </Section>
      )}
      <Section modifier={modifier}>
        <SectionInfo {...articleFeed} />
        <ThreeUpArticleFeed
          slug={articleFeed.slug}
          related={articleFeed.related}
          pagination={articleFeed.data.pagination}
        />
      </Section>
    </div>
  );
}
