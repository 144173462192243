import React, { useMemo } from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';
import { heading2, link } from '~/App/helpers/mixins';
import { DraggableModal } from '~/App/shared/components/Elements/Modal';
import { ICommerceOrder } from '~/types/ICommerceOrder';

const Heading = styled.div`
  ${heading2};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 0.5rem;
  line-height: 2 !important;

  ${mq('<mediumLarge')`
    font-size: 1.375rem;
  `};
`;

const Text = styled.div`
  font-size: 1.125rem;

  ${mq('<mediumLarge')`
    font-size: 1rem;
    `};

  :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const BlueCard = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPolar};
  padding: 1.5rem;
`;

const StyledModal = styled(DraggableModal)`
  padding: 2.5rem 2rem;
`;

const Link = styled.a`
  ${link}
`;

type Props = {
  order: ICommerceOrder;
  closeModal: () => void;
};

export function OrderReturnModal({ closeModal }: Props) {
  const tel = useMemo(
    () => (
      <Link target="_blank" href="tel:0101991010">
        010-1991010
      </Link>
    ),
    []
  );

  const mail = useMemo(
    () => (
      <Link target="_blank" href="mailto:info@cancerfonden.se">
        info@cancerfonden.se
      </Link>
    ),
    []
  );

  return (
    <StyledModal
      maxWidth="44.25rem"
      closeModal={closeModal}
      closeButton={{ style: 'outlinePrimary', text: 'Stäng' }}
    >
      <Heading>Ångerrätt</Heading>
      <Text>
        Ångerrätt av <strong>profilprodukter</strong> sker i enlighet med
        Konsumentverkets riktlinjer, dvs du har 14 dagars ångerrätt. Utskick och
        returer av fysiska profilprodukter hanteras av vår logistikpartner NeH.
      </Text>
      <Text>
        Om du ångrar ditt köp är du skyldig att betala eventuell returfrakt.
      </Text>
      <Text>
        Mer returinformation om hur du praktiskt går tillväga finns med i
        leveransen av dina produkter.
      </Text>
      <Text>
        Om du har frågor är du alltid välkommen att kontakta givarservice på{' '}
        {tel} och {mail}
      </Text>
      <Text>
        Frågor kring{' '}
        <strong>digitala gåvobevis, rosa band och rosa armband</strong> hanteras
        av vår givarservice. Kontakta gärna dem om du har frågor på {tel} och{' '}
        {mail}
      </Text>{' '}
      <Heading>Reklamationer</Heading>
      <Text>
        Du som beställare ansvarar för att kontrollera din leverans. Eventuella
        reklamationer av profilprodukter ska skickas tillbaka inom 14 dagar
        efter att felet har upptäckts. Vid godkända reklamationer står
        Cancerfonden för kostnaden av returfrakt och godkända reklamationer
        ersätts omgående av vår logistikpartner NeH.
      </Text>
      <Text>
        När du skickar tillbaka produkten som ska reklameras måste du bifoga en
        kopia på följesedeln. Information om hur du praktisk går tillväga finns
        med i leveransen.
      </Text>
      <Text>
        Om du har frågor är du alltid välkommen att kontakta vår givarservice.
      </Text>
      <BlueCard>
        <Text>
          Givarservice når du på {tel} och {mail}
        </Text>
      </BlueCard>
    </StyledModal>
  );
}
