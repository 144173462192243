import React from 'react';

import { LoginView, loginViews } from '~/App/components/Header/constants';

import { JoinCollection } from './views/JoinCollection';
import { ConfirmEmail } from './views/ConfirmEmail';
import { ChooseEmail } from './views/ChooseEmail';
import { Newsletter } from './views/Newsletter';
import { Researcher } from './views/Researcher';
import { VerifyEmail } from './views/VerifyEmail';
import { Private } from './views/Private';
import { Email } from './views/Email';

type Props = {
  view: LoginView;
};

export function View({ view }: Props) {
  if (view === loginViews.private) {
    return <Private />;
  }

  if (view === loginViews.researcher) {
    return <Researcher />;
  }

  if (view === loginViews.confirmEmail) {
    return <ConfirmEmail />;
  }

  if (view === loginViews.joinCollection) {
    return <JoinCollection />;
  }

  if (view === loginViews.newsletter) {
    return <Newsletter />;
  }

  if (view === loginViews.chooseEmail) {
    return <ChooseEmail />;
  }

  if (view === loginViews.email) {
    return <Email />;
  }

  if (view === loginViews.verifyEmail) {
    return <VerifyEmail />;
  }

  return null;
}
