import React from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';
const Wrapper = styled.div`
  padding: 1rem 1.25rem;
  font-size: 1.125rem;

  ${mq('<small')`
    font-size: 1rem;
  `}
`;

type Props = {
  loading: boolean;
};

export function buildNoOptionsMessage({ loading }: Props) {
  return function NoOptionsMessage() {
    return <Wrapper children={loading ? 'Söker...' : 'Inga resultat, fyll i manuellt'} />;
  };
}
