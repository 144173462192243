import styled, { css } from 'styled-components';

// Helpers
import { fadeIn } from '~/App/helpers/animations';

const ANIMATION_DELAY_BASE = 0.75;
const ANIMATION_DELAY_INCREMENT = 0.3;
const NUMBER_OF_BUTTONS = 6;

function getAnimationDelay(delay) {
  let str = '';

  for (let i = 0; i < NUMBER_OF_BUTTONS; i += 1) {
    str += `
          &:nth-child(${i + 1}) {
              animation-delay: ${i * delay + ANIMATION_DELAY_BASE}s;
          }
        `;
  }
  return str;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const largeShareButton = css`
  text-align: left;
  padding-left: 2.75rem;
  width: 12.625rem;
  opacity: 0;
  animation: ${fadeIn} 1 1s cubic-bezier(0.25, 0.62, 0.5, 0.94)
    ${ANIMATION_DELAY_BASE}s forwards;
  ${getAnimationDelay(ANIMATION_DELAY_INCREMENT)};

  &:before {
    position: absolute;
    left: 1rem;
    font-size: 1.25rem;
    top: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;
