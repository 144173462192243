import React, { useMemo, useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';
import { parse, stringify } from '~/lib/query-string';
import { useLocation, useNavigate } from 'react-router';

// Config and consts
import { buttonStyles } from '~/App/config/buttonStyles';

// Helpers
import mq from '~/App/helpers/mq';
import { siteUrl } from '~/App/helpers/env';
import { formatDate } from '~/App/helpers/date.service';
import { assemble, friendlyName } from '~/lib/slug-helper';
import { preamble, primaryButton } from '~/App/helpers/mixins';
import { scrollAnimation } from '~/App/helpers/animations';

// Shared components
import Container from '~/App/shared/components/Container';

// Local components
import SearchBar from './components/SearchBar';
import SearchResult from './components/SearchResult';
import SearchResults from './components/SearchResults';
import SearchCategory from './components/SearchCategory';
import SearchCategories from './components/SearchCategories';

const doSearch = async ({ query }) =>
  axios({
    url: `${process.env.CONTENT_API}/v1/algoliasearch`,
    params: {
      ...query,
      pp: 10
    },
    method: 'get',
    timeout: 15000,
    responseType: 'json'
  });

const Header = styled.div`
  ${mq('≥small')`
    padding-bottom: 3em;
  `};
`;

const Side = styled.div`
  padding: 0.5em 0;

  ${mq('≥small')`
    display: inline-block;
    padding: 0;
    width: 200px;
    vertical-align: top;
  `};

  ${mq('≥medium')`
    width: 300px;
    padding: 0 0 2em 0;
  `};
`;

const Content = styled.div`
  ${mq('≥small')`
    display: inline-block;
    width: calc(100% - 200px);
    padding-left: 1.5em;
    vertical-align: top;
  `};

  ${mq('≥medium')`
    width: calc(100% - 300px);
    padding-left: 3em;
  `};
`;

const PrimaryButton = styled.button`
  ${primaryButton};
  margin-right: 2rem;
`;

const NoResults = styled.div`
  ${preamble};
  color: #888;
  padding-top: 0.75em;
`;

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.smoke};
`;
export function Search() {
  const location = useLocation();
  const navigate = useNavigate();

  const [query, setQuery] = useState(
    parse(location.search, { parseNumbers: true }) || {}
  );
  const [searchTerm, setSearchTerm] = useState(query.q);
  const [data, setData] = useState({
    facets: [],
    results: [],
    total: {
      items: 0,
      pages: 0
    }
  });

  const [state, setState] = useState({
    isLoading: false,
    hasSearched: false
  });

  const reset = useCallback(() => {
    setState({
      isLoading: false,
      hasSearched: false
    });

    setData({
      total: {
        items: 0,
        pages: 0
      },
      facets: [],
      results: []
    });
  }, []);

  const search = useCallback(
    async (query) => {
      try {
        if (!query.q) {
          return reset();
        }

        scrollAnimation(0);
        setState((state) => ({ ...state, isLoading: true }));

        const { data = {} } = await doSearch({ query });

        setState((state) => ({
          ...state,
          isLoading: false,
          hasSearched: true
        }));

        setData((state) => ({
          ...state,
          total: data.total,
          facets: data.facets,
          results: data.results
        }));

        setQuery(query);
        navigate(`/sok?${stringify(query)}`);
      } catch (error) {
        console.log(error);

        setState((state) => ({
          ...state,
          isLoading: false
        }));
      }
    },
    [navigate, reset]
  );

  const handleChange = useCallback((event) => {
    event.persist();
    setSearchTerm(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      search({ ...query, q: searchTerm });
    },
    [query, searchTerm, search]
  );

  const handlePrevClick = useCallback(
    (event) => {
      event.preventDefault();

      search({ ...query, p: query.p - 1 });
    },
    [search, query]
  );

  const handleNextClick = useCallback(
    (event) => {
      event.preventDefault();

      search({ ...query, p: (query.p ?? 0) + 1 });
    },
    [search, query]
  );

  const handleFacetClick = useCallback(
    (facet, event) => {
      event.preventDefault();

      search({ ...query, p: 0, ct: facet });
    },
    [search, query]
  );

  useEffect(() => {
    search(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, hasSearched } = state;

  const { ct, p = 0 } = query;

  return useMemo(
    () => (
      <div>
        <Helmet>
          <title>Sök</title>
          <meta name="description" content="Sök på cancerfonden.se" />
          <link rel="canonical" href={siteUrl(location.pathname)} />
        </Helmet>
        <Background>
          <Container
            width="normal"
            paddingTop="large"
            paddingLeft="small"
            paddingRight="small"
            paddingBottom="large"
          >
            <Header>
              <SearchBar
                search={searchTerm}
                onSubmit={handleSubmit}
                onChange={handleChange}
                isLoading={isLoading}
              />
            </Header>
            <Side>
              {data.facets.length > 0 && (
                <SearchCategories>
                  {data.facets.map(({ key, value }, index) => (
                    <SearchCategory
                      key={index}
                      label={key === 'all' ? 'Alla' : friendlyName(key)}
                      value={value}
                      active={key === ct || (key === 'all' && !ct)}
                      onClick={(e) => handleFacetClick(key, e)}
                    />
                  ))}
                </SearchCategories>
              )}
            </Side>
            <Content>
              {data.results.length > 0 && (
                <SearchResults
                  header={`Visar sida ${parseInt(p, 10) + 1} av ${
                    data.total.pages
                  }`}
                  content={data.results.map(({ item = {} }, key) => (
                    <SearchResult
                      key={key}
                      url={assemble(item)}
                      date={formatDate(
                        item.updatedAt || item.publishedAt || item.createdAt,
                        'YYYY-MM-DD'
                      )}
                      heading={item.title}
                      description={item.content}
                    />
                  ))}
                  footer={
                    <div>
                      {Number(p) > 0 && (
                        <PrimaryButton
                          onClick={handlePrevClick}
                          buttonStyle={buttonStyles.outlinePrimary}
                        >
                          Föregående
                        </PrimaryButton>
                      )}
                      {Number(p) < data.total.pages - 1 && (
                        <PrimaryButton
                          onClick={handleNextClick}
                          buttonStyle={buttonStyles.outlinePrimary}
                        >
                          Nästa
                        </PrimaryButton>
                      )}
                    </div>
                  }
                />
              )}
              {!isLoading && hasSearched && data.results.length === 0 && (
                <NoResults>Din sökning gav tyvärr inga träffar</NoResults>
              )}
            </Content>
          </Container>
        </Background>
      </div>
    ),
    [
      ct,
      data.facets,
      data.results,
      data.total.pages,
      handleChange,
      handleFacetClick,
      handleNextClick,
      handlePrevClick,
      handleSubmit,
      hasSearched,
      isLoading,
      location.pathname,
      p,
      searchTerm
    ]
  );
}
