import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const Wrapper = styled.div`
  display: block;
  max-width: 592px;
  width: calc(100% - 2rem);
  padding: 0;
  background: ${(props) => props.theme.colors.white};
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  border-radius: 1.375rem;
  box-shadow: ${({ isExpanded = true }) =>
    isExpanded ? '0 0 0 5000px rgba(139,139,139,.3)' : 'none'};
  transition: bottom 0.35s ease;

  ${mq('≥large')`
    float: left;
    width: calc(((100% - 592px) / 2) - 2rem);
    max-width: 19rem;
    background: ${(props) => props.theme.colors.white};
    margin: 1rem 0 0 2rem;
    padding: 0.625rem 0.5rem;
    top: ${({ subMenuActive = false }) =>
      subMenuActive === false ? '140px' : '190px'};
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    transform: none;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

    @supports (position: sticky) {
      position: ${({ isSticky = false }) =>
        isSticky === false ? 'static' : 'sticky'};
    }
  `};
`;
