import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { slideDownAndBounce, slideDownAndBounceMobile } from '~/App/helpers/animations';
import { CSSTransition } from 'react-transition-group';

import { useShoppingCart } from '~/App/contexts/ShoppingCart';

import { link, primaryButton } from '~/App/helpers/mixins';
import { buttonStyles } from '~/App/config/buttonStyles';
import { ShoppingCartLarge } from './components/ShoppingCartLarge';
import { FreeShippingLarge } from './components/FreeShippingLarge';
import { FormHeading as Heading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import mq from '~/App/helpers/mq';

const Button = styled.button`
  ${primaryButton};
  width: 14rem;

  ${mq('<mediumLarge')`
    width: 10.8rem;
  `};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;

  &.content-appear-active {
    animation-name: ${slideDownAndBounce};
    animation-timing-function: ease-out;
    animation-duration: 1.4s;
  }

  &.content-exit {
    opacity: 1;
  }

  &.content-exit-active {
    opacity: 0;
    animation-name: ${slideDownAndBounce};
    animation-timing-function: ease-in;
    animation-duration: 1s;
    animation-direction: reverse;
  }

  &.content-exit-done {
    opacity: 0;
  }

  ${mq('<mediumLarge')`
    margin-top: 2.8125rem;

    &.content-appear {
      opacity: 0;
    }

    &.content-appear-active {
      animation-name: ${slideDownAndBounceMobile};
      animation-timing-function: ease-out;
      animation-duration: 1.1s;
      animation-delay: 330ms;
    }

    &.content-appear-done {
      opacity: 1;
    }

    &.content-exit {
      opacity: 1;
    }

    &.content-exit-active {
      opacity: 0;
      animation-name: ${slideDownAndBounceMobile};
      animation-timing-function: ease-in;
      animation-duration: 1.1s;
      animation-direction: reverse;
    }

    &.content-exit-done {
      opacity: 0;
    }
    `};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
`;

const Link = styled.p`
  ${link};
  text-decoration: underline;
  margin-top: 1rem;
  font-size: 1rem;
`;

export function EmptyShoppingCart() {
  const shoppingCart = useShoppingCart();
  const navigate = useNavigate();

  const handleToStoreClick = useCallback(() => {
    navigate('/gavoshop/privat');

    shoppingCart.close();
  }, [navigate, shoppingCart]);

  return (
    <Container>
      <CSSTransition
        in={shoppingCart.isOpen}
        appear={true}
        timeout={{ appear: 1430, enter: 1430, exit: 1400 }}
        classNames="content"
      >
        <Content>
          <ShoppingCartLarge />
          <Heading>Din varukorg är tom</Heading>
          <div>För att handla, besök vår gåvoshop.</div>
          <ButtonContainer>
            <Button
              onClick={handleToStoreClick}
              buttonStyle={buttonStyles.cta}
              children="Till gåvoshopen"
            />
            <Link onClick={shoppingCart.close} children="Stäng" />
          </ButtonContainer>
        </Content>
      </CSSTransition>
      <FreeShippingLarge />
    </Container>
  );
}
