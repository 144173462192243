import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { heartBeatAnimation } from '~/App/helpers/animations';
import mq from '~/App/helpers/mq';

import { CartIcon } from '../CartIcon';

import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { useFeatureToggle } from '~/App/contexts/FeatureToggles/FeatureToggle';

type CounterProps = {
  amount: number;
  cartAnimation: boolean;
};

const Counter = styled.div<CounterProps>`
  border-radius: 50%;
  color: white;
  background: ${({ amount }) => (amount < 1 ? '#001489' : '#ba0913')};
  position: absolute;
  z-index: 1;
  text-align: center;
  font-weight: bold;
  width: 1.5rem;
  height: 1.5rem;
  top: 1.8rem;
  right: 0;
  font-size: 16px;
  animation: ${({ cartAnimation }) =>
    cartAnimation
      ? css`
          ${heartBeatAnimation} 2s
        `
      : 'none'};

  ${mq('<mediumLarge')`
    width: 1.15rem;
    height: 1.15rem;
    top: 1.35rem;
    left: 0.75rem;
    font-size: 12px;
    `};
`;

const Container = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.themeColors.primary};

  ${mq('<mediumLarge')`
    margin-left: auto;
    margin-top: 0;
    font-size: 1.25rem;
  `};
`;

export function ShoppingCart() {
  const isActive = useFeatureToggle('FEATURE_COMMERCE');
  const shoppingCart = useShoppingCart();
  const itemAmount = shoppingCart.items.reduce(
    // eslint-disable-next-line no-param-reassign
    (total, current) => (total = total + current.quantity),
    0
  );
  const [prevCartAmount, setPrevCartAmount] = useState(itemAmount);
  const [cartAnimation, setCartAnimation] = useState(false);

  const isIncreasing = useCallback(
    (cartItemsAmount: number) => {
      if (cartItemsAmount > prevCartAmount) {
        setPrevCartAmount(cartItemsAmount);
        setCartAnimation(true);
        setTimeout(() => setCartAnimation(false), 1500);
        return true;
      } else if (cartItemsAmount < prevCartAmount) {
        setPrevCartAmount(cartItemsAmount);
        return false;
      }
      return false;
    },
    [prevCartAmount]
  );

  useEffect(() => {
    isIncreasing(itemAmount);
  }, [isIncreasing, itemAmount]);

  if (!isActive) {
    return null;
  }

  return (
    <Container
      data-cy="shoppingCart"
      onClick={shoppingCart.isOpen ? shoppingCart.close : shoppingCart.open}
    >
      <CartIcon />
      <Counter
        cartAnimation={cartAnimation}
        amount={itemAmount}
        children={`${itemAmount}`}
      ></Counter>
    </Container>
  );
}
