import React, { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import { ctaButton } from '~/App/helpers/mixins';

import { largeShareButton } from './LargeShareButtons.styled';

import { isAndroid, isIos } from '~/App/helpers/is-mobile-browser';
import { pushGTMEvent } from '~/App/helpers/gtm-helper';
import { gtmCustomEventCategories } from '~/App/config/gtmCustomEvents';
import { useAppContext } from '~/App/contexts/App';

const Button = styled.button`
  ${ctaButton};

  &:before {
    width: 20px;
    height: 20px;
    content: '';
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1605186584/assets/icons/sms.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  ${largeShareButton};
`;

type Props = {
  body: string;
};

export function Sms({ body }: Props) {
  const userAgent = useAppContext((x) => x.userAgent);

  const url = useMemo(() => {
    if (!body) {
      return null;
    }

    if (isAndroid(userAgent)) {
      return `sms:?body=${encodeURIComponent(body)}`;
    }

    if (isIos(userAgent)) {
      return `sms:&body=${encodeURIComponent(body)}`;
    }

    return null;
  }, [body, userAgent]);

  const handleClick = useCallback(() => {
    if (!url) {
      return;
    }

    pushGTMEvent({
      category: gtmCustomEventCategories.largeShareButtons._name,
      action: window.location.pathname,
      label: gtmCustomEventCategories.largeShareButtons.sms._name
    });

    window.location.href = url;
  }, [url]);

  if (!url || !body) {
    return null;
  }

  return <Button onClick={handleClick}>Dela med sms</Button>;
}
