import React, { ReactNode, useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';

// Shared components
import { RadioButton } from '~/App/shared/components/Fields/RadioButton';
import { IFavourablePaymentMethod } from '~/types/IPaymentMethod';
import { IValidation } from '~/App/shared/components/Validation';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '../../../../States';
import { PaymentMethod } from './PaymentMethod';
import { FetchBankAccountValues } from '../../FetchBankAccount';
import { paymentMethods } from '~/App/config/paymentMethods';
import { Error } from './Error';
import { getOffset } from '~/App/helpers/units-helper';
import { scrollAnimation } from '~/App/helpers/animations';

type WrapperProps = {
  checked: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  margin-bottom: 0.5rem;
  flex-basis: calc(100% - 0.5rem);
  border-radius: 2px;
  cursor: ${({ checked }) => (checked ? '' : 'pointer')};
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  padding: 1rem;

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${({ theme }) => theme.themeColors.primary};
    `};
`;

type HeaderProps = {
  checked: boolean;
};

const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  z-index: 1;
  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.themeColors.primary};
    `};
`;

type LabelProps = {
  isValid?: boolean;
  isInvalid?: boolean;
};

const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Content = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightDust};
  padding-top: 1rem;
  margin-top: 1rem;
`;

const Icon = styled.img`
  height: 30px;
  max-width: 72px;
`;

type Props = {
  method: IFavourablePaymentMethod;
  values: PaymentValues & ProductValues & ContactCustomerValues;
  validation: IValidation;
  fetchBankAccount: FetchBankAccountValues;
  formContent: {
    taxReductionInfoLink?: string;
  };
  children: ReactNode;
  pushGtmFormInteraction?: (
    method: IFavourablePaymentMethod,
    fieldName: string | undefined
  ) => void;
};

export function Item({
  values,
  method,
  validation,
  fetchBankAccount,
  children,
  pushGtmFormInteraction
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>();
  const isActive = useMemo(() => values.paymentMethod?.id === method.id, [
    method.id,
    values.paymentMethod?.id
  ]);

  const handleChange = useCallback(() => {
    if (isActive) return;

    values.handlers.handlePaymentMethod(method);

    if (pushGtmFormInteraction) {
      pushGtmFormInteraction(method, inputRef.current?.name);
    }

    setTimeout(() => {
      const container = ref.current;

      if (!container) return;

      const offset = getOffset(container);

      if (!offset) return;

      const offsetTop = offset.top;
      const $headerContainer = document.getElementById('headerContainer');
      const headerHeight = $headerContainer?.offsetHeight || 0;

      scrollAnimation(offsetTop - headerHeight - 32);
    }, 100);
  }, [
    method,
    ref,
    isActive,
    values.handlers,
    pushGtmFormInteraction,
    inputRef
  ]);

  const showSubmitButton = useMemo(() => {
    if (values.paymentMethod?.id === paymentMethods.autoGiroBankId) {
      if (values.mobileBankId.bank === 'CUSTOM') {
        return true;
      }

      if (!values.mobileBankId.accountNumber) {
        return false;
      }
      if (!values.mobileBankId.clearingNumber) {
        return false;
      }
    }

    return true;
  }, [
    values.mobileBankId.accountNumber,
    values.mobileBankId.bank,
    values.mobileBankId.clearingNumber,
    values.paymentMethod?.id
  ]);

  const content = useMemo(() => {
    if (!isActive) return null;

    return (
      <Content>
        <Error method={method} />
        <PaymentMethod
          method={method}
          values={values}
          validation={validation}
          fetchBankAccount={fetchBankAccount}
        />
        {showSubmitButton ? children : null}
      </Content>
    );
  }, [
    isActive,
    showSubmitButton,
    method,
    values,
    validation,
    fetchBankAccount,
    children
  ]);

  const icon = useMemo(() => {
    if (method.id === paymentMethods.swish) {
      return (
        <Icon src="https://res.cloudinary.com/cancerfonden/image/upload/v1607442186/assets/icons/swish-logo-secondary-light-bg-svg.svg" />
      );
    }

    if (method.id === paymentMethods.klarnaPayments) {
      return (
        <Icon src="https://res.cloudinary.com/cancerfonden/image/upload/v1633338141/assets/klarna/Klarna_Payment_Badge.svg" />
      );
    }

    return null;
  }, [method.id]);

  return useMemo(
    () => (
      <Wrapper checked={isActive} onClick={handleChange}>
        <Header checked={isActive} ref={ref}>
          <Label>
            <RadioButton
              value={method.id}
              checked={isActive}
              onChange={handleChange}
              name="paymentMethod"
              ref={inputRef}
            />
            <span children={method.name} />
          </Label>
          {icon}
        </Header>
        {content}
      </Wrapper>
    ),
    [
      content,
      isActive,
      handleChange,
      method.id,
      method.name,
      icon,
      ref,
      inputRef
    ]
  );
}
