export function theme(type) {
  return function (target) {
    if (target instanceof window.am4core.Label) {
      target.fill = window.am4core.color('#4a4a4a');
    }

    // only apply for XY charts
    if (type === 'XYChart') {
      if (
        target instanceof window.am4charts.AxisLabel &&
        target._className === 'AxisLabel'
      ) {
        target.adapter.add('dx', function (_dx, instance) {
          return -instance.maxRight / 2;
        });
      }
    }
  };
}
