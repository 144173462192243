import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Score } from '~/types/IQuestionSession';
import mq from '~/App/helpers/mq';

type ElementProps = {
  color: string;
};

const Element = styled.div<ElementProps>`
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  ::before {
    content: ' ';
    background-color: ${({ color }) => color};
    width: 1rem;
    height: 1rem;
    border-radius: 100px;

    ${mq('≥small')`
      width: 1.5rem;
      height: 1.5rem;
    `};
  }
`;

type Props = {
  value: Score;
};

export function ScoreIndicator({ value }: Props) {
  const color = useMemo(() => {
    if (value < 0) {
      return '#BA0913';
    }

    if (value > 0) {
      return '#316C05';
    }

    return '#DEB51A';
  }, [value]);

  return <Element color={color} />;
}
