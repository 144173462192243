import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ComponentProps
} from 'react';
import { toNumber } from 'lodash';

import { getListPrice } from '~/App/helpers/purchase-helper';

import { TaxReductionNudgingModal } from './TaxReductionNudging.Modal';
import { ISubmit } from '../Submits';
import { pushGTMEvent, pushGTMFormEvent } from '~/App/helpers/gtm-helper';
import { useScrollLock } from '~/App/contexts/ScrollLock';
import { paymentMethods } from '~/App/config/paymentMethods';
import { SubmitValues } from '../Submits/Submit/types/SubmitValues';

type Props = {
  name: string;
  values: SubmitValues;
  shouldNudge: boolean;
  validation: {
    isValidated: boolean;
    showAllErrors: () => void;
  };
  submit: ISubmit;
  maximumNudgeAmount?: number | null;
  getContent: ComponentProps<typeof TaxReductionNudgingModal>['getContent'];
  getRecommendedAmount: (
    amount: number,
    maximumNudgeAmount?: number | null
  ) => number | null;
  setAmount: (amount: number | null) => void;
  children: (submit: ISubmit) => ReactNode;
};

export function TaxReductionNudging({
  name,
  values,
  submit,
  shouldNudge,
  children,
  validation,
  setAmount,
  getContent,
  getRecommendedAmount,
  maximumNudgeAmount
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isNudged, setNudged] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);

  const isSent = useRef(false);
  const scrollLock = useScrollLock();

  const amount = useMemo(() => getListPrice(values.productOptions), [
    values.productOptions
  ]);

  const recommendedAmount = useMemo(() => {
    const value = toNumber(amount);
    if (isNaN(value)) return null;
    return getRecommendedAmount(value, maximumNudgeAmount);
  }, [amount, getRecommendedAmount, maximumNudgeAmount]);

  const handleSubmit = useCallback(
    async (event, partName?: string, buttonText?: string) => {
      event.persist();
      event.preventDefault();

      pushGTMFormEvent('formSubmit', {
        formName: values.gtm?.formName,
        amount: values.productOptions?.memorialGift
          ? values.productOptions?.memorialGift.amount
          : values.productOptions?.product?.price,
        period: values?.productOptions?.product?.period,
        step: values.partOptions?.currentPart,
        stepName: partName,
        paymentMethod:
          values.paymentMethod?.id === paymentMethods.klarnaPayments
            ? values.paymentMethod?.slug
            : values.paymentMethod?.name,
        buttonText
      });

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      if (isNudged || !shouldNudge) {
        return submit.handleSubmit(event);
      }

      if (recommendedAmount) {
        return setShowModal(true);
      }

      return submit.handleSubmit(event);
    },
    [
      isNudged,
      recommendedAmount,
      shouldNudge,
      submit,
      validation,
      values.gtm?.formName,
      values.productOptions.product.price,
      values.productOptions.product.period,
      values.partOptions?.currentPart,
      values.paymentMethod,
      values.productOptions?.memorialGift
    ]
  );

  const submitWithAmount = useCallback(
    (event) => {
      event.persist();
      setNudged(true);
      setShowModal(false);
      pushGTMEvent({
        category: `Nudge`,
        action: 'No',
        label: `${name} | Ge ${amount} och slutför`,
        value: 0
      });

      submit.handleSubmit(event);
    },
    [amount, name, submit]
  );

  const submitWithRecommendedAmount = useCallback(
    (event) => {
      event.persist();
      event.preventDefault();

      setNudged(true);
      setShowModal(false);
      setIsSubmitted(true);
      setSelectedAmount(recommendedAmount);

      pushGTMEvent({
        category: `Nudge`,
        action: 'Yes',
        label: `${name} | Ge ${recommendedAmount} och slutför`,
        value: (recommendedAmount ?? 0) - amount
      });

      setAmount(recommendedAmount);
    },
    [name, amount, recommendedAmount, setAmount]
  );

  const modal = useMemo(() => {
    if (!showModal) return null;
    if (!recommendedAmount) return null;

    return (
      <TaxReductionNudgingModal
        amount={amount}
        getContent={getContent}
        recommendedAmount={recommendedAmount}
        submitWithAmount={submitWithAmount}
        submitWithRecommendedAmount={submitWithRecommendedAmount}
      />
    );
  }, [
    amount,
    getContent,
    recommendedAmount,
    showModal,
    submitWithAmount,
    submitWithRecommendedAmount
  ]);

  const nextSubmit = useMemo(
    () => ({
      ...submit,
      isSending: showModal || isSubmitted,
      handleSubmit
    }),
    [handleSubmit, showModal, submit, isSubmitted]
  );

  const renderedChildren = useMemo(() => children(nextSubmit), [
    children,
    nextSubmit
  ]);

  useEffect(() => {
    if (isSent.current) return;
    if (!isSubmitted) return;
    if (selectedAmount !== amount) return;

    submit.handleSubmit();
    isSent.current = true;
  }, [amount, isSubmitted, recommendedAmount, selectedAmount, submit]);

  useEffect(() => {
    if (!submit.errors) return;

    setIsSubmitted(false);
    setSelectedAmount(null);

    isSent.current = false;
  }, [submit.errors]);

  useEffect(() => scrollLock.lock(showModal), [scrollLock, showModal]);

  return (
    <>
      {modal}
      {renderedChildren}
    </>
  );
}
