import React from 'react';
import styled from 'styled-components';

// Helpers
import {
  heading2,
  heading3,
  preamble,
  downloadLink
} from '~/App/helpers/mixins';
import mq from '~/App/helpers/mq';

// Constants
import { paymentMethods } from '~/App/config/paymentMethods';

// Shared components
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { Wrapper } from '~/App/shared/components/ThanksPage';

const Heading = styled.h2`
  ${heading2};
  margin-bottom: 1rem;
`;

const Heading2 = styled.h3`
  ${heading3};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const DownloadLinkContainer = styled.div`
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DownloadLink = styled.a`
  ${downloadLink};
`;

const StyledWrapper = styled(Wrapper)`
  margin-top: 1.5rem;
  text-align: center;

  ${mq('≥small')`
    margin-top: 1.5rem;
  `};
`;

const Content = styled.div`
  position: relative;
  max-width: 35.5rem;
  margin: 0 auto;
  padding: 3rem 0;
  text-align: center;
`;

export function MonthlyDonationThanksReceipt({ purchase, data }) {
  return (
    <StyledWrapper>
      <Content>
        <Heading>Ditt kvitto</Heading>
        <FetchPurchaseDocuments purchase={purchase}>
          {({ receiptStatus, receipt }) => (
            <>
              {purchase.paymentMethod.id !== paymentMethods.autoGiroSlip && (
                <>
                  <Preamble>
                    {data.texts.receiptText.replace(
                      '%%email%%',
                      purchase.purchase.productOptions.customerContact.email
                    )}
                  </Preamble>
                  <AsyncReceiptDocumentLink
                    url={receipt}
                    status={receiptStatus}
                    paymentMethodId={purchase?.paymentMethod?.id}
                  />
                </>
              )}
              {purchase.paymentMethod.id === paymentMethods.autoGiroSlip && (
                <>
                  <Heading2>{data.paymentSlipHeading}</Heading2>
                  <Preamble>
                    {data.texts.paymentSlipText.replace(
                      '%%email%%',
                      purchase.purchase.productOptions.customerContact.email
                    )}
                  </Preamble>
                  <DownloadLinkContainer>
                    <DownloadLink
                      rel="noopener noreferrer"
                      href={data.paymentSlipLink}
                      target="_blank"
                    >
                      {data.paymentSlipLinkText}
                    </DownloadLink>
                  </DownloadLinkContainer>
                  <AsyncReceiptDocumentLink
                    url={receipt}
                    status={receiptStatus}
                    paymentMethodId={purchase?.paymentMethod?.id}
                  />
                </>
              )}
            </>
          )}
        </FetchPurchaseDocuments>
      </Content>
    </StyledWrapper>
  );
}
