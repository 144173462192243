import styled, { css } from 'styled-components';

type Props = {
  percentageWidth?: string;
};

export const InputWrapper = styled.div`
  margin-top: 1rem;
  flex-grow: 0;
  flex-shrink: 0;

  ${(props: Props) =>
    props.percentageWidth === '66'
      ? css`
          flex-basis: calc(66.66% - 0.25rem);
        `
      : props.percentageWidth === '50'
      ? css`
          flex-basis: calc(50% - 0.25rem);
        `
      : props.percentageWidth === '33'
      ? css`
          flex-basis: calc(33.33% - 0.25rem);
        `
      : css`
          flex-basis: 100%;
        `};
`;