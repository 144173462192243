import React, { ReactNode, useCallback, useMemo } from 'react';
import { NavLink, To } from 'react-router-dom';
import { isObject, isString } from 'lodash';

import { assemble } from '~/lib/slug-helper';

type LookupItem = { slug?: string; contentType?: string };
type Props = {
  to: To | LookupItem;
  children: ReactNode;
  className?: string;
  target?: string;
  end?: boolean;
  onClick?: () => void;
};

export function Link({ to, children, className, end, target, onClick }: Props) {
  const isLookupItem = useCallback(
    (item: To | LookupItem): item is LookupItem => {
      const casted = item as LookupItem;

      return !!casted.slug || !!casted.contentType;
    },
    []
  );

  const url = useMemo(() => {
    if (!to) {
      return '';
    }

    if (isLookupItem(to)) {
      return assemble(to);
    }

    if (isString(to)) {
      return to;
    }

    return to;
  }, [isLookupItem, to]);

  const internal = /^\/(?!\/)/.test(`${url}`) || isObject(url);

  if (internal) {
    return (
      <NavLink
        to={url}
        className={className}
        end={end}
        children={children}
        target={target}
        onClick={onClick}
      />
    );
  }

  return (
    <a
      href={`${url}`}
      className={className}
      children={children}
      target={target}
      onClick={onClick}
    />
  );
}

export default Link;
