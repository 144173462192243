import { useMemo } from 'react';
import { ICommerceProduct } from '~/types/ICommerceProduct';
import { CommerceProductState } from './useCommerceProductState';

type Props = {
  state: CommerceProductState;
  product: ICommerceProduct;
};

export function useCommerceProductSchema({ product, state }: Props) {
  const variant = useMemo(
    () => product.variants?.find((x) => x.period === state.values.period),
    [product.variants, state.values.period]
  );

  const numericalitySchema = useMemo(() => {
    const minimum = variant?.price.minimum || 1;

    return {
      greaterThan: minimum ? minimum - 1 : 0,
      lessThanOrEqualTo: 999999,
      message: `^Minsta gåvobelopp är ${minimum} kr`
    };
  }, [variant?.price.minimum]);

  return useMemo(
    () => ({
      price: {
        presence: {
          allowEmpty: false,
          message: '^Ange ett belopp'
        },
        numericality: numericalitySchema
      },
      quantity: {
        numericality: {
          greaterThan: 0,
          message: `^Minsta kvantitet är 1`
        }
      },
      period: {
        presence: {
          allowEmpty: false,
          message: '^Interval måste vara satt'
        }
      },
      stockStatus: {
        inclusion: ['InStock', 'FewInStock', undefined]
      }
    }),
    [numericalitySchema]
  );
}
