import React from 'react';

import { IValidation } from '../../../Validation';
import { ProductValues } from '../States';

import { StepWrapper } from '../Forms/components/FormLayout';
import QuantitySelector from '../Forms/components/QuantitySelector';

type Value = string | number;

type Props = {
  validation: IValidation;
  values: ProductValues;
  customTitle?: string;
  options: Value[];
};

export function Quantity({ values, validation, customTitle, options }: Props) {
  return (
    <StepWrapper>
      <StepWrapper marginTop="tiny">
        <QuantitySelector
          quantities={options}
          selectedQuantity={values.productOptions.product.quantity}
          customQuantity={values.productOptions.product.customQuantity}
          customTitle={customTitle}
          setQuantity={values.handlers.setProductQuantity}
          setCustomQuantity={values.handlers.setProductCustomQuantity}
          resetQuantity={values.handlers.resetProductQuantity}
          validationKey={'productOptions.product.quantity'}
          validation={validation}
          tinyLayout="50"
          smallLayout="50"
          largeLayout="auto"
        />
      </StepWrapper>
    </StepWrapper>
  );
}
