import styled, { css } from 'styled-components';

import omitProps from '~/App/helpers/omit-props';
import mq from '~/App/helpers/mq';

import { NavLink } from '../NavLink';

export type NavItemProps = {
  hideOn?: string;
  subNavigation?: boolean;
  active?: boolean;
  align?: string;
  hide?: boolean;
};

export const NavItem = styled(
  omitProps('li', ['active', 'hideOn', 'subNavigation', 'align', 'hide'])
)<NavItemProps>`
  color: ${({ theme }) => theme.themeColors.primary};
  white-space: nowrap;
  transition: opacity 0.2s ease-out;

  ${mq('≥mediumLarge')`
        margin: 0 0.625rem;
    
        &:hover {
          ${NavLink}::after {
            visibility: visible;
            transform: scaleX(1);
          }
        }
      `};

  ${mq('<mediumLarge')`
        z-index: 1;
        position: relative;
      `};

  ${(props) =>
    props.hideOn &&
    css`
      ${mq(props.hideOn)`
            display: none;
          `};
    `};

  ${(props) =>
    props.active &&
    css<NavItemProps>`
      ${(props) =>
        props.subNavigation &&
        css`
          ${mq('≥mediumLarge')`
                ${NavLink} {
                  margin-bottom: 50px;
                }
              `};
        `};
    `};

  ${(props) =>
    props.align === 'right' &&
    css`
      margin-left: auto;
    `};

  ${(props) =>
    props.hide &&
    css`
      opacity: 0;
      pointer-events: none;
    `};
`;
