import { ChangeEvent, ChangeEventHandler } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { Period } from '~/types/IPurchase';
import { memorial } from '~/App/config/default-images';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues,
  GenericValues,
  AnalyticsValues
} from '~/App/shared/components/Donation/components/States';
import { MultiPartFormValues } from '~/App/shared/components/MultiPartForm';

import { useContactInformationValues } from '~/App/shared/components/Donation/components/States/useContactInformationValues';
import { useGenericValues } from '~/App/shared/components/Donation/components/States/useGenericValues';
import { usePaymentValues } from '~/App/shared/components/Donation/components/States/usePaymentValues';
import { useProductValues } from '~/App/shared/components/Donation/components/States/useProductValues';
import { useAnalyticsValues } from '~/App/shared/components/Donation/components/States/useAnalyticsValues';
import { DeepPartial } from 'ts-essentials';

type Props = {
  collectionId: number;
  formContent: {
    originPath: string;
    redirectPath: string;
    gtmId?: string;
  };
};

type BaseValues = PaymentValues &
  ContactCustomerValues &
  ProductValues &
  GenericValues &
  AnalyticsValues &
  DeepPartial<MultiPartFormValues>;

export type CreateMemoryStateValues = BaseValues & {
  productOptions: {
    memory: {
      image: string;
      content: string;
      by: string;
      period: Period | null;
    };
  };
  handlers: {
    setMemorialPageMemoryImage: (value: string) => void;
    setMemorialPageMemoryPeriod: (value: Period | null) => void;
    handleMemorialPageMemoryContent: ChangeEventHandler<HTMLTextAreaElement>;
    handleMemorialPageMemoryBy: ChangeEventHandler<HTMLInputElement>;
  };
};

type State = {
  image: string;
  content: string;
  by: string;
  period: Period | null;
};

const CAMPAIGN_ACTIVITY_ID = 3249;

export function useCreateMemoryState({ formContent, collectionId }: Props) {
  const [state, setState] = useState<State>({
    image: memorial[0],
    content: '',
    by: '',
    period: 'Month'
  });

  const isAuthenticated = useAuthenticationContext((x) => x.isAuthenticated);
  const generic = useGenericValues({
    productOptions: {
      collectionId,
      pul: true,
      product: {
        period: 'Month' as Period,
        price: 150
      },
      campaignActivityId: CAMPAIGN_ACTIVITY_ID
    }
  });

  const product = useProductValues({
    ...generic,
    productOptions: {
      ...generic.productOptions,
      product: {
        ...generic.productOptions.product,
        price: 200
      }
    }
  });

  const payment = usePaymentValues({
    values: product,
    formContent
  });

  const contactInformation = useContactInformationValues(payment);

  const analytics = useAnalyticsValues({
    values: contactInformation,
    formContent
  });

  const handleMemorialPageMemoryContent = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      event.persist();

      setState((state) => ({
        ...state,
        content: event.target.value
      }));
    },
    []
  );

  const handleMemorialPageMemoryBy = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setState((state) => ({
        ...state,
        by: event.target.value
      }));
    },
    []
  );

  const setMemorialPageMemoryPeriod = useCallback(
    (period: Period | null) => {
      setState((state) => ({ ...state, period }));
      product.handlers.setProductPrice(period === 'Once' ? 500 : 200);
      product.handlers.setProductPeriod(period === 'Once' ? 'Once' : 'Month');
      product.handlers.setProductId(period === 'Month' ? 31 : 3);
    },
    [product.handlers]
  );

  const setMemorialPageMemoryImage = useCallback(
    (image: string) => setState((state) => ({ ...state, image })),
    []
  );

  return useMemo<CreateMemoryStateValues>(
    () => ({
      ...analytics,
      isAuthenticated,
      productOptions: {
        ...analytics.productOptions,
        memory: state
      },
      handlers: {
        ...analytics.handlers,
        setMemorialPageMemoryImage,
        setMemorialPageMemoryPeriod,
        handleMemorialPageMemoryContent,
        handleMemorialPageMemoryBy
      }
    }),
    [
      isAuthenticated,
      analytics,
      state,
      setMemorialPageMemoryImage,
      handleMemorialPageMemoryContent,
      handleMemorialPageMemoryBy,
      setMemorialPageMemoryPeriod
    ]
  );
}
