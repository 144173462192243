import React, { useEffect, useMemo } from 'react';

import { useOverlayContext } from '~/App/contexts/Overlay';
import { useScrollLock } from '~/App/contexts/ScrollLock';

import { useImages } from './hooks/useImages';
import { Modal } from './components/Modal';

const isWindowContext = typeof window !== 'undefined';
export function Overlay() {
  const { overlay, dismiss } = useOverlayContext();
  const images = useImages({ overlay });
  const scrollLock = useScrollLock();
  const dontDisplaySwedishEngineerOverlay =
    isWindowContext &&
    !(
      window?.location.hostname === 'cf.se' ||
      window?.location.hostname === 'preview.cf.se'
    ) &&
    overlay?.id === '628b3b9fd37de50004e5e07d';

  const isReady = useMemo(() => !!(overlay && images.completed), [
    images.completed,
    overlay
  ]);

  useEffect(
    () => scrollLock.lock(isReady && !dontDisplaySwedishEngineerOverlay),
    [dontDisplaySwedishEngineerOverlay, isReady, scrollLock]
  );

  return useMemo(() => {
    if (!overlay) {
      return null;
    }

    if (!images.completed) {
      return null;
    }

    if (dontDisplaySwedishEngineerOverlay) {
      return null;
    }

    return <Modal overlay={overlay} images={images} onDismiss={dismiss} />;
  }, [overlay, images, dontDisplaySwedishEngineerOverlay, dismiss]);
}
