import React, { useMemo } from 'react';
import styled from 'styled-components';

import * as MultiPartForm from '~/App/shared/components/MultiPartForm';

import { Product } from './Product';
import { Payment } from './Payment';

import { SubmitButton } from '../../components/Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '../../components/Forms/components/SwishOtherDeviceButton';
import { IntegrityTextArea } from '../../components/Forms/components/IntegrityTextArea';
import { getPurchaseErrorMessage } from '../../components/Forms/helpers';
import { AlertMessageContainer } from '../../../Elements';
import { IValidation } from '../../../Validation';
import { ISubmit } from '../../components/Submits';
import { Layout } from '~/types/Layout';
import {
  AnalyticsValues,
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '../../components/States';

const IntegrityTextAreaWrapper = styled.div`
  margin: 0.5rem 0;
`;

type Values = ContactCustomerValues &
  PaymentValues &
  ProductValues &
  MultiPartForm.MultiPartFormValues &
  AnalyticsValues;

type FormContent = {
  anotherDeviceText?: string;
  prices: Record<number, number[]>;
  defaultPrice: Record<number, number>;
  taxReductionInfoLink?: string | undefined;
  layout?: {
    amountSelector?: {
      tinyLayout: Layout;
      smallLayout: Layout;
      largeLayout: Layout;
    };
  };
};

type Props = {
  values: Values;
  formContent: FormContent;
  validation: IValidation;
  submit: ISubmit;
};

export function Form({ formContent, values, validation, submit }: Props) {
  const submitButtons = useMemo(
    () => (
      <>
        {submit.errors && !submit.isPolling && !submit.isSending && (
          <AlertMessageContainer messageStyle="error">
            {getPurchaseErrorMessage(submit.exceptionCode)}
          </AlertMessageContainer>
        )}
        <SubmitButton values={values} validation={validation} submit={submit} />
        <SwishOtherDeviceButton
          values={values}
          text={formContent.anotherDeviceText}
        />

        <IntegrityTextAreaWrapper>
          <IntegrityTextArea values={values} />
        </IntegrityTextAreaWrapper>
      </>
    ),
    [formContent.anotherDeviceText, submit, validation, values]
  );

  return (
    <form onSubmit={submit.handleSubmit} noValidate>
      <MultiPartForm.Section
        title="Hur ofta vill du ge?"
        part={1}
        values={values}
        validation={validation}
        nextStepButton={{ text: 'Till betalsätt' }}
      >
        <Product
          values={values}
          formContent={formContent}
          validation={validation}
        />
      </MultiPartForm.Section>

      <MultiPartForm.Section
        title="Välj betalsätt"
        part={2}
        values={values}
        validation={validation}
      >
        <Payment
          values={values}
          formContent={formContent}
          validation={validation}
          submitButtons={submitButtons}
        />
      </MultiPartForm.Section>
    </form>
  );
}
