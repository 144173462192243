import React, { Fragment, useMemo, useState, useCallback } from 'react';

// Shared components
import * as TwoColumn from '../../components/Forms/components/TwoColumn';
import * as Steps from '../../components/Steps';
import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';

import { GenericCertificateSummary } from '../../components/Forms/components/Summaries';
import { NewFormHeading } from '../../components/Forms/components/FormTypography';

import { Payment } from './Payment';
import { SubmitButton } from '../../components/Forms/components/SubmitButton';
import { SwishOtherDeviceButton } from '../../components/Forms/components/SwishOtherDeviceButton';
import { GenericCertificatePreview } from '../../components/Forms/components/CertificatePreviews';
import { PreviewModal } from '../../components/Forms/components/PreviewModal';
import { MultiPartFormValues, Section } from '../../../MultiPartForm';
import { TaxReductionInfo } from '../../components/Forms/components/TaxReductionInfo';
import { ISubmit } from '../../components/Submits';
import { IValidation } from '../../../Validation';
import {
  CertificateValues,
  ContactCustomerValues,
  PaymentValues,
  ProductValues,
  AnalyticsValues
} from '../../components/States';
import { AlertMessageContainer } from '../../../Elements';
import { getPurchaseErrorMessage } from '../../components/Forms/helpers';
import { IntegrityTextArea } from '../../components/Forms/components/IntegrityTextArea';
import styled from 'styled-components';

const IntegrityTextAreaWrapper = styled.div`
  margin: 0.5rem 0;
`;

type Props = {
  formContent: any;
  values: ContactCustomerValues &
    PaymentValues &
    ProductValues &
    CertificateValues &
    MultiPartFormValues &
    AnalyticsValues;
  validation: IValidation;
  submit: ISubmit;
};

export function Form({ formContent, validation, values, submit }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const togglePreviewModal = useCallback(() => setModalOpen((x) => !x), []);

  const props = useMemo(
    () => ({
      formContent,
      validation,
      values,
      submit
    }),
    [formContent, submit, validation, values]
  );

  const submitButtons = useMemo(
    () => (
      <>
        {submit.errors && !submit.isPolling && !submit.isSending && (
          <AlertMessageContainer messageStyle="error">
            {getPurchaseErrorMessage(submit.exceptionCode)}
          </AlertMessageContainer>
        )}
        <SubmitButton values={values} validation={validation} submit={submit} />
        <SwishOtherDeviceButton
          values={values}
          text={formContent.anotherDeviceText}
        />

        <IntegrityTextAreaWrapper>
          <IntegrityTextArea values={values} />
        </IntegrityTextAreaWrapper>
      </>
    ),
    [formContent.anotherDeviceText, submit, validation, values]
  );

  return useMemo(
    () => (
      <TwoColumn.Wrapper>
        <TwoColumn.LeftColumn>
          {modalOpen && (
            <PreviewModal closeModal={togglePreviewModal}>
              <GenericCertificatePreview
                imageUrl={values?.productOptions?.certificate?.illustration}
                heading={values?.productOptions?.certificate?.heading}
                greeting={values?.productOptions?.certificate?.textGreeting}
                description={formContent?.certificateInfo?.description}
                fullSize={true}
              />
            </PreviewModal>
          )}

          <form onSubmit={submit.handleSubmit} noValidate>
            <Section
              {...props}
              part={1}
              title="Anpassa ditt gåvobevis"
              nextStepButton={{ text: 'Till belopp' }}
            >
              <Steps.GenericCertificateCustomization
                {...props}
                togglePreviewModal={togglePreviewModal}
              />
            </Section>
            <Section
              {...props}
              part={2}
              title="Välj belopp"
              nextStepButton={{ text: 'Till information om mottagare' }}
            >
              <Steps.Amount {...props} />
              <TaxReductionInfo
                amount={getListPrice(values.productOptions)}
                quantity={getQuantity(values.productOptions)}
              />
            </Section>
            <Section
              {...props}
              part={3}
              title="Skicka till mottagare"
              nextStepButton={{ text: 'Till betalsätt' }}
            >
              <Steps.GenericCertificateDeliveryRecipient {...props} />
            </Section>
            <Section {...props} part={4} title="Välj betalsätt">
              <Payment
                submitButtons={submitButtons}
                values={values}
                validation={validation}
                formContent={{
                  ...formContent
                }}
              />
            </Section>
          </form>
        </TwoColumn.LeftColumn>
        <TwoColumn.RightColumn>
          <TwoColumn.ColumnCard>
            <NewFormHeading>Ditt gåvobevis</NewFormHeading>
            <GenericCertificateSummary
              previewFields={{
                imageUrl: values?.productOptions?.certificate?.illustration,
                heading: values?.productOptions?.certificate?.heading,
                greeting: values?.productOptions?.certificate?.textGreeting,
                description: formContent?.certificateInfo?.description
              }}
              price={values.productOptions?.product?.price}
              infoText={formContent?.helpText}
              openPreviewModal={togglePreviewModal}
            />
          </TwoColumn.ColumnCard>
        </TwoColumn.RightColumn>
      </TwoColumn.Wrapper>
    ),
    [
      formContent,
      modalOpen,
      props,
      submit.handleSubmit,
      submitButtons,
      togglePreviewModal,
      validation,
      values
    ]
  );
}
