import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { assemble } from '~/lib/slug-helper';

class TwoColumn extends React.Component {
  render() {
    if (_.isEmpty(this.props.related)) {
      return null;
    }

    var heading = this.props.heading
      ? 'Läs mer om ' + this.props.heading
      : 'Läs mer';

    return (
      <div className="relatedBox">
        <div className="relatedBox__heading">{heading}</div>
        <div className="relatedBox__list">
          {_.map(this.props.related, function (related, index) {
            return (
              <div
                className="relatedBox__listItem related__listItem--twoColumn"
                key={related.slug + '-' + index}
              >
                <Link className="relatedBox__link" to={assemble(related)}>
                  {related.teaserHeading || related.heading}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

TwoColumn.displayName = 'RelatedBoxTwoColumn';

TwoColumn.defaultProps = {
  heading: '',
  related: []
};

export default TwoColumn;
