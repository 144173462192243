import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useMatchMedia } from '~/App/shared/hooks/use-match-media';
import mediaHelper from '~/App/helpers/media-helper';

type WrapperProps = {
  isVisible: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Player = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
`;

type Props = {
  source: string;
  matchMedia: string;
};

export function Video({ source, matchMedia }: Props) {
  const [isVisible, setVisible] = useState(false);

  const ref = useRef<HTMLVideoElement | null>(null);
  const shouldPlay = useMatchMedia(matchMedia, false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    shouldPlay ? ref.current.play() : ref.current.pause();
    setVisible(!!shouldPlay);
  }, [shouldPlay]);

  return useMemo(
    () => (
      <Wrapper isVisible={isVisible}>
        <Player
          id="heroVideo"
          ref={ref}
          aria-hidden="true"
          role="presentation"
          tabIndex={-1}
          loop={true}
          muted={true}
          playsInline={true}
        >
          <source
            src={mediaHelper.getUrl(
              source,
              {
                audio_codec: 'none',
                video_codec: 'h264'
              },
              'mp4'
            )}
            type="video/mp4"
          />
          <source
            src={mediaHelper.getUrl(
              source,
              {
                audio_codec: 'none',
                video_codec: 'vp8'
              },
              'webm'
            )}
            type="video/webm"
          />
        </Player>
      </Wrapper>
    ),
    [isVisible, source]
  );
}
