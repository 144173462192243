import React, { ReactNode } from 'react';
import styled from 'styled-components';

// Config
import { buttonStyles } from '~/App/config/buttonStyles';

//Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';

const Button = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 1.5rem;
`;

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export function PreviewButton({ children, onClick }: Props) {
  return (
    <Button
      buttonType="button"
      buttonStyle={buttonStyles.outlinePrimary}
      onClick={onClick}
      children={<span>{children}</span>}
    />
  );
}
