import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { card } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

import { Number } from './Number';
import { Heading } from './Heading';

const Card = styled.div`
  ${card};
  padding: 0 !important;
  border-radius: 0.25rem;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const Padding = styled.div`
  padding: 0 1.5rem 1rem;

  ${mq('≥small')`
  padding: 0 2.5rem 2.5rem 2.5rem;
`};
`;

const Header = styled.div`
  display: flex;
`;

const Error = styled(Padding)`
  padding-top: 1rem;
  background-color: ${({ theme }) => theme.colors.lightRed};
  color: ${({ theme }) => theme.colors.errorRed};
`;

export type ContentProps = {
  id: string;
  part: number;
  isActive: boolean;
  isValid: boolean;
  isInvalid: boolean;
  isStarted: boolean;

  heading: ReactNode;
  subHeading: ReactNode;
  summery: ReactNode;
  children: ReactNode;

  onEdit: () => void;
};

export function Content({
  id,
  part,
  heading,
  subHeading,
  isActive,
  isValid,
  isStarted,
  isInvalid,
  children,
  summery,
  onEdit
}: ContentProps) {
  const showChildren = useMemo(() => {
    if (!isActive) return false;
    if (!children) return false;

    return true;
  }, [children, isActive]);

  const showSummery = useMemo(() => {
    if (isActive) return false;
    if (!isValid) return false;
    if (!isStarted) return false;
    if (!summery) return false;

    return true;
  }, [isActive, isStarted, isValid, summery]);

  const showError = useMemo(() => {
    if (!isStarted) return false;
    if (isActive) return false;
    if (!isInvalid) return false;

    return true;
  }, [isActive, isInvalid, isStarted]);

  return useMemo(
    () => (
      <Card id={id}>
        <Header>
          <Number
            children={part}
            isValid={isValid}
            isActive={isActive}
            isInvalid={isInvalid}
            isStarted={isStarted}
          />
          <Heading
            heading={heading}
            subHeading={subHeading}
            isActive={isActive}
            isValid={isValid}
            isStarted={isStarted}
            isInvalid={isInvalid}
            onEdit={onEdit}
          />
        </Header>
        {showChildren && <Padding children={children} />}
        {showSummery && <Padding children={summery} />}
        {showError && (
          <Error children="Glöm inte att fylla i all information." />
        )}
      </Card>
    ),
    [
      id,
      part,
      isValid,
      isInvalid,
      isStarted,
      heading,
      subHeading,
      isActive,
      onEdit,
      showChildren,
      children,
      showSummery,
      summery,
      showError
    ]
  );
}
