import React from 'react';
import styled from 'styled-components';

const DropdownIndicatorWrapper = styled.div`
  color: white;
  padding-right: 0.5rem;
`;

const ArrowImage = styled.img`
  width: 15px;
`;

type Props = {
  selectProps: {
    menuIsOpen: boolean;
  };
};

export function DropdownIndicator({ selectProps }: Props) {
  return (
    <DropdownIndicatorWrapper>
      <ArrowImage
        src={
          selectProps.menuIsOpen
            ? '/images/arrow-up-white.png'
            : '/images/arrow-down-white.png'
        }
      />
    </DropdownIndicatorWrapper>
  );
}
