import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  MouseEvent
} from 'react';

import { merge } from 'lodash';
import { useLocation } from 'react-router';
import styled, { css, ThemeProvider } from 'styled-components';

import { IPrimaryMenuItem, navigation } from '~/App/config/navigation';
import { isActive } from '~/App/helpers/menu-item-is-active';

import * as themes from '~/App/config/themes';

// Helpers
import mq from '~/App/helpers/mq';
import { NavigationItem } from '../NavigationItem';
import { CaretIcon } from './components/CaretIcon';
import { SubNavigation } from './components/SubNavigation';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

type ListProps = {
  visible?: boolean;
};

export const List = styled.ul<ListProps>`
  display: flex;
  flex-direction: column;

  ${mq('≥mediumLarge')`
    margin-right: auto;
    flex-direction: row;
    order: 1;
    width: 100%;
    padding-left: 0.375rem;
    background-color: ${({ theme }) => theme.colors.white};
  `};

  ${mq<ListProps>(`<mediumLarge`)`
    position: fixed;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: scroll;
    top: 115px;
    left: 0;
    z-index: 6;
    background-color: ${({ theme }) => theme.colors.white};
    border-right: 1px solid ${({ theme }) => theme.colors.alto};
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    padding-bottom: 8rem;

    ${({ visible }) =>
      visible &&
      css`
        transform: translateX(0);
      `};
  `};
`;

type Props = {
  visible: boolean;
  toggleVisible: () => void;
};

export function PrimaryNavigation({ visible, toggleVisible }: Props) {
  const location = useLocation();

  const isAuthenticated = useAuthenticationContext(
    ({ isAuthenticated }) => isAuthenticated
  );

  const getOpen = useCallback(
    () =>
      navigation.primaryMenuItems.reduce(
        (prev, current, index) => ({
          ...prev,
          [index]: isActive({
            url: current.url,
            pathname: location.pathname,
            alternativeActiveUrls: current.alternativeActiveUrls
          })
        }),
        {}
      ),
    [location.pathname]
  );

  const toggleOpen = useCallback(
    (key: number) => (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();

      setOpen((state) => ({
        ...state,
        [key]: !state[key]
      }));
    },
    []
  );

  const [isOpen, setOpen] = useState<Record<number, boolean>>(getOpen);

  const renderItem = useCallback(
    (item: IPrimaryMenuItem, index) => {
      const customTheme = item.theme in themes ? themes[item.theme] : {};
      const theme = merge({}, themes.global, customTheme);
      const subNavigation = item.menuItems?.length > 0;

      return (
        <ThemeProvider key={index} theme={theme}>
          <NavigationItem
            item={{
              ...item,
              highlighted: false
            }}
            onClick={toggleVisible}
          >
            {subNavigation && (
              <CaretIcon open={isOpen[index]} onClick={toggleOpen(index)} />
            )}

            {subNavigation && isOpen[index] && (
              <SubNavigation
                items={item.menuItems}
                toggleVisible={toggleVisible}
              />
            )}
          </NavigationItem>
        </ThemeProvider>
      );
    },
    [isOpen, toggleOpen, toggleVisible]
  );

  const items = useMemo<IPrimaryMenuItem[]>(() => {
    const search: IPrimaryMenuItem = {
      url: '/sok',
      text: 'Sök',
      icon: '',
      theme: 'standard',
      menuItems: [],
      hideOn: '≥mediumLarge'
    };

    const myPage: IPrimaryMenuItem = {
      url: '/min-sida',
      text: 'Min sida',
      icon: '',
      theme: 'standard',
      menuItems: [],
      hideOn: '≥mediumLarge'
    };

    if (isAuthenticated) {
      return [...navigation.primaryMenuItems, search];
    }

    return [...navigation.primaryMenuItems, search, myPage];
  }, [isAuthenticated]);

  useEffect(() => setOpen(getOpen), [getOpen]);

  return useMemo(
    () => <List visible={visible} children={items.map(renderItem)} />,
    [items, renderItem, visible]
  );
}
