import React, { useEffect, useMemo, useState } from 'react';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import styled from 'styled-components';

import mediaHelper from '~/App/helpers/media-helper';
import { IImageSource } from '~/types/IImage';

type ImageProps = {
  binary: string | null;
  url?: string;
  darkBackground: boolean;
};

const getImage = ({ binary, url }: ImageProps) =>
  binary ? `data:image/png;base64,${binary}` : url;

const Image = styled.div<ImageProps>`
  overflow: hidden;
  background-color: ${({ theme, darkBackground }) =>
    darkBackground ? theme.colors.charcoal : 'none'};
  background-image: url(${getImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-top: 56.25%;
`;

type Props = {
  imageSize?: '16/9' | '4/4';
  imageAlt: string;
  image: IImageSource;
  width: number;
};

export function ImageMedia({ image, imageSize, imageAlt }: Props) {
  const [binary, setBinary] = useState<string | null>(null);

  const url = useMemo(
    () =>
      image?.secure_url
        ? mediaHelper.getUrl(image.secure_url, {
            width: 776,
            aspect_ratio: imageSize === '16/9' ? '16:9' : '4:4'
          })
        : null,
    [image, imageSize]
  );

  useEffect(() => {
    if (binary) return;
    if (!url) return;

    const options: AxiosRequestConfig = {
      responseType: 'arraybuffer'
    };

    const handleSuccess = ({ data }: AxiosResponse<string>) => {
      setBinary(Buffer.from(data, 'binary').toString('base64'));
    };

    const handleError = () => {
      setBinary(null);
    };

    Axios.get<string>(url, options).then(handleSuccess).catch(handleError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!url) {
    return null;
  }

  return (
    <Image
      url={url}
      binary={binary}
      role="img"
      darkBackground={imageSize === '4/4'}
      aria-label={imageAlt}
    />
  );
}
