import _ from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Helpers
import { siteUrl } from '~/App/helpers/env';

import Grid from '~/App/shared/components/Grid';
import Intro from '~/App/shared/components/Intro';
import Section from '~/App/shared/components/Section';
import Jumbotron from '~/App/shared/components/Jumbotron';
import SuperHero from '~/App/shared/components/SuperHero';
import ArticleThumb from '~/App/shared/components/ArticleThumb';
import FeaturedBrick from '~/App/shared/components/FeaturedBrick';

export function Podcasts({
  data: {
    contentDefinitions: {
      meta = {},
      podcastsHero: hero,
      podcastsIntro: intro,
      podcastsBrick1: brick1,
      podcastsChapters: chapters,
      podcastsBrick2: brick2,
      podcastsBrick3: brick3
    } = {}
  } = {}
}) {
  const location = useLocation();
  return (
    <div>
      <Helmet>
        <title>{meta.pageTitle}</title>
        <link rel="canonical" href={siteUrl(location.pathname)} />
        <meta name="description" content={meta.pageDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:image" content={meta.ogImage} />
        <meta property="og:description" content={meta.ogDescription} />
      </Helmet>
      {_.get(hero, 'hidden', false) === 'visible' && (
        <SuperHero
          size={_.get(hero, 'data.heroSize', undefined)}
          image={_.get(hero, 'image.secure_url', '')}
          backgroundSize={_.get(hero, 'data.backgroundSize', undefined)}
          contentPosition={_.get(hero, 'heroAlignContent', '')}
          backgroundColor={_.get(hero, 'data.backgroundColor', 'transparent')}
          backgroundPosition={_.get(hero, 'heroAlignBackground', '')}
          backgroundModifier={_.get(hero, 'heroBackgroundModifier', '')}
          content={
            <Jumbotron
              heading={_.get(hero, 'heading', '')}
              preamble={_.get(hero, 'preamble', '')}
              textColor={_.get(hero, 'heroTextColor', 'white')}
              buttonLink={_.get(hero, 'buttonLink', '')}
              buttonLabel={_.get(hero, 'buttonLabel', '')}
              description={_.get(hero, 'description', '')}
              preambleShort={_.get(hero, 'preambleShort', '')}
              headingFontSize={_.get(hero, 'headingFontSize', undefined)}
              headingFontWeight={_.get(hero, 'headingFontWeight', undefined)}
            />
          }
        />
      )}

      {_.get(intro, 'hidden', false) === 'visible' ? (
        <Section modifier={_.get(intro, 'data.modifier', '')} id={intro.slug}>
          <Intro
            heading={_.get(intro, 'heading', undefined)}
            preamble={_.get(intro, 'preamble', undefined)}
            maxWidth={_.get(intro, 'data.maxWidth', undefined)}
            textAlign={_.get(intro, 'data.textAlign', undefined)}
            description={_.get(intro, 'description', undefined)}
            headingFontSize={_.get(intro, 'headingFontSize', undefined)}
            headingFontWeight={_.get(intro, 'headingFontWeight', undefined)}
            preambleFontWeight={_.get(
              intro,
              'data.preambleFontWeight',
              undefined
            )}
          />
        </Section>
      ) : null}

      {_.get(brick1, 'hidden', false) === 'visible' ? (
        <Section modifier={_.get(brick1, 'data.modifier', '')} id={brick1.slug}>
          <FeaturedBrick
            data={_.get(brick1, 'data', undefined)}
            image={_.get(brick1, 'image', undefined)}
            embed={_.get(brick1, 'embed', undefined)}
            heading={_.get(brick1, 'heading', undefined)}
            hasMedia={_.get(brick1, 'data.hasMedia', true)}
            preamble={_.get(brick1, 'preamble', undefined)}
            maxWidth={_.get(brick1, 'data.maxWidth', undefined)}
            textAlign={_.get(brick1, 'data.textAlign', undefined)}
            textWidth={_.get(brick1, 'data.textWidth', undefined)}
            imageSize={_.get(brick1, 'data.imageSize', undefined)}
            imageWidth={_.get(brick1, 'data.imageWidth', undefined)}
            linkLayout={_.get(brick1, 'data.linkLayout', undefined)}
            buttonLink={_.get(brick1, 'buttonLink', undefined)}
            buttonLabel={_.get(brick1, 'buttonLabel', undefined)}
            description={_.get(brick1, 'description', undefined)}
            imagePosition={_.get(brick1, 'data.imagePosition', undefined)}
            headingFontSize={_.get(brick1, 'headingFontSize', undefined)}
            headingFontWeight={_.get(brick1, 'headingFontWeight', undefined)}
            preambleFontWeight={_.get(
              brick1,
              'data.preambleFontWeight',
              undefined
            )}
          />
        </Section>
      ) : null}

      {_.get(chapters, 'hidden', false) === 'visible' ? (
        <Section
          modifier={_.get(chapters, 'data.modifier', '')}
          id={chapters.slug}
        >
          <Grid block="packer">
            <Intro
              heading={_.get(chapters, 'heading', undefined)}
              preamble={_.get(chapters, 'preamble', undefined)}
              maxWidth={_.get(chapters, 'data.maxWidth', undefined)}
              textAlign={_.get(chapters, 'data.textAlign', undefined)}
              description={_.get(chapters, 'description', undefined)}
              headingFontSize={_.get(chapters, 'headingFontSize', undefined)}
              headingFontWeight={_.get(
                chapters,
                'headingFontWeight',
                undefined
              )}
              preambleFontWeight={_.get(
                chapters,
                'data.preambleFontWeight',
                undefined
              )}
            />
            <div className="threeUpArticleFeed threeUpArticleFeed--slimTop">
              <ul className="threeUpArticleFeed__list">
                {chapters.related.map((chapter, key) => (
                  <li className="threeUpArticleFeed__item" key={key}>
                    <ArticleThumb
                      link={chapter.url}
                      image={_.get(chapter, 'image.secure_url', '')}
                      label={chapter.teaserLabel}
                      heading={chapter.heading}
                      preamble={chapter.preamble}
                      publishedDate={chapter.publishedDate || chapter.createdAt}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        </Section>
      ) : null}

      {_.get(brick2, 'hidden', false) === 'visible' ? (
        <Section modifier={_.get(brick2, 'data.modifier', '')} id={brick2.slug}>
          <FeaturedBrick
            data={_.get(brick2, 'data', undefined)}
            image={_.get(brick2, 'image', undefined)}
            embed={_.get(brick2, 'embed', undefined)}
            heading={_.get(brick2, 'heading', undefined)}
            hasMedia={_.get(brick2, 'data.hasMedia', true)}
            preamble={_.get(brick2, 'preamble', undefined)}
            maxWidth={_.get(brick2, 'data.maxWidth', undefined)}
            textAlign={_.get(brick2, 'data.textAlign', undefined)}
            textWidth={_.get(brick2, 'data.textWidth', undefined)}
            imageSize={_.get(brick2, 'data.imageSize', undefined)}
            imageWidth={_.get(brick2, 'data.imageWidth', undefined)}
            linkLayout={_.get(brick2, 'data.linkLayout', undefined)}
            buttonLink={_.get(brick2, 'buttonLink', undefined)}
            buttonLabel={_.get(brick2, 'buttonLabel', undefined)}
            description={_.get(brick2, 'description', undefined)}
            imagePosition={_.get(brick2, 'data.imagePosition', undefined)}
            headingFontSize={_.get(brick2, 'headingFontSize', undefined)}
            headingFontWeight={_.get(brick2, 'headingFontWeight', undefined)}
            preambleFontWeight={_.get(
              brick2,
              'data.preambleFontWeight',
              undefined
            )}
          />
        </Section>
      ) : null}

      {_.get(brick3, 'hidden', false) === 'visible' ? (
        <Section modifier={_.get(brick3, 'data.modifier', '')} id={brick3.slug}>
          <FeaturedBrick
            data={_.get(brick3, 'data', undefined)}
            image={_.get(brick3, 'image', undefined)}
            embed={_.get(brick3, 'embed', undefined)}
            heading={_.get(brick3, 'heading', undefined)}
            hasMedia={_.get(brick3, 'data.hasMedia', true)}
            preamble={_.get(brick3, 'preamble', undefined)}
            maxWidth={_.get(brick3, 'data.maxWidth', undefined)}
            textAlign={_.get(brick3, 'data.textAlign', undefined)}
            textWidth={_.get(brick3, 'data.textWidth', undefined)}
            imageSize={_.get(brick3, 'data.imageSize', undefined)}
            imageWidth={_.get(brick3, 'data.imageWidth', undefined)}
            linkLayout={_.get(brick3, 'data.linkLayout', undefined)}
            buttonLink={_.get(brick3, 'buttonLink', undefined)}
            buttonLabel={_.get(brick3, 'buttonLabel', undefined)}
            description={_.get(brick3, 'description', undefined)}
            imagePosition={_.get(brick3, 'data.imagePosition', undefined)}
            headingFontSize={_.get(brick3, 'headingFontSize', undefined)}
            headingFontWeight={_.get(brick3, 'headingFontWeight', undefined)}
            preambleFontWeight={_.get(
              brick3,
              'data.preambleFontWeight',
              undefined
            )}
          />
        </Section>
      ) : null}
    </div>
  );
}
