import React, { useState, useMemo, useCallback, ChangeEvent } from 'react';
import styled from 'styled-components';

import { visuallyHidden } from '~/App/helpers/mixins';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';
import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';
import mq from '~/App/helpers/mq';

import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { useBreakpoint } from '~/App/shared/hooks/use-breakpoint';
import { useAppContext } from '~/App/contexts/App';

import {
  AlertMessageContainer,
  Spinner
} from '~/App/shared/components/Elements';

import ImageUploadButton from '~/App/views/Account/shared/components/ImageUploadButton';
import { Avatar } from '~/App/shared/components/Avatar';

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
`;

const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: -1rem;

  ${mq('≥small')`
    margin-bottom: -2rem;
  `};
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
`;

const ImageInput = styled.input`
  ${visuallyHidden}
`;

const CenteredSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-16px) translateY(-16px);
`;

type StyledAvatarProps = {
  isUploading: boolean;
};

const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
  filter: ${({ isUploading }) => (isUploading ? 'brightness(0.8)' : 'none')};
`;

const StyledImageUploadButton = styled(ImageUploadButton)`
  right: -10%;
  bottom: 0;

  ${mq('≥medium')`
    right: -3%;
    bottom: 10%;
  `};
`;

const IMAGE_UPLOAD_EXCEPTION = 'imageUploadException';
const IMAGE_SIZE_EXCEPTION = 'imageSizeException';

export function PictureUploader() {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const authentication = useAuthenticationContext();
  const userAgent = useAppContext((x) => x.userAgent);
  const isDesktop = useBreakpoint('≥medium', !isMobileBrowser(userAgent));

  const handleError = useCallback((error: string) => {
    setIsUploading(false);

    switch (error) {
      case IMAGE_SIZE_EXCEPTION:
        setError(
          'Bilden du försöker ladda upp är för stor. Max filstorlek är 20MB.'
        );
        break;
      case IMAGE_UPLOAD_EXCEPTION:
        setError(
          'Något gick fel vid bilduppladdningen, var vänlig försök igen.'
        );
        break;
      default:
        setError('Något gick fel vid sparningen, var vänlig försök igen.');
        break;
    }
  }, []);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const image = event?.target?.files?.[0];

      if (!image) {
        return null;
      }

      try {
        if (image.size > 20000000) {
          throw IMAGE_SIZE_EXCEPTION;
        }

        setIsUploading(true);

        const imageUpload = await uploadImage(image, {
          timeout: 30000,
          folder: 'user-profile-pictures'
        }).catch(() => {
          throw IMAGE_UPLOAD_EXCEPTION;
        });

        authentication.update({
          imageUrl: imageUpload?.data?.secure_url
        });
      } catch (error) {
        handleError(`${error}`);
      }

      setIsUploading(false);
    },
    [handleError, authentication]
  );

  const renderedSpinner = useMemo(
    () => <CenteredSpinner size="2rem" alternateColor={true} />,
    []
  );

  const renderedChildren = useMemo(
    () =>
      authentication.user ? (
        <ImageWrapper>
          <StyledAvatar
            isUploading={isUploading}
            user={authentication.user}
            size={isDesktop ? 'large' : 'medium'}
          />

          {isUploading ? renderedSpinner : null}
          {isUploading ? null : (
            <StyledImageUploadButton htmlFor="profile-image" />
          )}
          <ImageInput
            id="profile-image"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
        </ImageWrapper>
      ) : null,
    [isUploading, authentication.user, isDesktop, renderedSpinner, handleChange]
  );

  const renderedError = useMemo(
    () =>
      error ? (
        <AlertWrapper>
          <AlertMessageContainer messageStyle="error">
            {error}
          </AlertMessageContainer>
        </AlertWrapper>
      ) : null,
    [error]
  );

  return (
    <Wrapper>
      {renderedChildren}
      {renderedError}
    </Wrapper>
  );
}
