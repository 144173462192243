import _ from 'lodash';
import React from 'react';

import Button from '~/App/shared/components/Button';

export default class LinkList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let items = [];
    let wrapperClass = this.props.block;

    let {
      data,
      block,
      related,
      modifiers,
      linkClass,
      buttonLink,
      buttonLabel,
      includeButton
    } = this.props;

    if (!_.isEmpty(modifiers) && _.isArray(modifiers)) {
      _.map(modifiers, (modifier) => {
        wrapperClass = wrapperClass + ' ' + block + '--' + modifier;
      });
    }

    if (!_.isEmpty(buttonLink) && !_.isEmpty(buttonLabel) && includeButton) {
      items.push({
        url: buttonLink,
        text: buttonLabel,
        itemClass: linkClass
      });
    }

    if (!_.isEmpty(related)) {
      _.map(related, (link) => {
        items.push({
          url: link.url || link,
          text: link.text || link.teaserHeading || link.heading,
          itemClass: linkClass
        });
      });
    }

    if (_.get(data, 'links', '')) {
      _.map(data.links, (link) => {
        items.push({
          url: link.url || link,
          text: link.text || link.teaserHeading || link.heading,
          itemClass: link.class || linkClass
        });
      });
    }

    if (_.isEmpty(items)) {
      return null;
    }

    return (
      <ul className={wrapperClass}>
        {_.map(items, ({ url, text, itemClass }, key) => {
          return (
            <li className={block + '__item'} key={key}>
              <Button className={itemClass} link={url}>
                {text}
              </Button>
            </li>
          );
        })}
      </ul>
    );
  }
}

LinkList.displayName = 'LinkList';

LinkList.defaultProps = {
  block: 'linkListVertical',
  linkClass: 'ui-arrowLink',
  modifiers: ['normalSpacing'],
  includeButton: true
};
