import React from 'react';

import { ICmsProduct } from '~/types/ICmsProduct';
import { IPurchaseShow } from '~/types/IPurchaseShow';

import { Wrapper } from './components/Wrapper';
import { Header } from './components/Header';
import { ReceiptAndCertificate } from './components/ReceiptAndCertificate';

type Props = {
  product: ICmsProduct;
  purchase: IPurchaseShow;
};

export default function CmsProductThankYouCompany({
  purchase,
  product
}: Props) {
  return (
    <Wrapper>
      <Header
        heading="Stort tack för er gåva - ni är med och räddar liv!"
        purchase={purchase}
        product={product}
      >
        Vill ni handla fler livsviktiga produkter? Ni behöver inte ange era
        kontaktuppgifter igen utan kan snabbt och enkelt handla mer i vår
        gåvoshop.
      </Header>

      <ReceiptAndCertificate
        heading="Gåvobevis och kvitto"
        purchase={purchase}
      />
    </Wrapper>
  );
}
