import styled, { css } from 'styled-components';

import mq from '~/App/helpers/mq';

export type BackgroundColor =
  | 'standard'
  | 'primary'
  | 'pink'
  | 'green'
  | 'none';

type BlockBackgroundProps = {
  backgroundColor?: BackgroundColor;
};

export const BlockBackground = styled.div<BlockBackgroundProps>`
  background-color: ${({ backgroundColor, theme }) => {
    switch (backgroundColor) {
      case 'standard':
        return theme.themeColors.accentedBackgroundColor;
      case 'primary':
        return theme.themeColors.primaryBackgroundColor;
      case 'pink':
        return theme.themeColors.backgroundPink;
      case 'green':
        return theme.themeColors.backgroundGreen;
      default:
        break;
    }

    return 'transparent';
  }};
`;

const galaBackground = css`
  background-color: ${({ theme }) => theme.themeColors.accentedBackgroundColor};

  background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1574930534/assets/cancergalan_page_background.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
`;

const cfBluePinkBackground = css`
  background-color: ${({ theme }) => theme.themeColors.accentPink};

  ${mq('≥small')`
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1575291102/assets/donation-thanks-background.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  `};
`;

export const specialBackgrounds = {
  christmas: 'christmas',
  gala: 'gala',
  cfBluePink: 'cfBluePink'
};

type SpecialBlockBackgroundProps = {
  background: 'christmas' | 'gala' | 'cfBluePink';
};

export const SpecialBlockBackground = styled.div<SpecialBlockBackgroundProps>`
  ${({ background }) => {
    switch (background) {
      case 'gala':
        return galaBackground;
      case 'cfBluePink':
        return cfBluePinkBackground;
      default:
        break;
    }

    return css`
      background-color: transparent;
    `;
  }};
`;
