import React, { useCallback, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

// Config
import { buttonStyles } from '~/App/config/buttonStyles';

// Helpers
import { heading2 } from '~/App/helpers/mixins';
import { donations } from '~/App/helpers/icons';
import mq from '~/App/helpers/mq';

// Shared components
import { Gift } from './Gift';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { ICollection } from '~/types/ICollection';
import ShareButtons from '~/App/shared/components/ShareButtons';

import { UseGifts } from '../hooks/use-gifts';

const PulsateAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(7, 144, 9, 1); }
  70% { box-shadow: 0 0 0 10px rgba(7, 144, 9, 0); }
  100% { box-shadow: 0 0 0 50px rgba(7, 144, 9, 0); }
`;

const GiftsWrapper = styled.div`
  padding: 0 1rem;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: ${({ theme }) => theme.themeColors.primaryBackgroundColor};
  position: relative;

  ${mq('≥mediumLarge')`
  top: 180px;
  @supports (position: sticky) {
    position: sticky;
  }
  `};
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
`;

const ItemsButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  position: absolute;
  left: 0;
  right: 0;
`;

const Heading = styled.h2`
  ${heading2};
`;

const ShowMoreButton = styled(PrimaryButton)`
  display: block;
  margin: 1.5rem auto 0;
`;

const Preamble = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
  color: ${(props) => props.theme.themeColors.primary};
  margin-top: 2rem;
`;

const Icon = styled.div`
  color: ${(props) => props.theme.themeColors.primary};
  font-size: 3rem;
  text-align: center;
  margin: 1rem 0 0.5rem;

  &::before {
    ${donations};
  }
`;
const DonateButton = styled(PrimaryButton)`
  -moz-animation: ${PulsateAnimation} 2s infinite;
  -webkit-animation: ${PulsateAnimation} 2s infinite;
  animation: ${PulsateAnimation} 2s infinite;
`;

type Props = {
  collection: ICollection;
  gifts: UseGifts;
  isDesktop: boolean | null;
};

export function Gifts({
  collection,
  gifts: { items, total, status, fetch },
  isDesktop
}: Props) {
  const renderGift = useCallback(
    (gift) => <Gift key={`${gift.createdAt}-${gift.amount}`} gift={gift} />,
    []
  );

  const children = useMemo(() => {
    if (items.length > 0) {
      return (
        <>
          <Wrapper>
            <Heading>Gåvor</Heading>
            {isDesktop && (
              <>
                {collection.isPaused ? (
                  <Preamble>Insamlingen är avslutad</Preamble>
                ) : (
                  <>
                    <ItemsButtonWrapper>
                      <DonateButton
                        buttonType="link"
                        buttonStyle={buttonStyles.cta}
                        to={`/stod-oss/skank-till-en-insamling/${collection.slug}`}
                      >
                        Bidra till insamlingen
                      </DonateButton>
                    </ItemsButtonWrapper>

                    <ShareButtons
                      showFacebook={true}
                      showEmail={true}
                      showLink={true}
                      showLinkedIn={true}
                    />
                  </>
                )}
              </>
            )}
          </Wrapper>
          {items.map(renderGift)}
          {items.length !== total && (
            <ShowMoreButton
              buttonStyle={buttonStyles.outlinePrimary}
              onClick={fetch}
              isLoading={status === 'fetching'}
              children="Visa fler"
            />
          )}
        </>
      );
    }

    return (
      <>
        {isDesktop && (
          <ButtonWrapper>
            <DonateButton
              buttonType="link"
              buttonStyle={buttonStyles.cta}
              to={`/stod-oss/skank-till-en-insamling/${collection.slug}`}
            >
              Bidra till insamlingen
            </DonateButton>
          </ButtonWrapper>
        )}
        <Preamble>Var först med att bidra - varje krona räknas!</Preamble>
        <Icon />
        {isDesktop && (
          <ButtonWrapper>
            <ShareButtons
              showFacebook={true}
              showEmail={true}
              showLink={true}
              showLinkedIn={true}
            />
          </ButtonWrapper>
        )}
      </>
    );
  }, [
    fetch,
    items,
    renderGift,
    status,
    total,
    isDesktop,
    collection.isPaused,
    collection.slug
  ]);

  if (collection.isPaused) {
    return null;
  }

  if (!children) {
    return null;
  }

  return <GiftsWrapper children={children} />;
}
