export default function controlStyle(theme) {
  return (styles) => ({
    ...styles,
    backgroundColor: theme.themeColors.primary,
    borderRadius: '2px',
    border: '0 none',
    cursor: 'pointer',
    minHeight: 'unset',
    paddingTop: '0.25rem',
    paddingBottom: '0.15rem',
    display: 'inline-block',
    overflow: 'hidden'
  });
}
