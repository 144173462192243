import React, { useMemo } from 'react';
import styled from 'styled-components';

// Constants
import { BlockBackground } from '~/App/shared/components/BlockBackground';

// Helpers
import { heading2, preamble } from '~/App/helpers/mixins';

// Shared components
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { Header } from './shared/Header';
import HtmlContent from '~/App/shared/components/HtmlContent';

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 2.5rem;
`;

const Content = styled.div`
  position: relative;
  max-width: 35.5rem;
  margin: 0 auto;
  padding: 1rem 0 1rem;
  text-align: center;
`;

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

type DataProps = {
  heading: string;
  productImage: string;
  texts: {
    once: string;
    month: string;
    everyOtherMonth: string;
    everyThirdMonth: string;
  };
  headingName: string;
  enableLotteryEncasing: string;
  lotteryEncasingHtml: string;
  enableGiftCertificate: string;
  giftCertificateHtml: string;
  enableExtraConversion: string;
  subscriptionHtml: string;
  monthlyDonorHtml: string;
};

type Props = {
  data: DataProps;
  purchase: any;
};

export default function LotteryTicketThanks({ data, purchase = {} }: Props) {
  const {
    product,
    purchase: { productOptions: { customerContact = {} } = {} } = {},
    paymentMethod = {}
  } = purchase;

  const name = customerContact[data.headingName];
  const email = customerContact.email || 'din mail';

  const periodText = useMemo(() => {
    switch (product.period) {
      case 'Once':
        return data.texts.once;
      case 'Month':
        return data.texts.month;
      case 'EveryOtherMonth':
        return data.texts.everyOtherMonth;
      case 'EveryThirdMonth':
        return data.texts.everyThirdMonth;
    }
  }, [
    product.period,
    data.texts.once,
    data.texts.month,
    data.texts.everyOtherMonth,
    data.texts.everyThirdMonth
  ]);

  return (
    <BlockBackground backgroundColor="primary">
      <Background>
        <Header image={data.productImage}>
          <Heading1
            children={
              customerContact && name
                ? `${data.heading} ${name}!`
                : `${data.heading}!`
            }
          />
          <Preamble>
            {periodText} Bekräftelse skickar vi till {email}.
          </Preamble>

          <FetchPurchaseDocuments purchase={purchase}>
            {({ receiptStatus, receipt }) => (
              <AsyncReceiptDocumentLink
                url={receipt}
                status={receiptStatus}
                paymentMethodId={paymentMethod?.id}
              />
            )}
          </FetchPurchaseDocuments>
          {data.enableGiftCertificate === 'true' && (
            <HtmlContent children={data.giftCertificateHtml} />
          )}
          {data.enableLotteryEncasing === 'true' && (
            <HtmlContent children={data.lotteryEncasingHtml} />
          )}
          {data.enableExtraConversion === 'true' && (
            <Content>
              {product.period === 'Once' ? (
                <HtmlContent
                  children={data.subscriptionHtml}
                  template="newsPage"
                />
              ) : (
                <HtmlContent
                  children={data.monthlyDonorHtml}
                  template="newsPage"
                />
              )}
            </Content>
          )}
        </Header>
      </Background>
    </BlockBackground>
  );
}
