import React from 'react';
import { Helmet } from 'react-helmet';

// Helpers
import { siteUrl } from '~/App/helpers/env';

// Shared components
import Intro from '~/App/shared/components/Intro';
import Section from '~/App/shared/components/Section';
import * as Donation from '~/App/shared/components/Donation';

export function SupportMember({
  data: { contentDefinition = {} } = {},
  routes = []
}) {
  const { meta = {}, data: contentData = {} } = contentDefinition;

  const routerPath = routes
    .filter((r) => r.path !== '/' && r.path)
    .map((r) => r.path)
    .join('/');

  return (
    <div>
      <Helmet>
        <title>{meta.pageTitle}</title>
        <link rel="canonical" href={siteUrl(`/${routerPath}`)} />
        <meta name="description" content={meta.pageDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:image" content={meta.ogImage} />
        <meta property="og:description" content={meta.ogDescription} />
      </Helmet>
      <Section modifier={contentData.modifier || ''}>
        <Intro
          heading={contentDefinition.heading}
          preamble={contentDefinition.preamble}
          description={contentDefinition.description}
          headingFontSize={contentDefinition.headingFontSize}
          headingFontWeight={contentDefinition.headingFontWeight}
          preambleFontWeight={contentData.preambleFontWeight}
        />
      </Section>
      <Section>
        <Donation.SupportMember
          originPath="/stod-oss/bli-stodmedlem"
          formContent={contentData.formContent}
          redirectPath="/stod-oss/bli-stodmedlem/tack"
        />
      </Section>
    </div>
  );
}
