import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import { orderBy } from 'lodash';

import { mq } from '~/lib/mq';

import { ICommerceProduct } from '~/types/ICommerceProduct';

import { pushGTMDetail } from '~/App/helpers/gtm-helper';

import * as TwoColumn from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';

import { Hero } from './components/Hero';
import { LeftColumn } from './components/LeftColumn';
import { RightColumn } from './components/RightColumn';

import { useCommerceProductSchema } from './hooks/useCommerceProductSchema';
import { useCommerceProductState } from './hooks/useCommerceProductState';
import { useValidation } from '~/App/shared/components/Validation';
import { siteUrl } from '~/App/helpers/env';
import { stockStatus } from '~/App/helpers/stock-status';

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPolar};
`;

const Wrapper = styled(TwoColumn.Wrapper)`
  padding: 2rem 0;

  ${mq('<medium')`
    display: flex;
    flex-direction: column;
  `};
`;

type Props = {
  product: ICommerceProduct;
};

export function CommerceProduct({ product }: Props) {
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const toggleModal = useCallback(() => {
    setModalOpen((state) => !state);
  }, []);

  const state = useCommerceProductState({
    product
  });

  const schema = useCommerceProductSchema({
    state,
    product
  });

  const validation = useValidation({
    schema,
    values: state.values
  });

  const variant = useMemo(() => {
    const sorted = orderBy(product.variants || [], [(x) => !x.default]);
    const variant = sorted[0];
    return variant;
  }, [product.variants]);

  useEffect(
    () =>
      pushGTMDetail({
        id: variant?.id,
        name: product.name,
        category: `${product.target}/${product.type}`,
        variant: product.name,
        price: state.values.price,
        quantity: state.values.quantity,
        dimension10: stockStatus(variant?.stock?.status)
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.values.price]
  );

  if (!variant) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{`Gåvoshop - ${product.name}`}</title>
        <link rel="canonical" href={siteUrl(location.pathname)} />
        {variant.shortDescription && (
          <meta name="description" content={variant.shortDescription} />
        )}
        {product.name && (
          <meta property="og:title" content={`Gåvoshop - ${product.name}`} />
        )}
        {variant.previewImage && (
          <meta property="og:image" content={variant.previewImage} />
        )}
        {variant.shortDescription && (
          <meta property="og:description" content={variant.shortDescription} />
        )}
      </Helmet>
      <Background>
        <Hero product={product} />
        <Wrapper>
          <LeftColumn
            state={state}
            validation={validation}
            product={product}
            variant={variant}
            modalOpen={modalOpen}
            toggleModal={toggleModal}
          />
          <RightColumn
            product={product}
            variant={variant}
            toggleModal={toggleModal}
          />
        </Wrapper>
      </Background>
    </>
  );
}
