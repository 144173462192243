import React from 'react';

class EditorialItemVideo extends React.Component {
  render() {
    var imageFormat = this.props.imageLayout || 'hero',
      videoUrl = this.props.embed,
      videoUrl = videoUrl.replace('watch?v=', 'embed/'),
      videoUrl = videoUrl + '?enablejsapi=1';

    if (
      this.props.imageType === 'Image' ||
      imageFormat === 'hero' ||
      imageFormat === 'high'
    ) {
      return null;
    }

    return (
      <div className={'editorialItemVideo editorialItemVideo--' + imageFormat}>
        <div className="editorialItemVideo__content">
          <iframe
            className="editorialItemVideo__iframe"
            width="100%"
            height="100%"
            src={videoUrl + '?showinfo=0&rel=0'}
            frameBorder="0"
            allowFullScreen="1"
          />
        </div>
      </div>
    );
  }
}

EditorialItemVideo.displayName = 'EditorialItemVideo';

EditorialItemVideo.defaultProps = {
  embed: '',
  visibility: true
};

export default EditorialItemVideo;
