import React from 'react';

// Helpers
import mediaHelper from '../../../App/helpers/media-helper';
import { containerWidths } from '~/App/config/containerWidths';

class EditorialItemImage extends React.Component {
  imageFormat() {
    var imageFormat = this.props.imageLayout || 'hero';

    if (imageFormat === 'wide') {
      return {
        width: containerWidths.normal
      };
    } else if (imageFormat === 'normal') {
      return {
        width: containerWidths.limited
      };
    } else if (imageFormat === 'narrow') {
      return {
        width: 700
      };
    }

    return { width: containerWidths.limited };
  }

  render() {
    var imageFormat = this.props.imageLayout || 'hero',
      imageUrl = mediaHelper.getUrl(
        this.props.image.secure_url,
        this.imageFormat()
      );

    if (
      this.props.imageType === 'Embed' ||
      imageFormat === 'hero' ||
      imageFormat === 'high'
    ) {
      return null;
    }

    return (
      <div className={'editorialImage editorialImage--' + imageFormat}>
        <div className="editorialImage__content">
          {this.props.imageCaption ? (
            <div className="editorialImage__caption">
              {this.props.imageCaption}
            </div>
          ) : null}
          <img className="editorialImage__image" src={imageUrl} />
        </div>
      </div>
    );
  }
}

EditorialItemImage.displayName = 'EditorialItemImage';

EditorialItemImage.defaultProps = {
  image: '',
  imageCaption: '',
  customFormat: {}
};

export default EditorialItemImage;
