import React from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';

import * as TwoColumn from '~/App/shared/components/Donation/components/Forms/components/TwoColumn/';
import { ICommerceVariant } from '~/types/ICommerceVariant';
import { ICommerceProduct } from '~/types/ICommerceProduct';
import { IValidation } from '~/App/shared/components/Validation';

import { CommerceProductState } from '../hooks/useCommerceProductState';
import { AddToCartButton } from './AddToCartButton';
import { Information } from './Information';
import { Amount } from './Amount';

const Wrapper = styled(TwoColumn.LeftColumn)`
  ${mq('<medium')`
    position: relative;
    order: 1;
    padding: 1rem;
  `};
`;

type Props = {
  product: ICommerceProduct;
  variant: ICommerceVariant;
  validation: IValidation;
  state: CommerceProductState;
  modalOpen: boolean;
  toggleModal: () => void;
};

export function LeftColumn({
  state,
  variant,
  product,
  modalOpen,
  validation,
  toggleModal
}: Props) {
  return (
    <Wrapper>
      <Information
        state={state}
        product={product}
        variant={variant}
        modalOpen={modalOpen}
        toggleModal={toggleModal}
      />
      {variant.price.type === 'Flexible' && !variant.discontinued ? (
        <Amount state={state} validation={validation} variant={variant} />
      ) : null}

      {!variant.discontinued && (
        <AddToCartButton
          validation={validation}
          product={product}
          variant={variant}
          state={state}
        />
      )}
    </Wrapper>
  );
}
