import { useRef, useEffect, useState, RefObject } from 'react';

import 'intersection-observer';

type InViewport = boolean | undefined;

type Arguments = {
  threshold?: number;
};

export function useInViewport<T extends HTMLElement = HTMLDivElement>({
  threshold = 0
}: Arguments = {}): [InViewport, RefObject<T>] {
  const element = useRef<T | null>(null);
  const [inViewPort, setInViewport] = useState<InViewport>(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const targetElement = element.current;

    if (!targetElement) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          setInViewport(entry.isIntersecting);
        }
      },
      {
        threshold
      }
    );

    observer.observe(targetElement);

    return () => {
      observer.disconnect();
    };
  }, [threshold]);

  return [inViewPort, element];
}
