import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import mediaHelper from '~/App/helpers/media-helper';

export default function SuperHero({ image, ...props }) {
  let wrapperClasses = classNames('superHero', {
    [`superHero--${props.size}`]: !_.isEmpty(props.size),
    'superHero--overlay': props.backgroundModifier === 'overlay'
  });

  let imageSizeSmall = { height: 300 };
  let imageSizeMedium = { height: 450 };
  let imageSizeLarge = { height: 600 };

  //
  // Check if size is defined and contains 'by'
  //
  if (!_.isEmpty(props.size) && _.includes(props.size, 'by')) {
    const imageSizeArray = props.size.split('by');

    if (
      imageSizeArray.length === 2 &&
      parseInt(imageSizeArray[0], 10) &&
      parseInt(imageSizeArray[1], 10)
    ) {
      const imageSizeFactor = 1600 / parseInt(imageSizeArray[0], 10);
      const imageWidth = parseInt(imageSizeArray[0], 10);
      const imageHeight = parseInt(imageSizeArray[1], 10);

      imageSizeSmall = {
        width: Math.round(imageSizeFactor * 0.3 * imageWidth),
        height: Math.round(imageSizeFactor * 0.3 * imageHeight)
      };

      imageSizeMedium = {
        width: Math.round(imageSizeFactor * 0.6 * imageWidth),
        height: Math.round(imageSizeFactor * 0.6 * imageHeight)
      };

      imageSizeLarge = {
        height: Math.round(imageSizeFactor * 0.65 * imageHeight)
      };
    }
  }

  const wrapperStyles = {
    backgroundColor: props.backgroundColor
  };

  const contentStyles = {
    maxWidth: props.contentMaxWidth,
    textAlign: props.contentPosition ? props.contentPosition.split('-')[0] : '',
    verticalAlign: props.contentPosition
      ? props.contentPosition.split('-')[1]
      : ''
  };

  let imageStylesSmall = _.omitBy(
    {
      backgroundSize: props.backgroundSize,
      backgroundImage: image
        ? `url(${mediaHelper.getUrl(image, imageSizeSmall)})`
        : '',
      backgroundPosition: props.backgroundPosition
        ? props.backgroundPosition.split('-').join(' ')
        : ''
    },
    _.isEmpty
  );

  let imageStylesMedium = _.omitBy(
    {
      backgroundSize: props.backgroundSize,
      backgroundImage: image
        ? `url(${mediaHelper.getUrl(image, imageSizeMedium)})`
        : '',
      backgroundPosition: props.backgroundPosition
        ? props.backgroundPosition.split('-').join(' ')
        : ''
    },
    _.isEmpty
  );

  let imageStylesLarge = _.omitBy(
    {
      backgroundSize: props.backgroundSize,
      backgroundImage: image
        ? `url(${mediaHelper.getUrl(image, imageSizeLarge)})`
        : '',
      backgroundPosition: props.backgroundPosition
        ? props.backgroundPosition.split('-').join(' ')
        : ''
    },
    _.isEmpty
  );

  return (
    <div className={wrapperClasses} style={wrapperStyles}>
      <div className="superHero__main">
        <div className="superHero__content" style={contentStyles}>
          {props.content}
        </div>
      </div>
      <div className="superHero__backdrop">
        <div className="superHero__media superHero__media--small">
          <div
            className="superHero__image superHero__image--small"
            style={imageStylesSmall}
          />
        </div>
        <div className="superHero__media superHero__media--medium">
          <div
            className="superHero__image superHero__image--medium"
            style={imageStylesMedium}
          />
        </div>
        <div className="superHero__media superHero__media--large">
          <div
            className="superHero__image superHero__image--large"
            style={imageStylesLarge}
          />
        </div>
      </div>
    </div>
  );
}

SuperHero.defaultProps = {
  size: '9by4',
  textColor: 'white',
  backgroundSize: 'auto 100%',
  contentMaxWidth: '900px',
  contentPosition: 'center-middle',
  backgroundPosition: 'center-center'
};
