import { fetch, purchases, collections } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';
import { logSimpleMessage } from '~/App/helpers/logger';
import { LoadDataProps } from '~/types/routes';

type Query = {
  purchaseToken?: string;
  paymentMethodId?: string;
  page?: string;
};

// Load purchase
const loadPurchase = async (query: Query) => {
  const token = query?.purchaseToken;

  if (!token) {
    return {};
  }

  try {
    const response = await purchases.show({
      slug: token,
      params: query.paymentMethodId
        ? {
            payment_method_id: query.paymentMethodId
          }
        : undefined
    });

    const collection = await loadCollection(response?.data?.collection?.slug);

    return {
      purchase: response.data,
      collection: collection
    };
  } catch (error: any) {
    if (error.response && error.response.status === 404)
      logSimpleMessage(`Purchase ${query?.purchaseToken} not found`);
  }

  return {};
};

// Load collection
const loadCollection = async (slug?: string) => {
  if (!slug) {
    return null;
  }
  const response = await collections.show({ slug });
  const collection = response.data;

  return collection;
};

// Load page
const loadContent = async (pathname: string, query: Query) => {
  const response = await fetch({
    url: contentApi('/v1/pages'),
    params: { slug: pathname, page: query?.page }
  });

  if (response.status === 404) {
    throw response;
  }

  return response.data;
};

export const loadData = async ({
  pathname,
  query
}: LoadDataProps<string, Query>) => {
  const tasks = [loadContent(pathname, query), loadPurchase(query)];
  const [content, purchase] = await Promise.all(tasks);

  return {
    data: {
      ...content,
      ...purchase
    }
  };
};
