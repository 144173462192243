// helpers
import { logSimpleMessage } from '~/App/helpers/logger';
import { ICommerceOrderLine } from '~/types/ICommerceOrderLine';
import { QueryParameters } from '../views/Research/components/Filters/FilterContainer';
import { stockStatus } from './stock-status';

declare global {
  interface Window {
    dataLayer?: any[];
    carma?: {
      roi?: {
        register?: (amount: number | string | null) => void;
      };
    };
  }
}

type Amount = null | string | number;

export type GtmDetails = {
  name: string;
  brand: string;
  category: string;
  formName?: string;
  variant?: string;
  coupon?: string;
};

export type GtmImpressionData = {
  amount?: Amount;
  productId?: number;
};

export type GtmProductData = {
  amount?: Amount;
  productId?: number;
  quantity?: number;
};

export type GtmCheckoutData = {
  step: number;
  option: number | string;
};

export type GtmPurchaseData = {
  purchaseId?: number | string;
  productId?: number | string;
  totalAmount?: Amount;
  quantity?: number;
};

export type GtmEvent = {
  category?: string | number | null;
  action?: string | number | null;
  label?: string | number | null;
  value?: string | number | null;
};

export type GtmDynamicEvent = {
  event?: string | number | null;
  category?: string | number | null;
  action?: string | number | null;
  label?: string | number | null;
};

export type GtmPromotion = {
  id: string;
  name: string;
  creative: string;
  position: string;
};

export type GtmPromotionClick = GtmPromotion & {
  url: string;
};

export type GtmProductDetail = {
  id: string | undefined;
  name: string | undefined;
  category: string | undefined;
  variant: string;
  price: number | null | undefined;
  quantity: number;
  dimension10: string | undefined | null;
};
type Callback = () => void;

export const pushGTMIncrease = (product: GtmProductDetail) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.add',
    ecommerce: {
      add: {
        actionField: {
          list: 'Shopping cart'
        },
        products: [product]
      }
    }
  });
};
export const pushGTMAdd = (product: GtmProductDetail) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.add',
    ecommerce: {
      add: {
        products: [product]
      }
    }
  });
};

export const pushGTMDecrease = (product: GtmProductDetail) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.remove',
    ecommerce: {
      remove: {
        actionField: {
          list: 'Shopping cart'
        },
        products: [product]
      }
    }
  });
};

export const pushGTMRemove = (product: GtmProductDetail) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.remove',
    ecommerce: {
      remove: {
        actionField: {
          list: 'Shopping cart'
        },
        products: [product]
      }
    }
  });
};

export const pushGTMDetail = (product: GtmProductDetail) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.detail',
    ecommerce: {
      detail: {
        products: [product]
      }
    }
  });
};

export type GtmFormData = {
  formName: string | undefined;
  amount?: number | null | undefined;
  period?: string | undefined;
  fieldName?: string | undefined;
  step?: number | undefined;
  stepName?: string | undefined;
  paymentMethod?: string | undefined;
  buttonText?: string | undefined;
  fieldValue?: string | number | undefined;
};

type FormEvent =
  | 'formSubmit'
  | 'formComplete'
  | 'formImpression'
  | 'formInteraction'
  | 'formChange';

export const pushGTMFormEvent = (
  event: FormEvent,
  data: GtmFormData,
  clientId?: string | null
) => {
  logSimpleMessage(
    `Pushing ${event} step ${data.step} for ${clientId} to datalayer`
  );
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    formData: {
      ...data
    }
  });
};

type EecProduct = {
  id: string | number | undefined;
  name?: string;
  price?: string | number;
  brand?: string;
  category?: string;
  variant?: string;
  quantity?: number;
  dimension10?: string | undefined | null;
};

export type EecData = {
  products?: EecProduct[];
  actionField?: Record<string, unknown>;
};

type EecEvent = 'checkout_option' | 'checkout' | 'purchase';

export const pushEecEvent = (event: EecEvent, data: EecData) => {
  if (event === 'purchase') {
    pushSymplifyPurchase(data?.actionField?.revenue);
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: `eec.${event}`,
    ecommerce: {
      currencyCode: 'SEK',
      [event]: data
    }
  });
};

export const eecProduct = (orderLine: ICommerceOrderLine): EecProduct => ({
  id: `${orderLine.variant?.id}`,
  name: orderLine.variant?.product?.name,
  variant: orderLine.variant?.name,
  category: `${orderLine.variant?.product?.target}/${orderLine.variant?.product?.type}`,
  price: orderLine.price,
  quantity: orderLine.quantity,
  dimension10: stockStatus(orderLine.variant?.stock?.status)
});

const pushSymplifyPurchase = (amount: any) => {
  try {
    if (window.carma?.roi?.register) {
      window.carma?.roi?.register(amount); // send conversion to Symplify
    }
  } catch (_) {
    // ignore
  }
};

export const pushGTMPurchase = (
  purchase: GtmPurchaseData,
  details?: GtmDetails,
  eventCallback?: Callback
) => {
  logSimpleMessage(`Pushing purchase ${purchase.purchaseId} to datalayer`);

  const quantity = purchase.quantity || 1;
  const amount = parseNumber(purchase.totalAmount);
  const price = amount ? amount / quantity : null;

  pushSymplifyPurchase(amount);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.purchase',
    eventCallback: eventCallback,
    eventTimeout: 2000,
    ecommerce: {
      purchase: {
        actionField: {
          id: purchase.purchaseId, // Transaction ID. Required for purchases and refunds.
          // affiliation: '?',
          revenue: purchase.totalAmount // Total transaction value (incl. tax and shipping)
          // tax: '?',
          // shipping: '?'
        },
        products: [
          {
            // List of productFieldObjects.
            name: details?.name, // Name or ID is required.
            id: purchase.productId,
            price: price, // List price (one unit)
            brand: details?.brand || 'Cancerfonden',
            category: details?.category || '',
            variant: details?.variant || '',
            quantity: quantity,
            coupon: details?.coupon || ''
          }
        ]
      }
    }
  });
};

export const pushGTMPurchaseAsync = (
  purchase: GtmPurchaseData,
  details: GtmDetails
) => {
  return new Promise<void>((resolve) => {
    let redirectTimeoutId: ReturnType<typeof setTimeout> | null = null;

    const startRedirectTimeout = () => {
      redirectTimeoutId = setTimeout(resolvePromise, 2100);
    };

    const cancelRedirectTimeout = () => {
      if (redirectTimeoutId) clearTimeout(redirectTimeoutId);
    };

    const resolvePromise = () => {
      cancelRedirectTimeout();

      return resolve();
    };

    pushGTMPurchase(purchase, details, resolvePromise);
    startRedirectTimeout();
  });
};

export const pushGTMUserId = (userId: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'userId',
    userId: userId
  });
};

export const pushGTMResearchFilter = (searchFilters: QueryParameters) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'searchFilter',
    diagnoseGroup: searchFilters.diagnoseGroup,
    researchArea: searchFilters.researchArea,
    university: searchFilters.university,
    status: searchFilters.status,
    year: searchFilters.year
  });
};

export const pushGTMIsAuthenticated = (isAuthenticated: boolean) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'isAuthenticated',
    isAuthenticated: `${isAuthenticated}`
  });
};

export const pushGTMEvent = ({
  category = '',
  action = '',
  label = '',
  value = ''
}: GtmEvent) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'customSiteEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value
    });
  } catch (error) {
    console.error(error);
  }
};

export const pushGTMDynamicEvent = ({
  event,
  category,
  action,
  label
}: GtmDynamicEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label
  });
};

export function pushGTMPromoView(promotion: GtmPromotion) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.promoView',
    ecommerce: {
      promoView: {
        promotions: [promotion]
      }
    }
  });
}

export function pushGTMPromoClick({ url, ...promotion }: GtmPromotionClick) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.promoClick',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            ...promotion,
            cd9: url
          }
        ]
      }
    }
  });
}

export const getDurationVariant = (duration?: number | string) => {
  if (!duration) {
    return 'Tillsvidare';
  } else {
    return `${duration} månader`;
  }
};

const parseNumber = (
  value: string | number | undefined | null
): number | null => {
  if (value === undefined) return null;
  if (value === null) return null;

  if (typeof value === 'number' && !isNaN(value)) return value;
  if (typeof value !== 'string') return null;

  const parsed = parseInt(value, 10);

  return isNaN(parsed) ? null : parsed;
};
