import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import dayjs from 'dayjs';

import mediaHelper from '~/App/helpers/media-helper';
import { IMemorialPage } from '~/types/ICollection';
import { useInViewport } from '~/App/shared/hooks/use-in-viewport';
import { translateYAnimation } from '~/App/helpers/animations';
import { card } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  padding-top: 100%;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 1rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
`;

const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.3s ease;
`;

const Card = styled.div`
  ${card};
  ${translateYAnimation};
  padding: 0 !important;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`;

const Content = styled.div`
  padding: 0 0.75rem 0.75rem;

  ${mq('≥small')`
    padding: 0 1rem 1rem;
  `};
`;

const Small = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 1.125rem;

  ${mq('<medium')`
    font-size: 1rem;
  `};
`;

const Name = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 1.375rem;
  font-weight: bold;

  ${mq('<medium')`
    font-size: 1.125rem;
  `};
`;

const LifeSpan = styled.div`
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: 1.125rem;

  ${mq('<medium')`
    font-size: 0.75rem;
  `};
`;

type Props = {
  page: IMemorialPage;
};

export function MemorialPageCard({ page }: Props) {
  const [isVisible, ref] = useInViewport();

  const [isRunning, setRunning] = useState(false);

  const navigate = useNavigate();

  const date = useMemo(
    () =>
      [page.birthDate, page.deathDate]
        .filter((x) => !!x)
        .map((x) => dayjs(x ?? '').format('D MMM YYYY'))
        .join(' - '),
    [page]
  );

  const handleClick = useCallback(
    () => navigate(`/stod-oss/minnessidor/${page.slug}`),
    [navigate, page]
  );

  useEffect(() => {
    if (isRunning) return;
    if (!isVisible) return;

    setRunning(true);
  }, [isVisible, isRunning]);

  const imageUrl = useMemo(
    () =>
      mediaHelper.getUrl(page.imageUrl, {
        width: 470,
        height: 470
      }),
    [page]
  );

  return (
    <Card onClick={handleClick} isRunning={isRunning} ref={ref}>
      <ImageWrapper>
        <Image src={imageUrl} />
      </ImageWrapper>
      <Content>
        <Small children="Till minne av" />
        <Name children={page.name} />
        {date && <LifeSpan children={date} />}
      </Content>
    </Card>
  );
}
