import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router';

// Helpers
import { contentApi } from '~/App/helpers/env';
import { SearchResults } from './components/SearchResults/SearchResults';

// Local components
import SearchField from './components/Filters/FilterContainer';
import { ResearchInformation } from '../ResearchProject/components/ResearchInformation/ResearchInformation';
import axios, { AxiosRequestConfig } from 'axios';
import useQuery from '~/App/shared/hooks/use-query';
import useClientEffect from '~/App/shared/hooks/use-client-effect';
import { ResearchProject } from '../ResearchProject/ResearchProject';

type Category = {
  name: string;
  amount?: number;
  percent?: number;
};

type StateProps = {
  numberOfProjects: number;
  totalDonationAmount: number;
  researchProjects: ResearchProject[];
  diagnoseFilterOptions: string[];
  researchAreaFilterOptions: string[];
  universityFilterOptions: string[];
  yearsOptions: string[];
  diagnoseGroups: string[];
  researchAreas: string[];
  universities: string[];
  femaleResearchers: number;
  maleResearchers: number;
};

type SearchParams = {
  diagnoseGroup?: string;
  researchArea?: string;
  status?: 'Avslutad' | 'Pågående';
  university?: string;
  year?: number;
  take?: number;
};

export type Request = AxiosRequestConfig & {
  url: string;
  params?: SearchParams;
  method: string;
  timeout: number;
  responseType: string;
};

export default function Research() {
  const location = useLocation();
  const query = useQuery();
  const [state, setState] = useState<StateProps>({
    numberOfProjects: 0,
    totalDonationAmount: 0,
    researchProjects: [],
    diagnoseFilterOptions: [],
    researchAreaFilterOptions: [],
    universityFilterOptions: [],
    yearsOptions: [],
    diagnoseGroups: [],
    researchAreas: [],
    universities: [],
    femaleResearchers: 0,
    maleResearchers: 0
  });

  const search = useCallback(({ params }) => {
    const request: Request = {
      url: contentApi('/v1/research-projects'),
      params: {
        ...params
      },
      method: 'get',
      timeout: 15000,
      responseType: 'json'
    };

    axios(request)
      .then(({ data }) => {
        setState((state) => ({
          ...state,
          numberOfProjects: data.total,
          researchProjects: data.researchProjects
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchGroupedFilterOptions = useCallback(() => {
    const request: Request = {
      url: contentApi('/v1/research-categories'),
      params: {},
      method: 'get',
      timeout: 15000,
      responseType: 'json'
    };

    axios(request)
      .then(({ data }) => {
        setState((state) => ({
          ...state,
          diagnoseFilterOptions: getCategoryNames(
            data.categories?.diagnoseGroups
          ),
          researchAreaFilterOptions: getCategoryNames(
            data.categories?.researchAreas
          ),
          universityFilterOptions: getCategoryNames(
            data.categories?.universities
          ),
          yearsOptions: data.years
            .filter((year: number | null) => year !== null)
            .map((i: { toString: () => string }) => i.toString())
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchResearchCategories = useCallback(({ params }) => {
    const request: Request = {
      url: contentApi('/v1/research-categories'),
      params: { ...params },
      method: 'get',
      timeout: 15000,
      responseType: 'json'
    };
    axios(request)
      .then(({ data }) => {
        setState((state) => ({
          ...state,
          diagnoseGroups: getCategoryNames(data.categories?.diagnoseGroups),
          researchAreas: getCategoryNames(data.categories?.researchAreas),
          universities: getCategoryNames(data.categories?.universities),
          femaleResearchers: data.femaleResearchers,
          maleResearchers: data.maleResearchers,
          totalDonationAmount: data.total
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useClientEffect(() => {
    const params =
      Object.entries(query).length === 0
        ? {
            status: 'Pågående'
          }
        : query;

    search({
      params
    });
    fetchResearchCategories({ params });
  }, [query, location.search]);

  useClientEffect(() => {
    fetchGroupedFilterOptions();
  }, []);

  const {
    numberOfProjects,
    totalDonationAmount,
    researchProjects,
    universityFilterOptions,
    researchAreaFilterOptions,
    diagnoseFilterOptions,
    yearsOptions,
    universities,
    diagnoseGroups,
    researchAreas,
    femaleResearchers,
    maleResearchers
  } = state;

  const getCategoryNames = (categories: Category[]) => {
    return categories?.map((category: Category) => category.name);
  };

  return (
    <div>
      <SearchField
        universityOptions={universityFilterOptions}
        researchAreaOptions={researchAreaFilterOptions}
        diagnoseGroupOptions={diagnoseFilterOptions}
        yearsOptions={yearsOptions}
      />
      <ResearchInformation
        amount={totalDonationAmount}
        currency={'kronor'}
        linesDisplay={'inline-grid'}
        categoryLines={[
          {
            label: `${numberOfProjects}`,
            title: 'projekt'
          },
          {
            label: `${femaleResearchers}`,
            title: 'kvinnliga forskare'
          },
          {
            label: `${maleResearchers}`,
            title: 'manliga forskare'
          },
          {
            label: `${universities.length}`,
            title: 'lärosäten',
            hoverContent: universities
          },
          {
            label: `${researchAreas.length}`,
            title: 'forskningsområden',
            hoverContent: researchAreas
          },
          {
            label: `${diagnoseGroups.length}`,
            title: 'diagnoser',
            hoverContent: diagnoseGroups
          }
        ]}
      />

      <SearchResults
        researchProjects={researchProjects}
        query={query}
        numberOfProjects={numberOfProjects}
      />
    </div>
  );
}
