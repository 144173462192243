import { useRef, EffectCallback, useEffect } from 'react';

type Data = {
  firstTime: boolean;
  clearFunc?: void | (() => void);
};

export function useDebouncedEffect(
  callback: EffectCallback,
  delay: number,
  deps: any[] = []
) {
  const data = useRef<Data>({ firstTime: true });

  useEffect(() => {
    const { firstTime, clearFunc } = data.current;

    if (firstTime) {
      data.current.firstTime = false;
      return;
    }

    const handler = setTimeout(() => {
      if (clearFunc && typeof clearFunc === 'function') {
        clearFunc();
      }
      data.current.clearFunc = callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...deps]);
}
