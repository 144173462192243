import React from 'react';
import { IImageSource } from '~/types/IImage';
import { BackgroundColor, Padding, Width } from '~/types/Block';
import { SwishBlockDonation } from '../../../Donation/SwishBlockDonation';

type Props = {
  heading: string;
  headingSettings?: {
    fontSize: string;
  };
  large: {
    style: string;
    size: string;
    source: IImageSource;
  };
  small: {
    style: string;
    size: string;
    source: IImageSource;
  };
  imageAlt?: string;
  campaignId?: number;
  width: Width;
  padding?: Padding;
  backgroundColor?: BackgroundColor;
  showLightTextColor: boolean;
};

export default function SwishBlock({ ...props }: Props) {
  return <SwishBlockDonation {...props} />;
}
