import _ from 'lodash';
import axios from 'axios';
import React from 'react';
import classNames from 'classnames';

// Helpers
import { assemble } from '~/lib/slug-helper';
import { contentApi } from '~/App/helpers/env';

// Shared components
import ArticleThumb from '~/App/shared/components/ArticleThumb';

class ThreeUpArticleFeed extends React.Component {
  constructor(props) {
    super(props);

    this.handleLoadMore = this.handleLoadMore.bind(this);

    var hideButton = _.has(this.props, 'data.pagination')
      ? !this.props.data.pagination
      : !this.props.pagination;
    if (
      this.props.totalNrRelated !== false &&
      this.props.totalNrRelated === this.props.related.length
    ) {
      hideButton = true;
    }

    this.state = {
      items: this.props.related,
      hideButton: hideButton,
      loadingMore: false
    };

    this.skip = 0;
    this.take = props.related && props.related.length;
  }

  handleLoadMore() {
    this.skip += this.take;
    this.setState({ loadingMore: true });

    const request = {
      url: contentApi(`/v1/content-definitions/${this.props.slug}`),
      params: {
        skip: this.skip,
        take: this.take
      },
      method: 'get',
      timeout: 15000,
      responseType: 'json'
    };

    axios(request)
      .then(({ data }) => {
        var items = this.state.items,
          hideButton = false;

        _.forEach(data.contentDefinition.related, function (item) {
          items.push(item);
        });

        if (data.contentDefinition.related.length !== this.take) {
          hideButton = true;
        }

        this.setState({
          items: items,
          hideButton: hideButton,
          loadingMore: false
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    var buttonClasses = classNames('button', 'button--dove', {
      'is-loading': this.state.loadingMore
    });

    return (
      <div className={'threeUpArticleFeed ' + this.props.modifier}>
        <div className="threeUpArticleFeed__list">
          {this.state.items.map((item, key) => {
            const {
              image: { secure_url: imageSecureUrl = '' } = {},
              teaserImage: { secure_url: teaserImageSecureUrl = '' } = {}
            } = item;

            const imageUrl = teaserImageSecureUrl
              ? teaserImageSecureUrl
              : imageSecureUrl;

            return (
              <div className="threeUpArticleFeed__item" key={key}>
                <ArticleThumb
                  link={item.url || assemble(item)}
                  image={imageUrl}
                  embed={item.embed}
                  label={item.teaserLabel}
                  heading={item.teaserHeading || item.heading}
                  preamble={item.teaserPreamble || item.preamble}
                  defaultImage
                  publishedDate={item.publishedDate}
                />
              </div>
            );
          })}
        </div>
        {!this.state.hideButton ? (
          <div className="threeUpArticleFeed__button ui-center">
            <div className={buttonClasses} onClick={this.handleLoadMore}>
              Visa fler
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

ThreeUpArticleFeed.displayName = 'ThreeUpArticleFeed';

ThreeUpArticleFeed.defaultProps = {
  id: '',
  slug: '',
  related: [],
  totalNrRelated: false,
  modifier: false,
  showLabel: false,
  pagination: false
};

export default ThreeUpArticleFeed;
