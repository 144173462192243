import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { uniq } from 'lodash';

// constants
import { buttonStyles } from '~/App/config/buttonStyles';

// helpers
import mq from '~/App/helpers/mq';
import { visuallyHidden } from '~/App/helpers/mixins';

// shared components
import { PrimaryButton } from '~/App/shared/components/Elements';

// local components
import { Page } from './Pagination.Page';

const Wrapper = styled.nav`
  padding-top: 2rem;
`;

const SmallInfo = styled.div`
  margin-bottom: 1rem;
`;

const PaginationWrapper = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationButtonWrapper = styled.li`
  padding: 0 2rem;

  ${mq('<small')`
    padding: 0 0.5rem;
  `};
`;

const StyledPaginationButton = styled(PrimaryButton)`
  ${mq('<small')`
    min-width: 10rem;
  `};
`;

const Ellipsis = styled.li`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.themeColors.primary};
  margin: 0.5rem;
`;

const HiddenBelowSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq('<small')`
    display: none;
  `};
`;

const HiddenAboveSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq('>=small')`
    display: none;
  `};
`;

const VisuallyHidden = styled.span`
  ${visuallyHidden}
`;

type Props = {
  currentPage: number 
  totalPages: number
  controls: string, 
  navigateTo: (page: number) => void
}

export function Pagination({ currentPage, totalPages, controls, navigateTo }: Props) {
  const { firstEllipsis, lastEllipsis, numbersToShow } = useMemo(() => {
    const numbersToShowUnfiltered = [];

    const onlyOnePageOmittedBeginning = currentPage === 5;
    if (onlyOnePageOmittedBeginning) {
      numbersToShowUnfiltered.push(2);
    }

    for (let index = -2; index <= 2; index += 1) {
      numbersToShowUnfiltered.push(currentPage + index);
    }

    const onlyOnePageOmittedEnd = currentPage === totalPages - 4;

    if (onlyOnePageOmittedEnd) {
      numbersToShowUnfiltered.push(totalPages - 1);
    }

    const numbersToShow = uniq(
      numbersToShowUnfiltered.filter((page) => page > 1 && page < totalPages)
    );

    const firstEllipsis = currentPage > 5;
    const lastEllipsis = currentPage < totalPages - 4;

    return {
      firstEllipsis,
      lastEllipsis,
      numbersToShow
    };
  }, [currentPage, totalPages]);

  const renderNumber = useCallback(
    (page) => (
      <Page
        key={page}
        page={page}
        navigateTo={navigateTo}
        currentPage={currentPage}
        controls={controls}
      />
    ),
    [controls, currentPage, navigateTo]
  );

  const handleClick = useCallback((page) => () => navigateTo(page), [
    navigateTo
  ]);

  return useMemo(() => (
    <Wrapper aria-label="pagination">
      <HiddenAboveSmall>
        <SmallInfo>
          Sida {currentPage} (<VisuallyHidden>av </VisuallyHidden>
          {totalPages})
        </SmallInfo>
      </HiddenAboveSmall>
      <PaginationWrapper>
        <PaginationButtonWrapper>
          <StyledPaginationButton
            onClick={handleClick(currentPage - 1)}
            buttonStyle={buttonStyles.outlinePrimary}
            isDisabled={currentPage === 1}
            aria-controls={controls}
          >
            Föregående
          </StyledPaginationButton>
        </PaginationButtonWrapper>
        <HiddenBelowSmall>
          {renderNumber(1)}
          {firstEllipsis && <Ellipsis>...</Ellipsis>}
          {numbersToShow.map(renderNumber)}
          {lastEllipsis && <Ellipsis>...</Ellipsis>}
          {renderNumber(totalPages)}
        </HiddenBelowSmall>
        <PaginationButtonWrapper>
          <StyledPaginationButton
            onClick={handleClick(currentPage + 1)}
            buttonStyle={buttonStyles.outlinePrimary}
            isDisabled={currentPage === totalPages}
            aria-controls={controls}
          >
            Nästa
          </StyledPaginationButton>
        </PaginationButtonWrapper>
      </PaginationWrapper>
    </Wrapper>
  ), [controls, currentPage, firstEllipsis, handleClick, lastEllipsis, numbersToShow, renderNumber, totalPages]);
}
