import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, useMatch } from 'react-router';

import { heading1, heading2 } from '~/App/helpers/mixins';
import { useValidation } from '~/App/shared/components/Validation';
import { useImage } from '~/App/shared/hooks/use-image';
import { useMultiPartFormState } from '~/App/shared/components/MultiPartForm';
import { useCreateMemoryState } from './components/Form/hooks/useCreateMemoryState';
import { useCreateMemorySubmit } from './components/Form/hooks/useCreateMemorySubmit';

import { ImageSelectorProvider } from '~/App/shared/components/Fields/ImageSelector';
import { PaymentRedirect } from '~/App/shared/components/Donation/components/PaymentRedirect';

import { CircleImage } from '../../components/CircleImage';
import { Form, buildSchema } from './components/Form';
import { ICollection } from '~/types/ICollection';
import mediaHelper from '~/App/helpers/media-helper';

type BackgroundProps = {
  src: string | null;
};

const Background = styled.div<BackgroundProps>`
  padding: 2rem 0 30rem;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
`;

const Heading1 = styled.div`
  ${heading1};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin: 0.75rem 0 2rem;
`;

const Heading2 = styled.div`
  ${heading2};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-top: 2rem;
`;

const Wrapper = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 1rem;
`;

type Props = {
  data: ICollection;
};

export function CreateMemory({ data }: Props) {
  const location = useLocation();
  const match = useMatch({
    path: '/stod-oss/minnessidor/:slug',
    caseSensitive: false,
    end: false
  });
  const values = useCreateMemoryState({
    collectionId: data.id,
    formContent: {
      originPath: location.pathname,
      redirectPath: `/stod-oss/minnessidor/${match?.params.slug}`,
      gtmId: undefined
    }
  });

  const schema = useMemo(() => buildSchema(values), [values]);

  const validation = useValidation({
    values,
    schema
  });

  const multiPartValues = useMultiPartFormState({
    values,
    validation,
    parts: values.productOptions.memory.period ? 4 : 2,
    validationKeys: {
      1: ['productOptions.memory.content'],
      2: ['isAuthenticated'],
      3: ['productOptions.product.price'],
      4: [
        'mobileBankId.bank',
        'mobileBankId.accountNumber',
        'mobileBankId.clearingNumber',

        'productOptions.customerContact.email',
        'productOptions.customerContact.ssn',
        'productOptions.customerContact.firstName',
        'productOptions.customerContact.lastName',
        'productOptions.customerContact.phoneNumber',
        'productOptions.customerContact.addressStreet',
        'productOptions.customerContact.addressZip',
        'productOptions.customerContact.addressCity',
        'productOptions.customerContact.cid',
        'productOptions.customerContact.company',
        'bankTransfer.bank',
        'swishPhoneNumber',
        'stripeCard'
      ]
    }
  });

  const src = useImage(
    'https://res.cloudinary.com/cancerfonden/image/upload/v1637756882/minnesida/hero_3x.jpg'
  );

  const submit = useCreateMemorySubmit({
    values: multiPartValues,
    collection: data,
    validation
  });

  const imageUrl = useMemo(
    () =>
      mediaHelper.getUrl(data.imageUrl, {
        width: 400,
        height: 400
      }),
    [data]
  );

  return (
    <ImageSelectorProvider>
      <Background src={src}>
        <Wrapper>
          <CircleImage src={imageUrl} size="15rem" />
          <Heading2>Så här minns jag</Heading2>
          <Heading1 children={data?.name} />
          <Form
            validation={validation}
            values={multiPartValues}
            submit={submit}
          />
          <PaymentRedirect submit={submit} redirectPath={values.redirectPath} />
        </Wrapper>
      </Background>
    </ImageSelectorProvider>
  );
}
