import React, { useRef, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { State } from './useState';
import { useValidation } from '~/App/shared/components/Validation';
import * as TwoColumn from '../../components/Forms/components/TwoColumn';
import { FormHeading } from '../../components/Forms/components/FormTypography';
import AmountSelector from '../../components/Forms/components/AmountSelector';
import Text from '~/App/shared/components/Fields/Text';
import { IntegrityText } from '../../components/Forms/components/IntegrityText';
import { PrimaryButton } from '~/App/shared/components/Elements';
import { IncreaseMonthlySummary } from '../../components/Forms/components/Summaries';
import { schema } from '../schema';
import { useSubmitIncrease } from '../../components/Submits/Submit/hooks/useSubmitIncrease';
import { AlertMessageContainer } from '~/App/shared/components/Elements';
import BankIdWrapper from '../../components/Forms/components/BankIdWrapper';
import QRAutostartTokenWrapper from '../../components/Forms/components/QRAutostartTokenWrapper';
import Redirect from '~/App/shared/components/Redirect';
import { buttonStyles } from '~/App/config/buttonStyles';

import {
  StepWrapper,
  HiddenAboveMedium
} from '../../components/Forms/components/FormLayout';

const InputWrapper = styled.div`
  margin-top: 1rem;
`;
const Wrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

type Props = {
  redirectPath: string;
  formContent: {
    defaultPrice?: number;
    prices: number[];
    infoText?: string | undefined;
  };
};

export function Form({ formContent, redirectPath }: Props) {
  const state = State({ formContent });
  const validation = useValidation({ schema, values: state.values });
  const location = useLocation();
  const submit = useSubmitIncrease({
    values: state.values,
    paymentMethod: 'klarna',
    validation
  });
  const bankIdSsn = useRef<HTMLInputElement | null>(null);

  return (
    <TwoColumn.Wrapper>
      <TwoColumn.LeftColumn>
        {submit.isCompleted && (
          <Redirect
            to={`${redirectPath}?purchaseToken=${submit.publicToken}`}
          />
        )}
        <form onSubmit={submit.handleSubmit}>
          <TwoColumn.ColumnCard>
            <FormHeading>Välj det belopp du vill höja med</FormHeading>
            <p>
              Om du ger minst 200 kr i månaden kan du ha rätt till
              <a
                href={`${location.pathname}#skatteavdrag-for-gavor`}
                aria-current="page"
              >
                {' '}
                skatteavdrag
              </a>
            </p>
            <InputWrapper>
              <AmountSelector
                amounts={formContent.prices}
                selectedAmount={state.values.productOptions.product.price}
                customAmount={state.values.productOptions.product.customPrice}
                setAmount={state.handlers.setProductPrice}
                setCustomAmount={state.handlers.setProductCustomPrice}
                resetAmount={state.handlers.resetProductPrice}
                validationKey={'productOptions.product.price'}
                validation={validation}
                tinyLayout="50"
                smallLayout="50"
                largeLayout="auto"
              />
            </InputWrapper>
            <StepWrapper marginTop="medium">
              <FormHeading>Signera med Mobilt BankId</FormHeading>
              <p>
                Fyll i ditt personnummer och e-post och klicka på &quot;Signera
                och slutför&quot; så uppdaterar vi ditt belopp.
              </p>
              <InputWrapper>
                <Text
                  name="ssn"
                  type="tel"
                  value={state.values.productOptions.customerContact.ssn}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    state.handlers.handleCustomerContactSsn(
                      event,
                      bankIdSsn.current
                    );
                  }}
                  onBlur={validation.showError.bind(
                    undefined,
                    'productOptions.customerContact.ssn'
                  )}
                  errors={
                    validation.errors['productOptions.customerContact.ssn']
                  }
                  isValid={
                    validation.isValid('productOptions.customerContact.ssn') &&
                    submit.pollingStatus !== 'no_monthly_donation'
                  }
                  isInvalid={
                    validation.isInvalid(
                      'productOptions.customerContact.ssn'
                    ) || submit.pollingStatus === 'no_monthly_donation'
                  }
                  inputRef={bankIdSsn}
                  maxLength="12"
                  placeholder="Personnummer"
                />
              </InputWrapper>
              {submit.pollingStatus === 'no_monthly_donation' && (
                <AlertMessageContainer>
                  Vi hittar inte ditt personnummer registrerat via Klarna.
                  <br />
                  Varmt välkommen att ringa givarservice på 010-199 10 10 om du
                  behöver hjälp.
                </AlertMessageContainer>
              )}
              <InputWrapper>
                <Text
                  name="email"
                  type="email"
                  value={state.values.productOptions.customerContact.email}
                  onBlur={() => {
                    validation.showError(
                      'productOptions.customerContact.email'
                    );
                  }}
                  errors={
                    validation.errors['productOptions.customerContact.email']
                  }
                  isValid={validation.isValid(
                    'productOptions.customerContact.email'
                  )}
                  onChange={state.handlers.handleCustomerContactEmail}
                  isInvalid={validation.isInvalid(
                    'productOptions.customerContact.email'
                  )}
                  placeholder="E-post"
                  autoComplete="email"
                />
              </InputWrapper>
              {(submit.pollingStatus === 'failed' || submit.errors) && (
                <AlertMessageContainer messageStyle="error">
                  Det blev ett tekniskt fel. Testa gärna igen eller välkommen
                  att ringa givarservice på 010-199 10 10 om du behöver hjälp.
                </AlertMessageContainer>
              )}
              {submit.pollingStatus === 'invalid_payment_method' && (
                <AlertMessageContainer messageStyle="error">
                  Tyvärr fungerar inte höjning av månadsbelopp med din nuvarande
                  betalmetod. Välkommen att ringa givarservice på 010-199 10 10
                  om du behöver hjälp.
                </AlertMessageContainer>
              )}
              {submit.isPolling && submit.pollingStatus === 'sign_pending' ? (
                submit.qrCode ? (
                  <QRAutostartTokenWrapper
                    qr={submit.qrCode}
                    autostartToken={submit.autostartToken}
                  />
                ) : (
                  <BankIdWrapper />
                )
              ) : null}
            </StepWrapper>
            <HiddenAboveMedium marginBottom="medium">
              <IncreaseMonthlySummary
                price={state.values.productOptions.product.price}
                infoText={formContent.infoText}
                heading={'Ditt val'}
              />
            </HiddenAboveMedium>
            {submit.pollingStatus === 'sign_failed' && (
              <AlertMessageContainer messageStyle="error">
                Något gick fel vid signeringen, var vänlig försök igen. Du är
                välkommen att ringa givarservice på 010-199 10 10.
              </AlertMessageContainer>
            )}
            <IntegrityText />
          </TwoColumn.ColumnCard>
          <Wrapper>
            <PrimaryButton
              type="submit"
              buttonStyle={
                validation.isValidated
                  ? buttonStyles.success
                  : buttonStyles.disabled
              }
              isLoading={
                submit.isSending || submit.isPolling || submit.isCompleted
              }
              isDisabled={
                submit.isSending || submit.isPolling || submit.isCompleted
              }
            >
              Signera och slutför
            </PrimaryButton>
          </Wrapper>
        </form>
      </TwoColumn.LeftColumn>
      <TwoColumn.RightColumn>
        <TwoColumn.ColumnCard>
          <IncreaseMonthlySummary
            price={state.values.productOptions.product.price}
            infoText={formContent.infoText}
            heading={'Ditt val'}
          />
        </TwoColumn.ColumnCard>
      </TwoColumn.RightColumn>
    </TwoColumn.Wrapper>
  );
}
