import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useCallback,
  useState
} from 'react';
import styled from 'styled-components';

import Text from '~/App/shared/components/Fields/Text';
import { primaryButton } from '~/App/helpers/mixins';
import { useValidation } from '~/App/shared/components/Validation';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  ${primaryButton};
  margin: 3rem auto 0;
`;

type Props = {
  defaultValue?: string;
  onSubmit: (value: string) => void;
};

export function EmailForm({ defaultValue, onSubmit }: Props) {
  const [value, setValue] = useState(defaultValue || '');

  const validation = useValidation({
    values: { value },
    schema: {
      value: {
        validateEmail: () => true,
        email: {
          message: '^Felaktig e-postadress'
        }
      }
    }
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (event?: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();

      if (!validation.isValidated) {
        return validation.showAllErrors();
      }

      onSubmit(value);
    },
    [onSubmit, validation, value]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Text
        value={value}
        type="email"
        placeholder="E-post"
        maxLength="100"
        onBlur={validation.handleBlur.bind(undefined, 'email')}
        errors={validation.errors['value']}
        isValid={validation.isValid('value')}
        isInvalid={validation.isInvalid('value')}
        onChange={handleChange}
      />
      <Button
        onClick={handleSubmit}
        buttonStyle={validation.isValidated ? 'primary' : 'disabled'}
        children="Bekräfta"
      />
    </Form>
  );
}
