import React, { useCallback, useMemo, MouseEvent, ComponentProps } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';

import { PaymentMethod } from '../PaymentMethod';
import { IPaymentMethod } from '~/types/IPaymentMethod';
import { IPaymentMethodGroup } from '../../types';

import {
  Item,
  Content,
  Link,
  Navigation
} from '~/App/shared/components/TabBox';

const StepWrapper = styled.div`
  padding-top: 1rem;
`;

type Props = Omit<ComponentProps<typeof PaymentMethod>, 'method'> & {
  group: IPaymentMethodGroup;
};

export function PaymentMethodGroup({ group, values, ...props }: Props) {
  const handlePaymentMethod = useCallback(
    (paymentMethod: IPaymentMethod) => (
      event: MouseEvent<HTMLAnchorElement>
    ) => {
      event.preventDefault();
      values.handlers.handlePaymentMethod(paymentMethod);
    },
    [values.handlers]
  );

  const isActive = useCallback(
    (paymentMethodId: number) => values.paymentMethod?.id === paymentMethodId,
    [values.paymentMethod?.id]
  );

  const renderItem = useCallback(
    (method: IPaymentMethod) => (
      <Item isActive={isActive(method.id)} key={method.id}>
        <Link children={method.name} onClick={handlePaymentMethod(method)} />
      </Item>
    ),
    [handlePaymentMethod, isActive]
  );

  const children = useMemo(() => {
    const method = find(group.paymentMethods, {
      id: values.paymentMethod?.id
    });

    if (!method) {
      return null;
    }

    return <PaymentMethod method={method} {...props} values={values} />;
  }, [group.paymentMethods, props, values]);

  return (
    <StepWrapper>
      <Navigation children={group.paymentMethods.map(renderItem)} />
      <Content children={children} />
    </StepWrapper>
  );
}
