// import { meOrders } from '~/App/helpers/http';
import { ICommerceOrder } from '~/types/ICommerceOrder';
import { LoadDataProps } from '~/types/routes';

type Result = {
  orders: ICommerceOrder[];
};

export async function loadData({ cookies }: LoadDataProps): Promise<Result> {
  return {
    orders: []
  };
  // if (!cookies.secretKey) {
  //   return {
  //     orders: []
  //   };
  // }

  // return {
  //   orders: await meOrders.all({
  //     secretKey: `${cookies.secretKey}`,
  //     pagination: {
  //       page: 1,
  //       perPage: 10
  //     }
  //   })
  // };
}
