import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

// Shared components
import { Container } from '~/App/shared/components/Container';

import { useWindowSize } from '~/App/shared/hooks/use-window-size';
import { useMatchMedia } from '~/App/shared/hooks/use-match-media';

import { Video } from './components/Video';
import { Heading } from './components/Heading';
import { Background } from './components/Background';
import { ContentInHero } from './components/ContentInHero';
import { ContentBelowHero } from './components/ContentBelowHero';
import { ScrollDownButton } from './components/ScrollDownButton';
import { BackgroundColor, Padding, Width } from '~/types/Block';
import { IImageSource } from '~/types/IImage';

type WrapperProps = {
  small: {
    size: string;
  };
  large: {
    size: string;
  };
};

const Wrapper = styled(Container)<WrapperProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: ${({ small, large }) =>
    small.size === 'large' || large.size === 'large' ? 'flex-end' : 'center'};
  padding-bottom: ${({ small, large }) =>
    small.size === 'large' || large.size === 'large' ? '2rem' : '0'};
  align-items: center;

  ${mq('≥large')`
    justify-content: center;
    padding-bottom: 0;
  `};
`;

type ContentProps = {
  textAlign?: string;
  width?: Width;
  small: {
    size: string;
  };
  large: {
    size: string;
  };
};

const Content = styled.div<ContentProps>`
  width: 100%;
  max-width: ${({ small }) => (small.size === 'large' ? '70%' : 'none')};
  align-self: ${({ small }) =>
    small.size === 'large' ? 'flex-start' : 'center'};
  text-align: ${({ textAlign, small }) =>
    small.size === 'large' ? 'left' : textAlign};

  ${mq<ContentProps>('≥small')`
    max-width: ${({ width }) => (width === 'normal' ? '35rem' : 'none')};
    align-self: ${({ width, large }) =>
      width === 'normal' && large.size === 'large' ? 'flex-start' : 'center'};
    text-align: ${({ textAlign = 'left' }) => textAlign};
  `};
`;
type Props = {
  body?: string;
  heading: string;
  headingSettings?: {
    textAlign: string;
    fontSize: string;
  };
  width?: Width;
  padding?: Padding;
  imageAlt?: string;
  large: {
    style: string;
    size: string;
    source: IImageSource;
    videoSource?: string;
  };
  small: {
    style: string;
    size: string;
    source: IImageSource;
    videoSource?: string;
    showButton?: boolean;
  };
  backgroundColor?: BackgroundColor;
  showLightTextColor: boolean;
  showContentBelowHero?: boolean;
  scrollDownButton?: {
    text: string;
  };
  children?: React.ReactNode;
};

export default function BlockHero({
  large,
  small,
  body = '',
  width,
  padding,
  heading = '',
  imageAlt = '',
  headingSettings,
  showLightTextColor,
  showContentBelowHero = false,
  scrollDownButton,
  backgroundColor,
  children
}: Props) {
  const [backgroundStyle, setBackgroundStyle] = useState<CSSProperties>({});

  const { showButton = true } = small;

  const size = useWindowSize();
  const isDesktop = useMatchMedia('(min-width: 768px)');

  const largeBreakpoint = useMemo(
    () => ({
      ...large,
      url:
        large.source && large.source.secure_url ? large.source.secure_url : ''
    }),
    [large]
  );
  const smallBreakpoint = useMemo(
    () => ({
      ...small,
      url:
        small.source && small.source.secure_url
          ? small.source.secure_url
          : largeBreakpoint.url
    }),
    [largeBreakpoint.url, small]
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const shouldFitScreenOnDesktop = isDesktop && large.style === 'fitScreen';
    const shouldFitScreenOnMobile = !isDesktop && small.style === 'fitScreen';
    const shouldFitScreen = shouldFitScreenOnDesktop || shouldFitScreenOnMobile;

    if (!shouldFitScreen) {
      return setBackgroundStyle({});
    }

    const height = window.innerHeight;
    const header = document.getElementById('headerContainer');
    const headerHeight = header?.offsetHeight ?? 0;

    setBackgroundStyle({ height: `${height - headerHeight}px` });
  }, [isDesktop, large.style, size, small.style]);

  return (
    <div>
      <Background
        style={backgroundStyle}
        small={smallBreakpoint}
        large={largeBreakpoint}
        lightTextColor={showLightTextColor}
        backgroundColor={backgroundColor}
      >
        {imageAlt && <span role="img" aria-label={imageAlt} />}
        {large.videoSource && (
          <Video source={large.videoSource} matchMedia="(min-width: 75em)" />
        )}
        {small.videoSource && (
          <Video source={small.videoSource} matchMedia="(max-width: 36em)" />
        )}
        <Wrapper
          small={small}
          large={large}
          width={width}
          padding={{
            ...padding,
            left: 'small',
            right: 'small'
          }}
        >
          <Content
            width={width}
            small={small}
            large={large}
            textAlign={headingSettings?.textAlign}
          >
            <Heading heading={heading} fontSize={headingSettings?.fontSize} />
            <ContentInHero
              body={body}
              large={large}
              small={{ ...small, showButton }}
              hasHeading={!!heading}
              showContentBelowHero={showContentBelowHero}
            />
            {children}
          </Content>
        </Wrapper>
        <ScrollDownButton text={scrollDownButton?.text} />
      </Background>
      <ContentBelowHero
        width={width}
        body={body}
        showContentBelowHero={showContentBelowHero}
      />
    </div>
  );
}
