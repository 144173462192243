import React from 'react';
import styled from 'styled-components';

import { mq } from '~/lib/mq';

const Section = styled.div`
  padding: 1rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;

  ${mq('≥medium')`
    flex-direction: row;
    padding: 2rem;
  `};
`;

const Image = styled.img`
  width: 135px;
  height: auto;
`;

export function Footer() {
  return (
    <Section>
      <div />
      <Image src="https://res.cloudinary.com/cancerfonden/image/upload/v1605538750/90_Konto_Logo_RGB_atvggd.png" />
    </Section>
  );
}
