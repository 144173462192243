import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { heading4 } from '~/App/helpers/mixins';
import { angleDown } from '~/App/helpers/icons';

export const Header = styled.h4`
  ${heading4};
  line-height: 1;
  display: block;
  color: ${(props) => props.theme.colors.darkBlue};
  text-align: center;
  padding: 0.8125rem 2.75rem;
  cursor: pointer;
  position: relative;

  ${mq('≥large')`
    padding: 0 4.5rem 0 2.75rem;
  `};

  &::after {
    ${angleDown};
    font-size: 1.75rem;
    line-height: 1;
    display: block;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.themeColors.primary};
    text-align: center;
    position: absolute;
    right: 0;
    top: calc(50% - 0.875rem);
    color: ${({ theme }) => theme.colors.white};
    transition: transform 0.3s ease;
    transform: ${({ isExpanded = true }) =>
      isExpanded ? 'none' : 'rotate(-180deg)'};

    ${mq('≥large')`
      transform: ${({ isExpanded = true }) =>
        isExpanded ? 'rotate(-180deg)' : 'none'};
    `};
  }

  &:hover,
  &:focus {
    &::after {
      background-color: ${({ theme }) => theme.themeColors.primaryHover};
    }
  }
`;
