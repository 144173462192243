import React, { useMemo } from 'react';

import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { getHoursSinceDate } from '~/App/helpers/date.service';
import { EmailVerificationNotification } from './components/EmailVerificationNotification';
import { IncreaseMonthlyDonationTeaser } from './components/IncreaseMonthlyDonationTeaser';
import { MonthlyDonorTeaser } from './components/MonthlyDonorTeaser';

type View = 'emailVerification' | 'increaseMonthlyDonation' | 'monthlyDonation';

type Props = {
  purchaseInfo:
    | { isMonthlyDonor: false }
    | { isMonthlyDonor: true; autogiroAmount: number };
};

export function TeaserOrNotification({ purchaseInfo }: Props) {
  const user = useAuthenticationContext((x) => x.user);

  const showEmailVerification = useMemo(() => {
    if (user?.emailStatus === 'verified') {
      return false;
    }

    if (user?.firstLoginAt) {
      if (getHoursSinceDate(user.firstLoginAt) < 24) {
        return false;
      }
    }

    return true;
  }, [user?.emailStatus, user?.firstLoginAt]);

  const view = useMemo<View>(() => {
    if (showEmailVerification) {
      return 'emailVerification';
    }

    if (purchaseInfo.isMonthlyDonor && purchaseInfo.autogiroAmount) {
      return 'increaseMonthlyDonation';
    }

    return 'monthlyDonation';
  }, [purchaseInfo, showEmailVerification]);

  if (view === 'emailVerification') {
    return <EmailVerificationNotification />;
  }

  if (view === 'increaseMonthlyDonation') {
    return (
      <IncreaseMonthlyDonationTeaser
        amount={purchaseInfo.isMonthlyDonor ? purchaseInfo.autogiroAmount : 0}
      />
    );
  }

  return <MonthlyDonorTeaser />;
}
