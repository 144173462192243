import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { loadData } from './SelfTest.LoadData';
import { SelfTest } from './SelfTest';
import { SelfTestProvider } from './context/SelfTestProvider';
import { IQuestionSession, IAnswer } from '~/types/IQuestionSession';
import { ISelfTestConfig } from './types';

type PageContent = {
  defaultSession: IQuestionSession | null;
  defaultAnswers: IAnswer[];
  config: ISelfTestConfig;
};

const View = (props: any) => {
  const pageContent = usePageContent<PageContent>();

  const children = useMemo(
    () =>
      pageContent ? (
        <SelfTestProvider {...pageContent} children={<SelfTest {...props} />} />
      ) : null,
    [pageContent, props]
  );

  return children;
};

View.loadData = loadData;

export default View;
