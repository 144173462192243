import React from 'react';
import styled from 'styled-components';

import { AuthorHelperContext } from './AuthorHelperContext';

const editorUrl = process.env.EDITOR_URL || '';
const showEditorLink = process.env.EDITOR_LINKS === 'enabled';

const Wrapper = styled.div`
  position: relative;
`;

const PageLinkWrapper = styled.div`
  position: absolute;
  background-color: red;
  border: 1px solid pink;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -75%);
  transition: transform 0.3s ease;
  border-radius: 0 0 5px 5px;
  padding: 0.3rem 1rem;
  color: white;

  &:hover {
    transform: translate(-50%, 0);
    z-index: 901;
  }
`;

const Link = styled.a`
  color: #fff;
  padding-right: 0.7rem;
`;

const AuthorHelperToggle = styled.button`
  cursor: pointer;
  padding: 0;
  padding-left: 0.7rem;
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
`;

export default class AuthorHelperPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorHelperEnabled: true
    };

    this.toggleAuthorHelper = this.toggleAuthorHelper.bind(this);
  }

  toggleAuthorHelper() {
    this.setState((prevState) => ({
      authorHelperEnabled: !prevState.authorHelperEnabled
    }));
  }

  render() {
    const { id = '', path = '', title = '', publishState = '' } = this.props;

    if (!showEditorLink || !id) {
      return <div>{this.props.children}</div>;
    }

    return (
      <AuthorHelperContext.Provider value={this.state.authorHelperEnabled}>
        <Wrapper>
          <PageLinkWrapper>
            <Link
              href={editorUrl + path + id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title} ({publishState})
            </Link>
            {' | '}
            <AuthorHelperToggle onClick={this.toggleAuthorHelper} type="button">
              {this.state.authorHelperEnabled
                ? 'Dölj redaktörslänkar'
                : 'Visa redaktörslänkar'}
            </AuthorHelperToggle>
          </PageLinkWrapper>
          {this.props.children}
        </Wrapper>
      </AuthorHelperContext.Provider>
    );
  }
}
