import React from 'react';
import styled from 'styled-components';

import { HtmlContent } from '~/App/shared/components/HtmlContent';
import { mq } from '~/lib/mq';

type WrapperProps = {
  showContentBelowHero: boolean;
  small: {
    size: string;
    showButton?: boolean;
  };
  large: {
    size: string;
  };
};
const Wrapper = styled.div<WrapperProps>`
  margin-top: 1rem;
  display: ${({ small, showContentBelowHero }) =>
    !small.showButton || showContentBelowHero ? 'none' : 'block'};

  ${mq('≥large')`
    display: block;
    margin-top: 1.5rem;
  `};

  p {
    ${mq<WrapperProps>('≥large')`
      font-size: ${({ small }) =>
        small.size === 'small' ? '1.125rem' : '1.5rem'};
      line-height: ${({ small }) =>
        small.size === 'small' ? '1.5' : '1.416666667'};
    `};
  }
`;

const Text = styled(HtmlContent)`
  margin-top: 0;
`;

type Props = {
  body: string;
  hasHeading: boolean;
  showContentBelowHero: boolean;
  small: {
    size: string;
    showButton?: boolean;
  };
  large: {
    size: string;
  };
};

export function ContentInHero({ body, hasHeading, ...props }: Props) {
  if (!body) {
    return null;
  }

  return (
    <Wrapper {...props}>
      <Text spacingTop={hasHeading} children={body} />
    </Wrapper>
  );
}
