import React from 'react';
import styled from 'styled-components';

import { primaryButton } from '~/App/helpers/mixins';
import { x } from '~/App/helpers/icons';

import { largeShareButton } from './LargeShareButtons.styled';

import TwitterShareUrl from '../TwitterShareUrl';

const Button = styled(TwitterShareUrl)`
  ${primaryButton};
  background-color: ${({ theme }) => theme.colors.twitter};
  border-color: ${({ theme }) => theme.colors.twitter};

  &:hover,
  &:focus {
    background-color: #71d0f0;
    border-color: #71d0f0;
  }

  &:before {
    ${x};
  }

  ${largeShareButton};
`;

type Props = {
  to: string;
  text?: string;
  hashtags?: string[];
};

export function X({ to, text, hashtags }: Props) {
  return (
    <Button to={to} text={text} hashtags={hashtags} children="Dela på X" />
  );
}
