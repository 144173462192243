import { createContext } from 'react';
import { IUser } from '~/types/IUser';

type Func<T, R = void> = (_value: T) => R;
type FuncVoid = () => void;
type Callback = () => void;

export type HandleLoginOptions = {
  qrInit?: boolean;
  sameDevice?: boolean;
  redirectLocation?: string;
  testLogin?: boolean;
  callback?: Callback;
};

export type HandleLoginResponse = {
  shouldRedirect: boolean;
  success: boolean;
  user: IUser | null;
};

export type HandleLoginRedirectOptions = {
  path?: string;
  callback?: Func<void>;
  user: {
    emailStatus?: string;
  };
};

export type LoginContextValue = {
  qrCode?: string;
  isPolling: boolean;
  isAuthenticated: boolean;
  user: IUser | null;
  handlers: {
    handleLogout: () => Promise<void>;
    handleLogin: Func<HandleLoginOptions, Promise<HandleLoginResponse>>;
    handleLoginAbort: FuncVoid;
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const voidFunc = () => {};

const defaultValue: LoginContextValue = {
  isPolling: false,
  isAuthenticated: false,
  user: null,
  handlers: {
    handleLogout: () => Promise.resolve(),
    handleLogin: (_: HandleLoginOptions) =>
      Promise.resolve({ success: false, shouldRedirect: false, user: null }),
    handleLoginAbort: voidFunc
  }
};

export const LoginContext = createContext<LoginContextValue>(defaultValue);
