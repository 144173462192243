import { useMemo } from 'react';

import { ICustomerContact } from '~/types/ICustomerContact';

import { ContactCustomerValues, ProductValues } from '.';

export type LotteryTicketValues = {
  productOptions: {
    lotteryTicket: ProductValues['productOptions']['product'];
    receiverContact: ICustomerContact;
  };
};

type InputProps = ContactCustomerValues & ProductValues;

type OutputProps<T> = LotteryTicketValues &
  ContactCustomerValues &
  ProductValues &
  T;

export function useLotteryTicketValues<T extends InputProps>(values: T) {
  return useMemo<OutputProps<T>>(
    () => ({
      ...values,
      productOptions: {
        ...values?.productOptions,
        lotteryTicket: values.productOptions.product,
        receiverContact: values.productOptions.customerContact
      },
      handlers: values.handlers
    }),
    [values]
  );
}
