import React from 'react';
import styled from 'styled-components';

import { siteUrl } from '~/App/helpers/env';
import ShareButtons from '~/App/shared/components/ShareButtons';
import { ICollection } from '~/types/ICollection';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Text = styled.div`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 0.75rem;
`;

type Props = {
  collection: ICollection;
  className?: string;
  customText?: string;
};

export function Share({ collection, ...props }: Props) {
  return (
    <Wrapper {...props}>
      <Text
        children={props.customText ? props.customText : 'Dela minnessidan'}
      />
      <ShareButtons
        showFacebook
        showX
        showEmail
        showLink
        invertedColors
        facebook={`Det finns en minnessida för ${collection.name} hos Cancerfonden. Var med och dela dina minnen tillsammans med mig där.`}
        tweet={{
          text: `Det finns en minnessida för ${collection.name} hos Cancerfonden. Var med och dela dina minnen tillsammans med mig där.`
        }}
        email={{
          subject: `Det finns en minnessida för ${collection.name}`,
          body: ` Hej, det finns en minnessida för ${
            collection.name
          } hos Cancerfonden. Där kan vi dela minnen och tankar tillsammans. Du hittar den här: ${siteUrl(
            `/stod-oss/minnessidor/${collection.slug}`
          )}`
        }}
      />
    </Wrapper>
  );
}
