import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { pick } from 'lodash';
import dayjs from 'dayjs';

import { cookie } from '~/App/helpers/cookie';
import { paymentMethods } from '~/App/config/paymentMethods';
import { useUtmContext } from '~/App/contexts/Utm';
import { uploadImage } from '~/App/helpers/cloudinary-upload-helper';
import { getClientId } from '~/App/helpers/ga-helper';
import { getUrl, isCloudinaryUrl } from '~/App/helpers/media-helper';
import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';

import { purchases } from '~/App/helpers/http';
import { SubmitState } from '../types/SubmitState';
import { SubmitValues } from '../types/SubmitValues';

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
type Props = {
  setState: Dispatch<SetStateAction<SubmitState>>;
};

export function usePurchaseRequestHandlers({ setState }: Props) {
  const utm = useUtmContext();
  const clientId = useMemo<string | null>(getClientId, []);

  const getCertificateIllustration = useCallback(
    async (values: SubmitValues) => {
      try {
        const url = values.productOptions?.certificate?.illustration;

        if (!url) return;
        if (isCloudinaryUrl(url)) return url;

        // update to cloudinary
        const fetchResponse = await fetch(url);
        const blob = await fetchResponse.blob();
        const uploadResponse = await uploadImage(blob, {
          timeout: 30000,
          folder: 'gift-certificate-custom-images'
        });

        // crop to correct dimensions
        const formattedUrl = getUrl(
          uploadResponse?.data?.secure_url,
          {
            width: 192,
            height: 192,
            quality: 80,
            radius: 'max',
            gravity: 'face',
            crop: 'fill'
          },
          'png'
        );

        return formattedUrl;
      } catch (error) {
        // ignore
      }

      return null;
    },
    []
  );

  const getInMemoryOfContact = useCallback((values: SubmitValues) => {
    if (!values.productOptions?.inMemoryOfContact) {
      return null;
    }

    const { death, funeral, birth } = values.productOptions.inMemoryOfContact;
    const inMemoryOfContact = values.productOptions.inMemoryOfContact;

    return {
      ...inMemoryOfContact,
      death: {
        ...death,
        date: death?.date ? dayjs(death.date).format('YYYY-MM-DD') : null
      },
      birth: {
        ...birth,
        date: birth?.date ? dayjs(birth.date).format('YYYY-MM-DD') : null
      },
      funeral: {
        ...funeral,
        date: funeral?.date ? dayjs(funeral.date).format('YYYY-MM-DD') : null
      }
    };
  }, []);

  const getCustomerContact = useCallback((values: SubmitValues) => {
    if (!values.customerContactType) {
      return {
        ssn: '',
        email: ''
      };
    }

    if (values.customerContactType === 'automatic') {
      return pick(values.productOptions.customerContact, ['email', 'ssn']);
    }

    if (values.productOptions.lotteryTicket) {
      return values.productOptions.customerContact;
    }

    return values.productOptions.customerContact;
  }, []);

  const getPaymentMethodOptions = useCallback(async (values: SubmitValues) => {
    const price = getListPrice(values.productOptions);
    const quantity = getQuantity(values.productOptions);
    const totalPrice = price * quantity;

    if (values.paymentMethod?.id === paymentMethods.autoGiroBankId) {
      const userMessage = `Jag vill stödja Cancerfonden som månadsgivare med ${totalPrice}:- per månad.`;

      return {
        ...values.paymentMethodOptions,
        userMessage: userMessage
      };
    }

    if (values.paymentMethod?.id !== paymentMethods.klarnaPayments) {
      return values.paymentMethodOptions;
    }

    await values.handlers.klarnaPaymentsUpdateSession(totalPrice);

    const response = await values.handlers.klarnaPaymentsAuthorize();

    if (!response.authorization_token) {
      await values.handlers.klarnaPaymentsLoad(totalPrice);

      throw {
        reason: `Failed to obtain authorization_token from Klarna`
      };
    }

    return {
      authorizationToken: response.authorization_token
    };
  }, []);

  const buildAsync = useCallback(
    async (values: SubmitValues) => {
      const clientOptions: Record<string, unknown> = {
        ...values.clientOptions
      };

      if (values.clientOptions?.clientId === '' && clientId) {
        clientOptions.clientId = clientId;
        clientOptions.gclId = cookie.get('gclid');
      }

      const purchase = {
        data: {
          product: values.product,
          paymentMethod: values.paymentMethod,
          productOptions: {
            ...values.productOptions,
            customerContact: getCustomerContact(values),
            inMemoryOfContact: getInMemoryOfContact(values),
            certificate: values.productOptions
              ? {
                  ...values.productOptions.certificate,
                  illustration: await getCertificateIllustration(values)
                }
              : null
          },
          paymentMethodOptions: await getPaymentMethodOptions(values),
          clientOptions: clientOptions,
          social: {
            anonymous: values.socialName === '',
            profile: {
              name: values.socialName
            }
          },
          utmTags: utm
        },
        responseType: 'text'
      };

      return purchase;
    },
    [
      utm,
      clientId,
      getCustomerContact,
      getInMemoryOfContact,
      getPaymentMethodOptions,
      getCertificateIllustration
    ]
  );

  const sendAsync = useCallback(
    (purchase: Awaited<ReturnType<typeof buildAsync>>) => {
      if (purchase.data.paymentMethod?.id !== paymentMethods.autoGiroBankId) {
        return purchases.create(purchase);
      }

      setState((state) => ({
        ...state,
        isBankId: true
      }));

      return purchases.create(purchase);
    },
    [setState]
  );

  return {
    buildAsync,
    sendAsync
  };
}
