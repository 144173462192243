import React, { useCallback } from 'react';
import { Related } from '~/App/shared/components/Related';
import { IRelatedBlock } from '~/types/RelatedBlock';
import { fetch } from '~/App/helpers/http';
import { contentApi } from '~/App/helpers/env';

type Props = {
  slug: string;
  related?: IRelatedBlock;
};

export function InlineRelatedBlock({ slug, related }: Props) {
  const fetchPage = useCallback(
    async (page: number) => {
      const response = await fetch({
        url: contentApi('/v1/pages'),
        params: { slug, page }
      });

      return response?.data?.related?.items;
    },
    [slug]
  );

  if (!related) {
    return null;
  }

  return <Related {...related} itemsFor={fetchPage} />;
}
