import { contentApi } from '~/App/helpers/env';
import { fetch } from '~/App/helpers/http';

import { LoadDataProps } from '~/types/routes';

export const loadData = async ({ params }: LoadDataProps<'slug'>) => {
  const url = contentApi(`/v1/products/${params?.slug}`);
  const response = await fetch({ url });

  return { product: response.data.product };
};
