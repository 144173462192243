import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

export const ItemList = styled.ul`
  position: relative;
  margin: 0 auto;
  height: 0px;
  padding: 0;
  max-height: calc(100vh - 230px);
  max-width: 592px;
  overflow-y: auto;
  border-radius: 0 0 1.375rem 1.375rem;

  &::before {
    position: fixed;
    top: 2.625rem;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2.5rem;
    content: '';
    z-index: -1;
    background-image: linear-gradient(
      rgb(255, 255, 255),
      rgba(255, 255, 255, 0)
    );
    background-repeat: no-repeat;
    background-size: 100% 2.5rem;

    ${mq('≥small')`
      top: 3rem;
    `};

    ${mq('≥large')`
      display: none;
    `};
  }

  &::after {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    z-index: -1;
    width: 100%;
    height: 3rem;
    content: '';
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgb(255, 255, 255)
    );
    background-repeat: no-repeat;
    background-size: 100% 3rem;
    border-radius: 0 0 1.375rem 1.375rem;

    ${mq('≥large')`
      display: none;
    `};
  }

  ${mq('≥large')`
    display: block;
    margin: 0;
    max-height: calc(100vh - 246px);
    max-width: none;
  `};
`;
