import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: white;
  padding: 1.75rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.lightDust};
  color: ${({ theme }) => theme.colors.darkBlue};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  &::after {
    content: ' ';
    background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1599574628/assets/mail/arrow-blue.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 0.5rem;
    height: 1rem;
    display: block;
  }
`;

type Props = {
  text: string;
  url: string;
};

export function Item({ text, url }: Props) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => navigate(url), [navigate, url]);

  return <Wrapper children={text} onClick={handleClick} />;
}
