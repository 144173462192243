import React, { ComponentType, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from '../shared/hooks/use-history';

// This should NOT be used. Components should be refactored into function components
export function withRouter(C: ComponentType<any>) {
  return function WithRouter(props: Record<string, unknown>) {
    const location = useLocation();
    const params = useParams();
    const history = useHistory();

    return useMemo(
      () => (
        <C
          history={history}
          location={location}
          match={{ params }}
          {...props}
        />
      ),
      [history, location, params, props]
    );
  };
}
