import React, { useCallback } from 'react';
import styled from 'styled-components';
import { CollectionAdmin } from '~/types/ICollection';

// helpers
import mq from '~/App/helpers/mq';
import { primaryButton } from '~/App/helpers/mixins';

import { Modal } from '~/App/shared/components/Elements';
import { AccountHeading2 } from '~/App/views/Account/shared/components/AccountHeadings';
import { Avatar } from '~/App/shared/components/Avatar';

const ImageWrapper = styled.div`
  margin-top: 2.5rem;

  ${mq('<small')`
    margin: 1.5rem 0 0;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 4rem 0 3.5rem;

  ${mq('<small')`
    flex-direction: column-reverse;
    align-items: center;
    margin: 2rem 0 0.5rem;
  `};
`;
const Button = styled.button`
  ${primaryButton};
  width: 12.625rem;
  height: 2.625rem;
  margin: 0 0.75rem 0 0.75rem;
  ${mq('<small')`
    margin: 0.5rem 0 0.5rem;
  `};
`;
const Heading = styled(AccountHeading2)`
  font-size: 1.375rem;
  text-align: center;
  max-width: 34rem;
  line-height: 1.36;
`;

const Text = styled.p`
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

type Props = {
  admin: CollectionAdmin;
  showModal: (showModal: boolean) => void;
  deleteAdmin: () => void;
};

export function ConfirmationModal({ admin, showModal, deleteAdmin }: Props) {
  const closeModal = useCallback(() => showModal(false), [showModal]);

  return (
    <Modal closeModal={closeModal}>
      <Content>
        <ImageWrapper>
          <Avatar user={admin} size="medium" />
        </ImageWrapper>
        <Heading
          children={
            'Vill du ta bort ' +
            (admin.you ? 'dig' : `${admin.firstName} ${admin.lastName}`) +
            ' som administratör?'
          }
        />
        <Text
          children={
            (admin.you ? 'Du' : `${admin.firstName}`) +
            ' kommer inte längre kunna hantera insamlingen.'
          }
        />
        <ButtonWrapper>
          <Button buttonStyle="outlinePrimary" onClick={closeModal}>
            Avbryt
          </Button>
          <Button onClick={deleteAdmin}>Ta bort</Button>
        </ButtonWrapper>
      </Content>
    </Modal>
  );
}
