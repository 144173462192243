import React from 'react';

import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { whiteGradient } from '~/App/helpers/mixins';
import { ButtonStyle } from '~/App/config/buttonStyles';

// Shared components
import { PrimaryButton } from '~/App/shared/components/Elements';

const Wrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  &::before {
    ${whiteGradient};
  }

  ${mq('≥small')`
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem;
        background: ${({ theme }) => theme.colors.white};
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    `};
`;

const Button = styled(PrimaryButton)`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

  ${mq('≥small')`
        box-shadow: none;
    `};
`;

type Props = {
  to?: string;
  onClick?: () => void;
  buttonStyle: ButtonStyle;
  children: string;
  className?: string;
};

export default function FloatingButton({
  to,
  onClick,
  buttonStyle,
  children,
  ...props
}: Props) {
  return (
    <Wrapper {...props}>
      <Button
        buttonType={to ? 'link' : undefined}
        buttonStyle={buttonStyle}
        children={children}
        to={to}
        onClick={onClick}
      />
    </Wrapper>
  );
}
