const store: Record<string, Promise<Result>> = {};

type Params = {
  url: string;
  cache?: boolean;
  attributes?: Record<string, string>;
};

type Result = {
  loaded: boolean;
};

export const loadScript = ({ url, cache = true, attributes = {} }: Params) => {
  if (!url) {
    return Promise.reject({ message: 'No url specified' });
  }

  if (cache && store[url]) {
    return store[url];
  }

  store[url] = new Promise<Result>((resolve) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = url;

    for (const key in attributes) {
      script.setAttribute(key, attributes[key]);
    }

    script.setAttribute;
    script.onload = function () {
      resolve({ loaded: true });
    };

    const x = document.getElementsByTagName('script')[0];

    x.parentNode?.insertBefore(script, x);
  });

  return store[url];
};

export default loadScript;
