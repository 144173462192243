import { mePurchases } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export async function loadData({ cookies }: LoadDataProps) {
  return {
    purchaseInfo: { isMonthlyDonor: false }
  };
  // if (!cookies.secretKey) {
  //   return {
  //     purchaseInfo: null
  //   };
  // }

  // const data = await mePurchases.purchasesInfo({
  //   secretKey: cookies.secretKey
  // });

  // return {
  //   purchaseInfo: data
  // };
}
