import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { assemble } from '~/lib/slug-helper';

class OneRowPreamble extends React.Component {
  render() {
    if (_.isEmpty(this.props.related)) {
      return null;
    }

    var heading = this.props.heading
      ? 'Läs mer om ' + this.props.heading
      : 'Läs mer';

    return (
      <div className="relatedBox">
        <div className="relatedBox__heading">{heading}</div>
        <div className="relatedBox__list">
          {_.map(this.props.related, function (related, key) {
            if (related.teaserPreamble || related.preamble) {
              return (
                <div key={key} className="relatedBox__listItem">
                  <Link className="relatedBox__link" to={assemble(related)}>
                    {related.teaserHeading || related.heading}
                  </Link>
                  <span className="relatedBox__separator">-</span>
                  <span className="relatedBox__preamble">
                    {related.teaserPreamble || related.preamble}
                  </span>
                </div>
              );
            }

            return (
              <div key={key} className="relatedBox__listItem">
                <Link className="relatedBox__link" to={assemble(related)}>
                  {related.teaserHeading || related.heading}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

OneRowPreamble.displayName = 'RelatedBoxOneRowPreamble';

OneRowPreamble.defaultProps = {
  heading: '',
  related: []
};

export default OneRowPreamble;
