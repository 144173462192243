import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { siteUrl } from '~/App/helpers/env';
import { heading2 } from '~/App/helpers/mixins';
import { withRouter } from '~/App/hocs/with-router';

// Shared components
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import ShareButtons from '~/App/shared/components/ShareButtons';

const StyledBlockBackground = styled(BlockBackground)`
  padding: 2rem 1rem;
  text-align: center;

  ${mq('≥small')`
        padding: 3rem 1rem;
    `};
`;

const Heading = styled.h2`
  ${heading2};
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.themeColors.primary};
`;

const Description = styled.p`
  max-width: 46rem;
  margin: auto;
`;

const StyledShareButtons = styled(ShareButtons)`
  margin: 1.5rem auto 0;

  ${mq('≥small')`
      margin-top: 2rem;
  `};
`;

function Block(props) {
  const {
    backgroundColor = 'none',
    heading,
    body,
    url,
    twitter = {},
    facebook = {},
    email = {},
    link = {},
    print = {},
    location
  } = props;

  const urlToShare = siteUrl(url || location.pathname);

  return (
    <StyledBlockBackground backgroundColor={backgroundColor}>
      <Heading>{heading}</Heading>
      <Description>{body}</Description>
      <StyledShareButtons
        showFacebook={facebook.visible}
        showX={twitter.visible}
        showEmail={email.visible}
        showLink={link.visible}
        showPrint={print.visible}
        tweet={twitter}
        email={{
          ...email,
          body: `${email.body} \n\n ${urlToShare}`
        }}
        to={urlToShare}
        largeGutter={true}
      />
    </StyledBlockBackground>
  );
}

export const ShareBlock = withRouter(Block);
