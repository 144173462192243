import React from 'react';

import Text from '~/App/shared/components/Fields/Text';
import { InputWrapper } from '../components/InputWrapper';

import { IValidation } from '~/App/shared/components/Validation';
import { ContactCustomerValues } from '../../../../States';

type Props = {
  values: ContactCustomerValues;
  validation: IValidation;
  copy?: string;
};

export function Email({
  values,
  validation,
  copy = 'Bekräftelse för din betalning skickas via e-post.'
}: Props) {
  return (
    <InputWrapper>
      <Text
        name="email"
        type="email"
        value={values.productOptions.customerContact.email}
        onBlur={validation.showError.bind(
          undefined,
          'productOptions.customerContact.email'
        )}
        errors={validation.errors['productOptions.customerContact.email']}
        isValid={validation.isValid('productOptions.customerContact.email')}
        onChange={values.handlers.handleCustomerContactEmail}
        isInvalid={validation.isInvalid('productOptions.customerContact.email')}
        placeholder="E-post"
        autoComplete="email"
        instructions={copy}
        required
      />
    </InputWrapper>
  );
}
