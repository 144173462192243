import { orderBy } from 'lodash';
import { IRedirect } from '../../types/IRedirect';

const matchOrder = (a: IRedirect) => {
	switch (a.match) {
		case 'exact':
			return 0;
		case 'path':
			return 1;
		case 'beginsWith':
			return 2;
	}
};

const longerFirst = (a: IRedirect) => a.from.length * -1;

export const sort = (redirects: IRedirect[]): IRedirect[] => orderBy(redirects, [ matchOrder, longerFirst ]);
