import { formatMoney } from 'accounting';

import { swishTypes } from '~/App/config/swishTypes';
import { paymentMethods } from '~/App/config/paymentMethods';
import { PaymentValues, ContactCustomerValues } from '../components/States';

type Values = ContactCustomerValues & PaymentValues;
type Args = {
  data: {
    minimumPrice?: number;
  };
};

export function buildSchema({ data }: Args) {
  const { minimumPrice = 50 } = data;

  return {
    'productOptions.product.price': {
      presence: {
        allowEmpty: false,
        message: '^Ange ett belopp'
      },
      numericality: {
        greaterThan: minimumPrice - 1,
        lessThanOrEqualTo: 999999,
        message: `^Minsta gåvobelopp är  ${formatMoney(
          minimumPrice,
          'kr',
          0,
          ' ',
          undefined,
          '%v %s'
        )}`
      }
    },
    'productOptions.customerContact.email': {
      validateEmail: (_: unknown, { paymentMethod }: Values) =>
        paymentMethod?.id !== paymentMethods.klarnaPayments
    },
    'productOptions.customerContact.ssn': {
      validateSsn: (_: unknown, { customerContactType }: Values) =>
        customerContactType === 'automatic'
    },
    'productOptions.customerContact.cid': {
      validateCid: (_: unknown, { customerContactType }: Values) =>
        customerContactType === 'company'
    },
    'productOptions.customerContact.company': {
      validateCompany: (_: unknown, { customerContactType }: Values) =>
        customerContactType === 'company'
    },
    'productOptions.customerContact.firstName': {
      validateFirstName: (
        _: unknown,
        { customerContactType, paymentMethod }: Values
      ) =>
        customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip
    },
    'productOptions.customerContact.lastName': {
      validateLastName: (
        _: unknown,
        { customerContactType, paymentMethod }: Values
      ) =>
        customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip
    },
    'productOptions.customerContact.addressStreet': {
      validateStreetName: (
        _: unknown,
        { customerContactType, paymentMethod }: Values
      ) =>
        customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip
    },
    'productOptions.customerContact.addressZip': {
      validateZipCode: (
        _: unknown,
        { customerContactType, paymentMethod }: Values
      ) =>
        customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip
    },
    'productOptions.customerContact.addressCity': {
      validateCity: (
        _: unknown,
        { customerContactType, paymentMethod }: Values
      ) =>
        customerContactType === 'manual' ||
        paymentMethod?.id === paymentMethods.paymentSlip
    },
    'productOptions.customerContact.phoneNumber': {
      validatePhoneNumber: (_: unknown, { customerContactType }: Values) =>
        customerContactType === 'company'
    },
    swishPhoneNumber: {
      validatePhoneNumber: (_: unknown, { paymentMethod, swish }: Values) =>
        paymentMethod?.id === 8 && swish.swishType === swishTypes.ecommerce
    }
  };
}
