import React from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

// Shared components
import { InputWrapper } from '~/App/shared/components/Fields/InputWrapper';
import Text from '~/App/shared/components/Fields/Text';
import TextArea from '~/App/shared/components/Fields/TextArea';
import {
  PrimaryButton,
  AlertMessageContainer
} from '~/App/shared/components/Elements';
import { ImageUploader } from '~/App/shared/components/ImageUploader';
import { AccountHeading2 } from '~/App/views/Account/shared/components/AccountHeadings';
import { IValidation } from '~/App/shared/components/Validation';
import { CollectionEditState } from '../hooks/useCollectionEditState';
import { CollectionEditSubmit } from '../hooks/useCollectionEditSubmit';
import { RadioButtonAccordion } from '~/App/shared/components/Elements/RadioButtonAccordion';
import { CollectionProgressPreview } from './CollectionProgressPreview';
import { HideCollectionProgressPreview } from './HideCollectionProgressPreview';

const FlexWrapper = styled.div`
  ${mq('≥small')`
    display: flex;

    > div {
      width: 50%;

      &:last-child {
        margin-left: 1rem;
      }
    }
  `};
`;

const AlertMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: -1rem;

  ${mq('≥small')`
    margin-bottom: -2rem;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  flex-grow: 1;
  width: 100%;

  ${mq('≥small')`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
  `};
`;

const SaveCancelButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 1rem;
  }

  ${mq('≥small')`
    flex-direction: row-reverse;

    > * {
      margin-top: 0;
      margin-left: 1rem;
    }
  `};
`;

const ToggleCollectionStatusButton = styled(PrimaryButton)`
  ${mq('<small')`
    border: none;
    text-transform: uppercase;
    text-align: left;
    padding: 0;
    margin-bottom: 2rem;
    font-size: 0.9rem;
  `};
`;

const DescriptionTextArea = styled(TextArea)`
  min-height: 9rem;
`;

const CancelButton = styled(PrimaryButton)`
  min-width: 8rem;
`;

const StyledInputWrapper = styled(InputWrapper)`
  margin-top: 1rem;
`;

type Props = {
  validation: IValidation;
  state: CollectionEditState;
  submit: CollectionEditSubmit;
  error: string | null;
};

export function Form({ validation, submit, state, error }: Props) {
  return (
    <form onSubmit={submit.handleSaveSubmit}>
      <AccountHeading2>Redigera namn, avsändare, mål och bild</AccountHeading2>
      <InputWrapper>
        <Text
          name="name"
          value={state.values.name}
          onChange={state.handlers.handleChange}
          placeholder="Insamlingsnamn"
          autoComplete="off"
          onBlur={validation.showError.bind(undefined, 'name')}
          errors={validation.errors['name']}
          isValid={validation.isValid('name')}
          isInvalid={validation.isInvalid('name')}
          required
        />
      </InputWrapper>
      <FlexWrapper>
        <div>
          <StyledInputWrapper>
            <Text
              name="senderName"
              value={state.values.senderName}
              onChange={state.handlers.handleChange}
              placeholder="Avsändare"
              autoComplete="off"
              onBlur={validation.showError.bind(undefined, 'senderName')}
              errors={validation.errors['senderName']}
              isValid={validation.isValid('senderName')}
              isInvalid={validation.isInvalid('senderName')}
              required
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <Text
              name="goal"
              value={state.values.goal?.replace('.0', '')}
              onChange={state.handlers.handleChange}
              placeholder="Målsumma"
              autoComplete="off"
              suffix="kr"
              onBlur={validation.showError.bind(undefined, 'goal')}
              errors={validation.errors['goal']}
              isValid={validation.isValid('goal')}
              isInvalid={validation.isInvalid('goal')}
              required
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <RadioButtonAccordion
              value={state.values.showProgressBadge}
              onSelect={state.handlers.handleShowProgressBadge}
              items={[
                {
                  id: true,
                  name: 'Ja tack, visa andra hur min insamling går',
                  children: <CollectionProgressPreview />
                },
                {
                  id: false,
                  name: 'Nej tack, visa inte hur min insamling går',
                  children: <HideCollectionProgressPreview />
                }
              ]}
            />
          </StyledInputWrapper>
        </div>
        <ImageUploader
          value={state.values.imageUrl}
          onChange={state.handlers.handleImageChange}
          children="Byt insamlingsbild"
          showProgressBadge={state.values.showProgressBadge}
          collectionType={state.values.collectionType}
        />
      </FlexWrapper>
      <AccountHeading2>Berätta mer om din insamling</AccountHeading2>
      <InputWrapper>
        <DescriptionTextArea
          name="description"
          value={state.values.description}
          onChange={state.handlers.handleChange}
          placeholder="Beskrivning"
          autoComplete="off"
          onBlur={validation.showError.bind(undefined, 'description')}
          errors={validation.errors['description']}
          isValid={validation.isValid('description')}
          isInvalid={validation.isInvalid('description')}
          required
        />
      </InputWrapper>
      {error && (
        <AlertMessageWrapper>
          <AlertMessageContainer messageStyle="error" children={error} />
        </AlertMessageWrapper>
      )}
      <ButtonWrapper>
        {state.values.isPaused && (
          <ToggleCollectionStatusButton
            buttonStyle="outlinePrimary"
            onClick={submit.handleUnpauseSubmit}
            isLoading={submit.isUnpausing}
            children="Starta insamling"
          />
        )}
        {!state.values.isPaused && (
          <ToggleCollectionStatusButton
            buttonType="link"
            buttonStyle="outlinePrimary"
            to={`/min-sida/insamlingar/${state.values.slug}/avsluta`}
            children="Avsluta insamling"
          />
        )}
        <SaveCancelButtonWrapper>
          <PrimaryButton
            buttonType="button"
            type="submit"
            buttonStyle={validation.isValidated ? 'primary' : 'disabled'}
            children="Spara och stäng"
            isLoading={submit.isSaving}
          />
          <CancelButton
            buttonType="link"
            buttonStyle="outlinePrimary"
            to="/min-sida/insamlingar"
            children="Avbryt"
          />
        </SaveCancelButtonWrapper>
      </ButtonWrapper>
    </form>
  );
}
