import { meCollections } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

export async function loadData({ params, cookies }: LoadDataProps<'slug'>) {
  const { data } = await meCollections.show({
    slug: `${params.slug}`,
    secretKey: `${cookies.secretKey}`
  });

  return {
    data
  };
}
