import React from 'react';
import classNames from 'classnames';

import UploadHandler from './UploadHandler';

export default class ImageUploadButton extends React.Component {
  render() {
    return (
      <UploadHandler
        onUploadCompleted={this.props.onUploadCompleted}
        folder={this.props.folder}
      >
        {(uploadState) => {
          const { status } = uploadState;

          const buttonClasses = classNames(
            'button',
            'button--dove',
            'ui-imageUpload',
            { 'is-loading': status === 'uploading' }
          );

          return (
            <div>
              {status === 'failed' && (
                <div className="formError" style={{ marginBottom: '1.5em' }}>
                  <div className="formError__text">
                    <span>
                      Tyvärr stödjer vi inte det filformat du försöker ladda
                      upp.
                    </span>
                  </div>
                </div>
              )}
              <div className={buttonClasses}>
                <input
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={uploadState.handlers.handleChange}
                />
                {this.props.text}
              </div>
            </div>
          );
        }}
      </UploadHandler>
    );
  }
}
