import { createContext } from 'react';

export type UtmContextValue = {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
};

export const UtmContext = createContext<UtmContextValue>({});
