import React from 'react';
import styled from 'styled-components';
import { mq } from '~/lib/mq';
import { CollectionProgress } from '~/App/shared/components/CollectionImage/components/CollectionProgress';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  ${mq('>small')`
    margin-right: 1.125rem;
  `}
`;

const Text = styled.div`
  font-size: 0.75rem;

  ${mq('>small')`
    font-size: 0.875rem;
  `}
`;

export function CollectionProgressPreview() {
  return (
    <Wrapper>
      <CollectionProgress percentage={50} position={'static'} />
      <Column>
        <Text
          children={
            'Ikonen läggs på din profilbild och visar hur långt det är kvar till insamlingens mål.'
          }
        />
      </Column>
    </Wrapper>
  );
}
