import React from 'react';
import styled from 'styled-components';

import { primaryButton } from '~/App/helpers/mixins';
import { facebook } from '~/App/helpers/icons';

import { largeShareButton } from './LargeShareButtons.styled';

import FacebookShareUrl from '../FacebookShareUrl';

const Button = styled(FacebookShareUrl)`
  ${primaryButton};
  background-color: ${({ theme }) => theme.colors.facebook};
  border-color: ${({ theme }) => theme.colors.facebook};

  &:hover,
  &:focus {
    filter: brightness(1.125);
  }

  &:before {
    ${facebook};
  }

  ${largeShareButton};
`;

type Props = {
  to: string;
  text?: string;
};

export function Facebook({ to, text }: Props) {
  return (
    <Button to={to} text={text}>
      Dela på Facebook
    </Button>
  );
}
