/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect
} from 'react';

// Hooks
import useWindowSize from '~/App/shared/hooks/use-window-size';
import useScroll from '~/App/shared/hooks/use-scroll';

import { Wrapper, ItemWrapper, ArrowLeft, ArrowRight, Item } from './styled';

export default function Slider({ children }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const windowSize = useWindowSize();
  const wrapperRef = useRef();

  const itemWidth = useMemo(() => wrapperRef.current?.clientWidth ?? 0, [
    windowSize
  ]);

  const isLast = useMemo(() => selectedIndex === children.length - 1, [
    children,
    selectedIndex
  ]);

  const isFirst = useMemo(() => selectedIndex === 0, [selectedIndex]);

  const updateIndex = useCallback(
    (delta) => () => {
      if ((delta < 0 && isFirst) || (delta > 0 && isLast)) {
        return;
      }

      setSelectedIndex(selectedIndex + delta);
    },
    [setSelectedIndex, selectedIndex, isFirst, isLast]
  );

  const renderItem = useCallback(
    (child, index) => (
      <Item width={itemWidth} key={index}>
        {child}
      </Item>
    ),
    [itemWidth]
  );

  useScroll(
    (e) => {
      const offset = e.target.scrollLeft;
      const width = e.target.clientWidth;
      const isEven = offset % width === 0;
      const suggestedIndex = offset / width;

      if (!isEven) {
        return;
      }

      setSelectedIndex(suggestedIndex);
    },
    {
      el: wrapperRef.current
    }
  );

  useEffect(() => {
    const current = wrapperRef.current.scrollLeft;
    const suggested = selectedIndex * itemWidth;

    if (suggested === current) {
      return;
    }

    wrapperRef.current.scrollTo({
      left: suggested,
      behavior: 'smooth'
    });
  }, [wrapperRef, selectedIndex, itemWidth]);

  return (
    <Wrapper>
      <ArrowLeft onClick={updateIndex(-1)} disabled={isFirst} />
      <ItemWrapper ref={wrapperRef}>{children.map(renderItem)}</ItemWrapper>
      <ArrowRight onClick={updateIndex(1)} disabled={isLast} />
    </Wrapper>
  );
}
