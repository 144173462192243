import React, { ReactNode, useMemo } from 'react';

import mediaHelper from '~/App/helpers/media-helper';

import {
  Wrapper,
  ImageWrapper,
  ProductImage,
  Content
} from '~/App/shared/components/ThanksPage';

type Props = {
  image: string;
  imagePadding?: boolean;
  children: ReactNode;
  transformation?: boolean;
};

export function Header({
  image,
  transformation = true,
  imagePadding,
  children
}: Props) {
  const transformedImage = useMemo(
    () =>
      mediaHelper.getUrl(
        image,
        transformation && {
          transformation: [
            {
              width: 280,
              height: 280,
              crop: 'lpad',
              gravity: 'center'
            },
            {
              width: 92,
              height: 92,
              dpr: '2.0',
              crop: 'pad',
              quality: 100
            }
          ]
        }
      ),
    [image, transformation]
  );

  return (
    <Wrapper>
      <ImageWrapper padding={imagePadding}>
        <ProductImage src={transformedImage} />
      </ImageWrapper>
      <Content children={children} />
    </Wrapper>
  );
}
