import { useRef, useEffect, useState } from 'react';
import { orderBy } from 'lodash';

import { ICommerceProduct } from '~/types/ICommerceProduct';
import { stockStatus } from '~/App/helpers/stock-status';

type Props = {
  items: ICommerceProduct[];
};

export function useImpressions({ items }: Props) {
  const [isSent, setSent] = useState<string[]>([]);

  const container = useRef<HTMLDivElement>(null);
  const itemClassName = 'product-card';

  useEffect(() => {
    if (!items.length) {
      return; // no items to observe
    }

    if (typeof window === 'undefined') {
      return; // server side
    }

    if (!('IntersectionObserver' in window)) {
      return; // not supported
    }

    const onEnter = (entries: IntersectionObserverEntry[]) => {
      const intersectingEntries = entries.filter((x) => x.isIntersecting);
      const productIds = intersectingEntries
        .map((x) => `${x.target.getAttribute('data-id')}`)
        .filter((x) => !isSent.includes(x))
        .filter((x) => x);

      const products = items.filter((x) => productIds.includes(x.id));

      if (!products.length) {
        return; // nothing to log
      }

      setSent((state) => [...state, ...productIds]);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'eec.impressionView',
        ecommerce: {
          impressions: products.map((product) => {
            const sorted = orderBy(product.variants || [], [(x) => !x.default]);
            const variant = sorted.find(() => true);

            return {
              id: variant?.id,
              name: product.name,
              category: `${product.target}/${product.type}`,
              list: 'Gåvoshop',
              variant: variant?.name,
              position: items.indexOf(product) + 1,
              dimension10: stockStatus(variant?.stock?.status)
            };
          })
        }
      });
    };

    const observer = new IntersectionObserver(onEnter, {
      threshold: 0.5
    });

    container.current?.querySelectorAll(`.${itemClassName}`).forEach((x) => {
      observer.observe(x);
    });

    return () => observer.disconnect();
  }, [isSent, items]);

  return {
    container,
    itemClassName
  };
}
