import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { buttonStyles } from '~/App/config/buttonStyles';

// Helpers
import mq from '~/App/helpers/mq';
import { heading4, arrowLink } from '~/App/helpers/mixins';
import { mePurchases } from '~/App/helpers/http';

// Shared components
import Link from '~/App/shared/components/Link';
import { AccountColumn } from '~/App/views/Account/shared/components/AccountColumn';
import { NoResultContent } from '~/App/views/Account/shared/components/NoResultContent';

// Local components
import { DonationCard } from './components/DonationCard';
import { IncreaseMonthlyDonationTeaser } from '../../components/TeaserOrNotification/components/IncreaseMonthlyDonationTeaser';
import { MonthlyDonorTeaser } from '../../components/TeaserOrNotification/components/MonthlyDonorTeaser';

import { PrimaryButton } from '~/App/shared/components/Elements';
import { IPurchaseInfo } from '~/types/IPurchaseInfo';
import { IMePurchase } from '~/types/IPurchase';

const ShowMoreButton = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
`;

const Heading4 = styled.h4`
  ${heading4};
  color: ${({ theme }) => theme.themeColors.primary};
  margin: 0 0 2rem 0;
`;

const ArrowLink = styled(Link)`
  ${arrowLink};
  line-height: 1.25rem;
  margin-top: 0;

  ${mq('≥small')`
    line-height: 1.5rem;
  `};
`;

const InfoMessage = styled.div`
  font-size: 0.75rem;
  margin-top: 1.5rem;

  ${mq('≥small')`
  font-size: 0.875rem;
  margin-top: 2.125rem;
  width: 40rem;
  `};
`;

const PER_PAGE = 10;

type Props = {
  info: IPurchaseInfo;
  data: IMePurchase[];
};

export function Donations({ info, data = [] }: Props) {
  const [purchases, setPurchases] = useState(data);
  const [page, setPage] = useState(1);
  const [isVisible, setVisible] = useState(data.length === PER_PAGE);
  const [isLoadingMore, setLoadingMore] = useState(false);

  const renderPurchase = useCallback(
    (purchase: IMePurchase) => (
      <DonationCard donation={purchase} key={purchase.id} />
    ),
    []
  );

  const loadMore = useCallback(
    async (event) => {
      event.preventDefault();
      event.currentTarget.blur();

      const nextPage = page + 1;

      setLoadingMore(true);

      try {
        const data = await mePurchases.index({
          params: { page: nextPage, per_page: PER_PAGE }
        });

        setPage(nextPage);
        setPurchases((state) => [...state, ...data]);
        setVisible(data.length === PER_PAGE);
        setLoadingMore(false);
      } catch (error) {
        console.log(error);

        setLoadingMore(false);
      }
    },
    [page]
  );

  return useMemo(
    () => (
      <>
        {info && (
          <>
            {!info.isMonthlyDonor && <MonthlyDonorTeaser />}
            {info.isMonthlyDonor && info.autogiroAmount && (
              <IncreaseMonthlyDonationTeaser amount={info?.autogiroAmount} />
            )}
          </>
        )}

        {purchases.length === 0 && (
          <AccountColumn>
            <NoResultContent>
              <Heading4>Du har inte gett några gåvor ännu.</Heading4>
              <p>
                <ArrowLink to="/stod-cancerfonden">Stöd Cancerfonden</ArrowLink>
              </p>
            </NoResultContent>
          </AccountColumn>
        )}
        {purchases.length > 0 && (
          <>
            {purchases.map(renderPurchase)}
            {isVisible && purchases.length >= PER_PAGE && (
              <ShowMoreButton
                buttonStyle={buttonStyles.primary}
                onClick={loadMore}
                isLoading={isLoadingMore}
                children="Visa fler"
              />
            )}
          </>
        )}

        <InfoMessage>
          Alla sorters gåvor finns inte på Min sida än. Om du saknar någon gåva
          du skänkt, hör gärna av dig till vår givarservice.
        </InfoMessage>
      </>
    ),
    [info, purchases, renderPurchase, isVisible, loadMore, isLoadingMore]
  );
}
