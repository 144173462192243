import React, { ReactNode } from 'react';
import { FeatureToggle } from './FeatureToggle';

type Props = {
  children: ReactNode;
  feature: string;
}

export function FeatureToggleEnabled({ children, feature }: Props) {
  return (
    <FeatureToggle feature={feature}>
      {enabled => enabled ? children : null}
    </FeatureToggle>
  );
}
