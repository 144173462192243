import { orders } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

type Query = {
  purchaseToken?: string;
  paymentMethodId?: string | undefined;
};

export const LoadData = async ({ params, query }: LoadDataProps<string, Query>) => {
  const response = await orders.get(params?.orderId ?? '', query);
  return {
    order: response.data
  };
}
