import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { Item } from './components/Item';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type IItem<T> = {
  id: T;
  name: ReactNode;
  children: ReactNode;
  icon?: ReactNode;
};

type Props<T> = {
  value: T;
  onSelect: (value: T) => void;
  items: IItem<T>[];
};

export function RadioButtonAccordion<T>({ value, items, onSelect }: Props<T>) {
  const renderItem = useCallback(
    (item: IItem<T>) => (
      <Item
        key={`${item.id}`}
        {...item}
        onSelect={onSelect}
        isActive={value === item.id}
      />
    ),
    [onSelect, value]
  );

  return <Wrapper children={items.map(renderItem)} />;
}
