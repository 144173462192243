export type LoginView =
  | 'private'
  | 'researcher'
  | 'confirmEmail'
  | 'joinCollection'
  | 'newsletter'
  | 'chooseEmail'
  | 'verifyEmail'
  | 'email';

export const loginViews: Record<LoginView, LoginView> = {
  private: 'private',
  researcher: 'researcher',
  confirmEmail: 'confirmEmail',
  joinCollection: 'joinCollection',
  newsletter: 'newsletter',
  chooseEmail: 'chooseEmail',
  email: 'email',
  verifyEmail: 'verifyEmail'
};
