import { collections } from '~/App/helpers/http';
import { LoadDataProps } from '~/types/routes';

type Params = 'slug';

export const loadData = async ({ params }: LoadDataProps<Params>) => {
  const response = await collections.show({ slug: params.slug || '' });

  if (response.data.collectionType === 'memorial_page') {
    throw {
      response: {
        status: 404
      }
    };
  }

  return {
    data: response.data
  };
};
