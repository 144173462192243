import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { Divider } from '~/App/shared/components/Blocks';
import * as MultiPartForm from '~/App/shared/components/MultiPartForm';

import * as TwoColumn from './../components/Forms/components/TwoColumn/';
import { RightColumnText } from './../components/Forms/components/RightColumnText';

import MonthlyDonationFormPartOne from './MonthlyDonation.Form.Part.One';
import MonthlyDonationFormPartTwo from './MonthlyDonation.Form.Part.Two';

import AmountSummary from './MonthlyDonation.AmountSummary';
import { useInViewport } from '~/App/shared/hooks/use-in-viewport';
import styled from 'styled-components';
import { pushGTMFormEvent } from '~/App/helpers/gtm-helper';

const SectionWrapper = styled.div``;

export function MonthlyDonationParts({
  values,
  validation,
  formContent,
  submit,
  fromStart = false,
  ...props
}) {
  const [isVisible, ref] = useInViewport({ threshold: 0.5 });
  const [seenBlock, setSeenBlock] = useState(false);

  useEffect(() => {
    if (!isVisible || seenBlock) return;
    pushGTMFormEvent('formImpression', {
      formName: values.gtm?.formName,
      amount: values.productOptions.product.price,
      period: values.productOptions.product.period
    });
    setSeenBlock(true);
  }, [
    isVisible,
    seenBlock,
    values.gtm.formName,
    values.productOptions.product.price,
    values.productOptions.product.period
  ]);

  const formProps = useMemo(
    () => ({
      formContent,
      validation,
      values,
      submit,
      submitButtonText: 'Betala och slutför',
      nextStepButtonText: 'Till betalsätt',
      partName: {
        1: 'Välj belopp',
        2: 'Välj betalsätt'
      },
      ...props
    }),
    [formContent, values, validation, submit, props]
  );

  const renderRightColumnText = useCallback(
    (infoText, key) => (
      <RightColumnText
        icon={infoText.icon}
        iconColor={infoText.iconColor}
        key={key}
        children={infoText.text}
      />
    ),
    []
  );

  return (
    <TwoColumn.Wrapper>
      <TwoColumn.LeftColumn>
        <form
          onSubmit={(e) =>
            submit.handleSubmit(
              e,
              formProps.partName[2],
              formProps.submitButtonText
            )
          }
          noValidate
        >
          <If show={!fromStart}>
            <SectionWrapper ref={ref}>
              <MultiPartForm.Section
                part={1}
                title={formProps.partName[1]}
                values={values}
                validation={validation}
                nextStepButton={{ text: formProps.nextStepButtonText }}
              >
                <MonthlyDonationFormPartOne
                  formContent={formProps.formContent}
                  validation={formProps.validation}
                  values={formProps.values}
                  submit={formProps.submit}
                  partName={formProps.partName[1]}
                />
              </MultiPartForm.Section>
            </SectionWrapper>
          </If>

          <MultiPartForm.Section
            part={2}
            title={formProps.partName[2]}
            values={values}
            validation={validation}
          >
            <MonthlyDonationFormPartTwo
              formContent={formProps.formContent}
              values={formProps.values}
              validation={formProps.validation}
              submit={formProps.submit}
              submitButtonText={formProps.submitButtonText}
              partName={formProps.partName[2]}
            />
          </MultiPartForm.Section>
        </form>
      </TwoColumn.LeftColumn>
      <TwoColumn.RightColumn>
        <TwoColumn.ColumnCard>
          <AmountSummary price={values.productOptions.product.price} />

          <If show={formContent.infoTexts}>
            <Divider
              width="narrow"
              paddingTop="tiny"
              paddingLeft="none"
              paddingRight="none"
              height="2px"
              alternativeColor={true}
            />
            {formContent.infoTexts.map(renderRightColumnText)}
          </If>
        </TwoColumn.ColumnCard>
      </TwoColumn.RightColumn>
    </TwoColumn.Wrapper>
  );
}

const If = ({ show, children }) => (show ? children : null);
