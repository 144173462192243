export type ButtonStyle =
  | 'primary'
  | 'success'
  | 'inverted'
  | 'outlinePrimary'
  | 'outlineInverted'
  | 'exercisePrimary'
  | 'exerciseInverted'
  | 'cta'
  | 'error'
  | 'disabled';

export type ButtonSize = 'small' | 'large';

export const buttonStyles: Record<ButtonStyle, ButtonStyle> = {
  primary: 'primary',
  success: 'success',
  inverted: 'inverted',
  outlinePrimary: 'outlinePrimary',
  outlineInverted: 'outlineInverted',
  exercisePrimary: 'exercisePrimary',
  exerciseInverted: 'exerciseInverted',
  cta: 'cta',
  error: 'error',
  disabled: 'disabled'
};
