import { swishTypes } from '~/App/config/swishTypes';

type Values = {
  swish: {
    swishType: string;
  };
};

export const buildSchema = () => ({
  'productOptions.product.price': {
    presence: {
      allowEmpty: false,
      message: '^Ange ett belopp'
    },
    numericality: {
      greaterThan: 19,
      lessThanOrEqualTo: 999999,
      message: '^Minsta belopp är 20 kr'
    }
  },
  swishPhoneNumber: {
    validatePhoneNumber: (_: unknown, { swish }: Values) =>
      swish.swishType === swishTypes.ecommerce
  }
});
