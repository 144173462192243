export const containerWidthsPx = {
  normal: '1200px',
  limited: '1000px',
  narrow: '740px',
  tight: '592px',
  slim: '480px'
};

export const containerWidths = {
  normal: 1200,
  limited: 1000,
  narrow: 740,
  tight: 592,
  slim: 480
};
