import React, { useMemo } from 'react';

import {
  IFavourablePaymentMethod,
  IPaymentMethod
} from '~/types/IPaymentMethod';
import { CustomerContactType } from '~/App/config/customerContactTypes';
import { PaymentRedirect } from '../components/PaymentRedirect';
import { Form } from './components/Form';
import { useState } from '../components/States';
import { useQuery } from '~/App/shared/hooks/use-query';
import { useValidation } from '~/App/shared/components/Validation';
import { useSubmit } from '../components/Submits/Submit';
import { useMultiPartFormState } from '../../MultiPartForm';

import { buildSchema } from './schema';
import { buildState } from './state';

type Props = {
  data: {
    productId?: number;
    defaultPrice?: number;
    minimumPrice?: number;
    prices: number[];
    gtmId?: string;
    campaignActivityId?: number;
    defaultPaymentMethod?: IPaymentMethod;
    defaultCustomerContactType?: CustomerContactType;
    taxReductionInfoLink?: string;
    originPath: string;
    redirectPath: string;
    availablePaymentMethods: IFavourablePaymentMethod[];
  };
};

export function Donation({ data }: Props) {
  const query = useQuery();
  const state = useMemo(() => buildState({ data, query }), [data, query]);
  const schema = useMemo(() => buildSchema({ data }), [data]);

  const values = useState({
    values: state,
    formContent: data
  });

  const validation = useValidation({
    hasStripe: true,
    values,
    schema
  });

  const partValues = useMultiPartFormState({
    values,
    validation,
    parts: 2
  });

  const submit = useSubmit({
    values: partValues,
    validation
  });

  return (
    <form onSubmit={submit.handleSubmit}>
      <PaymentRedirect submit={submit} redirectPath={data.redirectPath} />
      <Form
        values={partValues}
        submit={submit}
        validation={validation}
        data={data}
      />
    </form>
  );
}
