import React, { Fragment } from 'react';
import { ICmsProduct } from '~/types/ICmsProduct';

import { setHero, buildUrl, setProductImage } from './helpers/productHelpers';

import Form from './components/Form';
import BlockArea from '~/App/shared/components/BlockArea';
import { BlockHero } from '~/App/shared/components/Blocks';

import useEnsureUrl from '~/App/shared/hooks/use-ensure-url';
import { usePageContent } from '~/App/shared/hooks/use-page-content';

type PageContent = {
  product: ICmsProduct;
};

export function CmsProduct() {
  const { product } = usePageContent<PageContent>();

  useEnsureUrl(buildUrl(product));

  return (
    <Fragment>
      <BlockHero {...setHero(product.hero, product.target)} />
      <Form product={setProductImage(product)} />
      <BlockArea blocks={product.blocks}></BlockArea>
    </Fragment>
  );
}
