import React, { ComponentType, useMemo } from 'react';
import { useLocation, Navigate } from 'react-router';
import styled from 'styled-components';

// Shared components
import Section from '~/App/shared/components/Section';
import SectionInfo from '~/App/shared/components/SectionInfo';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import { Spinner } from '~/App/shared/components/Elements';

const StyledSpinner = styled(Spinner)`
  margin-top: 2rem;
`;

export function withAuthorization<T>(Component: ComponentType<T>) {
  return function Authorization(props: T) {
    const location = useLocation();
    const { isAuthenticated, isIdle } = useAuthenticationContext();

    const spinner = useMemo(
      () => (
        <>
          <Section modifier="section--narrow section--smoke">
            <SectionInfo heading="Loggar in" headingStyle="small" />
            <StyledSpinner />
          </Section>
        </>
      ),
      []
    );

    return useMemo(() => {
      if (isAuthenticated) {
        return <Component {...props} />;
      }

      if (!isIdle) {
        return spinner;
      }

      return (
        <>
          <Navigate to={`/?logga-in=${location.pathname}`} />
          {spinner}
        </>
      );
    }, [isAuthenticated, isIdle, location.pathname, props, spinner]);
  };
}
