import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { CardElement } from '@stripe/react-stripe-js';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import {
  GeneralStyles,
  ValidStyles,
  InvalidStyles
} from '~/App/shared/components/Fields/Components/mixins';

// Shared components
import { Message } from '~/App/shared/components/Fields/Components/';
import { IValidation } from '~/App/shared/components/Validation';

const CreditCards = styled.div`
  line-height: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  picture {
    margin: 0 0.3125rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

type FlexWrapperProps = {
  lastItem?: boolean;
};

const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ lastItem }) => (lastItem ? '0' : '1.5rem')};
`;

const InputWrapper = styled.div`
  margin-top: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
`;

type StyledCardElementProps = {
  isInvalid: boolean;
  isValid: boolean;
};

const StyledCardElement = styled(CardElement)<StyledCardElementProps>`
  ${GeneralStyles};
  padding: 14px 10px !important;

  ${({ isInvalid }) => (isInvalid ? InvalidStyles : null)};
  ${({ isValid }) => (isValid ? ValidStyles : null)};
`;

type IconProps = {
  url: string;
  alt: string;
};

function Icon({ url, alt }: IconProps) {
  return (
    <picture>
      <source
        srcSet={`${mediaHelper.getUrl(url, { height: 44 })} 2x,
        ${mediaHelper.getUrl(url, { height: 22 })} 1x`}
      />
      <img src={mediaHelper.getUrl(url, { height: 22 })} alt={alt} />
    </picture>
  );
}

type Props = {
  validation: IValidation;
  lastItem?: boolean;
};

export function CreditCardFields({ validation, lastItem }: Props) {
  const isInvalid = useMemo(() => validation.isInvalid('stripeCard'), [
    validation
  ]);
  const isValid = useMemo(() => validation.isValid('stripeCard'), [validation]);

  const handleBlur = useCallback(() => {
    validation.handleBlur('stripeCard');
  }, [validation]);

  return (
    <div>
      <CreditCards>
        <Icon
          url="https://res.cloudinary.com/cancerfonden/image/upload/v1542716850/assets/Visa-logga.png"
          alt="VISA-logga"
        />
        <Icon
          url="https://res.cloudinary.com/cancerfonden/image/upload/v1542707457/assets/Mastercard-logga.png"
          alt="Mastercard-logga"
        />
        <Icon
          url="https://res.cloudinary.com/cancerfonden/image/upload/v1542716850/assets/Amex-logga.png"
          alt="American Express-logga"
        />
      </CreditCards>
      <FlexWrapper lastItem={lastItem}>
        <InputWrapper>
          <StyledCardElement
            isValid={isValid}
            isInvalid={isInvalid}
            onChange={validation.handleStripeValidation}
            onBlur={handleBlur}
          />
          {validation.errors['stripeCard'] && (
            <Message isInvalid={isInvalid}>
              {validation.errors['stripeCard']}
            </Message>
          )}
        </InputWrapper>
      </FlexWrapper>
    </div>
  );
}
