import { startsWith } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router';

import { IHelloBar } from '~/types/IHelloBar';
import { HelloBarContext } from './HelloBarContext';

type Props = {
  value: IHelloBar | null;
  children: ReactNode;
};

export function HelloBarProvider({ children, value }: Props) {
  const location = useLocation();

  const isVisible = useMemo(() => {
    if (!value) return false;
    if (!value.isVisible) return false;
    if (!value.url) return false;
    if (!value.text) return false;

    const hiddenOn = [
      '/minska-risken/kolla-risken',
      '/rosabandet',
      '/min-sida',
      '/insamlingar',
      '/stod-oss',
      '/bekrafta-swishbetalning',
      '/jul',
      '/gavoshop'
    ];

    if (hiddenOn.some((path) => startsWith(location.pathname, path))) {
      return false;
    }

    return true;
  }, [value, location.pathname]);

  return (
    <HelloBarContext.Provider
      value={isVisible ? value : null}
      children={children}
    />
  );
}
