// Constants
import { errorLevels } from '~/App/config/errorLevels';
import { exceptionMessages } from '~/App/config/exceptionMessages';

// Helpers
import { logMessage } from '~/App/helpers/logger';

export const getLoginErrorMessage = (exceptionCode: string): string => {
  if (exceptionMessages.hasOwnProperty(exceptionCode)) {
    return exceptionMessages[exceptionCode];
  } else {
    logMessage({
      level: errorLevels.info,
      message: 'Unhandled login exception code',
      data: { exceptionCode: exceptionCode }
    });

    return exceptionMessages.generalLoginError;
  }
};
