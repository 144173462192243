import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Spinner } from '../components/Elements';

import 'intersection-observer';

const InfiniteScroll = styled.div`
  margin: 4rem 0 20rem;
`;

type Status = 'idle' | 'loading' | 'completed';
type Props = {
  loadPage: (page: number) => Promise<boolean>;
  hasFollowingPage: boolean;
};

export function useInfiniteScroll({ loadPage, hasFollowingPage }: Props) {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<Status>(
    hasFollowingPage ? 'idle' : 'completed'
  );

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (status === 'completed') {
      return;
    }

    const handleIntersection = async (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (!entry.isIntersecting) {
          return;
        }

        if (status !== 'idle') {
          return;
        }

        setStatus('loading');
        const hasMore = await loadPage(page + 1);

        setPage(page + 1);
        setStatus(hasMore ? 'idle' : 'completed');
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '800px',
      threshold: 0
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [loadPage, page, status]);

  const trigger = useMemo(() => {
    if (status === 'completed') {
      return null;
    }

    return (
      <InfiniteScroll
        ref={ref}
        children={status === 'loading' && <Spinner size="2rem" />}
      />
    );
  }, [status]);

  return trigger;
}
