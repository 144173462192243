import React, { useMemo } from 'react';

import { usePageContent } from '~/App/shared/hooks/use-page-content';

import { loadData } from './Page.LoadData';
import { Page } from './Page';

const View = (props: any) => {
  const pageContent = usePageContent();
  const children = useMemo(() => pageContent
    ? <Page {...pageContent} {...props} />
    : null, [pageContent, props]);

  return children;
};

View.loadData = loadData;

export default View;