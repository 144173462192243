import styled, { css } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { containerWidthsPx } from '~/App/config/containerWidths';

import { Width, PaddingValue, Padding } from '~/types/Block';

const widths = {
  full: '',
  normal: containerWidthsPx.normal,
  limited: containerWidthsPx.limited,
  narrow: containerWidthsPx.narrow,
  tight: containerWidthsPx.tight,
  slim: containerWidthsPx.slim
};

const paddings = {
  top: {
    large: css`
      padding-top: 2.5rem;

      ${mq('≥small')`
        padding-top: 4rem;
      `};
    `,
    small: css`
      padding-top: 1rem;

      ${mq('≥small')`
        padding-top: 2rem;
      `};
    `,
    tiny: css`
      padding-top: 1rem;
    `,
    none: css`
      padding-top: 0rem;
    `
  },
  left: {
    none: css`
      padding-left: 0rem;
    `,
    tiny: null,
    small: css`
      padding-left: 1rem;
    `,
    large: css`
      padding-left: 1rem;

      ${mq('≥large')`
        padding-left: 2rem;
      `};
    `
  },
  right: {
    none: css`
      padding-right: 0rem;
    `,
    tiny: null,
    small: css`
      padding-right: 1rem;
    `,
    large: css`
      padding-left: 1rem;

      ${mq('≥large')`
        padding-left: 2rem;
      `};
    `
  },
  bottom: {
    none: css`
      padding-bottom: 0rem;
    `,
    large: css`
      padding-bottom: 2.5rem;

      ${mq('≥small')`
        padding-bottom: 4rem;
      `};
    `,
    small: css`
      padding-bottom: 1rem;

      ${mq('≥small')`
        padding-bottom: 2rem;
      `};
    `,
    tiny: css`
      padding-bottom: 1rem;
    `
  }
};

type Props = {
  width?: Width;
  paddingTop?: PaddingValue;
  paddingLeft?: PaddingValue;
  paddingRight?: PaddingValue;
  paddingBottom?: PaddingValue;
  padding?: Padding;
};

export const Container = styled.div<Props>`
  position: relative;
  width: 100%;
  margin: 0 auto;

  ${({ width }) =>
    width &&
    css`
      max-width: ${widths[width]};
    `};

  ${({ paddingTop, padding }) => {
    if (paddingTop) {
      return paddings.top[paddingTop];
    }

    if (padding?.top) {
      return paddings.top[padding.top];
    }

    return null;
  }};

  ${({ paddingLeft, padding }) => {
    if (paddingLeft) {
      return paddings.left[paddingLeft];
    }

    if (padding?.left) {
      return paddings.left[padding.left];
    }

    return null;
  }};
  ${({ paddingRight, padding }) => {
    if (paddingRight) {
      return paddings.right[paddingRight];
    }

    if (padding?.right) {
      return paddings.right[padding.right];
    }

    return null;
  }};
  ${({ paddingBottom, padding }) => {
    if (paddingBottom) {
      return paddings.bottom[paddingBottom];
    }

    if (padding?.bottom) {
      return paddings.bottom[padding.bottom];
    }

    return null;
  }};
`;

export default Container;
