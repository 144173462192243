import React, { useMemo } from 'react';
import styled from 'styled-components';

// helpers
import { heading2, preamble, externalLink } from '~/App/helpers/mixins';

import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { Header } from './shared/Header';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const ExternalLink = styled.a`
  ${externalLink};
`;

export default function CompanyPackageThanks({ purchase = {}, data = {} }) {
  const email =
    purchase?.purchase?.productOptions?.customerContact?.email ||
    purchase?.user?.email;

  const preambleWithEmail = data.description
    ? data.description.replace('%%email%%', email)
    : 'Er gåva bidrar till att föra cancerforskningen framåt – ni är med och räddar liv.';

  const buttonLink = useMemo(() => {
    switch (purchase.product.id) {
      case (9, 37):
        return data.buttonLinkBasic;
      case (10, 38):
        return data.buttonLinkSmall;
      case (11, 39):
        return data.buttonLinkMedium;
      case (12, 40):
        return data.buttonLinkLarge;
      default:
        return null;
    }
  }, [purchase.product.id, data]);

  return (
    <div>
      <Background backgroundColor="primary">
        <Header image="//res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png">
          <Heading1 children={data.heading || 'Tack!'} />
          <Preamble children={preambleWithEmail} />
          <FetchPurchaseDocuments purchase={purchase}>
            {({ receiptStatus, receipt }) => (
              <>
                <AsyncReceiptDocumentLink
                  url={receipt}
                  status={receiptStatus}
                  paymentMethodId={purchase?.paymentMethod?.id}
                />
                {buttonLink && data.buttonLabel && (
                  <ExternalLink
                    rel="noopener noreferrer"
                    href={buttonLink}
                    target="_blank"
                    children={data.buttonLabel}
                  />
                )}
              </>
            )}
          </FetchPurchaseDocuments>
        </Header>
      </Background>
    </div>
  );
}
