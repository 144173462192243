import React, { ReactNode } from 'react';
import { OnceDonationNudge } from '../../components/TaxReductionNudging/OnceDonationNudge';
import { MonthlyDonationNudge } from '../../components/TaxReductionNudging/MonthlyDonationNudge';
import { ISubmit } from '../../components/Submits';
import { IValidation } from '../../../Validation';
import { featureIsEnabled } from '~/App/helpers/feature-is-enabled';
import { SubmitValues } from '../../components/Submits/Submit/types/SubmitValues';

type Props = {
  submit: ISubmit;
  validation: IValidation;
  values: SubmitValues;
  children: (submit: ISubmit) => ReactNode;
};
export function CombinedDonationTaxReductionNudging(props: Props) {
  switch (props.values.product.id) {
    case 3:
      return (
        <OnceDonationNudge
          {...props}
          name="Engångsgåva"
          setAmount={props.values.handlers.setProductPrice}
          shouldNudge={featureIsEnabled('NUDGE_COMBINED_DONATION_ENABLED')}
        />
      );
    case 31:
      return (
        <MonthlyDonationNudge
          {...props}
          name="Månadsgåva"
          shouldNudge={featureIsEnabled('NUDGE_COMBINED_DONATION_ENABLED')}
        />
      );
    default:
      return <> {props.children(props.submit)}</>;
  }
}
