import React, { useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import { heading4 } from '~/App/helpers/mixins';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Amount = styled.h4`
  ${heading4};
  margin-bottom: 0.75rem;
`;

type Props = {
  text: string;
  amount: number | null | undefined;
};

export function AmountWrapper({ text, amount }: Props) {
  return useMemo(
    () => (
      <FlexWrapper>
        <span>{text}</span>
        <span>
          <Amount>{amount} kr</Amount>
        </span>
      </FlexWrapper>
    ),
    [amount, text]
  );
}
