import React, { useRef } from 'react';

// Fields
import Text from '~/App/shared/components/Fields/Text';
import Button from '~/App/shared/components/Fields/Button';
import IntegrityPolicyCheckbox from '~/App/shared/components/Fields/IntegrityPolicyCheckbox';

export function Form({ values, submit, validation, formContent }) {
  const customerContactSsn = useRef();

  return (
    <form onSubmit={submit.handleSubmit} className="tmcDonationForm" noValidate>
      <div className="tmcDonationForm__fields tmcDonationForm__fields--slimTop">
        <ul className="tmcForm">
          <li className="tmcForm__item">
            <h3 className="heading4">Dina kontaktuppgifter</h3>
          </li>
          <li className="tmcForm__item">
            <ul className="tmcForm">
              <li className="tmcForm__item tmcForm__item--50">
                <Text
                  name="givenName"
                  value={values.productOptions.customerContact.firstName}
                  onBlur={validation.handleBlur.bind(
                    undefined,
                    'productOptions.customerContact.firstName'
                  )}
                  errors={
                    validation.errors[
                      'productOptions.customerContact.firstName'
                    ]
                  }
                  isValid={validation.isValid(
                    'productOptions.customerContact.firstName'
                  )}
                  onChange={values.handlers.handleCustomerContactFirstName}
                  isInvalid={validation.isInvalid(
                    'productOptions.customerContact.firstName'
                  )}
                  maxLength="50"
                  placeholder="Förnamn"
                  autoComplete="given-name"
                />
              </li>
              <li className="tmcForm__item tmcForm__item--50">
                <Text
                  name="familyName"
                  value={values.productOptions.customerContact.lastName}
                  onBlur={validation.handleBlur.bind(
                    undefined,
                    'productOptions.customerContact.lastName'
                  )}
                  errors={
                    validation.errors['productOptions.customerContact.lastName']
                  }
                  isValid={validation.isValid(
                    'productOptions.customerContact.lastName'
                  )}
                  onChange={values.handlers.handleCustomerContactLastName}
                  isInvalid={validation.isInvalid(
                    'productOptions.customerContact.lastName'
                  )}
                  maxLength="50"
                  placeholder="Efternamn"
                  autoComplete="family-name"
                />
              </li>
              <li className="tmcForm__item">
                <Text
                  name="streetAddress"
                  value={values.productOptions.customerContact.addressStreet}
                  onBlur={validation.handleBlur.bind(
                    undefined,
                    'productOptions.customerContact.addressStreet'
                  )}
                  errors={
                    validation.errors[
                      'productOptions.customerContact.addressStreet'
                    ]
                  }
                  isValid={validation.isValid(
                    'productOptions.customerContact.addressStreet'
                  )}
                  onChange={values.handlers.handleCustomerContactAddressStreet}
                  isInvalid={validation.isInvalid(
                    'productOptions.customerContact.addressStreet'
                  )}
                  maxLength="100"
                  placeholder="Adress"
                  autoComplete="street-address"
                />
              </li>
              <li className="tmcForm__item tmcForm__item--33">
                <Text
                  name="postalCode"
                  value={values.productOptions.customerContact.addressZip}
                  onBlur={validation.handleBlur.bind(
                    undefined,
                    'productOptions.customerContact.addressZip'
                  )}
                  errors={
                    validation.errors[
                      'productOptions.customerContact.addressZip'
                    ]
                  }
                  isValid={validation.isValid(
                    'productOptions.customerContact.addressZip'
                  )}
                  onChange={values.handlers.handleCustomerContactAddressZip}
                  isInvalid={validation.isInvalid(
                    'productOptions.customerContact.addressZip'
                  )}
                  maxLength="10"
                  placeholder="Postnr"
                  autoComplete="postal-code"
                />
              </li>
              <li className="tmcForm__item tmcForm__item--66">
                <Text
                  name="addressLevel2"
                  value={values.productOptions.customerContact.addressCity}
                  onBlur={validation.handleBlur.bind(
                    undefined,
                    'productOptions.customerContact.addressCity'
                  )}
                  errors={
                    validation.errors[
                      'productOptions.customerContact.addressCity'
                    ]
                  }
                  isValid={validation.isValid(
                    'productOptions.customerContact.addressCity'
                  )}
                  onChange={values.handlers.handleCustomerContactAddressCity}
                  isInvalid={validation.isInvalid(
                    'productOptions.customerContact.addressCity'
                  )}
                  maxLength="50"
                  placeholder="Postort"
                  autoComplete="address-level2"
                />
              </li>
              <li className="tmcForm__item">
                <Text
                  name="email"
                  type="email"
                  value={values.productOptions.customerContact.email}
                  onBlur={() => {
                    validation.handleBlur(
                      'productOptions.customerContact.email'
                    );
                  }}
                  errors={
                    validation.errors['productOptions.customerContact.email']
                  }
                  isValid={validation.isValid(
                    'productOptions.customerContact.email'
                  )}
                  onChange={values.handlers.handleCustomerContactEmail}
                  isInvalid={validation.isInvalid(
                    'productOptions.customerContact.email'
                  )}
                  placeholder="E-post"
                  autoComplete="email"
                  instructions="Bekräftelse för din betalning skickas via e-post."
                />
              </li>
              <li className="tmcForm__item">
                <Text
                  name="ssn"
                  type="tel"
                  value={values.productOptions.customerContact.ssn}
                  onBlur={validation.handleBlur.bind(
                    undefined,
                    'productOptions.customerContact.ssn'
                  )}
                  errors={
                    validation.errors['productOptions.customerContact.ssn']
                  }
                  isValid={validation.isValid(
                    'productOptions.customerContact.ssn'
                  )}
                  onChange={(event) => {
                    values.handlers.handleCustomerContactSsn(
                      event,
                      customerContactSsn.current
                    );
                  }}
                  inputRef={(c) => (customerContactSsn.current = c)}
                  isInvalid={validation.isInvalid(
                    'productOptions.customerContact.ssn'
                  )}
                  maxLength="12"
                  placeholder="Personnummer"
                  instructions="Formatet för personnummer är ÅÅÅÅMMDDCCCC."
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="tmcDonationForm__submit">
        <ul className="tmcForm">
          <li className="tmcForm__item">
            <IntegrityPolicyCheckbox values={values} validation={validation} />
          </li>
          <li className="tmcForm__item">
            <Button
              block="tmcButtonGreen"
              modifiers={['wide']}
              isLoading={
                submit.isSending || submit.isPolling || submit.isCompleted
              }
              isDisabled={
                submit.isSending || submit.isPolling || submit.isCompleted
              }
            >
              {formContent.buttonLabel || 'Stöd oss'}
            </Button>
          </li>
          {submit.errors ? (
            <li className="tmcForm__item">
              <p className="tmcErrorMessage">
                Ditt köp gick tyvärr inte igenom, prova igen eller kontakta vår
                kundservice.
              </p>
            </li>
          ) : null}
        </ul>
      </div>
    </form>
  );
}
