export const diagnoseLinks = {
  Basalcellscancer: '/om-cancer/cancersjukdomar/basalcellscancer-basaliom',
  Blodcancer: '/om-cancer/cancersjukdomar/blodcancer',
  Bröstcancer: '/om-cancer/cancersjukdomar/brostcancer',
  Bukspottkörtelcancer: '/om-cancer/cancersjukdomar/bukspottkortelcancer',
  Gallblåsecancer: '/om-cancer/cancersjukdomar/gallvagscancer',
  GIST: '/om-cancer/cancersjukdomar/gist',
  'Gynekologisk cancer': '/om-cancer/cancersjukdomar/gynekologisk-cancer',
  Hjärntumör: '/om-cancer/cancersjukdomar/hjarntumor',
  Hudcancer: '/om-cancer/cancersjukdomar/hudcancer',
  'Huvud- och halscancer': '/om-cancer/cancersjukdomar/huvud-hals-cancer',
  Leukemi: '/om-cancer/cancersjukdomar/leukemi',
  Levercancer: '/om-cancer/cancersjukdomar/levercancer',
  Livmoderhalscancer: '/om-cancer/cancersjukdomar/livmoderhalscancer',
  Livmoderkroppscancer: '/om-cancer/cancersjukdomar/livmodercancer',
  Lungcancer: '/om-cancer/cancersjukdomar/lungcancer',
  Magsäckscancer: '/om-cancer/cancersjukdomar/magsackscancer',
  'Malignt melanom': '/om-cancer/cancersjukdomar/malignt-melanom',
  Matstrupscancer: '/om-cancer/cancersjukdomar/matstrupscancer',
  Myelom: '/om-cancer/cancersjukdomar/myelom',
  'Neuroendokrina tumörer': '/om-cancer/cancersjukdomar/neuroendokrina-tumorer',
  Njurcancer: '/om-cancer/cancersjukdomar/njurcancer',
  'Okänd primärtumör': '/om-cancer/cancersjukdomar/okand-primartumor',
  Peniscancer: '/om-cancer/cancersjukdomar/peniscancer',
  Prostatacancer: '/om-cancer/cancersjukdomar/prostatacancer',
  Sarkom: '/om-cancer/cancersjukdomar/sarkom',
  Skivepitelcancer: '/om-cancer/cancersjukdomar/skivepitelcancer',
  Testikelcancer: '/om-cancer/cancersjukdomar/testikelcancer',
  Tjocktarmscancer: '/om-cancer/cancersjukdomar/tjock-och-andtarmscancer',
  Tunntarmscancer: '/om-cancer/cancersjukdomar/tunntarmscancer',
  Urinblåsecancer: '/om-cancer/cancersjukdomar/urinblasecancer',
  Vulvacancer: '/om-cancer/cancersjukdomar/vulvacancer',
  Äggstockscancer: '/om-cancer/cancersjukdomar/aggstockscancer',
  Ändtarmscancer: '/om-cancer/cancersjukdomar/tjock-och-andtarmscancer',
  Ögontumörer: '/om-cancer/cancersjukdomar/ogoncancer'
};
