import React, { useCallback, useMemo } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import config from '~/App/config/routes';
import { RouteConfig } from '~/types/routes';

type RenderRoute = (config: RouteConfig) => RouteObject;

export function AppRoutes() {
  const renderRoute: RenderRoute = useCallback(
    (route: RouteConfig) => ({
      path: route.path,
      index: route.index,
      element: route.component ? <route.component /> : null,
      children: route.children ? route.children.map(renderRoute) : undefined
    }),
    []
  );

  const routes = useMemo(() => config.map(renderRoute), [renderRoute]);

  return useRoutes(routes);
}
