import { AxiosRequestConfig } from 'axios';

import { get } from '~/App/helpers/cookie';
import { IUser } from '~/types/IUser';
import { mwAxios } from '.';

type Arguments = AxiosRequestConfig;
type ArgumentsWithActivationToken = AxiosRequestConfig & {
  activationToken: string;
};

type VerifyEmailArguments = {
  email: string;
  token: string;
};

type VerifyEmailResponse = {
  confirmationNeeded: boolean;
};

export const users = {
  edit: (args: Arguments) =>
    mwAxios({
      url: `/api/v2/users/me`,
      method: 'put',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        ...args.data
      },
      ...args
    }),
  activate: (args: ArgumentsWithActivationToken) =>
    mwAxios({
      url: `/api/v2/users/activate/${args.activationToken}`,
      method: 'get'
    }),
  activateSubscription: (args: Arguments) =>
    mwAxios({
      url: `/api/v2/users/subscriptions/activate`,
      method: 'post',
      data: {
        ...args.data
      },
      headers: {
        'X-Session-Key': get('secretKey')
      }
    }),
  show: async () => {
    const { data } = await mwAxios.get<IUser>(`/api/v2/users/me`, {
      headers: {
        'X-Session-Key': get('secretKey')
      }
    });

    return data;
  },
  addMetadata: (args: Arguments) =>
    mwAxios({
      url: `/api/v2/users/me/add_metadata`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      data: {
        metadata: { ...args.data }
      }
    }),
  addSubscription: (args: Arguments) =>
    mwAxios({
      url: `/api/v2/users/subscriptions`,
      method: 'post',
      data: {
        ...args.data
      },
      headers: {
        'X-Session-Key': get('secretKey')
      }
    }),
  resendActivion: () =>
    mwAxios({
      url: `/api/v2/users/me/resend_activation`,
      method: 'post',
      headers: {
        'X-Session-Key': get('secretKey')
      },
      responseType: 'json'
    }),
  verifyEmail: (args: VerifyEmailArguments) =>
    mwAxios.post<VerifyEmailResponse>('/api/v2/users/verify_email', args, {
      headers: {
        'X-Session-Key': get('secretKey')
      },
      responseType: 'json'
    })
};
