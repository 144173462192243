import {
  ICmsProduct,
  ICmsProductHero,
  CmsProductTarget
} from '~/types/ICmsProduct';

export const setHero = (hero: ICmsProductHero, target: string) => {
  if (hero?.large?.source?.secure_url) {
    return hero;
  }

  const defaultHero = hero;
  defaultHero.large.source = {
    secure_url: getDefaultImage(target),
    url: '',
    format: 'png'
  };

  return defaultHero;
};
export const getDefaultImage = (target: string): string => {
  if (target === 'private') {
    return 'https://res.cloudinary.com/cancerfonden/image/upload/v1606902040/hero-images/content-components-hero-small-desktop-accent.png';
  }
  return 'https://res.cloudinary.com/cancerfonden/image/upload/v1606902605/hero-images/content-components-hero-small-desktop-accent-company.png';
};

export const setProductImage = (product: ICmsProduct): ICmsProduct => {
  if (!product.productImage || !product.productImage.secure_url) {
    product.productImage = {
      secure_url:
        'https://res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png',
      url:
        'http://res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png',
      format: 'png'
    };
  }
  return product;
};

export const buildUrl = (product?: ICmsProduct) =>
  product
    ? `/gavoshop/${
        product.target === CmsProductTarget.company ? 'foretag' : 'privat'
      }/${product.slug}`
    : null;
