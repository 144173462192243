import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash';

import mediaHelper from '~/App/helpers/media-helper';

import { card, heading3 } from '~/App/helpers/mixins';
import { mq } from '~/lib/mq';

import { ICommerceProduct } from '~/types/ICommerceProduct';
import { StockInfoBox } from '~/App/shared/components/StockInfoBox';
import { stockStatus } from '~/App/helpers/stock-status';

const Wrapper = styled.div`
  flex-basis: 100%;
  padding: 1rem 1rem 0;

  ${mq('≥tiny')`
  flex-basis: 50%;
  max-width: 50%;
  padding: 0.75rem 1rem;
`};

  ${mq('≥small')`
  flex-basis: 33%;
  max-width: 33%;
`};
`;

const Card = styled.div`
  ${card};
  padding: 0 !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;

  img {
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const StockInfoWapper = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
`;

const PictureWrapper = styled.div`
  position: relative;
  padding-top: 11.875rem;
  overflow: hidden;
  max-width: 100%;
`;

const Picture = styled.picture`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: block;
  background: none;
  border-radius: 0.125rem;
  position: absolute;
  top: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const Heading = styled.div`
  ${heading3};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 0.25rem;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
`;

const Small = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

const Body = styled.div`
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;

const Price = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-top: auto;
  margin-bottom: 1.5rem;
  cursor: pointer;

  ${mq('<small')`
    margin-bottom: 1rem;
`};
`;

type Props = {
  product: ICommerceProduct;
  index: number;
  className?: string;
};

export function CommerceProductCard({ product, index, ...props }: Props) {
  const navigate = useNavigate();

  const variant = useMemo(() => {
    const sorted = orderBy(product.variants || [], [(x) => !x.default]);
    const variant = sorted.find(() => true);

    return variant;
  }, [product.variants]);

  const price = useMemo(() => {
    if (variant?.price.type === 'Fixed') {
      return `${variant?.price.value} kr/st`;
    }

    if (variant?.price.custom) {
      return `Från ${variant?.price.minimum} kr`;
    }

    return `Från ${variant?.price.options?.[0]} kr`;
  }, [variant?.price]);

  const handleClick = useCallback(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'eec.impressionClick',
      ecommerce: {
        click: {
          actionField: {
            list: 'Gåvoshop' //list hamnar istället här när det är click.
          },
          products: [
            {
              id: variant?.id,
              name: product.name,
              category: `${product.target}/${product.type}`,
              list: 'Gåvoshop',
              variant: variant?.name,
              position: index + 1,
              impression10: stockStatus(variant?.stock?.status)
            }
          ]
        }
      }
    });

    navigate(`/gavoshop/privat/${product.slug}`);
  }, [
    navigate,
    index,
    product.name,
    product.slug,
    product.target,
    product.type,
    variant?.id,
    variant?.name,
    variant?.stock
  ]);

  const imageMarkup = useMemo(
    () => (
      <PictureWrapper>
        <Picture>
          <source
            srcSet={`
                ${mediaHelper.getUrl(variant?.previewImage, {
                  width: 800
                })} 2x,
                ${mediaHelper.getUrl(variant?.previewImage, {
                  width: 400
                })} 1x
            `}
          />
          <Image
            src={mediaHelper.getUrl(variant?.previewImage, {
              width: 400,
              height: 400,
              quality: 80,
              crop: 'fill',
              dpr: '2.0',
              fetch_format: 'png'
            })}
          />
          <StockInfoWapper>
            {variant?.stock?.status === 'FewInStock' &&
              !variant.discontinued && <StockInfoBox variant={variant} />}
          </StockInfoWapper>
        </Picture>
      </PictureWrapper>
    ),
    [variant]
  );
  if (!variant) {
    return null;
  }

  return (
    <Wrapper {...props}>
      <Card onClick={handleClick}>
        {imageMarkup}
        <Content>
          <Heading children={product.name} />
          {product?.type === 'DigitalCertificate' && (
            <Small children="Digitalt gåvobevis" />
          )}
          {variant?.shortDescription && (
            <Body children={variant?.shortDescription} />
          )}
          <Price children={price} />
        </Content>
      </Card>
    </Wrapper>
  );
}
