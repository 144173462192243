import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatUTCDate } from '~/App/helpers/date.service';

// Helpers
import mq from '~/App/helpers/mq';
import {
  heading3,
  preamble,
  description2,
  description3,
  arrowLink
} from '~/App/helpers/mixins';

// Shared components
import HtmlContent from '~/App/shared/components/HtmlContent';
import Link from '~/App/shared/components/Link';
import { RelatedPageItem } from '~/types/RelatedBlock';

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const NewsLabel = styled.span`
  ${description2};
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.charcoal};
`;

const PublishLegend = styled.span`
  margin-right: 0.5rem;
`;

const PublishDate = styled.span`
  ${description3};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  text-transform: none;
`;

const Heading = styled.h3`
  ${heading3};
  margin-top: 0.5rem;
  color: ${(props) => props.theme.themeColors.primary};
`;

const HeadingLink = styled(Link)`
  display: block;
  color: inherit;

  &:hover {
    color: ${(props) => props.theme.themeColors.primaryHover};
  }
`;

const Preamble = styled.div`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-top: 0.375rem;
  margin-bottom: 1rem;
`;

const ArrowLink = styled(Link)`
  ${arrowLink};
  line-height: 1.25rem;
  margin-top: 0;

  ${mq('≥small')`
    line-height: 1.5rem;
  `};
`;


type Props = {
  item: RelatedPageItem
}

export function NewsCard({ item }: Props) {
  const heading = item.preview?.heading;
  const legend = item.preview?.legend;
  const preamble = item.preview?.preamble;
  const linkText = item.preview?.linkText;
  const slug = item.slug;
  const contentType = item.contentType;

  const publishedAt = useMemo(() => item.publishedAt ? formatUTCDate(item.publishedAt, 'YYYY-MM-DD') : null, [item.publishedAt]);
  const updatedAt = useMemo(() => item.updatedAt ? formatUTCDate(item.updatedAt, 'YYYY-MM-DD') : null, [item.updatedAt]);

  return useMemo(() => (
    <Card>
      <NewsLabel>
        {legend && <PublishLegend>{legend}</PublishLegend>}
        {publishedAt && <PublishDate>{publishedAt}</PublishDate>}
        {!publishedAt && <PublishDate>{updatedAt}</PublishDate>}
      </NewsLabel>
      {heading && (
        <Heading>
          <HeadingLink to={{ slug, contentType }}>{heading}</HeadingLink>
        </Heading>
      )}
      {preamble && (
        <Preamble>
          <HtmlContent dangerouslySetInnerHTML={{ __html: preamble }} />
        </Preamble>
      )}
      <ArrowLink to={{ slug, contentType }}>{linkText}</ArrowLink>
    </Card>
  ), [contentType, heading, legend, linkText, preamble, publishedAt, slug, updatedAt]);
}
