import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState
} from 'react';

import { IMeCollection } from '~/types/ICollection';

export type CollectionEditState = {
  values: IMeCollection;
  handlers: {
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleShowProgressBadge: (value: boolean) => void;
    handleImageChange: ChangeEventHandler<HTMLInputElement>;
    setImageUrl: (url: string) => void;
  };
};

type Args = {
  data: IMeCollection;
  setError: (error: string | null) => void;
};

export function useCollectionEditState({ data, setError }: Args) {
  const [values, setValues] = useState<IMeCollection>(data);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValues((state) => ({
      ...state,
      [name]: value
    }));
  }, []);

  const handleShowProgressBadge = useCallback((value: boolean) => {
    setValues((state) => ({
      ...state,
      ['showProgressBadge']: value
    }));
  }, []);

  const setImageUrl = useCallback(
    (url: string) => setValues((state) => ({ ...state, imageUrl: url })),
    []
  );

  const handleImageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const image = event.target.files?.[0];

      if (!image) {
        return;
      }

      if (image.size > 20000000) {
        return setError(
          'Bilden du försöker ladda upp är för stor. Max filstorlek är 20MB.'
        );
      }

      setValues((state) => ({
        ...state,
        imageUrl: URL.createObjectURL(image)
      }));
    },
    [setError]
  );

  return useMemo<CollectionEditState>(
    () => ({
      values,
      handlers: {
        handleImageChange,
        handleChange,
        setImageUrl,
        handleShowProgressBadge
      }
    }),
    [
      handleChange,
      handleImageChange,
      setImageUrl,
      values,
      handleShowProgressBadge
    ]
  );
}
