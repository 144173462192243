import React from 'react';
import { SubmitButton } from '~/App/shared/components/Donation/components/Forms/components/SubmitButton';
import { ISubmit } from '~/App/shared/components/Donation/components/Submits';

import { MultiPartFormValues } from '~/App/shared/components/MultiPartForm';
import { Section } from '~/App/shared/components/MultiPartForm';
import { IValidation } from '~/App/shared/components/Validation';
import { CreateMemoryStateValues } from './hooks/useCreateMemoryState';
import { ContactInformation } from './steps/ContactInformation';
import { DescribeMemory } from './steps/DescribeMemory';
import { Payment } from './steps/Payment';
import { SelectAmount } from './steps/SelectAmount';

type Props = {
  submit: ISubmit;
  validation: IValidation;
  values: MultiPartFormValues & CreateMemoryStateValues;
};

export function Form(props: Props) {
  const { submit, values } = props;
  const hasPayment = !!values.productOptions.memory.period;
  const isLastStep =
    values.partOptions.currentPart === values.partOptions.parts;

  return (
    <form onSubmit={submit.handleSubmit}>
      <Section
        nextStepButton={{ style: 'inverted' }}
        title="Beskriv ditt minne"
        part={1}
        {...props}
      >
        <DescribeMemory {...props} />
      </Section>
      <Section
        nextStepButton={{ style: 'inverted' }}
        title="Dina kontaktuppgifter"
        part={2}
        {...props}
      >
        <ContactInformation />
      </Section>
      {!hasPayment && isLastStep && (
        <SubmitButton
          {...props}
          children={submit.isSending ? 'Skapar minnet' : 'Publicera ditt minne'}
        />
      )}

      {hasPayment ? (
        <>
          <Section
            nextStepButton={{ style: 'inverted' }}
            title="Välj belopp"
            part={3}
            {...props}
          >
            <SelectAmount {...props} />
          </Section>
          <Section
            nextStepButton={{ style: 'inverted' }}
            title="Välj betalsätt"
            part={4}
            {...props}
          >
            <Payment {...props} />
          </Section>
        </>
      ) : null}
    </form>
  );
}
