import React, { useCallback, useMemo } from 'react';

import { StepWrapper } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';
import * as MultiPartForm from '~/App/shared/components/MultiPartForm';
import { IntegrityText } from '~/App/shared/components/Donation/components/Forms/components/IntegrityText';
import { useAuthenticationContext } from '~/App/contexts/Authentication';
import styled from 'styled-components';
import {
  ctaButton,
  outlinePrimaryButton,
  primaryButton
} from '~/App/helpers/mixins';
import useLogin from '~/App/shared/hooks/use-login';
import { IValidation } from '../../Validation';
import useLoginModal from '~/App/shared/hooks/use-login-modal';
import { isMobileBrowser } from '~/App/helpers/is-mobile-browser';

const Button = styled.button`
  ${primaryButton};
  display: block;
  margin: 1rem auto 0;
`;

const CtaButton = styled.button`
  ${ctaButton};
  display: block;
  margin: 1rem auto 0;
`;

const OutlineButton = styled.button`
  ${outlinePrimaryButton};
  display: block;
  margin: 1rem auto 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin: 1rem auto 0;
`;

type Props = {
  values: any;
  validation: IValidation;
  part: any;
};

export default function FormPartLogin({ values, validation, part }: Props) {
  const login = useLogin();
  const loginModal = useLoginModal();

  const { user, isIdle, isAuthenticated } = useAuthenticationContext();

  // Login using BankId
  const performLogin = useCallback(
    async (sameDevice) => {
      loginModal.handlers.toggleQrMode(!sameDevice);
      login.handlers.handleLogin({
        qrInit: !sameDevice,
        sameDevice: sameDevice
      });
    },
    [login.handlers, loginModal.handlers]
  );

  const mobileLoginButton = useMemo(
    () => (
      <ButtonWrapper>
        <CtaButton onClick={() => performLogin(true)}>
          BankID på denna enhet
        </CtaButton>
        <OutlineButton onClick={() => performLogin(false)}>
          BankID på annan enhet
        </OutlineButton>
      </ButtonWrapper>
    ),
    [performLogin]
  );

  return useMemo(() => {
    if (!isIdle) return null;

    return (
      <MultiPartForm.Section
        values={values}
        part={part}
        validation={validation}
        title="Dina kontaktuppgifter"
      >
        <StepWrapper marginTop="tiny">
          {isAuthenticated ? (
            <p>
              Du är inloggad som{' '}
              <strong>
                {user?.firstName} {user?.lastName}
              </strong>{' '}
              och insamlingens administratör.
            </p>
          ) : (
            <>
              <p>
                För att skapa din insamling ber vi dig att bekräfta din
                identitet med BankID.
              </p>
              {isMobileBrowser() ? (
                mobileLoginButton
              ) : (
                <Button
                  children="Bekräfta med BankID"
                  onClick={() => performLogin(false)}
                />
              )}
            </>
          )}

          <IntegrityText />
        </StepWrapper>
      </MultiPartForm.Section>
    );
  }, [
    isAuthenticated,
    isIdle,
    part,
    user,
    validation,
    values,
    mobileLoginButton,
    performLogin
  ]);
}
