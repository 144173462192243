import React from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { heading2, preamble, externalLink } from '~/App/helpers/mixins';

// Shared components
import {
  Wrapper,
  ImageWrapper,
  ProductImage,
  Content
} from '~/App/shared/components/ThanksPage';
import { AsyncReceiptDocumentLink } from '~/App/shared/components/AsyncDocumentLink';
import { FetchPurchaseDocuments } from '~/App/shared/components/FetchPurchaseDocuments';
import { BlockBackground } from '~/App/shared/components/BlockBackground';

const Background = styled(BlockBackground)`
  padding: 3.125rem 1rem 5rem 1rem;
`;

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const ExternalLink = styled.a`
  ${externalLink};
`;

class PinkCompanyPackageThanks extends React.Component {
  render() {
    const { data = {}, purchase = {} } = this.props;
    const { product: { id: productId = '' } = {} } = purchase;

    const email =
      purchase?.purchase?.productOptions?.customerContact?.email ||
      purchase?.user?.email;

    const preambleWithEmail = data.preamble
      ? data.preamble.replace('%%email%%', email)
      : 'Din gåva bidrar till att föra cancerforskningen framåt – du är med och räddar liv.';

    const productContent = data[productId];
    const defaultImage =
      '//res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png';

    return (
      <div>
        <Background backgroundColor="primary">
          <Wrapper>
            <ImageWrapper>
              <ProductImage
                src={mediaHelper.getUrl(defaultImage, {
                  transformation: [
                    {
                      width: 280,
                      height: 280,
                      crop: 'lpad',
                      gravity: 'center'
                    },
                    {
                      width: 92,
                      height: 92,
                      dpr: '2.0',
                      crop: 'pad',
                      quality: 100
                    }
                  ]
                })}
              />
            </ImageWrapper>
            <Content>
              <Heading1>{data.heading || 'Tack!'}</Heading1>
              <Preamble>{preambleWithEmail}</Preamble>
              <FetchPurchaseDocuments purchase={purchase}>
                {({ receiptStatus, receipt }) => (
                  <AsyncReceiptDocumentLink
                    url={receipt}
                    status={receiptStatus}
                    paymentMethodId={purchase?.paymentMethod?.id}
                  />
                )}
              </FetchPurchaseDocuments>
              {productContent.linkUrl && productContent.linkText && (
                <ExternalLink
                  rel="noopener noreferrer"
                  href={productContent.linkUrl}
                  target="_blank"
                >
                  {productContent.linkText.trim()}
                </ExternalLink>
              )}
            </Content>
          </Wrapper>
        </Background>
      </div>
    );
  }
}

export default PinkCompanyPackageThanks;
