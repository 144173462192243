import React, { useMemo } from 'react';

// Constants
import { customerContactTypes } from '~/App/config/customerContactTypes';

// Shared components
import { StepWrapper } from '../../components/Forms/components/FormLayout';

import { ContactFields } from '../../components/Forms/components/ContactFields';

// Fields
import { MultiPartFormValues, Section } from '../../../MultiPartForm';
import { LotteryFormContent } from '../Lottery';
import { IValidation } from '../../../Validation';
import { LotteryTicketStateValues } from '../../components/States';

type Props = {
  formContent: LotteryFormContent;
  validation: IValidation;
  values: LotteryTicketStateValues & MultiPartFormValues;
};

export function Customer({ formContent, validation, values }: Props) {
  const showContactType = useMemo(
    () => ({
      company:
        formContent.availableCustomerContactTypes.indexOf(
          customerContactTypes.company
        ) !== -1,
      manual:
        formContent.availableCustomerContactTypes.indexOf(
          customerContactTypes.manual
        ) !== -1,
      automatic:
        formContent.availableCustomerContactTypes.indexOf(
          customerContactTypes.automatic
        ) !== -1
    }),
    [formContent.availableCustomerContactTypes]
  );

  return (
    <Section
      values={values}
      part={2}
      validation={validation}
      title="Din leveransadress"
      nextStepButton={{ text: 'Till betalsätt' }}
    >
      <StepWrapper>
        <ContactFields
          values={values}
          validation={validation}
          company={
            showContactType.company ? { email: true, phone: true } : false
          }
          manual={
            showContactType.manual
              ? {
                  email: true,
                  ssn: 'Åldersgräns på 18 år gäller vid köp av lotter.'
                }
              : false
          }
        />
      </StepWrapper>
    </Section>
  );
}
