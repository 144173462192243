import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @media print {
	html {
	  background: #fff;
	}

	body {
	  font-family: 'CancerfondenSans', sans-serif;
	  font-size: 10pt;
	  font-weight: 400;

	}

	// Remove header, footer and media.
	.header,
	.footer {
	  display: none;
	}

	// Remove Global Messages
	[class^="GlobalMessages__Wrapper-"] {
      display: none !important;
  	}

  	// Remove Table of Contents
  	#tableOfContents {
  	  display: none !important;
  	}

  	// Remove Article Page Footer
  	[class^="PageFooter__PageFooterWrapper-"] {
  	  display: none !important;
  	}

	video,
	audio,
	object,
	embed {
	  display: none;
	}

	h1 {
	  font-family: 'CancerfondenSans', sans-serif;
	  font-weight: 700;
	  font-size: 22pt;
	}

	h2 {
	  font-family: 'CancerfondenSans', sans-serif;
	  font-weight: 700;
	  font-size: 12pt;
	}

	h3, h4, h5 {
	  font-family: 'CancerfondenSans', sans-serif;
	  font-weight: 700;
	  font-size: 15pt;
	}


	//  Define some page breaks
	h1, h2, h3, h4, h5 {
	  page-break-after: avoid;
	}

	table, figure {
	  page-break-inside: avoid;
	}

	// Display link URL
	a[href]:after {
	  content: " <" attr(href) "> ";
	}

	// om-cancer cancersjukdommar list
	.sectionInfo {
	  display: none;
	}

	.diseasesList {
	  display: none;
	}

	 // stod-cancerfonden / editorials
	 .editorialImage {
	  display: none;
	 }

	.editorialItem {
	  &__meta {
	    display: none;
	  }
	}

	// Remove forms
	.wufoo-form-container {
	  display: none;
	}

	// Remove news feed
	.globalNewsFeed {
	  display: none;
	}

	#site-wrapper {
	  max-width: 622px !important;;
	}

	// Display link URL
	a[href^="http://"]::after, a[href^="https://"]::after {
	  content: " (" attr(href) ") ";
	  text-align: left !important;
	}

	a:not([href^="http"])::after {
	  content: " (https://www.cancerfonden.se" attr(href) ") ";
	  text-align: left !important;
	}

	// Style ArrowLink, DownloadLink and ExternalLink
	a[href^="http://"][class*="__PrimaryButton-"],
	a[href^="http://"][class*="__OutlinePrimaryButton-"],
	a[href^="http://"][class*="__CtaButton-"],
	a[href^="http://"][class*="__ArrowLink-"],
	a[href^="http://"][class*="__DownloadLink-"],
	a[href^="http://"][class*="__ExternalLink-"],
	.primaryButton[href^="http://"],
	.outlinePrimaryButton[href^="http://"],
	.ctaButton[href^="http://"],
	.arrowLink[href^="http://"],
	.downloadLink[href^="http://"],
	.externalLink[href^="http://"],
	a[href^="https://"][class*="__PrimaryButton-"],
	a[href^="https://"][class*="__OutlinePrimaryButton-"],
	a[href^="https://"][class*="__CtaButton-"],
	a[href^="https://"][class*="__ArrowLink-"],
	a[href^="https://"][class*="__DownloadLink-"],
	a[href^="https://"][class*="__ExternalLink-"],
	.primaryButton[href^="https://"],
	.outlinePrimaryButton[href^="https://"],
	.ctaButton[href^="https://"],
	.arrowLink[href^="https://"],
	.downloadLink[href^="https://"],
	.externalLink[href^="https://"] {
	  background-color: transparent !important;
	  padding: 0 !important;
	  border: 0 none !important;
	  font-weight: bold !important;
	  
	  &::after {
	    position: relative !important;
	    margin: 0 !important;
	    font-family: 'CancerfondenSans', sans-serif !important;
	    font-size: 1.125rem !important;
	    line-height: 1.25rem !important;
	    font-weight: normal !important;
	    content: " (" attr(href) ") " !important;
	    text-align: left !important;
	  }
	}

	a:not([href^="http"])[class*="__PrimaryButton-"],
	a:not([href^="http"])[class*="__OutlinePrimaryButton-"],
	a:not([href^="http"])[class*="__CtaButton-"],
	a:not([href^="http"])[class*="__ArrowLink-"],
	a:not([href^="http"])[class*="__DownloadLink-"],
	a:not([href^="http"])[class*="__ExternalLink-"],
	.primaryButton:not([href^="http"]),
	.outlinePrimaryButton:not([href^="http"]),
	.ctaButton:not([href^="http"]),
	.arrowLink:not([href^="http"]),
	.downloadLink:not([href^="http"]),
	.externalLink:not([href^="http"]) {
	  background-color: transparent !important;
	  padding: 0 !important;
	  border: 0 none !important;
	  font-weight: bold !important;
	  
	  &::after {
	    position: relative !important;
	    margin: 0 !important;
	    font-family: 'CancerfondenSans', sans-serif !important;
	    font-size: 1.125rem !important;
	    line-height: 1.25rem !important;
	    font-weight: normal !important;
	    content: " (https://www.cancerfonden.se" attr(href) ") " !important;
	    text-align: left !important;
	  }
	}

	* {
	  color: #000000 !important;
	  text-align: left !important;
	}

	body {
	  padding: 1rem !important;
	}

	// Style HR 
	hr {
	  background: none !important;
	  border-top: 1px solid #000 !important;
	}

	// Remove AuthorHelper links
	.kNndze {
	  display: none;
	}

	// Only show the Cancerfonden logo in the main header
	#headerContainer {
	  position: relative;
	  
	  [class^="MenuToggler__Wrapper-"] {
	    display: none !important;
	  }
	  
	  [class^="Brand__StyledLink-"]::after {
	    display: none !important;
	  }
	  
	  [class^="PrimaryNavigation-"] {
	    display: none !important;
	  }
	  
	  [class^="SecondaryNavigation-"] {
	    display: none !important;
	  }
	}

	[class^="Navbar__MenuHeight-"] {
	  display: none !important;
	}

	[class^="Navbar__SubMenuHeight-"] {
	  display: none !important;
	}

	[class*="BlockHero__Background-"] {
	  background-color: #fff !important;
	  background-image: none !important;
	  height: auto !important;
	}

	[class*="BlockHero__Content-"] {
	  margin: 0 !important;
	}

	[class*="BlockHero__Box-"] {
	  max-width: none !important;
	  align-self: flex-start !important;
	}

	[class*="BlockHero__HtmlContentWrapper-"] *,
	[class*="BlockHero__PreambleWrapper-"] * {
	}

	[class*="BlockHero__Heading1-"] {
	  margin: 0;
	}

	// Remove breadcrumbs
	[class^="Breadcrumbs__Block-"] {
	  display: none;
	}

	// Remove BlockBackground background-color
	[class^="BlockBackground-"] {
	  background-color: transparent !important;
	}

	// Style Contentblock
	[width="full"],
	[width="normal"],
	[width="limited"],
	[width="tight"],
	[width="narrow"],
	[width="slim"] {
	  max-width: 590px !important;;
	  margin: 0 !important;
	  padding-right: 0 !important;
	  padding-left: 0 !important;
	}

	// Remove blockquote quotation marks
	blockquote::before {
	  display: none !important;
	}

	// Remove SVG illustrations
	.svg-illustration {
	  background: transparent !important;
	  border: 1px solid #000000;
	  page-break-inside: avoid !important;
	  
	  &::before {
	    display: none !important;
	  }
	}

	// Style FAQ block
	[class^="FAQ__TwoColumns-"] {
	  flex-direction: column !important;;
	}

	[class^="FAQ__Heading"] {
	  font-weight: bold !important;
	  border-bottom: 0 none !important;
	  padding: 0 0 0.5rem 0 !important;
	  page-break-after: avoid !important;
	  
	  &::after {
	    display: none !important;
	  }
	}

	[class*="FAQ__Description-"] {
	  display: block !important;
	  border-bottom: 0 none !important;
	  padding: 0 0 1rem 0 !important;
	}

	// Style FileList
	[class^="FileList__Box-"] {
	  display: none !important;
	}

	[class^="FileList__Content-"] {
	  width: auto !important;
	  padding-left: 0 !important;
	  page-break-inside: avoid !important;
	}

	// Style Graph
	[class^="Graph__Canvas-"] {
	  margin: 1rem 0 2rem 0;
	}

	// Style LinkList
	[class^="LinkList__LinkListWrapper-"] {
	  flex-direction: column !important;
	}

	[class^="LinkList__UnorderedList-"] {
	  width: auto !important;
	}

	[class^="LinkList__UnorderedListItem-"] {
	  padding: 0 0 0.75rem 0 !important;
	}

	// Style Supporters
	[class^="Supporters__Wrapper-"] {
	  position: relative !important;
	  width: 100% !important;
	  font-size: 1.125rem !important;
	    
	  &::after {
	    display: block !important;
	    content: "För att se listan över sponsorer, besök https://www.cancerfonden.se/" !important;
	  }
	  
	  [class^="Supporters__Header-"] {
	    display: none !important;
	  }
	  
	  [class^="Supporters__Empty-"] {
	    display: none !important;
	  }
	}

	// Style TwoUp Media
	[class^="TwoUpMedia__Block-"] {
	  flex-direction: column !important;
	}

	[class^="TwoUpMedia__Figure-"], [class^="TwoUpMedia__Content-"] {
	  float: none !important;
	  width: 100% !important;
	  padding: 0 !important;
	}

	// Style LayouCell
	[class^="Layout__Cell-"] {
	  max-width: 100% !important;
	  flex-basis: 100% !important;
	}

	// Style NewsCard
	[class^="NewsCard__Card-"] {
	  page-break-inside: avoid !important;
	}

	[class^="NewsCard__Preamble-"] {
	  margin-bottom: 0 !important;
	  
	  + a {
	    display: none !important;
	  }
	}

	// Style GuideCard
	[class^="GuideCard__Card-"] {
	  background-color: transparent !important;
	  padding: 0 !important;
	}

	[class^="GuideCard__ImageContainer-"] {
	  display: none !important;
	}

	// Style Article Teaser
	[class^="ArticleTeaser__Wrapper"] {
	  page-break-inside: avoid !important;
	}

	[class^="ArticleTeaser__Figure"] {
	  display: none !important;
	}

	[class^="ArticleTeaser__HeadingLink-"] {
	  &::after {
	    display: none !important;
	  }
	}

	// Style Editorial Brick
	[class^="EditorialBrick__Figure-"] {
	  display: none !important;
	}

	// Style Sponsor Card
	[class^="SponsorCard__Wrapper-"] {
	  padding: 0 !important;
	  margin: 0 0 2rem 0;
	  background: transparent !important;
	  
	  img {
	    margin: 0 0 1rem 0 !important;
	  }
	  
	  [class^="SponsorCard__Preamble-"] {
	    margin: 0 !important;
	  }
	}

	// Style Partner Card
	[class^="PartnerCard__Wrapper-"] {
	  padding: 0 !important;
	  margin: 0 0 2rem 0;
	  background: transparent !important;
	  page-break-inside: avoid !important;
	  
	  img {
	    margin: 0 0 1rem 0 !important;
	  }
	  
	  [class^="PartnerCard__Preamble-"] {
	    margin: 0 !important;
	  }
	}

	// Style Package Card
	[class^="PackageCard__Wrapper-"] {
	  margin: 0 0 2rem 0;
	  padding: 0 !important;
	  background: transparent !important;
	  
	  [class^="PackageCard__Footer-"] {
	    display: none !important;
	  }
	}

	// Style Collection Item
	[class^="CollectionItem__Wrapper-"] {
	  padding-bottom: 1rem;
	  
	  &::after {
	    padding-left: 1rem;
	  }
	}

	[class^="CollectionItem__Figure-"] {
	  padding-bottom: 0 !important;
	  
	  [class^="CollectionItem__Image-"] {
	    display: none !important;
	  }
	  
	  [class^="CollectionItem__Progress-"] {
	    display: none !important;
	  }
	}

	[class^="CollectionItem__Content-"] {
	  padding: 1rem !important;
	}

	// Style Topic Card
	[class^="TopicCard__TopicCardLink-"] {
	  display: none !important;
	}

	// Style Product Grid
	[columnwidth=".grid-sizer"] {
	  height: auto !important;
	  
	  [class*="ProductGrid__GridSizer-"] {
	    position: relative !important;
	    width: 100% !important;
	    font-size: 1.125rem !important;
	    
	    &::after {
	      display: block !important;
	      content: "För att se listan över produkter, besök https://www.cancerfonden.se/" !important;
	    }
	  }
	  
	  [class^="Product__ProductWrapper-"] {
	    display: none !important;
	  }
	}

	// Remove Footer
	[class^="Footer__FooterMainContainer-"] {
	  display: none !important;
	}
  }
`;
