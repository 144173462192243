import React, { useCallback, useMemo, useState, ChangeEvent } from 'react';
import styled from 'styled-components';

import { BackgroundColor } from '~/types/Block';
import { Container } from '~/App/shared/components/Container';
import { BlockBackground } from '~/App/shared/components/BlockBackground';
import { HtmlContent } from '../../../HtmlContent';
import { heading2 } from '~/App/helpers/mixins';
import { IImageSource } from '~/types/IImage';
import { Card } from './components/Card';
import { mq } from '~/lib/mq';
import Select from '../../../Fields/Select';

const Heading = styled.div`
  ${heading2};
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 0 auto 0.5rem;
  max-width: 40rem;

  ${mq('>small')`
    text-align: center;
  `};
`;

const Preamble = styled(HtmlContent)`
  margin: 0 auto 0.5rem;
  max-width: 40rem;

  ${mq('>small')`
    text-align: center;
  `};
`;

const Cards = styled.div`
  margin: 2rem 0 0;
  flex-direction: column;
  display: none;

  ${mq('>small')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2rem -1rem 0;
  `};
`;

const DesktopOnly = styled.div`
  display: none;

  ${mq('>small')`
    display: block;
  `};
`;

const MobileOnly = styled.div`
  ${mq('>small')`
    display: none;
  `};
`;

type DisclaimerProps = {
  numberOfColumns: number;
};

const Disclaimer = styled(HtmlContent)<DisclaimerProps>`
  text-align: center;
  max-width: 35.5rem;
  margin: 0 auto;
  display: none;

  ${mq<DisclaimerProps>('>small')`
    display: ${({ numberOfColumns }) =>
      numberOfColumns === 3 ? 'block' : 'none'};
  `};

  p {
    font-size: 0.875rem;
    line-height: 1.875;
  }

  a {
    text-decoration: underline;
  }
`;

const StyledSelect = styled(Select)`
  display: block;
  margin-bottom: 1rem;

  ${mq('>small')`
    display: none;
  `};
`;

type Column = {
  heading: string;
  body: string;
  image: IImageSource;
  email: {
    heading: boolean;
    body: string;
    subject: string;
    files: string[];
  };
  button?: {
    url: string;
    text: string;
  };
};

type Props = {
  id: string;
  heading: string;
  body: string;
  columns: Column[];
  trackingCategory: string;
  disclaimer: string;
  stylingOptions?: {
    backgroundColor: BackgroundColor;
  };
};

export function EmailLeads({
  id,
  heading,
  body,
  columns,
  disclaimer,
  stylingOptions
}: Props) {
  const [selected, setSelected] = useState(0);

  const options = useMemo(
    () =>
      columns.map((column: Column, index) => ({
        label: column.heading,
        value: index
      })),
    [columns]
  );

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setSelected(parseInt(event.target.value, 10));
    },
    []
  );

  const renderCard = useCallback(
    (column: Column, index) => (
      <Card
        key={index}
        blockId={id}
        index={index}
        numberOfColumns={columns.length}
        disclaimer={disclaimer}
        {...column}
      />
    ),
    [columns.length, id, disclaimer]
  );

  const desktopOnly = useMemo(
    () => (
      <DesktopOnly>
        <Cards children={columns.map(renderCard)} />
      </DesktopOnly>
    ),
    [columns, renderCard]
  );

  const mobileOnly = useMemo(
    () => (
      <MobileOnly>
        <Card
          {...columns[selected]}
          index={selected}
          blockId={id}
          numberOfColumns={1}
          disclaimer={disclaimer}
        />
      </MobileOnly>
    ),
    [columns, id, selected, disclaimer]
  );

  return (
    <BlockBackground backgroundColor={stylingOptions?.backgroundColor}>
      <Container
        width="limited"
        paddingTop="large"
        paddingLeft="small"
        paddingRight="small"
        paddingBottom="large"
      >
        <Heading children={heading} />
        <Preamble children={body} />

        {options.length > 1 && (
          <StyledSelect
            value={selected}
            options={options}
            onChange={handleSelectChange}
          />
        )}

        {desktopOnly}
        {mobileOnly}
        <Disclaimer numberOfColumns={columns.length} children={disclaimer} />
      </Container>
    </BlockBackground>
  );
}
