import React from 'react';
import styled from 'styled-components';
import { angleDown } from '~/App/helpers/icons';

const Button = styled.div`
  position: absolute;
  width: 100%;
  padding: 0.75rem;
  left: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  text-align: center;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:after {
    ${angleDown};
    display: block;
    font-size: 1.5rem;
    line-height: 0.5;
  }
`;

type Props = {
  text?: string;
};

export function ScrollDownButton({ text }: Props) {
  return text ? <Button children={text} /> : null;
}
