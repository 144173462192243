import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { CustomerContactType } from '~/App/config/customerContactTypes';
import { getListPrice, getQuantity } from '~/App/helpers/purchase-helper';
import { useClientEffect } from '~/App/shared/hooks/use-client-effect';
import { usePreferredCustomerContactType } from '~/App/shared/hooks/use-preferred-customer-contact-type';
import { PaymentValues, ProductValues } from '../../States';

declare global {
  interface Window {
    klarnaAsyncCallback: any;
  }
}

type Props = {
  values: PaymentValues &
    ProductValues & {
      customerContactType?: CustomerContactType | null;

      handlers: {
        handleCustomerContactType?: (value: CustomerContactType | null) => void;
      } & Record<string, unknown>;
    };
  isGiftShop?: boolean;
};

const Wrapper = styled.div`
  padding: 1rem 0;
`;

const Container = styled.div`
  justify-content: center;
`;

export function KlarnaPaymentsFields({ values, isGiftShop = false }: Props) {
  usePreferredCustomerContactType({
    preferred: null,
    values
  });

  const price = useMemo(() => getListPrice(values.productOptions), [
    values.productOptions
  ]);

  const quantity = useMemo(() => getQuantity(values.productOptions), [
    values.productOptions
  ]);

  const totalPrice = price * quantity;

  const initKlarnaPayment = useCallback(async () => {
    await values.handlers.klarnaPaymentsPrefetch();
    await values.handlers.klarnaPaymentsInit(totalPrice, isGiftShop);
    await values.handlers.klarnaPaymentsLoad(totalPrice);
  }, [values.handlers, totalPrice, isGiftShop]);

  useClientEffect(() => {
    initKlarnaPayment();
  }, [values.product.id]);

  return (
    <Wrapper>
      <Container ref={values.klarna.container} />
    </Wrapper>
  );
}
