import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatMoney } from 'accounting';

// Helpers
import mq from '~/App/helpers/mq';
import { paymentMethods } from '~/App/config/paymentMethods';
import { products } from '~/App/config/products';
import { downloadLink, card } from '~/App/helpers/mixins';
import { formatDate } from '~/App/helpers/date.service';

// Shared components
import Link from '~/App/shared/components/Link';
import { Date } from '../../../shared/components/Date';
import { IMePurchase } from '~/types/IPurchase';

const Wrapper = styled.div`
  ${card}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;

  ${mq('≥small')`
    flex-direction: row;
  `};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const Type = styled.div`
  font-size: 1.125rem;
  line-height: 1.333333333;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin: 0 1rem 0.5rem 0;
  flex-grow: 1;

  ${mq('≥small')`
    font-size: 1.375rem;
    line-height: 1.3;
  `};
`;

const Sum = styled.div`
  font-size: 1.375rem;
  line-height: 1.090909091;
  font-weight: ${(props) => props.theme.fontWeights.bold};

  ${mq('≥small')`
    font-size: 2rem;
    line-height: 1;
  `};
`;

const Terminated = styled.p`
  flex-basis: 100%;
  font-size: 1rem;
`;

const Description = styled.div`
  width: 100%;
`;

const CardInformation = styled.div`
  margin-top: 1rem;
`;

const Actions = styled.ul`
  flex-basis: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1rem;

  li {
    margin-right: 1.25rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const DownloadLink = styled(Link)`
  ${downloadLink};
  line-height: 1.25rem;
  margin-top: 0;
  padding-right: 1.75rem;

  ${mq('≥small')`
    line-height: 1.5rem;
  `};
`;

type Props = {
  donation: IMePurchase;
};

export function DonationCard({ donation }: Props) {
  const isStripeSubscription = donation?.purchase?.isSubscription;
  const isActiveStripeSubscription = donation?.purchase?.hasActiveSubscription;
  const isMonthlyDonation =
    donation.product.id === products.monthlyDonation.id ||
    donation.product.id === products.monthlyDonationGala.id;
  const isAutogiroSubscription =
    donation.paymentMethod.id === paymentMethods.autoGiroBankId ||
    donation.paymentMethod.id === paymentMethods.autoGiroSlip;

  const lastFourDigits = donation?.paymentMethod?.cardDetails?.last4;
  const expiryMonth = donation?.paymentMethod?.cardDetails?.exp_month
    ?.toString()
    .padStart(2, '0');
  const expiryYear = donation?.paymentMethod?.cardDetails?.exp_year
    ?.toString()
    .slice(-2);
  const expiryDate =
    expiryMonth && expiryYear ? `${expiryMonth}/${expiryYear}` : null;

  const certificateDownloadName = useMemo(() => {
    if (donation.product.id === products.memorialGift.id) {
      return 'Minnesblad';
    } else if (donation.product.id === products.gratulationGift.id) {
      return 'Gratulationsblad';
    } else if (donation.product.id === products.giftCertificate.id) {
      return 'Gåvobevis';
    } else if (donation.product.name) {
      return donation.product.name.trim();
    } else {
      return 'Bifogad fil';
    }
  }, [donation.product.id, donation.product.name]);

  const certificateInfo = donation?.purchase?.productOptions?.certificate;
  const productName = donation.product.publicName || donation.product.name;
  const donationName = certificateInfo?.heading
    ? `${productName} - ${certificateInfo.heading}`
    : productName;

  return useMemo(
    () => (
      <Wrapper key={donation.id}>
        <Date date={donation.createdAt} />
        <Info>
          <Type children={donationName} />
          <Sum
            children={formatMoney(
              donation.amount,
              'kr',
              0,
              ' ',
              undefined,
              '%v %s'
            )}
          />
          {isStripeSubscription && !isActiveStripeSubscription ? (
            <Terminated>Avslutad</Terminated>
          ) : (
            <Description>
              {isMonthlyDonation && isAutogiroSubscription && (
                <p>Din gåva dras den 28:e varje månad via autogiro.</p>
              )}
              {isMonthlyDonation && isStripeSubscription && (
                <>
                  <p>
                    Din gåva dras den {formatDate(donation.createdAt, 'Do')}{' '}
                    varje månad via kort.
                  </p>
                  <CardInformation>
                    {lastFourDigits && <p>Betalkort **** {lastFourDigits}</p>}
                    {expiryDate && <p>Giltigt till {expiryDate}</p>}
                  </CardInformation>
                </>
              )}
            </Description>
          )}
          <Actions>
            {donation?.files?.receipt && (
              <li>
                <DownloadLink to={donation.files.receipt}>Kvitto</DownloadLink>
              </li>
            )}
            {donation?.files?.certificate && (
              <li>
                <DownloadLink to={donation.files.certificate}>
                  {certificateDownloadName}
                </DownloadLink>
              </li>
            )}
          </Actions>
        </Info>
      </Wrapper>
    ),
    [
      certificateDownloadName,
      donation.amount,
      donation.createdAt,
      donation.files.certificate,
      donation.files.receipt,
      donation.id,
      donationName,
      expiryDate,
      isActiveStripeSubscription,
      isAutogiroSubscription,
      isMonthlyDonation,
      isStripeSubscription,
      lastFourDigits
    ]
  );
}
