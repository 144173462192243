import { useEffect, useState, useCallback } from 'react';

import { fetch } from '~/App/helpers/http';

export function useImage(url: string) {
  const [cache, setCache] = useState<Record<string, string | null>>({});

  const download = useCallback(
    async (url: string) => {
      if (!url) {
        return;
      }

      if (cache[url]) {
        return;
      }

      try {
        const response = await fetch({
          url,
          responseType: 'arraybuffer'
        });

        setCache((state) => ({
          ...state,
          [url]: Buffer.from(response.data, 'binary').toString('base64')
        }));
      } catch (_) {
        setCache((state) => ({
          ...state,
          [url]: null
        }));
      }
    },
    [cache]
  );

  useEffect(() => {
    download(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!cache[url]) {
    return url;
  }

  return `data:image/png;base64,${cache[url]}`;
}
