import React, { ReactNode, useMemo } from 'react';

import { IValidation } from '../../../Validation';
import {
  useSubmitIncrease,
  PaymentMethod
} from './Submit/hooks/useSubmitIncrease';

type Props = {
  values: {
    productOptions: {
      product: {
        price: number | null;
        customPrice: boolean;
      };
      customerContact: {
        email?: string;
        ssn?: string;
      };
    };
  };
  paymentMethod: PaymentMethod;
  validation: IValidation;
  children: (result: any) => ReactNode;
};
export function SubmitIncrease({
  values,
  paymentMethod,
  validation,
  children
}: Props) {
  const submitValues = useSubmitIncrease({ values, paymentMethod, validation });
  return useMemo(
    () => (
      <>
        {children({
          ...submitValues,
          handleSubmit: submitValues.handleSubmit
        })}
      </>
    ),
    [children, submitValues]
  );
}
