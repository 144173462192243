import _ from 'lodash';
import React from 'react';

export default class EmbedVideo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { embed } = this.props;

    if (!_.isEmpty(embed)) {
      // Replace watch with embed
      if (_.includes(embed, 'watch?v=')) {
        embed = embed.replace('watch?v=', 'embed/');
      }

      // Append disabled show info based on query params
      if (_.includes(embed, '?')) {
        embed = embed + '&showinfo=0&rel=0';
      } else {
        embed = embed + '?showinfo=0&rel=0';
      }
    }

    if (_.isEmpty(embed)) {
      return false;
    }

    return (
      <div className="ui-videoPlayer">
        <iframe
          width="100%"
          height="100%"
          src={embed}
          frameBorder="0"
          allowFullScreen="1"
        />
      </div>
    );
  }
}

EmbedVideo.displayName = 'EmbedVideo';
