import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ShoppingCart } from '../ShoppingCart';
import { IPrimaryMenuItem, navigation } from '~/App/config/navigation';

// helpers
import mq from '~/App/helpers/mq';
import { AuthenticationButton } from '../AuthenticationButton';
import { MenuToggler } from './components/MenuToggler';
import { NavigationItem } from '../NavigationItem';

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
  left: 0;
  width: 100%;
  height: 3.5rem;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.white};
  order: 1;

  ${mq('≥mediumLarge')`
    display: none;
  `};
`;

export const ShoppingCartItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
  padding-bottom: 0.5rem;
`;

export const MenuTogglerItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 0.5rem;
  padding-bottom: 0.5rem;

  ${mq('≥micro')`
      margin: 0  0.75rem;
  `};
`;

type Props = {
  visible: boolean;
  toggleVisible: () => void;
};

export function HighlightedNavigation({ visible, toggleVisible }: Props) {
  const highlighted = useMemo(
    () => navigation.primaryMenuItems.filter((x) => x.highlighted),
    []
  );

  const renderItem = useCallback(
    (item: IPrimaryMenuItem, index: number) => (
      <NavigationItem key={index} item={item} />
    ),
    []
  );

  return useMemo(
    () => (
      <List>
        <MenuTogglerItem>
          <MenuToggler onClick={toggleVisible} primaryVisible={visible} />
        </MenuTogglerItem>
        {visible ? null : highlighted.map(renderItem)}
        {visible ? null : (
          <ShoppingCartItem>
            <ShoppingCart />
          </ShoppingCartItem>
        )}
        {visible ? null : <AuthenticationButton display="icon" />}
      </List>
    ),
    [highlighted, renderItem, toggleVisible, visible]
  );
}
