import _ from 'lodash';
import React, { useMemo } from 'react';

// Constants
import { gtmDetails } from '~/App/config/gtmDetails';

// Shared components
import Validation from '~/App/shared/components/Validation';

// Local components
import { Submit } from '../components/Submits';
import Stripe from '../components/Stripe';
import PaymentRedirect from '../components/PaymentRedirect';

import { Form } from './components/Form';
import { State } from '../components/States';

const defaultSchema = {
  'productOptions.pul': {
    validateIsTrue: true
  },
  'productOptions.product.price': {
    presence: true,
    numericality: {
      greaterThan: 49,
      lessThanOrEqualTo: 999999
    }
  },
  'productOptions.customerContact.email': {
    validateEmail: (_, {}) => true
  },
  'productOptions.customerContact.ssn': {
    validateSsn: true
  },
  'productOptions.customerContact.lastName': {
    validateLastName: true
  },
  'productOptions.customerContact.firstName': {
    validateFirstName: true
  },
  'productOptions.customerContact.addressZip': {
    validateZipCode: true
  },
  'productOptions.customerContact.addressCity': {
    validateCity: true
  },
  'productOptions.customerContact.addressStreet': {
    validateStreetName: true
  }
};

export function SupportMember({
  originPath,
  formContent,
  redirectPath,
  availablePaymentMethods,
  ...props
}) {
  const schema = useMemo(() => _.merge({}, defaultSchema, props.schema), [
    props.schema
  ]);

  const PRODUCT_ID = 8;
  const defaultPrice = 200;

  const defaultState = useMemo(
    () =>
      _.merge(
        {},
        {
          gtm: gtmDetails[PRODUCT_ID],
          product: { id: PRODUCT_ID },
          paymentMethod: { id: 5 },
          productOptions: {
            product: { price: defaultPrice, period: 'Year', quantity: 1 }
          }
        },
        props.defaultState
      ),
    [props.defaultState]
  );

  return (
    <State
      values={defaultState}
      formContent={{
        ...formContent,
        redirectPath,
        originPath
      }}
    >
      {(values) => (
        <Validation schema={schema} values={values}>
          {(validation) => (
            <Stripe>
              <Submit values={values} validation={validation}>
                {(submit) => (
                  <div>
                    <PaymentRedirect
                      submit={submit}
                      redirectPath={redirectPath}
                    />
                    <Form
                      values={values}
                      submit={submit}
                      validation={validation}
                      formContent={formContent}
                      availablePaymentMethods={availablePaymentMethods}
                    />
                  </div>
                )}
              </Submit>
            </Stripe>
          )}
        </Validation>
      )}
    </State>
  );
}
