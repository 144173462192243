import React, { useMemo } from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';

import TextField from '../../../Fields/Text';
import DateField from '../../../Fields/Date';

import { StepWrapper } from '../Forms/components/FormLayout';
import { InputWrapper } from '../../../Fields/InputWrapper';

const TextWrapper = styled(InputWrapper)`
  margin-top: 1rem;
`;

const DateWrapper = styled(InputWrapper)`
  margin-top: 1rem;

  ${mq('≥small')`
    max-width: 50%;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
`;

export function GenericCertificateDeliveryRecipient({
  validation,
  values,
  formContent
}) {
  const minDate = useMemo(() => new Date(), []);
  const maxDate = useMemo(
    () =>
      new Date(minDate.getFullYear(), minDate.getMonth() + 6, minDate.getDay()),
    [minDate]
  );

  return useMemo(
    () => (
      <StepWrapper paddingTop="none" marginTop="none">
        <Wrapper>
          <p>
            Vi skickar ett fint mejl med gåvobeviset till mottagaren i ditt
            namn. Välj att skicka direkt eller på ett visst datum.
          </p>
        </Wrapper>
        <TextWrapper>
          <TextField
            name="receiverEmail"
            type="email"
            value={values.productOptions.certificate.receiverContact.email}
            onBlur={() =>
              validation.handleBlur(
                'productOptions.certificate.receiverContact.email'
              )
            }
            errors={
              validation.errors[
                'productOptions.certificate.receiverContact.email'
              ]
            }
            isValid={validation.isValid(
              'productOptions.certificate.receiverContact.email'
            )}
            onChange={values.handlers.handleReceiverContactEmail}
            isInvalid={validation.isInvalid(
              'productOptions.certificate.receiverContact.email'
            )}
            placeholder="Mottagarens e-post"
            autoComplete="receiverEmail"
            instructions={formContent.receiverEmailCopy}
          />
        </TextWrapper>
        <TextWrapper>
          <TextField
            name="receiverName"
            type="text"
            value={values.productOptions.certificate.receiverContact.name}
            onBlur={() =>
              validation.handleBlur(
                'productOptions.certificate.receiverContact.name'
              )
            }
            errors={
              validation.errors[
                'productOptions.certificate.receiverContact.name'
              ]
            }
            isValid={validation.isValid(
              'productOptions.certificate.receiverContact.name'
            )}
            onChange={values.handlers.handleReceiverContactName}
            isInvalid={validation.isInvalid(
              'productOptions.certificate.receiverContact.name'
            )}
            placeholder="Mottagares namn"
            autoComplete="off"
          />
        </TextWrapper>
        <DateWrapper>
          <DateField
            value={values.productOptions.certificate.deliveryDate}
            onChange={values.handlers.setDeliveryDate}
            max={maxDate}
            min={minDate}
            isValid={validation.isValid(
              'productOptions.certificate.deliveryDate'
            )}
            isInvalid={validation.isInvalid(
              'productOptions.certificate.deliveryDate'
            )}
            placeholder="Skickas (åååå-mm-dd)"
          />
        </DateWrapper>
      </StepWrapper>
    ),
    [
      formContent.receiverEmailCopy,
      maxDate,
      minDate,
      validation,
      values.handlers.handleReceiverContactEmail,
      values.handlers.handleReceiverContactName,
      values.handlers.setDeliveryDate,
      values.productOptions.certificate.deliveryDate,
      values.productOptions.certificate.receiverContact.email,
      values.productOptions.certificate.receiverContact.name
    ]
  );
}
