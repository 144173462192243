export type CustomerContactType = 'company' | 'manual' | 'automatic';

export const customerContactTypes: Record<
  CustomerContactType,
  CustomerContactType
> = {
  company: 'company',
  manual: 'manual',
  automatic: 'automatic'
};
