import React from 'react';
import styled from 'styled-components';

import { HandleLoginOptions, HandleLoginResponse } from '~/App/contexts/Login';

import { BankId } from '../../../../BankId';
import { ViewWrapper } from '../../../../ViewWrapper';

const Heading = styled.p`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  width: 100%;
`;

const Text = styled.p`
  margin-bottom: 1.5rem;
`;

type Props = {
  onLogin: (options: HandleLoginOptions) => Promise<void | HandleLoginResponse>;
};

export function Login({ onLogin }: Props) {
  return (
    <ViewWrapper hero="newsletter" footer>
      <BankId beginAuthentication={onLogin}>
        <Heading>Uppdatera dina kontaktuppgifter </Heading>
        <Text>
          Bekräfta gärna dina kontaktuppgifter med BankID så kan du enkelt
          uppdatera dem i framtiden.
        </Text>
      </BankId>
    </ViewWrapper>
  );
}
