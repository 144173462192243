import styled from 'styled-components';

type InputIconProps = {
  iconUrl: string;
};

export const InputIcon = styled.div<InputIconProps>`
  position: absolute;
  top: 8px;
  left: 0.75em;
  width: 1.4375rem;
  height: 1.875rem;
  background: ${({ iconUrl }) =>
    'url(' + iconUrl + ') center center no-repeat'};
  background-size: contain;
  z-index: 1;
`;
